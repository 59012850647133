import { Grid } from '@mui/material';
import { useState, useRef } from 'react';
import { debounce, isEmpty } from 'lodash';

import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import CellRendererDate from 'shared/view/elements/DataGrid/columns/CellRendererDate';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { useUpdateEffect } from 'shared/view/hooks/useUpdateEffect';
import {
  EmptyGridContainer,
  EmptyFilteredList,
} from 'shared/view/elements/EmptyGrid/EmptyGrid';

import { useUsersList } from '../store/useUsersList';
import { CellRendererList } from '../../users/view/OrganizationUsersTable';

type ColumnType = NonNullable<ReturnType<typeof useUsersList>['data']>[0];

const columns: DataGridColumn<ColumnType>[] = [
  {
    field: 'Name',
    flex: 3,
    renderCell: ({ row }) => {
      let name: string = row.fullName;

      if (isEmpty(name) && row.firstName && row.lastName) {
        name = `${row.firstName} ${row.lastName}`;
      }
      return <CellRendererString dataTest="user-name" value={name} />;
    },
  },
  {
    field: 'Email',
    flex: 2,
    renderCell: (params) => (
      <CellRendererString dataTest="user-email" value={params.row.email} />
    ),
  },
  {
    field: 'Organizations',
    flex: 2,
    renderCell: (params) => (
      <CellRendererList
        items={params.row.organizations.map((org) => org.name)}
      />
    ),
  },

  {
    field: 'ID',
    minWidth: 100,
    renderCell: (params) => (
      <CellRendererString
        dataTest="organization-org-id"
        value={String(params.row.id)}
      />
    ),
  },
  {
    field: 'joinedAt',
    headerName: 'Joined at',
    flex: 1,
    minWidth: 200,
    renderCell: (params) => (
      <CellRendererDate value={params.row.joinedAt} showTime />
    ),
  },
];
const Users = (props: ReturnType<typeof useUsersList>) => {
  const { load } = props;
  const [search, setSearch] = useState('');

  const debouncedSearch = useRef(
    debounce((searchTerm: string) => {
      load({ variables: { query: { search: searchTerm } } });
    }, 300)
  ).current;

  useUpdateEffect(() => {
    debouncedSearch(search);
  }, [search]);

  return (
    <div data-test="users">
      <Grid item={true} style={{ width: '270px', marginBottom: 20 }}>
        <TextInput
          label="Filter by user's name or email"
          name="search"
          value={search}
          withoutError={true}
          withoutLabelPadding={true}
          startAdornmentIcons={[
            <IconAwesomeInfo key={0} icon={ICONS.magnifyingGlass} />,
          ]}
          onChange={setSearch}
        />
      </Grid>
      {props.data ? (
        <DataGridWithTypes
          columns={columns}
          heightType="pageHeight"
          rows={props.data}
          communication={props.communication}
          context="loading users"
        />
      ) : (
        <EmptyGridContainer>
          <EmptyFilteredList
            message="Use the search bar to find users"
            icon={ICONS.magnifyingGlass}
          />
        </EmptyGridContainer>
      )}
    </div>
  );
};

export default Users;
