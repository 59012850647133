export interface BasePoint {
  x: number;
  y: number;
}

export const calculateVectorLength = (p1: BasePoint, p2: BasePoint) => {
  const xLength = Math.abs(p1.x - p2.x);
  const yLength = Math.abs(p1.y - p2.y);
  return Math.sqrt(Math.pow(xLength, 2) + Math.pow(yLength, 2));
};

const calculateYVectorLength = (p1: BasePoint, p2: BasePoint) => {
  const yLength = Math.abs(p1.y - p2.y);
  return Math.sqrt(Math.pow(yLength, 2));
};

const calculateXVectorLength = (p1: BasePoint, p2: BasePoint) => {
  const xLength = Math.abs(p1.x - p2.x);
  return Math.sqrt(Math.pow(xLength, 2));
};

const calculateXAndYLengths = (p1: BasePoint, p2: BasePoint) => {
  return {
    x: calculateXVectorLength(p1, p2),
    y: calculateYVectorLength(p1, p2),
  };
};

export const arrangePoints = (p1: BasePoint, p2: BasePoint) => [
  {
    x: Math.min(p1.x, p2.x),
    y: Math.min(p1.y, p2.y),
  },
  {
    x: Math.max(p1.x, p2.x),
    y: Math.max(p1.y, p2.y),
  },
];

export const findClosestPoint = <Point extends BasePoint>(
  searchPoint: BasePoint,
  points: Point[]
): Point => {
  return points.reduce((acc, curr) => {
    const accXYLengths = calculateXAndYLengths(acc, {
      x: searchPoint.x,
      y: searchPoint.y,
    });
    const currXYLengths = calculateXAndYLengths(curr, {
      x: searchPoint.x,
      y: searchPoint.y,
    });
    if (accXYLengths.x === currXYLengths.x) {
      return accXYLengths.y < currXYLengths.y ? acc : curr;
    }
    return accXYLengths.x < currXYLengths.x ? acc : curr;
  });
};
