import { dropLastWhile, dropWhile } from 'ramda';

import { RegisteredModelVersionLockLevel } from 'generated/types';
import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';
import { makeObjectFromArray } from 'shared/utils/collection';

export type RegisteredModelVersionLockLevelWithoutUnknown = Exclude<
  RegisteredModelVersionLockLevel,
  RegisteredModelVersionLockLevel.UNKNOWN
>;

export type IChangingLockLevelsAccess = Record<
  RegisteredModelVersionLockLevelWithoutUnknown,
  boolean
>;

export interface IAllowedActionsForCheckingLockLevelsAccess {
  lock: boolean;
  unlock: boolean;
}

export const orderedLockLevels =
  exhaustiveStringTuple<RegisteredModelVersionLockLevelWithoutUnknown>()(
    RegisteredModelVersionLockLevel.OPEN,
    RegisteredModelVersionLockLevel.REDACT,
    RegisteredModelVersionLockLevel.CLOSED
  );

export const getChangingLockLevelsAccess = (
  currentLockLevel: RegisteredModelVersionLockLevel,
  allowedActions: IAllowedActionsForCheckingLockLevelsAccess
): IChangingLockLevelsAccess => {
  if (allowedActions.lock && allowedActions.unlock) {
    return makeObjectFromArray(() => true, orderedLockLevels);
  }

  if (!allowedActions.lock && !allowedActions.unlock) {
    return makeObjectFromArray(() => false, orderedLockLevels);
  }

  if (allowedActions.lock && !allowedActions.unlock) {
    const lockLevelsHigherThanCurrent = dropWhile(
      (lockLevel) => currentLockLevel !== lockLevel,
      orderedLockLevels
    );
    return makeObjectFromArray(
      (lockLevel) => lockLevelsHigherThanCurrent.includes(lockLevel),
      orderedLockLevels
    );
  }

  const lockLevelsLessThanCurrent = dropLastWhile(
    (lockLevel) => currentLockLevel !== lockLevel,
    orderedLockLevels
  );
  return makeObjectFromArray(
    (lockLevel) => lockLevelsLessThanCurrent.includes(lockLevel),
    orderedLockLevels
  );
};

export const getChangingLockLevelsAccessForNewVersion = ({
  lock,
}: {
  lock: boolean;
}) => makeObjectFromArray(() => lock, orderedLockLevels);
