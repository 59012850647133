import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';

import {
  MODEL_ACTIONS_DATA_QUERY,
  ModelActionsData,
  ModelActionsDataVariables,
} from '../graphql';

type Props = ModelActionsDataVariables;

export const useModelActions = ({ id }: Props) => {
  const query = useCustomQuery<ModelActionsData, ModelActionsDataVariables>(
    MODEL_ACTIONS_DATA_QUERY,
    { variables: { id } }
  );

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return {
    data,
    communication,
    reload: query.refetch,
  };
};

const convertResponse = ({ registeredModel }: ModelActionsData) =>
  mapDataOrError(registeredModel, (model) => model);
