/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { partition, pipe } from 'ramda';

import { IAttributeWithWidgetBlob } from 'shared/models/AttributeWithWidgetBlob';
import { isReservedAttribute } from 'shared/models/ReservedAttribute';
import {
  ARTIFACT_FRAGMENT,
  ARTIFACT_WITH_DATASET_VERSION_FRAGMENT,
} from 'shared/graphql/Artifact/Artifact';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import { convertGraphqlAttributeWithWidgetBlob } from 'shared/graphql/Attribute/converters';
import { IAttribute } from 'shared/models/Attribute';
import noop from 'shared/utils/noop';
import { RequiredEndpoint } from 'shared/models/CompareEntities/new/endpointsComparing';
import { getEnvironment } from 'shared/models/Deployment/canary/Endpoint';
import { useFirstRenderEffect } from 'shared/view/hooks/useFirstRenderEffect';
import { IEnvironmentBlob } from 'shared/models/Versioning/Blob/EnvironmentBlob';
import { convertEnvironmentBlob } from 'shared/models/Versioning/Blob/EnvironmentBlob/converters';
import { OmitStrict } from 'shared/utils/types';
import { CODE_VERSION_FRAGMENT } from 'shared/graphql/CodeVersion/CodeVersion';
import useQueryFromFunction from 'shared/utils/graphql/useQueryFromFunction';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
  isNotNullableRestrictedGraphqlError as isNotError,
} from 'shared/graphql/ErrorFragment';
import {
  CustomAttributeTargetType,
  CustomAttributeType,
  EndpointEnvironmentStatus,
} from 'generated/types';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { ArtifactWithDatasetVersionData } from 'shared/graphql/Artifact/graphql-types/Artifact.generated';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { ExtractByTypename } from 'shared/utils/types';
import { CUSTOM_ATTRIBUTE_VALUE_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeValue';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import {
  LOAD_CUSTOM_ATTRIBUTE_DEFINITIONS,
  LoadCustomAttributeDefinitions,
  LoadCustomAttributeDefinitionsVariables,
  CustomAttributeDefinitions,
  CustomAttributeValue,
} from 'features/catalog/shared/graphql';

import * as GraphqlTypes from './graphql-types/useRegisteredModelVersionsForComparing.generated';

export type ComparedRegisteredModelVersion = OmitStrict<
  ExtractByTypename<
    GraphqlTypes.RegisteredModelVersionForComparing['registeredModelVersion'],
    'RegisteredModelVersion'
  >,
  | 'attributes'
  | 'dateCreated'
  | 'dateUpdated'
  | 'datasets'
  | 'deployments'
  | 'environmentBlob'
> & {
  endpoints: RequiredEndpoint[];
  attributes: IAttribute[];
  dateCreated: number;
  dateUpdated: number;
  datasets: Array<
    Pick<
      ArtifactWithDatasetVersionData,
      'path' | 'key' | 'linkedDatasetVersion'
    >
  >;
  environment: IEnvironmentBlob | undefined;
};

const REGISTERED_MODEL_VERSION_FOR_COMPARING = gql`
  query RegisteredModelVersionForComparing($versionId: ID!) {
    registeredModelVersion(id: $versionId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        description
        dateCreated
        dateUpdated
        labels
        version
        attributes {
          ...AttributeData
        }
        artifacts {
          ...ArtifactData
        }
        customAttributeValues {
          id
          ...CustomAttributeValueData
        }
        datasets {
          ...ArtifactWithDatasetVersionData
        }
        codeVersions {
          key
          codeVersion {
            ...CodeVersion
          }
        }
        owner {
          ...OwnerData
        }
        registeredModel {
          id
          name
          taskType
          dataType
          versions {
            versions {
              id
              stage
            }
          }
          workspace {
            id
            name
          }
        }
        deployments {
          endpoint {
            id
            path
            workspace {
              id
              name
            }
            environments {
              id
              status
            }
          }
        }
        run {
          ... on Error {
            ...ErrorData
          }
          ... on ExperimentRun {
            id
            name
            project {
              name
              id
              workspace {
                id
                ...WorkspaceData
              }
            }
          }
        }
        stage
        environmentBlob
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${CODE_VERSION_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
  ${ARTIFACT_WITH_DATASET_VERSION_FRAGMENT}
  ${ARTIFACT_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_VALUE_FRAGMENT}
`;

export const useRegisteredModelVersionsForComparing = (ids: string[]) => {
  const organizationId = useCurrentOrganizationV2() || '';

  const [
    loadRegisteredModelVersionsForComparing,
    loadingRegisteredModelVersions,
    registeredModelVersions,
  ] = useQueryFromFunction(
    (apolloClient) => async () => {
      const results = await Promise.all(
        ids.map((versionId) =>
          apolloClient.query<
            GraphqlTypes.RegisteredModelVersionForComparing,
            GraphqlTypes.RegisteredModelVersionForComparingVariables
          >({
            query: REGISTERED_MODEL_VERSION_FOR_COMPARING,
            variables: { versionId },
          })
        )
      );

      const { data: definitionsData } = await apolloClient.query<
        LoadCustomAttributeDefinitions,
        LoadCustomAttributeDefinitionsVariables
      >({
        query: LOAD_CUSTOM_ATTRIBUTE_DEFINITIONS,
        variables: { organizationId },
      });

      const definitions = mapDataOrError(
        definitionsData,
        ({ organizationV2 }: LoadCustomAttributeDefinitions) =>
          mapDataOrError(organizationV2, (organization) =>
            mapDataOrError(
              organization.customAttributeDefinitions,
              (response) => response.customAttributeDefinitions
            )
          )
      );

      let versionDefinitions: CustomAttributeDefinitions = [];

      if (isNotError(definitions)) {
        versionDefinitions = definitions.filter(
          (definition) =>
            definition.targetType ===
            CustomAttributeTargetType.REGISTERED_MODEL_VERSION
        );
      }

      return results.map(({ data }) => {
        return convert(data, versionDefinitions);
      });
    },
    { onCompleted: noop, onError: noop }
  );

  useFirstRenderEffect(() => {
    loadRegisteredModelVersionsForComparing();
  });

  return {
    registeredModelVersions,
    loadingRegisteredModelVersions,
  };
};

function convert(
  data: GraphqlTypes.RegisteredModelVersionForComparing,
  definitions: CustomAttributeDefinitions
) {
  return mapDataOrError(
    data.registeredModelVersion,
    (x): ComparedRegisteredModelVersion => ({
      ...x,
      endpoints: x.deployments.map((deployment) => ({
        id: deployment.endpoint.id,
        path: deployment.endpoint.path,
        status:
          getEnvironment(deployment.endpoint)?.status ??
          EndpointEnvironmentStatus.UNKNOWN,
        workspaceName: deployment.endpoint.workspace.name,
      })),
      attributes: [
        ...groupAttributes(
          x.attributes.map(convertGraphqlAttributeWithWidgetBlob)
        ).customAttributes.filter(({ type }) => type === 'regular'),
        ...fillCustomAttributes({
          definitions,
          values: x.customAttributeValues,
        }),
      ],
      environment: x.environmentBlob
        ? convertEnvironmentBlob(x.environmentBlob) || undefined
        : undefined,
    })
  );
}

export function isHiddenAttribute(attribute: IAttributeWithWidgetBlob) {
  return (
    attribute.key.startsWith('__verta_feature_data_') ||
    isReservedAttribute(attribute)
  );
}

const groupAttributes = (
  attributes: IAttributeWithWidgetBlob[]
): {
  trainingDataDistributionAttributes: IAttributeWithWidgetBlob[];
  customAttributes: IAttributeWithWidgetBlob[];
} => {
  const trainingDataPrefix = '__verta_training_data_';

  return pipe(
    () => attributes.filter((x) => !isHiddenAttribute(x)),
    partition((a) => a.key.startsWith(trainingDataPrefix)),
    ([trainingDataDistributionAttributes, customAttributes]) => ({
      customAttributes,
      trainingDataDistributionAttributes:
        trainingDataDistributionAttributes.map((x) => ({
          ...x,
          key: x.key.replace(trainingDataPrefix, ''),
        })),
    })
  )();
};

const fillCustomAttributes = ({
  definitions,
  values,
}: {
  definitions: CustomAttributeDefinitions;
  values: CustomAttributeValue[];
}) => {
  return definitions.map((definition) => {
    const caValue = values.find(
      (attr) => definition.id === attr.customAttributeDefinitionId
    );

    const value =
      definition.attributeType === CustomAttributeType.CUSTOM_LIST
        ? definition.customAttributeListOptions?.find(
            (opt) => opt.id === caValue?.selectedValue
          )?.name
        : caValue?.selectedValue;

    return {
      key: definition.name,
      type: definition.attributeType,
      value,
      icon: definition.icon,
    };
  });
};
