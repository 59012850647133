import { useMemo } from 'react';
import { scaleOrdinal } from '@visx/scale';
import { uniq } from 'ramda';

import { Size } from 'shared/utils/charts/chartSizes';
import { OnSetReset } from 'shared/utils/charts/zoom/useZoomReset';
import MultiLineChart, {
  MultiLineChartRenderAdditionalContentProps,
} from 'shared/view/charts/MultiLineChart/MultiLineChart';
import { MultiLineChartData } from 'shared/view/charts/MultiLineChart/utils/types';
import { chartsDataColors } from 'shared/view/charts/shared/colors';
import { formatDateNumber } from 'shared/utils/charts/formatDateNumber';
import { MultiLineChartTooltipData } from 'shared/view/charts/MultiLineChart/utils/tooltip';
import { MonitoringAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import TooltipWithAlertDecorations from 'shared/view/domain/Monitoring/MonitoringAlert/AlertDecorations/TooltipWithAlertDecorations/TooltipWithAlertDecorations';
import { getLinearDateScaleDomain } from 'shared/utils/charts/getLinearDateScaleDomain';
import { useActiveKeys } from 'shared/utils/activeKeys';
import { useChartLegendProps } from 'shared/utils/charts/chartLegendProps/useChartLegendProps';
import ChartWithLegendContainer from 'shared/view/charts/ChartWithLegendContainer/ChartWithLegendContainer';

import TimeSeriesChartAlertDecorations from './TimeSeriesChartAlertDecorations/TimeSeriesChartAlertDecorations';

const makeRenderTooltipContent =
  (props: { alert: MonitoringAlert | undefined; yLabel: string }) =>
  (d: MultiLineChartTooltipData<TimeSeriesChartData>) => {
    return (
      <TooltipWithAlertDecorations
        alert={props.alert}
        date={d.closestPoint.meta.time}
        label={props.yLabel}
        values={[d.closestPoint.meta]}
      >
        {null}
      </TooltipWithAlertDecorations>
    );
  };

export interface TimeSeriesChartData {
  key: string;
  time: Date;
  value: number;
  modelVersion: string;
}

const makeRenderAlertDecorations = (alert: MonitoringAlert | undefined) => {
  if (alert) {
    return (props: MultiLineChartRenderAdditionalContentProps) => {
      return (
        <TimeSeriesChartAlertDecorations
          alerter={alert.alerter}
          innerWidth={props.chartSizes.innerWidth}
          yScale={props.yScale}
        />
      );
    };
  }

  return undefined;
};

interface Props {
  id: string;
  size: Size;
  data: TimeSeriesChartData[];
  yLabel: string;
  onSetReset: OnSetReset;
  alert: MonitoringAlert | undefined;
}

const TimeSeriesChart = (props: Props) => {
  const data = useMemo(
    () => props.data.map(convertData).filter((d) => isFinite(d.y)),
    [props.data]
  );

  const renderAlertDecorations = useMemo(
    () => makeRenderAlertDecorations(props.alert),
    [props.alert]
  );

  const renderTooltipContent = useMemo(
    () =>
      makeRenderTooltipContent({ alert: props.alert, yLabel: props.yLabel }),
    [props.alert, props.yLabel]
  );

  const xDomain = useMemo(
    () => getLinearDateScaleDomain(data.map((d) => d.x)),
    [data]
  );

  const keys = useMemo(() => uniq(data.map((d) => d.key)), [data]);

  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: keys,
        range: chartsDataColors,
      }),
    [keys]
  );

  const { activeKeys, toggleKey } = useActiveKeys(keys);
  const legendProps = useChartLegendProps({
    activeKeys,
    toggleKey,
    legendPosition: 'right',
    colorScale,
  });

  const filteredData = useMemo(
    () => data.filter((d) => activeKeys.includes(d.key)),
    [data, activeKeys]
  );

  const renderChart = (size: Size) => (
    <MultiLineChart
      data={filteredData}
      id={props.id}
      width={size.width}
      height={size.height}
      colorScale={colorScale}
      xLabel="Date"
      yLabel={props.yLabel}
      onSetReset={props.onSetReset}
      xTickFormat={formatDateNumber}
      xDomain={xDomain}
      xNumTicks={6}
      renderTooltipContent={renderTooltipContent}
      renderAdditionalContent={renderAlertDecorations}
    />
  );

  if (keys.length > 1) {
    return (
      <ChartWithLegendContainer
        legendProps={legendProps}
        widgetSize={props.size}
      >
        {renderChart}
      </ChartWithLegendContainer>
    );
  }

  return renderChart(props.size);
};

const convertData = (
  data: TimeSeriesChartData
): MultiLineChartData<TimeSeriesChartData> => {
  return {
    key: data.key,
    meta: data,
    y: data.value,
    x: data.time.getTime(),
  };
};

export default TimeSeriesChart;
