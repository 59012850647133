import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { Box, BoxProps, Typography } from '@mui/material';

import {
  validateEmail,
  combineValidators,
  validateEmailNotEmpty,
  validateNotEmpty,
  validatePassword,
} from 'shared/utils/validators';
import { IRegistrationByEmail } from 'shared/models/Authorization';
import { registerUser, selectCommunications } from 'features/user/store';
import { authErrorMessages } from 'shared/utils/customErrorMessages';
import { useToastCommunicationError } from 'features/toast/store/hooks';
import Button from 'shared/view/elements/Button/Button';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import { validateUsername } from 'shared/graphql/User/User';

import { ValidPasswordHelper } from '../../../shared/ValidPasswordHelper';

interface Props extends BoxProps {
  goBack(): void;
}

const initialForm: IRegistrationByEmail = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  username: '',
};

const getFieldName = makeGetFieldName<IRegistrationByEmail>();

const SignupByEmailForm: React.FC<React.PropsWithChildren<Props>> = ({
  goBack,
  ...others
}) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const toastCommunicationError = useToastCommunicationError();

  const { registrationUser } = useSelector(selectCommunications);

  useEffect(() => {
    if (registrationUser.error) {
      toastCommunicationError(registrationUser.error, {
        context: 'logging in',
        customErrorMessageByType: {
          emailAlreadyExists: authErrorMessages.emailAlreadyExists,
          usernameAlreadyExists: authErrorMessages.usernameAlreadyExists,
          companyAlreadyExists: authErrorMessages.companyAlreadyExists,
        },
      });
    }
  }, [registrationUser.error, toastCommunicationError]);

  const onSubmit = useCallback(
    (values: IRegistrationByEmail) => {
      dispatch(registerUser(values));
    },
    [dispatch]
  );

  return (
    <Box {...others}>
      <Box>
        <Button
          onClick={goBack}
          isLoading={false}
          size="small"
          variant="text"
          icon={ICONS.arrowLeft}
        >
          BACK
        </Button>
      </Box>
      <Typography variant="h6" mb={1}>
        Sign-up
      </Typography>

      <PresetFormik<IRegistrationByEmail>
        initialValues={initialForm}
        onSubmit={onSubmit}
      >
        {({ submitForm, values }) => (
          <FormStack spacing={0}>
            <TextInputField
              name={getFieldName({ username: null })}
              validate={validateUsername}
              label="Username"
              isRequired={true}
              size="small"
            />
            <TextInputField
              name={getFieldName({ firstName: null })}
              validate={validateNotEmpty('first name')}
              label="First name"
              isRequired={true}
              size="small"
            />
            <TextInputField
              name={getFieldName({ lastName: null })}
              validate={validateNotEmpty('last name')}
              label="Last name"
              isRequired={true}
              size="small"
            />
            <TextInputField
              name={getFieldName({ email: null })}
              validate={combineValidators([
                validateEmailNotEmpty,
                validateEmail,
              ])}
              label="Email"
              isRequired={true}
              size="small"
            />
            <TextInputField
              name={getFieldName({ password: null })}
              validate={validatePassword}
              label="Password"
              isRequired={true}
              size="small"
              type="password"
            />
            <ValidPasswordHelper password={values.password} />
            <Box mb={1} mt={2}>
              <Button
                onClick={submitForm}
                isLoading={registrationUser.isRequesting}
                size="small"
                variant="contained"
              >
                Sign up
              </Button>
            </Box>
          </FormStack>
        )}
      </PresetFormik>
    </Box>
  );
};

export default SignupByEmailForm;
