import { gql } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/useDeleteRole.generated';

export { Types };

export const QUERY = gql`
  mutation DeleteRoleToOrganizationV2($organizationId: ID!, $roleId: ID!) {
    organizationV2(id: $organizationId) {
      ... on OrganizationV2 {
        id
        role(id: $roleId) {
          id
          delete {
            ... on OrganizationV2 {
              id
              roles {
                orgId
                id
                name
                builtIn
                resourceActions {
                  resourceType
                  allowedActions
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useDeleteRole = () => {
  const [run, communication] = useCustomMutation<
    Types.DeleteRoleToOrganizationV2,
    Types.DeleteRoleToOrganizationV2Variables
  >(QUERY, {
    context: 'deleting role',
  });

  return {
    run,
    communication,
  };
};
