import {
  isNotNullableRestrictedGraphqlError,
  RestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { typeSafeConfiguration } from 'shared/view/elements/DataGrid/configuration/helpers/typeSafeConfiguration';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';

interface Row {
  modelVersionId?: string;
  modelVersion?: { version: string } | RestrictedGraphqlError;
}

const getModelVersion = (row: Row) =>
  isNotNullableRestrictedGraphqlError(row.modelVersion)
    ? row.modelVersion.version
    : row.modelVersionId;

export const modelVersionColumn: DataGridColumn<Row> = {
  field: 'Model version',
  flex: 1,
  minWidth: 150,
  additionalConfiguration: typeSafeConfiguration(
    ['sort', 'filter'],
    'string',
    (params) => getModelVersion(params.row)
  ),
  renderCell: (params) => (
    <CellRendererString value={getModelVersion(params.row)} />
  ),
};
