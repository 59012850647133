import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material';

import CopyButton from '../CopyButton/CopyButton';

interface Props {
  id: string;
}

const StyledStack = styled(Stack)({
  '&:hover > div': {
    opacity: 1,
  },
});

const IconWrapper = styled('div')({
  opacity: 0,
});

const IdTopEnhancer = (props: Props) => {
  return (
    <StyledStack direction="row" alignItems="center">
      <Typography variant="formValue">{`ID: ${props.id}`}</Typography>
      <IconWrapper>
        <CopyButton copyText={props.id} size="sm" />
      </IconWrapper>
    </StyledStack>
  );
};

export default IdTopEnhancer;
