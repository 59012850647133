import { FieldArray, FormikErrors } from 'formik';
import { isNil } from 'lodash';
import { Box, Grid } from '@mui/material';

import FormStack from 'shared/view/elements/FormStack/FormStack';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import Button from 'shared/view/elements/Button/Button';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import {
  validateNotEmpty,
  combineValidators,
  validate,
  validateMaxLength,
} from 'shared/utils/validators';
import { CustomAttributeDefinitionInput } from 'generated/types';

import {
  getField,
  EMPTY_CUSTOM_ATTRIBUTE_ITEM_INPUT,
} from './CustomModelAttributePopup';

type Props = {
  values: CustomAttributeDefinitionInput['customAttributeListOptions'];
  errors?: FormikErrors<any>[];
  initialExpandedItemIndex?: number;
};

export const CustomModelAttributeListItemManager = ({
  values,
  errors,
}: Props) => {
  const validateDuplicates = (v: string) => {
    const isValid = values
      ? values.filter((val) => val.name === v).length <= 1
      : false;
    return validate({
      isValid,
      errorMessage: 'duplicated options',
    });
  };

  return (
    <FieldArray name={getField({ customAttributeListOptions: null })}>
      {({ push, remove }) => (
        <FormStack pt={3}>
          {!isNil(values) &&
            values.map((_, i) => {
              return (
                <Grid container xxs={12} key={`custom-attr-option-${i}`}>
                  <Grid item xxs={11}>
                    <TextInputField
                      label="Option"
                      name={getField({
                        customAttributeListOptions: {
                          [i]: {
                            name: null,
                          },
                        },
                      })}
                      validate={combineValidators([
                        validateDuplicates,
                        validateNotEmpty('option'),
                        validateMaxLength(250),
                      ])}
                      isRequired
                      errorMessage={errors?.[i]?.name as unknown as string}
                      withoutError={!Boolean(errors?.[i]?.name)}
                    />
                  </Grid>
                  <Grid item xxs={1}>
                    <Box
                      display="flex"
                      justifyContent="end"
                      alignItems="start"
                      pt="12px"
                    >
                      <IconAwesomeClickable
                        onClick={() => remove(i)}
                        dataTest="delete-checklist-template-item"
                        size="lg"
                        icon={ICONS.trashCan}
                      />
                    </Box>
                  </Grid>
                </Grid>
              );
            })}

          <Button
            onClick={async () => {
              push({ ...EMPTY_CUSTOM_ATTRIBUTE_ITEM_INPUT });
            }}
            dataTest="add-custom-attribute-item"
            isLoading={false}
            size="medium"
            variant="text"
            icon={ICONS.circlePlus}
          >
            Add list item
          </Button>
        </FormStack>
      )}
    </FieldArray>
  );
};
