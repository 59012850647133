import { useEffect, useState } from 'react';
import { Skeleton, Stack, Typography } from '@mui/material';
import moment from 'moment';

import CardLayout from 'shared/view/elements/CardLayout/CardLayout';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';

import { EditCommmentPopup } from './EditComment';
import { useModelVersionActivities } from '../../hooks/useModelVersionActivities';

const defaultData = {
  comment: '',
  author: {
    fullName: '',
  },
  timeCreated: 0,
};

const CommentCard = ({ id }: { id: string }) => {
  const { openPopup, isPopupOpen, closePopup } = usePopupManager();
  const { data, communication } = useModelVersionActivities({ id });
  const [displayedData, setDisplayedData] = useState(defaultData);

  useEffect(() => {
    if (!isNotNullableRestrictedGraphqlError(data)) {
      return;
    } else if (data.length) {
      const lastComment = data[data.length - 1];
      setDisplayedData({
        comment: lastComment.comment || '',
        author: {
          fullName: lastComment.author.fullName,
        },
        timeCreated: lastComment.timeCreated,
      });
    }
  }, [communication, data, setDisplayedData]);

  return (
    <CardLayout
      sx={{ height: 231, justifySelf: 'flex-end' }}
      isEmbeded={false}
      header={{
        leftContent: (
          <Typography variant="subtitle2" color="text.secondary">
            Overall comment
          </Typography>
        ),
        rightContent: (
          <IconAwesomeClickable
            icon={ICONS.pencil}
            onClick={openPopup}
            size="lg"
            dataTest="evaluation-comment-view"
            disabled={false}
          />
        ),
      }}
    >
      {communication.isRequesting ? (
        <Stack p={3}>
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
        </Stack>
      ) : (
        <>
          <Stack
            p={3}
            spacing={2}
            direction="column"
            justifyContent="space-between"
            height="100%"
          >
            <DynamicTypography
              maxLines={4}
              color={displayedData.comment ? 'text.primary' : 'text.disabled'}
              value={
                displayedData.comment ? displayedData.comment : 'No comment'
              }
              variant="body2"
            />
            <Stack direction="row" spacing={2}>
              {displayedData.author.fullName ? (
                <Stack direction="row" spacing={1}>
                  <IconAwesomeInfo icon={ICONS.user} size="sm" />
                  <Typography variant="caption" color="text.secondary">
                    {displayedData.author.fullName}
                  </Typography>
                </Stack>
              ) : null}
              {displayedData.timeCreated ? (
                <Stack direction="row" spacing={1}>
                  <IconAwesomeInfo icon={ICONS.clock} size="sm" />
                  <Typography variant="caption" color="text.secondary">
                    Created at {moment(displayedData.timeCreated).fromNow()}
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
          </Stack>
          <EditCommmentPopup
            isPopupOpen={isPopupOpen}
            closePopup={closePopup}
            id={id}
            initialValue={displayedData.comment}
          />
        </>
      )}
    </CardLayout>
  );
};

export default CommentCard;
