import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { KafkaConfigurationInput } from 'generated/types';
import DefaultMatchRemoteData from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { useToast } from 'features/toast/store/hooks';

import useKafkaConfigurations from '../../hooks/useKafkaConfigurations';
import { HandleKafkaForm } from './HandleKafkaForm';
import { useUpdateKafkaConfiguration } from '../../hooks/useUpdateKafkaConfiguration';
import { useCreateKafkaConfiguration } from '../../hooks/useCreateKafkaConfiguration';

const KafkaSetupPopup = ({
  onClose,
  setIsLoading,
  reload,
}: {
  onClose: () => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const alertToast = useToast();

  const onCompletedSuccessfully = () => {
    alertToast('Kafka update successfully saved!', 'success');
    reload();
  };
  const { data, communication } = useKafkaConfigurations();
  const { createKafkaConfiguration, creatingKafkaConfiguration } =
    useCreateKafkaConfiguration({
      onCompleted: onCompletedSuccessfully,
    });

  const { updateKafkaConfiguration, updatingKafkaConfiguration } =
    useUpdateKafkaConfiguration({
      onCompleted: onCompletedSuccessfully,
    });

  // To handle the loading on the button to setup
  useEffect(() => {
    setIsLoading(communication.isRequesting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communication.isRequesting]);

  const handleSubmit = useCallback(
    async (values: KafkaConfigurationInput, id?: string) => {
      if (id) {
        await updateKafkaConfiguration({ ...values, id });
      } else {
        await createKafkaConfiguration({ config: values });
      }
      onClose();
    },
    [updateKafkaConfiguration, createKafkaConfiguration, onClose]
  );

  return (
    <DefaultMatchRemoteData
      data={data}
      communication={communication}
      context="loading kafka configurations"
      LoadingComponent={<></>}
    >
      {(configurations) => {
        const config = configurations[0];
        const hasKafkaConfiguration = isEmpty(config);
        const initialValues: KafkaConfigurationInput = {
          brokerAddresses: hasKafkaConfiguration ? '' : config.brokerAddresses,
          enabled: hasKafkaConfiguration ? true : config.enabled,
          name: hasKafkaConfiguration ? '' : config.name,
          tls: hasKafkaConfiguration ? undefined : config.tls,
          kerberos: hasKafkaConfiguration ? undefined : config.kerberos,
        };

        return (
          <HandleKafkaForm
            handleSubmit={handleSubmit}
            initialValues={initialValues}
            onClose={onClose}
            onCompletedSuccessfully={onCompletedSuccessfully}
            isSaving={
              creatingKafkaConfiguration.isRequesting ||
              updatingKafkaConfiguration.isRequesting
            }
            deleteIsDisabled={hasKafkaConfiguration}
            configurationId={hasKafkaConfiguration ? undefined : config.id}
          />
        );
      }}
    </DefaultMatchRemoteData>
  );
};

export default KafkaSetupPopup;
