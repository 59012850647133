import Grid from '@mui/material/Grid';
import { Outlet, useNavigate } from 'react-router-dom';

import routes from 'shared/routes';
import {
  MonitoringModel,
  MonitoringNotInitializedError,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import { MonitoringModelContextProvider } from 'features/monitoring/models/model/store/state/context';
import { useMonitoringModelState } from 'features/monitoring/models/model/store/state/useMonitoringModelState';
import { useMonitoringModelQuery } from 'features/monitoring/models/model/store/graphql/useMonitoringModel';
import { matchRemoteData } from 'shared/utils/redux/communication/remoteData';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
import Preloader from 'shared/view/elements/Preloader/Preloader';
import {
  useAppBreadcrumbs,
  AppBreadcrumbs,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import InlineCommunicationError from 'shared/view/elements/Errors/InlineCommunicationError/InlineCommunicationError';
import {
  isRestrictedGraphqlError,
  restrictedGraphqlErrorToAppError,
} from 'shared/graphql/ErrorFragment';
import BaseErrorBoundary from 'shared/utils/react/BaseErrorBoundary';
import DeleteIconButton from 'shared/view/elements/DeleteIconButton/DeleteIconButton';
import { useDeleteMonitoringModel } from 'features/monitoring/models/modelSettings/store/useDeleteMonitoringModel';
import { useRouteParams } from 'shared/utils/router/useRouteParams';

import { useMonitoringPageBreadcrumb } from '../shared/useMonitoringPageBreadcrumb';
import { useMonitoringModelPageBreadcrumbs } from './shared/useMonitoringModelPageBreadcrumbs';

const ErrorPage = ({
  error,
}: {
  error: Error | MonitoringNotInitializedError;
}) => {
  const model =
    error instanceof MonitoringNotInitializedError ? error.model : undefined;

  const params = useRouteParams(routes.monitoringDashboards);
  const breadcrumbs = useMonitoringModelPageBreadcrumbs({
    modelName: model?.name ?? '',
  });
  const navigate = useNavigate();
  const deleteApi = useDeleteMonitoringModel({
    onCompleted: () =>
      navigate(
        routes.monitoring.getRedirectPath({
          workspaceName: model?.workspaceName || params.workspaceName,
        })
      ),
  });

  return (
    <AuthorizedLayout pageEntityOrError={undefined} breadcrumbs={breadcrumbs}>
      <Grid container={true} justifyContent="flex-end">
        <DeleteIconButton
          disabled={deleteApi.deletingEntity.isRequesting}
          description="Model"
          onDelete={() =>
            deleteApi.deleteEntity({
              monitoringModelId: params.monitoringModelId,
            })
          }
        />
      </Grid>
      <Grid
        container={true}
        alignItems="center"
        justifyContent="center"
        style={{ padding: '40px' }}
      >
        <InlineCommunicationError context="running application" error={error} />
      </Grid>
    </AuthorizedLayout>
  );
};

const MonitoringModelPagesWithErrorHandling = () => {
  const breadcrumbs = useAppBreadcrumbs(useMonitoringPageBreadcrumb());

  return (
    <BaseErrorBoundary renderError={(error) => <ErrorPage error={error} />}>
      <MonitoringModelPages breadcrumbs={breadcrumbs} />
    </BaseErrorBoundary>
  );
};

const MonitoringModelPages = (props: { breadcrumbs: AppBreadcrumbs }) => {
  const { monitoringModelId } = useRouteParams(routes.monitoringModel);

  const { data, communication } = useMonitoringModelQuery({
    monitoringModelId,
  });

  return matchRemoteData(communication, data, {
    error: ({ error }) => (
      <AuthorizedLayout
        pageEntityOrError={undefined}
        breadcrumbs={props.breadcrumbs}
      >
        <InlineCommunicationError context="loading model" error={error} />
      </AuthorizedLayout>
    ),
    notAsked: () => (
      <AuthorizedLayout
        pageEntityOrError={undefined}
        breadcrumbs={props.breadcrumbs}
      >
        <Preloader />
      </AuthorizedLayout>
    ),
    requesting: () => (
      <AuthorizedLayout
        pageEntityOrError={undefined}
        breadcrumbs={props.breadcrumbs}
      >
        <Preloader />
      </AuthorizedLayout>
    ),
    success: (loadedData) => {
      if (isRestrictedGraphqlError(loadedData)) {
        return (
          <AuthorizedLayout
            pageEntityOrError={undefined}
            breadcrumbs={props.breadcrumbs}
          >
            <InlineCommunicationError
              context="loading model"
              error={restrictedGraphqlErrorToAppError(loadedData)}
            />
          </AuthorizedLayout>
        );
      }

      return <MonitoringModelPagesLoaded data={loadedData} />;
    },
  });
};

const MonitoringModelPagesLoaded = (props: { data: MonitoringModel }) => {
  const { model, updateModelState } = useMonitoringModelState({
    model: props.data,
  });

  return (
    <MonitoringModelContextProvider
      model={model}
      updateModelState={updateModelState}
    >
      <Outlet />
    </MonitoringModelContextProvider>
  );
};

export default MonitoringModelPagesWithErrorHandling;
