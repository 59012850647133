import { param, route } from 'shared/routes/shared/routesTreeBuilder';

export const privateRoutes = route('endpoints', {
  tag: 'endpoints',
})(
  param()('endpointId', undefined)(
    route('overview', {
      tag: 'endpointOverview',
    })(),
    route('usage', {
      tag: 'endpointUsage',
    })(),
    route('builds', {
      tag: 'endpointBuilds',
    })(),
    route('settings', {
      tag: 'endpointSettings',
    })(),
    route('streaming', {
      tag: 'endpointStreaming',
    })()
  )
);
