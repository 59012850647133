import { Stack, Typography } from '@mui/material';

import Button from 'shared/view/elements/Button/Button';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import FiltersPopoverContentWrapper from './FiltersPopoverContentWrapper';

interface Props<Filter> {
  filters: Filter[];
  applyFilters: (filters: Filter[]) => void;
  updateFilter: (filter: Filter) => void;
  removeFilter: (id: number) => void;
  addFilter: () => void;
  onCancel: () => void;
  renderFilterItem: (filter: Filter, plusIcon: JSX.Element) => JSX.Element;
}

const FiltersPopoverContent = <Filter extends object>(props: Props<Filter>) => {
  const onApplyClick = () => {
    props.applyFilters(props.filters);
    props.onCancel();
  };

  const plusIcon = (
    <IconAwesomeClickable
      size="lg"
      icon={ICONS.circlePlus}
      dataTest="add-filter"
      onClick={props.addFilter}
    />
  );

  return (
    <FiltersPopoverContentWrapper>
      <Stack flexGrow="1" justifyContent={'space-between'}>
        <Stack>
          {props.filters.map((filter) =>
            props.renderFilterItem(filter, plusIcon)
          )}
          {props.filters.length === 0 ? (
            <Stack direction="row" alignItems="center">
              {plusIcon}
              <Typography variant="helperText" color="text.secondary">
                Click the plus icon to add filtering options
              </Typography>
            </Stack>
          ) : null}
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button variant="outlined" onClick={props.onCancel} isLoading={false}>
            Cancel
          </Button>
          <Button
            dataTest="apply-filters"
            onClick={onApplyClick}
            isLoading={false}
          >
            Apply
          </Button>
        </Stack>
      </Stack>
    </FiltersPopoverContentWrapper>
  );
};

export default FiltersPopoverContent;
