/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IEnvironmentBlob } from 'shared/models/Versioning/Blob/EnvironmentBlob';
import { IDockerEnvironmentBlob } from 'shared/models/Versioning/Blob/EnvironmentBlob/DockerEnvironmentBlob';
import {
  IPythonEnvironmentBlob,
  PythonEnvironmentRequirements,
} from 'shared/models/Versioning/Blob/EnvironmentBlob/PythonEnvironmentBlob';

export const convertServerEnvironmentBlob = (
  serverEnvironment: Record<any, any>
): IEnvironmentBlob => {
  if (serverEnvironment.python) {
    return {
      category: 'environment',
      data: {
        data: convertServerPythonBlob(serverEnvironment.python),
        apt: serverEnvironment.apt,
        commandLine: serverEnvironment.command_line,
        variables: serverEnvironment.environment_variables,
      },
    };
  }

  if (serverEnvironment.docker) {
    return {
      category: 'environment',
      data: {
        data: convertServerDockerBlob(serverEnvironment.docker),
        apt: serverEnvironment.apt,
        commandLine: serverEnvironment.command_line,
        variables: serverEnvironment.environment_variables,
      },
    };
  }

  throw new Error(
    `${JSON.stringify(serverEnvironment)} unknown environment type`
  );
};

const convertServerPythonBlob = (serverPython: any): IPythonEnvironmentBlob => {
  return {
    type: 'python',
    data: {
      requirements: ((): PythonEnvironmentRequirements | undefined => {
        if (typeof serverPython.raw_requirements != 'undefined') {
          return { type: 'raw', requirements: serverPython.raw_requirements };
        } else if (typeof serverPython.requirements != 'undefined') {
          return { type: 'list', requirements: serverPython.requirements };
        }
        return undefined;
      })(),
      constraints: serverPython.constraints,
      pythonVersion: serverPython.version,
    },
  };
};

const convertServerDockerBlob = (serverDocker: any): IDockerEnvironmentBlob => {
  return {
    type: 'docker',
    data: {
      repository: serverDocker.repository,
      sha: serverDocker.sha,
      tag: serverDocker.tag,
    },
  };
};
