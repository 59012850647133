import { useRef, useState, MouseEvent } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Stack, { StackProps } from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { OmitStrict } from 'shared/utils/types';
import {
  isNonEmptyArray,
  NonEmptyArray,
} from 'shared/utils/opaqueTypes/NonEmptyArray';

import { ICONS } from '../../IconAwesome/ICONS';

const EXPAND_SIZE = {
  maxHeight: 300,
  minWidth: 120,
  maxWidth: 300,
};

const STACK_COMMON_PROPS: StackProps = {
  direction: 'row',
  alignItems: 'center',
  style: { width: '100%' },
};

export type ListItem<T> = {
  id: string;
  value: T;
};

type Props<T> = {
  list: ListItem<T>[] | undefined;
  columnKey: string;
  renderer(value: T): JSX.Element;
  additionalActions?: JSX.Element[];
};

export default function CellRendererList<T>(props: Props<T>): JSX.Element {
  if (props.list && isNonEmptyArray(props.list)) {
    if (props.list.length === 1) {
      return (
        <Stack {...STACK_COMMON_PROPS}>
          <AdditionalActions additionalActions={props.additionalActions} />
          {props.renderer(props.list[0].value)}
        </Stack>
      );
    }

    return <ListWithExpandButton {...props} list={props.list} />;
  }

  return (
    <Stack {...STACK_COMMON_PROPS}>
      <AdditionalActions additionalActions={props.additionalActions} />
      <CellRendererString value={null} />
    </Stack>
  );
}

export function ListWithExpandButton<T>(
  props: OmitStrict<Props<T>, 'list'> & {
    list: NonEmptyArray<ListItem<T>>;
    withoutFirstElement?: boolean;
  }
): JSX.Element {
  const { list, renderer, columnKey } = props;
  const cellValueRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack {...STACK_COMMON_PROPS}>
        <AdditionalActions additionalActions={props.additionalActions} />
        {!props.withoutFirstElement ? (
          <div ref={cellValueRef} style={{ width: '100%', overflow: 'hidden' }}>
            {renderer(list[0].value)}
          </div>
        ) : null}
        <IconAwesomeClickable
          dataTest="expand-list"
          icon={ICONS.ellipsisVertical}
          onClick={handleClick}
        />
      </Stack>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Paper>
          <Box
            maxHeight={EXPAND_SIZE.maxHeight}
            minWidth={EXPAND_SIZE.minWidth}
            maxWidth={EXPAND_SIZE.maxWidth}
          >
            <Grid
              container={true}
              direction="row"
              alignItems={'flex-start'}
              style={{ width: '100%' }}
              spacing={1}
              p={2}
            >
              {list.map((item) => (
                <Grid
                  item={true}
                  key={`${columnKey}-${item.id}`}
                  style={{ maxWidth: '100%' }}
                >
                  <span>{renderer(item.value)}</span>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      </Popover>
    </>
  );
}

function AdditionalActions<T>({
  additionalActions,
}: Pick<Props<T>, 'additionalActions'>) {
  return (
    <>
      {additionalActions
        ? additionalActions.map((value, i) => <div key={i}>{value}</div>)
        : null}
    </>
  );
}
