import { isNil, pipe } from 'ramda';

import {
  IAttribute,
  IListAttribute,
  IObjectAttribute,
  IPrimitiveAttribute,
  isListAttribute,
  isObjectAttribute,
  isPrimitiveAttribute,
  matchAttribute,
} from 'shared/models/Attribute';
import { isDifferentIfAtleastOneItemIsDifferentOrMissed } from 'shared/models/CompareEntities/helpers';
import { putDifferentItemsBeforeOthers } from 'shared/models/CompareEntities/sortings';
import { every } from 'shared/utils/collection';
import matchBy from 'shared/utils/matchBy';

import compareItemsWithTheSameKey from '../compareItemsWithTheSameKey';

export type IEntitiesAttributesDiff = Array<IEntitiesAttributesDiffByKey>;
type IEntitiesAttributesDiffByKey = {
  key: string;
  isDifferent: boolean;
  diffs: Array<IAttributeDiff>;
};

type IAttributeDiff =
  | IListAttributeDiff
  | IPrimitiveAttributeDiff
  | IObjectAttributeDiff
  | IMissedAttributeDiff;

type IMissedAttributeDiff = {
  type: 'missed';
  attribute: undefined;
  isDifferent: boolean;
};
const missedDiff: IMissedAttributeDiff = {
  type: 'missed',
  attribute: undefined,
  isDifferent: true,
};

type IListAttributeDiff = {
  type: 'list';
  isDifferent: boolean;
  attribute: IListAttribute;
  values: Array<{
    isDifferent: boolean;
    value: IListAttribute['value'][number];
  }>;
};

type IObjectAttributeDiff = {
  type: 'object';
  isDifferent: boolean;
  attribute: IObjectAttribute;
};

type IPrimitiveAttributeDiff = {
  type: 'primitive';
  attribute: IPrimitiveAttribute;
  isDifferent: boolean;
};

//

export type IEntityWithAttributes = {
  id: string;
  attributes: Array<IAttribute>;
};

export const compareAttributes = (
  entities: IEntityWithAttributes[]
): IEntitiesAttributesDiff => {
  return pipe(
    () => getAttributesDiffByKeys(entities),
    (x) => putDifferentItemsBeforeOthers(({ isDifferent }) => isDifferent, x)
  )();
};

function getAttributesDiffByKeys(
  entities: IEntityWithAttributes[]
): IEntitiesAttributesDiff {
  return compareItemsWithTheSameKey(
    (attribute) => attribute.key,
    (attributes, key): IEntitiesAttributesDiffByKey => {
      const diffs = compareAttributesByKey(attributes);
      return {
        isDifferent: diffs.some((x) => x.isDifferent),
        key,
        diffs,
      };
    },
    entities.map(({ attributes }) => attributes)
  );
}

const compareAttributesByKey = (
  attributes: (IAttribute | undefined)[]
): IAttributeDiff[] => {
  return matchBy(
    checkPrimitiveAttributesOrListAttributes(attributes),
    'type'
  )({
    differentAttributesTypesOrThereIsSomeMissed: () =>
      attributes.map((attribute) => {
        if (attribute) {
          return matchAttribute<IAttributeDiff>(
            {
              list: allListValuesAreDifferent,
              object: (obj) => ({
                isDifferent: true,
                type: 'object',
                attribute: obj,
              }),
              primitive: differentPrimitiveAttribute,
            },
            attribute
          );
        }
        return missedDiff;
      }),
    list: ({ listAttributes }) => compareListAttributes(listAttributes),
    object: ({ objectAttributes }) => compareObjectAttributes(objectAttributes),
    primitive: ({ primitiveAttributes }) =>
      comparePrimitiveAttributes(primitiveAttributes),
  });
};

const checkPrimitiveAttributesOrListAttributes = (
  attributes: (IAttribute | undefined)[]
):
  | { type: 'list'; listAttributes: IListAttribute[] }
  | { type: 'primitive'; primitiveAttributes: IPrimitiveAttribute[] }
  | { type: 'object'; objectAttributes: IObjectAttribute[] }
  | {
      type: 'differentAttributesTypesOrThereIsSomeMissed';
      attributes: (IAttribute | undefined)[];
    } => {
  if (thereIsNotMissedAttribute(attributes)) {
    if (every(isListAttribute, attributes)) {
      return { type: 'list', listAttributes: attributes };
    }
    if (every(isPrimitiveAttribute, attributes)) {
      return {
        type: 'primitive',
        primitiveAttributes: attributes,
      };
    }
    if (every(isObjectAttribute, attributes)) {
      return {
        type: 'object',
        objectAttributes: attributes,
      };
    }
  }
  return { type: 'differentAttributesTypesOrThereIsSomeMissed', attributes };
};

const differentPrimitiveAttribute = (
  attribute: IPrimitiveAttribute
): IPrimitiveAttributeDiff => ({
  type: 'primitive',
  attribute,
  isDifferent: true,
});
const comparePrimitiveAttributes = (
  attributes: IPrimitiveAttribute[]
): IPrimitiveAttributeDiff[] => {
  return attributes.map((attribute) => ({
    isDifferent: attributes.some(
      (anotherAttribute) => anotherAttribute.value !== attribute.value
    ),
    attribute,
    type: 'primitive',
  }));
};

const allListValuesAreDifferent = (
  attribute: IListAttribute
): IListAttributeDiff => ({
  type: 'list',
  isDifferent: true,
  attribute,
  values: attribute.value.map((value) => ({
    isDifferent: true,
    value,
  })),
});

const compareListAttributes = (
  attributes: IListAttribute[]
): IListAttributeDiff[] => {
  return attributes
    .map((attribute) => {
      const valuesDiff = attribute.value.map((value) => ({
        value,
        isDifferent: attributes.some(
          (anotherAttr) =>
            anotherAttr.value.find((x) => x === value) === undefined
        ),
      }));
      return {
        type: 'list' as const,
        values: valuesDiff,
        attribute,
      };
    })
    .map((diff, _, allDiffs) => ({
      ...diff,
      isDifferent: allDiffs.some(({ values }) =>
        values.some(({ isDifferent }) => isDifferent)
      ),
    }));
};

const compareObjectAttributes = (
  attributes: IObjectAttribute[]
): IObjectAttributeDiff[] => {
  return attributes.map((attribute) => {
    return {
      type: 'object' as const,
      attribute,
      isDifferent: isDifferentIfAtleastOneItemIsDifferentOrMissed(
        (x) => x,
        attributes.map(({ value }) => value)
      ),
    };
  });
};

const thereIsNotMissedAttribute = <T>(
  items: (T | undefined)[]
): items is T[] => {
  return !items.some(isNil);
};
