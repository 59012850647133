import { groupBy } from 'ramda';

import { IObservationWithExperimentRun } from 'shared/models/Observation';
import { ObservationAttribute } from 'shared/graphql/ExperimentRunDetails/Observation/graphql-types/Observation.generated';
import isNotNil from 'shared/utils/isNotNill';
import { AttributeData_FloatKeyValue_ } from 'shared/graphql/Attribute/graphql-types/Attribute.generated';
import { ExperimentRunWithDisplayedFields } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import { isObservationAttribute } from 'shared/graphql/ExperimentRunDetails/Observation/Observation';

import { ChartExperimentRun, ChartObservation } from './types';

export const convertObservationToObservationsWithCorrectValue = <
  T extends ObservationAttribute,
>(
  observations: T[]
): (T & {
  attribute: AttributeData_FloatKeyValue_;
})[] => {
  return (
    observations
      // observations can be boolean or string but we currently only support numeric values
      .map((observation) =>
        observation.attribute.__typename === 'StringKeyValue' &&
        !isNaN(Number(observation.attribute.stringValue))
          ? {
              ...observation,
              attribute: {
                key: observation.attribute.key,
                __typename: 'FloatKeyValue',
                floatValue: Number(observation.attribute.stringValue),
              },
            }
          : observation
      )
      .map((observation) =>
        observation.attribute.__typename === 'FloatKeyValue'
          ? { ...observation, attribute: observation.attribute }
          : null
      )
      .filter(isNotNil)
  );
};

export const convertExperimentRunsToObservationsWithExperimentRun = (
  experimentRuns: ChartExperimentRun[]
): IObservationWithExperimentRun[] => {
  return experimentRuns.flatMap((expRun) =>
    expRun.observations.map((observation) => ({
      ...observation,
      experimentRun: {
        name: expRun.name,
        id: expRun.id,
      },
    }))
  );
};

export const groupObservationsByExperimentRun = <T extends ChartObservation>(
  observations: T[]
): Record<string, T[]> => {
  return groupBy((obs) => obs.experimentRun.id, observations);
};

export const convertChartExperimentRun = (
  experimentRun: Pick<
    ExperimentRunWithDisplayedFields,
    'id' | 'name' | 'observations'
  >
) => ({
  id: experimentRun.id,
  name: experimentRun.name,
  observations: experimentRun.observations.filter(isObservationAttribute),
});
