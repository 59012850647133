import { monitoringWidgetMinSize } from 'features/monitoring/panel/store/useCustomMonitoringPanel';
import { MonitoringMetricType } from 'generated/types';
import {
  getLabelByMonitoringMetricType,
  getModelMetricsByType,
} from 'shared/models/Monitoring/MonitoringMetricType';
import generateId from 'shared/utils/generateId';

import { MonitoringPanel } from '../../../MonitoringPanel/MonitoringPanel';
import { MakeDefaultModelStateProps } from '../../shared/MakeDefaultModelStateProps';
import { MonitoringIODescription } from '../../../MonitoringIODescription';
import {
  NotInitializedSingleMetricWidget,
  SingleMetricWidget,
} from '../../../MonitoringPanel/MonitoringWidget/Widgets/SingleMetricWidget';

export const summaryPanelId = 'summaryPanelId';

const makeItem = (
  metricType: MonitoringMetricType,
  defaultOutput: MonitoringIODescription | undefined,
  index: number
) => {
  const width = 3;
  const x = width * index;

  const layout = {
    minH: monitoringWidgetMinSize,
    minW: monitoringWidgetMinSize,
    h: 4,
    i: generateId(),
    w: 3,
    y: 0,
    x,
  };

  const title = getLabelByMonitoringMetricType(metricType);

  if (defaultOutput) {
    const widget: SingleMetricWidget = {
      id: layout.i,
      type: 'singleMetric',
      title,
      variant: {
        type: 'metric',
        output: defaultOutput,
        metricType,
      },
    };

    return {
      layout,
      widget,
    };
  }

  const widget: NotInitializedSingleMetricWidget = {
    id: layout.i,
    title,
    type: 'notInitialized',
    settings: {
      type: 'singleMetric',
      metricType,
    },
  };

  return {
    layout,
    widget,
  };
};

export const makePresetSummaryPanel = (
  props: MakeDefaultModelStateProps,
  panelName: string
): MonitoringPanel => {
  const items = getModelMetricsByType(props.monitoredModelType).map(
    (metricType, index) => makeItem(metricType, props.defaultOutput, index)
  );
  return {
    id: summaryPanelId,
    type: 'custom',
    name: panelName,
    isOpen: true,
    layout: items.map((item) => item.layout),
    widgets: items.map((item) => item.widget),
  };
};
