import { createAction } from 'typesafe-actions';

import { Flags } from 'shared/models/Flags';
import { AppError } from 'shared/models/Error';
import makeThunkApiRequest from 'shared/utils/redux/actions/makeThunkApiRequest';
import { FlagsDataService } from 'services/flags';

import { SERVICE_FLAGS_QUERY } from '../graphql/queries';
import { ServiceFlags } from '../graphql/graphql-types/queries.generated';

const namespace = 'flags';

export const loadFlags = makeThunkApiRequest(
  namespace,
  'LOAD_FLAGS',
  undefined
)<void, Flags, AppError>(async () => {
  return await FlagsDataService.loadFlags();
});

export const loadServiceFlags = makeThunkApiRequest(
  namespace,
  'LOAD_SERVICE_FLAGS',
  undefined
)<void, { data: ServiceFlags['serviceFlags'] }, AppError>(async ({
  dependencies: { apolloClient },
}) => {
  const { data } = await apolloClient.query<ServiceFlags>({
    query: SERVICE_FLAGS_QUERY,
    fetchPolicy: 'no-cache',
  });

  return { data: data.serviceFlags };
});

export const updateFeatureFlag = createAction(
  `${namespace}/UPDATE_FEATURE_FLAG`
)<Flags>();
