import { gql } from '@apollo/client';

export const KAFKA_CONFIG_FRAGMENT = gql`
  fragment KafkaConfigData on KafkaConfig {
    configId
    updateRequest {
      disabled
      errorTopic
      inputTopic
      outputTopic
      reprocessFromScratch
      reprocessFromIndex
      reprocessFromTimestamp
    }
    message
    status
  }
`;
