import { FC } from 'react';
import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useSelector } from 'react-redux';

import { EndpointEnvironmentStatus } from 'generated/types';
import { getEnvironment } from 'shared/models/Deployment/canary/Endpoint';
import SummaryGrid from 'shared/view/elements/SummaryViewComponents/SummaryGrid/DefaultSummaryGrid';
import EndpointLabelsManager from 'features/deployment/canary/endpointLabelsManager/view/EndpointLabelsManager/EndpointLabelsManager';
import {
  IAutoscaling,
  IResources,
} from 'shared/graphql/Deployment/Endpoint/MachineConfig/MachineConfigInfo';
import {
  cpuInfo,
  memoryInfo,
} from 'shared/models/Deployment/canary/EndpointMachineConfiguration/Resources';
import CardLayout from 'shared/view/elements/CardLayout/CardLayout';
import isNotNil, { getFirstNonNullWithDefault } from 'shared/utils/isNotNill';
import { selectFlags } from 'features/flags';
import { getSuccess } from 'shared/utils/result';

import EndpointPlayground from './EndpointPlayground/EndpointPlayground';
import { BuildsInfo } from './BuildsInfo';
import { Endpoint } from '../../store/endpointOverview/endpointOverview';

interface Props {
  endpoint: Endpoint;
  isKafkaEnabled: boolean;
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
  },
}));

const EndpointOverview: FC<React.PropsWithChildren<Props>> = ({
  endpoint,
  isKafkaEnabled,
}) => {
  const environment = getEnvironment(endpoint);
  return (
    <Stack direction="column" spacing={3}>
      <EndpointOverviewInfo endpoint={endpoint} />
      {environment && environment.components.length > 0 ? (
        <>
          <Stack direction="row" spacing={2}>
            <Grid item xs>
              <BuildsInfo
                endpoint={endpoint}
                canaryStrategy={environment.updateRequest?.canaryStrategy}
              />
            </Grid>
            <Grid>
              <AutoscalingAndResourcesInfo
                autoscaling={environment.updateRequest?.autoscaling}
                resources={environment.updateRequest?.resources}
              />
            </Grid>
          </Stack>
          <EndpointPlayground
            tokensEnabled={environment.tokensEnabled}
            isKafkaEnabled={isKafkaEnabled}
            isEndpointEnvironmentActive={
              environment.status === EndpointEnvironmentStatus.ACTIVE
            }
            endpointPath={endpoint.path}
            endpointFullUrl={endpoint.fullUrl}
            environmentTokens={environment.tokens}
          />
        </>
      ) : null}
    </Stack>
  );
};

const EndpointOverviewInfo = ({ endpoint }: { endpoint: Endpoint }) => (
  <>
    <SummaryGrid
      entity={endpoint}
      labelsManager={
        <EndpointLabelsManager
          id={endpoint.id}
          labels={endpoint.labels}
          allowedActions={endpoint.allowedActions}
        />
      }
      type="endpoint"
    />
  </>
);

const AutoscalingAndResourcesInfo: React.FC<
  React.PropsWithChildren<{
    autoscaling?: IAutoscaling | null;
    resources?: IResources | null;
  }>
> = ({ autoscaling, resources }) => {
  const { quantities } = autoscaling || {};
  const {
    deployment: { defaultCpuValue, defaultMemoryValue },
  } = useSelector(selectFlags);
  return (
    <TableContainer component={CardLayout} sx={{ maxHeight: '80px' }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Replicas</StyledTableCell>
            <StyledTableCell>Scaling</StyledTableCell>
            <StyledTableCell>Memory Limit</StyledTableCell>
            <StyledTableCell>CPU Limit</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell scope="row">
              {isNotNil(quantities?.minReplicas) &&
              isNotNil(quantities?.maxReplicas)
                ? `${quantities?.minReplicas ?? ''} to ${
                    quantities?.maxReplicas ?? ''
                  }`
                : 1}
            </TableCell>
            <TableCell>
              {isNotNil(quantities?.minScale) && isNotNil(quantities?.maxScale)
                ? `${quantities?.minScale ?? ''} to ${
                    quantities?.maxScale ?? ''
                  }`
                : 1}
            </TableCell>
            <TableCell>
              {memoryInfo.format(
                getFirstNonNullWithDefault('256Mi', [
                  resources?.memory,
                  defaultMemoryValue,
                ])
              )}
            </TableCell>
            <TableCell>
              {`${getFirstNonNullWithDefault(2, [
                getSuccess(cpuInfo.format(resources?.cpuMillis)),
                getSuccess(cpuInfo.format(defaultCpuValue)),
              ])} cores`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EndpointOverview;
