import { localPoint } from '@visx/event';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useTooltip } from '@visx/tooltip';
import { useCallback } from 'react';

import ParallelCoordinateChartTooltip from './ParallelCoordinateChartTooltip/ParallelCoordinateChartTooltip';
import { FormatNumber, ParallelCoordinateChartData } from './types';

export const useParallelCoordinateChartTooltip = (
  formatNumber: FormatNumber
) => {
  const { showTooltip, hideTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useTooltip<ParallelCoordinateChartData>();

  const tooltipView = tooltipData ? (
    <ParallelCoordinateChartTooltip
      tooltipData={tooltipData}
      tooltipLeft={tooltipLeft}
      tooltipTop={tooltipTop}
      formatNumber={formatNumber}
    />
  ) : null;

  const onMouseOver = useCallback(
    (e: React.MouseEvent, d: ParallelCoordinateChartData) => {
      const { x, y } = localPoint(e) || { x: 0, y: 0 };
      showTooltip({
        tooltipData: d,
        tooltipLeft: x,
        tooltipTop: y,
      });
    },
    [showTooltip]
  );

  const onMouseOut = hideTooltip;

  return {
    tooltipView,
    onMouseOver,
    onMouseOut,
    tooltipData,
  };
};
