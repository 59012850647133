import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Button } from 'shared/view/elements/Button';
import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';
import CustomAttrCardSVG from 'features/homepage/images/custom-attributes.svg?react';
import routes from 'shared/routes';
import { useAdministratedOrganization } from 'features/shared/useAdministratedOrganization';

import { InformationalTile } from '../../../shared/InformationalTile';

export const CreateCustomAttribute = (props: WizardStepContentProps) => {
  const administratedOrganizationApi = useAdministratedOrganization();
  const organizationName = administratedOrganizationApi.data?.name || undefined;
  const navigate = useNavigate();

  return (
    <WizardStepContent {...props}>
      <Typography variant="subtitle1" mb={2}>
        Now it's your turn
      </Typography>
      <Typography variant="body2" mb={3}>
        Ready to see it in action? Give it a try!
      </Typography>
      <InformationalTile
        title="Create a custom model or version attribute"
        description="Create an attribute for anything you want to track on the
          organization admin panel. If you're a user feel free to ask our
          customer support to assist you with this task."
        svgComponent={<CustomAttrCardSVG />}
      >
        {organizationName !== undefined ? (
          <Button
            onClick={() =>
              navigate(
                routes.organizationCustomAttributes.getRedirectPath({
                  name: organizationName,
                })
              )
            }
            isLoading={false}
            variant="outlined"
            size="small"
          >
            Create custom attribute
          </Button>
        ) : null}
      </InformationalTile>
    </WizardStepContent>
  );
};
