import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import {
  DeleteRegisteredModelVersion,
  DeleteRegisteredModelVersionVariables,
} from './graphql-types/useDeleteRegisteredModelVersion.generated';

const MUTATION = gql`
  mutation DeleteRegisteredModelVersion($id: ID!) {
    registeredModelVersion(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        delete
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useDeleteRegisteredModelVersion = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [deleteEntity, deletingEntity] = useCustomMutation<
    DeleteRegisteredModelVersion,
    DeleteRegisteredModelVersionVariables
  >(MUTATION, { context: 'deleting registered model' }, { onCompleted });

  return { deleteEntity, deletingEntity };
};
