import { IGitCodeBlob } from 'shared/models/Versioning/Blob/CodeBlob';
import * as GitUrl from 'shared/utils/gitUrl/gitUrl';
import matchBy from 'shared/utils/matchBy';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';

interface ILocalProps {
  remoteRepoUrl: IGitCodeBlob['data']['remoteRepoUrl'];
}

const RepoProp = ({ remoteRepoUrl }: ILocalProps) => {
  return matchBy(
    remoteRepoUrl,
    'type'
  )({
    git: ({ value }) => (
      <InlineLink to={GitUrl.makeRepoUrl(value)} isExternal>
        {GitUrl.makeRepoShortName(value)}
      </InlineLink>
    ),
    unknown: ({ value }) => <span>{value}</span>,
  });
};

export default RepoProp;
