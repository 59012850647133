import * as React from 'react';

import routes from 'shared/routes';
import { AnonymousLayout } from 'shared/routes/layouts/AnonymousLayout';

import LoginProblemPage from './LoginProblemPage/LoginProblemPage';
import {
  SignUpPage,
  SignUpSelection,
  SignUpMailForm,
  SignUpPersonalInfoForm,
} from './SignUpPage';
import SignUpProblemPage from './SignUpProblemPage';
import { UnverifiedEmailPage } from './UnverifiedEmailPage';
import { LoginPage } from './LoginPage';

export const anonymousPages = [
  {
    path: '/',
    element: <AnonymousLayout />,
    children: [
      {
        path: routes.login.getPath(),
        element: <LoginPage />,
      },
      {
        path: routes.loginProblem.getPath(),
        element: <LoginProblemPage />,
      },
      {
        path: routes.signUp.getPath(),
        element: <SignUpPage />,
        children: [
          {
            path: '',
            index: true,
            element: <SignUpSelection />,
          },
          {
            path: routes.signUpMailForm.getPath(),
            element: <SignUpMailForm />,
          },
          {
            path: routes.signUpPersonalInfoForm.getPath(),
            element: <SignUpPersonalInfoForm />,
          },
        ],
      },
      {
        path: routes.signUpProblem.getPath(),
        element: <SignUpProblemPage />,
      },
      {
        path: routes.signUpUnverifiedEmail.getPath(),
        element: <UnverifiedEmailPage />,
      },
    ],
  },
];
