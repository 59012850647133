import {
  convertGraphqlExperimentRun,
  ExperimentRunDetails,
} from 'shared/graphql/ExperimentRunDetails/ExperimentRunDetails';
import { DataWithPagination, IPagination } from 'shared/models/Pagination';
import { DatasetVersionsQuery } from 'generated/types';
import { ISorting } from 'shared/models/Sorting';
import { convertSortingToGraphql } from 'shared/graphql/sorting/converters';
import { ExperimentRunData } from 'shared/graphql/ExperimentRunDetails/graphql-types/ExperimentRunDetails.generated';

export const convertExperimentRunsCommonVariables = (props: {
  convertedFilters: Pick<
    DatasetVersionsQuery,
    'floatPredicates' | 'stringPredicates'
  >;
  ids?: string[];
  pagination: IPagination | undefined;
  sorting: ISorting | null | undefined;
}) => {
  const { convertedFilters, sorting, pagination } = props;
  return {
    ...convertedFilters,
    ids: props.ids ? props.ids : null,
    sorting: sorting ? convertSortingToGraphql(sorting) : null,
    pagination: pagination
      ? {
          page: pagination.currentPage + 1,
          limit: pagination.pageSize,
        }
      : null,
  };
};

export const convertExperimentRunsByNullableRunsEntity = (
  entity:
    | {
        runs: {
          pagination: { totalRecords: number };
          runs: ExperimentRunData[];
        };
      }
    | null
    | undefined
): DataWithPagination<ExperimentRunDetails> => {
  if (!entity?.runs) {
    return {
      totalCount: 0,
      data: [],
    };
  }

  return {
    totalCount: entity.runs.pagination.totalRecords,
    data: entity.runs.runs.map((run) => convertGraphqlExperimentRun(run)),
  };
};
