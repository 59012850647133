import { MonitoringDataType } from 'generated/types';
import matchType from 'shared/utils/matchType';

import { MonitoringFilter } from './MonitoringFilter';

type MonitoringFilterType = MonitoringFilter['type'];

export const getMonitoringFilterTypeByDataType = (
  dataType: MonitoringDataType
): MonitoringFilterType => {
  return matchType(
    {
      BOOLEAN: () => 'boolean',
      DOUBLE: () => 'number',
      LONG: () => 'number',
      STRING: () => 'string',
    },
    dataType
  );
};
