import { clamp } from 'ramda';
import * as React from 'react';

import mainMuiTheme from 'shared/mui/themes/MainMuiTheme';
import { ChartMargin } from 'shared/utils/charts/chartSizes';
import { isRightMouseButton } from 'shared/utils/react/events';

import {
  arrangePoints,
  calculateVectorLength,
  BasePoint,
} from '../../../MultiLineChart/utils/point';
import { Bounds, Scale } from '../types';
import { getBounds } from './utils';

interface ILocalProps<XInput, YInput> {
  width: number;
  height: number;
  margin: ChartMargin;
  xScale: Scale<XInput>;
  yScale: Scale<YInput>;
  onZoomIn(bounds: Bounds<XInput, YInput>): void;
}

type State =
  | {
      type: 'noSelection';
    }
  | {
      type: 'startSelection';
      startPoint: BasePoint;
    }
  | {
      type: 'selection';
      startPoint: BasePoint;
      endPoint: BasePoint;
    };

const additionalMouseTargetPadding = 30;
const mouseTargetOffset = -additionalMouseTargetPadding / 2;
const dragSensitivityDistance = 25;

const CustomBrush = <XInput, YInput>(props: ILocalProps<XInput, YInput>) => {
  const [selectionState, setSelectionState] = React.useState<State>({
    type: 'noSelection',
  });

  const onMouseDown = (e: React.MouseEvent<SVGRectElement, MouseEvent>) => {
    if (isRightMouseButton(e)) {
      return;
    }
    setSelectionState({
      type: 'startSelection',
      startPoint: getMousePositionInsideMouseTargetArea(props, props.margin, e),
    });
  };

  const onMouseMove = (e: React.MouseEvent<SVGRectElement, MouseEvent>) => {
    const point = getMousePositionInsideMouseTargetArea(props, props.margin, e);
    if (
      (selectionState.type === 'startSelection' &&
        calculateVectorLength(selectionState.startPoint, point) >=
          dragSensitivityDistance) ||
      selectionState.type === 'selection'
    ) {
      setSelectionState({
        type: 'selection',
        startPoint: selectionState.startPoint,
        endPoint: point,
      });
    }
  };

  React.useEffect(() => {
    const onMouseUp = () => {
      if (selectionState.type === 'selection') {
        props.onZoomIn(getBounds(props, selectionState));
      }
      setSelectionState({ type: 'noSelection' });
    };

    window.addEventListener('mouseup', onMouseUp);
    return () => {
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [selectionState, props.onZoomIn, props.xScale, props.yScale, props]);

  return (
    <g className="custom-brush">
      <rect
        className="custom-brush-mouse-target"
        opacity={0}
        x={mouseTargetOffset}
        y={mouseTargetOffset}
        width={props.width + additionalMouseTargetPadding}
        height={props.height + additionalMouseTargetPadding}
        style={{ cursor: 'crosshair' }}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
      />
      {selectionState.type === 'selection' && (
        <rect
          className="custom-brush-selection"
          opacity={0.4}
          fill={mainMuiTheme.palette.primary.main}
          pointerEvents="none"
          {...getSelectionRectCoordAndSize(selectionState)}
        />
      )}
    </g>
  );
};

const getSelectionRectCoordAndSize = ({
  startPoint,
  endPoint,
}: {
  startPoint: BasePoint;
  endPoint: BasePoint;
}) => {
  const [closestPoint] = arrangePoints(startPoint, endPoint);
  return {
    x: closestPoint.x,
    y: closestPoint.y,
    width: Math.abs(startPoint.x - endPoint.x),
    height: Math.abs(startPoint.y - endPoint.y),
  };
};

const getMousePositionInsideMouseTargetArea = (
  size: { width: number; height: number },
  margin: {
    left: number;
    top: number;
  },
  e: React.MouseEvent<SVGRectElement, MouseEvent>
) => {
  return {
    x: clamp(0, size.width, e.nativeEvent.offsetX - margin.left),
    y: clamp(0, size.height, e.nativeEvent.offsetY - margin.top),
  };
};

export default CustomBrush;
