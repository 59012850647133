/* eslint-disable @typescript-eslint/ban-types */
import { generatePath, matchPath } from 'react-router-dom';
import { fromPairs, mapObjIndexed } from 'ramda';
import qs from 'query-string';

import { RemoveByValue } from 'shared/utils/types';
import { toKebabCase } from 'shared/utils/collection';

type RouteSettings<
  Name extends string,
  Tag extends string | undefined,
  _Params,
  _QueryParams,
  Children extends Array<DefaultRouteSettings>,
> = {
  path: string;
  name: Name;
  /** children */
  $: Children;
  tag: Tag | undefined;
};

type DefaultRouteSettings = RouteSettings<
  string,
  string | undefined,
  unknown,
  unknown,
  Array<DefaultRouteSettings>
>;

export type Route<
  Name extends string,
  Tag extends string | undefined,
  Params,
  QueryParams,
  Children extends Record<string, Route<any, any, any, any, any>>,
> = {
  getPath: () => string;
  name: Name;
  getRedirectPath: (params: Params) => string;
  getRedirectPathWithQueryParams: (opts: {
    params: Params;
    queryParams: Partial<QueryParams>;
  }) => string;
  getMatch: (pathname: string) => Params | null;
  parseQueryParams: (search: string) => Partial<QueryParams> | undefined;
  /** children */
  $: Children;
  tag: Tag;
};

export const queryParams = <QueryParams>(): QueryParams => ({}) as QueryParams;

const baseRoute =
  <ParamType extends string | string[] | undefined = never>(
    getPath: (name: string) => string
  ) =>
  <
    Name extends string,
    QueryParams,
    Tag extends string | undefined = undefined,
  >(
    name: Name,
    opts:
      | {
          tag: Tag;
          queryParams?: QueryParams;
        }
      | undefined
  ) => {
    type Params = RemoveByValue<{ [K in Name]: ParamType }, never>;
    return <Children extends Array<DefaultRouteSettings> = []>(
      ...children: Children
    ): RouteSettings<Name, Tag, Params, QueryParams, Children> => ({
      tag: opts?.tag,
      name: name,
      path: getPath(name),
      $: children,
    });
  };

type RoutesFromSettingsHelper<R, AccumParams> = R extends RouteSettings<
  infer Name,
  infer Tag,
  infer Params,
  infer QueryParams,
  infer Children
>
  ? Route<
      Name,
      Tag,
      AccumParams & Params,
      QueryParams,
      {
        [K in Children[number] as K['name']]: RoutesFromSettingsHelper<
          K,
          AccumParams & Params
        >;
      }
    >
  : R;

export const build = <R extends DefaultRouteSettings>(
  rootRouteSettings: R
): RoutesFromSettingsHelper<R, {}> => {
  const route: Route<
    string,
    string | undefined,
    Record<string, string>,
    Record<string, string>,
    Record<string, any>
  > = {
    tag: rootRouteSettings.tag,
    name: rootRouteSettings.name,
    $: mapObjIndexed(
      (routeSettings) =>
        build({
          ...routeSettings,
          path: `${rootRouteSettings.path}/${routeSettings.path}`.replace(
            /\/+/g,
            '/'
          ),
        }),
      fromPairs(rootRouteSettings.$.map((r) => [r.name, r]))
    ),
    parseQueryParams: (search: string) =>
      qs.parseUrl(search).query as Record<string, string> | undefined,
    getPath: () => rootRouteSettings.path,
    getMatch: (location: string) =>
      (matchPath({ path: `${rootRouteSettings.path}/*` }, location)?.params as
        | Record<string, string>
        | undefined) || null,
    getRedirectPath: (params) => {
      const encodedParams: Record<string, string> = {};

      for (let i in params) {
        encodedParams[i] = encodeURIComponent(params[i]);
      }

      return generatePath(rootRouteSettings.path, encodedParams);
    },
    getRedirectPathWithQueryParams({ params, queryParams: _queryParams }) {
      const queryParamsInPath = Object.entries(_queryParams)
        .map(([key, value]) => `${key}=${String(value)}`)
        .join('&');
      return `${this.getRedirectPath(params)}?${queryParamsInPath}`;
    },
  };
  return route as unknown as RoutesFromSettingsHelper<R, {}>;
};

export const route = baseRoute((name) => toKebabCase(name || '/'));

const baseParam =
  <BaseType extends string | string[] | undefined>(
    getPath: (name: string) => string
  ) =>
  <Type extends BaseType = BaseType>() =>
    baseRoute<Type>(getPath);

export const param = baseParam<string>((name) => `:${name}`);
// ts-unused-exports:disable-next-line
export const optionalParam = baseParam<string | undefined>(
  (name) => `:${name}?`
);
export const zeroOrMoreSegmentsParam = baseParam<string[]>(
  (name) => `:${name}/*`
);
