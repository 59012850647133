import { ActionType, createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';

import { SearchEntities } from 'shared/models/HighLevelSearch/SearchEntities';
import { SearchEntitiesResults } from 'shared/models/HighLevelSearch/SearchEntitiesResults';
import {
  initialCommunication,
  requestingCommunication,
  successfulCommunication,
} from 'shared/utils/redux/communication';
import { mapObj } from 'shared/utils/collection';

import * as actions from '../actions';
import { IHighLevelSearchState } from '../types';

const initial: IHighLevelSearchState['data'] = {
  searchEntitiesResults: mapObj(
    () => ({
      communication: initialCommunication,
      data: { totalCount: 0, data: undefined },
    }),
    SearchEntities
  ),
};

const entitiesResultsReducer = createReducer<
  IHighLevelSearchState['data']['searchEntitiesResults'],
  ActionType<typeof actions>
>(initial.searchEntitiesResults)
  .handleAction(actions.loadEntitiesRequest, (state, action) => {
    if (action.payload.loadType === 'activeEntitiesAndUpdateOthers') {
      return mapObj(
        (res) => ({ ...res, communication: requestingCommunication }),
        state
      ) as SearchEntitiesResults;
    } else {
      return {
        ...state,
        [action.payload.type]: {
          communication: requestingCommunication,
          data: {},
        },
      };
    }
  })
  .handleAction(actions.loadEntitiesByTypeActions.success, (state, action) => {
    return {
      ...state,
      [action.payload.type]: {
        communication: successfulCommunication,
        data: action.payload.data,
      },
    };
  });

export default combineReducers<IHighLevelSearchState['data']>({
  searchEntitiesResults: entitiesResultsReducer,
});
