import { useState, RefObject } from 'react';
import { head } from 'ramda';

import { useDebounced } from 'shared/utils/debounce';
import { useFirstRenderEffect } from 'shared/view/hooks/useFirstRenderEffect';

export const useElementMeasure = <Element extends HTMLElement>(
  elementRef: RefObject<Element>
): DOMRectReadOnly | undefined => {
  const [measure, changeMeasure] = useState<DOMRectReadOnly | undefined>();

  const debouncedChangeMeasure = useDebounced(changeMeasure, 1000 / 60);

  useFirstRenderEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const elem = head(entries);

      if (elem) {
        debouncedChangeMeasure(elem.contentRect);
      }
    });

    if (elementRef.current) observer.observe(elementRef.current);

    return observer.disconnect;
  });

  return measure;
};
