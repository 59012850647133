import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from 'shared/view/elements/Avatar/Avatar';
import routes from 'shared/routes';
import { openErrorReporterModal } from 'features/errorReporter/store/actions';
import { selectIsCurrentUserSystemAdmin } from 'features/systemAdmin/isAdmin/store';
import Menu from 'shared/view/elements/Menu/Menu';
import {
  ButtonMenuItem,
  LinkMenuItem,
} from 'shared/view/elements/Menu/MenuItem';
import { selectCurrentUserOrThrowError } from 'features/user/store/selectors';
import { logoutUser } from 'features/user/store/actions';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { useAdministratedOrganization } from 'features/shared/useAdministratedOrganization';

type Props = {
  setFlagModalOpen: (flagModalOpen: boolean) => void;
};

const UserBar = memo(({ setFlagModalOpen }: Props) => {
  const isCurrentUserSystemAdmin = useSelector(selectIsCurrentUserSystemAdmin);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUserOrThrowError);
  const administratedOrganizationApi = useAdministratedOrganization();

  const isEnableFlags =
    user.email.endsWith('@verta.ai') || user.email.endsWith('@novatics.com.br');

  return (
    <Menu
      renderAnchor={(onClick) => (
        <div id="user-bar" style={{ cursor: 'pointer' }} onClick={onClick}>
          <Avatar name={user.username} picture={user.picture} />
        </div>
      )}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <ButtonMenuItem
        id="logout-button"
        onClick={() => dispatch(logoutUser({}))}
      >
        Log out
      </ButtonMenuItem>
      <ButtonMenuItem
        onClick={() => dispatch(openErrorReporterModal(undefined))}
      >
        Report an error
      </ButtonMenuItem>
      {isCurrentUserSystemAdmin ? (
        <div>
          <LinkMenuItem to={routes.systemAdmin.$.admins.getRedirectPath({})}>
            System admin
          </LinkMenuItem>
        </div>
      ) : null}
      {administratedOrganizationApi.data ? (
        <LinkMenuItem
          to={routes.organizationUsers.getRedirectPath({
            name: administratedOrganizationApi.data.name,
          })}
        >
          Organization
        </LinkMenuItem>
      ) : null}
      {isEnableFlags && (
        <ButtonMenuItem
          id="feature-flags-button"
          onClick={() => setFlagModalOpen(true)}
        >
          Flags
        </ButtonMenuItem>
      )}
      <LinkMenuItem icon={ICONS.user} to={routes.profile.getRedirectPath({})}>
        {user.fullName || user.username}
      </LinkMenuItem>
    </Menu>
  );
});

export default UserBar;
