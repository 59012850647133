import { gql } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

const ENABLE_AUTHZ_PREDICTIONS = gql`
  mutation EnableAuthZPredictions($endpointId: ID!, $enable: Boolean!) {
    endpoint(id: $endpointId) {
      ... on Error {
        ...ErrorData
      }
      ... on Endpoint {
        id
        environments {
          id
          enableAuthzPrediction(enable: $enable) {
            id
            authzPredictionEnabled
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useEnableAuthZPredictions = () => {
  const [mutate, enablingAuthZPredictions] = useCustomMutation(
    ENABLE_AUTHZ_PREDICTIONS,
    {
      context: 'enabling authz predictions',
    }
  );

  const enableAuthZPredictions = (props: {
    enable: boolean;
    endpointId: string;
  }) => {
    mutate({
      ...props,
    });
  };

  return {
    enableAuthZPredictions,
    enablingAuthZPredictions,
  };
};
