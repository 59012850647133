/* eslint-disable rulesdir/no-deprecated-fields */
// This page is only for testing and it is not going to be used in production
import { gql } from '@apollo/client';
import { useState } from 'react';

import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import Button from 'shared/view/elements/Button/Button';
import InlineCommunicationError from 'shared/view/elements/Errors/InlineCommunicationError/InlineCommunicationError';
import { useToastCommunicationErrorWatcher } from 'shared/view/elements/Notification/Notification';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { useLazyRestQuery } from 'shared/utils/react/useRequest';
import CommentsService from 'services/comments/CommentsService';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import {
  isNotNullableRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import noop from 'shared/utils/noop';
import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';

import * as LoadProjectsForErrorReporter from './graphql-types/ErrorReporterPlaygroundPage.generated';
import AuthorizedLayout from './shared/AuthorizedLayout/AuthorizedLayout';

const LoadProjectWithErrorQuery = gql`
  query loadProjectErrorReporter {
    project(id: "asd") {
      ... on Error {
        ...ErrorData
      }
      ... on Project {
        id
        name
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

const LoadProjectsQuery = gql`
  query loadProjectsForErrorReporter($workspaceName: String!) {
    workspace(name: $workspaceName) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        projects(query: { pagination: { page: 0, limit: 10 } }) {
          projects {
            id
            name
            dateCreated
            dateUpdated
            tags
            description
            attributes {
              ...AttributeData
            }
            owner {
              ...OwnerData
            }
            workspace {
              id
              ...WorkspaceData
            }
          }
          pagination {
            totalRecords
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
`;

const useLoadProject = () => {
  const [load, res] = useCustomLazyQuery(LoadProjectWithErrorQuery);
  return {
    load,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    res: resultToCommunicationWithData((x) => x, res),
  };
};

const ErrorReporterPlaygroundPage = () => {
  const breadcrumbs = useAppBreadcrumbs(
    useBreadcrumbItem({
      name: 'Error reporter playground',
      route: routes.errorReporter,
    })
  );

  const marginTop = '20px';
  const border = '1px solid black';

  return (
    <AuthorizedLayout pageEntityOrError={undefined} breadcrumbs={breadcrumbs}>
      <div style={{ marginTop, border }}>
        <ProjectsLoaded />
      </div>
      <div style={{ marginTop, border }}>
        <GraphqlToastError />
      </div>
      <div style={{ marginTop, border }}>
        <GraphqlInlineError />
      </div>
      <div style={{ marginTop, border }}>
        <RestApiToastError />
      </div>
      <div style={{ marginTop }}>
        <ErrorReportDecoder />
      </div>
    </AuthorizedLayout>
  );
};

const RestApiToastError = () => {
  const { communication, fetch } = useLazyRestQuery(() =>
    CommentsService.loadComments('bla')
  );
  useToastCommunicationErrorWatcher(communication, {
    context: 'loading comments (rest-api)',
  });

  return (
    <Button isLoading={communication.isRequesting} onClick={() => fetch()}>
      Rest api toast error
    </Button>
  );
};

const ProjectsLoaded = () => {
  const workspaceName = useWorkspaceNameFromURL();
  const [loadProjects, res] = useCustomLazyQuery<
    LoadProjectsForErrorReporter.loadProjectsForErrorReporter,
    LoadProjectsForErrorReporter.loadProjectsForErrorReporterVariables
  >(LoadProjectsQuery, {
    variables: { workspaceName },
  });
  const loadingProjectsWithData = resultToCommunicationWithData((x) => x, res);

  return (
    <div>
      <Button
        isLoading={loadingProjectsWithData.communication.isRequesting}
        onClick={() => loadProjects()}
      >
        loading projects by Graphql
      </Button>
      {loadingProjectsWithData.communication.error && (
        <div>Error: {loadingProjectsWithData.communication.error.message}</div>
      )}
      {loadingProjectsWithData.data &&
        loadingProjectsWithData.communication.isSuccess && (
          <span>
            Projects are loaded:{' '}
            {isNotNullableRestrictedGraphqlError(
              loadingProjectsWithData.data.workspace
            ) &&
              loadingProjectsWithData.data.workspace.projects.projects
                .map((pr) => pr.name)
                .join(', ')}
          </span>
        )}
    </div>
  );
};

const ErrorReportDecoder = () => {
  const [encodedErrorReport, setEncodedErrorReport] = useState('');
  return (
    <div>
      <TextInput
        label={'Copied Error Report'}
        multiline={true}
        value={encodedErrorReport}
        onChange={setEncodedErrorReport}
      />
      <TextInput
        label={'Decoded Error Report'}
        multiline={true}
        onChange={noop}
        value={(() => {
          if (encodedErrorReport) {
            const report =
              /---BEGIN ERROR REPORT---\s*((\s|.)+)\s*---END ERROR REPORT---/g.exec(
                encodedErrorReport
              )?.[1];
            if (!report) {
              return `section with error report is not found\nexample:\n---BEGIN ERROR REPORT---\nadfadf\n---END ERROR REPORT---`;
            }
            try {
              return JSON.stringify(JSON.parse(atob(report)), null, 4);
              // eslint-disable-next-line @typescript-eslint/no-implicit-any-catch
            } catch (e: any) {
              return e.message as string;
            }
          }
        })()}
      />
    </div>
  );
};

const GraphqlToastError = () => {
  const { load, res } = useLoadProject();
  useToastCommunicationErrorWatcher(res.communication, {
    context: 'loading project (toast)',
  });

  return (
    <Button isLoading={res.communication.isRequesting} onClick={() => load()}>
      Graphql toast error
    </Button>
  );
};

const GraphqlInlineError = () => {
  const { load, res } = useLoadProject();

  return (
    <div>
      <Button isLoading={res.communication.isRequesting} onClick={() => load()}>
        Graphql inline error
      </Button>
      {res.communication.error && (
        <InlineCommunicationError
          error={res.communication.error}
          context="loading project (inline)"
        />
      )}
    </div>
  );
};

const errorReporterPlaygroundPage: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.errorReporter.getPath(),
      element: <ErrorReporterPlaygroundPage />,
    },
  ],
};

export default errorReporterPlaygroundPage;
