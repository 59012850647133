import { ComponentProps } from 'react';
import { useSelector } from 'react-redux';

import { OmitStrict } from 'shared/utils/types';
import { suggestDesktopUsage } from 'shared/utils/suggestDesktopUsage';
import { Zendesk } from 'setup/app/ZendexConfig';
import Layout from 'features/layout/view/Layout/Layout';
import { useNavRouteItems } from 'pages/authorized/shared/hooks/useNavRouteItems';
import { selectFlags } from 'features/flags';
import routes from 'shared/routes';
import GlobalPreloader from 'shared/view/elements/GlobalPreloader/GlobalPreloader';

type Props = OmitStrict<ComponentProps<typeof Layout>, 'navRouteItems'>;

export default function AuthorizedLayout(props: Props) {
  const {
    deployment: { isEnable: isEnableDeployment },
  } = useSelector(selectFlags);
  const navRouteItems = useNavRouteItems();
  suggestDesktopUsage();

  if (!isEnableDeployment) {
    window.location.replace(
      `${window.location.origin}${routes.llmProjectsList.getPath()}`
    );

    return <GlobalPreloader />;
  }

  return (
    <>
      <Zendesk />
      <Layout {...props} navRouteItems={navRouteItems} />
    </>
  );
}
