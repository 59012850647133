import { gql } from '@apollo/client';

export const ORGANIZATION_STATS_FRAGMENT = gql`
  fragment OrganizationStatsData on OrganizationStats {
    orgId
    numUsers
    numEndpoints
    numRegisteredModels
  }
`;
