import { scaleOrdinal } from '@visx/scale';
import { useMemo } from 'react';

import { SingleMetric as SingleMetricType } from 'features/monitoring/widgets/store/singleMetric/graphql-types/useSingleMetric.generated';
import { useSingleMetric } from 'features/monitoring/widgets/store/singleMetric/useSingleMetric';
import { MonitoringMetricType } from 'generated/types';
import { isNotRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import {
  predictionCountOutput,
  SingleMetricCommonWidget,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/SingleMetricWidget';
import { useActiveKeys } from 'shared/utils/activeKeys';
import { useChartLegendProps } from 'shared/utils/charts/chartLegendProps/useChartLegendProps';
import { Size } from 'shared/utils/charts/chartSizes';
import { formatWithDefaultPrecision } from 'shared/utils/formatters/formatWithDefaultPrecision';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';
import { head, isNonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';
import BarChart from 'shared/view/charts/BarChart/BarChart';
import { BarChartData } from 'shared/view/charts/BarChart/utils/model';
import ChartWithLegendContainer from 'shared/view/charts/ChartWithLegendContainer/ChartWithLegendContainer';
import { chartsDataColors } from 'shared/view/charts/shared/colors';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { ExtractByTypename } from 'shared/utils/types';

import { MonitoringWidgetProps } from '../../shared/types';
import SingleMetric from '../shared/SingleMetric/SingleMetric';

type MonitoredEntity = ExtractByTypename<
  SingleMetricType['monitoredEntity'],
  'MonitoredEntity'
>;

const SingleMetricCommonWidgetView = (
  props: MonitoringWidgetProps<SingleMetricCommonWidget>
) => {
  const { communication, data } = useSingleMetric({
    widgetExternalDeps: props.widgetExternalDeps,
    metricType: props.widget.variant.metricType,
    output:
      props.widget.variant.metricType !== MonitoringMetricType.PREDICTION_COUNT
        ? props.widget.variant.output
        : predictionCountOutput,
  });

  return (
    <DefaultMatchRemoteDataOrError
      data={data}
      communication={communication}
      context="loading single metric"
    >
      {(loadedData) => {
        if (loadedData.length === 1 && isNonEmptyArray(loadedData)) {
          const metric = head(loadedData);
          return (
            <SingleMetric
              color="green"
              size={props.size}
              value={Number(
                formatWithDefaultPrecision(parseGraphQLNumber(metric.value))
              )}
            />
          );
        }
        return (
          <SingleMetricChart
            id={props.id}
            data={loadedData}
            size={props.size}
          />
        );
      }}
    </DefaultMatchRemoteDataOrError>
  );
};

const SingleMetricChart = (props: {
  id: string;
  data: MonitoredEntity['metrics']['metric'];
  size: Size;
}) => {
  const data = useMemo(() => convertData(props.data), [props.data]);
  const keys = useMemo(() => data.map((d) => d.key), [data]);

  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: keys,
        range: chartsDataColors,
      }),
    [keys]
  );
  const { activeKeys, toggleKey } = useActiveKeys(keys);
  const legendProps = useChartLegendProps({
    activeKeys,
    toggleKey,
    legendPosition: 'right',
    colorScale,
  });
  const filteredData = useMemo(
    () => data.filter((d) => activeKeys.includes(d.key)),
    [data, activeKeys]
  );

  return (
    <ChartWithLegendContainer legendProps={legendProps} widgetSize={props.size}>
      {(size) => (
        <BarChart
          chartId={props.id}
          width={size.width}
          height={size.height}
          data={filteredData}
          getColorByData={(d) => colorScale(d.key)}
          renderTooltipContent={(d) => <span>{d.value}</span>}
        />
      )}
    </ChartWithLegendContainer>
  );
};

const convertData = (
  data: MonitoredEntity['metrics']['metric']
): Array<BarChartData<undefined>> => {
  return data.map((metric) => ({
    key: isNotRestrictedGraphqlError(metric.modelVersion)
      ? metric.modelVersion.version
      : metric.modelVersionId,
    value: Number(formatWithDefaultPrecision(parseGraphQLNumber(metric.value))),
    additionalData: undefined,
  }));
};

export default SingleMetricCommonWidgetView;
