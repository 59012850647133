import { gql } from '@apollo/client';
import { useCallback } from 'react';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import {
  TestPypiConfiguration,
  TestPypiConfigurationVariables,
} from 'features/systemAdmin/integrations/pypi/store/graphql-types/useTestPypiConfiguration.generated';
import { useToast } from 'features/toast/store/hooks';

const MUTATION = gql`
  mutation TestPypiConfiguration(
    $name: String!
    $url: String!
    $username: String!
    $password: String!
  ) {
    pypiConfigurations {
      id
      test(name: $name, url: $url, username: $username, password: $password)
    }
  }
`;

export const useTestPypiConfiguration = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const toast = useToast();
  const [mutate, testingPypiConfiguration] = useCustomMutation<
    TestPypiConfiguration,
    TestPypiConfigurationVariables
  >(
    MUTATION,
    { context: 'testing PyPI configuration' },
    {
      onCompleted: (res) => {
        if (res.pypiConfigurations.test) {
          toast('Success', 'success');
          onSuccess();
        } else {
          toast('Failed', 'warning');
        }
      },
    }
  );

  const testPypiConfiguration = useCallback(
    ({ name, password, url, username }: TestPypiConfigurationVariables) => {
      // don't provide just variables otherwise backend can be broken if you'll send additional unexpected props with that one
      mutate({ url, name, username, password });
    },
    [mutate]
  );

  return {
    testPypiConfiguration,
    testingPypiConfiguration,
  };
};
