import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { ACTIVITY_FRAGMENT } from 'shared/graphql/registry/stage/Activity';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';

export const MODEL_ACTIONS_DATA_QUERY = gql`
  query ModelActionsData($id: ID!) {
    registeredModel(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        allowedActions {
          ...AllowedActionsData
          lock
          unlock
          updateRedact
        }
        activities {
          id
          ...ActivityFragment
          version {
            id
            stage
            version
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;
