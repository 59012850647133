import { Grid, Stack } from '@mui/material';

import { OverflowPopover } from 'shared/view/elements/OverflowPopover/OverflowPopover';

interface Props {
  fields: JSX.Element[];
  maxPerColumn?: number;
  withMargin?: boolean;
  spacing?: number;
}

export const RightPartFields = ({
  fields,
  maxPerColumn = 8,
  spacing = 2,
  withMargin = true,
}: Props) => {
  const maxSize = maxPerColumn * 2;
  const hasManyFields = fields.length > maxSize;
  const firstColumn = fields.slice(0, maxPerColumn);
  const lastColumn = hasManyFields
    ? fields.slice(maxPerColumn, maxSize - 1)
    : fields.slice(maxPerColumn);

  if (hasManyFields) {
    lastColumn.push(
      <OverflowPopover
        overflowItems={fields.slice(maxSize - 1)}
        itemsLabel="hidden attributes"
      />
    );
  }

  return (
    <Grid
      container
      mt={{ lg: withMargin ? '30px' : undefined }}
      rowSpacing={0}
      columnSpacing={2}
    >
      <Grid item xs={6}>
        <Stack spacing={spacing}>{firstColumn}</Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={spacing} sx={{ display: 'table' }}>
          {lastColumn}
        </Stack>
      </Grid>
    </Grid>
  );
};
