import { useEffect, useRef } from 'react';

export const ScrollToElementOnMount = ({
  enabled,
  children,
}: {
  enabled: boolean;
  children: (ref: React.MutableRefObject<unknown>) => JSX.Element;
}) => {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (ref.current && enabled) {
      ref.current.scrollIntoView();
    }
  }, [enabled, ref]);

  return children(ref);
};
