import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { MonitoringMetricType } from 'generated/types';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import isNotNil from 'shared/utils/isNotNill';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { useZoomReset } from 'shared/utils/charts/zoom/useZoomReset';
import { MetricOverTime } from 'features/monitoring/widgets/store/metricOverTime/graphql-types/useMetricOverTime.generated';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import Popup from 'shared/view/elements/Popup/Popup';
import routes from 'shared/routes';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { ExtractByTypename } from 'shared/utils/types';

import { OverviewWidgetWrapper } from '../../overview/view/MonitoringOverview/shared/OverviewWidget/OverviewWidget';
import NewTimeSeries from '../NewTimeSeries/NewTimeSeries';

type MonitoredEntity = ExtractByTypename<
  MetricOverTime['monitoredEntity'],
  'MonitoredEntity'
>;

const TimeSeriesFullScreenAction = (props: {
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  metricOverTime: MonitoredEntity['metrics']['metricOverTime'];
  metricType: MonitoringMetricType;
  metricValue: number;
}) => {
  const { closePopup, isPopupOpen, openPopup } = usePopupManager();

  const { onSetReset, onReset } = useZoomReset();

  return (
    <div>
      <IconAwesomeClickable icon={ICONS.expand} onClick={openPopup} size="lg" />

      <Popup title="" isOpen={isPopupOpen} onClose={closePopup} fullScreen>
        <OverviewWidgetWrapper height="100%" width="100%">
          <Grid width="100%" height="100%" container spacing={4}>
            <Grid xs={3}>
              <Stack>
                <Typography variant="subtitle1">Prediction count</Typography>
                <Typography variant="h3">{props.metricValue}</Typography>

                <InlineLink
                  to={routes.monitoringPerformance.getRedirectPath({
                    monitoringModelId:
                      props.widgetExternalDeps.monitoringModelId,
                    workspaceName: props.widgetExternalDeps.workspaceName,
                  })}
                >
                  Go to performance
                </InlineLink>
              </Stack>
            </Grid>
            <Grid height="100%" xs={9}>
              <NewTimeSeries
                id="fullscreen-timeseries"
                actions={[
                  onReset ? (
                    <IconAwesomeClickable
                      icon={ICONS.magnifyingGlassMinus}
                      size="lg"
                      onClick={onReset}
                    />
                  ) : null,
                ].filter(isNotNil)}
                onSetReset={onSetReset}
                metricOverTime={props.metricOverTime}
                isHovered={true}
                metricType={props.metricType}
              />
            </Grid>
          </Grid>
        </OverviewWidgetWrapper>
      </Popup>
    </div>
  );
};

export default TimeSeriesFullScreenAction;
