import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';

import {
  IOrganizationShortWorkspace,
  IWorkspace,
  IUserWorkspaces,
  getDefaultWorkspace,
} from '.';

export type ICurrentWorkspace = { type: 'user' } | IOrganizationShortWorkspace;
export const workspaceToCurrentShortWorkspace = (
  workspace: IWorkspace
): ICurrentWorkspace => {
  switch (workspace.type) {
    case 'organization':
      return {
        type: 'organization',
        id: workspace.id,
        organizationId: workspace.organizationId,
        namespace: workspace.namespace,
        organizationName: workspace.organizationName,
      };
    case 'user':
      return { type: 'user' };
    default:
      return exhaustiveCheck(workspace);
  }
};

export const getCurrentWorkspace = (
  defaultWorkspaceId: string | null,
  userWorkspaces: IUserWorkspaces,
  shortCurrentWorkspace: ICurrentWorkspace | null
) => {
  if (!shortCurrentWorkspace) {
    return getDefaultWorkspace(defaultWorkspaceId, userWorkspaces);
  }

  const workspace = (() => {
    switch (shortCurrentWorkspace.type) {
      case 'user':
        return userWorkspaces.user;
      case 'organization':
        return userWorkspaces.organizations.find(
          (orgWorkspace) => orgWorkspace.id === shortCurrentWorkspace.id
        );
      default: {
        exhaustiveCheck(shortCurrentWorkspace);
      }
    }
  })();
  return workspace || getDefaultWorkspace(defaultWorkspaceId, userWorkspaces);
};
