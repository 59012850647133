import { gql } from '@apollo/client';
import { useCallback } from 'react';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import { EXPERIMENT_RUN_FRAGMENT } from 'shared/graphql/ExperimentRunDetails/ExperimentRunDetails';
import {
  ExperimentRuns,
  ExperimentRunsVariables,
} from 'features/experimentRuns/list/store/graphql-types/useExperimentRunsList.generated';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { IPagination } from 'shared/models/Pagination';
import { ISorting } from 'shared/models/Sorting';
import {
  convertExperimentRunsByNullableRunsEntity,
  convertExperimentRunsCommonVariables,
} from 'features/experimentRuns/shared/converters';
import { convertFilters } from 'shared/graphql/filters/converters';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { Filter } from 'shared/models/Filters/Filter';

export const EXPERIMENT_RUNS = gql`
  query ExperimentRuns($projectId: ID!, $query: ExperimentRunsQuery) {
    project(id: $projectId) {
      ... on Error {
        ...ErrorData
      }
      ... on Project {
        id
        runs(query: $query) {
          runs {
            id
            ...ExperimentRunData
          }
          pagination {
            totalRecords
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${EXPERIMENT_RUN_FRAGMENT}
`;

export const useExperimentRunsList = (basicProps: { projectId: string }) => {
  const [loadQuery, queryResult] = useCustomLazyQuery<
    ExperimentRuns,
    ExperimentRunsVariables
  >(EXPERIMENT_RUNS);

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult,
  });

  const loadExperimentRuns = useCallback(
    async (props: {
      filters: Filter[];
      pagination: IPagination;
      sorting: ISorting | null;
      ids?: string[];
    }) => {
      return loadQuery({
        variables: {
          projectId: basicProps.projectId,
          query: convertExperimentRunsCommonVariables({
            convertedFilters: convertFilters(props.filters),
            ids: props.ids,
            pagination: props.pagination,
            sorting: props.sorting,
          }),
        },
      });
    },
    [basicProps.projectId, loadQuery]
  );

  return {
    data,
    communication,
    loadExperimentRuns,
  };
};

const convert = (res: ExperimentRuns) =>
  mapDataOrError(res.project, convertExperimentRunsByNullableRunsEntity);
