import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';

interface Props {
  icon: IconDefinition;
  label: string;
  disabled?: boolean;
  dataTest?: string;
  type?: 'header' | 'title';
  labelPlacement?: 'start' | 'top' | 'bottom' | 'end';

  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

export default function IconAwesomeClickableWithLabel(props: Props) {
  const label = useLabel({ label: props.label, type: props.type });

  return (
    <FormControlLabel
      control={
        <IconAwesomeClickable
          icon={props.icon}
          disabled={props.disabled}
          dataTest={props.dataTest}
          size="lg"
          onClick={props.onClick}
        />
      }
      label={label}
      labelPlacement={props.labelPlacement || 'start'}
    />
  );
}

function useLabel({ label, type }: Pick<Props, 'label' | 'type'>) {
  switch (type) {
    case 'header':
      return (
        <Typography variant="h5" fontWeight={500}>
          {label}
        </Typography>
      );
    case 'title':
      return (
        <Typography variant="h6" fontWeight={500}>
          {label}
        </Typography>
      );
    default:
      return label;
  }
}
