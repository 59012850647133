import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';
import { useCallback } from 'react';

import { DraggableAccordionDroppableArea } from 'shared/view/elements/DraggableAccordion/DraggableAccordion';
import { usePanelsDragAndDrop } from 'shared/view/elements/DraggableAccordion/usePanelsDragAndDrop';
import { reorder } from 'shared/utils/collection';

import styles from './DndPanels.module.css';

const DnDPanels = <T extends { id: string }>(props: {
  droppableId: string;
  panels: T[];
  children: (props: {
    dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
    panel: T;
  }) => React.ReactNode;
  onReorderedPanels(panels: T[]): void;
}) => {
  const onReorder = useCallback(
    (_id: string, startIndex: number, endIndex: number) => {
      props.onReorderedPanels(reorder(props.panels, startIndex, endIndex));
    },
    [props]
  );

  const { handleDragStart, handleDragUpdate, handleDragEnd, placeholderProps } =
    usePanelsDragAndDrop({
      onReorder,
    });

  return (
    <DragDropContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragUpdate={handleDragUpdate}
    >
      <Droppable droppableId={props.droppableId}>
        {(droppable, snapshot) => (
          <DraggableAccordionDroppableArea
            droppable={droppable}
            snapshot={snapshot}
            placeholderProps={placeholderProps}
          >
            {props.panels.map((panel, index) => (
              <Draggable key={panel.id} draggableId={panel.id} index={index}>
                {(draggable) => (
                  <div
                    className={styles.panelWrapper}
                    ref={draggable.innerRef}
                    {...draggable.draggableProps}
                  >
                    {props.children({
                      dragHandleProps: draggable.dragHandleProps,
                      panel,
                    })}
                  </div>
                )}
              </Draggable>
            ))}
          </DraggableAccordionDroppableArea>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DnDPanels;
