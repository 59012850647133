/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { DataWithPagination } from 'shared/models/Pagination';
import { EndpointResult } from 'shared/models/HighLevelSearch/SearchResult';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { IHighLevelGraphqlSearchSettings } from 'services/highLevelSearch/shared/types';
import {
  EndpointResults,
  EndpointResultsVariables,
} from 'services/highLevelSearch/endpoints/loadEndpointResults/graphql-types/loadEndpointResults.generated';
import { EndpointQuery } from 'generated/types';
import matchBy from 'shared/utils/matchBy';
import {
  isRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';

const QUERY = gql`
  query EndpointResults(
    $workspaceName: String!
    $organizationId: ID
    $query: EndpointQuery
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoints(query: $query) {
          endpoints {
            id
            dateCreated
            dateUpdated
            labels
            path
            owner {
              ... on User {
                id
                username
              }
              ... on Group {
                id
                name
              }
            }
            workspace {
              id
              ...WorkspaceData
            }
          }
          pagination {
            totalRecords
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
`;

export const loadEndpointResults = async ({
  apolloClient,
  filter,
  workspaceName,
  organizationId,
}: IHighLevelGraphqlSearchSettings): Promise<
  DataWithPagination<EndpointResult>
> => {
  const res = await apolloClient.query<
    EndpointResults,
    EndpointResultsVariables
  >({
    query: QUERY,
    fetchPolicy: 'no-cache',
    variables: {
      workspaceName,
      organizationId,
      query: matchBy(
        filter,
        'type'
      )<EndpointQuery>({
        name: (nameFilter) => {
          return {
            fuzzyNames: [nameFilter.value],
          };
        },
        tag: (tagFilter) => {
          return {
            labels: [tagFilter.value],
          };
        },
      }),
    },
  });
  if (
    isRestrictedGraphqlError(res.data.workspace) ||
    (res.errors || []).length > 0
  ) {
    return { totalCount: 0, data: [] };
  }
  return {
    data: res.data.workspace.endpoints.endpoints.map((endpoint) => ({
      ...endpoint,
      entityType: 'endpoints',
      tags: null,
      labels: endpoint.labels,
      dateCreated: new Date(endpoint.dateCreated),
      dateUpdated: new Date(endpoint.dateUpdated),
      owner: endpoint.owner,
    })),
    totalCount: res.data.workspace.endpoints.pagination.totalRecords,
  };
};
