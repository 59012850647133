import { useEditResourceInformationMenuAction } from 'features/editResourceInformation/view/EditResourceInformation/EditResourceInformation';
import { useDeleteEntityAction } from 'features/shared/DeleteEntityIcon/useDeleteEntityAction';
import { BaseDataset } from 'pages/authorized/DatasetPages/DatasetDetailPages/LoadGraphqlDataset';
import routes from 'shared/routes';
import isNotNil from 'shared/utils/isNotNill';
import { MenuAction } from 'shared/view/elements/ActionsMenu/ActionsMenu';

interface Props {
  workspaceName: string;
  dataset: BaseDataset | null;
}

export const useDatasetSettingsActions = (props: Props) => {
  const actions: MenuAction[] = [
    useEditResourceInformationMenuAction(props.dataset),
    useDeleteEntityAction({
      id: props.dataset?.id ?? null,
      entityType: 'dataset',
      redirectUrl: routes.datasets.getRedirectPath({
        workspaceName: props.workspaceName,
      }),
      isNotAllowedToDelete: !props.dataset?.allowedActions.delete,
    }),
  ].filter(isNotNil);

  return actions;
};
