import { gql } from '@apollo/client';

import {
  DeleteExperimentRun,
  DeleteExperimentRunVariables,
} from 'features/experimentRuns/shared/hooks/graphql-types/useExperimentRunDelete.generated';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

const DELETE_EXPERIMENT_RUN = gql`
  mutation DeleteExperimentRun($id: ID!) {
    run(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on ExperimentRun {
        id
        delete
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export function useExperimentRunDelete({
  onCompleted,
}: {
  onCompleted?(): void;
}) {
  const [deleteExperimentRun, deletingExperimentRun] = useCustomMutation<
    DeleteExperimentRun,
    DeleteExperimentRunVariables
  >(
    DELETE_EXPERIMENT_RUN,
    { context: 'deleting experiment run' },
    { onCompleted }
  );

  return {
    deleteExperimentRun,
    deletingExperimentRun,
  };
}
