import { useState, FC, useEffect, createElement } from 'react';
import { Box, BoxProps, Divider } from '@mui/material';
import { cloneDeep } from 'lodash';

import {
  ControlledStepper,
  ControlledStepProps,
} from 'shared/view/elements/Stepper';

import { ActiveStepChangeProps, WizardStepContentProps } from './';

export type InternalWizardStepProps = ControlledStepProps & {
  group: number;
  isLast: boolean;
  active: boolean;
  completed: boolean;
  disabled: boolean;
  children: FC<WizardStepContentProps>;
};

export type WizardStepProps = {
  stepLabel: ControlledStepProps['stepLabel'];
  slides: FC<WizardStepContentProps>[];
};

type WizardProps = {
  steps: WizardStepProps[];
  onCompleted?: () => void;
  initialStep?: number;
  divider?: boolean;
  slots?: {
    LeftSide: BoxProps;
    RightSide: BoxProps;
  };
};

export const Wizard = (props: WizardProps) => {
  const { steps, slots, divider = true, initialStep = 0, onCompleted } = props;
  const [activeStep, setActiveStep] = useState<number>(initialStep);
  const [internalSteps, setInternalSteps] = useState<InternalWizardStepProps[]>(
    []
  );

  const handleNext = (args?: ActiveStepChangeProps) => {
    if (activeStep === internalSteps.length - 1) {
      onCompleted?.();
      return;
    }
    const _steps = cloneDeep(internalSteps);
    if (args?.jumpTo !== undefined) {
      let jumpToInternalIndex = 0;
      const newSteps = _steps.map((step, index) => {
        if (jumpToInternalIndex === 0 && step.group === args.jumpTo) {
          jumpToInternalIndex = index;
        }
        return {
          ...step,
          completed: step.group < (args.jumpTo as number),
          active: jumpToInternalIndex === index,
        };
      });
      setInternalSteps(newSteps);
      setActiveStep(jumpToInternalIndex);
    } else {
      const currentStep = _steps[activeStep];
      currentStep.completed = args?.forceComplete ?? true;
      currentStep.active = args?.forceActive ?? false;

      const nextStep = _steps[activeStep + 1];

      const newSteps = _steps.map((step, index) => ({
        ...step,
        completed: index < activeStep + 1,
        active: nextStep.group === step.group,
      }));

      setInternalSteps(newSteps);
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = (args?: ActiveStepChangeProps) => {
    if (activeStep <= 0) return;

    const _steps = cloneDeep(internalSteps);
    if (args?.jumpTo !== undefined) {
      setInternalSteps(
        _steps.map((step) => ({
          ...step,
          active: args.jumpTo === step.group,
          completed: step.group < (args.jumpTo as number),
        }))
      );
      setActiveStep(args.jumpTo);
    } else {
      const currentStep = _steps[activeStep];
      currentStep.completed = args?.forceComplete ?? false;
      currentStep.active = args?.forceActive ?? false;

      const prevStep = _steps[activeStep - 1];
      prevStep.completed = false;

      setInternalSteps(
        _steps.map((step) => ({
          ...step,
          active: prevStep.group === step.group,
          completed: step.group < prevStep.group,
        }))
      );
      setActiveStep(activeStep - 1);
    }
  };

  useEffect(() => {
    setInternalSteps(buildInternalSteps(steps, initialStep));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box flexDirection="row" height="100%" display="flex">
      <Box
        {...slots?.LeftSide}
        sx={{
          maxHeight: '100%',
          overflowY: 'auto',
          pr: 3,
          minWidth: 'calc(30% - 26px)', // divider width + margin
          ...slots?.LeftSide.sx,
        }}
      >
        <ControlledStepper activeStep={activeStep} steps={internalSteps} />
      </Box>
      {divider ? (
        <Divider orientation="vertical" flexItem sx={{ mr: 3 }} />
      ) : null}
      <Box
        {...slots?.RightSide}
        sx={{
          maxHeight: '100%',
          height: '100%',
          maxWidth: '70%',
          flex: 1,
          ...slots?.RightSide.sx,
        }}
      >
        <InternalChildren
          {...internalSteps[activeStep]}
          next={handleNext}
          back={handleBack}
          activeStep={activeStep}
        />
      </Box>
    </Box>
  );
};
const InternalChildren = ({ children, ...props }: WizardStepContentProps) => {
  if (typeof children === 'function') {
    return createElement(children, props);
  }
  return null;
};

const buildInternalSteps = (
  steps: WizardStepProps[],
  stepIndex: number
): InternalWizardStepProps[] =>
  steps
    .map((step, index) => {
      const { stepLabel, slides } = step;
      const defaultStep = {
        group: index,
        stepLabel: stepLabel,
        isLast: true,
        active: index === stepIndex,
        completed: false,
        disabled: false,
      };

      if (slides.length === 1) {
        return {
          ...defaultStep,
          children: slides[0],
        };
      }

      return slides.map((slide, slideIndex) => {
        const isLast = slides.length === slideIndex + 1;

        return {
          ...defaultStep,
          stepLabel: isLast ? stepLabel : (false as const),
          isLast,
          children: slide,
        };
      });
    })
    .flat();
