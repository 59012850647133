import { ChartObservation } from './types';

export const getObservationValue = <T extends ChartObservation>(
  observation: T
): T['attribute']['floatValue'] => {
  return observation.attribute.floatValue;
};
export const getObservationTimestamp = <T extends ChartObservation>(
  observation: T
): T['timestamp'] => {
  return observation.timestamp;
};
export const getObservationEpochNumber = <T extends ChartObservation>(
  observation: T
): T['epochNumber'] => {
  return observation.epochNumber;
};

export const getEpochNumbersFromObservations = <T extends ChartObservation>(
  observations: T[]
): number[] => {
  return observations
    .map((observation) => Number(getObservationEpochNumber(observation)))
    .filter((num) => !isNaN(num));
};
