import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Popup from 'shared/view/elements/Popup/Popup';
import RadioButtons from 'shared/view/elements/RadioButtons/RadioButtons';
import { getChangingLockLevelsAccess } from 'shared/models/Registry/RegisteredModelVersion/LockLevel';
import { RegisteredModelVersionLockLevel } from 'generated/types';
import { docsLinks } from 'shared/utils/globalConstants/vertaDocLinks';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { useUpdateEffect } from 'shared/view/hooks/useUpdateEffect';

import { useChangeLockLevel } from '../../store/useChangeLockLevel';
import { ModelVersionSettings } from '../../store/graphql-types/useModelVersionPageSettings.generated';

interface Props {
  isOpen: boolean;
  version: ModelVersionSettings;
  isEditable: boolean;
  onClose(): void;
}

const LockLevelEditor = (props: Props) => {
  const { isOpen, onClose, isEditable, version } = props;
  const [requested, setRequested] = useState(false);
  const { lockLevel, registeredModel, id } = version;
  const [localLockLevel, setLocalLockLevel] =
    useState<RegisteredModelVersionLockLevel>(lockLevel);
  const [changeLockLevel, communication] = useChangeLockLevel();

  const changingLockLevelAccess = getChangingLockLevelsAccess(
    lockLevel,
    registeredModel.allowedActions
  );

  const onConfirm = () => {
    if (localLockLevel !== RegisteredModelVersionLockLevel.UNKNOWN) {
      setRequested(true);
      changeLockLevel({
        registeredModelVersionId: id,
        lockLevel: localLockLevel,
      });
      onClose();
    }
  };

  const onCancel = () => {
    setRequested(false);
    setLocalLockLevel(lockLevel);
    onClose();
  };

  useUpdateEffect(() => {
    if (requested && communication.isSuccess) {
      onCancel();
    }
  }, [isOpen, requested, communication.isSuccess]);

  return (
    <Popup
      title="Edit lock level"
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      dataTest="lock-level-editor-popup"
      buttons={{
        mainButtonProps: {
          disabled:
            !isEditable ||
            localLockLevel === RegisteredModelVersionLockLevel.UNKNOWN,
          type: 'button',
          dataTest: 'lock-level-editor-ok-button',
          children: 'Save',
          onClick: onConfirm,
          isLoading: Boolean(communication.isRequesting),
        },
        secondaryButtonProps: {
          dataTest: 'lock-level-editor-cancel-button',
          children: 'Cancel',
          onClick: onCancel,
          isLoading: false,
        },
      }}
    >
      <Stack spacing={3} pt={1}>
        <Typography variant="body2">
          Change lock level to minimize risks and avoid unintended changes.
          <span style={{ display: 'inline-flex', marginLeft: '4px' }}>
            <InlineLink
              simple
              variant="body2"
              to={docsLinks.registry.guides.modelImmutability}
              isExternal
            >
              Learn more
            </InlineLink>
          </span>
        </Typography>
        <Box width="100%">
          <RadioButtons
            maxWidth="100%"
            onChange={(value) => {
              if (value !== RegisteredModelVersionLockLevel.UNKNOWN)
                setLocalLockLevel(value);
            }}
            value={localLockLevel}
            row={false}
            options={[
              {
                value: RegisteredModelVersionLockLevel.OPEN,
                name: RegisteredModelVersionLockLevel.OPEN,
                label: 'Open (default)',
                hint: 'Anyone with write permission can update the registered model.',
                disabled: !changingLockLevelAccess.OPEN,
              },
              {
                value: RegisteredModelVersionLockLevel.CLOSED,
                name: RegisteredModelVersionLockLevel.CLOSED,
                label: 'Closed',
                hint: 'No one can make updates once a model is registered. A new model needs to be registered if you want to make any updates.',
                disabled: !changingLockLevelAccess.CLOSED,
              },
              {
                value: RegisteredModelVersionLockLevel.REDACT,
                name: RegisteredModelVersionLockLevel.REDACT,
                label: 'Redact',
                hint: 'Only organization admins can make updates.',
                disabled: !changingLockLevelAccess.REDACT,
              },
            ]}
          />
        </Box>
      </Stack>
    </Popup>
  );
};

export default LockLevelEditor;
