import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { MonitoringMetricType } from 'generated/types';
import { convertTimeRangeToDateRange } from 'shared/utils/TimeRange';
import { toGraphQLDate } from 'shared/utils/graphql/toGraphQLDate';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { convertMonitoringFilterToGraphQL } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';

import {
  MetricOverTime,
  MetricOverTimeVariables,
} from './graphql-types/useMetricOverTime.generated';

const METRIC_OVER_TIME = gql`
  query MetricOverTime(
    $monitoredEntityId: ID!
    $slicedMetricQuery: MonitoringSlicedMetricQuery!
  ) {
    monitoredEntity(id: $monitoredEntityId) {
      ... on Error {
        ...ErrorData
      }
      ... on MonitoredEntity {
        id
        metrics {
          metricOverTime(query: $slicedMetricQuery) {
            modelVersionId
            modelVersion {
              ... on Error {
                ...ErrorData
              }
              ... on RegisteredModelVersion {
                id
                version
              }
            }
            values
            type
            time
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

interface Props {
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  metricType: MonitoringMetricType;
  output: MonitoringIODescription;
}

export const useMetricOverTime = (props: Props) => {
  const variables = useMemo(() => {
    const dateRange = convertTimeRangeToDateRange(
      props.widgetExternalDeps.timeRange
    );
    return {
      monitoredEntityId: props.widgetExternalDeps.monitoredEntityId,
      slicedMetricQuery: {
        output: props.output,
        startDate: toGraphQLDate(dateRange.from),
        endDate: toGraphQLDate(dateRange.to),
        types: [props.metricType],
        aggregationMilliseconds:
          props.widgetExternalDeps.aggregation.timeWindow,
        filters: props.widgetExternalDeps.filters.map(
          convertMonitoringFilterToGraphQL
        ),
      },
    };
  }, [
    props.metricType,
    props.output,
    props.widgetExternalDeps.aggregation.timeWindow,
    props.widgetExternalDeps.monitoredEntityId,
    props.widgetExternalDeps.timeRange,
    props.widgetExternalDeps.filters,
  ]);

  const query = useCustomQuery<MetricOverTime, MetricOverTimeVariables>(
    METRIC_OVER_TIME,
    {
      variables,
    }
  );

  const memoizedConvert = useCallback(
    (res: MetricOverTime) => {
      return mapDataOrError(res.monitoredEntity, (x) =>
        x.metrics.metricOverTime.filter((metric) =>
          props.widgetExternalDeps.registeredModelVersionIds.includes(
            metric.modelVersionId
          )
        )
      );
    },
    [props.widgetExternalDeps.registeredModelVersionIds]
  );

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert,
    queryResult: query,
  });
};
