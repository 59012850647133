import { equals } from 'ramda';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { pick } from 'lodash';
import { useSelector } from 'react-redux';

import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import Popup from 'shared/view/elements/Popup/Popup';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import {
  validateEmailNotEmpty,
  validateNotEmpty,
} from 'shared/utils/validators';
import { AllowedActionsData } from 'shared/graphql/collaborators/graphql-types/fragments.generated';
import RecordsSection, {
  RecordInfo,
} from 'shared/view/elements/RecordsSection/RecordsSection';
import DevKeysManagerV2 from 'features/developerKeysManager/view/DevKeysManager';
import Section from 'shared/view/elements/SectionComponents/Section/Section';
import { ExtractByTypename } from 'shared/utils/types';
import { trackEvent } from 'setup/app/analytics';
import { selectCurrentUserOrThrowError } from 'features/user';

import { useAddServiceAccount } from '../../store/useAddServiceAccount';
import { AddServiceAccountToOrganizationV2 } from '../../store/graphql-types/useAddServiceAccount.generated';

type Form = {
  fullName: string;
  description: string;
  email: string;
};

const initialValues: Form = {
  fullName: '',
  description: '',
  email: '',
};

const getFieldName = makeGetFieldName<Form>();

export function ServiceAccountCreatePopup(props: {
  organizationId: string;
  groups: Array<{
    id: string;
    name: string;
    allowedActions: AllowedActionsData;
  }>;
  onClose(): void;
}) {
  const currentUser = useSelector(selectCurrentUserOrThrowError);
  const serviceAccountApi = useAddServiceAccount();

  return serviceAccountApi.data ? (
    <CreatedServiceAccountPopup
      serviceAccount={serviceAccountApi.data}
      organizationId={props.organizationId}
      onClose={props.onClose}
    />
  ) : (
    <PresetFormik
      initialValues={initialValues}
      onSubmit={(form) => {
        serviceAccountApi.run({
          ...form,
          organizationId: props.organizationId,
        });
        trackEvent({
          type: 'organization.users.service_account_added',
          data: {
            inviter: pick(currentUser, [
              'id',
              'email',
              'firstName',
              'lastName',
              'jobTitle',
              'company',
            ]),
            invitee: {
              email: form.email,
            },
          },
        });
      }}
    >
      {({ isValid, submitForm, values }) => (
        <Popup
          isOpen={true}
          title="Add service account"
          onClose={props.onClose}
          buttons={{
            mainButtonProps: {
              isLoading: serviceAccountApi.communication.isRequesting,
              type: 'button',
              children: 'Add',
              disabled: !isValid || equals(initialValues, values),
              onClick: submitForm,
            },
            secondaryButtonProps: {
              isLoading: false,
              children: 'Cancel',
              onClick: props.onClose,
            },
          }}
          fullWidth={true}
          maxWidth={'xs'}
        >
          <Stack direction={'column'} divider={<Divider />}>
            <FormStack>
              <TextInputField
                label="Name"
                isRequired={true}
                name={getFieldName({ fullName: null })}
                validate={validateNotEmpty('Name')}
              />
              <TextInputField
                label="Email"
                isRequired={true}
                name={getFieldName({ email: null })}
                validate={validateEmailNotEmpty}
              />
              <TextInputField
                label="Describe your service"
                name={getFieldName({ description: null })}
              />
            </FormStack>
          </Stack>
        </Popup>
      )}
    </PresetFormik>
  );
}

const CreatedServiceAccountPopup = (props: {
  organizationId: string;
  serviceAccount: ExtractByTypename<
    AddServiceAccountToOrganizationV2['organizationV2'],
    'OrganizationV2'
  >['addServiceAccount'];
  onClose(): void;
}) => {
  const [devKeys, setDevKeys] = useState<{
    primaryKey: string;
    secondaryKey: string | undefined;
  }>({
    primaryKey: props.serviceAccount.primaryKey,
    secondaryKey: props.serviceAccount.secondaryKey,
  });

  return (
    <Popup
      isOpen={true}
      title="Add service account"
      onClose={props.onClose}
      buttons={{
        mainButtonProps: {
          isLoading: false,
          type: 'button',
          children: 'Close',
          onClick: props.onClose,
        },
      }}
      fullWidth={true}
      maxWidth={'xs'}
    >
      <Stack direction="column" spacing={4}>
        <RecordsSection>
          <RecordInfo label="Name">{props.serviceAccount.fullName}</RecordInfo>
          <RecordInfo label="Email">{props.serviceAccount.email}</RecordInfo>
          <RecordInfo label="Description">
            {props.serviceAccount.description}
          </RecordInfo>
        </RecordsSection>
        <Section label="Development key">
          <DevKeysManagerV2
            {...devKeys}
            onChangeDevKeys={setDevKeys}
            userId={props.serviceAccount.id}
            organizationId={props.organizationId}
          />
        </Section>
      </Stack>
    </Popup>
  );
};
