import { ArtifactWithPath } from 'shared/graphql/Artifact/Artifact';
import { useLazyRestQuery } from 'shared/utils/react/useRequest';

import { getLoadArtifactUrlService } from '../getLoadArtifactUrlService';
import { EntityWithArtifactsType } from '../types';

interface ILocalProps {
  entityType: EntityWithArtifactsType;
  entityId: string;
  artifact: ArtifactWithPath;
}

const useDownloadArtifact = ({
  artifact,
  entityId,
  entityType,
}: ILocalProps) => {
  const { communication: downloadingArtifact, fetch: downloadArtifact } =
    useLazyRestQuery(async () => {
      const artifactUrl = await getLoadArtifactUrlService({
        entityType,
      }).loadArtifactUrl(entityId, artifact);
      const { filenameExtension, key } = artifact;
      const fileName =
        // if key does not end in extension
        !filenameExtension ||
        (filenameExtension && key.endsWith(`.${filenameExtension}`))
          ? // then name is the key
            key
          : // otherwise key.fileExtension
            `${key}.${filenameExtension}`;

      await downloadFromUrl(artifactUrl, fileName);
    });

  return {
    downloadingArtifact,
    downloadArtifact,
  };
};

const downloadFromUrl = async (url: string, name: string) => {
  const link = document.createElement('a');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.setAttribute('href', url);
  link.setAttribute('download', name);
  link.click();
  window.URL.revokeObjectURL(link.href);
  document.body.removeChild(link);
};

export default useDownloadArtifact;
