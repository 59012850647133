export const withScientificNotationOrRounded = (num: number | string) => {
  // todo it is a bad move to modify arrived prop. You should create new variable and modify that variable.
  if (typeof num === 'string') {
    num = Number(num);
  }
  if (isNaN(num)) {
    return num;
  }
  if (isFinite(num)) {
    const exponential = parseInt(
      String(num.toExponential()).split('e')[1].substring(1)
    );
    const sign = String(num.toExponential()).split('e')[1].substring(0)[0];
    return sign === '-' && exponential > 4
      ? toFormattedExponential(num)
      : Math.round(num * 10000) / 10000;
  }
  return num;
};

const toFormattedExponential = (num: number) => {
  const [multiplier, exponential] = num.toExponential().split('e');
  const formattedMultiplier = Number(Number(multiplier).toFixed(2));
  return `${formattedMultiplier}e${exponential}`;
};
