import PagesTabs from 'shared/view/pages/PagesTabs/PagesTabs';
import routes from 'shared/routes';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import isNotNil from 'shared/utils/isNotNill';
import PageSettingsIcon from 'shared/view/elements/PageSettingsIcon/PageSettingsIcon';
import { useEndpointSettingsActions } from 'features/deployment/canary/endpointSettings/store/useEndpointSettingsActions';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { RequiredOwner } from 'features/editResourceInformation/store/useEditResourceInformation';

interface Props {
  endpointId: string;
  endpoint: {
    __typename: 'Endpoint';
    allowedActions: { delete: boolean; update: boolean };
    id: string;
    description: string;
    owner: RequiredOwner;
  } | null;
}

export default function EndpointPageTabs(props: Props) {
  const workspaceName = useWorkspaceNameFromURL();
  const organizationId = useCurrentOrganizationV2();
  const actions = useEndpointSettingsActions({
    endpoint: props.endpoint,
    endpointId: props.endpointId,
    workspaceName,
    organizationId,
  });

  return (
    <PagesTabs
      tabs={[
        {
          label: 'Overview',
          to: routes.endpointOverview.getRedirectPath({
            endpointId: props.endpointId,
            workspaceName,
          }),
        },
        {
          label: 'Usage',
          to: routes.endpointUsage.getRedirectPath({
            endpointId: props.endpointId,
            workspaceName,
          }),
        },
        {
          label: 'Builds',
          to: routes.endpointBuilds.getRedirectPath({
            endpointId: props.endpointId,
            workspaceName,
          }),
        },
        {
          label: 'Settings',
          to: routes.endpointSettings.getRedirectPath({
            endpointId: props.endpointId,
            workspaceName,
          }),
        },
      ].filter(isNotNil)}
      rightContent={<PageSettingsIcon actions={actions} />}
    />
  );
}
