import { compareArtifacts } from 'shared/models/CompareEntities/new/artifactsComparing';
import { compareEndpoint } from 'shared/models/CompareEntities/new/endpointsComparing';
import { compareLabels } from 'shared/models/CompareEntities/new/labelsComparing';
import { compareEntitiesPrimitives } from 'shared/models/CompareEntities/new/primitivesComparing';
import { compareAttributes } from 'shared/models/CompareEntities/new/attributesComparing';
import { compareDatasets } from 'shared/models/CompareEntities/new/datasetsComparing';
import { compareEnvironments } from 'shared/models/CompareEntities/new/environmentsComparing';
import { compareCodeVersions } from 'shared/models/CompareEntities/new/codeVersionsComparing';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';

import { compareTypes } from './typesComparing';
import { ComparedRegisteredModelVersions } from './comparedRegisteredModelVersions';

const compareRegisteredModelVersions = (
  registeredModelVersions: ComparedRegisteredModelVersions
) => {
  return {
    version: compareEntitiesPrimitives(
      ({ version }) => version,
      registeredModelVersions
    ),
    description: compareEntitiesPrimitives(
      ({ description }) => description,
      registeredModelVersions
    ),
    type: compareTypes(registeredModelVersions),
    owner: compareEntitiesPrimitives(
      ({ owner }) =>
        owner.__typename === 'User' ? owner.username : owner.name,
      registeredModelVersions
    ),
    project: compareEntitiesPrimitives(
      ({ run }) =>
        isNotNullableRestrictedGraphqlError(run) ? run.project.id : null,
      registeredModelVersions
    ),
    experimentRun: compareEntitiesPrimitives(
      ({ run }) => (isNotNullableRestrictedGraphqlError(run) ? run.id : null),
      registeredModelVersions
    ),
    stage: compareEntitiesPrimitives(
      ({ stage }) => stage,
      registeredModelVersions
    ),
    dateUpdated: compareEntitiesPrimitives(
      ({ dateUpdated }) => dateUpdated,
      registeredModelVersions
    ),
    artifacts: compareArtifacts(registeredModelVersions),
    endpoints: compareEndpoint(registeredModelVersions),
    labels: compareLabels(registeredModelVersions, ({ labels }) => labels),
    attributes: compareAttributes(registeredModelVersions),
    datasets: compareDatasets(registeredModelVersions),
    environments: compareEnvironments(registeredModelVersions),
    codeVersions: compareCodeVersions(registeredModelVersions),
  };
};

export default compareRegisteredModelVersions;
