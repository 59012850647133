import * as React from 'react';
import { ParentSize } from '@visx/responsive';

import Popup from 'shared/view/elements/Popup/Popup';
import WidgetView from 'shared/view/domain/CrossRunDashboard/CustomWidgetView/WidgetView';
import { ExperimentRunWithDisplayedFields } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import { WidgetSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/WidgetSettings';
import { CrossRunWidget } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunWidget';

interface ILocalProps {
  isOpen: true;
  widget: CrossRunWidget;
  experimentRuns: ExperimentRunWithDisplayedFields[];
  onRequestClose(): void;
  editWidget(widgetSettings: WidgetSettings): void;
}

const FullScreenWidget = (props: ILocalProps) => {
  return (
    <Popup
      title={props.widget.settings.name}
      fullScreen={true}
      isOpen={props.isOpen}
      onClose={props.onRequestClose}
    >
      <ParentSize>
        {(parent) => (
          <WidgetView
            experimentRuns={props.experimentRuns}
            id={props.widget.id}
            widgetSettings={props.widget.settings}
            height={parent.height}
            width={parent.width}
            editWidget={props.editWidget}
          />
        )}
      </ParentSize>
    </Popup>
  );
};

export default FullScreenWidget;
