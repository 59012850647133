import { useEffect } from 'react';
import { Typography, Stack } from '@mui/material';

import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';

import {
  PopperContainer,
  StyledPopper,
  WrapperWithCustomBorder,
} from './styles';

interface Props {
  anchorEl: HTMLElement | undefined;
  children: React.ReactNode;
  showReminder: boolean;
  onClose: () => void;
}

export const ReminderPopper = (props: Props) => {
  const { closePopup, isPopupOpen, openPopup } = usePopupManager();

  useEffect(() => {
    if (props.showReminder) {
      openPopup();
    }
  }, [openPopup, props.showReminder]);

  const handleRequestClose = () => {
    props.onClose();
    closePopup();
  };

  return (
    <>
      <StyledPopper
        open={isPopupOpen}
        anchorEl={props.anchorEl}
        sx={{ width: '300px' }}
        placement="right-end"
      >
        <PopperContainer>
          <Stack direction="row" justifyContent="space-between" mb="4px">
            <Typography fontSize={27}>✨</Typography>
            <IconAwesomeClickable
              size="lg"
              icon={ICONS.close}
              onClick={handleRequestClose}
            />
          </Stack>

          <Typography variant="subtitle2">Discover Verta</Typography>
          <Typography variant="body2">
            Our getting started tutorials can always be found within the
            "Documentation" section.
          </Typography>
        </PopperContainer>
      </StyledPopper>
      <WrapperWithCustomBorder $isReminderOpen={isPopupOpen}>
        {props.children}
      </WrapperWithCustomBorder>
    </>
  );
};
