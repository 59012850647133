import { clone } from 'ramda';

import isNotNil from 'shared/utils/isNotNill';
import {
  isJsonObject,
  JsonData,
  traverseJson,
  traverseJsonObjects,
} from 'shared/utils/json';

import { GraphqlRequestResponse } from '../GraphqlRequestResponse';

type RequiredRequest = {
  payload: { operationName: string; variables: Record<string, JsonData> };
};

export type RequiredResponse = {
  payload: GraphqlRequestResponse['response']['payload'];
};

export type RequiredRequestResponse = {
  request: RequiredRequest;
  response: RequiredResponse;
};

export type FieldObfuscator<Value = JsonData> = {
  typenames: string[] | 'any';
  field: string;
  obfuscate(value: Value): Value;
  requestVariablesMatchers: RequestVariableMatcher[];
};

export type RequestVariableMatcher<T = JsonData> = (
  keyValue: {
    key: string;
    value: T;
  },
  obfuscate: (value: T) => T,
  response: { field: string }
) => T;

function obfuscateResponse(
  obfuscator: FieldObfuscator,
  response: RequiredResponse
): RequiredResponse {
  if (response.payload.type === 'serverError') {
    return response;
  }

  traverseJsonObjects((data) => {
    if (
      isJsonObject(data) &&
      typeof data['__typename'] === 'string' &&
      (obfuscator.typenames === 'any' ||
        obfuscator.typenames.includes(data['__typename'])) &&
      obfuscator.field in data
    ) {
      data[obfuscator.field] = isNotNil(data[obfuscator.field])
        ? obfuscator.obfuscate(data[obfuscator.field])
        : data[obfuscator.field];
    }
  }, response.payload.data);

  return response;
}

const obfuscateRequest = (
  obfuscator: FieldObfuscator,
  request: RequiredRequest
): RequiredRequest => {
  traverseJson((key, value) => {
    return obfuscator.requestVariablesMatchers.reduce(
      (resValue, requestObfuscator) => {
        return requestObfuscator(
          { key, value: resValue },
          obfuscator.obfuscate,
          obfuscator
        );
      },
      value
    );
  }, request.payload.variables);
  return request;
};

const runObfuscator = <T extends RequiredRequestResponse>(
  obfuscator: FieldObfuscator,
  requestResponses: T[]
): T[] => {
  requestResponses.forEach((reqRes) => {
    obfuscateResponse(obfuscator, reqRes.response);
    obfuscateRequest(obfuscator, reqRes.request);
  });
  return requestResponses;
};

export const obfuscate = <T extends RequiredRequestResponse>(
  obfuscators: FieldObfuscator[],
  requestResponses: T[]
): T[] => {
  return obfuscators.reduce(
    (resRequestResponses, obfuscator) =>
      runObfuscator(obfuscator, resRequestResponses),
    requestResponses.map((x) => clone(x))
  );
};
