import { PredicateOperator } from 'generated/types';
import {
  Alerter,
  alerterFixedOperatorsForSelection,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/Alerter';
import { getLabelByPredicateOperator } from 'shared/models/PredicateOperator';
import matchType from 'shared/utils/matchType';

export interface AlertCondition {
  operator: RangeOperator | PredicateOperator;
  threshold: number;
  lowerBound: number;
  upperBound: number;
}

type RangeOperator = 'between' | 'notBetween';

type AlertConditionOperator = AlertCondition['operator'];

const makeIsType = <
  TargetType extends ValueType,
  ValueType extends string = string,
>(cases: { [K in TargetType]: K }) => {
  return (value: ValueType): value is TargetType => {
    return Object.keys(cases).includes(value);
  };
};

export const isRangeAlertConditionOperator = makeIsType<
  'between' | 'notBetween',
  AlertConditionOperator
>({
  between: 'between',
  notBetween: 'notBetween',
});

export const convertAlertConditionToAlerter = (
  condition: AlertCondition
): Alerter => {
  if (isRangeAlertConditionOperator(condition.operator)) {
    return {
      type: 'range',
      lowerBound: condition.lowerBound,
      upperBound: condition.upperBound,
      alertIfOutsideRange: condition.operator === 'notBetween',
    };
  }

  return {
    type: 'fixed',
    operator: condition.operator,
    threshold: condition.threshold,
  };
};

export const alertConditionOperators: AlertConditionOperator[] = [
  ...alerterFixedOperatorsForSelection,
  'between',
  'notBetween',
];

export const getLabelByAlertConditionOperator = (
  operator: AlertConditionOperator
) => {
  if (isRangeAlertConditionOperator(operator)) {
    return matchType(
      {
        between: () => 'between',
        notBetween: () => 'not between',
      },
      operator
    );
  }

  return getLabelByPredicateOperator(operator);
};
