import { gql } from '@apollo/client';

import { useLoadUserWorkspaces } from 'features/organizations/hooks/useLoadUserWorkspaces';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { combineCommunications } from 'shared/utils/redux/communication/combineCommunications';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/useAddWorkspace.generated';

export { Types };

export const QUERY = gql`
  mutation AddWorkspaceToOrganizationV2(
    $organizationId: ID!
    $input: WorkspaceV2Input!
  ) {
    organizationV2(id: $organizationId) {
      ... on OrganizationV2 {
        id
        addWorkspace(input: $input) {
          id
          name
          workspaces {
            orgId
            id
            name
            description
            createdTimestamp
            updatedTimestamp
            builtIn
            namespace
            allowedActions {
              ...AllowedActionsData
            }
            permissions {
              role {
                id
                name
                description
                builtIn
              }
              group {
                id
                name
                description
                builtIn
              }
            }
          }
        }
      }
    }
  }
  ${ALLOWED_ACTIONS_FRAGMENT}
`;

export const useAddWorkspace = (onCompleted: () => void) => {
  const [run, communication] = useCustomMutation<
    Types.AddWorkspaceToOrganizationV2,
    Types.AddWorkspaceToOrganizationV2Variables
  >(
    QUERY,
    {
      context: 'adding workspace',
    },
    {
      onCompleted: () => {
        loadUserWorkspacesApi.load();
      },
    }
  );

  const loadUserWorkspacesApi = useLoadUserWorkspaces(onCompleted);

  return {
    run,
    communication: combineCommunications([
      communication,
      loadUserWorkspacesApi.communication,
    ]),
  };
};
