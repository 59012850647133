import { AxisScale } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { useTheme } from '@mui/material';

import CustomAxisLeft, {
  CustomAxisLeftProps,
} from '../CustomAxisLeft/CustomAxisLeft';

const CustomAxisLeftWithGridRows = <Scale extends AxisScale>(
  props: CustomAxisLeftProps<Scale> & {
    innerWidth: number;
    maxCharCount?: number;
  }
) => {
  const theme = useTheme();
  return (
    <>
      <CustomAxisLeft {...props} />
      <GridRows
        scale={props.scale}
        width={props.innerWidth}
        stroke={theme.palette.text.secondary}
        numTicks={props.numTicks}
        strokeWidth={0.5}
      />
    </>
  );
};

export default CustomAxisLeftWithGridRows;
