import { TickRendererProps } from '@visx/axis';
import { FC } from 'react';

import SvgTextWithTruncation from '../SvgTextWithTruncation/SvgTextWithTruncation';

export const DefaultTruncatedTickComponent: FC<
  React.PropsWithChildren<TickRendererProps>
> = ({ formattedValue, ...props }) => (
  <SvgTextWithTruncation {...props} maxCharCount={20}>
    {formattedValue || ''}
  </SvgTextWithTruncation>
);
