import Stack from '@mui/material/Stack';

import { IntegrationsListItemType } from 'pages/authorized/SystemAdmin/shared/types/IntegrationsListItemType';
import SystemIntegrationsItem from 'features/systemAdmin/integrations/list/view/SystemIntegrationsItem';

type Props = {
  integrations: IntegrationsListItemType[];
  reload: () => void;
};

export default function SystemIntegrationsList(props: Props) {
  return (
    <Stack direction={'column'} spacing={3}>
      {props.integrations.map((integration, index) => (
        // divs for correct divider work (otherwise margins will conflict with Grid in content)
        <div key={`${integration.type}_${index}`}>
          <SystemIntegrationsItem
            integration={integration}
            reload={props.reload}
          />
        </div>
      ))}
    </Stack>
  );
}
