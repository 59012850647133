import Stack from '@mui/material/Stack';
import { ParentSize } from '@visx/responsive';

import { Size } from 'shared/utils/charts/chartSizes';

import styles from './MonitoringWidgetSizeDetector.module.css';

interface Props {
  children: (size: Size) => JSX.Element;
}

const MonitoringWidgetSizeDetector = (props: Props) => {
  return (
    <ParentSize debounceTime={100} className={styles.root}>
      {(size) =>
        // we need to check width > 1 because we should skip rendering
        // content with initial width(0)
        size.width > 1 ? (
          <Stack alignContent="center" justifyContent="center">
            <div>{props.children(size)}</div>
          </Stack>
        ) : (
          <div />
        )
      }
    </ParentSize>
  );
};

export default MonitoringWidgetSizeDetector;
