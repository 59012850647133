import { last30days } from 'shared/utils/TimeRange';

import { getDefaultAggregation } from '../MonitoringAggregation';
import {
  MonitoringModelState,
  CURRENT_MONITORING_MODEL_VERSION,
} from '../MonitoringModel';
import { makeDefaultCompareState } from './makeDefaultCompareState';
import { makePresetDriftDashboard } from './presetDashboards/presetDriftDashboard';
import { makePresetOutliersDashboard } from './presetDashboards/presetOutliersDashboard';
import { makePresetOverviewDashboard } from './presetDashboards/presetOverviewDashboard';
import { makePresetPerformanceDashboard } from './presetDashboards/presetPerformanceDashboard';
import { MakeDefaultModelStateProps } from './shared/MakeDefaultModelStateProps';

export const defaultTimeRange = last30days;

export const makeDefaultModelState = (
  props: MakeDefaultModelStateProps
): MonitoringModelState => {
  return {
    dashboards: [
      makePresetOverviewDashboard(props),
      makePresetPerformanceDashboard(props),
      makePresetDriftDashboard(props),
      makePresetOutliersDashboard(props),
    ],
    timeRange: defaultTimeRange,
    aggregation: getDefaultAggregation(defaultTimeRange),
    version: CURRENT_MONITORING_MODEL_VERSION,
    registeredModelVersion: props.registeredModelVersion,
    filters: [],
    compare: makeDefaultCompareState(props),
    stateMonitoredModelType: props.monitoredModelType,
  };
};
