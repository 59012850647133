import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useModelVersionWorkflows } from 'features/catalog/registeredModelVersion/workflows/store/useModelVersionWorkflows';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

const ModelVersionWorkflows = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ModelVersionWorkflows' */ 'features/catalog/registeredModelVersion/workflows/view/workflows'
    )
);

const WorkflowsPage = () => {
  const { versionId } = useRouteParams(routes.modelVersionWorkflows);

  const { data, communication, reload } = useModelVersionWorkflows({
    versionId,
  });

  return (
    <DefaultMatchRemoteDataOrError
      communication={communication}
      data={data}
      context="loading model version"
    >
      {(parsedWorkflows) => (
        <ModelVersionWorkflows
          parsedWorkflows={parsedWorkflows}
          reload={reload}
        />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default WorkflowsPage;
