import { useMemo } from 'react';

import { PrecisionRecall } from 'shared/models/Monitoring/PrecisionRecall';
import { Size } from 'shared/utils/charts/chartSizes';
import { OnSetReset } from 'shared/utils/charts/zoom/useZoomReset';

import BaseRocChart from '../../shared/BaseRocChart/BaseRocChart';

interface Props {
  id: string;
  precisionRecall: PrecisionRecall[];
  totalPredictions: number;
  totalPositives: number;
  widgetSize: Size;
  onSetReset: OnSetReset;
}

const PrecisionRecallChart = (props: Props) => {
  const { precisionRecall } = props;

  const noSkillValue = props.totalPositives / props.totalPredictions;

  const xAxis = useMemo(
    () => ({
      label: 'Recall',
      groups: precisionRecall.map((d) => ({
        values: d.recallValues,
        modelVersion: d.modelVersion,
      })),
    }),
    [precisionRecall]
  );

  const yAxis = useMemo(
    () => ({
      label: 'Precision',
      groups: precisionRecall.map((d) => ({
        values: d.precisionValues,
        modelVersion: d.modelVersion,
      })),
    }),
    [precisionRecall]
  );

  return (
    <BaseRocChart
      id={props.id}
      xAxis={xAxis}
      yAxis={yAxis}
      noSkillY1={noSkillValue}
      noSkillY2={noSkillValue}
      widgetSize={props.widgetSize}
      onSetReset={props.onSetReset}
    />
  );
};

export default PrecisionRecallChart;
