import { ReactElement } from 'react';

import matchBy from 'shared/utils/matchBy';
import { useUpdateEffect } from 'shared/view/hooks/useUpdateEffect';

export type UnionFieldsInitialValuesMap<Union extends { type: string }> = {
  [K in Union['type']]: Extract<Union, { type: K }>;
};

type MappedRender<Union extends { type: string }> = {
  [K in Union['type']]: (value: Extract<Union, { type: K }>) => ReactElement;
};

interface Props<Union extends { type: Type }, Type extends string> {
  initialValues: UnionFieldsInitialValuesMap<Union>;
  value: Union;
  onTypeChange: (value: Union) => void;
  children: MappedRender<Union>;
}

const UnionFields = <Union extends { type: Type }, Type extends string>(
  props: Props<Union, Type>
) => {
  useUpdateEffect(() => {
    props.onTypeChange(props.initialValues[props.value.type]);
  }, [props.value.type]);

  return matchBy(props.value, 'type')<React.ReactElement>(props.children);
};

export default UnionFields;
