import { isNil } from 'ramda';

import { AppError, CustomError } from 'shared/models/Error';
import { RecordFromUnion } from 'shared/utils/types';

import { isInitialCommunication } from './isInitialCommunication';
import { ICommunication, CommunicationState } from './types';

type IRemoteData<Data, Err = AppError> =
  | { type: typeof CommunicationState.notAsked }
  | { type: typeof CommunicationState.requesting }
  | { type: 'error'; data: { error: Err } }
  | { type: typeof CommunicationState.success; data: Data };

type IRemoteDataMatchers<Data, Err extends AppError, Result> = RecordFromUnion<
  IRemoteData<Data, Err>['type'],
  {
    notAsked: () => Result;
    requesting: () => Result;
    error: (data: { error: Err; data: Data | undefined | null }) => Result;
    success: (data: NonNullable<Data>) => Result;
  }
>;

export function matchRemoteData<Data, Err extends AppError, Result>(
  communication: ICommunication<Err>,
  data: Data | undefined | null,
  matchers: IRemoteDataMatchers<Data, Err, Result>
): Result {
  if (isInitialCommunication(communication)) {
    return matchers.notAsked();
  }
  if (communication.isRequesting) {
    return matchers.requesting();
  }
  if (communication.error || isNil(data)) {
    return matchers.error({
      error: communication.error || (new CustomError('error') as Err),
      data,
    });
  }
  if (communication.isSuccess) {
    return matchers.success(data as NonNullable<Data>);
  }
  return matchers.notAsked();
}
