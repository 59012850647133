import { useEffect, useState } from 'react';

import { useLoadCustomAttributeDefinitions } from 'features/catalog/shared/hooks/useLoadCustomAttributeDefinitions';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import { OmitStrict } from 'shared/utils/types';
import { CustomAttributeDefinition } from 'generated/types';

import { useCustomDashboardTopModels } from './useCustomDashboardTopModels';

interface CustomAttributeValue {
  id: string;
  customAttributeDefinitionId: string;
  targetId: string;
  selectedValue: string;
  customAttributeData?: OmitStrict<
    CustomAttributeDefinition,
    'createdBy' | 'updatedBy'
  >;
}

export interface Model {
  id: string;
  name: string;
  workspace: {
    name: string;
  };
  customAttributeValues: CustomAttributeValue[];
}

const mergeCustomAttributes = (
  customAttrsData: ReturnType<typeof useLoadCustomAttributeDefinitions>['data'],
  model: Model
): Model => {
  const mergedCustomAttributes: CustomAttributeValue[] = [];

  if (!customAttrsData || !isNotError(customAttrsData)) {
    return model;
  } else {
    for (const customAttrData of customAttrsData) {
      const matchedAttrValue = model.customAttributeValues.find(
        (attrValue) =>
          attrValue.customAttributeDefinitionId === customAttrData.id
      );

      if (matchedAttrValue) {
        mergedCustomAttributes.push({
          ...matchedAttrValue,
          //@ts-ignore
          customAttributeData: customAttrData,
        });
      }
    }
  }

  return { ...model, customAttributeValues: mergedCustomAttributes };
};

export const useCustomDashboardTopModelsWithAttrs = (props: {
  orgId: string;
  sortBy: string;
}) => {
  const { orgId, sortBy } = props;
  const { data: topModelsData, communication: topModelsCommunication } =
    useCustomDashboardTopModels({ orgId, sortBy });
  const { data: customAttrsdata, communication: customAttrCommunication } =
    useLoadCustomAttributeDefinitions({ organizationId: orgId });
  const [data, setData] = useState<Model[]>([]);

  useEffect(() => {
    if (isNotError(topModelsData) && isNotError(customAttrsdata)) {
      topModelsData.forEach((model) => {
        if (isNotError(model)) {
          const mergedModel = mergeCustomAttributes(customAttrsdata, model);
          setData((prev) => [...prev, mergedModel]);
        }
      });
    }
  }, [topModelsData, customAttrsdata]);

  return {
    data,
    isRequesting:
      topModelsCommunication.isRequesting ||
      customAttrCommunication.isRequesting,
  };
};
