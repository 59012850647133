import { MutationHookOptions } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { ExtractByTypename } from 'shared/utils/types';
import { namedOperations } from 'generated/named-operations';

import {
  EDIT_EVALUATION_BASIC_INFO,
  EditEvaluationBasicInfo,
  EditEvaluationBasicInfoVariables,
} from '../graphql';

type ResponseVersion = ExtractByTypename<
  EditEvaluationBasicInfo['registeredModelVersion'],
  'RegisteredModelVersion'
>['updateRegisteredModelVersion'];

export const useEditEvaluation = (
  { onSuccess }: { onSuccess: (evaluation: ResponseVersion) => void },
  options?: MutationHookOptions<
    EditEvaluationBasicInfo,
    EditEvaluationBasicInfoVariables
  >
) => {
  const [editEvaluationBasicInfo, editingEvaluationBasicInfo] =
    useCustomMutation<
      EditEvaluationBasicInfo,
      EditEvaluationBasicInfoVariables
    >(
      EDIT_EVALUATION_BASIC_INFO,
      { context: 'editing evaluation basic info' },
      {
        ...options,
        onCompleted: (_res) =>
          isNotNullableRestrictedGraphqlError(_res.registeredModelVersion) &&
          onSuccess(_res.registeredModelVersion.updateRegisteredModelVersion),
        refetchQueries: [namedOperations.Query.EvaluationSummary],
      }
    );

  return { editEvaluationBasicInfo, editingEvaluationBasicInfo };
};
