// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MUISelect, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Cancel from '@mui/icons-material/Cancel';

import Chip from 'shared/view/elements/Chip/Chip';
import VertaSelectBase, {
  SelectCommonProps,
  useSelectBaseCommonProps,
} from 'shared/view/elements/Selects/shared/view/VertaSelectBase';
import { useVertaSelectOptions } from 'shared/view/elements/Selects/shared/hooks/useVertaSelectOptions';
import isNotNil from 'shared/utils/isNotNill';
import {
  IGroupedOptions,
  IOptionType,
} from 'shared/view/elements/Selects/shared/types';

type Props<T> = SelectCommonProps & {
  options: Array<IOptionType<T>> | IGroupedOptions<T>;
  value: IOptionType<T>[];
  onChange(value: IOptionType<T>[]): void;
};

const MultiSelect = <T extends any = string>(props: Props<T>) => {
  const { firstOption, children } = useVertaSelectOptions(props.options);
  const commonProps = useSelectBaseCommonProps(props, !isNotNil(firstOption));

  /**
   * Workaround typecasting. The reason is that onChange type from MUI Select provides union with generic T and string.
   * But our MultiSelect component works only with IOptionType[], so it will be impossible to get event with string
   */
  const onChange = (event: SelectChangeEvent<IOptionType<T>[]>) => {
    props.onChange(event.target.value as IOptionType<T>[]);
  };

  return (
    <VertaSelectBase {...props} label={commonProps.label}>
      <MUISelect
        {...commonProps}
        value={props.value}
        onChange={onChange}
        renderValue={(_options) =>
          _options.length !== 0 ? (
            <Stack direction="row" spacing={0.5}>
              {_options.map((option) => (
                <Chip
                  size="small"
                  label={option.label}
                  key={option.label}
                  data-test="multi-select-selected-option"
                  deleteIcon={
                    !option.disabled ? (
                      <Cancel
                        data-test="multi-select-delete-option"
                        onMouseDown={(e) => e.stopPropagation()}
                      />
                    ) : (
                      <span></span>
                    )
                  }
                  onDelete={() =>
                    props.onChange(
                      _options.filter((o) => o.value !== option.value)
                    )
                  }
                />
              ))}
            </Stack>
          ) : null
        }
        multiple={true}
      >
        {children}
      </MUISelect>
    </VertaSelectBase>
  );
};

export default MultiSelect;
