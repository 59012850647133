import { gql } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { PYPI_CONFIGURATION_FRAGMENT } from 'shared/graphql/SystemAdmin/PypiIntegration';
import {
  CreatePypiConfiguration,
  CreatePypiConfigurationVariables,
} from 'features/systemAdmin/integrations/pypi/store/graphql-types/useCreatePypiConfiguration.generated';

const MUTATION = gql`
  mutation CreatePypiConfiguration(
    $name: String!
    $url: String!
    $username: String!
    $password: String!
    $enabled: Boolean!
  ) {
    pypiConfigurations {
      id
      createPypiConfiguration(
        name: $name
        url: $url
        username: $username
        password: $password
        enabled: $enabled
      ) {
        id
        configurations {
          id
          ...PypiConfigurationData
        }
      }
    }
  }
  ${PYPI_CONFIGURATION_FRAGMENT}
`;

export const useCreatePypiConfiguration = ({
  onCompleted,
}: {
  onCompleted(): void;
}) => {
  const [createPypiConfiguration, creatingPypiConfiguration] =
    useCustomMutation<
      CreatePypiConfiguration,
      CreatePypiConfigurationVariables
    >(MUTATION, { context: 'creating PyPI configuration' }, { onCompleted });

  return {
    createPypiConfiguration,
    creatingPypiConfiguration,
  };
};
