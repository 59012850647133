import { Wizard, WizardStepProps } from 'shared/view/elements/Wizard';

import * as slides from './slides';

const steps: WizardStepProps[] = [
  {
    stepLabel: {
      icon: '1',
      text: 'Write model documentation',
    },
    slides: [slides.DocsTabHighlight],
  },
  {
    stepLabel: {
      icon: '2',
      text: 'Generate AI assisted content',
    },
    slides: [
      slides.DocsUsePrompts,
      slides.DocsCopyResponse,
      slides.DocsEditorHighlight,
    ],
  },
  {
    stepLabel: false,
    slides: [slides.TrySampleModel],
  },
];

export const AiAssistedDocs = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  return <Wizard steps={steps} onCompleted={onCompleted} />;
};
