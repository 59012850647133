import { useDistributionQuery } from 'features/monitoring/widgets/store/drift/useDistribution';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { DistributionBarChartWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/BarChartWidget';

import { MonitoringWidgetProps } from '../shared/types';
import DistributionBarChart from './DistributionBarChart/DistributionBarChart';

const DistributionBarChartWidgetView = (
  props: MonitoringWidgetProps<DistributionBarChartWidget>
) => {
  const { data, communication } = useDistributionQuery({
    description: props.widget.ioDescription,
    widgetExternalDeps: props.widgetExternalDeps,
  });

  return (
    <DefaultMatchRemoteDataOrError
      data={data}
      communication={communication}
      context="loading distribution"
    >
      {(loadedData) => (
        <DistributionBarChart
          id={props.id}
          liveDistributions={loadedData.liveDistributions}
          referenceDistributions={loadedData.referenceDistributions}
          size={props.size}
        />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default DistributionBarChartWidgetView;
