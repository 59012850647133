import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';

import { validateNotEmpty } from 'shared/utils/validators';
import { ILoginByLdap } from 'shared/models/Authorization';
import { loginByLdap, selectCommunications } from 'features/user/store';
import { authErrorMessages } from 'shared/utils/customErrorMessages';
import { useToastCommunicationError } from 'features/toast/store/hooks';
import Button from 'shared/view/elements/Button/Button';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import routes from 'shared/routes';

interface ILocalProps {
  goBack(): void;
}

const initialForm: ILoginByLdap = {
  username: '',
  password: '',
  organizationUnit: '',
};

const getFieldName = makeGetFieldName<ILoginByLdap>();

const LoginByActiveDirectory: React.FC<
  React.PropsWithChildren<ILocalProps>
> = ({ goBack }) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const toastCommunicationError = useToastCommunicationError();

  const location = useLocation();
  const queryParams = routes.login.parseQueryParams(location.search);

  const { loginByLdap: loginByLdapCommunication } =
    useSelector(selectCommunications);

  useEffect(() => {
    if (loginByLdapCommunication.error) {
      toastCommunicationError(loginByLdapCommunication.error, {
        context: 'logging in',
        customErrorMessageByType: {
          invalidEmailOrPassword: authErrorMessages.invalidEmailOrPassword,
        },
      });
    }
  }, [loginByLdapCommunication.error, toastCommunicationError]);

  const onSubmit = (values: ILoginByLdap) =>
    dispatch(
      loginByLdap({
        data: values,
        redirectBack: queryParams?.redirect_back,
      })
    );

  return (
    <>
      <Box mt={4}>
        <Button
          onClick={goBack}
          isLoading={false}
          size="small"
          variant="text"
          icon={ICONS.arrowLeft}
        >
          BACK
        </Button>
      </Box>
      <Typography variant="h6">Active Directory Login</Typography>

      <PresetFormik<ILoginByLdap>
        initialValues={initialForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ submitForm }) => (
          <FormStack>
            <TextInputField
              name={getFieldName({ username: null })}
              validate={validateNotEmpty('Username')}
              label="Username"
              isRequired={true}
              size="medium"
            />
            <TextInputField
              name={getFieldName({ password: null })}
              validate={validateNotEmpty('Password')}
              label="Password"
              isRequired={true}
              size="medium"
              type="password"
            />
            <TextInputField
              name={getFieldName({ organizationUnit: null })}
              label="Organization unit"
              size="medium"
            />
            <Box mb={4} mt={3}>
              <Button
                onClick={submitForm}
                isLoading={loginByLdapCommunication.isRequesting}
                size="medium"
                variant="contained"
              >
                Login
              </Button>
            </Box>
          </FormStack>
        )}
      </PresetFormik>
    </>
  );
};

export default LoginByActiveDirectory;
