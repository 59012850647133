import { Box } from '@mui/material';

import isNotNil from 'shared/utils/isNotNill';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';
import { withScientificNotationOrRounded } from 'shared/utils/formatters/number';
import CellRendererEmpty from 'shared/view/elements/DataGrid/columns/CellRendererEmpty';

type Decoration = {
  position: 'before' | 'after';
  decoration: string;
};

type Props = {
  value: number | undefined | null;
  valueDecoration?: Decoration;
  dataTest?: string;
};

// ts-unused-exports:disable-next-line
export default function CellRendererNumeric(props: Props) {
  if (!isNotNil(props.value)) {
    return (
      <Box width="100%" textAlign="right">
        <CellRendererEmpty />;
      </Box>
    );
  }

  const convertedValue = getText({
    value: props.value,
    valueDecoration: props.valueDecoration,
  });
  return (
    <Box width="100%" textAlign="right">
      <CellRendererString value={convertedValue} dataTest={props.dataTest} />
    </Box>
  );
}

function getText({
  value,
  valueDecoration,
}: {
  value: number;
  valueDecoration?: Decoration;
}) {
  const text = String(withScientificNotationOrRounded(value));
  if (valueDecoration) {
    switch (valueDecoration.position) {
      case 'before':
        return `${valueDecoration.decoration}${text}`;
      case 'after':
        return `${text}${valueDecoration.decoration}`;
      default:
        exhaustiveCheck(valueDecoration.position);
    }
  }
  return text;
}
