import { ApolloLink, FetchResult, Operation } from '@apollo/client';

export type GraphqlRequestResponseInterceptor = {
  error: (error: { result: unknown }, operation: Operation) => void;
  ok: (
    result: FetchResult<
      {
        [key: string]: any;
      },
      Record<string, any>,
      Record<string, any>
    >,
    operation: Operation
  ) => void;
};

const requestResponseLink = (
  interceptors: GraphqlRequestResponseInterceptor[]
) =>
  new ApolloLink((operation, forward) => {
    const observable = forward(operation);
    observable.subscribe({
      next: (result) => {
        interceptors.forEach((e) => e.ok(result, operation));
      },
      error: (error) => {
        interceptors.forEach((e) => e.error(error, operation));
      },
    });
    return observable;
  });

export const createRequestResponseLinkWithInterceptors = () => {
  const interceptors: GraphqlRequestResponseInterceptor[] = [];

  return {
    addInterceptor: (f: GraphqlRequestResponseInterceptor) => {
      interceptors.push(f);
    },
    link: requestResponseLink(interceptors),
  };
};
