import { useIconAwesomeColorPalette } from 'shared/view/elements/IconAwesome/hooks/useIconAwesomeColorPalette';
import { useIconAwesomeMUIStylesConverter } from 'shared/view/elements/IconAwesome/useIconAwesomeMUIStylesConverter';
import { ICON_AWESOME_DEFAULT_SIZE } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { IconAwesomeProps } from 'shared/view/elements/IconAwesome/IconAwesomeProps';

interface Props extends IconAwesomeProps {
  disabled?: boolean;
  reversedColors?: boolean;
}

export function useIconClickable({ reversedColors, size, disabled }: Props) {
  const { colors } = useIconAwesomeColorPalette({
    reversedColors,
  });

  const sx = useIconAwesomeMUIStylesConverter({
    size: size ?? ICON_AWESOME_DEFAULT_SIZE,
  });

  return {
    sx,
    color: disabled ? colors.clickable.disabled : colors.clickable.active,
  };
}
