import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Stack, Typography, useTheme } from '@mui/material';
import { ReactNode, useMemo } from 'react';

import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { timeWindowsWithLabels } from 'shared/models/Monitoring/MonitoringModel/MonitoringAggregation';
import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import routes from 'shared/routes';
import { formatDateWithTime } from 'shared/utils/formatters/dateTime';
import { convertTimeRangeToDateRange } from 'shared/utils/TimeRange';
import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';

interface Props {
  model: MonitoringModel;
}

const MonitoringModelStatusLine = (props: Props) => {
  const dateRange = convertTimeRangeToDateRange(props.model.state.timeRange);
  const aggregationOption = useMemo(
    () =>
      timeWindowsWithLabels.find(
        (opt) => opt.value === props.model.state.aggregation.timeWindow
      ),
    [props.model.state.aggregation.timeWindow]
  );
  const workspaceName = useWorkspaceNameFromURL();

  return (
    <Stack alignItems={{ md: 'flex-start', lg: 'flex-end' }}>
      <div style={{ height: 16 }}>
        <Stack direction="row" alignItems="center">
          <StatusItem icon={ICONS.calendar} label="Range">
            <DynamicTypography
              variant="caption"
              value={`${formatDateWithTime(
                dateRange.from
              )} - ${formatDateWithTime(dateRange.to)}`}
              sx={{ lineHeight: '16px' }}
              maxLines={1}
            />
          </StatusItem>

          {aggregationOption ? (
            <StatusItem icon={ICONS.objectGroup} label="Aggregation">
              <DynamicTypography
                variant="caption"
                value={aggregationOption.label}
                sx={{ lineHeight: '16px' }}
                maxLines={1}
              />
            </StatusItem>
          ) : null}
        </Stack>
      </div>
      <Stack direction="row" alignItems="center">
        <StatusItem label="Registered model">
          <InlineLink
            variant="caption"
            to={routes.registeredModel.getRedirectPath({
              workspaceName,
              registeredModelId:
                props.model.state.registeredModelVersion.registeredModel.id,
            })}
          >
            {props.model.state.registeredModelVersion.registeredModel.name}
          </InlineLink>
        </StatusItem>
        {props.model.endpoint ? (
          <>
            <Typography variant="caption" lineHeight="16px">
              •
            </Typography>
            <StatusItem label="Endpoint">
              <InlineLink
                variant="caption"
                to={routes.endpointOverview.getRedirectPath({
                  workspaceName,
                  endpointId: props.model.endpoint.id,
                })}
              >
                {props.model.endpoint.path}
              </InlineLink>
            </StatusItem>
          </>
        ) : null}
      </Stack>
    </Stack>
  );
};

const StatusItem = (props: {
  label: string;
  icon?: IconDefinition;
  children: ReactNode;
}) => {
  const { palette } = useTheme();
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {props.icon ? (
        <IconAwesomeCustomizable
          icon={props.icon}
          nativeProps={{ color: palette.text.secondary }}
        />
      ) : null}

      <Typography
        variant="caption"
        lineHeight="16px"
        color="text.secondary"
        whiteSpace="nowrap"
      >
        {props.label}:
      </Typography>

      {props.children}
    </Stack>
  );
};

export default MonitoringModelStatusLine;
