import Stack from '@mui/material/Stack';
import { useMemo } from 'react';

import {
  findAlertIODescription,
  MonitoringAlert,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import { MonitoringWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidget';
import IconAwesomeClickableWithLabel from 'shared/view/elements/IconAwesome/withLabel/IconAwesomeClickableWithLabel';
import AlertDetailsMonitoringWidget from 'features/monitoring/widgets/view/AlertDetailsMonitoringWidget/AlertDetailsMonitoringWidget';
import matchBy from 'shared/utils/matchBy';
import { getAggregationByAlertAggregationWindow } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertAggregationWindow';
import { getMonitoringWidgetExternalDepsFromModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';

import styles from './MonitoringAlertDetails.module.css';
import UpdateAlertAction from '../shared/UpdateAlertAction/UpdateAlertAction';

interface Props {
  alert: MonitoringAlert;
  model: MonitoringModel;
}

const useAlertWidgets = (props: {
  alert: MonitoringAlert;
  ioDescriptions: MonitoringIODescription[];
}): MonitoringWidget[] => {
  const ioDescription = useMemo(
    () =>
      findAlertIODescription({
        alert: props.alert,
        ioDescriptions: props.ioDescriptions,
      }),
    [props.alert, props.ioDescriptions]
  );
  return useMemo(
    () =>
      ioDescription
        ? matchBy(
            props.alert.settings,
            'type'
          )<MonitoringWidget[]>({
            drift: (settings) => [
              {
                ioDescription,
                id: 'alert-distribution-over-time',
                type: 'histograms',
                title: 'Distribution over time',
                variant: 'distribution',
                driftMetricType: settings.driftMetricType,
                alert: props.alert,
              },
              {
                id: 'alert-drift-time-series',
                type: 'timeSeries',
                title: 'Drift over time',
                variant: {
                  type: 'drift',
                  ioDescription: ioDescription,
                  driftMetricType: settings.driftMetricType,
                },
                alert: props.alert,
              },
            ],
            metric: (settings) => [
              {
                id: 'alert-time-series',
                type: 'timeSeries',
                title: 'Time series',
                variant: {
                  type: 'metric',
                  metricType: settings.metricType,
                  output: ioDescription,
                },
                alert: props.alert,
              },
            ],
          })
        : [],
    [props.alert, ioDescription]
  );
};

const MonitoringAlertDetails = (props: Props) => {
  const widgets = useAlertWidgets({
    alert: props.alert,
    ioDescriptions: props.model.ioDescriptions,
  });

  return (
    <Stack spacing={2}>
      <div>
        <Stack direction="row" justifyContent="flex-end">
          {props.alert.alerter.type === 'fixed' ? (
            <UpdateAlertAction
              monitoredModelType={props.model.type}
              ioDescriptions={props.model.ioDescriptions}
              alert={props.alert}
              renderButton={(openPopup) => (
                <IconAwesomeClickableWithLabel
                  icon={ICONS.pencil}
                  onClick={openPopup}
                  label={'Edit alert rule'}
                />
              )}
            />
          ) : undefined}
        </Stack>
      </div>

      {widgets.map((widget) => (
        <div key={widget.id} className={styles.widget}>
          <AlertDetailsMonitoringWidget
            widgetExternalDeps={{
              ...getMonitoringWidgetExternalDepsFromModel(props.model),
              aggregation: getAggregationByAlertAggregationWindow(
                props.alert.aggregationWindow
              ),
            }}
            widget={widget}
            alert={props.alert}
            updateWidget={undefined}
          />
        </div>
      ))}
    </Stack>
  );
};

export default MonitoringAlertDetails;
