import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';

import EvaluationCreatePage from './EvaluationCreatePage';
import EvaluationsPage from './EvaluationsPage';
import { Layout } from './shared/Layout/view';
import SummaryPage from './Evaluation/SummaryPage';
import EvaluationComparePage from './EvaluationComparePage';
import EvaluationProjectsPage from './EvaluationProjectsPage';

export const evaluationsPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.evaluations.getPath(),
      element: <EvaluationsPage />,
    },
    {
      path: routes.createEvaluation.getPath(),
      element: <EvaluationCreatePage />,
    },
    {
      path: routes.compareEvaluations.getPath(),
      element: <EvaluationComparePage />,
    },
    {
      path: routes.evaluationProjects.getPath(),
      element: <EvaluationProjectsPage />,
    },
    {
      path: routes.evaluation.getPath(),
      element: <Layout />,
      children: [
        {
          path: routes.evaluationSummary.getPath(),
          element: <SummaryPage />,
        },
      ],
    },
  ],
};
