import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import {
  ChangeAlertStatusMutation,
  ChangeAlertStatusMutationVariables,
} from './graphql-types/useChangeAlertStatus.generated';

const CHANGE_ALERT_STATUS_MUTATION = gql`
  mutation ChangeAlertStatusMutation($alertId: ID!, $status: AlertStatus!) {
    alert(id: $alertId) {
      ... on Error {
        ...ErrorData
      }
      ... on Alert {
        id
        updateStatus(status: $status) {
          id
          status
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useChangeAlertStatus = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [mutate, communication] = useCustomMutation<
    ChangeAlertStatusMutation,
    ChangeAlertStatusMutationVariables
  >(
    CHANGE_ALERT_STATUS_MUTATION,
    {
      context: 'changing alert status',
    },
    {
      onCompleted,
    }
  );

  return {
    communication,
    mutate,
  };
};
