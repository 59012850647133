import React from 'react';
import { curveMonotoneY, line } from 'd3-shape';
import { ScaleLinear, ScalePoint } from 'd3-scale';

import { OmitStrict } from 'shared/utils/types';

import styles from './CustomViolinPlot.module.css';

// eslint-disable-next-line @typescript-eslint/ban-types
type ViolinPlotProps<Datum extends object> = {
  yScale: ScalePoint<string>;
  y: (d: Datum) => string;
  data: Datum[];
  value: (d: Datum) => number;
  valueScale: ScaleLinear<number, number>;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default function ViolinPlot<Datum extends object>({
  data,
  y,
  yScale,
  value,
  valueScale,
  ...restProps
}: ViolinPlotProps<Datum> & OmitStrict<React.SVGProps<SVGPathElement>, 'y'>) {
  const yOffset = 0;

  const rightCurve = line<Datum>()
    .x((_) => 0)
    .y((d) => (yScale(y(d)) ?? 0) + yOffset)
    .curve(curveMonotoneY);

  const leftCurve = line<Datum>()
    .x((d) => {
      // todo probably legacy code and can produce malfunctions. Need to be checked and fixed;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return -valueScale(value(d)) ?? 0;
    })
    .y((d) => {
      return (yScale(y(d)) ?? 0) + yOffset;
    })
    .curve(curveMonotoneY);

  const rightCurvePath = rightCurve(data) || '';
  const leftCurvePath = leftCurve([...data].reverse()) || '';
  const path = `${rightCurvePath} ${leftCurvePath.replace('M', 'L')} Z`;

  return (
    <path
      data-test="violin-area-chart-group-area"
      className={styles.root}
      d={path}
      {...restProps}
    />
  );
}
