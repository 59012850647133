import { useCallback } from 'react';

import MonitoringWidgetWrapper from 'shared/view/domain/Monitoring/MonitoringWidget/MonitoringWidgetWrapper/MonitoringWidgetWrapper';
import { MonitoringWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidget';
import { useZoomReset } from 'shared/utils/charts/zoom/useZoomReset';
import ZoomResetAction from 'features/monitoring/widgets/view/shared/actions/ZoomResetAction/ZoomResetAction';
import EditWidgetAction from 'features/monitoring/widgets/view/shared/actions/EditWidgetAction/EditWidgetAction';
import DeleteIconButton from 'shared/view/elements/DeleteIconButton/DeleteIconButton';
import MonitoringWidgetFullScreenAction from 'features/monitoring/widgets/view/shared/actions/MonitoringWidgetFullScreenAction/MonitoringWidgetFullScreenAction';

import { CommonMonitoringWidgetProps } from '../MonitoringWidgetView/shared/types';
import MonitoringWidgetView from '../MonitoringWidgetView/MonitoringWidgetView';
import { useAssociatedAlert } from '../shared/useAssociatedAlert';
import AlertAction from '../shared/actions/AlertAction/AlertAction';

interface Props extends CommonMonitoringWidgetProps {
  deleteWidget: (widgetId: string) => void;
  updateWidget: (widget: MonitoringWidget) => void;
}

const CustomMonitoringWidget = (props: Props) => {
  const { onReset, onSetReset } = useZoomReset();

  const { widget, deleteWidget } = props;
  const onDelete = useCallback(
    () => deleteWidget(widget.id),
    [deleteWidget, widget.id]
  );

  const alert = useAssociatedAlert({
    alerts: props.widgetExternalDeps.alerts,
    ioDescriptions: props.widgetExternalDeps.ioDescriptions,
    widget: props.widget,
  });

  return (
    <MonitoringWidgetWrapper
      draggable={true}
      widget={props.widget}
      actions={[
        <AlertAction
          key="alert"
          alert={alert}
          monitoringModelId={props.widgetExternalDeps.monitoringModelId}
          workspaceName={props.widgetExternalDeps.workspaceName}
        />,
        <ZoomResetAction key="reset" onZoomReset={onReset} />,
        props.widget.type !== 'notInitialized' ? (
          <EditWidgetAction
            key="edit"
            updateWidget={props.updateWidget}
            widget={props.widget}
            widgetExternalDeps={props.widgetExternalDeps}
          />
        ) : null,
        <DeleteIconButton
          key="delete"
          onDelete={onDelete}
          description="widget"
          size="1x"
          faiDataTest="delete-widget"
        />,
        <MonitoringWidgetFullScreenAction
          key="fullScreen"
          widgetExternalDeps={props.widgetExternalDeps}
          widget={props.widget}
          updateWidget={undefined}
        />,
      ]}
    >
      {(size) => (
        <MonitoringWidgetView
          {...props}
          id={props.widget.id}
          size={size}
          onSetReset={onSetReset}
        />
      )}
    </MonitoringWidgetWrapper>
  );
};

export default CustomMonitoringWidget;
