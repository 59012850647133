import { FC, useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Field, FieldProps } from 'formik';
import { debounce } from '@mui/material/utils';

import { trackEvent } from 'setup/app/analytics';
import { selectCurrentWorkspace } from 'features/workspaces';
import { defaultEndpointEnvironmentName } from 'shared/models/Deployment/canary/Endpoint';
import { BuildSource } from 'shared/models/Deployment/canary/Build';
import routes from 'shared/routes';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import handleUnionCases from 'shared/utils/handleUnionCases';
import matchType from 'shared/utils/matchType';
import { validateDescription } from 'shared/models/Description';
import { validateIsUrlPath, validateNotEmpty } from 'shared/utils/validators';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import TagsField from 'shared/view/formComponents/formikFields/TagsFieldWithTopLabel/TagsFieldWithTopLabel';
import withCancelPreviousQuery from 'shared/utils/graphql/withCancelPreviousQuery';
import {
  useSourceBuilds,
  LoadSourceBuildsSettings,
} from 'features/deployment/canary/shared/hooks/useSourceBuilds/useSourceBuilds';
import { selectFlags } from 'features/flags';
import RegisteredModelWithVersionFields from 'features/registry/shared/RegisteredModelWithVersionFields/RegisteredModelWithVersionFields';
import EndpointKafkaFormFields from 'features/deployment/canary/endpointSettings/view/EndpointKafkaFormFields';
import DefaultMatchRemoteData from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { IOptionType } from 'shared/view/elements/Selects/shared/types';
import DefaultConfirmAction, {
  ConfirmType,
} from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';
import AutocompleteField from 'shared/view/formComponents/formikFields/AutocompleteField';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import { PopupStateProps } from 'shared/view/elements/Popup/PopupButtons';
import PopupForm from 'shared/view/formComponents/PopupForm';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import SwitchField from 'shared/view/formComponents/formikFields/SwitchField/SwitchField';
import useKafkaConfigurationTopics from 'features/deployment/canary/endpointSettings/hooks/useKafkaConfigurationTopics';

import { changeSourceId, setSourceBuilds, Settings } from '../settings';
import { useCreateEndpointMutation } from '../../store/createEndpoint/createEndpoint';

interface Props extends PopupStateProps {
  modelId?: string;
  modelVersionId?: string;
  registeredModelId?: string;
}

const getFieldName = makeGetFieldName<Settings>();

// todo refactor this component, remove workarounds here
const CreateEndpointPopup: FC<React.PropsWithChildren<Props>> = ({
  modelVersionId,
  modelId,
  registeredModelId,
  ...popupStateProps
}) => {
  const navigate = useNavigate();
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const { data, communication } = useKafkaConfigurationTopics();

  const { createEndpoint, creatingEndpoint } = useCreateEndpointMutation({
    onCompleted: ({ endpointId }) =>
      navigate(
        routes.endpointOverview.getRedirectPath({
          workspaceName: currentWorkspace.name,
          endpointId,
        })
      ),
  });

  const {
    deployment: { isEnableExpandedEndpointPermissions, isEnableKafka },
  } = useSelector(selectFlags);

  const organizationId = useCurrentOrganizationV2();

  const initialValues: Settings = useMemo(() => {
    const source = (() => {
      if (modelId) {
        return 'experimentRun';
      }
      if (modelVersionId) {
        return 'modelVersion';
      }
      return 'noModel';
    })();

    return {
      source,
      sourceId: modelVersionId || modelId,
      sourceBuilds: undefined,
      build: undefined,
      path: '',
      labels: [],
      description: '',
      permission: null,
      tokensEnabled: true,
      authzPredictionEnabled: true,
      kafkaReq: undefined,
    };
  }, [modelId, modelVersionId]);

  const onSubmit = useCallback(
    (values: Settings) => {
      const { path } = values;
      trackEvent({ type: 'endpoints.endpoint_created' });
      createEndpoint({
        ...(values as Required<Settings>),
        path: path || undefined,
        organizationId,
        environmentName: defaultEndpointEnvironmentName,
        workspaceName: currentWorkspace.name,
        token: uuidv4(),
      });
    },
    [createEndpoint, currentWorkspace.name, organizationId]
  );

  return (
    <PopupForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      maxWidth="xs"
      title="Deploy"
      communication={creatingEndpoint}
      {...popupStateProps}
    >
      {function FormContent({ values, setValues, setFieldValue }) {
        // todo Contents of collection are queried, but never updated
        const availableSourceIds: string[] = [];
        const { loadSourceBuilds: loadSourceBuilds__ } = useSourceBuilds({
          onCompleted: (sourceBuilds) => {
            setValues(setSourceBuilds(sourceBuilds, values));
          },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const loadSourceBuild = useCallback(
          debounce(
            withCancelPreviousQuery(
              (context) => async (variables: LoadSourceBuildsSettings) => {
                if (variables.sourceId) {
                  await loadSourceBuilds__({
                    variables,
                    context,
                  });
                }
              }
            ),
            400
          ),
          [loadSourceBuilds__]
        );
        return (
          <>
            <TextInputField
              name={getFieldName({ path: null })}
              validate={validateIsUrlPath}
              label="Path"
              hint="Edit input above for custom endpoint path"
            />
            {(() => {
              const options = handleUnionCases<
                BuildSource,
                IOptionType<BuildSource>
              >()([
                [
                  'experimentRun',
                  (value) => ({ label: 'Experiment run', value }),
                ],
                [
                  'modelVersion',
                  (value) => ({ label: 'Model version', value }),
                ],
                ['noModel', (value) => ({ label: 'No model', value })],
              ]);
              return (
                <SelectField
                  label={'Source'}
                  name={getFieldName({ source: null })}
                  options={options}
                  onChange={() => {
                    setFieldValue(getFieldName({ sourceId: null }), '');
                  }}
                />
              );
            })()}
            <SourceIdField
              registeredModelId={registeredModelId}
              source={values.source}
              value={values.sourceId || ''}
              sourceIds={availableSourceIds}
              onChange={(sourceId) => {
                setValues(changeSourceId(sourceId, values));
                if (sourceId && values.source !== 'noModel') {
                  loadSourceBuild({
                    sourceId,
                    source: values.source,
                    workspaceName: currentWorkspace.name,
                    organizationId,
                  });
                }
              }}
            />
            <TextInputField
              name={getFieldName({ description: null })}
              validate={validateDescription}
              label="Model description"
              hint="Max. 250 characters"
            />
            <TagsField name={getFieldName({ labels: null })} />

            <EnableTokensField name={getFieldName({ tokensEnabled: null })} />
            {isEnableExpandedEndpointPermissions ? (
              <SwitchField
                name={getFieldName({ authzPredictionEnabled: null })}
                iconSize="lg"
                label={'Predict permissions'}
                info={
                  'Enabling access tokens will allow users with predict permission to make prediction using their Verta credentials.'
                }
              />
            ) : null}
            {isEnableKafka ? (
              <DefaultMatchRemoteData
                communication={communication}
                data={data}
                context={'preparing for create endpoint'}
              >
                {(configurations) => (
                  <EndpointKafkaFormFields
                    initialValues={initialValues}
                    kafkaConfigurations={configurations}
                  />
                )}
              </DefaultMatchRemoteData>
            ) : null}
          </>
        );
      }}
    </PopupForm>
  );
};

const EnableTokensField: FC<React.PropsWithChildren<{ name: string }>> = ({
  name,
}) => {
  return (
    <DefaultConfirmAction
      type={ConfirmType.disable}
      description="access tokens"
      additionalText="Disabling access tokens will make the endpoint accept all connections"
    >
      {(withConfirm) => (
        <Field name={name}>
          {({ field, form }: FieldProps<boolean>) => (
            <SwitchField
              label="Enable access tokens"
              name={name}
              iconSize="lg"
              checked={field.value}
              onClick={(event) => {
                const target = event.target as HTMLFormElement;
                if (target.value) {
                  form.setFieldValue(name, target.value);
                } else {
                  withConfirm((v) => form.setFieldValue(name, v))(target.value);
                }
              }}
              info={
                'Enabling access tokens allows making predictions using access tokens.'
              }
            />
          )}
        </Field>
      )}
    </DefaultConfirmAction>
  );
};

const SourceIdField = ({
  value,
  sourceIds,
  source,
  onChange,
  registeredModelId,
}: {
  source: BuildSource;
  value: string;
  sourceIds: string[];
  onChange(sourceId: string | undefined): void;
  registeredModelId?: string;
}) => {
  const options = useMemo(() => {
    return sourceIds.map((id) => ({ label: id, value: id }));
  }, [sourceIds]);
  const name = getFieldName({ sourceId: null });

  const opts: {
    validateFieldMessage: string;
    fieldPlaceholder: string;
  } | null = matchType(
    {
      experimentRun: () => ({
        validateFieldMessage: 'Field',
        fieldPlaceholder: 'Select an experiment run or enter ID',
      }),
      modelVersion: () => ({
        validateFieldMessage: 'Field',
        fieldPlaceholder: 'Select an model version id or enter ID',
      }),
      noModel: () => null,
    },
    source
  );

  return opts
    ? matchType(
        {
          experimentRun: () => (
            <AutocompleteField
              isRequired={true}
              name={name}
              validate={validateNotEmpty(opts.validateFieldMessage)}
              options={options}
              label={opts.fieldPlaceholder}
            />
          ),
          modelVersion: () => (
            <ModelVersionSource
              registeredModelId={registeredModelId}
              modelVersionId={value}
              onChange={onChange}
            />
          ),
          noModel: () => null,
        },
        source
      )
    : null;
};

function ModelVersionSource({
  registeredModelId,
  modelVersionId,
  onChange,
}: {
  registeredModelId: string | undefined;
  modelVersionId: string;
  onChange: (sourceId: string | undefined) => void;
}) {
  const [currRegisteredModelId, setCurrRegisteredModelId] =
    useState(registeredModelId);
  const organizationId = useCurrentOrganizationV2();
  return (
    <RegisteredModelWithVersionFields
      registeredModelId={currRegisteredModelId}
      changeRegisteredModelId={setCurrRegisteredModelId}
      modelVersionId={modelVersionId}
      organizationId={organizationId}
      changeModelVersionId={onChange}
    />
  );
}

export default CreateEndpointPopup;
