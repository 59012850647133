import { AxisScale, SharedAxisProps, AxisLeft } from '@visx/axis';
import { styled, useTheme } from '@mui/material';

import SvgTextWithTruncation from '../../shared/SvgTextWithTruncation/SvgTextWithTruncation';
import { tickLabelFontSize } from '../defaultStyles';

export type CustomAxisLeftProps<Scale extends AxisScale> = Pick<
  SharedAxisProps<Scale>,
  'label' | 'scale' | 'numTicks' | 'tickFormat' | 'left'
> & {
  maxCharCount?: number;
};

const Wrapper = styled('g')(({ theme }) => ({
  '.label': { fill: theme.palette.text.primary },
  '.tick': { fill: theme.palette.text.primary },
}));

const CustomAxisLeft = <Scale extends AxisScale>({
  scale,
  label,
  numTicks,
  tickFormat,
  maxCharCount,
}: CustomAxisLeftProps<Scale>) => {
  const theme = useTheme();
  const { fontFamily, fontSize, fontWeight, lineHeight } =
    theme.typography.body2;

  return (
    <Wrapper>
      <AxisLeft
        scale={scale}
        stroke={theme.palette.text.secondary}
        tickStroke={theme.palette.text.secondary}
        strokeWidth={0}
        hideTicks={true}
        numTicks={numTicks}
        tickComponent={({ formattedValue, ...props }) => (
          <SvgTextWithTruncation
            className="tick"
            fontSize={tickLabelFontSize}
            {...props}
            maxCharCount={maxCharCount ?? 10}
          >
            {formattedValue ?? ''}
          </SvgTextWithTruncation>
        )}
        labelOffset={50}
        tickFormat={tickFormat}
        label={label}
        labelClassName={'label'}
        labelProps={{
          fontFamily,
          fontSize,
          fontWeight,
          lineHeight,
          fill: theme.palette.text.secondary,
          textAnchor: 'middle',
        }}
      />
    </Wrapper>
  );
};

export default CustomAxisLeft;
