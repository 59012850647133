import InlineLink, {
  INLINE_LINK_EXTERNAL_GAP,
} from 'shared/view/elements/InlineLink/InlineLink';
import CellRendererEmpty from 'shared/view/elements/DataGrid/columns/CellRendererEmpty';

import NA from '../../PageComponents/NA/NA';

type Props = {
  to: string | null | undefined;
  label: string | undefined;
  isExternal?: boolean;
  dataTest?: string;
};

// ts-unused-exports:disable-next-line
export default function CellRendererLink(props: Props) {
  if (!props.to) {
    return <CellRendererEmpty />;
  }

  // todo paddingRight is a workaround. Find a way to avoid that
  return (
    <div
      style={{
        width: '100%',
        paddingRight: props.isExternal ? INLINE_LINK_EXTERNAL_GAP : 0,
      }}
    >
      <InlineLink
        to={props.to}
        isExternal={props.isExternal}
        dataTest={props.dataTest}
      >
        {props.label ?? NA}
      </InlineLink>
    </div>
  );
}
