import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/useUpdateGroup.generated';
import { USER_OR_SERVICE_ACCOUNT_FOR_GROUP } from './useOrganizationGroups';

export { Types };

export const QUERY = gql`
  mutation UpdateGroup(
    $organizationId: ID!
    $groupId: ID!
    $name: String!
    $description: String!
    $memberIds: [ID!]!
  ) {
    organizationV2(id: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        group(id: $groupId) {
          ... on Group {
            id
            update(
              input: {
                name: $name
                description: $description
                memberIds: $memberIds
              }
            ) {
              id
              name
              description
              members {
                ...UserOrServiceAccountForGroup
              }
            }
          }
        }
      }
    }
  }
  ${USER_OR_SERVICE_ACCOUNT_FOR_GROUP}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useUpdateGroup = (onCompleted: () => void) => {
  const [run, communication] = useCustomMutation<
    Types.UpdateGroup,
    Types.UpdateGroupVariables
  >(QUERY, { context: 'updating group' }, { onCompleted });

  return {
    run,
    communication,
  };
};
