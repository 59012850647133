import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import routes from 'shared/routes';
import { Tab } from 'shared/view/pages/PagesTabs/PagesTabs';
import isNotNil from 'shared/utils/isNotNill';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { selectFlags } from 'features/flags';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

type Props = {
  registeredModelId: string;
};

const useModelTabs = (props: Props) => {
  const workspaceName = useWorkspaceNameFromURL();
  const {
    catalog: { isEnableMLAssistance },
  } = useSelector(selectFlags);

  const { registeredModelId } = props;

  return useMemo(() => {
    return [
      {
        label: 'Overview',
        to: routes.usecasesModelOverview.getRedirectPath({
          registeredModelId,
          workspaceName,
        }),
      },
      {
        label: 'Documentation',
        icon: isEnableMLAssistance ? ICONS.sparkles : undefined,
        to: routes.usecasesModelDocumentation.getRedirectPath({
          registeredModelId,
          workspaceName,
        }),
      },
    ].filter(isNotNil) as Array<Tab>;
  }, [registeredModelId, workspaceName, isEnableMLAssistance]);
};

export default useModelTabs;
