import { fromPairs } from 'ramda';

import ParallelCoordinateChartWithGradient from 'shared/view/charts/ParallelCoordinate/ParallelCoordinateChartWithGradient/ParallelCoordinateChartWithGradient';
import {
  ExperimentRunWithDisplayedFields,
  getKeyValueBySpec,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import { ParallelCoordinateChartSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/ParallelCoordinateChartSettings';
import isNotNil from 'shared/utils/isNotNill';

interface ILocalProps {
  id: string;
  widgetSettings: ParallelCoordinateChartSettings;
  experimentRuns: ExperimentRunWithDisplayedFields[];
  width: number;
  height: number;
}

const ParallelCoordinateChartWidget = (props: ILocalProps) => {
  const data = props.experimentRuns.map((run) =>
    fromPairs(
      props.widgetSettings.yAxis.specs
        .map((spec) => getKeyValueBySpec(spec, run))
        .filter(isNotNil)
        .map(({ key, value }) => [key, value])
    )
  );
  const keys = props.widgetSettings.yAxis.specs.map(({ key }) => key);

  return (
    <ParallelCoordinateChartWithGradient
      chartId={props.id}
      formatNumber={(number) =>
        Number.isInteger(number)
          ? String(number)
          : number.toPrecision(props.widgetSettings.decimalPrecision)
      }
      width={props.width}
      height={props.height}
      keys={keys}
      data={data}
    />
  );
};

export default ParallelCoordinateChartWidget;
