import Popup, { PopupProps } from 'shared/view/elements/Popup/Popup';
import { trackEvent } from 'setup/app/analytics';
import matchType from 'shared/utils/matchType';
import {
  useOnboardingContext,
  AdvancedFeaturesContent,
} from 'features/homepage/hooks/useOnboarding';

import { AdvancedFeatures } from './AdvancedFeatures';
import { VulnerabilityScanning } from './VulnerabilityScanning/VulnerabilityScanning';
import { CustomAttributes } from './CustomAttributes/CustomAttributes';
import { AiAssistedDocs } from './AiAssistedDocs/AiAssistedDocs';
import { GovernanceChecklists } from './GovernanceChecklists/GovernanceChecklists';

export const AdvancedFeaturesPopup = (props: Partial<PopupProps>) => {
  const {
    updateOnboardingSteps,
    stepsValues,
    selectedAdvancedFeaturesContent: selectedContent,
    setSelectedAdvancedFeaturesContent: setSelectedContent,
  } = useOnboardingContext();

  const isOnDiscoverFeatures =
    selectedContent === AdvancedFeaturesContent.DISCOVER_FEATURES;

  const resetPopup = () => {
    setSelectedContent(AdvancedFeaturesContent.DISCOVER_FEATURES);
  };

  const handleClose = () => {
    trackEvent({
      type: 'homepage.discover_widget.advanced_features_abandoned',
    });
    resetPopup();
    props.onClose?.();
  };

  const onCompleted = async () => {
    await updateOnboardingSteps({ ...stepsValues, step3: true });
    trackEvent({
      type: 'homepage.discover_widget.advanced_features_completed',
    });
    resetPopup();
    props.onClose?.();
  };

  return (
    <Popup
      title={selectedContent}
      isOpen={Boolean(props.isOpen)}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      buttons={
        isOnDiscoverFeatures
          ? {
              secondaryButtonProps: {
                children: 'Cancel',
                isLoading: false,
                onClick: handleClose,
              },
            }
          : undefined
      }
      dialogContentProps={{
        height: isOnDiscoverFeatures ? 'auto' : '1065px',
      }}
    >
      {matchType(
        {
          [AdvancedFeaturesContent.DISCOVER_FEATURES]: () => (
            <AdvancedFeatures setSelectedContent={setSelectedContent} />
          ),
          [AdvancedFeaturesContent.GOVERNANCE_CHECKLISTS]: () => (
            <GovernanceChecklists onCompleted={onCompleted} />
          ),
          [AdvancedFeaturesContent.AI_ASSISTED_DOCS]: () => (
            <AiAssistedDocs onCompleted={onCompleted} />
          ),
          [AdvancedFeaturesContent.CUSTOM_ATTRIBUTES]: () => (
            <CustomAttributes onCompleted={onCompleted} />
          ),
          [AdvancedFeaturesContent.VULNERABILITY_SCANNING]: () => (
            <VulnerabilityScanning onCompleted={onCompleted} />
          ),
        },
        selectedContent
      )}
    </Popup>
  );
};
