import * as React from 'react';
import { useApolloClient, ApolloClient } from '@apollo/client';

import { PromiseValue } from 'shared/utils/types';
import {
  ICommunication,
  initialCommunication,
  requestingCommunication,
  successfulCommunication,
  makeErrorCommunication,
} from 'shared/utils/redux/communication';
import { apolloErrorFromUnknownToAppError } from 'shared/utils/graphql/apolloErrorToAppError';
import { AppError } from 'shared/models/Error';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { orgIdLink } from 'setup/apollo/createApolloLink';

export default function useQueryFromFunction<
  T extends (...args: any[]) => Promise<B>,
  B extends unknown = PromiseValue<ReturnType<T>>,
>(
  f: (apolloClient: ApolloClient<unknown>) => T,
  handlers?: {
    onCompleted: (res: B) => void;
    onError: (error: AppError) => void;
  }
): [T, ICommunication, B | undefined] {
  const apolloClient = useApolloClient();
  const orgId = useCurrentOrganizationV2();
  const handlersRef = React.useRef(handlers);
  handlersRef.current = handlers;
  const [result, setResult] = React.useState<undefined | B>(undefined);

  const [communication, setCommunication] =
    React.useState(initialCommunication);

  apolloClient.setLink(orgIdLink(orgId).concat(apolloClient.link));

  const resF = (async (...args: any[]) => {
    setCommunication(requestingCommunication);
    try {
      const res = await f(apolloClient)(...args);
      setResult(res);
      setCommunication(successfulCommunication);
      handlersRef.current?.onCompleted(res);
    } catch (e: unknown) {
      const apolloError = apolloErrorFromUnknownToAppError(e);
      setCommunication(makeErrorCommunication(apolloError));
      handlersRef.current?.onError(apolloError);
    }
  }) as T;

  return [resF, communication, result];
}
