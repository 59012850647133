/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

export const WORKSPACE_REGISTERED_MODELS = gql`
  query WorkspaceRegisteredModels(
    $workspaceName: String!
    $organizationId: ID
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id

        registeredModels(
          query: {
            sorting: { key: "time_updated", ascending: false }
            pagination: { page: 1, limit: 1 }
          }
        ) {
          models {
            id
            name
            dateUpdated
            versions {
              versions {
                id
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const WORKSPACE_REGISTERED_MODELS_SUMMARY = gql`
  query WorkspaceRegisteredModelsSummary(
    $workspaceName: String!
    $organizationId: ID
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        registeredModelVersions(query: { pagination: { page: 1, limit: 0 } }) {
          pagination {
            totalRecords
          }
        }
        registeredModels(query: { pagination: { page: 1, limit: 0 } }) {
          models {
            id
            labels
          }
          pagination {
            totalRecords
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const ORGANIZATION_USERS_COUNT = gql`
  query OrganizationUsersCount($organizationId: ID!) {
    organizationV2(id: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        users(query: { pagination: { limit: 0 } }) {
          pagination {
            totalRecords
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const WORKSPACE_ENDPOINTS_COUNT = gql`
  query WorkspaceEndpointsCount($workspaceName: String!, $organizationId: ID) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoints {
          endpoints {
            id
            environments {
              id
              status
            }
          }
        }
        registeredModelVersions(query: { pagination: { limit: 0 } }) {
          versions {
            id
            externalDeployments {
              id
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;
