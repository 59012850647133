import { Box } from '@mui/material';

import {
  isRestrictedGraphqlError,
  NotNullableRestrictedGraphqlError,
  RestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';
import { AppError } from 'shared/models/Error';
import { ICommunication } from 'shared/utils/redux/communication';
import { matchRemoteData } from 'shared/utils/redux/communication/remoteData';
import { OmitStrict } from 'shared/utils/types';
import InlineCommunicationError from 'shared/view/elements/Errors/InlineCommunicationError/InlineCommunicationError';
import Preloader from 'shared/view/elements/Preloader/Preloader';

interface ILocalProps<Communication extends ICommunication<AppError>, Data> {
  communication: Communication;
  data: Data;
  context: string;
  ErrorComponent?: (props: {
    error: AppError;
    context: string;
  }) => JSX.Element | null;
  LoadingComponent?: React.ReactNode;
  NotAskedComponent?: React.ReactNode;
  children: (success: NonNullable<Data>) => JSX.Element | null;
}

const DefaultLoadingComponent = (
  <Box width="100%">
    <Box display="flex" justifyContent="center" pt={3} pb={3}>
      <Preloader />
    </Box>
  </Box>
);

const DefaultNotAskedComponent = (
  <Box width="100%">
    <Box display="flex" justifyContent="center" pt={3} pb={3}>
      <Preloader />
    </Box>
  </Box>
);

const DefaultMatchRemoteData = <
  Communication extends ICommunication<AppError>,
  Data,
>({
  communication,
  data,
  context,
  children,
  ErrorComponent = InlineCommunicationError,
  LoadingComponent = DefaultLoadingComponent,
  NotAskedComponent = DefaultNotAskedComponent,
}: ILocalProps<Communication, Data>) => {
  return matchRemoteData(communication, data, {
    notAsked: () => <>{NotAskedComponent}</>,
    requesting: () => <>{LoadingComponent}</>,
    error: ({ error }) => (
      <Box width="100%">
        <ErrorComponent error={error} context={context} />
      </Box>
    ),
    success: (loadedData) => children(loadedData),
  });
};

export const DefaultMatchRemoteDataOrError = <
  Communication extends ICommunication<AppError>,
  Data,
>(
  props: OmitStrict<ILocalProps<Communication, Data>, 'data' | 'children'> & {
    data: Data | RestrictedGraphqlError;
    children: (
      success: NotNullableRestrictedGraphqlError<Data>
    ) => JSX.Element | null;
  }
) => {
  return (
    <DefaultMatchRemoteData {...props}>
      {(loadedData) => {
        if (isRestrictedGraphqlError(loadedData)) {
          return null;
        }
        return props.children(
          loadedData as NotNullableRestrictedGraphqlError<Data>
        );
      }}
    </DefaultMatchRemoteData>
  );
};

export default DefaultMatchRemoteData;
