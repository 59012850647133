import { FC } from 'react';
import { FieldArray, useField } from 'formik';
import Stack from '@mui/material/Stack';

import { IEnvironmentVariable } from 'shared/models/Deployment/canary/EndpointMachineConfiguration';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import Button from 'shared/view/elements/Button/Button';
import { componentsSizes } from 'shared/mui/themes/CommonMuiThemeOptions';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import { MachineConfigSettings } from '../settings';
import { getMachineConfigSettingsFieldName } from '../settings/getMachineConfigSettingsFieldName';

const EnvSection: FC<React.PropsWithChildren<unknown>> = () => {
  const [{ value: env }] = useField<MachineConfigSettings['env']>(
    getMachineConfigSettingsFieldName({ env: null })
  );
  return (
    <FieldArray
      name={getMachineConfigSettingsFieldName({
        env: null,
      })}
    >
      {({ push, remove }) => (
        <FormStack>
          <>
            {env.map((_, i) => (
              <EnvironmentVariableField key={i} index={i} remove={remove} />
            ))}
          </>
          <Button
            variant={'text'}
            onClick={() => {
              const newVariable: IEnvironmentVariable = {
                name: '',
                value: '',
              };
              push(newVariable);
            }}
            isLoading={false}
            dataTest="add-env-variable"
          >
            + Add variable
          </Button>
        </FormStack>
      )}
    </FieldArray>
  );
};

export const EnvironmentVariableField: FC<
  React.PropsWithChildren<{
    index: number;
    remove: (index: number) => void;
  }>
> = ({ index, remove }) => {
  return (
    <>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <span
          style={{ width: '100%', maxWidth: componentsSizes.input.maxWidth }}
        >
          <TextInputField
            label="MY_VARIABLE_NAME"
            name={getMachineConfigSettingsFieldName({
              env: {
                [index]: {
                  name: null,
                },
              },
            })}
            endAdornmentIcons={[
              <IconAwesomeClickable
                key={0}
                icon={ICONS.trashCan}
                onClick={() => remove(index)}
                dataTest="remove-env-variable"
              />,
            ]}
          />
        </span>
      </Stack>
      <TextInputField
        label="MY_VARIABLE_VALUE"
        name={getMachineConfigSettingsFieldName({
          env: {
            [index]: {
              value: null,
            },
          },
        })}
      />
    </>
  );
};

export default EnvSection;
