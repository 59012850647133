import { useMemo } from 'react';

import { ExperimentRunWithDisplayedFields } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import Placeholder from 'shared/view/elements/Placeholder/Placeholder';

import ObservationCharts from '../../Charts/ObservationCharts/ObservationCharts';
import { convertChartExperimentRun } from '../../Charts/ObservationCharts/utils/converters';
import { ChartExperimentRun } from '../../Charts/ObservationCharts/utils/types';

interface Props {
  id: string;
  width: number;
  height: number;
  experimentRuns: ExperimentRunWithDisplayedFields[];
}

const ObservationChartsWidget = (props: Props) => {
  const chartExperimentRuns = useMemo(
    (): ChartExperimentRun[] =>
      props.experimentRuns.map(convertChartExperimentRun),
    [props.experimentRuns]
  );

  return (
    <div style={{ width: '100%', padding: '5px', overflow: 'auto' }}>
      <ObservationCharts
        experimentRuns={chartExperimentRuns}
        renderNilContent={() => (
          <Placeholder>Experiment runs don't have any observations</Placeholder>
        )}
      />
    </div>
  );
};

export default ObservationChartsWidget;
