import Stack from '@mui/material/Stack';

import DevKey from 'features/developerKeysManager/view/DevKey/DevKey';
import Button from 'shared/view/elements/Button/Button';
import noop from 'shared/utils/noop';

import useDevKeysManager from '../useDevKeysManager';

interface Props {
  userId: string;
  primaryKey: string;
  secondaryKey: string | undefined;
  organizationId: string;
  onChangeDevKeys?(keys: {
    primaryKey: string;
    secondaryKey: string | undefined;
  }): void;
}

export default function DevKeysManager(props: Props) {
  const {
    data: {
      addingSecondaryDevKey,
      onAddSecondaryDevKey,
      secondaryDevKey,
      primaryDevKey,
    },
  } = useDevKeysManager({
    ...props,
    onChangeDevKeys: props.onChangeDevKeys || noop,
  });

  return (
    <Stack direction="column">
      <DevKey
        type="primary"
        developerKey={primaryDevKey.value}
        isDeleting={primaryDevKey.deleting.isRequesting}
        onDelete={primaryDevKey.onDelete}
        disabled={primaryDevKey.deleteDisabled}
      />
      {secondaryDevKey && (
        <DevKey
          type="secondary"
          developerKey={secondaryDevKey.value}
          isDeleting={secondaryDevKey.deleting.isRequesting}
          onDelete={secondaryDevKey.onDelete}
        />
      )}
      <div>
        <Button
          variant="outlined"
          disabled={onAddSecondaryDevKey === undefined}
          isLoading={addingSecondaryDevKey.isRequesting}
          onClick={onAddSecondaryDevKey ?? noop}
          dataTest="add-developer-key"
        >
          Add Key
        </Button>
      </div>
    </Stack>
  );
}
