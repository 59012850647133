import { useState } from 'react';
import { AxiosProgressEvent } from 'axios';

import ArtifactDataService from 'services/artifact/ArtifactDataService';
import { useLazyRestQuery } from 'shared/utils/react/useRequest';
import { AppError } from 'shared/models/Error';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

export interface UploadArtifactProps {
  entityId: string;
  key: string;
  artifact: File;
}

const controller = new AbortController();

const cancelRequest = () => controller.abort();

type Props = {
  onSuccess?: (data: void, requestArgs: UploadArtifactProps[]) => void;
  onError?: (appError: AppError) => void;
};

const useUploadArtifact = (options?: Props) => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const organizationId = useCurrentOrganizationV2();

  const onUploadProgress = (props: AxiosProgressEvent) => {
    const { loaded, total = 1 } = props;
    return setUploadProgress(Math.trunc((loaded * 100) / total));
  };

  const { communication: uploadingArtifact, fetch: uploadArtifact } =
    useLazyRestQuery(
      async ({ entityId, artifact, key }: UploadArtifactProps) => {
        await ArtifactDataService.uploadArtifact(
          entityId,
          artifact,
          key,
          controller,
          onUploadProgress,
          organizationId || ''
        );
      },
      { onSuccess: options?.onSuccess, onError: options?.onError }
    );
  return {
    uploadingArtifact,
    uploadArtifact,
    cancelRequest,
    uploadProgress,
  };
};

export default useUploadArtifact;
