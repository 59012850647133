import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import Popup from 'shared/view/elements/Popup/Popup';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import { useUpdateEffect } from 'shared/view/hooks/useUpdateEffect';
import { OwnerType, RegisteredModelVersionInput } from 'generated/types';
import { useUpdateModelVersion } from 'features/catalog/shared/store/useUpdateModelVersion/useUpdateModelVersion';
import { RequiredOwner } from 'features/editResourceInformation/store/useEditResourceInformation';
import OwnerSelector from 'features/editResourceInformation/view/EditResourceInformation/OwnerSelector';
import { trackEvent } from 'setup/app/analytics';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { RegisteredModelVersion_Edited } from 'setup/app/analytics/types/registeredModelVersion';

import { ModelVersionSettings } from '../../store/graphql-types/useModelVersionPageSettings.generated';

interface Props {
  isOpen: boolean;
  version: ModelVersionSettings;
  isEditable: boolean;
  onClose(): void;
}

type ModelVersionInput = {
  version: NonNullable<RegisteredModelVersionInput['version']>;
  description: NonNullable<RegisteredModelVersionInput['description']>;
  ownerType: RequiredOwner['__typename'];
  ownerId: string;
};

const getEditModelVersionInfoDataForSegmentEvent = (
  oldValues: ModelVersionSettings,
  newValues: ModelVersionInput,
  organizationId?: string
): RegisteredModelVersion_Edited['data'] => {
  const data: RegisteredModelVersion_Edited['data'] = {
    organizationId,
    name: newValues.version,
    id: oldValues.id,
    changed: {},
  };

  if (oldValues.version !== newValues.version) data.changed.name = true;
  if (oldValues.description !== newValues.description)
    data.changed.description = true;
  if (oldValues.owner.id !== newValues.ownerId) data.changed.ownership = true;

  return data;
};

const ModelVersionEditor = (props: Props) => {
  const { isOpen, onClose, isEditable, version } = props;
  const { description, version: versionName, id, owner } = version;
  const organizationId = useCurrentOrganizationV2();
  const [requested, setRequested] = useState(false);
  const [internalState, setInternalState] = useState<ModelVersionInput>({
    version: versionName,
    description,
    ownerId: owner.id,
    ownerType: owner.__typename,
  });
  const [updateModelVersion, communication] = useUpdateModelVersion();

  const handleUpdate = (newState: ModelVersionInput) => {
    setInternalState(newState);
  };

  const onConfirm = () => {
    setRequested(true);
    const { ownerId, ownerType, ...rest } = internalState;

    trackEvent({
      type: 'catalog.registered_model_version.edited',
      data: getEditModelVersionInfoDataForSegmentEvent(
        version,
        internalState,
        organizationId
      ),
    });

    updateModelVersion({
      id,
      fields: {
        ...rest,
        owner: {
          id: ownerId,
          type: ownerType as OwnerType,
        },
      },
    });
  };

  const onCancel = () => {
    setRequested(false);
    setInternalState({
      version: versionName,
      description,
      ownerId: owner.id,
      ownerType: owner.__typename,
    });
    onClose();
  };

  useUpdateEffect(() => {
    if (requested && communication.isSuccess) {
      onCancel();
    }
  }, [isOpen, requested, communication.isSuccess]);

  return (
    <Popup
      title="Edit version information"
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      dataTest="model-version-editor-popup"
      buttons={{
        mainButtonProps: {
          disabled: !isEditable,
          type: 'button',
          dataTest: 'confirm',
          children: 'Save',
          onClick: onConfirm,
          isLoading: Boolean(communication.isRequesting),
        },
        secondaryButtonProps: {
          dataTest: 'confirm-cancel-button',
          children: 'Cancel',
          onClick: onCancel,
          isLoading: false,
        },
      }}
    >
      <Stack spacing={3} pt={1}>
        <Box width="100%">
          <TextInput
            disabled={!isEditable}
            maxWidth="100%"
            hint="Max. 250 characters"
            isRequired
            value={internalState.version}
            meta={{
              touched: true,
              error:
                internalState.version.length >= 250
                  ? 'maximum character limit reached'
                  : undefined,
            }}
            label="Version name"
            onChange={(value) =>
              handleUpdate({ ...internalState, version: value })
            }
            name="model-version-editor-name-input"
            data-test="model-version-editor-name-input"
            withoutError={internalState.version.length < 250}
          />
        </Box>
        <Box width="100%">
          <TextInput
            disabled={!isEditable}
            maxWidth="100%"
            rows={5}
            hint="Max. 250 characters"
            value={internalState.description}
            multiline
            meta={{
              touched: true,
              error:
                internalState.description.length >= 250
                  ? 'maximum character limit reached'
                  : undefined,
            }}
            label="Version description"
            onChange={(value) =>
              handleUpdate({ ...internalState, description: value })
            }
            name="model-version-editor-description-input"
            data-test="model-version-editor-description-input"
          />
        </Box>
        <OwnerSelector
          ownerId={internalState.ownerId}
          ownerType={internalState.ownerType}
          onChangeOwnerId={(ownerId) =>
            handleUpdate({
              ...internalState,
              ownerId,
            })
          }
          onChangeOwnerType={(ownerType) =>
            handleUpdate({
              ...internalState,
              ownerType,
            })
          }
          resource={{
            ...props.version,
            id: props.version.registeredModel.id,
            allowedActions: props.version.registeredModel.allowedActions,
          }}
          disableGroups
        />
      </Stack>
    </Popup>
  );
};

export default ModelVersionEditor;
