import { ICanaryRule } from 'features/deployment/canary/endpoints/store/endpointMonitoring/endpointMonitoring';
import { Endpoint as EndpointType } from 'features/deployment/canary/endpoints/store/endpointQuery/graphql-types/endpointQuery.generated';
import {
  EndpointEnvironmentUpdateStrategy,
  CanaryRuleParametersType,
} from 'generated/types';
import { CanaryRule } from 'generated/types';
import { formatCanaryRuleInterval } from 'shared/models/Deployment/canary/CanaryRules/CanaryRuleInterval';
import { getRuleParameterValueFormater } from 'shared/models/Deployment/canary/CanaryRules/CanaryRuleParameter';
import { formatCanaryRuleStep } from 'shared/models/Deployment/canary/CanaryRules/CanaryRuleStep';
import isNotNil from 'shared/utils/isNotNill';
import { ExtractByTypename } from 'shared/utils/types';

type Workspace = ExtractByTypename<EndpointType['workspace'], 'Workspace'>;
type Endpoint = ExtractByTypename<Workspace['endpoint'], 'Endpoint'>;

export interface CanaryStrategyForm {
  updateStrategyType: EndpointEnvironmentUpdateStrategy.CANARY;
  interval: string;
  step: string;
  selectedRules: SelectedCanaryRule[];
}

export type SelectedCanaryRule = {
  id: string; // we have to have id for unique key
  canonicalRule?: CanonicalCanaryRuleData;
  parameters?: SelectedCanaryRuleParameter[];
};

export type CanonicalCanaryRuleData = Pick<
  CanaryRule,
  'id' | 'name' | 'description' | 'parameters'
>;
export type SelectedCanaryRuleParameter = {
  name: string;
  value: string;
  type: CanaryRuleParametersType;
  description: string;
};

export const makeEmptyCanaryRule = (
  selectedRuleId: string
): SelectedCanaryRule => ({
  id: selectedRuleId,
  canonicalRule: undefined,
  parameters: undefined,
});

export function getInitialCanaryStrategyForm(
  canaryStrategy: NonNullable<
    NonNullable<Endpoint['environments'][0]['updateRequest']>['canaryStrategy']
  >,
  canonicalCanaryRules: ICanaryRule[] | null
): CanaryStrategyForm {
  return {
    updateStrategyType: EndpointEnvironmentUpdateStrategy.CANARY,
    interval: formatCanaryRuleInterval(canaryStrategy.progressInterval),
    step: formatCanaryRuleStep(canaryStrategy.progressStep),
    selectedRules: canaryStrategy.rules
      .map((rule) => {
        const canonicalRule = canonicalCanaryRules?.find(
          (r) => r.id === rule.id
        );
        return canonicalRule
          ? {
              id: rule.id,
              canonicalRule,
              parameters: rule.parameters
                .map((param) => {
                  const findedParameter = canonicalRule.parameters.find(
                    (p) => p.name === param.name
                  );
                  return findedParameter
                    ? {
                        name: findedParameter.name,
                        value: getRuleParameterValueFormater({
                          value: param.value,
                          type: findedParameter.type,
                        })(param.value),
                        type: findedParameter.type,
                        description: findedParameter.description,
                      }
                    : undefined;
                })
                .filter(isNotNil),
            }
          : undefined;
      })
      .filter(isNotNil),
  };
}
