import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import customAttrCreate from '../images/custom-attribute-create.png';

export const CustomAttrList = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Create custom attributes
    </Typography>
    <Typography variant="body2" mb={3}>
      Attributes can be stored as fully customizable lists of up to 10 items,
      user and group selectors, form input of short or long text, or numeric
      values.
    </Typography>
    <img
      src={customAttrCreate}
      alt="custom attributes create"
      width="90%"
      height="auto"
    />
  </WizardStepContent>
);
