import Select from 'shared/view/elements/Selects/Select/Select';
import { TaskType } from 'generated/types';
import capitalize from 'shared/utils/capitalize';
import { FilterType } from 'features/catalog/registeredModel/catalogList/hooks/useFilter';
import { CLEAR_VALUE } from 'features/catalog/registeredModel/catalogList/hooks/useFilter';

type OptionProps = {
  label: string;
  value: string;
};

type Props = {
  value: string | undefined;
  includeAll?: boolean;
  disabled?: boolean;
  onChange: (p: any) => void;
  label?: string;
};

export const TaskTypeSelector = (props: Props) => {
  const { value, onChange, includeAll, ...other } = props;

  const taskTypeOptions = [];
  if (includeAll)
    taskTypeOptions.push({ label: 'All task types', value: CLEAR_VALUE });

  Object.keys(TaskType)
    .filter((t) => t !== TaskType.UNKNOWN)
    .forEach((t) =>
      taskTypeOptions.push({
        label: t === TaskType.OTHER ? 'Other task' : capitalize(t),
        value: t,
      })
    );

  const handleChange = (option: OptionProps) => {
    onChange({ ...option, type: FilterType.TASK_TYPE, value: option.value });
  };

  return (
    <Select
      {...other}
      label={props.label ?? 'Task type'}
      value={taskTypeOptions.find((o) => o.value === value)}
      options={taskTypeOptions}
      dataTest="task-type-select"
      name="task-type-select"
      onChange={handleChange}
      withoutError
    />
  );
};
