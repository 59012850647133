/* eslint-disable @typescript-eslint/no-unnecessary-condition */
export const suggestDesktopUsage = () => {
  const isMobile =
    window.matchMedia('(max-width: 768px)')?.matches &&
    navigator.maxTouchPoints > 0;

  if (isMobile) {
    confirm(
      `You are accessing the Verta platform from a mobile device. Verta is best experienced on desktop. Would you like to continue?`
    );
  }
};
