import { gql } from '@apollo/client';

import { CODE_VERSION_FRAGMENT } from './CodeVersion';

export const CODE_VERSION_BLOB_FRAGMENT = gql`
  fragment CodeVersionBlob on CodeVersionBlob {
    codeVersion {
      ...CodeVersion
    }
    key
  }
  ${CODE_VERSION_FRAGMENT}
`;
