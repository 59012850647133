import { IApplicationState } from 'setup/store/store';

import * as Types from './types';

export const selectState = (
  state: IApplicationState
): Types.IDeployedExperimentRunPlaygroundState =>
  state.deployedExperimentRunPlayground;

export const selectCommunications = (state: IApplicationState) =>
  selectState(state).communications;

export const selectDeployedExperimentRunInfo = (state: IApplicationState) =>
  selectState(state).data.deployedExperimentRunInfo;

export const selectOutput = (state: IApplicationState) =>
  selectState(state).data.output;
