import { identity } from 'ramda';

import { IKeyValuePair } from 'shared/models/KeyValuePair';
import isNotNil from 'shared/utils/isNotNill';
import toPlural from 'shared/utils/toPlural';
import { RecordFromUnion, RecordValues } from 'shared/utils/types';

export type KeyValueSpec<
  ValueType extends KeyValueDataType = KeyValueDataType,
  Subtype extends KeyValueType = KeyValueType,
> = {
  key: string;
  type: 'keyValue';
  subtype: Subtype;
  valueType: ValueType;
};

export type KeyValueDataType = 'number' | 'string';
export type KeyValueDataRealType = RecordValues<RealTypeByKeyValueDataType>;
type RealTypeByKeyValueDataType = RecordFromUnion<
  KeyValueDataType,
  {
    number: number;
    string: string;
  }
>;

export type KeyValueType = 'metric' | 'attribute' | 'hyperparameter';
export const keyValueTypeToPluralForm = (type: KeyValueType) => toPlural(type);

export type KeyValueSpecWithValue<T extends KeyValueType> = KeyValueSpec<
  KeyValueDataType,
  T
> & {
  value: KeyValueDataRealType;
};

export const filterKeyValueSpecsByValueType = <T extends KeyValueDataType>(
  valueType: T,
  specs: KeyValueSpec[]
): KeyValueSpec<T>[] =>
  specs.filter((spec): spec is KeyValueSpec<T> => spec.valueType === valueType);

export const filterKeyValueSpecsByType = <
  T extends KeyValueType,
  I extends KeyValueDataType,
>(
  type: T,
  specs: KeyValueSpec<I>[]
): KeyValueSpec<I, T>[] =>
  specs.filter((spec): spec is KeyValueSpec<I, T> => spec.subtype === type);

export const checkValueTypeOfKeyValuePair = (
  keyValue: IKeyValuePair<unknown>
) => {
  const parsers = identity<{
    [K in KeyValueDataType]: (
      value: unknown
    ) => { value: RealTypeByKeyValueDataType[K]; valueType: K } | undefined;
  }>({
    number: (value) => {
      if (typeof value === 'number') {
        return { value, valueType: 'number' as const };
      }
    },
    string: (value) => {
      if (typeof value === 'string') {
        return { value, valueType: 'string' as const };
      }
    },
  });
  return Object.values(parsers)
    .map((p) => p(keyValue.value))
    .find(isNotNil);
};
