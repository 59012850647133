import { ReactNode } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import DialogContent from '@mui/material/DialogContent';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import DialogActions from '@mui/material/DialogActions';

import PopupButtons from 'shared/view/elements/Popup/PopupButtons';

import WidgetFormPopupContentContainer from './WidgetFormPopupContentContainer/WidgetFormPopupContentContainer';

type SubmitProps = { onClick(): void; isLoading: boolean };

type Props = {
  widgetPreview: ReactNode;
  children: ReactNode;
  onReset: () => void;
  onBack?: () => void;
  submitText: string;
  isValid: boolean;
  submitButtonProps: SubmitProps;
};

const WidgetFormPopupContent = (props: Props) => {
  return (
    <>
      <DialogContent>
        <WidgetFormPopupContentContainer widgetPreview={props.widgetPreview}>
          {props.children}
        </WidgetFormPopupContentContainer>
      </DialogContent>
      <DialogActions>
        <PopupButtons
          mainButtonProps={{
            type: 'button',
            isLoading: props.submitButtonProps.isLoading,
            onClick: props.submitButtonProps.onClick,
            children: props.submitText,
            disabled: !props.isValid,
            dataTest: 'submit',
          }}
          secondaryButtonProps={{
            onClick: props.onReset,
            children: 'Reset',
            isLoading: false,
          }}
          tertiaryButtonProps={
            props.onBack
              ? {
                  onClick: props.onBack,
                  children: 'Back',
                  isLoading: false,
                }
              : undefined
          }
        />
      </DialogActions>
    </>
  );
};

export default WidgetFormPopupContent;
