import React from 'react';
import { range } from 'ramda';
import Slider from '@mui/material/Slider';
import { Field, FieldProps } from 'formik';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { selectFlags } from 'features/flags';
import { cpuInfo } from 'shared/models/Deployment/canary/EndpointMachineConfiguration/Resources';
import NumberInput from 'shared/view/elements/TextInput/NumberInput';
import { getValueOrDefault } from 'shared/utils/isNotNill';
import { withDefault } from 'shared/utils/result';

import { getMachineConfigSettingsFieldName } from '../../settings/getMachineConfigSettingsFieldName';

const CpuField: React.FC<
  React.PropsWithChildren<{
    disabled: boolean;
  }>
> = ({ disabled }) => {
  const {
    deployment: { maxCpuValue },
  } = useSelector(selectFlags);
  const name = getMachineConfigSettingsFieldName({ resources: { cpu: null } });

  const MARKS = range(
    cpuInfo.min,
    getValueOrDefault(cpuInfo.max, maxCpuValue) + 1
  ).map((n) => ({
    value: n,
    label: n,
  }));

  return (
    <Field
      name={name}
      validate={(value: number) =>
        cpuInfo.validate(
          value,
          withDefault(undefined, cpuInfo.format(maxCpuValue))
        )
      }
    >
      {({ meta, field, form }: FieldProps<number>) => (
        <Stack
          direction={'row'}
          spacing={2}
          sx={{ width: '100%' }}
          alignItems={'flex-start'}
        >
          <Box sx={{ width: 150 }}>
            <NumberInput
              label="Cores"
              name={name}
              onChange={(newValue) => form.setFieldValue(name, newValue)}
              onBlur={field.onBlur}
              meta={meta}
              value={meta.value}
              min={cpuInfo.min}
              max={withDefault(cpuInfo.max, cpuInfo.format(maxCpuValue))}
              step={1}
              disabled={disabled}
            />
          </Box>
          <Slider
            disabled={disabled}
            value={Number(meta.value)}
            min={cpuInfo.min}
            max={withDefault(cpuInfo.max, cpuInfo.format(maxCpuValue))}
            step={1}
            valueLabelDisplay="auto"
            marks={MARKS}
            onChange={(_, newValue) => {
              if (Array.isArray(newValue)) {
                form.setFieldValue(name, newValue[0]);
              } else {
                form.setFieldValue(name, newValue);
              }
            }}
          />
        </Stack>
      )}
    </Field>
  );
};

export default CpuField;
