import { useMemo } from 'react';
import MUIPagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import Select from 'shared/view/elements/Selects/Select/Select';
import { INITIAL_PAGINATION_PAGE, IPagination } from 'shared/models/Pagination';
import { OmitStrict } from 'shared/utils/types';

// used in storybook
// ts-unused-exports:disable-next-line
export type PaginationProps = OmitStrict<IPagination, 'currentPage'> & {
  page: number;
  onCurrentPageChange(newPage: number): void;
  onCurrentPageSizeChange(newPage: number): void;
  pageSizeOptions?: { label: string; value: number }[];
  disabled?: boolean;
};

const DEFAULT_PAGE_SIZE_OPTIONS = [
  { label: '12 items', value: 12 },
  { label: '24 items', value: 24 },
  { label: '48 items', value: 48 },
];

const Pagination = (props: PaginationProps) => {
  const {
    pageSizeOptions,
    page,
    pageSize,
    totalCount,
    onCurrentPageChange,
    onCurrentPageSizeChange,
    disabled,
  } = props;
  const pageSizeOpts = useMemo(() => {
    return pageSizeOptions || DEFAULT_PAGE_SIZE_OPTIONS;
  }, [pageSizeOptions]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      pl={3}
    >
      <Box width="110px">
        <Select
          label="View"
          value={pageSizeOpts.find((o) => o.value === pageSize)}
          options={pageSizeOpts}
          dataTest="page-size-select"
          disabled={disabled || totalCount === 0}
          onChange={(option) => {
            onCurrentPageSizeChange(option.value);
            onCurrentPageChange(0);
          }}
          withoutError
        />
      </Box>

      <MUIPagination
        defaultPage={INITIAL_PAGINATION_PAGE}
        page={page + 1}
        count={Math.ceil(totalCount / pageSize)}
        size="small"
        onChange={(_event: React.ChangeEvent<unknown>, value: number) => {
          onCurrentPageChange(value - 1);
        }}
      />
    </Stack>
  );
};

export const getPaginationProps = (props: {
  pagination?: IPagination;
  onCurrentPageChange?(newPage: number): void;
  onCurrentPageSizeChange?(newPage: number): void;
}) => {
  if (
    props.pagination &&
    props.onCurrentPageChange &&
    props.onCurrentPageSizeChange
  ) {
    return {
      page: props.pagination.currentPage,
      onCurrentPageChange: props.onCurrentPageChange,
      onCurrentPageSizeChange: props.onCurrentPageSizeChange,
      pageSize: props.pagination.pageSize,
      totalCount: props.pagination.totalCount,
      pagination: true as const,
    };
  }
  return {
    pagination: false as const,
    page: 0,
    onCurrentPageChange: undefined,
    onCurrentPageSizeChange: undefined,
    pageSize: 0,
    totalCount: 0,
  };
};

export default Pagination;
