import { AccessTokensManager } from 'features/deployment/canary/accessTokensManager';
import EntitySettingsContent from 'pages/authorized/shared/EntitySettingsContent/EntitySettingsContent';
import { EndpointSettings } from 'features/deployment/canary/endpoints/store/endpointSettings/graphql-types/endpointSettings.generated';
import { ExtractByTypename } from 'shared/utils/types';

type Workspace = ExtractByTypename<EndpointSettings['workspace'], 'Workspace'>;

export default function EndpointPermissionsPageContent({
  endpoint,
}: {
  endpoint: ExtractByTypename<Workspace['endpoint'], 'Endpoint'>;
}) {
  return (
    <EntitySettingsContent
      additionalSections={[
        {
          title: 'Access tokens',
          content: <AccessTokensManager key="tokens" endpoint={endpoint} />,
        },
      ]}
    />
  );
}
