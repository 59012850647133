import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { useUsersList } from 'features/organizations/organizations/store/useUsersList';
import Users from 'features/organizations/organizations/view/Users';
import routes from 'shared/routes';

import SystemAdminPagesLayout from '../shared/SystemAdminPagesLayout/SystemAdminPagesLayout';

export const SystemAdminUsersPage = () => {
  const breadcrumbs = useAppBreadcrumbs(
    useBreadcrumbItem({
      name: 'System admin',
      route: routes.systemAdmin.$.users,
    })
  );
  const usersApi = useUsersList({ search: '' });

  return (
    <SystemAdminPagesLayout breadcrumbs={breadcrumbs}>
      <Users {...usersApi} />
    </SystemAdminPagesLayout>
  );
};
