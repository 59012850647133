import { URLWithPagination } from 'shared/models/Pagination/PaginationInURL';
import {
  param,
  queryParams,
  route,
  zeroOrMoreSegmentsParam,
} from 'shared/routes/shared/routesTreeBuilder';

export const privateRoutes = route('evaluations', {
  tag: 'evaluations',
  queryParams: queryParams<URLWithPagination>(),
})(
  route('create', {
    tag: 'createEvaluation',
  })(),
  route('evaluation-projects', {
    tag: 'evaluationProjects',
  })(),
  route(
    'compare',
    undefined
  )(
    zeroOrMoreSegmentsParam()('ids', {
      tag: 'compareEvaluations',
    })()
  ),
  param()('evaluationId', {
    tag: 'evaluation',
  })(
    route('summary', {
      tag: 'evaluationSummary',
    })()
  )
);
