import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { ExtractByTypename } from 'shared/utils/types';
import { namedOperations } from 'generated/named-operations';

import * as Types from '../graphql/graphql-types/queries.generated';
import { CREATE_EVALUATION_PROJECT } from '../graphql';

type ResponseModel = ExtractByTypename<
  Types.CreateEvaluationProject['workspace'],
  'Workspace'
>['createRegisteredModel'];

export const useCreateEvaluationProject = ({
  onSuccess,
}: {
  onSuccess: (registeredModel: ResponseModel) => void;
}) => {
  const [createEvaluationProject, creatingEvaluationProject] =
    useCustomMutation<
      Types.CreateEvaluationProject,
      Types.CreateEvaluationProjectVariables
    >(
      CREATE_EVALUATION_PROJECT,
      { context: 'creating registered model' },
      {
        onCompleted: (_res) =>
          isNotNullableRestrictedGraphqlError(_res.workspace) &&
          onSuccess(_res.workspace.createRegisteredModel),
        refetchQueries: [namedOperations.Query.EvaluationProjectsList],
      }
    );

  return {
    createEvaluationProject,
    creatingEvaluationProject,
  };
};
