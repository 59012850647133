/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import {
  isNotNullableRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { IWorkspace } from 'shared/models/Workspace';
import onCompletedUpdate from 'shared/utils/graphql/onCompletedUpdate';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as GraphqlTypes from './graphql-types/datasetCreation.generated';

const CREATE_DATASET = gql`
  mutation CreateDataset(
    $workspaceName: String!
    $organizationId: ID
    $name: String!
    $tags: [String!]!
    $isTagsEmpty: Boolean!
    $description: String!
    $isDescriptionEmpty: Boolean!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        createDataset(name: $name) {
          id
          name
          addTags(tags: $tags) @skip(if: $isTagsEmpty) {
            id
            tags
          }
          changeDescription(description: $description)
            @skip(if: $isDescriptionEmpty) {
            id
            description
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export interface IDatasetCreationSettings {
  name: string;
  workspace: IWorkspace;
  description: string;
  tags: string[];
  organizationId: string | undefined;
}

export const useCreateDataset = () => {
  const [runMutation, creatingDataset] = useCustomMutation<
    GraphqlTypes.CreateDataset,
    GraphqlTypes.CreateDatasetVariables
  >(CREATE_DATASET, { context: 'creating dataset' });

  const createDataset = (
    variables: IDatasetCreationSettings,
    onSuccess: (id: string) => void
  ) => {
    runMutation(
      {
        name: variables.name,
        workspaceName: variables.workspace.name,

        tags: variables.tags,
        isTagsEmpty: variables.tags.length === 0,

        description: variables.description,
        isDescriptionEmpty: variables.description.length === 0,

        organizationId: variables.organizationId,
      },
      {
        update: onCompletedUpdate((createdDataset) => {
          if (
            createdDataset &&
            isNotNullableRestrictedGraphqlError(createdDataset.workspace)
          ) {
            onSuccess(createdDataset.workspace.createDataset.id);
          }
        }),
      }
    );
  };

  return {
    createDataset,
    creatingDataset,
  };
};
