/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';

export const EVALUATIONS_MODELS_VERSIONS_QUERY = gql`
  query EvaluationsModelsVersions(
    $workspaceName: String!
    $stringPredicates: [StringPredicate!]
    $sorting: SortingQuery!
    $organizationId: ID
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        registeredModels(
          query: {
            pagination: { limit: 0 }
            stringPredicates: $stringPredicates
            sorting: $sorting
          }
        ) {
          models {
            id
            name
            workspace {
              id
              name
            }
            versions(query: { pagination: { limit: 0 } }) {
              versions {
                id
                version
                labels
                dateCreated
                dateUpdated
                registeredModel {
                  id
                  name
                }
                owner {
                  ...OwnerData
                }
              }
            }
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const EVALUATIONS_PROJECTS_QUERY = gql`
  query EvaluationProjectsList(
    $workspaceName: String!
    $stringPredicates: [StringPredicate!]
    $sorting: SortingQuery!
    $organizationId: ID
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        registeredModels(
          query: {
            pagination: { limit: 0 }
            stringPredicates: $stringPredicates
            sorting: $sorting
          }
        ) {
          models {
            id
            name
            dateCreated
            versions {
              versions {
                id
              }
            }
            workspace {
              id
              name
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;
