import React from 'react';
import { ParentSize } from '@visx/responsive';

import { getChartSizes } from 'shared/utils/charts/chartSizes';

import { useGroupedObservations } from './utils/dataHooks';
import { useChartObservations } from './utils/dataHooks';
import { ChartExperimentRun } from './utils/types';
import SelectObservationAxisType from './SelectObservationAxisType/SelectObservationAxisType';
import ObservationChart from './ObservationChart/ObservationChart';
import styles from './ObservationCharts.module.css';
import { useObservationChartColorScale } from './utils/color';
import { useXAxisType } from './utils/axisType';

interface ILocalProps {
  experimentRuns: ChartExperimentRun[];
  renderNilContent: () => React.ReactElement | null;
}

const chartHeight = 220;

const accentColor = { accentColor: '#e6e6e9' };

const ObservationCharts: React.FC<React.PropsWithChildren<ILocalProps>> = ({
  experimentRuns,
  renderNilContent,
}) => {
  const observations = useChartObservations(experimentRuns);
  const groups = useGroupedObservations({ observations });

  const { setXAxisType, xAxisType } = useXAxisType(observations);

  const colorScale = useObservationChartColorScale(experimentRuns);

  return observations.length === 0 ? (
    renderNilContent()
  ) : (
    <div className={styles.root}>
      <div className={styles.controls}>
        <SelectObservationAxisType
          xAxisType={xAxisType}
          setXAxisType={setXAxisType}
        />
      </div>
      <div className={styles.charts}>
        {Object.keys(groups).map((attributeKey) => (
          <ParentSize key={attributeKey}>
            {({ width }) => (
              <ObservationChart
                attributeKey={attributeKey}
                observations={groups[attributeKey]}
                xAxisType={xAxisType}
                sizes={getChartSizes({
                  margin: {
                    top: 15,
                    right: 30,
                    left: 50,
                    bottom: 60,
                  },
                  width,
                  height: chartHeight,
                })}
                colors={accentColor}
                colorScale={colorScale}
              />
            )}
          </ParentSize>
        ))}
      </div>
    </div>
  );
};

export default ObservationCharts;
