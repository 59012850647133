import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { pick } from 'lodash';

import { selectCurrentWorkspace } from 'features/workspaces';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { trackEvent } from 'setup/app/analytics';
import { selectCurrentUserOrThrowError } from 'features/user';
import { VERTA_EVALUATION_LABEL } from 'features/evaluations/shared/utils/evaluation';
import ProjectPopup, {
  ModelRequestForm,
} from 'features/evaluations/shared/view/ProjectPopup';

import { useCreateEvaluationProject } from '../hooks/useCreateEvaluationProject';

export const CreateProjectPopup = ({ onClose }: { onClose: () => void }) => {
  const currentUser = useSelector(selectCurrentUserOrThrowError);
  const workspace = useSelector(selectCurrentWorkspace);

  const { createEvaluationProject, creatingEvaluationProject } =
    useCreateEvaluationProject({
      onSuccess: async ({ name, labels, id }) => {
        trackEvent({
          type: 'catalog.new_model_requested',
          data: {
            model: { name, labels, id },
            user: pick(currentUser, [
              'id',
              'email',
              'firstName',
              'lastName',
              'jobTitle',
              'company',
            ]),
          },
        });
        onClose();
      },
    });

  const initialValues = useMemo(
    () => ({
      name: '',
    }),
    []
  );

  const organizationId = useCurrentOrganizationV2();

  const onSubmit = useCallback(
    (values: ModelRequestForm) => {
      createEvaluationProject({
        name: values.name,
        labels: [VERTA_EVALUATION_LABEL],
        workspaceName: workspace.name,
        organizationId,
      });
    },
    [createEvaluationProject, workspace.name, organizationId]
  );

  return (
    <ProjectPopup
      title="Create new project"
      onSubmit={onSubmit}
      submitButton={{
        children: 'Create',
        isLoading: creatingEvaluationProject.isRequesting,
      }}
      initialValues={initialValues}
      onClose={onClose}
      isOpen={true}
    />
  );
};
