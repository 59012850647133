import { Stack, Box, styled } from '@mui/material';

import { WelcomeMessage } from './WelcomeMessage';
import { GetStartedSteps } from './GetStartedSteps';
import { HomepageStepper } from './HomepageStepper';

const StyleBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  height: '654px',
  padding: '40px 32px',
  borderRadius: '4px',
  position: 'relative',
}));

const FirstTimeUserWidget = () => (
  <StyleBox>
    <Stack direction="column" spacing={4}>
      <WelcomeMessage />
      <GetStartedSteps />
      <HomepageStepper />
    </Stack>
  </StyleBox>
);

export default FirstTimeUserWidget;
