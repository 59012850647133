/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import {
  isNotNullableRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/createRegisteredModelWithExperimentRun.generated';

const CREATE_REGISTERED_MODEL_WITH_EXPERIMENT_RUN = gql`
  mutation CreateRegisteredModelWithExperimentRun(
    $modelName: String!
    $workspaceName: String!
    $organizationId: ID
    $experimentRunId: ID!
    $versionName: String!
    $versionDescription: String!
    $versionLabels: [String!]!
    $lockLevel: RegisteredModelVersionLockLevel!
    $permission: PermissionInput
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        createRegisteredModel(name: $modelName, permission: $permission) {
          id
          name
          createVersion(
            name: $versionName
            experimentRunId: $experimentRunId
            description: $versionDescription
            labels: $versionLabels
            lockLevel: $lockLevel
          ) {
            id
            version
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useCreateRegisteredModelWithExperimentRun = ({
  onSuccess,
}: {
  onSuccess: (res: { registeredModelId: string; versionId: string }) => void;
}) => {
  const [createRegisteredModel, creatingRegisteredModel] = useCustomMutation<
    Types.CreateRegisteredModelWithExperimentRun,
    Types.CreateRegisteredModelWithExperimentRunVariables
  >(
    CREATE_REGISTERED_MODEL_WITH_EXPERIMENT_RUN,
    { context: 'creating registered model' },
    {
      onCompleted: (_res) =>
        isNotNullableRestrictedGraphqlError(_res.workspace) &&
        onSuccess({
          registeredModelId: _res.workspace.createRegisteredModel.id,
          versionId: _res.workspace.createRegisteredModel.createVersion.id,
        }),
    }
  );

  return {
    createRegisteredModel,
    creatingRegisteredModel,
  };
};
