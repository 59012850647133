import { v4 as uuidv4 } from 'uuid';

import { UserData } from 'shared/graphql/User/graphql-types/User.generated';

import { MonitoringPanel } from '../MonitoringPanel/MonitoringPanel';

export interface MonitoringDashboard {
  id: string;
  name: string;
  panels: MonitoringPanel[];
  createdBy: UserData | { __typename: 'Group'; name: string };
  dateCreated: string;
}

export const makeNewMonitoringDashboard = ({
  name,
  currentUser,
}: {
  name: string;
  currentUser: UserData;
}): MonitoringDashboard => {
  return {
    id: uuidv4(),
    name,
    panels: [
      {
        id: uuidv4(),
        layout: [],
        widgets: [],
        name: 'My new panel',
        isOpen: true,
        type: 'custom',
      },
    ],
    createdBy: { ...currentUser, __typename: 'User' },
    dateCreated: new Date().toISOString(),
  };
};
