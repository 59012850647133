// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MUIButton from '@mui/material/Button';

import {
  useButtonCommonProps,
  ButtonCommonProps,
} from 'shared/view/elements/Button/shared/useButtonCommonProps';
import { ButtonContent } from 'shared/view/elements/Button/shared/ButtonContent';
import LinkWrapper from 'shared/view/elements/LinkWrapper/LinkWrapper';

export type LinkButtonProps = ButtonCommonProps & {
  to: string;
  isExternal?: boolean;
};

export const LinkButton = (props: LinkButtonProps) => {
  const { buttonProps, contentProps } = useButtonCommonProps(props);
  return (
    <span>
      <MUIButton
        {...buttonProps}
        isExternal={props.isExternal}
        component={LinkWrapper}
        to={props.to}
      >
        <ButtonContent {...contentProps} isLoading={false} />
      </MUIButton>
    </span>
  );
};

export default LinkButton;
