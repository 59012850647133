import Grid from '@mui/material/Grid';

import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { docsLinks } from 'shared/utils/globalConstants/vertaDocLinks';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { useHomepageCatalogWidget } from 'features/homepage/hooks/useHomepageCatalogWidget';

import HomepageWidgetEmptyContent from '../shared/HomepageWidgetEmptyContent';
import CatalogCountStatistics from './CatalogCountStatistics';
import CatalogWidgetList from './CatalogWidgetList';
import HomepageWidgetWrapper from '../shared/HomepageWidgetWrapper';
import HomepageWidgetCardWrapper from '../shared/HomepageWidgetCardWrapper';

interface Props {
  workspaceName: string;
}

const HomepageCatalogWidget = (props: Props) => {
  const organizationId = useCurrentOrganizationV2();
  const { data, communication } = useHomepageCatalogWidget({
    workspaceName: props.workspaceName,
    organizationId,
  });

  return (
    <HomepageWidgetWrapper title="Catalog">
      <DefaultMatchRemoteDataOrError
        data={data}
        communication={communication}
        context="loading data for catalog widget"
      >
        {({ models, totalRecords, productionTotalRecords }) =>
          totalRecords > 0 ? (
            <div>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <HomepageWidgetCardWrapper title="Models">
                    <CatalogCountStatistics
                      productionTotalRecords={productionTotalRecords}
                      totalRecords={totalRecords}
                    />
                  </HomepageWidgetCardWrapper>
                </Grid>
                <Grid item xs={6}>
                  <HomepageWidgetCardWrapper title="Recent updates">
                    <CatalogWidgetList models={models} />
                  </HomepageWidgetCardWrapper>
                </Grid>
              </Grid>
            </div>
          ) : (
            <HomepageWidgetEmptyContent
              docLink={docsLinks.easySteps.registry}
              text="Register models to your model catalog to start managing, governing and sharing production model releases."
              icon={ICONS.magnifyingGlassChart}
            />
          )
        }
      </DefaultMatchRemoteDataOrError>
    </HomepageWidgetWrapper>
  );
};

export default HomepageCatalogWidget;
