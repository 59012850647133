import { DependencyList, EffectCallback, useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

// it has the same logic as useUpdateEffect but it uses useDeepCompareEffect instead of useEffect
function useDeepCompareUpdateEffect(
  callback: EffectCallback,
  conditions: DependencyList
): void {
  const hasMountedRef = useRef(false);
  if (conditions.length === 0) {
    console.error(
      'Using [] as the second argument makes useDeepCompareEffect a noop. The second argument should either be an array of length greater than 0.'
    );
  }

  useDeepCompareEffect(() => {
    if (hasMountedRef.current) {
      return callback();
    } else {
      hasMountedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, conditions);
}

export { useDeepCompareUpdateEffect };
