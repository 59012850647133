import { useCallback, useMemo } from 'react';

import { OutliersMonitoringPanel } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringPanel';
import { MonitoringWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidget';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import {
  STATIC_OUTLIER_DETECTION_TABLE_WIDGET_ID,
  STATIC_OUTLIER_DETECTION_WIDGET_ID,
} from 'shared/models/Monitoring/MonitoringModel/defaultModelState/presetDashboards/presetOutliersDashboard';
import EditPanelAction from 'shared/view/domain/Dashboards/Panel/EditPanelAction/EditPanelAction';

import StableMonitoringPanel from '../shared/StableMonitoringPanel/StableMonitoringPanel';
import { MonitoringPanelProps } from '../shared/MonitoringPanelProps';
import { useCustomPanelInitialization } from '../shared/useCustomPanelInitialization';

const OutliersMonitoringPanelView = (
  props: MonitoringPanelProps<OutliersMonitoringPanel>
) => {
  const { panel, updatePanel } = props;
  const onChangeIODescription = useCallback(
    (ioDescription: MonitoringIODescription) =>
      updatePanel({ ...panel, ioDescription }),
    [panel, updatePanel]
  );

  useCustomPanelInitialization({
    panel,
    changeIODescription: onChangeIODescription,
    ioDescriptions: props.widgetExternalDeps.ioDescriptions,
  });

  const widgets = useMemo(
    (): MonitoringWidget[] =>
      props.panel.ioDescription
        ? [
            {
              ioDescription: props.panel.ioDescription,
              id: STATIC_OUTLIER_DETECTION_WIDGET_ID,
              type: 'scatterPlot',
              title: 'Outlier detection over time',
              variant: 'outlierDetection',
            },
            {
              id: STATIC_OUTLIER_DETECTION_TABLE_WIDGET_ID,
              type: 'table',
              title: 'Outlier table',
              variant: {
                type: 'outlierDetection',
              },
              changeIODescription: onChangeIODescription,
              ioDescription: props.panel.ioDescription,
            },
          ]
        : [
            {
              id: STATIC_OUTLIER_DETECTION_WIDGET_ID,
              title: 'Outlier detection over time',
              type: 'notInitialized',
              settings: {
                type: 'scatterPlot',
                variant: 'outlierDetection',
              },
            },
            {
              id: STATIC_OUTLIER_DETECTION_TABLE_WIDGET_ID,
              type: 'table',
              title: 'Outlier table',
              variant: {
                type: 'outlierDetection',
              },
            },
          ],
    [onChangeIODescription, props.panel.ioDescription]
  );

  return (
    <StableMonitoringPanel
      {...props}
      widgets={widgets}
      actions={[
        <EditPanelAction
          key="edit"
          name={props.panel.name}
          onRename={(name) => props.updatePanel({ ...props.panel, name })}
        />,
      ]}
    />
  );
};

export default OutliersMonitoringPanelView;
