import { MutationHookOptions, gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { CUSTOM_ATTRIBUTE_VALUE_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeValue';

import {
  UpdateModel,
  UpdateModelVariables,
} from './graphql-types/useUpdateModel.generated';

const CHANGE_DESCRIPTION = gql`
  mutation UpdateModel(
    $id: ID!
    $fields: RegisteredModelInput
    $ownerInput: OwnerInput!
  ) {
    registeredModel(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        updateRegisteredModel(fields: $fields) {
          id
          description
          name
          dataType
          taskType
          pii
          customAttributeValues {
            id
            ...CustomAttributeValueData
          }
        }
        updateOwner(input: $ownerInput) {
          id
          owner {
            ...OwnerData
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_VALUE_FRAGMENT}
`;

export const useUpdateModel = (
  options?: MutationHookOptions<UpdateModel, UpdateModelVariables>
) =>
  useCustomMutation<UpdateModel, UpdateModelVariables>(
    CHANGE_DESCRIPTION,
    {
      context: 'updating model',
    },
    {
      ...options,
    }
  );
