import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import styled from '@emotion/styled';

import { AlertStatus } from 'generated/types';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import routes from 'shared/routes';
import DefaultMatchRemoteData from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import isNotNil from 'shared/utils/isNotNill';
import CellRendererNumeric from 'shared/view/elements/DataGrid/columns/CellRendererNumeric';
import makeLinkColumn from 'shared/view/elements/DataGrid/columns/link/linkColumn';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { docsLinks } from 'shared/utils/globalConstants/vertaDocLinks';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { trackEvent } from 'setup/app/analytics';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import {
  HomepageMonitoringWidgetData,
  useHomepageMonitoringWidget,
} from 'features/homepage/hooks/useHomepageMonitoringWidget';

import HomepageWidgetEmptyContent from './shared/HomepageWidgetEmptyContent';
import HomepageWidgetWrapper from './shared/HomepageWidgetWrapper';
import HomepageWidgetCardWrapper from './shared/HomepageWidgetCardWrapper';

export const HomepageMonitoringWidget = ({
  currentUserId,
  workspaceName,
}: {
  currentUserId: string;
  workspaceName: string;
}) => {
  const organizationId = useCurrentOrganizationV2();
  const { data, communication } = useHomepageMonitoringWidget({
    workspaceName,
    currentUserId,
    organizationId,
  });
  return (
    <HomepageWidgetWrapper title="Monitoring">
      <DefaultMatchRemoteData
        data={data}
        communication={communication}
        context="loading data for monitoring widget"
      >
        {(loadedData) =>
          loadedData.topModels.length > 0 ? (
            <div>
              <Grid container spacing={3}>
                <NumberCards data={loadedData} />
                <Grid item xs={6}>
                  <HomepageWidgetCardWrapper title="Monitored models">
                    <TopModelsTable
                      topModels={loadedData.topModels}
                      workspaceName={workspaceName}
                    />
                  </HomepageWidgetCardWrapper>
                </Grid>
              </Grid>
            </div>
          ) : (
            <HomepageWidgetEmptyContent
              text="Start monitoring your model performance and data patterns using the
              data page."
              docLink={docsLinks.monitoring.quickStart}
              icon={ICONS.emptyTiles}
            />
          )
        }
      </DefaultMatchRemoteData>
    </HomepageWidgetWrapper>
  );
};

const TopModelsTable = ({
  workspaceName,
  topModels,
}: {
  workspaceName: string;
  topModels: HomepageMonitoringWidgetData['topModels'];
}) => {
  const columns: DataGridColumn<
    HomepageMonitoringWidgetData['topModels'][number]
  >[] = [
    {
      field: 'Model',
      flex: 3,
      renderCell: (params) => <CellRendererString value={params.row.name} />,
    },
    {
      field: 'Prediction count',
      flex: 2,
      renderCell: (params) => (
        <CellRendererNumeric value={params.row.predictionCount} />
      ),
    },
    {
      field: 'Alerts',
      flex: 1,
      renderCell: (params) => (
        <CellRendererNumeric
          value={
            params.row.monitoredEntity.alerts.alerts.filter(
              ({ status }) => status === AlertStatus.ALERTING
            ).length
          }
        />
      ),
    },
    makeLinkColumn(
      (row) =>
        routes.monitoringModel.getRedirectPath({
          monitoringModelId: row.id,
          workspaceName,
        }),
      () =>
        trackEvent({ type: 'homepage.monitoring_widget.monitor_link_clicked' })
    ),
  ];

  return (
    <DataGridWithTypes
      rows={topModels}
      heightType="autoHeightButMax5Rows"
      density="compact"
      columns={columns}
    />
  );
};

function NumberCards(props: { data: HomepageMonitoringWidgetData }) {
  return (
    <>
      <Grid item xs={3}>
        <NumberCard
          title="Predictions"
          number={props.data.totalPredictionCount}
          footerContent="Last 30 days"
          color="success.main"
        />
      </Grid>

      <Grid item xs={3}>
        <NumberCard
          title="Alerts"
          footerContent="Last 30 days"
          titleRightContent={
            <Stack direction="row">
              {[
                {
                  infoType: 'success' as const,
                  icon: ICONS.circleCheck,
                  value: props.data.totalAlerts.OK,
                },
                {
                  infoType: 'nodata' as const,
                  icon: ICONS.circlePause,
                  value: props.data.totalAlerts.PAUSED,
                },
              ].map((x, i) => (
                <Stack
                  direction="row"
                  spacing={0.5}
                  alignItems="center"
                  key={i}
                >
                  <IconAwesomeInfo
                    icon={x.icon}
                    infoType={x.infoType}
                    size="1x"
                  />
                  <Typography fontWeight={600}>{x.value}</Typography>
                </Stack>
              ))}
            </Stack>
          }
          number={props.data.totalAlerts.ALERTING}
          color="error.main"
        />
      </Grid>
    </>
  );
}

const NumberContentWrapper = styled('div')`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
`;

const NumberCard = (props: {
  title: string;
  titleRightContent?: React.ReactNode;
  number: number;
  color: string;
  footerContent?: React.ReactNode;
}) => {
  return (
    <HomepageWidgetCardWrapper
      title={props.title}
      titleRightContent={props.titleRightContent}
    >
      <NumberContentWrapper>
        <Stack justifyContent="center">
          <Typography variant="h2" color={props.color} textAlign="center">
            {props.number}
          </Typography>
          {isNotNil(props.footerContent) ? (
            <Typography color="text.secondary" fontSize={16} textAlign="center">
              {props.footerContent}
            </Typography>
          ) : null}
        </Stack>
      </NumberContentWrapper>
    </HomepageWidgetCardWrapper>
  );
};
