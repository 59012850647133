import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import {
  AddEndpointLabel,
  AddEndpointLabelVariables,
  DeleteEndpointLabel,
  DeleteEndpointLabelVariables,
} from './graphql-types/endpointLabelsManager.generated';

const ADD_LABEL = gql`
  mutation AddEndpointLabel($id: ID!, $labels: [String!]!) {
    endpoint(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on Endpoint {
        id
        addLabels(labels: $labels) {
          id
          labels
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

const DELETE_LABEL = gql`
  mutation DeleteEndpointLabel($id: ID!, $label: String!) {
    endpoint(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on Endpoint {
        id
        deleteLabels(labels: [$label]) {
          id
          labels
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useAddLabelMutation = () =>
  useCustomMutation<AddEndpointLabel, AddEndpointLabelVariables>(ADD_LABEL, {
    context: 'adding label',
  });

export const useDeleteLabelMutation = () =>
  useCustomMutation<DeleteEndpointLabel, DeleteEndpointLabelVariables>(
    DELETE_LABEL,
    {
      context: 'deleting label',
    }
  );
