import { PredicateOperator, StringPredicate } from 'generated/types';
import matchBy from 'shared/utils/matchBy';

import { IHighLevelSearchFilter } from './types';

const convertHighLevelSearchFilterToGraphql = (
  filter: IHighLevelSearchFilter,
  {
    tagFilterToLabelFilter,
    labelKey,
  }:
    | { tagFilterToLabelFilter: boolean; labelKey?: undefined }
    | { labelKey: string; tagFilterToLabelFilter?: undefined }
): StringPredicate =>
  matchBy(
    filter,
    'type'
  )<StringPredicate>({
    name: (nameFilter) => ({
      key: 'name',
      operator: PredicateOperator.CONTAIN,
      value: nameFilter.value,
    }),
    tag: (tagFilter) => {
      return {
        key: labelKey ? labelKey : tagFilterToLabelFilter ? 'labels' : 'tags',
        operator: PredicateOperator.CONTAIN,
        value: tagFilter.value,
      };
    },
  });

export default convertHighLevelSearchFilterToGraphql;
