import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import Popup from 'shared/view/elements/Popup/Popup';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { useZoomReset } from 'shared/utils/charts/zoom/useZoomReset';
import Tooltip from 'shared/view/elements/Tooltip/Tooltip';
import MonitoringWidgetView from 'features/monitoring/widgets/view/MonitoringWidgetView/MonitoringWidgetView';
import { CommonMonitoringWidgetProps } from 'features/monitoring/widgets/view/MonitoringWidgetView/shared/types';
import MonitoringWidgetWrapper from 'shared/view/domain/Monitoring/MonitoringWidget/MonitoringWidgetWrapper/MonitoringWidgetWrapper';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import ZoomResetAction from '../ZoomResetAction/ZoomResetAction';

const MonitoringWidgetFullScreenAction = (
  props: CommonMonitoringWidgetProps
) => {
  const { isPopupOpen, closePopup, openPopup } = usePopupManager();

  const { onReset, onSetReset } = useZoomReset();

  return (
    <>
      <Tooltip type="withSpan" title="Expand widget">
        <IconAwesomeClickable icon={ICONS.expand} onClick={openPopup} />
      </Tooltip>
      <Popup
        title={props.widget.title}
        fullScreen={true}
        isOpen={isPopupOpen}
        onClose={closePopup}
      >
        <MonitoringWidgetWrapper
          widget={props.widget}
          draggable={false}
          actions={[<ZoomResetAction key="reset" onZoomReset={onReset} />]}
        >
          {(size) => (
            <MonitoringWidgetView
              {...props}
              id={`fullscreen-widget-${props.widget.id}`}
              onSetReset={onSetReset}
              size={size}
            />
          )}
        </MonitoringWidgetWrapper>
      </Popup>
    </>
  );
};

export default MonitoringWidgetFullScreenAction;
