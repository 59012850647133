import { FC } from 'react';
import { Box, Stack, StackProps, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';
import CopyButton from 'shared/view/elements/CopyButton/CopyButton';
import { useRegisteringModelContext } from 'features/homepage/hooks/useRegisteringModel';
import noop from 'shared/utils/noop';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import { selectCurrentUserOrThrowError } from 'features/user';
import { trackEvent } from 'setup/app/analytics';
import ReadonlyInputWithSecret from 'shared/view/elements/ReadonlyInput/ReadonlyInputWithSecret';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import routes from 'shared/routes';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

interface WrapperProps extends StackProps {
  copyText: string;
  children: JSX.Element;
}

const WrapperInput: FC<React.PropsWithChildren<WrapperProps>> = ({
  copyText,
  children,
  onClick,
  ...other
}) => (
  <Stack direction="row" alignItems="center" sx={{ mb: 1 }} {...other}>
    {children}
    <Box onClick={onClick}>
      <CopyButton copyText={copyText} icon={ICONS.clone} />
    </Box>
  </Stack>
);

export const VertaCredentials = (props: WizardStepContentProps) => {
  const { hostname } = useRegisteringModelContext();
  const currentUser = useSelector(selectCurrentUserOrThrowError);

  return (
    <WizardStepContent {...props}>
      <Typography variant="subtitle1" mb={2}>
        Obtain your verta credentials
      </Typography>
      <Typography variant="body2" mb={1}>
        Here's your host name
      </Typography>
      <WrapperInput copyText={`${hostname}`}>
        <Box width="314px">
          <TextInput
            name="Hostname"
            label="Host Name"
            readOnly
            withoutError
            value={`${hostname}`}
            onChange={noop}
          />
        </Box>
      </WrapperInput>
      <Typography variant="body2" mt={3}>
        Get your developer key:
      </Typography>
      <Typography variant="body2" my={1} color="text.secondary">
        ⚠️ Note that your developer key is unique to you. As with a password,
        don't share it with others!
      </Typography>
      <WrapperInput
        copyText={currentUser.devKey}
        onClick={() =>
          trackEvent({
            type: 'homepage.registration_tutorial.credentials_accessed',
          })
        }
      >
        <ReadonlyInputWithSecret
          value={currentUser.devKey}
          label="Primary key"
          width="314px"
          dataTest={'developer-key-value'}
        />
      </WrapperInput>
      <Typography variant="body2">
        You can add other keys and access it on your &nbsp;
        <span style={{ display: 'inline-flex' }}>
          <InlineLink
            simple
            onClick={(e) => e.stopPropagation()}
            to={routes.profile.getRedirectPath({})}
          >
            profile.
          </InlineLink>
        </span>
      </Typography>
    </WizardStepContent>
  );
};
