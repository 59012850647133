import { useCallback } from 'react';

import { useStateCallback } from './useStateCallback';

export const usePopupManager = (
  initialIsOpen?: boolean,
  callback?: () => void
) => {
  const [isPopupOpen, changePopupOpen] = useStateCallback(
    initialIsOpen || false
  );

  const closePopup = useCallback(
    () => changePopupOpen(false, callback),
    [changePopupOpen, callback]
  );

  const openPopup = useCallback(() => changePopupOpen(true), [changePopupOpen]);

  return {
    isOpen: isPopupOpen,
    onClose: closePopup,
    onOpen: openPopup,
    isPopupOpen,
    closePopup,
    openPopup,
  };
};
