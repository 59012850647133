import copy from 'copy-to-clipboard';
import * as React from 'react';

import Tooltip from 'shared/view/elements/Tooltip/Tooltip';

interface ILocalProps {
  text: string | (() => string);
  tooltipTitle?: string;
  withTooltip?: boolean;
  children: (onCopy: () => void) => React.ReactElement;
}

interface ILocalState {
  isShownNotificationAboutCopied: boolean;
}

class CopyToClipboard extends React.Component<ILocalProps, ILocalState> {
  public state: ILocalState = { isShownNotificationAboutCopied: false };

  public render() {
    const {
      children,
      withTooltip,
      tooltipTitle = 'Copied to Clipboard',
    } = this.props;
    const { isShownNotificationAboutCopied } = this.state;

    return (
      <Tooltip
        type="withSpan"
        open={withTooltip ? undefined : isShownNotificationAboutCopied}
        title={tooltipTitle}
      >
        {children(this.onCopy)}
      </Tooltip>
    );
  }

  private onCopy = () => {
    copy(
      typeof this.props.text === 'function'
        ? this.props.text()
        : this.props.text
    );
    this.setState({ isShownNotificationAboutCopied: true });
    setTimeout(
      () => this.setState({ isShownNotificationAboutCopied: false }),
      1000
    );
  };
}

export default CopyToClipboard;
