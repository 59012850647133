import React from 'react';
import { Helmet } from 'react-helmet';

interface ILocalProps {
  title: string;
}

const SEO: React.FC<React.PropsWithChildren<ILocalProps>> = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default SEO;
