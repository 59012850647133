import { gql } from '@apollo/client';

import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { ALERTER_FRAGMENT } from 'shared/graphql/monitoring/Alert/Alerter';
import {
  ALERT_HISTORY_ITEM_FRAGMENT,
  convertAlertHistoryItem,
} from 'shared/graphql/monitoring/Alert/AlertHistoryItem';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { convertAlertId } from 'shared/graphql/monitoring/Alert/Alert';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';

import {
  AlertHistoryQuery,
  AlertHistoryQueryVariables,
} from './graphql-types/useAlertHistory.generated';

const ALERT_HISTORY = gql`
  query AlertHistoryQuery($alertId: ID!) {
    alert(id: $alertId) {
      ... on Error {
        ...ErrorData
      }
      ... on Alert {
        id
        name
        history {
          ...AlertHistoryItemFragment
        }
      }
    }
  }
  ${ALERTER_FRAGMENT}
  ${ALERT_HISTORY_ITEM_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useAlertHistory = (props: { alertId: string }) => {
  const queryResult = useCustomQuery<
    AlertHistoryQuery,
    AlertHistoryQueryVariables
  >(ALERT_HISTORY, {
    variables: { alertId: props.alertId },
  });

  return useMemoizedResultToCommunicationWithData({
    queryResult,
    memoizedConvert: convert,
  });
};

const convert = (res: AlertHistoryQuery) => {
  return mapDataOrError(res.alert, (alert) => ({
    id: convertAlertId(alert.id),
    name: alert.name,
    history: alert.history.map(convertAlertHistoryItem),
  }));
};
