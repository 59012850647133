export const makeLogisticPoints = ({
  xGroups,
  yGroups,
}: {
  xGroups: Array<{
    modelVersion: string;
    values: number[];
  }>;
  yGroups: Array<{
    modelVersion: string;
    values: number[];
  }>;
}) => {
  return xGroups.flatMap((group, groupIndex) =>
    group.values.map((x, index) => ({
      x,
      y: yGroups[groupIndex].values[index],
      meta: undefined,
      key: `Logistic ${group.modelVersion}`,
      lineType: 'solid' as const,
    }))
  );
};
