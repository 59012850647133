import { AppError, isErrorWithStatus } from 'shared/models/Error';
import { normalizeAppErrorMessage } from 'shared/utils/normalizeErrorData';

export type AppErrorTextComponents = {
  errorCode: number | undefined;
  message: string;
};
export const getAppErrorTextComponents = ({
  error,
}: {
  error: AppError | Error | string;
}): AppErrorTextComponents => {
  const message =
    typeof error === 'string' ? error : normalizeAppErrorMessage(error);

  const errorCode =
    typeof error !== 'string' && isErrorWithStatus(error)
      ? error.status
      : undefined;

  return {
    errorCode,
    message,
  };
};

export const appErrorToString = (
  error: AppError | Error | string,
  context: string
) => {
  const components = getAppErrorTextComponents({ error });
  const errorCodeWithMessage = [components.errorCode, components.message]
    .filter(Boolean)
    .map((component) => component)
    .join(': ');
  const sanitizedErrorCodeWithMessage = `"${errorCodeWithMessage}"`
    .replace(/^""/, `"`)
    .replace(/""$/, `"`);
  return `Error: ${sanitizedErrorCodeWithMessage} when ${context}`;
};
