import { LinkButton } from '../Button/LinkButton';
import { ICONS } from '../IconAwesome/ICONS';

interface Props {
  docLink: string;
}

const LearnHowLinkButton = (props: Props) => {
  return (
    <LinkButton
      isExternal={true}
      to={props.docLink}
      variant="text"
      iconEnd={ICONS.arrowUpRightFromSquare}
    >
      Learn how
    </LinkButton>
  );
};

export default LearnHowLinkButton;
