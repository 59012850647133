import { KafkaConfigUpdateRequestInput } from 'generated/types';
import { BuildSource } from 'shared/models/Deployment/canary/Build';

import { RequiredBuild } from '../../shared/RequiredBuild';

export type Settings = {
  source: BuildSource;
  sourceId: string | undefined;
  sourceBuilds: RequiredBuild[] | undefined;
  build: BuildOption | undefined;
  labels: string[];
  path?: string;
  description?: string;
  tokensEnabled: boolean;
  authzPredictionEnabled: boolean;
  kafkaReq: KafkaConfigUpdateRequestInput | undefined;
};

type BuildOption = { type: 'newBuild' } | { type: 'existedBuild'; id: string };

export const changeSourceId = (
  sourceId: string | undefined,
  settings: Settings
): Settings => ({
  ...settings,
  sourceId,
  sourceBuilds: undefined,
  build: undefined,
});

export const setSourceBuilds = (
  sourceBuilds: RequiredBuild[],
  settings: Settings
): Settings => {
  return {
    ...settings,
    sourceBuilds: sourceBuilds.filter(
      (sourceBuild) => sourceBuild.source === settings.source
    ),
    build: sourceBuilds.length === 0 ? { type: 'newBuild' } : undefined,
  };
};
