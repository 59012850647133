import { useCallback } from 'react';

import { MonitoringDashboard } from 'shared/models/Monitoring/MonitoringModel/MonitoringDashboard/MonitoringDashboard';
import {
  MonitoringPanel,
  insertNewPanel,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringPanel';
import { updateById } from 'shared/utils/collection';

export const useMonitoringDashboard = ({
  dashboard,
  updateDashboard,
}: {
  dashboard: MonitoringDashboard;
  updateDashboard: (dashboard: MonitoringDashboard) => void;
}) => {
  const updatePanels = useCallback(
    (panels: MonitoringPanel[]) => {
      updateDashboard({
        ...dashboard,
        panels,
      });
    },
    [dashboard, updateDashboard]
  );

  const updatePanel = useCallback(
    (panel: MonitoringPanel) => {
      updatePanels(updateById(() => panel, panel.id, dashboard.panels));
    },
    [dashboard, updatePanels]
  );

  const createPanel = useCallback(
    (panel: MonitoringPanel) => {
      updateDashboard({
        ...dashboard,
        panels: insertNewPanel(panel, dashboard.panels),
      });
    },
    [dashboard, updateDashboard]
  );

  const deletePanel = useCallback(
    (panelId: string) => {
      updateDashboard({
        ...dashboard,
        panels: dashboard.panels.filter((panel) => panel.id !== panelId),
      });
    },
    [dashboard, updateDashboard]
  );

  return {
    updatePanel,
    createPanel,
    deletePanel,
    updatePanels,
  };
};
