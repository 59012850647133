import { useRouteParams } from 'shared/utils/router/useRouteParams';
import { useEndpointMonitoring } from 'features/deployment/canary/endpoints/store/endpointMonitoring/endpointMonitoring';
import routes from 'shared/routes';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

import EndpointDetailsLayout from '../shared/EndpointDetailsLayout/EndpointDetailsLayout';

const EndpointUsage = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'EndpointUsage' */ 'features/deployment/canary/endpoints/view/EndpointUsage/EndpointUsage'
    )
);

const EndpointUsagePage = () => {
  const params = useRouteParams(routes.endpointUsage);
  const organizationId = useCurrentOrganizationV2();

  const { data, communication } = useEndpointMonitoring({
    id: params.endpointId,
    workspaceName: params.workspaceName,
    organizationId,
  });

  return (
    <EndpointDetailsLayout
      data={data}
      communication={communication}
      endpointId={params.endpointId}
    >
      {(loaded) => <EndpointUsage endpoint={loaded.endpoint} />}
    </EndpointDetailsLayout>
  );
};

export default EndpointUsagePage;
