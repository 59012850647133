import { useEffect, useState, MouseEvent } from 'react';
import { Box, styled } from '@mui/material';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { useSelector } from 'react-redux';

import { selectFlags } from 'features/flags';
import { HttpActions } from 'services/HttpActions';
import { selectIsCurrentUserSystemAdmin } from 'features/systemAdmin/isAdmin/store';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

import ToggleOrg, { ToggleOrgOptions } from '../SysAdminOrgToggle';

const StyleBox = styled(Box)`
  iframe {
    width: 100%;
    min-height: 3000px;
    border: 0;
    overflow: hidden;
  }
`;

const contentTypeHeader = { 'Content-type': 'application/json' };

async function fetchEmbedDashboard(allOrgs: boolean, orgId?: string) {
  const dashboard_id = 'VertaMainDashboard';

  let data;
  if (allOrgs) {
    data = {
      dashboard_id,
      all_organizations: allOrgs,
    };
  } else {
    data = {
      dashboard_id,
      all_organizations: allOrgs,
      organization_ids: [orgId],
    };
  }

  const res: { data: { dashboardEmbedId: string; token: string } } =
    await HttpActions.post({
      url: `/v1/dashboard/fetch-dashboard-token`,
      data,
      config: {
        headers: { ...contentTypeHeader },
      },
    });
  return res.data;
}

// TODO: For local development, all URLs need to be hardcoded to VERTA_HOST
const GeneralDashboard = () => {
  const { isEnableDashboard } = useSelector(selectFlags);
  const isCurrentUserSystemAdmin = useSelector(selectIsCurrentUserSystemAdmin);
  const organizationId = useCurrentOrganizationV2();

  const [allOrgs, setAllOrgs] = useState<ToggleOrgOptions>(false);

  const handleOrgChange = (
    _e: MouseEvent,
    newValue: ToggleOrgOptions | null
  ) => {
    if (newValue !== null) setAllOrgs(newValue);
  };

  const dashboardElementId = 'dashboardElement';

  useEffect(() => {
    const embed = async () => {
      const { dashboardEmbedId, token } = await fetchEmbedDashboard(
        allOrgs,
        organizationId
      );
      embedDashboard({
        id: dashboardEmbedId,
        supersetDomain: `https://${window.location.hostname}/api/vertadashboard`,
        mountPoint: document.getElementById(dashboardElementId) as HTMLElement,
        fetchGuestToken: () => Promise.resolve(token),
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: true,
          hideTab: true,
        },
        debug: false,
      });
    };
    if (document.getElementById(dashboardElementId) && isEnableDashboard) {
      embed();
    }
  }, [dashboardElementId, isEnableDashboard, allOrgs, organizationId]);

  return (
    <Box>
      {isCurrentUserSystemAdmin ? (
        <Box mb={1}>
          <ToggleOrg onChange={handleOrgChange} value={allOrgs} />
        </Box>
      ) : null}
      <StyleBox id={dashboardElementId} />
    </Box>
  );
};

export default GeneralDashboard;
