import { isNil } from 'ramda';

import routes from 'shared/routes';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { Build } from 'generated/types';
import { EndpointEnvironmentComponentStatus } from 'generated/types';
import { WorkspaceData } from 'shared/graphql/Workspace/graphql-types/Workspace.generated';
import isNotNil from 'shared/utils/isNotNill';
import RecordsGridSection from 'shared/view/elements/RecordsGridSection/RecordsGridSection';
import matchBy from 'shared/utils/matchBy';

import BuildStatusWithError from '../../BuildStatusWithError/BuildStatusWithError';
import { formatCanaryRolloutPercentage } from './BuildsInfo';

export type RequiredComponentWithBuild = {
  status: EndpointEnvironmentComponentStatus;
  build: {
    id: Build['id'];
    status: Build['status'];
    canaryRolloutPercentage: number | null;
    source:
      | {
          __typename: 'ExperimentRun';
          id: string;
          projectId: string;
          name: string;
          project: {
            id: string;
            name: string;
            workspace: WorkspaceData;
          };
        }
      | {
          __typename: 'RegisteredModelVersion';
          id: string;
          registeredModel: {
            id: string;
            name: string;
            workspace: WorkspaceData;
          };
          version: string;
        }
      | {
          __typename: 'Error';
        }
      | null;
  };
};

const BuildInfo = ({
  endpointId,
  componentWithBuild,
  workspaceName,
  organizationId,
}: {
  endpointId: string;
  componentWithBuild: RequiredComponentWithBuild;
  organizationId: string | undefined;
  workspaceName: string;
}) => {
  return (
    <RecordsGridSection
      dataTest="build-info"
      columns={[
        componentWithBuild.build.source
          ? matchBy(
              componentWithBuild.build.source,
              '__typename'
            )({
              Error: () => [],
              ExperimentRun: (experimentRun) => [
                {
                  label: 'Project',
                  content: (
                    <InlineLink
                      to={routes.projectOverview.getRedirectPath({
                        projectId: experimentRun.projectId,
                        workspaceName: experimentRun.project.workspace.name,
                      })}
                    >
                      {experimentRun.project.name}
                    </InlineLink>
                  ),
                },
                {
                  label: 'Experiment Run',
                  content: (
                    <InlineLink
                      to={routes.experimentRun.getRedirectPath({
                        experimentRunId: experimentRun.id,
                        projectId: experimentRun.project.id,
                        workspaceName: experimentRun.project.workspace.name,
                      })}
                      dataTest="experiment-run-title-link"
                    >
                      {experimentRun.name}
                    </InlineLink>
                  ),
                },
              ],
              RegisteredModelVersion: (modelVersion) => [
                {
                  label: 'Registered model',
                  content: (
                    <InlineLink
                      to={routes.modelOverview.getRedirectPath({
                        workspaceName:
                          modelVersion.registeredModel.workspace.name,
                        registeredModelId: modelVersion.registeredModel.id,
                      })}
                    >
                      {modelVersion.registeredModel.name}
                    </InlineLink>
                  ),
                },
                {
                  label: 'Model version',
                  content: (
                    <InlineLink
                      to={routes.registeredModelVersion.getRedirectPath({
                        workspaceName:
                          modelVersion.registeredModel.workspace.name,
                        registeredModelId: modelVersion.registeredModel.id,
                        versionId: modelVersion.id,
                      })}
                      dataTest="registered-model-version-title"
                    >
                      {modelVersion.version}
                    </InlineLink>
                  ),
                },
              ],
            })
          : [],
        [
          {
            label: 'Build ID',
            content: componentWithBuild.build.id,
          },
          !isNil(componentWithBuild.build.canaryRolloutPercentage)
            ? {
                label: 'Percentage traffic',
                content: formatCanaryRolloutPercentage(
                  componentWithBuild.build.canaryRolloutPercentage
                ),
              }
            : null,
        ].filter(isNotNil),
        [
          {
            label: 'Build status',
            content: (
              <BuildStatusWithError
                data={{
                  endpointId,
                  status: componentWithBuild.status,
                  build: componentWithBuild.build,
                }}
                organizationId={organizationId}
                workspaceName={workspaceName}
              />
            ),
          },
        ],
      ]}
    />
  );
};

export default BuildInfo;
