import { EndpointEnvironmentUpdateStrategy } from 'generated/types';
import { ResultMachineConfigSettings } from 'features/deployment/canary/machineConfig/view/settings';
import { convertMachineConfig } from 'features/deployment/canary/machineConfig/convertMachineConfig';
import { AppError } from 'shared/models/Error';
import apolloErrorToAppError from 'shared/utils/graphql/apolloErrorToAppError';
import { OmitStrict } from 'shared/utils/types';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as UpdateEnvironmentGraphqlTypes from './graphql-types/UpdateEnvironmentQuery.generated';
import { CanaryStrategyForm } from '../../view/UpdateEndpointSection/settings/CanaryStrategyForm';
import { convertCanarySettingsToGraphql } from './convertCanarySettingsToGraphql';
import { UPDATE_ENVIRONMENT } from './UpdateEnvironmentQuery';

export type RollbackSettings = OmitStrict<
  UpdateEnvironmentGraphqlTypes.UpdateEnvironmentVariables,
  'canary' | 'autoscaling' | 'env' | 'resources'
> & {
  buildType: 'existedBuild';
  canary: CanaryStrategyForm | undefined;
  machineConfig: ResultMachineConfigSettings;
  organizationId: string | undefined;
};

export const useRollback = ({
  onCompleted,
  onError,
}: {
  onError: (error: AppError) => void;
  onCompleted: () => void;
}) => {
  const [rollbackMutation, rollbacking] = useCustomMutation<
    UpdateEnvironmentGraphqlTypes.UpdateEnvironment,
    UpdateEnvironmentGraphqlTypes.UpdateEnvironmentVariables
  >(
    UPDATE_ENVIRONMENT,
    { context: 'rollback' },
    {
      onCompleted,
      onError: (error) => {
        onError(apolloErrorToAppError(error));
      },
    }
  );
  const rollback = ({
    strategy,
    endpointId,
    environmentId,
    workspaceName,
    canary,
    buildId,
    organizationId,
    machineConfig,
  }: RollbackSettings) => {
    rollbackMutation({
      ...convertMachineConfig(machineConfig),
      buildId,
      strategy,
      endpointId,
      environmentId,
      workspaceName,
      organizationId,
      canary:
        strategy === EndpointEnvironmentUpdateStrategy.CANARY && canary
          ? convertCanarySettingsToGraphql(canary)
          : undefined,
    });
  };

  return {
    rollbacking,
    rollback,
  };
};
