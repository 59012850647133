import { Field, FieldConfig, useField } from 'formik';
import { useCallback, useMemo } from 'react';

import { OmitStrict } from 'shared/utils/types';
import MultiSelect from 'shared/view/elements/Selects/MultiSelect/MultiSelect';
import { validateNotEmpty } from 'shared/utils/validators';
import { SelectCommonProps } from 'shared/view/elements/Selects/shared/view/VertaSelectBase';
import {
  IGroupedOptions,
  IOptionType,
} from 'shared/view/elements/Selects/shared/types';

type Props<T> = OmitStrict<FieldConfig, 'value'> &
  SelectCommonProps & {
    options: Array<IOptionType<T>> | IGroupedOptions<T>;
    onChange?(value: IOptionType<T>[]): void;
  };

export default function MultiSelectField<T>(props: Props<T>) {
  const {
    extendedProps,
    findValue,
    errorMessage,
    inputName,
    inputOnBlur,
    handleChange,
    validate,
  } = useVertaCommonSelectField(props);

  const value = useMemo(
    () =>
      'type' in props.options
        ? findValue(props.options.groups.flatMap((opt) => opt.options))
        : findValue(props.options),
    [findValue, props.options]
  );

  return (
    <Field
      {...extendedProps}
      validate={validate}
      onChange={handleChange}
      inputOnBlur={inputOnBlur}
      inputName={inputName}
      errorMessage={errorMessage}
      component={MultiSelect}
      value={value}
      dataTest={props.name}
    />
  );
}

type VertaCommonSelectFieldProps<Name extends string, T> = SelectCommonProps & {
  name: Name;
  onChange?(value: IOptionType<T>[]): void;
};

function useVertaCommonSelectField<Name extends string, T>(
  props: VertaCommonSelectFieldProps<Name, T>
) {
  const [_, { value, error }, { setValue, setTouched }] = useField<T[]>(
    props.name
  );

  const { onChange } = props;

  const handleChange = useCallback(
    (newValue: IOptionType<T>[]) => {
      setValue(newValue.map((v) => v.value));
      setTouched(true);
      onChange?.(newValue);
    },
    [onChange, setTouched, setValue]
  );

  const onClose = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  const inputOnBlur = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  const findValue = useCallback(
    (options: IOptionType<T | undefined>[]) =>
      options.filter((o) => value.includes(o.value as any)),
    [value]
  );

  const errorMessage = useMemo(() => error?.toString(), [error]);

  const validate = useMemo(
    () =>
      props.required
        ? validateNotEmpty(props.label ?? 'This select')
        : undefined,
    [props.label, props.required]
  );

  const extendedProps: VertaCommonSelectFieldProps<Name, T> = useMemo(
    () => ({
      ...props,
      onClose,
    }),
    [onClose, props]
  );

  return {
    extendedProps,
    handleChange,
    inputOnBlur,
    validate,
    findValue,
    errorMessage,
    inputName: props.name,
  };
}
