import { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { TaskType, DataType, Stage } from 'generated/types';
import { useTaskType } from 'features/catalog/registeredModel/shared/hooks/useTaskType';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import Chip from 'shared/view/elements/Chip/Chip';

interface Props {
  kind: 'tile' | 'title';
  taskType: TaskType;
  dataType: DataType;
  modelId: string;
  versions: { id: string; stage: Stage }[];
}

const RegisteredModelTypeInfo = ({
  kind,
  taskType,
  dataType,
  modelId,
  versions,
}: Props) => {
  const { icon, text } = useTaskType({ taskType, dataType });

  const liveStatus = useMemo(
    () => versions.filter((v) => v.stage === Stage.PRODUCTION).length > 0,
    [versions]
  );

  return (
    <Stack
      direction="row"
      justifyContent={kind === 'tile' ? 'space-between' : ''}
      alignItems="center"
      spacing={2}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconAwesomeInfo icon={icon} />
        {text ? (
          <Typography variant="overline" color="text.secondary">
            {text}
          </Typography>
        ) : null}
        <Typography variant="overline" color="text.secondary">
          {modelId}
        </Typography>
      </Stack>
      {liveStatus ? <Chip label="Live" size="small" color="success" /> : null}
    </Stack>
  );
};

export default RegisteredModelTypeInfo;
