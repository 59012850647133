import { gql } from '@apollo/client';
import { useCallback } from 'react';

import { KafkaConfigurationInput } from 'generated/types';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

import { KafkaConfigurationFragment } from '../store/kafkaConfigurationFragment';
import * as GraphqlTypes from './graphql-types/useUpdateKafkaConfiguration.generated';

const MUTATION = gql`
  mutation UpdateKafkaConfiguration(
    $id: ID!
    $config: KafkaConfigurationInput!
  ) {
    kafkaConfiguration(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on KafkaConfiguration {
        id
        update(config: $config) {
          id
          ...KafkaConfigurationFragment
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${KafkaConfigurationFragment}
`;

export const useUpdateKafkaConfiguration = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [mutate, updatingKafkaConfiguration] = useCustomMutation<
    GraphqlTypes.UpdateKafkaConfiguration,
    GraphqlTypes.UpdateKafkaConfigurationVariables
  >(MUTATION, { context: 'updating kafka configuration' }, { onCompleted });

  const updateKafkaConfiguration = useCallback(
    async ({ id, ...config }: { id: string } & KafkaConfigurationInput) => {
      await mutate({
        id,
        config,
      });
    },
    [mutate]
  );

  return {
    updateKafkaConfiguration,
    updatingKafkaConfiguration,
  };
};
