import { Wizard, WizardStepProps } from 'shared/view/elements/Wizard';

import * as slides from './slides';

const steps: WizardStepProps[] = [
  {
    stepLabel: { icon: '1', text: 'About checklists' },
    slides: [slides.AboutChecklists, slides.EditChecklists],
  },
  {
    stepLabel: { icon: '2', text: 'Create a new checklist' },
    slides: [
      slides.CreateNewChecklist,
      slides.CreateChecklistRestrictGroup,
      slides.CreateChecklistStageChange,
      slides.CreateChecklistCustomAttr,
    ],
  },

  {
    stepLabel: { icon: '3', text: 'Complete items on a checklist' },
    slides: [
      slides.ChecklistCompleteItems,
      slides.ChecklistCompleteTimestamp,
      slides.ChecklistCompleteEvidence,
    ],
  },
  {
    stepLabel: false,
    slides: [slides.CreateNewChecklistAction],
  },
];

export const GovernanceChecklists = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  return <Wizard steps={steps} onCompleted={onCompleted} />;
};
