import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import matchType from 'shared/utils/matchType';
import BuildErrorEye, {
  IRequiredData,
} from 'features/deployment/canary/buildErrorTracker/view/BuildErrorEye/BuildErrorEye';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import Tooltip from 'shared/view/elements/Tooltip/Tooltip';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

interface Props {
  data: IRequiredData;
  workspaceName: string;
  organizationId: string | undefined;
}

const BuildStatusWithError: FC<React.PropsWithChildren<Props>> = ({
  data,
  workspaceName,
  organizationId,
}) => {
  return (
    <Stack direction="row" alignItems="center">
      {matchType(
        {
          INITIALIZING: () => (
            <Tooltip type="default" title="Initializing">
              <Stack
                direction="row"
                alignItems="center"
                data-test="build-status"
              >
                <IconAwesomeInfo
                  icon={ICONS.circleNotch}
                  infoType={'success'}
                  spin={true}
                  size={'lg'}
                />
              </Stack>
            </Tooltip>
          ),
          DELETING: () => (
            <Tooltip type="default" title="Deleting">
              <Stack
                direction="row"
                alignItems="center"
                data-test="build-status"
              >
                <IconAwesomeInfo
                  icon={ICONS.circleNotch}
                  infoType={'error'}
                  spin={true}
                  size={'lg'}
                />
              </Stack>
            </Tooltip>
          ),
          BUILDING: () => (
            <Tooltip type="default" title="Building">
              <Stack
                direction="row"
                alignItems="center"
                data-test="build-status"
              >
                <IconAwesomeInfo
                  icon={ICONS.circleNotch}
                  infoType={'success'}
                  spin={true}
                  size={'lg'}
                />
              </Stack>
            </Tooltip>
          ),
          FINISHED: () => (
            <Tooltip type="default" title="Built">
              <Stack
                direction="row"
                alignItems="center"
                data-test="build-status"
              >
                <IconAwesomeInfo
                  icon={ICONS.circleCheck}
                  infoType={'success'}
                  size={'lg'}
                />
                <Typography variant="body2" color="text.primary">
                  {data.build.id}
                </Typography>
              </Stack>
            </Tooltip>
          ),
          ERROR: () => (
            <Tooltip type="default" title={data.build.status}>
              <Stack
                direction="row"
                alignItems="center"
                data-test="build-status"
              >
                <IconAwesomeInfo
                  icon={ICONS.circleExclamation}
                  infoType={'error'}
                  size={'lg'}
                />
                <Typography variant="body2" color="text.primary">
                  Error
                </Typography>
              </Stack>
            </Tooltip>
          ),
        },
        data.build.status
      )}
      <BuildErrorEye
        workspaceName={workspaceName}
        organizationId={organizationId}
        data={data}
      />
    </Stack>
  );
};

export default BuildStatusWithError;
