import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';

import { endpointDetailsPages } from './EndpointDetailsPages';
import EndpointsPage from './EndpointsPage/EndpointsPage';

export const endpointsPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.endpoints.getPath(),
      element: <EndpointsPage />,
    },
    ...endpointDetailsPages.getRoutes(),
  ],
};
