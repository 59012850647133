import { scaleLinear } from '@visx/scale';

export const scaleLinearZoomed = (props: {
  range: [number, number];
  selectedDomain: [number, number] | undefined;
  domain: [number, number];
}) => {
  return scaleLinear({
    ...(props.selectedDomain
      ? {
          domain: props.selectedDomain,
        }
      : {
          domain: props.domain,
          round: true,
          nice: true,
        }),
    range: props.range,
  });
};
