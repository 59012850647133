import { AxisBottom, SharedAxisProps, AxisScale } from '@visx/axis';
import { Group } from '@visx/group';
import { Line } from '@visx/shape';
import { useTheme } from '@mui/material';

import truncateString from 'shared/utils/truncateString';

import { tickLabelFontSize } from '../defaultStyles';

const tickLabelSize = tickLabelFontSize - 1;
const tickXOffset = 4;
const tickYOffset = tickLabelSize + 2;
const tickRotate = -25;

const AxisBottomWithRotatedTicks = <T extends AxisScale>(
  axisProps: SharedAxisProps<T>
) => {
  const theme = useTheme();
  return (
    <AxisBottom {...axisProps}>
      {(props) => {
        const axisCenter = (props.axisToPoint.x - props.axisFromPoint.x) / 2;
        return (
          <g>
            {props.ticks.map((tick, i) => {
              const tickX = tick.to.x + tickXOffset;
              const tickY = tick.to.y + tickYOffset;
              return (
                <Group
                  key={`vx-tick-${Number(tick.value)}-${i}`}
                  className={props.tickClassName}
                >
                  <Line
                    from={tick.from}
                    to={tick.to}
                    stroke={props.tickStroke}
                  />
                  <text
                    transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                    fontSize={tickLabelSize}
                    textAnchor="end"
                    fill={theme.palette.text.primary}
                  >
                    <title>{tick.formattedValue}</title>
                    {truncateString(tick.formattedValue || '', 22)}
                  </text>
                </Group>
              );
            })}
            <text
              textAnchor="middle"
              transform={`translate(${axisCenter}, 55)`}
              fontSize="10"
              className={props.labelClassName}
              fill={theme.palette.text.primary}
            >
              {props.label}
            </text>
          </g>
        );
      }}
    </AxisBottom>
  );
};

export default AxisBottomWithRotatedTicks;
