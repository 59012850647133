import Stack from '@mui/material/Stack';
import { equals } from 'ramda';
import { useMemo } from 'react';

import { UpdateUserInput } from 'generated/types';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import { OmitStrict } from 'shared/utils/types';
import { validateNotEmpty } from 'shared/utils/validators';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import Popup from 'shared/view/elements/Popup/Popup';
import RecordsSection, {
  RecordInfo,
} from 'shared/view/elements/RecordsSection/RecordsSection';
import MultiSelectField from 'shared/view/formComponents/formikFields/MultiSelectField';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import { AllowedActionsData } from 'shared/graphql/collaborators/graphql-types/fragments.generated';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { formatDateWithTime } from 'shared/utils/formatters/dateTime';
import DevKeysManagerV2 from 'features/developerKeysManager/view/DevKeysManager';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import ConfirmPopup from 'shared/view/elements/ConfirmAction/ConfirmPopup/ConfirmPopup';
import { ExtractByTypename } from 'shared/utils/types';

import { OrganizationV2Users } from '../../store/graphql-types/useOrganizationUsers.generated';
import { useUpdateUser } from '../../store/useUpdateUser';
import { useDeleteUser } from '../../store/useDeleteUser';

type Form = OmitStrict<UpdateUserInput, 'id'>;

const getFieldName = makeGetFieldName<Form>();

const UserDetailsPopup = (props: {
  organizationId: string;
  user: ExtractByTypename<
    OrganizationV2Users['organizationV2ByName'],
    'OrganizationV2'
  >['users']['users'][0];
  groups: Array<{
    id: string;
    name: string;
    allowedActions: AllowedActionsData;
  }>;
  onClose(): void;
}) => {
  const updateUserApi = useUpdateUser();
  const deleteUserApi = useDeleteUser(props.onClose);
  const deletePopupApi = usePopupManager();

  const initialValues: Form = useMemo(() => {
    return {
      email: props.user.email,
      fullName: props.user.fullName,
      groupIds: props.user.groups.map((g) => g.id),
      description:
        props.user.__typename === 'ServiceAccountV2'
          ? props.user.description
          : null,
    };
  }, [props.user]);

  return (
    <>
      {deletePopupApi.isPopupOpen && (
        <ConfirmPopup
          title="Delete group"
          confirmButtonText="Delete"
          longPressConfirmation={true}
          isOpen={deletePopupApi.isPopupOpen}
          onCancel={deletePopupApi.closePopup}
          onConfirm={() => {
            deleteUserApi.run({
              organizationId: props.organizationId,
              userId: props.user.id,
            });
            deletePopupApi.closePopup();
          }}
        >
          Are you sure you want to delete this user?
        </ConfirmPopup>
      )}
      <PresetFormik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(form) =>
          updateUserApi.run({
            organizationId: props.organizationId,
            input: { ...form, id: props.user.id },
          })
        }
      >
        {({ isValid, values, submitForm }) => (
          <Popup
            title={
              props.user.__typename === 'UserV2'
                ? 'Edit user'
                : 'Edit service account'
            }
            isOpen={true}
            fullWidth={true}
            maxWidth={'xs'}
            buttons={{
              tertiaryButtonProps: {
                children: 'Delete',
                isLoading: deleteUserApi.communication.isRequesting,
                disabled: false,
                onClick: deletePopupApi.openPopup,
              },
              secondaryButtonProps: {
                isLoading: false,
                children: 'Cancel',
                onClick: props.onClose,
              },
              mainButtonProps: {
                children: 'Update',
                isLoading: updateUserApi.communication.isRequesting,
                disabled: !isValid || equals(values, initialValues),
                type: 'button',
                onClick: submitForm,
              },
            }}
            onClose={props.onClose}
          >
            <Stack direction="column" spacing={2}>
              <RecordsSection>
                <RecordInfo label="Joined">
                  {formatDateWithTime(props.user.joinedAt)}
                </RecordInfo>
                <RecordInfo label="Updated">
                  {formatDateWithTime(props.user.updatedAt)}
                </RecordInfo>
              </RecordsSection>
              <FormStack>
                <TextInputField
                  label="Name"
                  name={getFieldName({ fullName: null })}
                  isRequired={true}
                  validate={validateNotEmpty('Name')}
                />
                <TextInputField
                  label="Email"
                  isRequired={true}
                  name={getFieldName({ email: null })}
                  validate={validateNotEmpty('Email')}
                />
                {props.user.__typename === 'ServiceAccountV2' ? (
                  <TextInputField
                    label="Description"
                    name={getFieldName({ description: null })}
                  />
                ) : null}
                <MultiSelectField
                  label="Select groups"
                  name={getFieldName({ groupIds: null })}
                  dataTest="groupIds"
                  options={props.groups.map((g) => ({
                    label: g.name,
                    value: g.id,
                    disabled: !g.allowedActions.update,
                  }))}
                />
              </FormStack>
              {props.user.__typename === 'ServiceAccountV2' ? (
                <DevKeysManagerV2
                  primaryKey={props.user.primaryKey}
                  secondaryKey={props.user.secondaryKey}
                  userId={props.user.id}
                  organizationId={props.organizationId}
                />
              ) : null}
            </Stack>
          </Popup>
        )}
      </PresetFormik>
    </>
  );
};

export default UserDetailsPopup;
