import routes from 'shared/routes';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { makeKeyedItemsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/KeyedItemsDiff';
import getVersionView from 'shared/view/domain/Dataset/DatasetVersions/getVersionView';
import { DatasetsDiff } from 'shared/models/CompareEntities/new/datasetsComparing';

import makeItemPropertyDiffView from './ItemPropertyDiffView';

export const makeDatasetsDiff = (diff: DatasetsDiff) =>
  makeKeyedItemsDiff({
    name: 'Datasets',
    diff: diff,
    render: (datasetsByKeyDiff) => [
      makeItemPropertyDiffView({
        name: 'Key',
        diff: datasetsByKeyDiff.properties.key,
        render: (key) => key,
      }),
      makeItemPropertyDiffView({
        name: 'Dataset',
        diff: datasetsByKeyDiff.properties.dataset,
        render: (dataset) => {
          return (
            <InlineLink
              to={routes.datasetOverview.getRedirectPath({
                workspaceName: dataset.workspaceName,
                datasetId: dataset.id,
              })}
            >
              {dataset.name}
            </InlineLink>
          );
        },
      }),
      makeItemPropertyDiffView({
        name: 'Dataset Version',
        diff: datasetsByKeyDiff.properties.linkedDatasetVersion,
        render: (linkedDatasetVersion) => (
          <InlineLink
            to={routes.datasetVersion.getRedirectPath({
              datasetId: linkedDatasetVersion.dataset.id,
              datasetVersionId: linkedDatasetVersion.id,
              workspaceName: linkedDatasetVersion.dataset.workspaceName,
            })}
          >
            {getVersionView(linkedDatasetVersion.version)}
          </InlineLink>
        ),
      }),
      makeItemPropertyDiffView({
        name: 'Content',
        diff: datasetsByKeyDiff.properties.path,
        render: (path) => path,
      }),
    ],
  });
