import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { Navigate } from 'react-router-dom';
import { Box, Typography, Stack } from '@mui/material';

import { selectRegistrationUser } from 'features/user';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { logoutUser } from 'features/user/store/actions';
import { VertaLogo } from 'shared/view/elements/VertaLogo';
import routes from 'shared/routes';
import { selectFlags } from 'features/flags';
import VerifyMailSvg from 'pages/anonymous/shared/images/verify-mail.svg?react';

import { Layout, LeftSlot, RightSlot } from '../shared/Layout';
import { CopyRightFooter } from '../shared/CopyRightFooter';
import { SocialButtons } from '../shared/SocialButtons';
import { VertaOverallFreeTier } from '../shared/VertaOverallFreeTier';

export const UnverifiedEmailPage = () => {
  const { isEnableFreeTier } = useSelector(selectFlags);

  const user = useSelector(selectRegistrationUser);
  const dispatch = useDispatch<Dispatch<any>>();

  if (!isEnableFreeTier) {
    return <Navigate to={routes.login.getPath()} />;
  }

  return (
    <Layout>
      <LeftSlot>
        <Box display="flex" flexDirection="column" height="100%" padding={4}>
          <Box flex="1 1 auto" mb={2} display="flex" flexDirection="column">
            <Box mt={4} mb={5}>
              <VertaLogo
                width="76"
                height="24"
                color="primary.main"
                onClickRedirect={false}
                onClick={() => {
                  window.location.replace('https://www.verta.ai/');
                }}
              />
            </Box>
            <Box mb={1} height="30px"></Box>
            <Typography variant="h4" color="text.primary" mb={1}>
              Welcome
            </Typography>
            <Typography variant="h6" color="text.primary">
              Verify account
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              flex="1"
              alignItems="stretch"
            >
              <Stack spacing={4} flex="1">
                <Box display="flex" justifyContent="left">
                  <VerifyMailSvg />
                </Box>
                <Box maxWidth="600px">
                  <Typography variant="body1" color="text.primary" mb={2}>
                    Your account has not been verified yet. Please check your
                    email <strong>{user ? user.email : ''}</strong> and click on
                    'Activate Account' to complete the verification process.
                  </Typography>
                  <Typography variant="body1" color="text.primary" mb={2}>
                    The email could take up to five minutes to arrive. This tab
                    can be closed.
                  </Typography>
                </Box>
              </Stack>
              <Stack spacing={0}>
                <Typography variant="caption" color="text.secondary">
                  Click here to{' '}
                  <InlineLink
                    simple
                    onClick={() => dispatch(logoutUser({}))}
                    to="#"
                  >
                    get back to login page
                  </InlineLink>
                </Typography>
              </Stack>
            </Box>
          </Box>
          <Box flex="0 1 40px" display="flex">
            <SocialButtons />
          </Box>
        </Box>
      </LeftSlot>
      <RightSlot>
        <Box overflow="auto" height="100%" px={7} pt={20} pb={4}>
          <VertaOverallFreeTier />
          <CopyRightFooter />
        </Box>
      </RightSlot>
    </Layout>
  );
};
