import { monitoringWidgetMinSize } from 'features/monitoring/panel/store/useCustomMonitoringPanel';
import { MonitoringMetricType } from 'generated/types';
import { getLabelByMonitoringMetricType } from 'shared/models/Monitoring/MonitoringMetricType';
import generateId from 'shared/utils/generateId';

import { MonitoringIODescription } from '../../../MonitoringIODescription';
import {
  NotInitializedSingleMetricWidget,
  SingleMetricWidget,
} from '../../../MonitoringPanel/MonitoringWidget/Widgets/SingleMetricWidget';
import {
  NotInitializedTimeSeriesWidget,
  TimeSeriesWidget,
} from '../../../MonitoringPanel/MonitoringWidget/Widgets/TimeSeriesWidget';

export const makePresetMetricRow = (
  metricType: MonitoringMetricType,
  defaultOutput: MonitoringIODescription | undefined,
  index: number
) => {
  const height = 8;
  const y = height * index;

  const layout = [
    {
      minH: monitoringWidgetMinSize,
      minW: monitoringWidgetMinSize,
      h: height,
      i: generateId(),
      w: 5,
      x: 0,
      y,
    },
    {
      minH: monitoringWidgetMinSize,
      minW: monitoringWidgetMinSize,
      h: height,
      i: generateId(),
      w: 7,
      x: 5,
      y,
    },
  ];

  const label = getLabelByMonitoringMetricType(metricType);

  if (defaultOutput) {
    const singleMetricWidget: SingleMetricWidget = {
      id: layout[0].i,
      type: 'singleMetric',
      variant: {
        type: 'metric',
        metricType,
        output: defaultOutput,
      },
      title: label,
    };

    const timeSeriesWidget: TimeSeriesWidget = {
      id: layout[1].i,
      type: 'timeSeries',
      title: `${label} over time`,
      variant: {
        type: 'metric',
        metricType,
        output: defaultOutput,
      },
    };

    return {
      widgets: [singleMetricWidget, timeSeriesWidget],
      layout,
    };
  }

  if (metricType === MonitoringMetricType.PREDICTION_COUNT) {
    const singleMetricWidget: SingleMetricWidget = {
      id: layout[0].i,
      type: 'singleMetric',
      title: label,
      variant: {
        type: 'metric',
        metricType,
      },
    };

    const timeSeriesWidget: TimeSeriesWidget = {
      id: layout[1].i,
      type: 'timeSeries',
      title: `${label} over time`,
      variant: {
        type: 'metric',
        metricType,
      },
    };

    return {
      widgets: [singleMetricWidget, timeSeriesWidget],
      layout,
    };
  }

  const singleMetricWidget: NotInitializedSingleMetricWidget = {
    id: layout[0].i,
    type: 'notInitialized',
    title: label,
    settings: {
      metricType,
      type: 'singleMetric',
    },
  };

  const timeSeriesWidget: NotInitializedTimeSeriesWidget = {
    id: layout[1].i,
    title: `${label} over time`,
    type: 'notInitialized',
    settings: {
      type: 'timeSeries',
      variant: {
        type: 'metric',
        metricType,
      },
    },
  };

  return {
    widgets: [singleMetricWidget, timeSeriesWidget],
    layout,
  };
};
