import { gql } from '@apollo/client';
import { useCallback } from 'react';

import { ALERT_FRAGMENT } from 'shared/graphql/monitoring/Alert/Alert';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

import {
  UpdateAlertMutation,
  UpdateAlertMutationVariables,
} from './graphql-types/useUpdateAlert.generated';
import { convertAlertFormToGraphQL } from '../shared/convertAlertFormToGraphQL';
import { MonitoringAlertFormType } from '../../view/shared/MonitoringAlertForm/shared/MonitoringAlertFormType';

const UPDATE_ALERT = gql`
  mutation UpdateAlertMutation(
    $alertId: ID!
    $alerter: AlerterInput!
    $aggregationWindow: AlertAggregationWindow!
    $evaluationFrequency: AlertEvaluationFrequency!
    $settings: AlertSettingsInput!
    $name: String!
  ) {
    alert(id: $alertId) {
      ... on Error {
        ...ErrorData
      }
      ... on Alert {
        id
        updateName(name: $name) {
          id
          updateAlerter(alerter: $alerter) {
            id
            updateAggregationWindow(aggregationWindow: $aggregationWindow) {
              id
              updateEvaluationFrequency(
                evaluationFrequency: $evaluationFrequency
              ) {
                id
                updateSettings(settings: $settings) {
                  id
                  ...AlertFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ALERT_FRAGMENT}
`;

export const useUpdateAlert = ({
  onCompleted,
}: {
  onCompleted: (res: UpdateAlertMutation) => void;
}) => {
  const [mutate, updatingAlert] = useCustomMutation<
    UpdateAlertMutation,
    UpdateAlertMutationVariables
  >(
    UPDATE_ALERT,
    { context: 'updating alert' },
    {
      onCompleted,
    }
  );

  const updateAlert = useCallback(
    ({
      alertId,
      alert,
    }: {
      alertId: string;
      alert: MonitoringAlertFormType;
    }) => {
      mutate({
        alertId,
        ...convertAlertFormToGraphQL(alert),
      });
    },
    [mutate]
  );

  return {
    updateAlert,
    updatingAlert,
  };
};
