import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useFormikContext } from 'formik';

import {
  validateEmail,
  combineValidators,
  validateEmailNotEmpty,
  validatePassword,
} from 'shared/utils/validators';
import { registerUserByEmail, selectCommunications } from 'features/user/store';
import { authErrorMessages } from 'shared/utils/customErrorMessages';
import Button from 'shared/view/elements/Button/Button';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import routes from 'shared/routes';
import { ICommunication } from 'shared/utils/redux/communication';
import { isHttpError } from 'shared/models/Error';
import {
  RegistrationByEmail,
  RegistrationByEmailErrors,
} from 'shared/models/Authorization';
import { AppError } from 'shared/models/Error';
import { trackEvent } from 'setup/app/analytics';

import { ValidPasswordHelper } from '../shared/ValidPasswordHelper';

interface ILocalProps {}

const initialForm: RegistrationByEmail = {
  email: '',
  password: '',
};

const getFieldName = makeGetFieldName<RegistrationByEmail>();

const RequestErrorObserver = (props: {
  communication: ICommunication<
    AppError<keyof typeof RegistrationByEmailErrors>
  >;
}) => {
  const { setErrors } = useFormikContext();

  useEffect(() => {
    const { error } = props.communication;

    if (isHttpError(error) && error.type) {
      setErrors({
        email: authErrorMessages[error.type],
        password: undefined,
      });
    }
  }, [props.communication, setErrors]);

  return null;
};

export const SignUpMailForm: React.FC<
  React.PropsWithChildren<ILocalProps>
> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch<any>>();

  const { registrationUserByEmail } = useSelector(selectCommunications);

  const onSubmit = useCallback(
    (values: RegistrationByEmail) => {
      trackEvent({
        type: 'sign_up.email_entered',
        data: {
          email: values.email,
        },
      });
      dispatch(
        registerUserByEmail({
          values,
          onSuccess: () => {
            navigate(routes.signUpPersonalInfoForm.getPath(), {
              state: { showBackButton: false, provider: 'email-password' },
            });
          },
        })
      );
    },
    [dispatch, navigate]
  );

  return (
    <PresetFormik<RegistrationByEmail>
      initialValues={initialForm}
      onSubmit={onSubmit}
    >
      {({ submitForm, values }) => (
        <FormStack spacing={0.5}>
          <TextInputField
            name={getFieldName({ email: null })}
            validate={combineValidators([validateEmailNotEmpty, validateEmail])}
            label="Email"
            isRequired={true}
            size="medium"
          />
          <TextInputField
            name={getFieldName({ password: null })}
            validate={validatePassword}
            label="Password"
            isRequired={true}
            size="medium"
            type="password"
            withoutError
          />
          <Stack pt={1} spacing={4}>
            <ValidPasswordHelper password={values.password} />
            <Button
              onClick={submitForm}
              isLoading={registrationUserByEmail.isRequesting}
              size="medium"
              variant="contained"
            >
              Continue
            </Button>
          </Stack>
          <RequestErrorObserver communication={registrationUserByEmail} />
        </FormStack>
      )}
    </PresetFormik>
  );
};
