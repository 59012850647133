import { useState } from 'react';
import Stack from '@mui/material/Stack';

import Placeholder from 'shared/view/elements/Placeholder/Placeholder';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { useExpanded } from 'features/systemAdmin/integrations/shared/useExpanded';
import { ConfigurationsFormCreateWrapper } from 'features/systemAdmin/integrations/shared/ConfigurationsFormCreateWrapper';

export function ConfigurationsWithFormCreate<T extends { id: string }>(
  props: {
    configurations: T[];
    learnMoreTo: string;
    FormCreate: (props: { onDelete: () => void }) => JSX.Element;
    children: () => React.ReactNode;
  } & ReturnType<typeof useConfigurationsWithFormCreate>
) {
  if (props.configurations.length > 0 || props.isFormCreateShown) {
    return (
      <Stack direction={'column'}>
        {props.children()}
        {props.isFormCreateShown && (
          <ConfigurationsFormCreateWrapper
            key={formCreateId}
            expanded={props.expandedId === formCreateId}
            handleExpand={props.handleExpandById(formCreateId)}
            learnMoreTo={props.learnMoreTo}
          >
            <props.FormCreate onDelete={props.deleteFormCreate} />
          </ConfigurationsFormCreateWrapper>
        )}
      </Stack>
    );
  }
  return (
    <Placeholder>
      Setup your integration.{' '}
      <InlineLink isExternal to={props.learnMoreTo}>
        Learn More
      </InlineLink>
    </Placeholder>
  );
}

const formCreateId = '---newConfigurationForm---';

export function useConfigurationsWithFormCreate() {
  const [isFormCreateShown, setIsFormCreateShown] = useState(false);

  const useExpandedApi = useExpanded();
  const showFormCreate = () => {
    setIsFormCreateShown(true);
    useExpandedApi.handleExpandById(formCreateId)(true);
  };
  const deleteFormCreate = () => {
    setIsFormCreateShown(false);
    useExpandedApi.handleExpandById(formCreateId)(false);
  };

  return {
    ...useExpandedApi,
    addButtonProps: {
      onClick: showFormCreate,
      disabled: isFormCreateShown,
    },
    deleteFormCreate,
    isFormCreateShown,
  };
}
