import Grid from '@mui/material/Grid';
import { identity } from 'ramda';
import { useField, useFormikContext } from 'formik';

import Checkbox from 'shared/view/elements/Checkbox/Checkbox';

const getFieldName = (parent: string, field: string) => `${parent}.${field}`;

export default function ConditionalFields<
  Form extends Record<string, any>,
  Name extends keyof Form,
>(props: {
  name: Extract<Name, string>;
  title: string;
  tooltip: string;
  initialValues: NonNullable<Form[Name]>;
  fields: Array<{
    name: Extract<keyof NonNullable<Form[Name]>, string>;
    content: (fieldName: string) => React.ReactNode;
  }>;
}) {
  const [fieldInputProps] = useField<Form[Name]>({
    name: props.name,
  });
  const formik = useFormikContext<Form>();

  const hideOrShowFields = (isEnabled: boolean) => {
    formik.setFieldValue(
      props.name,
      identity<Form[Name] | undefined>(
        isEnabled ? props.initialValues : undefined
      )
    );
    // we have to mark hidden fields as not touched and validate form otherwise the fields errors will affects to the form even if they are not displayed
    props.fields.forEach((field) => {
      formik.setFieldTouched(getFieldName(props.name, field.name), false);
    });
    setTimeout(() => {
      formik.validateForm();
    });
  };

  return (
    <div>
      <Grid container={true} direction={'column'} spacing={2} mt={0}>
        <Grid item={true}>
          <Checkbox
            {...fieldInputProps}
            value={Boolean(fieldInputProps.value)}
            onChange={hideOrShowFields}
            label={props.title}
            info={props.tooltip}
          />
        </Grid>
        {fieldInputProps.value
          ? props.fields.map(({ content, name }) => (
              <Grid key={name} item={true}>
                {content(getFieldName(props.name, name))}
              </Grid>
            ))
          : null}
      </Grid>
    </div>
  );
}
