import { MarkdownWidgetSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/MarkdownWidgetSettings';
import MarkdownManager from 'shared/view/elements/MarkdownManager/MarkdownManager';

interface LocalProps {
  widgetSettings: MarkdownWidgetSettings;
  width: number;
  height: number;
  editWidget(widgetSettings: MarkdownWidgetSettings): void;
}

const MarkdownWidget = (props: LocalProps) => {
  return (
    <div
      style={{
        width: `${props.width}px`,
        height: `${props.height}px`,
        overflow: 'auto',
        padding: '1px',
      }}
    >
      <MarkdownManager
        initialValue={props.widgetSettings.markdown}
        isEditDisabled={false}
        isDownloadDisabled={false}
        title={props.widgetSettings.name}
        isEmbeded={true}
        onSaveChanges={(markdown) =>
          props.editWidget({ ...props.widgetSettings, markdown })
        }
        isSaving={false}
      />
    </div>
  );
};

export default MarkdownWidget;
