import { useCallback, useMemo } from 'react';
import Stack from '@mui/material/Stack';

import Popup from 'shared/view/elements/Popup/Popup';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import {
  validateMaxLength,
  combineValidators,
  validateNotEmpty,
} from 'shared/utils/validators';
import { trackEvent } from 'setup/app/analytics';
import { EvaluationAttributeKey } from 'features/evaluations/shared/utils/evaluation';
import { makeGetFieldName } from 'shared/utils/getFieldName';

import { useEditEvaluation } from '../../hooks/useEditEvaluation';
import { Evaluation } from '../../hooks/useEvaluationSummary';

type EvaluationVariables = {
  name?: string;
  description?: string;
  configuration?: string;
  modelIdentifier?: string;
};

interface Props {
  isOpen: boolean;
  onClose(): void;
  evaluationId: string;
  evaluation?: Evaluation;
}

const getFieldName = makeGetFieldName<EvaluationVariables>();

const EditEvaluationPopup = (props: Props) => {
  const { isOpen, onClose, evaluationId, evaluation } = props;
  const { editEvaluationBasicInfo, editingEvaluationBasicInfo } =
    useEditEvaluation({
      onSuccess: async ({ version, id }) => {
        trackEvent({
          type: 'catalog.registered_model_version.edited',
          data: {
            id: id,
            name: version,
            changed: {},
          },
        });
        onClose();
      },
    });

  const initialValues = useMemo(
    () => ({
      name: evaluation?.version,
      description: evaluation?.description,
      configuration: evaluation?.configuration,
      modelIdentifier: evaluation?.modelId,
    }),
    [evaluation]
  );

  const onSubmit = useCallback(
    (values: EvaluationVariables) => {
      editEvaluationBasicInfo({
        evaluationId: evaluationId,
        input: { version: values.name, description: values.description },
        attributes: [
          {
            key: EvaluationAttributeKey.CONFIGURATION,
            value: values.configuration ?? '',
          },
          {
            key: EvaluationAttributeKey.MODEL_ID,
            value: values.modelIdentifier ?? '',
          },
        ],
      });
    },
    [editEvaluationBasicInfo, evaluationId]
  );

  return (
    <PresetFormik<EvaluationVariables>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isValid, submitForm }) => (
        <Popup
          title="Edit basic info"
          isOpen={isOpen}
          onClose={onClose}
          maxWidth="xs"
          fullWidth
          buttons={{
            secondaryButtonProps: {
              children: 'Cancel',
              dataTest: 'evaluation-edit-action-cancel',
              isLoading: false,
              onClick: onClose,
            },
            mainButtonProps: {
              isLoading: editingEvaluationBasicInfo.isRequesting,
              children: 'Save',
              disabled: !isValid,
              type: 'submit',
              dataTest: 'evaluation-edit-action-confirm',
              onClick: submitForm,
            },
          }}
        >
          <Stack direction={'column'} spacing={0}>
            <TextInputField
              name={getFieldName({ name: null })}
              label="Evaluation name"
              isRequired
              validate={combineValidators([
                validateNotEmpty('Evaluation name'),
                validateMaxLength(250),
              ])}
            />
            <TextInputField
              name={getFieldName({ description: null })}
              label="Evaluation description"
              validate={validateMaxLength(250)}
            />
            <TextInputField
              name={getFieldName({ modelIdentifier: null })}
              label="Model/Pipeline Identifier"
              validate={validateMaxLength(250)}
            />
            <TextInputField
              name={getFieldName({ configuration: null })}
              label="Configuration"
              validate={validateMaxLength(250)}
            />
          </Stack>
        </Popup>
      )}
    </PresetFormik>
  );
};

export default EditEvaluationPopup;
