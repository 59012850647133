import Stack from '@mui/material/Stack';
import { ReactNode } from 'react';

const ControlWithCheckbox = ({
  control,
  checkbox,
}: {
  control: ReactNode;
  checkbox: ReactNode;
}) => {
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <span>{control}</span>
      <span>{checkbox}</span>
    </Stack>
  );
};

export default ControlWithCheckbox;
