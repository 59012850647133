import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

export enum enumCardsDocumentationTypes {
  VIDEO = 'VIDEO',
  ARTICLE = 'ARTICLE',
}

export enum documentationType {
  CATALOG = 'catalog',
  VERTA = 'Verta',
}

type MapperProps = {
  [key in enumCardsDocumentationTypes]: {
    label: string;
    icon: IconDefinition;
  };
};

export const CARD_DOCUMENTATION_TYPE: MapperProps = {
  VIDEO: {
    label: 'VIDEO TUTORIAL',
    icon: ICONS.play,
  },
  ARTICLE: {
    label: 'ARTICLE',
    icon: ICONS.fileLines,
  },
};
