import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as GraphqlTypes from './graphql-types/editDatasetVersionDescription.generated';

const EDIT_DATASET_DESCRIPTION = gql`
  mutation EditDatasetVersionDescription($id: ID!, $description: String!) {
    datasetVersion(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on DatasetVersion {
        id
        changeDescription(description: $description) {
          id
          description
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export function useEditDatasetVersionDescriptionMutation({
  onCompleted,
}: {
  onCompleted: () => void;
}) {
  return useCustomMutation<
    GraphqlTypes.EditDatasetVersionDescription,
    GraphqlTypes.EditDatasetVersionDescriptionVariables
  >(
    EDIT_DATASET_DESCRIPTION,
    {
      context: 'updating dataset version description',
    },
    { onCompleted }
  );
}
