import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FC } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';

import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { InfoColorType } from 'shared/view/elements/IconAwesome/hooks/useIconAwesomeColorPalette';

interface Props {
  text: string;
  icon: IconDefinition;
  infoType?: InfoColorType;
}

const TextWithInfoIcon: FC<React.PropsWithChildren<Props>> = ({
  text,
  icon,
  infoType,
}) => {
  return (
    <FormControlLabel
      sx={{ cursor: 'default' }}
      control={<IconAwesomeInfo icon={icon} size={'sm'} infoType={infoType} />}
      label={text}
    />
  );
};

export default TextWithInfoIcon;
