import { groupBy, toPairs } from 'ramda';

import { CanaryRule } from 'generated/types';
import matchType from 'shared/utils/matchType';
import { CanaryRuleCategory } from 'generated/types';
import {
  IGroupedOptions,
  IOptionType,
} from 'shared/view/elements/Selects/shared/types';

import {
  SelectedCanaryRule,
  CanonicalCanaryRuleData,
} from '../settings/CanaryStrategyForm';

export function getCanaryRuleOptionValue(
  selectedRule: SelectedCanaryRule
): IOptionType<CanonicalCanaryRuleData> | undefined {
  return selectedRule.canonicalRule
    ? {
        label: selectedRule.canonicalRule.description,
        value: selectedRule.canonicalRule,
      }
    : undefined;
}

export function getCanaryRuleOptions(
  rules: CanaryRule[]
): IGroupedOptions<CanonicalCanaryRuleData> {
  return {
    type: 'groupedOptions',
    groups: toPairs(groupBy(({ category }) => category, rules)).map(
      ([category, rulesByCategory]) => ({
        label: matchType(
          {
            ERROR: () => 'Error',
            LATENCY: () => 'Latency',
            UNKNOWN: () => 'Unknown',
          },
          category as CanaryRuleCategory
        ),
        options: rulesByCategory.map((rule) => ({
          label: rule.description,
          value: rule,
        })),
      })
    ),
  };
}
