import useSaveDisabled from 'features/systemAdmin/integrations/shared/useSaveDisabled';
import { KafkaConfigurationInput } from 'generated/types';

export const useSaveDisabledInternal = ({
  values,
  initialValues,
  isSaving,
  isValid,
  isConfigurationTested,
}: {
  values: KafkaConfigurationInput;
  initialValues: KafkaConfigurationInput;
  isSaving: boolean;
  isValid: boolean;
  isConfigurationTested: boolean;
}) => {
  const fullValues = {
    ...values,
    tls: values.tls ?? null,
    kerberos: values.kerberos ?? null,
  };

  return useSaveDisabled({
    isConfigurationTested,
    isValid,
    initialValues,
    isSaving,
    values: fullValues,
    isExtendedFormShown: true,
  });
};
