import Select from 'shared/view/elements/Selects/Select/Select';
import { DataType } from 'generated/types';
import capitalize from 'shared/utils/capitalize';
import { FilterType } from 'features/catalog/registeredModel/catalogList/hooks/useFilter';
import { CLEAR_VALUE } from 'features/catalog/registeredModel/catalogList/hooks/useFilter';

type OptionProps = {
  label: string;
  value: string;
};

type Props = {
  value: string | undefined;
  includeAll?: boolean;
  disabled?: boolean;
  onChange: (p: any) => void;
  label?: string;
};

export const DataTypeSelector = (props: Props) => {
  const { value, onChange, includeAll, label, ...other } = props;

  const dataTypeOptions = [];
  if (includeAll)
    dataTypeOptions.push({ label: 'All data types', value: CLEAR_VALUE });

  Object.keys(DataType)
    .filter((t) => t !== DataType.UNKNOWN)
    .forEach((t) =>
      dataTypeOptions.push({
        label: t === DataType.OTHER ? 'Other data' : capitalize(t),
        value: t,
      })
    );

  const handleChange = (option: OptionProps) => {
    onChange({ ...option, type: FilterType.DATA_TYPE, value: option.value });
  };

  return (
    <Select
      {...other}
      label={label ?? 'Data type'}
      value={dataTypeOptions.find((o) => o.value === value)}
      options={dataTypeOptions}
      name="data-type-select"
      dataTest="data-type-select"
      onChange={handleChange}
      withoutError
    />
  );
};
