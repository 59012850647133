import Stack from '@mui/material/Stack';

import SectionHeader, {
  SectionHeaderProps,
} from 'shared/view/elements/SectionComponents/SectionHeader/SectionHeader';

type Props = SectionHeaderProps & {
  children: React.ReactNode;
};

const Section = (props: Props) => {
  return (
    <Stack direction={'column'}>
      <SectionHeader {...props} />
      {props.children}
    </Stack>
  );
};

export default Section;
