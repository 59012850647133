import {
  useBreadcrumbItem,
  useAppBreadcrumbs,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import routes from 'shared/routes';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';

import { useEvaluationsPageBreadcrumbs } from './useEvaluationsPageBreadcrumbs';
import { EvaluationLayout } from './Layout/graphql';

export const useEvaluationBreadcrumbs = ({
  evaluation,
}: {
  evaluation?: EvaluationLayout['registeredModelVersion'];
}) => {
  return useAppBreadcrumbs(
    useEvaluationsPageBreadcrumbs(),
    useBreadcrumbItem({
      route: routes.evaluations,
      hasTopEnhancer: false,
      ...(isNotError(evaluation)
        ? { name: evaluation.version }
        : { isLoading: true }),
    })
  );
};
