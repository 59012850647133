import { ScaleLinear } from 'd3-scale';

import { PredicateOperator } from 'generated/types';
import { AlerterFixed } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/Alerter';
import matchType from 'shared/utils/matchType';

import { TimeSeriesChartAlertDecorationsProps } from './shared/TimeSeriesChartAlertDecorationsProps';
import {
  TimeSeriesChartViolatingArea,
  TimeSeriesChartViolationLine,
} from './shared/TimeSeriesViolationComponents';

const TimeSeriesChartViolationFixed = (
  props: TimeSeriesChartAlertDecorationsProps<AlerterFixed>
) => {
  const renderGreaterThan = () => (
    <TimeSeriesViolationAreaWithLine
      innerWidth={props.innerWidth}
      type="greaterThan"
      value={props.alerter.threshold}
      yScale={props.yScale}
    />
  );

  const renderLessThan = () => (
    <TimeSeriesViolationAreaWithLine
      innerWidth={props.innerWidth}
      type="lessThan"
      value={props.alerter.threshold}
      yScale={props.yScale}
    />
  );

  const renderEqual = () => (
    <TimeSeriesChartViolationLine
      value={props.alerter.threshold}
      yScale={props.yScale}
      innerWidth={props.innerWidth}
    />
  );

  const renderEmpty = () => <g />;

  return matchType(
    {
      [PredicateOperator.EQ]: renderEqual,
      [PredicateOperator.GT]: renderGreaterThan,
      [PredicateOperator.GTE]: renderGreaterThan,
      [PredicateOperator.LT]: renderLessThan,
      [PredicateOperator.LTE]: renderLessThan,
      // Not supported:
      [PredicateOperator.CONTAIN]: renderEmpty,
      [PredicateOperator.NOT_CONTAIN]: renderEmpty,
      [PredicateOperator.IN]: renderEmpty,
      [PredicateOperator.NE]: renderEmpty,
      [PredicateOperator.NOT_IN]: renderEmpty,
    },
    props.alerter.operator
  );
};

const TimeSeriesViolationAreaWithLine = (props: {
  type: 'greaterThan' | 'lessThan';
  value: number;
  yScale: ScaleLinear<number, number>;
  innerWidth: number;
}) => {
  const [yMin, yMax] = props.yScale.domain();

  const startValue = matchType(
    {
      greaterThan: () => yMax,
      lessThan: () => yMin,
    },
    props.type
  );

  return (
    <g>
      <TimeSeriesChartViolatingArea
        yScale={props.yScale}
        value1={startValue}
        value2={props.value}
        innerWidth={props.innerWidth}
      />

      <TimeSeriesChartViolationLine
        value={props.value}
        yScale={props.yScale}
        innerWidth={props.innerWidth}
      />
    </g>
  );
};

export default TimeSeriesChartViolationFixed;
