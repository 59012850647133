import { combineReducers } from 'redux';

import {
  makeCommunicationByIdReducerFromResetableActionCreators,
  makeCommunicationReducerFromResetableAsyncAction,
} from 'shared/utils/redux/actions';

import { addComment, deleteComment, loadComments } from '../actions';
import { ICommentsState } from '../types';

export default combineReducers<ICommentsState['communications']>({
  addingComment: makeCommunicationReducerFromResetableAsyncAction(addComment),
  deletingComment: makeCommunicationByIdReducerFromResetableActionCreators(
    deleteComment,
    {
      request: ({ commentId }) => commentId,
      success: ({ commentId }) => commentId,
      failure: ({ commentId }) => commentId,
      reset: ({ commentId }) => commentId,
    }
  ),
  loadingComments: makeCommunicationByIdReducerFromResetableActionCreators(
    loadComments,
    {
      request: ({ entityId }) => entityId,
      success: ({ entityId }) => entityId,
      failure: ({ entityId }) => entityId,
      reset: ({ entityId }) => entityId,
    }
  ),
});
