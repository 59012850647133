import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import viewScan from '../images/view-scan-results-2.png';

export const ViewScanReports = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      View scan results
    </Typography>
    <Typography variant="body2" mb={3}>
      A comprehensive report is shown outlining every vulnerability, its
      severity, the package in which it was found, and a brief description.
      Download and share this report with any team member to quickly address any
      issues.
    </Typography>
    <img src={viewScan} alt="view scan reports" width="90%" height="auto" />
  </WizardStepContent>
);
