import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { validateNotEmpty } from 'shared/utils/validators';
import { selectCurrentWorkspace } from 'features/workspaces/store';
import routes from 'shared/routes';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import TagsField from 'shared/view/formComponents/formikFields/TagsFieldWithTopLabel/TagsFieldWithTopLabel';
import { validateDescription } from 'shared/models/Description';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PopupForm from 'shared/view/formComponents/PopupForm';
import { PopupStateProps } from 'shared/view/elements/Popup/PopupButtons';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

import {
  IDatasetCreationSettings,
  useCreateDataset,
} from '../../store/datasetCreation/datasetCreation';

const getFieldName = makeGetFieldName<IDatasetCreationSettings>();

const DatasetCreationPopup: React.FC<
  React.PropsWithChildren<PopupStateProps>
> = (props) => {
  const currentWorkspace = useSelector(selectCurrentWorkspace);

  const organizationId = useCurrentOrganizationV2();
  const initialSettings: IDatasetCreationSettings = useMemo(
    () => ({
      name: '',
      description: '',
      tags: [],
      workspace: currentWorkspace,
      organizationId,
    }),
    [currentWorkspace, organizationId]
  );

  const { createDataset, creatingDataset } = useCreateDataset();

  const navigate = useNavigate();
  const onSubmit = useCallback(
    ({
      name,
      workspace,
      description,
      tags,
      organizationId: currOrganizationId,
    }: IDatasetCreationSettings) => {
      createDataset(
        {
          name,
          workspace,
          description,
          tags,
          organizationId: currOrganizationId,
        },
        (id) =>
          navigate(
            routes.datasetOverview.getRedirectPath({
              workspaceName: currentWorkspace.name,
              datasetId: id,
            })
          )
      );
    },
    [createDataset, navigate, currentWorkspace.name]
  );

  return (
    <PopupForm<IDatasetCreationSettings>
      initialValues={initialSettings}
      onSubmit={onSubmit}
      communication={creatingDataset}
      title="Create dataset"
      {...props}
    >
      {() => (
        <>
          <TextInputField
            name={getFieldName({ name: null })}
            label="Dataset name"
            isRequired={true}
            validate={validateNotEmpty('Dataset name')}
          />
          <TextInputField
            name={getFieldName({ workspace: { name: null } })}
            label="Workspace"
            disabled={true}
          />
          <TextInputField
            name={getFieldName({ description: null })}
            label="Description"
            validate={validateDescription}
          />
          <TagsField name={getFieldName({ tags: null })} />
        </>
      )}
    </PopupForm>
  );
};

export default DatasetCreationPopup;
