import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './actions';
import { IBuildErrorTrackerState } from './types';

const initialState: IBuildErrorTrackerState = {
  errorInfo: null,
};

export default createReducer<
  IBuildErrorTrackerState,
  ActionType<typeof actions>
>(initialState).handleAction(actions.changeOpenErrorModal, (state, action) => ({
  ...state,
  errorInfo: action.payload,
}));
