import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import Button from 'shared/view/elements/Button/Button';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { CreateGroupPopup } from 'features/organizations/groups/view/CreateGroupPopup';
import OrganizationGroupsTable from 'features/organizations/groups/view/OrganizationGroupsTable';
import useOrganizationGroups from 'features/organizations/groups/store/useOrganizationGroups';
import { trackEvent } from 'setup/app/analytics';

import OrganizationLayout from '../shared/OrganizationLayout';

const OrganizationGroupsPage = () => {
  const { name } = useRouteParams(routes.organization);
  const groupsApi = useOrganizationGroups(name);

  return (
    <OrganizationLayout
      pageEntityOrError={groupsApi.data}
      actions={(() => {
        const organization = groupsApi.data;
        return organization?.__typename === 'OrganizationV2'
          ? [
              <ButtonWithPopup
                key={0}
                button={(props) => (
                  <Button
                    {...props}
                    onClick={() => {
                      trackEvent({
                        type: 'organization.groups.create_clicked',
                      });
                      props.onClick();
                    }}
                    dataTest="open-create-group-popup"
                  >
                    Create new group
                  </Button>
                )}
                popup={(props) => (
                  <CreateGroupPopup organization={organization} {...props} />
                )}
              />,
            ]
          : undefined;
      })()}
    >
      <DefaultMatchRemoteDataOrError
        {...groupsApi}
        context="loading organization"
      >
        {(loadedOrganization) => (
          <div>
            <OrganizationGroupsTable
              {...groupsApi}
              organization={loadedOrganization}
            />
          </div>
        )}
      </DefaultMatchRemoteDataOrError>
    </OrganizationLayout>
  );
};

export default OrganizationGroupsPage;
