import * as React from 'react';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import InlineErrorView from 'shared/view/elements/Errors/InlineErrorView/InlineErrorView';
import { useCompareDatasetVersions } from 'features/compareDatasetVersions/store/compareDatasetVersions/compareDatasetVersions';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { useDatasetPageBreadcrumbs } from 'pages/authorized/DatasetPages/DatasetDetailPages/shared/DatasetDetailsLayout/DatasetDetailsLayout';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
import { makeRegularAttribute } from 'shared/models/AttributeWithWidgetBlob';

const CompareDatasetVersions = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'CompareDatasetVersions' */ 'features/compareDatasetVersions/view/CompareDatasetVersions/CompareDatasetVersions'
    )
);

const CompareDatasetVersionsPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { datasetId, datasetVersionId1, datasetVersionId2 } = useRouteParams(
    routes.compareDatasetVersions
  );

  const { communication, data } = useCompareDatasetVersions({
    datasetId,
    firstVersionId: datasetVersionId1,
    secondVersionId: datasetVersionId2,
  });

  // ########## REVIEW BREADCRUMB
  const breadcrumbs = useAppBreadcrumbs(
    useDatasetPageBreadcrumbs({
      datasetId,
    }),
    useBreadcrumbItem({
      name: 'Datasets versions: Compare',
      route: routes.compareDatasetVersions,
    })
  );

  return (
    <AuthorizedLayout pageEntityOrError={data} breadcrumbs={breadcrumbs}>
      <DefaultMatchRemoteDataOrError
        data={data}
        communication={communication}
        context="loading dataset versions"
      >
        {({ dataset, firstVersion, secondVersion }) => (
          <div style={{ width: '100%' }}>
            {firstVersion && secondVersion ? (
              <CompareDatasetVersions
                dataset={dataset}
                firstVersion={{
                  ...firstVersion,
                  attributes: [
                    {
                      key: 'list',
                      value: ['adfadf', 'zvzcv'],
                    },
                    makeRegularAttribute({
                      key: 'the-same',
                      value: 'apple',
                    }),
                  ],
                }}
                secondVersion={{
                  ...secondVersion,
                  attributes: [
                    {
                      key: 'list',
                      value: ['adfadf', 'zvzcv'],
                    },
                    makeRegularAttribute({
                      key: 'the-same',
                      value: 'apple',
                    }),
                  ],
                }}
              />
            ) : (
              <InlineErrorView error="one of dataset versions is not found" />
            )}
          </div>
        )}
      </DefaultMatchRemoteDataOrError>
    </AuthorizedLayout>
  );
};

export default CompareDatasetVersionsPage;
