import { WorkspaceV2Input } from 'generated/types';

import { OrganizationWorkspacesV2 } from '../store/useOrganizationWorkspaces';
import { useAddWorkspace } from '../store/useAddWorkspace';
import WorkspacePopup from './WorkspacePopup';

const AddWorkspacePopup = ({
  data,
  onClose,
}: {
  onClose(): void;
  data: Pick<OrganizationWorkspacesV2, 'id' | 'groups' | 'roles'>;
}) => {
  const api = useAddWorkspace(onClose);

  return (
    <WorkspacePopup
      groups={data.groups}
      roles={data.roles}
      title="Create workspace"
      submitButton={{
        isLoading: api.communication.isRequesting,
        children: 'Create',
        onSubmit: (form) => {
          api.run({
            organizationId: data.id,
            input: form as WorkspaceV2Input,
          });
        },
      }}
      settings={{
        name: '',
        description: '',
        namespace: '',
        permissions: [
          {
            groupId: undefined,
            roleId: undefined,
          },
        ],
      }}
      onClose={onClose}
    />
  );
};

export default AddWorkspacePopup;
