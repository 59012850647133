import { ReactNode } from 'react';
import ReactGridLayout, { Layout } from 'react-grid-layout';
import { useResizeDetector } from 'react-resize-detector';
import { styled } from '@mui/material';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { draggableHandleClassName } from 'shared/view/elements/IconAwesome/IconAwesomeDraggable';

interface Props {
  draggableHandleClassName: string;
  layout: Layout[];
  children: ReactNode[];
  onLayoutChange(layout: Layout[]): void;
}

const MonitoringWidgetsGridLayout = (props: Props) => {
  const { width = 0, ref } = useResizeDetector();

  return (
    <Root ref={ref}>
      <ReactGridLayout
        {...props}
        width={width}
        cols={12}
        rowHeight={30}
        isResizable={true}
        isDraggable={true}
        draggableHandle={`.${draggableHandleClassName}`}
        useCSSTransforms={true}
        containerPadding={[0, 0]}
      />
    </Root>
  );
};

const Root = styled('div')(({ theme }) => {
  return {
    '& .react-grid-item.react-grid-placeholder': {
      ...theme.palette.gridLayout.placeholder,
      position: 'absolute',
      boxSizing: 'border-box',
      opacity: 1,
      borderRadius: '4px',
    },
  };
});

export default MonitoringWidgetsGridLayout;
