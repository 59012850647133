import { v4 as uuidv4 } from 'uuid';
import { Layout } from 'react-grid-layout';

import { MonitoringDriftMetricType } from 'generated/types';

import { MonitoringWidget } from './MonitoringWidget/MonitoringWidget';
import { summaryPanelId } from '../defaultModelState/presetDashboards/shared/presetSummaryPanel';
import { MonitoringIODescription } from '../MonitoringIODescription';

export interface BaseMonitoringPanel<Type extends string> {
  id: string;
  name: string;
  layout: Layout[];
  isOpen: boolean;
  type: Type;
}

export interface DriftMonitoringPanel extends BaseMonitoringPanel<'drift'> {
  driftMetricType: MonitoringDriftMetricType;
  ioDescription: MonitoringIODescription | undefined;
}

export interface OutliersMonitoringPanel
  extends BaseMonitoringPanel<'outliers'> {
  ioDescription: MonitoringIODescription | undefined;
}

export interface CustomMonitoringPanel extends BaseMonitoringPanel<'custom'> {
  widgets: MonitoringWidget[];
}

export type MonitoringPanel =
  | DriftMonitoringPanel
  | OutliersMonitoringPanel
  | CustomMonitoringPanel;

export const makeNewMonitoringPanel = (name: string): CustomMonitoringPanel => {
  return {
    id: uuidv4(),
    name,
    layout: [],
    widgets: [],
    isOpen: true,
    type: 'custom',
  };
};

export const insertNewPanel = (
  panel: MonitoringPanel,
  panels: MonitoringPanel[]
) => {
  const summaryPanel = panels.find((p) => p.id === summaryPanelId);

  if (summaryPanel) {
    const panelsWithoutSummaryPanel = panels.filter(
      (p) => p.id !== summaryPanelId
    );
    return [summaryPanel, panel, ...panelsWithoutSummaryPanel];
  }

  return [panel, ...panels];
};
