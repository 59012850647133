import { FC, ReactElement, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import routes from 'shared/routes';
import { selectCurrentUser } from 'features/user';
import { selectFlags } from 'features/flags';
import GlobalPreloader from 'shared/view/elements/GlobalPreloader/GlobalPreloader';
import { loadServiceFlags } from 'features/flags';
import * as analytics from 'setup/app/analytics';
import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';
import { loadOrganizationWorkspaces } from 'features/workspaces/store/actions/actions';

// temporally workaround to add authorized layout to all pages of app
// we need to move from
// import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
// to the new AuthorizedLayout from 'shared/routes/layouts/AuthorizedLayout'
// to do that we need to investigate page by page, adjusting the properties and creating
// proper layouts and request

type AuthorizedDataLoaderProps = {
  currentUser: CurrentUserData;
};

const AuthorizedDataLoader: FC<
  React.PropsWithChildren<AuthorizedDataLoaderProps>
> = ({ children, currentUser }): ReactElement => {
  const { isSentryEnabled, segmentWriteKey, isEnableFreeTier } =
    useSelector(selectFlags);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const loadInitialData = async () => {
      const response = (await dispatch(
        loadOrganizationWorkspaces({ currentUser })
      )) as unknown as { payload: { organizationWorkspaces?: [] } };

      if (
        isEmpty(response.payload.organizationWorkspaces) &&
        isEnableFreeTier
      ) {
        navigate(routes.signUpPersonalInfoForm.getPath());
      } else {
        await dispatch(loadServiceFlags());
      }

      setIsLoading(false);
    };

    loadInitialData();
  }, [currentUser, dispatch, isEnableFreeTier, navigate]);

  useEffect(() => {
    if (isSentryEnabled) Sentry.setUser({ id: currentUser.id });
  }, [currentUser.id, isSentryEnabled]);

  useEffect(() => {
    if (segmentWriteKey) analytics.identify(currentUser.id, currentUser.email);
  }, [currentUser.email, currentUser.id, segmentWriteKey]);

  // workaround to use Outlet and render old layout
  return isLoading ? (
    <GlobalPreloader />
  ) : children !== undefined ? (
    <>{children}</>
  ) : (
    <Outlet />
  );
};

export const TempAuthorizedLayout: FC<React.PropsWithChildren<unknown>> = (
  props
): ReactElement => {
  const currentUser = useSelector(selectCurrentUser);

  if (!currentUser) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    const searchParams = new URLSearchParams();
    searchParams.set('redirect_back', window.location.pathname);

    return (
      <Navigate
        to={{
          pathname: routes.login.getRedirectPath({}),
          search: String(searchParams),
        }}
      />
    );
  }

  return <AuthorizedDataLoader {...props} currentUser={currentUser} />;
};
