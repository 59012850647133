import { useCallback, useState, forwardRef } from 'react';
import { isNil } from 'lodash';
import TextField from '@mui/material/TextField';

import {
  InputBase,
  InputCommonProps,
  useInputCommonProps,
} from 'shared/view/elements/TextInput/shared/InputBase';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import isNotNil from 'shared/utils/isNotNill';

import { ICONS } from '../IconAwesome/ICONS';

export type TextInputProps = InputCommonProps & {
  value?: string;
  onChange(value: string): void;
  type?: 'input' | 'password' | 'number';
  multiline?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  maxLength?: number;
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const { type, passwordAdornmentIcon } = useHidden(
    props.type ? props.type : 'input'
  );

  const { baseProps, fieldProps } = useInputCommonProps(
    {
      ...props,
      endAdornmentIcons: [
        ...(props.endAdornmentIcons ?? []),
        passwordAdornmentIcon,
      ].filter(isNotNil),
    },
    props.inputProps
  );

  const { onChange, maxLength } = props;
  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const inputValue = e.target.value;
      if (!isNil(maxLength) && inputValue.length > maxLength) {
        return;
      }
      onChange(inputValue);
    },
    [onChange, maxLength]
  );

  const value = props.value ?? '';

  return (
    <InputBase {...baseProps}>
      <TextField
        {...fieldProps}
        inputRef={ref}
        onChange={onChangeInput}
        value={value}
        type={type}
        multiline={props.multiline}
      />
    </InputBase>
  );
});

function useHidden(type: 'input' | 'password' | 'number') {
  const [isValueHidden, setIsValueHidden] = useState(type === 'password');

  const toggleValueHidden = useCallback(() => {
    setIsValueHidden(!isValueHidden);
  }, [isValueHidden]);

  const passwordAdornmentIcon =
    type === 'password' ? (
      <IconAwesomeClickable
        dataTest="toggle-password"
        key={'password'}
        icon={isValueHidden ? ICONS.eye : ICONS.eyeSlash}
        onClick={toggleValueHidden}
      />
    ) : null;

  return {
    type: !isValueHidden && type === 'password' ? 'input' : type,
    passwordAdornmentIcon,
  };
}

export default TextInput;
