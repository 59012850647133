import { formatWithDefaultPrecision } from 'shared/utils/formatters/formatWithDefaultPrecision';

import {
  IFloatHistogramBlob,
  IDiscreteHistogramBlob,
  INumericValueBlob,
} from '../../shared/models/Widget/WidgetBlob';

export interface IServerDiscreteHistogramBlobData {
  data: number[];
  buckets: Array<string | number>;
}

export interface IServerFloatHistogramBlobData {
  data: number[];
  bucketLimits: number[];
}

export interface IServerNumericValueBlobData {
  value: number;
  unit?: string;
}

export interface IServerStringValueBlobData {
  value: string;
}

export interface IServerTableValueBlobData {
  header: string[] | undefined;
  rows: string[][];
}

export const parseFloatHistogram = (
  server: IServerFloatHistogramBlobData
): IFloatHistogramBlob => ({
  type: 'float',
  data: {
    data: server.data,
    buckets: convertServerNumberBuckets(server.bucketLimits),
  },
});

export const parseDiscreteHistogram = (
  server: IServerDiscreteHistogramBlobData
): IDiscreteHistogramBlob => ({
  type: 'discrete',
  data: {
    data: server.data,
    buckets: server.buckets,
  },
});

export const parseNumericValue = (
  server: IServerNumericValueBlobData
): INumericValueBlob => ({
  type: 'numeric',
  data: {
    value: Number(formatWithDefaultPrecision(server.value)),
    unit: server.unit,
  },
});

const convertServerNumberBuckets = (buckets: number[]) => {
  return buckets.map((b) => Number(formatWithDefaultPrecision(b)));
};
