import { AlertStatus } from 'generated/types';
import matchType from 'shared/utils/matchType';
import { InfoColorType } from 'shared/view/elements/IconAwesome/hooks/useIconAwesomeColorPalette';

export const alertStatusesForSelection = [AlertStatus.OK, AlertStatus.PAUSED];

export const getAlertStatusLabel = (status: AlertStatus) => {
  return matchType(
    {
      [AlertStatus.OK]: () => 'OK',
      [AlertStatus.PAUSED]: () => 'Pause',
      [AlertStatus.ALERTING]: () => 'Alert',
      [AlertStatus.NODATA]: () => 'No data',
      [AlertStatus.UNKNOWN]: () => 'Unknown',
    },
    status
  );
};

export const getAlertStatusInfoType = (status: AlertStatus): InfoColorType =>
  matchType<AlertStatus, InfoColorType>(
    {
      [AlertStatus.OK]: () => 'success',
      [AlertStatus.PAUSED]: () => 'default',
      [AlertStatus.ALERTING]: () => 'error',
      [AlertStatus.NODATA]: () => 'nodata',
      [AlertStatus.UNKNOWN]: () => 'default',
    },
    status
  );
