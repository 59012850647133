import {
  Box,
  BoxProps,
  styled,
  Typography,
  Card as MuiCard,
  Stack,
  alpha,
} from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { get, round } from 'lodash';

import { transientOptions } from 'shared/utils/transientOptions';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

const IconWrapper = styled(
  ({
    icon,
    color,
    ...props
  }: BoxProps & { icon: IconDefinition; isDisabled?: boolean }) => (
    <Box {...props}>
      <IconAwesomeInfo icon={icon} size="lg" color={color} />
    </Box>
  ),
  transientOptions
)(({ theme, color }) => ({
  borderRadius: '4px',
  backgroundColor:
    typeof color === 'string'
      ? alpha(get(theme.palette, color), 0.1)
      : undefined,
  width: '28px',
  height: '28px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Card = styled(MuiCard)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  height: '135px',
  display: 'flex',
  flexDirection: 'column',
}));

type Props = {
  total: number;
  approved: number;
  rejected: number;
};

export const ResultCards = (props: Props) => {
  const { total, approved, rejected } = props;
  return (
    <Stack spacing={2}>
      <Card variant="outlined">
        <Stack direction="row" spacing={2}>
          <Box display="flex" justifyContent="space-between">
            <IconWrapper icon={ICONS.check} color="success.main" />
          </Box>
          <Stack>
            <Typography variant="subtitle1" color="text.secondary">
              Accepted evaluations
            </Typography>
            <Stack direction="row" alignItems="end">
              <Typography variant="h3">{approved}</Typography>
              <Typography variant="h6" color="text.disabled">
                ({round((approved * 100) / total, 2)}%)
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Card>
      <Card variant="outlined">
        <Stack direction="row" spacing={2}>
          <Box display="flex" justifyContent="space-between">
            <IconWrapper icon={ICONS.xMark} color="error.main" />
          </Box>
          <Stack>
            <Typography variant="subtitle1" color="text.secondary">
              Rejected evaluations
            </Typography>
            <Stack direction="row" alignItems="end">
              <Typography variant="h3">{rejected}</Typography>
              <Typography variant="h6" color="text.disabled">
                ({round((rejected * 100) / total, 2)}%)
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};
