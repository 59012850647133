import { RegisteredModelVersionLockLevel } from 'generated/types';
import matchType from 'shared/utils/matchType';

export const canUserDeleteRegisteredModelVersion = ({
  lockLevel,
  allowedActions,
}: {
  lockLevel: RegisteredModelVersionLockLevel;
  allowedActions: {
    updateRedact: boolean;
    delete: boolean;
  };
}) =>
  matchType(
    {
      UNKNOWN: () => true,
      OPEN: () => allowedActions.delete,
      CLOSED: () => false,
      REDACT: () => allowedActions.updateRedact && allowedActions.delete,
    },
    lockLevel
  );

export const canUserUpdateRegisteredModelVersion = ({
  update,
}: {
  update: boolean;
}) => update;
