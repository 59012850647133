import { map } from 'ramda';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Location } from 'react-router-dom';

import { validateNotEmpty } from 'shared/utils/validators';
import { ILoginByLdap } from 'shared/models/Authorization';
import { IApplicationState } from 'setup/store/store';
import { loginByLdap, selectCommunications } from 'features/user/store';
import { authErrorMessages } from 'shared/utils/customErrorMessages';
import routes from 'shared/routes';
import { selectFlags } from 'features/flags';
import { toastCommunicationErrorActionCreator } from 'features/toast/store/actions';
import Popup from 'shared/view/elements/Popup/Popup';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import FormStack from 'shared/view/elements/FormStack/FormStack';

import { IField, makeField } from '../formHelpers';

interface ILocalProps {
  isOpen: boolean;
  onClose(): void;
  location: Location;
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    isEnabledExternalAuth: selectFlags(state).isEnableExternalAuth,
    loginByLdapCommunication: selectCommunications(state).loginByLdap,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      loginByLdap,
      toastCommunicationError: toastCommunicationErrorActionCreator,
    },
    dispatch
  );
};

type AllProps = ILocalProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type ILoginFormField = IField<keyof ILoginByLdap>;

type ILoginByLdapForm = { [K in keyof ILoginByLdap]: ILoginFormField };

interface ILocalState {
  form: ILoginByLdapForm;
}

const initialForm: ILoginByLdapForm = {
  username: makeField<ILoginFormField>({
    name: 'username',
    value: '',
    validate: validateNotEmpty('username'),
  }),
  password: makeField<ILoginFormField>({
    name: 'password',
    value: '',
    validate: validateNotEmpty('password'),
  }),
  organizationUnit: makeField<ILoginFormField>({
    name: 'organizationUnit',
    value: '',
    validate: () => undefined,
  }),
};

class LoginForm extends PureComponent<AllProps, ILocalState> {
  declare context: any;
  public state: ILocalState = {
    form: initialForm,
  };

  public componentDidUpdate(prevProps: AllProps) {
    if (
      !prevProps.loginByLdapCommunication.error &&
      this.props.loginByLdapCommunication.error
    ) {
      this.props.toastCommunicationError(
        this.props.loginByLdapCommunication.error,
        {
          context: 'logging in',
          customErrorMessageByType: {
            invalidEmailOrPassword: authErrorMessages.invalidEmailOrPassword,
          },
        }
      );
    }
  }

  public render() {
    const { isOpen, onClose, loginByLdapCommunication, location } = this.props;
    const { form } = this.state;
    const queryParams = routes.login.parseQueryParams(location.search);

    return (
      <form method="post" onSubmit={this.onSubmit}>
        <Popup
          title="Active Directory login"
          isOpen={isOpen}
          onClose={onClose}
          buttons={{
            mainButtonProps: {
              type: 'button',
              isLoading: loginByLdapCommunication.isRequesting,
              disabled: Object.values(form).some(({ error }) => Boolean(error)),
              dataTest: 'submit',
              children: 'Login',
              onClick: this.onSubmit,
            },
          }}
        >
          <FormStack>
            {/* Adding 'returnTo' to formData  */}
            <input
              value={queryParams?.redirect_back || '/'}
              name="returnTo"
              type="hidden"
            />
            <this.TextField
              label="Username"
              name="username"
              field={form.username}
              isRequired={true}
            />
            <this.TextField
              label="Password"
              name="password"
              field={form.password}
              type={'password'}
              isRequired={true}
            />
            <this.TextField
              label="Organization unit"
              name="organizationUnit"
              field={form.organizationUnit}
            />
          </FormStack>
        </Popup>
      </form>
    );
  }

  private onSubmit = () => {
    const { isEnabledExternalAuth } = this.props;
    if (!isEnabledExternalAuth) {
      this.tryLoginByLdap();
    }
  };

  private TextField = ({
    label,
    field,
    type,
    name,
    isRequired,
  }: {
    label: string;
    field: ILoginFormField;
    name: string;
    type?: 'input' | 'password';
    isRequired?: boolean;
  }) => {
    const changeValue = (value: string) =>
      this.updateField(field.name, (targetField) => ({
        ...targetField,
        value,
        error: targetField.validate(value),
      }));
    const handleBlur = () =>
      this.updateField(field.name, (targetField) => ({
        ...targetField,
        isTouched: true,
      }));

    return (
      <TextInput
        isRequired={isRequired}
        value={field.value}
        label={label}
        type={type}
        name={name}
        onChange={changeValue}
        onBlur={handleBlur}
        meta={{ error: field.error, touched: field.isTouched }}
      />
    );
  };

  private updateField = <T extends keyof ILoginByLdapForm>(
    type: T,
    f: (field: ILoginByLdapForm[T]) => ILoginByLdapForm[T]
  ) => {
    this.setState({
      form: {
        ...this.state.form,
        [type]: f(this.state.form[type]),
      },
    });
  };

  private tryLoginByLdap = () => {
    if (Object.values(this.state.form).some(({ error }) => Boolean(error))) {
      this.setState({
        form: map(
          (field) => ({
            ...field,
            isTouched: true,
            error: field.validate(field.value),
          }),
          this.state.form
        ),
      });
    } else {
      const queryParams = routes.login.parseQueryParams(
        this.props.location.search
      );
      this.props.loginByLdap({
        data: map(({ value }) => value, this.state.form),
        redirectBack: queryParams?.redirect_back,
      });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
