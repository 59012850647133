import { Stack } from '@mui/material';

import { makeGetFieldName } from 'shared/utils/getFieldName';
import Popup from 'shared/view/elements/Popup/Popup';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import {
  validateMaxLength,
  combineValidators,
  validateNotEmpty,
} from 'shared/utils/validators';

export type ModelRequestForm = {
  name: string;
};

type Props = {
  title: string;
  initialValues: ModelRequestForm;
  isOpen: boolean;
  onClose(): void;
  onSubmit(values: ModelRequestForm): void;
  submitButton: {
    children: 'Save' | 'Create';
    isLoading: boolean;
  };
};

const getFieldName = makeGetFieldName<ModelRequestForm>();

const ProjectPopup = ({
  title,
  onClose,
  onSubmit,
  submitButton,
  initialValues,
  isOpen,
}: Props) => {
  return (
    <PresetFormik<ModelRequestForm>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isValid, submitForm }) => (
        <Popup
          title={title}
          isOpen={isOpen}
          onClose={onClose}
          maxWidth="xs"
          fullWidth
          buttons={{
            secondaryButtonProps: {
              children: 'Cancel',
              dataTest: 'evaluation-create-project-action-cancel',
              isLoading: false,
              onClick: onClose,
            },
            mainButtonProps: {
              ...submitButton,
              disabled: !isValid,
              type: 'submit',
              dataTest: 'evaluation-create-project-action-confirm',
              onClick: submitForm,
            },
          }}
        >
          <Stack direction={'column'} spacing={2}>
            <TextInputField
              name={getFieldName({ name: null })}
              label="Project name"
              hint="Max. 100 characters"
              isRequired
              validate={combineValidators([
                validateNotEmpty('Project name'),
                validateMaxLength(100),
              ])}
            />
          </Stack>
        </Popup>
      )}
    </PresetFormik>
  );
};

export default ProjectPopup;
