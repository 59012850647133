import euiDatemath from '@elastic/datemath';

import { IDateRange as DateRange } from 'shared/utils/DateRange';

export interface TimeRange {
  start: string;
  end: string;
}

const parseTimeString = (timeString: string, isEndString: boolean): Date => {
  const res = euiDatemath.parse(timeString, { roundUp: isEndString });

  if (res?.isValid()) {
    return res.toDate();
  }

  throw new TypeError('Incorrect time string');
};

export const convertTimeRangeToDateRange = (
  timeRange: TimeRange
): DateRange => {
  return {
    from: parseTimeString(timeRange.start, false),
    to: parseTimeString(timeRange.end, true),
  };
};

export const last30days: TimeRange = {
  start: 'now-30d',
  end: 'now',
};
