import ConfirmPopup from 'shared/view/elements/ConfirmAction/ConfirmPopup/ConfirmPopup';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';

import { OrganizationGroups } from '../store/useOrganizationGroups';
import GroupPopup, { GroupSettings } from './GroupPopup';
import { useDeleteGroup } from '../store/useDeleteGroup';
import { useUpdateGroup } from '../store/useUpdateGroup';

export const DetailsGroupPopup = ({
  users,
  settings,
  allowedActions,
  onClose,
}: {
  users: OrganizationGroups['users']['users'];
  settings: GroupSettings & {
    id: string;
    orgId: string;
    builtIn: boolean;
    orgName: string;
  };
  allowedActions: { update: boolean; delete: boolean };
  onClose(): void;
}) => {
  const updateGroupApi = useUpdateGroup(onClose);
  const deleteGroupApi = useDeleteGroup(onClose);
  const deletePopupApi = usePopupManager();

  return (
    <>
      {deletePopupApi.isPopupOpen && (
        <ConfirmPopup
          title="Delete group"
          confirmButtonText="Delete"
          longPressConfirmation={true}
          isOpen={deletePopupApi.isPopupOpen}
          onCancel={deletePopupApi.closePopup}
          onConfirm={() => {
            deleteGroupApi.run({
              groupId: settings.id,
              organizationName: settings.orgName,
            });
            deletePopupApi.closePopup();
          }}
        >
          Are you sure you want to delete the <b>{settings.name}</b>?
        </ConfirmPopup>
      )}
      <GroupPopup
        title="Edit group"
        users={users}
        builtIn={settings.builtIn}
        disabled={!allowedActions.update}
        tertiaryButton={{
          children: 'Delete',
          isLoading: deleteGroupApi.communication.isRequesting,
          disabled: !allowedActions.delete,
          onClick: deletePopupApi.openPopup,
        }}
        submitButton={{
          children: 'Update',
          isLoading: updateGroupApi.communication.isRequesting,
          onSubmit: (form) =>
            updateGroupApi.run({
              description: form.description,
              memberIds: form.memberIds,
              name: form.name,
              organizationId: settings.orgId,
              groupId: settings.id,
            }),
        }}
        initialSettings={settings}
        onClose={onClose}
      />
    </>
  );
};
