import { Box, Typography } from '@mui/material';

import { KafkaConfigurationInput } from 'generated/types';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import { validateNotEmpty } from 'shared/utils/validators';
import Button from 'shared/view/elements/Button/Button';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import InfoIcon from 'shared/view/elements/InfoIcon/InfoIcon';

import { FileWithTextAreaField } from './FileWithTextAreaField';
import ConditionalFields from './ConditionalFields';

type Props = {
  initialValues: KafkaConfigurationInput;
  testingKafkaConfigurationLoading: boolean;
  isValid: boolean;
  testKafkaConfiguration(): void;
  deleteDisablingReason?: string;
  enabledDisablingReason?: string;
};

const getFieldName = makeGetFieldName<KafkaConfigurationInput>();

const KafkaConfigurationForm = (props: Props) => {
  return (
    <FormStack minHeight="550px" mt={2}>
      <TextInputField
        withoutError
        label={'Name'}
        name={getFieldName({ name: null })}
        isRequired={true}
        validate={validateNotEmpty('Name')}
        endAdornmentIcons={[
          <InfoIcon
            key={'info'}
            tooltip={'Enter a name for the Kafka integration.'}
            tooltipPlacement="left"
          />,
        ]}
      />
      <TextInputField
        withoutError
        label={'Broker addresses'}
        endAdornmentIcons={[
          <InfoIcon
            key={'info'}
            tooltip={
              'Kafka broker allows consumers to fetch messages by topic, partition and offset. Enter address of the Kafka broker. BROKER1:PORT1,BROKER2:PORT2'
            }
            tooltipPlacement="left"
          />,
        ]}
        name={getFieldName({ brokerAddresses: null })}
        isRequired={true}
        validate={validateNotEmpty('Broker addresses')}
      />
      <TLSFields initialValues={props.initialValues.tls} />
      <KerberosFields initialValues={props.initialValues.kerberos} />
      <Box>
        <Typography my={2} variant="subtitle2">
          Test Kafka Connection
        </Typography>
        <Button
          key={'Test'}
          onClick={props.testKafkaConfiguration}
          disabled={!props.isValid}
          variant="outlined"
          isLoading={props.testingKafkaConfigurationLoading}
        >
          Run Test
        </Button>
      </Box>
    </FormStack>
  );
};

const TLSFields = ({
  initialValues,
}: {
  initialValues: KafkaConfigurationInput['tls'];
}) => {
  return (
    <ConditionalFields<KafkaConfigurationInput, 'tls'>
      name="tls"
      title="TLS"
      tooltip="Transport Layer Security (TLS) will be used for server authentication and encryption."
      initialValues={
        initialValues || {
          certificate: '',
        }
      }
      fields={[
        {
          name: 'certificate',
          content: (name) => (
            <FileWithTextAreaField
              name={name}
              label="Certificate*"
              fileFormat="text"
            />
          ),
        },
      ]}
    />
  );
};

const KerberosFields = ({
  initialValues,
}: {
  initialValues: KafkaConfigurationInput['kerberos'];
}) => {
  return (
    <ConditionalFields<KafkaConfigurationInput, 'kerberos'>
      name="kerberos"
      title="Kerberos"
      tooltip="Enable Kerberos network authentication protocol to establish connection between Verta and Kafka cluster."
      initialValues={
        initialValues || {
          conf: '',
          keytab: '',
          clientName: '',
          serviceName: '',
        }
      }
      fields={[
        {
          name: 'clientName',
          content: (name) => (
            <TextInputField
              maxWidth="90%"
              label={'Client name'}
              name={name}
              validate={validateNotEmpty('Client name')}
              isRequired={true}
              withoutError
            />
          ),
        },
        {
          name: 'serviceName',
          content: (name) => (
            <TextInputField
              maxWidth="90%"
              label={'Service name'}
              name={name}
              validate={validateNotEmpty('Service name')}
              isRequired={true}
              withoutError
            />
          ),
        },
        {
          name: 'conf',
          content: (name) => (
            <FileWithTextAreaField
              name={name}
              label="Krb5.conf*"
              fileFormat="text"
              info="The krb5.conf file contains Kerberos configuration information. Upload or paste your file."
              accept=".conf,.config"
            />
          ),
        },
        {
          name: 'keytab',
          content: (name) => (
            <FileWithTextAreaField
              name={name}
              label="Keytab"
              fileFormat="base64"
              info="Keytab stores your keys and can be a binary file. Please upload or paste your Keytab file."
            />
          ),
        },
      ]}
    />
  );
};

export default KafkaConfigurationForm;
