import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material';
import { head } from 'ramda';

import { useSingleMetric } from 'features/monitoring/widgets/store/singleMetric/useSingleMetric';
import { MonitoringMetricType } from 'generated/types';
import { getLabelByMonitoringMetricType } from 'shared/models/Monitoring/MonitoringMetricType';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { Button } from 'shared/view/elements/Button';
import { formatWithDefaultPrecision } from 'shared/utils/formatters/formatWithDefaultPrecision';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';

interface Props {
  metricType: MonitoringMetricType;
  icon: IconDefinition;
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  output: MonitoringIODescription;
  changeSelectedMetricType: (metricType: MonitoringMetricType) => void;
  isSelected: boolean;
}

const StyledBox = styled(Box)(({ isSelected }: { isSelected: boolean }) => ({
  borderRadius: '4px',
  height: '142px',
  width: '347px',
  border: '1px solid transparent',
  backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
  '&:hover': {
    border: '1px solid rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '& div': {
      opacity: 1,
    },
  },
  padding: '24px 32px',
}));

const MonitoringPerformanceCard = (props: Props) => {
  const { data, communication } = useSingleMetric({
    metricType: props.metricType,
    output: props.output,
    widgetExternalDeps: props.widgetExternalDeps,
  });

  return (
    <StyledBox isSelected={props.isSelected}>
      <DefaultMatchRemoteDataOrError
        data={data}
        communication={communication}
        context="loading metric"
      >
        {(metrics) => {
          const metric = head(metrics);
          return metric ? (
            <CardContent
              icon={props.icon}
              metricType={props.metricType}
              value={Number(
                formatWithDefaultPrecision(parseGraphQLNumber(metric.value))
              )}
              changeSelectedMetricType={props.changeSelectedMetricType}
            />
          ) : null;
        }}
      </DefaultMatchRemoteDataOrError>
    </StyledBox>
  );
};

const IconWrapper = styled(Grid)({
  paddingTop: '5px',
  display: 'inline-block',
});

const Row = styled(Grid)({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
});

const IconLabel = styled(Grid)({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  marginLeft: '7px',
});

const SeeDetails = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end',
  opacity: 0,
});

const CardContent = (props: {
  value: string | number;
  icon: IconDefinition;
  metricType: MonitoringMetricType;
  changeSelectedMetricType: (metricType: MonitoringMetricType) => void;
}) => (
  <Grid container>
    <Row>
      <IconWrapper xs={1}>
        <IconAwesomeCustomizable
          size="lg"
          icon={props.icon}
          nativeProps={{
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        />
      </IconWrapper>
      <IconLabel xs={6}>
        <Typography variant="subtitle1">
          {getLabelByMonitoringMetricType(props.metricType)}
        </Typography>
        <Box>
          <Typography variant="h3">{props.value}</Typography>
        </Box>
      </IconLabel>

      <SeeDetails xs={5}>
        <Button
          variant="text"
          onClick={() => props.changeSelectedMetricType(props.metricType)}
          isLoading={false}
        >
          See details
        </Button>
      </SeeDetails>
    </Row>
  </Grid>
);

export default MonitoringPerformanceCard;
