import cn from 'classnames';

// eslint-disable-next-line postcss-modules/no-unused-class
import styles from '../CompareTable.module.css';

interface LocalProps {
  name: string;
  isDifferent: boolean;
}

export const RowWithSingleColumn = (props: LocalProps) => {
  return (
    <tr
      className={cn(styles.rowDiff, {
        [styles.different]: props.isDifferent,
      })}
    >
      <td className={styles.rowDiff__name}>{props.name}</td>
      <td colSpan={1000} />
    </tr>
  );
};
