import { AppError } from 'shared/models/Error';

import { ICommunication } from './types';

export const initialCommunication: ICommunication = {
  isRequesting: false,
  isSuccess: false,
  error: undefined,
  isPolling: false,
};
export const requestingCommunication: ICommunication = {
  isRequesting: true,
  isSuccess: false,
  error: undefined,
  isPolling: false,
};
export const successfulCommunication: ICommunication = {
  isRequesting: false,
  error: undefined,
  isSuccess: true,
  isPolling: false,
};
export const pollingSuccessfulCommunication: ICommunication = {
  isRequesting: false,
  error: undefined,
  isSuccess: true,
  isPolling: true,
};

export const makeWithPollingErrorCommunication = <T extends AppError>(
  error: T,
  isPolling: boolean
): ICommunication<T> => {
  return { isSuccess: false, isRequesting: false, error, isPolling };
};
export const makeErrorCommunication = <T extends AppError>(
  error: T
): ICommunication<T> => {
  return { isSuccess: false, isRequesting: false, error, isPolling: false };
};
