import { values } from 'ramda';
import { useMemo } from 'react';

import { mapObj } from 'shared/utils/collection';
import UnionFields, {
  UnionFieldsInitialValuesMap,
} from 'shared/view/formComponents/UnionFields/UnionFields';
import { IOptionType } from 'shared/view/elements/Selects/shared/types';
import Select from 'shared/view/elements/Selects/Select/Select';

interface VariantProps<Union extends { type: string }> {
  label: string;
  render: (value: Union) => void;
  initialValue: Union;
}

export type VariantMatchers<Union extends { type: string }> = {
  [K in Union['type']]: VariantProps<Extract<Union, { type: K }>>;
};

interface Props<Union extends { type: string }> {
  value: Union;
  onChange: (value: Union) => void;
  variantTypeFieldName: string;
  variants: VariantMatchers<Union>;
}

const makeOption = <Union extends { type: string }>(
  variantProps: VariantProps<Union>
): IOptionType<Union> => ({
  label: variantProps.label,
  value: variantProps.initialValue,
});

const VariantFields = <Union extends { type: string }>(props: Props<Union>) => {
  const options = useMemo(
    () => values(props.variants).map(makeOption),
    [props.variants]
  );

  const initialValues = useMemo(
    () =>
      mapObj(
        (v) => v.initialValue,
        props.variants
      ) as any as UnionFieldsInitialValuesMap<Union>,
    [props.variants]
  );

  const renders = useMemo(
    () => mapObj((v) => v.render, props.variants),
    [props.variants]
  );

  return (
    <>
      <Select<Union>
        value={options.find((opt) => opt.value.type === props.value.type)}
        label="Variant"
        options={options}
        onChange={(opt) => props.onChange(opt.value)}
      />

      <UnionFields
        initialValues={initialValues}
        onTypeChange={props.onChange}
        value={props.value}
      >
        {renders as any}
      </UnionFields>
    </>
  );
};

export default VariantFields;
