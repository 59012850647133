export default function handleUnionCases<Union extends string, R = unknown>() {
  return function <T extends Array<[Union, (u: Union) => R]>>(
    items: T
  ): [Union] extends [T[number][0]]
    ? R[]
    : { __compileError: 'You don`t handle all cases' } {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return items.map(([a, f]) => f(a)) as any;
  };
}
