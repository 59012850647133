import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';

import { AuthExternalServiceProvider } from 'shared/models/Authorization';
import routes from 'shared/routes';
import { selectFlags } from 'features/flags';
import { authenticateUserByExternalService } from 'features/user/store';
import matchType from 'shared/utils/matchType';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import Button from 'shared/view/elements/Button/Button';

import LoginByUserForm from '../LoginByUserForm/LoginByLdapForm';
import {
  LoginOptionsContainer,
  OptionLoginButton,
  ButtonWrapper,
} from './styles';

interface ILocalProps {
  goToActiveDirectory(): void;
}

const ServiceButtons: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <LoginByExternalServiceButton
        provider="google"
        providerName="Google"
        icon={ICONS.google}
      />
      <LoginByExternalServiceButton
        provider="github"
        providerName="GitHub"
        icon={ICONS.github}
      />
      <LoginByExternalServiceButton
        provider="bitbucket"
        providerName="Bitbucket"
        icon={ICONS.bitbucket}
      />
    </>
  );
};

const ActiveDirectoryLoginButton: React.FC<
  React.PropsWithChildren<ILocalProps>
> = ({ goToActiveDirectory }) => (
  <ButtonWrapper onClick={goToActiveDirectory}>
    <OptionLoginButton>
      <IconAwesomeInfo infoType="purple" icon={ICONS.lock} size="lg" />
    </OptionLoginButton>
    <Typography variant="caption" mt="5px" textAlign="center">
      Active Directory
    </Typography>
  </ButtonWrapper>
);

const SocialLoginButtons: React.FC<React.PropsWithChildren<ILocalProps>> = ({
  goToActiveDirectory,
}) => {
  const [showingForm, changeShowingForm] = useState<'loginByLdapForm' | null>(
    null
  );

  const { isEnableAuth0Login, isEnableSAMLLogin, isEnableLdapLogin } =
    useSelector(selectFlags);

  const closeCurrentForm = () => changeShowingForm(null);

  const location = useLocation();

  return (
    <Box mb={4}>
      <Typography variant="body1">
        or continue with the following options
      </Typography>
      <LoginOptionsContainer>
        {isEnableAuth0Login && <ServiceButtons />}

        {isEnableLdapLogin && (
          <ActiveDirectoryLoginButton
            goToActiveDirectory={goToActiveDirectory}
          />
        )}

        {isEnableSAMLLogin && (
          <LoginByExternalServiceButton
            provider="saml"
            providerName="SAML"
            icon={ICONS.lock}
          />
        )}
      </LoginOptionsContainer>

      {showingForm
        ? matchType(
            {
              loginByLdapForm: () => (
                <LoginByUserForm
                  isOpen={true}
                  onClose={closeCurrentForm}
                  location={location}
                />
              ),
            },
            showingForm
          )
        : null}
    </Box>
  );
};

export const LoginByExternalServiceButton: React.FC<
  React.PropsWithChildren<{
    icon?: IconDefinition;
    provider: AuthExternalServiceProvider;
    providerName: string;
    signUp?: boolean;
    variant?: 'contained' | 'outlined';
  }>
> = ({ icon, provider, providerName, signUp, variant }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const authenticateViaExternalService = useCallback(() => {
    const currentQueryParams = routes.login.parseQueryParams(location.search);
    dispatch(
      authenticateUserByExternalService({
        authExternalServiceProvider: provider,
        redirectBack: currentQueryParams?.redirect_back,
        signUp,
      })
    );
  }, [dispatch, location.search, provider, signUp]);

  if (icon) {
    return (
      <ButtonWrapper onClick={authenticateViaExternalService}>
        <OptionLoginButton>
          <IconAwesomeInfo infoType="purple" icon={icon} size="lg" />
        </OptionLoginButton>
        <Typography mt="5px" textAlign="center" variant="caption">
          {providerName}
        </Typography>
      </ButtonWrapper>
    );
  }

  return (
    <Button
      onClick={authenticateViaExternalService}
      isLoading={false}
      variant={variant || 'contained'}
      size="large"
    >
      {providerName}
    </Button>
  );
};

export default SocialLoginButtons;
