export const parsePercentage = (string: string): number => {
  const maybeFloat = parseFloat(string);
  if (string.endsWith('%')) {
    return maybeFloat / 100;
  } else {
    return maybeFloat;
  }
};

export const formatPercentage = (value: string) =>
  `${parseFloat(value) * 100}%`;

export const validatePercentage = (field: string) => {
  return (value: string) => {
    const realValue = value.endsWith('%')
      ? Number(value.replace('%', '')) / 100
      : Number(value);
    return realValue >= 0 && realValue <= 1
      ? undefined
      : `${field} should be between 0-100%`;
  };
};
