import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import {
  UPDATE_STAGE_QUERY,
  UpdateStage,
  UpdateStageVariables,
} from '../graphql';

export const useUpdateStage = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [updateStage, updatingStage] = useCustomMutation<
    UpdateStage,
    UpdateStageVariables
  >(UPDATE_STAGE_QUERY, { context: 'update version stage' }, { onCompleted });

  return {
    updateStage,
    updatingStage,
  };
};
