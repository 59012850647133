import EndpointPermissionsPageContent from 'pages/authorized/EndpointsPages/EndpointDetailsPages/EndpointPermissionsPage/EndpointPermissionsPageContent';
import useEndpointSettings from 'features/deployment/canary/endpoints/store/endpointSettings/endpointSettings';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

export interface PermissionsProps {
  endpointId: string;
  workspaceName: string;
}

const EndpointPermissionsPage = (props: PermissionsProps) => {
  const { endpointId, workspaceName } = props;
  const organizationId = useCurrentOrganizationV2();
  const { data, communication } = useEndpointSettings(
    {
      id: endpointId,
      workspaceName: workspaceName,
      organizationId,
    },
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      returnPartialData: true,
    }
  );

  return (
    <DefaultMatchRemoteDataOrError
      data={data}
      communication={communication}
      context="loading prediction count time series"
    >
      {(loaded) => (
        <EndpointPermissionsPageContent endpoint={loaded.endpoint} />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default EndpointPermissionsPage;
