import { useCallback, useMemo } from 'react';

import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import { validateNotEmpty } from 'shared/utils/validators';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';

import MonitoringWidgetFormPopupContent from '../../MonitoringWidgetFormPopup/MonitoringWidgetFormPopupContent/MonitoringWidgetFormPopupContent';
import { BaseMonitoringWidgetFormProps } from '../shared/types/BaseMonitoringWidgetFormProps';

interface ConfusionMatrixWidgetFormType {
  type: 'confusionMatrix';
  title: string;
  output: MonitoringIODescription;
}

const getFieldName = makeGetFieldName<ConfusionMatrixWidgetFormType>();

interface Props extends BaseMonitoringWidgetFormProps {
  onSubmit: (form: ConfusionMatrixWidgetFormType) => void;
  initialValues: ConfusionMatrixWidgetFormType | null;
  defaultOutput: MonitoringIODescription;
}

const ConfusionMatrixWidgetForm = (props: Props) => {
  const { onSubmit } = props;
  const onSubmitForm = useCallback(
    (form: ConfusionMatrixWidgetFormType) => {
      onSubmit(form);
    },
    [onSubmit]
  );

  const initialValues = useMemo(
    (): ConfusionMatrixWidgetFormType => ({
      type: 'confusionMatrix',
      title: '',
      output: props.defaultOutput,
    }),
    [props.defaultOutput]
  );

  return (
    <PresetFormik
      initialValues={props.initialValues ?? initialValues}
      onSubmit={onSubmitForm}
    >
      {({ isValid, handleReset, values }) => (
        <MonitoringWidgetFormPopupContent
          isValid={isValid}
          onBack={props.onBack}
          onReset={handleReset}
          submitText={props.submitText}
          widgetPreview={values}
          onSubmitClick={() => {
            props.onSubmit(values);
          }}
          widgetExternalDeps={props.widgetExternalDeps}
        >
          <TextInputField
            isRequired={true}
            label="Title"
            name={getFieldName({ title: null })}
            validate={validateNotEmpty('title')}
          />
        </MonitoringWidgetFormPopupContent>
      )}
    </PresetFormik>
  );
};

export default ConfusionMatrixWidgetForm;
