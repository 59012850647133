import { useTheme } from '@mui/material';
import { scaleBand, scaleLinear } from '@visx/scale';
import { TickFormatter } from '@visx/axis';
import React, { useMemo } from 'react';
import { chain, uniq } from 'ramda';
import { NumberValue, ScaleBand } from 'd3-scale';

import { getChartSizes } from 'shared/utils/charts/chartSizes';
import { removeSpacesFromString } from 'shared/utils/removeSpacesFromString';
import { getLinearScaleDomain } from 'shared/utils/charts/getLinearScaleDomain';

import CustomAxisLeftWithGridRows from '../Axis/CustomAxisLeftWithGridRows/CustomAxisLeftWithGridRows';
import ChartSVG from '../shared/ChartSVG/ChartSVG';
import { useBarTooltip } from '../shared/barTooltip';
import CustomBarView from '../shared/shapes/CustomBarView/CustomBarView';
import AxisBottomWithRotatedTicks from '../Axis/AxisBottomWithRotatedTicks/AxisBottomWithRotatedTicks';
import { getBarSettings } from '../shared/getBarSettings';
import { defaultChartMargin } from '../shared/margin';

export type GetBarGroupXArguments = { x0Scale: ScaleBand<string>; x0: number };

export type GroupedBarData<Meta> = {
  value: number;
  key: string;
  meta: Meta;
};

export interface BaseGroupedBarChartProps<Meta> {
  chartId: string;
  data: Array<GroupedBarChartData<Meta>>;
  width: number;
  height: number;
  yAxisLabel?: string;
  yAxisTickFormat?: TickFormatter<NumberValue>;
  x0ScaleDomain: string[];
  getBarGroupX: (args: GetBarGroupXArguments) => number;
  renderTooltipContent: (
    d: GroupedBarData<Meta>,
    color: string
  ) => React.ReactElement;
  getColorByData: (d: GroupedBarData<Meta>) => string;
  title?: string;
}

export type GroupedBarChartData<Meta> = {
  key: string;
  barsData: Array<GroupedBarData<Meta>>;
};

const getYScaleDomain = (data: Array<GroupedBarChartData<unknown>>) => {
  const values = data.flatMap(({ barsData }) => barsData.map((d) => d.value));

  return getLinearScaleDomain(values);
};

const BaseGroupedBarChart = <Meta extends unknown>(
  props: BaseGroupedBarChartProps<Meta>
) => {
  const { width, height, innerHeight, innerWidth, margin } = getChartSizes({
    margin: defaultChartMargin,
    width: props.width,
    height: props.height,
  });

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: getYScaleDomain(props.data),
        range: [innerHeight, 0],
        round: true,
        zero: true,
      }),
    [innerHeight, props.data]
  );

  const groupXScale = useMemo(
    () =>
      scaleBand({
        domain: props.x0ScaleDomain,
        range: [0, innerWidth],
        padding: 0.1,
      }),
    [props.x0ScaleDomain, innerWidth]
  );

  const groupXScaleBandWidth = groupXScale.bandwidth();

  const valueKeys = useMemo(
    () =>
      uniq(chain(({ barsData }) => barsData.map(({ key }) => key), props.data)),
    [props.data]
  );

  const barXScale = useMemo(
    () =>
      scaleBand({
        domain: valueKeys,
        padding: 0,
        range: [0, groupXScaleBandWidth],
      }),
    [valueKeys, groupXScaleBandWidth]
  );

  const { tooltip, makeHandlers } = useBarTooltip<GroupedBarData<Meta>>({
    margin,
    renderTooltipContent: (d) =>
      props.renderTooltipContent(d, props.getColorByData(d)),
  });

  const theme = useTheme();

  return (
    <ChartSVG
      width={width}
      height={height}
      margin={margin}
      outSvgContent={tooltip}
      isNilData={props.data.every((group) => group.barsData.length === 0)}
    >
      <CustomAxisLeftWithGridRows
        innerWidth={innerWidth}
        scale={yScale}
        label={props.yAxisLabel}
        numTicks={4}
        tickFormat={props.yAxisTickFormat}
      />
      <AxisBottomWithRotatedTicks
        scale={groupXScale}
        top={innerHeight}
        tickStroke={theme.palette.charts.accentColor}
      />

      {props.data.map((group) => (
        <AnimatedGroup
          key={removeSpacesFromString(
            `bar-group-${group.key}-${props.chartId}`
          )}
          left={props.getBarGroupX({
            x0Scale: groupXScale,
            x0: Number(groupXScale(group.key)),
          })}
        >
          {group.barsData.map(({ value, meta: additionalData, key }) => {
            const bar = getBarSettings({
              d: {
                key,
                value: value,
              },
              innerHeight,
              xScale: barXScale,
              yScale,
            });

            const color = props.getColorByData({
              key,
              value,
              meta: additionalData,
            });

            const { onMouseLeave, onMouseMove } = makeHandlers({
              key,
              value,
              meta: additionalData,
            });

            const id = removeSpacesFromString(
              `bar-group-bar-${group.key}-${key}-${props.chartId}`
            );
            return (
              <CustomBarView
                key={id}
                padding={0.3}
                id={id}
                x={bar.x}
                y={bar.y}
                width={bar.width}
                height={bar.height}
                innerHeight={innerHeight}
                innerWidth={innerWidth}
                fill={color}
                onMouseMove={(event) =>
                  onMouseMove(event, {
                    height: bar.height,
                    width: bar.width,
                    y: bar.y,
                    x: Number(groupXScale(group.key)) + bar.x,
                  })
                }
                onMouseLeave={onMouseLeave}
              />
            );
          })}
        </AnimatedGroup>
      ))}
    </ChartSVG>
  );
};

const AnimatedGroup: React.FC<
  React.PropsWithChildren<{
    left: number;
    children: React.ReactElement | React.ReactElement[];
  }>
> = ({ left, children }) => {
  return <g transform={`translate(${left}, 0)`}>{children}</g>;
};

export default BaseGroupedBarChart;
