import {
  DatasetVersionsQuery,
  FloatPredicate,
  PredicateOperator,
  StringPredicate,
} from 'generated/types';
import matchBy from 'shared/utils/matchBy';
import isNotNil from 'shared/utils/isNotNill';
import { Filter, FilterOperator } from 'shared/models/Filters/Filter';
import matchType from 'shared/utils/matchType';
import { fieldNameToKey } from 'shared/models/FieldName';

// because we don't have the single type for this kind of query, I have to use the DatasetVersionQuery
type EntitiesQuery = Pick<
  DatasetVersionsQuery,
  'floatPredicates' | 'stringPredicates'
>;

export const convertFilters = (filters: Filter[]): EntitiesQuery => {
  const graphqlPredicates = filters.map((filter) =>
    matchBy(
      filter,
      'type'
    )<
      | { type: 'float'; data: FloatPredicate }
      | { type: 'string'; data: StringPredicate }
    >({
      number: (numericFilter) => ({
        type: 'float',
        data: {
          key: fieldNameToKey(numericFilter.name),
          value: numericFilter.value,
          operator: getGraphqlFilterOperator(numericFilter.operator),
        },
      }),
      string: (stringFilter) => ({
        type: 'string',
        data: {
          key: fieldNameToKey(stringFilter.name),
          value: stringFilter.value,
          operator: getGraphqlFilterOperator(stringFilter.operator),
        },
      }),
    })
  );

  return {
    floatPredicates: graphqlPredicates
      .map((p) => (p.type === 'float' ? p.data : undefined))
      .filter(isNotNil),
    stringPredicates: graphqlPredicates
      .map((p) => (p.type === 'string' ? p.data : undefined))
      .filter(isNotNil),
  };
};

const getGraphqlFilterOperator = (
  operator: FilterOperator
): PredicateOperator => {
  return matchType(
    {
      EQUALS: () => PredicateOperator.EQ,
      GREATER: () => PredicateOperator.GT,
      GREATER_OR_EQUALS: () => PredicateOperator.GTE,
      LESS: () => PredicateOperator.LT,
      LESS_OR_EQUALS: () => PredicateOperator.LTE,
      LIKE: () => PredicateOperator.CONTAIN,
      NOT_EQUALS: () => PredicateOperator.NE,
    },
    operator
  );
};
