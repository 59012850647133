import { useMemo } from 'react';

import { getDefaultIODescriptionsOrThrowError } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { InitializedMonitoringWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidget';
import { InitializedMonitoringWidgetType } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetType';
import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';
import { OmitStrict } from 'shared/utils/types';

import ConfusionMatrixWidgetForm from './ConfusionMatrixWidgetForm/ConfusionMatrixWidgetForm';
import SingleMetricWidgetForm from './SingleMetricWidgetForm/SingleMetricWidgetForm';
import TableWidgetForm from './TableWidgetForm/TableWidgetForm';
import ScatterPlotWidgetForm from './ScatterPlotWidgetForm/ScatterPlotWidgetForm';
import CurveChartWidgetForm from './CurveChartWidgetForm/CurveChartWidgetForm';
import { BaseMonitoringWidgetFormProps } from './shared/types/BaseMonitoringWidgetFormProps';
import TimeSeriesWidgetForm from './TimeSeriesWidgetForm/TimeSeriesWidgetForm';
import BarChartWidgetForm from './BarChartWidgetForm/BarChartWidgetForm';
import HistogramsWidgetForm from './HistogramsWidgetForm/HistogramsWidgetForm';

export type MonitoringWidgetFormType = OmitStrict<
  InitializedMonitoringWidget,
  'id'
>;

interface BaseProps<T extends InitializedMonitoringWidgetType>
  extends BaseMonitoringWidgetFormProps {
  widgetType: T;
  onSubmit: (widget: MonitoringWidgetFormType) => void;
  initialValues: Extract<InitializedMonitoringWidget, { type: T }> | null;
}

type Props<T extends InitializedMonitoringWidgetType> =
  T extends InitializedMonitoringWidgetType ? BaseProps<T> : never;

const MonitoringWidgetForm = <T extends InitializedMonitoringWidgetType>(
  props: Props<T>
) => {
  const { defaultInput, defaultOutput } = useMemo(
    () =>
      getDefaultIODescriptionsOrThrowError(
        props.widgetExternalDeps.ioDescriptions
      ),
    [props.widgetExternalDeps.ioDescriptions]
  );

  switch (props.widgetType) {
    case 'singleMetric':
      return (
        <SingleMetricWidgetForm {...props} defaultOutput={defaultOutput} />
      );
    case 'confusionMatrix':
      return (
        <ConfusionMatrixWidgetForm {...props} defaultOutput={defaultOutput} />
      );
    case 'table':
      return <TableWidgetForm {...props} initialValues={props.initialValues} />;
    case 'timeSeries':
      return (
        <TimeSeriesWidgetForm
          {...props}
          initialValues={props.initialValues}
          defaultOutput={defaultOutput}
          defaultInput={defaultInput}
        />
      );
    case 'histograms':
      return <HistogramsWidgetForm {...props} defaultInput={defaultInput} />;
    case 'barChart':
      return <BarChartWidgetForm {...props} defaultInput={defaultInput} />;
    case 'scatterPlot':
      return <ScatterPlotWidgetForm {...props} defaultInput={defaultInput} />;
    case 'curve':
      return <CurveChartWidgetForm {...props} defaultOutput={defaultOutput} />;

    default:
      exhaustiveCheck(props);
  }
};

export default MonitoringWidgetForm;
