import SEO from 'shared/view/elements/SEO/SEO';

import { AppBreadcrumbs } from './AppBreadcrumbs';

interface Props {
  breadcrumbs: AppBreadcrumbs | undefined;
}

const getTitleByBreadcrumbs = (breadcrumbs: AppBreadcrumbs) => {
  const isLoading = breadcrumbs.some((breadcrumb) => !breadcrumb.isLoaded);

  if (isLoading) {
    return '...Loading';
  }

  return breadcrumbs.map((breadcrumb) => breadcrumb.name).join(' > ');
};

const DEFAULT_TITLE = 'Verta AI';

const LayoutSEO = (props: Props) => {
  const title = props.breadcrumbs
    ? getTitleByBreadcrumbs(props.breadcrumbs)
    : DEFAULT_TITLE;

  return <SEO title={title} />;
};

export default LayoutSEO;
