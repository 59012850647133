import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material';
import { last } from 'ramda';

import Placeholder from 'shared/view/elements/Placeholder/Placeholder';
import isNotNil from 'shared/utils/isNotNill';
import Button from 'shared/view/elements/Button/Button';

import BuildInfo, { RequiredComponentWithBuild } from './BuildInfo';

const BuildsInfo = ({
  endpointId,
  componentsWithBuilds,
  organizationId,
  workspaceName,
}: {
  endpointId: string;
  organizationId: string | undefined;
  componentsWithBuilds: RequiredComponentWithBuild[];
  workspaceName: string;
}) => {
  const [isShowAllBuilds, setIsShowAllBuilds] = useState(false);
  const displayedComponentsWithBuilds = (
    isShowAllBuilds
      ? componentsWithBuilds
      : [last(componentsWithBuilds)].filter(isNotNil)
  )
    .slice()
    .reverse();

  const theme = useTheme();

  return (
    <div>
      {componentsWithBuilds.length > 0 ? (
        <>
          <Stack direction="column" spacing={3} divider={<Divider />}>
            {displayedComponentsWithBuilds.map((componentWithBuild) => (
              <BuildInfo
                workspaceName={workspaceName}
                organizationId={organizationId}
                endpointId={endpointId}
                componentWithBuild={componentWithBuild}
                key={componentWithBuild.build.id}
              />
            ))}
          </Stack>
          {componentsWithBuilds.length > 1 && (
            <div style={{ marginTop: theme.spacing(1) }}>
              <Button
                variant={'text'}
                onClick={() => setIsShowAllBuilds(!isShowAllBuilds)}
                isLoading={false}
              >
                {isShowAllBuilds ? `Hide builds` : `Show all builds`}
              </Button>
            </div>
          )}
        </>
      ) : (
        <Placeholder>You don't have a build</Placeholder>
      )}
    </div>
  );
};

export const formatCanaryRolloutPercentage = (value: number) => {
  return `${(value * 100).toFixed(0)}%`;
};

export default BuildsInfo;
