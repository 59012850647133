import { mangoFusionPalette } from '@mui/x-charts';
import { Box, Card, Skeleton, Stack, Typography, styled } from '@mui/material';

import { DonutChart, DonutChartProps } from 'shared/view/charts/DonutChart';
import { LabelsContainer } from 'shared/view/charts/shared/Labels';

export enum IncidentType {
  INCIDENTS = 'Incidents',
  MODEL = 'Model Lifecycle',
}

type Props = Pick<DonutChartProps, 'data'> & {
  title: string;
  dataType: string;
  colorPalette?: string[];
  isLoading?: boolean;
  chartType?: IncidentType;
};

const CenterBox = styled(Box)({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  textAlign: 'center',
});

const ChartBox = styled(Box)({
  position: 'relative',
  display: 'flex',
});

export const DonutChartCard = (props: Props) => {
  const {
    title,
    dataType,
    data,
    isLoading,
    chartType,
    colorPalette = mangoFusionPalette('light'),
  } = props;
  const totalValue = data.reduce((acc, obj) => acc + obj.value, 0);
  const colorPaletteSize = colorPalette.length;

  const parsedData = data.map((d, idx) => ({
    ...d,
    color: colorPalette[idx % colorPaletteSize],
  }));

  return (
    <Card sx={{ height: '495px', padding: '24px' }}>
      <Stack spacing={0}>
        <Typography variant="subtitle1" color="text.secondary">
          {isLoading ? <Skeleton /> : title}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {isLoading ? <Skeleton /> : `by ${dataType}`}
        </Typography>
        <ChartBox px={1} my={3} width="100%">
          <CenterBox alignItems="center">
            <Stack>
              <Typography variant="h2">
                {isLoading ? <Skeleton width={50} /> : totalValue}
              </Typography>
              <Typography variant="body2">
                {isLoading ? <Skeleton /> : 'in total'}
              </Typography>
            </Stack>
          </CenterBox>
          <DonutChart
            radius={120}
            data={parsedData}
            colors={mangoFusionPalette}
          />
        </ChartBox>
        <LabelsContainer labels={parsedData} chartType={chartType} />
      </Stack>
    </Card>
  );
};
