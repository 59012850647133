import { useCreateOrganization } from '../../organizations/store/useCreateOrganization';
import OrganizationPopup from './OrganizationPopup';

type Props = {
  isOpen: boolean;
  onClose(): void;
};

const initialForm = {
  name: '',
  description: '',
  adminEmail: '',
  adminEmails: [],
};

const CreateOrganizationPopup = (props: Props) => {
  const createOrganizationApi = useCreateOrganization(props.onClose);

  return (
    <OrganizationPopup
      {...props}
      title="Create organization"
      submitButton={{
        children: 'Create',
        isLoading: createOrganizationApi.communication.isRequesting,
        onSubmit: createOrganizationApi.run,
      }}
      initialForm={initialForm}
    />
  );
};

export default CreateOrganizationPopup;
