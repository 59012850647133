import {
  Alert as MuiAlert,
  AlertProps,
  AlertTitle as MuiAlertTitle,
  AlertColor,
  Box,
  BoxProps,
  Stack,
  StackProps,
  styled,
} from '@mui/material';
import { ReactNode } from 'react';

import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS, ICONS_SOLID } from 'shared/view/elements/IconAwesome/ICONS';
import Button, { ButtonProps } from 'shared/view/elements/Button/Button';
import { OmitStrict } from 'shared/utils/types';

type Props = OmitStrict<AlertProps, 'slots'> & {
  title?: string;
  children: ReactNode;
  onClose?: () => void;
  actions?: ButtonProps[];
  slots?: AlertProps['slots'] & {
    icon?: BoxProps;
  };
};

const icons: Record<AlertColor, ReactNode> = {
  info: (
    <IconAwesomeInfo
      icon={ICONS_SOLID.circleInfo}
      infoType="info"
      size="1x"
      color="inherit"
    />
  ),
  success: (
    <IconAwesomeInfo
      icon={ICONS_SOLID.circleCheck}
      infoType="success"
      size="1x"
    />
  ),
  warning: (
    <IconAwesomeInfo
      icon={ICONS_SOLID.triangleExclamation}
      infoType="warning"
      size="1x"
    />
  ),
  error: (
    <IconAwesomeInfo
      icon={ICONS_SOLID.circleXmark}
      infoType="error"
      size="1x"
    />
  ),
};

const StyledBox = styled(Box)({});

const MuiAlertIcon = (props: BoxProps) => (
  <StyledBox display="flex" className="MuiAlert-icon" {...props} />
);

const MuiAlertMessageWithTitle = ({
  severity = 'warning',
  title,
  children,
  icon,
  sx,
  slots,
  ...other
}: Props & StackProps) => {
  return (
    <Stack
      className="MuiAlert-message"
      spacing={1}
      sx={{
        paddingRight: '4px',
        paddingLeft: '4px',
        ...sx,
      }}
      {...other}
    >
      <Stack spacing={1} direction="row" alignItems="center">
        {icon !== false ? (
          <MuiAlertIcon {...slots?.icon}>{icons[severity]}</MuiAlertIcon>
        ) : null}
        <MuiAlertTitle>{title}</MuiAlertTitle>
      </Stack>
      <Box sx={{ color: 'text.primary', ...sx }}>{children}</Box>
    </Stack>
  );
};

const MuiAlertMessage = ({
  severity = 'warning',
  children,
  icon,
  sx,
  slots,
  ...other
}: Props & StackProps) => {
  return (
    <Stack className="MuiAlert-message" direction="row" spacing={1} {...other}>
      {icon !== false ? (
        <MuiAlertIcon pt="3px" {...slots?.icon}>
          {icons[severity]}
        </MuiAlertIcon>
      ) : null}
      <Box
        sx={{
          color: 'text.primary',
          width: other.onClose ? undefined : '100%',
          ...sx,
        }}
      >
        {children}
      </Box>
    </Stack>
  );
};

const CloseIcon = (props: BoxProps) => (
  <StyledBox {...props}>
    <IconAwesomeInfo icon={ICONS.xMark} color="text.secondary" size="sm" />
  </StyledBox>
);

type ActionsContainerProps = StackProps & {
  actions: ButtonProps[];
  severity: Props['severity'];
};

const ActionsContainer = ({
  actions,
  severity,
  ...other
}: ActionsContainerProps) => {
  return (
    <Stack spacing={1} direction="row" mt={0.5} {...other}>
      {actions.map((action, index) => (
        <Button
          key={`alert-action-${index}`}
          variant="text"
          size="small"
          color={severity === 'info' ? 'primary' : severity}
          {...action}
        />
      ))}
    </Stack>
  );
};

const Alert = (props: Props) => {
  const { title, actions, severity = 'warning', ...other } = props;

  return (
    <MuiAlert
      icon={false}
      severity={severity}
      variant="outlined"
      slots={{
        closeIcon: () => <CloseIcon pt={title ? '4px' : '0'} />,
        ...props.slots,
      }}
      {...other}
      sx={{
        '& .MuiAlert-message': { minWidth: props.onClose ? undefined : '100%' },
        ...other.sx,
      }}
    >
      {title ? (
        <MuiAlertMessageWithTitle {...props} />
      ) : (
        <MuiAlertMessage {...props} />
      )}
      {actions ? (
        <ActionsContainer
          actions={actions}
          severity={severity}
          pl={title ? '0' : '22px'}
        />
      ) : null}
    </MuiAlert>
  );
};

export default Alert;
