import { isEmpty } from 'lodash';

import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import capitalize from 'shared/utils/capitalize';
import { Stage, RiskLevel } from 'generated/types';
import { useLoadCustomAttributeDefinitions } from 'features/catalog/shared/hooks/useLoadCustomAttributeDefinitions';
import isNotNil from 'shared/utils/isNotNill';
import { OrganizationGovernance } from 'features/organizations/governance/store/useOrganizationGovernance';

type CustomAttributeDefinitions = ReturnType<
  typeof useLoadCustomAttributeDefinitions
>['data'];

export const handleChecklistConstrain = (
  {
    desiredStage,
    applyToRiskLevel,
    blocksDeployment,
    customAttributeValues,
  }: NonNullable<OrganizationGovernance['checklistTemplates']>[0],
  definitions: CustomAttributeDefinitions
) => {
  let texts = [];

  if (isNotNil(desiredStage) && desiredStage !== Stage.UNKNOWN) {
    texts.push(`When moving to "${capitalize(desiredStage)}"`);
  }

  if (isNotNil(applyToRiskLevel) && applyToRiskLevel !== RiskLevel.UNKNOWN) {
    texts.push(`${capitalize(applyToRiskLevel)} risk`);
  }

  if (blocksDeployment) {
    texts.push('Blocks deploy');
  }

  if (
    isNotNil(customAttributeValues) &&
    !isEmpty(customAttributeValues) &&
    isNotNil(definitions) &&
    !isEmpty(definitions) &&
    isNotError(definitions)
  ) {
    const definition = definitions.find(
      (_definition) =>
        customAttributeValues[0]?.customAttributeDefinitionId === _definition.id
    );

    if (definition) {
      const value = definition.customAttributeListOptions?.find(
        (opt) => opt.id === customAttributeValues[0]?.selectedValue
      );

      if (value) texts.push(`When ${definition.name} is "${value.name}"`);
    }
  }

  return texts.join(' | ');
};
