import moment from 'moment';

import {
  formatDurationMs,
  parseDurationMs,
  validateDuration,
} from 'shared/utils/duration';
import { Seconds } from 'shared/utils/types';
import { combineValidators, validateNotEmpty } from 'shared/utils/validators';

export const validateCanaryRuleInterval = combineValidators([
  validateNotEmpty('interval'),
  validateDuration('interval', {
    min: moment.duration({ seconds: 0 }).asMilliseconds(),
    max: moment.duration({ hours: 24 }).asMilliseconds(),
  }),
]);

export const parseCanaryRuleInterval = (value: string): Seconds =>
  parseDurationMs(value) / 1000;

export const formatCanaryRuleInterval = (value: Seconds) =>
  formatDurationMs(value * 1000);
