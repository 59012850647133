/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
export const namedOperations = {
  Query: {
    ArtifactDatasetVersion: 'ArtifactDatasetVersion' as const,
    MlFlowIntegrationModels: 'MlFlowIntegrationModels' as const,
    GetAllJobs: 'GetAllJobs' as const,
    ModelActionsData: 'ModelActionsData' as const,
    ModelActivitiesData: 'ModelActivitiesData' as const,
    OrganizationUsersQuery: 'OrganizationUsersQuery' as const,
    Endpoints: 'Endpoints' as const,
    Labels: 'Labels' as const,
    WorkspaceModelVersions: 'WorkspaceModelVersions' as const,
    Models: 'Models' as const,
    RegisteredModelVersionForComparing:
      'RegisteredModelVersionForComparing' as const,
    ModelDocumentationData: 'ModelDocumentationData' as const,
    ModelOverviewData: 'ModelOverviewData' as const,
    ModelVersionDocumentationData: 'ModelVersionDocumentationData' as const,
    ModelVersionChecklistItemValues: 'ModelVersionChecklistItemValues' as const,
    ModelVersionRelease: 'ModelVersionRelease' as const,
    ModelVersionIntegrate: 'ModelVersionIntegrate' as const,
    ModelVersionEndpoints: 'ModelVersionEndpoints' as const,
    ModelVersionExternalDeployments: 'ModelVersionExternalDeployments' as const,
    ScanStatus: 'ScanStatus' as const,
    ModelVersionReproduce: 'ModelVersionReproduce' as const,
    ModelVersionSummary: 'ModelVersionSummary' as const,
    ModelVersionWorkflows: 'ModelVersionWorkflows' as const,
    LoadCustomAttributeDefinitions: 'LoadCustomAttributeDefinitions' as const,
    LoadCustomAttributeDefinitionsWithCategories:
      'LoadCustomAttributeDefinitionsWithCategories' as const,
    LoadModelCustomAttributeValues: 'LoadModelCustomAttributeValues' as const,
    LoadModelVersionCustomAttributeValues:
      'LoadModelVersionCustomAttributeValues' as const,
    LoadCurrentOrganizationGroups: 'LoadCurrentOrganizationGroups' as const,
    JobsStatus: 'JobsStatus' as const,
    LoadChecklistTemplates: 'LoadChecklistTemplates' as const,
    ModelVersionEnviromentBlob: 'ModelVersionEnviromentBlob' as const,
    ModelVersionArtifacts: 'ModelVersionArtifacts' as const,
    CompareDatasetVersions: 'CompareDatasetVersions' as const,
    CustomDashboardSummariesCharts: 'CustomDashboardSummariesCharts' as const,
    CustomDashboardTopModels: 'CustomDashboardTopModels' as const,
    DatasetVersion: 'DatasetVersion' as const,
    DatasetVersions: 'DatasetVersions' as const,
    Datasets: 'Datasets' as const,
    BuildContainerLogs: 'BuildContainerLogs' as const,
    EndpointErrors: 'EndpointErrors' as const,
    BuildRuntimeLogs: 'BuildRuntimeLogs' as const,
    EndpointBuilds: 'EndpointBuilds' as const,
    kafkaConfigurations: 'kafkaConfigurations' as const,
    EndpointMonitoring: 'EndpointMonitoring' as const,
    EndpointOverview: 'EndpointOverview' as const,
    EndpointOverviewPolling: 'EndpointOverviewPolling' as const,
    Endpoint: 'Endpoint' as const,
    EndpointSettings: 'EndpointSettings' as const,
    EndpointsList: 'EndpointsList' as const,
    EndpointsEnvironmentStatuses: 'EndpointsEnvironmentStatuses' as const,
    ModelBuilds: 'ModelBuilds' as const,
    ModelVersionBuilds: 'ModelVersionBuilds' as const,
    UserDevKeys: 'UserDevKeys' as const,
    ProjectOwnerCandidates: 'ProjectOwnerCandidates' as const,
    DatasetOwnerCandidates: 'DatasetOwnerCandidates' as const,
    EndpointOwnerCandidates: 'EndpointOwnerCandidates' as const,
    RegisteredModelOwnerCandidates: 'RegisteredModelOwnerCandidates' as const,
    MonitoringOwnerCandidates: 'MonitoringOwnerCandidates' as const,
    EvaluationForComparing: 'EvaluationForComparing' as const,
    EvaluationSummary: 'EvaluationSummary' as const,
    ModelVersionActivitiesData: 'ModelVersionActivitiesData' as const,
    EvaluationsModelsVersions: 'EvaluationsModelsVersions' as const,
    EvaluationProjectsList: 'EvaluationProjectsList' as const,
    ExperimentRunsForComparing: 'ExperimentRunsForComparing' as const,
    ExperimentRun: 'ExperimentRun' as const,
    ExperimentRunEndpoint: 'ExperimentRunEndpoint' as const,
    ExperimentRuns: 'ExperimentRuns' as const,
    LoadExperiments: 'LoadExperiments' as const,
    ServiceFlags: 'ServiceFlags' as const,
    WorkspaceRegisteredModels: 'WorkspaceRegisteredModels' as const,
    WorkspaceRegisteredModelsSummary:
      'WorkspaceRegisteredModelsSummary' as const,
    OrganizationUsersCount: 'OrganizationUsersCount' as const,
    WorkspaceEndpointsCount: 'WorkspaceEndpointsCount' as const,
    CatalogWidget: 'CatalogWidget' as const,
    HomepageMonitoringWidget: 'HomepageMonitoringWidget' as const,
    AlertQuery: 'AlertQuery' as const,
    AlertHistoryQuery: 'AlertHistoryQuery' as const,
    AlertsQuery: 'AlertsQuery' as const,
    MonitoringModelQuery: 'MonitoringModelQuery' as const,
    MonitoringModels: 'MonitoringModels' as const,
    ConfidenceMetrics: 'ConfidenceMetrics' as const,
    ConfusionMatrixQuery: 'ConfusionMatrixQuery' as const,
    DistributionQuery: 'DistributionQuery' as const,
    DistributionOverTimeQuery: 'DistributionOverTimeQuery' as const,
    DriftQuery: 'DriftQuery' as const,
    DriftOverTimeQuery: 'DriftOverTimeQuery' as const,
    FullMetricQuery: 'FullMetricQuery' as const,
    MetricOverTime: 'MetricOverTime' as const,
    OutlierDetectionQuery: 'OutlierDetectionQuery' as const,
    OutlierDetectionOverTimeQuery: 'OutlierDetectionOverTimeQuery' as const,
    SingleMetric: 'SingleMetric' as const,
    OrganizationCustomAttributes: 'OrganizationCustomAttributes' as const,
    OrganizationGovernance: 'OrganizationGovernance' as const,
    OrganizationGroups: 'OrganizationGroups' as const,
    LoadUserWorkspaces: 'LoadUserWorkspaces' as const,
    OrganizationsV2List: 'OrganizationsV2List' as const,
    OrganizationsUsersList: 'OrganizationsUsersList' as const,
    OrganizationRolesV2: 'OrganizationRolesV2' as const,
    OrganizationV2Users: 'OrganizationV2Users' as const,
    OrganizationWorkspacesV2: 'OrganizationWorkspacesV2' as const,
    Projects: 'Projects' as const,
    RegisteredModels: 'RegisteredModels' as const,
    RegisteredModelVersionsByExperimentRun:
      'RegisteredModelVersionsByExperimentRun' as const,
    ModelVersionsForBuild: 'ModelVersionsForBuild' as const,
    RegisteredModelsForBuild: 'RegisteredModelsForBuild' as const,
    AdministratedOrganizationV2: 'AdministratedOrganizationV2' as const,
    SystemAdmins: 'SystemAdmins' as const,
    ActiveDirectoryConfiguration: 'ActiveDirectoryConfiguration' as const,
    KafkaConfigurations: 'KafkaConfigurations' as const,
    SystemIntegrationsWithoutKafka: 'SystemIntegrationsWithoutKafka' as const,
    SystemIntegrationsWithKafka: 'SystemIntegrationsWithKafka' as const,
    PypiConfigurationsList: 'PypiConfigurationsList' as const,
    EndpointsCases: 'EndpointsCases' as const,
    LabelsCases: 'LabelsCases' as const,
    WorkspaceModelVersionsCases: 'WorkspaceModelVersionsCases' as const,
    UseCaseModels: 'UseCaseModels' as const,
    ModelDocumentationDataCases: 'ModelDocumentationDataCases' as const,
    ModelOverviewDataCases: 'ModelOverviewDataCases' as const,
    LoadModelCustomAttributeValuesCases:
      'LoadModelCustomAttributeValuesCases' as const,
    LoadModelVersionCustomAttributeValuesCases:
      'LoadModelVersionCustomAttributeValuesCases' as const,
    ModelVersionEnviromentBlobCases: 'ModelVersionEnviromentBlobCases' as const,
    ModelVersionArtifactsCases: 'ModelVersionArtifactsCases' as const,
    LoadInitialAuthorizedAppData: 'LoadInitialAuthorizedAppData' as const,
    ModelLayout: 'ModelLayout' as const,
    ModelVersionCreation: 'ModelVersionCreation' as const,
    ModelVersionLayout: 'ModelVersionLayout' as const,
    DatasetOverview: 'DatasetOverview' as const,
    loadProjectErrorReporter: 'loadProjectErrorReporter' as const,
    loadProjectsForErrorReporter: 'loadProjectsForErrorReporter' as const,
    EvaluationLayout: 'EvaluationLayout' as const,
    ProjectOverviewPage: 'ProjectOverviewPage' as const,
    DefaultProject: 'DefaultProject' as const,
    ModelLayoutCases: 'ModelLayoutCases' as const,
    CurrentUser: 'CurrentUser' as const,
    EndpointResults: 'EndpointResults' as const,
    DatasetResults: 'DatasetResults' as const,
    DatasetVersionResults: 'DatasetVersionResults' as const,
    ExperimentRunsByWorkspace: 'ExperimentRunsByWorkspace' as const,
    ExperimentResults: 'ExperimentResults' as const,
    RegisteredModelResult: 'RegisteredModelResult' as const,
    RegisteredModelVersionResult: 'RegisteredModelVersionResult' as const,
    ProjectResults: 'ProjectResults' as const,
    DatasetBlobPreview: 'DatasetBlobPreview' as const,
    MonitoringRegisteredModelInfoQuery:
      'MonitoringRegisteredModelInfoQuery' as const,
  },
  Mutation: {
    DeleteModelVersionArtifact: 'DeleteModelVersionArtifact' as const,
    CreateJob: 'CreateJob' as const,
    UpdateModelReadmeOld: 'UpdateModelReadmeOld' as const,
    AddModelLabel: 'AddModelLabel' as const,
    DeleteModelLabel: 'DeleteModelLabel' as const,
    UpdateModelVersionReadmeOld: 'UpdateModelVersionReadmeOld' as const,
    CreateOrUpdateChecklistItemValues:
      'CreateOrUpdateChecklistItemValues' as const,
    CreateExternalDeployment: 'CreateExternalDeployment' as const,
    UpdateExternalDeployment: 'UpdateExternalDeployment' as const,
    DeleteExternalDeployment: 'DeleteExternalDeployment' as const,
    StartScan: 'StartScan' as const,
    StartScanExternal: 'StartScanExternal' as const,
    CloseModelVersion: 'CloseModelVersion' as const,
    OpenModelVersion: 'OpenModelVersion' as const,
    RedactModelVersion: 'RedactModelVersion' as const,
    AddModelVersionLabel: 'AddModelVersionLabel' as const,
    DeleteModelVersionLabel: 'DeleteModelVersionLabel' as const,
    UpdateModelReadme: 'UpdateModelReadme' as const,
    UpdateModelVersionReadme: 'UpdateModelVersionReadme' as const,
    CreateOrUpdateCustomAttributeValue:
      'CreateOrUpdateCustomAttributeValue' as const,
    CreateOrUpdateVersionCustomAttributeValue:
      'CreateOrUpdateVersionCustomAttributeValue' as const,
    ApproveTransition: 'ApproveTransition' as const,
    CommitTransition: 'CommitTransition' as const,
    CloseTransition: 'CloseTransition' as const,
    RejectTransition: 'RejectTransition' as const,
    UpdateModel: 'UpdateModel' as const,
    UpdateModelVersion: 'UpdateModelVersion' as const,
    DeleteBulkDatasetVersion: 'DeleteBulkDatasetVersion' as const,
    DeleteDatasetVersion: 'DeleteDatasetVersion' as const,
    EditDatasetVersionDescription: 'EditDatasetVersionDescription' as const,
    AddDatasetVersionTag: 'AddDatasetVersionTag' as const,
    DeleteDatasetVersionTag: 'DeleteDatasetVersionTag' as const,
    CreateDataset: 'CreateDataset' as const,
    DeleteDataset: 'DeleteDataset' as const,
    AddDatasetTag: 'AddDatasetTag' as const,
    DeleteDatasetTag: 'DeleteDatasetTag' as const,
    CreateAccessToken: 'CreateAccessToken' as const,
    RemoveAccessToken: 'RemoveAccessToken' as const,
    EnableAuthZPredictions: 'EnableAuthZPredictions' as const,
    EnableTokens: 'EnableTokens' as const,
    CreateEndpoint: 'CreateEndpoint' as const,
    AddEndpointLabel: 'AddEndpointLabel' as const,
    DeleteEndpointLabel: 'DeleteEndpointLabel' as const,
    DeleteEndpoint: 'DeleteEndpoint' as const,
    UpdateEndpointEnvironmentKafka: 'UpdateEndpointEnvironmentKafka' as const,
    UpdateEnvironment: 'UpdateEnvironment' as const,
    CreateNewBuild: 'CreateNewBuild' as const,
    UserAddDevKeyV2: 'UserAddDevKeyV2' as const,
    UserDeletePrimaryDevKeyV2: 'UserDeletePrimaryDevKeyV2' as const,
    UserDeleteSecondaryDevKeyV2: 'UserDeleteSecondaryDevKeyV2' as const,
    EditProjectInformation: 'EditProjectInformation' as const,
    EditDatasetInformation: 'EditDatasetInformation' as const,
    EditRegisteredModelInformation: 'EditRegisteredModelInformation' as const,
    EditRegisteredModelVersionInformation:
      'EditRegisteredModelVersionInformation' as const,
    EditEndpointInformation: 'EditEndpointInformation' as const,
    EditMonitoredEntityInformation: 'EditMonitoredEntityInformation' as const,
    CreateEvaluation: 'CreateEvaluation' as const,
    CreateEvaluationProject: 'CreateEvaluationProject' as const,
    UpdateEvaluationRow: 'UpdateEvaluationRow' as const,
    UpdateEvaluationAttributes: 'UpdateEvaluationAttributes' as const,
    EditEvaluationBasicInfo: 'EditEvaluationBasicInfo' as const,
    EditEvaluationProject: 'EditEvaluationProject' as const,
    DeleteEvaluationProject: 'DeleteEvaluationProject' as const,
    EvaluationAddAttributes: 'EvaluationAddAttributes' as const,
    UpdateAttributeWithDashboardForComparing:
      'UpdateAttributeWithDashboardForComparing' as const,
    UpdateAttributeWithDashboards: 'UpdateAttributeWithDashboards' as const,
    UpdateExperimentRunDescription: 'UpdateExperimentRunDescription' as const,
    DeleteExperimentRuns: 'DeleteExperimentRuns' as const,
    DeleteExperimentRun: 'DeleteExperimentRun' as const,
    DeleteExperimentRunArtifact: 'DeleteExperimentRunArtifact' as const,
    AddExperimentRunTag: 'AddExperimentRunTag' as const,
    DeleteExperimentRunTag: 'DeleteExperimentRunTag' as const,
    CreateExperiment: 'CreateExperiment' as const,
    ChangeExperimentDescription: 'ChangeExperimentDescription' as const,
    DeleteExperiment: 'DeleteExperiment' as const,
    AddExperimentTag: 'AddExperimentTag' as const,
    DeleteExperimentTag: 'DeleteExperimentTag' as const,
    ChangeAlertStatusMutation: 'ChangeAlertStatusMutation' as const,
    CreateAlertMutation: 'CreateAlertMutation' as const,
    DeleteAlertMutation: 'DeleteAlertMutation' as const,
    UpdateAlertMutation: 'UpdateAlertMutation' as const,
    UpdateAlertBulk: 'UpdateAlertBulk' as const,
    UpdateMonitoringModel: 'UpdateMonitoringModel' as const,
    DeleteMonitoringModel: 'DeleteMonitoringModel' as const,
    CreateOrganization: 'CreateOrganization' as const,
    CreateOrUpdateCustomAttributeDefinition:
      'CreateOrUpdateCustomAttributeDefinition' as const,
    DeleteCustomAttribute: 'DeleteCustomAttribute' as const,
    CreateOrUpdateCustomAttributeCategory:
      'CreateOrUpdateCustomAttributeCategory' as const,
    DeleteCustomAttributeCategory: 'DeleteCustomAttributeCategory' as const,
    CreateOrUpdateTemplate: 'CreateOrUpdateTemplate' as const,
    DeleteTemplate: 'DeleteTemplate' as const,
    CreateGroup: 'CreateGroup' as const,
    DeleteGroup: 'DeleteGroup' as const,
    UpdateGroup: 'UpdateGroup' as const,
    CreateOrganizationV2: 'CreateOrganizationV2' as const,
    OrganizationV2Delete: 'OrganizationV2Delete' as const,
    UpdateOrganizationV2: 'UpdateOrganizationV2' as const,
    AddRoleToOrganizationV2: 'AddRoleToOrganizationV2' as const,
    DeleteRoleToOrganizationV2: 'DeleteRoleToOrganizationV2' as const,
    UpdateRoleToOrganizationV2: 'UpdateRoleToOrganizationV2' as const,
    AddServiceAccountToOrganizationV2:
      'AddServiceAccountToOrganizationV2' as const,
    AddUsersToOrganizationV2: 'AddUsersToOrganizationV2' as const,
    DeleteUserFromOrganizationV2: 'DeleteUserFromOrganizationV2' as const,
    UpdateUserV2: 'UpdateUserV2' as const,
    AddWorkspaceToOrganizationV2: 'AddWorkspaceToOrganizationV2' as const,
    DeleteWorkspaceToOrganizationV2: 'DeleteWorkspaceToOrganizationV2' as const,
    UpdateWorkspaceToOrganizationV2: 'UpdateWorkspaceToOrganizationV2' as const,
    CreateProject: 'CreateProject' as const,
    ChangeReadme: 'ChangeReadme' as const,
    DeleteProject: 'DeleteProject' as const,
    AddProjecTag: 'AddProjecTag' as const,
    DeleteProjectTag: 'DeleteProjectTag' as const,
    DeleteRegisteredModel: 'DeleteRegisteredModel' as const,
    CreateRegisteredModelWithExperimentRun:
      'CreateRegisteredModelWithExperimentRun' as const,
    CreateRegisteredModelVersion: 'CreateRegisteredModelVersion' as const,
    DeleteRegisteredModelVersion: 'DeleteRegisteredModelVersion' as const,
    SystemAdminsAdd: 'SystemAdminsAdd' as const,
    SystemAdminsDelete: 'SystemAdminsDelete' as const,
    CreateKafkaConfiguration: 'CreateKafkaConfiguration' as const,
    DeleteKafkaConfiguration: 'DeleteKafkaConfiguration' as const,
    TestKafkaConfiguration: 'TestKafkaConfiguration' as const,
    UpdateKafkaConfiguration: 'UpdateKafkaConfiguration' as const,
    CreatePypiConfiguration: 'CreatePypiConfiguration' as const,
    DeletePypiConfiguration: 'DeletePypiConfiguration' as const,
    TestPypiConfiguration: 'TestPypiConfiguration' as const,
    UpdatePypiConfiguration: 'UpdatePypiConfiguration' as const,
    UpdateModelReadmeOldCases: 'UpdateModelReadmeOldCases' as const,
    AddModelLabelCases: 'AddModelLabelCases' as const,
    DeleteModelLabelCases: 'DeleteModelLabelCases' as const,
    CreateTransition: 'CreateTransition' as const,
    UpdateStage: 'UpdateStage' as const,
    CreateRegisteredModel: 'CreateRegisteredModel' as const,
    AnalyticsTrackEvent: 'AnalyticsTrackEvent' as const,
  },
  Fragment: {
    NewChecklistItemValue: 'NewChecklistItemValue' as const,
    LockLevelAndAllowedActions: 'LockLevelAndAllowedActions' as const,
    ModelVersionSettings: 'ModelVersionSettings' as const,
    organizationV2Fragment: 'organizationV2Fragment' as const,
    organizationV2CustromAttributesDefinitionFragment:
      'organizationV2CustromAttributesDefinitionFragment' as const,
    UserOrServiceAccountForGroup: 'UserOrServiceAccountForGroup' as const,
    KafkaConfigurationFragment: 'KafkaConfigurationFragment' as const,
    ModelBasicData: 'ModelBasicData' as const,
    ModelVersionBasicData: 'ModelVersionBasicData' as const,
    ModelBreadcrumbData: 'ModelBreadcrumbData' as const,
    DatasetBreadcrumb: 'DatasetBreadcrumb' as const,
    EvaluationBasicData: 'EvaluationBasicData' as const,
    ProjectBreadcrumb: 'ProjectBreadcrumb' as const,
    TrackData: 'TrackData' as const,
    ArtifactData: 'ArtifactData' as const,
    ArtifactWithDatasetVersionData: 'ArtifactWithDatasetVersionData' as const,
    AttributeData: 'AttributeData' as const,
    ChecklistItemValueData: 'ChecklistItemValueData' as const,
    ChecklistItemValuesData: 'ChecklistItemValuesData' as const,
    ChecklistTemplateData: 'ChecklistTemplateData' as const,
    ChecklistTemplateItemData: 'ChecklistTemplateItemData' as const,
    CodeVersionGit: 'CodeVersionGit' as const,
    CodeVersionArchive: 'CodeVersionArchive' as const,
    CodeVersion: 'CodeVersion' as const,
    CodeVersionBlob: 'CodeVersionBlob' as const,
    CustomAttributeCategoryData: 'CustomAttributeCategoryData' as const,
    CustomAttributeDefinitionData: 'CustomAttributeDefinitionData' as const,
    CustomAttributeValueData: 'CustomAttributeValueData' as const,
    DatasetVersionData: 'DatasetVersionData' as const,
    EndpointData: 'EndpointData' as const,
    UpdateRequestData: 'UpdateRequestData' as const,
    BuildData: 'BuildData' as const,
    BuildOrErrorData: 'BuildOrErrorData' as const,
    KafkaConfigData: 'KafkaConfigData' as const,
    KafkaConfigurationsSimplifiedListData:
      'KafkaConfigurationsSimplifiedListData' as const,
    MachineConfigInfo: 'MachineConfigInfo' as const,
    AutoscalingMetricsData: 'AutoscalingMetricsData' as const,
    ScanStatusData: 'ScanStatusData' as const,
    ErrorData: 'ErrorData' as const,
    ExperimentRunData: 'ExperimentRunData' as const,
    ObservationAttribute: 'ObservationAttribute' as const,
    ObservationArtifact: 'ObservationArtifact' as const,
    Observation: 'Observation' as const,
    ExternalDeploymentData: 'ExternalDeploymentData' as const,
    JobData: 'JobData' as const,
    OwnerData: 'OwnerData' as const,
    PermissionData: 'PermissionData' as const,
    PrimitiveKeyValueData: 'PrimitiveKeyValueData' as const,
    PypiConfigurationData: 'PypiConfigurationData' as const,
    SystemAdminUserData: 'SystemAdminUserData' as const,
    UserData: 'UserData' as const,
    CurrentUserData: 'CurrentUserData' as const,
    UserV2Data: 'UserV2Data' as const,
    ServiceAccountV2Data: 'ServiceAccountV2Data' as const,
    WorkspaceData: 'WorkspaceData' as const,
    WorkspaceOrganizationData: 'WorkspaceOrganizationData' as const,
    AllowedActionsData: 'AllowedActionsData' as const,
    AlertFragment: 'AlertFragment' as const,
    AlertHistoryItemFragment: 'AlertHistoryItemFragment' as const,
    AlertSettingsFragment: 'AlertSettingsFragment' as const,
    AlerterFragment: 'AlerterFragment' as const,
    MonitoringIODescriptionFragment: 'MonitoringIODescriptionFragment' as const,
    MonitoringModelFragment: 'MonitoringModelFragment' as const,
    MonitoringModelRegisteredModelVersionFragment:
      'MonitoringModelRegisteredModelVersionFragment' as const,
    OrganizationStatsData: 'OrganizationStatsData' as const,
    RegisteredModelVersionFragment: 'RegisteredModelVersionFragment' as const,
    ActivityFragment: 'ActivityFragment' as const,
    ActivityAfterUpdatingFragment: 'ActivityAfterUpdatingFragment' as const,
    EndpointForShortEndpointsTable: 'EndpointForShortEndpointsTable' as const,
  },
};
