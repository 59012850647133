import PypiConfigurationsForm from 'features/systemAdmin/integrations/pypi/view/form/shared/PypiConfigurationsForm';
import { useCreatePypiConfiguration } from 'features/systemAdmin/integrations/pypi/store/useCreatePypiConfiguration';

type Props = {
  onDelete(): void;
};

const initialValues = {
  name: '',
  username: '',
  password: '',
  url: '',
  enabled: true,
};

export default function PypiConfigurationsFormCreate(props: Props) {
  const { createPypiConfiguration, creatingPypiConfiguration } =
    useCreatePypiConfiguration({ onCompleted: props.onDelete });

  return (
    <PypiConfigurationsForm
      initialValues={initialValues}
      onDelete={props.onDelete}
      isDeleting={false}
      onSave={createPypiConfiguration}
      isSaving={creatingPypiConfiguration.isRequesting}
    />
  );
}
