import { FieldConfig, Field, FieldProps } from 'formik';

import { OmitStrict } from 'shared/utils/types';
import RadioButtons, {
  RadioButtonsProps,
} from 'shared/view/elements/RadioButtons/RadioButtons';

export default function RadioButtonsField<T extends unknown>(
  props: FieldConfig & OmitStrict<RadioButtonsProps<T>, 'value' | 'onChange'>
) {
  return (
    <Field {...props}>
      {({ field, form }: FieldProps<T>) => (
        <div>
          <RadioButtons
            {...props}
            value={field.value}
            onChange={(value) => {
              form.setFieldValue(props.name, value);
            }}
          />
        </div>
      )}
    </Field>
  );
}
