import { equals } from 'ramda';

import {
  IGroupedOptions,
  IOptionType,
} from 'shared/view/elements/Selects/shared/types';

export const getSelectedOptionFromGroupedOptions = <T>(
  value: T,
  groupedOptions: IGroupedOptions<Exclude<T, null | undefined>>
): IOptionType<Exclude<T, null | undefined>> | undefined => {
  return groupedOptions.groups
    .flatMap((x) => x.options)
    .find((option) => equals(option.value, value));
};

export const getSelectedOption = <T>(
  value: T,
  options: Array<IOptionType<Exclude<T, null | undefined>>>
): IOptionType<Exclude<T, null | undefined>> | undefined => {
  return options.find((option) => equals(option.value, value));
};
