import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  title: string;
}

const Root = styled(Grid)(({ theme }) => ({
  padding: '16px',
  borderRadius: '4px',
  border: theme.palette.border.default,
}));

const HomepageWidgetWrapper = (props: Props) => {
  return (
    <Root item xs={12}>
      <Typography variant="h5" fontFamily="DM Sans" sx={{ pb: 2 }}>
        {props.title}
      </Typography>
      {props.children}
    </Root>
  );
};

export default HomepageWidgetWrapper;
