// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ButtonProps as MUIButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { SizeProp, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useTheme } from '@mui/material';
import { get } from 'lodash';

import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import matchType from 'shared/utils/matchType';
import { OmitStrict } from 'shared/utils/types';
import { ButtonCommonProps } from 'shared/view/elements/Button/shared/useButtonCommonProps';

import { IconAwesomeCustomizable } from '../../IconAwesome/IconAwesomeCustomizable';
import { useIconAwesomeColorPalette } from '../../IconAwesome/hooks/useIconAwesomeColorPalette';

type Props = OmitStrict<ButtonCommonProps, 'variant'> & {
  isLoading: boolean;
  variant: Required<MUIButtonProps>['variant'];
};

const TYPOGRAPHY_VARIANTS: {
  [key: string]: Required<TypographyProps>['variant'];
} = {
  small: 'buttonSmall',
  medium: 'buttonMedium',
  large: 'buttonLarge',
};

export function ButtonContent(props: Props) {
  const {
    children,
    variant,
    icon,
    iconEnd,
    isLoading,
    size = 'medium',
    useDynamicTypography,
    color,
  } = props;
  const reversedColors = matchType(
    {
      text: () => false,
      contained: () => true,
      outlined: () => false,
    },
    variant
  );

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      style={{ width: '100%' }}
      spacing={1}
    >
      {isLoading ? (
        <ButtonIcon
          icon={ICONS.circleNotch}
          spin={true}
          color={color}
          reversedColors={reversedColors}
        />
      ) : icon ? (
        <ButtonIcon
          icon={icon}
          reversedColors={reversedColors}
          color={color}
          disabled={props.disabled}
        />
      ) : null}

      {typeof children === 'string' && useDynamicTypography ? (
        <DynamicTypography
          sx={{
            fontWeight: props.variant === 'text' ? 500 : undefined,
          }}
          variant={TYPOGRAPHY_VARIANTS[size]}
          value={children}
        />
      ) : (
        <Typography
          variant={TYPOGRAPHY_VARIANTS[size]}
          sx={{ width: 'max-content' }}
        >
          {children}
        </Typography>
      )}

      {iconEnd ? (
        <ButtonIcon
          icon={iconEnd}
          color={color}
          reversedColors={reversedColors}
          disabled={props.disabled}
        />
      ) : null}
    </Stack>
  );
}

export const ButtonIcon = (props: {
  reversedColors: boolean;
  icon: IconDefinition;
  size?: SizeProp;
  spin?: boolean;
  disabled?: boolean;
  color?: Props['color'];
}) => {
  const theme = useTheme();
  const { colors } = useIconAwesomeColorPalette({
    reversedColors: props.reversedColors,
  });

  let _color = colors.clickable.active;
  if (props.color) {
    const themeColor = get(theme.palette, props.color) as unknown as string;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    _color = themeColor ?? colors.clickable.active;
  }
  if (props.disabled) {
    _color = colors.clickable.disabled;
  }

  return (
    <IconAwesomeCustomizable
      icon={props.icon}
      size={props.size}
      nativeProps={{
        color: _color,
        spin: props.spin,
      }}
    />
  );
};
