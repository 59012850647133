import { Grid } from '@mui/material';
import { FormikErrors } from 'formik';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';

import { validateNotEmpty, combineValidators } from 'shared/utils/validators';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import { CustomAttributeValueInput } from 'generated/types';
import { IGroupedOptions } from 'shared/view/elements/Selects/shared/types';

import { getField } from './ChecklistTemplatePopup';
import { CustomAttributeDefinitions } from './CustomAttributeValueManager';

type Props = {
  value?: CustomAttributeValueInput | null;
  index: number;
  error?: FormikErrors<CustomAttributeValueInput>;
  replace: (idx: number, value: Partial<CustomAttributeValueInput>) => void;
  definitionOptions: IGroupedOptions;
  customAttributesDefinitions: CustomAttributeDefinitions;
};

export const CustomAttributeValueItem = (props: Props) => {
  const {
    index,
    value,
    error,
    replace,
    definitionOptions,
    customAttributesDefinitions,
  } = props;

  const currentCustomAttributeListOptions = useMemo(() => {
    if (
      !isEmpty(value) &&
      !isEmpty(value.customAttributeDefinitionId) &&
      isNotError(customAttributesDefinitions)
    ) {
      const option = customAttributesDefinitions.find(
        (opt) => opt.id === value.customAttributeDefinitionId
      );
      if (option?.customAttributeListOptions) {
        return option.customAttributeListOptions.map((opt) => ({
          ...opt,
          label: opt.name,
          value: opt.id,
        }));
      }
    }

    return [];
  }, [customAttributesDefinitions, value]);

  return (
    <Grid container spacing={1} rowSpacing={2}>
      <Grid item xxs={6}>
        <SelectField
          label="Select custom attribute"
          onChange={(val) => {
            replace(index, {
              ...value,
              customAttributeDefinitionId: val.value,
              selectedValue: '',
            });
          }}
          name={getField({
            customAttributeValues: {
              [index]: {
                customAttributeDefinitionId: null,
              },
            },
          })}
          validate={combineValidators([validateNotEmpty('Custom attribute')])}
          options={definitionOptions}
          withoutError={!error?.customAttributeDefinitionId}
          errorMessage={error?.customAttributeDefinitionId}
        />
      </Grid>
      <Grid item xxs={6}>
        <SelectField
          label="Select attribute value"
          onChange={(val) => {
            replace(index, {
              ...value,
              selectedValue: val.value,
            });
          }}
          name={getField({
            customAttributeValues: {
              [index]: {
                selectedValue: null,
              },
            },
          })}
          validate={combineValidators([
            validateNotEmpty('Attribute attribute'),
          ])}
          options={currentCustomAttributeListOptions}
          withoutError={!error?.selectedValue}
          errorMessage={error?.selectedValue}
        />
      </Grid>
    </Grid>
  );
};
