import { FC } from 'react';

import { Switch } from 'shared/view/elements/Switch/Switch';
import { useDarkMode } from 'features/darkMode/store/hook';

const DarkModeControl: FC<React.PropsWithChildren<unknown>> = () => {
  const { isEnableDarkMode, changeIsEnableDarkModeHandler } = useDarkMode();

  return (
    <label>
      <span>Dark mode:</span>
      <Switch
        checked={isEnableDarkMode}
        onChange={(e) => changeIsEnableDarkModeHandler(e.target.checked)}
      />
    </label>
  );
};

export default DarkModeControl;
