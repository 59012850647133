import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { MonitoringMetricType } from 'generated/types';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { convertMonitoringFilterToGraphQL } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { toGraphQLDate } from 'shared/utils/graphql/toGraphQLDate';
import { convertTimeRangeToDateRange } from 'shared/utils/TimeRange';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';

import {
  FullMetricQuery,
  FullMetricQueryVariables,
} from './graphql-types/useFullMetric.generated';
import { filterMetricsByRegisteredModelVersionIds } from '../shared/filterMetricsByRegisteredModelVersionIds';

const FULL_METRIC_QUERY = gql`
  query FullMetricQuery(
    $monitoredEntityId: ID!
    $output: MonitoringIODescriptionInput!
    $startDate: Date!
    $endDate: Date!
    $aggregationMilliseconds: Int!
    $type: MonitoringMetricType!
    $filters: [MonitoringFilterInput!]
  ) {
    monitoredEntity(id: $monitoredEntityId) {
      ... on Error {
        ...ErrorData
      }

      ... on MonitoredEntity {
        id
        metrics {
          metric(
            query: {
              startDate: $startDate
              endDate: $endDate
              filters: $filters
              output: $output
              types: [$type]
            }
          ) {
            modelVersionId
            modelVersion {
              ... on Error {
                ...ErrorData
              }
              ... on RegisteredModelVersion {
                id
                version
              }
            }
            value
            type
          }

          metricOverTime(
            query: {
              startDate: $startDate
              endDate: $endDate
              filters: $filters
              output: $output
              types: [$type]
              aggregationMilliseconds: $aggregationMilliseconds
            }
          ) {
            modelVersionId
            modelVersion {
              ... on Error {
                ...ErrorData
              }
              ... on RegisteredModelVersion {
                id
                version
              }
            }
            values
            time
            type
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useFullMetricQuery = (props: {
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  output: MonitoringIODescription;
  metricType: MonitoringMetricType;
}) => {
  const variables = useMemo((): FullMetricQueryVariables => {
    const dateRange = convertTimeRangeToDateRange(
      props.widgetExternalDeps.timeRange
    );
    return {
      monitoredEntityId: props.widgetExternalDeps.monitoredEntityId,
      output: props.output,
      startDate: toGraphQLDate(dateRange.from),
      endDate: toGraphQLDate(dateRange.to),
      type: props.metricType,
      aggregationMilliseconds: props.widgetExternalDeps.aggregation.timeWindow,
      filters: props.widgetExternalDeps.filters.map(
        convertMonitoringFilterToGraphQL
      ),
    };
  }, [
    props.widgetExternalDeps.timeRange,
    props.widgetExternalDeps.monitoredEntityId,
    props.widgetExternalDeps.aggregation.timeWindow,
    props.widgetExternalDeps.filters,
    props.output,
    props.metricType,
  ]);

  const query = useCustomQuery<FullMetricQuery, FullMetricQueryVariables>(
    FULL_METRIC_QUERY,
    {
      variables,
    }
  );

  const memoizedConvert = useCallback(
    (res: FullMetricQuery) => {
      return mapDataOrError(res.monitoredEntity, (x) => {
        return {
          metric: filterMetricsByRegisteredModelVersionIds(
            x.metrics.metric,
            props.widgetExternalDeps.registeredModelVersionIds
          ),
          metricOverTime: filterMetricsByRegisteredModelVersionIds(
            x.metrics.metricOverTime,
            props.widgetExternalDeps.registeredModelVersionIds
          ),
        };
      });
    },
    [props.widgetExternalDeps.registeredModelVersionIds]
  );

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert,
    queryResult: query,
  });
};
