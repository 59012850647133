import React, { useMemo } from 'react';

import { OmitStrict } from 'shared/utils/types';

import { getKey, getValue, BarChartData } from './utils/model';
import { getBarSettings } from '../shared/getBarSettings';
import BaseBarChart, { BaseBarChartProps } from './BaseBarChart';
import DefaultTooltipContent from '../shared/DefaultTooltipContent/DefaultTooltipContent';

interface LocalProps<AdditionalData>
  extends OmitStrict<
    BaseBarChartProps<AdditionalData>,
    'xScaleDomain' | 'getBarSettings'
  > {}

export function defaultRenderBarChartTooltipContent<AdditionalData>(
  d: BarChartData<AdditionalData>,
  color: string
) {
  return (
    <DefaultTooltipContent label={getKey(d)} labelColor={color}>
      {getValue(d)}
    </DefaultTooltipContent>
  );
}

function BarChart<AdditionalData>(props: LocalProps<AdditionalData>) {
  const xScaleDomain = useMemo(() => props.data.map(getKey), [props.data]);

  return (
    <BaseBarChart
      {...props}
      xScaleDomain={xScaleDomain}
      getBarSettings={getBarSettings}
    />
  );
}

export default BarChart;
