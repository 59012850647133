import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import customAttrEdit1 from '../images/custom-attributes-edit-1.png';

export const CustomAttrEditOrg = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Edit model or version attribute
    </Typography>
    <Typography variant="body2" mb={3}>
      The attributes that your organization tracks are viewable and editable on
      model cards and version summaries. You can edit via the UI by selecting
      the gear icon.
    </Typography>
    <img
      src={customAttrEdit1}
      alt="custom attributes selection to edit"
      width="90%"
      height="auto"
    />
  </WizardStepContent>
);
