import { Transform } from 'vega-lite/build/src/transform';

import isNotNil from 'shared/utils/isNotNill';

export const getFilterInvalidDatum = (
  fields: Array<{ field: string }>
): Transform => ({
  filter: {
    or: fields
      .map(({ field }) => (field ? { field, valid: true } : undefined))
      .filter(isNotNil),
  },
});
