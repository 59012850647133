import { makeGetFieldName } from 'shared/utils/getFieldName';
import { FirstArgument } from 'shared/utils/types';
import { getFieldName as getUpdateEndpointSettingsFieldName } from 'features/deployment/canary/updateEndpoint/view/UpdateEndpointSection/settings/getFieldName';

import { MachineConfigSettings } from './index';

const getFieldName = makeGetFieldName<MachineConfigSettings>();

const makeNestedGetFieldName =
  (prop: string) => (path: FirstArgument<typeof getFieldName>) => {
    return getFieldName({ [prop]: path });
  };

export const getMachineConfigSettingsFieldName = makeNestedGetFieldName(
  getUpdateEndpointSettingsFieldName({ machineConfiguration: null })
);
