import Alert from '@mui/material/Alert';

import { NonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';

type Props = {
  conditions: NonEmptyArray<boolean>;
  message: string;
  children: JSX.Element | null;
};

// ts-unused-exports:disable-next-line
export default function ConditionalWarningWrapper(props: Props) {
  if (props.conditions.some((value) => !value)) {
    return <Alert severity="warning">{props.message}</Alert>;
  }
  return props.children;
}
