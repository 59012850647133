import { QueryHookOptions } from '@apollo/client';

import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';
import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';

import {
  LOAD_CURRENT_ORGANIZATION_GROUPS,
  LoadCurrentOrganizationGroups,
  LoadCurrentOrganizationGroupsVariables,
} from '../graphql';

export const useOrganizationGroups = (
  {
    organizationId,
  }: {
    organizationId: string;
  },
  options?: QueryHookOptions<
    LoadCurrentOrganizationGroups,
    LoadCurrentOrganizationGroupsVariables
  >
) => {
  const [load, query] = useCustomLazyQuery<
    LoadCurrentOrganizationGroups,
    LoadCurrentOrganizationGroupsVariables
  >(LOAD_CURRENT_ORGANIZATION_GROUPS, {
    ...options,
    variables: { organizationId },
  });

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return {
    data: isNotError(data) ? data : undefined,
    communication,
    load,
  };
};

const convertResponse = ({ organizationV2 }: LoadCurrentOrganizationGroups) =>
  mapDataOrError(organizationV2, (organization) => organization.groups);
