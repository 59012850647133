import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { CHECKLIST_TEMPLATE_FRAGMENT } from 'shared/graphql/Checklist/ChecklistTemplate';
import { CHECKLIST_TEMPLATE_ITEM_FRAGMENT } from 'shared/graphql/Checklist/ChecklistTemplateItem';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { CUSTOM_ATTRIBUTE_VALUE_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeValue';

import * as Types from './graphql-types/useCreateOrUpdateChecklistTemplate.generated';

export { Types };

export const QUERY = gql`
  mutation CreateOrUpdateTemplate(
    $organizationId: ID!
    $input: ChecklistTemplateInput!
  ) {
    organizationV2(id: $organizationId) {
      ... on OrganizationV2 {
        id
        createOrUpdateChecklistTemplate(input: $input) {
          ... on ChecklistTemplate {
            id
            ...ChecklistTemplateData
            checklistTemplateItems {
              id
              ...ChecklistTemplateItemData
            }
            customAttributeValues {
              id
              ...CustomAttributeValueData
            }
          }
          ... on Error {
            ...ErrorData
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${CHECKLIST_TEMPLATE_FRAGMENT}
  ${CHECKLIST_TEMPLATE_ITEM_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_VALUE_FRAGMENT}
`;

type Props = {
  onCompleted: () => void;
};

export const useCreateOrUpdateChecklistTemplate = ({ onCompleted }: Props) => {
  const [createOrUpdate, communication] = useCustomMutation<
    Types.CreateOrUpdateTemplate,
    Types.CreateOrUpdateTemplateVariables
  >(
    QUERY,
    {
      context: 'creating or updating checklist template',
    },
    {
      onCompleted,
    }
  );

  return {
    createOrUpdate,
    communication,
  };
};
