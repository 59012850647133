import { useMemo } from 'react';

import Popup from 'shared/view/elements/Popup/Popup';
import { validateNotEmpty } from 'shared/utils/validators';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';

export enum NameFormPopupType {
  Add = 'Add',
  Edit = 'Edit',
}

export interface NameFormType {
  name: string;
}

interface Props {
  initialValues: NameFormType;
  onSubmit: (form: NameFormType) => void;
  type: NameFormPopupType;
  entityName: string;
  isOpen: boolean;
  closePopup: () => void;
}

const getLabels = (props: { type: NameFormPopupType; entityName: string }) => {
  return {
    title: `${props.type} ${props.entityName}`,
    submit: props.type,
  };
};

const getFieldName = makeGetFieldName<NameFormType>();

const NameFormPopup = (props: Props) => {
  const labels = useMemo(
    () => getLabels({ entityName: props.entityName, type: props.type }),
    [props.entityName, props.type]
  );

  return (
    <PresetFormik onSubmit={props.onSubmit} initialValues={props.initialValues}>
      {({ isValid, values }) => (
        <Popup
          title={labels.title}
          isOpen={props.isOpen}
          onClose={props.closePopup}
          buttons={{
            mainButtonProps: {
              type: 'button',
              disabled: !isValid,
              isLoading: false,
              children: props.type,
              dataTest: 'submit',
              onClick: () => {
                props.onSubmit(values);
              },
            },
            secondaryButtonProps: {
              children: 'Cancel',
              dataTest: 'cancel',
              isLoading: false,
              onClick: props.closePopup,
            },
          }}
        >
          <TextInputField
            isRequired={true}
            label="Name"
            name={getFieldName({
              name: null,
            })}
            validate={validateNotEmpty('name')}
          />
        </Popup>
      )}
    </PresetFormik>
  );
};

export default NameFormPopup;
