import { FC } from 'react';

import { makeEntitiesPrimitivesDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/EntitiesPrimitivesDiffView';
import { makeEntitiesLabelsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/EntityLabelsDiffView';
import { makeEnvironmentsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/EnvironmentsDiffView';
import CompareTable from 'shared/view/domain/CompareEntities/New/CompareTable/CompareTable';
import routes from 'shared/routes';
import { formatDateWithTime } from 'shared/utils/formatters/dateTime';
import { stageToView } from 'shared/view/domain/Registry/ModelVersion/RegisteredModelVersionStage/stageToView';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { makeKeyedItemsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/KeyedItemsDiff';
import { registeredModelVersionTypeToString } from 'shared/models/Registry/RegisteredModelVersion/Type';
import { makeAttributesDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/AttributesDiff/AttributesDiff';
import { makeArtifactsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/ArtifactsDiff';
import { makeDatasetsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/DatasetsDiff';
import { makeCodeVersionsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/CodeVersionDiffView';
import makeItemPropertyDiffView from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/ItemPropertyDiffView';
import * as PropertyDiffDescriptions from 'shared/view/domain/CompareEntities/New/CompareTable/PropertyDiffDescriptions';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';

import compareRegisteredModelVersions from '../../model/registeredModelVersionsComparing';
import { ComparedRegisteredModelVersions } from '../../model/comparedRegisteredModelVersions';

interface Props {
  registeredModelVersions: ComparedRegisteredModelVersions;
}

const RegisteredModelVersionsCompareTable: FC<
  React.PropsWithChildren<Props>
> = (props) => {
  return (
    <CompareTable
      columnEntityName="Model"
      entities={props.registeredModelVersions}
      compareEntities={compareRegisteredModelVersions}
      getRemovedEntityName={(entity) => entity.version}
      getPropertiesDiffDescriptions={getPropertiesDiffDescriptions}
    />
  );
};

function getPropertiesDiffDescriptions(
  registeredModelVersionsDiff: ReturnType<typeof compareRegisteredModelVersions>
) {
  return PropertyDiffDescriptions.makePropertyDiffDescriptions([
    makeEntitiesPrimitivesDiff({
      name: 'Version',
      diff: registeredModelVersionsDiff.version,
      render: (version, { id, registeredModel }) => (
        <InlineLink
          to={routes.registeredModelVersion.getRedirectPath({
            registeredModelId: registeredModel.id,
            versionId: id,
            workspaceName: registeredModel.workspace.name,
          })}
        >
          {version}
        </InlineLink>
      ),
    }),
    makeEntitiesPrimitivesDiff({
      name: 'Description',
      diff: registeredModelVersionsDiff.description,
      render: (d) => d,
    }),
    makeEntitiesPrimitivesDiff({
      name: 'Type',
      diff: registeredModelVersionsDiff.type,
      render: (type) => registeredModelVersionTypeToString(type),
    }),
    makeEntitiesPrimitivesDiff({
      name: 'Stage',
      diff: registeredModelVersionsDiff.stage,
      render: (type) => stageToView(type),
    }),
    makeEntitiesPrimitivesDiff({
      name: 'Project',
      diff: registeredModelVersionsDiff.project,
      render: (_, { run }) =>
        isNotNullableRestrictedGraphqlError(run) ? (
          <InlineLink
            to={routes.projectOverview.getRedirectPath({
              projectId: run.project.id,
              workspaceName: run.project.workspace.name,
            })}
          >
            {run.project.name}
          </InlineLink>
        ) : null,
    }),
    makeEntitiesPrimitivesDiff({
      name: 'Experiment run',
      diff: registeredModelVersionsDiff.experimentRun,
      render: (_, { run }) =>
        isNotNullableRestrictedGraphqlError(run) ? (
          <InlineLink
            to={routes.experimentRun.getRedirectPath({
              projectId: run.project.id,
              experimentRunId: run.id,
              workspaceName: run.project.workspace.name,
            })}
          >
            {run.name}
          </InlineLink>
        ) : null,
    }),
    makeEntitiesPrimitivesDiff({
      name: 'Date updated',
      diff: registeredModelVersionsDiff.dateUpdated,
      render: (x) => formatDateWithTime(new Date(x)),
    }),
    makeEntitiesLabelsDiff({
      name: 'Labels',
      diff: registeredModelVersionsDiff.labels,
    }),
    makeArtifactsDiff(registeredModelVersionsDiff.artifacts),
    makeAttributesDiff(registeredModelVersionsDiff.attributes),
    makeDatasetsDiff(registeredModelVersionsDiff.datasets),
    makeEnvironmentsDiff(registeredModelVersionsDiff.environments),
    makeCodeVersionsDiff(registeredModelVersionsDiff.codeVersions),
    makeKeyedItemsDiff({
      name: 'Endpoints',
      diff: registeredModelVersionsDiff.endpoints,
      render: (endpointsDiff) => [
        makeItemPropertyDiffView({
          name: 'Path',
          diff: endpointsDiff.properties.path,
          render: (path, item) => (
            <InlineLink
              to={routes.endpointOverview.getRedirectPath({
                endpointId: item.id,
                workspaceName: item.workspaceName,
              })}
            >
              {path}
            </InlineLink>
          ),
        }),
        makeItemPropertyDiffView({
          name: 'Status',
          diff: endpointsDiff.properties.status,
          render: (x) => x,
        }),
      ],
    }),
  ]);
}

export default RegisteredModelVersionsCompareTable;
