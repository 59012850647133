import handleUnionCases from 'shared/utils/handleUnionCases';
import camelCaseToSentenseCase from 'shared/utils/camelCaseToSentenseCase';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import { autoscalingQuantity } from 'shared/models/Deployment/canary/EndpointMachineConfiguration/Autoscaling/Autoscaling';

import { MachineConfigSettings } from '../../settings';
import { getMachineConfigSettingsFieldName } from '../../settings/getMachineConfigSettingsFieldName';

export const QuantitiesFields = ({ disabled }: { disabled: boolean }) => {
  const quantityFieldNames = handleUnionCases<
    keyof MachineConfigSettings['autoscaling']['quantities'],
    string
  >()([
    ['maxReplicas', (type) => type],
    ['minReplicas', (type) => type],
    ['maxScale', (type) => type],
    ['minScale', (type) => type],
  ]).map((type) => ({
    name: getMachineConfigSettingsFieldName({
      autoscaling: {
        quantities: {
          [type]: null,
        },
      },
    }),
    label: camelCaseToSentenseCase(type),
  }));

  return (
    <>
      {quantityFieldNames.map(({ label, name }) => (
        <TextInputField
          key={name}
          name={name}
          validate={autoscalingQuantity.validate}
          label={label}
          disabled={disabled}
        />
      ))}
    </>
  );
};
