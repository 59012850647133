import { useCallback } from 'react';

import {
  isNotNullableRestrictedGraphqlError,
  isRestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';
import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import { useDataGridHighlightRows } from 'shared/view/elements/DataGrid/hooks/useDataGridHighlightRows';
import Section from 'shared/view/elements/SectionComponents/Section/Section';

import { useAlertsQuery } from '../../store/alerts/useAlerts';
import { useUpdateBulkAlerts } from '../../store/updateBulkAlerts/useUpdateBulkAlerts';
import MonitoringAlertsTable from './MonitoringAlertsTable/MonitoringAlertsTable';
import { UpdateBulkAlertsFormType } from './MonitoringAlertsTable/UpdateBulkAlertsForm/UpdateBulkAlertsForm';

interface Props {
  model: MonitoringModel;
}

const MonitoringAlerts = (props: Props) => {
  const { highlight, highlightedRows } = useDataGridHighlightRows();

  const { mutate, communication: updatingAlerts } = useUpdateBulkAlerts({
    highlight,
  });

  const { data, communication: loadingAlerts } = useAlertsQuery({
    monitoredEntityId: props.model.monitoredEntity.id,
  });

  const alerts = isRestrictedGraphqlError(data) ? undefined : data;

  const updateBulkAlerts = useCallback(
    (alertsIds: string[], form: UpdateBulkAlertsFormType) => {
      mutate({
        alerts: (isNotNullableRestrictedGraphqlError(data) ? data : []).filter(
          (alert) => alertsIds.includes(alert.id)
        ),
        form,
      });
    },
    [data, mutate]
  );

  return (
    <Section label="Alert rules and notifications">
      <MonitoringAlertsTable
        allowedActions={props.model.monitoredEntity.allowedActions}
        alerts={alerts}
        loadingAlerts={loadingAlerts}
        monitoringModelId={props.model.id}
        monitoredModelType={props.model.type}
        workspaceName={props.model.workspaceName}
        ioDescriptions={props.model.ioDescriptions}
        isUpdating={updatingAlerts.isRequesting}
        updateBulkAlerts={updateBulkAlerts}
        highlightedRows={highlightedRows}
      />
    </Section>
  );
};

export default MonitoringAlerts;
