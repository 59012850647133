import { useMonitoringModelContext } from 'features/monitoring/models/model/store/state/context';
import MonitoringAlerts from 'features/monitoring/alerts/view/MonitoringAlerts/MonitoringAlerts';
import { useAppBreadcrumbs } from 'features/layout/view/Layout/AppBreadcrumbs';
import { LinkButton } from 'shared/view/elements/Button/LinkButton';
import routes from 'shared/routes';
import isNotNil from 'shared/utils/isNotNill';

import { useMonitoringModelPageBreadcrumbs } from '../shared/useMonitoringModelPageBreadcrumbs';
import MonitoringAlertsLayout from '../shared/MonitoringAlertsLayout/MonitoringAlertsLayout';
import { useMonitoringAlertsPageBreadcrumbs } from '../shared/useMonitoringAlertsPageBreadcrumbs';

const MonitoringAlertsPage = () => {
  const { model, updateModelState } = useMonitoringModelContext();
  const breadcrumbs = useAppBreadcrumbs(
    useMonitoringModelPageBreadcrumbs({
      modelName: model.name,
    }),
    useMonitoringAlertsPageBreadcrumbs()
  );

  return (
    <MonitoringAlertsLayout
      model={model}
      breadcrumbs={breadcrumbs}
      updateModelState={updateModelState}
      layoutActions={[
        model.monitoredEntity.allowedActions.update ? (
          <LinkButton
            to={routes.monitoringAlertCreation.getRedirectPath({
              monitoringModelId: model.id,
              workspaceName: model.workspaceName,
            })}
          >
            Add Rule
          </LinkButton>
        ) : null,
      ].filter(isNotNil)}
    >
      <MonitoringAlerts model={model} />
    </MonitoringAlertsLayout>
  );
};

export default MonitoringAlertsPage;
