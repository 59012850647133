import { FC } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';

import { selectFlags } from 'features/flags';
import { IAutoscalingMetric } from 'features/deployment/canary/endpoints/store/endpointQuery/endpointQuery';
import Accordion from 'shared/view/elements/Accordion/Accordion';
import { getContextualHelper } from 'features/deployment/canary/shared/getContextualHelper';

import AutoscalingSection from '../AutoscalingSection/AutoscalingSection';
import ResourcesSection from '../ResourcesSection/ResourcesSection';
import EnvSection from '../EnvSection/EnvSection';
import PerformanceSection from '../PerformanceSection/PerformanceSection';

interface Props {
  canonicalAutoscaling: IAutoscalingMetric[];
}

const MachineConfiguration: FC<React.PropsWithChildren<Props>> = ({
  canonicalAutoscaling,
}) => {
  const {
    deployment: { isEnableDedicatedResources },
  } = useSelector(selectFlags);
  return (
    <Stack spacing={2}>
      <Grid>
        <Accordion
          title="Autoscaling"
          contextualHelp={getContextualHelper('autoscaling')}
          subtitle="Dynamically adjust the capacity based on your workload to maintain steady and predictable performance at the lowest possible cost."
          dataTest="autoscaling"
        >
          <AutoscalingSection
            canonicalAutoscalingMetrics={canonicalAutoscaling}
          />
        </Accordion>
      </Grid>
      <Grid>
        <Accordion
          title="Environment variables"
          subtitle="Environment variables lets you control specific logic in your application dynamically. "
          contextualHelp={getContextualHelper('environment')}
          dataTest="environment-variables"
        >
          <EnvSection />
        </Accordion>
      </Grid>
      {isEnableDedicatedResources ? (
        <Grid>
          <Accordion
            title="Resources"
            subtitle="You can set custom resource requests and limits by specifying CPU and memory resource needs."
            dataTest="resources"
          >
            <ResourcesSection />
          </Accordion>
        </Grid>
      ) : null}
      <Grid>
        <Accordion
          maxLines={5}
          title="Performance"
          subtitle="Compute resource dedicated mode provides a dedicated machine by blocking entire nodes for model inferences of an endpoint and prevents noisy neighbor problems. With a shared mode, resources are shared between model endpoints."
          dataTest="resources"
        >
          <PerformanceSection />
        </Accordion>
      </Grid>
    </Stack>
  );
};

export default MachineConfiguration;
