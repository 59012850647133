import { gql } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/useAddServiceAccount.generated';
import { useOrganizationUsersContext } from './useOrganizationUsers';

export { Types };

export const QUERY = gql`
  mutation AddServiceAccountToOrganizationV2(
    $organizationId: ID!
    $fullName: String!
    $email: String!
    $description: String!
  ) {
    organizationV2(id: $organizationId) {
      ... on OrganizationV2 {
        id
        addServiceAccount(
          fullName: $fullName
          email: $email
          description: $description
        ) {
          id
          fullName
          email
          description
          primaryKey
          secondaryKey
        }
      }
    }
  }
`;

export const useAddServiceAccount = () => {
  const [run__, communication, data] = useCustomMutation<
    Types.AddServiceAccountToOrganizationV2,
    Types.AddServiceAccountToOrganizationV2Variables
  >(QUERY, {
    context: 'adding service account',
  });
  const { usersApi } = useOrganizationUsersContext();

  return {
    run: (variables: Types.AddServiceAccountToOrganizationV2Variables) => {
      run__(variables).then(() => usersApi.reload());
    },
    communication,
    data:
      data?.organizationV2.__typename === 'OrganizationV2'
        ? data.organizationV2.addServiceAccount
        : undefined,
  };
};
