import { SizeProp, IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';

import CopyToClipboard from '../CopyToClipboard/CopyToClipboard';
import { ICONS } from '../IconAwesome/ICONS';

interface ILocalProps {
  copyText: string | (() => string);
  tooltipTitle?: string;
  withTooltip?: boolean;
  disabled?: boolean;
  reversedColors?: boolean;
  size?: SizeProp;
  icon?: IconDefinition;
  onClick?: () => void;
}

const CopyButton = (props: ILocalProps) => (
  <CopyToClipboard
    text={props.copyText}
    withTooltip={props.withTooltip}
    tooltipTitle={props.tooltipTitle}
  >
    {(onCopy) => (
      <IconAwesomeClickable
        dataTest="copy-button"
        icon={props.icon || ICONS.copy}
        disabled={props.disabled}
        onClick={() => {
          onCopy();
          props.onClick?.();
        }}
        reversedColors={props.reversedColors}
        size={props.size}
      />
    )}
  </CopyToClipboard>
);

export default CopyButton;
