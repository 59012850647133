import { gql } from '@apollo/client';

export const CUSTOM_ATTRIBUTE_DEFINITION_FRAGMENT = gql`
  fragment CustomAttributeDefinitionData on CustomAttributeDefinition {
    id
    organizationId
    customAttributeCategoryId
    attributeType
    targetType
    name
    icon
    timeCreated
    timeUpdated
    sort
    sourceRequired
    customAttributeListOptions {
      id
      name
      sort
    }
  }
`;
