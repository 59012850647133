import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MUILink, { LinkProps } from '@mui/material/Link';
// eslint-disable-next-line no-restricted-imports
import { Variant } from '@mui/material/styles/createTypography';
import Stack from '@mui/material/Stack';

import { EXTERNAL_LINK_PROPS } from 'shared/utils/globalConstants/externalLinkProps';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';

import DynamicTypography from '../DynamicTypography/DynamicTypography';

export const INLINE_LINK_EXTERNAL_GAP = 4;

export interface InlineProps {
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onAuxClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  dataTest?: string;
  variant?: Variant;
  sx?: React.CSSProperties;
  to: string;
  children: React.ReactNode;
  isExternal?: boolean;
  withHover?: boolean;
  simple?: boolean;
  withoutArrow?: boolean;
  color?: LinkProps['color'];
}

export default function InlineLink(props: InlineProps) {
  if (props.isExternal) {
    return <External {...props} />;
  }

  return <Internal {...props} />;
}

function External(props: InlineProps) {
  const { to, children, sx, withoutArrow, color = 'info.main' } = props;

  return (
    <MUILink
      underline={props.withHover ? 'hover' : 'none'}
      variant={props.variant}
      {...EXTERNAL_LINK_PROPS}
      onClick={props.onClick}
      data-test={props.dataTest}
      href={to}
      color={color}
      sx={sx}
    >
      {props.simple ? (
        <span>
          {children}{' '}
          {withoutArrow ? null : (
            <IconAwesomeCustomizable icon={ICONS.arrowUpRight} size="sm" />
          )}
        </span>
      ) : (
        <Stack
          component="span"
          direction="row"
          spacing={0.5}
          width="fit-content"
          alignItems="center"
        >
          {typeof children === 'string' ? (
            <DynamicTypography
              maxLines={1}
              value={children}
              variant={props.variant}
              sx={{ ...sx }}
            />
          ) : (
            children
          )}
          {withoutArrow ? null : (
            <IconAwesomeCustomizable icon={ICONS.arrowUpRight} size="sm" />
          )}
        </Stack>
      )}
    </MUILink>
  );
}

function Internal(props: InlineProps) {
  const { to, children } = props;

  return (
    <Link
      onClick={props.onClick}
      data-test={props.dataTest}
      style={{ textDecoration: 'none' }}
      to={to}
      onAuxClick={props.onAuxClick}
    >
      <MUILink underline="none" component="span" color="info.main">
        {props.simple ? (
          <span>{children}</span>
        ) : typeof children === 'string' ? (
          <DynamicTypography
            maxLines={1}
            value={children}
            variant={props.variant}
            sx={props.sx}
          />
        ) : (
          children
        )}
      </MUILink>
    </Link>
  );
}
