import { queryParams, route } from 'shared/routes/shared/routesTreeBuilder';

interface ILoginQueryParams {
  redirect_back: string;
}

export const SIGN_UP = 'sign-up';
export const UNVERIFIED_MAIL = 'unverified-email';

export const loginRoutes = route('login', {
  tag: 'login',
  queryParams: queryParams<ILoginQueryParams>(),
})(
  route('problem', {
    tag: 'loginProblem',
    queryParams: queryParams<ILoginQueryParams>(),
  })()
);

export const signUpRoutes = route(SIGN_UP, {
  tag: 'signUp',
})(
  route('mail-form', {
    tag: 'signUpMailForm',
  })(),
  route('personal-info-form', {
    tag: 'signUpPersonalInfoForm',
  })(),
  route('problem', {
    tag: 'signUpProblem',
  })(),
  route(UNVERIFIED_MAIL, {
    tag: 'signUpUnverifiedEmail',
  })()
);
