// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MonitoringPopup from 'shared/view/elements/Popup/MonitoringPopup';

interface Props {
  isOpen: boolean;
  closePopup: () => void;
  children: JSX.Element;
}

const WidgetFormPopup = (props: Props) => {
  return (
    <MonitoringPopup
      title="Configure widget"
      isOpen={props.isOpen}
      onClose={props.closePopup}
    >
      {props.children}
    </MonitoringPopup>
  );
};

export default WidgetFormPopup;
