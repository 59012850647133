import { gql } from '@apollo/client';

export const PYPI_CONFIGURATION_FRAGMENT = gql`
  fragment PypiConfigurationData on PypiConfiguration {
    id
    name
    url
    username
    password
    enabled
  }
`;
