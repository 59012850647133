import { Flags } from 'shared/models/Flags';

import { ServiceFlags } from './graphql/graphql-types/queries.generated';

export const defaultServiceFlags: ServiceFlags['serviceFlags'] = {
  __typename: 'FeatureFlags',
  enableDatasets: false,
  enableDeployment: false,
  enableMonitoring: false,
  enableProjects: false,
  enableRegistry: false,
  enableDashboard: false,
  enablePromotionApprovalRequired: false,
  enableEndpoints: false,
  enableMonitoredEntity: false,
  enableNotificationChannel: false,
  enableRegisteredModel: false,
};

export const mergeServiceFlags = ({
  serviceFlags,
  featureFlags,
}: {
  serviceFlags: ServiceFlags['serviceFlags'];
  featureFlags: Flags;
}) => {
  const mergedFlags = { ...featureFlags };
  mergedFlags.isEnableDatasets =
    featureFlags.isEnableDatasets && serviceFlags.enableDatasets;
  mergedFlags.deployment.isEnable =
    featureFlags.deployment.isEnable && serviceFlags.enableDeployment;
  mergedFlags.monitoring.isEnable =
    featureFlags.monitoring.isEnable && serviceFlags.enableMonitoring;
  mergedFlags.isEnableProjects =
    featureFlags.isEnableProjects && serviceFlags.enableProjects;
  mergedFlags.isEnableRegistry =
    featureFlags.isEnableRegistry && serviceFlags.enableRegistry;
  mergedFlags.isEnableDashboard =
    featureFlags.isEnableDashboard && serviceFlags.enableDashboard;
  mergedFlags.catalog.isEnablePromotionApprovalRequired =
    featureFlags.catalog.isEnablePromotionApprovalRequired &&
    serviceFlags.enablePromotionApprovalRequired;

  return mergedFlags;
};
