import Stack from '@mui/material/Stack';

import { CustomDomain } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/shared/CustomDomain';
import NumberInput from 'shared/view/elements/TextInput/NumberInput';

const CustomDomainView = ({
  domain,
  onChange,
}: {
  domain: CustomDomain;
  onChange: (domain: CustomDomain) => void;
}) => {
  return (
    <Stack direction={'row'} alignItems={'center'} style={{ width: '100%' }}>
      <NumberInput
        value={domain.min}
        label="min"
        withoutError={true}
        onChange={(newMin) => onChange({ ...domain, min: newMin })}
      />
      <NumberInput
        value={domain.max}
        label="max"
        withoutError={true}
        onChange={(newMax) => onChange({ ...domain, max: newMax })}
      />
    </Stack>
  );
};

export default CustomDomainView;
