import { EnabledResourceActions } from '../store/useOrganizationRoles';
import { useAddRole } from '../store/useAddRole';
import RolePopup from './RolePopup';

const initialSettings = {
  description: '',
  name: '',
  resourceActions: [],
};

const CreateRolePopup = (props: {
  organizationId: string;
  enabledResourceActions: EnabledResourceActions;
  onClose: () => void;
}) => {
  const addRoleApi = useAddRole(props.onClose);

  return (
    <RolePopup
      enabledResourceActions={props.enabledResourceActions}
      submitButton={{
        children: 'Create',
        isLoading: addRoleApi.communication.isRequesting,
        onSubmit: (settings) => {
          addRoleApi.run({
            organizationId: props.organizationId,
            input: settings,
          });
        },
      }}
      initialSettings={initialSettings}
      onClose={props.onClose}
      title="Create new role"
    />
  );
};

export default CreateRolePopup;
