import { QueryHookOptions } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';

import {
  WorkspaceRegisteredModelsSummary,
  WorkspaceRegisteredModelsSummaryVariables,
} from '../graphql/graphql-types/queries.generated';
import { WORKSPACE_REGISTERED_MODELS_SUMMARY } from '../graphql/queries';

export const useWorkspaceRegisteredModelSummary = (
  props: {
    workspaceName: string;
    organizationId: string | undefined;
  },
  options?: QueryHookOptions<
    WorkspaceRegisteredModelsSummary,
    WorkspaceRegisteredModelsSummaryVariables
  >
) => {
  const { workspaceName, organizationId } = props;

  const query = useCustomQuery<
    WorkspaceRegisteredModelsSummary,
    WorkspaceRegisteredModelsSummaryVariables
  >(WORKSPACE_REGISTERED_MODELS_SUMMARY, {
    ...options,
    variables: {
      workspaceName,
      organizationId,
    },
  });

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return {
    data,
    communication,
    reload: query.refetch,
  };
};

const convertResponse = ({ workspace }: WorkspaceRegisteredModelsSummary) =>
  mapDataOrError(workspace, (_workspace) => _workspace);
