import { head } from 'ramda';

import { normalizeDistribution } from 'shared/models/Monitoring/Distribution/NormalizedDistribution';

import DiscreteDistributionBarChart from './DiscreteDistributionBarChart/DiscreteDistributionBarChart';
import FloatDistributionBarChart from './FloatDistributionBarChart/FloatDistributionBarChart';
import { DistributionBarChartProps } from './shared/DistributionBarChartProps';

const DistributionBarChart = (props: DistributionBarChartProps) => {
  const distribution = head(props.referenceDistributions);
  const normalizedReferenceDistribution = distribution
    ? normalizeDistribution(distribution)
    : null;
  return normalizedReferenceDistribution?.type === 'discrete' ? (
    <DiscreteDistributionBarChart {...props} />
  ) : (
    <FloatDistributionBarChart {...props} />
  );
};

export default DistributionBarChart;
