// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MuiAccordionDetails, {
  AccordionDetailsProps,
} from '@mui/material/AccordionDetails';
import Divider, { DividerProps } from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MuiAccordion, {
  AccordionProps as MuiAccordionProps,
} from '@mui/material/Accordion';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { pick } from 'lodash';

import isNotNil from 'shared/utils/isNotNill';

import DynamicTypography, {
  DynamicTypographyProps,
} from '../DynamicTypography/DynamicTypography';
import InfoIcon from '../InfoIcon/InfoIcon';

export type AccordionProps = {
  title: string;
  headerRightContent?: React.ReactNode;
  subtitle?: string;
  contextualHelp?: string;
  summaryLeftContent?: React.ReactNode;
  summaryRightContent?: React.ReactNode;
  children: React.ReactNode;
  dataTest?: string;
  maxLines?: number;
} & (
  | {
      expanded: boolean;
      onChange(_: React.SyntheticEvent, expanded: boolean): void;
    }
  | { expanded?: undefined; defaultExpanded?: boolean }
) & {
    slots?: {
      Accordion?: Partial<MuiAccordionProps>;
      AccordionSummary?: Partial<AccordionSummaryProps>;
      Title?: Partial<DynamicTypographyProps>;
      AccordionDetails?: Partial<AccordionDetailsProps>;
      Divider?: Partial<DividerProps>;
    };
  };

function Accordion(props: AccordionProps) {
  const {
    dataTest,
    summaryLeftContent,
    title,
    headerRightContent,
    contextualHelp,
    subtitle,
    summaryRightContent,
    children,
    maxLines = 1,
    slots,
  } = props;
  const pickedProps = pick(props, ['expanded', 'defaultExpanded', 'onChange']);

  return (
    <MuiAccordion {...pickedProps} data-test="accordion" {...slots?.Accordion}>
      <MuiAccordionSummary {...slots?.AccordionSummary}>
        <Stack width="100%" alignItems="center" direction="row">
          <Stack minWidth={0} direction="column" data-test={dataTest}>
            <Stack direction="row" alignItems="center">
              {isNotNil(summaryLeftContent) ? (
                <div onClick={(e) => e.stopPropagation()}>
                  {summaryLeftContent}
                </div>
              ) : null}

              <div style={{ minWidth: 0, width: 'auto' }}>
                <DynamicTypography
                  variant="avatarInitials"
                  dataTest="accordion-title"
                  value={title}
                  color="text.primary"
                  maxLines={3}
                  {...slots?.Title}
                />
              </div>

              {isNotNil(headerRightContent) ? (
                <div>{headerRightContent}</div>
              ) : null}

              {contextualHelp ? (
                <InfoIcon tooltip={contextualHelp} tooltipPlacement="bottom" />
              ) : null}
            </Stack>

            {subtitle ? (
              <DynamicTypography
                sx={{ fontSize: '14px' }}
                value={subtitle}
                maxLines={maxLines}
              />
            ) : null}
          </Stack>
          {isNotNil(summaryRightContent) ? (
            <div
              style={{ marginLeft: 'auto' }}
              onClick={(e) => e.stopPropagation()}
            >
              {summaryRightContent}
            </div>
          ) : null}
        </Stack>
      </MuiAccordionSummary>
      <MuiAccordionDetails {...slots?.AccordionDetails}>
        <Stack direction={'column'}>
          <Divider {...slots?.Divider} />
          {children}
        </Stack>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
}

export default Accordion;
