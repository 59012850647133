import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { useAppBreadcrumbs } from 'features/layout/view/Layout/AppBreadcrumbs';
import { TabsLayout } from 'pages/authorized/shared/TabsLayout';
import { useWorkspaceEvaluations } from 'features/evaluations/evaluationsList/hooks/useWorkspaceEvaluations';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

import { useEvaluationsPageBreadcrumbs } from './shared/useEvaluationsPageBreadcrumbs';

const Evaluations = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'Catalog' */ 'features/evaluations/evaluationsList/view/Evaluations'
    )
);

const EvaluationsPage = () => {
  const workspaceName: string = useWorkspaceNameFromURL();
  const { data, communication } = useWorkspaceEvaluations({ workspaceName });

  const breadcrumbs = useAppBreadcrumbs(useEvaluationsPageBreadcrumbs());

  return (
    <TabsLayout breadcrumbs={breadcrumbs} pageEntityOrError={data}>
      <DefaultMatchRemoteDataOrError
        communication={communication}
        data={data}
        context="loading model"
      >
        {(loaded) => (
          <Evaluations
            versions={loaded.versions}
            workspaceName={workspaceName}
          />
        )}
      </DefaultMatchRemoteDataOrError>
    </TabsLayout>
  );
};

export default EvaluationsPage;
