import { ScaleLinear } from 'd3-scale';

export const TimeSeriesChartViolationLine = (props: {
  yScale: ScaleLinear<number, number>;
  value: number;
  innerWidth: number;
}) => {
  const [yMin, yMax] = props.yScale.domain();
  return isValueInRange({
    from: yMin,
    to: yMax,
  })(props.value) ? (
    <line
      x1={0}
      x2={props.innerWidth}
      y1={props.yScale(props.value)}
      y2={props.yScale(props.value)}
      strokeWidth={2}
      stroke="red"
    />
  ) : null;
};

export const TimeSeriesChartViolatingArea = (props: {
  value1: number;
  value2: number;
  yScale: ScaleLinear<number, number>;
  innerWidth: number;
}) => {
  const [yMin, yMax] = props.yScale.domain();
  const yMinValue = Math.min(props.value1, props.value2);
  const yMaxValue = Math.max(props.value1, props.value2);
  const checkValue = isValueInRange({
    from: yMin,
    to: yMax,
  });
  const yStart = checkValue(yMinValue) ? yMinValue : yMin;
  const yEnd = checkValue(yMaxValue) ? yMaxValue : yMax;

  return (
    <rect
      x={0}
      y={props.yScale(yEnd)}
      height={props.yScale(yStart) - props.yScale(yEnd)}
      width={props.innerWidth}
      fill="red"
      fillOpacity={0.25}
    />
  );
};

const isValueInRange =
  (range: { from: number; to: number }) => (value: number) => {
    return range.from <= value && range.to >= value;
  };
