import { PartialUnion } from 'shared/utils/types';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';

import AddTagModal from '../../TagsManager/AddTagModal/AddTagModal';
import { TagOptionalProps } from '../../TagsManager/Tag/Tag';

export type LabelUpdateProps = PartialUnion<
  TagOptionalProps & {
    onAdd(value: string): void;
  }
>;

const UpdateLabelsAction = (props: {
  labels: string[];
  labelUpdateProps: LabelUpdateProps;
}) => {
  const { openPopup, isPopupOpen, closePopup } = usePopupManager();

  return props.labelUpdateProps.onDelete ? (
    <div>
      {isPopupOpen ? (
        <AddTagModal
          isOpen={isPopupOpen}
          onClose={closePopup}
          tags={props.labels}
          {...props.labelUpdateProps}
          onRemove={props.labelUpdateProps.onDelete}
        />
      ) : undefined}
      <IconAwesomeClickable
        dataTest="tags-manager-open-tag-creator-button"
        icon={ICONS.circlePlus}
        onClick={openPopup}
      />
    </div>
  ) : null;
};

export default UpdateLabelsAction;
