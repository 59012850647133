import { uniq } from 'ramda';

import isNotNil from 'shared/utils/isNotNill';

const compareItemsWithTheSameKey = <Item, Diff>(
  getKey: (item: NonNullable<Item>) => string,
  compare: (items: (Item | undefined)[], key: string) => Diff,
  entitiesItems: (Item[] | undefined)[]
): Array<Diff> => {
  const allKeys = uniq(
    entitiesItems.flatMap((items) =>
      (items || [])
        .map((x) => (isNotNil(x) ? getKey(x) : undefined))
        .filter(isNotNil)
    )
  );
  return allKeys.map((key) => {
    const itemsByKey = entitiesItems.map((items) =>
      (items || []).find((item) => isNotNil(item) && getKey(item) === key)
    );
    return compare(itemsByKey, key);
  });
};

export default compareItemsWithTheSameKey;
