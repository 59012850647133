import { AlerterRange } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/Alerter';

import { TimeSeriesChartAlertDecorationsProps } from './shared/TimeSeriesChartAlertDecorationsProps';
import {
  TimeSeriesChartViolatingArea,
  TimeSeriesChartViolationLine,
} from './shared/TimeSeriesViolationComponents';

const TimeSeriesChartViolationRange = (
  props: TimeSeriesChartAlertDecorationsProps<AlerterRange>
) => {
  const [yMin, yMax] = props.yScale.domain();
  return (
    <g>
      {props.alerter.alertIfOutsideRange ? (
        <g>
          <TimeSeriesChartViolatingArea
            value1={yMin}
            value2={props.alerter.lowerBound}
            yScale={props.yScale}
            innerWidth={props.innerWidth}
          />
          <TimeSeriesChartViolatingArea
            yScale={props.yScale}
            value1={props.alerter.upperBound}
            value2={yMax}
            innerWidth={props.innerWidth}
          />
        </g>
      ) : (
        <TimeSeriesChartViolatingArea
          yScale={props.yScale}
          value1={props.alerter.upperBound}
          value2={props.alerter.lowerBound}
          innerWidth={props.innerWidth}
        />
      )}
      <TimeSeriesChartViolationLine
        yScale={props.yScale}
        value={props.alerter.lowerBound}
        innerWidth={props.innerWidth}
      />
      <TimeSeriesChartViolationLine
        yScale={props.yScale}
        value={props.alerter.upperBound}
        innerWidth={props.innerWidth}
      />
    </g>
  );
};

export default TimeSeriesChartViolationRange;
