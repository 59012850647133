import { useCallback, useMemo } from 'react';
import { ScalePoint, ScaleBand } from 'd3-scale';
import { head, sortBy, uniq } from 'ramda';
import { scaleOrdinal } from '@visx/scale';

import { Distribution } from 'shared/models/Monitoring/Distribution/Distribution';
import {
  ViolinChartData,
  ViolinRenderAdditionalContentProps,
} from 'shared/view/charts/ViolinCharts/shared/types';
import { formatDateNumber } from 'shared/utils/charts/formatDateNumber';
import { Size } from 'shared/utils/charts/chartSizes';
import { OnSetReset } from 'shared/utils/charts/zoom/useZoomReset';
import { ViolinChartTooltipData } from 'shared/view/charts/ViolinCharts/shared/tooltip';
import ViolinBarChart from 'shared/view/charts/ViolinCharts/ViolinBarChart/ViolinBarChart';
import ViolinAreaChart from 'shared/view/charts/ViolinCharts/ViolinAreaChart/ViolinAreaChart';
import { defaultChartMargin } from 'shared/view/charts/shared/margin';
import { MonitoringAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import { makeDistributionKeys } from 'shared/models/Monitoring/Distribution/DistributionDescription';
import { DistributionOverTime } from 'shared/models/Monitoring/Distribution/DistributionOverTime';
import { normalizeDistribution } from 'shared/models/Monitoring/Distribution/NormalizedDistribution';
import parseGraphqlDate from 'shared/utils/graphql/parseGraphqlDate';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';
import { chartsDataColors } from 'shared/view/charts/shared/colors';
import ChartWithLegendContainer from 'shared/view/charts/ChartWithLegendContainer/ChartWithLegendContainer';
import { useChartLegendProps } from 'shared/utils/charts/chartLegendProps/useChartLegendProps';
import { useActiveKeys } from 'shared/utils/activeKeys';
import { Scalars } from 'generated/types';

import HistogramsChartAlertDecorations from './HistogramsChartAlertDecorations/HistogramsChartAlertDecorations';
import HistogramTooltipChart from './HistogramTooltipChart/HistogramTooltipChart';

interface DriftOverTime {
  modelVersionId: string;
  time: Scalars['Date']['output'][];
  values: Scalars['Number']['output'][];
}

interface Props {
  id: string;
  size: Size;
  onSetReset: OnSetReset;
  liveDistributions: DistributionOverTime[];
  referenceDistributions: Distribution[];
  alert: MonitoringAlert | undefined;
  driftOverTime: DriftOverTime[];
}

interface Meta {
  distribution: DistributionOverTime | Distribution;
  drift: number | undefined;
  modelVersion: string;
  colorKey: string;
  type: 'live' | 'reference';
}

export type HistogramsChartData = ViolinChartData<Meta>;

const tooltipChartSize = {
  width: 350,
  height: 180,
};

const makeRenderTooltipContent =
  (props: {
    referenceDistributions: Distribution[];
    alert: MonitoringAlert | undefined;
    getColorByData: (d: HistogramsChartData) => string;
  }) =>
  (data: ViolinChartTooltipData<Meta>) => {
    const group = head(data.groups);

    return (
      <HistogramTooltipChart
        id={data.groupKey}
        size={tooltipChartSize}
        liveDistributions={data.groups
          .filter((t) =>
            props.referenceDistributions.length === 1
              ? t.meta.type === 'live'
              : true
          )
          .map((g) => g.meta.distribution)}
        referenceDistributions={props.referenceDistributions}
        alert={props.alert}
        time={group.meta.distribution.time}
        dataColor={props.getColorByData(group)}
        values={data.groups.map((d) => d.meta)}
      />
    );
  };

const margin = { ...defaultChartMargin, left: 100 };

const HistogramsChart = (props: Props) => {
  const chartData = useMemo(
    () =>
      sortBy((a) => a.time, props.liveDistributions).map(
        makeConvertHistogramsChartData(props.driftOverTime)
      ),
    [props.liveDistributions, props.driftOverTime]
  );

  const colorKeys = useMemo(
    () => uniq(chartData.map((d) => d.meta.colorKey)),
    [chartData]
  );

  const { activeKeys, toggleKey } = useActiveKeys(colorKeys);

  const filteredData = useMemo(
    () =>
      chartData
        .filter((d) => !d.items.every((item) => item.value === 0))
        .filter((d) => activeKeys.includes(d.meta.colorKey)),
    [activeKeys, chartData]
  );

  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: colorKeys,
        range: chartsDataColors,
      }),
    [colorKeys]
  );

  const getColorByData = useCallback(
    (d: HistogramsChartData) => colorScale(d.meta.colorKey),
    [colorScale]
  );

  const renderTooltipContent = useMemo(
    () =>
      makeRenderTooltipContent({
        referenceDistributions: props.referenceDistributions,
        alert: props.alert,
        getColorByData,
      }),
    [props.referenceDistributions, props.alert, getColorByData]
  );

  const renderAdditionalContent = useCallback(
    (
      renderAdditionalContentProps: ViolinRenderAdditionalContentProps<
        Meta,
        ScaleBand<string> | ScalePoint<string>
      >
    ) =>
      props.alert ? (
        <HistogramsChartAlertDecorations
          {...renderAdditionalContentProps}
          data={filteredData}
          alerter={props.alert.alerter}
        />
      ) : null,
    [filteredData, props.alert]
  );

  const chartProps = {
    id: props.id,
    onSetReset: props.onSetReset,
    data: filteredData,
    renderTooltipContent,
    margin,
    renderAdditionalContent,
    getColorByData,
  };

  const baseDistribution = head([
    ...props.referenceDistributions,
    ...props.liveDistributions,
  ]);
  const normalizedReferenceDistribution = baseDistribution
    ? normalizeDistribution(baseDistribution)
    : undefined;

  const legendProps = useChartLegendProps({
    activeKeys,
    colorScale,
    legendPosition: 'right',
    toggleKey,
  });

  const renderChart = (size: Size) =>
    normalizedReferenceDistribution?.type === 'discrete' ? (
      <ViolinBarChart {...chartProps} width={size.width} height={size.height} />
    ) : (
      <ViolinAreaChart
        {...chartProps}
        width={size.width}
        height={size.height}
        yScaleDomain={
          normalizedReferenceDistribution
            ? makeDistributionKeys(normalizedReferenceDistribution)
            : []
        }
      />
    );

  if (colorKeys.length > 1) {
    return (
      <ChartWithLegendContainer
        legendProps={legendProps}
        widgetSize={props.size}
      >
        {renderChart}
      </ChartWithLegendContainer>
    );
  }

  return renderChart(props.size);
};

const makeConvertHistogramsChartData =
  (driftOverTime: DriftOverTime[]) =>
  (distribution: DistributionOverTime): HistogramsChartData => {
    const normalizedDistribution = normalizeDistribution(distribution);
    const keys = makeDistributionKeys(normalizedDistribution);

    const driftItem = driftOverTime.find(
      (d) => d.modelVersionId === distribution.modelVersionId
    );

    const drift = driftItem
      ? parseGraphQLNumber(
          driftItem.values[
            driftItem.time.findIndex(
              (time) => +parseGraphqlDate(time) === +distribution.time
            )
          ]
        )
      : undefined;

    return {
      key: formatDateNumber(distribution.time),
      items: normalizedDistribution.normalizedValues.map((value, i) => ({
        value,
        key: keys[i],
      })),
      meta: {
        distribution,
        drift,
        modelVersion: normalizedDistribution.modelVersion,
        colorKey: normalizedDistribution.modelVersion,
        type: 'live',
      },
    };
  };

export default HistogramsChart;
