import { FieldConfig, Field, FieldProps } from 'formik';

import { OmitStrict } from 'shared/utils/types';
import TextInput, {
  TextInputProps,
} from 'shared/view/elements/TextInput/TextInput';

type Props = FieldConfig &
  OmitStrict<TextInputProps, 'value' | 'onChange' | 'onBlur' | 'name' | 'meta'>;

export default function TextInputField(props: Props) {
  return (
    <Field {...props}>
      {({ field, meta, form }: FieldProps<string>) => (
        <TextInput
          {...props}
          value={field.value}
          onChange={(v) => form.setFieldValue(field.name, v)}
          onBlur={field.onBlur}
          meta={meta}
        />
      )}
    </Field>
  );
}
