/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';

import {
  MonitoringModels,
  MonitoringModelsVariables,
} from './graphql-types/useMonitoringModels.generated';

const MONITORING_MODELS = gql`
  query MonitoringModels($workspaceName: String!, $organizationId: ID) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        monitoredModels {
          models {
            id
            name
            createdAt
            monitoredEntity {
              id
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useMonitoringModels = ({
  workspaceName,
  organizationId,
}: {
  workspaceName: string;
  organizationId: string | undefined;
}) => {
  const query = useCustomQuery<MonitoringModels, MonitoringModelsVariables>(
    MONITORING_MODELS,
    {
      variables: {
        workspaceName,
        organizationId,
      },
    }
  );

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult: query,
  });
};

const convert = (res: MonitoringModels) => {
  return mapDataOrError(
    res.workspace,
    (workspace) => workspace.monitoredModels
  );
};
