import { DeployedExperimentRunPlayground } from 'features/deployment/deployedExperimentRunPlayground';

type ILocalProps = {
  endpointPath: string;
  endpointFullUrl: string | null;
  isEndpointEnvironmentActive: boolean;
  environmentTokens: Array<{ id: string; value: string }>;
  tokensEnabled: boolean;
  isKafkaEnabled: boolean;
};

const EndpointPlayground = ({
  endpointPath,
  endpointFullUrl,
  environmentTokens = [],
  isEndpointEnvironmentActive,
  tokensEnabled,
  isKafkaEnabled,
  ...additionalProps
}: ILocalProps) => {
  return (
    <DeployedExperimentRunPlayground
      isKafkaEnabled={isKafkaEnabled}
      endpointInfo={{
        isActive: isEndpointEnvironmentActive,
        path: endpointPath,
        token: tokensEnabled ? environmentTokens[0]?.value : null,
        fullUrl: endpointFullUrl,
      }}
      {...additionalProps}
    />
  );
};

export default EndpointPlayground;
