import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useResizeDetector } from 'react-resize-detector';
import Skeleton from '@mui/material/Skeleton';

import { AppBreadcrumbs } from 'features/layout/view/Layout/AppBreadcrumbs';
import Breadcrumbs from 'shared/view/elements/Breadcrumbs/Breadcrumbs';
import { IApplicationState } from 'setup/store/store';
import { closeAlert } from 'features/restrictedGraphqlError/actions';
import {
  isRestrictedGraphqlError,
  RestrictedGraphqlErrorOrData,
} from 'shared/graphql/ErrorFragment';
import { IBreadcrumbItem } from 'shared/view/elements/Breadcrumbs/Breadcrumbs';
import { NonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';
import Alert from 'shared/view/elements/Alert/Alert';

type Props = {
  breadcrumbs: AppBreadcrumbs | undefined;
  actions?: JSX.Element[] | null;
  tabsElement?: JSX.Element | null;
  children: Exclude<React.ReactNode, null | undefined>;
  // todo find a better name
  pageEntityOrError: RestrictedGraphqlErrorOrData<unknown> | undefined;
  titleAdditionalContent?: JSX.Element | null;
};

export const TABS_LAYOUT_CONTENT_OFFSET = '225px';

export const TabsLayout = (props: Props) => {
  return (
    <>
      {props.breadcrumbs ? (
        <>
          <Breadcrumbs breadcrumbs={props.breadcrumbs} />
          <TitleBlock
            actions={props.actions}
            breadcrumbs={props.breadcrumbs}
            titleAdditionalContent={props.titleAdditionalContent}
          />
        </>
      ) : null}

      {props.tabsElement ? <Box mt={3}>{props.tabsElement}</Box> : null}

      <GraphqlErrorBlock pageEntityOrError={props.pageEntityOrError} />

      <Box mt={3} flex="1 1 auto" pb={8}>
        {props.children}
      </Box>
    </>
  );
};

const ActionsWrapper = styled('div')({
  height: '100%',
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
});

const TitleBlock = (props: {
  actions?: JSX.Element[] | null;
  breadcrumbs: NonEmptyArray<IBreadcrumbItem>;
  titleAdditionalContent?: JSX.Element | null;
}) => {
  const { actions = [], breadcrumbs, titleAdditionalContent } = props;
  const { width = 0, ref } = useResizeDetector();
  const { name, topEnhancer, hasTopEnhancer } =
    breadcrumbs[breadcrumbs.length - 1];

  return (
    <Stack direction="row" marginTop={2} alignItems="center">
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        width={
          actions !== null && actions.length > 0
            ? `calc(100% - ${width}px)`
            : '100%'
        }
      >
        <Box
          pr={actions !== null && actions.length > 0 ? 1 : 0}
          overflow="hidden"
        >
          {hasTopEnhancer ? (
            topEnhancer !== undefined ? (
              topEnhancer
            ) : (
              <Typography variant="overline">
                <Skeleton variant="text" width={225} />
              </Typography>
            )
          ) : null}
          {name ? (
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
              color="text.primary"
              variant="h4"
            >
              {name}
            </Typography>
          ) : (
            <Typography variant="h4">
              <Skeleton variant="text" width={350} />
            </Typography>
          )}
        </Box>

        {titleAdditionalContent}
      </Stack>
      <ActionsWrapper ref={ref}>
        {name && actions ? (
          <Stack direction="row">
            {actions.map((a, i) => (
              <Box key={i} width="max-content">
                {a}
              </Box>
            ))}
          </Stack>
        ) : null}
      </ActionsWrapper>
    </Stack>
  );
};

function GraphqlErrorBlock({
  pageEntityOrError,
}: Pick<Props, 'pageEntityOrError'>) {
  const graphqlError = useSelector(
    (state: IApplicationState) => state.graphqlError.error
  );

  const dispatch = useDispatch();

  return (
    graphqlError && (
      <Box mt={1}>
        <Alert title="Access limitation" onClose={() => dispatch(closeAlert())}>
          {isRestrictedGraphqlError(pageEntityOrError)
            ? 'You do not have permission to view the page you have requested.'
            : 'You cannot view some information on this page due to lack of permission or the associated resources have been deleted.'}
        </Alert>
      </Box>
    )
  );
}
