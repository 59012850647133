import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import Preloader from 'shared/view/elements/Preloader/Preloader';
import useDownloadArtifact from 'features/artifactManager/store/hooks/useDownloadArtifact';
import { useToastCommunicationErrorWatcher } from 'shared/view/elements/Notification/Notification';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import Tooltip from 'shared/view/elements/Tooltip/Tooltip';
import { selectFlags } from 'features/flags';
import { useToast } from 'features/toast/store/hooks';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import isNotNil from 'shared/utils/isNotNill';
import Pile from 'shared/view/elements/Pile/Pile';
import { ArtifactData } from 'shared/graphql/Artifact/graphql-types/Artifact.generated';
import {
  ArtifactWithPath,
  checkArtifactWithPath,
} from 'shared/graphql/Artifact/Artifact';
import DeleteIconButton from 'shared/view/elements/DeleteIconButton/DeleteIconButton';
import { trackEvent } from 'setup/app/analytics';

import { EntityWithArtifactsType } from '../store/types';

interface ArtifactProps {
  entityId: string;
  entityType: EntityWithArtifactsType;
  artifact: ArtifactData;
  onDelete?: () => void;
  isDeleteDisable?: boolean;
  getAdditionalButtons?: (
    artifact: ArtifactData
  ) => [JSX.Element, ...JSX.Element[]];
}

const Artifact = (props: ArtifactProps) => {
  const icon = getArtifactIcon(props.artifact.filenameExtension || 'other');
  const { isEnableIncompleteArtifact } = useSelector(selectFlags);

  const additionalButtons = props.getAdditionalButtons?.(props.artifact) ?? [];

  return (
    <Pile
      key={props.artifact.key}
      icon={icon}
      rightContent={
        isEnableIncompleteArtifact ? (
          <StatusIcon isCompleted={props.artifact.uploadCompleted} />
        ) : undefined
      }
      content={
        <Stack spacing={0}>
          <Typography variant="body2">{props.artifact.key}</Typography>
          <Typography variant="caption" color="text.secondary">
            .{props.artifact.filenameExtension}
          </Typography>
        </Stack>
      }
      buttons={[
        ...additionalButtons,
        !checkArtifactWithPath(props.artifact) ? null : (
          <ArtifactDownloadButton
            key={`${props.artifact.key}-download`}
            artifact={props.artifact}
            entityId={props.entityId}
            entityType={props.entityType}
          />
        ),
        props.onDelete ? (
          <DeleteIconButton
            key={props.artifact.key}
            description={'artifact'}
            entityName="artifact"
            disabled={props.isDeleteDisable}
            customBasicText="Are you sure you want to delete this artifact? This action cannot be undone."
            onDelete={props.onDelete}
          />
        ) : null,
      ].filter(isNotNil)}
    />
  );
};

const StatusIcon = ({ isCompleted }: { isCompleted: boolean }) => (
  <Tooltip
    type="withSpan"
    title={`This artifact has ${
      isCompleted ? 'finished uploading' : 'completed upload'
    }`}
  >
    <IconAwesomeInfo
      icon={isCompleted ? ICONS.circleCheck : ICONS.circleDashed}
      size="lg"
    />
  </Tooltip>
);

export const ArtifactDownloadButton = (props: {
  entityId: string;
  entityType: EntityWithArtifactsType;
  artifact: ArtifactWithPath;
}) => {
  const { downloadArtifact, downloadingArtifact } = useDownloadArtifact(props);

  const incomplete = !props.artifact.uploadCompleted;

  const toast = useToast();

  useEffect(() => {
    if (downloadingArtifact.isSuccess) {
      toast('The artifact is downloading', 'success');
    }
  }, [downloadingArtifact.isSuccess, toast]);
  useToastCommunicationErrorWatcher(downloadingArtifact, {
    context: 'when downloading an artifact',
  });

  return downloadingArtifact.isRequesting ? (
    <Preloader size="small" />
  ) : (
    <IconAwesomeClickable
      icon={ICONS.arrowDownToBracket}
      onClick={() => {
        trackEvent({
          type: 'catalog.registered_model_version.integrate.artifact_downloaded',
        });
        downloadArtifact();
      }}
      disabled={incomplete}
      size="lg"
    />
  );
};

export const getArtifactIcon = (extension: string): IconDefinition => {
  let icon = ICONS.boxTaped;

  switch (extension) {
    case 'pkl':
      icon = ICONS.python;
      break;
    case 'py':
      icon = ICONS.python;
      break;
    case 'json':
      icon = ICONS.json;
      break;
    case 'ipnyb':
      icon = ICONS.jupyterNotebook;
      break;
    case 'tar.gz':
      icon = ICONS.docker;
      break;
    case 'docker':
      icon = ICONS.docker;
      break;
    default:
      icon = ICONS.boxTaped;
  }

  return icon;
};

export default Artifact;
