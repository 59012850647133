import { equals } from 'ramda';

import { TimeRange } from 'shared/utils/TimeRange';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import Select from 'shared/view/elements/Selects/Select/Select';

const options: Array<{ label: string; value: TimeRange }> = [
  {
    label: '1 hour',
    value: {
      start: 'now-1h',
      end: 'now',
    },
  },
  {
    label: '1 Day',
    value: {
      start: 'now-24h',
      end: 'now',
    },
  },
  {
    label: '7 Days',
    value: {
      start: 'now-7d',
      end: 'now',
    },
  },
  {
    label: '30 Days',
    value: {
      start: 'now-30d',
      end: 'now',
    },
  },
];

const MonitoringTimeRangeQuickSelect = (props: {
  timeRange: TimeRange;
  updateTimeRange: (timeRange: TimeRange) => void;
}) => {
  return (
    <Select
      icon={ICONS.clock}
      value={options.find((r) => equals(r.value, props.timeRange))}
      options={options}
      withoutError={true}
      placeholder="Select range"
      onChange={({ value }) => {
        props.updateTimeRange(value);
      }}
    />
  );
};

export default MonitoringTimeRangeQuickSelect;
