import { useMemo } from 'react';

import { DistributionWidgetVariant } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/shared/BaseDistributionVariantWidget';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import handleUnionCases from 'shared/utils/handleUnionCases';
import { OmitStrict } from 'shared/utils/types';
import { validateNotEmpty } from 'shared/utils/validators';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { IOptionType } from 'shared/view/elements/Selects/shared/types';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import { getAvailableIODescriptionsForDrift } from 'shared/models/Monitoring/Distribution/Drift';

import MonitoringIODescriptionField from '../shared/MonitoringIODescriptionField/MonitoringIODescriptionField';
import { BaseMonitoringWidgetFormProps } from '../shared/types/BaseMonitoringWidgetFormProps';
import VariantTypeField from '../shared/VariantFields/VariantTypeField/VariantTypeField';
import MonitoringWidgetFormPopupContent from '../../MonitoringWidgetFormPopup/MonitoringWidgetFormPopupContent/MonitoringWidgetFormPopupContent';

interface BarChartWidgetFormType {
  type: 'barChart';
  title: string;
  variant: DistributionWidgetVariant;
  ioDescription: MonitoringIODescription;
}

const getFieldName = makeGetFieldName<BarChartWidgetFormType>();

interface Props extends BaseMonitoringWidgetFormProps {
  submitText: string;
  onSubmit: (
    form: OmitStrict<BarChartWidgetFormType, 'ioDescription'> & {
      ioDescription: MonitoringIODescription;
    }
  ) => void;
  initialValues: BarChartWidgetFormType | null;
  defaultInput: MonitoringIODescription;
}

const variantOptions = handleUnionCases<
  DistributionWidgetVariant,
  IOptionType<DistributionWidgetVariant>
>()([['distribution', (value) => ({ label: 'Distribution', value })]]);

const BarChartWidgetForm = (props: Props) => {
  const initialValues = useMemo(
    (): BarChartWidgetFormType => ({
      type: 'barChart',
      title: '',
      variant: 'distribution',
      ioDescription: props.defaultInput,
    }),
    [props.defaultInput]
  );

  return (
    <PresetFormik
      initialValues={props.initialValues ?? initialValues}
      onSubmit={props.onSubmit}
    >
      {({ isValid, handleReset, values }) => (
        <MonitoringWidgetFormPopupContent
          isValid={isValid}
          onBack={props.onBack}
          onReset={handleReset}
          submitText={props.submitText}
          onSubmitClick={() => {
            props.onSubmit(values);
          }}
          widgetPreview={values}
          widgetExternalDeps={props.widgetExternalDeps}
        >
          <TextInputField
            label="Title"
            isRequired={true}
            name={getFieldName({ title: null })}
            validate={validateNotEmpty('title')}
          />

          <VariantTypeField
            name={getFieldName({ variant: null })}
            options={variantOptions}
          />

          <MonitoringIODescriptionField
            label="Feature"
            name={getFieldName({ ioDescription: null })}
            ioDescriptions={getAvailableIODescriptionsForDrift(
              props.widgetExternalDeps.ioDescriptions
            )}
          />
        </MonitoringWidgetFormPopupContent>
      )}
    </PresetFormik>
  );
};

export default BarChartWidgetForm;
