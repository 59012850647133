import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import {
  DeleteMonitoringModel,
  DeleteMonitoringModelVariables,
} from './graphql-types/useDeleteMonitoringModel.generated';

const DELETE_MONITORING_MODEL = gql`
  mutation DeleteMonitoringModel($monitoringModelId: ID!) {
    monitoredModel(id: $monitoringModelId) {
      ... on Error {
        ...ErrorData
      }
      ... on MonitoredModel {
        id
        delete
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useDeleteMonitoringModel = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [deleteEntity, deletingEntity] = useCustomMutation<
    DeleteMonitoringModel,
    DeleteMonitoringModelVariables
  >(DELETE_MONITORING_MODEL, { context: 'deleting model' }, { onCompleted });

  return {
    deleteEntity,
    deletingEntity,
  };
};
