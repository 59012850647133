import * as React from 'react';
import { FormikProps, FormikValues } from 'formik';
import { Breakpoint } from '@mui/material';

import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import Popup from 'shared/view/elements/Popup/Popup';
import { ICommunication } from 'shared/utils/redux/communication';

function PopupForm<T extends FormikValues = FormikValues>(props: {
  initialValues: T;
  title: string;
  communication: ICommunication;
  children: (props: FormikProps<T>) => React.ReactNode;
  isOpen: boolean;
  onSubmit: (form: T) => void;
  onClose(): void;
  maxWidth?: Breakpoint;
  getMainButtonProps?: (values: T) => { disabled: boolean };
}) {
  return (
    <PresetFormik<T>
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
    >
      {(formProps) => (
        <Popup
          fullWidth={true}
          maxWidth={props.maxWidth ?? 'sm'}
          title={props.title}
          isOpen={props.isOpen}
          onClose={props.onClose}
          buttons={{
            secondaryButtonProps: {
              isLoading: false,
              children: 'Cancel',
              onClick: props.onClose,
            },
            mainButtonProps: {
              type: 'submit',
              disabled:
                !formProps.isValid ||
                (props.getMainButtonProps
                  ? props.getMainButtonProps(formProps.values).disabled
                  : false),
              onClick: formProps.submitForm,
              isLoading: props.communication.isRequesting,
              children: 'Create',
              dataTest: 'create',
            },
          }}
        >
          <FormStack>{props.children(formProps)}</FormStack>
        </Popup>
      )}
    </PresetFormik>
  );
}

export default PopupForm;
