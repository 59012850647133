import { SVGProps } from 'react';
import { Box, useTheme, SxProps } from '@mui/material';
import { path, isEmpty, isNil } from 'ramda';
import { useNavigate } from 'react-router-dom';

import VertaLogoSVG from './verta-logo.svg?react';

export const VertaLogo = (
  props: SVGProps<SVGSVGElement> & {
    onClickRedirect?: boolean;
    onClick?: () => void;
    sx?: SxProps;
  }
) => {
  const navigate = useNavigate();
  const { color, onClickRedirect = true, onClick, sx, ...other } = props;
  const { palette } = useTheme();
  let finalColor = palette.text.primary;

  if (typeof color === 'string') {
    const themeColor = path(color.split('.'))(palette);
    if (!isEmpty(themeColor) && !isNil(themeColor)) {
      finalColor = themeColor as string;
    } else {
      finalColor = color;
    }
  }

  return (
    <Box
      onClick={() => (onClickRedirect ? navigate('/') : onClick?.())}
      sx={{ ':hover': { cursor: 'pointer', width: 'min-content' }, ...sx }}
    >
      <VertaLogoSVG fill={finalColor} {...other} />
    </Box>
  );
};
