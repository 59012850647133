import { useMemo } from 'react';

import { Distribution } from 'shared/models/Monitoring/Distribution/Distribution';
import { GroupedBarChartData } from 'shared/view/charts/GroupedBarChart/BaseGroupedBarChart';
import GroupedBarChart from 'shared/view/charts/GroupedBarChart/GroupedBarChart';
import { discreteDistributionKeys } from 'shared/models/Monitoring/Distribution/DistributionDescription';

import { DistributionBarChartProps } from '../shared/DistributionBarChartProps';
import BaseDistributionBarChart, {
  DistributionBarChartMeta,
} from '../shared/BaseDistributionBarChart';
import { makeDistributionBarsDataBuilder } from '../shared/makeDistributionBarData';

type DiscreteDistributionBarChartData =
  GroupedBarChartData<DistributionBarChartMeta>;

const DiscreteDistributionBarChart = (props: DistributionBarChartProps) => {
  const chartData = useMemo(
    () =>
      convertData({
        liveDistributions: props.liveDistributions,
        referenceDistributions: props.referenceDistributions,
      }),
    [props.liveDistributions, props.referenceDistributions]
  );

  return (
    <BaseDistributionBarChart
      size={props.size}
      chartData={chartData}
      withoutTooltipAndLegend={props.withoutTooltipAndLegend}
    >
      {({ data, size, renderTooltipContent, getColorByData }) => (
        <GroupedBarChart
          chartId={props.id}
          data={data}
          getColorByData={getColorByData}
          width={size.width}
          height={size.height}
          renderTooltipContent={renderTooltipContent}
        />
      )}
    </BaseDistributionBarChart>
  );
};

const convertData = (props: {
  liveDistributions: Distribution[];
  referenceDistributions: Distribution[];
}): Array<DiscreteDistributionBarChartData> => {
  const makeBarsData = makeDistributionBarsDataBuilder(props);

  return discreteDistributionKeys.map(
    (key, index): DiscreteDistributionBarChartData => {
      return {
        key,
        barsData: makeBarsData(index),
      };
    }
  );
};

export default DiscreteDistributionBarChart;
