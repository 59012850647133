import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';

import { useMonitoringDashboard } from 'features/monitoring/dashboards/dashboard/store/useMonitoringDashboard';
import { AllowedActionsData } from 'shared/graphql/collaborators/graphql-types/fragments.generated';
import { MonitoringDashboard } from 'shared/models/Monitoring/MonitoringModel/MonitoringDashboard/MonitoringDashboard';
import NameFormPopup, {
  NameFormPopupType,
  NameFormType,
} from 'shared/view/domain/Dashboards/NameForm/NameFormPopup';
import { ConfirmType } from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import {
  makeNewMonitoringPanel,
  MonitoringPanel,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringPanel';
import { MenuAction } from 'shared/view/elements/ActionsMenu/ActionsMenu';
import routes from 'shared/routes';
import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import { useMonitoringModelSettingsActions } from 'features/monitoring/models/modelSettings/store/useMonitoringModelSettingsActions';

import EditDashboard from '../view/EditDashboard/EditDashboard';

interface Props {
  model: MonitoringModel;
  dashboard: MonitoringDashboard;
  dashboards: MonitoringDashboard[];
  updateDashboards: (dashboard: MonitoringDashboard[]) => void;
  updateDashboard: (dashboard: MonitoringDashboard) => void;
  deleteDashboard: (dashboardId: string) => void;
  allowedActions: AllowedActionsData;
}

export const useDashboardSettingsActions = (props: Props) => {
  const { createPanel } = useMonitoringDashboard({
    dashboard: props.dashboard,
    updateDashboard: props.updateDashboard,
  });

  const editDashboard = useEditDashboardAction({
    allowedActions: props.allowedActions,
    dashboard: props.dashboard,
    dashboards: props.dashboards,
    updateDashboards: props.updateDashboards,
  });

  const addPanel = useAddPanelAction({
    createPanel,
    allowedActions: props.allowedActions,
  });

  const deleteDashboard = useDeleteDashboardAction({
    allowedActions: props.allowedActions,
    dashboard: props.dashboard,
    deleteDashboard: props.deleteDashboard,
    model: props.model,
  });

  const settingsActions = useMonitoringModelSettingsActions({
    model: props.model,
  });

  return [editDashboard, addPanel, deleteDashboard, ...settingsActions];
};

const useEditDashboardAction = (props: {
  dashboard: MonitoringDashboard;
  dashboards: MonitoringDashboard[];
  updateDashboards: (dashboards: MonitoringDashboard[]) => void;
  allowedActions: { update: boolean };
}): MenuAction => {
  const { closePopup, isPopupOpen, openPopup } = usePopupManager();

  return {
    type: 'button',
    label: 'Edit dashboard',
    onClick: openPopup,
    dataTest: 'edit-dashboard',
    disabled: !props.allowedActions.update,
    additionalContent: isPopupOpen ? (
      <EditDashboard
        dashboard={props.dashboard}
        dashboards={props.dashboards}
        isOpen={isPopupOpen}
        onClose={closePopup}
        updateDashboards={props.updateDashboards}
      />
    ) : null,
  };
};

const useDeleteDashboardAction = ({
  dashboard,
  deleteDashboard,
  allowedActions,
  model,
}: {
  dashboard: MonitoringDashboard;
  deleteDashboard: (dashboardId: string) => void;
  allowedActions: { update: boolean };
  model: MonitoringModel;
}): MenuAction => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    deleteDashboard(dashboard.id);
    navigate(
      routes.monitoringModel.getRedirectPath({
        monitoringModelId: model.id,
        workspaceName: model.workspaceName,
      })
    );
  }, [dashboard.id, deleteDashboard, navigate, model.id, model.workspaceName]);

  const { palette } = useTheme();

  return {
    type: 'button',
    label: 'Delete dashboard',
    onClick,
    dataTest: 'delete-dashboard',
    disabled: !allowedActions.update,
    labelColor: palette.error.main,
    confirmAction: {
      description: 'dashboard',
      type: ConfirmType.delete,
      longPressConfirmation: true,
      additionalText:
        'All data associated with the dashboard will be deleted. This action cannot be undone.',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'delete',
      maxWidth: 'xs',
    },
  };
};

const initialValues: NameFormType = {
  name: '',
};

const useAddPanelAction = ({
  allowedActions,
  createPanel,
}: {
  createPanel: (panel: MonitoringPanel) => void;
  allowedActions: AllowedActionsData;
}): MenuAction => {
  const { openPopup, isPopupOpen, closePopup } = usePopupManager();

  const onSubmit = useCallback(
    (form: NameFormType) => {
      createPanel(makeNewMonitoringPanel(form.name));
      closePopup();
    },
    [createPanel, closePopup]
  );

  return {
    type: 'button',
    label: 'Add panel',
    onClick: openPopup,
    dataTest: 'add-panel',
    disabled: !allowedActions.update,
    additionalContent: isPopupOpen ? (
      <NameFormPopup
        entityName="panel"
        type={NameFormPopupType.Add}
        isOpen={isPopupOpen}
        initialValues={initialValues}
        onSubmit={onSubmit}
        closePopup={closePopup}
      />
    ) : null,
  };
};
