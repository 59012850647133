import { useEffect } from 'react';

import {
  getDefaultIODescriptions,
  MonitoringIODescription,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import {
  DriftMonitoringPanel,
  OutliersMonitoringPanel,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringPanel';

interface Props {
  panel: DriftMonitoringPanel | OutliersMonitoringPanel;
  changeIODescription: (ioDescription: MonitoringIODescription) => void;
  ioDescriptions: MonitoringIODescription[];
}

export const useCustomPanelInitialization = ({
  panel,
  changeIODescription,
  ioDescriptions,
}: Props) => {
  useEffect(() => {
    if (!panel.ioDescription) {
      const { defaultIODescription } = getDefaultIODescriptions(ioDescriptions);

      if (defaultIODescription) {
        changeIODescription(defaultIODescription);
      }
    }
  }, [changeIODescription, ioDescriptions, panel.ioDescription]);
};
