import { useState, SVGProps } from 'react';

import { ChartSizes } from 'shared/utils/charts/chartSizes';
import { isRightMouseButton } from 'shared/utils/react/events';

import CustomBrush from '../shared/zoom/CustomBrush/CustomBrush';
import { Bounds, Scale } from '../shared/zoom/types';

interface TooltipApi {
  handleTooltip: (e: React.MouseEvent<SVGGElement>) => void;
  hideTooltip: () => void;
  tooltipIndicator: React.ReactNode;
  tooltipAreaClick?: () => void;
}

interface Props {
  tooltipApi?: TooltipApi;
  chartSizes: ChartSizes;
  xScale: Scale;
  yScale: Scale;
  onZoomIn(bounds: Bounds): void;
  onZoomOut(): void;
  children: React.ReactNode;
}

function PlotWithBrush(props: Props) {
  const tooltipHandlers = useTooltipHandlers(props.tooltipApi);

  return (
    <g
      width={props.chartSizes.innerWidth}
      height={props.chartSizes.innerHeight + 5}
      {...tooltipHandlers}
    >
      {props.children}
      {props.tooltipApi?.tooltipIndicator}
      <g
        width={props.chartSizes.innerWidth}
        height={props.chartSizes.innerHeight}
        {...getRightButtonClickHandlers({ onClick: props.onZoomOut })}
      >
        <CustomBrush
          width={props.chartSizes.innerWidth}
          height={props.chartSizes.innerHeight}
          margin={props.chartSizes.margin}
          xScale={props.xScale}
          yScale={props.yScale}
          onZoomIn={props.onZoomIn}
        />
      </g>
    </g>
  );
}

const useTooltipHandlers = (
  props: TooltipApi | undefined
): SVGProps<SVGGElement> | undefined => {
  const [isDragging, setIsDragging] = useState(false);

  if (props) {
    return {
      onMouseMove: (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
        props.handleTooltip(e);
        setIsDragging(true);
      },
      onMouseLeave: props.hideTooltip,
      onMouseDown: () => {
        setIsDragging(false);
      },
      onMouseUp: () => {
        if (isDragging) {
          return;
        }

        if (props.tooltipAreaClick) {
          props.tooltipAreaClick();
        }
      },
    };
  }
  return undefined;
};

const getRightButtonClickHandlers = ({ onClick }: { onClick(): void }) => ({
  onMouseUp: (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
    if (isRightMouseButton(e)) {
      e.preventDefault();
      e.stopPropagation();
    }
  },
  onContextMenu: (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
    if (isRightMouseButton(e)) {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }
  },
});

export default PlotWithBrush;
