/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { ArtifactData } from 'shared/graphql/Artifact/graphql-types/Artifact.generated';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from '../ErrorFragment';

export const ARTIFACT_FRAGMENT = gql`
  fragment ArtifactData on Artifact {
    key
    path
    pathOnly
    type
    filenameExtension
    uploadCompleted
    linkedArtifactId
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const ARTIFACT_WITH_DATASET_VERSION_FRAGMENT = gql`
  fragment ArtifactWithDatasetVersionData on Artifact {
    key
    path
    pathOnly
    type
    filenameExtension
    linkedArtifactId
    uploadCompleted
    linkedDatasetVersion {
      ... on Error {
        ...ErrorData
      }
      ... on DatasetVersion {
        id
        version
        dataset {
          id
          name
          workspace {
            id
            name
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export interface ArtifactWithPath extends ArtifactData {
  pathOnly: false;
}

export const checkArtifactWithPath = (artifact: {
  path: string;
  pathOnly: boolean;
}): artifact is ArtifactWithPath => {
  return Boolean(artifact.path && !artifact.pathOnly);
};
