import { RecordFromUnion } from 'shared/utils/types';

import { dateUpdatedSpec, DateUpdatedSpec } from '../DataSpec';
import { KeyValueSpec } from '../KeyValueSpec';
import { CommonWidgetSettings } from './shared/CommonWidgetSettings';
import { CustomDomain } from './shared/CustomDomain';

export type BoxPlotXAxisDataSpec = KeyValueSpec | DateUpdatedSpec;
export type BoxPlotYAxisDataSpec = KeyValueSpec;

export interface BoxPlotSettings extends CommonWidgetSettings<'boxPlot'> {
  xAxis: {
    spec: BoxPlotXAxisDataSpec;
    isLogScale: boolean;
    domain: CustomDomain;
  };
  yAxis: {
    spec: BoxPlotYAxisDataSpec;
    isLogScale: boolean;
    domain: CustomDomain;
  };
}

export interface BoxPlotAxisesDataSpec {
  xAxis: RecordFromUnion<
    BoxPlotXAxisDataSpec['type'],
    {
      dateUpdated: DateUpdatedSpec;
      keyValue: Array<KeyValueSpec>;
    }
  >;
  yAxis: BoxPlotYAxisDataSpec[];
}

export const getBoxPlotAxisesDataSpecs = (
  keyValueSpecs: KeyValueSpec[]
): BoxPlotAxisesDataSpec => {
  return {
    xAxis: {
      dateUpdated: dateUpdatedSpec,
      keyValue: keyValueSpecs,
    },
    yAxis: keyValueSpecs,
  };
};
