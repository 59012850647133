import { AlertEvaluationFrequency } from 'generated/types';
import matchType from 'shared/utils/matchType';

export const defaultEvaluationFrequency = AlertEvaluationFrequency.HOUR;

export const getAlertEvaluationFrequencyLabel = (
  frequency: AlertEvaluationFrequency
) => {
  return matchType(
    {
      [AlertEvaluationFrequency.UNKNOWN]: () => 'Unknown',
      [AlertEvaluationFrequency.FIVE_MINUTES]: () => 'Five minutes',
      [AlertEvaluationFrequency.HOUR]: () => 'Hour',
      [AlertEvaluationFrequency.DAY]: () => 'Day',
    },
    frequency
  );
};

export const alertEvaluationFrequencyForSelection = [
  AlertEvaluationFrequency.FIVE_MINUTES,
  AlertEvaluationFrequency.HOUR,
  AlertEvaluationFrequency.DAY,
];
