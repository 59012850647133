import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import Stack from '@mui/material/Stack';
import { ReactNode } from 'react';
import { useTheme } from '@mui/material';

import EditPanelAction from 'shared/view/domain/Dashboards/Panel/EditPanelAction/EditPanelAction';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import DefaultConfirmAction, {
  ConfirmType,
} from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';

import DraggableAccordion from '../../../elements/DraggableAccordion/DraggableAccordion';
import PanelActionIcon from './PanelActionIcon/PanelActionIcon';
import Button from '../../../elements/Button/Button';

interface Props {
  isOpen: boolean;
  name: string;
  onChangeOpen: (isOpen: boolean) => void;
  children: ReactNode;
  onDelete(): void;
  onRename(name: string): void;
  onCreate(): void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  widgetsCount: number;
  headerDataTest?: string;
}

const Panel = (props: Props) => {
  const theme = useTheme();
  return (
    <div data-test="panel">
      <DraggableAccordion
        expanded={props.isOpen}
        onChange={props.onChangeOpen}
        dragHandleProps={props.dragHandleProps}
        title={props.name}
        dataTest={'panel-name'}
        summaryRightContent={
          props.isOpen ? (
            <Stack direction="row">
              <PanelActionIcon
                title="Create widget"
                icon={ICONS.circlePlus}
                onClick={props.onCreate}
                dataTest="create-widget"
              />
              <EditPanelAction
                key="edit"
                name={props.name}
                onRename={props.onRename}
              />
              <DefaultConfirmAction
                description="panel"
                additionalText="All data associated with the panel will be deleted. This action cannot be undone."
                type={ConfirmType.delete}
                longPressConfirmation
                cancelButtonText="Cancel"
                confirmButtonText="delete"
                maxWidth="xs"
              >
                {(withConfirm) => (
                  <PanelActionIcon
                    title="Delete panel"
                    icon={ICONS.trashCan}
                    onClick={withConfirm(props.onDelete)}
                    dataTest="delete-panel"
                  />
                )}
              </DefaultConfirmAction>
            </Stack>
          ) : null
        }
      >
        {props.isOpen ? (
          props.widgetsCount > 0 ? (
            props.children
          ) : (
            <div style={{ padding: theme.spacing(2) }}>
              <Stack alignItems="center" justifyContent="center" spacing={2}>
                <span>
                  Your new panel is empty, to get started create a new widget.
                </span>
                <Button
                  icon={ICONS.circlePlus}
                  onClick={props.onCreate}
                  isLoading={false}
                  variant="outlined"
                >
                  Create new widget
                </Button>
              </Stack>
            </div>
          )
        ) : null}
      </DraggableAccordion>
    </div>
  );
};

export default Panel;
