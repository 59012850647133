import { AxiosResponse, Method } from 'axios';

import { JsonData } from 'shared/utils/json';

export type RestApiRequestResponse = {
  type: 'restApi';
  request: {
    path: string;
    payload: JsonData | File;
    method: Method | string;
  };
  response: {
    statusInfo: {
      text: string;
      code: number;
    };
    payload: JsonData;
  };
};

const handlePayload = (res: AxiosResponse<unknown>) => {
  if (res.config.data instanceof File) {
    return res.config.data;
  }
  return res.config.data
    ? (JSON.parse(res.config.data) as JsonData)
    : undefined;
};

export const make = (res: AxiosResponse<unknown>): RestApiRequestResponse => {
  return {
    type: 'restApi',
    request: {
      path: res.config.url || '',
      method: res.config.method || 'get',
      payload: handlePayload(res),
    },
    response: {
      payload: res.data as JsonData,
      statusInfo: {
        code: res.status,
        text: res.statusText,
      },
    },
  };
};
