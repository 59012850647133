import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';

import ProfilePageContent from './ProfilePage';

export const profilePage: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.profile.getPath(),
      element: <ProfilePageContent />,
    },
  ],
};
