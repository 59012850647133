import Stack from '@mui/material/Stack';
import { useCallback, useMemo, useState } from 'react';

import { getAlertAggregationWindowLabel } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertAggregationWindow';
import { getAlertEvaluationFrequencyLabel } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertEvaluationFrequency';
import { MonitoringAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import routes from 'shared/routes';
import capitalize from 'shared/utils/capitalize';
import { ICommunication } from 'shared/utils/redux/communication';
import MonitoringAlertStatus from 'shared/view/domain/Monitoring/MonitoringAlert/MonitoringAlertStatus/MonitoringAlertStatus';
import CellRendererDate from 'shared/view/elements/DataGrid/columns/CellRendererDate';
import CellRendererLink from 'shared/view/elements/DataGrid/columns/CellRendererLink';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { typeSafeConfiguration } from 'shared/view/elements/DataGrid/configuration/helpers/typeSafeConfiguration';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import { DataGridHighlightedRow } from 'shared/view/elements/DataGrid/hooks/useDataGridHighlightRows';
import { AllowedActionsData } from 'shared/graphql/collaborators/graphql-types/fragments.generated';
import { toAlerterRuleString } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/Alerter';
import { MonitoredModelType } from 'shared/models/Monitoring/MonitoringModel/MonitoredModelType';
import makeActionsColumn from 'shared/view/elements/DataGrid/columns/makeActionsColumn';

import MonitoringAlertsCards from './MonitoringAlertsCards/MonitoringAlertsCards';
import MonitoringAlertsTableActions from './MonitoringAlertsTableActions/MonitoringAlertsTableActions';
import UpdateBulkAlertsForm, {
  UpdateBulkAlertsFormType,
} from './UpdateBulkAlertsForm/UpdateBulkAlertsForm';

interface Props {
  alerts: MonitoringAlert[] | undefined;
  loadingAlerts: ICommunication;
  workspaceName: string;
  monitoringModelId: string;
  ioDescriptions: MonitoringIODescription[];
  updateBulkAlerts: (
    alertsIds: string[],
    form: UpdateBulkAlertsFormType
  ) => void;
  isUpdating: boolean;
  highlightedRows: DataGridHighlightedRow[];
  monitoredModelType: MonitoredModelType;
  allowedActions: AllowedActionsData;
}

const makeColumns = (props: {
  workspaceName: string;
  monitoringModelId: string;
  ioDescriptions: MonitoringIODescription[];
  monitoredModelType: MonitoredModelType;
  allowedActions: AllowedActionsData;
}): DataGridColumn<MonitoringAlert>[] => [
  {
    field: 'Status',
    width: 60,
    align: 'center',
    renderCell: (params) => (
      <MonitoringAlertStatus status={params.row.status} />
    ),
  },
  {
    field: 'Name',
    flex: 2,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'string',
      (params) => params.row.name
    ),
    renderCell: (params) => (
      <CellRendererLink
        label={params.row.name}
        to={routes.monitoringAlert.getRedirectPath({
          workspaceName: props.workspaceName,
          monitoringModelId: props.monitoringModelId,
          alertId: params.row.id,
        })}
      />
    ),
  },
  {
    field: 'Type',
    width: 75,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'string',
      (params) => params.row.settings.type
    ),
    renderCell: (params) => (
      <CellRendererString value={capitalize(params.row.settings.type)} />
    ),
  },
  {
    field: 'Condition',
    minWidth: 200,
    renderCell: (params) => (
      <CellRendererString value={toAlerterRuleString(params.row.alerter)} />
    ),
  },
  {
    field: 'Aggregation',
    flex: 2,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'string',
      (params) => params.row.aggregationWindow
    ),
    renderCell: (params) => (
      <CellRendererString
        value={getAlertAggregationWindowLabel(params.row.aggregationWindow)}
      />
    ),
  },
  {
    field: 'Frequency',
    flex: 2,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'string',
      (params) => params.row.evaluationFrequency
    ),
    renderCell: (params) => (
      <CellRendererString
        value={getAlertEvaluationFrequencyLabel(params.row.evaluationFrequency)}
      />
    ),
  },
  {
    field: 'Latest activity',
    flex: 3,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'date',
      (params) => params.row.dateUpdated
    ),
    renderCell: (params) => (
      <CellRendererDate value={params.row.dateUpdated} showTime={true} />
    ),
  },
  makeActionsColumn({
    renderCell: (params) => (
      <MonitoringAlertsTableActions
        alert={params.row}
        ioDescriptions={props.ioDescriptions}
        monitoringModelId={props.monitoringModelId}
        workspaceName={props.workspaceName}
        monitoredModelType={props.monitoredModelType}
        allowedActions={props.allowedActions}
      />
    ),
  }),
];

const MonitoringAlertsTable = (props: Props) => {
  const columns = useMemo(
    () =>
      makeColumns({
        workspaceName: props.workspaceName,
        monitoringModelId: props.monitoringModelId,
        ioDescriptions: props.ioDescriptions,
        monitoredModelType: props.monitoredModelType,
        allowedActions: props.allowedActions,
      }),
    [
      props.ioDescriptions,
      props.monitoredModelType,
      props.monitoringModelId,
      props.workspaceName,
      props.allowedActions,
    ]
  );

  const [selection, changeSelection] = useState<string[]>([]);

  const { updateBulkAlerts } = props;
  const onUpdate = useCallback(
    (form: UpdateBulkAlertsFormType) => updateBulkAlerts(selection, form),
    [updateBulkAlerts, selection]
  );

  return (
    <Stack spacing={2}>
      <MonitoringAlertsCards alerts={props.alerts} />

      <UpdateBulkAlertsForm
        isUpdateButtonDisabled={
          selection.length === 0 || !props.allowedActions.update
        }
        isLoading={props.isUpdating}
        onUpdate={onUpdate}
      />

      <DataGridWithTypes
        columns={columns}
        communication={props.loadingAlerts}
        context="loading alerts"
        rows={props.alerts}
        heightType="pageHeight"
        selectionType="multiple"
        selection={selection}
        onSelectionChange={changeSelection}
        highlightedRows={props.highlightedRows}
      />
    </Stack>
  );
};

export default MonitoringAlertsTable;
