import { pipe } from 'ramda';
import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';

import { GraphqlRequestResponseInterceptor } from 'setup/apollo/GraphqlRequestResponseInterceptor';

import * as GraphqlRequestResponse from '../requestResponse/GraphqlRequestResponse';
import * as RestApiRequestResponse from '../requestResponse/RestApiRequestResponse';
import { addRequestResponse } from './actions';

export const makeGraphqlErrorReporterInterceptor = (
  dispatch: Dispatch
): GraphqlRequestResponseInterceptor => ({
  ok: pipe(GraphqlRequestResponse.makeErrorOrOk, (r) =>
    dispatch(addRequestResponse(r))
  ),
  error: pipe(GraphqlRequestResponse.makeServerError, (r) =>
    dispatch(addRequestResponse(r))
  ),
});

export const makeRestApiErrorReporterInterceptor =
  (dispatch: Dispatch) => (res: AxiosResponse<unknown>) => {
    dispatch(addRequestResponse(RestApiRequestResponse.make(res)));
    return res;
  };
