import React from 'react';
import { Stack, Typography } from '@mui/material';

import { ICommunication } from 'shared/utils/redux/communication';
import { IWorkspace } from 'shared/models/Workspace';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import DefaultMatchRemoteMutation from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteMutation';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

export const DefaultWorkspaceSelector = (props: {
  workspace: IWorkspace;
  isActive: boolean;
  changing: ICommunication;
  onChange(workspace: IWorkspace, e: React.MouseEvent): void;
}) => {
  return (
    <DefaultMatchRemoteMutation communication={props.changing}>
      <Stack
        direction="row"
        data-test="default-workspace-selector"
        display="flex"
        alignItems="end"
      >
        <Typography
          variant="caption"
          data-test="default-workspace-selector-text"
        >
          {props.isActive ? 'default' : 'set as default'}
        </Typography>
        <IconAwesomeClickable
          icon={ICONS.star}
          dataTest="default-workspace-selector-icon"
          onClick={(e) => {
            props.onChange(props.workspace, e);
          }}
        />
      </Stack>
    </DefaultMatchRemoteMutation>
  );
};
