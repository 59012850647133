import { gql } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/useDeleteUser.generated';
import { useOrganizationUsersContext } from './useOrganizationUsers';

const QUERY = gql`
  mutation DeleteUserFromOrganizationV2($organizationId: ID!, $userId: ID!) {
    organizationV2(id: $organizationId) {
      ... on OrganizationV2 {
        id
        removeUser(id: $userId) {
          id
        }
      }
    }
  }
`;

export const useDeleteUser = (onCompleted?: () => void) => {
  const [run, communication] = useCustomMutation<
    Types.DeleteUserFromOrganizationV2,
    Types.DeleteUserFromOrganizationV2Variables
  >(
    QUERY,
    {
      context: 'deleting user',
    },
    { onCompleted }
  );
  const { usersApi } = useOrganizationUsersContext();

  return {
    run: (v: Types.DeleteUserFromOrganizationV2Variables) =>
      run(v).then(() => usersApi.reload()),
    communication,
  };
};
