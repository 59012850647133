import Stack from '@mui/material/Stack';

import Preloader from 'shared/view/elements/Preloader/Preloader';
import CopyButton from 'shared/view/elements/CopyButton/CopyButton';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import ReadonlyInputWithSecret from 'shared/view/elements/ReadonlyInput/ReadonlyInputWithSecret';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

export default function DevKey({
  developerKey,
  isDeleting,
  disabled,
  type,
  onDelete,
}: {
  developerKey: string;
  type: 'primary' | 'secondary';
  isDeleting: boolean;
  disabled?: boolean;
  onDelete(): void;
}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      data-test="developer-key"
      data-type={type}
    >
      <ReadonlyInputWithSecret
        value={developerKey}
        label={type === 'primary' ? 'Primary key' : 'Secondary key'}
        dataTest={'developer-key-value'}
      />

      <Stack direction="row" alignItems="center">
        <CopyButton copyText={developerKey} />
        {isDeleting ? (
          <Preloader size="small" />
        ) : (
          <IconAwesomeClickable
            icon={ICONS.trashCan}
            disabled={disabled}
            dataTest="developer-key-delete"
            onClick={onDelete}
          />
        )}
      </Stack>
    </Stack>
  );
}
