import { scalePoint } from '@visx/scale';
import { FC } from 'react';

const LinearGradient: FC<
  React.PropsWithChildren<{ id: string; colors: string[] }>
> = ({ colors, id }) => {
  const scale = scalePoint({
    domain: colors,
    range: [0, 100],
  });

  return (
    <linearGradient id={id} x1="0" y1="0" x2="0" y2="100%">
      {colors.map((color, index) => (
        <stop key={index} offset={`${scale(color) ?? 0}%`} stopColor={color} />
      ))}
    </linearGradient>
  );
};

export default LinearGradient;
