import React from 'react';
import { Typography } from '@mui/material';

import routes from 'shared/routes';
import { WorkspaceData } from 'shared/graphql/Workspace/graphql-types/Workspace.generated';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { OmitStrict } from 'shared/utils/types';

interface ILocalProps {
  registeredModel: {
    name: string;
    id: string;
    workspace: WorkspaceData;
  };
  version: string;
  id: string;
  withRegisteredModelName: boolean;
}

interface TitleProps extends ILocalProps {
  label: string;
}

export const VersionTitle: React.FC<React.PropsWithChildren<ILocalProps>> = (
  props
) => {
  const label = getVersionTitleLabel({
    version: props.version,
  });
  return <Title label={label} {...props} />;
};

export const ModelTitle: React.FC<React.PropsWithChildren<ILocalProps>> = (
  props
) => {
  const label = getModelTitleLabel({
    registeredModel: props.registeredModel,
    withRegisteredModelName: props.withRegisteredModelName,
  });
  return <Title label={label} {...props} />;
};

const Title = (props: TitleProps) => {
  const { label, id, registeredModel } = props;
  return (
    <Typography
      data-test="registered-model-version-title"
      variant="formValue"
      title={label}
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <InlineLink
        simple
        to={getVersionTitleRedirectPath({ id, registeredModel })}
      >
        {label}
      </InlineLink>
    </Typography>
  );
};

export function getVersionTitleLabel(
  props: Pick<ILocalProps, 'version'>
): string {
  const { version } = props;
  return version;
}

function getModelTitleLabel(
  props: OmitStrict<ILocalProps, 'id' | 'version'>
): string {
  const { registeredModel, withRegisteredModelName } = props;
  return withRegisteredModelName ? registeredModel.name : '';
}

export function getVersionTitleRedirectPath(
  props: Pick<ILocalProps, 'id' | 'registeredModel'>
) {
  const { id, registeredModel } = props;
  return routes.registeredModelVersion.getRedirectPath({
    workspaceName: registeredModel.workspace.name,
    registeredModelId: registeredModel.id,
    versionId: id,
  });
}
