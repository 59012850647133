import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { isEmpty } from 'lodash';

import { validateEmail } from 'shared/utils/validators';
import Popup, { PopupProps } from 'shared/view/elements/Popup/Popup';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { useToast } from 'features/toast/store/hooks';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import {
  IconAwesomeClickable,
  IconAwesomeClickableProps,
} from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { Button, ButtonProps } from 'shared/view/elements/Button';
import { selectIsCurrentUserSystemAdmin } from 'features/systemAdmin/isAdmin/store';
import { useOnboardingContext } from 'features/homepage/hooks/useOnboarding';
import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import Chip from 'shared/view/elements/Chip/Chip';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import CodeBlock from 'shared/view/elements/CodeBlock/CodeBlock';
import { ButtonsProps } from 'shared/view/elements/Popup/PopupButtons';

import { useAddUsers } from '../../store/useAddUsers';
import { ContentWrapper } from '../styled-components';

type AddNewUserPopupProps = AddNewUserButton['popupProps'] &
  Pick<PopupProps, 'isOpen'> &
  Pick<AddNewUserButton, 'onSuccess'>;

type AddNewUserButton = {
  buttonProps?: Partial<ButtonProps>;
  iconButtonProps?: Partial<IconAwesomeClickableProps>;
  popupProps: Pick<PopupProps, 'title' | 'onClose'> & {
    mainButtonProps?: Partial<ButtonsProps['mainButtonProps']>;
    secondaryButtonProps?: Partial<ButtonsProps['secondaryButtonProps']>;
    updateStepId?: string;
    linkLabel?: string;
    linkDescription?: string;
    inputDescription: string;
  };
  onSuccess?: (emails: string[]) => void;
};

const initialValues: { emails: string[] } = { emails: [] };

export const AddNewUserPopup = (props: AddNewUserPopupProps) => {
  const {
    updateStepId,
    linkLabel,
    linkDescription,
    inputDescription,
    onSuccess,
    ...popupProps
  } = props;

  const { updateOnboardingSteps, stepsValues } = useOnboardingContext();
  const [invitedEmails, setInvitedEmails] = useState<string[]>([]);

  const handleAddUserSuccess = () => {
    toast('User added to the organization successfully!', 'success');
    popupProps.onClose?.();
    onSuccess?.(invitedEmails);
    updateStepId &&
      updateOnboardingSteps({ ...stepsValues, [updateStepId]: true });
  };
  const addUsersApi = useAddUsers({ onCompleted: handleAddUserSuccess }, true);
  const organizationId = useCurrentOrganizationV2();
  const isCurrentUserSystemAdmin = useSelector(selectIsCurrentUserSystemAdmin);
  const toast = useToast();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    if (addUsersApi.communication.error?.message) {
      toast(addUsersApi.communication.error.message, 'error', {
        title: 'Error',
      });
    }
  }, [addUsersApi.communication.error, toast]);

  const handleEnter = (emailArray: FieldArrayRenderProps | string[]) => {
    if (validateEmail(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
      setEmail('');
      emailArray.push(email);
    }
  };

  return (
    <PresetFormik
      initialValues={initialValues}
      onSubmit={(form) => {
        if (organizationId) {
          if (form.emails.length === 0 && email) handleEnter(form.emails);
          if (form.emails.length > 0) {
            setInvitedEmails(form.emails);
            addUsersApi.run({
              emails: form.emails,
              id: organizationId,
            });
          }
        }
      }}
    >
      {({ submitForm, values }) => (
        <Popup
          fullWidth
          maxWidth="xs"
          buttons={{
            secondaryButtonProps: {
              isLoading: false,
              children: 'Cancel',
              onClick: () => popupProps.onClose?.(),
              ...popupProps.secondaryButtonProps,
            },
            mainButtonProps: {
              children: 'Share',
              isLoading: addUsersApi.communication.isRequesting,
              disabled:
                (isEmpty(values.emails) && isEmpty(email)) ||
                isCurrentUserSystemAdmin,
              onClick: submitForm,
              ...popupProps.mainButtonProps,
              type: 'button',
            },
          }}
          {...popupProps}
        >
          <Stack spacing={3}>
            {linkLabel && linkDescription ? (
              <>
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Typography variant="subtitle2" color="text.secondary">
                      {linkLabel}
                    </Typography>
                    <Typography variant="body2" my="8px">
                      {linkDescription}
                    </Typography>
                  </Stack>
                </Stack>
                <CodeBlock withCopy>{window.location.href}</CodeBlock>
              </>
            ) : null}
            <Stack spacing={2}>
              <Stack spacing={1}>
                {linkLabel && linkDescription ? (
                  <Typography variant="subtitle2" color="text.secondary">
                    Add new users
                  </Typography>
                ) : null}
                <Typography>{inputDescription}</Typography>
              </Stack>
              <FieldArray
                name="emails"
                render={(arrayHelpers) => (
                  <>
                    <Stack direction="row" spacing={1}>
                      <TextInput
                        maxWidth="100%"
                        value={email}
                        data-test="add-emails-input"
                        meta={{
                          touched: true,
                          error: emailError ? 'Email is invalid' : undefined,
                        }}
                        label="New user email"
                        onChange={setEmail}
                        onEnter={() => handleEnter(arrayHelpers)}
                        name="email"
                        minWidth="100%"
                        hint='To add multiple users, press the "enter" key after each email.'
                      />
                    </Stack>
                    <ContentWrapper>
                      {values.emails.map((_email, index) => (
                        <Chip
                          key={`add-emails-chip-${_email}`}
                          data-test={`add-emails-chip-${_email}`}
                          size="small"
                          variant="outlined"
                          color="primary"
                          onDelete={() => arrayHelpers.remove(index)}
                          label={
                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              spacing={0.5}
                            >
                              <DynamicTypography value={_email} />
                            </Stack>
                          }
                        />
                      ))}
                    </ContentWrapper>
                  </>
                )}
              />
            </Stack>
          </Stack>
        </Popup>
      )}
    </PresetFormik>
  );
};

const AddNewUserButton = (props: AddNewUserButton) => {
  return (
    <ButtonWithPopup
      key={1}
      button={(buttonProps) => (
        <>
          {props.buttonProps ? (
            <Button
              size="medium"
              variant="outlined"
              icon={ICONS.plus}
              dataTest="open-new-user-popup"
              {...buttonProps}
              {...props.buttonProps}
              onClick={(e) => {
                buttonProps.onClick();
                props.buttonProps?.onClick?.(e);
              }}
            />
          ) : null}
          {props.iconButtonProps ? (
            <IconAwesomeClickable
              size="lg"
              icon={ICONS.shareNodes}
              {...buttonProps}
              {...props.iconButtonProps}
              onClick={(e) => {
                buttonProps.onClick();
                props.iconButtonProps?.onClick?.(e);
              }}
            />
          ) : null}
        </>
      )}
      popup={(popupProps) => (
        <AddNewUserPopup
          {...popupProps}
          {...props.popupProps}
          onSuccess={props.onSuccess}
        />
      )}
    />
  );
};

export default AddNewUserButton;
