import { uniqBy } from 'ramda';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { useCallback, useState } from 'react';

import { IPagination } from 'shared/models/Pagination';
import {
  ICommunication,
  initialCommunication,
  makeErrorCommunication,
  requestingCommunication,
  successfulCommunication,
} from 'shared/utils/redux/communication';
import { ExperimentRunDetails } from 'shared/graphql/ExperimentRunDetails/ExperimentRunDetails';
import normalizeError from 'shared/utils/normalizeError';
import { convertFilters } from 'shared/graphql/filters/converters';
import {
  ExperimentRuns,
  ExperimentRunsVariables,
} from 'features/experimentRuns/list/store/graphql-types/useExperimentRunsList.generated';
import { apolloErrorFromUnknownToAppError } from 'shared/utils/graphql/apolloErrorToAppError';
import { EXPERIMENT_RUNS } from 'features/experimentRuns/list/store/useExperimentRunsList';
import {
  convertExperimentRunsByNullableRunsEntity,
  convertExperimentRunsCommonVariables,
} from 'features/experimentRuns/shared/converters';
import {
  isRestrictedGraphqlError,
  restrictedGraphqlErrorToAppError,
} from 'shared/graphql/ErrorFragment';
import { Filter } from 'shared/models/Filters/Filter';

export const experimentRunsLazyLoadSettings = {
  pageSize: 100,
  datapointLimit: 500,
};

const getMaxPage = (props: { totalCount: number; pageSize: number }) => {
  const { pageSize, totalCount } = props;
  const datapointLimit = experimentRunsLazyLoadSettings.datapointLimit;
  const numOfCalls = Math.floor(totalCount / pageSize);
  const callLimit = datapointLimit / pageSize - 1;
  return numOfCalls > callLimit ? callLimit : numOfCalls;
};

export const useExperimentRunsLazyLoad = (props: { projectId: string }) => {
  const apolloClient = useApolloClient();
  const { projectId } = props;

  const [loadingInitialExperimentRuns, setLoadingInitialExperimentRuns] =
    useState(initialCommunication);
  const [loadingRestExperimentRuns, setLoadingRestExperimentRuns] =
    useState(initialCommunication);
  const [experimentRuns, setExperimentRuns] = useState<ExperimentRunDetails[]>(
    []
  );

  const onRestCommunicationChange = useCallback((value: ICommunication) => {
    setLoadingRestExperimentRuns(value);
  }, []);

  const onAdditionalExperimentRunsLoaded = (value: ExperimentRunDetails[]) => {
    setExperimentRuns((oldValue) =>
      uniqBy(({ id }) => id, [...oldValue, ...value])
    );
  };

  const lazyLoad = useCallback(
    (filters: Filter[]) => {
      setExperimentRuns([]);
      setLoadingInitialExperimentRuns(requestingCommunication);
      const { pageSize } = experimentRunsLazyLoadSettings;
      loadExperimentRunsAsync({
        apolloClient,
        projectId,
        filters,
        pagination: {
          totalCount: 0,
          currentPage: 0,
          pageSize,
        },
      })
        .then((resultData) => {
          const runsData =
            convertExperimentRunsByNullableRunsEntity(resultData);
          const totalCount = runsData.totalCount;

          const pagination: IPagination = {
            currentPage: 0,
            pageSize,
            totalCount,
          };
          if (totalCount > pageSize) {
            loadRestExperimentRuns({
              apolloClient,
              projectId,
              pagination,
              filters,
              onRestCommunicationChange,
              onAdditionalExperimentRunsLoaded,
            });
          }
          onAdditionalExperimentRunsLoaded(runsData.data);
          setLoadingInitialExperimentRuns(successfulCommunication);
        })
        .catch((error) => {
          setLoadingInitialExperimentRuns(
            makeErrorCommunication(normalizeError(error))
          );
        });
    },
    [apolloClient, onRestCommunicationChange, projectId]
  );

  return {
    loadingInitialExperimentRuns,
    loadingRestExperimentRuns,
    experimentRuns,
    lazyLoad,
  };
};

const loadRestExperimentRuns = async (props: {
  apolloClient: ApolloClient<unknown>;
  projectId: string;
  pagination: IPagination;
  onRestCommunicationChange: (newCommunication: ICommunication) => void;
  onAdditionalExperimentRunsLoaded: (runs: ExperimentRunDetails[]) => void;
  filters: Filter[];
}) => {
  const {
    apolloClient,
    projectId,
    onRestCommunicationChange,
    onAdditionalExperimentRunsLoaded,
    filters,
  } = props;

  onRestCommunicationChange(requestingCommunication);

  const pagination = {
    ...props.pagination,
    currentPage: props.pagination.currentPage + 1,
  };

  const maxPage = getMaxPage({
    totalCount: pagination.totalCount,
    pageSize: experimentRunsLazyLoadSettings.pageSize,
  });

  await loadExperimentRunsAsync({
    apolloClient,
    projectId,
    filters,
    pagination,
  })
    .then((data) => {
      const runs = convertExperimentRunsByNullableRunsEntity(data).data;
      onAdditionalExperimentRunsLoaded(runs);
      if (maxPage > pagination.currentPage) {
        loadRestExperimentRuns({
          apolloClient,
          projectId,
          pagination,
          filters,
          onRestCommunicationChange,
          onAdditionalExperimentRunsLoaded,
        });
      } else {
        onRestCommunicationChange(successfulCommunication);
      }
    })
    .catch((error) => {
      onRestCommunicationChange(makeErrorCommunication(normalizeError(error)));
    });
};

async function loadExperimentRunsAsync(props: {
  apolloClient: ApolloClient<unknown>;
  projectId: string;
  filters: Filter[];
  pagination: IPagination;
}) {
  try {
    const { apolloClient, projectId, filters, pagination } = props;

    const result = await apolloClient.query<
      ExperimentRuns,
      ExperimentRunsVariables
    >({
      query: EXPERIMENT_RUNS,
      fetchPolicy: 'network-only',
      variables: {
        projectId,
        query: convertExperimentRunsCommonVariables({
          convertedFilters: convertFilters(filters),
          pagination,
          sorting: null,
        }),
      },
    });

    if (result.errors && result.errors.length > 0) {
      throw result.errors[0];
    }
    if (isRestrictedGraphqlError(result.data.project)) {
      throw restrictedGraphqlErrorToAppError(result.data.project);
    }

    return result.data.project;
  } catch (e: unknown) {
    throw apolloErrorFromUnknownToAppError(e);
  }
}
