import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';
import { CustomRedirect } from 'shared/routes/shared/CustomRedirect';

import OverviewPage from './OverviewPage/OverviewPage';
import DocumentationPage from './DocumentationPage/DocumentationPage';
import { Layout } from './shared/Layout/view';

export const modelPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.usecasesRegisteredModel.getPath(),
      element: <Layout />,
      children: [
        {
          path: '',
          index: true,
          element: (
            <CustomRedirect route={routes.usecasesModelOverview} replace />
          ),
        },
        {
          path: routes.usecasesModelOverview.getPath(),
          element: <OverviewPage />,
        },
        {
          path: routes.usecasesModelDocumentation.getPath(),
          element: <DocumentationPage />,
        },
      ],
    },
  ],
};
