import { memoizeWith, pipe } from 'ramda';

import { JsonData, JsonObject, traverseJson } from 'shared/utils/json';
import matchType from 'shared/utils/matchType';
import { OmitStrict, PickByValue } from 'shared/utils/types';

import * as RequestVariableMatchers from './requestVariableMatchers';
import {
  RequestVariableMatcher,
  FieldObfuscator,
} from './graphqlRequestResponseObfuscator';
import { getRandomWords, getRandomInt } from './dataGenerators';

type RecordWithTypename = { __typename: string; [str: string]: JsonData };

type FieldObfuscatorOpts<V extends JsonData, T extends RecordWithTypename> = {
  typenames: { [K in T['__typename']]: K } | 'any';
  field: Extract<keyof PickByValue<T, V | null | undefined>, string>;
  requestVariablesMatchers: RequestVariableMatcher[];
};

export const fieldObfuscator =
  <V extends JsonData>(obf: (value: V) => V) =>
  <T extends RecordWithTypename>(
    opts: FieldObfuscatorOpts<V, T>
  ): FieldObfuscator<V> => ({
    field: opts.field,
    obfuscate: (v) => {
      try {
        return obf(v);
      } catch (e: unknown) {
        console.error(e);
        return v;
      }
    },
    typenames:
      // todo: find a reason why opts.typenames is returned
      (opts.typenames === 'any'
        ? opts.typenames
        : Object.keys(opts.typenames)) as 'any' | string[],
    requestVariablesMatchers: opts.requestVariablesMatchers,
  });

export const stringObfuscator = memoizeWith(JSON.stringify, (value: string) =>
  value === '' ? value : getRandomWords(3).replace(/\s+/g, '-')
);
export const numberObfuscator = memoizeWith(JSON.stringify, (_: number) =>
  getRandomInt(1000000)
);

export const stringFieldObfuscator = fieldObfuscator(stringObfuscator);

export const numberFieldObfuscator = fieldObfuscator(numberObfuscator);

export const arrayFieldObfuscator =
  <V extends JsonData>(obf: (v: V) => V) =>
  <T extends RecordWithTypename>(
    opts: OmitStrict<
      FieldObfuscatorOpts<V[], T>,
      'requestVariablesMatchers'
    > & {
      requestVariablesMatchers: Array<{
        name: string;
        type: 'single' | 'array';
      }>;
    }
  ) =>
    fieldObfuscator<Array<V>>((x) => x.map(obf))<T>({
      ...opts,
      requestVariablesMatchers: opts.requestVariablesMatchers.map<
        RequestVariableMatcher<JsonData>
      >(({ name, type }) => {
        return matchType(
          {
            single: () => (keyAndValue, _, response) =>
              RequestVariableMatchers.whenVariableIsEqualTo(name)(
                keyAndValue,
                obf as any,
                response
              ),
            array:
              () =>
              ({ key, value }) =>
                key === name && Array.isArray(value)
                  ? value.map((x) => obf(x as any))
                  : value,
          },
          type
        );
      }),
    });

export const arrayStringFieldObfuscator =
  arrayFieldObfuscator(stringObfuscator);

const nonAlphaNumericString = (str: string) => /^[^a-zA-Z\d\s:]+$/.test(str);
const objectObfuscator = (record: JsonObject) => {
  traverseJson((_, value) => {
    if (typeof value === 'number') {
      return numberObfuscator(value);
    }
    if (typeof value === 'string' && !nonAlphaNumericString(value)) {
      return stringObfuscator(value);
    }
    return value;
  }, record);
  return record;
};
export const objectFieldObfuscator =
  fieldObfuscator<JsonObject>(objectObfuscator);
export const stringRecordFieldObfuscator = fieldObfuscator<string>(
  pipe(JSON.parse, objectObfuscator, JSON.stringify)
);
