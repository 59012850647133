import {
  AlerterInput,
  AlertSettingsInput,
  AlertType,
  AlerterType as GraphQLAlerterType,
} from 'generated/types';
import { AlerterType } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/Alerter';
import { AlertSettingsType } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertSettings';
import matchBy from 'shared/utils/matchBy';
import matchType from 'shared/utils/matchType';

import { convertAlertConditionToAlerter } from '../../view/shared/MonitoringAlertForm/shared/AlertCondition';
import { MonitoringAlertFormType } from '../../view/shared/MonitoringAlertForm/shared/MonitoringAlertFormType';

export const convertAlerterTypeToGraphQL = (
  alerterType: AlerterType
): GraphQLAlerterType =>
  matchType(
    {
      fixed: () => GraphQLAlerterType.FIXED,
      range: () => GraphQLAlerterType.RANGE,
    },
    alerterType
  );

export const convertAlertFormToGraphQL = (form: MonitoringAlertFormType) => {
  const alerter = convertAlertConditionToAlerter(form.condition);
  return {
    name: form.name,
    alerter: matchBy(
      alerter,
      'type'
    )<AlerterInput>({
      fixed: (fixedAlerter) => ({
        fixed: {
          operator: fixedAlerter.operator,
          threshold: fixedAlerter.threshold,
        },
      }),
      range: (rangeAlerter) => ({
        range: {
          alertIfOutsideRange: rangeAlerter.alertIfOutsideRange,
          lowerBound: rangeAlerter.lowerBound,
          upperBound: rangeAlerter.upperBound,
        },
      }),
    }),
    settings: matchType<AlertSettingsType, AlertSettingsInput>(
      {
        drift: () => ({
          driftAlertSettings: {
            driftMetricType: form.driftMetricType,
            ioDescription: form.ioDescription,
          },
          type: AlertType.DRIFT,
        }),
        metric: () => ({
          metricAlertSettings: {
            metricType: form.metricType,
            output: form.output,
          },
          type: AlertType.METRIC,
        }),
      },
      form.type
    ),
    aggregationWindow: form.aggregationWindow,
    evaluationFrequency: form.evaluationFrequency,
  };
};
