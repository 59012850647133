import { alpha } from '@mui/material/styles';

import { muiThemeTypography } from 'shared/mui/themes/muiThemeFonts';

import {
  createMuiThemeFromCommon,
  ColorsOptions,
} from './CommonMuiThemeOptions';

/**
 *  When using TypeScript 4.x and above
 *  according to https://mui.com/components/data-grid/getting-started/#typescript
 */
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

// opacities
const opacity = {
  font: {
    primary: 0.87,
    secondary: 0.6,
  },
  color: {
    inactive: 0.26,
    codeBlock: 0.04,
  },
};

// colors
const colors: ColorsOptions = {
  border: {
    default: '1px solid #404040',
    alternative: '1px solid #3b3b3b',
  },
  gridLayout: {
    placeholder: {
      background: '#2c4962',
      border: '1px solid #adcdfb',
      boxShadow: '0 0 12px #bbd5fca4',
    },
  },
  charts: {
    accentColor: '#4b4b4b',
    backgroundColor: '#0d0d0d',
  },
  additionalBackground: {
    content: '#f9fafc',
    sidebar: '#1B1B1B',
    sidebarContrast: '#5B62C0',
    sidebarActive: '#5B62C0',
    tabs: '#272727',
    breadcrumbs: '#000000',
    pageContent: '#0D0D0D',
    inactive: alpha('#FFF', opacity.color.inactive),
    codeBlock: alpha('#FFF', opacity.color.codeBlock),
    loginBackground: '#2B2B2B',
    stepBackground: 'rgba(63, 81, 181, 0.08)',
  },
  text: {
    primary: alpha('#FFF', opacity.font.primary),
    secondary: alpha('#FFF', opacity.font.secondary),
  },
  primary: {
    main: '#666FEA',
    light: '#7986CB',
  },
  error: {
    main: '#DC7C7C',
  },
  warning: {
    main: '#F2C665',
    contrastText: '#FFF',
  },
  success: {
    main: '#7EB589',
  },
  info: {
    main: '#7292F7',
  },
  default: {
    main: '#E0E0E0',
    dark: '#AEAEAE',
  },
  background: {
    default: '#1B1B1B',
    paper: '#222222',
    level1: '#222222',
    level2: '#272727',
    level3: '#2C2C2C',
  },
  divider: '#484848',
  rating: {
    active: '#FFB400',
  },
};

const MuiAppBar = {
  styleOverrides: {
    root: {
      backgroundColor: colors.background?.default,
      borderBottomColor: colors.divider,
    },
  },
};

const MuiPaper = {
  styleOverrides: {
    root: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: colors.divider,
      backgroundImage: 'none',
    },
  },
};

const MuiTabs = {
  styleOverrides: {
    root: {
      backgroundColor: colors.background?.level2,
    },
  },
};

const MuiAccordion = {
  styleOverrides: {
    root: {
      backgroundColor: colors.background?.default,
    },
  },
};

const MuiDataGrid = {
  styleOverrides: {
    columnHeaders: {
      backgroundColor: colors.background?.level2,
      borderColor: colors.background?.level2,
    },
    columnSeparator: {
      color: colors.text?.secondary,
    },
    cell: {
      borderColor: colors.background?.level2,
    },
  },
};

const MuiTooltip = {
  styleOverrides: {
    tooltip: {
      backgroundColor: colors.background?.level3,
    },
    arrow: {
      '&::before': {
        backgroundColor: colors.background?.level3,
      },
    },
  },
};

const DarkMuiTheme = createMuiThemeFromCommon({
  palette: {
    ...colors,
    mode: 'dark',
  },
  typography: muiThemeTypography,
  components: {
    MuiAppBar,
    MuiPaper,
    MuiTabs,
    MuiAccordion,
    MuiTooltip,
    MuiDataGrid,
  },
});

export default DarkMuiTheme;
