import {
  useBreadcrumbItem,
  useAppBreadcrumbs,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import routes from 'shared/routes';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import ModelVersionTypeInfo from 'shared/view/domain/Catalog/ModelVersionTypeInfo';
import { ModelVersionLayout } from 'pages/authorized/CatalogPages/ModelVersionPages/shared/Layout/graphql';

import { useCatalogsPageBreadcrumbs } from './useCatalogsPageBreadcrumbs';

export const useModelVersionPageBreadcrumbs = ({
  version,
}: {
  version?: ModelVersionLayout['registeredModelVersion'];
}) => {
  const topEnhancer = isNotError(version) ? (
    <ModelVersionTypeInfo
      kind="title"
      versionId={version.id}
      lockLevel={version.lockLevel}
      stage={version.stage}
      attributes={version.attributes}
    />
  ) : undefined;

  return useAppBreadcrumbs(
    useCatalogsPageBreadcrumbs(),
    useBreadcrumbItem({
      route: routes.registeredModel,
      hasTopEnhancer: true,
      ...(isNotError(version)
        ? { name: version.registeredModel.name, topEnhancer }
        : { isLoading: true }),
    }),
    useBreadcrumbItem({
      route: routes.registeredModelVersion,
      hasTopEnhancer: true,
      ...(isNotError(version)
        ? { name: version.version, topEnhancer }
        : { isLoading: true }),
    })
  );
};
