import { isNil } from 'ramda';

import { IAttributeValue } from 'shared/models/Attribute';
import { IAttributeWithWidgetBlob } from 'shared/models/AttributeWithWidgetBlob';
import { IWidgetBlob } from 'shared/models/Widget/WidgetBlob';
import { RecordFromUnion, RecordValues } from 'shared/utils/types';

import {
  IServerDiscreteHistogramBlobData,
  IServerFloatHistogramBlobData,
  IServerNumericValueBlobData,
  IServerStringValueBlobData,
  IServerTableValueBlobData,
  parseDiscreteHistogram,
  parseFloatHistogram,
  parseNumericValue,
} from '../ServerWidgetBlobData';

interface IServerAttributeUnknown {
  key: string;
  value:
    | IAttributeValue
    | IServerWidgetBlob
    | {
        type?: undefined;
      };
}

interface IServerAttributeWithWidgetBlob {
  key: string;
  value:
    | IServerWidgetBlob
    | {
        type?: undefined;
      };
}

type IServerWidgetBlob = RecordValues<
  RecordFromUnion<
    IWidgetBlob['type'],
    {
      string: {
        type: 'verta.stringValue.v1';
        stringValue: IServerStringValueBlobData;
      };
      numeric: {
        type: 'verta.numericValue.v1';
        numericValue: IServerNumericValueBlobData;
      };
      table: {
        type: 'verta.table.v1';
        table: IServerTableValueBlobData;
      };
      float: {
        type: 'verta.floatHistogram.v1';
        floatHistogram: IServerFloatHistogramBlobData;
      };
      discrete: {
        type: 'verta.discreteHistogram.v1';
        discreteHistogram: IServerDiscreteHistogramBlobData;
      };
      line: {
        type: 'verta.line.v1';
        line: {
          x: number[];
          y: number[];
        };
      };
      matrix: {
        type: 'verta.matrix.v1';
        matrix: {
          value: number[][];
        };
      };
      confusionMatrix: {
        type: 'verta.confusionMatrix.v1';
        confusionMatrix: {
          labels: string[];
          value: number[][];
        };
      };
    }
  >
>;

const isRegularAttributeValue = (server: IServerAttributeUnknown) => {
  return (
    typeof server.value === 'string' ||
    typeof server.value === 'number' ||
    typeof server.value === 'boolean' ||
    isNil(server.value) ||
    Array.isArray(server.value) ||
    !server.value.type
  );
};

export const convertAttributeWithBlob = (
  server: IServerAttributeUnknown
): IAttributeWithWidgetBlob => {
  if (isRegularAttributeValue(server)) {
    return { type: 'regular', key: server.key, value: server.value };
  }
  const customAttribute =
    server.value as IServerAttributeWithWidgetBlob['value'];
  switch (customAttribute.type) {
    case 'verta.discreteHistogram.v1': {
      return {
        type: 'discrete',
        key: server.key,
        value: parseDiscreteHistogram(customAttribute.discreteHistogram),
      };
    }
    case 'verta.floatHistogram.v1': {
      return {
        type: 'float',
        key: server.key,
        value: parseFloatHistogram(customAttribute.floatHistogram),
      };
    }
    case 'verta.numericValue.v1': {
      return {
        type: 'numeric',
        key: server.key,
        value: parseNumericValue(customAttribute.numericValue),
      };
    }
    case 'verta.stringValue.v1': {
      return {
        type: 'string',
        key: server.key,
        value: {
          type: 'string',
          data: { value: customAttribute.stringValue.value },
        },
      };
    }
    case 'verta.table.v1': {
      return {
        type: 'table',
        key: server.key,
        value: {
          type: 'table',
          data: {
            headers: customAttribute.table.header || [],
            rows: customAttribute.table.rows,
          },
        },
      };
    }
    case 'verta.line.v1': {
      return {
        type: 'line',
        key: server.key,
        value: {
          type: 'line',
          data: {
            x: customAttribute.line.x,
            y: customAttribute.line.y,
          },
        },
      };
    }
    case 'verta.matrix.v1': {
      return {
        type: 'matrix',
        key: server.key,
        value: {
          type: 'matrix',
          data: customAttribute.matrix.value,
        },
      };
    }
    case 'verta.confusionMatrix.v1': {
      return {
        type: 'confusionMatrix',
        key: server.key,
        value: {
          type: 'confusionMatrix',
          data: {
            labels: customAttribute.confusionMatrix.labels,
            value: customAttribute.confusionMatrix.value,
          },
        },
      };
    }
    default:
      return {
        type: 'regular',
        key: server.key,
        value: server.value,
      };
  }
};
