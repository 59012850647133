import { getArrayItem, isArrayWithLength2 } from 'shared/utils/collection';
import isNotNil from 'shared/utils/isNotNill';
import {
  ChartInterval,
  toStringInterval,
} from 'shared/view/charts/shared/intervals';

import { NormalizedDistribution } from './NormalizedDistribution';

export const discreteDistributionKeys = ['0', '1'];

export const makeDistributionKeys = (distribution: NormalizedDistribution) => {
  return isArrayWithLength2(distribution.normalizedValues)
    ? discreteDistributionKeys
    : makeFloatDistributionKeys(distribution);
};

const makeFloatDistributionKeys = (distribution: NormalizedDistribution) => {
  return makeFloatDistributionIntervals(distribution).map(toStringInterval);
};

export const makeFloatDistributionIntervals = (
  distribution: NormalizedDistribution
): ChartInterval[] => {
  return distribution.buckets.reduce<ChartInterval[]>(
    (intervals, intervalStart, index, array) => {
      const intervalEnd = getArrayItem(index + 1, array);
      if (isNotNil(intervalEnd)) {
        return intervals.concat({
          intervalEnd,
          intervalStart,
        });
      }

      return intervals;
    },
    []
  );
};
