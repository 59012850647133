import React from 'react';
import { Field, FieldProps } from 'formik';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { selectFlags } from 'features/flags';
import {
  IResourcesMemory,
  MemoryMeasure,
  memoryInfo,
} from 'shared/models/Deployment/canary/EndpointMachineConfiguration/Resources';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import { IOptionType } from 'shared/view/elements/Selects/shared/types';
import NumberInput from 'shared/view/elements/TextInput/NumberInput';

import { getMachineConfigSettingsFieldName } from '../../settings/getMachineConfigSettingsFieldName';

const MemoryField: React.FC<
  React.PropsWithChildren<{
    disabled: boolean;
  }>
> = ({ disabled }) => {
  const {
    deployment: { maxMemoryValue },
  } = useSelector(selectFlags);
  const amountName = getMachineConfigSettingsFieldName({
    resources: {
      memory: {
        amount: null,
      },
    },
  });

  return (
    <Field
      name={getMachineConfigSettingsFieldName({
        resources: {
          memory: null,
        },
      })}
      validate={(value: IResourcesMemory) =>
        memoryInfo.validate(value, memoryInfo.convertToInt(maxMemoryValue))
      }
    >
      {({ meta, field, form }: FieldProps<IResourcesMemory>) => (
        <Stack
          direction={'row'}
          spacing={2}
          sx={{ width: '100%' }}
          alignItems={'center'}
        >
          <Box sx={{ width: 150 }}>
            <NumberInput
              label="Size"
              name={amountName}
              onChange={(newValue) => form.setFieldValue(amountName, newValue)}
              onBlur={field.onBlur}
              meta={meta}
              value={meta.value.amount}
              min={memoryInfo.minMi}
              disabled={disabled}
            />
          </Box>
          <div style={{ width: '100%' }}>
            <SelectField
              name={getMachineConfigSettingsFieldName({
                resources: {
                  memory: {
                    measure: null,
                  },
                },
              })}
              label={'Units'}
              options={measureOptions}
              disabled={disabled}
            />
          </div>
        </Stack>
      )}
    </Field>
  );
};

const measureOptions: IOptionType<MemoryMeasure>[] = [
  { value: 'Mi', label: 'MB' },
  { value: 'Gi', label: 'GB' },
];

export default MemoryField;
