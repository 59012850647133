/* eslint-disable rulesdir/no-deprecated-fields */
import * as React from 'react';
import { gql } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';

import usePollingQuery from 'shared/view/hooks/apollo/usePollingQuery';
import { getEndpointPollingInterval } from 'shared/models/Deployment/canary/Endpoint';
import {
  BUILD_OR_ERROR_FRAGMENT,
  ENDPOINT_FRAGMENT,
} from 'shared/graphql/Deployment/Endpoint/Endpoint';
import { OmitStrict } from 'shared/utils/types';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

import * as ServerTypes from './graphql-types/endpointOverviewWIthPolling.generated';
import { useEndpointOverviewQuery } from '../endpointOverview/endpointOverview';

const ENDPOINT_OVERVIEW_POLLING = gql`
  query EndpointOverviewPolling(
    $workspaceName: String!
    $organizationId: ID
    $id: ID!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoint(id: $id) {
          ... on Error {
            ...ErrorData
          }
          ... on Endpoint {
            ...EndpointData
            id
            environments {
              id
              status
              kafka {
                status
                message
              }
              components {
                build {
                  ...BuildOrErrorData
                }
                status
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${BUILD_OR_ERROR_FRAGMENT}
  ${ENDPOINT_FRAGMENT}
`;

export const useEndpointOverviewWithPolling = (
  {
    workspaceName,
    id,
    organizationId,
  }: {
    workspaceName: string;
    id: string;
    organizationId: string | undefined;
  },
  options: OmitStrict<
    QueryHookOptions<
      ServerTypes.EndpointOverviewPolling,
      ServerTypes.EndpointOverviewPollingVariables
    >,
    'variables'
  >
) => {
  const res = useEndpointOverviewQuery(
    { workspaceName, id, organizationId },
    options
  );

  const [isSkipPolling, changeIsSkipPolling] = React.useState(true);
  React.useEffect(() => {
    if (res.communication.isSuccess) {
      setTimeout(() => {
        changeIsSkipPolling(false);
      }, getEndpointPollingInterval());
    }
  }, [res.communication.isSuccess]);

  usePollingQuery<
    ServerTypes.EndpointOverviewPolling,
    ServerTypes.EndpointOverviewPollingVariables
  >(ENDPOINT_OVERVIEW_POLLING, {
    variables: { workspaceName, id, organizationId },
    pollInterval: getEndpointPollingInterval(),
    skip: isSkipPolling,
    ...options,
  });

  return res;
};
