import { sortBy } from 'ramda';
import { FC, useMemo } from 'react';
import { FieldArray, Field, FieldProps, useField } from 'formik';
import Stack from '@mui/material/Stack';

import Select from 'shared/view/elements/Selects/Select/Select';
import { autoscalingMetricParameterInfo } from 'shared/models/Deployment/canary/EndpointMachineConfiguration/Autoscaling/Autoscaling';
import capitalize from 'shared/utils/capitalize';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { IAutoscalingMetric } from 'features/deployment/canary/endpoints/store/endpointQuery/endpointQuery';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import { IOptionType } from 'shared/view/elements/Selects/shared/types';
import Button from 'shared/view/elements/Button/Button';
import { componentsSizes } from 'shared/mui/themes/CommonMuiThemeOptions';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import { IAutoscalingMetricForm } from '../../settings';
import { getMachineConfigSettingsFieldName } from '../../settings/getMachineConfigSettingsFieldName';

const AutoscalingMetrics = ({
  canonicalAutoscalingMetrics,
  disabled,
}: {
  canonicalAutoscalingMetrics: IAutoscalingMetric[];
  disabled: boolean;
}) => {
  const [{ value: metricsForm }] = useField<IAutoscalingMetricForm[]>(
    getMachineConfigSettingsFieldName({ autoscaling: { metrics: null } })
  );

  return (
    <FieldArray
      name={getMachineConfigSettingsFieldName({
        autoscaling: {
          metrics: null,
        },
      })}
    >
      {({ push, remove }) => (
        <>
          {metricsForm.map((as, metricIndex) => [
            <Stack
              direction={'row'}
              key={metricIndex}
              spacing={2}
              alignItems={'center'}
            >
              <AutoscalingSelectField
                autoscalingMetricForm={as}
                canonicalAutoscalingMetric={canonicalAutoscalingMetrics}
                name={getMachineConfigSettingsFieldName({
                  autoscaling: {
                    metrics: {
                      [metricIndex]: null,
                    },
                  },
                })}
                disabled={disabled}
                endAdormentIcons={
                  metricIndex !== 0
                    ? [
                        <IconAwesomeClickable
                          key={0}
                          icon={ICONS.trashCan}
                          onClick={() => remove(metricIndex)}
                          dataTest="remove-autoscaling-metric"
                        />,
                      ]
                    : undefined
                }
              />
            </Stack>,
            ...as.parameters.map((parameterValue, parameterIndex) => (
              <TextInputField
                label={capitalize(parameterValue.name)}
                key={`${metricIndex} ${parameterIndex}`}
                name={getMachineConfigSettingsFieldName({
                  autoscaling: {
                    metrics: {
                      [metricIndex]: {
                        parameters: {
                          [parameterIndex]: { value: null },
                        },
                      },
                    },
                  },
                })}
                validate={autoscalingMetricParameterInfo.validate(
                  parameterValue.type
                )}
              />
            )),
          ])}

          <Button
            variant={'text'}
            disabled={disabled}
            onClick={() => {
              const newVariable: IAutoscalingMetricForm = {
                id: null,
                parameters: [],
              };
              push(newVariable);
            }}
            dataTest="add-autoscaling-metrics"
            isLoading={false}
          >
            + Add metric
          </Button>
        </>
      )}
    </FieldArray>
  );
};

interface IAutoscalingSelectFieldProps {
  autoscalingMetricForm: IAutoscalingMetricForm;
  canonicalAutoscalingMetric: IAutoscalingMetric[];
  name: string;
  disabled: boolean;
  endAdormentIcons?: JSX.Element[];
}

export const AutoscalingSelectField: FC<
  React.PropsWithChildren<IAutoscalingSelectFieldProps>
> = ({
  autoscalingMetricForm,
  canonicalAutoscalingMetric,
  name,
  disabled,
  endAdormentIcons,
}) => {
  const options: IOptionType<IAutoscalingMetric>[] = useMemo(
    () =>
      sortBy(
        ({ label }) => label,
        canonicalAutoscalingMetric.map((ca) => ({
          label: ca.description,
          value: ca,
        }))
      ),
    [canonicalAutoscalingMetric]
  );
  return (
    <Field name={name}>
      {({ field, form }: FieldProps<IAutoscalingMetricForm>) => (
        <span
          style={{ width: '100%', maxWidth: componentsSizes.input.maxWidth }}
        >
          <Select
            dataTest={name}
            label="Select autoscaling metric"
            value={options.find(
              (opt) => opt.value.id === autoscalingMetricForm.id
            )}
            options={options}
            onChange={(opt) =>
              form.setFieldValue(name, {
                id: opt.value.id,
                parameters: opt.value.parameters.map((p) => ({
                  name: p.name,
                  value: '',
                  type: p.type,
                })),
              })
            }
            endAdornmentIcons={endAdormentIcons}
            onBlur={field.onBlur}
            disabled={disabled}
          />
        </span>
      )}
    </Field>
  );
};

export default AutoscalingMetrics;
