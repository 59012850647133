import { ScaleTime, ScaleLinear } from 'd3-scale';
import { scaleTime, scaleLinear } from '@visx/scale';

import { extentWithDefault } from 'shared/utils/charts/d3/extentWithDefault';
import { ChartSizes } from 'shared/utils/charts/chartSizes';
import { RecordFromUnion } from 'shared/utils/types';
import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';
import { getLinearScaleDomain } from 'shared/utils/charts/getLinearScaleDomain';

import {
  getEpochNumbersFromObservations,
  getObservationTimestamp,
} from './accessors';
import { matchObservationXAxisType } from './axisType';
import { ChartObservation } from './types';

export type IObservationChartXAxisSettings =
  | {
      type: 'timestamp';
      scale: ScaleTime<number, number>;
    }
  | {
      type: 'epochNumber';
      scale: ScaleLinear<number, number>;
    };

export type ObservationChartXAxisType = IObservationChartXAxisSettings['type'];

export const useXAxisSettings = ({
  observations,
  chartSizes,
  xAxisType,
}: {
  observations: ChartObservation[];
  chartSizes: ChartSizes;
  xAxisType: ObservationChartXAxisType;
}) => {
  return matchObservationXAxisType<IObservationChartXAxisSettings>(
    {
      timestamp: () => {
        const scale = scaleTime({
          domain: extentWithDefault(
            observations.map(getObservationTimestamp),
            +new Date()
          ),
          range: [0, chartSizes.innerWidth],
          nice: true,
        });

        return {
          type: 'timestamp',
          scale,
        };
      },
      epochNumber: () => {
        const domain = getLinearScaleDomain(
          getEpochNumbersFromObservations(observations)
        );
        const scale = scaleLinear({
          domain,
          range: [0, chartSizes.innerWidth],
        });

        return {
          type: 'epochNumber',
          scale,
        };
      },
    },
    xAxisType
  );
};

export const matchObservationChartAxisSettings = <
  R1,
  R2,
  B extends RecordFromUnion<
    IObservationChartXAxisSettings['type'],
    {
      timestamp: (
        settings: Extract<IObservationChartXAxisSettings, { type: 'timestamp' }>
      ) => R1;
      epochNumber: (
        settings: Extract<
          IObservationChartXAxisSettings,
          { type: 'epochNumber' }
        >
      ) => R2;
    }
  >,
>(
  matchers: B,
  settings: IObservationChartXAxisSettings
) => {
  switch (settings.type) {
    case 'timestamp':
      return matchers.timestamp(settings);
    case 'epochNumber':
      return matchers.epochNumber(settings);
    default:
      exhaustiveCheck(settings);
  }
};
