import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/useDeleteChecklistTemplate.generated';

const QUERY = gql`
  mutation DeleteTemplate($organizationId: ID!, $checklistTemplateId: ID!) {
    organizationV2(id: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        deleteChecklistTemplate(id: $checklistTemplateId) {
          id
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

type Props = {
  onCompleted: () => void;
};

export const useDeleteChecklistTemplate = ({ onCompleted }: Props) => {
  const [deleteChecklistTemplate, communication] = useCustomMutation<
    Types.DeleteTemplate,
    Types.DeleteTemplateVariables
  >(
    QUERY,
    {
      context: 'delete checklist template',
    },
    {
      onCompleted,
    }
  );

  return {
    deleteChecklistTemplate,
    communication,
  };
};
