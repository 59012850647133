import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';

import {
  MODEL_DOCUMENTATION_DATA_QUERY,
  ModelDocumentationDataCases,
  ModelDocumentationDataCasesVariables,
} from '../graphql';

type Props = ModelDocumentationDataCasesVariables;

export const useModelDocumentation = ({ id }: Props) => {
  const query = useCustomQuery<
    ModelDocumentationDataCases,
    ModelDocumentationDataCasesVariables
  >(MODEL_DOCUMENTATION_DATA_QUERY, { variables: { id } });

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return {
    data,
    communication,
    reload: query.refetch,
  };
};

const convertResponse = ({ registeredModel }: ModelDocumentationDataCases) =>
  mapDataOrError(registeredModel, (model) => model);
