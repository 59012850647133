import { StringPredicate } from 'generated/types';
import { UserData } from 'shared/graphql/User/graphql-types/User.generated';

export enum FilterType {
  HAS_PRODUCTION_VERSIONS = 'hasProductionVersions',
  HAS_ENDPOINTS = 'hasEndpoints',
  HAS_NO_ENDPOINTS = 'hasNoEndpoints',
  TASK_TYPE = 'taskType',
  DATA_TYPE = 'dataType',
  DATE_UPDATED = 'dateUpdated',
  GROUP = 'group',
  ORGANIZATION = 'organization',
  SEARCH = 'search',
  LABEL = 'label',
  OWNER = 'owner',
  MODEL_VERSION = 'model-versions',
  CLEAR = 'clear',
  VERSION = 'version',
}

export type LabelOptionsProps = {
  label: string;
  value: string;
  checked: boolean;
};

type BooleanOptProps = {
  type: FilterType.HAS_PRODUCTION_VERSIONS;
  value: boolean;
};

type EndpointsProps = {
  type: FilterType.HAS_ENDPOINTS | FilterType.HAS_NO_ENDPOINTS;
  value?: string[];
};

type StringOptProps = {
  type:
    | FilterType.TASK_TYPE
    | FilterType.DATA_TYPE
    | FilterType.DATE_UPDATED
    | FilterType.GROUP
    | FilterType.ORGANIZATION
    | FilterType.SEARCH;
  value?: string;
};

export type OwnerModelProps = UserData;

export type OwnerOptionsProps = LabelOptionsProps & {
  id: string;
  email: string;
};

export type VersionOptionsProps = { label: string; value: string };

type ListLabelOptProps = { type: FilterType.LABEL; value: LabelOptionsProps };
type ListOwnerOptProps = { type: FilterType.OWNER; value: OwnerOptionsProps };
export type ListVersionOptProps = {
  type: FilterType.VERSION;
  value: VersionOptionsProps;
};
export type ModelVersionsOptProps = {
  type: FilterType.MODEL_VERSION;
  value: {
    dateUpdated?: string;
    labels?: LabelOptionsProps[];
    owners?: OwnerOptionsProps[];
  };
};

export type FilterOptionProps =
  | { type: FilterType.CLEAR; value: 'clear' }
  | BooleanOptProps
  | StringOptProps
  | ListLabelOptProps
  | ListOwnerOptProps
  | EndpointsProps
  | ModelVersionsOptProps
  | ListVersionOptProps;

export type CurrentFilterProps = {
  hasProductionVersions?: boolean;
  hasEndpoints?: string[];
  hasNoEndpoints?: string[];
  taskType?: string;
  dataType?: string;
  dateUpdated?: string;
  group?: string;
  organization?: string;
  labels?: LabelOptionsProps[];
  owners?: OwnerOptionsProps[];
  search?: string;
  version?: VersionOptionsProps;
};

export type HandlerProps = {
  currentFilters: CurrentFilterProps;
  stringPredicates: StringPredicate[];
  setCurrentFilters: (p: any) => void;
  setStringPredicates: (p: any) => void;
};

export type UseFilterProps = {
  stringPredicates: StringPredicate[];
  currentFilters: CurrentFilterProps;
  onCurrentFiltersChange: (p: FilterOptionProps) => void;
  isFiltering: boolean;
};
