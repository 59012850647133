/* eslint-disable rulesdir/no-deprecated-fields */
import moment from 'moment';
import { gql } from '@apollo/client';
import { descend, partition, pipe, sort } from 'ramda';
import { useCallback } from 'react';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { toGraphQLDate } from 'shared/utils/graphql/toGraphQLDate';
import { AlertStatus } from 'generated/types';
import {
  isRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { ExtractByTypename } from 'shared/utils/types';

import * as GraphqlTypes from './graphql-types/useHomepageMonitoringWidget.generated';

const QUERY = gql`
  query HomepageMonitoringWidget(
    $workspaceName: String!
    $organizationId: ID
    $startDate: Date!
    $endDate: Date!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        monitoredModels {
          models {
            id
            name
            monitoredEntity {
              id
              workspace {
                id
                ...WorkspaceData
              }
              owner {
                ... on User {
                  id
                }
                ... on Group {
                  id
                }
              }
              alerts {
                alerts {
                  id
                  status
                }
              }
              metrics {
                metric(
                  query: {
                    startDate: $startDate
                    endDate: $endDate
                    types: [PREDICTION_COUNT]
                    output: { ioType: OUTPUT, name: "prediction_count" }
                    filters: []
                  }
                ) {
                  type
                  value
                }
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
`;

const startDate = toGraphQLDate(moment().subtract(30, 'days').toDate());
const endDate = toGraphQLDate(new Date());

export const useHomepageMonitoringWidget = ({
  workspaceName,
  currentUserId,
  organizationId,
}: {
  workspaceName: string;
  currentUserId: string;
  organizationId: string | undefined;
}) => {
  const queryResult = useCustomQuery<
    GraphqlTypes.HomepageMonitoringWidget,
    GraphqlTypes.HomepageMonitoringWidgetVariables
  >(QUERY, {
    variables: { workspaceName, startDate, endDate, organizationId },
  });

  const memoizedConvert = useCallback(
    (result: GraphqlTypes.HomepageMonitoringWidget) =>
      convert(currentUserId, result),
    [currentUserId]
  );

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert,
    queryResult,
  });
};

export type HomepageMonitoringWidgetData = ReturnType<typeof convert>;

function convert(
  currentUserId: string,
  result: GraphqlTypes.HomepageMonitoringWidget
) {
  const modelsWithSinglePredictionMetric =
    getModelsWithSinglePredictionMetric(result);
  const topModels = getTopModels(
    modelsWithSinglePredictionMetric,
    currentUserId
  );

  return {
    topModels,
    totalPredictionCount: modelsWithSinglePredictionMetric.reduce(
      (a, b) => a + b.predictionCount,
      0
    ),
    totalAlerts: pipe(
      () =>
        isRestrictedGraphqlError(result.workspace)
          ? []
          : result.workspace.monitoredModels.models.flatMap(
              (m) => m.monitoredEntity.alerts.alerts
            ),
      (allAlerts) => ({
        ALERTING: allAlerts.filter((a) => a.status === AlertStatus.ALERTING)
          .length,
        OK: allAlerts.filter((a) => a.status === AlertStatus.OK).length,
        PAUSED: allAlerts.filter((a) => a.status === AlertStatus.PAUSED).length,
      })
    )(),
  };
}

type Workspace = ExtractByTypename<
  GraphqlTypes.HomepageMonitoringWidget['workspace'],
  'Workspace'
>;

type ModelWithSinglePredictionMetric =
  Workspace['monitoredModels']['models'][0] & {
    predictionCount: number;
  };

function getModelsWithSinglePredictionMetric(
  result: GraphqlTypes.HomepageMonitoringWidget
): ModelWithSinglePredictionMetric[] {
  return (
    isRestrictedGraphqlError(result.workspace)
      ? ([] as Workspace['monitoredModels']['models'])
      : result.workspace.monitoredModels.models
  ).map((m) => ({
    ...m,
    predictionCount: m.monitoredEntity.metrics.metric.reduce(
      (a, b) =>
        a + (typeof b.value === 'string' ? parseFloat(b.value) : b.value),
      0
    ),
  }));
}

function getTopModels(
  modelsWithSinglePredictionMetric: ModelWithSinglePredictionMetric[],
  currentUserId: string
) {
  return pipe(
    () => modelsWithSinglePredictionMetric,
    (x) =>
      sort(
        descend((m) => m.predictionCount),
        x
      ),
    (x) => partition((m) => m.monitoredEntity.owner.id === currentUserId, x),
    ([ownedModels, notOwnedModels]) =>
      [...ownedModels, ...notOwnedModels].slice(0, 4)
  )();
}
