import { Typography } from '@mui/material';

import {
  modelCatalogLifecycle,
  modelVersionConcept,
  screenshotModelCatalog,
  screenshotModelCatalogVersion,
  screenshotModelCatalogReproduce,
  screenshotModelCatalogIntegrate,
  screenshotModelCatalogRelease,
  screenshotModelVersionSummary,
  screenshotModelCatalogOverview,
  screenshotModelCatalogDocumentation,
  screenshotModelCatalogActivity,
  screenshotModelCatalogHomepage,
} from 'features/homepage/images/modelCatalog';
import {
  WizardStepContent,
  WizardStepContentProps,
  WizardStepProps,
} from 'shared/view/elements/Wizard';

import { StepName, firstCopy, footerCopy } from './copyValues';
import * as slides from './slides';

type Props = WizardStepContentProps & {
  firstText: string | JSX.Element;
  image: string;
  footerText?: string;
  label: string;
};

const ElementHandler = ({
  label,
  firstText,
  image,
  footerText,
  ...rest
}: Props) => {
  return (
    <WizardStepContent {...rest}>
      <Typography variant="subtitle1" mb={2}>
        {label}
      </Typography>
      <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }} mb={2}>
        {firstText}
      </Typography>
      <img
        src={image}
        width="90%"
        height="auto"
        alt="describing model catalog"
      />
      {footerText ? (
        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }} mt={2}>
          {footerText}
        </Typography>
      ) : null}
    </WizardStepContent>
  );
};

export const steps: WizardStepProps[] = [
  {
    stepLabel: { icon: '1', text: StepName.KEY_CONCEPTS },
    slides: [
      (props: WizardStepContentProps) => (
        <WizardStepContent {...props}>
          <Typography variant="subtitle1" mb={2}>
            {StepName.KEY_CONCEPTS}
          </Typography>
          <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }} mb={2}>
            {firstCopy[StepName.KEY_CONCEPTS][0]}
          </Typography>
          <img
            src={modelCatalogLifecycle}
            width="100%"
            height="285px"
            alt="describing model catalog"
          />
          <Typography variant="body2" mt={2} sx={{ whiteSpace: 'pre-wrap' }}>
            {footerCopy[StepName.KEY_CONCEPTS][0]}
          </Typography>
        </WizardStepContent>
      ),
      (props: WizardStepContentProps) => (
        <WizardStepContent {...props}>
          <Typography variant="subtitle1" mb={2}>
            {StepName.KEY_CONCEPTS}
          </Typography>
          <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }} mb={2}>
            {firstCopy[StepName.KEY_CONCEPTS][1]}
          </Typography>
          <img
            src={modelVersionConcept}
            width="100%"
            height="173px"
            alt="describing model catalog"
          />
          <Typography variant="body2" mt={2} sx={{ whiteSpace: 'pre-wrap' }}>
            {footerCopy[StepName.KEY_CONCEPTS][1]}
          </Typography>
        </WizardStepContent>
      ),
      (props: WizardStepContentProps) => (
        <ElementHandler
          {...props}
          label="Key concepts"
          key={3}
          firstText={firstCopy[StepName.KEY_CONCEPTS][2]}
          image={screenshotModelCatalog}
          footerText={footerCopy[StepName.KEY_CONCEPTS][2]}
        />
      ),
    ],
  },
  {
    stepLabel: { icon: '2', text: StepName.MODEL_VERSION },
    slides: [
      (props: WizardStepContentProps) => (
        <ElementHandler
          {...props}
          label="Model versions"
          key={1}
          firstText={firstCopy[StepName.MODEL_VERSION][0]}
          image={screenshotModelCatalogVersion}
        />
      ),
      (props: WizardStepContentProps) => (
        <ElementHandler
          {...props}
          label="Model versions"
          key={2}
          firstText={firstCopy[StepName.MODEL_VERSION][1]}
          image={screenshotModelCatalogReproduce}
        />
      ),
      (props: WizardStepContentProps) => (
        <ElementHandler
          {...props}
          label="Model versions"
          key={3}
          firstText={firstCopy[StepName.MODEL_VERSION][2]}
          image={screenshotModelCatalogIntegrate}
        />
      ),
      (props: WizardStepContentProps) => (
        <ElementHandler
          {...props}
          label="Model versions"
          key={4}
          firstText={firstCopy[StepName.MODEL_VERSION][3]}
          image={screenshotModelCatalogRelease}
        />
      ),
      (props: WizardStepContentProps) => (
        <ElementHandler
          {...props}
          label="Model versions"
          key={5}
          firstText={firstCopy[StepName.MODEL_VERSION][4]}
          image={screenshotModelVersionSummary}
        />
      ),
    ],
  },
  {
    stepLabel: { icon: '3', text: StepName.MODEL_CARD },
    slides: [
      (props: WizardStepContentProps) => (
        <ElementHandler
          {...props}
          label="Model card"
          key={1}
          firstText={firstCopy[StepName.MODEL_CARD][0]}
          image={screenshotModelCatalogOverview}
        />
      ),
      (props: WizardStepContentProps) => (
        <ElementHandler
          {...props}
          label="Model card"
          key={2}
          firstText={firstCopy[StepName.MODEL_CARD][1]}
          image={screenshotModelCatalogDocumentation}
        />
      ),
      (props: WizardStepContentProps) => (
        <ElementHandler
          {...props}
          label="Model card"
          key={3}
          firstText={firstCopy[StepName.MODEL_CARD][2]}
          image={screenshotModelCatalogActivity}
        />
      ),
    ],
  },
  {
    stepLabel: { icon: '4', text: StepName.MODEL_CATALOG },
    slides: [
      (props: WizardStepContentProps) => (
        <ElementHandler
          {...props}
          label="Model catalog"
          key={1}
          firstText={firstCopy[StepName.MODEL_CATALOG][0]}
          image={screenshotModelCatalogHomepage}
        />
      ),
    ],
  },
  {
    stepLabel: false,
    slides: [slides.YourTurnStep],
  },
];
