import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import customAttrPage from '../images/custom-attributes-page.png';

export const CustomAttrPage = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Create custom attributes
    </Typography>
    <Typography variant="body2" mb={3}>
      Custom attributes can be tracked at both the model (card) and model
      version levels. Organization admins have the ability to create and update
      these attributes, and there is no limit to the number you can have.
    </Typography>
    <img
      src={customAttrPage}
      alt="custom attributes page"
      width="90%"
      height="auto"
    />
  </WizardStepContent>
);
