import { getArrayItem, isNotEmptyArray } from 'shared/utils/collection';
import { Result } from 'shared/utils/result';
import {
  BarChartXAxisDataSpec,
  BarChartYAxisDataSpec,
  BarChartAxisesDataSpec,
  BarChartSettings,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/BarChartSettings';
import { emptyCustomDomain } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/shared/CustomDomain';
import { OmitStrict } from 'shared/utils/types';

export type BarChartSettingsForm = {
  name: string;
  type: 'barChart';
  xAxis: OmitStrict<BarChartSettings['xAxis'], 'spec'> & {
    spec?: BarChartXAxisDataSpec;
  };
  yAxis: OmitStrict<BarChartSettings['yAxis'], 'specs'> & {
    specs: BarChartYAxisDataSpec[];
  };
};

export const getInitialBarChartSettingsForm = (
  name: string,
  axisesSpec: BarChartAxisesDataSpec
): BarChartSettingsForm => {
  const xAxisSpec = axisesSpec.xAxis.dateUpdated;
  const yAxisSpec = getArrayItem(0, axisesSpec.yAxis);

  return {
    type: 'barChart',
    name,
    xAxis: {
      spec: xAxisSpec,
    },
    yAxis: {
      specs: yAxisSpec ? [yAxisSpec] : [],
      domain: emptyCustomDomain,
      aggregationType: undefined,
    },
  };
};

export const validateBarChartSettingsForm = (
  form: BarChartSettingsForm
): Result<BarChartSettings, string> => {
  if (form.xAxis.spec && isNotEmptyArray(form.yAxis.specs)) {
    return {
      type: 'success',
      data: {
        name: form.name,
        type: 'barChart',
        xAxis: {
          spec: form.xAxis.spec,
        },
        yAxis: {
          specs: form.yAxis.specs,
          domain: form.yAxis.domain,
          aggregationType: form.yAxis.aggregationType,
        },
      },
    };
  }
  return {
    type: 'error',
    error: 'Select a metric to visualize in this bar chart.',
  };
};
