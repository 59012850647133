import { ICommunication } from 'shared/utils/redux/communication';
import Preloader from 'shared/view/elements/Preloader/Preloader';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { experimentRunsLazyLoadSettings } from 'features/experimentRuns/shared/hooks/useExperimentRunsLazyLoad';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import styles from './ExperimentRunsLoadingInfo.module.css';

const ExperimentRunsLoadingInfo = ({
  experimentRunsCount,
  loadingAdditionalExperimentRuns,
}: {
  experimentRunsCount: number;
  loadingAdditionalExperimentRuns: ICommunication;
}) => {
  return (
    <div className={styles.root}>
      Data count: {experimentRunsCount}
      {loadingAdditionalExperimentRuns.isRequesting && (
        <div className={styles.loadingAdditional}>
          <div>
            <Preloader />
          </div>
          Loading more data...
        </div>
      )}
      {experimentRunsCount >= experimentRunsLazyLoadSettings.datapointLimit && (
        <div className={styles.limitReached}>
          <IconAwesomeInfo
            icon={ICONS.triangleExclamation}
            infoType={'warning'}
          />
          Max data limit reached, consider applying filters
        </div>
      )}
    </div>
  );
};

export default ExperimentRunsLoadingInfo;
