import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useEndpointOverviewWithPolling } from 'features/deployment/canary/endpoints/store/endpointOverviewWIthPolling/endpointOverviewWIthPolling';
import EndpointOverview from 'features/deployment/canary/endpoints/view/EndpointOverview/EndpointOverview';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { KafkaConfigStatus } from 'generated/types';

import EndpointDetailsLayout from '../shared/EndpointDetailsLayout/EndpointDetailsLayout';

const EndpointOverviewPage = () => {
  const params = useRouteParams(routes.endpointOverview);
  const organizationId = useCurrentOrganizationV2();

  const { data, communication } = useEndpointOverviewWithPolling(
    {
      id: params.endpointId,
      workspaceName: params.workspaceName,
      organizationId,
    },
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      returnPartialData: true,
    }
  );
  return (
    <EndpointDetailsLayout
      data={data}
      communication={communication}
      endpointId={params.endpointId}
    >
      {(loaded) => (
        <EndpointOverview
          endpoint={loaded.endpoint}
          isKafkaEnabled={
            loaded.endpoint.environments[0].kafka?.status ===
            KafkaConfigStatus.ACTIVE
          }
        />
      )}
    </EndpointDetailsLayout>
  );
};

export default EndpointOverviewPage;
