import { Box, Grid, Stack, styled, Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';
import ClockImage from 'features/homepage/images/clock.svg';
import ScriptImage from 'features/homepage/images/script.svg';
import DashboardImage from 'features/homepage/images/dashboard.svg';

interface BackgroundImageType {
  image: string;
}

const StyleGrid = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  height: '344px',
}));

const BackgroundImageContainer = styled(Box)<BackgroundImageType>((props) => ({
  backgroundImage: `url(${props.image})`,
  height: '146px',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
}));

const cards = [
  {
    image: ScriptImage,
    description:
      'This tutorial will teach you how to register a sample model (and version) via a Jupyter notebook in Colab.',
  },
  {
    image: ClockImage,
    description:
      "This will take 10 minutes. If you're familiar with similar tools it can be much faster.",
  },
  {
    image: DashboardImage,
    description:
      'After completing the tutorial, start registering your own models and versions to get organized.',
  },
];

export const BeforeStart = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Before you start
    </Typography>
    <Typography variant="body2" mb={2}>
      We have some things to clarify before you start:
    </Typography>
    <Grid container spacing={3}>
      {cards.map((card) => (
        <Grid item xxs={4} key={card.description}>
          <StyleGrid spacing={3} padding={3}>
            <Box>
              <BackgroundImageContainer image={card.image} />
            </Box>
            <Box px={1}>
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
              >
                {card.description}
              </Typography>
            </Box>
          </StyleGrid>
        </Grid>
      ))}
    </Grid>
  </WizardStepContent>
);
