import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { MonitoringConfidenceMetricType } from 'generated/types';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { toGraphQLDate } from 'shared/utils/graphql/toGraphQLDate';
import { convertTimeRangeToDateRange } from 'shared/utils/TimeRange';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { convertMonitoringFilterToGraphQL } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';

import {
  ConfidenceMetrics,
  ConfidenceMetricsVariables,
} from './graphql-types/useConfidenceMetrics.generated';

const CONFIDENCE_METRICS = gql`
  query ConfidenceMetrics(
    $monitoredEntityId: ID!
    $confidenceMetricQuery: MonitoringConfidenceMetricQuery!
  ) {
    monitoredEntity(id: $monitoredEntityId) {
      ... on Error {
        ...ErrorData
      }
      ... on MonitoredEntity {
        id
        metrics {
          confidenceMetric(query: $confidenceMetricQuery) {
            modelVersionId
            modelVersion {
              ... on Error {
                ...ErrorData
              }
              ... on RegisteredModelVersion {
                id
                version
              }
            }
            values
            type
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

interface Props {
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  metricTypes: MonitoringConfidenceMetricType[];
  output: MonitoringIODescription;
}

const VALUES_ARRAY_SIZE = 100;

export const useConfidenceMetrics = (props: Props) => {
  const variables = useMemo(() => {
    const dateRange = convertTimeRangeToDateRange(
      props.widgetExternalDeps.timeRange
    );
    return {
      monitoredEntityId: props.widgetExternalDeps.monitoredEntityId,
      confidenceMetricQuery: {
        startDate: toGraphQLDate(dateRange.from),
        endDate: toGraphQLDate(dateRange.to),
        types: props.metricTypes,
        size: VALUES_ARRAY_SIZE,
        output: props.output,
        filters: props.widgetExternalDeps.filters.map(
          convertMonitoringFilterToGraphQL
        ),
      },
    };
  }, [
    props.metricTypes,
    props.output,
    props.widgetExternalDeps.monitoredEntityId,
    props.widgetExternalDeps.timeRange,
    props.widgetExternalDeps.filters,
  ]);

  const query = useCustomQuery<ConfidenceMetrics, ConfidenceMetricsVariables>(
    CONFIDENCE_METRICS,
    {
      variables,
    }
  );

  const convert = useCallback(
    (res: ConfidenceMetrics) => {
      return mapDataOrError(res.monitoredEntity, (x) =>
        x.metrics.confidenceMetric.filter((c) =>
          props.widgetExternalDeps.registeredModelVersionIds.includes(
            c.modelVersionId
          )
        )
      );
    },
    [props.widgetExternalDeps.registeredModelVersionIds]
  );

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult: query,
  });
};
