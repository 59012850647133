import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import { pipe, mapObjIndexed, isNil } from 'ramda';
import { useMemo } from 'react';

import { AlertStatus } from 'generated/types';
import {
  getAlertStatusInfoType,
  getAlertStatusLabel,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertStatus';
import { MonitoringAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import { groupBy } from 'shared/utils/collection';
import { useIconAwesomeColorPalette } from 'shared/view/elements/IconAwesome/hooks/useIconAwesomeColorPalette';
import Preloader from 'shared/view/elements/Preloader/Preloader';

interface Props {
  alerts: MonitoringAlert[] | undefined;
}

const initial: Record<AlertStatus, number> = {
  [AlertStatus.OK]: 0,
  [AlertStatus.ALERTING]: 0,
  [AlertStatus.NODATA]: 0,
  [AlertStatus.PAUSED]: 0,
  [AlertStatus.UNKNOWN]: 0,
};

const countAlertsByStatus = (alerts: MonitoringAlert[]) =>
  pipe(
    () => groupBy((a) => a.status, alerts),
    (x) => mapObjIndexed((value) => value.length, x),
    (x) => ({ ...initial, ...x })
  )();

const STATUSES = [
  AlertStatus.ALERTING,
  AlertStatus.OK,
  AlertStatus.PAUSED,
  AlertStatus.NODATA,
];

const MonitoringAlertsCards = (props: Props) => {
  const alertsCounts = useMemo(
    () => countAlertsByStatus(props.alerts || []),
    [props.alerts]
  );

  return (
    <Grid container spacing={2}>
      {STATUSES.map((status) => (
        <Grid key={status} item xs={3}>
          <AlertCountCard
            status={status}
            count={alertsCounts[status]}
            isLoading={isNil(props.alerts)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const AlertCountCard = (props: {
  status: AlertStatus;
  count: number;
  isLoading: boolean;
}) => {
  const { colors } = useIconAwesomeColorPalette({});

  const color = colors.info[getAlertStatusInfoType(props.status)];

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: color,
          padding: '8px',
        }}
        title={getAlertStatusLabel(props.status)}
        titleTypographyProps={{
          color: '#fff',
          align: 'center',
          fontSize: '20px',
        }}
      />
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 100,
        }}
      >
        {props.isLoading ? (
          <Preloader />
        ) : (
          <span
            style={{
              color,
              fontSize: '60px',
              fontWeight: 300,
            }}
          >
            {props.count}
          </span>
        )}
      </CardContent>
    </Card>
  );
};

export default MonitoringAlertsCards;
