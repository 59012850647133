import { Box } from '@mui/material';
import { styled } from '@mui/material';

export const OverviewWidgetWrapper = styled(Box)({
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  border: '1px solid transparent',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const OverviewWidgetWrapperHoverable = styled(Box)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  border: '1px solid transparent',
  '&:hover': {
    border: '1px solid rgba(0, 0, 0, 0.26)',
  },
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));
