import { ArtifactData } from 'shared/graphql/Artifact/graphql-types/Artifact.generated';

import { compareKeyedItemsProperties } from './keyedItemsPropertiesDiff';

export type ArtifactsDiff = ReturnType<typeof compareArtifacts>;

export const artifactComparedProps: Array<keyof ArtifactData> = [
  'key',
  'path',
  'type',
  'uploadCompleted',
];

export const compareArtifacts = (
  entitiesArtifacts: Array<{ artifacts: Array<ArtifactData> }>
) => {
  return compareKeyedItemsProperties(
    ({ key }) => key,
    artifactComparedProps,
    entitiesArtifacts.map(({ artifacts }) => artifacts)
  );
};
