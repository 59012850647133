import Typography from '@mui/material/Typography';
import { useEffect } from 'react';

import { getDefaultIODescriptions } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { MonitoringWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidget';
import { NotInitializedWidget as NotInitializedWidgetType } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/NotInitializedWidget';
import matchBy from 'shared/utils/matchBy';

import { MonitoringWidgetProps } from '../shared/types';

type Props = MonitoringWidgetProps<NotInitializedWidgetType> & {
  updateWidget: ((widget: MonitoringWidget) => void) | undefined;
};

const useNotInitializedWidgetInitialization = (props: Props) => {
  useEffect(() => {
    if (props.updateWidget) {
      const updateWidget = props.updateWidget;
      const { defaultIODescription, defaultOutput } = getDefaultIODescriptions(
        props.widgetExternalDeps.ioDescriptions
      );

      matchBy(
        props.widget.settings,
        'type'
      )({
        barChart: (settings) => {
          if (defaultIODescription) {
            updateWidget({
              ...props.widget,
              ...settings,
              ioDescription: defaultIODescription,
            });
          }
        },
        confusionMatrix: (settings) => {
          if (defaultOutput) {
            updateWidget({
              ...props.widget,
              ...settings,
              output: defaultOutput,
            });
          }
        },
        curve: (settings) => {
          if (defaultOutput) {
            updateWidget({
              ...props.widget,
              ...settings,
              output: defaultOutput,
            });
          }
        },
        histograms: (settings) => {
          if (defaultIODescription) {
            updateWidget({
              ...props.widget,
              ...settings,
              ioDescription: defaultIODescription,
            });
          }
        },
        scatterPlot: (settings) => {
          if (defaultIODescription) {
            updateWidget({
              ...props.widget,
              ...settings,
              ioDescription: defaultIODescription,
            });
          }
        },
        singleMetric: (settings) => {
          if (defaultOutput) {
            updateWidget({
              ...props.widget,
              ...settings,
              variant: {
                type: 'metric',
                metricType: settings.metricType,
                output: defaultOutput,
              },
            });
          }
        },
        timeSeries: (settings) => {
          matchBy(
            settings.variant,
            'type'
          )({
            drift: (variant) => {
              if (defaultIODescription) {
                updateWidget({
                  ...props.widget,
                  ...settings,
                  variant: {
                    ...variant,
                    ioDescription: defaultIODescription,
                  },
                });
              }
            },
            metric: (variant) => {
              if (defaultOutput) {
                updateWidget({
                  ...props.widget,
                  ...settings,
                  variant: {
                    ...variant,
                    output: defaultOutput,
                  },
                });
              }
            },
          });
        },
      });
    }
  }, [
    props.updateWidget,
    props.widget,
    props.widgetExternalDeps.ioDescriptions,
  ]);
};

const NotInitializedWidget = (props: Props) => {
  useNotInitializedWidgetInitialization(props);

  return (
    <Typography variant="subtitle1" color="error">
      Not initialized
    </Typography>
  );
};

export default NotInitializedWidget;
