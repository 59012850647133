import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useModelVersionReproduce } from 'features/catalog/registeredModelVersion/reproduce/store/useModelVersionReproduce';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

const ModelVersionReproduce = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ModelVersionReproduce' */ 'features/catalog/registeredModelVersion/reproduce/view/reproduce'
    )
);

const ReproducePage = () => {
  const { versionId } = useRouteParams(routes.modelVersionReproduce);

  const { version, loadingVersion } = useModelVersionReproduce({
    versionId,
  });

  return (
    <DefaultMatchRemoteDataOrError
      communication={loadingVersion}
      data={version}
      context="loading model version"
    >
      {(loadedModelVersion) => (
        <ModelVersionReproduce version={loadedModelVersion} />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default ReproducePage;
