import { Box, Stack, Typography } from '@mui/material';

import { Stage } from 'generated/types';
import matchType from 'shared/utils/matchType';

import DevToProdSVG from './img/dev-to-prod.svg?react';
import ProdToDevSVG from './img/prod-to-dev.svg?react';
import ProdToStagingSVG from './img/prod-to-staging.svg?react';

interface Props {
  stage: Stage;
  desiredStage: Stage;
}

const ProdToDev = () => (
  <Stack spacing={3}>
    <Typography variant="body2">
      This model version is currently in production and might have already been
      in development. Are you sure you want to move this version back to
      development?
    </Typography>
    <Box display="flex" justifyContent="center">
      <ProdToDevSVG />
    </Box>
  </Stack>
);

const ProdToStaging = () => (
  <Stack spacing={3}>
    <Typography variant="body2">
      This model version is currently in production and might have already been
      in staging. Are you sure you want to move this version back to staging?
    </Typography>
    <Box display="flex" justifyContent="center">
      <ProdToStagingSVG />
    </Box>
  </Stack>
);

const DevToProd = () => (
  <Stack spacing={3}>
    <Typography variant="body2">
      Staging environment can be used for testing your model before it goes to
      production. Are you sure you want to move this version straight to
      production?
    </Typography>
    <Box display="flex" justifyContent="center">
      <DevToProdSVG />
    </Box>
  </Stack>
);

const CustomDesiredStageMessage = ({ stage, desiredStage }: Props) => {
  return matchType(
    {
      DEVELOPMENT: () => (stage === Stage.PRODUCTION ? <ProdToDev /> : null),
      STAGING: () => (stage === Stage.PRODUCTION ? <ProdToStaging /> : null),
      PRODUCTION: () => (stage === Stage.DEVELOPMENT ? <DevToProd /> : null),
      ARCHIVED: () => (
        <Typography variant="body2">
          The Verta platform keeps records of all logs and model changes made to
          this version for documentation purposes.
        </Typography>
      ),
      UNASSIGNED: () => null,
      UNKNOWN: () => null,
    },
    desiredStage
  );
};

export default CustomDesiredStageMessage;
