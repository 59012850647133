import routes from 'shared/routes';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { TabsLayout } from 'pages/authorized/shared/TabsLayout';
import { useRouteParams } from 'shared/utils/router/useRouteParams';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import {
  isEnoughComparedEntities,
  minNumberOfEntitiesForComparing,
} from 'shared/models/CompareEntities/new/comparedEntities';
import { isNotRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { EvaluationsCompareTable } from 'features/evaluations/compareEvaluations/view';
import Placeholder from 'shared/view/elements/Placeholder/Placeholder';
import { useEvaluationsForComparing } from 'features/evaluations/compareEvaluations/hooks/useEvaluationsForComparing';

import { useEvaluationsPageBreadcrumbs } from './shared/useEvaluationsPageBreadcrumbs';

const EvaluationComparePage = () => {
  const { ids } = useRouteParams(routes.registeredModelVersionsCompare);

  const { loadingEvaluations, evaluations } = useEvaluationsForComparing(
    ids.split(',')
  );
  const breadcrumbs = useAppBreadcrumbs(
    useEvaluationsPageBreadcrumbs(),
    useBreadcrumbItem({
      name: 'Compare evaluations',
      route: routes.compareEvaluations,
    })
  );

  return (
    <TabsLayout breadcrumbs={breadcrumbs} pageEntityOrError={evaluations}>
      <DefaultMatchRemoteDataOrError
        communication={loadingEvaluations}
        data={evaluations?.filter(isNotRestrictedGraphqlError)}
        context="loading registered model versions"
      >
        {(loadedEvaluations) => {
          return isEnoughComparedEntities(loadedEvaluations) ? (
            <EvaluationsCompareTable evaluations={loadedEvaluations} />
          ) : (
            <Placeholder>
              You should select at least {minNumberOfEntitiesForComparing} for
              comparing
            </Placeholder>
          );
        }}
      </DefaultMatchRemoteDataOrError>
    </TabsLayout>
  );
};

export default EvaluationComparePage;
