import {
  IPathDatasetComponentBlob,
  IS3DatasetComponentBlob,
  IPathDatasetBlob,
  IDatasetBlob,
} from 'shared/models/Versioning/Blob/DatasetBlob';

interface IServerPathDatasetComponent {
  path?: string; // Full path to the file
  size?: number;
  last_modified_at_source?: number;
  md5?: string;
}

export type ServerDatasetBlob = {
  s3?: {
    components: Array<{
      path: IServerPathDatasetComponent;
      s3_version_id: string;
    }>;
  };
  path?: {
    components: Array<IServerPathDatasetComponent>;
  };
};

export const convertServerDatasetBlobToClient = (
  serverDataset: ServerDatasetBlob
): IDatasetBlob => {
  if (serverDataset.s3) {
    return {
      category: 'dataset',
      type: 's3',
      data: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        components: serverDataset.s3.components.map(
          convertServerDatasetS3Component
        ),
      },
    };
  }
  if (serverDataset.path) {
    return convertPathDatasetBlob(serverDataset.path);
  }
  throw new Error('unknown dataset blob type');
};

const convertServerDatasetPathComponent = (
  serverDatasetComponent: IServerPathDatasetComponent & {
    internal_versioned_path?: string;
    size?: number;
  }
): IPathDatasetComponentBlob => {
  return {
    path: serverDatasetComponent.path || '',
    size: Number(serverDatasetComponent.size ?? 0),
    lastModifiedAtSource:
      typeof serverDatasetComponent.last_modified_at_source !== 'undefined'
        ? new Date(Number(serverDatasetComponent.last_modified_at_source))
        : null,
    md5: serverDatasetComponent.md5
      ? removeEnclosingQuotes(serverDatasetComponent.md5 || '')
      : '',
    internalVersionedPath:
      serverDatasetComponent.internal_versioned_path || null,
  };
};

const removeEnclosingQuotes = (str: string) => {
  if (str && str[0] === '"' && str[str.length - 1] === '"') {
    return str.slice(1, -1);
  }
  return str;
};

const convertServerDatasetS3Component = (serverDatasetComponent: {
  path: IServerPathDatasetComponent;
  s3_version_id: string;
}): IS3DatasetComponentBlob => {
  return {
    s3VersionId: serverDatasetComponent.s3_version_id,
    ...convertServerDatasetPathComponent(serverDatasetComponent.path),
  };
};

const convertPathDatasetBlob = (serverPath: {
  components: Array<IServerPathDatasetComponent>;
}): IPathDatasetBlob => {
  return {
    category: 'dataset',
    type: 'path',
    data: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      components: serverPath.components.map(convertServerDatasetPathComponent),
    },
  };
};
