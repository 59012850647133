import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import routes from 'shared/routes';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { convertAlertId } from 'shared/graphql/monitoring/Alert/Alert';

import { CreateAlertMutation } from '../../store/createAlert/graphql-types/useCreateAlert.generated';
import { useCreateAlert } from '../../store/createAlert/useCreateAlert';
import MonitoringAlertForm from '../shared/MonitoringAlertForm/MonitoringAlertForm';
import { MonitoringAlertFormType } from '../shared/MonitoringAlertForm/shared/MonitoringAlertFormType';

interface Props {
  ioDescriptions: MonitoringIODescription[];
  model: MonitoringModel;
}

const CreateMonitoringAlert = (props: Props) => {
  const navigate = useNavigate();

  const onCompleted = useCallback(
    (res: CreateAlertMutation) => {
      if (isNotNullableRestrictedGraphqlError(res.monitoredEntity)) {
        navigate(
          routes.monitoringAlert.getRedirectPath({
            alertId: convertAlertId(res.monitoredEntity.createAlert.id),
            monitoringModelId: props.model.id,
            workspaceName: props.model.workspaceName,
          })
        );
      }
    },
    [navigate, props.model.id, props.model.workspaceName]
  );

  const { createAlert, creatingAlert } = useCreateAlert({
    onCompleted,
  });

  const onSubmit = useCallback(
    (form: MonitoringAlertFormType) =>
      createAlert({
        alert: form,
        monitoredEntityId: props.model.monitoredEntity.id,
      }),
    [createAlert, props.model.monitoredEntity.id]
  );

  const onCancel = useCallback(
    () =>
      navigate(
        routes.monitoringAlerts.getRedirectPath({
          monitoringModelId: props.model.id,
          workspaceName: props.model.workspaceName,
        })
      ),
    [navigate, props.model.id, props.model.workspaceName]
  );

  return (
    <MonitoringAlertForm
      monitoredModelType={props.model.type}
      initialValues={null}
      onSubmit={onSubmit}
      ioDescriptions={props.ioDescriptions}
      submitText="Create"
      isLoading={creatingAlert.isRequesting}
      onCancel={onCancel}
    />
  );
};

export default CreateMonitoringAlert;
