import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import checklistCreateNew from '../images/checklist-create-new.png';

export const CreateNewChecklist = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Create a new checklist
    </Typography>
    <Typography variant="body2" mb={3}>
      Feel free to create as many new checklists as you need. Each checklist
      will be given a name and applied to specific model versions, requiring at
      least one item to be checked off. We recommend keeping checklists to 6 to
      8 items for optimal digestibility and actionability.
    </Typography>
    <img
      src={checklistCreateNew}
      width="100%"
      height="auto"
      alt="checklist create new"
    />
  </WizardStepContent>
);
