import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  image?: string | undefined;
  svgComponent?: ReactNode;
  title: string;
  description: string;
  children?: ReactNode;
};

export const InformationalTile = ({
  image,
  svgComponent,
  title,
  description,
  children,
}: Props) => (
  <Stack
    spacing={3}
    direction="row"
    padding={3}
    sx={{ backgroundColor: 'action.hover' }}
  >
    {image ? <img height="146px" width="auto" src={image} alt="" /> : null}
    {svgComponent !== undefined ? svgComponent : null}
    <Stack spacing={2}>
      <Typography variant="subtitle2" mt={1}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" whiteSpace="pre-wrap">
        {description}
      </Typography>
      {children}
    </Stack>
  </Stack>
);
