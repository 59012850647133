import { Stack, Typography } from '@mui/material';

import {
  RequiredOwner,
  RequiredResource,
} from 'features/editResourceInformation/store/useEditResourceInformation';
import useOwnerCandidates from 'features/editResourceInformation/store/useOwnerCandidates';
import matchType from 'shared/utils/matchType';
import DefaultMatchRemoteData from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import RadioButtons from 'shared/view/elements/RadioButtons/RadioButtons';
import Select from 'shared/view/elements/Selects/Select/Select';
import capitalize from 'shared/utils/capitalize';

import { resourceTypeToView } from './resourceTypeToView';

interface Props {
  resource: RequiredResource;
  ownerType: RequiredOwner['__typename'];
  ownerId: string;
  onChangeOwnerType(ownerType: RequiredOwner['__typename']): void;
  onChangeOwnerId(ownerId: string): void;
  disableGroups?: boolean;
  disableUsers?: boolean;
}

const OwnerSelector = (props: Props) => {
  const userOwnersCandidatesApi = useOwnerCandidates(props.resource);
  const { disableGroups, disableUsers } = props;

  return (
    <div>
      <Stack>
        <Typography variant="subtitle2" color="text.secondary">
          {capitalize(resourceTypeToView(props.resource.__typename))} ownership
        </Typography>
        <Typography variant="body2">
          You can reassign {resourceTypeToView(props.resource.__typename)}{' '}
          ownership to a user or a group.
        </Typography>
      </Stack>

      <DefaultMatchRemoteData
        context="loading owner candidates"
        {...userOwnersCandidatesApi}
      >
        {({ groups, users }) => {
          const options = matchType(
            {
              Group: () =>
                groups?.map((group) => ({
                  label: group.name,
                  value: group.id,
                  description: group.id === props.ownerId ? 'Owner' : undefined,
                })) || [],
              User: () =>
                users?.map((user) => ({
                  label: `${user.fullName || ''} (${user.email || ''})`,
                  value: user.id,
                  description: user.id === props.ownerId ? 'Owner' : undefined,
                })) || [],
            },
            props.ownerType
          );

          return (
            <div>
              <RadioButtons<RequiredOwner['__typename']>
                value={props.ownerType}
                options={[
                  {
                    label: 'Users',
                    name: 'ownerType',
                    value: 'User',
                    disabled: Boolean(disableUsers),
                  },
                  {
                    label: 'Groups',
                    name: 'ownerType',
                    value: 'Group',
                    disabled: Boolean(disableGroups),
                  },
                ]}
                onChange={props.onChangeOwnerType}
              />
              <Select
                options={options}
                value={options.find((opt) => opt.value === props.ownerId)}
                disabled={!props.resource.allowedActions.update}
                maxWidth="1000px"
                onChange={({ value }) => props.onChangeOwnerId(value)}
              />
            </div>
          );
        }}
      </DefaultMatchRemoteData>
    </div>
  );
};

export default OwnerSelector;
