import { Box, Typography, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useLocation, Navigate } from 'react-router-dom';

import { VertaLogo } from 'shared/view/elements/VertaLogo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import Button, { ButtonProps } from 'shared/view/elements/Button/Button';
import routes from 'shared/routes';
import { selectFlags } from 'features/flags';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { OmitStrict } from 'shared/utils/types';

import { Layout, LeftSlot, RightSlot } from '../shared/Layout';
import { CopyRightFooter } from '../shared/CopyRightFooter';
import { SocialButtons } from '../shared/SocialButtons';
import { VertaOverallFreeTier } from '../shared/VertaOverallFreeTier';

const BackButton = (props: OmitStrict<ButtonProps, 'isLoading'>) => (
  <Button
    size="small"
    variant="text"
    sx={{ color: 'text.secondary' }}
    isLoading={false}
    {...props}
  >
    <Stack direction="row">
      <IconAwesomeCustomizable icon={ICONS.arrowLeft} size="lg" />
      <Typography variant="body2">Back</Typography>
    </Stack>
  </Button>
);

export const SignUpPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isEnableFreeTier } = useSelector(selectFlags);

  if (!isEnableFreeTier) {
    return <Navigate to={routes.login.getPath()} />;
  }
  const showBackButton =
    Boolean(location.state?.showBackButton) &&
    Boolean(routes.signUpMailForm.getMatch(location.pathname));

  return (
    <Layout sx={{ backgroundColor: '#FFF' }}>
      <LeftSlot>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          padding={{ xxs: 5, sm: 4 }}
        >
          <Box mt={4} mb={6}>
            <VertaLogo
              width="76"
              height="24"
              color="primary.main"
              onClickRedirect={false}
              onClick={() => {
                window.location.replace('https://www.verta.ai/');
              }}
            />
          </Box>
          <Box
            flex="1 1 auto"
            mb={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Box mb={1} height="30px">
              {showBackButton ? (
                <BackButton onClick={() => navigate(-1)} />
              ) : null}
            </Box>
            <Typography variant="h4" color="text.primary" mb={1}>
              Welcome
            </Typography>
            <Typography variant="h6" color="text.primary" mb={2}>
              Sign up
            </Typography>

            <Outlet />
          </Box>
          <Box flex="0 1 40px" display="flex">
            <SocialButtons />
          </Box>
        </Box>
      </LeftSlot>
      <RightSlot sx={{ backgroundColor: '#F7FAFC' }}>
        <Box overflow="auto" height="100%" px={7} pt={20} pb={4}>
          <VertaOverallFreeTier />
          <CopyRightFooter />
        </Box>
      </RightSlot>
    </Layout>
  );
};
