import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import TextInput from 'shared/view/elements/TextInput/TextInput';
import Button from 'shared/view/elements/Button/Button';

interface Props {
  label: string;
  onAdd: (p: string) => void;
}

const TextInputTag = ({ onAdd, label }: Props) => {
  const [tag, setTag] = useState('');
  const [tagLimitReached, setTagLimitReached] = useState(false);

  const updateInput = (value: string) => {
    if (tag.length >= 40) {
      setTagLimitReached(true);
    } else {
      setTagLimitReached(false);
      setTag(value);
    }
  };

  const onAddTag = () => {
    onAdd(tag);
    setTag('');
  };

  return (
    <Stack direction="row" spacing={1}>
      <Box sx={{ flex: '1' }}>
        <div data-test="tags-manager-creator">
          <TextInput
            maxWidth="100%"
            value={tag}
            meta={{
              touched: true,
              error: tagLimitReached
                ? 'maximum character limit reached'
                : undefined,
            }}
            label={label}
            onChange={updateInput}
            onEnter={onAddTag}
            name="tags-manager-creator-input"
            data-test="tags-manager-creator-input"
            withoutError={!tagLimitReached}
          />
        </div>
      </Box>
      <Box pt={1}>
        <div data-test="tags-manager-create-button">
          <Button
            variant="outlined"
            isLoading={false}
            onClick={onAddTag}
            disabled={tag.length === 0}
          >
            Add
          </Button>
        </div>
      </Box>
    </Stack>
  );
};

export default TextInputTag;
