import { useMemo } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { head } from 'ramda';

import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import InfoIcon from 'shared/view/elements/InfoIcon/InfoIcon';
import {
  IGroupedOptions,
  IOptionsGroup,
  IOptionType,
} from 'shared/view/elements/Selects/shared/types';

export function useVertaSelectOptions<T>(
  options: Array<IOptionType<T>> | IGroupedOptions<T>,
  withDescription = false
): {
  firstOption: IOptionType<T> | null;
  children: JSX.Element[];
} {
  return useMemo(() => {
    if ('type' in options) {
      const optionsList = options.groups.flatMap((g) => g.options);
      return {
        firstOption: head(optionsList) ?? null,
        children: options.groups.flatMap((group) =>
          getGroupOptions(group, withDescription)
        ),
      };
    }
    return {
      firstOption: head(options) ?? null,
      children: options.map((option) => getSelectItem(option, withDescription)),
    };
  }, [options, withDescription]);
}

/*
   it must be pure array, not Fragment <> </> otherwise click on item will not works. Possible reason that MUI provides
   some hidden/undercover props
*/
const getGroupOptions = <T extends unknown = string>(
  group: IOptionsGroup<T | undefined> | IOptionsGroup<T>,
  withDescription: boolean
): JSX.Element[] => {
  return [
    <ListSubheader key={`__group_${group.label}`}>
      <b>{group.label}</b>
    </ListSubheader>,
  ].concat(
    group.options.map((option) => getSelectItem(option, withDescription))
  );
};
/*
  don't convert it to component otherwise click on item will not works. Possible reason that MUI provides
  some hidden/undercover props
*/
const getSelectItem = <T extends unknown = string>(
  option: IOptionType<T>,
  withDescription: boolean
) => {
  return (
    <MenuItem
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value={option as any}
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      key={option.label || option.element?.key}
      data-test={'select-item'}
      data-test-label={option.label}
      disabled={option.disabled}
    >
      <Stack direction={'row'} sx={{ width: '100%' }}>
        {withDescription && option.description ? (
          <Stack direction={'column'} spacing={0}>
            <DynamicTypography
              key={option.label ?? ''}
              value={option.label ?? ''}
            />
            <DynamicTypography
              variant="caption"
              color="text.secondary"
              key={option.description}
              value={option.description}
            />
          </Stack>
        ) : (
          <>
            {option.label && (
              <DynamicTypography key={option.label} value={option.label} />
            )}
            {option.element ?? option.element}
            {option.description && <InfoIcon tooltip={option.description} />}
          </>
        )}
      </Stack>
    </MenuItem>
  );
};
