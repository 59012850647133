import {
  useBreadcrumbItem,
  useAppBreadcrumbs,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import routes from 'shared/routes';

import { useMonitoringPageBreadcrumb } from '../../shared/useMonitoringPageBreadcrumb';

export const useMonitoringModelPageBreadcrumbs = ({
  modelName,
}: {
  modelName: string;
}) => {
  return useAppBreadcrumbs(
    useMonitoringPageBreadcrumb(),
    useBreadcrumbItem({
      name: modelName,
      route: routes.monitoringModel,
    })
  );
};
