import Grid from '@mui/material/Grid';
import { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';

import { StatsBar } from 'shared/view/elements/StatsBar/StatsBar';
import {
  StatsLegendItem,
  StatsLegendItemProps,
} from 'shared/view/elements/StatsLegendItem/StatsLegendItem';

type CountStatistic = StatsLegendItemProps['item'];

interface Props {
  totalRecords: number;
  productionTotalRecords: number;
}

const CatalogCountStatistics = (props: Props) => {
  const { totalRecords, productionTotalRecords } = props;
  const theme = useTheme();

  const statistics = useMemo((): CountStatistic[] => {
    return [
      {
        color: theme.palette.success.main,
        label: 'Have versions in production',
        value: productionTotalRecords,
      },
      {
        color: theme.palette.additionalBackground.inactive,
        label: 'No versions in production',
        value: totalRecords - productionTotalRecords,
      },
    ];
  }, [
    productionTotalRecords,
    theme.palette.additionalBackground.inactive,
    theme.palette.success.main,
    totalRecords,
  ]);

  return (
    <Grid container alignItems="flex-end" gap={5}>
      <Grid item xs={7}>
        <Stack direction="row" alignItems="flex-end">
          <Typography variant="h2">{totalRecords}</Typography>
          <Typography variant="body1" lineHeight="40px">
            Registered models
          </Typography>
        </Stack>
        <StatsBar items={statistics} />
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={3}>
          {statistics.map((s, index) => (
            <StatsLegendItem key={`legend-${s.label || index}`} item={s} />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CatalogCountStatistics;
