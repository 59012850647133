import { formatWithDefaultPrecision } from 'shared/utils/formatters/formatWithDefaultPrecision';

export type IntervalValue = string | number;

export interface ChartInterval {
  intervalStart: IntervalValue;
  intervalEnd: IntervalValue;
}

export function makeIntervals(data: ChartInterval[]): IntervalValue[] {
  if (data.length > 0) {
    return [
      data[0].intervalStart,
      ...data.map(({ intervalEnd }) => intervalEnd),
    ];
  }

  return [];
}

export const toStringInterval = (interval: ChartInterval) => {
  return `${interval.intervalStart} - ${interval.intervalEnd}`;
};

export const toIntervalWithPrecision = (
  interval: ChartInterval
): ChartInterval => {
  return {
    intervalStart:
      typeof interval.intervalStart === 'number'
        ? formatWithDefaultPrecision(interval.intervalStart)
        : interval.intervalStart,
    intervalEnd:
      typeof interval.intervalEnd === 'number'
        ? formatWithDefaultPrecision(interval.intervalEnd)
        : interval.intervalEnd,
  };
};
