import { FC } from 'react';
import cn from 'classnames';
import { useTheme } from '@mui/material';

import { formatBadgeValue } from 'shared/view/elements/Badge/Badge';

import styles from './DiffCount.module.css';

interface Props {
  value: number;
  withOutline: boolean;
  dataTest?: string;
}

const DiffCount: FC<React.PropsWithChildren<Props>> = (props) => {
  const formattedValue = formatBadgeValue(props.value);
  const theme = useTheme();
  return (
    <div
      className={cn(styles.root, { [styles.withOutline]: props.withOutline })}
      style={{
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
      }}
      data-test={props.dataTest}
    >
      {formattedValue}
    </div>
  );
};

export default DiffCount;
