import { LinePath, Circle } from '@visx/shape';
import { LinePathProps } from '@visx/shape/lib/shapes/LinePath';
import { AccessorForArrayItem, AddSVGProps } from '@visx/shape/lib/types';

interface ILocalProps<Datum>
  extends AddSVGProps<LinePathProps<Datum>, SVGPathElement> {
  x: AccessorForArrayItem<Datum, number>;
  y: AccessorForArrayItem<Datum, number>;
  color: string;
  hidePoints: boolean;
  pointSize?: number;
}

function LinePathWithPoints<Datum>(props: ILocalProps<Datum>) {
  const { color, hidePoints, pointSize, ...linePathProps } = props;
  return (
    <>
      <LinePath {...linePathProps} stroke={color} />

      {hidePoints
        ? null
        : props.data?.map((d, i, arr) => (
            <Circle
              key={i}
              cx={props.x(d, 0, arr)}
              cy={props.y(d, 0, arr)}
              fill={color}
              r={pointSize ?? 3}
              data-test="line-point"
            />
          ))}
    </>
  );
}

export default LinePathWithPoints;
