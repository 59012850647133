/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { keys, omit } from 'ramda';

import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import {
  isNotNullableRestrictedGraphqlError,
  isNotRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import {
  DEFAULT_MONITORED_MODEL_TYPE,
  MonitoredModelType,
} from 'shared/models/Monitoring/MonitoringModel/MonitoredModelType';
import { MonitoringModelWithoutState } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import {
  getLastUpdatedRegisteredModelVersion,
  MonitoringModelRegisteredModelVersion,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringModelRegisteredModelVersion';
import { MonitoringModelStatus } from 'shared/models/Monitoring/MonitoringModel/MonitoringModelStatus';
import isNotNil from 'shared/utils/isNotNill';
import matchType from 'shared/utils/matchType';

import { MonitoringModelFragment } from './graphql-types/MonitoringModel.generated';
import {
  convertMonitoringModelRegisteredModelVersion,
  MONITORING_MODEL_REGISTERED_MODEL_VERSION_FRAGMENT,
} from './MonitoringModelRegisteredModelVersion';

export const MONITORING_MODEL_FRAGMENT = gql`
  fragment MonitoringModelFragment on MonitoredModel {
    id
    name
    json
    version
    createdAt
    monitoredEntity {
      id
      name
      allowedActions {
        ...AllowedActionsData
      }
      workspace {
        id
        name
      }
      owner {
        ...OwnerData
      }
      alerts {
        alerts {
          id
          status
        }
      }
      metrics {
        # We can not use variables inside fragment, therefore I decided
        # to set constant huge time range inside it
        # 2000000000000 ~ May 18 2033
        modelVersions(query: { startDate: "0", endDate: "2000000000000" }) {
          modelVersion {
            ... on Error {
              ...ErrorData
            }
            ... on RegisteredModelVersion {
              id
              dateUpdated
              ...MonitoringModelRegisteredModelVersionFragment
            }
          }
        }

        status {
          isLiveDataExist
          isReferenceDataExist
          isGroundTruthExist
        }
      }
      endpoint {
        ... on Error {
          ...ErrorData
        }
        ... on Endpoint {
          id
          path
          dateUpdated
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${MONITORING_MODEL_REGISTERED_MODEL_VERSION_FRAGMENT}
`;

export const convertMonitoringModel = (
  data: MonitoringModelFragment
): MonitoringModelWithoutState => {
  const modelVersions = data.monitoredEntity.metrics.modelVersions
    .map((mv) => convertMonitoringModelRegisteredModelVersion(mv.modelVersion))
    .filter(isNotRestrictedGraphqlError);

  const modelVersion = getLastUpdatedRegisteredModelVersion(modelVersions);
  const type: MonitoredModelType = modelVersion
    ? getMonitoredModelTypeFromModelVersion(modelVersion)
    : DEFAULT_MONITORED_MODEL_TYPE;

  return {
    id: data.id,
    name: data.name,
    createdAt: data.createdAt,
    type,
    workspaceName: data.monitoredEntity.workspace.name,
    monitoredEntity: data.monitoredEntity,
    endpoint: isNotNullableRestrictedGraphqlError(data.monitoredEntity.endpoint)
      ? {
          ...data.monitoredEntity.endpoint,
          dateUpdated: String(data.monitoredEntity.endpoint.dateUpdated),
        }
      : undefined,
    owner: data.monitoredEntity.owner,
    alerts: data.monitoredEntity.alerts.alerts,
    registeredModelVersions: modelVersions,
    status: convertMonitoringModelStatus(data.monitoredEntity.metrics.status),
  };
};

export const getMonitoredModelTypeFromModelVersion = (
  modelVersion: MonitoringModelRegisteredModelVersion
) => {
  return matchType(
    {
      CLASSIFICATION: () => MonitoredModelType.CLASSIFICATION,
      REGRESSION: () => MonitoredModelType.REGRESSION,
      CLUSTERING: () => DEFAULT_MONITORED_MODEL_TYPE,
      DETECTION: () => DEFAULT_MONITORED_MODEL_TYPE,
      OTHER: () => DEFAULT_MONITORED_MODEL_TYPE,
      TRANSCRIPTION: () => DEFAULT_MONITORED_MODEL_TYPE,
      TRANSLATION: () => DEFAULT_MONITORED_MODEL_TYPE,
      UNKNOWN: () => DEFAULT_MONITORED_MODEL_TYPE,
    },
    modelVersion.registeredModel.taskType
  );
};

const convertMonitoringModelStatus = (
  data: MonitoringModelFragment['monitoredEntity']['metrics']['status']
): MonitoringModelStatus => {
  return keys(omit(['__typename'], data))
    .map((key) => {
      return data[key]
        ? matchType(
            {
              isGroundTruthExist: () => 'ground_truth' as const,
              isLiveDataExist: () => 'live' as const,
              isReferenceDataExist: () => 'reference' as const,
            },
            key
          )
        : null;
    })
    .filter(isNotNil);
};
