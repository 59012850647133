import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';

import {
  WorkspaceEndpointsCount,
  WorkspaceEndpointsCountVariables,
} from '../graphql/graphql-types/queries.generated';
import { WORKSPACE_ENDPOINTS_COUNT } from '../graphql/queries';

export const useWorkaceEndpointCount = () => {
  const [load, queryResult] = useCustomLazyQuery<
    WorkspaceEndpointsCount,
    WorkspaceEndpointsCountVariables
  >(WORKSPACE_ENDPOINTS_COUNT);

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert,
    queryResult,
  });

  return {
    data,
    communication,
    load,
  };
};

const memoizedConvert = ({ workspace }: WorkspaceEndpointsCount) =>
  mapDataOrError(workspace, (_workspace) => _workspace);
