import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

import routes from 'shared/routes';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import DeleteIconButton from 'shared/view/elements/DeleteIconButton/DeleteIconButton';
import CopyButton from 'shared/view/elements/CopyButton/CopyButton';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { useExperimentRun } from 'features/experimentRuns/details/store/useExperimentRun';
import { useExperimentRunDelete } from 'features/experimentRuns/shared/hooks/useExperimentRunDelete';
import isNotNil from 'shared/utils/isNotNill';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
import {
  isNotNullableRestrictedGraphqlError,
  isNotRestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';
import { useRouteParams } from 'shared/utils/router/useRouteParams';

const ExperimentRun = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ExperimentRun' */ 'features/experimentRuns/details/view/ExperimentRun'
    )
);

function ExperimentRunPage() {
  const {
    data,
    communication,
    deletingExperimentRun,
    deleteCurrentExperimentRun,
  } = useExperimentRunHook();

  // todo add breadcrumbs from previous levels Projects -> ProjectName
  const breadcrumbs = useAppBreadcrumbs(
    useBreadcrumbItem({
      route: routes.experimentRuns,
      name: 'Experiment runs',
    }),
    useBreadcrumbItem({
      route: routes.experimentRun,
      ...(isNotNullableRestrictedGraphqlError(data)
        ? { name: data.run.name }
        : { isLoading: true }),
    })
  );

  return (
    <AuthorizedLayout
      breadcrumbs={breadcrumbs}
      actions={[
        isNotNullableRestrictedGraphqlError(data) &&
        data.run.allowedActions.delete ? (
          <DeleteIconButton
            description="Experiment Run"
            faiDataTest="delete-experiment-run-button"
            onDelete={deleteCurrentExperimentRun}
          />
        ) : null,
      ].filter(isNotNil)}
      titleAdditionalContent={
        isNotNullableRestrictedGraphqlError(data) ? (
          <CopyButton copyText={data.run.name} />
        ) : null
      }
      pageEntityOrError={data}
    >
      <DefaultMatchRemoteDataOrError
        data={data}
        communication={communication}
        context="loading experiment run"
      >
        {({ run, project }) => (
          <ExperimentRun
            run={run}
            project={project}
            deletingExperimentRun={deletingExperimentRun}
          />
        )}
      </DefaultMatchRemoteDataOrError>
    </AuthorizedLayout>
  );
}

const useExperimentRunHook = () => {
  const { experimentRunId: runId } = useRouteParams(routes.experimentRun);

  const { data, communication, loadExperimentRun } = useExperimentRun();
  const navigate = useNavigate();
  const { deleteExperimentRun, deletingExperimentRun } = useExperimentRunDelete(
    {
      onCompleted: () => {
        const project = isNotRestrictedGraphqlError(data)
          ? data?.project
          : undefined;
        return project
          ? navigate(
              routes.experimentRuns.getRedirectPath({
                projectId: project.id,
                workspaceName: project.workspace.name,
              }),
              { replace: true }
            )
          : undefined;
      },
    }
  );

  const loadExperimentRunForCurrentId = useCallback(
    () => loadExperimentRun({ runId }),
    [runId, loadExperimentRun]
  );

  const deleteCurrentExperimentRun = useCallback(
    () => deleteExperimentRun({ id: runId }),
    [deleteExperimentRun, runId]
  );

  useEffect(() => {
    loadExperimentRun({
      runId,
    });
  }, [loadExperimentRun, runId]);

  return {
    data,
    communication,
    deleteCurrentExperimentRun,
    loadExperimentRunForCurrentId,
    deletingExperimentRun,
  };
};

export default ExperimentRunPage;
