import * as React from 'react';
import { Box } from '@mui/material';

import Preloader from '../Preloader/Preloader';

interface ILocalProps {
  children: JSX.Element;
}

const pageHasBeenForceRefreshed = 'page-has-been-force-refreshed';

const LazyLoadComponentWithPreloader = ({ children }: ILocalProps) => {
  return (
    <React.Suspense
      fallback={
        <Box width="100%">
          <Box display="flex" justifyContent="center" pt={3} pb={3}>
            <Preloader />
          </Box>
        </Box>
      }
    >
      {children}
    </React.Suspense>
  );
};

type ComponentImport = () => Promise<{
  default: React.ComponentType<React.PropsWithChildren<any>>;
}>;

const lazyWithRetry = (componentImport: ComponentImport) =>
  React.lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(pageHasBeenForceRefreshed) || 'false'
    ) as boolean;

    try {
      const component = await componentImport();

      window.localStorage.setItem(pageHasBeenForceRefreshed, 'false');
      return component;
    } catch (error: unknown) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem(pageHasBeenForceRefreshed, 'true');
        window.location.reload();
      }
      throw error;
    }
  });

export const makeLazyLoadComponentWithPreloader = <
  T extends React.ComponentType<React.PropsWithChildren<any>>,
>(
  lazyImport: ComponentImport
) => {
  const LazyComponent = lazyWithRetry(lazyImport);
  return (props: React.ComponentProps<T>) => {
    return (
      <LazyLoadComponentWithPreloader>
        <LazyComponent {...props} />
      </LazyLoadComponentWithPreloader>
    );
  };
};
