import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import isNotNil from 'shared/utils/isNotNill';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import routes from 'shared/routes';
import PagesTabs from 'shared/view/pages/PagesTabs/PagesTabs';
import { selectFlags } from 'features/flags';

import { TabsLayout } from '../shared/TabsLayout';

const Dashboard = () => {
  const { isEnableCustomDashboard } = useSelector(selectFlags);
  return (
    <TabsLayout
      breadcrumbs={useAppBreadcrumbs(
        useBreadcrumbItem({
          name: 'Dashboard',
          route: routes.dashboard,
        })
      )}
      pageEntityOrError={undefined}
      tabsElement={
        <PagesTabs
          tabs={[
            {
              label: 'General Dashboard',
              to: routes.dashboardGeneral.getPath(),
            },
            isEnableCustomDashboard
              ? {
                  label: 'Portfolio Dashboard',
                  to: routes.dashboardPortfolio.getPath(),
                }
              : null,
          ].filter(isNotNil)}
        />
      }
    >
      <Outlet />
    </TabsLayout>
  );
};

export default Dashboard;
