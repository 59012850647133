import Stack, { StackProps } from '@mui/material/Stack';
import { ReactNode } from 'react';

type Children = JSX.Element | ReactNode | Element | null | undefined;

type Props = StackProps & {
  children: Array<Children> | Children;
  dataTest?: string;
};

export default function FormStack({ children, dataTest, ...other }: Props) {
  return (
    <Stack direction={'column'} spacing={1} data-test={dataTest} {...other}>
      {children}
    </Stack>
  );
}
