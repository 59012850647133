import { Box, alpha, styled } from '@mui/material';

export const Circle = styled(Box)(({ theme }) => ({
  ...theme.typography.subtitle1,
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.main,
  borderRadius: '32px',
  width: '32px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
}));
