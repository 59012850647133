import { Box, Stack, Typography } from '@mui/material';

import image from '../images/iterate.png';
import { Circle } from '../Circle';

export const Iterate = () => (
  <Stack direction="row" spacing={0} alignItems="center">
    <Box width="45%" ml={4}>
      <Stack maxWidth="350px" display="flex" alignItems="flex-start">
        <Circle>4</Circle>
        <Typography variant="h6" color="text.primary">
          Iterate with end user feedback
        </Typography>
        <Box>
          <Typography
            mb={2}
            fontFamily="DM Sans"
            variant="body2"
            color="text.primary"
          >
            Continuously improve with input from experts—your users.
            Automatically collect their evaluations, follow updates to your
            leaderboard and swap models and prompts effortlessly.
          </Typography>
        </Box>
      </Stack>
    </Box>
    <Box width="55%" ml={4}>
      <img
        src={image}
        width="100%"
        height="auto"
        alt="leaderboard comparing prompts results"
      />
    </Box>
  </Stack>
);
