import { gql } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { CUSTOM_ATTRIBUTE_DEFINITION_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeDefinition';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import { ExtractByTypename } from 'shared/utils/types';

import {
  CREATE_OR_UPDATE_CUSTOM_ATTRIBUTE_DEFINITION_QUERY,
  CreateOrUpdateCustomAttributeDefinition,
  CreateOrUpdateCustomAttributeDefinitionVariables,
} from '../graphql';
import { organizationV2CustromAttributesDefinitionFragment } from './graphql-types/useCreateOrUpdateCustomAttributeDefinition.generated';

type Props = {
  onCompleted: () => void;
};

type CustomAttributeDefinitions = ExtractByTypename<
  organizationV2CustromAttributesDefinitionFragment['customAttributeDefinitions'],
  'CustomAttributeDefinitions'
>;

const ORGANIZATION_CUSTOM_ATTRIBUTES_DEFINITION_FRAGMENT = gql`
  fragment organizationV2CustromAttributesDefinitionFragment on OrganizationV2 {
    id
    customAttributeDefinitions {
      ... on CustomAttributeDefinitions {
        customAttributeDefinitions {
          id
          ...CustomAttributeDefinitionData
        }
      }
    }
  }
  ${CUSTOM_ATTRIBUTE_DEFINITION_FRAGMENT}
`;

export const useCreateOrUpdateCustomAttribute = ({ onCompleted }: Props) => {
  const [createOrUpdate, communication] = useCustomMutation<
    CreateOrUpdateCustomAttributeDefinition,
    CreateOrUpdateCustomAttributeDefinitionVariables
  >(
    CREATE_OR_UPDATE_CUSTOM_ATTRIBUTE_DEFINITION_QUERY,
    {
      context: 'creating or updating custom attribute',
    },
    {
      onCompleted,
      update: (cache, { data }) => {
        if (
          isNotError(data?.organizationV2) &&
          isNotError(
            data?.organizationV2.createOrUpdateCustomAttributeDefinition
          ) &&
          data?.organizationV2.createOrUpdateCustomAttributeDefinition !==
            undefined
        ) {
          const definition =
            data.organizationV2.createOrUpdateCustomAttributeDefinition;

          const organization: organizationV2CustromAttributesDefinitionFragment | null =
            cache.readFragment({
              id: `OrganizationV2:${definition.organizationId}`,
              fragment: ORGANIZATION_CUSTOM_ATTRIBUTES_DEFINITION_FRAGMENT,
              fragmentName: 'organizationV2CustromAttributesDefinitionFragment',
            });

          if (
            organization &&
            isNotError(organization.customAttributeDefinitions) &&
            organization.customAttributeDefinitions
              .customAttributeDefinitions !== null &&
            // just update cache if is a new record. Apollo cache binds automatically for existing elements
            !Boolean(
              organization.customAttributeDefinitions.customAttributeDefinitions.find(
                (_definition) => _definition.id === definition.id
              )
            )
          ) {
            const newCustomAttributeDefinitions: CustomAttributeDefinitions['customAttributeDefinitions'] =
              [
                ...organization.customAttributeDefinitions
                  .customAttributeDefinitions,
                definition,
              ];
            cache.writeFragment({
              id: `OrganizationV2:${definition.organizationId}`,
              fragment: ORGANIZATION_CUSTOM_ATTRIBUTES_DEFINITION_FRAGMENT,
              fragmentName: 'organizationV2CustromAttributesDefinitionFragment',
              data: {
                ...organization,
                customAttributeDefinitions: {
                  ...organization.customAttributeDefinitions,
                  customAttributeDefinitions: newCustomAttributeDefinitions,
                },
              },
            });
          }
        }
      },
    }
  );

  return {
    createOrUpdate,
    communication,
  };
};
