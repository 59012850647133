/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import {
  BUILD_OR_ERROR_FRAGMENT,
  UPDATE_REQUEST_FRAGMENT,
} from 'shared/graphql/Deployment/Endpoint/Endpoint';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

export const UPDATE_ENVIRONMENT = gql`
  mutation UpdateEnvironment(
    $workspaceName: String!
    $organizationId: ID
    $endpointId: ID!
    $environmentId: ID!
    $buildId: ID!
    $strategy: EndpointEnvironmentUpdateStrategy
    $canary: CanaryStrategy
    $autoscaling: AutoscalingUpdateConfig
    $resources: ResourcesUpdateConfig
    $env: [EndpointEnvironmentEnvInput!]
    $isolation: EndpointEnvironmentIsolation!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoint(id: $endpointId) {
          ... on Error {
            ...ErrorData
          }
          ... on Endpoint {
            id
            environment(id: $environmentId) {
              id
              update(
                buildId: $buildId
                config: {
                  autoscaling: $autoscaling
                  env: $env
                  resources: $resources
                  strategy: $strategy
                  canary: $canary
                  isolation: $isolation
                }
              ) {
                id
                components {
                  build {
                    ...BuildOrErrorData
                  }
                }
                ...UpdateRequestData
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${BUILD_OR_ERROR_FRAGMENT}
  ${UPDATE_REQUEST_FRAGMENT}
`;
