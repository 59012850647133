import Stack from '@mui/material/Stack';
import { useMemo } from 'react';

import { OwnerType } from 'generated/types';
import {
  RequiredOwner,
  RequiredResource,
  useEditResourceInformation,
} from 'features/editResourceInformation/store/useEditResourceInformation';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import Popup from 'shared/view/elements/Popup/Popup';
import { PopupStateProps } from 'shared/view/elements/Popup/PopupButtons';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { MenuAction } from 'shared/view/elements/ActionsMenu/ActionsMenu';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';

import { resourceTypeToView } from './resourceTypeToView';
import OwnerSelector from './OwnerSelector';

interface Props extends PopupStateProps {
  resource: RequiredResource;
}

type EntityInfoForm = {
  description: string;
  ownerType: RequiredOwner['__typename'];
  ownerId: string;
};

const getFieldName = makeGetFieldName<EntityInfoForm>();

const EditResourceInformation = (props: Props) => {
  const initialValues: EntityInfoForm = useMemo(() => {
    return {
      description: props.resource.description,
      ownerId: props.resource.owner.id,
      ownerType: props.resource.owner.__typename,
    };
  }, [
    props.resource.description,
    props.resource.owner.id,
    props.resource.owner.__typename,
  ]);

  const editResourceInformationApi = useEditResourceInformation(
    props.resource,
    props.onClose
  );

  return (
    <PresetFormik
      initialValues={initialValues}
      onSubmit={(form) => {
        editResourceInformationApi.run({
          id: props.resource.id,
          description:
            props.resource.__typename === 'MonitoredEntity'
              ? undefined
              : form.description,
          ownerInput: {
            id: form.ownerId,
            type: form.ownerType as OwnerType,
          },
        });
      }}
    >
      {({ setFieldValue, values, submitForm }) => (
        <Popup
          {...props}
          title={`Edit ${resourceTypeToView(
            props.resource.__typename
          )} information`}
          buttons={{
            secondaryButtonProps: {
              children: 'Cancel',
              isLoading: false,
              onClick: props.onClose,
            },
            mainButtonProps: {
              type: 'button',
              children: 'Save',
              isLoading: editResourceInformationApi.communication.isRequesting,
              disabled: !props.resource.allowedActions.update,
              onClick: submitForm,
            },
          }}
        >
          <Stack spacing={2}>
            {props.resource.__typename !== 'MonitoredEntity' ? (
              <TextInputField
                name={getFieldName({ description: null })}
                label={`description`}
                multiline={true}
                rows={3}
                hint="Max. 250 characters"
                disabled={!props.resource.allowedActions.update}
              />
            ) : null}

            <OwnerSelector
              ownerId={values.ownerId}
              ownerType={values.ownerType}
              resource={props.resource}
              onChangeOwnerId={(ownerId) =>
                setFieldValue(getFieldName({ ownerId: null }), ownerId)
              }
              onChangeOwnerType={(ownerType) =>
                setFieldValue(getFieldName({ ownerType: null }), ownerType)
              }
            />
          </Stack>
        </Popup>
      )}
    </PresetFormik>
  );
};

export const useEditResourceInformationMenuAction = (
  resource: RequiredResource | undefined | null
): MenuAction | null => {
  const popupManager = usePopupManager();

  return resource
    ? {
        onClick: popupManager.openPopup,
        label: `Edit ${resourceTypeToView(resource.__typename)} information`,
        type: 'button',
        disabled: !resource.allowedActions.update,
        dataTest: 'edit-info-menu-item',
        additionalContent: popupManager.isPopupOpen ? (
          <EditResourceInformation
            resource={resource}
            isOpen={true}
            onClose={popupManager.closePopup}
          />
        ) : null,
      }
    : null;
};
