import { fromPairs, groupBy, toPairs } from 'ramda';

import { Observation } from 'shared/graphql/ExperimentRunDetails/Observation/graphql-types/Observation.generated';
import { ObservationAttribute } from 'shared/graphql/ExperimentRunDetails/Observation/graphql-types/Observation.generated';

export interface IObservationWithExperimentRun extends ObservationAttribute {
  experimentRun: {
    name: string;
    id: string;
  };
}

type IGroupedObservationsByAttributeKey<T> = Record<string, T[]>;

export const groupObservationsByAttributeKey = <T extends ObservationAttribute>(
  observations: T[]
): IGroupedObservationsByAttributeKey<T> => {
  return fromPairs(
    toPairs(groupBy((obs) => obs.attribute.key, observations)).map(
      ([key, groupedObservations]) => [key, groupedObservations]
    )
  );
};

export const hasEpochValues = (observations: Observation[]): boolean => {
  return observations.some(
    ({ epochNumber }) => typeof epochNumber !== 'undefined'
  );
};

export type IObservationWithEpoch<T extends Observation> = T & {
  epochNumber: number;
};

export const isObservationWithEpoch = <T extends Observation>(
  observation: T
): observation is IObservationWithEpoch<T> => {
  return (
    typeof observation.epochNumber === 'number' &&
    !isNaN(observation.epochNumber)
  );
};
