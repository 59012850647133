import { useState, useCallback } from 'react';

import {
  ICommunication,
  requestingCommunication,
  successfulCommunication,
  makeErrorCommunication,
  initialCommunication,
} from 'shared/utils/redux/communication';
import { ArgumentTypes, PromiseValue } from 'shared/utils/types';
import normalizeError from 'shared/utils/normalizeError';
import { AppError } from 'shared/models/Error';

export function useLazyRestQuery<T extends (...args: any[]) => Promise<any>>(
  api: T,
  handlers: {
    onSuccess?: (
      data: PromiseValue<ReturnType<T>>,
      requestArgs: ArgumentTypes<T>
    ) => void;
    onError?: (appError: AppError) => void;
  } = {}
): {
  communication: ICommunication<AppError>;
  data: PromiseValue<ReturnType<T>> | null;
  fetch: (...args: ArgumentTypes<T>) => Promise<void>;
} {
  const [communication, setCommunication] =
    useState<ICommunication>(initialCommunication);
  const [data, setData] = useState<PromiseValue<ReturnType<T>> | null>(null);

  const { onSuccess, onError } = handlers;

  const callApi = useCallback(
    (...args: ArgumentTypes<T>) => {
      return api(...args)
        .then((result) => {
          setData(result);
          setCommunication(successfulCommunication);
          if (onSuccess) {
            onSuccess(result, args);
          }
        })
        .catch((error) => {
          const normalizedError = normalizeError(error);
          setCommunication(makeErrorCommunication(normalizedError));
          if (onError) {
            onError(normalizedError);
          }
        });
    },
    [api, onSuccess, onError]
  );

  const fetch = useCallback(
    (...args: ArgumentTypes<T>) => {
      setCommunication(requestingCommunication);
      return callApi(...args);
    },
    [callApi]
  );

  return { communication, data, fetch };
}
