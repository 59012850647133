import { scaleBand, scaleLinear } from '@visx/scale';
import { Group } from '@visx/group';
import { useMemo } from 'react';
import { uniq } from 'ramda';
import { GridColumns, GridRows } from '@visx/grid';
import { AxisLeft } from '@visx/axis';
import { ScaleBand } from 'd3-scale';
import { useTheme } from '@mui/material';

import { getChartSizes } from 'shared/utils/charts/chartSizes';
import { DefaultTruncatedTickComponent } from 'shared/view/charts/shared/TickComponentWithTruncation/TickComponentWithTruncation';
import { tickLabelFontSize } from 'shared/view/charts/Axis/defaultStyles';
import { useZoom } from 'shared/view/charts/shared/zoom/useZoom';
import PlotWithBrush from 'shared/view/charts/MultiLineChart/PlotWithBrush';

import AxisBottomWithRotatedTicks from '../../Axis/AxisBottomWithRotatedTicks/AxisBottomWithRotatedTicks';
import ChartSVG from '../../shared/ChartSVG/ChartSVG';
import { defaultChartMargin } from '../../shared/margin';
import { ViolinChartProps } from '../shared/types';
import {
  useViolinChartTooltipWithView,
  ViolinChartTooltipData,
} from '../shared/tooltip';

interface ViolinBarChartProps<Meta>
  extends ViolinChartProps<Meta, ScaleBand<string>> {
  renderTooltipContent: (
    tooltipData: ViolinChartTooltipData<Meta>
  ) => React.ReactNode;
}

type SelectedDomain = {
  x: string[];
  y: string[];
};

function ViolinBarChart<Meta>(props: ViolinBarChartProps<Meta>) {
  const { selectedDomain, ...zoomApi } = useZoom<SelectedDomain>({
    getDomain: ({ yValues, xValues }) => ({
      x: xValues || [],
      y: yValues || [],
    }),
    onSetReset: props.onSetReset,
  });

  const filteredData = selectedDomain
    ? props.data
        .filter((d) => selectedDomain.x.some((x) => d.key === x))
        .map((d) => {
          return {
            ...d,
            items: d.items.filter((item) =>
              selectedDomain.y.includes(item.key)
            ),
          };
        })
    : props.data;

  const chartSizes = getChartSizes({
    margin: props.margin ?? defaultChartMargin,
    width: props.width,
    height: props.height,
  });

  const xScale = useMemo(
    () =>
      scaleBand({
        domain: selectedDomain?.x ?? props.data.map((d) => d.key),
        range: [0, chartSizes.innerWidth],
        padding: 0.2,
      }),
    [selectedDomain, chartSizes.innerWidth, props.data]
  );
  const xScaleBandwidth = xScale.bandwidth();

  const yScale = useMemo(
    () =>
      scaleBand({
        domain:
          selectedDomain?.y ??
          uniq(props.data.map((d) => d.items.map((item) => item.key)).flat()),
        range: [chartSizes.innerHeight, 0],
      }),
    [chartSizes.innerHeight, props.data, selectedDomain?.y]
  );
  const yScaleBandwidth = yScale.bandwidth();

  const valueScales = useMemo(
    () =>
      filteredData
        .map(({ items }) => {
          return Math.max(...items.map((d) => d.value)) || 1;
        })
        .map((max) =>
          scaleLinear({
            domain: [0, max],
            range: [0, xScaleBandwidth / 2],
          })
        ),
    [filteredData, xScaleBandwidth]
  );

  const tooltipApi = useViolinChartTooltipWithView({
    chartSizes,
    data: props.data,
    renderTooltipContent: props.renderTooltipContent,
    onTooltipAreaClick: props.onTooltipAreaClick,
    xScale,
  });

  const theme = useTheme();

  return (
    <ChartSVG
      width={chartSizes.width}
      height={chartSizes.height}
      margin={chartSizes.margin}
      isNilData={props.data.every((d) => d.items.length === 0)}
      outSvgContent={tooltipApi.tooltipView}
    >
      <AxisLeft
        scale={yScale}
        stroke={theme.palette.charts.accentColor}
        tickStroke={theme.palette.charts.accentColor}
        hideTicks={true}
        tickComponent={(tickProps) => (
          <DefaultTruncatedTickComponent
            {...tickProps}
            fontSize={tickLabelFontSize}
            fill={theme.palette.text.primary}
          />
        )}
      />
      <GridRows
        scale={yScale}
        width={chartSizes.innerWidth}
        stroke={theme.palette.charts.accentColor}
        strokeWidth={0.5}
        offset={yScaleBandwidth}
        pointerEvents="none"
      />

      <AxisBottomWithRotatedTicks
        scale={xScale}
        top={chartSizes.innerHeight}
        tickStroke={theme.palette.charts.accentColor}
      />
      <GridColumns
        scale={xScale}
        height={chartSizes.innerHeight}
        stroke={theme.palette.charts.accentColor}
        strokeWidth={1}
        offset={xScaleBandwidth / 2}
        pointerEvents="none"
      />

      {props.renderAdditionalContent?.({
        chartSizes,
        xScale,
        yScale,
        displayedData: filteredData,
      })}

      <PlotWithBrush
        {...zoomApi}
        tooltipApi={tooltipApi}
        chartSizes={chartSizes}
        xScale={xScale}
        yScale={yScale}
      >
        {filteredData.map((d, i) => (
          <Group
            key={`${d.key}${i}`}
            left={xScale(d.key)}
            data-test="violin-bar-chart-group"
          >
            {d.items.map((item) => {
              const width = valueScales[i](item.value);
              const height = yScaleBandwidth * 0.85;
              const yPadding = yScaleBandwidth - height;
              const color = props.getColorByData(d);

              return (
                <rect
                  data-test="violin-bar-chart-group-item"
                  key={item.key}
                  x={xScaleBandwidth / 2 - width}
                  y={(yScale(item.key) ?? 0) + yPadding / 2}
                  strokeWidth={1}
                  stroke={color}
                  fill={color}
                  fillOpacity={0.5}
                  width={width}
                  height={height}
                />
              );
            })}
          </Group>
        ))}
      </PlotWithBrush>
    </ChartSVG>
  );
}

export default ViolinBarChart;
