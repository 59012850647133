import React from 'react';

import routes from 'shared/routes';
import { WorkspaceData } from 'shared/graphql/Workspace/graphql-types/Workspace.generated';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';

interface ILocalProps {
  experimentRun: {
    name: string;
    id: string;
    project: {
      id: string;
      name: string;
      workspace: WorkspaceData;
    };
  };
}

const ExperimentRunTitle: React.FC<React.PropsWithChildren<ILocalProps>> = ({
  experimentRun,
}) => {
  return (
    <InlineLink
      to={routes.experimentRun.getRedirectPath({
        experimentRunId: experimentRun.id,
        projectId: experimentRun.project.id,
        workspaceName: experimentRun.project.workspace.name,
      })}
      dataTest="experiment-run-title-link"
    >
      {`${experimentRun.project.name} / ${experimentRun.name}`}
    </InlineLink>
  );
};

export default ExperimentRunTitle;
