import { Typography, Box } from '@mui/material';

import InlineLink from 'shared/view/elements/InlineLink/InlineLink';

export const CopyRightFooter = () => (
  <Box display="flex" width="fit-content" alignItems="center">
    <Typography mr="20px" variant="overline" color="text.secondary">
      Copyright © {new Date().getFullYear()} Verta
    </Typography>
    <InlineLink
      to="https://www.verta.ai/privacy-policy"
      variant="overline"
      isExternal
      withoutArrow
      sx={{ color: 'text.secondary' }}
    >
      Privacy Policy
    </InlineLink>

    <Typography mx="8px" variant="overline" color="text.secondary">
      |
    </Typography>

    <InlineLink
      to="https://www.verta.ai/security"
      variant="overline"
      isExternal
      withoutArrow
      sx={{ color: 'text.secondary' }}
    >
      Security
    </InlineLink>
  </Box>
);
