const localStorageKey: string = 'high-level-page-recent-searches';
export const getRecentSearchesFromLocalStorage = (): string[] => {
  const value = localStorage.getItem(localStorageKey);
  if (!value) {
    return [];
  } else {
    try {
      return (JSON.parse(value) as string[] | undefined) || [];
    } catch {
      return [];
    }
  }
};
export const updateRecentSearchesToLocalStorage = (
  f: (recentSearches: string[]) => string[]
) => {
  localStorage[localStorageKey] = JSON.stringify(
    f(getRecentSearchesFromLocalStorage())
  );
};
