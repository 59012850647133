import { convertServerEnvironmentBlob } from 'services/serverModel/Versioning/RepositoryData/Blob/EnvironmentBlob';
import { IEnvironmentBlob } from 'shared/models/Versioning/Blob/EnvironmentBlob/index';

export const convertEnvironmentBlob = (
  environmentBlob: string | null
): IEnvironmentBlob | null => {
  if (environmentBlob) {
    const environmentBlobJson = JSON.parse(environmentBlob) as Record<any, any>;
    return Object.keys(environmentBlobJson).length > 0
      ? convertServerEnvironmentBlob(environmentBlobJson)
      : null;
  }
  return null;
};
