import matchBy from 'shared/utils/matchBy';
import { IPrimitiveKeyValue } from 'shared/models/PrimitiveKeyValue';
import { PrimitiveKeyValueData } from 'shared/graphql/PrimitiveKeyValue/graphql-types/PrimitiveKeyValue.generated';

export const convertGraphqlPrimitiveKeyValue = (
  attribute: PrimitiveKeyValueData
) => {
  return matchBy(
    attribute,
    '__typename'
  )<IPrimitiveKeyValue>({
    FloatKeyValue: ({ floatValue, key }) => ({ key, value: floatValue }),
    StringKeyValue: ({ stringValue, key }) => ({ key, value: stringValue }),
    BoolKeyValue: ({ value, key }) => ({ key, value }),
  });
};
