import { gql } from '@apollo/client';

import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/useUpdateWorkspace.generated';

export { Types };

export const QUERY = gql`
  mutation UpdateWorkspaceToOrganizationV2(
    $organizationId: ID!
    $workspaceId: ID!
    $input: WorkspaceV2Input!
  ) {
    organizationV2(id: $organizationId) {
      ... on OrganizationV2 {
        id
        workspace(id: $workspaceId) {
          id
          update(input: $input) {
            orgId
            id
            name
            description
            createdTimestamp
            updatedTimestamp
            builtIn
            namespace
            allowedActions {
              ...AllowedActionsData
            }
            permissions {
              role {
                id
                name
                description
                builtIn
              }
              group {
                id
                name
                description
                builtIn
              }
            }
          }
        }
      }
    }
  }
  ${ALLOWED_ACTIONS_FRAGMENT}
`;

export const useUpdateWorkspace = (onCompleted: () => void) => {
  const [run, communication] = useCustomMutation<
    Types.UpdateWorkspaceToOrganizationV2,
    Types.UpdateWorkspaceToOrganizationV2Variables
  >(
    QUERY,
    {
      context: 'updating workspace',
    },
    { onCompleted }
  );

  return {
    run,
    communication,
  };
};
