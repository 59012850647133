import { Box, Skeleton, Stack, Typography, styled } from '@mui/material';

interface Props {
  variant?: 'square' | 'slim';
  icon?: JSX.Element;
  iconBackground?: string;
  title?: string;
  value?: string;
  valueSymbol?: string;
  isLoading?: boolean;
}

const commonCardStyling = {
  borderRadius: 4,
  border: '1px solid #C4C4C4',
};

const SquareSummaryCard = styled(Box)(({ theme }) => ({
  ...commonCardStyling,
  padding: theme.spacing(4, 3),
  minWidth: '250px',
  height: '263px',
}));

const SlimSummaryCard = styled(Box)(({ theme }) => ({
  ...commonCardStyling,
  padding: theme.spacing(3, 4),
  minWidth: '300px',
  height: '142px',
}));

const IconSquareWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: theme.palette.additionalBackground.codeBlock,
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(4),
  width: '48px',
  height: '48px',
}));

const IconSlimWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  backgroundColor: theme.palette.additionalBackground.codeBlock,
  width: '28px',
  height: '28px',
}));

const abbreviateValue = (value?: string) => {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value ? Number(value) : 0);
};

const SummaryCard = (props: Props) => {
  const {
    variant = 'square',
    icon,
    value,
    valueSymbol,
    title,
    iconBackground,
    isLoading,
  } = props;
  return variant === 'square' ? (
    <SquareSummaryCard>
      <IconSquareWrapper sx={{ backgroundColor: iconBackground }}>
        {icon}
      </IconSquareWrapper>
      {isLoading ? (
        <Skeleton height={70} width={100} />
      ) : (
        <Typography variant="h2">
          {valueSymbol && (
            <Typography
              variant="h2"
              display="inline"
              color="text.disabled"
              mr={1.25}
            >
              {valueSymbol}
            </Typography>
          )}
          {abbreviateValue(value)}
        </Typography>
      )}
      <Typography variant="subtitle1" color="text.secondary">
        {isLoading ? <Skeleton width={200} /> : title}
      </Typography>
    </SquareSummaryCard>
  ) : (
    <SlimSummaryCard>
      <Stack direction="row" spacing={2}>
        <IconSlimWrapper sx={{ backgroundColor: iconBackground }}>
          {icon}
        </IconSlimWrapper>
        <Typography variant="subtitle1" color="text.secondary">
          {isLoading ? <Skeleton width={200} /> : title}
        </Typography>
      </Stack>
      {isLoading ? (
        <Skeleton height={70} width={100} />
      ) : (
        <Typography variant="h3" ml={5.5}>
          {valueSymbol && (
            <Typography
              variant="h3"
              display="inline"
              color="text.disabled"
              mr={1.25}
            >
              {valueSymbol}
            </Typography>
          )}
          {abbreviateValue(value)}
        </Typography>
      )}
    </SlimSummaryCard>
  );
};

export default SummaryCard;
