import { isNil } from 'lodash';

import { IResourcesGpu } from 'shared/graphql/Deployment/Endpoint/MachineConfig/MachineConfigInfo';
import isNotNil, { getValueOrDefault } from 'shared/utils/isNotNill';
import matchType from 'shared/utils/matchType';
import { makeError, makeSuccess, Result } from 'shared/utils/result';

export type MemoryMeasure = 'Mi' | 'Gi';

export interface IResourcesMemory {
  amount: number;
  measure: MemoryMeasure;
}

export type IResources = {
  cpu: number;
  memory: IResourcesMemory;
  isGpuEnabled?: boolean;
  gpu: { number: number | null } | null;
};
export const gpuInfo = {
  convert: (value?: boolean | null) =>
    isNotNil(value) && value ? { number: 1 } : null,
  format: (value?: IResourcesGpu | null) =>
    isNotNil(value?.number) ? 'Enabled' : 'Disabled',
};

export const cpuInfo = {
  min: 0,
  max: 16,
  validate: (value: number, maxValue?: number) => {
    if (isNaN(Number(value))) {
      return 'CPU value should be a number';
    }

    if (value < cpuInfo.min || value > (maxValue ?? cpuInfo.max)) {
      return `CPU value should be between 0 and ${maxValue ?? cpuInfo.max}`;
    }
  },
  convert: (value: number | null) => (isNotNil(value) ? value * 1000 : 0),
  format: (value?: number | null): Result<number, string> => {
    if (isNotNil(value)) {
      return makeSuccess(value / 1000);
    }
    return makeError('invalid cpu value');
  },
};

export const gpuQuantityInfo = {
  min: 1,
  max: 4,
  validate: (value: number) => {
    if (isNaN(Number(value))) {
      return 'GPU value should be a number';
    }

    if (value < cpuInfo.min || value > cpuInfo.max) {
      return 'GPU value should be between 1 and 4';
    }
  },
  convert: (value: number | null) => (isNotNil(value) ? value : null),
  format: (value: number | null) => (isNotNil(value) ? value : null),
};

export const memoryInfo = {
  minMi: 6,
  maxMi: 64000,
  validate: (memory: IResourcesMemory, maxValue?: number | null) => {
    const calculatedAmount =
      memory.measure === 'Mi' ? memory.amount : memory.amount * 1000;
    const maxAmount = memoryInfo.format(
      `${getValueOrDefault(memoryInfo.maxMi, maxValue)}Mi`
    );
    if (calculatedAmount > (maxValue ?? memoryInfo.maxMi)) {
      return `Maximum allowed amount is ${maxAmount}`;
    }
    if (calculatedAmount < 0) {
      return `Minimum allowed amount is zero.`;
    }
  },
  format: (str: string | null) => {
    if (isNil(str)) {
      return '-';
    }
    const value = Number(str.match(/\d/g)?.join(''));
    const measure = getFormattedMeasureFromMemoryValueString(str);
    return `${value}${measure}`;
  },
  convertToInt: (str?: string | null): number | null => {
    if (isNil(str)) {
      return null;
    }
    const multiplier: number = str.endsWith('Gi') ? 1000 : 1;
    const value = Number(str.match(/\d/g)?.join(''));
    return value * multiplier;
  },
  convert: (value: IResourcesMemory | null) =>
    isNotNil(value) ? `${value.amount}${value.measure}` : '-',
  parse: (value: string | null): Result<IResourcesMemory, string> => {
    if (value !== '') {
      const res = value?.match(/^(\d+)(Gi|Mi)$/);
      if (res) {
        const [_, amountStr, measureStr] = res;
        const amount = parseInt(amountStr);
        const measure =
          measureStr === 'Gi' ? 'Gi' : measureStr === 'Mi' ? 'Mi' : undefined;
        if (!isNaN(amount) && measure) {
          return makeSuccess({ amount, measure });
        }
      }
    }
    return makeError('invalid memory');
  },
};

const getFormattedMeasureFromMemoryValueString = (value: string) => {
  const measure = value.substring(value.length - 2, value.length) as
    | 'Mi'
    | 'Gi';
  return matchType(
    {
      Gi: () => 'GB',
      Mi: () => 'MB',
    },
    measure
  );
};
