import { Stack, Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';
import Persona1 from 'features/homepage/images/persona1.svg';
import Persona2 from 'features/homepage/images/persona2.svg';
import Persona3 from 'features/homepage/images/persona3.svg';
import Persona4 from 'features/homepage/images/persona4.svg';

import { InformationalTile } from '../../shared/InformationalTile';

const cards = [
  {
    image: Persona1,
    title: 'Invite your Machine Learning and Data Scientists',
    description:
      "Encourage them to register all the models they've created into the catalog.\n\nEmphasize how this can help them discover their peers' prior work and save time in the future.",
  },
  {
    image: Persona2,
    title: 'Invite your leaders and product managers',
    description:
      'Show them how they can stay up-to-date on models and projects with Verta.\n\nEncourage them to define the release process, checklists, and attributes for tracking.',
  },
  {
    image: Persona3,
    title: 'Invite your engineering team',
    description:
      "Show them how easily they can access integration information on any model and update it when models are updated.\n\nEncourage them to refer to the catalog before tapping anyone's shoulder.",
  },
  {
    image: Persona4,
    title: 'Invite your governance, ethics and risks teams',
    description:
      'Demonstrate how your organization adheres to compliance and where they can access reports for themselves.',
  },
];

export const InviteYourTeam = (props: WizardStepContentProps) => {
  return (
    <WizardStepContent
      {...props}
      buttons={{ next: { children: 'Complete tutorial' } }}
    >
      <Typography variant="subtitle1" mb={2}>
        Let's invite your team now
      </Typography>
      <Typography variant="body2" mb={3}>
        Ready to see it in action? Give it a try!
      </Typography>
      <Stack spacing={2}>
        {cards.map((card, index) => (
          <InformationalTile {...card} key={`${card.title}-${index}`} />
        ))}
      </Stack>
    </WizardStepContent>
  );
};
