import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Flags } from 'shared/models/Flags';
import { selectFlags } from 'features/flags';
import { RegisteredModelVersionLockLevel } from 'generated/types';
import LockLevelForm from 'shared/view/domain/Registry/ModelVersion/LockLevel/LockLevelForm/LockLevelForm';
import {
  ILockLevelForm,
  ILockLevelFormDeps,
} from 'shared/view/domain/Registry/ModelVersion/LockLevel/LockLevelForm/LockLevelFormModel';
import { RegisteredModelVersionLockLevelWithoutUnknown } from 'shared/models/Registry/RegisteredModelVersion/LockLevel';

export interface IVersionCreationForm extends ILockLevelForm {
  description: string;
  name: string;
  labels: string[];
  experimentRunId: string;
}

const getFeatureFlagLockLevel = (
  isDefaultRegistryLockedEnabled: boolean,
  defaultRegistryLockedContent: Flags['defaultRegistryLockedContent']
) => {
  if (isDefaultRegistryLockedEnabled) {
    switch (defaultRegistryLockedContent) {
      case 'open':
        return RegisteredModelVersionLockLevel.OPEN;
      case 'closed':
        return RegisteredModelVersionLockLevel.CLOSED;
      case 'redact':
        return RegisteredModelVersionLockLevel.REDACT;
      default:
        return undefined;
    }
  }
};

const FeatureLockLevelSelect: FC<
  React.PropsWithChildren<{
    fieldName: string;
    setFieldValue: (
      field: string,
      value: RegisteredModelVersionLockLevelWithoutUnknown,
      shouldValidate?: boolean | undefined
    ) => void;
    value: RegisteredModelVersionLockLevelWithoutUnknown;
    allowedActions: ILockLevelFormDeps['allowedActions'];
  }>
> = ({ fieldName, allowedActions, setFieldValue, value }) => {
  const onChange = useCallback(
    (lockLevel: RegisteredModelVersionLockLevelWithoutUnknown) => {
      setFieldValue(fieldName, lockLevel);
    },
    [setFieldValue, fieldName]
  );
  const { isDefaultRegistryLockedEnabled } = useSelector(selectFlags);
  const { defaultRegistryLockedContent } = useSelector(selectFlags);
  // doesn't need memoization
  const lockLevel = getFeatureFlagLockLevel(
    isDefaultRegistryLockedEnabled,
    defaultRegistryLockedContent
  );
  return (
    <LockLevelForm
      allowedActions={allowedActions}
      disabled={isDefaultRegistryLockedEnabled}
      lockLevel={lockLevel ?? value}
      onChange={onChange}
    />
  );
};

export default FeatureLockLevelSelect;
