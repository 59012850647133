import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';

import ChartsPage from './ChartsPage/ChartsPage';
import ExperimentRunsComparePage from './ExperimentRunsComparePage/ExperimentRunsComparePage';
import ExperimentRunsPage from './ExperimentRunsPage/ExperimentRunsPage';
import ExperimentsPage from './ExperimentsPage/ExperimentsPage';
import ExperimentRunPage from './ExperimentRunPage/ExperimentRunPage';
import ProjectOverviewPage from './ProjectOverviewPage/ProjectOverviewPage';

export const projectDetailsPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.projectOverview.getPath(),
      element: <ProjectOverviewPage />,
    },
    {
      path: routes.charts.getPath(),
      element: <ChartsPage />,
    },
    {
      path: routes.experimentRuns.getPath(),
      element: <ExperimentRunsPage />,
    },
    {
      path: routes.experiments.getPath(),
      element: <ExperimentsPage />,
    },
    {
      path: routes.experimentRun.getPath(),
      element: <ExperimentRunPage />,
    },
    {
      path: routes.experimentRunsCompare.getPath(),
      element: <ExperimentRunsComparePage />,
    },
  ],
};
