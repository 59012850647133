import { Box, Paper, Grid, styled, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { useAnalyticsTrack } from 'shared/hooks';
import { useToast } from 'features/toast/store/hooks';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { selectCurrentWorkspace } from 'features/workspaces';
import { selectCurrentUserOrThrowError } from 'features/user';
import { selectFlags } from 'features/flags';
import { Flags } from 'shared/models/Flags';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

import { RegisteredModelSummary } from './RegisteredModelSummary';
import { UsersSummary } from './UsersSummary';
import { EndpointsSummary } from './EndpointsSummary';
import { PredictionsSummary } from './PredictionsSummary';

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  height: 'fit-content',
  paddingTop: theme.spacing(3),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(4),
}));

export const PlatformMonitoringWidget = () => {
  const [trackEvent] = useAnalyticsTrack();
  const currentUser = useSelector(selectCurrentUserOrThrowError);
  const workspace = useSelector(selectCurrentWorkspace);
  const organizationId = useCurrentOrganizationV2();
  const toast = useToast();
  const flags = useSelector(selectFlags);

  const isFreetier = getIsFreetierStatus(flags);

  const handleRequestContact = (value?: string) => {
    trackEvent({
      input: {
        userId: currentUser.id,
        event: 'homepage.request_contact',
        properties: JSON.stringify({
          email: currentUser.email,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          company: currentUser.email,
          position: currentUser.jobTitle,
          context: 'request made via homepage "platform monitoring" widget',
          interest: value,
          workspaceName: workspace.name,
        }),
      },
    });

    toast(
      'Success:  Your request has been registered! Verta will reach out soon.',
      'success'
    );
  };

  return (
    <StyledPaper>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        mb={2}
      >
        <Typography variant="h6">Platform Monitoring</Typography>
        <Typography variant="body2">
          You are currently on Verta's{' '}
          {isFreetier ? (
            <>
              <strong>Free Tier</strong>. Want to upgrade?{' '}
              <InlineLink onClick={() => handleRequestContact()} simple to="#">
                Click here.
              </InlineLink>
            </>
          ) : (
            <>
              <strong>Standard Tier</strong>.
            </>
          )}
        </Typography>
      </Box>
      <Grid container spacing={3} height="100%">
        <Grid item xxs={3}>
          <RegisteredModelSummary
            workspaceName={workspace.name}
            organizationId={organizationId}
            isFreetier={isFreetier}
          />
        </Grid>
        <Grid item xxs={3}>
          <EndpointsSummary
            workspaceName={workspace.name}
            organizationId={organizationId}
            isFreetier={isFreetier}
            handleRequestContact={handleRequestContact}
          />
        </Grid>
        <Grid item xxs={3}>
          <PredictionsSummary
            isFreetier={isFreetier}
            handleRequestContact={handleRequestContact}
          />
        </Grid>
        <Grid item xxs={3}>
          <UsersSummary
            organizationId={organizationId}
            isFreetier={isFreetier}
          />
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

const getIsFreetierStatus = (flags: Flags) => {
  if (!flags.isEnableFreeTier) return false;

  const { deployment, monitoring, isEnableRegistry } = flags;

  //  "Verta's Standard Tier." https://www.verta.ai/pricing

  return !(deployment.isEnable && monitoring.isEnable && isEnableRegistry);
};
