import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentWorkspaceName } from 'features/workspaces';
import { useToastCommunicationErrorWatcher } from 'shared/view/elements/Notification/Notification';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';

import VersionField from './VersionField/VersionField';
import RegisteredModelField from './RegisteredModelField/RegisteredModelField';
import useModelVersionsForBuild from './useModelVersionsForBuild/useModelVersionsForBuild';
import useRegisteredModelsForBuild from './useRegisteredModelsForBuild/useRegisteredModelsForBuild';

interface ILocalProps {
  registeredModelId: string | undefined;
  modelVersionId: string | undefined;
  changeRegisteredModelId: (id: string | undefined) => void;
  changeModelVersionId: (id: string | undefined) => void;
  organizationId: string | undefined;
}

const RegisteredModelWithVersionFields: React.FC<
  React.PropsWithChildren<ILocalProps>
> = ({
  modelVersionId,
  changeModelVersionId,
  changeRegisteredModelId,
  registeredModelId,
  organizationId,
}) => {
  const workspaceName = useSelector(selectCurrentWorkspaceName);

  const { registeredModels, loadingRegisteredModels } =
    useRegisteredModelsForBuild({
      workspaceName,
      organizationId,
    });
  useToastCommunicationErrorWatcher(loadingRegisteredModels, {
    context: 'loading registered model',
  });

  const { modelVersions, loadingModelVersions } = useModelVersionsForBuild(
    registeredModelId ? { registeredModelId } : undefined
  );

  const onChangeRegisteredModelId = useCallback(
    (id: string) => {
      changeModelVersionId(undefined);
      changeRegisteredModelId(id);
    },
    [changeRegisteredModelId, changeModelVersionId]
  );

  return (
    <>
      {isNotNullableRestrictedGraphqlError(registeredModels) ? (
        <RegisteredModelField
          registeredModels={registeredModels}
          selectedModelId={registeredModelId}
          isLoading={loadingModelVersions.isRequesting}
          changeSelectedModelId={onChangeRegisteredModelId}
        />
      ) : null}
      {registeredModelId &&
      isNotNullableRestrictedGraphqlError(modelVersions) ? (
        <VersionField
          versionId={modelVersionId}
          changeVersionId={changeModelVersionId}
          modelVersions={modelVersions}
          isLoading={loadingModelVersions.isRequesting}
        />
      ) : null}
    </>
  );
};

export default RegisteredModelWithVersionFields;
