import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import isNotNil from 'shared/utils/isNotNill';

import InfoIcon from '../../InfoIcon/InfoIcon';

export type SectionHeaderProps = {
  label: string;
  rightContent?: React.ReactNode;
  info?: string;
  contextualHelp?: string;
};

export default function SectionHeader(props: SectionHeaderProps) {
  return (
    <Stack direction={'column'} style={{ width: 'unset' }}>
      <Stack direction={'row'} alignItems={'center'}>
        <Typography variant={'h6'} color="text.primary">
          {props.label}
        </Typography>

        {props.info ? <InfoIcon tooltip={props.info} /> : null}
        {props.contextualHelp ? (
          <InfoIcon tooltip={props.contextualHelp} tooltipPlacement="bottom" />
        ) : null}

        {isNotNil(props.rightContent) ? (
          <div style={{ marginLeft: 'auto' }}>{props.rightContent}</div>
        ) : null}
      </Stack>
    </Stack>
  );
}
