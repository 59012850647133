import { styled } from '@mui/material';

const FiltersPopoverContentWrapper = styled('div')(({ theme }) => ({
  width: '660px',
  minHeight: '167px',
  backgroundColor: theme.palette.background.level2,
  boxShadow:
    '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
  padding: '22px 19px',
  display: 'flex',
  flexDirection: 'column',
}));

export default FiltersPopoverContentWrapper;
