import { Box, Grid } from '@mui/material';

import { Evaluation } from '../../hooks/useEvaluationSummary';
import { Counter } from './EvaluationCounter';
import { ResultCards } from './ResultCards';
import { LabelDistributionChart } from './LabelDistributionChart';

type Props = Pick<
  Evaluation,
  'approvedRows' | 'rejectedRows' | 'rows' | 'labelsDistribution'
> & {
  startEvaluation: () => void;
};

export const SummarySection = (props: Props) => {
  const {
    rows,
    approvedRows,
    rejectedRows,
    labelsDistribution,
    startEvaluation,
  } = props;
  return (
    <Box height="308px">
      <Grid container spacing={3} sx={{ height: '308px' }}>
        <Grid item xxs={6}>
          <LabelDistributionChart labelsDistribution={labelsDistribution} />
        </Grid>
        <Grid item xxs={3} sx={{ height: '100%' }}>
          <Counter
            total={rows.length}
            done={approvedRows.length + rejectedRows.length}
            startEvaluation={startEvaluation}
          />
        </Grid>
        <Grid item xxs={3}>
          <ResultCards
            total={rows.length}
            approved={approvedRows.length}
            rejected={rejectedRows.length}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
