import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import useEndpointBuilds from 'features/deployment/canary/endpointBuilds/store/useEndpointBuilds/useEndpointBuilds';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

import EndpointDetailsLayout from '../shared/EndpointDetailsLayout/EndpointDetailsLayout';

const EndpointBuildsView = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'EndpointBuildsView' */ 'features/deployment/canary/endpointBuilds/view/EndpointBuilds/EndpointBuilds'
    )
);

const EndpointBuildsPage = () => {
  const params = useRouteParams(routes.endpointBuilds);
  const organizationId = useCurrentOrganizationV2();

  const { data, communication } = useEndpointBuilds({
    endpointId: params.endpointId,
    workspaceName: params.workspaceName,
    organizationId,
  });

  return (
    <EndpointDetailsLayout
      data={data}
      endpointId={params.endpointId}
      communication={communication}
    >
      {(loaded) => <EndpointBuildsView endpoint={loaded.endpoint} />}
    </EndpointDetailsLayout>
  );
};

export default EndpointBuildsPage;
