import { gql } from '@apollo/client';

import { AlertHistoryItem } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertHistoryItem';
import parseGraphqlDate from 'shared/utils/graphql/parseGraphqlDate';

import { AlertHistoryItemFragment } from './graphql-types/AlertHistoryItem.generated';
import { ALERTER_FRAGMENT, convertAlerter } from './Alerter';

export const ALERT_HISTORY_ITEM_FRAGMENT = gql`
  fragment AlertHistoryItemFragment on AlertHistoryItem {
    eventTime
    status
    alertSnapshot {
      aggregationWindow
      evaluationFrequency
      alerter {
        ...AlerterFragment
      }
    }
  }
  ${ALERTER_FRAGMENT}
`;

export const convertAlertHistoryItem = (
  data: AlertHistoryItemFragment
): AlertHistoryItem => {
  return {
    status: data.status,
    time: parseGraphqlDate(data.eventTime),
    alert: {
      ...data.alertSnapshot,
      alerter: convertAlerter(data.alertSnapshot.alerter),
    },
  };
};
