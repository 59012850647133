import { FC } from 'react';
import { useField } from 'formik';

import RadioButtonsField from 'shared/view/formComponents/formikFields/RadioButtonsField/RadioButtonsField';
import { EndpointEnvironmentIsolation } from 'generated/types';

import { MachineConfigSettings } from '../settings';
import { getMachineConfigSettingsFieldName } from '../settings/getMachineConfigSettingsFieldName';

const PerformanceSection: FC<React.PropsWithChildren<unknown>> = () => {
  const [{ value: performance }] = useField<MachineConfigSettings['isolation']>(
    getMachineConfigSettingsFieldName({ isolation: null })
  );

  return (
    <RadioButtonsField
      name={getMachineConfigSettingsFieldName({ isolation: null })}
      value={performance}
      options={[
        {
          label: 'Shared resource',
          value: EndpointEnvironmentIsolation.SHARED,
          name: EndpointEnvironmentIsolation.SHARED,
        },
        {
          label: 'Dedicated resource',
          value: EndpointEnvironmentIsolation.ISOLATED,
          name: EndpointEnvironmentIsolation.ISOLATED,
        },
      ]}
    />
  );
};

export default PerformanceSection;
