import React from 'react';
import { Field, FieldProps } from 'formik';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { gpuQuantityInfo } from 'shared/models/Deployment/canary/EndpointMachineConfiguration/Resources';
import NumberInput from 'shared/view/elements/TextInput/NumberInput';

import { getMachineConfigSettingsFieldName } from '../../settings/getMachineConfigSettingsFieldName';

const GpuQuantityField: React.FC<
  React.PropsWithChildren<{
    disabled: boolean;
  }>
> = ({ disabled }) => {
  const name = getMachineConfigSettingsFieldName({
    resources: { gpu: { number: null } },
  });

  return (
    <Field name={name} validate={gpuQuantityInfo.validate}>
      {({ meta, field, form }: FieldProps<number>) => {
        return (
          <Stack
            direction={'row'}
            spacing={2}
            sx={{ width: '60%' }}
            alignItems={'flex-start'}
          >
            <Box sx={{ width: 150 }}>
              <NumberInput
                label="Cores"
                name={name}
                onChange={(newValue) => form.setFieldValue(name, newValue)}
                onBlur={field.onBlur}
                meta={meta}
                value={meta.value}
                min={gpuQuantityInfo.min}
                max={gpuQuantityInfo.max}
                step={1}
                disabled={disabled}
              />
            </Box>
          </Stack>
        );
      }}
    </Field>
  );
};

export default GpuQuantityField;
