import { FC, MouseEvent } from 'react';
import { IconButton, Theme, SxProps } from '@mui/material';

import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { IconAwesomeProps } from 'shared/view/elements/IconAwesome/IconAwesomeProps';
import { useIconClickable } from 'shared/view/elements/IconAwesome/hooks/useIconClickable';

export interface IconAwesomeClickableProps extends IconAwesomeProps {
  onClick(e: MouseEvent): void;
  dataTest?: string;
  disabled?: boolean;
  reversedColors?: boolean;
  sx?: SxProps<Theme>;
}

// todo create folder 'clickable' (or similar) and move this file there
// todo after removing Icon components rename this one into IconClickable
export const IconAwesomeClickable: FC<
  React.PropsWithChildren<IconAwesomeClickableProps>
> = (props) => {
  const { color, sx } = useIconClickable(props);
  const mergedSx = { ...sx, ...props.sx };

  // don't provide {...props} to IconAwesomeCustomizable, because it contain onClick, which should not be provided there
  // too to avoid issue https://github.com/mui-org/material-ui/issues/13957
  return (
    <IconButton
      sx={mergedSx}
      onClick={props.onClick}
      disabled={props.disabled}
      data-test={props.dataTest}
    >
      <IconAwesomeCustomizable
        icon={props.icon}
        size={props.size}
        dataId={props.dataId}
        nativeProps={{ color }}
      />
    </IconButton>
  );
};
