import axios, { AxiosError } from 'axios';
import { AnyAction, Store } from 'redux';
import { Operation } from '@apollo/client';
import { createBrowserRouter } from 'react-router-dom';

import { IApplicationState } from 'setup/store/store';
import routes from 'shared/routes';
import {
  isNetworkServerParseError,
  isNetworkUnauthorizedError,
} from 'shared/utils/graphql/apolloError';
import {
  makeRestApiErrorReporterInterceptor,
  makeGraphqlErrorReporterInterceptor,
} from 'features/errorReporter/store/addInterceptors';
import { selectIsUserAuthenticated } from 'features/user';
import { RestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { makeRestrictedGraphqlErrorInterceptor } from 'features/restrictedGraphqlError/makeInterceptor';

import { GraphqlErrorInterceptor } from '../apollo/createApolloLink';
import { GraphqlRequestResponseInterceptor } from '../apollo/GraphqlRequestResponseInterceptor';
import makeUnauthorizedErrorInterceptor from './rest/unauthorizedErrorInterceptor';
import makeResponseParseErrorInterceptor from './rest/responseParseErrorInterceptor';

const addInterceptors = ({
  store,
  router,
  addGraphqlErrorInterceptor,
  addGraphqlRequestResponseInterceptor,
  addRestrictedGraphqlErrorInterceptor,
}: {
  store: Store<IApplicationState>;
  router: ReturnType<typeof createBrowserRouter>;
  addGraphqlErrorInterceptor: (f: GraphqlErrorInterceptor) => void;
  addGraphqlRequestResponseInterceptor: (
    f: GraphqlRequestResponseInterceptor
  ) => void;
  addRestrictedGraphqlErrorInterceptor: (
    h: (error: RestrictedGraphqlError, operation: Operation) => void
  ) => void;
}) => {
  addRestApiInterceptors(store);
  addGraphqlErrorInterceptor(graphqlErrorInterceptors(store));
  addGraphqlRequestResponseInterceptor(
    makeGraphqlErrorReporterInterceptor(store.dispatch)
  );
  addRestrictedGraphqlErrorInterceptor(
    makeRestrictedGraphqlErrorInterceptor({ store, router })
  );
};

const addRestApiInterceptors = (store: Store<IApplicationState>) => {
  addResponseErrorInterceptor(makeUnauthorizedErrorInterceptor(store));
  axios.interceptors.response.use(makeResponseParseErrorInterceptor(store));
  axios.interceptors.response.use(
    makeRestApiErrorReporterInterceptor(store.dispatch)
  );
};

const graphqlErrorInterceptors =
  (store: Store<IApplicationState, AnyAction>): GraphqlErrorInterceptor =>
  (response) => {
    if (
      selectIsUserAuthenticated(store.getState()) &&
      response.networkError &&
      (isNetworkUnauthorizedError(response.networkError) ||
        isNetworkServerParseError(response.networkError))
    ) {
      const redirectBack = window.location.pathname;
      window.location.replace(routes.login.getRedirectPath({ redirectBack }));
    }
  };

const addResponseErrorInterceptor = (
  interceptor: (error: AxiosError) => void
) => {
  axios.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      await interceptor(error);
      return Promise.reject(error);
    }
  );
};

export default addInterceptors;
