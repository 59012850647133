import { AxisLeft } from '@visx/axis';
import { FC, useMemo, Fragment } from 'react';
import { Text } from '@visx/text';
import { useTheme } from '@mui/material';

import { ChartSizes, getChartSizes } from 'shared/utils/charts/chartSizes';
import isNotNil from 'shared/utils/isNotNill';

import ChartSVG from '../../shared/ChartSVG/ChartSVG';
import { defaultChartMargin } from '../../shared/margin';
import LinePathOrPoint from '../../shared/shapes/LinePathOrPoint/LinePathOrPoint';
import styles from './ParallelCoordinateChart.module.css';
import { useDimensions } from './utils/dimensions';
import { FormatNumber, ParallelCoordinateChartData } from './utils/types';
import { makePath } from './utils/path';
import { ParallelCoordinateChartPoint } from './utils/points';
import { useParallelCoordinateChartTooltip } from './utils/tooltip';

interface Props {
  width: number;
  height: number;
  keys: string[];
  data: ParallelCoordinateChartData[];
  getColorByData: (d: ParallelCoordinateChartData) => string;
  chartId: string;
  formatNumber: FormatNumber;
  renderSvgContent?: (chartSizes: ChartSizes) => React.ReactNode;
}

const ParallelCoordinateChart: FC<React.PropsWithChildren<Props>> = (props) => {
  const chartSizes = getChartSizes({
    width: props.width,
    height: props.height,
    margin: defaultChartMargin,
  });

  const dimensions = useDimensions({
    data: props.data,
    keys: props.keys,
    innerHeight: chartSizes.innerHeight,
    innerWidth: chartSizes.innerWidth,
  });

  const paths = useMemo(
    () =>
      props.data
        .map((d) =>
          makePath(d, { dimensions, getColorByData: props.getColorByData })
        )
        .filter(isNotNil),
    [dimensions, props.data, props.getColorByData]
  );

  const { onMouseOut, onMouseOver, tooltipView, tooltipData } =
    useParallelCoordinateChartTooltip(props.formatNumber);

  const theme = useTheme();

  return (
    <ChartSVG
      width={chartSizes.width}
      height={chartSizes.height}
      margin={chartSizes.margin}
      isNilData={paths.every((d) => d.points.length === 0)}
      outSvgContent={tooltipView}
    >
      <g>
        {paths.map((path, i) => {
          return (
            <ParallelCoordinateChartPath
              key={i}
              className={styles.path}
              points={path.points}
              opacity={tooltipData ? 0.3 : 1}
              color={path.color}
              strokeWidth={1}
            />
          );
        })}
      </g>

      <g>
        {paths.map((path, i) => {
          const isActive = path.data === tooltipData;
          return (
            <ParallelCoordinateChartPath
              className={styles.hoverPath}
              key={i}
              points={path.points}
              opacity={isActive ? 1 : 0}
              color={path.color}
              onMouseOver={(e) => onMouseOver(e, path.data)}
              onMouseOut={onMouseOut}
              strokeWidth={3}
            />
          );
        })}
      </g>

      {dimensions.map((dimension) => (
        <Fragment key={dimension.key}>
          <Text
            x={dimension.x}
            y={-16}
            textAnchor="middle"
            fontSize={10}
            fill="#222"
          >
            {dimension.key}
          </Text>
          <AxisLeft
            scale={dimension.scale}
            left={dimension.x}
            numTicks={4}
            stroke={theme.palette.charts.accentColor}
            tickStroke={theme.palette.charts.accentColor}
            tickFormat={(value) =>
              typeof value === 'number'
                ? props.formatNumber(value)
                : String(value)
            }
          />
        </Fragment>
      ))}

      {props.renderSvgContent ? props.renderSvgContent(chartSizes) : null}
    </ChartSVG>
  );
};

const ParallelCoordinateChartPath: FC<
  React.PropsWithChildren<{
    className: string;
    points: ParallelCoordinateChartPoint[];
    color: string;
    strokeWidth: number;
    onMouseOver?: (e: React.MouseEvent) => void;
    onMouseOut?: (e: React.MouseEvent) => void;
    opacity?: number;
  }>
> = ({
  points,
  color,
  onMouseOver,
  onMouseOut,
  opacity,
  strokeWidth,
  className,
}) => {
  return (
    <LinePathOrPoint
      className={className}
      data={points}
      x={(d) => d.x}
      y={(d) => d.y}
      stroke={color}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      opacity={opacity}
      strokeWidth={strokeWidth}
    />
  );
};

export default ParallelCoordinateChart;
export type { Props as ParallelCoordinateChartProps };
