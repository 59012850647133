import { useRouteParams } from 'shared/utils/router/useRouteParams';
import { useModelActions } from 'features/catalog/registeredModel/actions/hooks';
import routes from 'shared/routes';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

const ModelActions = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'RegisteredModelActions' */ 'features/catalog/registeredModel/actions/view/ModelActions'
    )
);

const ActionsPage = () => {
  const { registeredModelId, workspaceName } = useRouteParams(
    routes.modelActivities
  );

  const { data, communication } = useModelActions({
    id: registeredModelId,
  });

  return (
    <DefaultMatchRemoteDataOrError
      communication={communication}
      data={data}
      context="loading model"
    >
      {({ activities, allowedActions }) => (
        <ModelActions
          modelId={registeredModelId}
          workspaceName={workspaceName}
          activities={activities}
          allowedActions={allowedActions}
        />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default ActionsPage;
