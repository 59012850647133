import { getArrayItem, isNotEmptyArray } from 'shared/utils/collection';
import { Result } from 'shared/utils/result';
import {
  LineChartXAxisDataSpec,
  LineChartYAxisDataSpec,
  LineChartAxisesDataSpec,
  LineChartSettings,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/LineChartSettings';
import { emptyCustomDomain } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/shared/CustomDomain';
import { OmitStrict } from 'shared/utils/types';

export type LineChartSettingsForm = {
  name: string;
  type: 'lineChart';
  xAxis: OmitStrict<LineChartSettings['xAxis'], 'spec'> & {
    spec?: LineChartXAxisDataSpec;
  };
  yAxis: OmitStrict<LineChartSettings['yAxis'], 'specs'> & {
    specs: LineChartYAxisDataSpec[];
  };
};

export const getInitialLineChartSettingsForm = (
  name: string,
  axisesSpec: LineChartAxisesDataSpec
): LineChartSettingsForm => {
  const xAxisSpec = axisesSpec.xAxis.dateUpdated;
  const yAxisSpec = getArrayItem(0, axisesSpec.yAxis);

  return {
    type: 'lineChart',
    name,
    xAxis: {
      spec: xAxisSpec,
      isLogScale: false,
      domain: emptyCustomDomain,
    },
    yAxis: {
      specs: yAxisSpec ? [yAxisSpec] : [],
      isLogScale: false,
      domain: emptyCustomDomain,
    },
  };
};

export const validateLineChartSettingsForm = (
  form: LineChartSettingsForm
): Result<LineChartSettings, string> => {
  if (form.xAxis.spec && isNotEmptyArray(form.yAxis.specs)) {
    return {
      type: 'success',
      data: {
        name: form.name,
        type: 'lineChart',
        xAxis: {
          spec: form.xAxis.spec,
          isLogScale: form.xAxis.isLogScale,
          domain: form.xAxis.domain,
        },
        yAxis: {
          specs: form.yAxis.specs,
          isLogScale: form.yAxis.isLogScale,
          domain: form.yAxis.domain,
        },
      },
    };
  }
  return {
    type: 'error',
    error: 'Select a metric to visualize in this line chart.',
  };
};
