import { styled } from '@mui/material';

import isNotNil from 'shared/utils/isNotNill';
import matchType from 'shared/utils/matchType';

import { Spacing } from './RecordsSection';

const levelPaddingLeft = '12px';
export const RecordsSectionContainer = styled('div')(({
  $spacing,
  $labelColumnMinWidth,
}: {
  $spacing: Spacing;
  $labelColumnMinWidth?: number;
}) => {
  const firstColumnWidth = isNotNil($labelColumnMinWidth)
    ? `${$labelColumnMinWidth}px`
    : 'min-content';
  return {
    gridTemplateColumns: `${firstColumnWidth} auto`,
    display: 'grid',
    gridRowGap: '8px',
    fontSize: '15px',
    lineHeight: '20px',
    gridColumnGap: matchType(
      {
        default: () => '24px',
        max: () => '48px',
        min: () => '16px',
      },
      $spacing
    ),
  };
});
export const RecordContainer = styled(
  ({
    dataTest,
    children,
    className,
  }: {
    className: string;
    dataTest: string | undefined;
    children: React.ReactNode;
  }) => (
    <div className={className} data-test={dataTest}>
      {children}
    </div>
  )
)`
  display: contents;

  .stub {
  }

  .record__records {
    display: contents;
  }

  .record__label {
    font-weight: 500;
    white-space: nowrap;
    display: flex;
  }

  .record__labelContent {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    gap: 8px;
  }

  .record__label_align_top {
    align-items: flex-start;
  }

  .record__label_align_center {
    align-items: center;
  }

  .record__label_align_withInput {
    padding-top: 25px !important;
  }

  .record__content {
    min-width: 0;
  }

  .record__content,
  .record__label {
    padding-top: 8px;
  }

  .record__records .record__content,
  .record__records .record__label {
    padding-top: 0;
  }

  .record__records > .record > .record__label {
    padding-left: calc(${levelPaddingLeft} * 1);
  }

  .record__records > .record > .record__records > .record > .record__label {
    padding-left: calc(${levelPaddingLeft} * 2);
  }

  .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__label {
    padding-left: calc(${levelPaddingLeft} * 3);
  }

  .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__label {
    padding-left: calc(${levelPaddingLeft} * 4);
  }

  .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__label {
    padding-left: calc(${levelPaddingLeft} * 5);
  }

  .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__label {
    padding-left: calc(${levelPaddingLeft} * 6);
  }

  .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__label {
    padding-left: calc(${levelPaddingLeft} * 7);
  }

  .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__label {
    padding-left: calc(${levelPaddingLeft} * 8);
  }

  .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__label {
    padding-left: calc(${levelPaddingLeft} * 9);
  }

  .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__records
    > .record
    > .record__label {
    padding-left: calc(${levelPaddingLeft} * 10);
  }
`;
