import { gql } from '@apollo/client';
import { sortBy, last } from 'lodash';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { USERV2_FRAGMENT } from 'shared/graphql/User/User';
import { ORGANIZATION_STATS_FRAGMENT } from 'shared/graphql/organization/OrganizationStats';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';

import * as Types from './graphql-types/useOrganizationsList.generated';

export { Types };

export const QUERY = gql`
  query OrganizationsV2List {
    organizationsV2(query: { pagination: { limit: 1000, page: 1 } }) {
      organizations {
        id
        name
        description
        timeCreated
        timeUpdated
        admins {
          id
          ...UserV2Data
        }
        allowedActions {
          ...AllowedActionsData
        }
        workspaces {
          id
          name
          builtIn
        }
      }
      organizationsStats {
        ...OrganizationStatsData
      }
    }
  }
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${USERV2_FRAGMENT}
  ${ORGANIZATION_STATS_FRAGMENT}
`;

export const useOrganizationsList = () => {
  const queryResult = useCustomQuery<Types.OrganizationsV2List>(QUERY);

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult,
  });

  return {
    data,
    communication,
    reload: queryResult.refetch,
  };
};

const convert = ({ organizationsV2 }: Types.OrganizationsV2List) =>
  organizationsV2.organizations.map((organization) => {
    const stats = organizationsV2.organizationsStats.find(
      (s) => s.orgId === organization.id
    );

    const defaultWorkspace = last(sortBy(organization.workspaces, ['builtIn']));

    return { ...organization, stats, defaultWorkspace };
  });
