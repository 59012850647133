/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import routes from 'shared/routes';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { PERMISSION_FRAGMENT } from 'shared/graphql/Permission/Permission';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import {
  isNotNullableRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';

import * as GraphqlTypes from './graphql-types/ProjectOverviewPage.generated';
import { ProjectDetailsLayoutWithLoading } from '../shared/layouts/ProjectDetailsLayout';

const ProjectSummaryContent = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ProjectSummaryContent' */ './ProjectOverviewContent'
    )
);

const PROJECT_OVERVIEW_PAGE = gql`
  query ProjectOverviewPage($projectId: ID!) {
    project(id: $projectId) {
      ... on Error {
        ...ErrorData
      }
      ... on Project {
        id
        name
        tags
        description
        readmeText
        dateCreated
        dateUpdated
        attributes {
          ...AttributeData
        }
        owner {
          ...OwnerData
        }
        allowedActions {
          ...AllowedActionsData
        }
        workspace {
          id
          ...WorkspaceData
        }
        visibility
        customPermission {
          ...PermissionData
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${PERMISSION_FRAGMENT}
`;

const useProjectOverviewData = (projectId: string) => {
  const result = useCustomQuery<
    GraphqlTypes.ProjectOverviewPage,
    GraphqlTypes.ProjectOverviewPageVariables
  >(PROJECT_OVERVIEW_PAGE, {
    variables: { projectId },
  });

  const { data, communication } = resultToCommunicationWithData(
    (response) => response.project,
    result
  );

  return {
    project: data,
    loadingProject: communication,
  };
};

function ProjectOverviewPage() {
  const { projectId } = useRouteParams(routes.projectOverview);

  const { project, loadingProject } = useProjectOverviewData(projectId);

  return (
    <ProjectDetailsLayoutWithLoading
      data={project}
      loadingData={loadingProject}
      projectName={
        isNotNullableRestrictedGraphqlError(project) ? project.name : undefined
      }
      projectId={projectId}
    >
      {({ loadedData: loadedProject }) => (
        <div>
          <ProjectSummaryContent project={loadedProject} />
        </div>
      )}
    </ProjectDetailsLayoutWithLoading>
  );
}

export default ProjectOverviewPage;
