import { useMemo } from 'react';
import { scaleOrdinal } from '@visx/scale';

import { chartsDataColors } from 'shared/view/charts/shared/colors';

import { ChartExperimentRun } from './types';

export const useObservationChartColorScale = (
  experimentRuns: ChartExperimentRun[]
) => {
  return useMemo(
    () =>
      scaleOrdinal({
        domain: experimentRuns.map(({ id }) => id),
        range: chartsDataColors,
      }),
    [experimentRuns]
  );
};
