import { FC } from 'react';

import mainMuiTheme from 'shared/mui/themes/MainMuiTheme';
import { OmitStrict } from 'shared/utils/types';

import LinearGradient from '../../shared/LinearGradient/LinearGradient';
import ParallelCoordinateChart, {
  ParallelCoordinateChartProps,
} from '../ParallelCoordinateChart/ParallelCoordinateChart';
import { useGradientScale } from './utils/gradientScale';

interface Props
  extends OmitStrict<
    ParallelCoordinateChartProps,
    'getColorByData' | 'renderSvgContent'
  > {}

const colors = [mainMuiTheme.palette.primary.main, '#23d8c0'];

const ParallelCoordinateChartWithGradient: FC<
  React.PropsWithChildren<Props>
> = (props) => {
  const { getColorByData } = useGradientScale({
    data: props.data,
    keys: props.keys,
    colors,
  });

  const gradientId = `${props.chartId}-gradientId`;

  return (
    <ParallelCoordinateChart
      {...props}
      getColorByData={getColorByData}
      renderSvgContent={({ innerHeight, innerWidth }) => (
        <>
          <defs>
            <LinearGradient id={gradientId} colors={[...colors].reverse()} />
          </defs>
          <rect
            fill={`url(#${gradientId}`}
            height={innerHeight + 2}
            x={innerWidth}
            y={-1}
            width={20}
          />
        </>
      )}
    />
  );
};

export default ParallelCoordinateChartWithGradient;
