import Papa from 'papaparse';

import { EvaluationHeaderKey } from 'features/evaluations/shared/utils/evaluation';

export type Header = {
  columnNumber: number;
  originalValue: string;
  value: string;
  isReserved: boolean;
};

type KeyValue = Record<
  string,
  string | boolean | undefined | string[] | number
>;

interface CsvDataProps extends KeyValue {
  input: string;
  output: string;
  groundtruth: string;
  trace: string;
  [EvaluationHeaderKey.ID]: number;
  [EvaluationHeaderKey.RESULT]?: boolean;
  [EvaluationHeaderKey.LABELS]: string[];
  [EvaluationHeaderKey.FEEDBACK]: string;
}

export const RESERVED_INPUT_HEADERS = [
  'input',
  'output',
  'groundtruth',
  'trace',
];

export const HEADERS_DICTIONARY = {
  input: 'Input',
  output: 'Output',
  groundtruth: 'Groundtruth',
  trace: 'Trace',
  [EvaluationHeaderKey.ID]: 'ID',
  [EvaluationHeaderKey.RESULT]: 'Approve/Reject',
  [EvaluationHeaderKey.LABELS]: 'Labels',
  [EvaluationHeaderKey.FEEDBACK]: 'Feedback',
};

const extractHeadersPosition = (fields: string[]): Header[] => {
  return fields.map((_field, idx) => {
    const field = _field.toLowerCase().trim();
    const isReserved = RESERVED_INPUT_HEADERS.includes(field);
    return {
      columnNumber: idx,
      originalValue: _field,
      value: isReserved ? field : `${_field} (Metadata)`,
      isReserved,
    };
  });
};

type CsvReturnProps = {
  headers: Header[];
  data: CsvDataProps[];
  originalData: Record<string, string>[];
  meta: Papa.ParseMeta;
  errors: Papa.ParseError[];
};

export const parseCsvFileToAttributeData = async (
  file: File
): Promise<CsvReturnProps> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target?.result as string;

      Papa.parse<Record<string, string>>(fileContent, {
        header: true,
        skipEmptyLines: true,
        complete: ({ data, meta, errors }) => {
          const headers = extractHeadersPosition(meta.fields || []);
          const _data = data.map((_row, idx) => {
            const row: KeyValue = {
              ..._row,
              [EvaluationHeaderKey.ID]: idx + 1,
              [EvaluationHeaderKey.RESULT]: undefined,
              [EvaluationHeaderKey.LABELS]: [],
              [EvaluationHeaderKey.FEEDBACK]: '',
            };
            headers.forEach((header) => {
              row[header.value] = _row[header.originalValue];
            });

            return row as CsvDataProps;
          });

          resolve({ headers, data: _data, originalData: data, meta, errors });
        },
        error: (error: Error) => {
          return reject(error);
        },
      });
    };

    reader.readAsText(file);
  });
};
