import * as React from 'react';

import styles from './Controls.module.css';

interface ILocalProps {
  controls: React.ReactNode[];
}

const Controls = (props: ILocalProps) => {
  return (
    <div className={styles.root}>
      {props.controls.map((control, i) => (
        <div className={styles.control} key={i}>
          {control}
        </div>
      ))}
    </div>
  );
};

export default Controls;
