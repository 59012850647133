import { Box, Popover, Stack, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import {
  getAvailableTimeWindowsWithLabels,
  MonitoringAggregation,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAggregation';
import { TimeRange } from 'shared/utils/TimeRange';
import Button from 'shared/view/elements/Button/Button';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import IconButton from 'shared/view/elements/IconButton/IconButton';
import Select from 'shared/view/elements/Selects/Select/Select';
import { IOptionType } from 'shared/view/elements/Selects/shared/types';
import { usePopoverManager } from 'shared/view/hooks/usePopoverManager';

interface Props {
  timeRange: TimeRange;
  aggregation: MonitoringAggregation;
  onAggregationChange: (aggregation: MonitoringAggregation) => void;
}

const MonitoringAggregationControl = (props: Props) => {
  const { isOpen, anchorEl, closePopover, openPopover } = usePopoverManager();

  return (
    <div>
      <IconButton icon={ICONS.objectGroup} onClick={openPopover} />

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MonitoringAggregationControlContent
          {...props}
          onCancel={closePopover}
        />
      </Popover>
    </div>
  );
};

const MonitoringAggregationControlContent = (
  props: Props & { onCancel: () => void }
) => {
  const [aggregation, updateAggregation] = useState(props.aggregation);

  const { onAggregationChange, onCancel } = props;
  const onApply = useCallback(() => {
    onAggregationChange(aggregation);
    onCancel();
  }, [onAggregationChange, aggregation, onCancel]);

  return (
    <Box sx={{ padding: '16px', maxWidth: '400px' }}>
      <Stack spacing={2}>
        <div>
          <AggregationSelect
            aggregation={aggregation}
            onAggregationChange={updateAggregation}
            timeRange={props.timeRange}
          />
          <Box sx={{ padding: '0 8px' }}>
            <Typography variant="helperText">
              Aggregation combines data points over specified time groups called
              aggregation windows. All metric data points for each time group
              are aggregated together into a single value for visualization in
              the charts.
            </Typography>
          </Box>
        </div>
        <Stack direction="row" justifyContent="flex-end">
          <Button variant="outlined" isLoading={false} onClick={props.onCancel}>
            Cancel
          </Button>
          <Button
            onClick={onApply}
            disabled={props.aggregation.timeWindow === aggregation.timeWindow}
            isLoading={false}
          >
            Apply
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

const AggregationSelect = (props: Props) => {
  const options: Array<IOptionType<number>> = useMemo(
    () => getAvailableTimeWindowsWithLabels(props.timeRange),
    [props.timeRange]
  );

  const { onAggregationChange } = props;
  const onChange = useCallback(
    (option: IOptionType<number>) =>
      onAggregationChange({ timeWindow: option.value }),
    [onAggregationChange]
  );

  return (
    <Select<number>
      label="Aggregation window"
      options={options}
      value={options.find((opt) => opt.value === props.aggregation.timeWindow)}
      onChange={onChange}
    />
  );
};

export default MonitoringAggregationControl;
