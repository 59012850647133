/* eslint-disable rulesdir/no-deprecated-fields */
import { useDispatch } from 'react-redux';
import { gql } from '@apollo/client';

import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { WorkspaceType } from 'generated/types';
import { convertOrganizationWorkspace } from 'shared/models/Workspace';
import isNotNil from 'shared/utils/isNotNill';
import { setOrganizationWorkspaces } from 'features/workspaces';
import { WORKSPACE_ORGANIZATION_FRAGMENT } from 'shared/graphql/Workspace';
import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';

import * as Types from './graphql-types/useLoadUserWorkspaces.generated';

export { Types };

export const QUERY = gql`
  query LoadUserWorkspaces {
    workspaces {
      id
      ...WorkspaceOrganizationData
    }
  }
  ${WORKSPACE_ORGANIZATION_FRAGMENT}
`;

export const useLoadUserWorkspaces = (onCompleted?: () => void) => {
  const dispatch = useDispatch();
  const [load, queryResult] = useCustomLazyQuery<Types.LoadUserWorkspaces>(
    QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (result) => {
        const orgWorkspaces = result.workspaces
          .map((w) =>
            w.type === WorkspaceType.ORGANIZATION
              ? convertOrganizationWorkspace({
                  id: w.oldId,
                  workspace: {
                    ...w,
                    organizationV2:
                      w.organizationV2?.__typename === 'OrganizationV2'
                        ? w.organizationV2
                        : undefined,
                  },
                })
              : undefined
          )
          .filter(isNotNil);

        dispatch(setOrganizationWorkspaces(orgWorkspaces));
        onCompleted?.();
      },
    }
  );

  const { communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert: (x) => x,
    queryResult,
  });

  return {
    communication,
    load,
  };
};
