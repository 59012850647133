import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';
import AdminsPage from 'pages/authorized/SystemAdmin/AdminsPage/AdminsPage';
import IntegrationsPage from 'pages/authorized/SystemAdmin/IntegrationsPage/IntegrationsPage';
import PypiConfigurationsPage from 'pages/authorized/SystemAdmin/IntegrationsPage/pypi/PypiConfigurationsPage';
import ActiveDirectoryConfigurationPage from 'pages/authorized/SystemAdmin/IntegrationsPage/active-directory/ActiveDirectoryConfigurationPage';

import NewSystemAdminOrganizationsPage from './OrganizationsPage/OrganizationsPage';
import { SystemAdminUsersPage } from './UsersPage/UsersPage';

export const systemAdminPage: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.systemAdmin.$.admins.getPath(),
      element: <AdminsPage />,
    },
    {
      path: routes.systemAdmin.$.organizations.getPath(),
      element: <NewSystemAdminOrganizationsPage />,
    },
    {
      path: routes.systemAdmin.$.users.getPath(),
      element: <SystemAdminUsersPage />,
    },
    {
      path: routes.systemAdmin.$.integrations.$.pypi.getPath(),
      element: <PypiConfigurationsPage />,
    },
    {
      path: routes.systemAdmin.$.integrations.$.active_directory.getPath(),
      element: <ActiveDirectoryConfigurationPage />,
    },
    {
      path: routes.systemAdmin.$.integrations.getPath(),
      element: <IntegrationsPage />,
    },
  ],
};
