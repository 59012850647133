import { createAction } from 'typesafe-actions';
import axios from 'axios';

import {
  convertOrganizationWorkspace,
  getDefaultWorkspace,
  IOrganizationWorkspace,
  IUserWorkspaces,
  IWorkspace,
} from 'shared/models/Workspace';
import { selectCurrentUserOrThrowError, updateUser } from 'features/user';
import {
  ICurrentWorkspace,
  workspaceToCurrentShortWorkspace,
} from 'shared/models/Workspace/CurrentWorkspace';
import { createdOrganizationObserver } from 'features/organizations/create/store/useCreateOrganization/useCreateOrganization';
import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';
import {
  selectWorkspaceOrganizations,
  selectWorkspaces,
} from 'features/workspaces/store/selectors';
import {
  getInitialCurrentWorkspace,
  getInitialCurrentWorkspaceV2,
  saveWorkspaceInLocalStorage,
} from 'features/workspaces/store/helpers';
import { normalizeErrorFromUnknown } from 'shared/utils/normalizeError';
import { trackEvent } from 'setup/app/analytics';
import makeThunkApiRequest from 'shared/utils/redux/actions/makeThunkApiRequest';
import { AppError } from 'shared/models/Error';
import {
  LOAD_INITIAL_AUTHORIZED_APP_DATA,
  LoadInitialAuthorizedAppData,
} from 'features/workspaces/store/graphql';
import { apolloErrorFromUnknownToAppError } from 'shared/utils/graphql/apolloErrorToAppError';
import { WorkspaceType } from 'generated/types';
import isNotNil from 'shared/utils/isNotNill';
import { setIsSystemAdmin } from 'features/systemAdmin/isAdmin/store/actions';
import { addError } from 'features/errorReporter/store/actions';
import { currentOrganizationStorage } from 'services/utils/currentOrganizationStorage';

const namespace = 'workspaces';

export const loadOrganizationWorkspaces = makeThunkApiRequest(
  namespace,
  'LOAD_ORGANIZATION_WORKSPACES',
  undefined
)<
  { currentUser: CurrentUserData },
  {
    organizationWorkspaces: IOrganizationWorkspace[];
  },
  AppError
>(async ({
  payload: { currentUser },
  dispatch,
  dependencies: { apolloClient },
}) => {
  try {
    const result = await apolloClient.query<
      Partial<LoadInitialAuthorizedAppData>
    >({
      query: LOAD_INITIAL_AUTHORIZED_APP_DATA,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    });

    const organizationWorkspaces =
      result.data.workspaces
        ?.map((w) =>
          w.type === WorkspaceType.ORGANIZATION
            ? convertOrganizationWorkspace({
                id: w.oldId,
                workspace: {
                  ...w,
                  organizationV2:
                    w.organizationV2?.__typename === 'OrganizationV2'
                      ? w.organizationV2
                      : undefined,
                },
              })
            : undefined
        )
        .filter(isNotNil) || [];

    const defaultWorkspaceId =
      currentUser.defaultWorkspaceId !== '0'
        ? currentUser.defaultWorkspaceId
        : currentUser.workspaceId;

    await dispatch(
      setCurrentWorkspaceOnInitAppV2(defaultWorkspaceId, organizationWorkspaces)
    );

    await dispatch(
      setIsSystemAdmin(Boolean(result.data.systemAdmins?.selfIsSystemAdmin))
    );

    return {
      organizationWorkspaces,
    };
  } catch (e: unknown) {
    throw apolloErrorFromUnknownToAppError(e);
  }
});

export const setOrganizationWorkspaces = createAction(
  `${namespace}/SET_ORGANIZATION_WORKSPACES`
)<IOrganizationWorkspace[]>();

export const setCurrentWorkspaceOnInitAppV2 = (
  userDefaultWorkspaceId: string | null,
  workspaces: IOrganizationWorkspace[]
) => {
  try {
    const currentWorkspace = getInitialCurrentWorkspaceV2({
      localStorage,
      workspaces,
      userDefaultWorkspaceId,
    });

    if (currentWorkspace?.type === 'organization') {
      const { organizationId } = currentWorkspace;
      axios.defaults.headers.common['Grpc-Metadata-organization-id'] =
        organizationId;

      currentOrganizationStorage.write(organizationId);
    }

    return setCurrentWorkspace(
      currentWorkspace || {
        id: '',
        namespace: null,
        organizationId: '',
        organizationName: '',
        type: 'organization',
      },
      {
        withSavingInLocalStorage: false,
      }
    );
  } catch (e: unknown) {
    return addError({
      error: normalizeErrorFromUnknown(e),
      context: 'setCurrentWorkspaceOnInitAppV2',
      pathname: '',
    });
  }
};

export const setCurrentWorkspaceOnInitApp = (
  userDefaultWorkspaceId: string | null,
  userWorkspaces: IUserWorkspaces
) => {
  try {
    const currentWorkspace = getInitialCurrentWorkspace({
      userDefaultWorkspaceId,
      localStorage,
      userWorkspaces,
    });
    return setCurrentWorkspace(currentWorkspace, {
      withSavingInLocalStorage: false,
    });
  } catch (e: unknown) {
    return addError({
      error: normalizeErrorFromUnknown(e),
      context: 'setCurrentWorkspaceOnInitApp',
      pathname: '',
    });
  }
};
export interface IChangeWorkspaceByUIParams {
  event: { preventDefault: () => void } | undefined;
  to: string;
  workspace: IWorkspace;
  preventTrack?: boolean;
}
export const changeWorkspaceWithPageReloading = ({
  event,
  to,
  workspace,
  preventTrack,
}: IChangeWorkspaceByUIParams) => {
  !preventTrack && trackEvent({ type: 'homepage.app_bar.workspace_changed' });
  event?.preventDefault();
  saveWorkspaceInLocalStorage(workspaceToCurrentShortWorkspace(workspace));
  window.location.assign(to);
};

createdOrganizationObserver.subscribe(
  ({
    createdOrganization: { id, workspace, updatedCurrentUser },
    thunkArgs: { getState, dispatch },
  }) => {
    const previousUser = selectCurrentUserOrThrowError(getState());

    const newOrganization = convertOrganizationWorkspace({
      workspace,
      id,
    });
    const oldOrganizationsList = selectWorkspaceOrganizations(getState());
    const newOrganizationsList: IOrganizationWorkspace[] = [
      ...oldOrganizationsList,
      newOrganization,
    ];

    dispatch(setOrganizationWorkspaces(newOrganizationsList));

    if (
      previousUser.defaultWorkspaceId !== updatedCurrentUser.defaultWorkspaceId
    ) {
      dispatch(updateUser(updatedCurrentUser));
      const userWorkspaces = selectWorkspaces(getState());
      const newWorkspace = workspaceToCurrentShortWorkspace(
        getDefaultWorkspace(
          updatedCurrentUser.defaultWorkspaceId,
          userWorkspaces
        )
      );
      dispatch(
        setCurrentWorkspace(newWorkspace, { withSavingInLocalStorage: true })
      );
    }
  }
);

export const setCurrentWorkspace = createAction(
  `${namespace}/SET_CURRENT_WORKSPACE`,
  (
    currentWorkspace: ICurrentWorkspace,
    { withSavingInLocalStorage }: { withSavingInLocalStorage: boolean }
  ) => {
    if (withSavingInLocalStorage) {
      saveWorkspaceInLocalStorage(currentWorkspace);
    }
    return currentWorkspace;
  }
)();
