import * as React from 'react';
import { ParentSize } from '@visx/responsive';
import { range } from 'ramda';
import { useMemo } from 'react';

import {
  getExperimentRunsWithDisplayedFields,
  getKeyValueSpecs,
  RequiredExperimentRun,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import { KeyValueSpec } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/KeyValueSpec';
import {
  getInitialLineChartSettingsForm,
  validateLineChartSettingsForm,
} from 'features/experimentRuns/crossRunDashboard/view/WidgetBuilder/form/LineChartSettingsForm';
import {
  getLineChartAxisesDataSpecs,
  LineChartXAxisDataSpec,
  LineChartYAxisDataSpec,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/LineChartSettings';
import { CustomDomain } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/shared/CustomDomain';
import Select from 'shared/view/elements/Selects/Select/Select';
import Placeholder from 'shared/view/elements/Placeholder/Placeholder';
import MultiSelect from 'shared/view/elements/Selects/MultiSelect/MultiSelect';
import {
  getSelectedOption,
  getSelectedOptionFromGroupedOptions,
} from 'shared/view/elements/Selects/Select/helpers';
import { getSelectedOptionsFromGroupedOptions } from 'shared/view/elements/Selects/MultiSelect/helpers';
import { WidgetSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/WidgetSettings';
import { WidgetSettingsForm } from 'features/experimentRuns/crossRunDashboard/view/WidgetBuilder/form/WidgetSettingsForm';
import {
  BarChartXAxisDataSpec,
  BarChartYAxisDataSpec,
  getBarChartAxisesDataSpecs,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/BarChartSettings';
import {
  getInitialBarChartSettingsForm,
  validateBarChartSettingsForm,
} from 'features/experimentRuns/crossRunDashboard/view/WidgetBuilder/form/BarChartSettingsForm';
import matchBy from 'shared/utils/matchBy';
import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import matchType from 'shared/utils/matchType';
import { mapObjectKeysToArray } from 'shared/utils/collection';
import {
  getParallelCoordinateChartAxisesDataSpecs,
  ParallelCoordinateChartYAxisDataSpec,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/ParallelCoordinateChartSettings';
import {
  getScatterChartAxisesDataSpecs,
  ScatterChartXAxisDataSpec,
  ScatterChartYAxisDataSpec,
  ScatterChartZAxisDataSpec,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/ScatterChartSettings';
import { AggregationType } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/shared/Aggregation';
import {
  BoxPlotXAxisDataSpec,
  BoxPlotYAxisDataSpec,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/BoxPlotSettings';
import {
  dataSpecsToGroupedOptions,
  keyValueSpecsToGroupedOptions,
  keyValueSpecsToOptionsGroups,
} from 'features/experimentRuns/crossRunDashboard/view/WidgetBuilder/dataSpecsOptions';
import ControlWithCheckbox from 'features/experimentRuns/crossRunDashboard/view/WidgetBuilder/ControlWithCheckbox/ControlWithCheckbox';
import CustomDomainView from 'features/experimentRuns/crossRunDashboard/view/WidgetBuilder/CustomDomain/CustomDomain';
import {
  getInitialScatterChartSettingsForm,
  validateScatterChartSettingsForm,
} from 'features/experimentRuns/crossRunDashboard/view/WidgetBuilder/form/ScatterChartSettingsForm';
import AggregationTypeSelect from 'features/experimentRuns/crossRunDashboard/view/WidgetBuilder/AggregationTypeSelect/AggregationTypeSelect';
import Controls from 'features/experimentRuns/crossRunDashboard/view/WidgetBuilder/Controls/Controls';
import {
  getInitialBoxPlotSettingsForm,
  validateBoxPlotSettingsForm,
} from 'features/experimentRuns/crossRunDashboard/view/WidgetBuilder/form/BoxPlotSettingsForm';
import {
  getInitialParallelCoordinateChartSettingsForm,
  validateParallelCoordinateChartSettingsForm,
} from 'features/experimentRuns/crossRunDashboard/view/WidgetBuilder/form/ParallelCoordinateChartSettingsForm';
import WidgetFormPopup from 'shared/view/domain/Dashboards/WidgetFormPopup';
import WidgetFormPopupContent from 'shared/view/domain/Dashboards/WidgetFormPopupContent/WidgetFormPopupContent';
import FieldWithTopLabel from 'shared/view/elements/FieldWithTopLabel/FieldWithTopLabel';
import {
  getAvailableTableWidgetColumns,
  TableWidgetColumn,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/TableWidgetSettings';
import { CrossRunWidgetType } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunWidget';
import Checkbox from 'shared/view/elements/Checkbox/Checkbox';
import {
  IGroupedOptions,
  IOptionsGroup,
} from 'shared/view/elements/Selects/shared/types';

import {
  getInitialTableWidgetSettingsForm,
  validateTableWidgetSettingsForm,
} from './form/TableWidgetSettingsForm';
import {
  getInitialMarkdownWidgetSettings,
  validateMarkdownSettingsForm,
} from './form/MarkdownWidgetSettingsForm';
import WidgetPreview from './WidgetPreview';
import {
  getInitialObservationChartsSettingsForm,
  validateObservationChartsSettingsForm,
} from './form/ObservationChartsSettingsForm';

interface Props {
  isOpen: boolean;
  experimentRuns: RequiredExperimentRun[];
  initialSettings: WidgetSettings | undefined;
  type: CrossRunWidgetType;
  onClose: () => void;
  onBack?: () => void;
  onSubmit(widgetSettings: WidgetSettings): void;
  submitText: string;
}

const WidgetBuilder = (props: Props) => {
  const experimentRunsWithDisplayedFields =
    getExperimentRunsWithDisplayedFields(props.experimentRuns);
  const dataSpecs = getKeyValueSpecs(experimentRunsWithDisplayedFields);
  const widgetSettingsForm = useWidgetSettingsForm({
    keyValueSpecs: dataSpecs,
    initialSettings: props.initialSettings,
    type: props.type,
  });

  const { onSubmit } = props;
  const successData =
    widgetSettingsForm.result.type === 'success'
      ? widgetSettingsForm.result.data
      : undefined;
  const submitButtonProps = useMemo(
    () => ({
      type: 'button' as const,
      onClick: () => {
        if (successData) {
          onSubmit(successData);
        }
      },
      isLoading: false,
    }),
    [onSubmit, successData]
  );

  return (
    <WidgetFormPopup closePopup={props.onClose} isOpen={props.isOpen}>
      <WidgetFormPopupContent
        isValid={
          widgetSettingsForm.result.type === 'success' &&
          widgetSettingsForm.form.name !== ''
        }
        onReset={widgetSettingsForm.reset}
        onBack={props.onBack}
        submitButtonProps={submitButtonProps}
        submitText={props.submitText}
        widgetPreview={
          <ParentSize>
            {(parent) =>
              widgetSettingsForm.result.type === 'success' ? (
                <WidgetPreview
                  id="widget-builder-chart"
                  width={parent.width}
                  height={parent.height}
                  experimentRuns={experimentRunsWithDisplayedFields}
                  widgetSettings={widgetSettingsForm.result.data}
                />
              ) : (
                <Placeholder>{widgetSettingsForm.result.error}</Placeholder>
              )
            }
          </ParentSize>
        }
      >
        <TextInput
          label={'Name'}
          isRequired={true}
          value={widgetSettingsForm.form.name}
          onChange={widgetSettingsForm.changeName}
        />
        {matchBy(
          widgetSettingsForm,
          'widgetType'
        )({
          markdown: () => null,
          table: (tableWidgetForm) => {
            const singleColumnOptionsGroup = {
              label: 'common',
              options: tableWidgetForm.tableColumns.single.map((t) => ({
                label: t.type,
                value: t,
              })),
            };
            const keyValueColumnOptions = keyValueSpecsToOptionsGroups(
              tableWidgetForm.tableColumns.keyValue
            );
            const groupedOptions: IGroupedOptions<TableWidgetColumn> = {
              type: 'groupedOptions' as const,
              groups: [singleColumnOptionsGroup, ...keyValueColumnOptions],
            };
            const selectedOptions = getSelectedOptionsFromGroupedOptions(
              tableWidgetForm.form.columns,
              groupedOptions
            );

            return (
              <MultiSelect
                label={'Columns'}
                value={selectedOptions}
                options={groupedOptions}
                onChange={(option) =>
                  tableWidgetForm.changeColumns(
                    option.map(({ value }) => value)
                  )
                }
              />
            );
          },
          observationCharts: () => null,
          scatterChart: (scatterChartSettingsForm) => {
            const xAxisOptions = dataSpecsToGroupedOptions(
              Object.values(scatterChartSettingsForm.axisesDataSpecs.xAxis)
                .map((v) => (Array.isArray(v) ? v : [v]))
                .flat()
            );
            const selectedXAxisOption = getSelectedOptionFromGroupedOptions(
              scatterChartSettingsForm.form.xAxis.spec,
              xAxisOptions
            );

            const yAxisOptions = keyValueSpecsToGroupedOptions(
              scatterChartSettingsForm.axisesDataSpecs.yAxis
            );
            const selectedYAxisOptions = getSelectedOptionFromGroupedOptions(
              scatterChartSettingsForm.form.yAxis.spec,
              yAxisOptions
            );

            const zAxisOptions: IGroupedOptions<ScatterChartZAxisDataSpec> = {
              type: 'groupedOptions',
              groups: mapObjectKeysToArray(
                scatterChartSettingsForm.axisesDataSpecs.zAxis
              )<Array<IOptionsGroup<ScatterChartZAxisDataSpec>>>({
                keyValue: (v) => keyValueSpecsToOptionsGroups(v),
                experimentRunStringField: (fields) => [
                  {
                    label: 'property',
                    options: fields.map((field) => ({
                      label: field.name,
                      value: field,
                    })),
                  },
                ],
              }).flatMap((x) => x),
            };
            const selectedZAxisOption = getSelectedOptionFromGroupedOptions(
              scatterChartSettingsForm.form.zAxis.spec,
              zAxisOptions
            );

            return (
              <>
                <Select
                  label={'X'}
                  value={selectedXAxisOption}
                  options={xAxisOptions}
                  onChange={(option) =>
                    scatterChartSettingsForm.changeXAxisSpec(option.value)
                  }
                />
                <Select
                  label={'Y'}
                  value={selectedYAxisOptions}
                  options={yAxisOptions}
                  onChange={(option) =>
                    scatterChartSettingsForm.changeYAxisSpec(option.value)
                  }
                />
                <Select
                  label={'Z'}
                  value={selectedZAxisOption}
                  options={zAxisOptions}
                  onChange={(option) =>
                    scatterChartSettingsForm.changeZAxisSpec(option.value)
                  }
                />
                <FieldWithTopLabel label="X Axis">
                  <ControlWithCheckbox
                    control={
                      <CustomDomainView
                        domain={scatterChartSettingsForm.form.xAxis.domain}
                        onChange={scatterChartSettingsForm.changeXAxisDomain}
                      />
                    }
                    checkbox={
                      <Checkbox
                        label="log scale"
                        value={scatterChartSettingsForm.form.xAxis.isLogScale}
                        onChange={scatterChartSettingsForm.toggleXAxisScale}
                      />
                    }
                  />
                </FieldWithTopLabel>
                <FieldWithTopLabel label="Y Axis">
                  <ControlWithCheckbox
                    control={
                      <CustomDomainView
                        domain={scatterChartSettingsForm.form.yAxis.domain}
                        onChange={scatterChartSettingsForm.changeYAxisDomain}
                      />
                    }
                    checkbox={
                      <Checkbox
                        label="log scale"
                        value={scatterChartSettingsForm.form.yAxis.isLogScale}
                        onChange={scatterChartSettingsForm.toggleYAxisScale}
                      />
                    }
                  />
                </FieldWithTopLabel>
              </>
            );
          },
          lineChart: (lineChartSettingsForm) => {
            const xAxisOptions = dataSpecsToGroupedOptions(
              Object.values(lineChartSettingsForm.axisesDataSpecs.xAxis)
                .map((v) => (Array.isArray(v) ? v : [v]))
                .flat()
            );
            const selectedXAxisOption = getSelectedOptionFromGroupedOptions(
              lineChartSettingsForm.form.xAxis.spec,
              xAxisOptions
            );

            const yAxisOptions = keyValueSpecsToGroupedOptions(
              lineChartSettingsForm.axisesDataSpecs.yAxis
            );
            const selectedYAxisOptions = getSelectedOptionsFromGroupedOptions(
              lineChartSettingsForm.form.yAxis.specs,
              yAxisOptions
            );

            return (
              <>
                <Select
                  label={'X'}
                  value={selectedXAxisOption}
                  options={xAxisOptions}
                  onChange={(option) =>
                    lineChartSettingsForm.changeXAxisSpec(option.value)
                  }
                />
                <MultiSelect
                  label={'Y'}
                  value={selectedYAxisOptions}
                  options={yAxisOptions}
                  onChange={(option) =>
                    lineChartSettingsForm.changeYAxisSpec(
                      option.map(({ value }) => value)
                    )
                  }
                />
                <FieldWithTopLabel label="X Axis">
                  <ControlWithCheckbox
                    control={
                      <CustomDomainView
                        domain={lineChartSettingsForm.form.xAxis.domain}
                        onChange={lineChartSettingsForm.changeXAxisDomain}
                      />
                    }
                    checkbox={
                      <Checkbox
                        label="log scale"
                        value={lineChartSettingsForm.form.xAxis.isLogScale}
                        onChange={lineChartSettingsForm.toggleXAxisScale}
                      />
                    }
                  />
                </FieldWithTopLabel>
                <FieldWithTopLabel label="Y Axis">
                  <ControlWithCheckbox
                    control={
                      <CustomDomainView
                        domain={lineChartSettingsForm.form.yAxis.domain}
                        onChange={lineChartSettingsForm.changeYAxisDomain}
                      />
                    }
                    checkbox={
                      <Checkbox
                        label="log scale"
                        value={lineChartSettingsForm.form.yAxis.isLogScale}
                        onChange={lineChartSettingsForm.toggleYAxisScale}
                      />
                    }
                  />
                </FieldWithTopLabel>
              </>
            );
          },
          boxPlot: (boxPlotSettingsForm) => {
            const xAxisOptions = dataSpecsToGroupedOptions(
              Object.values(boxPlotSettingsForm.axisesDataSpecs.xAxis)
                .map((v) => (Array.isArray(v) ? v : [v]))
                .flat()
            );
            const selectedXAxisOption = getSelectedOptionFromGroupedOptions(
              boxPlotSettingsForm.form.xAxis.spec,
              xAxisOptions
            );

            const yAxisOptions = keyValueSpecsToGroupedOptions(
              boxPlotSettingsForm.axisesDataSpecs.yAxis
            );
            const selectedYAxisOptions = getSelectedOptionFromGroupedOptions(
              boxPlotSettingsForm.form.yAxis.spec,
              yAxisOptions
            );

            return (
              <>
                <Select
                  label={'X'}
                  value={selectedXAxisOption}
                  options={xAxisOptions}
                  onChange={(option) =>
                    boxPlotSettingsForm.changeXAxisSpec(option.value)
                  }
                />
                <Select
                  label={'Y'}
                  value={selectedYAxisOptions}
                  options={yAxisOptions}
                  onChange={(option) =>
                    boxPlotSettingsForm.changeYAxisSpec(option.value)
                  }
                />
                <FieldWithTopLabel label="X Axis">
                  <ControlWithCheckbox
                    control={
                      <CustomDomainView
                        domain={boxPlotSettingsForm.form.xAxis.domain}
                        onChange={boxPlotSettingsForm.changeXAxisDomain}
                      />
                    }
                    checkbox={
                      <Checkbox
                        label="log scale"
                        value={boxPlotSettingsForm.form.xAxis.isLogScale}
                        onChange={boxPlotSettingsForm.toggleXAxisScale}
                      />
                    }
                  />
                </FieldWithTopLabel>
                <FieldWithTopLabel label="Y Axis">
                  <ControlWithCheckbox
                    control={
                      <CustomDomainView
                        domain={boxPlotSettingsForm.form.yAxis.domain}
                        onChange={boxPlotSettingsForm.changeYAxisDomain}
                      />
                    }
                    checkbox={
                      <Checkbox
                        label="log scale"
                        value={boxPlotSettingsForm.form.yAxis.isLogScale}
                        onChange={boxPlotSettingsForm.toggleYAxisScale}
                      />
                    }
                  />
                </FieldWithTopLabel>
              </>
            );
          },
          barChart: (barChartSettingsForm) => {
            const xAxisOptions = dataSpecsToGroupedOptions(
              Object.values(barChartSettingsForm.axisesDataSpecs.xAxis)
                .map((v) => (Array.isArray(v) ? v : [v]))
                .flat()
            );
            const selectedXAxisOption = getSelectedOptionFromGroupedOptions(
              barChartSettingsForm.form.xAxis.spec,
              xAxisOptions
            );

            const yAxisOptions = keyValueSpecsToGroupedOptions(
              barChartSettingsForm.axisesDataSpecs.yAxis
            );
            const selectedYAxisOptions = getSelectedOptionsFromGroupedOptions(
              barChartSettingsForm.form.yAxis.specs,
              yAxisOptions
            );

            return (
              <>
                <Select
                  label={'X'}
                  value={selectedXAxisOption}
                  options={xAxisOptions}
                  onChange={(option) =>
                    barChartSettingsForm.changeXAxisSpec(option.value)
                  }
                />
                <MultiSelect
                  label={'Y'}
                  value={selectedYAxisOptions}
                  options={yAxisOptions}
                  onChange={(option) =>
                    barChartSettingsForm.changeYAxisSpec(
                      option.map(({ value }) => value)
                    )
                  }
                />
                <FieldWithTopLabel label="Y Axis">
                  <Controls
                    controls={[
                      <CustomDomainView
                        key={0}
                        domain={barChartSettingsForm.form.yAxis.domain}
                        onChange={barChartSettingsForm.changeYAxisDomain}
                      />,
                      <AggregationTypeSelect
                        key={1}
                        value={barChartSettingsForm.form.yAxis.aggregationType}
                        onChange={
                          barChartSettingsForm.changeYAxisAggregationType
                        }
                      />,
                    ]}
                  />
                </FieldWithTopLabel>
              </>
            );
          },
          parallelCoordinateChart: (parallelCoordinateChartSettingsForm) => {
            const yAxisOptions = keyValueSpecsToGroupedOptions(
              parallelCoordinateChartSettingsForm.axisesDataSpecs.yAxis
            );
            const selectedYAxisOptions = getSelectedOptionsFromGroupedOptions(
              parallelCoordinateChartSettingsForm.form.yAxis.specs,
              yAxisOptions
            );

            const decimalPrecisionOptions = range(1, 7).map((x) => ({
              label: String(x),
              value: x,
            }));
            const selectedDecimalPrecisionOption = getSelectedOption(
              parallelCoordinateChartSettingsForm.form.decimalPrecision,
              decimalPrecisionOptions
            );

            return (
              <>
                <MultiSelect
                  label={'Y'}
                  value={selectedYAxisOptions}
                  options={yAxisOptions}
                  onChange={(option) =>
                    parallelCoordinateChartSettingsForm.changeYAxisSpec(
                      option.map(({ value }) => value)
                    )
                  }
                />
                <Select
                  label={'Decimal Precision'}
                  value={selectedDecimalPrecisionOption}
                  options={decimalPrecisionOptions}
                  onChange={(option) =>
                    parallelCoordinateChartSettingsForm.changeDecimalPrecision(
                      option.value
                    )
                  }
                />
              </>
            );
          },
        })}
      </WidgetFormPopupContent>
    </WidgetFormPopup>
  );
};

function useWidgetSettingsForm({
  keyValueSpecs,
  initialSettings,
  type,
}: {
  keyValueSpecs: KeyValueSpec[];
  initialSettings: WidgetSettings | undefined;
  type: CrossRunWidgetType;
}) {
  const lineChartAxisesDataSpecs = getLineChartAxisesDataSpecs(keyValueSpecs);
  const barChartAxisesDataSpecs = getBarChartAxisesDataSpecs(keyValueSpecs);
  const scatterChartAxisesDataSpecs =
    getScatterChartAxisesDataSpecs(keyValueSpecs);
  const parallelCoordinateChartDataSpecs =
    getParallelCoordinateChartAxisesDataSpecs(keyValueSpecs);
  const tableColumns = getAvailableTableWidgetColumns(keyValueSpecs);

  const [form, changeForm] = React.useState<WidgetSettingsForm>(
    () => initialSettings || getInitialChartSettingsForm('', type)
  );

  const changeName = (name: string) => {
    changeForm({ ...form, name });
  };

  function reset() {
    return changeForm(initialSettings || getInitialChartSettingsForm('', type));
  }

  function getInitialChartSettingsForm(
    formName: string,
    _type: CrossRunWidgetType
  ) {
    return matchType<CrossRunWidgetType, WidgetSettingsForm>(
      {
        barChart: () =>
          getInitialBarChartSettingsForm(formName, barChartAxisesDataSpecs),
        lineChart: () =>
          getInitialLineChartSettingsForm(formName, lineChartAxisesDataSpecs),
        scatterChart: () =>
          getInitialScatterChartSettingsForm(
            formName,
            scatterChartAxisesDataSpecs
          ),
        boxPlot: () =>
          getInitialBoxPlotSettingsForm(formName, lineChartAxisesDataSpecs),
        parallelCoordinateChart: () =>
          getInitialParallelCoordinateChartSettingsForm(
            formName,
            parallelCoordinateChartDataSpecs
          ),
        table: () => getInitialTableWidgetSettingsForm(formName, tableColumns),
        markdown: () => getInitialMarkdownWidgetSettings(formName),
        observationCharts: () =>
          getInitialObservationChartsSettingsForm(formName),
      },
      _type
    );
  }

  switch (form.type) {
    case 'lineChart': {
      const changeXAxisSpec = (spec: LineChartXAxisDataSpec) => {
        changeForm({ ...form, xAxis: { ...form.xAxis, spec } });
      };
      const toggleXAxisScale = () => {
        changeForm({
          ...form,
          xAxis: { ...form.xAxis, isLogScale: !form.xAxis.isLogScale },
        });
      };
      const changeXAxisDomain = (customDomain: CustomDomain) => {
        changeForm({ ...form, xAxis: { ...form.xAxis, domain: customDomain } });
      };

      const changeYAxisSpec = (specs: LineChartYAxisDataSpec[]) => {
        changeForm({
          ...form,
          yAxis: { ...form.yAxis, specs },
        });
      };
      const toggleYAxisScale = () =>
        changeForm({
          ...form,
          yAxis: { ...form.yAxis, isLogScale: !form.yAxis.isLogScale },
        });
      const changeYAxisDomain = (customDomain: CustomDomain) => {
        changeForm({ ...form, yAxis: { ...form.yAxis, domain: customDomain } });
      };

      return {
        widgetType: form.type,
        form,
        changeName,
        changeXAxisSpec,
        toggleXAxisScale,
        changeXAxisDomain,
        changeYAxisSpec,
        toggleYAxisScale,
        reset,
        changeYAxisDomain,
        axisesDataSpecs: lineChartAxisesDataSpecs,
        result: validateLineChartSettingsForm(form),
      };
    }
    case 'boxPlot': {
      const changeXAxisSpec = (spec: BoxPlotXAxisDataSpec) => {
        changeForm({ ...form, xAxis: { ...form.xAxis, spec } });
      };
      const toggleXAxisScale = () => {
        changeForm({
          ...form,
          xAxis: { ...form.xAxis, isLogScale: !form.xAxis.isLogScale },
        });
      };
      const changeXAxisDomain = (customDomain: CustomDomain) => {
        changeForm({ ...form, xAxis: { ...form.xAxis, domain: customDomain } });
      };

      const changeYAxisSpec = (spec: BoxPlotYAxisDataSpec) => {
        changeForm({
          ...form,
          yAxis: { ...form.yAxis, spec },
        });
      };
      const toggleYAxisScale = () =>
        changeForm({
          ...form,
          yAxis: { ...form.yAxis, isLogScale: !form.yAxis.isLogScale },
        });
      const changeYAxisDomain = (customDomain: CustomDomain) => {
        changeForm({ ...form, yAxis: { ...form.yAxis, domain: customDomain } });
      };

      return {
        widgetType: form.type,
        form,
        changeName,
        changeXAxisSpec,
        reset,
        toggleXAxisScale,
        changeXAxisDomain,
        changeYAxisSpec,
        toggleYAxisScale,
        changeYAxisDomain,
        axisesDataSpecs: lineChartAxisesDataSpecs,
        result: validateBoxPlotSettingsForm(form),
      };
    }
    case 'barChart': {
      const changeXAxisSpec = (spec: BarChartXAxisDataSpec) => {
        changeForm({ ...form, xAxis: { ...form.xAxis, spec } });
      };

      const changeYAxisSpec = (specs: BarChartYAxisDataSpec[]) => {
        changeForm({
          ...form,
          yAxis: { ...form.yAxis, specs },
        });
      };
      const changeYAxisDomain = (customDomain: CustomDomain) => {
        changeForm({ ...form, yAxis: { ...form.yAxis, domain: customDomain } });
      };
      const changeYAxisAggregationType = (
        aggregationType: AggregationType | undefined
      ) => {
        changeForm({ ...form, yAxis: { ...form.yAxis, aggregationType } });
      };

      return {
        widgetType: form.type,
        form,
        result: validateBarChartSettingsForm(form),
        changeName,
        changeXAxisSpec,
        reset,
        axisesDataSpecs: barChartAxisesDataSpecs,
        changeYAxisSpec,
        changeYAxisDomain,
        changeYAxisAggregationType,
      };
    }
    case 'scatterChart': {
      const changeXAxisSpec = (spec: ScatterChartXAxisDataSpec) => {
        changeForm({ ...form, xAxis: { ...form.xAxis, spec } });
      };
      const changeXAxisDomain = (customDomain: CustomDomain) => {
        changeForm({ ...form, xAxis: { ...form.xAxis, domain: customDomain } });
      };
      const toggleXAxisScale = () => {
        changeForm({
          ...form,
          xAxis: { ...form.xAxis, isLogScale: !form.xAxis.isLogScale },
        });
      };

      const changeYAxisSpec = (spec: ScatterChartYAxisDataSpec) => {
        changeForm({
          ...form,
          yAxis: { ...form.yAxis, spec },
        });
      };
      const changeYAxisDomain = (customDomain: CustomDomain) => {
        changeForm({ ...form, yAxis: { ...form.yAxis, domain: customDomain } });
      };
      const toggleYAxisScale = () =>
        changeForm({
          ...form,
          yAxis: { ...form.yAxis, isLogScale: !form.yAxis.isLogScale },
        });

      const changeZAxisSpec = (spec: ScatterChartZAxisDataSpec) => {
        changeForm({
          ...form,
          zAxis: { ...form.zAxis, spec },
        });
      };

      return {
        widgetType: form.type,
        form,
        result: validateScatterChartSettingsForm(form),
        changeName,
        changeXAxisSpec,
        changeXAxisDomain,
        toggleXAxisScale,
        reset,
        changeYAxisSpec,
        changeYAxisDomain,
        toggleYAxisScale,
        changeZAxisSpec,
        axisesDataSpecs: scatterChartAxisesDataSpecs,
      };
    }
    case 'parallelCoordinateChart': {
      const changeYAxisSpec = (
        specs: ParallelCoordinateChartYAxisDataSpec[]
      ) => {
        changeForm({
          ...form,
          yAxis: { ...form.yAxis, specs },
        });
      };

      const changeDecimalPrecision = (value: number) => {
        changeForm({
          ...form,
          decimalPrecision: value,
        });
      };

      return {
        widgetType: form.type,
        form,
        result: validateParallelCoordinateChartSettingsForm(form),
        changeYAxisSpec,
        reset,
        changeDecimalPrecision,
        changeName,
        axisesDataSpecs: parallelCoordinateChartDataSpecs,
      };
    }
    case 'table': {
      const changeColumns = (columns: TableWidgetColumn[]) => {
        changeForm({
          ...form,
          columns,
        });
      };

      return {
        widgetType: form.type,
        form,
        reset,
        result: validateTableWidgetSettingsForm(form),
        changeName,
        changeColumns,
        tableColumns,
      };
    }
    case 'markdown': {
      return {
        widgetType: form.type,
        form,
        reset,
        result: validateMarkdownSettingsForm(form),
        changeName,
      };
    }
    case 'observationCharts': {
      return {
        widgetType: form.type,
        form,
        result: validateObservationChartsSettingsForm(form),
        reset,
        changeName,
      };
    }
    default:
      exhaustiveCheck(form);
  }
}

export default WidgetBuilder;
