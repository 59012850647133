import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { ExtractByTypename } from 'shared/utils/types';

import * as GraphqlTypes from './graphql-types/useOrganizationGroups.generated';

export type OrganizationGroups = ExtractByTypename<
  GraphqlTypes.OrganizationGroups['organizationV2ByName'],
  'OrganizationV2'
>;

export const USER_OR_SERVICE_ACCOUNT_FOR_GROUP = gql`
  fragment UserOrServiceAccountForGroup on UserOrServiceAccountV2 {
    ... on ServiceAccountV2 {
      id
      fullName
      email
    }
    ... on UserV2 {
      id
      fullName
      email
    }
  }
`;

export const QUERY = gql`
  query OrganizationGroups($name: String!) {
    organizationV2ByName(name: $name) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        name
        users {
          users {
            ... on UserV2 {
              id
              email
              fullName
            }
            ... on ServiceAccountV2 {
              id
              email
              fullName
            }
          }
        }
        groups {
          id
          name
          description
          builtIn
          members {
            ...UserOrServiceAccountForGroup
          }
          allowedActions {
            ...AllowedActionsData
          }
        }
      }
    }
  }
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${USER_OR_SERVICE_ACCOUNT_FOR_GROUP}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

const useOrganizationGroups = (organizationName: string) => {
  const queryResult = useCustomQuery<
    GraphqlTypes.OrganizationGroups,
    GraphqlTypes.OrganizationGroupsVariables
  >(QUERY, {
    variables: { name: organizationName },
  });

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert: ({ organizationV2ByName }) => organizationV2ByName,
    queryResult,
  });

  return {
    data,
    communication,
  };
};

export default useOrganizationGroups;
