import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
import HomePageContent from 'features/homepage/view/HomePageContent';

export default function HomePage() {
  return (
    <AuthorizedLayout breadcrumbs={undefined} pageEntityOrError={undefined}>
      <HomePageContent />
    </AuthorizedLayout>
  );
}
