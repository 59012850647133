import { useMemo } from 'react';

import { Roc } from 'shared/models/Monitoring/Roc';
import { Size } from 'shared/utils/charts/chartSizes';
import { OnSetReset } from 'shared/utils/charts/zoom/useZoomReset';

import BaseRocChart from '../../shared/BaseRocChart/BaseRocChart';

interface Props {
  roc: Roc[];
  id: string;
  widgetSize: Size;
  onSetReset: OnSetReset;
}

const RocChart = (props: Props) => {
  const xAxis = useMemo(
    () => ({
      label: 'False positive rate',
      groups: props.roc.map((roc) => ({
        modelVersion: roc.modelVersion,
        values: roc.falsePositiveRates,
      })),
    }),
    [props.roc]
  );

  const yAxis = useMemo(
    () => ({
      label: 'True positive rate',
      groups: props.roc.map((roc) => ({
        modelVersion: roc.modelVersion,
        values: roc.truePositiveRates,
      })),
    }),
    [props.roc]
  );

  return (
    <BaseRocChart
      id={props.id}
      xAxis={xAxis}
      yAxis={yAxis}
      noSkillY1={0}
      noSkillY2={1}
      widgetSize={props.widgetSize}
      onSetReset={props.onSetReset}
    />
  );
};

export default RocChart;
