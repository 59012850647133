import { useState } from 'react';
import * as codemirror from 'codemirror';
import 'codemirror-github-light/lib/codemirror-github-light-theme.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/markdown/markdown';
import { Controlled as CodeMirror } from 'react-codemirror2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import Select from 'shared/view/elements/Selects/Select/Select';

interface ILocalProps {
  value: string;
  onChange(value: string): void;
  splitMode?: boolean;
}

const INDENT_MODE_OPTIONS = [
  { label: 'Spaces', value: 'spaces' },
  { label: 'Tabs', value: 'tabs' },
];

const LINE_WRAP_OPTIONS = [
  { label: 'No wrap', value: 'noWrap' },
  { label: 'Soft wrap', value: 'softWrap' },
];

const INDENT_SIZE_OPTIONS = [
  { label: '2', value: 2 },
  { label: '4', value: 4 },
  { label: '8', value: 8 },
];

const CodeMirrorWrapper = styled(Box)`
  .OverrideCodeMirror {
    .CodeMirror {
      height: auto;
      width: 100%;
      .CodeMirror-line {
        font-size: 16px;
      }
    }
  }
`;

const Editor = (props: ILocalProps) => {
  const { value, onChange, splitMode } = props;
  const [indentMode, setIndentMode] = useState(INDENT_MODE_OPTIONS[0]);
  const [lineWrapMode, setLineWrapMode] = useState(LINE_WRAP_OPTIONS[0]);
  const [indentSize, setIndentSize] = useState(INDENT_SIZE_OPTIONS[0]);

  const onCodeChange = (
    _editor: codemirror.Editor,
    _data: codemirror.EditorChange,
    newValue: string
  ) => {
    onChange(newValue);
  };

  return (
    <Stack spacing={3}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          position: 'sticky',
          top: 0,
          paddingLeft: splitMode ? '16px' : undefined,
        }}
      >
        <Box width="176px">
          <Select
            label="Indent mode"
            value={indentMode}
            options={INDENT_MODE_OPTIONS}
            withoutError
            onChange={(selected) =>
              setIndentMode({
                label: selected.label ?? '',
                value: selected.value,
              })
            }
          />
        </Box>
        <Box width="176px">
          <Select
            label="Line wrap mode"
            value={lineWrapMode}
            withoutError
            options={LINE_WRAP_OPTIONS}
            onChange={(selected) =>
              setLineWrapMode({
                label: selected.label ?? '',
                value: selected.value,
              })
            }
          />
        </Box>
        <Box width="176px">
          <Select
            label="Indent size"
            value={indentSize}
            withoutError
            options={INDENT_SIZE_OPTIONS}
            onChange={(selected) =>
              setIndentSize({
                label: selected.label ?? '',
                value: selected.value,
              })
            }
          />
        </Box>
      </Stack>
      <CodeMirrorWrapper
        data-test="markdown-editor"
        sx={{ overflow: splitMode ? 'auto' : undefined }}
      >
        <CodeMirror
          className="OverrideCodeMirror"
          value={value}
          options={{
            mode: 'markdown',
            theme: 'github-light',
            lineNumbers: true,
            lineWrapping: lineWrapMode.value === 'softWrap',
            indentWithTabs: indentMode.value === 'tabs',
            tabSize: indentSize.value,
          }}
          onChange={onCodeChange}
          onBeforeChange={onCodeChange}
        />
      </CodeMirrorWrapper>
    </Stack>
  );
};

export default Editor;
