import React from 'react';

import {
  EndpointEnvironmentComponentStatus,
  BuildStatus,
} from 'generated/types';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import styles from './BuildErrorEye.module.css';
import BuildErrorPopup from '../BuildErrorPopup/BuildErrorPopup';

interface ILocalProps {
  data: IRequiredData;
  workspaceName: string;
  organizationId: string | undefined;
}

const BuildErrorEye: React.FC<React.PropsWithChildren<ILocalProps>> = ({
  data,
  workspaceName,
  organizationId,
}) => {
  const [showingBuildErrorType, changeShowingBuildErrorType] =
    React.useState(false);
  const activeError = getBuildError(data.build.status, data.status);
  return (
    <>
      {showingBuildErrorType && (
        <BuildErrorPopup
          errorInfo={{
            buildId: data.build.id,
            endpointId: data.endpointId,
          }}
          isOpen={true}
          organizationId={organizationId}
          workspaceName={workspaceName}
          onClose={() => changeShowingBuildErrorType(false)}
        />
      )}
      {activeError && (
        <span className={styles.eye}>
          <IconAwesomeClickable
            icon={ICONS.triangleExclamation}
            onClick={() => changeShowingBuildErrorType(true)}
            size={'lg'}
          />
        </span>
      )}
    </>
  );
};

function isBuildError(buildStatus: BuildStatus) {
  return buildStatus === BuildStatus.ERROR;
}

function isDeployError(envStatus: EndpointEnvironmentComponentStatus) {
  return (
    envStatus === EndpointEnvironmentComponentStatus.ERROR ||
    envStatus === EndpointEnvironmentComponentStatus.INTERNAL_ERROR
  );
}

function getBuildError(
  buildStatus: BuildStatus,
  envStatus: EndpointEnvironmentComponentStatus
) {
  if (isBuildError(buildStatus)) {
    return 'buildError';
  }
  if (isDeployError(envStatus)) {
    return 'deployError';
  }
  return undefined;
}

export interface IRequiredData {
  endpointId: string;
  status: EndpointEnvironmentComponentStatus;
  build: {
    id: string;
    status: BuildStatus;
  };
}

export default BuildErrorEye;
