export interface IDockerEnvironmentBlob {
  type: 'docker';
  data: {
    repository: string;
    tag?: string;
    sha?: string;
  };
}
export const makeDockerImage = ({
  repository,
  sha,
  tag,
}: IDockerEnvironmentBlob['data']): string => {
  const shaStr = sha ? `@${sha}` : '';
  const tagStr = tag ? `:${tag}` : '';
  return `${repository}${shaStr}${tagStr}`;
};
