import { useCallback } from 'react';

import {
  BaseMonitoringPanel,
  CustomMonitoringPanel,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringPanel';
import { MonitoringWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidget';
import { updateById } from 'shared/utils/collection';

import { useBaseMonitoringPanel } from './useBaseMonitoringPanel';

export const monitoringWidgetMinSize = 3;
const monitoringWidgetDefaultWidth = 6;
const monitoringWidgetDefaultHeight = 8;

export const useCustomMonitoringPanel = ({
  panel,
  updatePanel,
  deletePanel,
}: {
  panel: CustomMonitoringPanel;
  updatePanel: (panel: CustomMonitoringPanel) => void;
  deletePanel: (panelId: string) => void;
}) => {
  const updateWidget = useCallback(
    (widget: MonitoringWidget) => {
      updatePanel({
        ...panel,
        widgets: updateById(() => widget, widget.id, panel.widgets),
      });
    },
    [panel, updatePanel]
  );

  const deleteWidget = useCallback(
    (widgetId: any) => {
      updatePanel({
        ...panel,
        widgets: panel.widgets.filter((w) => w.id !== widgetId),
        layout: panel.layout.filter((l) => l.i !== widgetId),
      });
    },
    [panel, updatePanel]
  );

  const createWidget = useCallback(
    (widget: MonitoringWidget) => {
      updatePanel({
        ...panel,
        widgets: panel.widgets.concat(widget),
        layout: panel.layout.concat({
          i: widget.id,
          h: monitoringWidgetDefaultHeight,
          w: monitoringWidgetDefaultWidth,
          x: panel.widgets.length % 2 === 0 ? 0 : monitoringWidgetDefaultWidth,
          y: 0,
          minH: monitoringWidgetMinSize,
          minW: monitoringWidgetMinSize,
        }),
      });
    },
    [panel, updatePanel]
  );

  const baseUpdatePanel = useCallback(
    (basePanel: BaseMonitoringPanel<'custom'>) =>
      updatePanel({ ...panel, ...basePanel }),
    [panel, updatePanel]
  );

  const { changePanelOpen, deleteCurrentPanel, updateLayout } =
    useBaseMonitoringPanel({
      panel,
      deletePanel,
      updatePanel: baseUpdatePanel,
    });

  return {
    updateWidget,
    deleteWidget,
    createWidget,
    updateLayout,
    changePanelOpen,
    deleteCurrentPanel,
  };
};
