import * as React from 'react';
import Stack from '@mui/material/Stack';

import { ICommunication } from 'shared/utils/redux/communication';
import Placeholder from 'shared/view/elements/Placeholder/Placeholder';
import matchBy from 'shared/utils/matchBy';
import { isNonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';
import {
  CrossRunDashboardManager,
  DashboardIsSelectedCase,
} from 'features/experimentRuns/crossRunDashboard/store/crossRunDashboardManager/crossRunDashboardManager';
import Widgets from 'features/experimentRuns/crossRunDashboard/view/Widgets/Widgets';
import DashboardSelector from 'features/experimentRuns/crossRunDashboard/view/CrossRunDashboard/DashboardSelector/DashboardSelector';
import ActionsPanel from 'features/experimentRuns/crossRunDashboard/view/CrossRunDashboard/ActionsPanel/ActionsPanel';
import ExperimentRunsLoadingInfo from 'features/experimentRuns/crossRunDashboard/view/CrossRunDashboard/ExperimentRunsLoadingInfo/ExperimentRunsLoadingInfo';
import { OmitStrict } from 'shared/utils/types';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import Panel from 'shared/view/domain/Dashboards/Panel/Panel';
import DnDPanels from 'shared/view/domain/Dashboards/DndPanels/DndPanels';

import WidgetCreator from '../WidgetCreator/WidgetCreator';

interface ILocalProps
  extends OmitStrict<
    React.ComponentProps<typeof CrossRunDashboardManager>,
    'children'
  > {
  loadingInitialExperimentRuns: ICommunication;
  loadingRestExperimentRuns: ICommunication;
}

const CrossRunDashboardView = (props: ILocalProps) => {
  return (
    <CrossRunDashboardManager {...props}>
      {(crossRunDashboardCase) => (
        <div>
          <ExperimentRunsLoadingInfo
            experimentRunsCount={props.experimentRuns.length}
            loadingAdditionalExperimentRuns={props.loadingRestExperimentRuns}
          />
          {matchBy(
            crossRunDashboardCase,
            'type'
          )({
            unknownDashboard: (data) => (
              <div>
                <DashboardSelector
                  disabled={false}
                  dashboardId={undefined}
                  dashboards={data.crossRunDashboards}
                  onChange={data.changeSelectedCrossRunDashboard}
                />
                <Placeholder>The dashboard is not found.</Placeholder>
              </div>
            ),
            emptyExperimentRuns: () => (
              <Placeholder>
                <Stack
                  direction={'row'}
                  spacing={0.5}
                  justifyContent={'center'}
                >
                  <span>
                    This project has no data to visualize. Refer to the api
                    guide for
                  </span>
                  <InlineLink
                    to="https://verta.readthedocs.io/en/master/_autogen/verta.tracking.entities.ExperimentRun.html"
                    isExternal={true}
                    simple={true}
                  >
                    examples
                  </InlineLink>
                </Stack>
              </Placeholder>
            ),
            dashboardIsSelected: (data) => (
              <CrossRunDashboardWithSelectedDashboard {...data} />
            ),
          })}
        </div>
      )}
    </CrossRunDashboardManager>
  );
};

const CrossRunDashboardWithSelectedDashboard = (
  props: DashboardIsSelectedCase
) => {
  return (
    <Stack spacing={3}>
      <div>
        <ActionsPanel {...props} />
        {props.promptUserAboutUnsavedChangesOnLivingRoute}
      </div>
      <div id="dashboard">
        {isNonEmptyArray(props.selectedCrossRunDashboard.panels) ? (
          <DnDPanels
            droppableId={props.selectedCrossRunDashboard.id}
            panels={props.selectedCrossRunDashboard.panels}
            onReorderedPanels={props.updatePanels}
          >
            {({ dragHandleProps, panel }) => (
              <WidgetCreator
                experimentRuns={props.experimentRuns}
                onCreate={props.createWidget.bind(null, panel.id)}
              >
                {(openWidgetCreator) => (
                  <Panel
                    isOpen={props.isPanelOpened(panel.id)}
                    name={panel.name}
                    widgetsCount={panel.widgets.length}
                    dragHandleProps={dragHandleProps}
                    onChangeOpen={props.setPanelExpansion.bind(null, panel.id)}
                    onCreate={openWidgetCreator}
                    onDelete={props.deletePanel.bind(null, panel.id)}
                    onRename={props.renamePanel.bind(null, panel.id)}
                  >
                    <Widgets
                      widgets={panel.widgets}
                      dashboardLayout={panel.layout}
                      experimentRuns={props.experimentRuns}
                      onDelete={props.deleteWidget.bind(null, panel.id)}
                      onEdit={props.editWidget.bind(null, panel.id)}
                      onLayoutChange={props.updatePanelLayout.bind(
                        null,
                        panel.id
                      )}
                    />
                  </Panel>
                )}
              </WidgetCreator>
            )}
          </DnDPanels>
        ) : (
          <Placeholder>You don't have any charts yet.</Placeholder>
        )}
      </div>
    </Stack>
  );
};

export default CrossRunDashboardView;
