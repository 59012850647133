/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import makeTuple from 'shared/utils/makeTuple';
import { FirstArgument, OmitStrict } from 'shared/utils/types';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

import * as GrahpqlTypes from './graphql-types/accessTokensManager.generated';

const CREATE_ACCESS_TOKEN = gql`
  mutation CreateAccessToken(
    $workspaceName: String!
    $organizationId: ID
    $endpointId: ID!
    $environmentId: ID!
    $token: String!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoint(id: $endpointId) {
          ... on Error {
            ...ErrorData
          }
          ... on Endpoint {
            id
            environment(id: $environmentId) {
              id
              createToken(value: $token) {
                id
                tokens {
                  id
                  value
                }
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useCreateAccessTokenMutation = () => {
  return useCustomMutation<
    GrahpqlTypes.CreateAccessToken,
    GrahpqlTypes.CreateAccessTokenVariables
  >(CREATE_ACCESS_TOKEN, { context: 'creating access token' });
};

export const useCreateRandomAccessTokenMutation = () => {
  const [createAccessToken, creatingAccessToken] =
    useCreateAccessTokenMutation();
  const createRandomAccessToken = (
    props: OmitStrict<FirstArgument<typeof createAccessToken>, 'token'>
  ) => {
    createAccessToken({ ...props, token: uuidv4() });
  };

  return makeTuple(createRandomAccessToken, creatingAccessToken);
};

const REMOVE_ACCESS_TOKEN = gql`
  mutation RemoveAccessToken(
    $workspaceName: String!
    $organizationId: ID
    $endpointId: ID!
    $environmentId: ID!
    $tokenId: ID!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoint(id: $endpointId) {
          ... on Error {
            ...ErrorData
          }
          ... on Endpoint {
            id
            environment(id: $environmentId) {
              id
              deleteToken(tokenId: $tokenId) {
                id
                tokens {
                  id
                  value
                }
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useRemoveAccessToken = () => {
  return useCustomMutation<
    GrahpqlTypes.RemoveAccessToken,
    GrahpqlTypes.RemoveAccessTokenVariables
  >(REMOVE_ACCESS_TOKEN, { context: 'removing access token' });
};
