import * as React from 'react';
import { Box, styled } from '@mui/material';

import Preloader from '../Preloader/Preloader';

const GlobalWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  position: 'fixed',
  backgroundColor: theme.palette.background.default,
}));

const GlobalPreloader = React.memo(() => {
  return (
    <GlobalWrapper data-test="global-preloader">
      <Preloader size="big" />
    </GlobalWrapper>
  );
});

export default GlobalPreloader;
