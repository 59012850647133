import React from 'react';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Skeleton, Typography } from '@mui/material';

import { IconAwesomeInfo } from '../IconAwesome/IconAwesomeInfo';
import LinkWrapper from '../LinkWrapper/LinkWrapper';
import { ICONS } from '../IconAwesome/ICONS';

type Props = {
  breadcrumbs: IBreadcrumbItem[];
};

export interface IBreadcrumbItem {
  redirectPath: string;
  isLoaded: boolean;
  name: string;
  dataTest?: string;
  topEnhancer?: React.ReactNode;
  hasTopEnhancer?: boolean;
}

const Breadcrumbs: React.FC<React.PropsWithChildren<Props>> = ({
  breadcrumbs,
}) => {
  return (
    <MuiBreadcrumbs
      separator={<IconAwesomeInfo icon={ICONS.angleRight} size="sm" />}
    >
      {breadcrumbs.map((item, i, items) => (
        <BreadcrumbItem key={i} item={item} isActive={items.length - 1 === i} />
      ))}
    </MuiBreadcrumbs>
  );
};

const BreadcrumbItem = ({
  item,
  isActive,
}: {
  item: IBreadcrumbItem;
  isActive: boolean;
}) => {
  return item.isLoaded ? (
    <Typography
      component={LinkWrapper}
      to={item.redirectPath}
      variant="body2"
      color="text.primary"
      sx={(theme) => ({
        textDecoration: 'none',
        background: 'transparent !important',
        color: isActive
          ? theme.palette.text.primary
          : theme.palette.text.secondary,
      })}
    >
      {isActive ? '' : item.name}
    </Typography>
  ) : isActive ? null : (
    <Typography variant="body2">
      <Skeleton variant="text" width={80} />
    </Typography>
  );
};

export default Breadcrumbs;
