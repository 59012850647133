import { IAttribute } from 'shared/models/Attribute';
import matchType from 'shared/utils/matchType';

type ModelVersionType = 'standard' | 'custom';

export const getRegisteredModelVersionType = (
  attributes: IAttribute[]
): ModelVersionType =>
  Boolean(
    attributes.find(
      (atr) =>
        atr.key === '__verta_reserved__model_type' &&
        atr.value === 'StandardVertaModel'
    )
  )
    ? 'standard'
    : 'custom';

export const registeredModelVersionTypeToString = (type: ModelVersionType) =>
  matchType(
    {
      custom: () => 'Custom',
      standard: () => 'Verta Standard Model',
    },
    type
  );

const anyStandard = (attr: IAttribute) =>
  attr.key === '__verta_reserved__model_type' &&
  attr.value === 'StandardVertaModel';

export const getModelVersionType = (attrs: IAttribute[]): ModelVersionType =>
  Boolean(attrs.find(anyStandard)) ? 'standard' : 'custom';
