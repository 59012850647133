import { useCallback, useMemo, useState } from 'react';

import { CrossRunDashboard } from 'shared/models/CrossRunDashboard/CrossRunDashboard';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import { ICommunication } from 'shared/utils/redux/communication';
import { MakeCrossRunDashboardName } from 'features/experimentRuns/crossRunDashboard/store/crossRunDashboardManager/useCrossRunDashboard/useDashboardActions';
import noop from 'shared/utils/noop';
import Popup from 'shared/view/elements/Popup/Popup';

interface ILocalProps {
  isOpen: true;
  cloning: ICommunication;
  makeCrossRunDashboardName: MakeCrossRunDashboardName;
  onClone(name: CrossRunDashboard['name']): void;
  onRequestClose(): void;
}

const CloneDashboardModal = (props: ILocalProps) => {
  const [newDashboardName, changeDashboardName] = useState<string>('');
  const [touched, setTouched] = useState(false);
  const validatedDashboardName = useMemo(
    () => props.makeCrossRunDashboardName(newDashboardName),
    [newDashboardName, props]
  );

  const onChange = useCallback((value: string) => {
    changeDashboardName(value);
    setTouched(true);
  }, []);

  return (
    <Popup
      isOpen={props.isOpen}
      title="Clone the dashboard"
      onClose={props.onRequestClose}
      buttons={{
        mainButtonProps: {
          type: 'button',
          isLoading: props.cloning.isRequesting,
          children: 'OK',
          ...(validatedDashboardName.type === 'error'
            ? {
                disabled: true,
                onClick: noop,
              }
            : {
                onClick: () => props.onClone(validatedDashboardName.data),
              }),
        },
      }}
    >
      <TextInput
        label={'Name'}
        isRequired={true}
        value={newDashboardName}
        onChange={onChange}
        meta={{
          touched,
          error:
            validatedDashboardName.type === 'error'
              ? validatedDashboardName.error
              : undefined,
        }}
      />
    </Popup>
  );
};

export default CloneDashboardModal;
