import Stack from '@mui/material/Stack';

import WidgetBuilderTab from './WidgetBuilderTab/WidgetBuilderTab';

type LocalProps<T> = {
  infos: Array<WidgetInfo<T>>;
  changeType(type: T): void;
};

export interface WidgetInfo<T> {
  type: T;
  image: React.ReactNode;
  label: string;
  description: string;
}

function WidgetBuilderTabs<T>(props: LocalProps<T>) {
  return (
    <Stack gap={3}>
      {props.infos.map((info, i) => (
        <WidgetBuilderTab key={i} info={info} changeType={props.changeType} />
      ))}
    </Stack>
  );
}

export default WidgetBuilderTabs;
