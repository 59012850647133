/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { DATASET_VERSION_FRAGMENT } from 'shared/graphql/Datasets/DatasetVersion/DatasetVersion';
import { convertGraphqlDatasetVersion } from 'shared/graphql/Datasets/DatasetVersion/converters';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';

import * as Types from './graphql-types/compareDatasetVersions.generated';

const COMPARE_DATASET_VERSIONS = gql`
  query CompareDatasetVersions(
    $datasetId: ID!
    $firstVersionId: ID!
    $secondVersionId: ID!
  ) {
    dataset(id: $datasetId) {
      ... on Error {
        ...ErrorData
      }
      ... on Dataset {
        id
        name
        allowedActions {
          ...AllowedActionsData
        }
        workspace {
          id
          ...WorkspaceData
        }
        datasetVersions(query: { ids: [$firstVersionId, $secondVersionId] }) {
          datasetVersions {
            id
            ...DatasetVersionData
            runs {
              runs {
                id
                name
                projectId
                project {
                  id
                  name
                  workspace {
                    id
                    ...WorkspaceData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${DATASET_VERSION_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;

export const useCompareDatasetVersions = (
  variables: Types.CompareDatasetVersionsVariables
) => {
  const query = useCustomQuery<
    Types.CompareDatasetVersions,
    Types.CompareDatasetVersionsVariables
  >(COMPARE_DATASET_VERSIONS, {
    variables,
  });

  const { data, communication } = resultToCommunicationWithData(
    (res) =>
      convertResponse({
        res,
        firstVersionId: variables.firstVersionId,
        secondVersionId: variables.secondVersionId,
      }),
    query
  );

  const refetch = (newVariables: Types.CompareDatasetVersionsVariables) =>
    query.refetch(newVariables);

  return {
    data,
    communication,
    refetch,
  };
};

const convertResponse = ({
  res,
  firstVersionId,
  secondVersionId,
}: {
  res: Types.CompareDatasetVersions;
  firstVersionId: string;
  secondVersionId: string;
}) => {
  return mapDataOrError(res.dataset, (dataset) => {
    const firstVersion = dataset.datasetVersions.datasetVersions.find(
      (d) => d.id === firstVersionId
    );
    const secondVersion = dataset.datasetVersions.datasetVersions.find(
      (d) => d.id === secondVersionId
    );

    return {
      firstVersion: firstVersion
        ? {
            ...convertGraphqlDatasetVersion(firstVersion),
            experimentRuns: firstVersion.runs.runs,
          }
        : undefined,
      secondVersion: secondVersion
        ? {
            ...convertGraphqlDatasetVersion(secondVersion),
            experimentRuns: secondVersion.runs.runs,
          }
        : undefined,
      dataset: {
        id: dataset.id,
        name: dataset.name,
        workspaceName: dataset.workspace.name,
        allowedActions: dataset.allowedActions,
      },
    };
  });
};
