import { MutationHookOptions } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import {
  UPDATE_EVALUATION_ATTRIBUTES,
  UpdateEvaluationAttributes,
  UpdateEvaluationAttributesVariables,
} from '../graphql';

export const useUpdateEvaluationAttributes = (
  options?: MutationHookOptions<
    UpdateEvaluationAttributes,
    UpdateEvaluationAttributesVariables
  >
) => {
  const [updateEvaluationAttributes, updatingEvaluationAttributes] =
    useCustomMutation<
      UpdateEvaluationAttributes,
      UpdateEvaluationAttributesVariables
    >(
      UPDATE_EVALUATION_ATTRIBUTES,
      { context: 'updating evaluation attributes' },
      { ...options }
    );

  return { updateEvaluationAttributes, updatingEvaluationAttributes };
};
