import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';

import Button from '../../Button/Button';
import ShareLink from '../../ShareLink/ShareLink';

interface ToolbarAction {
  label: string;
  disabled: boolean;
  dataTest?: string;
  onClick: () => void;
}

export interface DataGridToolbarProps {
  toolbar?: {
    withColumnsButton: boolean;
    withShareButton: boolean;
    actions: ToolbarAction[];
  };
}

export const useDataGridToolbarProps = (props: DataGridToolbarProps) => {
  if (props.toolbar) {
    const toolbar = props.toolbar;
    return {
      toolbar: () => <DataGridToolbar {...toolbar} />,
    };
  }

  return {};
};

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  color: theme.palette.text.disabled,
}));

export const useDataGridNoRowsOverlay = (customNoRowsOverlay?: string) => {
  if (customNoRowsOverlay) {
    return {
      noRowsOverlay: () => (
        <StyledGridOverlay>{customNoRowsOverlay}</StyledGridOverlay>
      ),
    };
  }

  return {};
};

const DataGridToolbar = (
  props: Exclude<DataGridToolbarProps['toolbar'], undefined>
) => {
  return (
    <GridToolbarContainer>
      <Stack spacing={2} direction={'row'} style={{ padding: '0 10px' }}>
        {props.actions.map((action) => (
          <Button
            variant={'text'}
            key={action.label}
            disabled={action.disabled}
            dataTest={action.dataTest}
            onClick={action.onClick}
            isLoading={false}
          >
            {action.label}
          </Button>
        ))}

        {props.withColumnsButton ? (
          <GridToolbarColumnsButton
            onResize={undefined}
            onResizeCapture={undefined}
            nonce={undefined}
            touchRippleRef={undefined}
          />
        ) : null}
      </Stack>
      {props.withShareButton ? (
        <div style={{ marginLeft: 'auto' }}>
          <FormControlLabel
            control={<ShareLink link={window.location.href} />}
            label={'Share'}
            labelPlacement={'start'}
          />
        </div>
      ) : null}
    </GridToolbarContainer>
  );
};
