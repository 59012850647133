import { useMemo } from 'react';

import {
  makeFormattedMonitoringFilterString,
  MonitoringFilter,
} from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';
import FiltersList from 'shared/view/domain/Filters/FiltersList/FiltersList';

interface Props {
  filters: MonitoringFilter[];
  removeFilter: (filterId: number) => void;
}

const MonitoringModelFilters = (props: Props) => {
  const formattedFilters = useMemo(
    () =>
      props.filters.map((f) => ({
        id: f.id,
        formattedString: makeFormattedMonitoringFilterString(f),
      })),
    [props.filters]
  );
  return (
    <FiltersList filters={formattedFilters} removeFilter={props.removeFilter} />
  );
};

export default MonitoringModelFilters;
