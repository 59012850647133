import { Box, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';

import ErrorBoundary from 'setup/app/ErrorBoundary';
import { Zendesk } from 'setup/app/ZendexConfig';
import { SEOProvider } from 'shared/view/elements/SEOProvider';
import { componentsSizes } from 'shared/mui/themes/CommonMuiThemeOptions';
import { useNavRouteItems } from 'pages/authorized/shared/hooks/useNavRouteItems';
import {
  OnboardingContextProvider,
  useOnboardingSteps,
} from 'features/homepage/hooks/useOnboarding';

import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100vh - ${componentsSizes.appBar.height}px)`,
  overflow: 'auto',
  marginLeft: componentsSizes.sidebar.width,
  width: '100%',
  minWidth: 580,
  padding: `16px ${componentsSizes.layout.content.padding}px 0px`,
});

export const AuthorizedLayout = () => {
  const navRouteItems = useNavRouteItems();
  const {
    onboardingStep,
    setOnboardingStep,
    isHiddenGettingStarted,
    setIsHiddenGettingStarted,
    isShowingReminder,
    setIsShowingReminder,
    selectedAdvancedFeaturesContent,
    setSelectedAdvancedFeaturesContent,
  } = useOnboardingSteps();

  return (
    <SEOProvider>
      <OnboardingContextProvider
        stepsValues={onboardingStep}
        updateOnboardingSteps={setOnboardingStep}
        hideGettingStared={setIsHiddenGettingStarted}
        isHiddenGettingStarted={isHiddenGettingStarted}
        setIsShowingReminder={setIsShowingReminder}
        isShowingReminder={isShowingReminder}
        selectedAdvancedFeaturesContent={selectedAdvancedFeaturesContent}
        setSelectedAdvancedFeaturesContent={setSelectedAdvancedFeaturesContent}
      >
        <Zendesk />
        <Box
          sx={{ backgroundColor: 'background.default' }}
          data-test="authorized-layout"
        >
          <Header />
          <Box display="flex">
            <Sidebar navRouteItems={navRouteItems} />
            <ErrorBoundary>
              <ContentWrapper id="content-wrapper">
                <Outlet />
              </ContentWrapper>
            </ErrorBoundary>
          </Box>
        </Box>
      </OnboardingContextProvider>
    </SEOProvider>
  );
};
