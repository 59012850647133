import routes from 'shared/routes';

import { RoutesGenerator } from '../types';
import CatalogPage from './CatalogPage/CatalogPage';
import { modelPages } from './ModelPages';
import { modelImportPages } from './ImportedModelsPage';
import { modelVersionPages as versionPages } from './ModelVersionPages';

export const modelVersionPages: RoutesGenerator = {
  getRoutes: () => [...versionPages.getRoutes()],
};

export const catalogPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.catalog.getPath(),
      element: <CatalogPage />,
    },
    ...modelImportPages.getRoutes(),
    ...modelPages.getRoutes(),
  ],
};
