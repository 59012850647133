import { ServerError, ServerParseError } from '@apollo/client';

import { HttpError } from 'shared/models/Error';

import { defaultErrorMessages } from '../customErrorMessages';

export const isServerError = (error: Error): error is ServerError => {
  return error.name === 'ServerError';
};

export const isServerParseError = (error: Error): error is ServerParseError => {
  return error.name === 'ServerParseError';
};

export const getHttpErrorFromServerError = (
  error: ServerError | ServerParseError
): HttpError<undefined> => {
  if (isServerError(error)) {
    return new HttpError({
      status: error.statusCode,
      message: error.message.replace(/Network\s+error:\s+/i, '').trim(),
      isMessageUserFriendly: true,
    });
  }
  return new HttpError({
    status: error.statusCode,
    isMessageUserFriendly: true,
    message:
      error.statusCode === 401
        ? defaultErrorMessages.client_error_401
        : error.message,
  });
};

type NetworkErrorWithStatusCode = Error & Pick<ServerError, 'statusCode'>;
const isNetworkErrorWithStatusCode = (
  error: Error
): error is NetworkErrorWithStatusCode => {
  return 'statusCode' in error;
};

export const isNetworkUnauthorizedError = (error: Error) => {
  return isNetworkErrorWithStatusCode(error) && error.statusCode === 401;
};

export const isNetworkServerParseError = (error: Error) => {
  return (
    isNetworkErrorWithStatusCode(error) &&
    error.statusCode === 200 &&
    isServerParseError(error)
  );
};
