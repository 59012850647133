import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';
import {
  useOnboardingContext,
  AdvancedFeaturesContent,
} from 'features/homepage/hooks/useOnboarding';

import startScan from '../images/start-vulnerability-scanning.png';

export const StartScan = (props: WizardStepContentProps) => {
  const { setSelectedAdvancedFeaturesContent } = useOnboardingContext();

  return (
    <WizardStepContent
      {...props}
      buttons={{
        back: {
          onClick: () =>
            setSelectedAdvancedFeaturesContent(
              AdvancedFeaturesContent.DISCOVER_FEATURES
            ),
          skipWizardBack: true,
        },
      }}
    >
      <Typography variant="subtitle1" mb={2}>
        Start a model vulnerability scan
      </Typography>
      <Typography variant="body2" mb={2}>
        Our scanning feature thoroughly checks the entire deployable container
        for any known vulnerabilities (using Amazon ECR).
      </Typography>
      <Typography variant="body2" mb={3}>
        This quick step can prevent easily avoidable risks that could severely
        impact your business. Just click a button and wait to ensure your
        model's security.
      </Typography>
      <img
        src={startScan}
        alt="start model vulnerability scan"
        width="90%"
        height="auto"
      />
    </WizardStepContent>
  );
};
