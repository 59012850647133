import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { ExtractByTypename } from 'shared/utils/types';
import { namedOperations } from 'generated/named-operations';

import {
  DELETE_EVALUATION_PROJECT,
  DeleteEvaluationProject,
  DeleteEvaluationProjectVariables,
} from '../graphql';

type ResponseModel = ExtractByTypename<
  DeleteEvaluationProject['registeredModel'],
  'RegisteredModel'
>['delete'];

export const useDeleteEvaluationProject = ({
  onSuccess,
}: {
  onSuccess: (registeredModel: ResponseModel) => void;
}) => {
  const [deleteEvaluationProject, deletingEvaluationProject] =
    useCustomMutation<
      DeleteEvaluationProject,
      DeleteEvaluationProjectVariables
    >(
      DELETE_EVALUATION_PROJECT,
      { context: 'deleting registered model' },
      {
        onCompleted: (_res) =>
          isNotNullableRestrictedGraphqlError(_res.registeredModel) &&
          onSuccess(_res.registeredModel.delete),
        refetchQueries: [namedOperations.Query.EvaluationProjectsList],
      }
    );

  return {
    deleteEvaluationProject,
    deletingEvaluationProject,
  };
};
