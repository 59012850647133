import { MutationHookOptions } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import {
  UPDATE_EVALUATION_ROW,
  UpdateEvaluationRow,
  UpdateEvaluationRowVariables,
} from '../graphql';

export const useUpdateEvaluationRow = (
  options?: MutationHookOptions<
    UpdateEvaluationRow,
    UpdateEvaluationRowVariables
  >
) => {
  const [updateEvaluationRow, updatingEvaluationRow] = useCustomMutation<
    UpdateEvaluationRow,
    UpdateEvaluationRowVariables
  >(
    UPDATE_EVALUATION_ROW,
    { context: 'updating evaluation row' },
    { ...options }
  );

  return { updateEvaluationRow, updatingEvaluationRow };
};
