import React from 'react';

import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import routes from 'shared/routes';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import Button from 'shared/view/elements/Button/Button';
import { ProjectCreationPopup } from 'features/projects/projectCreation';

const ProjectsPageContent = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: "ProjectsPageContent" */ './ProjectsPageContent'
    )
);

const ProjectsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <AuthorizedLayout
      pageEntityOrError={undefined}
      breadcrumbs={useAppBreadcrumbs(
        useBreadcrumbItem({ name: 'Projects', route: routes.projects })
      )}
      actions={[
        <ButtonWithPopup
          key={0}
          button={(props) => <Button {...props}>Create</Button>}
          popup={(props) => <ProjectCreationPopup {...props} />}
        />,
      ]}
    >
      <ProjectsPageContent />
    </AuthorizedLayout>
  );
};

export default ProjectsPage;
