import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectFlags } from 'features/flags';

const CHAT_WIDGET_KEY = '6c9b038a-3f08-454d-a93f-5e486b55ece0';

declare global {
  interface Window {
    zE?: (...args: any[]) => void;
    zEInit?: () => void;
    zESettings?: Record<string, unknown>;
  }
}

interface ZendeskProps {
  defer?: boolean;
  initCallback?: () => void;
  zendeskSettings?: Record<string, unknown>;
}

const canUseDOM = !(
  typeof window === 'undefined' ||
  !Boolean(window.document) ||
  !Boolean(window.document.createElement)
);

export const Zendesk: FC<React.PropsWithChildren<ZendeskProps>> = ({
  defer,
  initCallback,
  zendeskSettings = {},
}) => {
  const { isEnableChatWidget } = useSelector(selectFlags);

  useEffect(() => {
    if (!isEnableChatWidget) return;

    const onScriptLoaded = () => {
      window.zEInit?.();
    };

    const insertScript = () => {
      const script = document.createElement('script');
      if (defer) {
        script.defer = true;
      } else {
        script.async = true;
      }
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${CHAT_WIDGET_KEY}`;
      script.addEventListener('load', onScriptLoaded);
      document.body.appendChild(script);
    };

    if (canUseDOM && !window.zE) {
      window.zEInit = initCallback;
      window.zESettings = zendeskSettings;

      insertScript();
    }

    return () => {
      if (!canUseDOM || !window.zE) return;
      delete window.zE;
      delete window.zEInit;
      delete window.zESettings;
      window.removeEventListener('load', onScriptLoaded);
    };
  }, [defer, isEnableChatWidget, initCallback, zendeskSettings]);

  return null;
};
