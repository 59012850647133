import Stack from '@mui/material/Stack';

import { useBaseMonitoringPanel } from 'features/monitoring/panel/store/useBaseMonitoringPanel';
import StaticMonitoringWidget from 'features/monitoring/widgets/view/StaticMonitoringWidget/StaticMonitoringWidget';
import {
  DriftMonitoringPanel,
  OutliersMonitoringPanel,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringPanel';
import { MonitoringWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidget';
import isNotNil from 'shared/utils/isNotNill';
import MonitoringWidgetsGridLayout from 'shared/view/domain/Monitoring/MonitoringWidget/MonitoringWidgetsGridLayout/MonitoringWidgetsGridLayout';
import DraggableAccordion from 'shared/view/elements/DraggableAccordion/DraggableAccordion';
import { draggableHandleClassName } from 'shared/view/elements/IconAwesome/IconAwesomeDraggable';

import { MonitoringPanelProps } from '../MonitoringPanelProps';

interface Props<Panel extends OutliersMonitoringPanel | DriftMonitoringPanel>
  extends MonitoringPanelProps<Panel> {
  widgets: MonitoringWidget[];
  actions: (JSX.Element | null)[];
}

const StableMonitoringPanel = <
  Panel extends OutliersMonitoringPanel | DriftMonitoringPanel,
>(
  props: Props<Panel>
) => {
  const { changePanelOpen, updateLayout } = useBaseMonitoringPanel({
    panel: props.panel,
    updatePanel: props.updatePanel,
    deletePanel: props.deletePanel,
  });

  return (
    <DraggableAccordion
      expanded={props.panel.isOpen}
      onChange={changePanelOpen}
      dragHandleProps={props.dragHandleProps}
      title={props.panel.name}
      summaryRightContent={
        <Stack direction="row">{props.actions.filter(isNotNil)}</Stack>
      }
    >
      <MonitoringWidgetsGridLayout
        layout={props.panel.layout}
        onLayoutChange={updateLayout}
        draggableHandleClassName={draggableHandleClassName}
      >
        {props.widgets.map((widget) => (
          <div key={widget.id}>
            <StaticMonitoringWidget
              widgetExternalDeps={props.widgetExternalDeps}
              widget={widget}
              updateWidget={undefined}
            />
          </div>
        ))}
      </MonitoringWidgetsGridLayout>
    </DraggableAccordion>
  );
};

export default StableMonitoringPanel;
