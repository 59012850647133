import { ActionType, createReducer } from 'typesafe-actions';

import { defaultFlags } from 'shared/models/Flags';
import {
  defaultServiceFlags,
  mergeServiceFlags,
} from 'features/flags/mergeServiceFlags';

import { IFlagsState } from '../types';
import * as actions from '../actions';

export default createReducer<IFlagsState['data'], ActionType<typeof actions>>({
  flags: defaultFlags,
})
  .handleAction(actions.loadFlags.success, (state, action) => ({
    ...state,
    flags: action.payload,
  }))
  .handleAction(actions.loadServiceFlags.success, (state, action) => {
    const mergedFlags = mergeServiceFlags({
      serviceFlags: action.payload.data,
      featureFlags: state.flags,
    });

    return {
      ...state,
      flags: mergedFlags,
    };
  })
  .handleAction(actions.loadServiceFlags.failure, (state) => {
    const mergedFlags = mergeServiceFlags({
      serviceFlags: defaultServiceFlags,
      featureFlags: state.flags,
    });

    return {
      ...state,
      flags: mergedFlags,
    };
  })
  .handleAction(actions.updateFeatureFlag, (state, action) => {
    return {
      ...state,
      flags: action.payload,
    };
  });
