import Stack from '@mui/material/Stack';

import CopyButton from 'shared/view/elements/CopyButton/CopyButton';
import ReadonlyInputWithSecret from 'shared/view/elements/ReadonlyInput/ReadonlyInputWithSecret';

type Props = {
  token: string;
};

export default function ActiveDirectoryConfigurationToken({ token }: Props) {
  return (
    <Stack direction={'row'} alignItems={'center'} alignContent={'center'}>
      <ReadonlyInputWithSecret value={token} label="Token" />
      <CopyButton copyText={token} />
    </Stack>
  );
}
