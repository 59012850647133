import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  DEFAULT_PAGE_SIZE,
  INITIAL_PAGINATION_PAGE,
} from 'shared/models/Pagination';
import * as PaginationInURL from 'shared/models/Pagination/PaginationInURL';

const useBasePagination = ({
  initialPage,
  totalCount,
  initialPageSize,
}: {
  totalCount: number;
  initialPageSize?: number;
  initialPage?: number;
}) => {
  const [page, changePage] = useState(initialPage ?? INITIAL_PAGINATION_PAGE);
  const [pageSize, changePageSize] = useState(
    initialPageSize ?? DEFAULT_PAGE_SIZE
  );

  const pagination = useMemo(
    () => ({
      currentPage: page,
      pageSize,
      totalCount,
    }),
    [page, pageSize, totalCount]
  );

  const onPageChange = useCallback(
    (currentPage: number) => {
      if (page !== currentPage) {
        changePage(currentPage);
      }
    },
    [page]
  );

  const onPageSizeChange = useCallback(
    (currentPageSize: number) => {
      if (pageSize !== currentPageSize) {
        changePageSize(currentPageSize);
      }
    },
    [pageSize]
  );

  return {
    pagination,
    onPageChange,
    onPageSizeChange,
  };
};

export const usePagination = (props: {
  totalCount: number;
  pageSize?: number;
  additionalActionOnPageChange?: (currentPage: number) => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    pagination,
    onPageChange: onPageChangeBase,
    onPageSizeChange: onPageSizeChangeBase,
  } = useBasePagination({
    initialPage: PaginationInURL.getPageFromURL(location.search),
    totalCount: props.totalCount,
    initialPageSize: PaginationInURL.getPageSizeFromURL(
      location.search,
      props.pageSize
    ),
  });

  const onCurrentPageChange = useCallback(
    (currentPage: number) => {
      onPageChangeBase(currentPage);
      PaginationInURL.changePageInURL(navigate, currentPage);
      props.additionalActionOnPageChange?.(currentPage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, onPageChangeBase]
  );

  const onCurrentPageSizeChange = useCallback(
    (currentPageSize: number) => {
      onPageSizeChangeBase(currentPageSize);
      PaginationInURL.changePageSizeInURL(navigate, location, currentPageSize);
    },
    [navigate, location, onPageSizeChangeBase]
  );

  const onResetCurrentPage = useCallback(() => {
    onCurrentPageChange(0);
  }, [onCurrentPageChange]);

  return {
    pagination,
    onCurrentPageChange,
    onCurrentPageSizeChange,
    onResetCurrentPage,
  };
};

/** required for totalCount memoization when data become undefined */
export const useDataGridPagination = <T>({
  data,
  pageSize,
}: {
  data: (T & { totalCount: number }) | undefined;
  pageSize?: number;
}) => {
  const [totalCount, setTotalCount] = useState(0);
  const paginationApi = usePagination({
    totalCount,
    pageSize,
  });

  useEffect(() => {
    if (data) {
      setTotalCount(data.totalCount);
    }
  }, [data]);

  return paginationApi;
};
