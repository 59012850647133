import { isNil } from 'lodash';

// todo rename filename
export default function isNotNil<T>(
  item: T | null | undefined
): item is NonNullable<T> {
  return !isNil(item);
}

export function getValueOrDefault<T>(
  defaultValue: T,
  value: T | null | undefined
): T {
  return isNotNil(value) ? value : defaultValue;
}

export function getFirstNonNullWithDefault<T>(
  defaultValue: T,
  values: (T | null | undefined)[]
): T {
  for (const value of values) {
    if (isNotNil(value)) {
      return value;
    }
  }
  return defaultValue;
}
