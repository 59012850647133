import { FieldArray } from 'formik';
import Stack from '@mui/material/Stack';

import Tag from 'shared/view/domain/Labels/TagsManager/Tag/Tag';
import { ContentWrapper } from 'shared/view/domain/Labels/TagsManager/styled-components';

import TextInputTag from './TextInputTag';

const TagsField = ({ name, label }: { name: string; label: string }) => {
  return (
    <FieldArray name={name}>
      {(arrayHelpers) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const vals: string[] = arrayHelpers.form.values[arrayHelpers.name];

        return (
          <Stack>
            <TextInputTag
              label={label}
              onAdd={(tag) => {
                if (!vals.some((e) => e === tag)) {
                  arrayHelpers.push(tag);
                }
              }}
            />
            <ContentWrapper>
              {vals.map((val: string, i: number) => (
                <Tag
                  key={i}
                  value={val}
                  isEditable
                  isUpdating={false}
                  withConfirmation={false}
                  onDelete={(deletedTag: string) => {
                    arrayHelpers.remove(
                      vals.findIndex((tag: string) => tag === deletedTag)
                    );
                  }}
                />
              ))}
            </ContentWrapper>
          </Stack>
        );
      }}
    </FieldArray>
  );
};

export default TagsField;
