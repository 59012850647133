import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useModelVersionSummary } from 'features/catalog/registeredModelVersion/summary/store/useModelVersionSummary';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

const ModelVersionSummary = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ModelVersionSummary' */ 'features/catalog/registeredModelVersion/summary/view/summary'
    )
);

const SummaryPage = () => {
  const { versionId } = useRouteParams(routes.modelVersionSummary);
  const { version, loadingVersion, reload } = useModelVersionSummary({
    versionId,
  });

  return (
    <DefaultMatchRemoteDataOrError
      communication={loadingVersion}
      data={version}
      context="loading model version"
    >
      {(loadedModelVersion) => (
        <ModelVersionSummary
          version={loadedModelVersion}
          reloadModelVersion={reload}
          isLoadingModel={loadingVersion.isRequesting}
        />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default SummaryPage;
