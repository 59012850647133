import { NonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';

import {
  filterKeyValueSpecsByType,
  KeyValueDataType,
  KeyValueSpec,
} from '../KeyValueSpec';
import { CommonWidgetSettings } from './shared/CommonWidgetSettings';

export type ParallelCoordinateChartYAxisDataSpec = KeyValueSpec<
  KeyValueDataType,
  'metric' | 'hyperparameter'
>;

export interface ParallelCoordinateChartSettings
  extends CommonWidgetSettings<'parallelCoordinateChart'> {
  yAxis: {
    specs: NonEmptyArray<ParallelCoordinateChartYAxisDataSpec>;
  };
  decimalPrecision: number;
}

export interface ParallelCoordinateChartAxisesDataSpec {
  yAxis: ParallelCoordinateChartYAxisDataSpec[];
}

export const getParallelCoordinateChartAxisesDataSpecs = (
  keyValueSpecs: KeyValueSpec[]
): ParallelCoordinateChartAxisesDataSpec => {
  const metricSpecs = filterKeyValueSpecsByType('metric', keyValueSpecs);
  const hyperparameterSpecs = filterKeyValueSpecsByType(
    'hyperparameter',
    keyValueSpecs
  );

  return {
    yAxis: [...hyperparameterSpecs, ...metricSpecs],
  };
};
