import Stack, { StackProps } from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { pluck, sum } from 'ramda';

interface ItemProps {
  value: number;
  color?: string;
  label?: string;
}

export interface StatsBarProps extends StackProps {
  items: ItemProps[];
  height?: number | string;
}

export const StatsBar = (props: StatsBarProps) => {
  const { items, height = '8px', ...other } = props;
  const sumValues = sum(pluck('value')(items));

  const numberHeight = Number(String(height).replace(/[^0-9]/g, ''));

  return (
    <Stack direction="row" spacing={0.5} {...other}>
      {items.map(({ value, label, color }, index) =>
        value > 0 ? (
          <Box
            key={label || `stats-${value}-${index}`}
            sx={{
              backgroundColor: color || 'additionalBackground.inactive',
              borderRadius: `${numberHeight / 2}px`,
              height: `${numberHeight}px`,
              width: `${(value / sumValues) * 100}%`,
              minWidth: `${numberHeight}px`,
            }}
          />
        ) : null
      )}
    </Stack>
  );
};
