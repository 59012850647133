import { SnackbarKey, VariantType } from 'notistack';
import { ActionType, createAction } from 'typesafe-actions';

import { ActionResult } from 'setup/store/store';
import { AppError, isHttpError } from 'shared/models/Error';
import { hasKey } from 'shared/utils/collection';
import { appErrorToString } from 'shared/view/elements/Errors/helpers';

import { Toast } from './types';

export const toastAction = createAction('@@toast/TOAST_ACTION')<{
  message: Toast['message'];
  variant: VariantType;
}>();

export const createToastAction = createAction('@@toast/CREATE_TOAST')<Toast>();

export const removeToastAction = createAction(
  '@@toast/REMOVE_TOAST'
)<SnackbarKey>();

export const closeToastAction = createAction(
  '@@toast/CLOSE_TOAST'
)<SnackbarKey>();

export const toastCommunicationErrorActionCreator =
  <T extends AppError, B extends string>(
    communicationError: T,
    options: {
      toastId?: string;
      context: string;
      customErrorMessageByType?: T extends AppError<infer ErrorType>
        ? ErrorType extends B
          ? Record<B, string>
          : never
        : never;
    }
  ): ActionResult<
    void,
    ActionType<typeof createToastAction | typeof closeToastAction>
  > =>
  (dispatch) => {
    const errorStr = (() => {
      if (options.customErrorMessageByType && isHttpError(communicationError)) {
        return (
          (hasKey(communicationError.type, options.customErrorMessageByType) &&
            options.customErrorMessageByType[communicationError.type]) ||
          appErrorToString(communicationError, options.context)
        );
      } else {
        return appErrorToString(communicationError, options.context);
      }
    })();

    dispatch(
      createToastAction({
        key: errorStr,
        dismissed: false,
        message: errorStr,

        options: {
          variant: 'error',
          persist: true,
          preventDuplicate: true,
          onClose: () => dispatch(closeToastAction(errorStr)),
        },
      })
    );
  };
