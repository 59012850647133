import * as CrossRunDashboard from 'shared/models/CrossRunDashboard/CrossRunDashboard';
import noop from 'shared/utils/noop';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { Result } from 'shared/utils/result';
import { areThereAtLeast2Items } from 'shared/utils/collection';
import { useSaveDashboardChanges } from 'features/experimentRuns/crossRunDashboard/store/saveDashboardChanges/saveDashboardChanges';
import useCloneDashboard from 'features/experimentRuns/crossRunDashboard/store/cloneDashboard/cloneDashboard';
import { useDeleteDashboard } from 'features/experimentRuns/crossRunDashboard/store/deleteDashboard/deleteDashboard';

import { IUseCrossRunDashboardProps } from './IUseCrossRunDashboardProps';

export const useDashboardActions = (props: IUseCrossRunDashboardProps) => {
  const isDashboardChanged = !CrossRunDashboard.equals(
    props.selectedCrossRunDashboard,
    props.initialSelectedCrossRunDashboard
  );
  const dashboardChangesActions = useDashboardChangesActions({
    ...props,
    isDashboardChanged,
  });
  const cloneDashboardAction = useCloneDashboardAction(
    props,
    isDashboardChanged
  );
  const deleteDashboardAction = useDeleteDashboardAction(props);
  const selectDashboardAction = isDashboardChanged
    ? {
        disabled: true,
        selectDashboard: noop,
      }
    : {
        disabled: false,
        selectDashboard: props.changeSelectedCrossRunDashboard,
      };

  return {
    dashboardChangesActions,
    cloneDashboardAction,
    deleteDashboardAction,
    selectDashboardAction,
  };
};

export type MakeCrossRunDashboardName = (
  str: string
) => Result<CrossRunDashboard.CrossRunDashboard['name'], string>;

function useDashboardChangesActions(
  props: IUseCrossRunDashboardProps & { isDashboardChanged: boolean }
) {
  const { saveDashboardChanges: __saveDashboardChanges, savingDashboard } =
    useSaveDashboardChanges(noop);

  return props.isDashboardChanged
    ? ({
        save: {
          savingDashboard,
          saveDashboardChanges: () =>
            __saveDashboardChanges({
              dashboards: props.crossRunDashboards,
              projectId: props.project.id,
              updatedDashboard: props.selectedCrossRunDashboard,
            }),
          disabled: !props.project.allowedActions.update,
        },
        reset: {
          resetDashboardChanges: () =>
            props.changeSelectedCrossRunDashboard(
              props.initialSelectedCrossRunDashboard
            ),
        },
      } as const)
    : undefined;
}
function useDeleteDashboardAction({
  crossRunDashboards,
  selectedCrossRunDashboard,
  project,
  changeSelectedCrossRunDashboard,
}: IUseCrossRunDashboardProps) {
  const { deleteDashboard: __deleteDashboard, deletingDashboard } =
    useDeleteDashboard();
  return areThereAtLeast2Items(crossRunDashboards)
    ? {
        deletingDashboard,
        deleteDashboard: () =>
          __deleteDashboard(
            (dashboardsAfterDeleting) => {
              changeSelectedCrossRunDashboard(dashboardsAfterDeleting[0]);
            },
            {
              dashboardId: selectedCrossRunDashboard.id,
              dashboards: crossRunDashboards,
              projectId: project.id,
            }
          ),
      }
    : undefined;
}
function useCloneDashboardAction(
  props: IUseCrossRunDashboardProps,
  isDashboardChanged: boolean
) {
  const {
    closePopup: closeCloneDashboard,
    isPopupOpen: isCloneDashboardOpen,
    openPopup: openCloneDashboard,
  } = usePopupManager();

  const { cloneDashboard: __cloneDashboard, cloningDashboard } =
    useCloneDashboard((newDashboard) => {
      closeCloneDashboard();
      props.changeSelectedCrossRunDashboard(newDashboard);
    });
  const cloneDashboard = (
    newDashboardName: CrossRunDashboard.CrossRunDashboard['name']
  ) =>
    __cloneDashboard({
      dashboards: props.crossRunDashboards,
      dashboardId: props.selectedCrossRunDashboard.id,
      projectId: props.project.id,
      newDashboardName,
    });

  return isDashboardChanged
    ? undefined
    : {
        cloneDashboard,
        cloningDashboard,
        closeCloneDashboard,
        isCloneDashboardOpen,
        openCloneDashboard,
        makeCrossRunDashboardName: (str: string) =>
          CrossRunDashboard.makeCrossRunDashboardName(
            props.crossRunDashboards,
            str
          ),
      };
}
