import isNotNil from './isNotNill';
import { Primitive } from './types';

export type JsonData = Primitive | JsonObject | JsonList;

interface JsonList extends Array<JsonData> {}

export interface JsonObject {
  [k: string]: JsonData;
}

export const safeParseJson = <T>(jsonString: string): T | undefined => {
  try {
    return JSON.parse(jsonString) as T;
  } catch {
    return undefined;
  }
};

export const isValidStringJson = (json: string) => {
  try {
    if (json) {
      JSON.parse(json);
    }
    return true;
  } catch {
    return false;
  }
};

export const jsonPrettify = (json: string): string => {
  if (typeof json === 'object') {
    return JSON.stringify(json, undefined, 4);
  }
  // It is a recursive function to return an object if it has been stringified multiple times
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const obj: string = JSON.parse(json);
    return jsonPrettify(obj);
  } catch (e: unknown) {
    return json;
  }
};

export const jsonToString = (json: JsonData) => {
  return isNotNil(json) ? JSON.stringify(json, undefined, 4) : '';
};

export const isJsonObject = (arg: unknown): arg is Record<string, unknown> =>
  isNotNil(arg) && typeof arg === 'object';

export const traverseJsonObjects = (
  handle: (obj: Record<string, JsonData>) => void,
  json: JsonData
): void => {
  if (isJsonObject(json)) {
    handle(json);
    Object.values(json).forEach((value) => traverseJsonObjects(handle, value));
  }
};

export const traverseJson = (
  handle: (key: string, value: JsonData) => JsonData,
  json: JsonData
) => {
  if (isJsonObject(json) || Array.isArray(json)) {
    Object.entries(json).forEach(([k, v]) => {
      (json as any)[k] = handle(k, v);
      if (isJsonObject(v)) {
        traverseJson(handle, v);
      }
    });
  }
};
