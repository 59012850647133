import {
  DraggableProvidedDragHandleProps,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { ReactNode, useCallback } from 'react';
import { styled } from '@mui/material';

import { IconAwesomeDraggable } from '../IconAwesome/IconAwesomeDraggable';
import { PlaceholderProps } from './usePanelsDragAndDrop';
import Accordion from '../Accordion/Accordion';
import { ICONS } from '../IconAwesome/ICONS';

const DraggableAccordion = (props: {
  expanded: boolean;
  onChange: (isOpen: boolean) => void;
  children: ReactNode;
  title: string;
  summaryRightContent: ReactNode;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  dataTest?: string;
}) => {
  const { onChange: onChangeOpen } = props;
  const onAccordionChange = useCallback(
    (_: any, expanded: boolean) => onChangeOpen(expanded),
    [onChangeOpen]
  );

  return (
    <Accordion
      dataTest={props.dataTest}
      summaryLeftContent={
        <div {...props.dragHandleProps}>
          <IconAwesomeDraggable size="lg" icon={ICONS.gripDotsVertical} />
        </div>
      }
      summaryRightContent={props.summaryRightContent}
      title={props.title}
      expanded={props.expanded}
      onChange={onAccordionChange}
    >
      {props.children}
    </Accordion>
  );
};

export const DraggableAccordionDroppableArea = (props: {
  children: ReactNode;
  droppable: DroppableProvided;
  placeholderProps: PlaceholderProps | null;
  snapshot: DroppableStateSnapshot;
}) => {
  return (
    <div
      {...props.droppable.droppableProps}
      style={{
        position: 'relative',
      }}
      ref={props.droppable.innerRef}
    >
      {props.children}

      {props.droppable.placeholder}

      {props.placeholderProps && props.snapshot.isDraggingOver && (
        <Placeholder
          style={{
            top: props.placeholderProps.clientY,
            left: props.placeholderProps.clientX,
            height: props.placeholderProps.clientHeight,
            width: props.placeholderProps.clientWidth,
          }}
        />
      )}
    </div>
  );
};

const Placeholder = styled('div')(({ theme }) => {
  return {
    ...theme.palette.gridLayout.placeholder,
    position: 'absolute',
    boxSizing: 'border-box',
    opacity: 1,
    borderRadius: '4px',
  };
});

export default DraggableAccordion;
