import { pipe } from 'ramda';

import isNotNil from 'shared/utils/isNotNill';

import { appObfuscators } from './appGraphqlObfuscators';
import * as GraphqlRequestResponseObfuscator from './graphqlRequestResponseObfuscator/graphqlRequestResponseObfuscator';
import { GraphqlRequestResponse } from './GraphqlRequestResponse';
import { RestApiRequestResponse } from './RestApiRequestResponse';

export type RequestResponse = GraphqlRequestResponse | RestApiRequestResponse;

export const obfuscateRequestResponses = (
  requestResponses: RequestResponse[]
) => {
  const indexedRequestResponses = requestResponses.map((r, i) => ({
    ...r,
    idx: i,
  }));

  return pipe(
    () =>
      indexedRequestResponses
        .map((r) => (r.type === 'graphql' ? r : undefined))
        .filter(isNotNil),
    (x) => GraphqlRequestResponseObfuscator.obfuscate(appObfuscators, x),
    (graphqlReqRes) =>
      indexedRequestResponses.map(({ idx, ...reqRes }) =>
        reqRes.type === 'graphql'
          ? graphqlReqRes.find((x) => x.idx === idx) || reqRes
          : reqRes
      )
  )();
};
