/* eslint-disable rulesdir/no-deprecated-fields */
import { ApolloClient } from '@apollo/client';
import { gql } from '@apollo/client';

import {
  IRegistrationByEmail,
  ILoginByEmail,
  RegistrationByEmailErrors,
  LoginByEmailErrors,
  AuthExternalServiceProvider,
  ILoginByLdap,
  RegistrationByEmail,
} from 'shared/models/Authorization';
import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';
import { CustomApiErrorConverter, HttpActions } from 'services/HttpActions';
import {
  convertServerUser,
  convertServerCurrentUser,
} from 'services/serverModel/User/converters';
import { IServerUserInfo } from 'services/serverModel/User/User';
import { CURRENT_USER_FRAGMENT } from 'shared/graphql/User/User';
import { UpdateUserInfo } from 'features/workspaces/store/types';

import * as CurrentUserQuery from './graphql-types/AuthenticationService.generated';

const UPDATE_USER_PATH = '/v1/uac-proxy/uac/updateUser';

export default class AuthenticationService {
  static loginByExternalService(
    authExternalService: AuthExternalServiceProvider,
    redirectBack: string | undefined,
    signUp?: boolean
  ): void {
    const searchParams = new URLSearchParams();
    if (redirectBack) {
      searchParams.set(
        'returnTo',
        `${redirectBack + '?provider=' + authExternalService}`
      );
    }
    if (signUp) {
      searchParams.set('signUp', 'true');
    }
    const url = `/api/auth/login/${authExternalService}?${searchParams.toString()}`;

    window.location.replace(url);
  }

  static async loginByEmail(
    apolloClient: ApolloClient<unknown>,
    { email, password }: ILoginByEmail
  ): Promise<CurrentUserData> {
    await HttpActions.post<unknown, keyof typeof LoginByEmailErrors>({
      url: '/auth/login',
      data: { email, password },
      config: {
        headers: {
          'Grpc-Metadata-source': 'PythonClient',
        },
      },
      errorConverters: {
        invalidEmailOrPassword: ({ status }) => status === 401,
      },
    });
    const { self } = await AuthenticationService.loadCurrentUser(apolloClient);
    return self;
  }

  static async logout() {
    window.location.replace('/api/auth/logout');
  }

  static async loginByLdap(
    apolloClient: ApolloClient<unknown>,
    { username, password, organizationUnit }: ILoginByLdap
  ): Promise<CurrentUserData> {
    await HttpActions.post<unknown, keyof typeof LoginByEmailErrors>({
      url: '/auth/login_ldap',
      data: {
        username,
        password,
        ...(organizationUnit ? { organizationUnit } : undefined),
      },
      config: {
        headers: {
          'Grpc-Metadata-scheme': 'https',
          'Grpc-metadata-source': 'PythonClient',
        },
        responseType: 'text',
      },
      errorConverters: {
        invalidEmailOrPassword: ({ status }) => status === 401,
      },
    });
    const { self } = await AuthenticationService.loadCurrentUser(apolloClient);
    return self;
  }

  static async loadCurrentUser(
    apolloClient: ApolloClient<unknown>
  ): Promise<CurrentUserQuery.CurrentUser> {
    const { data } = await apolloClient.query<CurrentUserQuery.CurrentUser>({
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'no-cache',
    });

    return data;
  }

  static async registerUser(
    registrationInfo: IRegistrationByEmail,
    createUserToken: string
  ): Promise<void> {
    await HttpActions.post<unknown, keyof typeof RegistrationByEmailErrors>({
      url: '/v1/uac-proxy/uac/createUser',
      data: {
        info: {
          full_name: `${registrationInfo.firstName} ${registrationInfo.lastName}`,
          first_name: registrationInfo.firstName,
          last_name: registrationInfo.lastName,
          email: registrationInfo.email,
          verta_info: {
            username: registrationInfo.username,
          },
        },
        password: registrationInfo.password,
      },
      config: {
        headers: {
          'Grpc-metadata-source': 'PythonClient',
          'grpc-metadata-create_user_token': createUserToken,
        },
      },
      errorConverters: {
        emailAlreadyExists: this.isEmailAlreadyExistError,
        usernameAlreadyExists: this.isUsernameAlreadyExist,
        companyAlreadyExists: this.isCompanyAlreadyExistError,
      },
    });
  }

  static async registerUserByEmail(
    registrationInfo: RegistrationByEmail,
    createUserToken: string
  ): Promise<{ currentUser: CurrentUserData }> {
    const { data } = await HttpActions.post<
      {
        info: IServerUserInfo | undefined;
      },
      keyof typeof RegistrationByEmailErrors
    >({
      url: '/v1/uac-proxy/uac/createUser',
      data: {
        info: {
          email: registrationInfo.email,
        },
        password: registrationInfo.password,
      },
      config: {
        headers: {
          'Grpc-metadata-source': 'PythonClient',
          'grpc-metadata-create_user_token': createUserToken,
        },
      },
      errorConverters: {
        emailAlreadyExists: this.isEmailAlreadyExistError,
        usernameAlreadyExists: this.isUsernameAlreadyExist,
        companyAlreadyExists: this.isCompanyAlreadyExistError,
      },
    });

    return { currentUser: convertServerCurrentUser(data.info) };
  }

  static async updateUserInfo({
    id,
    firstName,
    lastName,
    fullName,
    company,
    jobTitle,
    email,
  }: UpdateUserInfo): Promise<void> {
    await HttpActions.post<{
      info: IServerUserInfo | undefined;
    }>({
      url: UPDATE_USER_PATH,
      data: {
        info: {
          verta_info: {
            user_id: id,
          },
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          full_name: fullName.trim(),
          company: company,
          job_title: jobTitle,
          email,
        },
      },
      errorConverters: {
        companyAlreadyExists: ({ serverErrorResponse }) =>
          Boolean(serverErrorResponse && serverErrorResponse.code === 3),
      },
    });
  }

  static async updateUsername(
    currentUserId: string,
    username: string
  ): Promise<string> {
    const response = await HttpActions.post<{
      info: IServerUserInfo | undefined;
    }>({
      url: UPDATE_USER_PATH,
      data: {
        info: {
          verta_info: {
            user_id: currentUserId,
            username,
          },
        },
      },
      errorConverters: {
        usernameAlreadyExists: ({ serverErrorResponse }) =>
          Boolean(serverErrorResponse && serverErrorResponse.code === 6),
      },
    });
    return convertServerUser(response.data.info).username;
  }

  static async updateDefaultWorkspace(
    apolloClient: ApolloClient<unknown>,
    currentUserId: string,
    defaultWorkspaceId: string
  ) {
    await HttpActions.post<unknown>({
      url: UPDATE_USER_PATH,
      data: {
        info: {
          verta_info: {
            user_id: currentUserId,
          },
        },
        default_workspace_id: defaultWorkspaceId,
      },
    });
    const { self } = await this.loadCurrentUser(apolloClient);
    return self.defaultWorkspaceId;
  }

  private static isEmailAlreadyExistError: CustomApiErrorConverter = ({
    status,
    serverErrorResponse,
  }) =>
    status === 409 &&
    Boolean(
      serverErrorResponse?.message
        .toLocaleLowerCase()
        .includes('email already exists')
    );

  private static isUsernameAlreadyExist: CustomApiErrorConverter = ({
    status,
    serverErrorResponse,
  }) =>
    status === 409 &&
    Boolean(
      serverErrorResponse?.message
        .toLocaleLowerCase()
        .includes('username already exists')
    );

  private static isCompanyAlreadyExistError: CustomApiErrorConverter = ({
    status,
    serverErrorResponse,
  }) =>
    status === 400 &&
    Boolean(
      serverErrorResponse?.message
        .toLocaleLowerCase()
        .includes('company already exists')
    );
}

const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    self {
      id
      ...CurrentUserData
    }
    organizations {
      id
    }
    organizationsV2 {
      ... on OrganizationsV2Response {
        organizations {
          id
        }
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;
