import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Box, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material';
import { head } from 'ramda';
import { useRef } from 'react';

import { useSingleMetric } from 'features/monitoring/widgets/store/singleMetric/useSingleMetric';
import { MonitoringMetricType } from 'generated/types';
import { getLabelByMonitoringMetricType } from 'shared/models/Monitoring/MonitoringMetricType';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { formatWithDefaultPrecision } from 'shared/utils/formatters/formatWithDefaultPrecision';
import routes from 'shared/routes';
import { useHover } from 'shared/utils/react/useHover';

import { OverviewWidgetWrapperHoverable } from '../shared/OverviewWidget/OverviewWidget';
import MonitoringOverviewCardDetails from './MonitoringOverviewCardDetails/MonitoringOverviewCardDetails';

interface Props {
  metricType: MonitoringMetricType;
  icon: IconDefinition;
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  output: MonitoringIODescription;
  isLast: boolean;
}

const MonitoringOverviewCard = (props: Props) => {
  const { data, communication } = useSingleMetric({
    metricType: props.metricType,
    output: props.output,
    widgetExternalDeps: props.widgetExternalDeps,
  });

  const { handlers, isHovered } = useHover();

  return (
    <OverviewWidgetWrapperHoverable {...handlers} minHeight="263px">
      <DefaultMatchRemoteDataOrError
        data={data}
        communication={communication}
        context="loading metric"
      >
        {(metrics) => {
          const metric = head(metrics);
          return metric ? (
            <CardContent
              icon={props.icon}
              metricType={props.metricType}
              value={Number(
                formatWithDefaultPrecision(parseGraphQLNumber(metric.value))
              )}
              isHovered={isHovered}
              output={props.output}
              widgetExternalDeps={props.widgetExternalDeps}
              isLast={props.isLast}
            />
          ) : null;
        }}
      </DefaultMatchRemoteDataOrError>
    </OverviewWidgetWrapperHoverable>
  );
};

const IconWrapper = styled(Box)({
  padding: '12px',
  backgroundColor: '#fff',
  borderRadius: '10px',
  display: 'inline-block',
});

const CardContent = (props: {
  value: number;
  icon: IconDefinition;
  metricType: MonitoringMetricType;
  isHovered: boolean;
  output: MonitoringIODescription;
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  isLast: boolean;
}) => {
  const { palette } = useTheme();

  const ref = useRef();

  return (
    <Box style={{ padding: '32px 24px', width: '100%' }} ref={ref}>
      <Stack spacing={4}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <IconWrapper>
            <IconAwesomeCustomizable
              size="lg"
              icon={props.icon}
              nativeProps={{
                color: palette.primary.main,
              }}
            />
          </IconWrapper>

          <div style={{ opacity: props.isHovered ? 1 : 0 }}>
            <MonitoringOverviewCardDetails
              anchorEl={ref.current}
              id={`${props.metricType}-details`}
              metricType={props.metricType}
              metricValue={props.value}
              output={props.output}
              widgetExternalDeps={props.widgetExternalDeps}
              icon={props.icon}
              isLast={props.isLast}
            />
          </div>
        </Stack>
        <Box>
          <Typography variant="h2">{props.value}</Typography>
          <Typography variant="subtitle1">
            {getLabelByMonitoringMetricType(props.metricType)}
          </Typography>
          <div style={{ opacity: props.isHovered ? 1 : 0 }}>
            <InlineLink
              to={routes.monitoringPerformance.getRedirectPath({
                monitoringModelId: props.widgetExternalDeps.monitoringModelId,
                workspaceName: props.widgetExternalDeps.workspaceName,
              })}
            >
              Go to performance
            </InlineLink>
          </div>
        </Box>
      </Stack>
    </Box>
  );
};

export default MonitoringOverviewCard;
