import { ActionType, createAction, getType } from 'typesafe-actions';
import { AnyAction } from 'redux';

import { AppError } from 'shared/models/Error';
import { JsonData } from 'shared/utils/json';
import { ActionResult, IApplicationState } from 'setup/store/store';
import { IActiveEndpointInfo } from 'shared/models/Deployment/DeployedExperimentRun';
import { normalizeErrorFromUnknown } from 'shared/utils/normalizeError';
import DeployedExperimentRunService from 'services/deployment/deployedExperimentRun/DeployedExperimentRunService';
import { toastCommunicationErrorActionCreator } from 'features/toast/store/actions';

const pollingSettings = {
  stop: 60 * 1000,
  interval: 1000,
};

export type BulkPredictionPollingState =
  | { type: 'idle' }
  | { type: 'running'; communication: { type: 'loading' } | { type: 'loaded' } }
  | { type: 'error'; error: AppError };

const internalActions = {
  startBulkPrediction: createAction(
    '@@deployedExperimentRunPlayground/START_BULK_PREDICTION'
  )(),
  loadedOutput: createAction(
    '@@deployedExperimentRunPlayground/BULK_PREDICTION_LOADED_OUTPUT'
  )<JsonData>(),
  setError: createAction(
    '@@deployedExperimentRunPlayground/FAILURE_BULK_PREDICTION'
  )<AppError>(),
  stopBulkPrediction: createAction(
    '@@deployedExperimentRunPlayground/STOP_BULK_PREDICTION'
  )(),
  startLoading: createAction(
    '@@deployedExperimentRunPlayground/START_LOADING_BULK_PREDICTION'
  )(),
};
export const actions = (() => {
  const startBulkPredictionThunk =
    ({
      endpointInfo,
      input,
    }: {
      endpointInfo: IActiveEndpointInfo;
      input: string;
    }): ActionResult<void, AnyAction> =>
    async (dispatch, getState) => {
      dispatch(internalActions.startBulkPrediction());

      async function bulkPredictionFlow() {
        try {
          if (selectors.selectState(getState()).type !== 'running') {
            return;
          }
          dispatch(internalActions.startLoading());

          const result = await DeployedExperimentRunService.runPrediction(
            endpointInfo,
            input
          );

          if (selectors.selectState(getState()).type !== 'running') {
            return;
          }
          dispatch(internalActions.loadedOutput(result));

          setTimeout(() => {
            bulkPredictionFlow();
          }, pollingSettings.interval);
        } catch (e: unknown) {
          const appError = normalizeErrorFromUnknown(e);
          dispatch(
            toastCommunicationErrorActionCreator(appError, {
              context: 'bulk prediction',
            })
          );
          dispatch(internalActions.setError(appError));
        }
      }

      bulkPredictionFlow();
      setTimeout(() => {
        dispatch(internalActions.stopBulkPrediction());
      }, pollingSettings.stop);
    };

  return {
    startBulkPrediction: startBulkPredictionThunk,
    stopBulkPrediction: internalActions.stopBulkPrediction,
    loadedOutput: internalActions.loadedOutput,
  };
})();

export const selectors = {
  selectState: (state: IApplicationState): BulkPredictionPollingState => {
    return state.deployedExperimentRunPlayground.communications.bulkPrediction;
  },
};

export const bulkPredictionReducer = (
  state: BulkPredictionPollingState = { type: 'idle' },
  action: ActionType<typeof internalActions>
): BulkPredictionPollingState => {
  switch (action.type) {
    case getType(internalActions.startBulkPrediction): {
      return { type: 'running', communication: { type: 'loading' } };
    }
    case getType(internalActions.startLoading): {
      return { type: 'running', communication: { type: 'loading' } };
    }
    case getType(internalActions.loadedOutput): {
      return { type: 'running', communication: { type: 'loaded' } };
    }
    case getType(internalActions.setError): {
      return { type: 'error', error: action.payload };
    }
    case getType(internalActions.stopBulkPrediction): {
      return { type: 'idle' };
    }
    default:
      return state;
  }
};
