/* eslint-disable rulesdir/no-deprecated-fields */
import * as React from 'react';
import { gql } from '@apollo/client';

import { IWorkspace } from 'shared/models/Workspace';
import { getEndpointPollingInterval } from 'shared/models/Deployment/canary/Endpoint';
import usePollingQuery from 'shared/view/hooks/apollo/usePollingQuery';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

import * as ServerTypes from './graphql-types/endpointsListWithPolling.generated';
import { useEndpointsListQuery } from '../endpointsLIstQuery/endpointsListQuery';

const EndpointsEnvironmentStatusesQuery = gql`
  query EndpointsEnvironmentStatuses(
    $workspaceName: String!
    $organizationId: ID
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoints {
          endpoints {
            id
            environments {
              id
              status
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useEndpointsListWithPollingQuery = ({
  workspaceName,
  organizationId,
}: {
  workspaceName: IWorkspace['name'];
  organizationId: string | undefined;
}) => {
  const res = useEndpointsListQuery({ workspaceName, organizationId });

  const [isSkipPolling, changeIsSkipPolling] = React.useState(true);
  React.useEffect(() => {
    if (res.endpointsList) {
      const cancelId = setTimeout(() => {
        changeIsSkipPolling(false);
      }, getEndpointPollingInterval());
      return () => clearTimeout(cancelId);
    }
  }, [res.endpointsList, res.loadingEndpointsList]);

  usePollingQuery<
    ServerTypes.EndpointsEnvironmentStatuses,
    ServerTypes.EndpointsEnvironmentStatusesVariables
  >(EndpointsEnvironmentStatusesQuery, {
    variables: { workspaceName, organizationId },
    pollInterval: getEndpointPollingInterval(),
    skip: isSkipPolling,
  });

  return res;
};
