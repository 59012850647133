import { gql } from '@apollo/client';

import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import * as GraphqlTypes from 'features/experimentRuns/crossRunDashboard/store/updateAttributeWithDashboards/graphql-types/updateAttributeWithDashboards.generated';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

const MUTATION = gql`
  mutation UpdateAttributeWithDashboards(
    $projectId: ID!
    $attribute: StringKeyValueInput!
  ) {
    project(id: $projectId) {
      ... on Error {
        ...ErrorData
      }
      ... on Project {
        id
        updateAttribute(keyValue: $attribute) {
          id
          attributes {
            ...AttributeData
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
`;

export const useUpdateAttributeWithDashboards = (
  context: string,
  onCompleted: (() => void) | undefined
) => {
  const [updateAttributeWithDashboards, updating] = useCustomMutation<
    GraphqlTypes.UpdateAttributeWithDashboards,
    GraphqlTypes.UpdateAttributeWithDashboardsVariables
  >(
    MUTATION,
    { context },
    {
      onCompleted,
    }
  );

  return {
    updateAttributeWithDashboards,
    updating,
  };
};
