import {
  alertAggregationWindowsForSelection,
  getAlertAggregationWindowLabel,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertAggregationWindow';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';

interface Props {
  name: string;
}

const options = alertAggregationWindowsForSelection.map((window) => ({
  label: getAlertAggregationWindowLabel(window),
  value: window,
}));

const AggregationWindowSelect = (props: Props) => {
  return (
    <SelectField
      name={props.name}
      options={options}
      label={'Aggregation window'}
    />
  );
};

export default AggregationWindowSelect;
