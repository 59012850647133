import { useSelector } from 'react-redux';
import { useCallback } from 'react';

import { selectCurrentWorkspaceName } from 'features/workspaces';
import { useCreateRegisteredModelVersion } from 'features/registry/registeredModelVersion/createRegisteredModelVersion/store/createRegisteredModelVersion/createRegisteredModelVersion';
import { ILockLevelForm } from 'shared/view/domain/Registry/ModelVersion/LockLevel/LockLevelForm/LockLevelFormModel';
import { combineCommunications } from 'shared/utils/redux/communication/combineCommunications';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

import { useCreateRegisteredModelWithExperimentRun } from '../createRegisteredModelWithExperimentRun/createRegisteredModelWithExperimentRun';

export interface RegisteredModelWithExperimentRunCreationForm
  extends ILockLevelForm {
  modelName: string;
  versionName: string;
  experimentRunId: string;
  versionLabels: string[];
  versionDescription: string;
}

export const useCreateRegisteredModelVersionWithExistingOrNewModel = ({
  registeredModels,
  onSuccess,
}: {
  registeredModels: {
    id: string;
    name: string;
  }[];
  onSuccess: (ids: { registeredModelId: string; versionId: string }) => void;
}) => {
  const workspaceName = useSelector(selectCurrentWorkspaceName);
  const organizationId = useCurrentOrganizationV2();

  const { createRegisteredModel, creatingRegisteredModel } =
    useCreateRegisteredModelWithExperimentRun({
      onSuccess,
    });

  const { createVersion, creatingVersion } = useCreateRegisteredModelVersion({
    onSuccess,
  });

  const create = useCallback(
    (values: RegisteredModelWithExperimentRunCreationForm) => {
      const model = registeredModels.find((m) => m.name === values.modelName);
      if (model) {
        createVersion({
          name: values.versionName,
          description: values.versionDescription,
          labels: values.versionLabels,
          experimentRunId: values.experimentRunId,
          registeredModelId: model.id,
          lockLevel: values.lockLevel,
        });
      } else {
        createRegisteredModel({
          modelName: values.modelName,
          experimentRunId: values.experimentRunId,
          versionName: values.versionName,
          organizationId,
          versionDescription: values.versionDescription,
          versionLabels: values.versionLabels,
          workspaceName,
          lockLevel: values.lockLevel,
        });
      }
    },
    [
      createRegisteredModel,
      workspaceName,
      registeredModels,
      organizationId,
      createVersion,
    ]
  );

  const creating = combineCommunications([
    creatingRegisteredModel,
    creatingVersion,
  ]);

  return {
    create,
    creating,
  };
};
