import { OmitStrict } from 'shared/utils/types';
import CellRendererEmpty from 'shared/view/elements/DataGrid/columns/CellRendererEmpty';
import DynamicTypography, {
  DynamicTypographyProps,
} from 'shared/view/elements/DynamicTypography/DynamicTypography';

export type CellRendererStringProps = OmitStrict<
  DynamicTypographyProps,
  'value'
> & {
  value: string | undefined | null;
};

export default function CellRendererString(props: CellRendererStringProps) {
  if (!props.value) {
    return <CellRendererEmpty dataTest={props.dataTest} />;
  }

  return <DynamicTypography {...props} value={props.value} />;
}
