import * as CrossRunDashboard from 'shared/models/CrossRunDashboard/CrossRunDashboard';
import * as AttributeWithCrossRunDashboards from 'shared/models/CrossRunDashboard/AttributeWithCrossRunDashboards';
import { useUpdateAttributeWithDashboards } from 'features/experimentRuns/crossRunDashboard/store/updateAttributeWithDashboards/updateAttributeWithDashboards';

export const useSaveDashboardChanges = (onCompleted: () => void) => {
  const { updateAttributeWithDashboards, updating } =
    useUpdateAttributeWithDashboards('saving dashboard', onCompleted);
  const saveDashboardChanges = (data: {
    updatedDashboard: CrossRunDashboard.CrossRunDashboard;
    projectId: string;
    dashboards: CrossRunDashboard.CrossRunDashboard[];
  }) =>
    updateAttributeWithDashboards({
      projectId: data.projectId,
      attribute: AttributeWithCrossRunDashboards.saveDashboardChanges(
        data.updatedDashboard,
        data.dashboards
      ),
    });

  return {
    saveDashboardChanges,
    savingDashboard: updating,
  };
};
