// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from '@mui/material/Tooltip';
import { FC } from 'react';

import matchType from 'shared/utils/matchType';

type TooltipProps = Pick<
  MuiTooltipProps,
  | 'children'
  | 'placement'
  | 'open'
  | 'disableHoverListener'
  | 'componentsProps'
  | 'arrow'
  | 'onOpen'
  | 'onClose'
  | 'sx'
> & {
  title?: MuiTooltipProps['title'];
  type?: 'default' | 'withDiv' | 'withSpan';
};

const Tooltip: FC<React.PropsWithChildren<TooltipProps>> = ({
  type = 'default',
  children,
  title,
  ...tooltipProps
}) => {
  return matchType(
    {
      default: () =>
        title !== undefined ? (
          <MuiTooltip title={title} {...tooltipProps}>
            {children}
          </MuiTooltip>
        ) : (
          children
        ),
      withDiv: () =>
        title !== undefined ? (
          <MuiTooltip title={title} {...tooltipProps}>
            <div>{children}</div>
          </MuiTooltip>
        ) : (
          <div>{children}</div>
        ),
      withSpan: () =>
        title !== undefined ? (
          <MuiTooltip title={title} {...tooltipProps}>
            <span style={{ width: 'min-content' }}>{children}</span>
          </MuiTooltip>
        ) : (
          <span style={{ width: 'min-content' }}>{children}</span>
        ),
    },
    type
  );
};

export default Tooltip;
