import { validateDescription } from 'shared/models/Description';
import { validateNotEmpty } from 'shared/utils/validators';
import TagsField from 'shared/view/formComponents/formikFields/TagsFieldWithTopLabel/TagsFieldWithTopLabel';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import { PopupStateProps } from 'shared/view/elements/Popup/PopupButtons';
import PopupForm from 'shared/view/formComponents/PopupForm';

import { useCreateExperiment } from '../../store/createExperiment/useCreateExperiment';

interface ILocalProps extends PopupStateProps {
  projectId: string;
  workspaceName: string;
}

const initialSettings: { name: string; description: string; tags: string[] } = {
  name: '',
  description: '',
  tags: [],
};

const ExperimentCreationPopup = (props: ILocalProps) => {
  const { createExperiment, creatingExperiment } = useCreateExperiment(
    props.onClose
  );

  return (
    <PopupForm
      initialValues={initialSettings}
      onSubmit={(settings) =>
        createExperiment({ ...settings, projectId: props.projectId })
      }
      title="Create experiment"
      communication={creatingExperiment}
      {...props}
    >
      {() => (
        <>
          <TextInputField
            name="name"
            dataTest="name"
            label="Experiment name"
            validate={validateNotEmpty('Experiment name')}
            isRequired={true}
          />
          <TextInputField
            name="description"
            dataTest="description"
            label="Description"
            validate={validateDescription}
          />

          <TagsField name="tags" />
        </>
      )}
    </PopupForm>
  );
};

export default ExperimentCreationPopup;
