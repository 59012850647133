import { useState } from 'react';

import { SupportedIntegrations } from 'shared/models/SystemAdmin/SupportedIntegrations';
import matchType from 'shared/utils/matchType';
import Button from 'shared/view/elements/Button/Button';
import routes from 'shared/routes';
import { SupportedIntegrationsStatuses } from 'shared/models/SystemAdmin/SupportedIntegrationsStatuses';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import Popup from 'shared/view/elements/Popup/Popup';
import { LinkButton } from 'shared/view/elements/Button/LinkButton';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import KafkaSetupPopup from 'features/systemAdmin/integrations/kafka/view/Popup/KafkaSetupPopup';

export default function IntegrationItemButton({
  type,
  status,
  reload,
}: {
  type: SupportedIntegrations;
  status: SupportedIntegrationsStatuses;
  reload: () => void;
}) {
  const { openPopup, isPopupOpen, closePopup } = usePopupManager();
  const [isLoading, setIsLoading] = useState(false);
  const text = matchType(
    {
      active: () => 'View',
      inactive: () =>
        matchType(
          {
            Pypi: () => 'Setup',
            Kafka: () => 'Setup',
            ActiveDirectory: () => 'Contact',
          },
          type
        ),
    },
    status
  );

  const to = matchType(
    {
      Kafka: () =>
        routes.systemAdmin.$.integrations.$.kafka.getRedirectPath({}),
      Pypi: () => routes.systemAdmin.$.integrations.$.pypi.getRedirectPath({}),
      ActiveDirectory: () =>
        routes.systemAdmin.$.integrations.$.active_directory.getRedirectPath(
          {}
        ),
    },
    type
  );

  if (type === 'ActiveDirectory' && status === 'inactive') {
    return (
      <>
        <Button onClick={openPopup} isLoading={false}>
          {text}
        </Button>
        <Popup
          title="Contact Verta support"
          isOpen={isPopupOpen}
          onClose={closePopup}
        >
          <div>
            Contact Verta support to activate the Active Directory integration.
          </div>
        </Popup>
      </>
    );
  }
  if (type === 'Kafka') {
    return (
      <ButtonWithPopup
        key={0}
        button={(buttonProps) => (
          <Button
            {...buttonProps}
            isLoading={isLoading}
            variant={status === 'inactive' ? 'outlined' : 'contained'}
          >
            {text}
          </Button>
        )}
        popup={(popupProps) => (
          <KafkaSetupPopup
            {...popupProps}
            setIsLoading={setIsLoading}
            reload={reload}
          />
        )}
      />
    );
  }
  return <LinkButton to={to}>{text}</LinkButton>;
}
