import { Stack, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { equals } from 'ramda';
import { useCallback, useMemo } from 'react';

import { MonitoringDashboard } from 'shared/models/Monitoring/MonitoringModel/MonitoringDashboard/MonitoringDashboard';
import { reorder, updateById } from 'shared/utils/collection';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import Popup from 'shared/view/elements/Popup/Popup';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';

interface Props {
  dashboard: MonitoringDashboard;
  dashboards: MonitoringDashboard[];
  updateDashboards: (dashboards: MonitoringDashboard[]) => void;
  isOpen: boolean;
  onClose: () => void;
}

interface Form {
  name: string;
  dashboards: MonitoringDashboard[];
}

const getFieldName = makeGetFieldName<Form>();

const EditDashboard = (props: Props) => {
  const initialValues = useMemo(
    (): Form => ({
      dashboards: props.dashboards,
      name: props.dashboard.name,
    }),
    [props.dashboard.name, props.dashboards]
  );

  const { updateDashboards, onClose } = props;
  const onSubmit = useCallback(
    (form: Form) => {
      updateDashboards(form.dashboards);
      onClose();
    },
    [onClose, updateDashboards]
  );

  const isSubmitDisabled = useCallback(
    (form: Form) =>
      equals(form.dashboards, props.dashboards) &&
      form.name === props.dashboard.name,
    [props.dashboards, props.dashboard.name]
  );

  return (
    <PresetFormik onSubmit={onSubmit} initialValues={initialValues}>
      {({ values, submitForm, setFieldValue }) => (
        <Popup
          title="Edit dashboard"
          isOpen={props.isOpen}
          onClose={props.onClose}
          maxWidth="xl"
          buttons={{
            mainButtonProps: {
              type: 'button',
              disabled: isSubmitDisabled(values),
              isLoading: false,
              children: 'Update',
              dataTest: 'submit',
              onClick: submitForm,
            },
            secondaryButtonProps: {
              children: 'Cancel',
              onClick: onClose,
              isLoading: false,
            },
          }}
        >
          <Stack sx={{ width: '611px' }}>
            <Field name={getFieldName({ name: null })}>
              {({ field, meta, form }: FieldProps<string>) => (
                <TextInput
                  label="Name"
                  value={field.value}
                  name={field.name}
                  onChange={(v) => {
                    form.setFieldValue(field.name, v);
                    form.setFieldValue(
                      getFieldName({
                        dashboards: null,
                      }),
                      updateById(
                        (dashboard) => ({ ...dashboard, name: v }),
                        props.dashboard.id,
                        values.dashboards
                      )
                    );
                  }}
                  onBlur={field.onBlur}
                  meta={meta}
                />
              )}
            </Field>

            <Typography>Reorder</Typography>
            <DashboardsReorderTable
              dashboards={values.dashboards}
              updateDashboards={(dashboards) =>
                setFieldValue(getFieldName({ dashboards: null }), dashboards)
              }
            />
          </Stack>
        </Popup>
      )}
    </PresetFormik>
  );
};

const columns: Array<DataGridColumn<MonitoringDashboard>> = [
  {
    field: 'Dashboard name',
    flex: 1,
    renderCell: (params) => <CellRendererString value={params.row.name} />,
  },
];

const DashboardsReorderTable = (props: {
  dashboards: MonitoringDashboard[];
  updateDashboards: (dashboards: MonitoringDashboard[]) => void;
}) => {
  return (
    <DataGridWithTypes
      columns={columns}
      rows={props.dashboards}
      heightType="autoHeightButMax5Rows"
      rowReordering
      onRowOrderChange={(params) => {
        props.updateDashboards(
          reorder(props.dashboards, params.oldIndex, params.targetIndex)
        );
      }}
    />
  );
};

export default EditDashboard;
