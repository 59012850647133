/* eslint-disable graphql/template-strings */
import { gql } from '@apollo/client';

export const ALLOWED_ACTIONS_FRAGMENT = gql`
  fragment AllowedActionsData on AllowedActions {
    create
    update
    deploy
    delete
    updatePermissions
    predict
    lock
    unlock
    updateRedact
  }
`;
