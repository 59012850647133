import { Box, BoxProps, Stack, Paper, PaperProps, styled } from '@mui/material';

type Props = PaperProps &
  Pick<BoxProps, 'overflow'> & {
    header?: {
      withoutPadding?: boolean;
      leftContent?: React.ReactNode;
      rightContent?: React.ReactNode;
    };
    isEmbeded?: boolean;
    hasPadding?: boolean;
    noHeader?: boolean;
  };

const HeaderWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '44px',
}));

const CardLayout = (props: Props) => {
  const {
    isEmbeded = false,
    hasPadding = false,
    header,
    children,
    overflow,
    ...other
  } = props;

  return (
    <Paper
      variant={isEmbeded ? 'elevation' : 'outlined'}
      elevation={0}
      {...other}
      sx={{ height: '100%', ...other.sx }}
    >
      <Stack height="100%">
        {header && (
          <HeaderWrapper
            padding={header.withoutPadding ? undefined : '8px 16px'}
          >
            <Box display="flex" alignItems="center">
              {header.leftContent}
            </Box>
            <Box display="flex" alignItems="center">
              {header.rightContent}
            </Box>
          </HeaderWrapper>
        )}
        <Box padding={hasPadding ? 2 : 0} flex="1" overflow={overflow}>
          {children}
        </Box>
      </Stack>
    </Paper>
  );
};

export default CardLayout;
