import Stack from '@mui/material/Stack';
import { sortBy } from 'ramda';

import PypiConfigurationsFormUpdate from 'features/systemAdmin/integrations/pypi/view/form/PypiConfigurationsFormUpdate';
import { PypiConfigurationsList } from 'features/systemAdmin/integrations/pypi/store/graphql-types/usePypiConfigurationsList.generated';
import Accordion from 'shared/view/elements/Accordion/Accordion';

import IntegrationItemStatus from '../../shared/IntegrationItemStatus';

type Props = {
  configurations: PypiConfigurationsList['pypiConfigurations']['configurations'];
  expandedId: string;
  handleExpandById: (id: string) => (expanded: boolean) => void;
};

export default function PypiConfigurationList(props: Props) {
  return (
    <Stack direction={'column'}>
      {sortBy((x) => x.name, props.configurations).map((configuration) => (
        <IntegrationAccordion
          key={configuration.id}
          name={configuration.name}
          enabled={configuration.enabled}
          caption={configuration.url}
          expanded={props.expandedId === configuration.id}
          handleExpand={props.handleExpandById(configuration.id)}
        >
          <PypiConfigurationsFormUpdate
            id={configuration.id}
            values={configuration}
          />
        </IntegrationAccordion>
      ))}
    </Stack>
  );
}

const IntegrationAccordion = ({
  name,
  caption,
  enabled,
  expanded,
  handleExpand,
  children,
}: {
  name: string;
  caption: string;
  enabled: boolean;
  expanded: boolean;
  handleExpand(isExpanded: boolean): void;
  children: JSX.Element;
}) => {
  return (
    <Accordion
      title={name}
      subtitle={caption}
      expanded={expanded}
      summaryRightContent={
        <IntegrationItemStatus status={enabled ? 'active' : 'inactive'} />
      }
      onChange={(_, newExpanded) => handleExpand(newExpanded)}
    >
      {children}
    </Accordion>
  );
};
