import {
  AppError,
  createCodeErrorFromError,
  isAppError,
} from 'shared/models/Error';

export default function normalizeError(error: AppError | Error): AppError {
  if (isAppError(error)) {
    return error;
  }
  return createCodeErrorFromError(error);
}

// implement correct check
export function normalizeErrorFromUnknown(error: unknown): AppError {
  return normalizeError(error as AppError | Error);
}
