import { useCallback } from 'react';
import { equals } from 'ramda';

import PanelActionIcon from 'shared/view/domain/Dashboards/Panel/PanelActionIcon/PanelActionIcon';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { DriftMonitoringPanel } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringPanel';
import Popup from 'shared/view/elements/Popup/Popup';
import { validateNotEmpty } from 'shared/utils/validators';
import MonitoringDriftMetricTypeField from 'features/monitoring/shared/MonitoringWidgetForm/shared/MonitoringDriftMetricTypeField/MonitoringDriftMetricTypeField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { MonitoringDriftMetricType } from 'generated/types';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

interface Props {
  panel: DriftMonitoringPanel;
  updatePanel: (panel: DriftMonitoringPanel) => void;
}

interface EditDriftPanelFormType {
  name: string;
  driftMetricType: MonitoringDriftMetricType;
}

const getFieldName = makeGetFieldName<EditDriftPanelFormType>();

const EditDriftPanelAction = (props: Props) => {
  const { closePopup, isPopupOpen, openPopup } = usePopupManager();

  const { panel, updatePanel } = props;
  const onSubmit = useCallback(
    (form: EditDriftPanelFormType) => {
      updatePanel({ ...panel, ...form });
      closePopup();
    },
    [panel, updatePanel, closePopup]
  );

  return (
    <>
      <PanelActionIcon
        title="Edit panel"
        icon={ICONS.pencil}
        onClick={openPopup}
      />
      {isPopupOpen ? (
        <PresetFormik initialValues={props.panel} onSubmit={onSubmit}>
          {({ values, isValid }) => (
            <Popup
              title="Edit panel"
              isOpen={isPopupOpen}
              onClose={closePopup}
              buttons={{
                mainButtonProps: {
                  type: 'button',
                  disabled: !isValid || equals(props.panel, values),
                  isLoading: false,
                  children: 'OK',
                  onClick: () => {
                    onSubmit(values);
                  },
                },
              }}
            >
              <FormStack>
                <TextInputField
                  label="Name"
                  isRequired={true}
                  name={getFieldName({
                    name: null,
                  })}
                  validate={validateNotEmpty('name')}
                />
                <MonitoringDriftMetricTypeField
                  name={getFieldName({ driftMetricType: null })}
                />
              </FormStack>
            </Popup>
          )}
        </PresetFormik>
      ) : null}
    </>
  );
};

export default EditDriftPanelAction;
