import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import checklistCreateCustomAttr from '../images/checklist-create-custom-attr.png';

export const CreateChecklistCustomAttr = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Create a new checklist
    </Typography>
    <Typography variant="body2" mb={3}>
      You can selectively apply checklists only to versions with a specific
      custom attribute value. For instance, if your organization assigns risk
      scores to models, you can configure distinct checklists based on different
      risk levels. A long checklist for high-risk models and so on.
    </Typography>
    <img
      src={checklistCreateCustomAttr}
      width="70%"
      height="auto"
      alt="checklist create custom attr"
    />
  </WizardStepContent>
);
