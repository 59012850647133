import { ScaleOrdinal } from 'd3-scale';
import { useCallback, useMemo } from 'react';

import {
  LegendItem,
  LegendPosition,
  LegendProps,
} from 'shared/view/charts/CustomLegend/CustomLegend';

export const useChartLegendProps = <ColorScaleDomain extends string>({
  toggleKey,
  activeKeys,
  legendPosition = 'right',
  colorScale,
}: {
  toggleKey: (key: string) => void;
  activeKeys: string[];
  legendPosition: LegendPosition | undefined;
  colorScale: ScaleOrdinal<ColorScaleDomain, string>;
}) => {
  const renderLegendItem = useCallback(
    (key: string, color: string) => (
      <LegendItem
        label={key}
        color={color}
        onClick={() => toggleKey(key)}
        isUnActive={!activeKeys.includes(key)}
      />
    ),
    [activeKeys, toggleKey]
  );

  const legendProps: LegendProps<ColorScaleDomain> = useMemo(
    () => ({
      position: legendPosition,
      scale: colorScale,
      title: '',
      renderItem: renderLegendItem,
    }),
    [legendPosition, colorScale, renderLegendItem]
  );

  return legendProps;
};
