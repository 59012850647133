import { identity, isNil } from 'ramda';

import { filterByType } from 'shared/utils/collection';
import isNotNil from 'shared/utils/isNotNill';
import { AddNullToArray, OmitStrict } from 'shared/utils/types';

import { RowDiffRenderResult } from '../BaseComponents/RowDiff';

type PropertyDiffDescription =
  | ExpansionPropertyDescription
  | NestedExpansionPropertyDescription
  | RowDiffDescription<any>;

export type ExpansionPropertyDescription = {
  type: 'expansionProperty';
  name: string;
  itemsCount: number;
  diffCount: number;
  children: Array<NestedExpansionPropertyDescription | RowDiffDescription<any>>;
};

export type NestedExpansionPropertyDescription = {
  type: 'expansionProperty';
  name: string;
  diffCount: number;
  itemsCount: number;
  children: Array<RowDiffDescription<any> | RowWithSingleColumn>;
};

export type RowDiffDescription<T> = {
  type: 'rowDiff';
  name: string;
  data: Array<T>;
  isDifferent: boolean;
  render: (data: T) => RowDiffRenderResult;
};

export type RowWithSingleColumn = {
  type: 'rowWithSingleColumn';
  isDifferent: boolean;
  name: string;
};

export const makeExpansionPropertyDescription = <
  C extends NestedExpansionPropertyDescription | RowDiffDescription<any>,
>(desc: {
  name: string;
  children: AddNullToArray<C[]>;
}): OmitStrict<ExpansionPropertyDescription, 'children'> & {
  children: C[];
} => {
  const nonEmptyChildren = desc.children
    .filter(isNotNil)
    .filter((x) => getItemsCount(x) > 0);
  return {
    name: desc.name,
    children: nonEmptyChildren,
    diffCount: nonEmptyChildren.filter((p) => getDiffCount(p) > 0).length,
    itemsCount: nonEmptyChildren.length,
    type: 'expansionProperty',
  };
};

export const makeNestedExpansionPropertyDescription = (desc: {
  name: string;
  children: AddNullToArray<NestedExpansionPropertyDescription['children']>;
}): NestedExpansionPropertyDescription => {
  const nonEmptyChildrenAndRowWithSingleColumn = desc.children
    .filter(isNotNil)
    .filter((x) =>
      x.type === 'rowWithSingleColumn' ? true : getItemsCount(x) > 0
    );
  return {
    name: desc.name,
    children: nonEmptyChildrenAndRowWithSingleColumn,
    diffCount: filterByType(
      nonEmptyChildrenAndRowWithSingleColumn,
      'rowDiff'
    ).filter((p) => getDiffCount(p) > 0).length,
    itemsCount: filterByType(nonEmptyChildrenAndRowWithSingleColumn, 'rowDiff')
      .length,
    type: 'expansionProperty',
  };
};

export const makeRowDiffDescription = <T>(
  desc: OmitStrict<RowDiffDescription<T>, 'type'> & {
    getValue?: (d: T) => unknown;
  }
): RowDiffDescription<T> | null => {
  const getValue = desc.getValue || identity;
  return desc.data.every(
    (x) => isNil(getValue(x)) || isEmptyString(getValue(x))
  )
    ? null
    : { ...desc, type: 'rowDiff' };
};

const isEmptyString = (x: unknown) => typeof x === 'string' && x === '';

export const makeRowWithSingleColumn = (
  desc: OmitStrict<RowWithSingleColumn, 'type'>
): RowWithSingleColumn => ({
  ...desc,
  type: 'rowWithSingleColumn',
});

export const getDiffCount = (p: PropertyDiffDescription) => {
  if (p.type === 'rowDiff') {
    return p.isDifferent ? 1 : 0;
  }
  return p.diffCount;
};

export const getItemsCount = (p: PropertyDiffDescription) => {
  if (p.type === 'rowDiff') {
    return 1;
  }
  return p.itemsCount;
};
