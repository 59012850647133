import { Box, Tabs, Tab, BadgeProps, styled, Stack } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Link, LinkProps } from 'react-router-dom';
import { forwardRef } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import isNotNil from 'shared/utils/isNotNill';
import { componentsSizes } from 'shared/mui/themes/CommonMuiThemeOptions';
import Badge from 'shared/view/elements/Badge/Badge';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';

interface Props {
  tabs: Tab[];
  rightContent?: ReactNode;
}

interface Counter {
  color: BadgeProps['color'];
  value: number;
}

export interface Tab {
  label: string;
  to: string;
  icon?: IconDefinition;
  counter?: Counter;
  dataTest?: string;
}

function PagesTabs(props: Props) {
  const { tabs, rightContent } = props;
  const location = useLocation();
  const tabValue = useMemo(() => {
    let selected = tabs[0].to;

    tabs.forEach((tab) => {
      if (window.location.href.match(tab.to)) selected = tab.to;
    });

    return selected;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs, location.pathname]);

  return (
    <StyledContainer>
      <Tabs value={tabValue} variant="scrollable" scrollButtons={false}>
        {tabs.map(({ dataTest, icon, ...tab }) => (
          <Tab
            {...tab}
            data-test={dataTest}
            value={tab.to}
            key={tab.label}
            component={forwardRef<HTMLAnchorElement, LinkProps>(
              (linkProps, ref) => {
                let paddingRight = 16;
                if (tab.counter?.value !== undefined) {
                  const nDigits = tab.counter.value.toString().length;
                  const extraPaddingArray = [0, 4, 8, 12, 16];
                  paddingRight =
                    nDigits < 4
                      ? paddingRight + extraPaddingArray[nDigits]
                      : extraPaddingArray[4];
                }

                return tab.counter ? (
                  <Link {...linkProps} ref={ref} style={{ paddingRight }}>
                    <Badge value={tab.counter.value} color={tab.counter.color}>
                      <Stack direction="row" spacing={0.5}>
                        {icon ? <IconAwesomeInfo icon={icon} /> : null}
                        <span>{linkProps.children}</span>
                      </Stack>
                    </Badge>
                  </Link>
                ) : (
                  <Link {...linkProps} ref={ref}>
                    <Stack direction="row" spacing={0.5}>
                      {icon ? <IconAwesomeInfo icon={icon} /> : null}
                      <span>{linkProps.children}</span>
                    </Stack>
                  </Link>
                );
              }
            )}
          />
        ))}
      </Tabs>
      {isNotNil(rightContent) ? <Box>{rightContent}</Box> : null}
    </StyledContainer>
  );
}

const StyledContainer = styled(Box)(({ theme }) => ({
  width: `calc(100% + ${contentPadding}px * 2)`,
  marginLeft: `-${contentPadding}px`,
  marginRight: `-${contentPadding}px`,
  paddingLeft: `${contentPadding}px`,
  paddingRight: `${contentPadding}px`,
  backgroundColor: theme.palette.background.level2,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
}));

const contentPadding = componentsSizes.layout.content.padding;

export default PagesTabs;
