import { Paper, PaperProps, Stack } from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';

type Props = PaperProps & {
  icon: IconDefinition;
};

const PaperLink = (props: Props) => {
  const { icon, children, ...rest } = props;
  return (
    <Paper sx={{ padding: 2, height: '74px' }} {...rest}>
      <Stack direction="row" alignItems="start" spacing={1}>
        <IconAwesomeInfo icon={icon} size="1x" />
        <Stack spacing={0}>{children}</Stack>
      </Stack>
    </Paper>
  );
};

export default PaperLink;
