import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';

import HighLevelSearchPage from './HighLevelSearchPage';

export const highLevelSearchPage: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.highLevelSearch.getPath(),
      element: <HighLevelSearchPage />,
    },
  ],
};
