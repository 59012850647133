export interface ChartMargin {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export interface Size {
  width: number;
  height: number;
}
export interface ChartSizes extends Size {
  innerWidth: number;
  innerHeight: number;
  margin: ChartMargin;
}

export const getChartSizes = ({
  width,
  height,
  margin,
}: {
  margin: ChartMargin;
} & Size): ChartSizes => {
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  return {
    innerWidth,
    innerHeight,
    margin,
    width,
    height,
  };
};
