import { useMemo, useEffect } from 'react';

import { useCurrentOrganizationUsers } from 'features/catalog/registeredModel/catalogList/hooks/useCurrentOrganizationUsers/useCurrentOrganizationUsers';

type Props = {
  id: any | undefined | null;
  fallbackComponent?: JSX.Element;
};

export const UserRecordInfo = ({ id, fallbackComponent }: Props) => {
  const { data, load } = useCurrentOrganizationUsers({
    options: { fetchPolicy: 'cache-first' },
  });

  const value = useMemo(() => {
    if (id && data) {
      const user = [...data].find((u) => u.id === id);
      if (user) {
        return user.fullName || user.email || user.id;
      }
    }

    return undefined;
  }, [data, id]);

  useEffect(() => {
    if (id) {
      load();
    }
  }, [id, load]);

  return value ? <span>{String(value)}</span> : fallbackComponent ?? <></>;
};
