import { FieldArray, FormikErrors, useFormikContext } from 'formik';
import { useState, useMemo } from 'react';
import { head, isEmpty } from 'lodash';
import { Box } from '@mui/material';

import FormStack from 'shared/view/elements/FormStack/FormStack';
import Checkbox from 'shared/view/elements/Checkbox/Checkbox';
import { ChecklistTemplateInput } from 'generated/types';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import { useLoadCustomAttributeDefinitions } from 'features/catalog/shared/hooks/useLoadCustomAttributeDefinitions';
import {
  CustomAttributeTargetType,
  CustomAttributeValueInput,
} from 'generated/types';
import {
  IGroupedOptions,
  IOptionType,
} from 'shared/view/elements/Selects/shared/types';
import { CustomAttributeType } from 'generated/types';

import { getField } from './ChecklistTemplatePopup';
import { CustomAttributeValueItem } from './CustomAttributeValueItem';

type Props = {
  values: ChecklistTemplateInput['customAttributeValues'] | null | undefined;
  errors?: FormikErrors<CustomAttributeValueInput>[];
  organizationId: string;
};

export const CustomAttributeValueManager = ({
  errors,
  organizationId,
  values,
}: Props) => {
  const { setFieldValue } = useFormikContext();
  const [showSeletors, setShowSelectors] = useState(!isEmpty(values));
  const { data: definitions } = useLoadCustomAttributeDefinitions(
    { organizationId },
    { fetchPolicy: 'cache-first' }
  );

  const definitionOptions = useMemo(() => {
    return groupDefinitions(definitions);
  }, [definitions]);

  const handleShowSelectorsChange = () => {
    if (!showSeletors) {
      setFieldValue(getField({ customAttributeValues: null }), [
        {
          customAttributeDefinitionId: '',
          selectedValue: '',
          targetId: '',
          targetType: CustomAttributeTargetType.CHECKLIST_TEMPLATE,
        },
      ]);
    }

    setShowSelectors(!showSeletors);
  };

  /* for now, just 1 custom attr value */
  const item = head(values);

  const error = errors ? errors[0] : undefined;

  return (
    <>
      <Checkbox
        onChange={handleShowSelectorsChange}
        dataTest="restricted"
        label="Apply checklist only to models/versions with specific custom attribute values"
        value={showSeletors}
        maxWidth="100%"
        color="text.primary"
      />
      {showSeletors && (
        <Box ml="30px" mt={1}>
          <FieldArray name={getField({ customAttributeValues: null })}>
            {({ replace }) => (
              <FormStack>
                {/* for now, just 1 custom attr value */}
                <CustomAttributeValueItem
                  value={item}
                  key={`custom-attr-value-item-${0}`}
                  index={0}
                  error={error}
                  replace={replace}
                  definitionOptions={definitionOptions}
                  customAttributesDefinitions={definitions}
                />
              </FormStack>
            )}
          </FieldArray>
        </Box>
      )}
    </>
  );
};

export type CustomAttributeDefinitions = ReturnType<
  typeof useLoadCustomAttributeDefinitions
>['data'];

const groupDefinitions = (definitions: CustomAttributeDefinitions) => {
  const result: IGroupedOptions = {
    type: 'groupedOptions',
    groups: [],
  };

  if (isNotError(definitions)) {
    const modelVersionAttrs: IOptionType[] = [];
    const modelAttrs: IOptionType[] = [];

    definitions.forEach((attr) => {
      if (attr.attributeType === CustomAttributeType.CUSTOM_LIST) {
        if (attr.targetType === CustomAttributeTargetType.REGISTERED_MODEL) {
          modelAttrs.push({ ...attr, label: attr.name, value: attr.id });
        }
        if (
          attr.targetType === CustomAttributeTargetType.REGISTERED_MODEL_VERSION
        ) {
          modelVersionAttrs.push({
            ...attr,
            label: attr.name,
            value: attr.id,
          });
        }
      }
    });

    result.groups = [
      {
        label: 'MODEL ATTRIBUTES',
        options: modelAttrs,
      },
      {
        label: 'VERSION ATTRIBUTES',
        options: modelVersionAttrs,
      },
    ];
  }

  return result;
};
