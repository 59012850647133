import { gql } from '@apollo/client';

import {
  ALERT_FRAGMENT,
  convertAlert,
} from 'shared/graphql/monitoring/Alert/Alert';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { ALERTER_FRAGMENT } from 'shared/graphql/monitoring/Alert/Alerter';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { AlertStatus } from 'generated/types';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';

import {
  AlertsQuery,
  AlertsQueryVariables,
} from './graphql-types/useAlerts.generated';

const ALERTS_QUERY = gql`
  query AlertsQuery($monitoredEntityId: ID!) {
    monitoredEntity(id: $monitoredEntityId) {
      ... on MonitoredEntity {
        id
        alerts {
          alerts {
            id
            ...AlertFragment
          }
        }
      }
      ... on Error {
        ...ErrorData
      }
    }
  }
  ${ALERT_FRAGMENT}
  ${ALERTER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useAlertsQuery = (props: { monitoredEntityId: string }) => {
  const queryResult = useCustomQuery<AlertsQuery, AlertsQueryVariables>(
    ALERTS_QUERY,
    {
      variables: { monitoredEntityId: props.monitoredEntityId },
    }
  );

  return useMemoizedResultToCommunicationWithData({
    queryResult,
    memoizedConvert: convert,
  });
};

const convert = (res: AlertsQuery) => {
  return mapDataOrError(res.monitoredEntity, (monitoredEntity) => {
    const { alerts } = monitoredEntity.alerts;
    return [...alerts]
      .sort((alert) => (alert.status === AlertStatus.ALERTING ? -1 : 1))
      .map(convertAlert);
  });
};
