// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { Line } from '@visx/shape';
import { minBy, pipe, uniq } from 'ramda';
import { ScaleBand } from 'd3-scale';

import { ChartSizes } from 'shared/utils/charts/chartSizes';
import { isNotEmptyArray } from 'shared/utils/collection';
import { useChartSizeScales } from 'shared/utils/charts/sizeScale/useChartSizeScales';

import CustomTooltipWithBounds from '../../shared/CustomTooltipWithBound/CustomTooltipWithBound';
import { ViolinChartData } from './types';

export interface ViolinChartTooltipData<Meta> {
  groups: [ViolinChartData<Meta>, ...ViolinChartData<Meta>[]];
  groupKey: string;
}

const getTooltipLeft = ({
  groupKey,
  xScale,
}: {
  xScale: ScaleBand<string>;
  groupKey: string;
}) => {
  return (xScale(groupKey) ?? 0) + xScale.bandwidth() / 2;
};

export function useViolinChartTooltipWithView<Meta>(props: {
  data: ViolinChartData<Meta>[];
  xScale: ScaleBand<string>;
  chartSizes: ChartSizes;
  renderTooltipContent: (
    tooltipData: ViolinChartTooltipData<Meta>
  ) => React.ReactNode;
  onTooltipAreaClick?: (
    tooltipData: ViolinChartTooltipData<Meta> | undefined
  ) => void;
}) {
  const { handleTooltip, hideTooltip, tooltipData, tooltipLeft } =
    useViolinChartTooltip(props);

  const tooltipView = tooltipData && (
    <CustomTooltipWithBounds top={20} left={Number(tooltipLeft)}>
      {props.renderTooltipContent(tooltipData)}
    </CustomTooltipWithBounds>
  );

  const lineLeft = tooltipData
    ? getTooltipLeft({
        xScale: props.xScale,
        groupKey: tooltipData.groupKey,
      })
    : 0;

  const tooltipIndicator = tooltipData && (
    <Line
      from={{ x: lineLeft, y: 0 }}
      to={{ x: lineLeft, y: props.chartSizes.innerHeight }}
      stroke={'black'}
      strokeWidth={1.5}
      pointerEvents="none"
      strokeDasharray="1.5 1.5"
    />
  );

  return {
    tooltipView,
    tooltipIndicator,
    tooltipAreaClick: () => props.onTooltipAreaClick?.(tooltipData),
    handleTooltip,
    hideTooltip,
    tooltipData,
  };
}

function useViolinChartTooltip<Meta>({
  data,
  xScale,
  chartSizes,
}: {
  data: ViolinChartData<Meta>[];
  xScale: ScaleBand<string>;
  chartSizes: ChartSizes;
}) {
  const tooltip = useTooltip<ViolinChartTooltipData<Meta>>();

  const { scaleChartX } = useChartSizeScales({ chartSizes });

  const handleTooltip = (event: React.MouseEvent<SVGGElement>) => {
    const { x } = localPoint(event) || { x: 0 };
    const scaledX = scaleChartX(x);

    const closestGroupKey = pipe(
      () => data.map((d) => d.key),
      (d) => uniq(d),
      (d) =>
        d.reduce((acc, curr) =>
          minBy((v) => Math.abs((xScale(v) ?? Infinity) - scaledX), acc, curr)
        )
    )();

    const groups = data.filter((d) => d.key === closestGroupKey);

    if (isNotEmptyArray(groups)) {
      tooltip.showTooltip({
        tooltipData: {
          groups,
          groupKey: closestGroupKey,
        },
        tooltipLeft: scaleChartX.invert(
          getTooltipLeft({
            groupKey: closestGroupKey,
            xScale,
          })
        ),
      });
    }
  };

  return { ...tooltip, handleTooltip };
}
