// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MUIToggleButtonGroup, {
  ToggleButtonGroupProps as MUIToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';

export type ToggleButtonGroupProps<T> = MUIToggleButtonGroupProps & {
  value: T;
};

const ToggleButtonGroup = <T,>(props: ToggleButtonGroupProps<T>) => (
  <MUIToggleButtonGroup {...props} />
);

export default ToggleButtonGroup;
