import { useCallback, useMemo } from 'react';
import { Grid, Stack } from '@mui/material';
import { isEqual } from 'lodash';

import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { getEnvironment } from 'shared/models/Deployment/canary/Endpoint';
import { KafkaConfigUpdateRequestInput } from 'generated/types';
import Accordion from 'shared/view/elements/Accordion/Accordion';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { SubmitButton } from 'shared/view/elements/Button';
import EndpointKafkaFormFields from 'features/deployment/canary/endpointSettings/view/EndpointKafkaFormFields';
import useKafkaConfigurationTopics from 'features/deployment/canary/endpointSettings/hooks/useKafkaConfigurationTopics';
import DefaultMatchRemoteData from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { ExtractByTypename } from 'shared/utils/types';

import { useUpdateEndpointEnvironmentKafka } from '../../../store/endpointEnvironmentKafka/useUpdateEndpointEnvironmentKafka';
import { Endpoint } from '../../../store/endpointQuery/graphql-types/endpointQuery.generated';
import StreamingKafkaBlock from '../../EndpointOverview/StreamingBLock/StreamingKafkaBlock';

type Workspace = ExtractByTypename<Endpoint['workspace'], 'Workspace'>;

type Props = {
  endpoint: ExtractByTypename<Workspace['endpoint'], 'Endpoint'>;
};

export const KafkaConfiguration = (props: Props) => (
  <Grid py={2}>
    <Accordion
      title="Kafka Configuration"
      subtitle="Update Kafka Configuration"
      dataTest="kafkaConfiguration"
    >
      <KafkaConfigurationForm {...props} />
    </Accordion>
  </Grid>
);

const KafkaConfigurationForm = (props: Props) => {
  const environment = getEnvironment(props.endpoint);
  const organizationId = useCurrentOrganizationV2();

  const { updateKafka, updatingKafka } = useUpdateEndpointEnvironmentKafka();
  const { data, communication } = useKafkaConfigurationTopics();

  const update = useCallback(
    async (value: { kafkaReq: KafkaConfigUpdateRequestInput | undefined }) => {
      await updateKafka({
        workspaceName: props.endpoint.workspace.name,
        organizationId,
        endpointId: props.endpoint.id,
        environmentId: environment?.id ?? '',
        kafkaReq: value.kafkaReq ?? {
          disabled: true,
          kafkaConfigId: '',
          inputTopic: '',
          outputTopic: '',
          errorTopic: '',
        },
      });
    },
    [
      environment?.id,
      props.endpoint.id,
      props.endpoint.workspace.name,
      updateKafka,
      organizationId,
    ]
  );

  const initialValues = useMemo(
    (): {
      kafkaReq: KafkaConfigUpdateRequestInput | undefined;
    } => ({
      kafkaReq: environment?.kafka
        ? {
            ...environment.kafka.updateRequest,
            kafkaConfigId: environment.kafka.configId ?? '',
            inputTopic: environment.kafka.updateRequest?.inputTopic ?? '',
            outputTopic: environment.kafka.updateRequest?.outputTopic ?? '',
            errorTopic: environment.kafka.updateRequest?.errorTopic ?? '',
          }
        : undefined,
    }),
    [environment?.kafka]
  );
  return (
    <Stack direction={'column'}>
      {environment?.kafka?.status !== undefined &&
      environment.kafka.status !== null ? (
        <Stack direction={'row'}>
          <div>Kafka status</div>
          <StreamingKafkaBlock
            status={environment.kafka.status}
            message={environment.kafka.message}
          />
        </Stack>
      ) : null}
      <div>
        <PresetFormik initialValues={initialValues} onSubmit={update}>
          {({ isValid, values }) => (
            <Stack direction={'column'}>
              {/* TODO: fix Kafka configuration data */}
              <span>
                <DefaultMatchRemoteData
                  communication={communication}
                  data={data}
                  context={'preparing for create endpoint'}
                >
                  {(configurations) => (
                    <EndpointKafkaFormFields
                      initialValues={initialValues}
                      kafkaConfigurations={configurations}
                    />
                  )}
                </DefaultMatchRemoteData>
              </span>
              <span>
                <SubmitButton
                  isLoading={updatingKafka.isRequesting}
                  disabled={
                    !isValid || isEqual(initialValues.kafkaReq, values.kafkaReq)
                  }
                  dataTest="streaming-submit"
                >
                  Update
                </SubmitButton>
              </span>
            </Stack>
          )}
        </PresetFormik>
      </div>
    </Stack>
  );
};
