/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { ARTIFACT_FRAGMENT } from 'shared/graphql/Artifact/Artifact';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { ACTIVITY_FRAGMENT } from 'shared/graphql/registry/stage/Activity';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { CUSTOM_ATTRIBUTE_VALUE_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeValue';

export const MODEL_OVERVIEW_DATA_QUERY = gql`
  query ModelOverviewDataCases($id: ID!) {
    registeredModel(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        name
        pii
        dateCreated
        dateUpdated
        description
        labels
        taskType
        dataType
        versions {
          versions {
            id
            stage
            version
            dateCreated
            inputDescription
            outputDescription
            hideInputLabel
            hideOutputLabel
            registeredModel {
              id
            }
            artifacts {
              ...ArtifactData
            }
          }
        }
        activities {
          id
          ...ActivityFragment
        }
        allowedActions {
          ...AllowedActionsData
        }
        owner {
          ...OwnerData
        }
        workspace {
          id
          ...WorkspaceData
        }
        customAttributeValues {
          id
          ...CustomAttributeValueData
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ARTIFACT_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_VALUE_FRAGMENT}
`;
