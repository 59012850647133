import Box, { BoxProps } from '@mui/material/Box';
import { forwardRef } from 'react';

import { componentsSizes } from 'shared/mui/themes/CommonMuiThemeOptions';

const FieldBox = forwardRef<unknown, BoxProps>((props, ref) => (
  <Box
    ref={ref}
    minWidth={componentsSizes.input.minWidth}
    maxWidth={componentsSizes.input.maxWidth}
    {...props}
  />
));
export default FieldBox;
