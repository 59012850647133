import { Box, Popover, Stack, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { identity } from 'ramda';
import { ReactNode } from 'react';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import routes from 'shared/routes';
import { formatDateWithTime } from 'shared/utils/formatters/dateTime';
import parseGraphqlDate from 'shared/utils/graphql/parseGraphqlDate';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import RegisteredModelTypeInfo from 'shared/view/domain/Catalog/RegisteredModelTypeInfo';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { usePopoverManager } from 'shared/view/hooks/usePopoverManager';

import {
  MonitoringRegisteredModelInfoQuery,
  MonitoringRegisteredModelInfoQueryVariables,
} from './graphql-types/MonitoringModelInfoPopover.generated';

interface Props {
  model: MonitoringModel;
}

const MonitoringModelInfoPopover = (props: Props) => {
  const { isOpen, anchorEl, closePopover, openPopover } = usePopoverManager();

  return (
    <div>
      <IconAwesomeClickable
        icon={ICONS.ellipsisVertical}
        onClick={openPopover}
        size="lg"
      />

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MonitoringModelInfoPopoverContent {...props} />
      </Popover>
    </div>
  );
};

const MONITORING_REGISTERED_MODEL_INFO_QUERY = gql`
  query MonitoringRegisteredModelInfoQuery($registeredModelId: ID!) {
    registeredModel(id: $registeredModelId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        name
        taskType
        dataType
        versions {
          versions {
            id
            stage
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

const useMonitoringRegisteredModelInfo = (variables: {
  registeredModelId: string;
}) => {
  const query = useCustomQuery<
    MonitoringRegisteredModelInfoQuery,
    MonitoringRegisteredModelInfoQueryVariables
  >(MONITORING_REGISTERED_MODEL_INFO_QUERY, { variables });

  return useMemoizedResultToCommunicationWithData({
    queryResult: query,
    memoizedConvert: identity,
  });
};

const MonitoringModelInfoPopoverContent = (props: Props) => {
  const { data, communication } = useMonitoringRegisteredModelInfo({
    registeredModelId:
      props.model.state.registeredModelVersion.registeredModel.id,
  });

  return (
    <Box sx={{ padding: '16px' }}>
      <DefaultMatchRemoteDataOrError
        data={data?.registeredModel}
        communication={communication}
        context="loading registered model"
      >
        {(registeredModel) => (
          <Stack>
            <RegisteredModelTypeInfo
              kind="title"
              modelId={registeredModel.id}
              dataType={registeredModel.dataType}
              taskType={registeredModel.taskType}
              versions={registeredModel.versions.versions}
            />

            <ContentRow label="Release date">
              <Typography typography="formValue">
                {formatDateWithTime(parseGraphqlDate(props.model.createdAt))}
              </Typography>
            </ContentRow>

            {props.model.endpoint ? (
              <ContentRow label="Endpoint">
                <InlineLink
                  to={routes.endpointOverview.getRedirectPath({
                    endpointId: props.model.endpoint.id,
                    workspaceName: props.model.workspaceName,
                  })}
                >
                  {props.model.endpoint.path}
                </InlineLink>
              </ContentRow>
            ) : null}

            <ContentRow label="Registered model">
              <InlineLink
                to={routes.registeredModel.getRedirectPath({
                  registeredModelId: registeredModel.id,
                  workspaceName: props.model.workspaceName,
                })}
              >
                {registeredModel.name}
              </InlineLink>
            </ContentRow>
          </Stack>
        )}
      </DefaultMatchRemoteDataOrError>
    </Box>
  );
};

const ContentRow = (props: { label: string; children: ReactNode }) => {
  return (
    <Stack direction="row" alignItems="center">
      <Typography typography="formLabel">{`${props.label}:`}</Typography>
      {props.children}
    </Stack>
  );
};

export default MonitoringModelInfoPopover;
