import isNotNil from 'shared/utils/isNotNill';

import SummaryGrid, {
  CreatedAtSummaryRecordInfo,
  OwnerSummaryField,
  SummaryEntity,
  SummaryEntityType,
} from './SummaryGrid';

interface Props {
  entity: SummaryEntity & {
    dateCreated: number;
  };
  type: SummaryEntityType;
  labelsManager: JSX.Element;
  additionalRightPartFields?: JSX.Element | JSX.Element[];
  hideRightPartFields?: boolean;
}

const DefaultSummaryGrid = (props: Props) => {
  return (
    <SummaryGrid
      entity={props.entity}
      labelsManager={props.labelsManager}
      type={props.type}
      rightPartFields={
        props.hideRightPartFields
          ? []
          : [
              <OwnerSummaryField owner={props.entity.owner} key={0} />,
              <CreatedAtSummaryRecordInfo
                createdAt={new Date(props.entity.dateCreated)}
                key={1}
              />,
              props.additionalRightPartFields,
            ]
              .flat()
              .filter(isNotNil)
      }
    />
  );
};

export default DefaultSummaryGrid;
