import { Box, styled } from '@mui/material';
import { isEmpty } from 'lodash';

import {
  EmptyFilteredList,
  EmptyGridContainer,
} from 'shared/view/elements/EmptyGrid/EmptyGrid';

import { CustomAttributeCategories } from '../../graphql';
import { AttributeCategoryItem } from './AttributeCategoryItem';

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  rowGap: theme.spacing(2),
  columnGap: theme.spacing(3),
}));

export const CategoriesGrid = ({
  categories,
  categoriesInUse,
}: {
  categories: CustomAttributeCategories | null;
  categoriesInUse: Record<string, boolean>;
}) => {
  return categories && !isEmpty(categories) ? (
    <GridContainer>
      {categories.map((category, index) => (
        <AttributeCategoryItem
          key={index}
          category={category}
          isCategoryInUse={categoriesInUse[category.id]}
        />
      ))}
    </GridContainer>
  ) : (
    <EmptyGridContainer maxHeight="120px" sx={{ backgroundColor: 'white' }}>
      <EmptyFilteredList icon={false} message="No attributes categories." />
    </EmptyGridContainer>
  );
};
