import { useField, useFormikContext } from 'formik';
import { equals, without, isNil } from 'ramda';

import {
  ActionTypeV2,
  ResourceTypeV2,
  RoleResourceActionsInput,
} from 'generated/types';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import { validateNotEmpty } from 'shared/utils/validators';
import Checkbox from 'shared/view/elements/Checkbox/Checkbox';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import NA from 'shared/view/elements/PageComponents/NA/NA';
import Popup from 'shared/view/elements/Popup/Popup';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { updateBy, updateByOrDefault } from 'shared/utils/collection';
import {
  Role,
  EnabledResourceActions,
} from 'features/organizations/roles/store/useOrganizationRoles';

import BaseOrganizationRolesTable from './BaseOrganizationRolesTable';

export type RoleSettings = Pick<
  Role,
  'name' | 'description' | 'resourceActions'
>;

const getField = makeGetFieldName<RoleSettings>();

const RolePopup = (props: {
  initialSettings: RoleSettings;
  enabledResourceActions: EnabledResourceActions;
  title: string;
  submitButton: {
    isLoading: boolean;
    children: 'Create' | 'Update';
    onSubmit(form: RoleSettings): void;
  };
  onClose(): void;
}) => {
  return (
    <PresetFormik
      initialValues={props.initialSettings}
      enableReinitialize={true}
      onSubmit={props.submitButton.onSubmit}
    >
      {({ values, isValid, submitForm }) => {
        return (
          <Popup
            isOpen={true}
            title={props.title}
            fullWidth={true}
            maxWidth="xxl"
            buttons={{
              secondaryButtonProps: {
                isLoading: false,
                children: 'Cancel',
                onClick: props.onClose,
              },
              mainButtonProps: {
                children: props.submitButton.children,
                isLoading: props.submitButton.isLoading,
                disabled: !isValid || equals(values, props.initialSettings),
                type: 'button',
                onClick: submitForm,
              },
            }}
            onClose={props.onClose}
          >
            <FormStack>
              <TextInputField
                label="Role name"
                name={getField({ name: null })}
                isRequired={true}
                validate={validateNotEmpty('Role name')}
              />
              <TextInputField
                label="Role description"
                name={getField({ description: null })}
              />
              <PermissionsTableField
                enabledResourceActions={props.enabledResourceActions}
              />
            </FormStack>
          </Popup>
        );
      }}
    </PresetFormik>
  );
};

const PermissionsTableField = (props: {
  enabledResourceActions: EnabledResourceActions;
}) => {
  const { setFieldValue } = useFormikContext();
  const [{ value }] = useField<RoleResourceActionsInput[]>({
    name: getField({ resourceActions: null }),
  });

  return (
    <BaseOrganizationRolesTable
      renderCell={({ resourceType, actionType }) => {
        if (
          !isNil(
            props.enabledResourceActions
              .find((r) => r.resourceType === resourceType)
              ?.allowedActions.find((a) => a === actionType)
          )
        ) {
          return (
            <Checkbox
              onChange={(changed) => {
                const updatedValue = updateResourceActions(
                  changed,
                  value,
                  resourceType,
                  actionType
                );
                setFieldValue(
                  getField({ resourceActions: null }),
                  updatedValue
                );
              }}
              value={Boolean(
                value
                  .find((v) => v.resourceType === resourceType)
                  ?.allowedActions.some((a) => a === actionType)
              )}
              name={`${resourceType}.${actionType}`}
              label={undefined}
            />
          );
        }
        return NA;
      }}
    />
  );
};

function updateResourceActions(
  changed: boolean,
  value: RoleResourceActionsInput[],
  resourceType: ResourceTypeV2,
  actionType: ActionTypeV2
): RoleResourceActionsInput[] {
  const res = (() => {
    if (changed) {
      return updateByOrDefault(
        (v) => v.resourceType === resourceType,
        (v) => ({ ...v, allowedActions: v.allowedActions.concat(actionType) }),
        {
          resourceType,
          allowedActions: [actionType],
        },
        value
      );
    } else {
      return updateBy(
        (v) => v.resourceType === resourceType,
        (v) => ({
          ...v,
          allowedActions: without([actionType], v.allowedActions),
        }),
        value
      );
    }
  })();
  return res.filter((r) => r.allowedActions.length > 0);
}

export default RolePopup;
