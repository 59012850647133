import { gql } from '@apollo/client';

import { CHECKLIST_ITEM_VALUE_FRAGMENT } from 'shared/graphql/Checklist/ChecklistItemValue';
import { USER_FRAGMENT } from 'shared/graphql/User/User';

export const CHECKLIST_ITEM_VALUES_FRAGMENT = gql`
  fragment ChecklistItemValuesData on ChecklistItemValues {
    checklistItemValues {
      id
      ... on ChecklistItemValue {
        ...ChecklistItemValueData
        updatedBy {
          id
          ...UserData
        }
      }
    }
  }
  ${CHECKLIST_ITEM_VALUE_FRAGMENT}
  ${USER_FRAGMENT}
`;
