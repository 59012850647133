import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';
import { CustomRedirect } from 'shared/routes/shared/CustomRedirect';

import OverviewPage from './OverviewPage/OverviewPage';
import DocumentationPage from './DocumentationPage/DocumentationPage';
import ActivityPage from './ActivityPage/ActivityPage';
import ActionsPage from './ActionsPage/ActionsPage';
import RegisteredModelVersionsComparePage from './ModelVersionsComparePage/ModelVersionsComparePage';
import { Layout } from './shared/Layout/view';

export const modelPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.registeredModel.getPath(),
      element: <Layout />,
      children: [
        {
          path: '',
          index: true,
          element: <CustomRedirect route={routes.modelOverview} replace />,
        },
        {
          path: routes.modelOverview.getPath(),
          element: <OverviewPage />,
        },
        {
          path: routes.modelDocumentation.getPath(),
          element: <DocumentationPage />,
        },
        {
          path: routes.modelActivities.getPath(),
          element: <ActivityPage />,
        },
        {
          path: routes.modelActions.getPath(),
          element: <ActionsPage />,
        },
        {
          path: routes.registeredModelVersionsCompare.getPath(),
          element: <RegisteredModelVersionsComparePage />,
        },
        {
          path: `${routes.registeredModel.getPath()}/*`,
          element: <CustomRedirect route={routes.modelOverview} replace />,
        },
      ],
    },
  ],
};
