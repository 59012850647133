import { useState, useCallback } from 'react';

import { useDeepCompareUpdateEffect } from 'shared/view/hooks/useDeepCompareUpdateEffect';

export const useNewConfiguration = <T>(values: T) => {
  const [isConfigurationTested, setIsConfigurationTested] = useState(false);
  const setConfigurationAsTested = useCallback(
    () => setIsConfigurationTested(true),
    []
  );

  useDeepCompareUpdateEffect(() => {
    setIsConfigurationTested(false);
  }, [values]);

  return {
    isConfigurationTested,
    setConfigurationAsTested,
  };
};
