/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { IWorkspace } from 'shared/models/Workspace';
import { WorkspaceType } from 'generated/types';
import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';

import * as Types from '../Workspace/graphql-types/Workspace.generated';

export const WORKSPACE_FRAGMENT = gql`
  fragment WorkspaceData on Workspace {
    id
    oldId
    name
    type
    namespace
  }
`;

export const convertWorkspaceToGraphqlWorkspace = (
  workspace: IWorkspace
): Types.WorkspaceData => {
  const type: WorkspaceType = (() => {
    switch (workspace.type) {
      case 'user':
        return WorkspaceType.USER;
      case 'organization':
        return WorkspaceType.ORGANIZATION;
      default:
        exhaustiveCheck(workspace);
    }
  })();

  return {
    __typename: 'Workspace',
    id: workspace.id,
    name: workspace.name,
    oldId: workspace.id,
    namespace: workspace.namespace,
    type,
  };
};
