import { Box, Stack, Typography } from '@mui/material';

import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS, ICONS_SOLID } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';

import {
  BuildAndExperiment,
  Iterate,
  ReleaseAndCompareResults,
  EvaluateAndRefine,
} from './capabilities';
import { VertaFooter } from '../VertaOverall/VertaFooter';

const customTypographyStyle = {
  fontSize: '40px',
  lineHeight: '52px',
};

export const VertaOverallFreeTier = () => {
  const verbs = ['Build', 'Evaluate', 'Release', 'Iterate'];
  return (
    <Box>
      <Stack spacing={0} mb={11}>
        <Stack direction="row">
          {verbs.map((verb, index) => (
            <Stack
              color="primary.main"
              direction="row"
              key={verb}
              alignItems="center"
            >
              <Typography
                variant="h4"
                color="primary.main"
                sx={{
                  ...customTypographyStyle,
                  fontWeight: 600,
                }}
              >
                {verb}
              </Typography>
              {index === verbs.length - 1 ? null : (
                <IconAwesomeCustomizable
                  size="2xl"
                  icon={ICONS_SOLID.arrowRight}
                />
              )}
            </Stack>
          ))}
        </Stack>
        <Typography
          variant="h4"
          color="text.primary"
          sx={{
            ...customTypographyStyle,
            fontWeight: 400,
          }}
        >
          your GenAI applications, from
          <br /> prototype to product.
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        display="flex"
        mb={6}
      >
        <IconAwesomeInfo
          icon={ICONS.circleArrowDown}
          size="2xl"
          color="primary.dark"
        />
        <Typography variant="h5" color="text.primary">
          What to expect from Verta
        </Typography>
      </Stack>
      <Stack spacing={12} mb={12}>
        <BuildAndExperiment />
        <EvaluateAndRefine />
        <ReleaseAndCompareResults />
        <Iterate />
      </Stack>
      <VertaFooter />
    </Box>
  );
};
