import { gql } from '@apollo/client';
import { isNil } from 'ramda';

import { useToast } from 'features/toast/store/hooks';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { MonitoringAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import useMutationFromFunction from 'shared/utils/graphql/useMutationFromFunction';
import noop from 'shared/utils/noop';
import { isSuccessFulfilledPromiseSettledResult } from 'shared/utils/promise/isSuccessFulfilledPromiseResult';
import { DataGridHighlightCallback } from 'shared/view/elements/DataGrid/hooks/useDataGridHighlightRows';

import { UpdateBulkAlertsFormType } from '../../view/MonitoringAlerts/MonitoringAlertsTable/UpdateBulkAlertsForm/UpdateBulkAlertsForm';
import {
  UpdateAlertBulk,
  UpdateAlertBulkVariables,
} from './graphql-types/useUpdateBulkAlerts.generated';

const UPDATE_ALERT_BULK = gql`
  mutation UpdateAlertBulk(
    $alertId: ID!
    $status: AlertStatus!
    $aggregationWindow: AlertAggregationWindow!
    $evaluationFrequency: AlertEvaluationFrequency!
    $skipUpdateStatus: Boolean!
    $skipUpdateAggregationWindow: Boolean!
    $skipUpdateEvaluationFrequency: Boolean!
  ) {
    alert(id: $alertId) {
      ... on Error {
        ...ErrorData
      }
      ... on Alert {
        id
        updateStatus(status: $status) @skip(if: $skipUpdateStatus) {
          id
          status
        }
        updateAggregationWindow(aggregationWindow: $aggregationWindow)
          @skip(if: $skipUpdateAggregationWindow) {
          id
          aggregationWindow
        }
        updateEvaluationFrequency(evaluationFrequency: $evaluationFrequency)
          @skip(if: $skipUpdateEvaluationFrequency) {
          id
          evaluationFrequency
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

interface Props {
  highlight: DataGridHighlightCallback;
}

interface UpdateBulkAlertsArguments {
  alerts: MonitoringAlert[];
  form: UpdateBulkAlertsFormType;
}

export const useUpdateBulkAlerts = (props: Props) => {
  const toast = useToast();

  const [mutate, communication] = useMutationFromFunction(
    (apolloClient) => async (args: UpdateBulkAlertsArguments) => {
      return Promise.allSettled(
        args.alerts.map((alert) =>
          apolloClient
            .mutate<UpdateAlertBulk, UpdateAlertBulkVariables>({
              mutation: UPDATE_ALERT_BULK,
              variables: {
                alertId: alert.id,
                aggregationWindow:
                  args.form.aggregationWindow ?? alert.aggregationWindow,
                evaluationFrequency:
                  args.form.evaluationFrequency ?? alert.evaluationFrequency,
                status: args.form.status ?? alert.status,
                skipUpdateAggregationWindow: isNil(args.form.aggregationWindow),
                skipUpdateEvaluationFrequency: isNil(
                  args.form.evaluationFrequency
                ),
                skipUpdateStatus: isNil(args.form.status),
              },
            })
            .then(() => ({ status: 'success', alertId: alert.id }))
            .catch(() => ({ status: 'error', alertId: alert.id }))
        )
      );
    },
    {
      context: 'update bulk alerts',
    },
    {
      onCompleted: (data) => {
        const success = data
          .filter(isSuccessFulfilledPromiseSettledResult)
          .filter((d) => d.value.status === 'success')
          .map((d) => d.value.alertId);
        const error = data
          .filter(isSuccessFulfilledPromiseSettledResult)
          .filter((d) => d.value.status === 'error')
          .map((d) => d.value.alertId);

        if (success.length > 0) {
          toast(`${success.length} rows updated successfully.`, 'success');
        }

        if (error.length > 0) {
          toast(`Update failed for ${error.length} rows `, 'error');
        }

        props.highlight({
          success,
          error,
        });
      },
      onError: noop,
    }
  );

  return {
    mutate,
    communication,
  };
};
