import { ActionType, createReducer } from 'typesafe-actions';

import composeReducers from 'shared/utils/redux/composeReducers';

import * as actions from './actions';
import { RestrictedGraphqlErrorState } from './types';

const initialState = {
  error: null,
  pathname: null,
  wasClosed: false,
};

export const restrictedGraphqlErrorReducer = composeReducers([
  createReducer<RestrictedGraphqlErrorState, ActionType<typeof actions>>(
    initialState
  )
    .handleAction(actions.setRestrictedGraphqlError, (state, { payload }) =>
      payload.pathname === state.pathname
        ? state
        : {
            ...state,
            ...payload,
          }
    )
    .handleAction(actions.closeAlert, (state) => ({
      ...state,
      error: null,
      wasClosed: true,
    }))
    .handleAction(actions.resetState, () => ({
      error: null,
      pathname: null,
      wasClosed: false,
    })),
]);
