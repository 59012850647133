import { QueryHookOptions } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';

import {
  ModelLayout,
  ModelLayoutVariables,
} from './graphql-types/query.generated';
import { MODEL_LAYOUT } from './query';

export const useModelLayout = (
  { modelId }: { modelId: string },
  options?: QueryHookOptions<ModelLayout, ModelLayoutVariables>
) => {
  const query = useCustomQuery<ModelLayout, ModelLayoutVariables>(
    MODEL_LAYOUT,
    { ...options, variables: { modelId } }
  );

  return resultToCommunicationWithData(convertResponse, query);
};

const convertResponse = ({ registeredModel }: ModelLayout) =>
  mapDataOrError(registeredModel, (model) => model);
