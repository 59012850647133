import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';

import { trackEvent } from 'setup/app/analytics';
import routes from 'shared/routes';
import { defaultResultsSorting } from 'shared/models/HighLevelSearch/SearchSettings';
import { WorkspaceName } from 'shared/models/Workspace';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import isNotNil from 'shared/utils/isNotNill';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import { defaultFilter } from '../../constants';

interface Props {
  currentWorkspaceName: WorkspaceName;
}

const HeaderSearch: React.FC<React.PropsWithChildren<Props>> = ({
  currentWorkspaceName,
}) => {
  const navigate = useNavigate();
  const [value, changeValue] = React.useState<string>('');

  const [isInputShown, setIsInputShown] = React.useState<boolean>(false);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const focusInput = () => {
    // we need setTimeout because after calling setIsInputShown(true); the input node is not in dom yet
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    });
  };

  const redirectToHighLevelSearchWithValue = () => {
    trackEvent({ type: 'homepage.app_bar.search' });
    navigate(
      routes.highLevelSearch.getRedirectPathWithQueryParams({
        params: { workspaceName: currentWorkspaceName },
        queryParams: {
          q: value,
          type: defaultFilter,
          'sorting.direction': defaultResultsSorting.direction,
          'sorting.field': defaultResultsSorting.field,
        },
      })
    );
  };

  return (
    <Stack direction="row" alignItems="center">
      {!isInputShown ? (
        // marginRight needed to avoid jumping of an icon when the input is shown
        <div style={{ marginRight: '14px' }}>
          <IconAwesomeClickable
            icon={ICONS.magnifyingGlass}
            size="lg"
            onClick={() => {
              setIsInputShown(!isInputShown);
              focusInput();
            }}
          />
        </div>
      ) : (
        <ClickAwayListener onClickAway={() => setIsInputShown(false)}>
          <div style={{ width: '240px' }}>
            <TextInput
              ref={inputRef}
              value={value}
              label="Search"
              withoutError={true}
              withoutLabelPadding
              endAdornmentIcons={[
                value ? (
                  <IconAwesomeClickable
                    key={'close'}
                    icon={ICONS.close}
                    size="lg"
                    onClick={(e) => {
                      e.stopPropagation(); // without it, the click away is triggered
                      changeValue('');
                      focusInput();
                    }}
                  />
                ) : undefined,
                <IconAwesomeClickable
                  key="search"
                  size="lg"
                  icon={ICONS.magnifyingGlass}
                  onClick={redirectToHighLevelSearchWithValue}
                />,
              ].filter(isNotNil)}
              onKeyUp={(e) => {
                // when press enter
                if (e.keyCode === 13) {
                  redirectToHighLevelSearchWithValue();
                }
              }}
              onChange={changeValue}
            />
          </div>
        </ClickAwayListener>
      )}
    </Stack>
  );
};

export default HeaderSearch;
