import React from 'react';

import PagesTabs from 'shared/view/pages/PagesTabs/PagesTabs';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import routes from 'shared/routes';
import getTabs from 'shared/view/pages/PagesTabs/getTabs';

interface ILocalProps {
  projectId: string;
  rightContent?: React.ReactNode;
}

const ProjectPageTabs: React.FC<React.PropsWithChildren<ILocalProps>> = ({
  projectId,
  rightContent,
}) => {
  const workspaceName = useWorkspaceNameFromURL();

  return (
    <PagesTabs
      tabs={getTabs(routes.project.$)(
        [
          ['overview', () => 'Overview'],
          ['experiments', () => 'Experiments'],
          ['exp-runs', () => 'Experiment runs'],
          ['charts', () => 'Charts'],
        ],
        { projectId, workspaceName }
      )}
      rightContent={rightContent}
    />
  );
};

export default ProjectPageTabs;
