import { gql } from '@apollo/client';

import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import { CrossRunDashboardForComparing } from 'features/experimentRuns/compare/model/CrossRunDashboardForComparing';
import { saveDashboardChangesAsAttribute } from 'features/experimentRuns/compare/model/AttributeWithCrossRunDashboardForComparing';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

import * as GraphqlTypes from './graphql-types/useSaveDashboardChanges.generated';

const MUTATION = gql`
  mutation UpdateAttributeWithDashboardForComparing(
    $projectId: ID!
    $attribute: StringKeyValueInput!
  ) {
    project(id: $projectId) {
      ... on Error {
        ...ErrorData
      }
      ... on Project {
        id
        updateAttribute(keyValue: $attribute) {
          id
          attributes {
            ...AttributeData
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
`;

export const useSaveDashboardChanges = () => {
  const [mutate, savingDashboardChanges] = useCustomMutation<
    GraphqlTypes.UpdateAttributeWithDashboardForComparing,
    GraphqlTypes.UpdateAttributeWithDashboardForComparingVariables
  >(MUTATION, { context: 'saving dashboard' });
  const saveDashboardChanges = (
    dashboard: CrossRunDashboardForComparing,
    projectId: string
  ) =>
    mutate({
      projectId,
      attribute: saveDashboardChangesAsAttribute(dashboard),
    });

  return {
    saveDashboardChanges,
    savingDashboardChanges,
  };
};
