import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';

import {
  MODEL_VERSION_DOCUMENTATION_DATA_QUERY,
  ModelVersionDocumentationData,
  ModelVersionDocumentationDataVariables,
} from '../graphql';

type Props = ModelVersionDocumentationDataVariables;

export const useModelVersionDocumentation = ({ id }: Props) => {
  const query = useCustomQuery<
    ModelVersionDocumentationData,
    ModelVersionDocumentationDataVariables
  >(MODEL_VERSION_DOCUMENTATION_DATA_QUERY, { variables: { id } });

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return {
    data,
    communication,
    reload: query.refetch,
  };
};

const convertResponse = ({
  registeredModelVersion,
}: ModelVersionDocumentationData) =>
  mapDataOrError(registeredModelVersion, (version) => version);
