import { map, pipe, take } from 'ramda';

import isNotNil from 'shared/utils/isNotNill';
import * as NonEmptyArray from 'shared/utils/opaqueTypes/NonEmptyArray';

import {
  ExperimentRunWithDisplayedFields,
  getValueByKeyValueSpec,
} from './CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import { dateUpdatedSpec } from './CrossRunWidget/CrossRunCustomWidget/DataSpec';
import { KeyValueSpec } from './CrossRunWidget/CrossRunCustomWidget/KeyValueSpec';
import {
  getParallelCoordinateChartAxisesDataSpecs,
  ParallelCoordinateChartSettings,
} from './CrossRunWidget/CrossRunCustomWidget/WidgetSettings/ParallelCoordinateChartSettings';
import {
  getScatterChartAxisesDataSpecs,
  ScatterChartSettings,
} from './CrossRunWidget/CrossRunCustomWidget/WidgetSettings/ScatterChartSettings';
import { emptyCustomDomain } from './CrossRunWidget/CrossRunCustomWidget/WidgetSettings/shared/CustomDomain';

export const generateParallelCoordinateChart = (
  keyValueSpecs: KeyValueSpec[],
  experimentRuns: ExperimentRunWithDisplayedFields[]
): ParallelCoordinateChartSettings | undefined => {
  const yAxisSpecs = getParallelCoordinateChartAxisesDataSpecs(
    keyValueSpecs
  ).yAxis.filter((spec) =>
    experimentRuns.every((experimentRun) =>
      isNotNil(getValueByKeyValueSpec(spec, experimentRun))
    )
  );

  return NonEmptyArray.isNonEmptyArray(yAxisSpecs)
    ? {
        type: 'parallelCoordinateChart',
        name: 'Parallel coordinate chart',
        decimalPrecision: 1,
        yAxis: {
          specs: yAxisSpecs,
        },
      }
    : undefined;
};

export const generateMetricsCharts = (
  keyValueSpecs: KeyValueSpec[]
): ScatterChartSettings[] => {
  const chartsLimit = 10;
  const chartDataSpecs = getScatterChartAxisesDataSpecs(keyValueSpecs);

  return pipe(
    () => chartDataSpecs.yAxis.filter((x) => x.subtype === 'metric'),
    (x) => take(chartsLimit, x),
    map(
      (spec): ScatterChartSettings => ({
        name: `${spec.key} metric summary`,
        type: 'scatterChart',
        xAxis: {
          spec: dateUpdatedSpec,
          domain: emptyCustomDomain,
          isLogScale: false,
        },
        yAxis: {
          spec,
          domain: emptyCustomDomain,
          isLogScale: false,
        },
        zAxis: {
          spec: NonEmptyArray.head(
            chartDataSpecs.zAxis.experimentRunStringField
          ),
        },
      })
    )
  )();
};
