import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import routes from 'shared/routes';
import { Tab } from 'shared/view/pages/PagesTabs/PagesTabs';
import isNotNil from 'shared/utils/isNotNill';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { selectCurrentUserOrThrowError } from 'features/user';
import { getActivitiesWithTransitionsPendingApproval } from 'shared/models/Registry/RegisteredModelVersion/Stage/ActivityWithTransitionPendingApproval';
import { ModelVersionSummary } from 'features/catalog/registeredModelVersion/summary/store/graphql-types/useModelVersionSummary.generated';
import { selectFlags } from 'features/flags';
import { ExtractByTypename } from 'shared/utils/types';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

type Props = {
  registeredModelId: string;
  activities?: ExtractByTypename<
    ModelVersionSummary['registeredModelVersion'],
    'RegisteredModelVersion'
  >['activities'];
};

const useModelTabs = (props: Props) => {
  const workspaceName = useWorkspaceNameFromURL();
  const currentUser = useSelector(selectCurrentUserOrThrowError);
  const {
    catalog: { isEnableMLAssistance },
  } = useSelector(selectFlags);
  const { registeredModelId, activities } = props;

  const alertingActivitiesCount = useMemo(() => {
    if (activities) {
      const pendingActivities =
        getActivitiesWithTransitionsPendingApproval(activities);
      const fromOtherRequests = pendingActivities.filter(
        (activity) => activity.author.id !== currentUser.id
      );

      return fromOtherRequests.length;
    }
  }, [currentUser.id, activities]);

  return useMemo(() => {
    return [
      {
        label: 'Overview',
        to: routes.modelOverview.getRedirectPath({
          registeredModelId,
          workspaceName,
        }),
      },
      {
        label: 'Documentation',
        icon: isEnableMLAssistance ? ICONS.sparkles : undefined,
        to: routes.modelDocumentation.getRedirectPath({
          registeredModelId,
          workspaceName,
        }),
      },
      {
        label: 'Activity',
        to: routes.modelActivities.getRedirectPath({
          registeredModelId,
          workspaceName,
        }),
      },
      {
        label: 'Pending approvals',
        to: routes.modelActions.getRedirectPath({
          registeredModelId,
          workspaceName,
        }),
        counter: {
          value: alertingActivitiesCount,
          color: 'error' as const,
        },
      },
    ].filter(isNotNil) as Array<Tab>;
  }, [
    registeredModelId,
    workspaceName,
    isEnableMLAssistance,
    alertingActivitiesCount,
  ]);
};

export default useModelTabs;
