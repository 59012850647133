import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import routes from 'shared/routes';
import { Tab } from 'shared/view/pages/PagesTabs/PagesTabs';
import { selectFlags } from 'features/flags';
import isNotNil from 'shared/utils/isNotNill';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { selectCurrentUserOrThrowError } from 'features/user';
import { getActivitiesWithTransitionsPendingApproval } from 'shared/models/Registry/RegisteredModelVersion/Stage/ActivityWithTransitionPendingApproval';
import { ModelVersionSummary } from 'features/catalog/registeredModelVersion/summary/store/graphql-types/useModelVersionSummary.generated';
import { ExtractByTypename } from 'shared/utils/types';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

type Props = {
  registeredModelId: string;
  versionId: string;
  activities?: ExtractByTypename<
    ModelVersionSummary['registeredModelVersion'],
    'RegisteredModelVersion'
  >['activities'];
};

const useModelVersionTabs = (props: Props) => {
  const workspaceName = useWorkspaceNameFromURL();
  const currentUser = useSelector(selectCurrentUserOrThrowError);

  const { registeredModelId, versionId, activities } = props;
  const {
    isEnableWorkflows,
    isEnableFairness,
    catalog: { isEnableMLAssistance },
  } = useSelector(selectFlags);

  const alertingActivitiesCount = useMemo(() => {
    if (activities) {
      const pendingActivities =
        getActivitiesWithTransitionsPendingApproval(activities);
      const fromOtherRequests = pendingActivities.filter(
        (activity) => activity.author.id !== currentUser.id
      );

      return fromOtherRequests.length;
    }
  }, [currentUser.id, activities]);

  return useMemo(() => {
    const params = {
      registeredModelId,
      versionId,
      workspaceName,
    };
    return [
      {
        label: 'Summary',
        to: routes.modelVersionSummary.getRedirectPath(params),
        counter: {
          value: alertingActivitiesCount,
          color: 'error' as const,
        },
      },
      {
        label: 'Documentation',
        icon: isEnableMLAssistance ? ICONS.sparkles : undefined,
        to: routes.modelVersionDocumentation.getRedirectPath(params),
      },
      {
        label: 'Reproduce',
        to: routes.modelVersionReproduce.getRedirectPath(params),
      },
      {
        label: 'Integrate',
        to: routes.modelVersionIntegrate.getRedirectPath(params),
      },
      {
        label: 'Govern',
        to: routes.modelVersionGovern.getRedirectPath(params),
      },
      {
        label: 'Release',
        to: routes.modelVersionRelease.getRedirectPath(params),
      },
      isEnableFairness
        ? {
            label: 'Fairness',
            to: routes.fairnessPage.getRedirectPath(params),
          }
        : null,
      isEnableWorkflows
        ? {
            label: 'Workflows',
            to: routes.modelVersionWorkflows.getRedirectPath(params),
          }
        : null,
    ].filter(isNotNil) as Array<Tab>;
  }, [
    registeredModelId,
    versionId,
    workspaceName,
    alertingActivitiesCount,
    isEnableMLAssistance,
    isEnableFairness,
    isEnableWorkflows,
  ]);
};

export default useModelVersionTabs;
