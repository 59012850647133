import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import { KafkaConfigurationFragment } from '../store/kafkaConfigurationFragment';
import * as GraphqlTypes from './graphql-types/useDeleteKafkaConfiguration.generated';

const MUTATION = gql`
  mutation DeleteKafkaConfiguration($id: ID!) {
    kafkaConfiguration(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on KafkaConfiguration {
        id
        delete {
          id
          configurations {
            id
            ...KafkaConfigurationFragment
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${KafkaConfigurationFragment}
`;

export const useDeleteKafkaConfiguration = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [deleteKafkaConfiguration, deletingKafkaConfiguration] =
    useCustomMutation<
      GraphqlTypes.DeleteKafkaConfiguration,
      GraphqlTypes.DeleteKafkaConfigurationVariables
    >(MUTATION, { context: 'deleting kafka configuration' }, { onCompleted });
  return { deleteKafkaConfiguration, deletingKafkaConfiguration };
};
