/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { DatasetVersionResult } from 'shared/models/HighLevelSearch/SearchResult';
import { convertSortingToGraphql } from 'shared/graphql/sorting/converters';
import { ChangePropertyType } from 'shared/utils/types';
import { changeSortingDateFieldName } from 'shared/models/Sorting';
import {
  isRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';

import * as GraphqlTypes from './graphql-types/loadDatasetVersionResults.generated';
import convertHighLevelSearchFilterToGraphql from '../shared/converters';
import {
  IHighLevelGraphqlSearchSettings,
  IHighLevelSearchFilter,
} from '../shared/types';

const DATASET_VERSION_RESULTS = gql`
  query DatasetVersionResults(
    $workspaceName: String!
    $organizationId: ID
    $query: DatasetVersionsQuery!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        name
        datasetVersions(query: $query) {
          datasetVersions {
            id
            version
            owner {
              id
              username
            }
            dateCreated
            dateUpdated
            tags
            dataset {
              id
              name
              workspace {
                id
                ...WorkspaceData
              }
            }
          }
          total
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
`;

const loadDatasetVersionResults = async ({
  workspaceName,
  sorting,
  pagination,
  organizationId,
  filter,
  apolloClient,
}: ChangePropertyType<
  IHighLevelGraphqlSearchSettings,
  'filter',
  Extract<IHighLevelSearchFilter, { type: 'tag' }>
>) => {
  const res = await apolloClient.query<
    GraphqlTypes.DatasetVersionResults,
    GraphqlTypes.DatasetVersionResultsVariables
  >({
    query: DATASET_VERSION_RESULTS,
    fetchPolicy: 'no-cache',
    variables: {
      workspaceName,
      organizationId,
      query: {
        stringPredicates: [
          convertHighLevelSearchFilterToGraphql(filter, {
            tagFilterToLabelFilter: false,
          }),
        ],
        sorting: sorting
          ? convertSortingToGraphql(
              changeSortingDateFieldName(
                {
                  date_created: 'time_logged',
                  date_updated: 'time_updated',
                },
                sorting
              )
            )
          : undefined,
        pagination: {
          limit: pagination.pageSize,
          page: pagination.currentPage,
        },
      },
    },
  });
  if (
    isRestrictedGraphqlError(res.data.workspace) ||
    (res.errors || []).length > 0
  ) {
    return { totalCount: 0, data: [] };
  }
  return {
    data: res.data.workspace.datasetVersions.datasetVersions.map(
      (datasetVersion) => {
        const datasetVersionResult: DatasetVersionResult = {
          ...datasetVersion,
          dataset: datasetVersion.dataset,
          dateCreated: new Date(datasetVersion.dateCreated),
          dateUpdated: new Date(datasetVersion.dateUpdated),
          owner: datasetVersion.owner,
          workspace: datasetVersion.dataset.workspace,
          entityType: 'datasetVersions',
        };
        return datasetVersionResult;
      }
    ),
    totalCount: res.data.workspace.datasetVersions.total,
  };
};

export default loadDatasetVersionResults;
