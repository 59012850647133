import { IComment } from 'shared/models/Comment';
import { UserData } from 'shared/graphql/User/graphql-types/User.generated';

export const convertServerComment = (
  serverComment: ServerComment,
  user: UserData
): IComment => {
  return {
    user,
    id: serverComment.id,
    dateTime: new Date(Number(serverComment.date_time)),
    message: serverComment.message,
  };
};

export type ServerComment = {
  verta_id: string;
  id: string;
  date_time: string;
  message: string;
};
