// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MuiDialog from '@mui/material/Dialog';

import PopupTitle from 'shared/view/elements/Popup/PopupTitle';
import { PopupProps } from 'shared/view/elements/Popup/Popup';
import { OmitStrict } from 'shared/utils/types';

type Props = OmitStrict<PopupProps, 'buttons'>;

/**
 * Do not use that component. It was created specifically for Monitoring feature, because there are too many
 * rework/refactoring required, so we decide to use this approach, but forbid to use it in other places;
 */

export default function MonitoringPopup(props: Props) {
  const { title, isOpen, children, onClose, fullScreen } = props;

  return (
    <MuiDialog open={isOpen} fullScreen={fullScreen} maxWidth={false}>
      <PopupTitle onClose={onClose} title={title} />
      {children}
    </MuiDialog>
  );
}
