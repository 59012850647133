import { Wizard, WizardStepProps } from 'shared/view/elements/Wizard';

import * as slides from './slides';

const steps: WizardStepProps[] = [
  {
    stepLabel: { icon: '1', text: 'Start a model vulnerability scan' },
    slides: [slides.StartScan],
  },
  {
    stepLabel: { icon: '2', text: 'View scan results' },
    slides: [slides.ViewScanResults, slides.ViewScanReports],
  },
  {
    stepLabel: false,
    slides: [slides.ScanSampleModel],
  },
];

export const VulnerabilityScanning = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  return <Wizard steps={steps} onCompleted={onCompleted} />;
};
