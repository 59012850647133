import { useEffect } from 'react';

import { IWorkspace } from 'shared/models/Workspace';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { useSorting } from 'features/catalog/registeredModel/catalogList/hooks/useSorting';
import { PredicateOperator } from 'generated/types';
import { VERTA_EVALUATION_LABEL } from 'features/evaluations/shared/utils/evaluation';

import { useWorkspaceModels } from './useWorkspaceModels';

export const useWorkspaceEvaluations = ({
  workspaceName,
}: {
  workspaceName: IWorkspace['name'];
}) => {
  const { data, communication, load: loadQuery } = useWorkspaceModels();
  const organizationId = useCurrentOrganizationV2();

  const { ascending, key } = useSorting({
    field: 'time_updated',
    direction: 'desc',
  });

  useEffect(() => {
    loadQuery({
      variables: {
        workspaceName,
        organizationId,
        stringPredicates: [
          {
            key: 'label',
            operator: PredicateOperator.EQ,
            value: VERTA_EVALUATION_LABEL,
          },
        ],
        sorting: {
          ascending,
          key,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceName]);

  return {
    data,
    reload: loadQuery,
    communication,
  };
};
