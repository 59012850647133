import { useMemo, useState } from 'react';

import { MonitoringDoubleFilterOperator } from 'generated/types';
import {
  getDefaultIODescriptions,
  MonitoringIODescription,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import {
  getNextFilterId,
  MonitoringFilter,
} from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';

export const useMonitoringFilters = (props: {
  initialFilters: MonitoringFilter[];
  ioDescriptions: MonitoringIODescription[];
}) => {
  const [filters, setFilters] = useState(props.initialFilters);

  const { defaultInput } = useMemo(
    () => getDefaultIODescriptions(props.ioDescriptions),
    [props.ioDescriptions]
  );

  const addFilter = () => {
    if (defaultInput) {
      setFilters(
        filters.concat([
          {
            id: getNextFilterId(filters),
            type: 'number',
            operator: MonitoringDoubleFilterOperator.EQ,
            ioDescription: defaultInput,
            value: 0,
          },
        ])
      );
    }
  };

  const removeFilter = (id: number) => {
    setFilters(filters.filter((f) => f.id !== id));
  };

  const updateFilter = (filter: MonitoringFilter) => {
    setFilters(filters.map((f) => (f.id === filter.id ? filter : f)));
  };

  return {
    filters,
    updateFilter,
    removeFilter,
    addFilter,
  };
};
