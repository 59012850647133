import { useCallback } from 'react';

import { InitializedMonitoringWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidget';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import Tooltip from 'shared/view/elements/Tooltip/Tooltip';
import WidgetFormPopup from 'shared/view/domain/Dashboards/WidgetFormPopup';
import MonitoringWidgetForm, {
  MonitoringWidgetFormType,
} from 'features/monitoring/shared/MonitoringWidgetForm/MonitoringWidgetForm';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

interface Props {
  widget: InitializedMonitoringWidget;
  updateWidget: (Widget: InitializedMonitoringWidget) => void;
  widgetExternalDeps: MonitoringWidgetExternalDeps;
}

const EditWidgetAction = (props: Props) => {
  const { closePopup, isPopupOpen, openPopup } = usePopupManager();

  const onSubmit = useCallback(
    (form: MonitoringWidgetFormType) => {
      props.updateWidget({ id: props.widget.id, ...form });
      closePopup();
    },
    [props, closePopup]
  );

  return (
    <>
      <Tooltip type="withSpan" title="Edit widget">
        <IconAwesomeClickable
          icon={ICONS.pencil}
          onClick={openPopup}
          dataTest="edit-widget"
        />
      </Tooltip>
      <WidgetFormPopup isOpen={isPopupOpen} closePopup={closePopup}>
        <MonitoringWidgetForm
          widgetExternalDeps={props.widgetExternalDeps}
          widgetType={props.widget.type}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          initialValues={props.widget as any}
          onSubmit={onSubmit}
          submitText="OK"
        />
      </WidgetFormPopup>
    </>
  );
};

export default EditWidgetAction;
