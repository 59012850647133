import { Box, Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import docsEditorHighlight from '../images/docs-editor-highlight.png';

export const DocsEditorHighlight = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Generate AI assisted content
    </Typography>
    <Typography variant="body2" mb={3}>
      Your documentation won't be updated until you copy and save the text into
      the editor. This is your chance to confirm the accuracy of the ML
      assistance.
    </Typography>
    <Typography variant="body2" mb={3}>
      Once saved, your documentation will be available in your catalog, and you
      can also download a markdown file for uploading to GitHub or other tools.
    </Typography>
    <Box>
      <img
        src={docsEditorHighlight}
        width="100%"
        height="auto"
        alt="homepage invite users button modal"
      />
    </Box>
  </WizardStepContent>
);
