import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { toGraphQLDate } from 'shared/utils/graphql/toGraphQLDate';
import {
  OutlierDetectionOverTime,
  OUTLIER_DETECTION_MAX_SAMPLES,
} from 'shared/models/Monitoring/OutlierDetection';
import { convertTimeRangeToDateRange } from 'shared/utils/TimeRange';
import parseGraphqlDate from 'shared/utils/graphql/parseGraphqlDate';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';
import {
  isNotRestrictedGraphqlError,
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { convertMonitoringFilterToGraphQL } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';
import { ExtractByTypename } from 'shared/utils/types';

import {
  OutlierDetectionOverTimeQuery,
  OutlierDetectionOverTimeQueryVariables,
} from './graphql-types/useOutlierDetectionOverTime.generated';
import { convertIODescriptionToQuery } from '../shared/convertIODescriptionToQuery';

type MonitoredEntity = ExtractByTypename<
  OutlierDetectionOverTimeQuery['monitoredEntity'],
  'MonitoredEntity'
>;

const OUTLIER_DETECTION_OVER_TIME_QUERY = gql`
  query OutlierDetectionOverTimeQuery(
    $monitoredEntityId: ID!
    $outlierQuery: MonitoringOutlierQuery!
    $aggregationMilliseconds: Int!
  ) {
    monitoredEntity(id: $monitoredEntityId) {
      ... on Error {
        ...ErrorData
      }
      ... on MonitoredEntity {
        id
        metrics {
          outliersOverTime(
            query: {
              base: $outlierQuery
              aggregationMilliseconds: $aggregationMilliseconds
            }
          ) {
            time
            data {
              mean
              median
              sigma
              modelVersionId
              sampledInliers
              sampledOutliers
              outlierSigmaThreshold
              modelVersion {
                ... on Error {
                  ...ErrorData
                }
                ... on RegisteredModelVersion {
                  id
                  version
                }
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

interface Props {
  description: MonitoringIODescription;
  widgetExternalDeps: MonitoringWidgetExternalDeps;
}

export const useOutlierDetectionOverTime = (props: Props) => {
  const variables = useMemo((): OutlierDetectionOverTimeQueryVariables => {
    const dateRange = convertTimeRangeToDateRange(
      props.widgetExternalDeps.timeRange
    );
    return {
      monitoredEntityId: props.widgetExternalDeps.monitoredEntityId,
      outlierQuery: {
        startDate: toGraphQLDate(dateRange.from),
        endDate: toGraphQLDate(dateRange.to),
        ioDescriptions: [convertIODescriptionToQuery(props.description)],
        maxSamples: OUTLIER_DETECTION_MAX_SAMPLES,
        filters: props.widgetExternalDeps.filters.map(
          convertMonitoringFilterToGraphQL
        ),
      },
      aggregationMilliseconds: props.widgetExternalDeps.aggregation.timeWindow,
    };
  }, [
    props.widgetExternalDeps.timeRange,
    props.widgetExternalDeps.monitoredEntityId,
    props.widgetExternalDeps.aggregation.timeWindow,
    props.widgetExternalDeps.filters,
    props.description,
  ]);

  const query = useCustomQuery<
    OutlierDetectionOverTimeQuery,
    OutlierDetectionOverTimeQueryVariables
  >(OUTLIER_DETECTION_OVER_TIME_QUERY, {
    variables,
  });

  const convert = useCallback(
    (res: OutlierDetectionOverTimeQuery) => {
      return mapDataOrError(res.monitoredEntity, (monitoredEntity) => {
        return monitoredEntity.metrics.outliersOverTime.flatMap((d) =>
          convertOutlierDetectionOverTime(
            props.widgetExternalDeps.registeredModelVersionIds,
            d
          )
        );
      });
    },
    [props.widgetExternalDeps.registeredModelVersionIds]
  );

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult: query,
  });
};

const convertOutlierDetectionOverTime = (
  registeredModelVersionIds: string[],
  data: MonitoredEntity['metrics']['outliersOverTime'][0]
): OutlierDetectionOverTime[] => {
  const filteredData = {
    ...data,
    data: data.data.filter(({ modelVersionId }) =>
      registeredModelVersionIds.includes(modelVersionId)
    ),
    time: data.time.filter((_, i) =>
      registeredModelVersionIds.includes(data.data[i].modelVersionId)
    ),
  };

  return filteredData.time.map((time, index) => {
    const modelVersion = filteredData.data[index].modelVersion;
    return {
      time: parseGraphqlDate(time),
      inliers: filteredData.data[index].sampledInliers.map(parseGraphQLNumber),
      outliers:
        filteredData.data[index].sampledOutliers.map(parseGraphQLNumber),
      mean: parseGraphQLNumber(filteredData.data[index].mean),
      median: parseGraphQLNumber(filteredData.data[index].median),
      sigma: parseGraphQLNumber(filteredData.data[index].sigma),
      outlierSigmaThreshold: filteredData.data[index].outlierSigmaThreshold,
      modelVersion: isNotRestrictedGraphqlError(modelVersion)
        ? modelVersion.version
        : filteredData.data[index].modelVersionId,
    };
  });
};
