import { IKeyValuePair } from 'shared/models/KeyValuePair';
import { Primitive } from 'shared/utils/types';

import {
  compareKeyedItemsProperties,
  KeyedItemsPropertiesDiff,
} from './keyedItemsPropertiesDiff';

export type KeyValuePairsDiff<T extends Primitive> = KeyedItemsPropertiesDiff<
  IKeyValuePair<T>,
  'value'
>;

export const compareKeyValuePairs = <
  E extends { id: string },
  T extends Primitive,
>(
  entities: E[],
  getPairs: (entity: E) => IKeyValuePair<T>[]
): KeyValuePairsDiff<T> => {
  return compareKeyedItemsProperties(
    ({ key }) => key,
    ['value'],
    entities.map(getPairs)
  );
};
