import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { AreaClosed, Circle, LinePath } from '@visx/shape';
import { curveMonotoneX } from 'd3-shape';
import { ScaleLinear } from 'd3-scale';

import { ChartMargin, getChartSizes } from 'shared/utils/charts/chartSizes';
import { OnSetReset } from 'shared/utils/charts/zoom/useZoomReset';
import matchBy from 'shared/utils/matchBy';
import { defaultChartMargin } from 'shared/view/charts/shared/margin';
import ChartSVG from 'shared/view/charts/shared/ChartSVG/ChartSVG';
import CustomAxisLeftWithGridRows from 'shared/view/charts/Axis/CustomAxisLeftWithGridRows/CustomAxisLeftWithGridRows';
import AxisBottomWithRotatedTicks from 'shared/view/charts/Axis/AxisBottomWithRotatedTicks/AxisBottomWithRotatedTicks';
import PlotWithBrush from 'shared/view/charts/MultiLineChart/PlotWithBrush';
import { useZoomedXYChartScales } from 'shared/utils/charts/zoom/useZoomedXYChartScales';
import XYChartBorders from 'shared/view/charts/shared/XYChart/XYChartBorders/XYChartBorders';
import { formatDateNumber } from 'shared/utils/charts/formatDateNumber';
import { BasePoint } from 'shared/view/charts/MultiLineChart/utils/point';
import between from 'shared/utils/between';
import { getLinearScaleDomain } from 'shared/utils/charts/getLinearScaleDomain';
import { getLinearDateScaleDomain } from 'shared/utils/charts/getLinearDateScaleDomain';

import {
  groupOutlierChartData,
  OutlierDetectionChartData,
} from './utils/types';
import { useOutlierDetectionChartTooltipWithView } from './utils/tooltip';
import { outlierDetectionChartColorScale } from './utils/colorScale';

interface Props {
  width: number;
  height: number;
  id: string;
  data: OutlierDetectionChartData[];
  onSetReset: OnSetReset;
  yDomain?: [number, number];
  margin?: ChartMargin;
}

const getYDomain = (data: OutlierDetectionChartData[]) =>
  getLinearScaleDomain(
    data.flatMap((d) =>
      matchBy(
        d,
        'type'
      )<number | number[]>({
        mean: ({ y }) => y,
        normalArea: ({ y0, y1 }) => [y1, y0],
        outlier: ({ y }) => y,
        inlier: ({ y }) => y,
        median: ({ y }) => y,
      })
    )
  );

const getXDomain = (data: OutlierDetectionChartData[]) =>
  getLinearDateScaleDomain(data.map(({ x }) => x));

const OutlierDetectionChartCore = (props: Props) => {
  const chartSizes = getChartSizes({
    margin: defaultChartMargin,
    width: props.width,
    height: props.height,
  });

  const xDomain = useMemo(() => getXDomain(props.data), [props.data]);
  const yDomain = useMemo(() => getYDomain(props.data), [props.data]);

  const { xScale, yScale, zoomApi, selectedDomain } = useZoomedXYChartScales({
    innerHeight: chartSizes.innerHeight,
    innerWidth: chartSizes.innerWidth,
    onSetReset: props.onSetReset,
    xDomain,
    yDomain: props.yDomain ?? yDomain,
  });

  const tooltipApi = useOutlierDetectionChartTooltipWithView({
    data: selectedDomain
      ? props.data.filter((point) => between(point.x, ...selectedDomain.x))
      : props.data,
    xScale,
    yScale,
    chartSizes,
  });

  const groupedData = useMemo(
    () => groupOutlierChartData(props.data),
    [props.data]
  );

  const theme = useTheme();

  return (
    <ChartSVG
      width={chartSizes.width}
      height={chartSizes.height}
      margin={chartSizes.margin}
      isNilData={props.data.length === 0}
      outSvgContent={tooltipApi.tooltipView}
    >
      <CustomAxisLeftWithGridRows
        innerWidth={chartSizes.innerWidth}
        scale={yScale}
        label="Value"
        numTicks={6}
      />
      <AxisBottomWithRotatedTicks
        scale={xScale}
        top={chartSizes.innerHeight}
        stroke={theme.palette.charts.accentColor}
        tickStroke={theme.palette.charts.accentColor}
        tickFormat={formatDateNumber}
        label="Timestamp"
        numTicks={6}
      />

      <PlotWithBrush
        {...zoomApi}
        tooltipApi={tooltipApi}
        chartSizes={chartSizes}
        xScale={xScale}
        yScale={yScale}
      >
        <XYChartBorders
          chartId={props.id}
          innerWidth={chartSizes.innerWidth}
          innerHeight={chartSizes.innerHeight}
        >
          <LinePath
            curve={curveMonotoneX}
            data={groupedData.mean}
            x={({ x }) => xScale(x)}
            y={({ y }) => yScale(y)}
            strokeWidth={2}
            stroke={outlierDetectionChartColorScale('mean')}
          />

          <AreaClosed
            data={groupedData.normalArea}
            x={({ x }) => xScale(x)}
            y0={({ y0 }) => yScale(y0)}
            y1={({ y1 }) => yScale(y1)}
            yScale={yScale}
            fill={outlierDetectionChartColorScale('normalArea')}
            curve={curveMonotoneX}
          />

          <Points
            id={props.id + '-inlier'}
            points={groupedData.inlier ?? []}
            color={outlierDetectionChartColorScale('inlier')}
            xScale={xScale}
            yScale={yScale}
          />

          <Points
            id={props.id + '-outlier'}
            points={groupedData.outlier ?? []}
            color={outlierDetectionChartColorScale('outlier')}
            xScale={xScale}
            yScale={yScale}
          />

          <LinePath
            curve={curveMonotoneX}
            data={groupedData.median}
            x={({ x }) => xScale(x)}
            y={({ y }) => yScale(y)}
            strokeWidth={2}
            stroke={outlierDetectionChartColorScale('median')}
          />
        </XYChartBorders>
      </PlotWithBrush>
    </ChartSVG>
  );
};

const Points = (props: {
  id: string;
  points: BasePoint[];
  color: string;
  xScale: ScaleLinear<number, number, never>;
  yScale: ScaleLinear<number, number, never>;
}) => {
  return (
    <g>
      {props.points.map((d, i) => (
        <Circle
          key={`${props.id}-${i}`}
          cx={props.xScale(d.x)}
          cy={props.yScale(d.y)}
          fill={props.color}
          r={3}
        />
      ))}
    </g>
  );
};

export default OutlierDetectionChartCore;
