import { Operation } from '@apollo/client';
import { Dispatch, Store } from 'redux';
import { createBrowserRouter } from 'react-router-dom';

import { toastCommunicationErrorActionCreator } from 'features/toast/store/actions';
import { GraphqlErrorContextWithProp } from 'setup/apollo/createRestrictedGraphqlErrorLink';
import { IApplicationState } from 'setup/store/store';
import {
  RestrictedGraphqlError,
  restrictedGraphqlErrorToAppError,
} from 'shared/graphql/ErrorFragment';

import { setRestrictedGraphqlError, resetState } from './actions';

export const makeRestrictedGraphqlErrorInterceptor = ({
  store,
  router,
}: {
  store: Store<IApplicationState>;
  router: ReturnType<typeof createBrowserRouter>;
}) => {
  router.subscribe(() => {
    store.dispatch(resetState());
  });

  return (error: RestrictedGraphqlError, operation: Operation) => {
    store.dispatch(
      setRestrictedGraphqlError({
        error,
        pathname: router.state.location.pathname,
      })
    );

    toastErrorWhenOperationIsMutation({
      error,
      operation,
      dispatch: store.dispatch,
    });
  };
};

const toastErrorWhenOperationIsMutation = ({
  dispatch,
  operation,
  error,
}: {
  error: RestrictedGraphqlError; // to ensure that this function is called only when there is an error
  operation: Operation;
  dispatch: Dispatch<any>;
}) => {
  const operationDefinition = operation.query.definitions.find(
    (d) => d.kind === 'OperationDefinition'
  );
  if (
    operationDefinition &&
    operationDefinition.kind === 'OperationDefinition' &&
    operationDefinition.operation === 'mutation'
  ) {
    dispatch(
      toastCommunicationErrorActionCreator(
        restrictedGraphqlErrorToAppError(error),
        {
          context:
            (operation.getContext() as Partial<GraphqlErrorContextWithProp>)
              .errorOptions?.context || '',
        }
      )
    );
  }
};
