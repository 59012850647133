import routes from 'shared/routes';

import { RoutesGenerator } from '../types';
import CatalogPage from './CatalogPage/CatalogPage';
import { modelPages } from './ModelPages';

export const useCasesPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.usecases.getPath(),
      element: <CatalogPage />,
    },
    ...modelPages.getRoutes(),
  ],
};
