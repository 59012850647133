import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const ContentWrapper = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  fontSize: '15px',
  gap: '4px',
});

export const PlaceholderWrapper = styled(Box)({
  display: 'inline-flex',
  position: 'relative',
});
