import { useMemo } from 'react';

import { MonitoringMetricType } from 'generated/types';
import {
  getLabelByMonitoringMetricType,
  getAvailableMetricTypesForSelection,
} from 'shared/models/Monitoring/MonitoringMetricType';
import { validateNotEmpty } from 'shared/utils/validators';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import { MonitoredModelType } from 'shared/models/Monitoring/MonitoringModel/MonitoredModelType';

const makeMetricOptions = (metricTypes: MonitoringMetricType[]) =>
  metricTypes.map((metric) => ({
    label: getLabelByMonitoringMetricType(metric),
    value: metric,
  }));

const MonitoringMetricTypeField = (props: {
  name: string;
  monitoredModelType: MonitoredModelType;
}) => {
  const metricOptions = useMemo(
    () =>
      makeMetricOptions(
        getAvailableMetricTypesForSelection(props.monitoredModelType)
      ),
    [props.monitoredModelType]
  );
  return (
    <SelectField
      label={'Metric'}
      name={props.name}
      options={metricOptions}
      validate={validateNotEmpty('metric')}
    />
  );
};

export default MonitoringMetricTypeField;
