import * as React from 'react';

export const useSubscribeOnVisibilityApiIsChanged = (
  f: (isHidden: boolean) => void
) => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#example
  const res = (() => {
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      return { hidden: 'hidden', visibilityChange: 'visibilitychange' };
    } else if (typeof (document as any).msHidden !== 'undefined') {
      return { hidden: 'msHidden', visibilityChange: 'msvisibilitychange' };
    } else if (typeof (document as any).webkitHidden !== 'undefined') {
      return {
        hidden: 'webkitHidden',
        visibilityChange: 'webkitvisibilitychange',
      };
    }
    return undefined;
  })();

  React.useEffect(() => {
    if (!res) {
      return;
    }
    const handler = () => {
      f((document as any)[res.hidden] as boolean);
    };
    document.addEventListener(res.visibilityChange, handler, false);
    return () => {
      document.removeEventListener(res.visibilityChange, handler, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res]);
};
