import { UserData } from 'shared/graphql/User/graphql-types/User.generated';
import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';
import { IServerUserInfo } from 'services/serverModel/User/User';

export const convertServerUser = (
  serverUser: IServerUserInfo | undefined
): UserData => {
  if (!serverUser) {
    return unknownUser;
  }
  return {
    __typename: 'User',
    id: serverUser.verta_info.user_id,
    email: serverUser.email,
    username: serverUser.verta_info.username,
    fullName: serverUser.full_name || serverUser.first_name || '',
    picture: serverUser.image_url || null,
  };
};

export const convertServerCurrentUser = (
  serverUser: IServerUserInfo | undefined
): CurrentUserData => {
  if (!serverUser) {
    return {
      ...unknownUser,
      firstName: 'unknown',
      lastName: 'unknown',
      jobTitle: '',
      company: '',
      devKey: '',
      secondaryDevKey: null,
      defaultWorkspaceId: '',
      workspaceId: '',
      lastLoginAt: 0,
      createdAt: 0,
    };
  }
  return {
    __typename: 'User',
    id: serverUser.verta_info.user_id,
    email: serverUser.email,
    username: serverUser.verta_info.username,
    fullName: serverUser.full_name || serverUser.first_name || '',
    firstName: serverUser.first_name || '',
    lastName: serverUser.last_name || '',
    picture: serverUser.image_url || null,
    jobTitle: serverUser.verta_info.job_title,
    company: serverUser.verta_info.company,
    devKey: serverUser.dev_key,
    secondaryDevKey: serverUser.secondary_dev_key || null,
    defaultWorkspaceId: serverUser.verta_info.default_workspace_id,
    workspaceId: serverUser.verta_info.workspace_id,
    lastLoginAt: parseInt(serverUser.verta_info.last_login_timestamp),
    createdAt: parseInt(serverUser.verta_info.created_at_timestamp),
  };
};

export const unknownUser: UserData = {
  __typename: 'User',
  id: '',
  email: 'unknown',
  username: 'unknown',
  fullName: 'unknown',
  picture: '',
};
