import { useState, MouseEvent, useMemo } from 'react';
import { Box, BoxProps, Grid, Paper, Popover, styled } from '@mui/material';
import { isEmpty } from 'ramda';

import Chip, { ChipProps } from 'shared/view/elements/Chip/Chip';
import OverflowList from 'shared/view/elements/OverflowList/OverflowList';

import UpdateLabelsAction, {
  LabelUpdateProps,
} from './UpdateLabelsAction/UpdateLabelsAction';
import Tag from '../TagsManager/Tag/Tag';

const EXPAND_SIZE = {
  maxHeight: 300,
  minWidth: 120,
  maxWidth: 300,
};

const ContentWrapper = styled(Box)({
  position: 'relative',
  width: '100%',
});

type Props = {
  labels: string[];
  emptyLabelsContent: JSX.Element;
  containerProps?: BoxProps;
  labelProps?: Pick<
    ChipProps,
    'color' | 'variant' | 'size' | 'sx' | 'clickable'
  >;
  labelUpdateProps?: LabelUpdateProps;
};

const LabelsRenderer = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const mappedLabels = useMemo(
    () => props.labels.map((label) => ({ id: label, label })),
    [props.labels]
  );

  return (
    <ContentWrapper {...props.containerProps}>
      {isEmpty(props.labels) ? (
        props.emptyLabelsContent
      ) : (
        <OverflowList
          items={mappedLabels}
          additionalActions={
            props.labelUpdateProps ? (
              <UpdateLabelsAction
                labels={props.labels}
                labelUpdateProps={props.labelUpdateProps}
              />
            ) : undefined
          }
          renderVisibleItem={({ label }) => (
            <Tag
              value={label}
              {...props.labelProps}
              {...props.labelUpdateProps}
            />
          )}
          renderOverflowItems={(overflowItems) => (
            <>
              <Chip
                label={`+${overflowItems.length}`}
                variant="outlined"
                size="small"
                color="default"
                {...props.labelProps}
                clickable
                onClick={handleClick}
                onAuxClick={handleClick}
              />

              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <Paper>
                  <Box
                    maxHeight={EXPAND_SIZE.maxHeight}
                    minWidth={EXPAND_SIZE.minWidth}
                    maxWidth={EXPAND_SIZE.maxWidth}
                  >
                    <Grid
                      container={true}
                      direction="row"
                      alignItems={'flex-start'}
                      style={{ width: '100%' }}
                      gap={1}
                      p={2}
                    >
                      {overflowItems.map(({ label }) => (
                        <Grid item key={label} style={{ maxWidth: '100%' }}>
                          <Tag
                            value={label}
                            {...props.labelProps}
                            {...props.labelUpdateProps}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Paper>
              </Popover>
            </>
          )}
        />
      )}
    </ContentWrapper>
  );
};

export default LabelsRenderer;
