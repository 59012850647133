import { gql } from '@apollo/client';

import { MonitoringAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import parseGraphqlDate from 'shared/utils/graphql/parseGraphqlDate';

import { ALERTER_FRAGMENT, convertAlerter } from './Alerter';
import { AlertFragment } from './graphql-types/Alert.generated';
import { ALERT_SETTINGS_FRAGMENT, convertAlertSettings } from './AlertSettings';

export const ALERT_FRAGMENT = gql`
  fragment AlertFragment on Alert {
    id
    name
    aggregationWindow
    evaluationFrequency
    createdAt
    updatedAt
    status
    featureName
    alerter {
      ...AlerterFragment
    }
    settings {
      ...AlertSettingsFragment
    }
  }
  ${ALERTER_FRAGMENT}
  ${ALERT_SETTINGS_FRAGMENT}
`;

export const convertAlert = (data: AlertFragment): MonitoringAlert => {
  return {
    id: convertAlertId(data.id),
    status: data.status,
    dateCreated: parseGraphqlDate(data.createdAt),
    dateUpdated: parseGraphqlDate(data.updatedAt),
    aggregationWindow: data.aggregationWindow,
    evaluationFrequency: data.evaluationFrequency,
    name: data.name,
    alerter: convertAlerter(data.alerter),
    settings: convertAlertSettings(data.settings),
    featureName: data.featureName,
  };
};

export const convertAlertId = (alertId: string) => {
  return alertId.split('-')[0];
};
