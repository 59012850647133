import { useTheme } from '@mui/material';
import { curveLinear } from '@visx/curve';
import React, { useMemo } from 'react';
import { groupBy, head } from 'ramda';
import { NumberValue, ScaleLinear } from 'd3-scale';

import {
  ChartMargin,
  ChartSizes,
  getChartSizes,
} from 'shared/utils/charts/chartSizes';
import between from 'shared/utils/between';
import { OnSetReset } from 'shared/utils/charts/zoom/useZoomReset';
import matchType from 'shared/utils/matchType';
import { useZoomedXYChartScales } from 'shared/utils/charts/zoom/useZoomedXYChartScales';
import { getLinearScaleDomain } from 'shared/utils/charts/getLinearScaleDomain';

import AxisBottomWithRotatedTicks from '../Axis/AxisBottomWithRotatedTicks/AxisBottomWithRotatedTicks';
import CustomAxisLeftWithGridRows from '../Axis/CustomAxisLeftWithGridRows/CustomAxisLeftWithGridRows';
import {
  MultiLineChartTooltipData,
  useMultiLineChartTooltipWithView,
} from './utils/tooltip';
import ChartSVG from '../shared/ChartSVG/ChartSVG';
import { defaultChartMargin } from '../shared/margin';
import LinePathWithPoints from '../shared/shapes/LinePathWithPoints/LinePathWithPoints';
import XYChartBorders from '../shared/XYChart/XYChartBorders/XYChartBorders';
import PlotWithBrush from '../MultiLineChart/PlotWithBrush';
import { LineType, MultiLineChartData } from '../MultiLineChart/utils/types';

export interface MultiLineChartRenderAdditionalContentProps {
  xScale: ScaleLinear<number, number, never>;
  yScale: ScaleLinear<number, number, never>;
  chartSizes: ChartSizes;
}

interface MultiLineChartProps<Meta> {
  width: number;
  height: number;
  id: string;
  data: MultiLineChartData<Meta>[];
  colorScale: (key: string) => string;
  onSetReset: OnSetReset;
  xNumTicks?: number;
  xLabel?: string;
  yLabel?: string;
  renderTooltipContent?: (
    tooltipData: MultiLineChartTooltipData<Meta>
  ) => React.ReactNode;
  yDomain?: [number, number];
  xDomain?: [number, number];
  xTickFormat?: (x: NumberValue) => string;
  margin?: ChartMargin;
  renderAdditionalContent?: (
    props: MultiLineChartRenderAdditionalContentProps
  ) => React.ReactNode;
  onTooltipAreaClick?: (
    tooltipData: MultiLineChartTooltipData<Meta> | undefined
  ) => void;
  hidePoints?: boolean;
  pointSize?: number;
}

const getYDomainWithDefault = (data: MultiLineChartData<unknown>[]) =>
  getLinearScaleDomain(data.map(({ y }) => y));

const getXDomainWithDefault = (data: MultiLineChartData<unknown>[]) =>
  getLinearScaleDomain(data.map(({ x }) => x));

function MultiLineChart<Meta>(props: MultiLineChartProps<Meta>) {
  const chartSizes = getChartSizes({
    margin: props.margin ?? defaultChartMargin,
    width: props.width,
    height: props.height,
  });

  const { selectedDomain, xScale, yScale, zoomApi } = useZoomedXYChartScales({
    innerHeight: chartSizes.innerHeight,
    innerWidth: chartSizes.innerWidth,
    onSetReset: props.onSetReset,
    xDomain: props.xDomain ?? getXDomainWithDefault(props.data),
    yDomain: props.yDomain ?? getYDomainWithDefault(props.data),
  });

  const tooltipApi = useMultiLineChartTooltipWithView({
    data: selectedDomain
      ? props.data.filter((point) => between(point.x, ...selectedDomain.x))
      : props.data,
    xScale,
    yScale,
    colorScale: props.colorScale,
    renderTooltipContent: props.renderTooltipContent,
    chartSizes,
    onTooltipAreaClick: props.onTooltipAreaClick,
  });

  const groupedData = useMemo(
    () => groupBy((p) => p.key, props.data),
    [props.data]
  );

  const theme = useTheme();

  return (
    <ChartSVG
      width={chartSizes.width}
      height={chartSizes.height}
      margin={chartSizes.margin}
      outSvgContent={tooltipApi.tooltipView}
      isNilData={props.data.length === 0}
    >
      <CustomAxisLeftWithGridRows
        innerWidth={chartSizes.innerWidth}
        scale={yScale}
        label={props.yLabel}
        numTicks={4}
      />
      <AxisBottomWithRotatedTicks
        scale={xScale}
        top={chartSizes.innerHeight}
        stroke={theme.palette.charts.accentColor}
        tickStroke={theme.palette.charts.accentColor}
        tickFormat={props.xTickFormat}
        label={props.xLabel}
        numTicks={props.xNumTicks}
      />

      {props.renderAdditionalContent?.({
        yScale,
        xScale,
        chartSizes,
      })}

      <PlotWithBrush
        {...zoomApi}
        tooltipApi={tooltipApi}
        chartSizes={chartSizes}
        xScale={xScale}
        yScale={yScale}
      >
        <XYChartBorders
          chartId={props.id}
          innerWidth={chartSizes.innerWidth}
          innerHeight={chartSizes.innerHeight}
        >
          {Object.keys(groupedData).map((key) => (
            <LinePathWithPoints
              hidePoints={Boolean(props.hidePoints)}
              pointSize={props.pointSize}
              key={`${props.id}-${key}-line`}
              curve={curveLinear}
              data={groupedData[key]}
              x={({ x }) => xScale(x)}
              y={({ y }) => yScale(y)}
              color={props.colorScale(key)}
              {...getLinePropsByLineType(getLineTypeByPoints(groupedData[key]))}
            />
          ))}
        </XYChartBorders>
      </PlotWithBrush>
    </ChartSVG>
  );
}

const getLineTypeByPoints = (points: MultiLineChartData<unknown>[]) => {
  return head(points)?.lineType ?? 'solid';
};

const getLinePropsByLineType = (lineType: LineType) => {
  const strokeDasharray = getStrokeDasharrayByLineType(lineType);
  const strokeWidth = getStrokeWidthByLineType(lineType);

  return {
    strokeDasharray,
    strokeWidth,
  };
};

const getStrokeDasharrayByLineType = (lineType: LineType) => {
  return matchType<LineType, string | number | undefined>(
    {
      dashed: () => 5,
      solid: () => undefined,
      dotted: () => '0.9 5',
      noLine: () => undefined,
    },
    lineType
  );
};

const getStrokeWidthByLineType = (lineType: LineType) => {
  return matchType<LineType, number>(
    {
      dashed: () => 2,
      solid: () => 2,
      dotted: () => 2,
      noLine: () => 0,
    },
    lineType
  );
};

export default MultiLineChart;
