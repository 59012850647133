import { Stack } from '@mui/material';

import MonitoringModelDetailsLayout from 'pages/authorized/Monitoring/MonitoringModelPages/shared/MonitoringModelDetailsLayout/MonitoringModelDetailsLayout';
import { useDebounced } from 'shared/utils/debounce';
import {
  MonitoringModel,
  MonitoringModelState,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import { AppBreadcrumbs } from 'features/layout/view/Layout/AppBreadcrumbs';
import { useMonitoringModelStateManager } from 'features/monitoring/models/model/store/state/useMonitoringModelStateManager';
import { useMonitoringModelSettingsActions } from 'features/monitoring/models/modelSettings/store/useMonitoringModelSettingsActions';

import MonitoringModelBar from '../../MonitoringDashboardPage/MonitoringDashboardLayout/MonitoringModelBar/MonitoringModelBar';

interface Props {
  model: MonitoringModel;
  updateModelState: (state: MonitoringModelState) => void;
  children: JSX.Element;
  breadcrumbs: AppBreadcrumbs;
}

const MonitoringPerformancePageLayout = (props: Props) => {
  const {
    updateFilters,
    updateTimeRange,
    updateAggregation,
    selectRegisteredModelVersion,
  } = useMonitoringModelStateManager({
    modelState: props.model.state,
    updateModelState: props.updateModelState,
    registeredModelVersions: props.model.registeredModelVersions,
  });

  const debouncedChangeTimeRange = useDebounced(updateTimeRange, 500);

  const settingsActions = useMonitoringModelSettingsActions({
    model: props.model,
  });

  return (
    <MonitoringModelDetailsLayout
      breadcrumbs={props.breadcrumbs}
      model={props.model}
      pageSettingsActions={settingsActions}
    >
      <Stack spacing={3}>
        <MonitoringModelBar
          model={props.model}
          updateAggregation={updateAggregation}
          updateTimeRange={debouncedChangeTimeRange}
          updateFilters={updateFilters}
          selectRegisteredModelVersion={selectRegisteredModelVersion}
        />
        <div>{props.children}</div>
      </Stack>
    </MonitoringModelDetailsLayout>
  );
};

export default MonitoringPerformancePageLayout;
