import { FieldConfig } from 'formik';
import { useMemo } from 'react';

import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';

const TypeSelectFieldName = <T extends string>(
  props: {
    label: string;
    types: T[];
    disabled?: boolean;
  } & FieldConfig
) => {
  const options = useMemo(
    () => props.types.map((c) => ({ label: c, value: c })),
    [props.types]
  );

  return (
    <SelectField label={props.label} name={props.name} options={options} />
  );
};

export default TypeSelectFieldName;
