import { gql } from '@apollo/client';

import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/useAddRole.generated';

export { Types };

export const QUERY = gql`
  mutation AddRoleToOrganizationV2($organizationId: ID!, $input: RoleV2Input!) {
    organizationV2(id: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        addRole(input: $input) {
          id
          name
          roles {
            __typename
            orgId
            id
            name
            description
            builtIn
            allowedActions {
              ...AllowedActionsData
            }
            resourceActions {
              resourceType
              allowedActions
            }
          }
        }
      }
    }
  }
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useAddRole = (onCompleted: () => void) => {
  const [run, communication] = useCustomMutation<
    Types.AddRoleToOrganizationV2,
    Types.AddRoleToOrganizationV2Variables
  >(
    QUERY,
    {
      context: 'adding role',
    },
    { onCompleted }
  );

  return {
    run,
    communication,
  };
};
