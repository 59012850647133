import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';

import { ProfilePageContent } from './ProfilePageContent';

const ProfilePage = () => {
  return (
    <AuthorizedLayout pageEntityOrError={undefined} breadcrumbs={undefined}>
      <ProfilePageContent />
    </AuthorizedLayout>
  );
};

export default ProfilePage;
