import FormControlLabel from '@mui/material/FormControlLabel';
import { useField, useFormikContext } from 'formik';
import { equals, difference } from 'ramda';
import { useState } from 'react';

import { makeGetFieldName } from 'shared/utils/getFieldName';
import { validateNotEmpty } from 'shared/utils/validators';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import Popup from 'shared/view/elements/Popup/Popup';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { Switch } from 'shared/view/elements/Switch/Switch';
import { ButtonsProps } from 'shared/view/elements/Popup/PopupButtons';

import { OrganizationGroups } from '../store/useOrganizationGroups';

export type GroupSettings = {
  name: string;
  description: string;
  memberIds: string[];
};

type Props = {
  users: OrganizationGroups['users']['users'];
  builtIn: boolean;
  initialSettings: GroupSettings;
  title: string;
  tertiaryButton?: ButtonsProps['tertiaryButtonProps'];
  submitButton: {
    children: 'Create' | 'Update';
    isLoading: boolean;
    onSubmit(settings: GroupSettings): void;
  };
  disabled?: boolean;
  onClose(): void;
};

const getField = makeGetFieldName<GroupSettings>();

const GroupPopup = (props: Props) => {
  return (
    <PresetFormik
      enableReinitialize={true}
      initialValues={props.initialSettings}
      onSubmit={props.submitButton.onSubmit}
    >
      {({ isValid, values, submitForm, validateForm }) => (
        <Popup
          isOpen={true}
          title={props.title}
          fullWidth={true}
          maxWidth="xs"
          buttons={{
            tertiaryButtonProps: props.tertiaryButton,
            secondaryButtonProps: {
              isLoading: false,
              children: 'Cancel',
              onClick: props.onClose,
            },
            mainButtonProps: {
              ...props.submitButton,
              type: 'button',
              disabled:
                !isValid ||
                equals(values, props.initialSettings) ||
                props.disabled,
              onClick: submitForm,
            },
          }}
          onClose={props.onClose}
        >
          <FormStack>
            <TextInputField
              name={getField({ name: null })}
              label="Group name"
              isRequired={true}
              validate={validateNotEmpty('Name')}
              disabled={props.builtIn || props.disabled}
              dataTest="group-name-input"
            />
            <TextInputField
              name={getField({ description: null })}
              label="Group description"
              disabled={props.builtIn || props.disabled}
              dataTest="group-description-input"
            />
            <UsersField
              disabled={Boolean(props.disabled)}
              validateForm={validateForm}
              users={props.users}
            />
          </FormStack>
        </Popup>
      )}
    </PresetFormik>
  );
};

const columns: DataGridColumn<OrganizationGroups['users']['users'][0]>[] = [
  {
    field: 'User',
    flex: 1,
    renderCell: (params) => (
      <CellRendererString
        value={`${params.row.fullName} (${params.row.email})`}
      />
    ),
  },
];

const UsersField = ({
  users,
  disabled,
  validateForm,
}: {
  users: OrganizationGroups['users']['users'];
  disabled: boolean;
  validateForm: () => void;
}) => {
  const [searchMembers, setSearchMembers] = useState('');
  const [isOnlySelectedUsers, setIsOnlySelectedUsers] = useState(false);

  const { setFieldValue } = useFormikContext();
  const [{ value: selection }] = useField<GroupSettings['memberIds']>({
    name: getField({ memberIds: null }),
  });

  const rows = users.filter((row) =>
    [
      isOnlySelectedUsers ? selection.includes(row.id) : true,
      searchMembers
        ? row.email.toLowerCase().includes(searchMembers.toLowerCase()) ||
          row.fullName.toLowerCase().includes(searchMembers.toLowerCase())
        : true,
    ].every(Boolean)
  );

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            name="only-selected-users"
            checked={isOnlySelectedUsers}
            data-test="only-selected-users-switch"
            onChange={(_, v) => {
              setIsOnlySelectedUsers(v);
            }}
          />
        }
        label="Only selected users"
      />
      <TextInput
        label="Search users"
        name="search-user"
        data-test="search-user-input"
        value={searchMembers}
        onChange={setSearchMembers}
      />
      {disabled ? (
        <DataGridWithTypes
          rows={rows}
          heightType="autoHeightButMax5Rows"
          columns={columns}
        />
      ) : (
        <DataGridWithTypes
          rows={rows}
          heightType="autoHeightButMax5Rows"
          columns={columns}
          selectionType="multiple"
          selection={selection}
          onSelectionChange={(ids: string[]) => {
            if (
              ids.length < selection.length &&
              difference(selection, ids).some(
                (id) => !rows.map((r) => r.id).includes(id)
              )
            ) {
              return;
            }
            setFieldValue(getField({ memberIds: null }), ids);
            setTimeout(() => {
              validateForm();
            }, 0);
          }}
        />
      )}
    </>
  );
};

export default GroupPopup;
