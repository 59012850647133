import { gql } from '@apollo/client';

import * as Types from 'features/developerKeysManager/graphql-types/useUserAddDevKey.generated';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

const QUERY = gql`
  mutation UserAddDevKeyV2($organizationId: ID!, $userId: String!) {
    organizationV2(id: $organizationId) {
      ... on OrganizationV2 {
        id
        addDevKey(userId: $userId) {
          ... on ServiceAccountV2 {
            id
            primaryKey
            secondaryKey
            updatedAt
          }
        }
      }
    }
  }
`;

export const useUserAddDevKey = ({
  organizationId,
  userId,
  onChangeDevKeys,
}: {
  organizationId: string;
  userId: string;
  onChangeDevKeys(keys?: {
    primaryKey: string;
    secondaryKey: string | undefined;
  }): void;
}) => {
  const [mutate, addingDevKey] = useCustomMutation<
    Types.UserAddDevKeyV2,
    Types.UserAddDevKeyV2Variables
  >(
    QUERY,
    { context: 'adding dev key' },
    {
      onCompleted: (res) => {
        if (
          res.organizationV2.__typename === 'OrganizationV2' &&
          res.organizationV2.addDevKey.__typename === 'UserV2'
        ) {
          onChangeDevKeys();
          return;
        }
        if (
          res.organizationV2.__typename === 'OrganizationV2' &&
          res.organizationV2.addDevKey.__typename === 'ServiceAccountV2'
        ) {
          onChangeDevKeys(res.organizationV2.addDevKey);
        }
      },
    }
  );

  return {
    addDevKey: () => mutate({ organizationId, userId }),
    addingDevKey,
  };
};
