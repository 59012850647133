import { useMemo } from 'react';

import { OmitStrict } from 'shared/utils/types';

import BaseGroupedBarChart, {
  BaseGroupedBarChartProps,
  GetBarGroupXArguments,
} from './BaseGroupedBarChart';

interface Props<Meta extends unknown>
  extends OmitStrict<
    BaseGroupedBarChartProps<Meta>,
    'x0ScaleDomain' | 'getBarGroupX'
  > {}

const getBarGroupX = ({ x0 }: GetBarGroupXArguments) => x0;

const GroupedBarChart = <Meta extends unknown>(props: Props<Meta>) => {
  const x0ScaleDomain = useMemo(
    () => props.data.map(({ key }) => key),
    [props.data]
  );

  return (
    <BaseGroupedBarChart
      {...props}
      getBarGroupX={getBarGroupX}
      x0ScaleDomain={x0ScaleDomain}
    />
  );
};

export default GroupedBarChart;
