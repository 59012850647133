import { pipe } from 'ramda';

import { IAttribute } from 'shared/models/Attribute';
import { compareEntitiesPrimitives } from 'shared/models/CompareEntities/new/primitivesComparing';
import { getRegisteredModelVersionType } from 'shared/models/Registry/RegisteredModelVersion/Type';

type RequiredRegisteredModelVersion = {
  id: string;
  attributes: IAttribute[];
};

export const compareTypes = (
  registeredModelVersions: RequiredRegisteredModelVersion[]
) =>
  pipe(
    () =>
      registeredModelVersions.map((e) => ({
        ...e,
        type: getRegisteredModelVersionType(e.attributes),
      })),
    (x) => compareEntitiesPrimitives(({ type }) => type, x)
  )();
