import React from 'react';
import { LinePath, Circle } from '@visx/shape';
import { LinePathProps } from '@visx/shape/lib/shapes/LinePath';
import { AccessorForArrayItem, AddSVGProps } from '@visx/shape/lib/types';

interface LinePathOrPointProps<Datum>
  extends AddSVGProps<LinePathProps<Datum>, SVGPathElement> {
  x: AccessorForArrayItem<Datum, number>;
  y: AccessorForArrayItem<Datum, number>;
  stroke: string;
  onMouseOut?: React.MouseEventHandler<SVGElement>;
  onMouseOver?: React.MouseEventHandler<SVGElement>;
}

function LinePathOrPoint<Datum>(props: LinePathOrPointProps<Datum>) {
  if (props.data?.length === 1) {
    const d = props.data[0];
    return (
      <Circle
        cx={props.x(d, 0, props.data)}
        cy={props.y(d, 0, props.data)}
        fill={props.stroke}
        r={3}
        onMouseOut={props.onMouseOut}
        onMouseOver={props.onMouseOver}
      />
    );
  }
  return <LinePath {...props} />;
}

export default LinePathOrPoint;
