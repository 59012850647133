import {
  ExperimentRunWithDisplayedFields,
  getValueByKeyValueSpec,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import { TableWidgetSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/TableWidgetSettings';
import routes from 'shared/routes';
import matchBy from 'shared/utils/matchBy';
import CellRendererDate from 'shared/view/elements/DataGrid/columns/CellRendererDate';
import CellRendererLink from 'shared/view/elements/DataGrid/columns/CellRendererLink';
import CellRendererNumeric from 'shared/view/elements/DataGrid/columns/CellRendererNumeric';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { typeSafeConfiguration } from 'shared/view/elements/DataGrid/configuration/helpers/typeSafeConfiguration';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import CellRendererLabels from 'shared/view/elements/DataGrid/columns/CellRendererLabels';

interface LocalProps {
  widgetSettings: TableWidgetSettings;
  width: number;
  height: number;
  experimentRuns: ExperimentRunWithDisplayedFields[];
}

const TableWidget = (props: LocalProps) => {
  const columns = props.widgetSettings.columns.map((type) =>
    matchBy(
      type,
      'type'
    )<DataGridColumn<ExperimentRunWithDisplayedFields>>({
      id: () => ({
        field: 'Run ID',
        flex: 1,
        additionalConfiguration: typeSafeConfiguration(
          ['sort', 'filter'],
          'string',
          (params) => params.row.id
        ),
        renderCell: (params) => (
          <CellRendererLink
            to={routes.experimentRun.getRedirectPath({
              experimentRunId: params.row.id,
              projectId: params.row.project.id,
              workspaceName: params.row.project.workspace.name,
            })}
            label={params.row.id}
          />
        ),
      }),
      experiment: () => ({
        field: 'Experiment',
        flex: 1,
        additionalConfiguration: typeSafeConfiguration(
          ['sort', 'filter'],
          'string',
          (params) => params.row.experiment.name
        ),
        renderCell: (params) => (
          <CellRendererString value={params.row.experiment.name} />
        ),
      }),
      timestamp: () => ({
        field: 'Timestamp',
        flex: 1,
        additionalConfiguration: typeSafeConfiguration(
          ['sort', 'filter'],
          'date',
          (params) => params.row.dateUpdated
        ),
        renderCell: (params) => (
          <CellRendererDate value={params.row.dateUpdated} showTime={true} />
        ),
      }),
      tags: () => ({
        field: 'Labels',
        flex: 1,
        renderCell: (params) => (
          <CellRendererLabels
            labels={params.row.tags}
            columnKey="labels-list"
          />
        ),
      }),
      keyValue: (keyValue) => ({
        field: keyValue.key,
        flex: 1,
        additionalConfiguration: typeSafeConfiguration(
          ['sort', 'filter'],
          keyValue.valueType,
          (params) => getValueByKeyValueSpec(keyValue, params.row)
        ),
        renderCell: (params) => {
          const value = getValueByKeyValueSpec(keyValue, params.row);
          return typeof value === 'number' ? (
            <CellRendererNumeric value={value} />
          ) : (
            <CellRendererString value={value} />
          );
        },
      }),
    })
  );

  return (
    <div
      style={{
        width: `${props.width}px`,
        height: `${props.height}px`,
        overflow: 'auto',
      }}
    >
      <DataGridWithTypes
        heightType="parentHeight"
        columns={columns}
        rows={props.experimentRuns}
      />
    </div>
  );
};

export default TableWidget;
