import {
  CrossRunWidgetType,
  crossRunWidgetTypes,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunWidget';
import matchType from 'shared/utils/matchType';

import BarChartSVG from './img/bar_chart.svg?react';
import BoxPlotSvg from './img/box_plot_chart.svg?react';
import LineChartSvg from './img/line_chart.svg?react';
import MarkdownSVG from './img/markdown.svg?react';
import ObservationChartsSvg from './img/observation_charts.svg?react';
import ParallelCoordChartSvg from './img/parallel_coordinate_chart.svg?react';
import ScatterPlotSVG from './img/scatter_plot_chart.svg?react';
import TableSVG from './img/table_icon.svg?react';

const getDescription = (type: CrossRunWidgetType) =>
  matchType(
    {
      barChart: () =>
        'Visualize categorical data with rectangular bars with heights or lengths proportional to the values that they represent.',
      boxPlot: () =>
        'Visualize the distribution of data and skewness using quartiles (or percentiles) and averages.',
      lineChart: () =>
        'Visualize a series of data points for one or more variables in the x-axis against a y-axis variable.',
      observationCharts: () =>
        'View and compare metadata that are repeatedly measured over time, such as batch losses over an epoch or memory usage across runs.',
      parallelCoordinateChart: () =>
        'Compare multiple variables by plotting and connecting them across multiple parallel axes.',
      scatterChart: () =>
        'Plot a variable in the y-axis against another variable in the x-axis represented as data points to depict patterns and correlation between the variables.',
      table: () => 'A tabular view of a set of rows and columns.',
      markdown: () =>
        'Write descriptive notes and summaries with markdown support',
    },
    type
  );

const getLabel = (type: CrossRunWidgetType) =>
  matchType(
    {
      barChart: () => 'Bar chart',
      boxPlot: () => 'Box plot',
      lineChart: () => 'Line chart',
      observationCharts: () => 'Observation chart',
      parallelCoordinateChart: () => 'Parallel coordinate chart',
      scatterChart: () => 'Scatter plot',
      table: () => 'Table',
      markdown: () => 'Markdown',
    },
    type
  );

const getImage = (type: CrossRunWidgetType) =>
  matchType(
    {
      barChart: () => BarChartSVG,
      boxPlot: () => BoxPlotSvg,
      lineChart: () => LineChartSvg,
      parallelCoordinateChart: () => ParallelCoordChartSvg,
      observationCharts: () => ObservationChartsSvg,
      scatterChart: () => ScatterPlotSVG,
      table: () => TableSVG,
      markdown: () => MarkdownSVG,
    },
    type
  );

export const widgetsInfo = crossRunWidgetTypes.map((type) => {
  const Image = getImage(type);
  return {
    label: getLabel(type),
    description: getDescription(type),
    image: <Image />,
    type,
  };
});
