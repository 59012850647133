import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import isNotNil from 'shared/utils/isNotNill';

export const UpdatedAtTagGraphql = ({
  dateUpdated,
}: {
  dateUpdated?: number;
}) => {
  return isNotNil(dateUpdated) ? (
    <UpdatedAtTag dateUpdated={new Date(dateUpdated)} />
  ) : (
    <></>
  );
};

export const UpdatedAtTag = ({ dateUpdated }: { dateUpdated: Date }) => {
  const updatedAt = useMemo(() => moment(dateUpdated).fromNow(), [dateUpdated]);

  return (
    <Box
      key="update-at-layout"
      display="flex"
      height="42px"
      flexDirection="column"
      justifyContent="end"
    >
      <Stack spacing={1} direction="row" alignItems="center">
        <IconAwesomeInfo icon={ICONS.arrowsRotate} size="sm" />
        <Typography variant="caption" color="text.secondary">
          {`Updated ${updatedAt}`}
        </Typography>
      </Stack>
    </Box>
  );
};
