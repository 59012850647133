import { KeyValueDataType, KeyValueSpec } from './KeyValueSpec';

export type DataSpec<ValueType extends KeyValueDataType = KeyValueDataType> =
  | KeyValueSpec<ValueType>
  | DateUpdatedSpec;

export type DateUpdatedSpec = {
  key: 'date';
  type: 'dateUpdated';
  valueType: 'date';
};
export const dateUpdatedSpec: DateUpdatedSpec = {
  key: 'date',
  type: 'dateUpdated',
  valueType: 'date',
};
