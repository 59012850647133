import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorWithInfo } from 'features/errorReporter';
import useAddError from 'features/errorReporter/hooks/useAddError';
import { openErrorReporterModal } from 'features/errorReporter/store/actions';
import { selectErrorWithInfo } from 'features/errorReporter/store/selectors';
import { IApplicationState } from 'setup/store/store';

interface Props {
  error: ErrorWithInfo['error'];
  context: string;
  children: (text: string, openErrorReporterModal: () => void) => JSX.Element;
}

const CreateErrorReportButtonWrapper: FC<Props> = (props) => {
  useAddError({
    context: props.context,
    error: props.error,
  });
  const dispatch = useDispatch();
  const errorWithInfo = useSelector((state: IApplicationState) =>
    selectErrorWithInfo(state, props.error)
  );

  return props.children('Report an error', () =>
    dispatch(openErrorReporterModal(errorWithInfo))
  );
};

export default CreateErrorReportButtonWrapper;
