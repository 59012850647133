import { IApplicationState } from 'setup/store/store';

import { ErrorWithInfo } from './types';

export const selectRequestResponses = (state: IApplicationState) =>
  state.errorReporter.requestResponses;

export const selectErrorWithInfo = (
  state: IApplicationState,
  error: ErrorWithInfo['error']
) => state.errorReporter.errorsWithInfo.find((x) => x.error === error);

export const selectErrorsWithInfo = (state: IApplicationState) =>
  state.errorReporter.errorsWithInfo;

export const selectIsErrorsPanelOpened = (state: IApplicationState) =>
  state.errorReporter.isErrorsPanelOpened;

export const selectErrorReporterModalData = (state: IApplicationState) =>
  state.errorReporter.errorReporterModal;
