import { DocumentNode } from 'graphql';
import { useEffect, useRef } from 'react';
import {
  TypedDocumentNode,
  LazyQueryHookOptions,
  OperationVariables,
  // eslint-disable-next-line no-restricted-imports
  useLazyQuery,
} from '@apollo/client';

import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

export const useCustomLazyQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>
) => {
  const abortRef = useRef(new AbortController());
  const orgId = useCurrentOrganizationV2();

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      abortRef.current.abort();
    };
  }, []);

  return useLazyQuery<TData, TVariables>(query, {
    ...options,
    context: {
      ...options?.context,
      headers: {
        ...(options?.context?.headers as { [key: string]: string }),
        'Grpc-Metadata-organization-id': orgId,
      },
      fetchOptions: {
        signal: abortRef.current.signal,
      },
    },
  });
};
