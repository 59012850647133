import { Grid, Stack } from '@mui/material';
import { keys, pickBy } from 'lodash';

import Chip from 'shared/view/elements/Chip/Chip';

export const CellRendererLabels = ({
  labels,
}: {
  labels: Record<string, boolean>;
  columnKey: string;
}) => {
  const selectedLabels = pickBy(labels, (label) => label);
  return (
    <Stack
      data-test="tags-manager"
      direction={'row'}
      style={{ width: '100%', overflow: 'auto', height: '100%' }}
    >
      <Grid
        container={true}
        direction="row"
        alignItems={'flex-start'}
        style={{ width: '100%' }}
        gap={1}
        p={2}
      >
        {keys(selectedLabels).map((key) => (
          <Grid item key={key} style={{ maxWidth: '100%' }}>
            <Chip
              label={key}
              variant={labels[key] ? 'filled' : 'outlined'}
              size="small"
              color="primary"
              clickable
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
