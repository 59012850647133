import { QueryHookOptions } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';

import {
  ModelVersionLayout,
  ModelVersionLayoutVariables,
} from '../graphql/graphql-types/query.generated';
import { MODEL_VERSION_LAYOUT } from '../graphql';

export const useModelVersionLayout = (
  { versionId }: { versionId: string },
  options?: QueryHookOptions<ModelVersionLayout, ModelVersionLayoutVariables>
) => {
  const query = useCustomQuery<ModelVersionLayout, ModelVersionLayoutVariables>(
    MODEL_VERSION_LAYOUT,
    { ...options, variables: { versionId } }
  );

  return resultToCommunicationWithData(convertResponse, query);
};

const convertResponse = ({ registeredModelVersion }: ModelVersionLayout) =>
  mapDataOrError(registeredModelVersion, (version) => version);
