import React, { useState, useEffect } from 'react';
import { debounce } from '@mui/material/utils';

import withCancelPreviousQuery from 'shared/utils/graphql/withCancelPreviousQuery';
import { RequiredBuild } from 'features/deployment/canary/shared/RequiredBuild';
import { Endpoint } from 'features/deployment/canary/endpoints/store/endpointQuery/graphql-types/endpointQuery.generated';
import { useToastCommunicationErrorWatcher } from 'shared/view/elements/Notification/Notification';
import { ExtractByTypename } from 'shared/utils/types';

import { Source } from './settings';
import {
  useSourceBuilds,
  LoadSourceBuildsSettings,
} from '../../../shared/hooks/useSourceBuilds/useSourceBuilds';

type Workspace = ExtractByTypename<Endpoint['workspace'], 'Workspace'>;

export function useSourceBuildsForm({
  source,
  organizationId,
  endpoint,
}: {
  source: Source;
  organizationId: string | undefined;
  endpoint: ExtractByTypename<Workspace['endpoint'], 'Endpoint'>;
}) {
  const [sourceBuilds, setSourceBuilds] = useState<RequiredBuild[] | undefined>(
    undefined
  );

  const { loadSourceBuilds: loadSourceBuilds__, loadingSourceBuilds } =
    useSourceBuilds({
      onCompleted: (newSourceBuilds) => {
        setSourceBuilds(newSourceBuilds);
      },
    });

  useToastCommunicationErrorWatcher(loadingSourceBuilds, {
    context: 'loading source builds',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadSourceBuild = React.useCallback(
    debounce(
      withCancelPreviousQuery(
        (context) => (variables: LoadSourceBuildsSettings) => {
          loadSourceBuilds__({
            variables,
            context,
          });
        }
      ),
      400
    ),
    [endpoint.id]
  );

  useEffect(() => {
    setSourceBuilds(undefined);
    if (source.id) {
      loadSourceBuild({
        source: source.type,
        sourceId: source.id,
        workspaceName: endpoint.workspace.name,
        organizationId,
      });
    }
  }, [endpoint.workspace.name, loadSourceBuild, source, organizationId]);

  return {
    loadingSourceBuilds,
    sourceBuilds,
  };
}
