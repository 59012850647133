import { timeFormat } from 'd3';

import { withScientificNotationOrRounded } from 'shared/utils/formatters/number';

import { ChartObservation } from './types';

export const formatObservationValue = (
  value: ChartObservation['attribute']['floatValue']
) => String(withScientificNotationOrRounded(value));

export const formatObservationTimestamp = timeFormat('%b/%d %H:%M');
