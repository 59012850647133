import routes from 'shared/routes';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { useRouteParams } from 'shared/utils/router/useRouteParams';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import Button from 'shared/view/elements/Button/Button';
import { isRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { ChecklistTemplatePopup } from 'features/organizations/governance/view/ChecklistTemplatePopup/ChecklistTemplatePopup';
import useOrganizationGovernance from 'features/organizations/governance/store/useOrganizationGovernance';
import { OrganizationGovernanceScreen } from 'features/organizations/governance/view/OrganizationGovernance';
import { trackEvent } from 'setup/app/analytics';

import OrganizationLayout from '../shared/OrganizationLayout';

const OrganizationWorkspacesPage = () => {
  const { name } = useRouteParams(routes.organization);
  const organizationGovernanceApi = useOrganizationGovernance(name);
  const actions = (() => {
    const data = organizationGovernanceApi.data;
    return !isRestrictedGraphqlError(data) && data
      ? [
          <ButtonWithPopup
            key="create-new-checklist-template-button"
            button={(props) => (
              <Button
                {...props}
                onClick={() => {
                  trackEvent({
                    type: 'organization.checklists.create_clicked',
                  });
                  props.onClick();
                }}
              >
                Create checklist
              </Button>
            )}
            popup={(props) => (
              <ChecklistTemplatePopup
                {...props}
                checklistTemplate={undefined}
                submitButton={{
                  children: 'Create checklist',
                }}
                onCompleted={() => organizationGovernanceApi.reload()}
                title="Create new checklist"
                organizationId={data.id}
                groups={data.groups}
                initialExpandedItemIndex={0}
              />
            )}
          />,
        ]
      : [];
  })();

  return (
    <OrganizationLayout pageEntityOrError={undefined} actions={actions}>
      <DefaultMatchRemoteDataOrError
        {...organizationGovernanceApi}
        context="loading governance"
      >
        {(loadedOrganization) => (
          <OrganizationGovernanceScreen
            {...organizationGovernanceApi}
            organization={loadedOrganization}
          />
        )}
      </DefaultMatchRemoteDataOrError>
    </OrganizationLayout>
  );
};

export default OrganizationWorkspacesPage;
