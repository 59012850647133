import { Stack, StackProps } from '@mui/material';

import { Evaluation } from 'features/evaluations/evaluation/summary/hooks/useEvaluationSummary';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import CellRendererExpandableString from 'shared/view/elements/DataGrid/columns/CellRendererExpandableString';
import { useVirtualPagination } from 'shared/hooks/useVirtualPagination';
import {
  EvaluationData,
  EvaluationHeaderKey,
} from 'features/evaluations/shared/utils/evaluation';
import {
  HEADERS_DICTIONARY,
  Header,
  RESERVED_INPUT_HEADERS,
} from 'features/evaluations/shared/utils/parseCsvFileToAttributeData';

import { CellRendererLabels } from './CellRendererLabels';
import { CellRendererResult } from './CellRenderResult';

type Props = StackProps & { evaluation: Evaluation };

const buildColumns = (headers: Header[]): DataGridColumn<any>[] => {
  const columns: DataGridColumn<any>[] = [
    {
      field: HEADERS_DICTIONARY[EvaluationHeaderKey.ID],
      flex: 0,
      maxWidth: 50,
      renderCell: (params: {
        row: { [x: string]: string | null | undefined };
      }) => <CellRendererString value={params.row[EvaluationHeaderKey.ID]} />,
    },
  ];

  headers.forEach((header) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    // @ts-ignore
    const field = (HEADERS_DICTIONARY[header.value] ?? header.value) as string;

    columns.push({
      field,
      flex: RESERVED_INPUT_HEADERS.includes(header.value) ? 1 : undefined,
      width: RESERVED_INPUT_HEADERS.includes(header.value) ? 400 : undefined,
      renderCell: (params: {
        row: { [x: string]: string | null | undefined };
      }) => (
        <CellRendererExpandableString
          value={params.row[header.value]}
          charactersLimit={200}
        />
      ),
    });
  });

  return [
    ...columns,
    {
      field: HEADERS_DICTIONARY[EvaluationHeaderKey.RESULT],
      minWidth: 130,
      renderCell: (params: { row: { [x: string]: boolean | undefined } }) => (
        <CellRendererResult
          value={params.row[EvaluationHeaderKey.RESULT]}
          columnKey="result"
        />
      ),
    },
    {
      field: HEADERS_DICTIONARY[EvaluationHeaderKey.LABELS],
      flex: 1,
      minWidth: 130,
      renderCell: (params: {
        row: { [x: string]: Record<string, boolean> };
      }) => (
        <CellRendererLabels
          labels={params.row[EvaluationHeaderKey.LABELS]}
          columnKey="labels-list"
        />
      ),
    },
    {
      field: HEADERS_DICTIONARY[EvaluationHeaderKey.FEEDBACK],
      flex: 1,
      renderCell: (params: {
        row: { [x: string]: string | null | undefined };
      }) => (
        <CellRendererString value={params.row[EvaluationHeaderKey.FEEDBACK]} />
      ),
    },
  ];
};

export const EvaluationsTable: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { evaluation, ...other } = props;
  const columns = buildColumns(evaluation.headers);

  const { paginatedValues, pagination } = useVirtualPagination<EvaluationData>({
    totalCount: evaluation.rows.length,
    values: evaluation.rows,
    pageSize: 12,
  });

  return (
    <Stack spacing={0} {...other}>
      <DataGridWithTypes
        rows={paginatedValues}
        columns={columns}
        heightType="pageHeight"
        getRowHeight={() => 'auto'}
        pagination={pagination.pagination}
        onPageChange={pagination.onCurrentPageChange}
        onPageSizeChange={pagination.onCurrentPageSizeChange}
      />
    </Stack>
  );
};
