import { Stack, Typography } from '@mui/material';

import matchBy from 'shared/utils/matchBy';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import routes from 'shared/routes';
import Chip from 'shared/view/elements/Chip/Chip';
import {
  IBuild,
  RegisteredModelVersion,
} from 'features/deployment/canary/endpoints/store/endpointQuery/endpointQuery';

const ChipStage = ({
  modelVersion,
}: {
  modelVersion: RegisteredModelVersion;
}) => {
  const version = modelVersion.registeredModel.versions.versions.find(
    (item) => item.version === modelVersion.version
  );

  return <Chip label={version?.stage} color="success" size="small" />;
};

export const ModelServing = ({
  lastBuild,
}: {
  lastBuild: IBuild | undefined;
}) => {
  return lastBuild?.source ? (
    matchBy(
      lastBuild.source,
      '__typename'
    )({
      Error: () => <></>,
      ExperimentRun: (experimentRun) => (
        <Stack direction="row" alignItems="center">
          <Typography variant="formValue">Project: </Typography>
          <InlineLink
            sx={{ marginRight: 1 }}
            to={routes.projectOverview.getRedirectPath({
              projectId: experimentRun.projectId,
              workspaceName: experimentRun.project.workspace.name,
            })}
          >
            {experimentRun.project.name}
          </InlineLink>
          &#x2022;
          <Typography variant="formValue">Experiment Run: </Typography>
          <InlineLink
            to={routes.experimentRun.getRedirectPath({
              experimentRunId: experimentRun.id,
              projectId: experimentRun.project.id,
              workspaceName: experimentRun.project.workspace.name,
            })}
            dataTest="experiment-run-title-link"
          >
            {experimentRun.name}
          </InlineLink>
        </Stack>
      ),
      RegisteredModelVersion: (modelVersion) => (
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Stack direction="row" alignItems="center">
              <Typography variant="formValue">Model: </Typography>
              <InlineLink
                sx={{ marginRight: 1 }}
                to={routes.modelOverview.getRedirectPath({
                  workspaceName: modelVersion.registeredModel.workspace.name,
                  registeredModelId: modelVersion.registeredModel.id,
                })}
              >
                {modelVersion.registeredModel.name}
              </InlineLink>
              &#x2022;
              <Typography variant="formValue">Version: </Typography>
              <InlineLink
                to={routes.registeredModelVersion.getRedirectPath({
                  workspaceName: modelVersion.registeredModel.workspace.name,
                  registeredModelId: modelVersion.registeredModel.id,
                  versionId: modelVersion.id,
                })}
                dataTest="registered-model-version-title"
              >
                {modelVersion.version}
              </InlineLink>
              <ChipStage modelVersion={modelVersion} />
            </Stack>
            <Typography variant="caption" color="text.secondary">
              Build: {lastBuild.id}
            </Typography>
          </Stack>
        </Stack>
      ),
    })
  ) : (
    <Stack direction="row" alignItems="center">
      <Typography variant="formValue" marginRight={1}>
        Model: no model
      </Typography>
      &#x2022;
      <Typography variant="formValue"> Version: no version</Typography>
    </Stack>
  );
};
