import { Box, Stack, Typography } from '@mui/material';

import image from '../images/build-and-experiment.png';
import { Circle } from '../Circle';

export const BuildAndExperiment = () => (
  <Stack direction="row" spacing={0} alignItems="center">
    <Box width="65%" mr={4}>
      <img src={image} width="100%" height="auto" alt="LLM options cards" />
    </Box>
    <Stack width="35%" maxWidth="306px" display="flex" alignItems="flex-start">
      <Circle>1</Circle>
      <Typography variant="h6" color="text.primary">
        Build & experiment
      </Typography>
      <Box>
        <Typography
          mb={2}
          fontFamily="DM Sans"
          variant="body2"
          color="text.primary"
        >
          Get everything you need to get started with LLMs and prompt
          engineering immediately—no GenAI expertise required. Try our Starter
          Kits which take you from use case to prototype with sample data and
          suggested models and prompts..
          <br />
          <br />
          Experiment with proprietary and open source models and prompts to
          speed up the iteration process.
        </Typography>
      </Box>
    </Stack>
  </Stack>
);
