/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  Number: { input: string | number; output: string | number };
  Timestamp: { input: number; output: number };
};

export enum AccessType {
  OWNER = 'OWNER',
  READ_ONLY = 'READ_ONLY',
  READ_WRITE = 'READ_WRITE',
}

export enum ActionTypeV2 {
  ACTION_TYPE_UNKNOWN = 'ACTION_TYPE_UNKNOWN',
  APPROVE_REJECT = 'APPROVE_REJECT',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  DEPLOY = 'DEPLOY',
  PREDICT = 'PREDICT',
  READ = 'READ',
  REGISTER = 'REGISTER',
  UPDATE = 'UPDATE',
}

export type ActiveDirectory = {
  __typename: 'ActiveDirectory';
  configuration?: Maybe<ActiveDirectoryConfiguration>;
  id: Scalars['ID']['output'];
};

export type ActiveDirectoryConfiguration = {
  __typename: 'ActiveDirectoryConfiguration';
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  token: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Activity = {
  __typename: 'Activity';
  activityState: TransitionState;
  approval?: Maybe<ApprovalOfTransition>;
  author: User;
  close?: Maybe<CloseOfTransition>;
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  rejection?: Maybe<RejectionOfTransition>;
  timeCreated: Scalars['Timestamp']['output'];
  transition?: Maybe<Transition>;
  version: RegisteredModelVersion;
};

export type AddFeedbackInput = {
  labels?: InputMaybe<Array<InputMaybe<LLMLabelInput>>>;
  targetId: Scalars['ID']['input'];
};

export type Alert = {
  __typename: 'Alert';
  aggregationWindow: AlertAggregationWindow;
  alerter: Alerter;
  createdAt: Scalars['Date']['output'];
  delete: Scalars['Boolean']['output'];
  enable: Alert;
  enabled: Scalars['Boolean']['output'];
  evaluationFrequency: AlertEvaluationFrequency;
  featureName: Scalars['String']['output'];
  history: Array<AlertHistoryItem>;
  id: Scalars['ID']['output'];
  lastEvaluatedAt: Scalars['Date']['output'];
  monitoredEntity: MonitoredEntity;
  name: Scalars['String']['output'];
  notificationChannels: Array<AlertNotificationChannel>;
  settings: AlertSettings;
  status: AlertStatus;
  type: AlerterType;
  updateAggregationWindow: Alert;
  updateAlerter: Alert;
  updateEvaluationFrequency: Alert;
  updateName: Alert;
  updateSettings: Alert;
  updateStatus: Alert;
  updatedAt: Scalars['Date']['output'];
};

export type Alert_enableArgs = {
  enable: Scalars['Boolean']['input'];
};

export type Alert_updateAggregationWindowArgs = {
  aggregationWindow: AlertAggregationWindow;
};

export type Alert_updateAlerterArgs = {
  alerter: AlerterInput;
};

export type Alert_updateEvaluationFrequencyArgs = {
  evaluationFrequency: AlertEvaluationFrequency;
};

export type Alert_updateNameArgs = {
  name: Scalars['String']['input'];
};

export type Alert_updateSettingsArgs = {
  settings: AlertSettingsInput;
};

export type Alert_updateStatusArgs = {
  status: AlertStatus;
};

export enum AlertAggregationWindow {
  DAY = 'DAY',
  FIVE_MINUTES = 'FIVE_MINUTES',
  HOUR = 'HOUR',
  UNKNOWN = 'UNKNOWN',
}

export enum AlertEvaluationFrequency {
  DAY = 'DAY',
  FIVE_MINUTES = 'FIVE_MINUTES',
  HOUR = 'HOUR',
  UNKNOWN = 'UNKNOWN',
}

export type AlertHistoryItem = {
  __typename: 'AlertHistoryItem';
  alertSnapshot: AlertSnapshot;
  eventTime: Scalars['Date']['output'];
  status: AlertStatus;
};

export type AlertNotificationChannel = {
  __typename: 'AlertNotificationChannel';
  channel: NotificationChannel;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: AlertNotificationChannelType;
  updatedAt: Scalars['Date']['output'];
};

export type AlertNotificationChannelSlackWebhook = {
  __typename: 'AlertNotificationChannelSlackWebhook';
  webhook: Scalars['String']['output'];
};

export enum AlertNotificationChannelType {
  SLACK = 'SLACK',
  UNKNOWN = 'UNKNOWN',
}

export type AlertOrError = Alert | Error;

export type AlertQuery = {
  alerterTypes?: InputMaybe<Array<AlerterType>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastEvaluatedAtAfter?: InputMaybe<Scalars['Date']['input']>;
  monitoredEntityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  pagination?: InputMaybe<PaginationQuery>;
  status?: InputMaybe<Array<AlertStatus>>;
};

export type AlertSettings =
  | ConfidenceMetricAlertSettings
  | DriftMetricAlertSettings
  | MetricAlertSettings;

export type AlertSettingsInput = {
  confidenceMetricAlertSettings?: InputMaybe<ConfidenceMetricAlertSettingsInput>;
  driftAlertSettings?: InputMaybe<DriftAlertSettingsInput>;
  metricAlertSettings?: InputMaybe<MetricAlertSettingsInput>;
  type: AlertType;
};

export type AlertSnapshot = {
  __typename: 'AlertSnapshot';
  aggregationWindow: AlertAggregationWindow;
  alerter: Alerter;
  createdAt: Scalars['Date']['output'];
  delete: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  evaluationFrequency: AlertEvaluationFrequency;
  featureName: Scalars['String']['output'];
  lastEvaluatedAt: Scalars['Date']['output'];
  monitoredEntity: MonitoredEntity;
  name: Scalars['String']['output'];
  notificationChannels: Array<AlertNotificationChannel>;
  settings: AlertSettings;
  status: AlertStatus;
  type: AlerterType;
  updatedAt: Scalars['Date']['output'];
};

export enum AlertStatus {
  ALERTING = 'ALERTING',
  NODATA = 'NODATA',
  OK = 'OK',
  PAUSED = 'PAUSED',
  UNKNOWN = 'UNKNOWN',
}

export enum AlertType {
  CONFIDENCE_METRIC = 'CONFIDENCE_METRIC',
  DRIFT = 'DRIFT',
  METRIC = 'METRIC',
}

export type Alerter = AlerterFixed | AlerterRange;

export type AlerterFixed = {
  __typename: 'AlerterFixed';
  operator: PredicateOperator;
  threshold: Scalars['Float']['output'];
};

export type AlerterFixedInput = {
  operator: PredicateOperator;
  threshold: Scalars['Float']['input'];
};

export type AlerterInput = {
  fixed?: InputMaybe<AlerterFixedInput>;
  range?: InputMaybe<AlerterRangeInput>;
};

export type AlerterRange = {
  __typename: 'AlerterRange';
  alertIfOutsideRange: Scalars['Boolean']['output'];
  lowerBound: Scalars['Float']['output'];
  upperBound: Scalars['Float']['output'];
};

export type AlerterRangeInput = {
  alertIfOutsideRange: Scalars['Boolean']['input'];
  lowerBound: Scalars['Float']['input'];
  upperBound: Scalars['Float']['input'];
};

export enum AlerterType {
  FIXED = 'FIXED',
  RANGE = 'RANGE',
  REFERENCE = 'REFERENCE',
  UNKNOWN = 'UNKNOWN',
}

export type Alerts = {
  __typename: 'Alerts';
  alerts: Array<Alert>;
  next?: Maybe<Scalars['String']['output']>;
  pagination: PaginationResponse;
};

export type AllowedActions = {
  __typename: 'AllowedActions';
  create: Scalars['Boolean']['output'];
  delete: Scalars['Boolean']['output'];
  deploy: Scalars['Boolean']['output'];
  lock: Scalars['Boolean']['output'];
  predict: Scalars['Boolean']['output'];
  unlock: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  updatePermissions: Scalars['Boolean']['output'];
  updateRedact: Scalars['Boolean']['output'];
};

export type Analytics = {
  __typename: 'Analytics';
  track: TrackOrError;
};

export type Analytics_trackArgs = {
  event?: InputMaybe<TrackEvent>;
};

export type ApprovalOfTransition = {
  __typename: 'ApprovalOfTransition';
  exists?: Maybe<Scalars['Boolean']['output']>;
};

export type Artifact = {
  __typename: 'Artifact';
  artifactSubtype?: Maybe<Scalars['String']['output']>;
  filenameExtension?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  linkedArtifactId: Scalars['String']['output'];
  linkedDatasetVersion?: Maybe<DatasetVersionOrError>;
  path: Scalars['String']['output'];
  pathOnly: Scalars['Boolean']['output'];
  serialization?: Maybe<Scalars['String']['output']>;
  type: ArtifactType;
  uploadCompleted: Scalars['Boolean']['output'];
};

export type ArtifactInput = {
  filenameExtension?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  linkedArtifactId: Scalars['String']['input'];
  path: Scalars['String']['input'];
  pathOnly: Scalars['Boolean']['input'];
  type: ArtifactType;
};

export enum ArtifactType {
  BLOB = 'BLOB',
  DATA = 'DATA',
  IMAGE = 'IMAGE',
  MODEL = 'MODEL',
  STRING = 'STRING',
  TENSORBOARD = 'TENSORBOARD',
}

export type AutoscalingMetric = {
  __typename: 'AutoscalingMetric';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parameters: Array<AutoscalingMetricParameter>;
};

export type AutoscalingMetricParameter = {
  __typename: 'AutoscalingMetricParameter';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: AutoscalingMetricParameterType;
};

export type AutoscalingMetricParameterStatus = {
  __typename: 'AutoscalingMetricParameterStatus';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum AutoscalingMetricParameterType {
  COUNT = 'COUNT',
  LATENCY_MS = 'LATENCY_MS',
  PERCENTAGE = 'PERCENTAGE',
  UNKNOWN = 'UNKNOWN',
}

export type AutoscalingMetricStatus = {
  __typename: 'AutoscalingMetricStatus';
  id: Scalars['ID']['output'];
  parameters: Array<AutoscalingMetricParameterStatus>;
};

export type AutoscalingMetricsParameterUpdateConfig = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type AutoscalingMetricsUpdateConfig = {
  id?: InputMaybe<Scalars['ID']['input']>;
  parameters: Array<AutoscalingMetricsParameterUpdateConfig>;
};

export type AutoscalingQuantitiesStatus = {
  __typename: 'AutoscalingQuantitiesStatus';
  maxReplicas?: Maybe<Scalars['Int']['output']>;
  maxScale?: Maybe<Scalars['Float']['output']>;
  minReplicas?: Maybe<Scalars['Int']['output']>;
  minScale?: Maybe<Scalars['Float']['output']>;
};

export type AutoscalingQuantitiesUpdateConfig = {
  maxReplicas?: InputMaybe<Scalars['Int']['input']>;
  maxScale?: InputMaybe<Scalars['Float']['input']>;
  minReplicas?: InputMaybe<Scalars['Int']['input']>;
  minScale?: InputMaybe<Scalars['Float']['input']>;
};

export type AutoscalingStatus = {
  __typename: 'AutoscalingStatus';
  metrics: Array<AutoscalingMetricStatus>;
  quantities: AutoscalingQuantitiesStatus;
};

export type AutoscalingUpdateConfig = {
  metrics?: InputMaybe<Array<AutoscalingMetricsUpdateConfig>>;
  quantities?: InputMaybe<AutoscalingQuantitiesUpdateConfig>;
};

/** @deprecated */
export enum BaselinePermissions {
  ADMIN = 'ADMIN',
  PRIVATE = 'PRIVATE',
  READ = 'READ',
  UNKNOWN = 'UNKNOWN',
  WRITE = 'WRITE',
}

export type BoolKeyValue = {
  __typename: 'BoolKeyValue';
  key: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

export type BranchesNetwork = {
  __typename: 'BranchesNetwork';
  branches: Array<NetworkBranchColor>;
  commits: Array<NetworkCommitColor>;
  edges: Array<NetworkEdgeColor>;
};

export type Build = {
  __typename: 'Build';
  dateCreated: Scalars['Timestamp']['output'];
  dateUpdated: Scalars['Timestamp']['output'];
  externalLocation?: Maybe<Scalars['String']['output']>;
  hardwareCompatibility?: Maybe<BuildHardwareCompatibility>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  scan: BuildScanStatus;
  scanStatus: BuildScanStatus;
  source?: Maybe<BuildSourceOrError>;
  status: BuildStatus;
};

export type Build_scanArgs = {
  external?: InputMaybe<Scalars['Boolean']['input']>;
  workspaceName?: InputMaybe<Scalars['String']['input']>;
};

export type BuildHardwareCompatibility = {
  __typename: 'BuildHardwareCompatibility';
  nvidiaGpu?: Maybe<NvidiaGpuModel>;
};

export type BuildHardwareCompatibilityInput = {
  nvidiaGpu?: InputMaybe<NvidiaGpuModel>;
};

export type BuildOrError = Build | Error;

export type BuildScanExternalResults = {
  __typename: 'BuildScanExternalResults';
  safetyStatus?: Maybe<BuildScanSafetyStatus>;
  url?: Maybe<Scalars['String']['output']>;
};

export type BuildScanResultDetail = {
  __typename: 'BuildScanResultDetail';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  package?: Maybe<Scalars['String']['output']>;
  severity: BuildScanSeverity;
};

export enum BuildScanSafetyStatus {
  SAFE = 'SAFE',
  UNKNOWN = 'UNKNOWN',
  UNSAFE = 'UNSAFE',
}

export enum BuildScanSeverity {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  INFORMATIONAL = 'INFORMATIONAL',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  UNKNOWN = 'UNKNOWN',
}

export type BuildScanStatus =
  | BuildScanStatusError
  | BuildScanStatusScanned
  | BuildScanStatusScanning
  | BuildScanStatusUnscanned;

export type BuildScanStatusError = {
  __typename: 'BuildScanStatusError';
  scanExternal?: Maybe<Scalars['Boolean']['output']>;
  systemErrorLog: Scalars['String']['output'];
};

export type BuildScanStatusScanned = {
  __typename: 'BuildScanStatusScanned';
  dateUpdated: Scalars['Timestamp']['output'];
  details: Array<BuildScanResultDetail>;
  safetyStatus: BuildScanSafetyStatus;
  scanExternal?: Maybe<Scalars['Boolean']['output']>;
  scanExternalResults?: Maybe<BuildScanExternalResults>;
  scanner: Scalars['String']['output'];
};

export type BuildScanStatusScanning = {
  __typename: 'BuildScanStatusScanning';
  empty?: Maybe<Scalars['Boolean']['output']>;
  scanExternal?: Maybe<Scalars['Boolean']['output']>;
};

export type BuildScanStatusUnscanned = {
  __typename: 'BuildScanStatusUnscanned';
  empty?: Maybe<Scalars['Boolean']['output']>;
};

export type BuildSourceOrError = Error | ExperimentRun | RegisteredModelVersion;

export enum BuildStatus {
  BUILDING = 'BUILDING',
  DELETING = 'DELETING',
  ERROR = 'ERROR',
  FINISHED = 'FINISHED',
  INITIALIZING = 'INITIALIZING',
}

export type Builds = {
  __typename: 'Builds';
  builds: Array<Build>;
};

export type CanaryRolloutStatus = {
  __typename: 'CanaryRolloutStatus';
  components: Array<Maybe<CanaryRolloutStatusComponent>>;
  time: Array<Scalars['Int']['output']>;
};

export type CanaryRolloutStatusComponent = {
  __typename: 'CanaryRolloutStatusComponent';
  build: BuildOrError;
  buildID: Scalars['ID']['output'];
  ratio: Array<Scalars['Float']['output']>;
};

export type CanaryRule = {
  __typename: 'CanaryRule';
  category: CanaryRuleCategory;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parameters: Array<CanaryRuleParameters>;
};

export enum CanaryRuleCategory {
  ERROR = 'ERROR',
  LATENCY = 'LATENCY',
  UNKNOWN = 'UNKNOWN',
}

export type CanaryRuleParameters = {
  __typename: 'CanaryRuleParameters';
  defaultValue?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: CanaryRuleParametersType;
};

export enum CanaryRuleParametersType {
  LATENCY_MS = 'LATENCY_MS',
  PERCENTAGE = 'PERCENTAGE',
  UNKNOWN = 'UNKNOWN',
}

export type CanaryStrategy = {
  progressInterval: Scalars['Int']['input'];
  progressStep: Scalars['Float']['input'];
  rules: Array<CanaryStrategyRule>;
};

export type CanaryStrategyRule = {
  id: Scalars['ID']['input'];
  parameters: Array<CanaryStrategyRuleParameter>;
};

export type CanaryStrategyRuleParameter = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CanaryStrategyRuleParameterStatus = {
  __typename: 'CanaryStrategyRuleParameterStatus';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CanaryStrategyRuleStatus = {
  __typename: 'CanaryStrategyRuleStatus';
  id: Scalars['ID']['output'];
  parameters: Array<CanaryStrategyRuleParameterStatus>;
};

export type CanaryStrategyStatus = {
  __typename: 'CanaryStrategyStatus';
  progressInterval: Scalars['Int']['output'];
  progressStep: Scalars['Float']['output'];
  rules: Array<CanaryStrategyRuleStatus>;
};

export type CatalogModelIdentifier = {
  __typename: 'CatalogModelIdentifier';
  modelVersionId: Scalars['ID']['output'];
  registeredModelId: Scalars['ID']['output'];
};

export type CatalogModelIdentifierInput = {
  modelVersionId: Scalars['ID']['input'];
  registeredModelId: Scalars['ID']['input'];
};

export type ChecklistItemValue = {
  __typename: 'ChecklistItemValue';
  checklistTemplateId: Scalars['ID']['output'];
  checklistTemplateItemId: Scalars['ID']['output'];
  completed: Scalars['Boolean']['output'];
  createdBy: User;
  evidence?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  registeredModelVersion: RegisteredModelVersion;
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  updatedBy: User;
};

export type ChecklistItemValues = {
  __typename: 'ChecklistItemValues';
  checklistItemValues?: Maybe<Array<ChecklistItemValue>>;
};

export type ChecklistItemValuesOrError = ChecklistItemValues | Error;

export type ChecklistTemplate = {
  __typename: 'ChecklistTemplate';
  applyToRiskLevel?: Maybe<RiskLevel>;
  blocksDeployment: Scalars['Boolean']['output'];
  checklistTemplateItems?: Maybe<Array<ChecklistTemplateItem>>;
  createdBy: User;
  customAttributeValues?: Maybe<Array<Maybe<CustomAttributeValue>>>;
  desiredStage?: Maybe<Stage>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  sort: Scalars['Int']['output'];
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  updatedBy: User;
};

export type ChecklistTemplateInput = {
  applyToRiskLevel?: InputMaybe<RiskLevel>;
  blocksDeployment?: InputMaybe<Scalars['Boolean']['input']>;
  checklistTemplateItems?: InputMaybe<Array<ChecklistTemplateItemInput>>;
  customAttributeValues?: InputMaybe<
    Array<InputMaybe<CustomAttributeValueInput>>
  >;
  desiredStage?: InputMaybe<Stage>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
};

export type ChecklistTemplateItem = {
  __typename: 'ChecklistTemplateItem';
  checklistTemplateId: Scalars['ID']['output'];
  createdBy: User;
  description?: Maybe<Scalars['String']['output']>;
  editable: Editable;
  id: Scalars['ID']['output'];
  itemType: ChecklistTemplateItemType;
  name: Scalars['String']['output'];
  requireEvidence: Scalars['Boolean']['output'];
  required: Scalars['Boolean']['output'];
  sort: Scalars['Int']['output'];
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  updatedBy: User;
  userGroupId?: Maybe<Scalars['ID']['output']>;
};

export type ChecklistTemplateItemInput = {
  checklistTemplateId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  editable: Editable;
  id?: InputMaybe<Scalars['ID']['input']>;
  itemType: ChecklistTemplateItemType;
  name: Scalars['String']['input'];
  requireEvidence: Scalars['Boolean']['input'];
  required: Scalars['Boolean']['input'];
  sort?: InputMaybe<Scalars['Int']['input']>;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ChecklistTemplateItemType {
  CHECKBOX = 'CHECKBOX',
  SYSTEM = 'SYSTEM',
  UNKNOWN = 'UNKNOWN',
  WORKFLOW = 'WORKFLOW',
}

export type ChecklistTemplateOrError = ChecklistTemplate | Error;

export type ChecklistTemplatesInput = {
  modelVersionId?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
  workspaceName?: InputMaybe<Scalars['String']['input']>;
};

export type ChecklistTemplatesResponse = {
  __typename: 'ChecklistTemplatesResponse';
  checklistTemplates?: Maybe<Array<ChecklistTemplate>>;
};

export type CloseOfTransition = {
  __typename: 'CloseOfTransition';
  exists?: Maybe<Scalars['Boolean']['output']>;
};

export type CodeVersion = CodeVersionArchive | CodeVersionGit;

export type CodeVersionArchive = {
  __typename: 'CodeVersionArchive';
  artifact: Artifact;
  dateLogged: Scalars['Timestamp']['output'];
  filenameExtension?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  path: Scalars['String']['output'];
  pathOnly: Scalars['Boolean']['output'];
  type: ArtifactType;
};

export type CodeVersionBlob = {
  __typename: 'CodeVersionBlob';
  codeVersion: CodeVersion;
  key: Scalars['String']['output'];
};

export type CodeVersionGit = {
  __typename: 'CodeVersionGit';
  branch?: Maybe<Scalars['String']['output']>;
  dateLogged: Scalars['Timestamp']['output'];
  filepaths: Array<Scalars['String']['output']>;
  hash: Scalars['String']['output'];
  isDirty: Scalars['Boolean']['output'];
  repo: Scalars['String']['output'];
};

/** @deprecated */
export type Collaborator = UserCollaborator;

/** @deprecated */
export type CollaboratorReference = {
  usernameOrEmail?: InputMaybe<Scalars['String']['input']>;
};

/** @deprecated */
export enum CollaboratorType {
  READ_ONLY = 'READ_ONLY',
  READ_WRITE = 'READ_WRITE',
  UNKNOWN = 'UNKNOWN',
}

export type Commit = {
  __typename: 'Commit';
  asDiff?: Maybe<CommitAsDiff>;
  author: User;
  date: Scalars['Date']['output'];
  getLocation?: Maybe<CommitElement>;
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  runs: ExperimentRuns;
  setBranch: Repository;
  setTag: Repository;
};

export type Commit_getLocationArgs = {
  location: Array<Scalars['String']['input']>;
};

export type Commit_runsArgs = {
  query?: InputMaybe<ExperimentRunsQuery>;
};

export type Commit_setBranchArgs = {
  name: Scalars['String']['input'];
};

export type Commit_setTagArgs = {
  name: Scalars['String']['input'];
};

export type CommitAsDiff = {
  __typename: 'CommitAsDiff';
  diff?: Maybe<Array<Scalars['String']['output']>>;
  parent: Scalars['String']['output'];
};

export type CommitBlob = {
  __typename: 'CommitBlob';
  content: Scalars['String']['output'];
  downloadUrlForComponent: Scalars['String']['output'];
  runs: ExperimentRuns;
};

export type CommitBlob_downloadUrlForComponentArgs = {
  componentPath: Scalars['String']['input'];
};

export type CommitBlob_runsArgs = {
  query?: InputMaybe<ExperimentRunsQuery>;
};

export type CommitElement = CommitBlob | CommitFolder;

export type CommitFolder = {
  __typename: 'CommitFolder';
  blobs: Array<NamedCommitBlob>;
  subfolders: Array<NamedCommitFolder>;
};

export type CommitReference = {
  branch?: InputMaybe<Scalars['String']['input']>;
  commit?: InputMaybe<Scalars['ID']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type Commits = {
  __typename: 'Commits';
  commits: Array<Commit>;
};

export type ConfidenceMetricAlertSettings = {
  __typename: 'ConfidenceMetricAlertSettings';
  confidenceMetricType: MonitoringConfidenceMetricType;
};

export type ConfidenceMetricAlertSettingsInput = {
  confidenceMetricType: MonitoringConfidenceMetricType;
  output: MonitoringIODescriptionInput;
};

export type ConfigResourcesNvidiaGpu = {
  __typename: 'ConfigResourcesNvidiaGpu';
  model?: Maybe<NvidiaGpuModel>;
  number?: Maybe<Scalars['Int']['output']>;
};

export type ConfigResourcesNvidiaGpuInput = {
  model?: InputMaybe<NvidiaGpuModel>;
  number?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateAlert = {
  aggregationWindow: AlertAggregationWindow;
  alerter: AlerterInput;
  enable: Scalars['Boolean']['input'];
  evaluationFrequency: AlertEvaluationFrequency;
  name: Scalars['String']['input'];
  settings: AlertSettingsInput;
  status: AlertStatus;
  type: AlerterType;
};

export type CreateDistillationDatasetSource = {
  experiment?: InputMaybe<CreateDistillationJobDatasetSourceExperiment>;
  gcs?: InputMaybe<CreateDistillationJobDatasetSourceGcs>;
  inferenceJob?: InputMaybe<CreateDistillationJobDatasetSourceInferenceJob>;
};

export type CreateDistillationJob = {
  accelerator: DistillationAccelerator;
  datasetSource: CreateDistillationDatasetSource;
  distilledModelName: Scalars['String']['input'];
  distilledModelVersionId: Scalars['ID']['input'];
  fineTuningStudentModelId: Scalars['ID']['input'];
  fineTuningTeacherModelId: Scalars['ID']['input'];
  inputFeature: Scalars['String']['input'];
  targetFeature?: InputMaybe<Scalars['String']['input']>;
  task: DistillationTask;
};

export type CreateDistillationJobDatasetSourceExperiment = {
  experimentId: Scalars['ID']['input'];
};

export type CreateDistillationJobDatasetSourceGcs = {
  bucket: Scalars['String']['input'];
  path: Scalars['String']['input'];
};

export type CreateDistillationJobDatasetSourceInferenceJob = {
  inferenceJobId: Scalars['ID']['input'];
};

export type CreateFineTuningJobInput = {
  baseModelVersionId: Scalars['ID']['input'];
  destinationModelVersionId: Scalars['ID']['input'];
  evalDatasetVersionId?: InputMaybe<Scalars['String']['input']>;
  loraConfigParameters: CreateFineTuningJobLoraConfigParameters;
  runId: Scalars['String']['input'];
  testDatasetVersionId?: InputMaybe<Scalars['String']['input']>;
  trainDatasetVersionId: Scalars['String']['input'];
};

export type CreateFineTuningJobLoraConfigParameters = {
  alpha?: InputMaybe<Scalars['Int']['input']>;
  dropout?: InputMaybe<Scalars['Float']['input']>;
  r?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateGcloudDeployment = {
  modelId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  promptId: Scalars['String']['input'];
  shortUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
  useCaseId: Scalars['String']['input'];
};

export type CreateInferenceJob = {
  experimentId: Scalars['ID']['input'];
};

export type CreateJobInput = {
  jobDetailsJson: Scalars['String']['input'];
  jobType: JobType;
  orgId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateLlmEndpointInput = {
  accessToken: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  modelId: Scalars['String']['input'];
  path: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  promptId: Scalars['String']['input'];
};

export type CreateMonitoredModel = {
  json: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CustomAttributeCategories = {
  __typename: 'CustomAttributeCategories';
  customAttributeCategories?: Maybe<Array<CustomAttributeCategory>>;
};

export type CustomAttributeCategoriesOrError =
  | CustomAttributeCategories
  | Error;

export type CustomAttributeCategory = {
  __typename: 'CustomAttributeCategory';
  createdBy: User;
  description?: Maybe<Scalars['String']['output']>;
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  sort: Scalars['Int']['output'];
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  updatedBy: User;
};

export type CustomAttributeCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  sort?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomAttributeCategoryOrError = CustomAttributeCategory | Error;

export type CustomAttributeDefinition = {
  __typename: 'CustomAttributeDefinition';
  attributeType: CustomAttributeType;
  createdBy: User;
  customAttributeCategoryId: Scalars['ID']['output'];
  customAttributeListOptions?: Maybe<Array<CustomAttributeListOption>>;
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  sort: Scalars['Int']['output'];
  sourceRequired: Scalars['Boolean']['output'];
  targetType: CustomAttributeTargetType;
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  updatedBy: User;
};

export type CustomAttributeDefinitionInput = {
  attributeType: CustomAttributeType;
  customAttributeCategoryId?: InputMaybe<Scalars['ID']['input']>;
  customAttributeListOptions?: InputMaybe<
    Array<CustomAttributeListOptionInput>
  >;
  icon: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  sort?: InputMaybe<Scalars['Int']['input']>;
  sourceRequired: Scalars['Boolean']['input'];
  targetType: CustomAttributeTargetType;
};

export type CustomAttributeDefinitionOrError =
  | CustomAttributeDefinition
  | Error;

export type CustomAttributeDefinitions = {
  __typename: 'CustomAttributeDefinitions';
  customAttributeDefinitions?: Maybe<Array<CustomAttributeDefinition>>;
};

export type CustomAttributeDefinitionsOrError =
  | CustomAttributeDefinitions
  | Error;

export type CustomAttributeListOption = {
  __typename: 'CustomAttributeListOption';
  customAttributeDefinitionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sort: Scalars['Int']['output'];
};

export type CustomAttributeListOptionInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  sort: Scalars['Int']['input'];
};

export enum CustomAttributeTargetType {
  CHECKLIST_TEMPLATE = 'CHECKLIST_TEMPLATE',
  REGISTERED_MODEL = 'REGISTERED_MODEL',
  REGISTERED_MODEL_VERSION = 'REGISTERED_MODEL_VERSION',
  UNKNOWN = 'UNKNOWN',
}

export enum CustomAttributeType {
  CUSTOM_LIST = 'CUSTOM_LIST',
  GROUP = 'GROUP',
  LONG_TEXT = 'LONG_TEXT',
  NUMERIC = 'NUMERIC',
  TEXT = 'TEXT',
  UNKNOWN = 'UNKNOWN',
  USER = 'USER',
}

export type CustomAttributeValue = {
  __typename: 'CustomAttributeValue';
  createOrUpdate?: Maybe<CustomAttributeValueOrError>;
  createdBy: User;
  customAttributeDefinitionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  selectedValue: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
  targetId: Scalars['ID']['output'];
  targetType: CustomAttributeTargetType;
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  updatedBy: User;
};

export type CustomAttributeValue_createOrUpdateArgs = {
  input: CustomAttributeValueInput;
};

export type CustomAttributeValueInput = {
  customAttributeDefinitionId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  selectedValue: Scalars['String']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
  targetId: Scalars['ID']['input'];
  targetType: CustomAttributeTargetType;
};

export type CustomAttributeValueOrError = CustomAttributeValue | Error;

export type CustomDashboard = {
  __typename: 'CustomDashboard';
  charts: Array<CustomDashboardChart>;
  name: Scalars['String']['output'];
  summaries: Array<CustomDashboardSummary>;
};

export type CustomDashboardChart = {
  __typename: 'CustomDashboardChart';
  chartValues: Array<CustomDashboardChartValue>;
  name: Scalars['String']['output'];
};

export type CustomDashboardChartValue = {
  __typename: 'CustomDashboardChartValue';
  name: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type CustomDashboardOrError = CustomDashboard | Error;

export type CustomDashboardSummary = {
  __typename: 'CustomDashboardSummary';
  name: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type CustomDashboardTopModels = {
  __typename: 'CustomDashboardTopModels';
  models: Array<RegisteredModelOrError>;
};

export type CustomDashboardTopModelsOrError = CustomDashboardTopModels | Error;

export type CustomPermissionsInput = {
  canDeploy: Scalars['Boolean']['input'];
  canPredict: Scalars['Boolean']['input'];
  type: AccessType;
};

export enum DataType {
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  OTHER = 'OTHER',
  TABULAR = 'TABULAR',
  TEXT = 'TEXT',
  UNKNOWN = 'UNKNOWN',
  VIDEO = 'VIDEO',
}

export type Dataset = {
  __typename: 'Dataset';
  addAttributes: Dataset;
  addTags: Dataset;
  allowedActions: AllowedActions;
  attributes: Array<KeyValue>;
  changeDescription: Dataset;
  customPermission?: Maybe<Permissions>;
  datasetVersions: DatasetVersions;
  dateCreated: Scalars['Timestamp']['output'];
  dateUpdated: Scalars['Timestamp']['output'];
  delete: Scalars['Boolean']['output'];
  deleteAttributes: Dataset;
  deleteTags: Dataset;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: UserOrGroup;
  ownerCandidates: OwnerCandidates;
  ownerV2Candidates: OwnerV2Candidates;
  tags: Array<Scalars['String']['output']>;
  updateAttribute: Dataset;
  updateOwner: Dataset;
  updatePermission: Dataset;
  visibility: Visibility;
  /** @deprecated Field is deprecated! */
  workspace: Workspace;
};

export type Dataset_addAttributesArgs = {
  keyValues: Array<StringKeyValueInput>;
};

export type Dataset_addTagsArgs = {
  tags: Array<Scalars['String']['input']>;
};

export type Dataset_changeDescriptionArgs = {
  description: Scalars['String']['input'];
};

export type Dataset_datasetVersionsArgs = {
  query?: InputMaybe<DatasetVersionsQuery>;
};

export type Dataset_deleteAttributesArgs = {
  keys: Array<Scalars['String']['input']>;
};

export type Dataset_deleteTagsArgs = {
  tags: Array<Scalars['String']['input']>;
};

export type Dataset_ownerV2CandidatesArgs = {
  organizationId: Scalars['ID']['input'];
};

export type Dataset_updateAttributeArgs = {
  keyValue: StringKeyValueInput;
};

export type Dataset_updateOwnerArgs = {
  input: OwnerInput;
};

export type Dataset_updatePermissionArgs = {
  permission: PermissionInput;
};

export enum DatasetInputType {
  TEXT = 'TEXT',
  UNKNOWN = 'UNKNOWN',
  URL = 'URL',
}

export type DatasetOrError = Dataset | Error;

export type DatasetVersion = {
  __typename: 'DatasetVersion';
  addTags: DatasetVersion;
  attributes: Array<KeyValue>;
  blobInfo?: Maybe<Scalars['String']['output']>;
  changeDescription: DatasetVersion;
  dataset: Dataset;
  datasetID: Scalars['ID']['output'];
  dateCreated: Scalars['Timestamp']['output'];
  dateUpdated: Scalars['Timestamp']['output'];
  delete: Scalars['Boolean']['output'];
  deleteTags: DatasetVersion;
  description: Scalars['String']['output'];
  downloadUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  owner: User;
  parentID: Scalars['ID']['output'];
  pathInfo?: Maybe<Scalars['String']['output']>;
  queryInfo?: Maybe<Scalars['String']['output']>;
  rawInfo?: Maybe<Scalars['String']['output']>;
  runs: ExperimentRuns;
  tags: Array<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
};

export type DatasetVersion_addTagsArgs = {
  tags: Array<Scalars['String']['input']>;
};

export type DatasetVersion_changeDescriptionArgs = {
  description: Scalars['String']['input'];
};

export type DatasetVersion_deleteTagsArgs = {
  tags: Array<Scalars['String']['input']>;
};

export type DatasetVersion_downloadUrlArgs = {
  blobPath: Scalars['String']['input'];
};

export type DatasetVersion_runsArgs = {
  query?: InputMaybe<ExperimentRunsQuery>;
};

export type DatasetVersionOrError = DatasetVersion | Error;

export type DatasetVersions = {
  __typename: 'DatasetVersions';
  datasetVersions: Array<DatasetVersion>;
  next?: Maybe<Scalars['String']['output']>;
  pagination: PaginationResponse;
  total: Scalars['Int']['output'];
};

export type DatasetVersionsQuery = {
  floatPredicates?: InputMaybe<Array<FloatPredicate>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<PaginationQuery>;
  sorting?: InputMaybe<SortingQuery>;
  stringPredicates?: InputMaybe<Array<StringPredicate>>;
};

export type Datasets = {
  __typename: 'Datasets';
  datasets: Array<Dataset>;
  next?: Maybe<Scalars['String']['output']>;
  pagination: PaginationResponse;
  total: Scalars['Int']['output'];
};

export type DatasetsQuery = {
  floatPredicates?: InputMaybe<Array<FloatPredicate>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<PaginationQuery>;
  sorting?: InputMaybe<SortingQuery>;
  stringPredicates?: InputMaybe<Array<StringPredicate>>;
};

export type DeleteLabelInput = {
  llmLabelId: Scalars['ID']['input'];
  outputDatasetId: Scalars['ID']['input'];
  targetId: Scalars['ID']['input'];
};

export type Deployment = {
  __typename: 'Deployment';
  build: Build;
  endpoint: Endpoint;
  environment: EndpointEnvironment;
};

export enum DistillationAccelerator {
  A100 = 'A100',
  V100 = 'V100',
}

export type DistillationJob = {
  __typename: 'DistillationJob';
  accelerator: DistillationAccelerator;
  datasetSource: DistillationJobDatasetSource;
  distilledModelName: Scalars['String']['output'];
  distilledModelVersionId: Scalars['ID']['output'];
  fineTuningStudentModelId: Scalars['ID']['output'];
  fineTuningTeacherModelId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  inputFeature: Scalars['String']['output'];
  status: DistillationJobStatus;
  targetFeature?: Maybe<Scalars['String']['output']>;
  task: DistillationTask;
};

export type DistillationJobDatasetSource = {
  __typename: 'DistillationJobDatasetSource';
  gcs?: Maybe<DistillationJobDatasetSourceGcs>;
  inferenceJob?: Maybe<DistillationJobDatasetSourceInferenceJob>;
};

export type DistillationJobDatasetSourceExperiment = {
  __typename: 'DistillationJobDatasetSourceExperiment';
  experimentId: Scalars['ID']['output'];
};

export type DistillationJobDatasetSourceGcs = {
  __typename: 'DistillationJobDatasetSourceGcs';
  bucket: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type DistillationJobDatasetSourceInferenceJob = {
  __typename: 'DistillationJobDatasetSourceInferenceJob';
  inferenceJobId: Scalars['ID']['output'];
};

export type DistillationJobOrError = DistillationJob | Error;

export type DistillationJobStatus = {
  __typename: 'DistillationJobStatus';
  details?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  status: DistillationStatus;
};

export enum DistillationStatus {
  CREATING = 'CREATING',
  ERROR = 'ERROR',
  FINISHED = 'FINISHED',
  GENERATING = 'GENERATING',
  INITIALIZING = 'INITIALIZING',
  RUNNING = 'RUNNING',
}

export enum DistillationTask {
  CAUSAL = 'CAUSAL',
  SEQ2SEQ = 'SEQ2SEQ',
}

export type DockerMetadata = {
  __typename: 'DockerMetadata';
  healthPath: Scalars['String']['output'];
  requestPath: Scalars['String']['output'];
  requestPort: Scalars['Int']['output'];
};

export type DockerMetadataInput = {
  healthPath: Scalars['String']['input'];
  requestPath: Scalars['String']['input'];
  requestPort: Scalars['Int']['input'];
};

export type DriftAlertSettingsInput = {
  driftMetricType: MonitoringDriftMetricType;
  ioDescription: MonitoringIODescriptionInput;
};

export type DriftMetricAlertSettings = {
  __typename: 'DriftMetricAlertSettings';
  driftMetricType: MonitoringDriftMetricType;
};

export enum Editable {
  EDITABLE = 'EDITABLE',
  NOT_EDITABLE = 'NOT_EDITABLE',
  PARTIAL_EDITABLE = 'PARTIAL_EDITABLE',
  UNKNOWN = 'UNKNOWN',
}

export type Endpoint = {
  __typename: 'Endpoint';
  addLabels: Endpoint;
  allowedActions: AllowedActions;
  changeDescription: Endpoint;
  changePath: Endpoint;
  createEnvironment: EndpointEnvironment;
  customPermission?: Maybe<Permissions>;
  dateCreated: Scalars['Timestamp']['output'];
  dateUpdated: Scalars['Timestamp']['output'];
  delete: Scalars['Boolean']['output'];
  deleteLabels: Endpoint;
  description: Scalars['String']['output'];
  environment?: Maybe<EndpointEnvironment>;
  environments: Array<EndpointEnvironment>;
  fullUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  labels: Array<Scalars['String']['output']>;
  monitoredEntities: MonitoredEntities;
  owner: UserOrGroup;
  ownerCandidates: OwnerCandidates;
  ownerV2Candidates: OwnerV2Candidates;
  path: Scalars['String']['output'];
  updateOwner: Endpoint;
  updatePermission: Endpoint;
  visibility: Visibility;
  /** @deprecated Field is deprecated! */
  workspace: Workspace;
};

export type Endpoint_addLabelsArgs = {
  labels: Array<Scalars['String']['input']>;
};

export type Endpoint_changeDescriptionArgs = {
  description: Scalars['String']['input'];
};

export type Endpoint_changePathArgs = {
  path: Scalars['String']['input'];
};

export type Endpoint_createEnvironmentArgs = {
  autocreateToken?: InputMaybe<Scalars['Boolean']['input']>;
  enableAccessTokenPrediction?: InputMaybe<Scalars['Boolean']['input']>;
  enableAuthzPrediction?: InputMaybe<Scalars['Boolean']['input']>;
  kafkaReq?: InputMaybe<KafkaConfigUpdateRequestInput>;
  name: Scalars['String']['input'];
};

export type Endpoint_deleteLabelsArgs = {
  labels: Array<Scalars['String']['input']>;
};

export type Endpoint_environmentArgs = {
  id: Scalars['ID']['input'];
};

export type Endpoint_monitoredEntitiesArgs = {
  next?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<MonitoredEntityQuery>;
};

export type Endpoint_ownerV2CandidatesArgs = {
  organizationId: Scalars['ID']['input'];
};

export type Endpoint_updateOwnerArgs = {
  input: OwnerInput;
};

export type Endpoint_updatePermissionArgs = {
  permission: PermissionInput;
};

export type EndpointEnvironment = {
  __typename: 'EndpointEnvironment';
  authzPredictionEnabled: Scalars['Boolean']['output'];
  autoscalingMetrics: Array<AutoscalingMetric>;
  canaryRules: Array<CanaryRule>;
  components: Array<EndpointEnvironmentComponent>;
  createBuild: Build;
  createToken: EndpointEnvironment;
  dateCreated: Scalars['Timestamp']['output'];
  dateUpdated: Scalars['Timestamp']['output'];
  deleteToken: EndpointEnvironment;
  enableAuthzPrediction?: Maybe<EndpointEnvironment>;
  enableTokens?: Maybe<EndpointEnvironment>;
  id: Scalars['ID']['output'];
  kafka?: Maybe<KafkaConfig>;
  monitoring: Array<EndpointEnvironmentMonitoringComponent>;
  name: Scalars['String']['output'];
  rolloutStatus?: Maybe<CanaryRolloutStatus>;
  runtimeLogs?: Maybe<EndpointEnvironmentLogs>;
  status: EndpointEnvironmentStatus;
  token: EndpointEnvironmentToken;
  tokens: Array<EndpointEnvironmentToken>;
  tokensEnabled: Scalars['Boolean']['output'];
  update: EndpointEnvironment;
  updateKafka: EndpointEnvironment;
  updateRequest?: Maybe<EndpointEnvironmentUpdateRequest>;
};

export type EndpointEnvironment_createBuildArgs = {
  compatibility?: InputMaybe<BuildHardwareCompatibilityInput>;
  config?: InputMaybe<EndpointEnvironmentUpdateConfig>;
  modelVersion?: InputMaybe<Scalars['ID']['input']>;
  runId?: InputMaybe<Scalars['ID']['input']>;
};

export type EndpointEnvironment_createTokenArgs = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type EndpointEnvironment_deleteTokenArgs = {
  tokenId: Scalars['ID']['input'];
};

export type EndpointEnvironment_enableAuthzPredictionArgs = {
  enable: Scalars['Boolean']['input'];
};

export type EndpointEnvironment_enableTokensArgs = {
  enable: Scalars['Boolean']['input'];
};

export type EndpointEnvironment_monitoringArgs = {
  end?: InputMaybe<Scalars['Int']['input']>;
  interval?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
  step?: InputMaybe<Scalars['Int']['input']>;
};

export type EndpointEnvironment_rolloutStatusArgs = {
  end?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
  step?: InputMaybe<Scalars['Int']['input']>;
};

export type EndpointEnvironment_runtimeLogsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  buildId?: InputMaybe<Scalars['ID']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
};

export type EndpointEnvironment_tokenArgs = {
  tokenId: Scalars['ID']['input'];
};

export type EndpointEnvironment_updateArgs = {
  buildId?: InputMaybe<Scalars['ID']['input']>;
  config?: InputMaybe<EndpointEnvironmentUpdateConfig>;
};

export type EndpointEnvironment_updateKafkaArgs = {
  req: KafkaConfigUpdateRequestInput;
};

export type EndpointEnvironmentComponent = {
  __typename: 'EndpointEnvironmentComponent';
  build: BuildOrError;
  buildID: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  ratio: Scalars['Float']['output'];
  status: EndpointEnvironmentComponentStatus;
};

export enum EndpointEnvironmentComponentStatus {
  CREATING = 'CREATING',
  ERROR = 'ERROR',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  RUNNING = 'RUNNING',
}

export type EndpointEnvironmentEnvInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type EndpointEnvironmentEnvStatus = {
  __typename: 'EndpointEnvironmentEnvStatus';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum EndpointEnvironmentIsolation {
  ISOLATED = 'ISOLATED',
  SHARED = 'SHARED',
}

export type EndpointEnvironmentLogEntry = {
  __typename: 'EndpointEnvironmentLogEntry';
  build: BuildOrError;
  buildId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  stream: Scalars['String']['output'];
  timestamp: Scalars['Timestamp']['output'];
};

export type EndpointEnvironmentLogs = {
  __typename: 'EndpointEnvironmentLogs';
  before?: Maybe<Scalars['String']['output']>;
  entries: Array<EndpointEnvironmentLogEntry>;
};

export type EndpointEnvironmentMonitoringComponent = {
  __typename: 'EndpointEnvironmentMonitoringComponent';
  build: BuildOrError;
  buildID: Scalars['ID']['output'];
  latency: EndpointEnvironmentMonitoringComponentLatency;
  throughput: Array<EndpointEnvironmentMonitoringComponentThroughput>;
  time: Array<Scalars['Int']['output']>;
};

export type EndpointEnvironmentMonitoringComponentLatency = {
  __typename: 'EndpointEnvironmentMonitoringComponentLatency';
  average: Array<Scalars['Float']['output']>;
  p90: Array<Scalars['Float']['output']>;
  p99: Array<Scalars['Float']['output']>;
};

export type EndpointEnvironmentMonitoringComponentThroughput = {
  __typename: 'EndpointEnvironmentMonitoringComponentThroughput';
  code: Scalars['Int']['output'];
  value: Array<Scalars['Float']['output']>;
};

export type EndpointEnvironmentResourceStatus = {
  __typename: 'EndpointEnvironmentResourceStatus';
  cpuMillis?: Maybe<Scalars['Int']['output']>;
  memory?: Maybe<Scalars['String']['output']>;
  nvidiaGpu?: Maybe<ConfigResourcesNvidiaGpu>;
};

export enum EndpointEnvironmentStatus {
  ACTIVE = 'ACTIVE',
  CREATING = 'CREATING',
  ERROR = 'ERROR',
  INACTIVE = 'INACTIVE',
  UNKNOWN = 'UNKNOWN',
  UPDATING = 'UPDATING',
}

export type EndpointEnvironmentToken = {
  __typename: 'EndpointEnvironmentToken';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type EndpointEnvironmentUpdateConfig = {
  autoscaling?: InputMaybe<AutoscalingUpdateConfig>;
  canary?: InputMaybe<CanaryStrategy>;
  env?: InputMaybe<Array<EndpointEnvironmentEnvInput>>;
  isolation?: InputMaybe<EndpointEnvironmentIsolation>;
  resources?: InputMaybe<ResourcesUpdateConfig>;
  strategy?: InputMaybe<EndpointEnvironmentUpdateStrategy>;
};

export type EndpointEnvironmentUpdateRequest = {
  __typename: 'EndpointEnvironmentUpdateRequest';
  autoscaling?: Maybe<AutoscalingStatus>;
  build: BuildOrError;
  buildId: Scalars['ID']['output'];
  canaryStrategy?: Maybe<CanaryStrategyStatus>;
  env?: Maybe<Array<EndpointEnvironmentEnvStatus>>;
  isolation?: Maybe<EndpointEnvironmentIsolation>;
  resources?: Maybe<EndpointEnvironmentResourceStatus>;
};

export enum EndpointEnvironmentUpdateStrategy {
  CANARY = 'CANARY',
  ROLLOUT = 'ROLLOUT',
}

export type EndpointOrError = Endpoint | Error;

export type EndpointQuery = {
  exactName?: InputMaybe<Array<Scalars['String']['input']>>;
  fuzzyNames?: InputMaybe<Array<Scalars['String']['input']>>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Endpoints = {
  __typename: 'Endpoints';
  endpoints: Array<Endpoint>;
  pagination: EndpointsPagination;
};

export type EndpointsPagination = {
  __typename: 'EndpointsPagination';
  totalRecords: Scalars['Int']['output'];
};

export enum Entity {
  ENDPOINT = 'ENDPOINT',
  MONITORING = 'MONITORING',
  PROJECT = 'PROJECT',
  REGISTRY = 'REGISTRY',
  UNKNOWN = 'UNKNOWN',
}

export type Error = {
  __typename: 'Error';
  code: ErrorCode;
  message: Scalars['String']['output'];
};

export enum ErrorCode {
  Forbidden = 'Forbidden',
  NotFound = 'NotFound',
}

export type EvaluationStats = {
  __typename: 'EvaluationStats';
  statsByLabel: Array<LabelStatsKey>;
  totalLabeledRecords?: Maybe<Scalars['Int']['output']>;
};

export type Experiment = {
  __typename: 'Experiment';
  addTags: Experiment;
  artifacts: Array<Artifact>;
  attributes: Array<KeyValue>;
  changeDescription: Experiment;
  createExperimentRun: ExperimentRun;
  dateCreated: Scalars['Timestamp']['output'];
  dateUpdated: Scalars['Timestamp']['output'];
  delete: Scalars['Boolean']['output'];
  deleteTags: Experiment;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: User;
  project: Project;
  projectId: Scalars['String']['output'];
  runs: ExperimentRuns;
  tags: Array<Scalars['String']['output']>;
};

export type Experiment_addTagsArgs = {
  tags: Array<Scalars['String']['input']>;
};

export type Experiment_changeDescriptionArgs = {
  description: Scalars['String']['input'];
};

export type Experiment_createExperimentRunArgs = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tags: Array<Scalars['String']['input']>;
};

export type Experiment_deleteTagsArgs = {
  tags: Array<Scalars['String']['input']>;
};

export type Experiment_runsArgs = {
  next?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<ExperimentRunsQuery>;
};

export type ExperimentOrError = Error | Experiment;

export type ExperimentRun = {
  __typename: 'ExperimentRun';
  addAttributes: ExperimentRun;
  addTags: ExperimentRun;
  artifacts: Array<Artifact>;
  attributes: Array<KeyValue>;
  codeVersions: Array<CodeVersionBlob>;
  datasets: Array<Artifact>;
  dateCreated: Scalars['Timestamp']['output'];
  dateUpdated: Scalars['Timestamp']['output'];
  delete: Scalars['Boolean']['output'];
  deleteArtifact: ExperimentRun;
  deleteAttributes: ExperimentRun;
  deleteTags: ExperimentRun;
  deployments: Array<Deployment>;
  description: Scalars['String']['output'];
  environment?: Maybe<Scalars['String']['output']>;
  experiment: Experiment;
  experimentId: Scalars['String']['output'];
  features: Array<Feature>;
  hyperparameters: Array<PrimitiveKeyValue>;
  id: Scalars['ID']['output'];
  jobId?: Maybe<Scalars['String']['output']>;
  metrics: Array<PrimitiveKeyValue>;
  name: Scalars['String']['output'];
  observations: Array<Observation>;
  owner: User;
  project: Project;
  projectId: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  updateDescription: ExperimentRun;
  versionedInputs?: Maybe<VersioningEntry>;
};

export type ExperimentRun_addAttributesArgs = {
  attributes?: InputMaybe<Array<StringKeyValueInput>>;
};

export type ExperimentRun_addTagsArgs = {
  tags: Array<Scalars['String']['input']>;
};

export type ExperimentRun_deleteArtifactArgs = {
  key: Scalars['String']['input'];
};

export type ExperimentRun_deleteAttributesArgs = {
  attributes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ExperimentRun_deleteTagsArgs = {
  tags: Array<Scalars['String']['input']>;
};

export type ExperimentRun_updateDescriptionArgs = {
  description: Scalars['String']['input'];
};

export type ExperimentRunOrError = Error | ExperimentRun;

export type ExperimentRuns = {
  __typename: 'ExperimentRuns';
  next?: Maybe<Scalars['String']['output']>;
  pagination: PaginationResponse;
  runs: Array<ExperimentRun>;
};

export type ExperimentRunsQuery = {
  floatPredicates?: InputMaybe<Array<FloatPredicate>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<PaginationQuery>;
  sorting?: InputMaybe<SortingQuery>;
  stringPredicates?: InputMaybe<Array<StringPredicate>>;
};

export type Experiments = {
  __typename: 'Experiments';
  experiments: Array<Experiment>;
  next?: Maybe<Scalars['String']['output']>;
  pagination: PaginationResponse;
};

export type ExperimentsQuery = {
  floatPredicates?: InputMaybe<Array<FloatPredicate>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<PaginationQuery>;
  sorting?: InputMaybe<SortingQuery>;
  stringPredicates?: InputMaybe<Array<StringPredicate>>;
};

export type ExternalDeployment = {
  __typename: 'ExternalDeployment';
  cloudProvider?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locationDeployed?: Maybe<Scalars['String']['output']>;
  locationType: LocationType;
  registeredModelVersion: RegisteredModelVersion;
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  urlPathTitle: Scalars['String']['output'];
};

export type ExternalDeploymentInput = {
  cloudProvider?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locationDeployed?: InputMaybe<Scalars['String']['input']>;
  locationType?: InputMaybe<LocationType>;
  timeCreated?: InputMaybe<Scalars['Timestamp']['input']>;
  timeUpdated?: InputMaybe<Scalars['Timestamp']['input']>;
  urlPathTitle?: InputMaybe<Scalars['String']['input']>;
};

export type Feature = {
  __typename: 'Feature';
  name?: Maybe<Scalars['String']['output']>;
};

export type FeatureFlags = {
  __typename: 'FeatureFlags';
  createProjectViaUi: Scalars['Boolean']['output'];
  createUserToken: Scalars['String']['output'];
  defaultRegistryLockedLevel: Scalars['Boolean']['output'];
  enableAdvancedDeployment: Scalars['Boolean']['output'];
  enableAttributesVisualization: Scalars['Boolean']['output'];
  enableAuth0Login: Scalars['Boolean']['output'];
  enableBaselinePermissions: Scalars['Boolean']['output'];
  enableCatalogWidget: Scalars['Boolean']['output'];
  enableChatWidget: Scalars['Boolean']['output'];
  enableCrossRunDashboard: Scalars['Boolean']['output'];
  enableCustomCharts: Scalars['Boolean']['output'];
  enableCustomContainer: Scalars['Boolean']['output'];
  enableCustomUsers: Scalars['Boolean']['output'];
  enableDarkModeControl: Scalars['Boolean']['output'];
  enableDashboard: Scalars['Boolean']['output'];
  enableDatasets: Scalars['Boolean']['output'];
  enableDemoSchemas: Scalars['Boolean']['output'];
  enableDeployment: Scalars['Boolean']['output'];
  enableEndpoints: Scalars['Boolean']['output'];
  enableErrorReportingNetwork: Scalars['Boolean']['output'];
  enableErrorReportingObfuscating: Scalars['Boolean']['output'];
  enableErrorReportingPlayground: Scalars['Boolean']['output'];
  enableErrorReportingSupportChannel: Scalars['Boolean']['output'];
  enableExpandedEndpointPermissions: Scalars['Boolean']['output'];
  enableExplainabilityDemo: Scalars['Boolean']['output'];
  enableExternalAuth: Scalars['Boolean']['output'];
  enableExternalSecurityScanning: Scalars['Boolean']['output'];
  enableFreeTier: Scalars['Boolean']['output'];
  enableHomepage: Scalars['Boolean']['output'];
  enableImageScanning: Scalars['Boolean']['output'];
  enableIncompleteArtifact: Scalars['Boolean']['output'];
  enableKafka: Scalars['Boolean']['output'];
  enableLdapLogin: Scalars['Boolean']['output'];
  enableMachineConfigGpu: Scalars['Boolean']['output'];
  enableModelVersionStages: Scalars['Boolean']['output'];
  enableMonitoredEntity: Scalars['Boolean']['output'];
  enableMonitoring: Scalars['Boolean']['output'];
  enableMonitoringAlerts: Scalars['Boolean']['output'];
  enableMonitoringCompare: Scalars['Boolean']['output'];
  enableMonitoringWidget: Scalars['Boolean']['output'];
  enableNewMonitoringUx: Scalars['Boolean']['output'];
  enableNewPermissions: Scalars['Boolean']['output'];
  enableNewSearchUx: Scalars['Boolean']['output'];
  enableNotificationChannel: Scalars['Boolean']['output'];
  enableOrganizations: Scalars['Boolean']['output'];
  enableOverview: Scalars['Boolean']['output'];
  enableProjects: Scalars['Boolean']['output'];
  enablePromotion: Scalars['Boolean']['output'];
  enablePromotionApprovalRequired: Scalars['Boolean']['output'];
  enableRegisteredModel: Scalars['Boolean']['output'];
  enableRegistry: Scalars['Boolean']['output'];
  enableRegistryLock: Scalars['Boolean']['output'];
  enableRepositories: Scalars['Boolean']['output'];
  enableSamlLogin: Scalars['Boolean']['output'];
  enableServiceAccounts: Scalars['Boolean']['output'];
  enableSystemAdmins: Scalars['Boolean']['output'];
  enableWorkflows: Scalars['Boolean']['output'];
  modelDeploymentBasePath: Scalars['String']['output'];
  monitoringChosenPrecision: Scalars['Int']['output'];
  muiDatatableLicense: Scalars['String']['output'];
  pypiDisableConfigNameMarker: Scalars['String']['output'];
  segmentWriteKey: Scalars['String']['output'];
  sentryEnabled: Scalars['Boolean']['output'];
};

export enum FilterableKeyType {
  NAME = 'NAME',
  TEMPLATE_CONTENTS = 'TEMPLATE_CONTENTS',
  UNKNOWN = 'UNKNOWN',
  UNUSED_ONLY = 'UNUSED_ONLY',
}

export type FineTuningJob = {
  __typename: 'FineTuningJob';
  baseModelVersionId: Scalars['ID']['output'];
  destinationModelVersionId: Scalars['ID']['output'];
  evalDatasetVersionId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  loraConfigParameters: FineTuningJobLoraConfigParameters;
  runId: Scalars['String']['output'];
  status: FineTuningJobState;
  testDatasetVersionId?: Maybe<Scalars['ID']['output']>;
  trainDatasetVersionId: Scalars['ID']['output'];
};

export type FineTuningJobLoraConfigParameters = {
  __typename: 'FineTuningJobLoraConfigParameters';
  alpha?: Maybe<Scalars['Int']['output']>;
  dropout?: Maybe<Scalars['Float']['output']>;
  r?: Maybe<Scalars['Int']['output']>;
};

export type FineTuningJobOrError = Error | FineTuningJob;

export type FineTuningJobState = {
  __typename: 'FineTuningJobState';
  message: Scalars['String']['output'];
  status: FineTuningJobStatus;
};

export enum FineTuningJobStatus {
  ERROR = 'ERROR',
  FINISHED = 'FINISHED',
  INITIALIZING = 'INITIALIZING',
  RUNNING = 'RUNNING',
}

export type FloatKeyValue = {
  __typename: 'FloatKeyValue';
  key: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type FloatPredicate = {
  key: Scalars['String']['input'];
  operator: PredicateOperator;
  value: Scalars['Float']['input'];
};

export enum GcloudApplicationStatus {
  DELETED = 'DELETED',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
}

export type GcloudDeployment = {
  __typename: 'GcloudDeployment';
  applicationStatus: GcloudDeploymentApplicationStatus;
  application_url?: Maybe<Scalars['String']['output']>;
  deploymentStatusMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  model?: Maybe<LlmModel>;
  modelId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
  prompt: LlmPrompt;
  promptId: Scalars['String']['output'];
  shortUrl: Scalars['String']['output'];
  status: GcloudDeploymentStatus;
  title: Scalars['String']['output'];
  useCaseId: Scalars['String']['output'];
};

export type GcloudDeploymentApplicationStatus = {
  __typename: 'GcloudDeploymentApplicationStatus';
  body: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status: GcloudApplicationStatus;
};

export type GcloudDeploymentOrError = Error | GcloudDeployment;

export enum GcloudDeploymentStatus {
  CREATING = 'CREATING',
  DEPLOYED = 'DEPLOYED',
  ERROR = 'ERROR',
  INITIALIZING = 'INITIALIZING',
  RUNNING = 'RUNNING',
}

export enum GenAiProjectType {
  DISTILLATION = 'DISTILLATION',
  EXTERNALLY_HOSTED = 'EXTERNALLY_HOSTED',
  GEN_AI_PROJECT_TYPE_UNSPECIFIED = 'GEN_AI_PROJECT_TYPE_UNSPECIFIED',
  VERTA_HOSTED = 'VERTA_HOSTED',
}

export type GetExperimentResponse = {
  __typename: 'GetExperimentResponse';
  evaluationStats: EvaluationStats;
  experiment: LlmExperiment;
  status: LLMExperimentStatus;
};

export type GetExperimentResponseOrError = Error | GetExperimentResponse;

export type GetScoredAppConfigurationInput = {
  modelId?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  promptId?: InputMaybe<Scalars['ID']['input']>;
};

export type Group = {
  __typename: 'Group';
  allowedActions: AllowedActions;
  builtIn: Scalars['Boolean']['output'];
  delete: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  members: Array<UserOrServiceAccountV2>;
  name: Scalars['String']['output'];
  organization: OrganizationV2;
  update: Group;
};

export type Group_updateArgs = {
  input: GroupInput;
};

export type GroupInput = {
  description: Scalars['String']['input'];
  memberIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type GroupOrError = Error | Group;

export type HomePage = {
  __typename: 'HomePage';
  activities: Array<HomePageActivity>;
  id: Scalars['ID']['output'];
  videos: Array<HomePageVideos>;
};

export type HomePageActivity = {
  __typename: 'HomePageActivity';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Entity;
  updated: Scalars['Timestamp']['output'];
  updatedBy: Scalars['String']['output'];
  workspaceName: Scalars['String']['output'];
};

export type HomePageVideos = {
  __typename: 'HomePageVideos';
  description: Scalars['String']['output'];
  header: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type InferenceJob = {
  __typename: 'InferenceJob';
  experimentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  status: InferenceJobStatus;
};

export type InferenceJobOrError = Error | InferenceJob;

export type InferenceJobStatus = {
  __typename: 'InferenceJobStatus';
  message: Scalars['String']['output'];
  status: InferenceStatus;
};

export enum InferenceStatus {
  CREATING = 'CREATING',
  ERROR = 'ERROR',
  FINISHED = 'FINISHED',
  INITIALIZING = 'INITIALIZING',
  RUNNING = 'RUNNING',
}

export type Job = {
  __typename: 'Job';
  completionTimestamp: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  jobDetailsJson: Scalars['String']['output'];
  jobType: JobType;
  latestUpdateTimestamp: Scalars['Timestamp']['output'];
  orgId: Scalars['ID']['output'];
  owner: UserOrGroup;
  resultJson: Scalars['String']['output'];
  startTimestamp: Scalars['Timestamp']['output'];
  update: JobOrError;
  userId: Scalars['ID']['output'];
  workspaceId: Scalars['ID']['output'];
};

export type Job_updateArgs = {
  input?: InputMaybe<UpdateJobInput>;
};

export type JobOrError = Error | Job;

export enum JobType {
  IMPORT_MLFLOW = 'IMPORT_MLFLOW',
  UNKNOWN = 'UNKNOWN',
}

export type JsonKeyValue = {
  __typename: 'JsonKeyValue';
  content: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export type KafkaConfig = {
  __typename: 'KafkaConfig';
  config?: Maybe<KafkaConfiguration>;
  configId?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<KafkaConfigStatus>;
  updateRequest?: Maybe<KafkaConfigUpdateRequest>;
};

export enum KafkaConfigStatus {
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
  INACTIVE = 'INACTIVE',
  UPDATING = 'UPDATING',
}

export type KafkaConfigUpdateRequest = {
  __typename: 'KafkaConfigUpdateRequest';
  disabled?: Maybe<Scalars['Boolean']['output']>;
  errorTopic: Scalars['String']['output'];
  inputTopic: Scalars['String']['output'];
  outputTopic: Scalars['String']['output'];
  reprocessFromIndex?: Maybe<Scalars['Int']['output']>;
  reprocessFromScratch?: Maybe<Scalars['Boolean']['output']>;
  reprocessFromTimestamp?: Maybe<Scalars['Date']['output']>;
};

export type KafkaConfigUpdateRequestInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  errorTopic: Scalars['String']['input'];
  inputTopic: Scalars['String']['input'];
  kafkaConfigId: Scalars['ID']['input'];
  outputTopic: Scalars['String']['input'];
  reprocessFromIndex?: InputMaybe<Scalars['Int']['input']>;
  reprocessFromScratch?: InputMaybe<Scalars['Boolean']['input']>;
  reprocessFromTimestamp?: InputMaybe<Scalars['Date']['input']>;
};

export type KafkaConfiguration = {
  __typename: 'KafkaConfiguration';
  brokerAddresses: Scalars['String']['output'];
  delete: KafkaConfigurations;
  deployments: Array<Deployment>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  kafkaTopics: KafkaTopics;
  kerberos?: Maybe<KafkaConfigurationKerberos>;
  name: Scalars['String']['output'];
  tls?: Maybe<KafkaConfigurationTLS>;
  update: KafkaConfiguration;
};

export type KafkaConfiguration_updateArgs = {
  config: KafkaConfigurationInput;
};

export type KafkaConfigurationInput = {
  brokerAddresses: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  kerberos?: InputMaybe<KafkaConfigurationKerberosInput>;
  name: Scalars['String']['input'];
  tls?: InputMaybe<KafkaConfigurationTLSInput>;
};

export type KafkaConfigurationKerberos = {
  __typename: 'KafkaConfigurationKerberos';
  clientName: Scalars['String']['output'];
  conf: Scalars['String']['output'];
  keytab: Scalars['String']['output'];
  serviceName: Scalars['String']['output'];
};

export type KafkaConfigurationKerberosInput = {
  clientName: Scalars['String']['input'];
  conf: Scalars['String']['input'];
  keytab: Scalars['String']['input'];
  serviceName: Scalars['String']['input'];
};

export type KafkaConfigurationOrError = Error | KafkaConfiguration;

export type KafkaConfigurationTLS = {
  __typename: 'KafkaConfigurationTLS';
  certificate: Scalars['String']['output'];
};

export type KafkaConfigurationTLSInput = {
  certificate: Scalars['String']['input'];
};

export type KafkaConfigurations = {
  __typename: 'KafkaConfigurations';
  configurations: Array<KafkaConfiguration>;
  createKafkaConfiguration: KafkaConfigurations;
  id: Scalars['ID']['output'];
  test: Scalars['Boolean']['output'];
};

export type KafkaConfigurations_createKafkaConfigurationArgs = {
  config: KafkaConfigurationInput;
};

export type KafkaConfigurations_testArgs = {
  config: KafkaConfigurationInput;
};

export type KafkaTopic = {
  __typename: 'KafkaTopic';
  name: Scalars['String']['output'];
};

export type KafkaTopics = {
  __typename: 'KafkaTopics';
  topics: Array<KafkaTopic>;
};

export type KafkaTopicsList = {
  __typename: 'KafkaTopicsList';
  topics: Array<Scalars['String']['output']>;
};

export type KeyLocations = {
  __typename: 'KeyLocations';
  key: Scalars['String']['output'];
  locations: Array<Scalars['String']['output']>;
};

export type KeyValue =
  | BoolKeyValue
  | FloatKeyValue
  | JsonKeyValue
  | StringKeyValue;

export enum LLMExperimentStatus {
  COMPLETE = 'COMPLETE',
  COMPLETE_WITH_ERRORS = 'COMPLETE_WITH_ERRORS',
  CREATED = 'CREATED',
  RUNNING = 'RUNNING',
  STALLED = 'STALLED',
  UNKNOWN = 'UNKNOWN',
}

export type LLMFeedback = {
  __typename: 'LLMFeedback';
  createdBy: User;
  feedback: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  targetId: Scalars['ID']['output'];
  targetType: LlmLabelTargetType;
  timeCreated: Scalars['Timestamp']['output'];
};

export type LLMLabel = {
  __typename: 'LLMLabel';
  createdBy: User;
  creatorType: LlmLabelCreatorType;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  score: Scalars['Float']['output'];
  targetId: Scalars['ID']['output'];
  targetType: LlmLabelTargetType;
  timeCreated: Scalars['Timestamp']['output'];
};

export type LLMLabelInput = {
  label: Scalars['String']['input'];
};

export type LabelStats = {
  __typename: 'LabelStats';
  count: Scalars['Int']['output'];
  percentageApplied: Scalars['Float']['output'];
  totalRecords: Scalars['Int']['output'];
};

export type LabelStatsKey = {
  __typename: 'LabelStatsKey';
  label: Scalars['String']['output'];
  stats: LabelStats;
};

export type LlmAppConfiguration = {
  __typename: 'LlmAppConfiguration';
  catalogModelIdentifier?: Maybe<CatalogModelIdentifier>;
  id: Scalars['ID']['output'];
  modelId?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
  promptId: Scalars['ID']['output'];
};

export type LlmAppConfigurationOrError = Error | LlmAppConfiguration;

export type LlmAppConfigurationScores = {
  __typename: 'LlmAppConfigurationScores';
  appConfiguration: LlmAppConfiguration;
  appConfigurationId: Scalars['ID']['output'];
  catalogModelIdentifier?: Maybe<CatalogModelIdentifier>;
  model?: Maybe<LlmModel>;
  modelId?: Maybe<Scalars['ID']['output']>;
  prompt: LlmPrompt;
  promptId: Scalars['ID']['output'];
  registeredModel?: Maybe<RegisteredModel>;
  registeredModelVersion?: Maybe<RegisteredModelVersion>;
  scores: Array<LlmScore>;
  statsByLabel: Array<LabelStatsKey>;
  timeCreated: Scalars['Timestamp']['output'];
};

export type LlmAppConfigurationScoresOrError =
  | Error
  | LlmAppConfigurationScores;

export type LlmAppConfigurations = {
  __typename: 'LlmAppConfigurations';
  llmAppConfigurations: Array<LlmAppConfiguration>;
};

export type LlmAppConfigurationsOrError = Error | LlmAppConfigurations;

export type LlmDataset = {
  __typename: 'LlmDataset';
  appendRecords: LlmDataset;
  createdBy: User;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  records: LlmRecordsOrError;
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  updatedBy: User;
  workspaceId: Scalars['ID']['output'];
};

export type LlmDataset_appendRecordsArgs = {
  records: Array<LlmRecordInput>;
};

export type LlmDataset_recordsArgs = {
  query?: InputMaybe<LlmRecordsQuery>;
};

export type LlmDatasetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  records?: InputMaybe<Array<LlmRecordInput>>;
  workspaceId: Scalars['ID']['input'];
};

export type LlmDatasetOrError = Error | LlmDataset;

export type LlmDatasets = {
  __typename: 'LlmDatasets';
  LlmDatasets?: Maybe<Array<LlmDataset>>;
};

export type LlmDatasetsOrError = Error | LlmDatasets;

export type LlmEndpoint = {
  __typename: 'LlmEndpoint';
  accessTokens: Array<Scalars['String']['output']>;
  createAccessToken: LlmEndpoint;
  deleteAccessToken: LlmEndpoint;
  description?: Maybe<Scalars['String']['output']>;
  fullUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  model?: Maybe<LlmModel>;
  modelId: Scalars['String']['output'];
  path: Scalars['String']['output'];
  project: LlmProject;
  projectId: Scalars['ID']['output'];
  prompt: LlmPrompt;
  promptId: Scalars['String']['output'];
};

export type LlmEndpoint_createAccessTokenArgs = {
  accessToken: Scalars['String']['input'];
};

export type LlmEndpoint_deleteAccessTokenArgs = {
  accessToken: Scalars['String']['input'];
};

export type LlmEndpointOrError = Error | LlmEndpoint;

export type LlmEndpoints = {
  __typename: 'LlmEndpoints';
  endpoints: Array<LlmEndpoint>;
};

export type LlmEndpointsOrError = Error | LlmEndpoints;

export type LlmExperiment = {
  __typename: 'LlmExperiment';
  addOutputRecordLabel: GetExperimentResponseOrError;
  catalogModelIdentifier?: Maybe<CatalogModelIdentifier>;
  createdBy: User;
  dataset: LlmDataset;
  datasetId: Scalars['ID']['output'];
  deleteOutputRecordLabel: GetExperimentResponseOrError;
  experimentGroup?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  model?: Maybe<LlmModel>;
  modelId?: Maybe<Scalars['ID']['output']>;
  organizationId: Scalars['ID']['output'];
  outputDataset: LlmOutputDataset;
  projectId?: Maybe<Scalars['ID']['output']>;
  prompt: LlmPrompt;
  promptId: Scalars['ID']['output'];
  registeredModel?: Maybe<RegisteredModel>;
  registeredModelVersion?: Maybe<RegisteredModelVersion>;
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  updatedBy: User;
  workspaceId: Scalars['ID']['output'];
};

export type LlmExperiment_addOutputRecordLabelArgs = {
  input: AddFeedbackInput;
};

export type LlmExperiment_deleteOutputRecordLabelArgs = {
  input: DeleteLabelInput;
};

export type LlmExperimentInput = {
  catalogModelIdentifier?: InputMaybe<CatalogModelIdentifierInput>;
  datasetId: Scalars['ID']['input'];
  experimentGroup?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  modelId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  promptId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type LlmExperimentOrError = Error | LlmExperiment;

export type LlmExperiments = {
  __typename: 'LlmExperiments';
  LlmExperiments?: Maybe<Array<LlmExperiment>>;
};

export type LlmExperimentsOrError = Error | LlmExperiments;

export type LlmInput = {
  __typename: 'LlmInput';
  content: Scalars['String']['output'];
  createdBy: User;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  type: DatasetInputType;
  updatedBy: User;
};

export type LlmInputInput = {
  content: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  key: Scalars['String']['input'];
  type: DatasetInputType;
};

export enum LlmLabelCreatorType {
  HUMAN = 'HUMAN',
  MACHINE = 'MACHINE',
  UNKNOWN_CREATOR_TYPE = 'UNKNOWN_CREATOR_TYPE',
}

export enum LlmLabelTargetType {
  OUTPUT_DATASET = 'OUTPUT_DATASET',
  OUTPUT_DATASET_RECORD = 'OUTPUT_DATASET_RECORD',
  UNKNOWN_LABEL_TARGET = 'UNKNOWN_LABEL_TARGET',
}

export type LlmLeaderboard = {
  __typename: 'LlmLeaderboard';
  scoredConfigurations: Array<LlmAppConfigurationScores>;
};

export type LlmLeaderboardOrError = Error | LlmLeaderboard;

export type LlmModel = {
  __typename: 'LlmModel';
  contextSize?: Maybe<Scalars['String']['output']>;
  costInput?: Maybe<Scalars['String']['output']>;
  costOutput?: Maybe<Scalars['String']['output']>;
  createdBy: User;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locked?: Maybe<Scalars['Boolean']['output']>;
  modelMetrics?: Maybe<Array<PrimitiveKeyValue>>;
  name: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  rateLimits?: Maybe<Array<ModelRateLimit>>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  type?: Maybe<Scalars['String']['output']>;
  uiHidden?: Maybe<Scalars['Boolean']['output']>;
  updatedBy: User;
};

export type LlmModelInput = {
  contextSize?: InputMaybe<Scalars['String']['input']>;
  costInput?: InputMaybe<Scalars['String']['input']>;
  costOutput?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  provider?: InputMaybe<Scalars['String']['input']>;
  rateLimits?: InputMaybe<Array<ModelRateLimitInput>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type LlmModelMetricsDefinitions = {
  __typename: 'LlmModelMetricsDefinitions';
  metrics: Array<ModelMetricsDefinition>;
};

export type LlmModelOrError = Error | LlmModel;

export type LlmModels = {
  __typename: 'LlmModels';
  LlmModels?: Maybe<Array<LlmModel>>;
};

export type LlmModelsOrError = Error | LlmModels;

export type LlmOutputDataset = {
  __typename: 'LlmOutputDataset';
  addFeedback: LlmOutputDatasetOrError;
  appendRecords: LlmOutputDataset;
  createdBy: User;
  deleteLabel: LlmOutputDatasetOrError;
  id: Scalars['ID']['output'];
  labels: Array<Maybe<LLMLabel>>;
  llmExperimentId: Scalars['ID']['output'];
  llmInputDatasetId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
  records: LlmOutputRecordsOrError;
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  updatedBy: User;
  workspaceId: Scalars['ID']['output'];
};

export type LlmOutputDataset_addFeedbackArgs = {
  input: AddFeedbackInput;
};

export type LlmOutputDataset_appendRecordsArgs = {
  records: Array<LlmOutputRecordInput>;
};

export type LlmOutputDataset_deleteLabelArgs = {
  input: DeleteLabelInput;
};

export type LlmOutputDataset_recordsArgs = {
  query?: InputMaybe<LlmOutputRecordsQuery>;
};

export type LlmOutputDatasetInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  llmExperimentId: Scalars['ID']['input'];
  llmInputDatasetId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  outputRecords?: InputMaybe<Array<LlmOutputRecordInput>>;
  projectId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type LlmOutputDatasetOrError = Error | LlmOutputDataset;

export type LlmOutputDatasets = {
  __typename: 'LlmOutputDatasets';
  LlmOutputDatasets?: Maybe<Array<LlmOutputDataset>>;
};

export type LlmOutputDatasetsOrError = Error | LlmOutputDatasets;

export type LlmOutputRecord = {
  __typename: 'LlmOutputRecord';
  createdBy: User;
  errors?: Maybe<Scalars['String']['output']>;
  feedback: Array<LLMFeedback>;
  id: Scalars['ID']['output'];
  inputRecord: LlmRecord;
  labels: Array<LLMLabel>;
  llmInputRecordId: Scalars['ID']['output'];
  llmOutputDatasetId: Scalars['ID']['output'];
  output: Scalars['String']['output'];
  timeCreated: Scalars['Timestamp']['output'];
};

export type LlmOutputRecordInput = {
  errors?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  llmInputRecordId: Scalars['ID']['input'];
  llmOutputDatasetId: Scalars['ID']['input'];
  output: Scalars['String']['input'];
};

export type LlmOutputRecords = {
  __typename: 'LlmOutputRecords';
  Total: Scalars['Int']['output'];
  pagination: PaginationResponse;
  records: Array<LlmOutputRecord>;
};

export type LlmOutputRecordsOrError = Error | LlmOutputRecords;

export type LlmOutputRecordsQuery = {
  inputDatasetId?: InputMaybe<Scalars['ID']['input']>;
  pagination?: InputMaybe<PaginationQuery>;
  recordsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type LlmProject = {
  __typename: 'LlmProject';
  addOutputRecordLabel: LlmProjectOrError;
  createLlmEndpoint: LlmProjectOrError;
  createOrUpdateLlmDataset: LlmDatasetOrError;
  createOrUpdateLlmExperiments: Array<LlmExperiment>;
  createOrUpdateLlmOutputDataset: LlmProjectOrError;
  createOrUpdateLlmPrompt: LlmPromptOrError;
  createdBy: User;
  currentLeaderboard?: Maybe<LlmLeaderboard>;
  datasets?: Maybe<Array<LlmDataset>>;
  delete?: Maybe<Scalars['ID']['output']>;
  deleteLlmDataset?: Maybe<Scalars['ID']['output']>;
  deleteLlmEndpoint?: Maybe<LlmProjectOrError>;
  deleteLlmPrompt?: Maybe<Scalars['ID']['output']>;
  deleteOutputRecordLabel: LlmProjectOrError;
  description?: Maybe<Scalars['String']['output']>;
  endpoint: LlmEndpointOrError;
  endpoints: LlmEndpointsOrError;
  experiments?: Maybe<Array<LlmExperiment>>;
  gcloudDeployments: Array<Maybe<GcloudDeployment>>;
  id: Scalars['ID']['output'];
  labels: Array<Maybe<Scalars['String']['output']>>;
  llmDataset: LlmDatasetOrError;
  llmExperiment: GetExperimentResponseOrError;
  llmPrompt: LlmPromptOrError;
  llmRankableOutputPairs: LlmRankableOutputPairsOrError;
  models?: Maybe<Array<LlmModel>>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  outputDataset: LlmOutputDatasetOrError;
  outputDatasets: LlmOutputDatasetsOrError;
  projectType: GenAiProjectType;
  prompts: LlmPromptsOrError;
  registeredModelVersions?: Maybe<Array<RegisteredModelVersion>>;
  registeredModels?: Maybe<Array<RegisteredModel>>;
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  update: LlmProjectOrError;
  updateLlmEndpoint: LlmProjectOrError;
  updatedBy: User;
  useCase?: Maybe<LlmUseCase>;
  useCaseId?: Maybe<Scalars['ID']['output']>;
  workspaceId: Scalars['ID']['output'];
};

export type LlmProject_addOutputRecordLabelArgs = {
  input: AddFeedbackInput;
};

export type LlmProject_createLlmEndpointArgs = {
  input?: InputMaybe<CreateLlmEndpointInput>;
};

export type LlmProject_createOrUpdateLlmDatasetArgs = {
  input?: InputMaybe<LlmDatasetInput>;
};

export type LlmProject_createOrUpdateLlmExperimentsArgs = {
  inputs: Array<LlmExperimentInput>;
};

export type LlmProject_createOrUpdateLlmOutputDatasetArgs = {
  input?: InputMaybe<LlmOutputDatasetInput>;
};

export type LlmProject_createOrUpdateLlmPromptArgs = {
  input?: InputMaybe<LlmPromptInput>;
};

export type LlmProject_deleteLlmDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type LlmProject_deleteLlmEndpointArgs = {
  id: Scalars['ID']['input'];
};

export type LlmProject_deleteLlmPromptArgs = {
  id: Scalars['ID']['input'];
};

export type LlmProject_deleteOutputRecordLabelArgs = {
  input: DeleteLabelInput;
};

export type LlmProject_endpointArgs = {
  id: Scalars['ID']['input'];
};

export type LlmProject_llmDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type LlmProject_llmExperimentArgs = {
  id: Scalars['ID']['input'];
};

export type LlmProject_llmPromptArgs = {
  id: Scalars['ID']['input'];
};

export type LlmProject_llmRankableOutputPairsArgs = {
  query: LlmRankableOutputPairsQuery;
};

export type LlmProject_outputDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type LlmProject_outputDatasetsArgs = {
  experimentId: Scalars['ID']['input'];
};

export type LlmProject_promptsArgs = {
  input?: InputMaybe<LlmPromptQuery>;
  next?: InputMaybe<Scalars['String']['input']>;
};

export type LlmProject_updateArgs = {
  input?: InputMaybe<LlmProjectInput>;
};

export type LlmProject_updateLlmEndpointArgs = {
  input?: InputMaybe<UpdateLlmEndpointInput>;
};

export type LlmProjectInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  projectType: GenAiProjectType;
  useCaseId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type LlmProjectOrError = Error | LlmProject;

export type LlmProjects = {
  __typename: 'LlmProjects';
  LlmProjects?: Maybe<Array<LlmProject>>;
};

export type LlmProjectsOrError = Error | LlmProjects;

export type LlmPrompt = {
  __typename: 'LlmPrompt';
  createdBy: User;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  templates: Array<LlmTemplate>;
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  updatedBy: User;
  workspaceId: Scalars['ID']['output'];
};

export type LlmPromptFilter = {
  filterKey: FilterableKeyType;
  value: Scalars['String']['input'];
};

export type LlmPromptInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  templates: Array<LlmTemplateInput>;
  workspaceId: Scalars['ID']['input'];
};

export type LlmPromptOrError = Error | LlmPrompt;

export type LlmPromptQuery = {
  filters?: InputMaybe<Array<LlmPromptFilter>>;
  pagination?: InputMaybe<PaginationQuery>;
  sorting?: InputMaybe<LlmPromptSortingQuery>;
};

export type LlmPromptSortingQuery = {
  ascending?: InputMaybe<Scalars['Boolean']['input']>;
  key: SortableKeyType;
};

export type LlmPrompts = {
  __typename: 'LlmPrompts';
  LlmPrompts?: Maybe<Array<LlmPrompt>>;
  next?: Maybe<Scalars['String']['output']>;
  pagination: PaginationResponse;
};

export type LlmPromptsOrError = Error | LlmPrompts;

export type LlmRankableOutputPair = {
  __typename: 'LlmRankableOutputPair';
  config1Id: Scalars['ID']['output'];
  config2Id: Scalars['ID']['output'];
  inputRecord: LlmRecord;
  inputRecordId: Scalars['ID']['output'];
  outputRecord1: LlmOutputRecord;
  outputRecord1Id: Scalars['ID']['output'];
  outputRecord2: LlmOutputRecord;
  outputRecord2Id: Scalars['ID']['output'];
};

export type LlmRankableOutputPairs = {
  __typename: 'LlmRankableOutputPairs';
  pairs: Array<LlmRankableOutputPair>;
};

export type LlmRankableOutputPairsOrError = Error | LlmRankableOutputPairs;

export type LlmRankableOutputPairsQuery = {
  appConfigIds: Array<Scalars['ID']['input']>;
};

export type LlmRecord = {
  __typename: 'LlmRecord';
  createdBy: User;
  externalId: Scalars['ID']['output'];
  groundTruth: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inputs: Array<LlmInput>;
  metadata: Scalars['String']['output'];
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  updatedBy: User;
};

export type LlmRecordInput = {
  externalId: Scalars['ID']['input'];
  groundTruth?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inputs: Array<LlmInputInput>;
  metadata?: InputMaybe<Scalars['String']['input']>;
};

export type LlmRecords = {
  __typename: 'LlmRecords';
  Total: Scalars['Int']['output'];
  pagination: PaginationResponse;
  records: Array<LlmRecord>;
};

export type LlmRecordsOrError = Error | LlmRecords;

export type LlmRecordsQuery = {
  pagination?: InputMaybe<PaginationQuery>;
  recordsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type LlmScore = {
  __typename: 'LlmScore';
  key: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type LlmTemplate = {
  __typename: 'LlmTemplate';
  createdBy: User;
  id: Scalars['ID']['output'];
  promptId: Scalars['ID']['output'];
  template: Scalars['String']['output'];
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  type: TemplateType;
  updatedBy: User;
  variables: Array<Scalars['String']['output']>;
};

export type LlmTemplateInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  template: Scalars['String']['input'];
  type: TemplateType;
  variables?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LlmUseCase = {
  __typename: 'LlmUseCase';
  appImage: Scalars['String']['output'];
  datasetTemplates?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use examples instead */
  example?: Maybe<Scalars['String']['output']>;
  examplePrompts?: Maybe<Array<LlmPrompt>>;
  examples?: Maybe<Array<Scalars['String']['output']>>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isAvailable: Scalars['Boolean']['output'];
  labels?: Maybe<Array<Scalars['String']['output']>>;
  recommendedModelsIds?: Maybe<Array<Scalars['ID']['output']>>;
  title: Scalars['String']['output'];
};

export type LlmUseCaseOrError = Error | LlmUseCase;

export type LlmUseCases = {
  __typename: 'LlmUseCases';
  LlmUseCases?: Maybe<Array<LlmUseCase>>;
};

export type LlmUseCasesOrError = Error | LlmUseCases;

export enum LocationType {
  CLOUD_PROVIDER = 'CLOUD_PROVIDER',
  DEVICE = 'DEVICE',
  INTERNAL_PRIVATE_NETWORK = 'INTERNAL_PRIVATE_NETWORK',
  UNKNOWN = 'UNKNOWN',
}

export type MLFlowModelsQuery = {
  filter?: InputMaybe<Scalars['String']['input']>;
  maxResults?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<Scalars['String']['input']>>;
  pageToken?: InputMaybe<Scalars['String']['input']>;
};

export type MLFlowResponse = {
  __typename: 'MLFlowResponse';
  response?: Maybe<Scalars['String']['output']>;
};

export type MLFlowResponseOrError = Error | MLFlowResponse;

export type MLflowIntegration = {
  __typename: 'MLflowIntegration';
  models?: Maybe<MLFlowResponseOrError>;
};

export type MLflowIntegration_modelsArgs = {
  authToken: Scalars['String']['input'];
  host: Scalars['String']['input'];
  payload?: InputMaybe<MLFlowModelsQuery>;
};

export type MergeResult = {
  __typename: 'MergeResult';
  commit?: Maybe<Commit>;
  commonBase?: Maybe<Commit>;
  conflicts?: Maybe<Array<Scalars['String']['output']>>;
};

export type MetricAlertSettings = {
  __typename: 'MetricAlertSettings';
  metricType: MonitoringMetricType;
};

export type MetricAlertSettingsInput = {
  metricType: MonitoringMetricType;
  output: MonitoringIODescriptionInput;
};

export type ModelMetricsDefinition = {
  __typename: 'ModelMetricsDefinition';
  defaultValue?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  type: Scalars['String']['output'];
};

export type ModelRateLimit = {
  __typename: 'ModelRateLimit';
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type ModelRateLimitInput = {
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type MonitoredEntities = {
  __typename: 'MonitoredEntities';
  entities: Array<MonitoredEntity>;
  next?: Maybe<Scalars['String']['output']>;
  pagination: PaginationResponse;
};

export type MonitoredEntity = {
  __typename: 'MonitoredEntity';
  alerts: Alerts;
  allowedActions: AllowedActions;
  attributes: Array<StringKeyValue>;
  createAlert: Alert;
  createMonitoredModel: MonitoredModel;
  createdAt: Scalars['Date']['output'];
  customPermission?: Maybe<Permissions>;
  delete: Scalars['Boolean']['output'];
  deleteAttributes: Array<StringKeyValue>;
  endpoint?: Maybe<EndpointOrError>;
  id: Scalars['ID']['output'];
  metrics: MonitoringMetrics;
  monitoredModels?: Maybe<MonitoredModels>;
  name: Scalars['String']['output'];
  owner: UserOrGroup;
  ownerCandidates: OwnerCandidates;
  ownerV2Candidates: OwnerV2Candidates;
  schema: MonitoringSchema;
  setAttributes: Array<StringKeyValue>;
  updateOwner: MonitoredEntity;
  updatedAt: Scalars['Date']['output'];
  visibility: Visibility;
  /** @deprecated Field is deprecated! */
  workspace: Workspace;
};

export type MonitoredEntity_alertsArgs = {
  next?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<AlertQuery>;
};

export type MonitoredEntity_createAlertArgs = {
  data: CreateAlert;
};

export type MonitoredEntity_createMonitoredModelArgs = {
  data: CreateMonitoredModel;
};

export type MonitoredEntity_deleteAttributesArgs = {
  inputs: Array<Scalars['String']['input']>;
};

export type MonitoredEntity_ownerV2CandidatesArgs = {
  organizationId: Scalars['ID']['input'];
};

export type MonitoredEntity_setAttributesArgs = {
  inputs: Array<StringKeyValueInput>;
};

export type MonitoredEntity_updateOwnerArgs = {
  input: OwnerInput;
};

export type MonitoredEntityOrError = Error | MonitoredEntity;

export type MonitoredEntityQuery = {
  endpointIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  fuzzyNames?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  pagination?: InputMaybe<PaginationQuery>;
};

export type MonitoredModel = {
  __typename: 'MonitoredModel';
  createdAt: Scalars['Date']['output'];
  delete: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  json: Scalars['String']['output'];
  monitoredEntity: MonitoredEntity;
  name: Scalars['String']['output'];
  updateJson: MonitoredModel;
  version: Scalars['String']['output'];
};

export type MonitoredModel_updateJsonArgs = {
  json: Scalars['String']['input'];
};

export type MonitoredModelOrError = Error | MonitoredModel;

export type MonitoredModelQuery = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  monitoredEntityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MonitoredModels = {
  __typename: 'MonitoredModels';
  models: Array<MonitoredModel>;
};

export type MonitoringBooleanFilterInput = {
  ioDescription: MonitoringIODescriptionInput;
  operator: MonitoringBooleanFilterOperator;
  value: Scalars['Boolean']['input'];
};

export enum MonitoringBooleanFilterOperator {
  EQ = 'EQ',
  NE = 'NE',
}

export type MonitoringConfidenceMetricQuery = {
  endDate: Scalars['Date']['input'];
  filters?: InputMaybe<Array<MonitoringFilterInput>>;
  output: MonitoringIODescriptionInput;
  size: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
  types: Array<MonitoringConfidenceMetricType>;
};

export type MonitoringConfidenceMetricResult = {
  __typename: 'MonitoringConfidenceMetricResult';
  modelVersion: RegisteredModelVersionOrError;
  modelVersionId: Scalars['ID']['output'];
  type: MonitoringConfidenceMetricType;
  values: Array<Scalars['Number']['output']>;
};

export enum MonitoringConfidenceMetricType {
  ACCURACY = 'ACCURACY',
  F1 = 'F1',
  FN = 'FN',
  FP = 'FP',
  FPR = 'FPR',
  PRECISION = 'PRECISION',
  RECALL = 'RECALL',
  TN = 'TN',
  TP = 'TP',
  TPR = 'TPR',
}

export enum MonitoringDataType {
  BOOLEAN = 'BOOLEAN',
  DOUBLE = 'DOUBLE',
  LONG = 'LONG',
  STRING = 'STRING',
}

export enum MonitoringDistributionBucketLimitSource {
  LIVE = 'LIVE',
  REFERENCE = 'REFERENCE',
}

export type MonitoringDistributionOverTimeQuery = {
  aggregationMilliseconds: Scalars['Int']['input'];
  base: MonitoringDistributionQuery;
};

export type MonitoringDistributionOverTimeResult = {
  __typename: 'MonitoringDistributionOverTimeResult';
  bucketCounts: Array<Array<Scalars['Int']['output']>>;
  bucketLimits: Array<Scalars['Float']['output']>;
  ioType: MonitoringIOType;
  modelVersion: RegisteredModelVersionOrError;
  modelVersionId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  time: Array<Scalars['Date']['output']>;
};

export type MonitoringDistributionQuery = {
  bucketLimitSource?: InputMaybe<MonitoringDistributionBucketLimitSource>;
  endDate: Scalars['Date']['input'];
  filters?: InputMaybe<Array<MonitoringFilterInput>>;
  ioDescriptions: Array<MonitoringIODescriptionQuery>;
  startDate: Scalars['Date']['input'];
};

export type MonitoringDistributionResult = {
  __typename: 'MonitoringDistributionResult';
  bucketCounts: Array<Scalars['Int']['output']>;
  bucketLimits: Array<Scalars['Float']['output']>;
  ioType: MonitoringIOType;
  modelVersion: RegisteredModelVersionOrError;
  modelVersionId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type MonitoringDoubleFilterInput = {
  ioDescription: MonitoringIODescriptionInput;
  operator: MonitoringDoubleFilterOperator;
  value: Scalars['Float']['input'];
};

export enum MonitoringDoubleFilterOperator {
  EQ = 'EQ',
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE',
  NE = 'NE',
}

export enum MonitoringDriftMetricType {
  COSINE_DISTANCE = 'COSINE_DISTANCE',
  KL_DIVERGENCE = 'KL_DIVERGENCE',
}

export type MonitoringDriftOverTimeQuery = {
  aggregationMilliseconds: Scalars['Int']['input'];
  base: MonitoringDriftQuery;
};

export type MonitoringDriftOverTimeResult = {
  __typename: 'MonitoringDriftOverTimeResult';
  ioType: MonitoringIOType;
  modelVersion: RegisteredModelVersionOrError;
  modelVersionId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  time: Array<Scalars['Date']['output']>;
  type: MonitoringDriftMetricType;
  values: Array<Scalars['Number']['output']>;
};

export type MonitoringDriftQuery = {
  base: MonitoringDistributionQuery;
  types: Array<MonitoringDriftMetricType>;
};

export type MonitoringDriftResult = {
  __typename: 'MonitoringDriftResult';
  ioType: MonitoringIOType;
  max: Scalars['Number']['output'];
  min: Scalars['Number']['output'];
  modelVersion: RegisteredModelVersionOrError;
  modelVersionId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: MonitoringDriftMetricType;
  value: Scalars['Number']['output'];
};

export type MonitoringFeature = {
  __typename: 'MonitoringFeature';
  name: Scalars['String']['output'];
  type: MonitoringFeatureType;
};

export enum MonitoringFeatureType {
  BOOLEAN = 'BOOLEAN',
  DOUBLE = 'DOUBLE',
  LONG = 'LONG',
  STRING = 'STRING',
  UNDEFINED = 'UNDEFINED',
}

export type MonitoringFilterInput = {
  boolean?: InputMaybe<MonitoringBooleanFilterInput>;
  double?: InputMaybe<MonitoringDoubleFilterInput>;
  string?: InputMaybe<MonitoringStringFilterInput>;
  stringArray?: InputMaybe<MonitoringStringArrayFilterInput>;
};

export type MonitoringIODescription =
  | MonitoringInputDescription
  | MonitoringOutputDescription;

export type MonitoringIODescriptionInput = {
  dataType?: InputMaybe<MonitoringDataType>;
  hasConfidence?: InputMaybe<Scalars['Boolean']['input']>;
  ioType: MonitoringIOType;
  name: Scalars['String']['input'];
};

export type MonitoringIODescriptionQuery = {
  ioType: MonitoringIOType;
  name: Array<Scalars['String']['input']>;
};

export enum MonitoringIOType {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export type MonitoringInputDescription = {
  __typename: 'MonitoringInputDescription';
  dataType: MonitoringDataType;
  ioType: MonitoringIOType;
  name: Scalars['String']['output'];
};

export type MonitoringMetricQuery = {
  endDate: Scalars['Date']['input'];
  filters?: InputMaybe<Array<MonitoringFilterInput>>;
  output: MonitoringIODescriptionInput;
  startDate: Scalars['Date']['input'];
  types: Array<MonitoringMetricType>;
};

export type MonitoringMetricResult = {
  __typename: 'MonitoringMetricResult';
  modelVersion: RegisteredModelVersionOrError;
  modelVersionId: Scalars['ID']['output'];
  type: MonitoringMetricType;
  value: Scalars['Number']['output'];
};

export enum MonitoringMetricType {
  ACCURACY = 'ACCURACY',
  F1 = 'F1',
  FN = 'FN',
  FP = 'FP',
  FPR = 'FPR',
  MAE = 'MAE',
  MSE = 'MSE',
  PRECISION = 'PRECISION',
  PREDICTION_COUNT = 'PREDICTION_COUNT',
  R2 = 'R2',
  RECALL = 'RECALL',
  RMSE = 'RMSE',
  TN = 'TN',
  TP = 'TP',
  TPR = 'TPR',
}

export type MonitoringMetrics = {
  __typename: 'MonitoringMetrics';
  confidenceMetric: Array<MonitoringConfidenceMetricResult>;
  drift: Array<MonitoringDriftResult>;
  driftOverTime: Array<MonitoringDriftOverTimeResult>;
  ioDescriptions: Array<MonitoringIODescription>;
  liveDistribution: Array<MonitoringDistributionResult>;
  liveDistributionOverTime: Array<MonitoringDistributionOverTimeResult>;
  metric: Array<MonitoringMetricResult>;
  metricOverTime: Array<MonitoringSlicedMetricResult>;
  modelVersions: Array<MonitoringModelVersionResult>;
  modelVersionsOverTime: Array<MonitoringModelVersionOverTimeResult>;
  outliers: Array<MonitoringOutlierResult>;
  outliersOverTime: Array<MonitoringOutlierOverTimeResult>;
  referenceDistribution: Array<MonitoringDistributionResult>;
  status: MonitoringMetricsStatus;
};

export type MonitoringMetrics_confidenceMetricArgs = {
  query: MonitoringConfidenceMetricQuery;
};

export type MonitoringMetrics_driftArgs = {
  query: MonitoringDriftQuery;
};

export type MonitoringMetrics_driftOverTimeArgs = {
  query: MonitoringDriftOverTimeQuery;
};

export type MonitoringMetrics_liveDistributionArgs = {
  query: MonitoringDistributionQuery;
};

export type MonitoringMetrics_liveDistributionOverTimeArgs = {
  query: MonitoringDistributionOverTimeQuery;
};

export type MonitoringMetrics_metricArgs = {
  query: MonitoringMetricQuery;
};

export type MonitoringMetrics_metricOverTimeArgs = {
  query: MonitoringSlicedMetricQuery;
};

export type MonitoringMetrics_modelVersionsArgs = {
  query: MonitoringModelVersionQuery;
};

export type MonitoringMetrics_modelVersionsOverTimeArgs = {
  query: MonitoringModelVersionOverTimeQuery;
};

export type MonitoringMetrics_outliersArgs = {
  query: MonitoringOutlierQuery;
};

export type MonitoringMetrics_outliersOverTimeArgs = {
  query: MonitoringOutlierOverTimeQuery;
};

export type MonitoringMetrics_referenceDistributionArgs = {
  query: MonitoringDistributionQuery;
};

export type MonitoringMetricsStatus = {
  __typename: 'MonitoringMetricsStatus';
  isGroundTruthExist: Scalars['Boolean']['output'];
  isLiveDataExist: Scalars['Boolean']['output'];
  isReferenceDataExist: Scalars['Boolean']['output'];
};

export type MonitoringModelVersionOverTimeQuery = {
  aggregationMilliseconds: Scalars['Int']['input'];
  base: MonitoringModelVersionQuery;
};

export type MonitoringModelVersionOverTimeResult = {
  __typename: 'MonitoringModelVersionOverTimeResult';
  data: Array<MonitoringModelVersionResult>;
  time: Scalars['Date']['output'];
};

export type MonitoringModelVersionQuery = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type MonitoringModelVersionResult = {
  __typename: 'MonitoringModelVersionResult';
  modelVersion: RegisteredModelVersionOrError;
  modelVersionId: Scalars['ID']['output'];
};

export type MonitoringOutlierOverTimeQuery = {
  aggregationMilliseconds: Scalars['Int']['input'];
  base: MonitoringOutlierQuery;
};

export type MonitoringOutlierOverTimeResult = {
  __typename: 'MonitoringOutlierOverTimeResult';
  data: Array<MonitoringOutlierResult>;
  time: Array<Scalars['Date']['output']>;
};

export type MonitoringOutlierQuery = {
  endDate: Scalars['Date']['input'];
  filters?: InputMaybe<Array<MonitoringFilterInput>>;
  ioDescriptions: Array<MonitoringIODescriptionQuery>;
  maxSamples: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type MonitoringOutlierResult = {
  __typename: 'MonitoringOutlierResult';
  ioType: MonitoringIOType;
  max: Scalars['Number']['output'];
  mean: Scalars['Number']['output'];
  median: Scalars['Number']['output'];
  min: Scalars['Number']['output'];
  modelVersion: RegisteredModelVersionOrError;
  modelVersionId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  outlierSigmaThreshold: Scalars['Int']['output'];
  sampledInliers: Array<Scalars['Number']['output']>;
  sampledOutliers: Array<Scalars['Number']['output']>;
  sigma: Scalars['Number']['output'];
  totalInliers: Scalars['Int']['output'];
  totalOutliers: Scalars['Int']['output'];
};

export type MonitoringOutputDescription = {
  __typename: 'MonitoringOutputDescription';
  dataType: MonitoringDataType;
  hasConfidence: Scalars['Boolean']['output'];
  ioType: MonitoringIOType;
  name: Scalars['String']['output'];
};

export type MonitoringSchema = {
  __typename: 'MonitoringSchema';
  createdAt: Scalars['Date']['output'];
  features: Array<MonitoringFeature>;
};

export type MonitoringSlicedMetricQuery = {
  aggregationMilliseconds: Scalars['Int']['input'];
  endDate: Scalars['Date']['input'];
  filters?: InputMaybe<Array<MonitoringFilterInput>>;
  output: MonitoringIODescriptionInput;
  startDate: Scalars['Date']['input'];
  types: Array<MonitoringMetricType>;
};

export type MonitoringSlicedMetricResult = {
  __typename: 'MonitoringSlicedMetricResult';
  modelVersion: RegisteredModelVersionOrError;
  modelVersionId: Scalars['ID']['output'];
  time: Array<Scalars['Date']['output']>;
  type: MonitoringMetricType;
  values: Array<Scalars['Number']['output']>;
};

export type MonitoringStringArrayFilterInput = {
  ioDescription: MonitoringIODescriptionInput;
  operator: MonitoringStringArrayFilterOperator;
  value: Array<Scalars['String']['input']>;
};

export enum MonitoringStringArrayFilterOperator {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
}

export type MonitoringStringFilterInput = {
  ioDescription: MonitoringIODescriptionInput;
  operator: MonitoringStringFilterOperator;
  value: Scalars['String']['input'];
};

export enum MonitoringStringFilterOperator {
  EQ = 'EQ',
  LIKE = 'LIKE',
  NE = 'NE',
  NOT_LIKE = 'NOT_LIKE',
}

export type Mutation = {
  __typename: 'Mutation';
  addFeedback: LlmOutputDatasetOrError;
  alert: AlertOrError;
  analytics?: Maybe<Analytics>;
  createDistillationJob: DistillationJobOrError;
  createGcloudDeployment: GcloudDeploymentOrError;
  createInferenceJob: InferenceJobOrError;
  createJob: JobOrError;
  createOrUpdateChecklistItemValues?: Maybe<ChecklistItemValuesOrError>;
  createOrUpdateCustomAttributeCategory: CustomAttributeCategoryOrError;
  createOrUpdateLlmDataset: LlmDatasetOrError;
  createOrUpdateLlmExperiments: LlmExperimentsOrError;
  createOrUpdateLlmModel: LlmModelOrError;
  createOrUpdateLlmOutputDataset: LlmOutputDatasetOrError;
  createOrUpdateLlmProject: LlmProjectOrError;
  createOrUpdateLlmPrompt: LlmPromptOrError;
  /** @deprecated Field is deprecated! */
  createOrganization: Organization;
  createOrganizationV2: OrganizationV2;
  dataset: DatasetOrError;
  datasetVersion: DatasetVersionOrError;
  deleteCustomAttributeCategory?: Maybe<Scalars['ID']['output']>;
  deleteGcloudDeployment?: Maybe<Scalars['ID']['output']>;
  deleteLabel: LlmOutputDatasetOrError;
  deleteLlmDataset?: Maybe<Scalars['ID']['output']>;
  deleteLlmModel?: Maybe<Scalars['ID']['output']>;
  deleteLlmProject?: Maybe<Scalars['ID']['output']>;
  deleteLlmPrompt?: Maybe<Scalars['ID']['output']>;
  endpoint: EndpointOrError;
  experiment: ExperimentOrError;
  job: JobOrError;
  kafkaConfiguration: KafkaConfigurationOrError;
  kafkaConfigurations: KafkaConfigurations;
  llmDataset: LlmDatasetOrError;
  llmExperiment: GetExperimentResponseOrError;
  llmModel: LlmModelOrError;
  llmOutputDataset: LlmOutputDatasetOrError;
  llmProject: LlmProjectOrError;
  llmPrompt: LlmPromptOrError;
  monitoredEntity: MonitoredEntityOrError;
  monitoredModel: MonitoredModelOrError;
  /** @deprecated Field is deprecated! */
  organization: OrganizationOrError;
  /** @deprecated Field is deprecated! */
  organizationByName: OrganizationOrError;
  organizationV2: OrganizationV2OrError;
  organizationV2ByName: OrganizationV2OrError;
  /** @deprecated Field is deprecated! */
  organizations: Array<Organization>;
  project: ProjectOrError;
  pypiConfiguration: PypiConfigurationOrError;
  pypiConfigurations: PypiConfigurations;
  registeredModel: RegisteredModelOrError;
  registeredModelVersion: RegisteredModelVersionOrError;
  repository: RepositoryOrError;
  run: ExperimentRunOrError;
  self: User;
  serviceAccount: ServiceAccountOrError;
  systemAdmins: SystemAdmins;
  updateGcloudDeployment: GcloudDeploymentOrError;
  updateInferenceJob: InferenceJobOrError;
  user: User;
  userByName: User;
  /** @deprecated Field is deprecated! */
  workspace: WorkspaceOrError;
  /** @deprecated Field is deprecated! */
  workspaces: Array<Workspace>;
};

export type Mutation_addFeedbackArgs = {
  input: AddFeedbackInput;
};

export type Mutation_alertArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_createDistillationJobArgs = {
  input?: InputMaybe<CreateDistillationJob>;
};

export type Mutation_createGcloudDeploymentArgs = {
  input?: InputMaybe<CreateGcloudDeployment>;
};

export type Mutation_createInferenceJobArgs = {
  input?: InputMaybe<CreateInferenceJob>;
};

export type Mutation_createJobArgs = {
  input?: InputMaybe<CreateJobInput>;
};

export type Mutation_createOrUpdateChecklistItemValuesArgs = {
  values?: InputMaybe<Array<SetChecklistItemValueInput>>;
};

export type Mutation_createOrUpdateCustomAttributeCategoryArgs = {
  input?: InputMaybe<CustomAttributeCategoryInput>;
  organizationId: Scalars['ID']['input'];
};

export type Mutation_createOrUpdateLlmDatasetArgs = {
  input?: InputMaybe<LlmDatasetInput>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation_createOrUpdateLlmExperimentsArgs = {
  inputs: Array<LlmExperimentInput>;
};

export type Mutation_createOrUpdateLlmModelArgs = {
  input?: InputMaybe<LlmModelInput>;
};

export type Mutation_createOrUpdateLlmOutputDatasetArgs = {
  input?: InputMaybe<LlmOutputDatasetInput>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation_createOrUpdateLlmProjectArgs = {
  input?: InputMaybe<LlmProjectInput>;
};

export type Mutation_createOrUpdateLlmPromptArgs = {
  input?: InputMaybe<LlmPromptInput>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation_createOrganizationArgs = {
  baselinePermissions?: InputMaybe<OrganizationBaselinePermissionsInput>;
  collaboratorType?: InputMaybe<CollaboratorType>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  namespace?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation_createOrganizationV2Args = {
  input: OrganizationV2Input;
};

export type Mutation_datasetArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_datasetVersionArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_deleteCustomAttributeCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_deleteGcloudDeploymentArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_deleteLabelArgs = {
  input: DeleteLabelInput;
};

export type Mutation_deleteLlmDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_deleteLlmModelArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_deleteLlmProjectArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_deleteLlmPromptArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_endpointArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_experimentArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_jobArgs = {
  jobId: Scalars['ID']['input'];
};

export type Mutation_kafkaConfigurationArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_llmDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_llmExperimentArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_llmModelArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_llmOutputDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_llmProjectArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_llmPromptArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_monitoredEntityArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_monitoredModelArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_organizationArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_organizationByNameArgs = {
  name: Scalars['String']['input'];
};

export type Mutation_organizationV2Args = {
  id: Scalars['ID']['input'];
};

export type Mutation_organizationV2ByNameArgs = {
  name: Scalars['String']['input'];
};

export type Mutation_projectArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_pypiConfigurationArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_registeredModelArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_registeredModelVersionArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_repositoryArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_runArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_serviceAccountArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_updateGcloudDeploymentArgs = {
  input?: InputMaybe<UpdateGcloudDeployment>;
};

export type Mutation_updateInferenceJobArgs = {
  input?: InputMaybe<UpdateInferenceJob>;
};

export type Mutation_userArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_userByNameArgs = {
  username: Scalars['String']['input'];
};

export type Mutation_workspaceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type NamedCommitBlob = {
  __typename: 'NamedCommitBlob';
  content: CommitBlob;
  name: Scalars['String']['output'];
};

export type NamedCommitFolder = {
  __typename: 'NamedCommitFolder';
  content: CommitFolder;
  name: Scalars['String']['output'];
};

export type NetworkBranchColor = {
  __typename: 'NetworkBranchColor';
  branch: Scalars['String']['output'];
  color: Scalars['Int']['output'];
  commitIndex: Scalars['Int']['output'];
};

export type NetworkCommitColor = {
  __typename: 'NetworkCommitColor';
  color: Scalars['Int']['output'];
  commit: Commit;
};

export type NetworkEdgeColor = {
  __typename: 'NetworkEdgeColor';
  color: Scalars['Int']['output'];
  edgeType: NetworkEdgeType;
  fromCommitIndex: Scalars['Int']['output'];
  toCommitIndex: Scalars['Int']['output'];
};

export enum NetworkEdgeType {
  BRANCH = 'BRANCH',
  DEFAULT = 'DEFAULT',
  MERGE = 'MERGE',
}

export type NotificationChannel = AlertNotificationChannelSlackWebhook;

export enum NvidiaGpuModel {
  Any = 'Any',
  T4 = 'T4',
  V100 = 'V100',
}

export type Observation = ObservationArtifact | ObservationAttribute;

export type ObservationArtifact = {
  __typename: 'ObservationArtifact';
  artifact: Artifact;
  epochNumber?: Maybe<Scalars['Float']['output']>;
  timestamp: Scalars['Timestamp']['output'];
};

export type ObservationAttribute = {
  __typename: 'ObservationAttribute';
  attribute: PrimitiveKeyValue;
  epochNumber?: Maybe<Scalars['Float']['output']>;
  timestamp: Scalars['Timestamp']['output'];
};

/** @deprecated */
export type Organization = {
  __typename: 'Organization';
  /** @deprecated Field is deprecated! */
  addUser: Organization;
  allowedActions: AllowedActions;
  baselinePermissions: OrganizationBaselinePermissions;
  dateCreated: Scalars['Date']['output'];
  dateUpdated: Scalars['Date']['output'];
  /** @deprecated Field is deprecated! */
  defaultDatasetCollaboratorType: CollaboratorType;
  /** @deprecated Field is deprecated! */
  defaultEndpointCollaboratorType: CollaboratorType;
  /** @deprecated Field is deprecated! */
  defaultMonitoredEntityCollaboratorType: CollaboratorType;
  /** @deprecated Field is deprecated! */
  defaultNotificationChannelCollaboratorType: CollaboratorType;
  /** @deprecated Field is deprecated! */
  defaultProjectCollaboratorType: CollaboratorType;
  /** @deprecated Field is deprecated! */
  defaultRegisteredModelCollaboratorType: CollaboratorType;
  /** @deprecated Field is deprecated! */
  defaultRepoCollaboratorType: CollaboratorType;
  /** @deprecated Field is deprecated! */
  delete: Array<Organization>;
  description: Scalars['String']['output'];
  endpointCanPredict: Scalars['Boolean']['output'];
  /** @deprecated Field is deprecated! */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  namespace?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<User>;
  projectCanDeploy: Scalars['Boolean']['output'];
  provisioning?: Maybe<Array<ProvisioningItem>>;
  registeredModelCanDeploy: Scalars['Boolean']['output'];
  removeSelf: Scalars['Boolean']['output'];
  /** @deprecated Field is deprecated! */
  removeUser: Organization;
  shortName: Scalars['String']['output'];
  /** @deprecated Field is deprecated! */
  update: Organization;
  userIds: Array<Scalars['ID']['output']>;
  users: Array<User>;
  /** @deprecated Field is deprecated! */
  workspace: Workspace;
};

/** @deprecated */
export type Organization_addUserArgs = {
  usernameOrEmail: Scalars['String']['input'];
};

/** @deprecated */
export type Organization_removeUserArgs = {
  userId: Scalars['ID']['input'];
};

/** @deprecated */
export type Organization_updateArgs = {
  baselinePermissions?: InputMaybe<OrganizationBaselinePermissionsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  namespace?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<OrganizationPermissions>;
};

/** @deprecated */
export type Organization_usersArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** @deprecated */
export type OrganizationAdmins = {
  __typename: 'OrganizationAdmins';
  userIds: Array<Scalars['ID']['output']>;
  users: Array<User>;
};

/** @deprecated */
export type OrganizationBaselinePermissions = {
  __typename: 'OrganizationBaselinePermissions';
  endpointCanPredict: Scalars['Boolean']['output'];
  minimumResourcesPermission: BaselinePermissions;
  projectCanDeploy: Scalars['Boolean']['output'];
  registryCanDeploy: Scalars['Boolean']['output'];
};

/** @deprecated */
export type OrganizationBaselinePermissionsInput = {
  endpointCanPredict: Scalars['Boolean']['input'];
  minimumResourcesPermission: BaselinePermissions;
  projectCanDeploy: Scalars['Boolean']['input'];
  registryCanDeploy: Scalars['Boolean']['input'];
};

/** @deprecated */
export type OrganizationOrError = Error | Organization;

/** @deprecated */
export type OrganizationPermissions = {
  defaultDatasetCollaboratorType?: InputMaybe<CollaboratorType>;
  defaultEndpointCollaboratorType?: InputMaybe<CollaboratorType>;
  defaultMonitoredEntityCollaboratorType?: InputMaybe<CollaboratorType>;
  defaultNotificationChannelCollaboratorType?: InputMaybe<CollaboratorType>;
  defaultProjectCollaboratorType?: InputMaybe<CollaboratorType>;
  defaultRegisteredModelCollaboratorType?: InputMaybe<CollaboratorType>;
  defaultRepoCollaboratorType?: InputMaybe<CollaboratorType>;
  endpointCanPredict?: InputMaybe<Scalars['Boolean']['input']>;
  projectCanDeploy?: InputMaybe<Scalars['Boolean']['input']>;
  registeredModelCanDeploy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationStats = {
  __typename: 'OrganizationStats';
  numEndpoints: Scalars['Int']['output'];
  numRegisteredModels: Scalars['Int']['output'];
  numUsers: Scalars['Int']['output'];
  orgId: Scalars['ID']['output'];
};

export type OrganizationV2 = {
  __typename: 'OrganizationV2';
  addDevKey: UserOrServiceAccountV2;
  addRole: OrganizationV2;
  addServiceAccount: ServiceAccountV2;
  /** @deprecated Field is deprecated! use addUsers instead */
  addUser: OrganizationV2;
  addUsers: OrganizationV2;
  addWorkspace: OrganizationV2;
  admins: Array<UserV2>;
  allowedActions: AllowedActions;
  checklistTemplates?: Maybe<Array<ChecklistTemplate>>;
  createGroup: OrganizationV2;
  createJob: JobOrError;
  createOrUpdateChecklistTemplate: ChecklistTemplateOrError;
  createOrUpdateCustomAttributeCategory: CustomAttributeCategoryOrError;
  createOrUpdateCustomAttributeDefinition: CustomAttributeDefinitionOrError;
  currentUserIsAdmin: Scalars['Boolean']['output'];
  customAttributeCategories: CustomAttributeCategoriesOrError;
  customAttributeDefinitions: CustomAttributeDefinitionsOrError;
  customDashboard: CustomDashboardOrError;
  customDashboardTopModels: CustomDashboardTopModelsOrError;
  delete: Scalars['Boolean']['output'];
  deleteChecklistTemplate: OrganizationV2;
  deleteCustomAttributeCategory: OrganizationV2;
  deleteCustomAttributeDefinition: OrganizationV2;
  deletePrimaryKey: UserOrServiceAccountV2;
  deleteSecondaryKey: UserOrServiceAccountV2;
  description: Scalars['String']['output'];
  enabledResourceActions: Array<RoleResourceActions>;
  group: GroupOrError;
  groups: Array<Group>;
  id: Scalars['ID']['output'];
  job: JobOrError;
  jobs: Array<Job>;
  name: Scalars['String']['output'];
  removeServiceAccount: OrganizationV2;
  removeUser: OrganizationV2;
  role: RoleV2;
  roles: Array<RoleV2>;
  timeCreated: Scalars['Timestamp']['output'];
  timeUpdated: Scalars['Timestamp']['output'];
  update: OrganizationV2;
  updateUser: OrganizationV2;
  user: UserOrServiceAccountV2;
  users: Users;
  workspace: WorkspaceV2;
  workspaces: Array<WorkspaceV2>;
};

export type OrganizationV2_addDevKeyArgs = {
  userId: Scalars['String']['input'];
};

export type OrganizationV2_addRoleArgs = {
  input: RoleV2Input;
};

export type OrganizationV2_addServiceAccountArgs = {
  description: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
};

export type OrganizationV2_addUserArgs = {
  email: Scalars['String']['input'];
};

export type OrganizationV2_addUsersArgs = {
  emails: Array<Scalars['String']['input']>;
};

export type OrganizationV2_addWorkspaceArgs = {
  input: WorkspaceV2Input;
};

export type OrganizationV2_createGroupArgs = {
  input: GroupInput;
};

export type OrganizationV2_createJobArgs = {
  input?: InputMaybe<CreateJobInput>;
};

export type OrganizationV2_createOrUpdateChecklistTemplateArgs = {
  input?: InputMaybe<ChecklistTemplateInput>;
};

export type OrganizationV2_createOrUpdateCustomAttributeCategoryArgs = {
  input?: InputMaybe<CustomAttributeCategoryInput>;
};

export type OrganizationV2_createOrUpdateCustomAttributeDefinitionArgs = {
  input?: InputMaybe<CustomAttributeDefinitionInput>;
};

export type OrganizationV2_customDashboardTopModelsArgs = {
  sortBy: Scalars['String']['input'];
};

export type OrganizationV2_deleteChecklistTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type OrganizationV2_deleteCustomAttributeCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type OrganizationV2_deleteCustomAttributeDefinitionArgs = {
  id: Scalars['ID']['input'];
};

export type OrganizationV2_deletePrimaryKeyArgs = {
  userId: Scalars['String']['input'];
};

export type OrganizationV2_deleteSecondaryKeyArgs = {
  userId: Scalars['String']['input'];
};

export type OrganizationV2_groupArgs = {
  id: Scalars['ID']['input'];
};

export type OrganizationV2_jobArgs = {
  jobId: Scalars['ID']['input'];
};

export type OrganizationV2_jobsArgs = {
  workspaceId: Scalars['ID']['input'];
};

export type OrganizationV2_removeServiceAccountArgs = {
  id: Scalars['ID']['input'];
};

export type OrganizationV2_removeUserArgs = {
  id: Scalars['ID']['input'];
};

export type OrganizationV2_roleArgs = {
  id: Scalars['ID']['input'];
};

export type OrganizationV2_updateArgs = {
  input: OrganizationV2Input;
};

export type OrganizationV2_updateUserArgs = {
  input: UpdateUserInput;
};

export type OrganizationV2_userArgs = {
  id: Scalars['ID']['input'];
};

export type OrganizationV2_usersArgs = {
  query?: InputMaybe<UsersQuery>;
};

export type OrganizationV2_workspaceArgs = {
  id: Scalars['ID']['input'];
};

export type OrganizationV2Input = {
  adminEmails: Array<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type OrganizationV2OrError = Error | OrganizationV2;

export type OrganizationsV2Input = {
  pagination: PaginationQuery;
};

export type OrganizationsV2Response = {
  __typename: 'OrganizationsV2Response';
  organizations: Array<OrganizationV2>;
  organizationsStats: Array<OrganizationStats>;
  pagination: PaginationResponse;
};

export type OwnerCandidates = {
  __typename: 'OwnerCandidates';
  groups: Array<Group>;
  users: Array<User>;
};

export type OwnerInput = {
  id: Scalars['ID']['input'];
  type: OwnerType;
};

export enum OwnerType {
  Group = 'Group',
  User = 'User',
}

export type OwnerV2Candidates = {
  __typename: 'OwnerV2Candidates';
  groups: Array<Group>;
  users: Array<UserOrServiceAccountV2>;
};

export type PaginationQuery = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type PaginationResponse = {
  __typename: 'PaginationResponse';
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalRecords: Scalars['Int']['output'];
};

export type PermissionInput = {
  customPermission?: InputMaybe<CustomPermissionsInput>;
  visibility?: InputMaybe<Visibility>;
};

export type Permissions = {
  __typename: 'Permissions';
  canDeploy: Scalars['Boolean']['output'];
  canPredict: Scalars['Boolean']['output'];
  type: AccessType;
};

export enum PredicateOperator {
  CONTAIN = 'CONTAIN',
  EQ = 'EQ',
  GT = 'GT',
  GTE = 'GTE',
  IN = 'IN',
  LT = 'LT',
  LTE = 'LTE',
  NE = 'NE',
  NOT_CONTAIN = 'NOT_CONTAIN',
  NOT_IN = 'NOT_IN',
}

export type PrimitiveKeyValue = BoolKeyValue | FloatKeyValue | StringKeyValue;

export type Project = {
  __typename: 'Project';
  access: AccessType;
  addAttributes: Project;
  addTags: Project;
  allowedActions: AllowedActions;
  artifacts: Array<Artifact>;
  attributes: Array<KeyValue>;
  changeDescription: Project;
  changeReadme: Project;
  createExperiment: Experiment;
  customPermission?: Maybe<Permissions>;
  dateCreated: Scalars['Timestamp']['output'];
  dateUpdated: Scalars['Timestamp']['output'];
  delete: Scalars['Boolean']['output'];
  deleteArtifact: Project;
  deleteAttributes: Project;
  deleteTags: Project;
  description: Scalars['String']['output'];
  experiments: Experiments;
  id: Scalars['ID']['output'];
  logArtifact: Project;
  name: Scalars['String']['output'];
  owner: UserOrGroup;
  ownerCandidates: OwnerCandidates;
  ownerV2Candidates: OwnerV2Candidates;
  readmeText: Scalars['String']['output'];
  runs: ExperimentRuns;
  shortName: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  updateAttribute: Project;
  updateOwner: Project;
  updatePermission: Project;
  visibility: Visibility;
  /** @deprecated Field is deprecated! */
  workspace: Workspace;
};

export type Project_addAttributesArgs = {
  keyValues: Array<StringKeyValueInput>;
};

export type Project_addTagsArgs = {
  tags: Array<Scalars['String']['input']>;
};

export type Project_changeDescriptionArgs = {
  description: Scalars['String']['input'];
};

export type Project_changeReadmeArgs = {
  readme: Scalars['String']['input'];
};

export type Project_createExperimentArgs = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tags: Array<Scalars['String']['input']>;
};

export type Project_deleteArtifactArgs = {
  key: Scalars['String']['input'];
};

export type Project_deleteAttributesArgs = {
  keys: Array<Scalars['String']['input']>;
};

export type Project_deleteTagsArgs = {
  tags: Array<Scalars['String']['input']>;
};

export type Project_experimentsArgs = {
  next?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<ExperimentsQuery>;
};

export type Project_logArtifactArgs = {
  artifact: ArtifactInput;
};

export type Project_ownerV2CandidatesArgs = {
  organizationId: Scalars['ID']['input'];
};

export type Project_runsArgs = {
  next?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<ExperimentRunsQuery>;
};

export type Project_updateAttributeArgs = {
  keyValue: StringKeyValueInput;
};

export type Project_updateOwnerArgs = {
  input: OwnerInput;
};

export type Project_updatePermissionArgs = {
  permission: PermissionInput;
};

export type ProjectOrError = Error | Project;

export type Projects = {
  __typename: 'Projects';
  next?: Maybe<Scalars['String']['output']>;
  pagination: PaginationResponse;
  projects: Array<Project>;
  total: Scalars['Int']['output'];
};

export type ProjectsQuery = {
  floatPredicates?: InputMaybe<Array<FloatPredicate>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<PaginationQuery>;
  sorting?: InputMaybe<SortingQuery>;
  stringPredicates?: InputMaybe<Array<StringPredicate>>;
};

export type ProvisioningItem = {
  __typename: 'ProvisioningItem';
  groupName: Scalars['String']['output'];
  role: ProvisioningRole;
};

export enum ProvisioningRole {
  Admin = 'Admin',
  Member = 'Member',
}

export type PypiConfiguration = {
  __typename: 'PypiConfiguration';
  delete: PypiConfigurations;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  password: Scalars['String']['output'];
  update: PypiConfiguration;
  url: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type PypiConfiguration_updateArgs = {
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  url: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type PypiConfigurationOrError = Error | PypiConfiguration;

export type PypiConfigurations = {
  __typename: 'PypiConfigurations';
  configurations: Array<PypiConfiguration>;
  createPypiConfiguration: PypiConfigurations;
  id: Scalars['ID']['output'];
  test: Scalars['Boolean']['output'];
};

export type PypiConfigurations_createPypiConfigurationArgs = {
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  url: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type PypiConfigurations_testArgs = {
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  url: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type Query = {
  __typename: 'Query';
  activeDirectory: ActiveDirectory;
  administratedOrganizationV2?: Maybe<OrganizationV2>;
  alert: AlertOrError;
  /** @deprecated Field is deprecated! */
  allOrganizations: Array<Organization>;
  checklistTemplates: ChecklistTemplatesResponse;
  currentLlmLeaderboard: LlmLeaderboardOrError;
  customAttributeCategories: CustomAttributeCategoriesOrError;
  customDashboard: CustomDashboardOrError;
  customDashboardTopModels: CustomDashboardTopModelsOrError;
  dataset: DatasetOrError;
  datasetVersion: DatasetVersionOrError;
  distillationJob: DistillationJobOrError;
  distillationJobs: Array<Maybe<DistillationJob>>;
  endpoint: EndpointOrError;
  experiment: ExperimentOrError;
  featureFlags: FeatureFlags;
  gcloudDeployment: GcloudDeploymentOrError;
  gcloudDeployments: Array<Maybe<GcloudDeployment>>;
  help: Scalars['String']['output'];
  homePage: HomePage;
  inferenceJob: InferenceJobOrError;
  inferenceJobs: Array<Maybe<InferenceJob>>;
  job: JobOrError;
  jobs: Array<Job>;
  kafkaConfiguration: KafkaConfigurationOrError;
  kafkaConfigurations: KafkaConfigurations;
  kafkaTopicsList: KafkaTopicsList;
  llmAppConfiguration: LlmAppConfigurationOrError;
  llmAppConfigurationScores: LlmAppConfigurationScoresOrError;
  llmAppConfigurations: LlmAppConfigurationsOrError;
  llmDataset: LlmDatasetOrError;
  llmDatasets: LlmDatasetsOrError;
  llmExperiment: GetExperimentResponseOrError;
  llmExperiments: LlmExperimentsOrError;
  llmModel: LlmModelOrError;
  llmModelMetricsDefinitions: LlmModelMetricsDefinitions;
  llmModels: LlmModelsOrError;
  llmOutputDataset: LlmOutputDatasetOrError;
  llmOutputDatasets: LlmOutputDatasetsOrError;
  llmProject: LlmProjectOrError;
  llmProjects: LlmProjectsOrError;
  llmPrompt: LlmPromptOrError;
  llmPrompts: LlmPromptsOrError;
  llmRankableOutputPairs: LlmRankableOutputPairsOrError;
  llmUseCase: LlmUseCaseOrError;
  llmUseCases: LlmUseCasesOrError;
  mLflowIntegration: MLflowIntegration;
  monitoredEntity: MonitoredEntityOrError;
  monitoredModel: MonitoredModelOrError;
  /** @deprecated Field is deprecated! */
  organization: OrganizationOrError;
  /** @deprecated Field is deprecated! */
  organizationByName: OrganizationOrError;
  organizationV2: OrganizationV2OrError;
  organizationV2ByName: OrganizationV2OrError;
  /** @deprecated Field is deprecated! */
  organizations: Array<Organization>;
  organizationsV2: OrganizationsV2Response;
  project: ProjectOrError;
  pypiConfiguration: PypiConfigurationOrError;
  pypiConfigurations: PypiConfigurations;
  registeredModel: RegisteredModelOrError;
  registeredModelVersion: RegisteredModelVersionOrError;
  repository: RepositoryOrError;
  run: ExperimentRunOrError;
  self: User;
  selfV2: UserOrServiceAccountV2;
  serviceAccount: ServiceAccountOrError;
  serviceFlags: FeatureFlags;
  systemAdmins: SystemAdmins;
  user: User;
  userByName: User;
  users: Array<User>;
  /** @deprecated Field is deprecated! */
  workspace: WorkspaceOrError;
  /** @deprecated Field is deprecated! */
  workspaces: Array<Workspace>;
};

export type Query_alertArgs = {
  id: Scalars['ID']['input'];
};

export type Query_allOrganizationsArgs = {
  externalOnly: Scalars['Boolean']['input'];
};

export type Query_checklistTemplatesArgs = {
  query?: InputMaybe<ChecklistTemplatesInput>;
};

export type Query_currentLlmLeaderboardArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_customAttributeCategoriesArgs = {
  organizationId: Scalars['ID']['input'];
};

export type Query_customDashboardArgs = {
  organizationId: Scalars['ID']['input'];
};

export type Query_customDashboardTopModelsArgs = {
  organizationId: Scalars['ID']['input'];
  sortBy: Scalars['String']['input'];
};

export type Query_datasetArgs = {
  id: Scalars['ID']['input'];
};

export type Query_datasetVersionArgs = {
  id: Scalars['ID']['input'];
};

export type Query_distillationJobArgs = {
  id: Scalars['ID']['input'];
};

export type Query_distillationJobsArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_endpointArgs = {
  id: Scalars['ID']['input'];
};

export type Query_experimentArgs = {
  id: Scalars['ID']['input'];
};

export type Query_gcloudDeploymentArgs = {
  id: Scalars['ID']['input'];
};

export type Query_gcloudDeploymentsArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_helpArgs = {
  key: Scalars['String']['input'];
  section?: InputMaybe<Scalars['String']['input']>;
};

export type Query_inferenceJobArgs = {
  id: Scalars['ID']['input'];
};

export type Query_inferenceJobsArgs = {
  experimentId: Scalars['ID']['input'];
};

export type Query_jobArgs = {
  jobId: Scalars['ID']['input'];
};

export type Query_jobsArgs = {
  organizationId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type Query_kafkaConfigurationArgs = {
  id: Scalars['ID']['input'];
};

export type Query_llmAppConfigurationArgs = {
  id: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type Query_llmAppConfigurationScoresArgs = {
  input?: InputMaybe<GetScoredAppConfigurationInput>;
};

export type Query_llmAppConfigurationsArgs = {
  projectId: Scalars['ID']['input'];
};

export type Query_llmDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type Query_llmDatasetsArgs = {
  projectId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type Query_llmExperimentArgs = {
  id: Scalars['ID']['input'];
};

export type Query_llmExperimentsArgs = {
  projectId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type Query_llmModelArgs = {
  id: Scalars['ID']['input'];
};

export type Query_llmOutputDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type Query_llmOutputDatasetsArgs = {
  experimentId: Scalars['ID']['input'];
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type Query_llmProjectArgs = {
  id: Scalars['ID']['input'];
};

export type Query_llmProjectsArgs = {
  workspaceId: Scalars['ID']['input'];
};

export type Query_llmPromptArgs = {
  id: Scalars['ID']['input'];
};

export type Query_llmPromptsArgs = {
  input?: InputMaybe<LlmPromptQuery>;
  next?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type Query_llmRankableOutputPairsArgs = {
  projectId: Scalars['ID']['input'];
  query: LlmRankableOutputPairsQuery;
};

export type Query_llmUseCaseArgs = {
  id: Scalars['ID']['input'];
};

export type Query_monitoredEntityArgs = {
  id: Scalars['ID']['input'];
};

export type Query_monitoredModelArgs = {
  id: Scalars['ID']['input'];
};

export type Query_organizationArgs = {
  id: Scalars['ID']['input'];
};

export type Query_organizationByNameArgs = {
  name: Scalars['String']['input'];
};

export type Query_organizationV2Args = {
  id: Scalars['ID']['input'];
};

export type Query_organizationV2ByNameArgs = {
  name: Scalars['String']['input'];
};

export type Query_organizationsV2Args = {
  query?: InputMaybe<OrganizationsV2Input>;
};

export type Query_projectArgs = {
  id: Scalars['ID']['input'];
};

export type Query_pypiConfigurationArgs = {
  id: Scalars['ID']['input'];
};

export type Query_registeredModelArgs = {
  id: Scalars['ID']['input'];
};

export type Query_registeredModelVersionArgs = {
  id: Scalars['ID']['input'];
};

export type Query_repositoryArgs = {
  id: Scalars['ID']['input'];
};

export type Query_runArgs = {
  id: Scalars['ID']['input'];
};

export type Query_serviceAccountArgs = {
  id: Scalars['ID']['input'];
};

export type Query_userArgs = {
  id: Scalars['ID']['input'];
};

export type Query_userByNameArgs = {
  username: Scalars['String']['input'];
};

export type Query_workspaceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type RegisteredModel = {
  __typename: 'RegisteredModel';
  activities: Array<Activity>;
  addLabels: RegisteredModel;
  allowedActions: AllowedActions;
  attributes: Array<KeyValue>;
  createOrUpdateCustomAttributeValue?: Maybe<CustomAttributeValueOrError>;
  createVersion: RegisteredModelVersion;
  customAttributeValues: Array<CustomAttributeValue>;
  customPermission?: Maybe<Permissions>;
  dataType: DataType;
  dateCreated: Scalars['Timestamp']['output'];
  dateUpdated: Scalars['Timestamp']['output'];
  delete: Scalars['Boolean']['output'];
  deleteLabels: RegisteredModel;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  labels: Array<Scalars['String']['output']>;
  monitoredModels: MonitoredModels;
  name: Scalars['String']['output'];
  owner: UserOrGroup;
  ownerCandidates: OwnerCandidates;
  ownerV2Candidates: OwnerV2Candidates;
  pii: Scalars['Boolean']['output'];
  readmeText: Scalars['String']['output'];
  taskType: TaskType;
  updateAttribute: RegisteredModel;
  updateDataType: RegisteredModel;
  updateDescription: RegisteredModel;
  updateName: RegisteredModel;
  updateOwner: RegisteredModel;
  updatePermission: RegisteredModel;
  updateReadmeText: RegisteredModel;
  updateRegisteredModel: RegisteredModel;
  updateTaskType: RegisteredModel;
  versions: RegisteredModelVersions;
  visibility: Visibility;
  /** @deprecated Field is deprecated! */
  workspace: Workspace;
};

export type RegisteredModel_addLabelsArgs = {
  labels: Array<Scalars['String']['input']>;
};

export type RegisteredModel_createOrUpdateCustomAttributeValueArgs = {
  input: CustomAttributeValueInput;
};

export type RegisteredModel_createVersionArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  experimentRunId?: InputMaybe<Scalars['ID']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  lockLevel: RegisteredModelVersionLockLevel;
  name: Scalars['String']['input'];
  readmeText?: InputMaybe<Scalars['String']['input']>;
};

export type RegisteredModel_deleteLabelsArgs = {
  labels: Array<Scalars['String']['input']>;
};

export type RegisteredModel_ownerV2CandidatesArgs = {
  organizationId: Scalars['ID']['input'];
};

export type RegisteredModel_updateAttributeArgs = {
  keyValue: StringKeyValueInput;
};

export type RegisteredModel_updateDataTypeArgs = {
  dataType: DataType;
};

export type RegisteredModel_updateDescriptionArgs = {
  description: Scalars['String']['input'];
};

export type RegisteredModel_updateNameArgs = {
  name: Scalars['String']['input'];
};

export type RegisteredModel_updateOwnerArgs = {
  input: OwnerInput;
};

export type RegisteredModel_updatePermissionArgs = {
  permission: PermissionInput;
};

export type RegisteredModel_updateReadmeTextArgs = {
  readme: Scalars['String']['input'];
};

export type RegisteredModel_updateRegisteredModelArgs = {
  fields?: InputMaybe<RegisteredModelInput>;
};

export type RegisteredModel_updateTaskTypeArgs = {
  taskType: TaskType;
};

export type RegisteredModel_versionsArgs = {
  next?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<RegisteredModelVersionsQuery>;
};

export type RegisteredModelInput = {
  customAttributeValues?: InputMaybe<Array<CustomAttributeValueInput>>;
  dataType?: InputMaybe<DataType>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pii?: InputMaybe<Scalars['Boolean']['input']>;
  taskType?: InputMaybe<TaskType>;
};

export type RegisteredModelOrError = Error | RegisteredModel;

export type RegisteredModelVersion = {
  __typename: 'RegisteredModelVersion';
  activities: Array<Activity>;
  addAttributes: RegisteredModelVersion;
  addLabels: RegisteredModelVersion;
  approveTransition?: Maybe<Activity>;
  archived: Scalars['Boolean']['output'];
  artifacts: Array<Artifact>;
  attributes: Array<KeyValue>;
  checklistItemValues?: Maybe<ChecklistItemValues>;
  close: Scalars['Boolean']['output'];
  closeAndLoad: RegisteredModelVersion;
  closeTransition?: Maybe<Activity>;
  codeVersions: Array<CodeVersionBlob>;
  commitTransition?: Maybe<Activity>;
  createExternalDeployment: ExternalDeployment;
  createOrUpdateCustomAttributeValue?: Maybe<CustomAttributeValueOrError>;
  createTransition?: Maybe<Activity>;
  customAttributeValues: Array<CustomAttributeValue>;
  datasets: Array<Artifact>;
  dateCreated: Scalars['Timestamp']['output'];
  dateUpdated: Scalars['Timestamp']['output'];
  delete: Scalars['Boolean']['output'];
  deleteArtifact: Array<Artifact>;
  deleteExternalDeployment: Scalars['Boolean']['output'];
  deleteLabels: RegisteredModelVersion;
  deployments: Array<Deployment>;
  description: Scalars['String']['output'];
  dockerMetadata?: Maybe<DockerMetadata>;
  environmentBlob?: Maybe<Scalars['String']['output']>;
  externalDeployments: Array<ExternalDeployment>;
  hideInputLabel: Scalars['Boolean']['output'];
  hideOutputLabel: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inputDescription?: Maybe<Scalars['String']['output']>;
  labels: Array<Scalars['String']['output']>;
  lockLevel: RegisteredModelVersionLockLevel;
  model?: Maybe<Artifact>;
  monitoredModels: MonitoredModels;
  open: Scalars['Boolean']['output'];
  openAndLoad: RegisteredModelVersion;
  outputDescription?: Maybe<Scalars['String']['output']>;
  owner: UserOrGroup;
  readmeText: Scalars['String']['output'];
  readmeTextUpdated: Scalars['Boolean']['output'];
  redact: Scalars['Boolean']['output'];
  redactAndLoad: RegisteredModelVersion;
  redirectMetadata?: Maybe<Scalars['String']['output']>;
  registeredModel: RegisteredModel;
  rejectTransition?: Maybe<Activity>;
  run?: Maybe<ExperimentRunOrError>;
  stage: Stage;
  updateArchived: RegisteredModelVersion;
  updateAttribute: RegisteredModelVersion;
  updateAttributes: RegisteredModelVersion;
  updateDescription: RegisteredModelVersion;
  updateDockerMetadata: DockerMetadata;
  updateExternalDeployment: ExternalDeployment;
  updateOwner: RegisteredModelVersion;
  updateReadmeText: RegisteredModelVersion;
  updateRegisteredModelVersion: RegisteredModelVersion;
  updateStage?: Maybe<Activity>;
  version: Scalars['String']['output'];
};

export type RegisteredModelVersion_addAttributesArgs = {
  keyValues: Array<StringKeyValueInput>;
};

export type RegisteredModelVersion_addLabelsArgs = {
  labels: Array<Scalars['String']['input']>;
};

export type RegisteredModelVersion_approveTransitionArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  transitionId: Scalars['ID']['input'];
};

export type RegisteredModelVersion_closeTransitionArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  transitionId: Scalars['ID']['input'];
};

export type RegisteredModelVersion_commitTransitionArgs = {
  transitionId: Scalars['ID']['input'];
};

export type RegisteredModelVersion_createExternalDeploymentArgs = {
  fields?: InputMaybe<ExternalDeploymentInput>;
};

export type RegisteredModelVersion_createOrUpdateCustomAttributeValueArgs = {
  input: CustomAttributeValueInput;
};

export type RegisteredModelVersion_createTransitionArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  desiredStage: Stage;
};

export type RegisteredModelVersion_deleteArtifactArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
};

export type RegisteredModelVersion_deleteExternalDeploymentArgs = {
  id: Scalars['ID']['input'];
};

export type RegisteredModelVersion_deleteLabelsArgs = {
  labels: Array<Scalars['String']['input']>;
};

export type RegisteredModelVersion_rejectTransitionArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  transitionId: Scalars['ID']['input'];
};

export type RegisteredModelVersion_updateArchivedArgs = {
  archived: Scalars['Boolean']['input'];
};

export type RegisteredModelVersion_updateAttributeArgs = {
  keyValue: StringKeyValueInput;
};

export type RegisteredModelVersion_updateAttributesArgs = {
  keyValues: Array<StringKeyValueInput>;
};

export type RegisteredModelVersion_updateDescriptionArgs = {
  description: Scalars['String']['input'];
};

export type RegisteredModelVersion_updateDockerMetadataArgs = {
  input?: InputMaybe<DockerMetadataInput>;
};

export type RegisteredModelVersion_updateExternalDeploymentArgs = {
  fields?: InputMaybe<ExternalDeploymentInput>;
};

export type RegisteredModelVersion_updateOwnerArgs = {
  input: OwnerInput;
};

export type RegisteredModelVersion_updateReadmeTextArgs = {
  readme: Scalars['String']['input'];
};

export type RegisteredModelVersion_updateRegisteredModelVersionArgs = {
  fields?: InputMaybe<RegisteredModelVersionInput>;
};

export type RegisteredModelVersion_updateStageArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  stage: Stage;
  versionId: Scalars['ID']['input'];
};

export type RegisteredModelVersionInput = {
  customAttributeValues?: InputMaybe<Array<CustomAttributeValueInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  hideInputLabel?: InputMaybe<Scalars['Boolean']['input']>;
  hideOutputLabel?: InputMaybe<Scalars['Boolean']['input']>;
  inputDescription?: InputMaybe<Scalars['String']['input']>;
  outputDescription?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<OwnerInput>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export enum RegisteredModelVersionLockLevel {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  REDACT = 'REDACT',
  UNKNOWN = 'UNKNOWN',
}

export type RegisteredModelVersionOrError = Error | RegisteredModelVersion;

export type RegisteredModelVersions = {
  __typename: 'RegisteredModelVersions';
  next?: Maybe<Scalars['String']['output']>;
  pagination: PaginationResponse;
  versions: Array<RegisteredModelVersion>;
};

export type RegisteredModelVersionsQuery = {
  floatPredicates?: InputMaybe<Array<FloatPredicate>>;
  pagination?: InputMaybe<PaginationQuery>;
  sorting?: InputMaybe<SortingQuery>;
  stringPredicates?: InputMaybe<Array<StringPredicate>>;
};

export type RegisteredModels = {
  __typename: 'RegisteredModels';
  models: Array<RegisteredModel>;
  next?: Maybe<Scalars['String']['output']>;
  pagination: PaginationResponse;
};

export type RegisteredModelsQuery = {
  floatPredicates?: InputMaybe<Array<FloatPredicate>>;
  pagination?: InputMaybe<PaginationQuery>;
  sorting?: InputMaybe<SortingQuery>;
  stringPredicates?: InputMaybe<Array<StringPredicate>>;
};

export type RejectionOfTransition = {
  __typename: 'RejectionOfTransition';
  exists?: Maybe<Scalars['Boolean']['output']>;
};

export type Repositories = {
  __typename: 'Repositories';
  next?: Maybe<Scalars['String']['output']>;
  pagination: PaginationResponse;
  repositories: Array<Repository>;
  total: Scalars['Int']['output'];
};

export type RepositoriesQuery = {
  floatPredicates?: InputMaybe<Array<FloatPredicate>>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  pagination?: InputMaybe<PaginationQuery>;
  stringPredicates?: InputMaybe<Array<StringPredicate>>;
};

export type Repository = {
  __typename: 'Repository';
  addLabels: Repository;
  allowedActions: AllowedActions;
  branch?: Maybe<Commit>;
  branches: Array<RepositoryBranch>;
  commit?: Maybe<Commit>;
  commitByReference?: Maybe<Commit>;
  customPermission?: Maybe<Permissions>;
  dateCreated: Scalars['Date']['output'];
  dateUpdated: Scalars['Date']['output'];
  delete: Scalars['Boolean']['output'];
  deleteLabels: Repository;
  diff?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  labels: Array<Scalars['String']['output']>;
  log: Commits;
  merge: MergeResult;
  name: Scalars['String']['output'];
  network: BranchesNetwork;
  owner: User;
  tag?: Maybe<Commit>;
  tags: Array<RepositoryTag>;
  updatePermission: Repository;
  visibility: Visibility;
  /** @deprecated Field is deprecated! */
  workspace: Workspace;
};

export type Repository_addLabelsArgs = {
  labels: Array<Scalars['String']['input']>;
};

export type Repository_branchArgs = {
  name: Scalars['String']['input'];
};

export type Repository_commitArgs = {
  id: Scalars['ID']['input'];
};

export type Repository_commitByReferenceArgs = {
  ref: CommitReference;
};

export type Repository_deleteLabelsArgs = {
  labels: Array<Scalars['String']['input']>;
};

export type Repository_diffArgs = {
  a: CommitReference;
  b: CommitReference;
};

export type Repository_logArgs = {
  commit: CommitReference;
};

export type Repository_mergeArgs = {
  a: CommitReference;
  b: CommitReference;
  isDryRun?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
};

export type Repository_tagArgs = {
  name: Scalars['String']['input'];
};

export type Repository_updatePermissionArgs = {
  permission: PermissionInput;
};

export type RepositoryBranch = {
  __typename: 'RepositoryBranch';
  commit: Commit;
  name: Scalars['String']['output'];
};

export type RepositoryOrError = Error | Repository;

export type RepositoryTag = {
  __typename: 'RepositoryTag';
  commit: Commit;
  name: Scalars['String']['output'];
};

export enum ResourceTypeV2 {
  DATA = 'DATA',
  ENDPOINTS = 'ENDPOINTS',
  MODELS_AND_VERSIONS = 'MODELS_AND_VERSIONS',
  MONITOR = 'MONITOR',
  PROJECT = 'PROJECT',
  RESOURCE_TYPE_UNKNOWN = 'RESOURCE_TYPE_UNKNOWN',
}

export type ResourcesUpdateConfig = {
  cpuMillis?: InputMaybe<Scalars['Int']['input']>;
  memory?: InputMaybe<Scalars['String']['input']>;
  nvidiaGpu?: InputMaybe<ConfigResourcesNvidiaGpuInput>;
};

export enum RiskLevel {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  UNKNOWN = 'UNKNOWN',
}

export type RoleResourceActions = {
  __typename: 'RoleResourceActions';
  allowedActions: Array<ActionTypeV2>;
  resourceType: ResourceTypeV2;
};

export type RoleResourceActionsInput = {
  allowedActions: Array<ActionTypeV2>;
  resourceType: ResourceTypeV2;
};

export type RoleV2 = {
  __typename: 'RoleV2';
  allowedActions: AllowedActions;
  builtIn: Scalars['Boolean']['output'];
  delete: OrganizationV2OrError;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  resourceActions: Array<RoleResourceActions>;
  update: RoleV2;
};

export type RoleV2_updateArgs = {
  id: Scalars['ID']['input'];
  input: RoleV2Input;
};

export type RoleV2Input = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  resourceActions: Array<RoleResourceActionsInput>;
};

export type ServiceAccount = {
  __typename: 'ServiceAccount';
  dateCreated: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  updateServiceAccount: ServiceAccount;
  user: User;
};

export type ServiceAccount_updateServiceAccountArgs = {
  description: Scalars['String']['input'];
};

export type ServiceAccountOrError = Error | ServiceAccount;

export type ServiceAccountV2 = {
  __typename: 'ServiceAccountV2';
  company?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  groups: Array<ShortGroup>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  joinedAt: Scalars['Timestamp']['output'];
  lastName: Scalars['String']['output'];
  lastSignedIn: Scalars['Timestamp']['output'];
  orgId?: Maybe<Scalars['String']['output']>;
  primaryKey: Scalars['String']['output'];
  secondaryKey: Scalars['String']['output'];
  updatedAt: Scalars['Timestamp']['output'];
  workspaces: Array<ShortWorkspace>;
};

export type SetChecklistItemValueInput = {
  checklistTemplateId: Scalars['ID']['input'];
  checklistTemplateItemId: Scalars['ID']['input'];
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  registeredModelVersionId: Scalars['ID']['input'];
};

export type ShortGroup = {
  __typename: 'ShortGroup';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ShortWorkspace = {
  __typename: 'ShortWorkspace';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum SortableKeyType {
  NAME = 'NAME',
  TIME_CREATED = 'TIME_CREATED',
  UNKNOWN = 'UNKNOWN',
}

export type SortingQuery = {
  ascending?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
};

export enum Stage {
  ARCHIVED = 'ARCHIVED',
  DEVELOPMENT = 'DEVELOPMENT',
  PRODUCTION = 'PRODUCTION',
  STAGING = 'STAGING',
  UNASSIGNED = 'UNASSIGNED',
  UNKNOWN = 'UNKNOWN',
}

export type StringKeyValue = {
  __typename: 'StringKeyValue';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type StringKeyValueInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type StringPredicate = {
  key: Scalars['String']['input'];
  operator: PredicateOperator;
  value: Scalars['String']['input'];
};

export type SystemAdmin = {
  __typename: 'SystemAdmin';
  allowedActions: AllowedActions;
  id: Scalars['ID']['output'];
  user: User;
};

export type SystemAdmins = {
  __typename: 'SystemAdmins';
  addSystemAdmin: SystemAdmins;
  admins: Array<SystemAdmin>;
  id: Scalars['ID']['output'];
  removeSystemAdmin: SystemAdmins;
  selfIsSystemAdmin: Scalars['Boolean']['output'];
};

export type SystemAdmins_addSystemAdminArgs = {
  usernameOrEmail: Scalars['String']['input'];
};

export type SystemAdmins_removeSystemAdminArgs = {
  usernameOrEmail: Scalars['String']['input'];
};

export enum TaskType {
  CLASSIFICATION = 'CLASSIFICATION',
  CLUSTERING = 'CLUSTERING',
  DETECTION = 'DETECTION',
  OTHER = 'OTHER',
  REGRESSION = 'REGRESSION',
  TRANSCRIPTION = 'TRANSCRIPTION',
  TRANSLATION = 'TRANSLATION',
  UNKNOWN = 'UNKNOWN',
}

export enum TemplateType {
  SYSTEM = 'SYSTEM',
  TASK = 'TASK',
  UNKNOWN = 'UNKNOWN',
}

export type Track = {
  __typename: 'Track';
  event: Scalars['String']['output'];
  properties?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type TrackEvent = {
  event: Scalars['String']['input'];
  properties?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type TrackOrError = Error | Track;

export type Transition = {
  __typename: 'Transition';
  desiredStage: Stage;
  id: Scalars['ID']['output'];
  sourceStage: Stage;
  state: TransitionState;
};

export enum TransitionState {
  APPROVED = 'APPROVED',
  CLOSED = 'CLOSED',
  COMMITTED = 'COMMITTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  UNKNOWN = 'UNKNOWN',
}

export type UpdateGcloudDeployment = {
  id: Scalars['ID']['input'];
  modelId: Scalars['String']['input'];
  promptId: Scalars['String']['input'];
  shortUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  useCaseId: Scalars['String']['input'];
};

export type UpdateInferenceJob = {
  experimentId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateJobInput = {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  jobId: Scalars['ID']['input'];
  resultJson?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLlmEndpointInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endpointId: Scalars['ID']['input'];
  modelId: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  promptId: Scalars['String']['input'];
};

export type UpdateUserInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  groupIds: Array<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type User = {
  __typename: 'User';
  addDevKey: User;
  company?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Timestamp']['output']>;
  /** @deprecated Field is deprecated! */
  defaultWorkspaceId?: Maybe<Scalars['ID']['output']>;
  deletePrimaryDevKey: User;
  deleteSecondaryDevKey: User;
  description: Scalars['String']['output'];
  devKey: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastLoginAt: Scalars['Timestamp']['output'];
  lastName: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  roles: Array<Scalars['String']['output']>;
  secondaryDevKey?: Maybe<Scalars['String']['output']>;
  shortUsername: Scalars['String']['output'];
  update: User;
  username: Scalars['String']['output'];
  workspaceId: Scalars['ID']['output'];
};

export type User_updateArgs = {
  defaultWorkspaceId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** @deprecated */
export type UserCollaborator = {
  __typename: 'UserCollaborator';
  canDeploy: Scalars['Boolean']['output'];
  canPredict: Scalars['Boolean']['output'];
  type: AccessType;
  user: User;
};

export type UserOrGroup = Group | User;

export type UserOrServiceAccountV2 = ServiceAccountV2 | UserV2;

export type UserV2 = {
  __typename: 'UserV2';
  company?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  groups: Array<ShortGroup>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  joinedAt: Scalars['Timestamp']['output'];
  lastName: Scalars['String']['output'];
  lastSignedIn: Scalars['Timestamp']['output'];
  orgId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
  workspaces: Array<ShortWorkspace>;
};

export type Users = {
  __typename: 'Users';
  pagination: PaginationResponse;
  users: Array<UserOrServiceAccountV2>;
};

export type UsersQuery = {
  groupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isOnlyServiceAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<PaginationQuery>;
  search?: InputMaybe<Scalars['String']['input']>;
  workspaceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type VersioningEntry = {
  __typename: 'VersioningEntry';
  commit: Scalars['String']['output'];
  keyLocations: Array<KeyLocations>;
  repository: Repository;
};

export enum Visibility {
  ORG_CUSTOM = 'ORG_CUSTOM',
  ORG_DEFAULT = 'ORG_DEFAULT',
  PRIVATE = 'PRIVATE',
  WORKSPACE_DEFAULT = 'WORKSPACE_DEFAULT',
}

/** @deprecated */
export type Workspace = {
  __typename: 'Workspace';
  alerts: Alerts;
  allowedActions: AllowedActions;
  build: BuildOrError;
  builds: Builds;
  containerRegistries: Array<WorkspaceContainerRegistryConfiguration>;
  createBuild: Build;
  createContainerRegistry: Workspace;
  createDataset: Dataset;
  createEndpoint: Endpoint;
  createFineTuningJob: FineTuningJob;
  createProject: Project;
  createRegisteredModel: RegisteredModel;
  createRepository: Repository;
  createServiceAccount: ServiceAccount;
  datasetVersions: DatasetVersions;
  datasets: Datasets;
  deleteContainerRegistry: Workspace;
  deleteServiceAccount: Workspace;
  endpoint: EndpointOrError;
  endpoints: Endpoints;
  experiments: Experiments;
  fineTuningJobs?: Maybe<Array<FineTuningJob>>;
  getFineTuningJob: FineTuningJobOrError;
  /** @deprecated Field is deprecated! */
  id: Scalars['ID']['output'];
  monitoredEntities: MonitoredEntities;
  monitoredModels: MonitoredModels;
  name: Scalars['String']['output'];
  namespace?: Maybe<Scalars['String']['output']>;
  oldId: Scalars['ID']['output'];
  organizationV2?: Maybe<OrganizationV2OrError>;
  projects: Projects;
  registeredModelVersions: RegisteredModelVersions;
  registeredModels: RegisteredModels;
  repositories: Repositories;
  repository: RepositoryOrError;
  runs: ExperimentRuns;
  serviceAccountByName: ServiceAccountOrError;
  serviceAccounts: Array<ServiceAccount>;
  type: WorkspaceType;
  updateContainerRegistry: Workspace;
};

/** @deprecated */
export type Workspace_alertsArgs = {
  next?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<AlertQuery>;
};

/** @deprecated */
export type Workspace_buildArgs = {
  id: Scalars['ID']['input'];
};

/** @deprecated */
export type Workspace_buildsArgs = {
  modelVersion?: InputMaybe<Scalars['ID']['input']>;
  runId?: InputMaybe<Scalars['ID']['input']>;
};

/** @deprecated */
export type Workspace_createBuildArgs = {
  compatibility?: InputMaybe<BuildHardwareCompatibilityInput>;
  external?: InputMaybe<Scalars['Boolean']['input']>;
  modelVersion?: InputMaybe<Scalars['ID']['input']>;
  runId?: InputMaybe<Scalars['ID']['input']>;
};

/** @deprecated */
export type Workspace_createContainerRegistryArgs = {
  containerRegistry?: InputMaybe<WorkspaceContainerRegistryConfigurationInput>;
};

/** @deprecated */
export type Workspace_createDatasetArgs = {
  name: Scalars['String']['input'];
  permission?: InputMaybe<PermissionInput>;
};

/** @deprecated */
export type Workspace_createEndpointArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<PermissionInput>;
};

/** @deprecated */
export type Workspace_createFineTuningJobArgs = {
  input: CreateFineTuningJobInput;
};

/** @deprecated */
export type Workspace_createProjectArgs = {
  name: Scalars['String']['input'];
  permission?: InputMaybe<PermissionInput>;
};

/** @deprecated */
export type Workspace_createRegisteredModelArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  permission?: InputMaybe<PermissionInput>;
  readmeText?: InputMaybe<Scalars['String']['input']>;
};

/** @deprecated */
export type Workspace_createRepositoryArgs = {
  name: Scalars['String']['input'];
  permission?: InputMaybe<PermissionInput>;
};

/** @deprecated */
export type Workspace_createServiceAccountArgs = {
  description: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/** @deprecated */
export type Workspace_datasetVersionsArgs = {
  query?: InputMaybe<DatasetVersionsQuery>;
};

/** @deprecated */
export type Workspace_datasetsArgs = {
  next?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<DatasetsQuery>;
};

/** @deprecated */
export type Workspace_deleteContainerRegistryArgs = {
  containerRegistryId: Scalars['ID']['input'];
};

/** @deprecated */
export type Workspace_deleteServiceAccountArgs = {
  serviceAccountId: Scalars['ID']['input'];
};

/** @deprecated */
export type Workspace_endpointArgs = {
  id: Scalars['ID']['input'];
};

/** @deprecated */
export type Workspace_endpointsArgs = {
  query?: InputMaybe<EndpointQuery>;
};

/** @deprecated */
export type Workspace_experimentsArgs = {
  next?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<ExperimentsQuery>;
};

/** @deprecated */
export type Workspace_getFineTuningJobArgs = {
  id: Scalars['Int']['input'];
};

/** @deprecated */
export type Workspace_monitoredEntitiesArgs = {
  next?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<MonitoredEntityQuery>;
};

/** @deprecated */
export type Workspace_monitoredModelsArgs = {
  query?: InputMaybe<MonitoredModelQuery>;
};

/** @deprecated */
export type Workspace_projectsArgs = {
  next?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<ProjectsQuery>;
};

/** @deprecated */
export type Workspace_registeredModelVersionsArgs = {
  query?: InputMaybe<RegisteredModelVersionsQuery>;
};

/** @deprecated */
export type Workspace_registeredModelsArgs = {
  next?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<RegisteredModelsQuery>;
};

/** @deprecated */
export type Workspace_repositoriesArgs = {
  next?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<RepositoriesQuery>;
};

/** @deprecated */
export type Workspace_repositoryArgs = {
  name: Scalars['String']['input'];
};

/** @deprecated */
export type Workspace_runsArgs = {
  next?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<ExperimentRunsQuery>;
};

/** @deprecated */
export type Workspace_serviceAccountByNameArgs = {
  name: Scalars['String']['input'];
};

/** @deprecated */
export type Workspace_updateContainerRegistryArgs = {
  containerRegistry?: InputMaybe<WorkspaceContainerRegistryConfigurationInput>;
  containerRegistryId: Scalars['ID']['input'];
};

/** @deprecated */
export type WorkspaceContainerRegistryConfiguration = {
  __typename: 'WorkspaceContainerRegistryConfiguration';
  base: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  secretKey: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

/** @deprecated */
export type WorkspaceContainerRegistryConfigurationInput = {
  base: Scalars['String']['input'];
  secretKey: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/** @deprecated */
export type WorkspaceOrError = Error | Workspace;

export type WorkspacePermission = {
  __typename: 'WorkspacePermission';
  group: Group;
  role: RoleV2;
};

export type WorkspacePermissionInput = {
  groupId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
};

export enum WorkspaceType {
  ORGANIZATION = 'ORGANIZATION',
  USER = 'USER',
}

export type WorkspaceV2 = {
  __typename: 'WorkspaceV2';
  allowedActions: AllowedActions;
  builtIn: Scalars['Boolean']['output'];
  createOrUpdateLlmDataset: LlmDatasetOrError;
  createOrUpdateLlmExperiments: LlmExperimentsOrError;
  createOrUpdateLlmProject: LlmProjectOrError;
  createOrUpdateLlmPrompt: LlmPromptOrError;
  createdTimestamp: Scalars['Timestamp']['output'];
  delete: OrganizationV2OrError;
  deleteLlmDataset: WorkspaceV2;
  deleteLlmProject: WorkspaceV2;
  deleteLlmPrompt: WorkspaceV2;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  llmDataset: LlmDatasetOrError;
  llmDatasets: LlmDatasetsOrError;
  llmExperiment: GetExperimentResponseOrError;
  llmExperiments: LlmExperimentsOrError;
  llmProject: LlmProjectOrError;
  llmProjects: LlmProjectsOrError;
  llmPrompt: LlmPromptOrError;
  llmPrompts: LlmPromptsOrError;
  name: Scalars['String']['output'];
  namespace?: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  permissions: Array<WorkspacePermission>;
  update: WorkspaceV2;
  updatedTimestamp: Scalars['Timestamp']['output'];
};

export type WorkspaceV2_createOrUpdateLlmDatasetArgs = {
  input?: InputMaybe<LlmDatasetInput>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type WorkspaceV2_createOrUpdateLlmExperimentsArgs = {
  inputs: Array<LlmExperimentInput>;
};

export type WorkspaceV2_createOrUpdateLlmProjectArgs = {
  input?: InputMaybe<LlmProjectInput>;
};

export type WorkspaceV2_createOrUpdateLlmPromptArgs = {
  input?: InputMaybe<LlmPromptInput>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type WorkspaceV2_deleteLlmDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type WorkspaceV2_deleteLlmProjectArgs = {
  id: Scalars['ID']['input'];
};

export type WorkspaceV2_deleteLlmPromptArgs = {
  id: Scalars['ID']['input'];
};

export type WorkspaceV2_llmDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type WorkspaceV2_llmExperimentArgs = {
  id: Scalars['ID']['input'];
};

export type WorkspaceV2_llmProjectArgs = {
  id: Scalars['ID']['input'];
};

export type WorkspaceV2_llmPromptArgs = {
  id: Scalars['ID']['input'];
};

export type WorkspaceV2_llmPromptsArgs = {
  input?: InputMaybe<LlmPromptQuery>;
};

export type WorkspaceV2_updateArgs = {
  input: WorkspaceV2Input;
};

export type WorkspaceV2Input = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  namespace?: InputMaybe<Scalars['String']['input']>;
  permissions: Array<WorkspacePermissionInput>;
};
