import { MonitoringWidgetType } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetType';
import matchType from 'shared/utils/matchType';
import InfoIcon from 'shared/view/elements/InfoIcon/InfoIcon';

interface Props {
  widgetType: MonitoringWidgetType;
}

export const WidgetInfoIcon = (props: Props) => {
  const text = getWidgetInfoTextType(props.widgetType);

  if (text) {
    return <InfoIcon tooltip={text} iconSize="1x" />;
  }

  return null;
};

const getWidgetInfoTextType = (type: MonitoringWidgetType): string | null => {
  return matchType(
    {
      scatterPlot: () =>
        'Data points are downsampled without impacting the correctness of the calculation.',
      barChart: () => null,
      histograms: () => null,
      singleMetric: () => null,
      confusionMatrix: () => null,
      curve: () => null,
      table: () => null,
      timeSeries: () => null,
      notInitialized: () => null,
    },
    type
  );
};
