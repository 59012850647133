import { gql } from '@apollo/client';
import { useEffect } from 'react';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';

import * as ModelVersionsTypes from './graphql-types/useModelVersionsForBuild.generated';

const MODEL_VERSIONS_FOR_BUILD = gql`
  query ModelVersionsForBuild($registeredModelId: ID!) {
    registeredModel(id: $registeredModelId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        versions {
          versions {
            id
            version
            registeredModel {
              id
              name
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

const useModelVersions = (
  variables: ModelVersionsTypes.ModelVersionsForBuildVariables | undefined
) => {
  const [loadQuery, query] = useCustomLazyQuery<
    ModelVersionsTypes.ModelVersionsForBuild,
    ModelVersionsTypes.ModelVersionsForBuildVariables
  >(MODEL_VERSIONS_FOR_BUILD);

  useEffect(() => {
    if (variables) {
      loadQuery({
        variables,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables?.registeredModelId]);

  const { data: modelVersions, communication: loadingModelVersions } =
    resultToCommunicationWithData(
      (res) => mapDataOrError(res.registeredModel, (x) => x.versions.versions),
      query
    );

  return {
    modelVersions,
    loadingModelVersions,
  };
};

export default useModelVersions;
