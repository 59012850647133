import { CurveChartWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/CurveChartWidget';
import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';

import { MonitoringWidgetProps } from '../shared/types';
import PrecisionRecallWidget from './RoC/PrecisionRecallWidget/PrecisionRecallWidget';
import RocWidget from './RoC/RocWidget/RocWidget';

const CurveChartWidgetView = (
  props: MonitoringWidgetProps<CurveChartWidget>
) => {
  switch (props.widget.variant) {
    case 'roc':
      return <RocWidget {...props} widget={props.widget} />;
    case 'precisionRecall':
      return <PrecisionRecallWidget {...props} widget={props.widget} />;
    default:
      exhaustiveCheck(props.widget);
  }
};

export default CurveChartWidgetView;
