import { Typography } from '@mui/material';
import { last } from 'ramda';

import {
  MonitoringModelStatus,
  MonitoringModelStatusCheck,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringModelStatus';
import matchBy from 'shared/utils/matchBy';

interface Props {
  status: MonitoringModelStatus;
  statusCheck: MonitoringModelStatusCheck | null;
  children: JSX.Element;
}

const MonitoringModelStatusHandler = (props: Props) => {
  if (!props.statusCheck) {
    return props.children;
  }

  const errorMessage = getErrorMessage({
    statusCheck: props.statusCheck,
    status: props.status,
  });

  return errorMessage ? (
    <Typography variant="subtitle1" color="error" sx={{ textAlign: 'center' }}>
      {errorMessage}
    </Typography>
  ) : (
    props.children
  );
};

const getErrorMessage = (props: {
  status: MonitoringModelStatus;
  statusCheck: MonitoringModelStatusCheck;
}): string | null => {
  return matchBy(
    props.statusCheck,
    'type'
  )({
    oneOf: () => {
      if (
        props.statusCheck.items.every((item) => !props.status.includes(item))
      ) {
        if (props.statusCheck.items.length === 2) {
          return `No ${props.statusCheck.items[0]} or ${props.statusCheck.items[1]} data logged`;
        }
        return `No ${props.statusCheck.items.sort().join(', ')} data logged`;
      }

      return null;
    },
    required: () => {
      if (props.statusCheck.items.length === 0) {
        return 'No data logged';
      }

      const notIncluded = props.statusCheck.items
        .filter((item) => !props.status.includes(item))
        .sort();

      if (notIncluded.length > 0) {
        if (notIncluded.length > 1) {
          const lastItem = last(notIncluded);

          return `No ${notIncluded
            .slice(0, notIncluded.length - 1)
            .join(', ')} and ${lastItem as string} data logged`;
        }

        return `No ${notIncluded.join(', ')} data logged`;
      }

      return null;
    },
  });
};

export default MonitoringModelStatusHandler;
