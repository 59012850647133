import { useMemo } from 'react';

import { ChartInterval, makeIntervals } from '../shared/intervals';
import BaseGroupedBarChart, {
  BaseGroupedBarChartProps,
  GetBarGroupXArguments,
  GroupedBarData,
} from './BaseGroupedBarChart';

interface Props<Meta>
  extends Omit<
    BaseGroupedBarChartProps<Meta>,
    'data' | 'x0ScaleDomain' | 'getBarGroupX'
  > {
  data: Array<GroupedIntervalBarChartData<Meta>>;
}

export interface GroupedIntervalBarChartData<Meta> extends ChartInterval {
  barsData: Array<GroupedBarData<Meta>>;
}

const getBarGroupX = ({ x0, x0Scale }: GetBarGroupXArguments) =>
  x0 + x0Scale.step() / 2;

const GroupedIntervalBarChart = <Meta extends unknown>({
  data,
  ...chartProps
}: Props<Meta>) => {
  const chartData = useMemo(
    () =>
      data.map(({ barsData, intervalStart }) => ({
        key: intervalStart.toString(),
        barsData,
      })),
    [data]
  );

  const x0ScaleDomain = useMemo(() => makeIntervals(data).map(String), [data]);

  return (
    <BaseGroupedBarChart
      {...chartProps}
      data={chartData}
      x0ScaleDomain={x0ScaleDomain}
      getBarGroupX={getBarGroupX}
    />
  );
};

export default GroupedIntervalBarChart;
