import { ComputeObject } from 'shared/utils/types';

import { IAttribute, IAttributeValue } from './Attribute';
import { IWidgetBlob } from './Widget/WidgetBlob';

export type IAttributeWithWidgetBlob =
  | AttributeFromWidgetBlob<IAttributeWidgetBlob>
  | IRegularAttribute;

type IAttributeWidgetBlob = IWidgetBlob;

type AttributeFromWidgetBlob<T extends IAttributeWidgetBlob> =
  T extends IAttributeWidgetBlob
    ? ComputeObject<IAttribute<T> & { type: T['type'] }>
    : never;

type IRegularAttribute = ComputeObject<IAttribute & { type: 'regular' }>;

// todo rename?
// ts-unused-exports:disable-next-line
export const makeRegularAttribute = ({
  key,
  value,
}: {
  key: string;
  value: IAttributeValue;
}): IRegularAttribute => ({
  key,
  type: 'regular',
  value,
});
