import { createAction } from 'typesafe-actions';

import { RestrictedGraphqlError } from 'shared/graphql/ErrorFragment';

export const setRestrictedGraphqlError = createAction(
  `restrictedGraphqlError/SET_ERROR`
)<{
  pathname: string;
  error: RestrictedGraphqlError;
}>();

export const closeAlert = createAction(`restrictedGraphqlError/CLOSE_ALERT`)();

export const resetState = createAction('restrictedGraphqlError/RESET_STATE')();
