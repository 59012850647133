import { useState } from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';

import CellRendererEmpty from 'shared/view/elements/DataGrid/columns/CellRendererEmpty';

import { Button } from '../../Button';
import Linkify from '../../Linkify/Linkify';

type CellRendererExpandableStringProps = TypographyProps & {
  value: string | undefined | null;
  dataTest?: string;
  charactersLimit?: number;
};

const EXPANDABLE_TYPOGRAPHY_VARIANT = 'body2';

export default function CellRendererExpandableString(
  props: CellRendererExpandableStringProps
) {
  const { value, charactersLimit = 200 } = props;
  const [expanded, setExpanded] = useState(false);

  if (!value) {
    return <CellRendererEmpty dataTest={props.dataTest} />;
  }

  const exceedsLimit = value.length > charactersLimit;

  return (
    <Box py={1}>
      <Linkify>
        <Typography
          {...props}
          variant={props.variant ?? EXPANDABLE_TYPOGRAPHY_VARIANT}
        >
          {expanded || !exceedsLimit
            ? value
            : value.slice(0, charactersLimit) + '...'}
        </Typography>
      </Linkify>
      {exceedsLimit ? (
        <Button
          onClick={() => setExpanded((e) => !e)}
          variant="text"
          isLoading={false}
          size="small"
          sx={{ px: 0, py: 0.25 }}
        >
          {expanded ? 'Show less' : 'Show more'}
        </Button>
      ) : null}
    </Box>
  );
}
