import { ExperimentRunWithDisplayedFields } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import matchBy from 'shared/utils/matchBy';
import { WidgetSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/WidgetSettings';

import {
  getBarChartSpec,
  getExperimentRunsForBarChartSpec,
} from './BarChartVegaSpec';
import {
  getBoxPlotSpec,
  getExperimentRunsForBoxPlotSpec,
} from './BoxPlotVegaSpec';
import {
  getExperimentRunsForLineChartSpec,
  getLineChartSpec,
} from './LineChartVegaSpec';
import {
  getExperimentRunsForScatterChartSpec,
  getScatterChartSpec,
} from './ScatterChartVegaSpec';
import { WidgetVisualizationSpec } from './shared/WidgetVisualizationSpec';

type VegaWidgetSettings = Exclude<
  WidgetSettings,
  {
    type:
      | 'parallelCoordinateChart'
      | 'observationCharts'
      | 'table'
      | 'markdown';
  }
>;

type VegaSpecWithNamedDataSource = {
  spec: WidgetVisualizationSpec;
  data: Record<'experimentRuns', Array<Record<string, unknown>>>;
};

export const generateSpecWithNamedDataSource = (
  settings: VegaWidgetSettings,
  experimentRuns: ExperimentRunWithDisplayedFields[]
): VegaSpecWithNamedDataSource => {
  const dataSourceName = 'experimentRuns';
  return {
    spec: generateSpec(dataSourceName, settings),
    data: {
      [dataSourceName]: matchBy(
        settings,
        'type'
      )<Array<Record<string, unknown>>>({
        barChart: (barChartSettings) =>
          getExperimentRunsForBarChartSpec(barChartSettings, experimentRuns),
        boxPlot: () => getExperimentRunsForBoxPlotSpec(experimentRuns),
        lineChart: () => getExperimentRunsForLineChartSpec(experimentRuns),
        scatterChart: () =>
          getExperimentRunsForScatterChartSpec(experimentRuns),
      }),
    },
  };
};

const generateSpec = (
  dataSourceName: string,
  settings: VegaWidgetSettings
): WidgetVisualizationSpec => {
  return {
    autosize: {
      type: 'fit',
      contains: 'padding',
    },
    ...matchBy(
      settings,
      'type'
    )({
      lineChart: (s) => getLineChartSpec(dataSourceName, s),
      barChart: (s) => getBarChartSpec(dataSourceName, s),
      scatterChart: (s) => getScatterChartSpec(dataSourceName, s),
      boxPlot: (s) => getBoxPlotSpec(dataSourceName, s),
    }),
  };
};
