import { gql } from '@apollo/client';
import { useEffect } from 'react';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { USERV2_FRAGMENT } from 'shared/graphql/User/User';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { combineCommunications } from 'shared/utils/redux/communication/combineCommunications';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as UseOrganizationsList from './useOrganizationsList';
import * as Types from './graphql-types/useCreateOrganization.generated';
import { useLoadUserWorkspaces } from '../../hooks/useLoadUserWorkspaces';

export { Types };

export const QUERY = gql`
  mutation CreateOrganizationV2(
    $name: String!
    $description: String!
    $adminEmails: [String!]!
  ) {
    createOrganizationV2(
      input: {
        adminEmails: $adminEmails
        description: $description
        name: $name
      }
    ) {
      id
      name
      description
      admins {
        id
        ...UserV2Data
      }
      allowedActions {
        ...AllowedActionsData
      }
    }
  }
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${USERV2_FRAGMENT}
`;

export const useCreateOrganization = (onCompleted?: () => void) => {
  const [loadOrganizations, resOrganizations] =
    useCustomLazyQuery<UseOrganizationsList.Types.OrganizationsV2List>(
      UseOrganizationsList.QUERY
    );

  const { communication: loadingOrganizations } =
    useMemoizedResultToCommunicationWithData({
      memoizedConvert: (x) => x,
      queryResult: resOrganizations,
    });

  const loadUserWorkspacesApi = useLoadUserWorkspaces();

  const [run, communication] = useCustomMutation<
    Types.CreateOrganizationV2,
    Types.CreateOrganizationV2Variables
  >(
    QUERY,
    { context: 'creating organization' },
    {
      onCompleted: () => {
        loadOrganizations();
        loadUserWorkspacesApi.load();
      },
    }
  );
  const resCommunication = combineCommunications([
    communication,
    loadingOrganizations,
    loadUserWorkspacesApi.communication,
  ]);
  useEffect(() => {
    if (onCompleted && resCommunication.isSuccess) {
      onCompleted();
    }
  }, [onCompleted, resCommunication.isSuccess]);

  return {
    run,
    communication: combineCommunications([
      communication,
      loadingOrganizations,
      loadUserWorkspacesApi.communication,
    ]),
  };
};
