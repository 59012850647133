import {
  // eslint-disable-next-line no-restricted-imports
  useParams,
  useLocation,
  NavigateProps,
  Navigate,
} from 'react-router-dom';
import { isEmpty } from 'ramda';
import { useSelector } from 'react-redux';

import { OmitStrict } from 'shared/utils/types';
import { Route } from 'shared/routes/shared/routesTreeBuilder';
import { selectCurrentWorkspaceName } from 'features/workspaces';
import routes from 'shared/routes';
import GlobalPreloader from 'shared/view/elements/GlobalPreloader/GlobalPreloader';

export const CustomRedirect = ({
  route,
  redirectAll = false,
  ...others
}: OmitStrict<NavigateProps, 'to'> & {
  redirectAll?: boolean;
  route: Route<any, any, any, any, Record<string, any>>;
}) => {
  const params = useParams();
  const location = useLocation();
  let to = route.getRedirectPath(params);

  if (redirectAll && params['*']) {
    to = `${to}/${params['*']}`;
  }

  if (!isEmpty(location.search)) {
    const queryParams = route.parseQueryParams(location.search);
    if (queryParams) {
      to = route.getRedirectPathWithQueryParams({ params, queryParams });
      if (redirectAll && params['*']) {
        const [path, qsearch] = to.split('?');
        to = `${path}/${params['*']}?${qsearch}`;
      }
    }
  }

  return <Navigate to={to} {...others} />;
};

export const DefaultRedirect = () => {
  const workspaceName = useSelector(selectCurrentWorkspaceName);
  const isCurrentWorkspaceExisted = Boolean(workspaceName);

  return isCurrentWorkspaceExisted ? (
    <Navigate to={routes.homepage.getRedirectPath({ workspaceName })} />
  ) : (
    <></>
  );
};

// component to force the replacement of the current url or new url
export const WindowLocationRedirect = ({ url }: { url?: string }) => {
  let urlToRedirect = window.location.href;

  if (url) {
    urlToRedirect = url;
  }
  window.location.replace(urlToRedirect);

  return <GlobalPreloader />;
};
