import { gql } from '@apollo/client';

import { MachineConfigInfo } from './graphql-types/MachineConfigInfo.generated';

export const MACHINE_CONFIG_INFO_QUERY = gql`
  fragment MachineConfigInfo on EndpointEnvironmentUpdateRequest {
    autoscaling {
      metrics {
        id
        parameters {
          name
          value
        }
      }
      quantities {
        maxReplicas
        minReplicas
        maxScale
        minScale
      }
    }
    resources {
      cpuMillis
      memory
      nvidiaGpu {
        number
        model
      }
    }
    env {
      name
      value
    }
    isolation
  }
`;

export const AUTOSCALING_METRICS_QUERY = gql`
  fragment AutoscalingMetricsData on EndpointEnvironment {
    id
    autoscalingMetrics {
      id
      description
      name
      parameters {
        name
        description
        type
      }
    }
  }
`;

export type IAutoscaling = NonNullable<MachineConfigInfo['autoscaling']>;
export type IResources = NonNullable<MachineConfigInfo['resources']>;
export type IResourcesGpu = IResources['nvidiaGpu'];
export type IEnvironmentVariable = NonNullable<MachineConfigInfo['env']>;
