import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';
import { ExtractByTypename } from 'shared/utils/types';
import {
  EvaluationData,
  convertVersionToEvaluation,
} from 'features/evaluations/shared/utils/evaluation';
import { Header } from 'features/evaluations/shared/utils/parseCsvFileToAttributeData';

import {
  EVALUATION_SUMMARY,
  EvaluationSummary,
  EvaluationSummaryVariables,
} from '../graphql';

type Version = ExtractByTypename<
  EvaluationSummary['registeredModelVersion'],
  'RegisteredModelVersion'
>;

export type Evaluation = Version & {
  rows: EvaluationData[];
  approvedRows: EvaluationData[];
  rejectedRows: EvaluationData[];
  labelsDistribution: Record<string, number>;
  headers: Header[];
  modelId?: string;
  configuration?: string;
};

export const useEvaluationSummary = ({
  evaluationId,
}: {
  evaluationId: string;
}) => {
  const query = useCustomQuery<EvaluationSummary, EvaluationSummaryVariables>(
    EVALUATION_SUMMARY,
    { variables: { evaluationId } }
  );

  const { data: evaluation, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return { evaluation, communication };
};

const convertResponse = ({ registeredModelVersion }: EvaluationSummary) =>
  mapDataOrError(registeredModelVersion, convertVersionToEvaluation);
