// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MuiMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu';
import { useState } from 'react';

import { OmitStrict } from 'shared/utils/types';

type Props = {
  children: React.ReactNode;
  renderAnchor: (onClick: (e: React.MouseEvent<HTMLElement>) => void) => void;
  isForceOpen?: boolean;
} & OmitStrict<
  MuiMenuProps,
  'children' | 'open' | 'anchorEl' | 'onClose' | 'onClick'
>;

const Menu = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { renderAnchor, children, isForceOpen, ...rest } = props;

  const [isOpenState, changeIsOpenState] = useState(false);

  const isOpen = Boolean(isOpenState) || Boolean(isForceOpen);

  return (
    <>
      {renderAnchor((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        changeIsOpenState(true);
      })}
      {/* need avoid rendering menu when it is closed because mui menu 
        always render children so it can affect performance */}
      {isOpen ? (
        <MuiMenu
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          {...rest}
          anchorEl={anchorEl}
          open={isOpen}
          onClose={() => {
            changeIsOpenState(false);
          }}
          onClick={() => {
            // close menu after clicking on menu items
            changeIsOpenState(false);
          }}
        >
          {children}
        </MuiMenu>
      ) : null}
    </>
  );
};

export default Menu;
