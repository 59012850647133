import { AlertStatus } from 'generated/types';
import { SingleMetricHealthWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/SingleMetricWidget';
import { formatWithDefaultPrecision } from 'shared/utils/formatters/formatWithDefaultPrecision';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';

import { MonitoringWidgetProps } from '../../shared/types';
import SingleMetric from '../shared/SingleMetric/SingleMetric';

const SingleMetricHealthWidgetView = (
  props: MonitoringWidgetProps<SingleMetricHealthWidget>
) => {
  const alertingAlerts = props.widgetExternalDeps.alerts
    .filter((alert) => alert.settings.type === props.widget.variant.alertType)
    .filter((alert) => alert.status === AlertStatus.ALERTING);

  return (
    <SingleMetric
      color={alertingAlerts.length > 0 ? 'red' : 'green'}
      size={props.size}
      value={Number(
        formatWithDefaultPrecision(parseGraphQLNumber(alertingAlerts.length))
      )}
    />
  );
};

export default SingleMetricHealthWidgetView;
