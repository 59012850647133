import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import {
  CREATE_TRANSITION_QUERY,
  CreateTransition,
  CreateTransitionVariables,
} from '../graphql';

export const useCreateTransition = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [createTransition, creatingTransition] = useCustomMutation<
    CreateTransition,
    CreateTransitionVariables
  >(
    CREATE_TRANSITION_QUERY,
    { context: 'creating transition' },
    { onCompleted }
  );

  return {
    creatingTransition,
    createTransition,
  };
};
