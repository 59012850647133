import { useCallback, useMemo, useState } from 'react';
import { isEmpty, isNil } from 'ramda';

import { PredicateOperator, StringPredicate } from 'generated/types';
import isNotNil from 'shared/utils/isNotNill';
import { useCaseLabel } from 'features/catalog/registeredModel/catalogList/hooks/useFilter';

import {
  handleTaskType,
  handleDataType,
  handleDateUpdated,
  handleHasProductionVersions,
  handleLabels,
  handleSearch,
  handleOwner,
  handleHasEndpoints,
  handleHasNoEndpoints,
} from './handlers';

import { FilterOptionProps, UseFilterProps, CurrentFilterProps } from '.';

export const useFilter = (
  additionalActionOnFilterChange?: (x: FilterOptionProps) => void
): UseFilterProps => {
  const [currentFilters, setCurrentFilters] = useState<CurrentFilterProps>({});
  const [stringPredicates, setStringPredicates] = useState<StringPredicate[]>([
    {
      key: 'label',
      operator: PredicateOperator.EQ,
      value: useCaseLabel,
    },
  ]);

  const isFiltering = useMemo(() => {
    const values = Object.values(currentFilters);

    return isEmpty(values)
      ? false
      : Object.values(currentFilters)
          .map((el) => {
            if (typeof el === 'boolean') return el;

            return !isNil(el) && !isEmpty(el);
          })
          .reduce((acc, e) => acc || e);
  }, [currentFilters]);

  const onCurrentFiltersChange = useCallback(
    (option: FilterOptionProps) => {
      if (isNotNil(option.value)) {
        switch (option.type) {
          case 'hasProductionVersions':
            handleHasProductionVersions({
              currentFilters,
              stringPredicates,
              value: option.value,
              setCurrentFilters,
              setStringPredicates,
            });
            break;

          case 'hasEndpoints':
            handleHasEndpoints({
              currentFilters,
              stringPredicates,
              value: option.value,
              setCurrentFilters,
              setStringPredicates,
            });
            break;

          case 'hasNoEndpoints':
            handleHasNoEndpoints({
              currentFilters,
              stringPredicates,
              value: option.value,
              setCurrentFilters,
              setStringPredicates,
            });
            break;

          case 'taskType':
            handleTaskType({
              currentFilters,
              stringPredicates,
              value: option.value,
              setCurrentFilters,
              setStringPredicates,
            });
            break;

          case 'dataType':
            handleDataType({
              currentFilters,
              stringPredicates,
              value: option.value,
              setCurrentFilters,
              setStringPredicates,
            });
            break;

          case 'dateUpdated':
            handleDateUpdated({
              currentFilters,
              stringPredicates,
              value: option.value,
              setCurrentFilters,
              setStringPredicates,
            });
            break;

          case 'group':
            setCurrentFilters({
              ...currentFilters,
              group: option.value,
            });
            break;

          case 'organization':
            setCurrentFilters({
              ...currentFilters,
              organization: option.value,
            });
            break;

          case 'label':
            handleLabels({
              currentFilters,
              stringPredicates,
              value: option.value,
              setCurrentFilters,
              setStringPredicates,
            });
            break;

          case 'search':
            handleSearch({
              currentFilters,
              stringPredicates,
              value: option.value,
              setCurrentFilters,
              setStringPredicates,
            });
            break;

          case 'owner':
            handleOwner({
              currentFilters,
              stringPredicates,
              value: option.value,
              setCurrentFilters,
              setStringPredicates,
            });
            break;

          case 'clear':
            setCurrentFilters({});
            setStringPredicates([]);
            break;

          default:
            break;
        }
        additionalActionOnFilterChange?.(option);
      }
    },
    [additionalActionOnFilterChange, currentFilters, stringPredicates]
  );

  return {
    stringPredicates,
    currentFilters,
    onCurrentFiltersChange,
    isFiltering,
  };
};
