import cn from 'classnames';
import { times } from 'lodash';

import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

// eslint-disable-next-line postcss-modules/no-unused-class
import styles from '../CompareTable.module.css';
import DiffCount from '../DiffCount/DiffCount';

export default function ExpansionProperty(props: {
  name: string;
  isExpanded: boolean;
  highlight?: boolean;
  colName?: string;
  compareEntitiesCount?: number;
  toggleExpansion: (name: string) => void;
  children: React.ReactNode;
  itemsCount: number;
  diffCount: number;
}) {
  return (
    <tbody className={styles.expansionProperty}>
      <tr
        className={cn({
          [styles.different]: props.diffCount !== 0,
          [styles.highlight]: props.highlight,
        })}
      >
        <td
          className={styles.expansionProperty__nameColumn}
          data-name={props.name.toLowerCase()}
          data-test={`expansion-property`}
          onClick={() => props.toggleExpansion(props.name)}
        >
          <div className={styles.expansionProperty__nameColumnContent}>
            {props.name}
            {/* <Count count={props.itemsCount} /> */}
            <div className={styles.expansionProperty__diffCount}>
              <DiffCount value={props.diffCount} withOutline={false} />
            </div>
            <div className={styles.expansionProperty__toggleExpansion}>
              <IconAwesomeInfo
                size="lg"
                icon={props.isExpanded ? ICONS.angleUp : ICONS.angleDown}
              />
            </div>
          </div>
        </td>
        {props.colName ? (
          times(props.compareEntitiesCount ?? 0, (i) => (
            <td className={styles.expansionProperty__nameColumn} key={i}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions*/}
              {`${props.colName} ${i + 1}`}
            </td>
          ))
        ) : (
          <td colSpan={1000} />
        )}
      </tr>
      {props.isExpanded && props.children}
    </tbody>
  );
}
