import { AlertAggregationWindow } from 'generated/types';
import { TIME_UNITS_IN_MILLIS } from 'shared/utils/duration';
import matchType from 'shared/utils/matchType';

import { MonitoringAggregation } from '../MonitoringAggregation';

export const defaultAggregationWindow = AlertAggregationWindow.HOUR;

export const getAlertAggregationWindowLabel = (
  frequency: AlertAggregationWindow
) => {
  return matchType(
    {
      [AlertAggregationWindow.UNKNOWN]: () => 'Unknown',
      [AlertAggregationWindow.FIVE_MINUTES]: () => 'Five minutes',
      [AlertAggregationWindow.HOUR]: () => 'Hour',
      [AlertAggregationWindow.DAY]: () => 'Day',
    },
    frequency
  );
};

const getAlertAggregationWindowValue = (
  aggregationWindow: AlertAggregationWindow
): number => {
  return matchType(
    {
      [AlertAggregationWindow.UNKNOWN]: () => 0,
      [AlertAggregationWindow.FIVE_MINUTES]: () =>
        TIME_UNITS_IN_MILLIS.minute * 5,
      [AlertAggregationWindow.HOUR]: () => TIME_UNITS_IN_MILLIS.hour,
      [AlertAggregationWindow.DAY]: () => TIME_UNITS_IN_MILLIS.day,
    },
    aggregationWindow
  );
};

export const getAggregationByAlertAggregationWindow = (
  aggregationWindow: AlertAggregationWindow
): MonitoringAggregation => ({
  timeWindow: getAlertAggregationWindowValue(aggregationWindow),
});

export const alertAggregationWindowsForSelection = [
  AlertAggregationWindow.FIVE_MINUTES,
  AlertAggregationWindow.HOUR,
  AlertAggregationWindow.DAY,
];
