/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';

import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import {
  UPDATE_REQUEST_FRAGMENT,
  BUILD_OR_ERROR_FRAGMENT,
} from 'shared/graphql/Deployment/Endpoint/Endpoint';
import { OmitStrict, ExtractByTypename } from 'shared/utils/types';
import {
  mapDataOrError,
  RestrictedGraphqlErrorOrData,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { KAFKA_CONFIG_FRAGMENT } from 'shared/graphql/Deployment/Endpoint/KafkaConfig/KafkaConfig';
import { KAFKA_CONFIGURATIONS_SIMPLIFIED_LIST_FRAGMENT } from 'shared/graphql/Deployment/Endpoint/KafkaConfig/KafkaConfiguration';
import { EndpointWithKafkaConfigurations } from 'features/deployment/canary/shared/EndpointWithKafkaConfigurations';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';

import * as GraphqlTypes from './graphql-types/endpointQuery.generated';

type Workspace = ExtractByTypename<
  GraphqlTypes.Endpoint['workspace'],
  'Workspace'
>;
export type Endpoint = ExtractByTypename<Workspace['endpoint'], 'Endpoint'>;
export type IAutoscalingMetric =
  Endpoint['environments'][0]['autoscalingMetrics'][0];
export type IBuild = ExtractByTypename<
  Endpoint['environments'][0]['components'][0]['build'],
  'Build'
>;
export type RegisteredModelVersion = ExtractByTypename<
  IBuild['source'],
  'RegisteredModelVersion'
>;

const ENDPOINT = gql`
  query Endpoint($workspaceName: String!, $organizationId: ID, $id: ID!) {
    kafkaConfigurations {
      id
      ...KafkaConfigurationsSimplifiedListData
    }
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoint(id: $id) {
          ... on Error {
            ...ErrorData
          }
          ... on Endpoint {
            id
            workspace {
              id
              type
            }
            owner {
              ...OwnerData
            }

            allowedActions {
              ...AllowedActionsData
            }
            environments {
              id
              status
              kafka {
                ...KafkaConfigData
              }
              rolloutStatus {
                time
                components {
                  buildID
                  ratio
                }
              }
              components {
                buildID
                status
                build {
                  ...BuildOrErrorData
                }
              }
              canaryRules {
                id
                name
                description
                category
                parameters {
                  name
                  description
                  type
                }
              }
              ...UpdateRequestData
            }
            dateCreated
            dateUpdated
            description
            labels
            path
            workspace {
              id
              ...WorkspaceData
            }
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${BUILD_OR_ERROR_FRAGMENT}
  ${UPDATE_REQUEST_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${KAFKA_CONFIG_FRAGMENT}
  ${KAFKA_CONFIGURATIONS_SIMPLIFIED_LIST_FRAGMENT}
`;

export const useEndpointQuery = (
  { workspaceName, id, organizationId }: GraphqlTypes.EndpointVariables,
  options?: OmitStrict<
    QueryHookOptions<GraphqlTypes.Endpoint, GraphqlTypes.EndpointVariables>,
    'variables'
  >
) => {
  const query = useCustomQuery<
    GraphqlTypes.Endpoint,
    GraphqlTypes.EndpointVariables
  >(ENDPOINT, {
    variables: {
      workspaceName,
      id,
      organizationId,
    },
    ...options,
  });

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return {
    data,
    communication,
    reload: query.refetch,
  };
};

const convertResponse = (
  data: GraphqlTypes.Endpoint
): RestrictedGraphqlErrorOrData<EndpointWithKafkaConfigurations<Endpoint>> => {
  return mapDataOrError(
    data.workspace,
    (x) => x.endpoint,
    (endpoint) => ({
      endpoint,
      kafkaConfigurations: data.kafkaConfigurations.configurations,
    })
  );
};
