import { FC } from 'react';
import IconButton from '@mui/material/IconButton';
// eslint-disable-next-line no-restricted-imports
import { Link } from 'react-router-dom';

import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { IconAwesomeProps } from 'shared/view/elements/IconAwesome/IconAwesomeProps';
import { EXTERNAL_LINK_PROPS } from 'shared/utils/globalConstants/externalLinkProps';
import { useIconClickable } from 'shared/view/elements/IconAwesome/hooks/useIconClickable';

interface Props extends IconAwesomeProps {
  to: string;
  download?: string;
  isExternal?: boolean;
  dataTest?: string;
  disabled?: boolean;
  reversedColors?: boolean;
  onClick?: () => void;
}

// todo create folder 'link' (or similar) and move this file there
// todo after removing Icon components rename this one into IconLink
export const IconAwesomeLink: FC<React.PropsWithChildren<Props>> = (props) => {
  const { color, sx } = useIconClickable(props);

  const icon = <IconAwesomeCustomizable {...props} nativeProps={{ color }} />;

  return (
    <IconButton
      sx={sx}
      download={props.download}
      to={props.to}
      data-test={props.dataTest}
      component={Link}
      {...(props.isExternal ? EXTERNAL_LINK_PROPS : {})}
    >
      {icon}
    </IconButton>
  );
};
