import { combineReducers } from 'redux';

import { makeCommunicationReducerFromResetableAsyncAction } from 'shared/utils/redux/actions';

import {
  checkUserAuthentication,
  loginByEmail,
  logoutUser,
  registerUser,
  registerUserByEmail,
  updateUsername,
  authenticateUserByExternalService,
  loginByLdap,
  updateUserInfo,
} from '../actions';
import { IUserState } from '../types';

export default combineReducers<IUserState['communications']>({
  authenticatingUserByExternalService:
    makeCommunicationReducerFromResetableAsyncAction(
      authenticateUserByExternalService
    ),
  checkingUserAuthentication: makeCommunicationReducerFromResetableAsyncAction(
    checkUserAuthentication
  ),
  logouting: makeCommunicationReducerFromResetableAsyncAction(logoutUser),
  updatingUsername:
    makeCommunicationReducerFromResetableAsyncAction(updateUsername),
  registrationUser:
    makeCommunicationReducerFromResetableAsyncAction(registerUser),
  updatingUserInfo:
    makeCommunicationReducerFromResetableAsyncAction(updateUserInfo),
  registrationUserByEmail:
    makeCommunicationReducerFromResetableAsyncAction(registerUserByEmail),
  loginingByEmail:
    makeCommunicationReducerFromResetableAsyncAction(loginByEmail),
  loginByLdap: makeCommunicationReducerFromResetableAsyncAction(loginByLdap),
});
