/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { IWorkspace } from 'shared/models/Workspace';
import isNotNil from 'shared/utils/isNotNill';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { ExtractByTypename } from 'shared/utils/types';

import * as GraphqlTypes from './graphql-types/endpointsListQuery.generated';

type Workspace = ExtractByTypename<
  GraphqlTypes.EndpointsList['workspace'],
  'Workspace'
>;
export type Endpoints = Workspace['endpoints']['endpoints'];

export const ENDPOINTS_LIST = gql`
  query EndpointsList($workspaceName: String!, $organizationId: ID) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoints {
          endpoints {
            id
            owner {
              ...OwnerData
            }
            environments {
              kafka {
                configId
                status
              }
              id
              status
              components {
                build {
                  ... on Error {
                    ...ErrorData
                  }
                  ... on Build {
                    id
                    status
                    message
                    source {
                      ... on Error {
                        ...ErrorData
                      }
                      ... on ExperimentRun {
                        id
                      }
                      ... on RegisteredModelVersion {
                        id
                        version
                        registeredModel {
                          id
                          name
                          workspace {
                            id
                            ...WorkspaceData
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            dateUpdated
            labels
            path
            workspace {
              id
              ...WorkspaceData
            }
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
`;

export const useEndpointsListQuery = ({
  workspaceName,
  organizationId,
}: {
  workspaceName: IWorkspace['name'];
  organizationId: string | undefined;
}) => {
  const query = useCustomQuery<
    GraphqlTypes.EndpointsList,
    GraphqlTypes.EndpointsListVariables
  >(ENDPOINTS_LIST, {
    notifyOnNetworkStatusChange: true,
    variables: {
      workspaceName,
      organizationId,
    },
  });
  const { data: endpointsList, communication: loadingEndpointsList } =
    resultToCommunicationWithData(
      (data) =>
        mapDataOrError(data.workspace, (x) =>
          x.endpoints.endpoints.filter(isNotNil)
        ),
      query
    );

  return {
    endpointsList,
    loadingEndpointsList,
    refetch: query.refetch,
  };
};
