import { alpha, Components } from '@mui/material';

import { muiThemeTypography } from 'shared/mui/themes/muiThemeFonts';
import {
  createMuiThemeFromCommon,
  ColorsOptions,
} from 'shared/mui/themes/CommonMuiThemeOptions';

/**
 *  When using TypeScript 4.x and above
 *  according to https://mui.com/components/data-grid/getting-started/#typescript
 */
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

const DEFAULT_BORDER_COLOR = '#CCC';
const ALTERNATIVE_BORDER_COLOR = '#C4C4C4';

// opacities
const opacity = {
  font: {
    primary: 0.87,
    secondary: 0.6,
  },
  color: {
    sideBarActive: 0.6,
    inactive: 0.26,
    codeBlock: 0.04,
  },
};

const colors: ColorsOptions = {
  border: {
    default: `1px solid ${DEFAULT_BORDER_COLOR}`,
    alternative: `1px solid ${ALTERNATIVE_BORDER_COLOR}`,
  },
  charts: {
    accentColor: '#d9d9d9',
    backgroundColor: '#f9fafc',
  },
  gridLayout: {
    placeholder: {
      background: '#e7eefc',
      border: '1px solid #adcdfb',
      boxShadow: '0 0 12px #bbd5fca4',
    },
  },
  additionalBackground: {
    content: '#f9fafc',
    sidebar: '#4b587a',
    sidebarContrast: alpha('#FFF', opacity.color.inactive),
    sidebarActive: alpha('#FFF', opacity.color.sideBarActive),
    tabs: '#F3F3F3',
    breadcrumbs: '#FFFFFF',
    pageContent: '#F3F3F3',
    inactive: alpha('#000', opacity.color.inactive),
    codeBlock: '#F5F5F5',
    loginBackground: '#E7E8EC',
    stepBackground: 'rgba(63, 81, 181, 0.08)',
    skeleton: '#B1B4BE',
    assistedDocs: '#3F51B51A',
    evaluationInput: '#3F51B5',
    evaluationMetadata: '#2196F3',
    evaluationOutput: '#ED6C02',
    evaluationGroundTruth: '#4CAF50',
    evaluationTrace: '#F44336',
  },
  text: {
    primary: alpha('#000', opacity.font.primary),
    secondary: alpha('#000', opacity.font.secondary),
  },
  primary: {
    main: '#553EB0',
    dark: '#303F9F',
    light: '#7986CB',
  },
  error: {
    main: '#C75757',
    light: '#F44336',
  },
  warning: {
    main: '#DBA709',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#6FA347',
  },
  info: {
    main: '#0F62FE',
  },
  default: {
    main: '#E0E0E0',
    dark: '#AEAEAE',
  },
  background: {
    default: '#FFF',
    level1: '#FAFAFA',
    level2: '#F3F3F3',
  },
  rating: {
    active: '#FFB400',
  },
};

const MuiAccordion: Components['MuiAccordion'] = {
  styleOverrides: {
    root: {
      borderColor: DEFAULT_BORDER_COLOR, // need to understand why this color is here
    },
  },
};

const MuiAccordionSummary: Components['MuiAccordionSummary'] = {
  styleOverrides: {
    root: {
      color: '#444', // need to understand why this color is here
    },
  },
};

const MuiAlert: Components['MuiAlert'] = {
  styleOverrides: {
    outlined: {
      color: colors.text?.primary,
      backgroundColor: colors.background?.default,
    },
    outlinedSuccess: {
      color: colors.success?.main,
      borderColor: colors.success?.main,
    },
    outlinedInfo: {
      color: colors.primary?.main,
      borderColor: colors.primary?.main,
    },
    outlinedWarning: {
      color: colors.warning?.main,
      borderColor: colors.warning?.main,
    },
    outlinedError: {
      color: colors.error?.main,
      borderColor: colors.error?.main,
    },
  },
};

const MuiAppBar: Components['MuiAppBar'] = {
  styleOverrides: {
    root: {
      backgroundColor: colors.background?.default,
      borderBottomColor: '#bbb', // need to understand why this color is here
    },
  },
};

const MuiCard: Components['MuiCard'] = {
  styleOverrides: {
    root: {
      borderColor: DEFAULT_BORDER_COLOR, // need to understand why this color is here
    },
  },
};

const MuiDataGrid: Components['MuiDataGrid'] = {
  styleOverrides: {
    root: {
      border: colors.border.alternative,
      borderRadius: '4px 4px 0 0',
      backgroundColor: colors.background?.default,
      '& .MuiDataGrid-columnSeparator': {
        color: colors.default.main,
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: colors.background?.level1,
      },
      '& .MuiDataGrid-row:hover': {
        backgroundColor: '#F6F9FF', // need to understand why this color is here
      },
      '.MuiDataGrid-row:last-child > .MuiDataGrid-cell': {
        borderBottom: 'none',
      },
    },
  },
};

const MuiFormControlLabel: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      color: colors.text?.primary,
    },
  },
};

const MuiMenu: Components['MuiMenu'] = {
  styleOverrides: {
    paper: {
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important',
    },
  },
};

const MuiPaper: Components['MuiPaper'] = {
  defaultProps: {
    elevation: 0,
  },
  styleOverrides: {
    root: {
      borderColor: DEFAULT_BORDER_COLOR,
    },
  },
};

const MuiSkeleton: Components['MuiSkeleton'] = {
  styleOverrides: {
    root: {
      backgroundColor: colors.additionalBackground.skeleton,
    },
  },
};

const MuiTabs: Components['MuiTabs'] = {
  styleOverrides: {
    root: {
      backgroundColor: colors.additionalBackground.tabs,
    },
  },
};

const MuiTooltip: Components['MuiTooltip'] = {
  styleOverrides: {
    tooltip: {
      backgroundColor: '#172343', // need to understand why this color is here
    },
    arrow: {
      '&::before': {
        backgroundColor: '#172343', // need to understand why this color is here
      },
    },
  },
};

const MuiStepIcon: Components['MuiStepIcon'] = {
  styleOverrides: {
    root: {
      color: colors.additionalBackground.stepBackground,
      '&.Mui-active': {
        border: `1px solid ${colors.primary?.dark || ''}`,
        borderRadius: '30px',
        color: 'transparent',
        boxSizing: 'border-box',
      },
      '&.Mui-completed': {
        color: colors.primary?.dark,
      },
    },
    text: {
      fill: colors.primary?.dark,
    },
  },
};

const MainMuiTheme = createMuiThemeFromCommon({
  palette: {
    ...colors,
    mode: 'light',
  },
  typography: muiThemeTypography,
  components: {
    MuiAccordion,
    MuiAccordionSummary,
    MuiAlert,
    MuiAppBar,
    MuiCard,
    MuiDataGrid,
    MuiFormControlLabel,
    MuiMenu,
    MuiPaper,
    MuiSkeleton,
    MuiTabs,
    MuiTooltip,
    MuiStepIcon,
  },
});

export default MainMuiTheme;
