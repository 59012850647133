import { QueryHookOptions } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';

import {
  ModelLayoutCases,
  ModelLayoutCasesVariables,
} from './graphql-types/query.generated';
import { MODEL_LAYOUT } from './query';

export const useModelLayout = (
  { modelId }: { modelId: string },
  options?: QueryHookOptions<ModelLayoutCases, ModelLayoutCasesVariables>
) => {
  const query = useCustomQuery<ModelLayoutCases, ModelLayoutCasesVariables>(
    MODEL_LAYOUT,
    { ...options, variables: { modelId } }
  );

  return resultToCommunicationWithData(convertResponse, query);
};

const convertResponse = ({ registeredModel }: ModelLayoutCases) =>
  mapDataOrError(registeredModel, (model) => model);
