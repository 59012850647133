import { useCallback, useMemo } from 'react';

import { trackEvent } from 'setup/app/analytics';
import ProjectPopup, {
  ModelRequestForm,
} from 'features/evaluations/shared/view/ProjectPopup';

import { useEditEvaluationProject } from '../hooks/useEditEvaluationProject';

export const EditProjectPopup = ({
  onClose,
  isOpen,
  projectId,
  previousValues,
}: {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  previousValues: ModelRequestForm;
}) => {
  const { editEvaluationProject, editingEvaluationProject } =
    useEditEvaluationProject({
      onSuccess: async ({ name, id }) => {
        trackEvent({
          type: 'catalog.registered_model.edited',
          data: {
            id: id,
            name: name,
            changed: {},
          },
        });
        onClose();
      },
    });

  const initialValues = useMemo(() => previousValues, [previousValues]);

  const onSubmit = useCallback(
    (values: ModelRequestForm) => {
      editEvaluationProject({
        name: values.name,
        projectId,
      });
    },
    [projectId, editEvaluationProject]
  );

  return (
    <ProjectPopup
      title="Edit project"
      onSubmit={onSubmit}
      submitButton={{
        children: 'Save',
        isLoading: editingEvaluationProject.isRequesting,
      }}
      initialValues={initialValues}
      onClose={onClose}
      isOpen={isOpen}
    />
  );
};
