import moment from 'moment';

import { Milliseconds } from '../types';
import { combineValidators } from '../validators';
import isNotNil from '../isNotNill';
import toPlural from '../toPlural';

export const TIME_UNITS_IN_MILLIS = {
  year: 31556952000,
  month: 2592000000,
  week: 604800000,
  day: 86400000,
  hour: 3600000,
  minute: 60000,
  second: 1000,
};

const ORDERED_DURATION_COMPONENTS = [
  { type: 'year', milliseconds: TIME_UNITS_IN_MILLIS.year, ending: 'y' },
  { type: 'month', milliseconds: TIME_UNITS_IN_MILLIS.month, ending: 'mo' },
  { type: 'week', milliseconds: TIME_UNITS_IN_MILLIS.week, ending: 'w' },
  { type: 'day', milliseconds: TIME_UNITS_IN_MILLIS.day, ending: 'd' },
  { type: 'hour', milliseconds: TIME_UNITS_IN_MILLIS.hour, ending: 'h' },
  { type: 'minute', milliseconds: TIME_UNITS_IN_MILLIS.minute, ending: 'm' },
  { type: 'second', milliseconds: TIME_UNITS_IN_MILLIS.second, ending: 's' },
  { type: 'millisecond', milliseconds: 1, ending: 'ms' },
] as const;

type FormattedDuration = string;

export const formatDurationMs = (value: Milliseconds): FormattedDuration => {
  return ORDERED_DURATION_COMPONENTS.reduce<string[]>(
    (res, component) =>
      addIfNotZero(
        moment.duration(value)[toPlural(component.type)](),
        (v) => `${v}${component.ending}`,
        res
      ),
    []
  ).join('');
};
export const parseDurationMs = (duration: FormattedDuration): Milliseconds => {
  return parseDurationMsOrUndefined(duration) ?? 0;
};

export const parseDurationMsOrUndefined = (duration: FormattedDuration) => {
  return getComponentDefsWithNumbers(duration)?.reduce(
    (sum, [componentDef, number]) => sum + componentDef.milliseconds * number,
    0
  );
};

const getComponentDefsWithNumbers = (duration: FormattedDuration) => {
  const allComponents = getComponents(duration);
  if (!allComponents) {
    return undefined;
  }

  const componentDefsWithNumbers = allComponents
    .map((component) => {
      const targetComponentDef = ORDERED_DURATION_COMPONENTS.find(
        ({ ending }) => component.ending === ending
      );
      return targetComponentDef
        ? ([targetComponentDef, component.number] as const)
        : undefined;
    })
    .filter(isNotNil);

  return componentDefsWithNumbers.length !== allComponents.length ||
    componentDefsWithNumbers.length === 0
    ? undefined
    : componentDefsWithNumbers;
};

const getComponents = (duration: FormattedDuration) => {
  const durationWithoutSpaces = duration.replace(/\s+/g, '');
  const matches = Array.from(durationWithoutSpaces.matchAll(/(\d+)([a-z]+)/g));

  const isNotFullDurationMatched =
    matches.map(([match]) => match).join('') !== durationWithoutSpaces;
  if (isNotFullDurationMatched) return undefined;

  return matches.map(([_, number, ending]) => ({
    number: Number(number),
    ending,
  }));
};

export const validateDuration = (
  field: string,
  range: { min: Milliseconds; max: Milliseconds }
) =>
  combineValidators([
    validateDurationFormat(field),
    validateDurationInRange(field, range),
  ]);

const validateDurationFormat = (field: string) => (duration: string) => {
  return parseDurationMsOrUndefined(duration) === undefined
    ? `invalid ${field} format`
    : undefined;
};

const validateDurationInRange = (
  field: string,
  { min, max }: { min: Milliseconds; max: Milliseconds }
) => {
  return (duration: string) => {
    const durationInMs = moment
      .duration(`PT${duration.toUpperCase()}`)
      .asMilliseconds();
    return durationInMs > min && durationInMs <= max
      ? undefined
      : `Allowed ${field} between 0s and 24h`;
  };
};

const addIfNotZero = (
  number: number,
  format: (number: number) => string,
  array: string[]
) => {
  if (number !== 0) {
    return [...array, format(number)];
  }
  return array;
};
