import Stack from '@mui/material/Stack';

import { LabelUpdateProps } from 'shared/view/domain/Labels/LabelsRenderer/UpdateLabelsAction/UpdateLabelsAction';
import LabelsRenderer from 'shared/view/domain/Labels/LabelsRenderer/LabelsRenderer';

import CellRendererEmpty from './CellRendererEmpty';

type Props = {
  labels: string[];
  columnKey: string;
} & LabelUpdateProps;

const CellRendererLabels = (props: Props) => {
  const { labels, columnKey: _columnKey, ...others } = props;
  return (
    <Stack data-test="tags-manager" direction={'row'} style={{ width: '100%' }}>
      <LabelsRenderer
        emptyLabelsContent={<CellRendererEmpty />}
        labels={labels}
        labelUpdateProps={others}
      />
    </Stack>
  );
};

export default CellRendererLabels;
