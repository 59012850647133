import { URLWithPagination } from 'shared/models/Pagination/PaginationInURL';

import * as Datasets from './modules/datasets';
import * as Projects from './modules/projects';
import * as Endpoints from './modules/endpoints';
import * as Catalog from './modules/catalog';
import * as Organizations from './modules/organizations';
import * as Profile from './modules/profile';
import * as Authentication from './modules/authentication';
import * as HighLevelSearch from './modules/highLevelSearch';
import * as Monitoring from './modules/monitoring';
import * as SystemAdmins from './modules/systemAdmins';
import * as HomePage from './modules/homepage';
import * as Organization from './modules/organization';
import * as Dashboard from './modules/dashboard';
import * as UseCases from './modules/usecases';
import * as Evaluation from './modules/evaluations';
import { param, route, build, queryParams } from './shared/routesTreeBuilder';
import { getRoutesByTags } from './shared/getRoutesByTags';

export const routesTree = build(
  route('/', {
    tag: 'root',
  })(
    Authentication.loginRoutes,
    Authentication.signUpRoutes,
    param()('workspaceName', {
      tag: 'workspace',
    })(
      Datasets.publicRoutes,
      Projects.publicRoutes,
      HighLevelSearch.publicRoutes,
      Endpoints.privateRoutes,
      Catalog.privateRoutes,
      Monitoring.privateRoutes,
      UseCases.privateRoutes,
      Evaluation.privateRoutes,
      route('error-reporter', {
        tag: 'errorReporter',
      })()
    ),
    SystemAdmins.privateRoutes,
    HomePage.privateRoutes,
    Profile.privateRoutes,
    Organization.privateRoutes,
    Organizations.privateRoutes,
    Dashboard.privateRoutes,
    route('llm-app', {
      tag: 'llmApp',
    })(
      route('projects', {
        tag: 'llmProjectsList',
        queryParams: queryParams<URLWithPagination>(),
      })()
    )
  )
);

const routes = getRoutesByTags(routesTree);

export default routes;
