import { GridRowParams } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { keys } from 'ramda';

import matchType from 'shared/utils/matchType';

import styles from './dataGridHighlightRows.module.css';

export interface DataGridHighlightedRow {
  id: string;
  type: 'success' | 'error';
}

export interface DataGridHighlightRowsProps {
  highlightedRows?: DataGridHighlightedRow[];
  getRowClassName?: (params: GridRowParams) => string;
}

export type DataGridHighlightCallback = (
  value: Record<DataGridHighlightedRow['type'], string[]>
) => void;

const DATA_GRID_ROW_HIGHLIGHT_TIMEOUT = 3000;

export const useDataGridHighlightRowsProps = (
  props: DataGridHighlightRowsProps
) => {
  const { getRowClassName: propsGetRowClassName } = props;
  const getRowClassName = useCallback(
    (params: GridRowParams): string => {
      const highlightedRow = props.highlightedRows?.find(
        (r) => r.id === String(params.id)
      );
      if (highlightedRow) {
        return cn(
          propsGetRowClassName?.(params),
          matchType(
            {
              error: () => styles.error,
              success: () => styles.success,
            },
            highlightedRow.type
          )
        );
      }

      return cn(propsGetRowClassName?.(params));
    },
    [props.highlightedRows, propsGetRowClassName]
  );

  return {
    getRowClassName,
  };
};

export const useDataGridHighlightRows = () => {
  const [highlightedRows, changeHighlightedRows] = useState<
    DataGridHighlightedRow[]
  >([]);

  const highlight = useCallback<DataGridHighlightCallback>((value) => {
    changeHighlightedRows(
      keys(value).flatMap((type) => value[type].map((id) => ({ id, type })))
    );
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      changeHighlightedRows([]);
    }, DATA_GRID_ROW_HIGHLIGHT_TIMEOUT);

    return () => clearTimeout(timeoutId);
  }, [highlightedRows]);

  return {
    highlightedRows,
    highlight,
  };
};
