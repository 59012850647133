import { useEffect, useMemo } from 'react';
import { isAfter, subMinutes, isEqual } from 'date-fns';

import { IWorkspace } from 'shared/models/Workspace';
import { useDataGridPagination } from 'shared/view/hooks/usePagination';
import { IPaginationSettings } from 'shared/models/Pagination';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import { useFilter } from 'features/catalog/registeredModel/catalogList/hooks/useFilter';
import { trackEvent } from 'setup/app/analytics';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { ExtractByTypename } from 'shared/utils/types';

import { useSorting } from '../../hooks/useSorting';
import { useWorkspaceEndpoints } from '../../hooks/useWorkspaceEndpoints/useWorkspaceEndpoints';
import { useWorkspaceModels } from '../../hooks/useWorkspaceModels/useWorkspaceModels';
import { useWorkspaceModelVersions } from '../../hooks/useWorkspaceModelVersions/useWorkspaceModelVersions';
import { hideCongratulationPopperStorage } from '../../view/Catalog/CatalogTile/CongratulationPopper/congratulationPopperShow';
import { Models } from '../../hooks/useWorkspaceModels/graphql-types/useWorkspaceModels.generated';

type RegisteredModels = ExtractByTypename<
  Models['workspace'],
  'Workspace'
>['registeredModels']['models'][0];

export const useCatalog = ({
  workspaceName,
}: {
  workspaceName: IWorkspace['name'];
}) => {
  const { data, communication, load: loadQuery } = useWorkspaceModels();

  const organizationId = useCurrentOrganizationV2();
  const {
    data: versionsData,
    communication: versionsCommunication,
    load: loadVersionsQuery,
  } = useWorkspaceModelVersions();

  const { data: endpointsData, communication: endpointsCommunication } =
    useWorkspaceEndpoints({ workspaceName, organizationId });

  const { pagination, onCurrentPageChange, onCurrentPageSizeChange } =
    useDataGridPagination({
      data: isNotError(data) ? data : undefined,
      pageSize: 12,
    });

  const {
    stringPredicates,
    currentFilters,
    onCurrentFiltersChange,
    isFiltering,
  } = useFilter((filter) => {
    onCurrentPageChange(0);
    trackEvent({
      type: 'catalog.filter_apply',
      data: filter,
    });
  });

  const { sorting, onSortingChange, ascending, key } = useSorting(
    { field: 'time_updated', direction: 'desc' },
    (sort) => {
      onCurrentPageChange(0);
      trackEvent({
        type: 'catalog.sort_apply',
        data: sort,
      });
    }
  );

  const loadCatalog = (newPagination: IPaginationSettings) => {
    if (pagination.currentPage !== newPagination.currentPage) {
      onCurrentPageChange(newPagination.currentPage);
    } else if (pagination.pageSize !== newPagination.pageSize) {
      onCurrentPageSizeChange(newPagination.pageSize);
    } else {
      loadQuery({
        variables: {
          workspaceName,
          organizationId,
          stringPredicates,
          pagination: {
            limit: newPagination.pageSize,
            page: newPagination.currentPage + 1,
          },
          sorting: {
            ascending,
            key,
          },
        },
      });
      loadVersionsQuery({
        variables: {
          workspaceName,
          organizationId,
          stringPredicates,
          pagination: {
            limit: newPagination.pageSize,
            page: newPagination.currentPage + 1,
          },
          sorting: {
            ascending,
            key,
          },
        },
      });
    }
  };

  useEffect(() => {
    loadCatalog(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.pageSize,
    pagination.currentPage,
    workspaceName,
    currentFilters,
    ascending,
    key,
  ]);

  const combinedData = useMemo(() => {
    let showTadaAux = false;

    const checkModelIsNew = (
      e: RegisteredModels,
      models: RegisteredModels[]
    ) => {
      if (!hideCongratulationPopperStorage.read()) {
        const dateNow = new Date();
        const tenMinutesAgo = subMinutes(dateNow, 10);

        const dateCreatedObj = new Date(e.dateCreated);
        const result = isAfter(dateCreatedObj, tenMinutesAgo);
        const vertaTemplateModelLabel = 'Verta-Example-Model';

        if (showTadaAux === false && result) {
          let isNewest: Date = new Date(0);

          for (const model of models) {
            const modelDateCreatedObj = new Date(model.dateCreated);
            if (
              isAfter(modelDateCreatedObj, tenMinutesAgo) &&
              isAfter(modelDateCreatedObj, isNewest) &&
              !model.labels.includes(vertaTemplateModelLabel)
            ) {
              isNewest = modelDateCreatedObj;
            }
          }

          showTadaAux = isEqual(isNewest, dateCreatedObj);

          return isEqual(isNewest, dateCreatedObj);
        }
      }

      return false;
    };

    if (isNotError(data)) {
      if (isNotError(versionsData)) {
        const updatedModels = data.models.map((e, idx) => ({
          ...e,
          versions: versionsData.models[idx]?.versions ?? { versions: [] },
        }));

        return {
          ...data,
          models: updatedModels.map((e) => ({
            ...e,
            isNew: checkModelIsNew(e, updatedModels),
          })),
        };
      } else {
        return {
          ...data,
          models: data.models.map((e) => ({
            ...e,
            versions: {
              versions: [],
              pagination: {
                __typename: 'PaginationResponse' as const,
                totalRecords: 0,
              },
              __typename: 'RegisteredModelVersions' as const,
            },
            isNew: checkModelIsNew(e, data.models),
          })),
        };
      }
    }

    return data;
  }, [data, versionsData]);

  return {
    data: combinedData,
    reload: () => loadCatalog(pagination),
    communication,
    versionsCommunication,
    currentFilters,
    onCurrentFiltersChange,
    isFiltering,
    onCurrentPageChange,
    onCurrentPageSizeChange,
    pagination,
    sorting,
    onSortingChange,
    endpointsData,
    endpointsCommunication,
  };
};
