import { gql } from '@apollo/client';
import { values } from 'lodash';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import { USERV2_FRAGMENT, SERVICEV2_FRAGMENT } from 'shared/graphql/User/User';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';

import * as Types from './graphql-types/useUsersList.generated';

const QUERY = gql`
  query OrganizationsUsersList($query: UsersQuery!) {
    organizationsV2(query: { pagination: { limit: 1000, page: 1 } }) {
      organizations {
        id
        name
        description
        timeCreated
        timeUpdated
        users(query: $query) {
          users {
            ... on UserV2 {
              id
              ...UserV2Data
            }
            ... on ServiceAccountV2 {
              id
              ...ServiceAccountV2Data
            }
          }
        }
      }
    }
  }
  ${USERV2_FRAGMENT}
  ${SERVICEV2_FRAGMENT}
`;

type Props = { search?: string };

export const useUsersList = ({ search }: Props) => {
  const [load, queryResult] = useCustomLazyQuery<
    Types.OrganizationsUsersList,
    Types.OrganizationsUsersListVariables
  >(QUERY, { variables: { query: { search } } });

  const { data, communication } = resultToCommunicationWithData(
    convert,
    queryResult
  );

  return {
    data,
    communication,
    load,
  };
};

type UserV2OrServiceAccountV2 =
  Types.OrganizationsUsersList['organizationsV2']['organizations'][0]['users']['users'][0] & {
    organizations: Types.OrganizationsUsersList['organizationsV2']['organizations'];
  };

const convert = ({ organizationsV2 }: Types.OrganizationsUsersList) => {
  const organizations = organizationsV2.organizations;
  const users: Record<string, UserV2OrServiceAccountV2> = {};
  organizations.forEach((org) => {
    org.users.users.forEach((user) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (users[user.id] === undefined) {
        users[user.id] = { ...user, organizations: [org] };
      } else {
        users[user.id] = {
          ...users[user.id],
          organizations: [...users[user.id].organizations, org],
        };
      }
    });
  });

  return values(users);
};
