import { useTheme } from '@mui/material';
import { useEffect, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { isEmpty, isEqual, uniqBy } from 'lodash';

import { getEnvironment } from 'shared/models/Deployment/canary/Endpoint';
import { BuildSource } from 'shared/models/Deployment/canary/Build';
import { Endpoint } from 'features/deployment/canary/endpoints/store/endpointQuery/endpointQuery';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import noop from 'shared/utils/noop';
import MachineConfigurationSections from 'features/deployment/canary/machineConfig/view/MachineConfigurationSections/MachineConfigurationSections';
import ResetButton from 'shared/view/elements/Button/ResetButton';
import Button from 'shared/view/elements/Button/Button';
import Tooltip from 'shared/view/elements/Tooltip/Tooltip';
import { RequiredBuild } from 'features/deployment/canary/shared/RequiredBuild';
import isNotNil from 'shared/utils/isNotNill';
import matchType from 'shared/utils/matchType';
import matchBy from 'shared/utils/matchBy';
import {
  isNotNullableRestrictedGraphqlError,
  isNotRestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';
import {
  useToast,
  useToastCommunicationError,
} from 'features/toast/store/hooks';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { getMachineConfigSettingsFieldName } from 'features/deployment/canary/machineConfig/view/settings/getMachineConfigSettingsFieldName';
import { KafkaConfiguration } from 'features/deployment/canary/endpoints/view/EndpointUsage/KafkaConfiguration/KafkaConfiguration';
import { selectFlags } from 'features/flags';
import { KafkaConfigurations } from 'generated/types';

import { GetInitialSettings } from './settings';
import { useUpdate } from '../../store/useUpdate/useUpdate';
import { ModelSection } from './ModelSection/ModelSection';
import { EndpointUpdateSettingsForm } from './settings';
import { RolloutStrategySection } from './RolloutStrategySection';
import { useSourceBuildsForm } from './useSourceBuildsForm';

export const UpdateEndpointSectionDataTestIds = {
  updateButton: 'update-button',
};

const UpdateEndpointSection = ({
  endpoint,
  environment,
  organizationId,
  kafkaConfigurations,
}: {
  endpoint: Endpoint;
  environment: Endpoint['environments'][0];
  organizationId?: string | undefined;
  kafkaConfigurations: Pick<
    KafkaConfigurations['configurations'][0],
    'id' | 'name' | 'enabled'
  >[];
}) => {
  const {
    deployment: { isEnableKafka },
  } = useSelector(selectFlags);
  const {
    allowedActions: { update: isUpdateAllowed },
  } = endpoint;
  const initialSettings = useMemo(
    () => GetInitialSettings({ endpoint }),
    [endpoint]
  );

  const toast = useToast();
  const toastCommunicationError = useToastCommunicationError();

  const theme = useTheme();

  return (
    <PresetFormik<EndpointUpdateSettingsForm>
      initialValues={initialSettings}
      enableReinitialize
      onSubmit={noop}
    >
      {function FormContent({ values, isValid, resetForm, setFieldValue }) {
        const environmentSourceBuilds = getEnvironmentSourceBuilds(
          values.source.type,
          endpoint
        );
        const { loadingSourceBuilds, sourceBuilds } = useSourceBuildsForm({
          source: values.source,
          endpoint,
          organizationId,
        });
        const updateInfo = useUpdate(
          {
            endpoint,
            environmentId: environment.id,
            settings: values,
            isValid: isValid,
            organizationId,
          },
          {
            onUpdated: () => {
              toast('Endpoint configuration updated successfully.', 'success');
              resetForm();
            },
            onError: (error) =>
              toastCommunicationError(error, {
                context: 'updating endpoint configuration',
              }),
          }
        );

        useEffect(() => {
          if (!values.machineConfiguration.resourcesEnabled) {
            setFieldValue(
              getMachineConfigSettingsFieldName({
                resources: { isGpuEnabled: null },
              }),
              false
            );
          }
        }, [values.machineConfiguration.resourcesEnabled, setFieldValue]);

        const buttonTooltipText =
          isUpdateAllowed && !values.build
            ? 'Please select a build or trigger a new one in the Model section to enable the update.'
            : 'Read/Only';

        return (
          <div data-test="update-endpoint-section">
            <Stack spacing={2}>
              <ModelSection
                sourceBuilds={sourceBuilds}
                loadingSourceBuilds={loadingSourceBuilds}
                environmentSourceBuilds={environmentSourceBuilds}
              />
              {isEnableKafka && !isEmpty(kafkaConfigurations) ? (
                <KafkaConfiguration endpoint={endpoint} />
              ) : null}
              <MachineConfigurationSections
                canonicalAutoscaling={environment.autoscalingMetrics}
              />
              <RolloutStrategySection environment={environment} />
            </Stack>

            <div style={{ marginTop: theme.spacing(4) }}>
              <Stack direction="row" spacing={1}>
                <ResetButton
                  variant="outlined"
                  disabled={isEqual(values, initialSettings)}
                />
                <Tooltip
                  type="withDiv"
                  title={buttonTooltipText}
                  disableHoverListener={
                    Boolean(updateInfo) || isEqual(values, initialSettings)
                  }
                >
                  <Button
                    disabled={!updateInfo}
                    isLoading={Boolean(updateInfo?.updating.isRequesting)}
                    dataTest={UpdateEndpointSectionDataTestIds.updateButton}
                    onClick={updateInfo?.update ?? noop}
                    icon={isUpdateAllowed ? undefined : ICONS.lock}
                  >
                    Update
                  </Button>
                </Tooltip>
              </Stack>
            </div>
          </div>
        );
      }}
    </PresetFormik>
  );
};

const getEnvironmentSourceBuilds = (
  source: BuildSource,
  endpoint: Endpoint
): RequiredBuild[] => {
  return uniqBy(
    (getEnvironment(endpoint)?.components.map((c) => c.build) || [])
      .filter(isNotRestrictedGraphqlError)
      .filter(
        (build) =>
          isNotNullableRestrictedGraphqlError(build.source) &&
          matchType(
            {
              ExperimentRun: () => source === 'experimentRun',
              RegisteredModelVersion: () => source === 'modelVersion',
            },
            build.source.__typename
          )
      )
      .map((build) =>
        isNotNullableRestrictedGraphqlError(build.source)
          ? matchBy(
              build.source,
              '__typename'
            )<RequiredBuild | undefined>({
              ExperimentRun: (experimentRun) => ({
                ...build,
                source: 'experimentRun' as const,
                modelVersion: null,
                experimentRun,
              }),
              RegisteredModelVersion: (modelVersion) => ({
                ...build,
                source: 'modelVersion' as const,
                experimentRun: null,
                modelVersion: modelVersion,
              }),
            })
          : undefined
      )
      .filter(isNotNil),
    ({ id }) => id
  );
};

export default UpdateEndpointSection;
