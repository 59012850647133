import { createAction } from 'typesafe-actions';

import { AppError } from 'shared/models/Error';
import { makeThunkApiRequest } from 'shared/utils/redux/actions';
import * as DeployedExperimentRun from 'shared/models/Deployment/DeployedExperimentRun';
import { JsonData } from 'shared/utils/json';
import DeployedExperimentRunService from 'services/deployment/deployedExperimentRun/DeployedExperimentRunService';

const namespace = 'deployedExperimentRunPlayground';

export const loadDeployedExperimentRunInfo = makeThunkApiRequest(
  namespace,
  'LOAD_DEPLOYED_EXPERIMENT_RUN_INFO',
  undefined
)<
  { endpointInfo: DeployedExperimentRun.IActiveEndpointInfo },
  {
    endpointPath: string;
    deployedExperimentRunInfo: DeployedExperimentRun.IDeployedExperimentRunInfo;
  },
  AppError
>(async ({ payload }) => {
  const deployedExperimentRunInfo =
    await DeployedExperimentRunService.loadDeployedExperimentRunInfo(
      payload.endpointInfo
    );
  return {
    endpointPath: payload.endpointInfo.path,
    deployedExperimentRunInfo,
  };
});

export const runPrediction = makeThunkApiRequest(
  namespace,
  'RUN_PREDICTION',
  undefined
)<
  {
    endpointInfo: DeployedExperimentRun.IActiveEndpointInfo;
    input: string;
  },
  { endpointPath: string; output: JsonData },
  AppError
>(async ({ payload }) => {
  const output = await DeployedExperimentRunService.runPrediction(
    payload.endpointInfo,
    payload.input
  );
  return { endpointPath: payload.endpointInfo.path, output };
});

export const reset = createAction(`${namespace}/reset`)();
