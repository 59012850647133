import Popup from 'shared/view/elements/Popup/Popup';
import { trackEvent } from 'setup/app/analytics';
import { Wizard } from 'shared/view/elements/Wizard';
import { useOnboardingContext } from 'features/homepage/hooks/useOnboarding';

import { steps } from '../ExploringModelCatalog/ExploringModelCatalogValues';

export const ModelCatalogPopup = (props: {
  isPopupOpen: boolean;
  closePopup: () => void;
  onCompleted: () => void;
}) => {
  const { isPopupOpen, closePopup, onCompleted } = props;
  const { updateOnboardingSteps, stepsValues } = useOnboardingContext();

  const handleOnCompleted = async () => {
    await updateOnboardingSteps({ ...stepsValues, step1: true });
    trackEvent({ type: 'homepage.model_catalog_tutorial.completed' });
    closePopup();
    onCompleted();
  };

  const onClose = () => {
    trackEvent({ type: 'homepage.model_catalog_tutorial.abandoned' });
    closePopup();
  };

  return (
    <Popup
      title="Explore model catalog"
      {...props}
      isOpen={isPopupOpen}
      onClose={onClose}
      dialogContentProps={{ height: '1050px' }}
      maxWidth="lg"
      fullWidth
    >
      <Wizard steps={steps} onCompleted={handleOnCompleted} />
    </Popup>
  );
};
