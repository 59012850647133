import { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material';
import { head, isNil } from 'ramda';

import { getDefaultIODescriptions } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import { getMonitoringWidgetExternalDepsFromModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { getModelMetricsByType } from 'shared/models/Monitoring/MonitoringMetricType';
import { getIconByMonitoringMetricType } from 'features/monitoring/shared/IconChart';
import matchType from 'shared/utils/matchType';

import MonitoringPerformanceCard from './MonitoringPerformanceCard/MonitoringPerformanceCard';
import MonitoringPerformanceWidget from './MonitoringPerformanceWidget/MonitoringPerformanceWidget';
import ConfusionMatrixImage from '../../images/ConfusionMatrix.png';
import PrecisionRecallImage from '../../images/PrecisionRecall.png';
import RocImage from '../../images/Roc.png';

interface Props {
  model: MonitoringModel;
}

interface BackgroundImageType {
  image: string;
}

const BackgroundImageContainer = styled(Box)((props: BackgroundImageType) => ({
  backgroundImage: `url(${props.image})`,
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
  margin: '0px 12px 12px 12px',
}));

const ChartGrid = styled(Grid)({
  display: 'flex',
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  padding: '12px 8px',
  marginBottom: '24px',
  gap: '12px',
});

const StyledGrid = styled(Grid)({
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  height: '531px',
  maxWidth: '49%',
  display: 'flex',
  flexDirection: 'column',
});

const StyledTabs = styled((props: React.ComponentProps<typeof Tabs>) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: 'var(--main-color6)',
  },
});

type DisplayedChartType = 'roc' | 'pr';

const MonitoringPerformance = (props: Props) => {
  const widgetExternalDeps = getMonitoringWidgetExternalDepsFromModel(
    props.model
  );
  const { defaultOutput } = getDefaultIODescriptions(
    widgetExternalDeps.ioDescriptions
  );
  const [displayedChartType, changeDisplayedChartType] =
    useState<DisplayedChartType>('pr');

  const [selectedMetricType, changeSelectedMetricType] = useState(() =>
    head(getModelMetricsByType(widgetExternalDeps.monitoredModelType))
  );

  return (
    <div>
      {defaultOutput ? (
        <Grid>
          <ChartGrid container>
            <Grid>
              {getModelMetricsByType(widgetExternalDeps.monitoredModelType).map(
                (metricType) => (
                  <Grid key={metricType}>
                    <MonitoringPerformanceCard
                      key={metricType}
                      icon={getIconByMonitoringMetricType(metricType)}
                      metricType={metricType}
                      widgetExternalDeps={widgetExternalDeps}
                      output={defaultOutput}
                      changeSelectedMetricType={changeSelectedMetricType}
                      isSelected={selectedMetricType === metricType}
                    />
                  </Grid>
                )
              )}
            </Grid>
            <Grid xs>
              {!isNil(selectedMetricType) ? (
                <MonitoringPerformanceWidget
                  widgetExternalDeps={widgetExternalDeps}
                  metricType={selectedMetricType}
                  output={defaultOutput}
                />
              ) : null}
            </Grid>
          </ChartGrid>
          <Grid container xs={12} justifyContent={'space-between'}>
            <StyledGrid xs={6}>
              <Typography variant="subtitle1" margin="8px" paddingLeft="6px">
                Confusion Matrix
              </Typography>
              <BackgroundImageContainer image={ConfusionMatrixImage} />
            </StyledGrid>
            <StyledGrid xs={6}>
              <StyledTabs
                value={displayedChartType}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="Precision Recall"
                  id="presicionRecall"
                  aria-controls="tabpanel-precision-recall"
                  value="pr"
                  onClick={() => changeDisplayedChartType('pr')}
                />
                <Tab
                  label="RoC"
                  id="roc"
                  aria-controls="tabpanel-roc"
                  value="roc"
                  onClick={() => changeDisplayedChartType('roc')}
                />
              </StyledTabs>
              <BackgroundImageContainer
                image={matchType(
                  {
                    pr: () => PrecisionRecallImage,
                    roc: () => RocImage,
                  },
                  displayedChartType
                )}
              />
            </StyledGrid>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export default MonitoringPerformance;
