import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';

import CompareDatasetVersionsPage from './CompareDatasetVersionsPage/CompareDatasetVersionsPage';
import DatasetOverviewPage from './DatasetOverviewPage/DatasetOverviewPage';
import DatasetVersionPage from './DatasetVersionPage/DatasetVersionPage';
import DatasetVersionsPage from './DatasetVersionsPage/DatasetVersionsPage';

export const datasetDetailsPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.datasetOverview.getPath(),
      element: <DatasetOverviewPage />,
    },
    {
      path: routes.datasetVersions.getPath(),
      element: <DatasetVersionsPage />,
    },
    {
      path: routes.datasetVersion.getPath(),
      element: <DatasetVersionPage />,
    },
    {
      path: routes.compareDatasetVersions.getPath(),
      element: <CompareDatasetVersionsPage />,
    },
  ],
};
