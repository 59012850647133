import { QueryHookOptions } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';

import {
  CustomDashboardTopModels,
  CustomDashboardTopModelsVariables,
} from '../graphql/graphql-types/queries.generated';
import { CUSTOM_DASHBOARD_TOP_MODELS } from '../graphql/queries';

export const useCustomDashboardTopModels = (
  props: {
    orgId: string;
    sortBy: string;
  },
  options?: QueryHookOptions<
    CustomDashboardTopModels,
    CustomDashboardTopModelsVariables
  >
) => {
  const { orgId, sortBy } = props;

  const query = useCustomQuery<
    CustomDashboardTopModels,
    CustomDashboardTopModelsVariables
  >(CUSTOM_DASHBOARD_TOP_MODELS, {
    ...options,
    variables: {
      orgId,
      sortBy,
    },
  });

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return {
    data,
    communication,
    reload: query.refetch,
  };
};

const convertResponse = ({ organizationV2 }: CustomDashboardTopModels) =>
  mapDataOrError(organizationV2, (_organizationV2) =>
    mapDataOrError(
      _organizationV2.customDashboardTopModels,
      (models) => models.models
    )
  );
