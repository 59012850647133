import { range } from 'ramda';

import { HttpActions } from 'services/HttpActions';
import { defaultFlags, Flags } from 'shared/models/Flags';

export default class FlagsDataService {
  static async loadFlags(): Promise<Flags> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { data } = await HttpActions.get({ url: '/v1/feature-flags/' });

      FlagsDataService.setPredefinedFlags(data);

      return {
        deployment: {
          isEnable: Boolean(data.enable_endpoints),
          isEnableMonitoring: Boolean(data.enable_monitoring_dashboard),
          isEnableMachineConfigGpu: Boolean(data.enable_machine_config_gpu),
          isEnableKafka: Boolean(data.enable_kafka),
          isEnableExpandedEndpointPermissions: Boolean(
            data.enable_expanded_endpoint_permissions
          ),
          isEnableDedicatedResources: Boolean(data.enable_dedicated_resources),
          defaultCpuValue: data.default_cpu_value as number | undefined,
          defaultMemoryValue: data.default_memory_value as string | undefined,
          maxCpuValue: data.max_cpu_value as number | undefined,
          maxMemoryValue: data.max_memory_value as string | undefined,
        },
        errorReporting: {
          isEnableAttachingRequestResponses: Boolean(
            data.enable_error_reporting_network
          ),
          supportChannelLink: data.enable_error_reporting_support_channel as
            | string
            | undefined,
          isEnableObfuscation: Boolean(data.enable_error_reporting_obfuscating),
          isEnablePlayground: Boolean(data.enable_error_reporting_playground),
        },
        isEnableAttributesVisualization: Boolean(
          data.enable_attributes_visualization
        ),
        isEnableRegisteredModelVersionsLock: Boolean(data.enable_registry_lock),
        isEnableCustomUsers: Boolean(data.enable_custom_users),
        isEnableAuth0Login: Boolean(data.enable_auth0_login),
        isEnableSAMLLogin: Boolean(data.enable_saml_login),
        isEnableLdapLogin: Boolean(data.enable_ldap_login),
        isEnableRegistry: Boolean(data.enable_registry),
        isEnableDatasets: true, // TODO: update flag to pull from new UAC flags
        isEnableProjects: true, // TODO: update flag to pull from new UAC flags
        isEnableExternalAuth: Boolean(data.enable_external_auth),
        isDefaultRegistryLockedEnabled: Boolean(
          data.default_registry_locked_level
        ),
        isEnableExternalSecurityScanning: Boolean(
          data.enable_external_security_scanning
        ),
        defaultRegistryLockedContent:
          data.default_registry_locked_level as Flags['defaultRegistryLockedContent'],
        isEnableBaselinePermission: Boolean(data.enable_baseline_permissions),
        isEnableCustomContainer: Boolean(data.enable_custom_container),
        isSentryEnabled: Boolean(data.sentry_enabled),
        isEnableDarkModeControl: Boolean(data.enable_dark_mode_control),
        isEnableIncompleteArtifact: Boolean(data.enable_incomplete_artifact),
        isEnablePromotion: Boolean(data.enable_promotion),
        pypiDisableConfigNameMarker: data.pypi_disable_config_name_marker as
          | string
          | undefined,
        muiDataGridLicense: data.mui_datatable_license
          ? String(data.mui_datatable_license)
          : null,
        segmentWriteKey: data.segment_write_key
          ? String(data.segment_write_key)
          : null,
        isEnableServiceAccounts: Boolean(data.enable_service_accounts),
        monitoring: {
          isEnable: Boolean(data.enable_monitoring),
          isEnableMonitoringAlerts: Boolean(data.enable_monitoring_alerts),
          isEnableMonitoringCompare: Boolean(data.enable_monitoring_compare),
          isEnableNewMonitoringUX: Boolean(data.enable_new_monitoring_ux),
        },
        homepage: {
          isEnableMonitoringWidget: Boolean(data.enable_monitoring_widget),
          isEnableCatalogWidget: Boolean(data.enable_catalog_widget),
        },
        catalog: {
          isEnableNewEditor:
            defaultFlags.catalog.isEnableNewEditor ||
            Boolean(data.enable_new_editor),
          isEnableMLAssistance:
            defaultFlags.catalog.isEnableMLAssistance ||
            Boolean(data.enable_ml_assistance),
          isEnableMLInline:
            defaultFlags.catalog.isEnableMLInline ||
            Boolean(data.enable_ml_inline),
          isEnableFineFineTuning:
            defaultFlags.catalog.isEnableFineFineTuning ||
            Boolean(data.enable_fine_tuning),
          isEnablePromotionApprovalRequired:
            defaultFlags.catalog.isEnablePromotionApprovalRequired ||
            Boolean(data.enable_promotion_approval_required),
        },
        evaluation: {
          isEnabled:
            defaultFlags.evaluation.isEnabled ||
            Boolean(data.enable_evaluation),
        },
        isEnableChatWidget: Boolean(data.enable_chat_widget),
        isEnableWorkflows: Boolean(data.enable_workflows),
        isEnableFairness: Boolean(data.enable_fairness),
        isEnableDashboard: Boolean(data.enable_dashboard),
        isEnableCustomDashboard: Boolean(data.custom_dashboard),
        createUserToken: data.create_user_token
          ? String(data.create_user_token)
          : '',
        isEnableFreeTier: Boolean(data.enable_free_tier),
        isEnableUseCases: Boolean(data.enable_use_cases),
      };
    } catch {
      return defaultFlags;
    }
  }

  // https://github.com/VertaAI/docs/blob/main/engineering/frontend/feature%20flags.md#setting-feature-flags-in-browser
  static setPredefinedFlags(loadedFlags: Record<string, unknown>) {
    range(0, localStorage.length).forEach((idx) => {
      const key = localStorage.key(idx);
      if (key?.startsWith('flag_')) {
        const valueStr = localStorage.getItem(key);
        if (valueStr) {
          loadedFlags[key] = JSON.parse(valueStr) as unknown;
        }
      }
    });
  }
}
