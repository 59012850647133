/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { DATASET_VERSION_FRAGMENT } from 'shared/graphql/Datasets/DatasetVersion/DatasetVersion';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { convertGraphqlDatasetVersion } from 'shared/graphql/Datasets/DatasetVersion/converters';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';

import * as Types from './graphql-types/datasetVersion.generated';

const DATASET_VERSION = gql`
  query DatasetVersion($id: ID!) {
    datasetVersion(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on DatasetVersion {
        id
        ...DatasetVersionData
        runs {
          runs {
            id
            name
            projectId
            project {
              id
              workspace {
                id
                ...WorkspaceData
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${DATASET_VERSION_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
`;

export const useDatasetVersion = ({
  id,
}: {
  datasetId: string;
  id: string;
}) => {
  const query = useCustomQuery<
    Types.DatasetVersion,
    Types.DatasetVersionVariables
  >(DATASET_VERSION, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id,
    },
  });

  const { data, communication: loadingDatasetVersion } =
    resultToCommunicationWithData(convertResponse, query);

  return {
    data,
    loadingDatasetVersion,
    refetch: query.refetch,
  };
};

const convertResponse = (res: Types.DatasetVersion) =>
  mapDataOrError(res.datasetVersion, (x) => ({
    datasetVersion: convertGraphqlDatasetVersion(x),
    experimentRuns: x.runs.runs,
    dataset: x.dataset,
  }));
