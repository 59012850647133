import { gql } from '@apollo/client';

import { entityTypeWithCollaboratorsToString } from 'features/collaborators/view/Collaboration/shared/entityTypeWithCollaboratorsToString';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

import * as GraphqlTypes from './graphql-types/useDeleteDataset.generated';

const MUTATION = gql`
  mutation DeleteDataset($id: ID!) {
    dataset(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on Dataset {
        id
        delete
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useDeleteDataset = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [deleteEntity, deletingEntity] = useCustomMutation<
    GraphqlTypes.DeleteDataset,
    GraphqlTypes.DeleteDatasetVariables
  >(
    MUTATION,
    { context: `deleting ${entityTypeWithCollaboratorsToString('dataset')}` },
    { onCompleted }
  );

  return { deleteEntity, deletingEntity };
};
