import { useLocation } from 'react-router-dom';

import {
  useBreadcrumbItem,
  useAppBreadcrumbs,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import routes from 'shared/routes';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';

import { useRegistryPageBreadcrumb } from '../shared/useRegistryPageBreadcrumbs';
import RegisteredModelWithExperimentRunCreation from './RegisteredModelWithExperimentRunCreation';
import RegisteredModelCreation from './RegisteredModelCreation';

const RegisteredModelCreationPage = () => {
  const location = useLocation();

  const queryParams = routes.createRegisteredModelVersion.parseQueryParams(
    location.search
  );

  const breadcrumbs = useAppBreadcrumbs(
    useRegistryPageBreadcrumb(),
    useBreadcrumbItem({
      name: 'Create',
      route: routes.createRegisteredModel,
    })
  );

  return (
    <AuthorizedLayout breadcrumbs={breadcrumbs} pageEntityOrError={undefined}>
      {queryParams?.experimentRunId ? (
        <RegisteredModelWithExperimentRunCreation
          experimentRunId={queryParams.experimentRunId}
        />
      ) : (
        <RegisteredModelCreation />
      )}
    </AuthorizedLayout>
  );
};

export default RegisteredModelCreationPage;
