import { CanaryRuleParametersType } from 'generated/types';
import matchType from 'shared/utils/matchType';
import { skipWhenEmpty } from 'shared/utils/validators';
import {
  validatePercentage,
  parsePercentage,
} from 'shared/utils/percentage/percentage';
import {
  parseLatency,
  validateLatency,
  formatLatency,
} from 'shared/utils/latency/latency';

const parameterPercentageInfo = {
  parse: (value: string) => {
    const parsedPercentage = parsePercentage(value);
    return parsedPercentage * 100;
  },
  format: (value: string) => `${value}%`,
  validate: (name: string) => skipWhenEmpty(validatePercentage(name)),
};

export const getRuleParameterParser = (type: CanaryRuleParametersType) => {
  return matchType(
    {
      LATENCY_MS: () => parseLatency,
      PERCENTAGE: () => parameterPercentageInfo.parse,
      UNKNOWN: () => Number,
    },
    type
  );
};

export const getRuleParameterValidator = ({
  type,
  name,
}: {
  type: CanaryRuleParametersType;
  name: string;
}) =>
  matchType(
    {
      LATENCY_MS: () => validateLatency(name),
      PERCENTAGE: () => parameterPercentageInfo.validate(name),
      UNKNOWN: () => () => undefined,
    },
    type
  );

export const getRuleParameterValueFormater = ({
  value,
  type,
}: {
  value: string;
  type: CanaryRuleParametersType;
}) => {
  return matchType(
    {
      LATENCY_MS: () => formatLatency,
      PERCENTAGE: () => parameterPercentageInfo.format,
      UNKNOWN: () => () => value,
    },
    type
  );
};
