import { Field, FieldConfig, FieldProps } from 'formik';
import React from 'react';

import Autocomplete from 'shared/view/elements/Autocomplete/Autocomplete';
import { OmitStrict } from 'shared/utils/types';

function AutocompleteField(
  props: FieldConfig &
    OmitStrict<
      React.ComponentProps<typeof Autocomplete>,
      'value' | 'onChange' | 'onBlur'
    >
) {
  return (
    <Field {...props}>
      {({ field, form, meta }: FieldProps<string>) => (
        <Autocomplete
          options={props.options}
          label={props.label}
          isRequired={props.isRequired}
          disabled={props.disabled}
          name={field.name}
          value={field.value}
          error={meta.touched ? meta.error : undefined}
          onChange={(v) => {
            form.setFieldValue(field.name, v);
          }}
          onBlur={field.onBlur}
          maxWidth={props.maxWidth}
          minWidth={props.minWidth}
          creatable={props.creatable}
        />
      )}
    </Field>
  );
}

export default AutocompleteField;
