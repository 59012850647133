import React, { useState } from 'react';
import { FieldProps, Field, useFormikContext, useField } from 'formik';

import { RequiredBuildSource } from 'shared/models/Deployment/canary/Build';
import { ICommunication } from 'shared/utils/redux/communication';
import { validateNotEmpty } from 'shared/utils/validators';
import {
  getAvailableSourceIds,
  RequiredBuild,
} from 'features/deployment/canary/shared/RequiredBuild';
import Accordion from 'shared/view/elements/Accordion/Accordion';
import matchBy from 'shared/utils/matchBy';
import RadioButtons from 'shared/view/elements/RadioButtons/RadioButtons';
import RegisteredModelWithVersionFields from 'features/registry/shared/RegisteredModelWithVersionFields/RegisteredModelWithVersionFields';
import Checkbox from 'shared/view/elements/Checkbox/Checkbox';
import AutocompleteField from 'shared/view/formComponents/formikFields/AutocompleteField';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { getMachineConfigSettingsFieldName } from 'features/deployment/canary/machineConfig/view/settings/getMachineConfigSettingsFieldName';
import Alert from 'shared/view/elements/Alert/Alert';
import { getContextualHelper } from 'features/deployment/canary/shared/getContextualHelper';

import { EndpointUpdateSettingsForm, Source } from '../settings';
import { getFieldName } from '../settings/getFieldName';
import { BuildField } from './BuildField';

type Props = {
  sourceBuilds: RequiredBuild[] | undefined;
  loadingSourceBuilds: ICommunication;
  environmentSourceBuilds: RequiredBuild[];
};

export function ModelSection({
  sourceBuilds,
  loadingSourceBuilds,
  environmentSourceBuilds,
}: Props) {
  const [openAccordion, setOpenAccordion] = useState<boolean>(true);
  const { values, setFieldValue } =
    useFormikContext<EndpointUpdateSettingsForm>();
  const availableSourceIds = getAvailableSourceIds(environmentSourceBuilds);
  const [{ value: isGpuEnabled }] = useField<boolean>(
    getMachineConfigSettingsFieldName({ resources: { isGpuEnabled: null } })
  );

  return (
    <Accordion
      title="Model"
      expanded={openAccordion}
      onChange={() => setOpenAccordion((old) => !old)}
      subtitle="You can deploy a model either from Registry (Registered Model Version) or Projects (Experiment Run)."
      contextualHelp={getContextualHelper('model')}
      dataTest="model-section"
    >
      <FormStack>
        <SourceType source={values.source} />
        <SourceIdField sourceIds={availableSourceIds} />
        <Checkbox
          name="trigger-new-build"
          value={values.build?.type === 'newBuild'}
          label="Trigger new build"
          onChange={(value) => {
            setFieldValue(
              getFieldName({ build: null }),
              value ? { type: 'newBuild' } : undefined
            );
          }}
        />
        <BuildField
          value={values.build}
          sourceId={values.source.id}
          sourceBuilds={sourceBuilds}
          loading={loadingSourceBuilds}
          environmentSourceBuilds={environmentSourceBuilds}
          onChange={(value) =>
            setFieldValue(getFieldName({ build: null }), value)
          }
        />
        {isGpuEnabled ? (
          <Alert severity="info">GPU support enabled for build</Alert>
        ) : null}
      </FormStack>
    </Accordion>
  );
}

function SourceType({ source }: { source: Source }) {
  const [inputProps, _, helpers] = useField<Source>(
    getFieldName({ source: null })
  );
  const [previousSource, setPreviousSource] = useState<Source | undefined>(
    undefined
  );

  const onChange = (type: RequiredBuildSource): void => {
    const newSource: Source = (() => {
      if (previousSource?.type === type) {
        return previousSource;
      }
      return type === 'modelVersion'
        ? {
            type,
            id: undefined,
            registeredModelId: undefined,
          }
        : {
            type,
            id: undefined,
          };
    })();
    setPreviousSource(source);
    return helpers.setValue(newSource);
  };

  return (
    <RadioButtons<RequiredBuildSource>
      value={inputProps.value.type}
      options={[
        {
          label: 'Model version',
          value: 'modelVersion',
          name: 'modelVersion',
        },
        {
          label: 'Experiment run',
          value: 'experimentRun',
          name: 'experimentRun',
        },
      ]}
      onChange={onChange}
    />
  );
}

const SourceIdField = ({ sourceIds }: { sourceIds: string[] }) => {
  const { values } = useFormikContext<EndpointUpdateSettingsForm>();
  const options = React.useMemo(() => {
    return sourceIds.map((id) => ({ label: id, value: id }));
  }, [sourceIds]);
  const organizationId = useCurrentOrganizationV2();

  return matchBy(
    values.source,
    'type'
  )({
    experimentRun: () => (
      <AutocompleteField
        isRequired={true}
        name={getFieldName({ source: { id: null } })}
        options={options}
        label={'Select an experiment run or enter ID'}
        validate={validateNotEmpty('Field')}
      />
    ),
    modelVersion: (x) => (
      <Field>
        {({ form }: FieldProps<unknown>) => (
          <RegisteredModelWithVersionFields
            organizationId={organizationId}
            registeredModelId={x.registeredModelId}
            changeRegisteredModelId={(id) => {
              form.setFieldValue(
                getFieldName({ source: { registeredModelId: null } }),
                id
              );
            }}
            modelVersionId={x.id}
            changeModelVersionId={(id) => {
              if (id) {
                form.setFieldValue(getFieldName({ source: { id: null } }), id);
              }
            }}
          />
        )}
      </Field>
    ),
  });
};
