import { gql } from '@apollo/client';

import {
  isRestrictedGraphqlError,
  RestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';
import { MonitoringModelRegisteredModelVersion } from 'shared/models/Monitoring/MonitoringModel/MonitoringModelRegisteredModelVersion';

import { MonitoringModelRegisteredModelVersionFragment } from './graphql-types/MonitoringModelRegisteredModelVersion.generated';

export const MONITORING_MODEL_REGISTERED_MODEL_VERSION_FRAGMENT = gql`
  fragment MonitoringModelRegisteredModelVersionFragment on RegisteredModelVersionOrError {
    ... on Error {
      code
      message
    }

    ... on RegisteredModelVersion {
      id
      version
      dateUpdated
      registeredModel {
        id
        name
        taskType
      }
    }
  }
`;

export const convertMonitoringModelRegisteredModelVersion = (
  data: MonitoringModelRegisteredModelVersionFragment
): MonitoringModelRegisteredModelVersion | RestrictedGraphqlError => {
  if (isRestrictedGraphqlError(data)) {
    return data;
  }

  return {
    id: data.id,
    dateUpdated: new Date(data.dateUpdated),
    version: data.version,
    registeredModel: data.registeredModel,
  };
};
