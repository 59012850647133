import { useMemo } from 'react';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import { useAppBreadcrumbs } from 'features/layout/view/Layout/AppBreadcrumbs';
import routes from 'shared/routes';
import { useMonitoringModelContext } from 'features/monitoring/models/model/store/state/context';
import MonitoringDashboardView from 'features/monitoring/dashboards/dashboard/view/MonitoringDashboardView/MonitoringDashboardView';
import { useMonitoringModelStateManager } from 'features/monitoring/models/model/store/state/useMonitoringModelStateManager';
import InlineCommunicationError from 'shared/view/elements/Errors/InlineCommunicationError/InlineCommunicationError';
import { getMonitoringWidgetExternalDepsFromModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { useMonitoringModelSettingsActions } from 'features/monitoring/models/modelSettings/store/useMonitoringModelSettingsActions';

import MonitoringDashboardLayout from './MonitoringDashboardLayout/MonitoringDashboardLayout';
import MonitoringModelDetailsLayout from '../shared/MonitoringModelDetailsLayout/MonitoringModelDetailsLayout';
import { useMonitoringModelPageBreadcrumbs } from '../shared/useMonitoringModelPageBreadcrumbs';

const MonitoringDashboardPage = () => {
  const { dashboardId } = useRouteParams(routes.monitoringDashboard);

  const { model, updateModelState } = useMonitoringModelContext();

  const { updateDashboard, createDashboard } = useMonitoringModelStateManager({
    modelState: model.state,
    updateModelState,
    registeredModelVersions: model.registeredModelVersions,
  });

  const dashboard = useMemo(
    () => model.state.dashboards.find((d) => d.id === dashboardId),
    [model.state.dashboards, dashboardId]
  );

  const breadcrumbs = useAppBreadcrumbs(
    useMonitoringModelPageBreadcrumbs({
      modelName: model.name,
    })
  );

  const actions = useMonitoringModelSettingsActions({ model });

  return dashboard ? (
    <MonitoringDashboardLayout
      model={model}
      updateModelState={updateModelState}
      dashboard={dashboard}
      updateDashboard={updateDashboard}
      breadcrumbs={breadcrumbs}
      createDashboard={createDashboard}
    >
      <MonitoringDashboardView
        widgetExternalDeps={getMonitoringWidgetExternalDepsFromModel(model)}
        dashboard={dashboard}
        updateDashboard={updateDashboard}
      />
    </MonitoringDashboardLayout>
  ) : (
    <MonitoringModelDetailsLayout
      breadcrumbs={breadcrumbs}
      model={model}
      createDashboard={createDashboard}
      pageSettingsActions={actions}
    >
      <InlineCommunicationError
        error="Dashboard not found"
        context="loading dashboard"
      />
    </MonitoringModelDetailsLayout>
  );
};

export default MonitoringDashboardPage;
