import { BasePoint } from 'shared/view/charts/MultiLineChart/utils/point';

import { Bounds, isLinearScale, isOrdinalScale, Scale } from '../types';

// https://github.com/airbnb/visx/blob/ed061ccff89453689b17f803d1c2b2edace1aab5/packages/visx-brush/src/utils.ts#L27
function getDomainFromExtent<Input>(
  scale: Scale<Input>,
  start: number,
  end: number,
  tolerantDelta: number
) {
  const invertedStart = scaleInvert(
    scale,
    start + (start < end ? -tolerantDelta : tolerantDelta)
  );
  const invertedEnd = scaleInvert(
    scale,
    end + (end < start ? -tolerantDelta : tolerantDelta)
  );
  const minValue = Math.min(invertedStart, invertedEnd);
  const maxValue = Math.max(invertedStart, invertedEnd);
  if (isLinearScale(scale)) {
    return {
      start: minValue,
      end: maxValue,
    };
  } else {
    return {
      values: scale.domain().slice(minValue, maxValue + 1),
    };
  }
}

// https://github.com/airbnb/visx/blob/ed061ccff89453689b17f803d1c2b2edace1aab5/packages/visx-brush/src/utils.ts#L27
function scaleInvert(scale: Scale, value: number) {
  if (isOrdinalScale(scale)) {
    const [start, end] = scale.range();
    let i = 0;
    const width = (scale.step() * (end - start)) / Math.abs(end - start);
    if (width > 0) {
      while (value > start + width * (i + 1)) {
        i += 1;
      }
    } else {
      while (value < start + width * (i + 1)) {
        i += 1;
      }
    }

    return i;
  }

  if (isLinearScale(scale)) {
    return scale.invert(value);
  }

  return 0;
}

export function getBounds<XInput, YInput>(
  scales: {
    xScale: Scale<XInput>;
    yScale: Scale<YInput>;
  },
  selectionState: {
    type: 'selection';
    startPoint: BasePoint;
    endPoint: BasePoint;
  }
) {
  const SAFE_PIXELS = 2;
  const xDomain = getDomainFromExtent(
    scales.xScale,
    selectionState.startPoint.x,
    selectionState.endPoint.x,
    SAFE_PIXELS
  );
  const yDomain = getDomainFromExtent(
    scales.yScale,
    selectionState.startPoint.y,
    selectionState.endPoint.y,
    SAFE_PIXELS
  );
  const domain: Bounds<XInput, YInput> = {
    x0: xDomain.start ?? 0,
    x1: xDomain.end ?? 0,
    xValues: xDomain.values,
    y0: yDomain.start ?? 0,
    y1: yDomain.end ?? 0,
    yValues: yDomain.values,
  };
  return domain;
}
