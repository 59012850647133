import { route } from 'shared/routes/shared/routesTreeBuilder';

export const privateRoutes = route('dashboard', {
  tag: 'dashboard',
})(
  route('portfolio', {
    tag: 'dashboardPortfolio',
  })(),
  route('general', {
    tag: 'dashboardGeneral',
  })()
);
