import { Box, Stack, Typography } from '@mui/material';

import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import { Title } from './Title';
import {
  OrganizeModels,
  CustomAttributes,
  DocumentModel,
  ChecklistDeliver,
  DeployFeature,
  DashboardFeature,
} from './capabilities';
import { VertaFooter } from './VertaFooter';

export const VertaOverall = () => {
  return (
    <Box>
      <Title mb={11} />
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        display="flex"
        mb={6}
      >
        <IconAwesomeInfo
          icon={ICONS.circleArrowDown}
          size="2xl"
          color="primary.dark"
        />
        <Typography variant="h4">What to expect from Verta</Typography>
      </Stack>
      <Stack spacing={12} mb={12}>
        <OrganizeModels />
        <DocumentModel />
        <ChecklistDeliver />
        <CustomAttributes />
        <DashboardFeature />
        <DeployFeature />
      </Stack>
      <VertaFooter />
    </Box>
  );
};
