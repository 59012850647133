import SettingsSections, {
  AdditionalSection,
} from 'shared/view/domain/EntitySettings/SettingsSections';

export default function EntitySettingsContent({
  additionalSections,
}: {
  additionalSections?: AdditionalSection[];
}) {
  return (
    <div>
      <SettingsSections additionalSections={additionalSections} />
    </div>
  );
}
