import { AsyncActionResult } from './types';

export default async function unwrapAsyncActionResult<
  T extends AsyncActionResult<B>,
  B = T extends AsyncActionResult<infer R> ? R : unknown,
>(promise: T): Promise<B> {
  const res = await promise;
  if (res.type === 'success') {
    return res.payload;
  } else {
    return Promise.reject(res.payload);
  }
}
