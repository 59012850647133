import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormControlLabel, Stack } from '@mui/material';

import { selectFlags } from 'features/flags';
import { Endpoint } from 'features/deployment/canary/endpoints/store/endpointSettings/endpointSettings';
import { getEnvironment } from 'shared/models/Deployment/canary/Endpoint';
import Placeholder from 'shared/view/elements/Placeholder/Placeholder';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import Switch from 'shared/view/elements/Switch/Switch';
import Preloader from 'shared/view/elements/Preloader/Preloader';

import AccessTokens from './AccessTokens/AccessTokens';
import CreateAccessTokenForm from './CreateAccessTokenForm/CreateAccessTokenForm';
import EnableTokensControl from './EnableTokensControl/EnableTokensControl';
import { useEnableAuthZPredictions } from '../../store/enableAuthzPrediction/enableAuthzPrediction';

interface Props {
  endpoint: Endpoint;
}

export const AccessTokensManager: FC<React.PropsWithChildren<Props>> = ({
  endpoint,
}) => {
  const {
    deployment: { isEnableExpandedEndpointPermissions },
  } = useSelector(selectFlags);
  const environment = getEnvironment(endpoint);
  const [authzPrediction, setAuthzPrediction] = useState<boolean>(
    environment?.authzPredictionEnabled || false
  );
  const organizationId = useCurrentOrganizationV2();
  const { enableAuthZPredictions, enablingAuthZPredictions } =
    useEnableAuthZPredictions();

  return (
    <div data-test="access-tokens">
      <Stack direction="column" spacing={2}>
        {environment ? (
          <>
            <EnableTokensControl
              endpointId={endpoint.id}
              environmentId={environment.id}
              tokensEnabled={environment.tokensEnabled}
              workspaceName={endpoint.workspace.name}
              hasAccess={endpoint.allowedActions.update}
              tokens={environment.tokens}
              organizationId={organizationId}
            />
            <CreateAccessTokenForm
              disabled={!environment.tokensEnabled}
              endpointId={endpoint.id}
              environmentId={environment.id}
              hasAccess={endpoint.allowedActions.update}
              workspaceName={endpoint.workspace.name}
            />
            <AccessTokens
              accessTokens={environment.tokens}
              endpointId={endpoint.id}
              environmentId={environment.id}
              hasAccess={endpoint.allowedActions.update}
              tokensEnabled={environment.tokensEnabled}
              workspaceName={endpoint.workspace.name}
            />
            {isEnableExpandedEndpointPermissions ? (
              <Stack direction="row" alignItems="center">
                <FormControlLabel
                  sx={{ marginLeft: -1 }}
                  control={
                    <Switch
                      name="authzPredictionEnabled"
                      checked={authzPrediction}
                      onChange={(_, v) => {
                        setAuthzPrediction(v);
                        enableAuthZPredictions({
                          enable: v,
                          endpointId: endpoint.id,
                        });
                      }}
                    />
                  }
                  label="Predict permissions"
                />
                {enablingAuthZPredictions.isRequesting ? (
                  <Preloader size="small" />
                ) : null}
              </Stack>
            ) : null}
          </>
        ) : (
          <div>
            <Placeholder>You don't have an environment</Placeholder>
          </div>
        )}
      </Stack>
    </div>
  );
};
