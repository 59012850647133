import { CrossRunDashboard } from 'shared/models/CrossRunDashboard/CrossRunDashboard';
import * as AttributeWithCrossRunDashboards from 'shared/models/CrossRunDashboard/AttributeWithCrossRunDashboards';
import { useUpdateAttributeWithDashboards } from 'features/experimentRuns/crossRunDashboard/store/updateAttributeWithDashboards/updateAttributeWithDashboards';

const useCloneDashboard = (
  onCompleted: (createdDashboard: CrossRunDashboard) => void
) => {
  const { updateAttributeWithDashboards, updating: cloningDashboard } =
    useUpdateAttributeWithDashboards('cloning dashboard', undefined);
  const cloneDashboard = (data: {
    dashboardId: string;
    dashboards: CrossRunDashboard[];
    projectId: string;
    newDashboardName: CrossRunDashboard['name'];
  }) => {
    const result = AttributeWithCrossRunDashboards.cloneDashboard(data);
    if (result) {
      updateAttributeWithDashboards({
        projectId: data.projectId,
        attribute: result.attribute,
      }).then((res) => {
        if (res.errors && res.errors.length === 0) {
          return;
        }
        onCompleted(result.newDashboard);
      });
    }
  };

  return { cloneDashboard, cloningDashboard };
};

export default useCloneDashboard;
