import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { ARTIFACT_FRAGMENT } from 'shared/graphql/Artifact/Artifact';
import { ExtractByTypename } from 'shared/utils/types';

import {
  ModelVersionIntegrate,
  ModelVersionIntegrateVariables,
} from './graphql-types/useModelVersionIntegrate.generated';

export type RegisteredModelVersion = ExtractByTypename<
  ModelVersionIntegrate['registeredModelVersion'],
  'RegisteredModelVersion'
>;

const MODEL_VERSION_INTEGRATE = gql`
  query ModelVersionIntegrate($versionId: ID!) {
    registeredModelVersion(id: $versionId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        version
        stage
        dateCreated
        inputDescription
        outputDescription
        hideInputLabel
        hideOutputLabel
        artifacts {
          ...ArtifactData
        }
        model {
          ...ArtifactData
        }
        registeredModel {
          id
          name
          allowedActions {
            ...AllowedActionsData
            lock
            unlock
            updateRedact
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ARTIFACT_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;

export const useModelVersionIntegrate = ({
  versionId,
}: {
  versionId: string;
}) => {
  const query = useCustomQuery<
    ModelVersionIntegrate,
    ModelVersionIntegrateVariables
  >(MODEL_VERSION_INTEGRATE, {
    variables: {
      versionId,
    },
  });

  const { data: version, communication: loadingVersion } =
    resultToCommunicationWithData(convertResponse, query);

  return {
    version,
    loadingVersion,
    reloadVersion: query.refetch,
  };
};

const convertResponse = ({ registeredModelVersion }: ModelVersionIntegrate) =>
  mapDataOrError(registeredModelVersion, (version) => version);
