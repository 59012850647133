import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import Popup from 'shared/view/elements/Popup/Popup';
import { Markdown } from 'shared/utils/types';

import MarkdownView from '../shared/Markdown/Markdown';
import Editor from './Editor';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  id: string;
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => (
  <Paper
    variant="outlined"
    elevation={0}
    role="tabpanel"
    hidden={value !== index}
    sx={{ maxHeight: 'calc(100vh - 346px)', overflow: 'auto' }}
    {...other}
  >
    {value === index ? <Box sx={{ p: 3 }}>{children}</Box> : null}
  </Paper>
);

const TabsStickyWrapper = styled(Box)({
  position: 'sticky',
  top: '0px',
  backgroundColor: 'white',
  zIndex: 5,
});

interface ILocalProps {
  title: string;
  isOpen: boolean;
  onSave: (v: string) => void;
  onClose: () => void;
  isSaving: boolean;
  initialValue: Markdown;
}

const MarkdownEditor = (props: ILocalProps) => {
  const { title, isOpen, onClose, onSave, isSaving, initialValue } = props;
  const [tabValue, setTabValue] = useState<number>(0);
  const [value, setValue] = useState(initialValue);

  const handleClose = () => {
    setValue(initialValue);
    onClose();
  };

  const handleSave = () => {
    onSave(value);
    onClose();
  };

  return (
    <Popup
      title={`Edit ${title.toLowerCase()}`}
      isOpen={isOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      dataTest="markdown-editor-popup"
      buttons={{
        mainButtonProps: {
          type: 'button',
          dataTest: 'confirm',
          children: 'Save',
          onClick: handleSave,
          isLoading: isSaving,
        },
        secondaryButtonProps: {
          dataTest: 'confirm-cancel-button',
          children: 'Cancel',
          onClick: handleClose,
          isLoading: false,
        },
      }}
    >
      <Stack spacing={3}>
        <TabsStickyWrapper>
          <Tabs
            value={tabValue}
            onChange={(_e, tabIndex) => setTabValue(tabIndex)}
            aria-label="basic tabs example"
          >
            <Tab label="Edit" id="edit" aria-controls="tabpanel-edit" />
            <Tab
              label="Preview"
              id="preview"
              aria-controls="tabpanel-preview"
            />
          </Tabs>
        </TabsStickyWrapper>
        <TabPanel
          value={tabValue}
          index={0}
          id="tabpanel-edit"
          aria-labelledby="tab-edit"
        >
          <Editor value={value} onChange={(val) => setValue(val)} />
        </TabPanel>
        <TabPanel
          value={tabValue}
          index={1}
          id="tabpanel-preview"
          aria-labelledby="tab-preview"
        >
          <MarkdownView mode="preview">{value}</MarkdownView>
        </TabPanel>
      </Stack>
    </Popup>
  );
};

export default MarkdownEditor;
