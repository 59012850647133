import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { ChipProps } from 'shared/view/elements/Chip/Chip';
import Button from 'shared/view/elements/Button/Button';
import Popup from 'shared/view/elements/Popup/Popup';
import TextInput from 'shared/view/elements/TextInput/TextInput';

import Tag from '../Tag/Tag';
import { ContentWrapper } from '../styled-components';

interface Props {
  isOpen: boolean;
  tags: string[];
  tagProps?: Pick<ChipProps, 'color' | 'variant' | 'size' | 'sx'>;
  isUpdating: boolean;
  isEditable: boolean;
  onAdd(tag: string): void;
  onRemove(tag: string): void;
  onClose(): void;
}

const AddTagModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    onAdd,
    tags,
    tagProps,
    isUpdating,
    isEditable,
    onRemove,
  } = props;

  const [tag, setTag] = useState('');
  const [tagLimitReached, setTagLimitReached] = useState(false);

  const updateInput = (value: string) => {
    if (tag.length >= 40) {
      setTagLimitReached(true);
    } else {
      setTagLimitReached(false);
      setTag(value);
    }
  };

  const onAddTag = () => {
    onAdd(tag);
    setTag('');
  };

  return (
    <Popup
      title="Add new label"
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      dataTest="tags-manager-add-tag-popup"
    >
      <Stack direction="row" spacing={1}>
        <Box sx={{ flex: '1' }}>
          <div data-test="tags-manager-creator">
            <TextInput
              maxWidth="100%"
              value={tag}
              meta={{
                touched: true,
                error: tagLimitReached
                  ? 'maximum character limit reached'
                  : undefined,
              }}
              label="New label"
              onChange={updateInput}
              onEnter={onAddTag}
              name="tags-manager-creator-input"
              dataTest="tags-manager-creator-input"
            />
          </div>
        </Box>
        <Box pt={1}>
          <div>
            <Button
              onClick={onAddTag}
              isLoading={isUpdating}
              disabled={tag.length === 0}
              dataTest="tags-manager-create-button"
            >
              Add
            </Button>
          </div>
        </Box>
      </Stack>
      <ContentWrapper>
        {tags.map((val: string, i: number) => (
          <Tag
            key={i}
            value={val}
            isEditable={isEditable}
            isUpdating={isUpdating}
            onDelete={onRemove}
            {...tagProps}
          />
        ))}
      </ContentWrapper>
    </Popup>
  );
};

export default AddTagModal;
