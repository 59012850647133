import routes from 'shared/routes';
import { useRouteParams } from 'shared/utils/router/useRouteParams';
import { useEvaluationSummary } from 'features/evaluations/evaluation/summary/hooks/useEvaluationSummary';
import EvaluationSummary from 'features/evaluations/evaluation/summary/view/summary';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

const SummaryPage = () => {
  const { evaluationId } = useRouteParams(routes.evaluationSummary);
  const { evaluation, communication } = useEvaluationSummary({ evaluationId });

  return (
    <DefaultMatchRemoteDataOrError
      communication={communication}
      data={evaluation}
      context="loading model version"
    >
      {(loadedEvaluation) => (
        <EvaluationSummary evaluation={loadedEvaluation} />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default SummaryPage;
