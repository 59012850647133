import Grid from '@mui/material/Unstable_Grid2';

import { getIconByMonitoringMetricType } from 'features/monitoring/shared/IconChart';
import { getModelMetricsByType } from 'shared/models/Monitoring/MonitoringMetricType';
import { getDefaultIODescriptions } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import { getMonitoringWidgetExternalDepsFromModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';

import MonitoringOverviewAlertsWidget from './MonitoringOverviewAlertsWidget/MonitoringOverviewAlertsWidget';
import MonitoringOverviewCard from './MonitoringOverviewCard/MonitoringOverviewCard';
import MonitoringOverviewPredictionCountWidget from './MonitoringOverviewPredictionCountWidget/MonitoringOverviewPredictionCountWidget';

interface Props {
  model: MonitoringModel;
}

const MonitoringOverview = (props: Props) => {
  const widgetExternalDeps = getMonitoringWidgetExternalDepsFromModel(
    props.model
  );
  const { defaultOutput } = getDefaultIODescriptions(
    widgetExternalDeps.ioDescriptions
  );

  return (
    <Grid container spacing={3} alignItems="stretch" direction="row">
      {defaultOutput
        ? getModelMetricsByType(widgetExternalDeps.monitoredModelType).map(
            (metricType, index, arr) => (
              <Grid key={metricType} xs={3}>
                <MonitoringOverviewCard
                  key={metricType}
                  icon={getIconByMonitoringMetricType(metricType)}
                  metricType={metricType}
                  widgetExternalDeps={widgetExternalDeps}
                  output={defaultOutput}
                  isLast={index === arr.length - 1}
                />
              </Grid>
            )
          )
        : null}
      <Grid xs={8}>
        <MonitoringOverviewPredictionCountWidget
          widgetExternalDeps={widgetExternalDeps}
        />
      </Grid>
      <Grid xs={4}>
        <MonitoringOverviewAlertsWidget model={props.model} />
      </Grid>
    </Grid>
  );
};

export default MonitoringOverview;
