import * as CrossRunDashboard from 'shared/models/CrossRunDashboard/CrossRunDashboard';
import { WidgetSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/WidgetSettings';
import generateId from 'shared/utils/generateId';

import { IUseCrossRunDashboardProps } from './IUseCrossRunDashboardProps';

export function useWidgetActions(props: IUseCrossRunDashboardProps) {
  const createWidget = (panelId: string, widgetSettings: WidgetSettings) =>
    props.changeSelectedCrossRunDashboard(
      CrossRunDashboard.addWidget(
        props.selectedCrossRunDashboard,
        panelId,
        widgetSettings,
        generateId()
      )
    );

  const deleteWidget = (panelId: string, id: string) =>
    props.changeSelectedCrossRunDashboard(
      CrossRunDashboard.deleteWidget(
        props.selectedCrossRunDashboard,
        panelId,
        id
      )
    );

  const editWidget = (
    panelId: string,
    id: string,
    widgetSettings: WidgetSettings
  ) =>
    props.changeSelectedCrossRunDashboard(
      CrossRunDashboard.updateWidget(
        props.selectedCrossRunDashboard,
        panelId,
        id,
        widgetSettings
      )
    );

  return {
    createWidget,
    deleteWidget,
    editWidget,
  };
}
