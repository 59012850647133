import {
  Step,
  StepProps as MuiStepProps,
  StepLabel,
  Stepper as MuiStepper,
  StepperProps as MuiStepperProps,
  Typography,
  styled,
} from '@mui/material';

import { OmitStrict } from 'shared/utils/types';

export type ControlledStepProps = OmitStrict<MuiStepProps, 'children'> & {
  stepLabel:
    | {
        icon: React.ReactNode;
        text: string;
      }
    | false;
  group: number;
};

type ControlledStepperProps = MuiStepperProps & {
  steps: ControlledStepProps[];
};

const StyledStepper = styled(MuiStepper)(() => ({ width: 'max-content' }));

export const ControlledStepper = (props: ControlledStepperProps) => {
  const { steps, ...restProps } = props;
  return (
    <StyledStepper orientation="vertical" nonLinear {...restProps}>
      {steps.map((step, index) => {
        const { stepLabel, completed, active } = step;
        return stepLabel !== false ? (
          <Step
            key={`${stepLabel.text}-${index}`}
            completed={completed}
            active={active}
          >
            <StepLabel icon={stepLabel.icon}>
              <Typography variant="overline">{stepLabel.text}</Typography>
            </StepLabel>
          </Step>
        ) : null;
      })}
    </StyledStepper>
  );
};
