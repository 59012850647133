import { useDeleteEntityAction } from 'features/shared/DeleteEntityIcon/useDeleteEntityAction';
import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import routes from 'shared/routes';
import { MenuAction } from 'shared/view/elements/ActionsMenu/ActionsMenu';
import isNotNil from 'shared/utils/isNotNill';
import { useEditResourceInformationMenuAction } from 'features/editResourceInformation/view/EditResourceInformation/EditResourceInformation';

export const useMonitoringModelSettingsActions = (props: {
  model: MonitoringModel;
}): MenuAction[] => {
  return [
    useEditResourceInformationMenuAction({
      ...props.model,
      description: '',
      allowedActions: props.model.monitoredEntity.allowedActions,
      __typename: 'MonitoredEntity',
    }),
    useDeleteEntityAction({
      entityType: 'monitoringModel',
      id: props.model.id,
      isNotAllowedToDelete: !props.model.monitoredEntity.allowedActions.delete,
      redirectUrl: routes.monitoring.getRedirectPath({
        workspaceName: props.model.workspaceName,
      }),
    }),
  ].filter(isNotNil);
};
