import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';

import Popup from 'shared/view/elements/Popup/Popup';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import { useUpdateStage } from 'pages/authorized/CatalogPages/ModelVersionPages/shared/Layout/hooks/useUpdateStage';
import { Stage } from 'generated/types';

export const EditCommmentPopup = ({
  isPopupOpen,
  closePopup,
  initialValue,
  id,
}: {
  isPopupOpen: boolean;
  closePopup: () => void;
  initialValue: string;
  id: string;
}) => {
  const [evaluationComment, setEvaluationComment] = useState('');
  const { updateStage, updatingStage } = useUpdateStage({
    onCompleted: () => {
      closePopup();
    },
  });

  useEffect(() => {
    setEvaluationComment(initialValue);
  }, [initialValue]);

  const handleSave = () => {
    updateStage({
      comment: evaluationComment,
      desiredStage: Stage.ARCHIVED,
      registeredModelVersionId: id,
    });
  };

  return (
    <Popup
      title="Adding comment"
      isOpen={isPopupOpen}
      onClose={closePopup}
      maxWidth="xs"
      fullWidth
      scroll="body"
      dataTest="evaluation-commment-edit-popup"
      buttons={{
        mainButtonProps: {
          type: 'button',
          dataTest: 'confirm',
          children: 'Save',
          onClick: handleSave,
          isLoading: updatingStage.isRequesting,
          disabled: false,
        },
        secondaryButtonProps: {
          dataTest: 'confirm-cancel-button',
          children: 'Cancel',
          onClick: closePopup,
          isLoading: false,
        },
      }}
    >
      <Stack spacing={3}>
        <TextInput
          maxWidth="100%"
          rows={3}
          hint="Max. 250 characters"
          value={evaluationComment}
          multiline
          meta={{
            touched: true,
            error:
              evaluationComment.length >= 250
                ? 'maximum character limit reached'
                : undefined,
          }}
          label="Evaluation comment"
          onChange={(value) => setEvaluationComment(value)}
          name="evaluationComment-input"
          data-test="evaluationComment-input"
        />
      </Stack>
    </Popup>
  );
};
