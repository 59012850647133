import { Typography, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import IconButton from 'shared/view/elements/IconButton/IconButton';
import { LinkButton } from 'shared/view/elements/Button/LinkButton';
import routes from 'shared/routes';
import { selectFlags } from 'features/flags';

import { ExternalSignUpServiceButton } from '../shared/SignUpServiceButton';

export const SignUpSelection = () => {
  const navigate = useNavigate();
  const { isEnableCustomUsers } = useSelector(selectFlags);

  const signUpPersonalInfoFormStep = routes.signUpPersonalInfoForm.getPath();

  return (
    <>
      <Stack direction="row" spacing={2} mb={7}>
        {isEnableCustomUsers ? (
          <Stack width="fit-content" textAlign="center" spacing={0.5}>
            <IconButton
              withPadding
              icon={ICONS.mail}
              onClick={() =>
                navigate(routes.signUpMailForm.getPath(), {
                  state: { showBackButton: true },
                })
              }
            />
            <Typography variant="caption" color="text.secondary">
              E-mail
            </Typography>
          </Stack>
        ) : null}
        <ExternalSignUpServiceButton
          provider="google"
          providerName="Google"
          icon={ICONS.google}
          redirectBack={signUpPersonalInfoFormStep}
        />
        <ExternalSignUpServiceButton
          provider="github"
          providerName="GitHub"
          icon={ICONS.github}
          redirectBack={signUpPersonalInfoFormStep}
        />
        <ExternalSignUpServiceButton
          provider="bitbucket"
          providerName="Bitbucket"
          icon={ICONS.bitbucket}
          redirectBack={signUpPersonalInfoFormStep}
        />
      </Stack>
      <Typography variant="body1" color="text.secondary" mb={2}>
        Already have an account:
      </Typography>
      <LinkButton to={routes.login.getPath()} variant="outlined">
        Login
      </LinkButton>
    </>
  );
};
