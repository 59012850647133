import { scalePoint } from '@visx/scale';
import { last, takeLastWhile, takeWhile } from 'ramda';

export const getZoomedScale = ({
  domain,
  selectedDomain,
  range,
  innerHeight,
}: {
  domain: string[];
  selectedDomain: string[] | undefined;
  range: [number, number];
  innerHeight: number;
}) => {
  if (selectedDomain && selectedDomain.length !== 0) {
    const rangeDiff = range[1] - range[0];
    const selectedRangeDiff = rangeDiff / (selectedDomain.length - 1);
    const beforeLength = takeWhile(
      (x) => x !== selectedDomain[0],
      domain
    ).length;
    const afterLength = takeLastWhile(
      (x) => x !== last(selectedDomain),
      domain
    ).length;
    const selectedRange: [number, number] = [
      range[0] - selectedRangeDiff * beforeLength,
      range[1] + selectedRangeDiff * afterLength,
    ];

    return {
      zoomedScale: scalePoint({
        domain,
        range: selectedRange,
      }),
      selectedDomainScale: scalePoint({
        domain: selectedDomain,
        range: [innerHeight, 0],
      }),
    };
  }

  return {
    zoomedScale: scalePoint({
      domain,
      range,
    }),
    selectedDomainScale: scalePoint({
      domain,
      range: [innerHeight, 0],
    }),
  };
};
