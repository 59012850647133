import { extent, Numeric } from 'd3-array';

export const extentWithDefault = <T extends Numeric>(
  values: T[],
  defaultValue: T
): [T, T] => {
  const extentResult = extent(values);

  return [extentResult[0] ?? defaultValue, extentResult[1] ?? defaultValue];
};
