/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

export const CREATE_EVALUATION = gql`
  mutation CreateEvaluation(
    $name: String!
    $description: String!
    $labels: [String!]!
    $evaluationProjectId: ID!
    $lockLevel: RegisteredModelVersionLockLevel!
    $attributes: [StringKeyValueInput!]!
  ) {
    registeredModel(id: $evaluationProjectId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        createVersion(
          description: $description
          name: $name
          lockLevel: $lockLevel
          experimentRunId: ""
        ) {
          id
          addLabels(labels: $labels) {
            id
            labels
          }
          addAttributes(keyValues: $attributes) {
            id
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const CREATE_EVALUATION_PROJECT = gql`
  mutation CreateEvaluationProject(
    $name: String!
    $labels: [String!]
    $workspaceName: String!
    $organizationId: ID
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        createRegisteredModel(name: $name, labels: $labels) {
          id
          name
          labels
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;
