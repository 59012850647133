interface Props {
  chartId: string;
  children: React.ReactNode;
  width: number;
  height: number;
  transform?: string;
}

const Borders = (props: Props) => {
  const id = `${props.chartId}-borders`;
  return (
    <>
      <clipPath id={id}>
        <rect
          width={props.width}
          height={props.height}
          transform={props.transform}
        />
      </clipPath>
      <g clipPath={`url(#${id})`}>{props.children}</g>
    </>
  );
};

export default Borders;
