import { useMemo } from 'react';

import { ICommunication } from 'shared/utils/redux/communication';
import { useUserDeletePrimaryDevKey } from 'features/developerKeysManager/useUserDeletePrimaryDevKey';
import { useUserDeleteSecondaryDevKey } from 'features/developerKeysManager/useUserDeleteSecondaryDevKey';

import { useUserAddDevKey } from './useUserAddDevKey';

export default function useDevKeysManager(props: {
  userId: string;
  primaryKey: string;
  secondaryKey: string | undefined;
  organizationId: string;
  onChangeDevKeys(keys: {
    primaryKey: string;
    secondaryKey: string | undefined;
  }): void;
}) {
  const { addDevKey, addingDevKey } = useUserAddDevKey(props);
  const { deletePrimaryDevKey, deletingPrimaryDevKey } =
    useUserDeletePrimaryDevKey(props);
  const { deleteSecondaryDevKey, deletingSecondaryDevKey } =
    useUserDeleteSecondaryDevKey(props);

  const data: DevKeysData = useMemo(
    () => ({
      primaryDevKey: {
        value: props.primaryKey,
        deleting: deletingPrimaryDevKey,
        onDelete: deletePrimaryDevKey,
        deleteDisabled: !props.secondaryKey,
      },
      secondaryDevKey: props.secondaryKey
        ? {
            value: props.secondaryKey,
            deleting: deletingSecondaryDevKey,
            onDelete: deleteSecondaryDevKey,
          }
        : undefined,
      addingSecondaryDevKey: addingDevKey,
      onAddSecondaryDevKey: props.secondaryKey ? undefined : addDevKey,
    }),
    [
      addDevKey,
      addingDevKey,
      deletePrimaryDevKey,
      deleteSecondaryDevKey,
      props.primaryKey,
      props.secondaryKey,
      deletingPrimaryDevKey,
      deletingSecondaryDevKey,
    ]
  );

  return {
    data,
  };
}

type DevKeysData = {
  primaryDevKey: {
    value: string;
    deleting: ICommunication;
    onDelete(): void;
    deleteDisabled: boolean;
  };
  secondaryDevKey:
    | {
        value: string;
        deleting: ICommunication;
        onDelete(): void;
      }
    | undefined;
  addingSecondaryDevKey: ICommunication;
  onAddSecondaryDevKey: (() => void) | undefined;
};
