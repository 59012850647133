import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import {
  UserDevKeys,
  UserDevKeysVariables,
} from 'features/developerKeysManager/graphql-types/useUserDevKeys.generated';

const QUERY = gql`
  query UserDevKeys($id: ID!) {
    user(id: $id) {
      id
      devKey
      secondaryDevKey
    }
  }
`;

export const useUserDevKeys = ({ userId }: { userId: string }) => {
  const result = useCustomQuery<UserDevKeys, UserDevKeysVariables>(QUERY, {
    variables: { id: userId },
  });

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert: convertUser,
    queryResult: result,
  });

  return {
    userDevKeys: data,
    loadingUserDevKeys: communication,
    reload: result.refetch,
  };
};

function convertUser(result: UserDevKeys): UserDevKeys['user'] {
  return result.user;
}
