/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { WORKSPACE_FRAGMENT } from '../Workspace/Workspace';

export const REGISTERED_MODEL_VERSION_FRAGMENT = gql`
  fragment RegisteredModelVersionFragment on RegisteredModelVersion {
    id
    version
    registeredModel {
      id
      name
      taskType
      dataType
      workspace {
        id
        ...WorkspaceData
      }
      versions {
        versions {
          id
          stage
          version
        }
      }
    }
  }
  ${WORKSPACE_FRAGMENT}
`;
