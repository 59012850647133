import { Stack, Grid } from '@mui/material';

import {
  Incidents,
  Summary,
  ModelsHeatmap,
  TopModelsTable,
} from 'features/dashboard/dashboard';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { useCustomDashboard } from 'features/dashboard/hooks/useCustomDashboard';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import { useCustomDashboardTopModelsWithAttrs } from 'features/dashboard/hooks/useCustomDashboardTopModelsWithAttrs';

const PortfolioDashboard = () => {
  const orgId = useCurrentOrganizationV2() || '';
  const { data, communication } = useCustomDashboard({ orgId });
  const { data: topModelAttrData, isRequesting: topModelIsRequesting } =
    useCustomDashboardTopModelsWithAttrs({
      orgId,
      sortBy: 'Business Value Delivered',
    });
  const isLoading = communication.isRequesting || topModelIsRequesting;
  const summaries = isNotError(data) ? data.summaries : [];
  const charts = isNotError(data) ? data.charts : [];

  return (
    <Stack spacing={5} mt={2}>
      <Summary summaries={summaries} isLoading={isLoading} />
      <Grid container gap={2} wrap="nowrap">
        <Grid item xs={8}>
          <TopModelsTable data={topModelAttrData} />
        </Grid>
        <Grid item xs={4} mt={3}>
          <ModelsHeatmap data={topModelAttrData} />
        </Grid>
      </Grid>
      <Incidents charts={charts} isLoading={isLoading} />
    </Stack>
  );
};

export default PortfolioDashboard;
