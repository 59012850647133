import { useState } from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { isEmpty } from 'lodash';

import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { trackEvent } from 'setup/app/analytics';
import Alert from 'shared/view/elements/Alert/Alert';
import { AppError } from 'shared/models/Error';

import { verifyCSVFile } from '../utils/utils';
import { FileItem } from './FileItem';

const MAX_SIZE = 10000000;

const Container = styled(Box)<{ isFocused: boolean; hasError: boolean }>(
  ({ isFocused, hasError, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    borderWidth: '1px',
    borderStyle: 'dashed',
    borderColor: hasError
      ? theme.palette.error.main
      : isFocused
        ? theme.palette.primary.main
        : theme.palette.divider,

    height: '197px',
    borderRadius: '4px',
    backgroundColor: isFocused
      ? theme.palette.additionalBackground.stepBackground
      : theme.palette.background.default,
  })
);

const codeErrorToMessage = (code: string) => {
  switch (code) {
    case 'file-invalid-type':
      return 'File does not follow the specified format. <strong>Only .csv file format is supported.</strong>';
    case 'file-too-large':
      return `File cannot be uploaded as it is bigger than supported <strong>${
        MAX_SIZE / 10000000
      }MB</strong> file size.`;
    default:
      return `Please check the file and upload again.`;
  }
};

const UploadArtifacts = (props: {
  handleUpload: (file: FileWithPath) => void;
  maxSize?: number;
  hasError?: string | boolean;
  uploadingArtifactError?: AppError;
  uploadProgress: number;
}) => {
  const {
    handleUpload,
    maxSize = MAX_SIZE,
    hasError,
    uploadingArtifactError,
    uploadProgress,
  } = props;
  const [dragEnter, setDragEnter] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      onDrop: (e) => uploadFiles(e),
      onDragEnter: () => setDragEnter(true),
      onDragLeave: () => setDragEnter(false),
      maxFiles: 1,
      maxSize,
      accept: { 'text/csv': ['.csv'] },
    });

  const uploadFiles = async (accepted: FileWithPath[]) => {
    try {
      await verifyCSVFile(accepted[0]);
      trackEvent({
        type: 'evaluations.artifact_uploaded',
      });
      setValidationError(null);
      return handleUpload(accepted[0]);
      // eslint-disable-next-line @typescript-eslint/no-implicit-any-catch
    } catch (e: any) {
      if (e.name !== 'TypeError') {
        setValidationError(e.message);
      } else {
        // typeErrors will be handled by the other error alert
        setValidationError(null);
      }
    }
  };

  return (
    <>
      <Container
        {...getRootProps({ isFocused: dragEnter })}
        hasError={Boolean(hasError)}
      >
        <input {...getInputProps()} />
        <Stack alignItems="center" spacing={0}>
          <Box sx={{ color: 'action.disabled' }}>
            <IconAwesomeCustomizable icon={ICONS.fileArrowUp} size="2x" />
          </Box>
          <Typography variant="body2" color="text.secondary" mt={0.5}>
            Drag and drop or{' '}
            <InlineLink simple onClick={open} to="">
              chose file
            </InlineLink>{' '}
            to upload.
          </Typography>
          <Typography variant="caption" color="text.disabled" mt={0}>
            {`Max. file size: ${maxSize / 1000000}MB`}
          </Typography>
        </Stack>
      </Container>
      {acceptedFiles.length ? (
        <Stack mt={2}>
          <FileItem
            file={acceptedFiles[0]}
            key={acceptedFiles[0].name}
            uploadProgress={uploadProgress}
          />
        </Stack>
      ) : null}
      {!isEmpty(fileRejections) ? (
        <Box mt={2}>
          <Alert severity="error" title="Error">
            <span
              dangerouslySetInnerHTML={{
                __html: codeErrorToMessage(fileRejections[0].errors[0].code),
              }}
            ></span>
          </Alert>
        </Box>
      ) : null}
      <Box mt={2}>
        {Boolean(
          validationError ||
            Boolean(hasError) ||
            uploadingArtifactError?.message
        ) ? (
          <Alert severity="error" title="Error">
            <Typography variant="body2">
              {validationError ? (
                <span
                  dangerouslySetInnerHTML={{ __html: validationError }}
                ></span>
              ) : null}
              {hasError}
              {uploadingArtifactError?.message}
            </Typography>
          </Alert>
        ) : null}
      </Box>
    </>
  );
};

export default UploadArtifacts;
