import Accordion from 'shared/view/elements/Accordion/Accordion';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';

export function ConfigurationsFormCreateWrapper({
  expanded,
  handleExpand,
  children,
  learnMoreTo,
}: {
  learnMoreTo: string;
  expanded: boolean;
  handleExpand(isExpanded: boolean): void;
  children: JSX.Element;
}) {
  return (
    <Accordion
      title="No data"
      expanded={expanded}
      summaryRightContent={
        <InlineLink
          onClick={(e) => {
            // to prevent expansion of according
            e.stopPropagation();
          }}
          isExternal
          to={learnMoreTo}
        >
          Learn More
        </InlineLink>
      }
      onChange={(_, isExpanded) => handleExpand(isExpanded)}
    >
      {children}
    </Accordion>
  );
}
