/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import {
  combineValidators,
  validateNotEmpty,
  validateSymbols,
} from 'shared/utils/validators';

export const USER_FRAGMENT = gql`
  fragment UserData on User {
    id
    email
    picture
    username
    fullName
  }
`;

export const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUserData on User {
    id
    email
    picture
    devKey
    secondaryDevKey
    username
    defaultWorkspaceId
    workspaceId
    fullName
    firstName
    lastName
    lastLoginAt
    jobTitle
    company
    createdAt
  }
`;

export const USERV2_FRAGMENT = gql`
  fragment UserV2Data on UserV2 {
    id
    email
    fullName
    firstName
    lastName
    lastSignedIn
    joinedAt
  }
`;

export const SERVICEV2_FRAGMENT = gql`
  fragment ServiceAccountV2Data on ServiceAccountV2 {
    id
    email
    fullName
    firstName
    lastName
    lastSignedIn
    joinedAt
  }
`;

export const validateUsername = (value: string): string | undefined => {
  return combineValidators([
    validateNotEmpty('username'),
    validateSymbols(['alphanumeric characters', 'dashes', 'underscores']),
  ])(value);
};
