/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

export const EDIT_EVALUATION_PROJECT = gql`
  mutation EditEvaluationProject($projectId: ID!, $name: String!) {
    registeredModel(id: $projectId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        updateRegisteredModel(fields: { name: $name }) {
          id
          name
          labels
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const DELETE_EVALUATION_PROJECT = gql`
  mutation DeleteEvaluationProject($projectId: ID!) {
    registeredModel(id: $projectId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        delete
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;
