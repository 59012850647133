import { useState } from 'react';

export const useHover = () => {
  const [isHovered, changeIsHovered] = useState(false);

  const handleMouseOver = () => {
    changeIsHovered(true);
  };

  const handleMouseOut = () => {
    changeIsHovered(false);
  };

  return {
    isHovered,
    handlers: {
      onMouseOver: handleMouseOver,
      onMouseOut: handleMouseOut,
    },
  };
};
