import { gql } from '@apollo/client';

export const SYSTEM_ADMIN_USER_FRAGMENT = gql`
  fragment SystemAdminUserData on User {
    id
    name
    username
    email
    fullName
  }
`;
