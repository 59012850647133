import { gql } from '@apollo/client';

export const OWNER_FRAGMENT = gql`
  fragment OwnerData on UserOrGroup {
    ... on User {
      id
      email
      picture
      username
      fullName
    }
    ... on Group {
      id
      name
    }
  }
`;
