import { useFormikContext } from 'formik';

import { Endpoint } from 'features/deployment/canary/endpoints/store/endpointQuery/endpointQuery';
import { EndpointEnvironmentUpdateStrategy } from 'generated/types';
import matchType from 'shared/utils/matchType';
import RadioButtons from 'shared/view/elements/RadioButtons/RadioButtons';
import Accordion from 'shared/view/elements/Accordion/Accordion';
import { getContextualHelper } from 'features/deployment/canary/shared/getContextualHelper';

import { makeEmptyCanaryRule } from './settings/CanaryStrategyForm';
import CanaryRules from './CanaryRules/CanaryRules';
import { EndpointUpdateSettingsForm } from './settings';

export function RolloutStrategySection({
  environment,
}: {
  environment: Endpoint['environments'][0];
}) {
  const { setValues, values } = useFormikContext<EndpointUpdateSettingsForm>();

  const onChangeStrategyType = (
    strategyType: EndpointEnvironmentUpdateStrategy
  ): void => {
    setValues(
      matchType<typeof strategyType, EndpointUpdateSettingsForm>(
        {
          CANARY: () => {
            return {
              ...values,
              updateStrategyType: EndpointEnvironmentUpdateStrategy.CANARY,
              selectedRules: [
                makeEmptyCanaryRule(environment.canaryRules[0].id),
              ],
              interval: '',
              step: '',
            };
          },
          ROLLOUT: () => ({
            machineConfiguration: values.machineConfiguration,
            source: values.source,
            build: values.build,
            updateStrategyType: EndpointEnvironmentUpdateStrategy.ROLLOUT,
          }),
        },
        strategyType
      )
    );
  };

  return (
    <Accordion
      title="Rollout strategy"
      subtitle="The rollout strategy lets you define how you want to deploy or update your service. You can choose direct or canary rollouts for endpoints."
      dataTest="rollout-strategy-section"
      contextualHelp={getContextualHelper('rollout')}
    >
      <span>
        <RadioButtons<EndpointEnvironmentUpdateStrategy>
          value={values.updateStrategyType}
          options={[
            {
              label: 'Direct',
              value: EndpointEnvironmentUpdateStrategy.ROLLOUT,
              name: 'rollout',
            },
            {
              label: 'Canary',
              value: EndpointEnvironmentUpdateStrategy.CANARY,
              name: 'canary',
            },
          ]}
          onChange={onChangeStrategyType}
        />
        {values.updateStrategyType ===
          EndpointEnvironmentUpdateStrategy.CANARY && (
          <CanaryRules canaryRules={environment.canaryRules} />
        )}
      </span>
    </Accordion>
  );
}
