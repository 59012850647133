/* eslint-disable graphql/template-strings */
import { gql } from '@apollo/client';

export const PERMISSION_FRAGMENT = gql`
  fragment PermissionData on Permissions {
    canDeploy
    canPredict
    type
  }
`;
