import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import routes from 'shared/routes';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { Button } from 'shared/view/elements/Button';
import pluralize from 'shared/utils/pluralize';
import { useWorkspaceRegisteredModelSummary } from 'features/homepage/hooks/useWorkspaceRegisteredModelSummary';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import Chip from 'shared/view/elements/Chip/Chip';

import { RegisterModelPopup } from '../shared/RegisterModelPopup';
import { Container, IconWrapper } from './styled';

export const RegisteredModelSummary = (props: {
  workspaceName: string;
  organizationId: string | undefined;
  isFreetier: boolean;
}) => {
  const { organizationId, workspaceName, isFreetier } = props;
  const { closePopup, isPopupOpen, openPopup } = usePopupManager();
  const navigate = useNavigate();
  const { data, communication } = useWorkspaceRegisteredModelSummary({
    organizationId,
    workspaceName,
  });

  const onCompleted = () => {
    navigate(routes.catalog.getRedirectPath({ workspaceName }));
  };

  const models = isNotError(data) ? data.registeredModels.models : undefined;
  const modelsCount = isNotError(data)
    ? data.registeredModels.pagination.totalRecords
    : 0;
  const modelVersionsCount = isNotError(data)
    ? data.registeredModelVersions.pagination.totalRecords
    : 0;
  const filteredModels = models
    ? models.filter((model) => !model.labels.includes('Verta-Example-Model'))
    : undefined;

  return (
    <Container spacing={4}>
      <Stack direction="row" justifyContent="space-between">
        <IconWrapper icon={ICONS.rectangleVerticalHistory} />
        {isFreetier ? (
          <Chip
            label="Unlimited"
            variant="outlined"
            color="primary"
            size="small"
          />
        ) : null}
      </Stack>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex="1 1 auto" mb={2} display="flex" flexDirection="column">
          <Typography variant="h2">
            {communication.isRequesting ? (
              <Skeleton variant="text" width={50} />
            ) : (
              modelsCount
            )}
          </Typography>

          <Typography variant="subtitle1" color="text.secondary">
            {communication.isRequesting ? (
              <Skeleton variant="text" width={100} />
            ) : (
              pluralize('Registered Model', modelsCount, false)
            )}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {communication.isRequesting ? (
              <Skeleton variant="text" width={150} />
            ) : (
              <>
                <strong>{modelVersionsCount}</strong>{' '}
                {pluralize(
                  'Registered Model Version',
                  modelVersionsCount,
                  false
                )}
              </>
            )}
          </Typography>
        </Box>
        <Box flex="0 1 auto" display="flex">
          {isEmpty(filteredModels) ? (
            communication.isRequesting ? (
              <Skeleton variant="rectangular" width={115} height={30} />
            ) : (
              <Button
                variant="outlined"
                onClick={openPopup}
                size="small"
                isLoading={false}
              >
                Register Model
              </Button>
            )
          ) : null}
        </Box>
      </Box>
      <RegisterModelPopup
        isPopupOpen={isPopupOpen}
        closePopup={closePopup}
        onCompleted={onCompleted}
      />
    </Container>
  );
};
