import { useMemo } from 'react';

import { MonitoringMetricType } from 'generated/types';
import { defaultMonitoringMetricType } from 'shared/models/Monitoring/MonitoringMetricType';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import { validateNotEmpty } from 'shared/utils/validators';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { SingleMetricWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/SingleMetricWidget';
import { OmitStrict } from 'shared/utils/types';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import { MonitoredModelType } from 'shared/models/Monitoring/MonitoringModel/MonitoredModelType';
import { alertSettingsTypes } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertSettings';

import MonitoringWidgetFormPopupContent from '../../MonitoringWidgetFormPopup/MonitoringWidgetFormPopupContent/MonitoringWidgetFormPopupContent';
import MonitoringMetricTypeField from '../shared/MonitoringMetricTypeField/MonitoringMetricTypeField';
import { BaseMonitoringWidgetFormProps } from '../shared/types/BaseMonitoringWidgetFormProps';
import MonitoringOutputDescriptionField from '../shared/MonitoringOutputDescriptionField/MonitoringOutputDescriptionField';
import VariantFields, {
  VariantMatchers,
} from '../shared/VariantFields/VariantFields';
import TypeSelectFieldName from '../shared/TypeSelect/TypeSelectFieldName';

type SingleMetricWidgetFormType = OmitStrict<SingleMetricWidget, 'id'>;

const getFieldName = makeGetFieldName<SingleMetricWidgetFormType>();

interface Props extends BaseMonitoringWidgetFormProps {
  onSubmit: (form: SingleMetricWidgetFormType) => void;
  initialValues: SingleMetricWidgetFormType | null;
  defaultOutput: MonitoringIODescription;
}

const makeVariants = (props: {
  defaultOutput: MonitoringIODescription;
  ioDescriptions: MonitoringIODescription[];
  monitoredModelType: MonitoredModelType;
}): VariantMatchers<SingleMetricWidgetFormType['variant']> => ({
  metric: {
    initialValue: {
      metricType: defaultMonitoringMetricType,
      output: props.defaultOutput,
      type: 'metric',
    },
    label: 'Common',
    render: (values) => (
      <>
        <MonitoringMetricTypeField
          name={getFieldName({ variant: { metricType: null } })}
          monitoredModelType={props.monitoredModelType}
        />

        {values.metricType !== MonitoringMetricType.PREDICTION_COUNT ? (
          <MonitoringOutputDescriptionField
            ioDescriptions={props.ioDescriptions}
            name={getFieldName({ variant: { output: null } })}
          />
        ) : null}
      </>
    ),
  },
  health: {
    initialValue: {
      type: 'health',
      alertType: 'drift',
    },
    label: 'Alerts health',
    render: () => (
      <TypeSelectFieldName
        label="Alert type"
        name={getFieldName({ variant: { alertType: null } })}
        types={alertSettingsTypes}
      />
    ),
  },
});

const SingleMetricWidgetForm = (props: Props) => {
  const initialValues = useMemo(
    (): SingleMetricWidgetFormType => ({
      variant: {
        type: 'metric',
        output: props.defaultOutput,
        metricType: defaultMonitoringMetricType,
      },
      type: 'singleMetric',
      title: '',
    }),
    [props.defaultOutput]
  );

  const variants = useMemo(
    (): VariantMatchers<SingleMetricWidgetFormType['variant']> =>
      makeVariants({
        defaultOutput: props.defaultOutput,
        ioDescriptions: props.widgetExternalDeps.ioDescriptions,
        monitoredModelType: props.widgetExternalDeps.monitoredModelType,
      }),
    [
      props.defaultOutput,
      props.widgetExternalDeps.ioDescriptions,
      props.widgetExternalDeps.monitoredModelType,
    ]
  );

  return (
    <PresetFormik
      initialValues={props.initialValues ?? initialValues}
      onSubmit={props.onSubmit}
    >
      {({ isValid, handleReset, values, setFieldValue }) => (
        <MonitoringWidgetFormPopupContent
          isValid={isValid}
          onBack={props.onBack}
          onReset={handleReset}
          submitText={props.submitText}
          widgetPreview={values}
          onSubmitClick={() => {
            props.onSubmit(values);
          }}
          widgetExternalDeps={props.widgetExternalDeps}
        >
          <TextInputField
            isRequired={true}
            label="Between 1 and 100 Models"
            name={getFieldName({ title: null })}
            validate={validateNotEmpty('title')}
          />

          <VariantFields<SingleMetricWidgetFormType['variant']>
            variants={variants}
            variantTypeFieldName={getFieldName({ variant: { type: null } })}
            value={values.variant}
            onChange={(variant) =>
              setFieldValue(getFieldName({ variant: null }), variant)
            }
          />
        </MonitoringWidgetFormPopupContent>
      )}
    </PresetFormik>
  );
};

export default SingleMetricWidgetForm;
