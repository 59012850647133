import { useSelector } from 'react-redux';

import { selectCurrentWorkspace } from 'features/workspaces';

export const useCurrentOrganizationV2 = (): string | undefined => {
  const workspace = useSelector(selectCurrentWorkspace);
  return workspace.type === 'organization'
    ? workspace.organizationId
    : undefined;
};
