import { gql } from '@apollo/client';

export const PRIMITIVE_KEY_VALUE_FRAGMENT = gql`
  fragment PrimitiveKeyValueData on PrimitiveKeyValue {
    ... on FloatKeyValue {
      key
      floatValue: value
    }
    ... on StringKeyValue {
      key
      stringValue: value
    }
    ... on BoolKeyValue {
      key
      value
    }
  }
`;
