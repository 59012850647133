import { IEnvironmentBlob } from 'shared/models/Versioning/Blob/EnvironmentBlob';
import { makeDockerImage } from 'shared/models/Versioning/Blob/EnvironmentBlob/DockerEnvironmentBlob';
import {
  requirementEnvironmentToString,
  versionEnvironmentToString,
} from 'shared/models/Versioning/Blob/EnvironmentBlob/PythonEnvironmentBlob';

import {
  compareKeyValuePairs,
  KeyValuePairsDiff,
} from './keyValuePairsComparing';
import {
  compareEntitiesPrimitives,
  EntitiesPrimitivesDiff,
} from './primitivesComparing';

export type EnvironmentsDiff = {
  aptPackagesDiff: KeyValuePairsDiff<string>;
  variablesDiff: KeyValuePairsDiff<string>;
  dockersDiff: EntitiesPrimitivesDiff<{ id: string }, string | undefined>;
  pythonsDiff: PythonsDiff;
};

type PythonsDiff = {
  requirements: EntitiesPrimitivesDiff<{ id: string }, string | undefined>;
  pythonVersions: EntitiesPrimitivesDiff<{ id: string }, string | undefined>;
  constraints: KeyValuePairsDiff<string>;
};

type EntityWithEnvironment = {
  id: string;
  environment: IEnvironmentBlob | undefined;
};

export const compareEnvironments = (
  entities: EntityWithEnvironment[]
): EnvironmentsDiff => {
  return {
    aptPackagesDiff: compareKeyValuePairs(
      entities,
      (e) =>
        e.environment?.data.apt?.packages?.map((p) => ({ key: p, value: p })) ||
        []
    ),
    dockersDiff: compareEntitiesPrimitives((e) => {
      const dockerData = getDockerData(e.environment);
      return dockerData ? makeDockerImage(dockerData) : undefined;
    }, entities),
    pythonsDiff: comparePythons(entities),
    variablesDiff: compareKeyValuePairs(
      entities,
      (e) =>
        e.environment?.data.variables?.map((v) => ({
          key: v.name,
          value: v.value,
        })) || []
    ),
  };
};

const comparePythons = (entities: EntityWithEnvironment[]): PythonsDiff => {
  return {
    pythonVersions: compareEntitiesPrimitives((e) => {
      const version = getPythonData(e.environment)?.pythonVersion;
      return version ? versionEnvironmentToString(version) : undefined;
    }, entities),
    constraints: compareKeyValuePairs(
      entities,
      (e) =>
        getPythonData(e.environment)?.constraints?.map((c) => ({
          key: requirementEnvironmentToString(c),
          value: requirementEnvironmentToString(c),
        })) || []
    ),
    requirements: compareRequirements(entities),
  };
};

function compareRequirements(entities: EntityWithEnvironment[]) {
  return compareEntitiesPrimitives((entity) => {
    const requirements = getPythonData(entity.environment)?.requirements;
    if (!requirements) {
      return undefined;
    }
    return requirements.type === 'raw'
      ? requirements.requirements
      : requirements.requirements
          .map(requirementEnvironmentToString)
          .join('\n');
  }, entities);
}

const getPythonData = (environment: IEnvironmentBlob | undefined) =>
  environment?.data.data.type === 'python'
    ? environment.data.data.data
    : undefined;

const getDockerData = (environment: IEnvironmentBlob | undefined) =>
  environment?.data.data.type === 'docker'
    ? environment.data.data.data
    : undefined;
