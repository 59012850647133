import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';
import { isFinishedCommunication } from 'shared/utils/redux/communication';

import { IApplicationState } from '../../../setup/store/store';
import { IUserState } from './types';

const selectState = (state: IApplicationState): IUserState => state.user;

export const selectCurrentUserOrThrowError = (
  state: IApplicationState
): CurrentUserData => {
  const user = selectState(state).data.user;
  if (user) {
    return user;
  }
  throw new Error('user is not found in store');
};

export const selectRegistrationUser = (
  state: IApplicationState
): CurrentUserData | undefined => {
  const user = selectState(state).data.registrationUser;
  if (user !== null) {
    return user;
  }

  return undefined;
};

export const selectCurrentUser = (
  state: IApplicationState
): CurrentUserData | null => {
  return selectState(state).data.user;
};

export const selectCurrentUserOrganizations = (
  state: IApplicationState
): { id: string }[] | null => {
  const { isEnableFreeTier } = state.flags.data.flags;
  const organizations =
    (!isEnableFreeTier && selectState(state).data.organizations) || [];

  const organizationsV2 = selectState(state).data.organizationsV2 || [];

  return [...organizations, ...organizationsV2];
};

export const selectIsCheckedUserAuthentication = (
  state: IApplicationState
): boolean => {
  return isFinishedCommunication(
    selectCommunications(state).checkingUserAuthentication
  );
};

export const selectIsCheckingUserAuthentication = (
  state: IApplicationState
): boolean =>
  selectCommunications(state).checkingUserAuthentication.isRequesting;

export const selectIsUserAuthenticated = (state: IApplicationState): boolean =>
  Boolean(selectState(state).data.user);

export const selectCommunications = (state: IApplicationState) =>
  selectState(state).communications;
