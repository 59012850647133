import { URLWithPagination } from 'shared/models/Pagination/PaginationInURL';
import {
  param,
  queryParams,
  route,
} from 'shared/routes/shared/routesTreeBuilder';
import { IURLWithFilters } from 'features/filters/store/storage/filtersInURL';

const versions = route('versions', {
  tag: 'datasetVersions',
  queryParams: queryParams<IURLWithFilters & URLWithPagination>(),
})(
  param()('datasetVersionId', {
    tag: 'datasetVersion',
  })(),
  route(
    'compare',
    undefined
  )(
    param()('datasetVersionId1', undefined)(
      param()('datasetVersionId2', {
        tag: 'compareDatasetVersions',
      })()
    )
  )
);

export const publicRoutes = route('datasets', {
  tag: 'datasets',
})(
  param()('datasetId', {
    tag: 'dataset',
  })(
    route('overview', {
      tag: 'datasetOverview',
    })(),
    versions
  )
);
