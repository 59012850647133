import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';

import {
  MODEL_ACTIVITIES_DATA_QUERY,
  ModelActivitiesData,
  ModelActivitiesDataVariables,
} from '../graphql';

type Props = ModelActivitiesDataVariables;

export const useModelActivities = ({ id }: Props) => {
  const query = useCustomQuery<
    ModelActivitiesData,
    ModelActivitiesDataVariables
  >(MODEL_ACTIVITIES_DATA_QUERY, {
    variables: { id },
  });

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return {
    data,
    communication,
    reload: query.refetch,
  };
};

const convertResponse = ({ registeredModel }: ModelActivitiesData) =>
  mapDataOrError(registeredModel, (model) => model);
