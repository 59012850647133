import { Stack, Box } from '@mui/material';

import Chip from 'shared/view/elements/Chip/Chip';
import CellRendererEmpty from 'shared/view/elements/DataGrid/columns/CellRendererEmpty';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';

export const CellRendererResult = ({
  value,
}: {
  value: boolean | undefined;
  columnKey: string;
}) => {
  if (value === undefined) {
    return <CellRendererEmpty />;
  }

  const label = value ? 'Accepted' : 'Rejected';
  const color = value ? 'success' : 'error';
  const icon = value ? ICONS.check : ICONS.xMark;

  return (
    <Stack alignItems="center" width="100%">
      <Box>
        <Chip
          variant={'outlined'}
          size="small"
          color={color}
          label={label}
          icon={
            <span>
              <IconAwesomeInfo icon={icon} size="xs" color={`${color}.main`} />
            </span>
          }
        />
      </Box>
    </Stack>
  );
};
