import { Box, Stack, Typography } from '@mui/material';

import Chip from 'shared/view/elements/Chip/Chip';

import image from '../images/checklist-value-delivered.png';
import { Circle } from '../Circle';

export const ChecklistDeliver = () => (
  <Stack direction="row" spacing={0}>
    <Box width="65%" mr={4}>
      <img
        src={image}
        width="100%"
        height="auto"
        alt="custom attributes example"
      />
    </Box>
    <Box width="35%" display="flex" alignItems="center">
      <Box>
        <Circle mb={5}>3</Circle>
        <Typography mb="12px" variant="subtitle1" color="text.secondary">
          Quality and Compliance Simplified
        </Typography>
        <Box maxWidth="290px">
          <Typography mb={2} variant="body2">
            Checklists maintain focus on critical tasks for everyone involved.
          </Typography>
        </Box>
        <Chip
          variant="outlined"
          label="Unlimited for Free Tier"
          color="primary"
          size="small"
        />
      </Box>
    </Box>
  </Stack>
);
