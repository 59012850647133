import { FieldConfig, Field, FieldProps } from 'formik';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  Stack,
} from '@mui/material';
import { ReactNode } from 'react';

import { OmitStrict } from 'shared/utils/types';
import Switch, { SwitchProps } from 'shared/view/elements/Switch/Switch';
import InfoIcon, {
  InfoIconProps,
} from 'shared/view/elements/InfoIcon/InfoIcon';

export default function SwitchField(
  props: FieldConfig &
    OmitStrict<SwitchProps, 'value' | 'name' | 'onChange'> &
    Pick<FormControlLabelProps, 'labelPlacement'> &
    OmitStrict<InfoIconProps, 'tooltip'> & {
      label: string | ReactNode;
      info?: string;
    }
) {
  const { labelPlacement, iconSize, ...rest } = props;
  return (
    <Field {...rest}>
      {({ field, form }: FieldProps<boolean>) => (
        <Box>
          <FormControl fullWidth>
            <Stack direction="row" alignItems="center" width="100%">
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    {...field}
                    checked={field.value || false}
                    onChange={(_, value) => {
                      form.setFieldValue(rest.name, value);
                    }}
                    {...rest}
                  />
                }
                label={rest.label}
                labelPlacement={labelPlacement}
              />
              {rest.info ? (
                <InfoIcon tooltip={rest.info} iconSize={iconSize || 'sm'} />
              ) : null}
            </Stack>
          </FormControl>
        </Box>
      )}
    </Field>
  );
}
