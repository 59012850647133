import { gql } from '@apollo/client';

import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

export const MODEL_DOCUMENTATION_DATA_QUERY = gql`
  query ModelDocumentationDataCases($id: ID!) {
    registeredModel(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        name
        readmeText
        # readmeTextIsFavorite # future prop for saving documentation as favorite
        allowedActions {
          ...AllowedActionsData
        }
        versions {
          versions {
            id
            version
            readmeText
            # readmeTextIsFavorite # future prop for saving documentation as favorite
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;
