import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';

import { OrganizationWorkspacesV2 } from '../store/useOrganizationWorkspaces';

const columns: Array<
  DataGridColumn<OrganizationWorkspacesV2['workspaces'][0]['permissions'][0]>
> = [
  {
    field: 'Group',
    flex: 1,
    renderCell: (params) => (
      <CellRendererString
        value={`${params.row.group.name} ${
          params.row.group.builtIn ? '(Locked)' : ''
        }`}
      />
    ),
  },
  {
    field: 'Role',
    flex: 1,
    renderCell: (params) => (
      <CellRendererString
        value={`${params.row.role.name} ${
          params.row.role.builtIn ? '(Locked)' : ''
        }`}
      />
    ),
  },
  {
    field: 'Role description',
    flex: 1,
    renderCell: (params) => (
      <CellRendererString value={params.row.role.description} />
    ),
  },
];

const WorkspacePermissionsTable = (props: {
  permissions: OrganizationWorkspacesV2['workspaces'][0]['permissions'];
}) => {
  return (
    <DataGridWithTypes
      rows={props.permissions.map((p, i) => ({ ...p, id: String(i) }))}
      columns={columns}
      heightType="autoHeightButMax5Rows"
    />
  );
};

export default WorkspacePermissionsTable;
