import { SupportedIntegrationsStatuses } from 'shared/models/SystemAdmin/SupportedIntegrationsStatuses';
import matchType from 'shared/utils/matchType';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import TextWithInfoIcon from 'shared/view/elements/MarkdownManager/shared/TextWithIcon/TextWithInfoIcon';

export default function IntegrationItemStatus({
  status,
}: {
  status: SupportedIntegrationsStatuses;
}) {
  return matchType(
    {
      active: () => (
        <TextWithInfoIcon
          icon={ICONS.circleCheck}
          infoType={'success'}
          text={'Active'}
        />
      ),
      inactive: () => (
        <TextWithInfoIcon icon={ICONS.circleMinus} text={'Inactive'} />
      ),
    },
    status
  );
}
