import { useState } from 'react';

import { Observation } from 'shared/graphql/ExperimentRunDetails/Observation/graphql-types/Observation.generated';
import { hasEpochValues } from 'shared/models/Observation';
import matchType from 'shared/utils/matchType';

import { ObservationChartXAxisType } from './settings';

export const useXAxisType = (observations: Observation[]) => {
  const [xAxisType, setXAxisType] = useState<ObservationChartXAxisType>(() =>
    hasEpochValues(observations) ? 'epochNumber' : 'timestamp'
  );

  return { xAxisType, setXAxisType };
};

export const matchObservationXAxisType = <T>(
  matchers: Record<ObservationChartXAxisType, () => T>,
  xAxisType: ObservationChartXAxisType
) => {
  return matchType(matchers, xAxisType);
};
