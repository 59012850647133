import { complement, isNil } from 'ramda';

export interface IPythonEnvironmentBlob {
  type: 'python';

  data: {
    requirements?: PythonEnvironmentRequirements;
    constraints?: IPythonRequirementEnvironmentListItem[];
    pythonVersion?: IVersionEnvironmentBlob;
  };
}

export type PythonEnvironmentRequirements =
  | {
      type: 'list';
      requirements: IPythonRequirementEnvironmentListItem[];
    }
  | {
      type: 'raw';
      requirements: string;
    };

export interface IPythonRequirementEnvironmentListItem {
  library: string;
  constraint?: string; // == != and so on
  version?: IVersionEnvironmentBlob;
}
export interface IAptEnvironmentBlob {
  packages?: string[];
}
interface IVersionEnvironmentBlob {
  major?: number;
  minor?: number;
  patch?: number;
  suffix?: string;
}
export const versionEnvironmentToString = ({
  major = 0,
  minor = 0,
  patch = 0,
  suffix,
}: IVersionEnvironmentBlob): string => {
  return `${[major, minor, patch].filter(complement(isNil)).join('.')}${
    suffix || ''
  }`;
};

export const requirementEnvironmentToString = ({
  library,
  constraint,
  version,
}: IPythonRequirementEnvironmentListItem) =>
  `${library} ${constraint || ''} ${
    version ? versionEnvironmentToString(version) : ''
  }`;
