import { isNil } from 'ramda';

import {
  ArtifactDiff,
  CodeVersionsDiff,
  GitDiff,
} from 'shared/models/CompareEntities/new/codeVersionsComparing';
import HashProp from 'shared/view/domain/Versioning/Blob/CodeBlob/GitBlob/HashProp/HashProp';
import { makeRepoBlobUrl } from 'shared/utils/gitUrl/gitUrl';
import { makeKeyedItemsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/KeyedItemsDiff';
import makeItemPropertyDiffView from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/ItemPropertyDiffView';
import BranchProp from 'shared/view/domain/Versioning/Blob/CodeBlob/GitBlob/BranchProp/BranchProp';
import RepoProp from 'shared/view/domain/Versioning/Blob/CodeBlob/GitBlob/RepoProp/RepoProp';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';

import { getArtifactsDiffProperties } from './ArtifactsDiff';
import { makeRowWithSingleColumn } from '../CompareTable/PropertyDiffDescriptions/PropertyDiffDescription';

export const makeCodeVersionsDiff = (diff: CodeVersionsDiff) => {
  const name = 'Code versions';
  return makeKeyedItemsDiff({
    diff,
    name,
    render: (diffByKey) => {
      return [
        ...(diffByKey.data.git ? makeGitDiffView(diffByKey.data.git) : []),
        ...(diffByKey.data.artifact
          ? makeArtifactDiffView(diffByKey.data.artifact)
          : []),
      ];
    },
  });
};

function makeArtifactDiffView(diff: ArtifactDiff) {
  return [
    makeRowWithSingleColumn({
      name: 'Artifact',
      isDifferent: diff.isDifferent,
    }),
    ...getArtifactsDiffProperties(diff.properties),
  ];
}

function makeGitDiffView(diff: GitDiff) {
  return [
    makeRowWithSingleColumn({
      name: 'Git',
      isDifferent: diff.isDifferent,
    }),
    makeItemPropertyDiffView({
      name: 'Hash',
      diff: diff.properties.hash,
      render: (commitHash, git) => (
        <HashProp commitHash={commitHash} remoteRepoUrl={git.remoteRepoUrl} />
      ),
    }),
    makeItemPropertyDiffView({
      name: 'Dirty',
      diff: diff.properties.isDirty,
      render: (isDirty) => isDirty,
    }),
    makeItemPropertyDiffView({
      name: 'Exec path',
      diff: diff.properties.execPath,
      render: (execPath, git) =>
        !isNil(git.remoteRepoUrl) &&
        !isNil(git.hash) &&
        git.remoteRepoUrl.type === 'git' ? (
          <InlineLink
            to={makeRepoBlobUrl(git.remoteRepoUrl.value, {
              execPath,
              commitHash: git.hash,
            })}
            isExternal={true}
          >
            {execPath}
          </InlineLink>
        ) : (
          execPath
        ),
    }),
    makeItemPropertyDiffView({
      name: 'Branch',
      diff: diff.properties.branch,
      render: (branch, git) => (
        <BranchProp branch={branch} remoteRepoUrl={git.remoteRepoUrl} />
      ),
    }),
    makeItemPropertyDiffView({
      name: 'Repo',
      diff: diff.properties.remoteRepoUrl,
      render: (remoteRepoUrl) => <RepoProp remoteRepoUrl={remoteRepoUrl} />,
    }),
  ];
}
