import { useMemo } from 'react';
import { sortBy } from 'ramda';

import { ICommunication } from 'shared/utils/redux/communication';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import CellRendererActions from 'shared/view/elements/DataGrid/columns/CellRendererActions';
import { ConfirmType } from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import isNotNil from 'shared/utils/isNotNill';
import CellRendererNumeric from 'shared/view/elements/DataGrid/columns/CellRendererNumeric';
import makeActionsColumn from 'shared/view/elements/DataGrid/columns/makeActionsColumn';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { trackEvent } from 'setup/app/analytics';

import { DetailsGroupPopup } from './DetailsGroupPopup';
import { OrganizationGroups } from '../store/useOrganizationGroups';
import { useDeleteGroup } from '../store/useDeleteGroup';

type Props = {
  organization: OrganizationGroups;
  communication: ICommunication;
};

const Actions = ({
  group,
  organization,
}: {
  organization: OrganizationGroups;
  group: OrganizationGroups['groups'][0];
}) => {
  const deleteApi = useDeleteGroup();
  const popupApi = usePopupManager();
  return (
    <>
      {popupApi.isPopupOpen ? (
        <DetailsGroupPopup
          users={organization.users.users}
          allowedActions={group.allowedActions}
          settings={{
            ...group,
            orgName: organization.name,
            orgId: organization.id,
            memberIds: group.members.map(({ id }) => id),
          }}
          onClose={popupApi.closePopup}
        />
      ) : null}
      <CellRendererActions
        actions={[
          {
            type: 'icon' as const,
            icon: ICONS.pencil,
            label: 'Edit',
            dataTest: 'edit-action',
            onClick: () => {
              trackEvent({ type: 'organization.groups.edit_clicked' });
              popupApi.openPopup();
            },
          },
          !group.builtIn
            ? {
                type: 'icon' as const,
                icon: ICONS.trashCan,
                label: 'Delete',
                dataTest: 'delete-action',
                disabled: !group.allowedActions.delete,
                onClick: () => {
                  trackEvent({ type: 'organization.groups.delete_clicked' });
                  deleteApi.run.bind(null, {
                    groupId: group.id,
                    organizationName: organization.name,
                  });
                },
                isLoading: deleteApi.communication.isRequesting,
                confirmAction: {
                  type: ConfirmType.delete,
                  description: 'group',
                  entityName: group.name,
                },
              }
            : undefined,
        ].filter(isNotNil)}
      />
    </>
  );
};

const OrganizationGroupsTable = (props: Props) => {
  const columns: Array<DataGridColumn<OrganizationGroups['groups'][0]>> = [
    {
      field: 'Name',
      flex: 1,
      renderCell: (params) => (
        <CellRendererString
          value={
            params.row.builtIn ? `${params.row.name} (Locked)` : params.row.name
          }
        />
      ),
    },
    {
      field: 'Description',
      flex: 1,
      renderCell: (params) => (
        <CellRendererString value={params.row.description} />
      ),
    },
    {
      field: 'Number of users',
      flex: 1,
      renderCell: (params) => (
        <CellRendererNumeric value={params.row.members.length} />
      ),
    },
    makeActionsColumn({
      renderCell: (params) => (
        <Actions organization={props.organization} group={params.row} />
      ),
    }),
  ];

  const rows = useMemo(
    () => sortBy((p) => (p.builtIn ? 0 : 1), props.organization.groups),
    [props.organization.groups]
  );

  return (
    <DataGridWithTypes
      heightType="pageHeight"
      rows={rows}
      columns={columns}
      communication={props.communication}
      context="loading users"
    />
  );
};

export default OrganizationGroupsTable;
