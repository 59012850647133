import { Divider, Grid, Box } from '@mui/material';
import { FormikErrors } from 'formik';
import { isEmpty, isNil } from 'lodash';

import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import {
  validateNotEmpty,
  validateNotEqual,
  combineValidators,
  validate,
  validateMaxLength,
} from 'shared/utils/validators';
import Popup from 'shared/view/elements/Popup/Popup';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import {
  CustomAttributeDefinitionInput,
  CustomAttributeTargetType,
  CustomAttributeType,
} from 'generated/types';
import { useCreateOrUpdateCustomAttribute } from 'features/organizations/custom-attributes/hooks';
import Alert from 'shared/view/elements/Alert/Alert';
import CheckboxField from 'shared/view/formComponents/formikFields/CheckboxField/CheckboxField';

import { CustomModelAttributeListItemManager } from './CustomModelAttributeListItemManager';
import { CustomAttributeCategories } from '../../graphql';

export const EMPTY_CUSTOM_ATTRIBUTE_ITEM_INPUT = {
  name: '',
  sort: 0,
};

const EMPTY_CUSTOM_ATTRIBUTE_TEMPLATE_INPUT: CustomAttributeDefinitionInput = {
  name: '',
  attributeType: CustomAttributeType.UNKNOWN,
  icon: '',
  sort: 1,
  targetType: CustomAttributeTargetType.UNKNOWN,
  organizationId: '',
  sourceRequired: false,
  customAttributeListOptions: [EMPTY_CUSTOM_ATTRIBUTE_ITEM_INPUT],
};

type AttrsWithValues = Record<string, boolean>;

type Props = {
  title: string;
  customAttribute: CustomAttributeDefinitionInput | undefined;
  submitButton: {
    children: 'Create attribute' | 'Save changes';
  };
  organizationId: string;
  onClose: () => void;
  type?: CustomAttributeTargetType;
  attrsWithValues: AttrsWithValues;
  categories: CustomAttributeCategories | null;
};

interface CustomAttributeTypeOption {
  label: string;
  value: CustomAttributeType;
  description?: string;
}

const CUSTOM_ATTRIBUTES_TYPES_OPTIONS: CustomAttributeTypeOption[] = [
  {
    value: CustomAttributeType.CUSTOM_LIST,
    label: 'Custom list',
    description:
      'Build your own list with custom options/items for your team to chose from.',
  },
  {
    value: CustomAttributeType.USER,
    label: 'List of users',
    description:
      'Your team will select a user from a list of all users in your organization.',
  },
  {
    value: CustomAttributeType.GROUP,
    label: 'List of groups',
    description:
      'Your team will select a group from a list of users groups in your organization.',
  },
  {
    value: CustomAttributeType.TEXT,
    label: 'Short text input',
    description:
      'Allow your team to enter a short text value with up to 250 characters.',
  },
  {
    value: CustomAttributeType.LONG_TEXT,
    label: 'Long text input',
    description:
      'Allow your team to enter a long text value with up to 2500 characters.',
  },
  {
    value: CustomAttributeType.NUMERIC,
    label: 'Numeric',
    description: 'Your team will enter a numeric value.',
  },
];

export const CustomModelAttributePopup = (props: Props) => {
  const {
    title,
    customAttribute,
    submitButton,
    onClose,
    organizationId,
    type,
    attrsWithValues,
    categories,
  } = props;

  const initialValues =
    customAttribute === undefined
      ? {
          ...EMPTY_CUSTOM_ATTRIBUTE_TEMPLATE_INPUT,
          targetType: type || CustomAttributeTargetType.UNKNOWN,
          organizationId,
        }
      : cleanCustomAttribute(customAttribute);

  let showWarningMessage = false;

  if (initialValues.id) {
    showWarningMessage = Object.keys(attrsWithValues).includes(
      initialValues.id
    );
  }

  const categoriesSelect = categories
    ? categories.map((category) => ({
        value: category.id,
        label: category.name,
      }))
    : [];

  const { createOrUpdate, communication } = useCreateOrUpdateCustomAttribute({
    onCompleted: onClose,
  });

  const renderEditImplicationsWarning = () => {
    return (
      <Alert severity="warning" title="Attention" sx={{ mt: 1 }}>
        Please be aware that{' '}
        <u>
          any changes made to a model custom attribute will be immediately
          reflected across all other models using the same attribute
        </u>
        . Make sure that you have carefully considered the implications before
        saving any changes.
      </Alert>
    );
  };

  return (
    <PresetFormik<CustomAttributeDefinitionInput>
      initialValues={initialValues}
      onSubmit={async (values) => {
        values.customAttributeListOptions =
          values.customAttributeListOptions?.map((value, index) => ({
            ...value,
            sort: index,
          }));
        await createOrUpdate({
          input: values,
          organizationId,
        });
      }}
      validateOnChange={false}
      validateOnMount={false}
      validateOnBlur={false}
    >
      {({ values, submitForm, errors }) => {
        const validateEmptyOptions = () => {
          const isEmptyOptions =
            values.attributeType === CustomAttributeType.CUSTOM_LIST &&
            isEmpty(values.customAttributeListOptions);
          return validate({
            isValid: !isEmptyOptions,
            errorMessage: 'Must have options',
          });
        };

        return (
          <Popup
            isOpen={true}
            title={title}
            fullWidth={true}
            maxWidth="xs"
            buttons={{
              secondaryButtonProps: {
                children: 'Cancel',
                isLoading: false,
                onClick: onClose,
              },
              mainButtonProps: {
                ...submitButton,
                isLoading: communication.isRequesting,
                type: 'button',
                onClick: submitForm,
              },
            }}
            onClose={onClose}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInputField
                  label="Attribute name"
                  name={getField({ name: null })}
                  isRequired
                  validate={combineValidators([
                    validateNotEmpty('Attribute name*'),
                    validateMaxLength(250),
                  ])}
                  withoutError={!Boolean(errors.name)}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectField
                  label="Attribute category"
                  name={getField({ customAttributeCategoryId: null })}
                  options={categoriesSelect}
                  withoutError={!Boolean(errors.customAttributeCategoryId)}
                />
              </Grid>
              <Grid item xs={12}>
                <CheckboxField
                  name={getField({ sourceRequired: null })}
                  dataTest="source-required"
                  label="Request source"
                />
              </Grid>

              <Grid item xs={12} my="10px">
                <Divider />
              </Grid>

              <Grid item xs={11}>
                <SelectField
                  label="Attribute type"
                  name={getField({ attributeType: null })}
                  options={CUSTOM_ATTRIBUTES_TYPES_OPTIONS}
                  validate={combineValidators([
                    validateEmptyOptions,
                    validateNotEqual(
                      CustomAttributeType.UNKNOWN,
                      'Attribute type'
                    ),
                  ])}
                  required
                  withDescription
                  withoutError={!Boolean(errors.attributeType)}
                  disabled={Boolean(initialValues.id)}
                />
              </Grid>
            </Grid>
            <Box>
              {values.attributeType === CustomAttributeType.CUSTOM_LIST ? (
                <CustomModelAttributeListItemManager
                  values={values.customAttributeListOptions}
                  errors={
                    errors.customAttributeListOptions as unknown as FormikErrors<any>[]
                  }
                />
              ) : null}
            </Box>
            {showWarningMessage ? renderEditImplicationsWarning() : null}
          </Popup>
        );
      }}
    </PresetFormik>
  );
};

export const getField = makeGetFieldName<any>();

const cleanCustomAttribute = (
  customAttribute: CustomAttributeDefinitionInput
) => {
  const cleaned: CustomAttributeDefinitionInput = {
    id: customAttribute.id,
    name: customAttribute.name,
    attributeType: customAttribute.attributeType,
    icon: customAttribute.icon,
    organizationId: customAttribute.organizationId,
    targetType: customAttribute.targetType,
    sort: customAttribute.sort,
    sourceRequired: customAttribute.sourceRequired,
    customAttributeCategoryId: customAttribute.customAttributeCategoryId,
  };

  cleaned.customAttributeListOptions =
    isEmpty(customAttribute.customAttributeListOptions) ||
    isNil(customAttribute.customAttributeListOptions)
      ? []
      : customAttribute.customAttributeListOptions.map((e) => ({
          id: e.id,
          name: e.name,
          sort: e.sort,
        }));

  return cleaned;
};
