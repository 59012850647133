import { QueryHookOptions } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';

import {
  OrganizationUsersCount,
  OrganizationUsersCountVariables,
} from '../graphql/graphql-types/queries.generated';
import { ORGANIZATION_USERS_COUNT } from '../graphql/queries';

export const useOrganizationUsersCount = (
  props: {
    organizationId: string;
  },
  options?: QueryHookOptions<
    OrganizationUsersCount,
    OrganizationUsersCountVariables
  >
) => {
  const { organizationId } = props;

  const query = useCustomQuery<
    OrganizationUsersCount,
    OrganizationUsersCountVariables
  >(ORGANIZATION_USERS_COUNT, {
    ...options,
    variables: {
      organizationId,
    },
  });

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return {
    data,
    communication,
    reload: query.refetch,
  };
};

const convertResponse = ({ organizationV2 }: OrganizationUsersCount) =>
  mapDataOrError(
    organizationV2,
    (_organizationV2) => _organizationV2.users.pagination.totalRecords
  );
