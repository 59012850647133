import { gql } from '@apollo/client';

import { AlertSettings } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertSettings';
import matchBy from 'shared/utils/matchBy';

import { AlertSettingsFragment } from './graphql-types/AlertSettings.generated';

export const ALERT_SETTINGS_FRAGMENT = gql`
  fragment AlertSettingsFragment on AlertSettings {
    ... on DriftMetricAlertSettings {
      driftMetricType
    }
    ... on MetricAlertSettings {
      metricType
    }
    ... on ConfidenceMetricAlertSettings {
      confidenceMetricType
    }
  }
`;

export const convertAlertSettings = (
  data: AlertSettingsFragment
): AlertSettings => {
  if (data.__typename === 'ConfidenceMetricAlertSettings') {
    throw new Error('Alert with confidence metric type is not supported');
  }

  return matchBy(
    data,
    '__typename'
  )<AlertSettings>({
    DriftMetricAlertSettings: (d) => ({
      type: 'drift',
      driftMetricType: d.driftMetricType,
    }),
    MetricAlertSettings: (d) => ({
      type: 'metric',
      metricType: d.metricType,
    }),
  });
};
