import { groupBy } from 'ramda';

import MainMuiTheme from 'shared/mui/themes/MainMuiTheme';
import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';
import matchType from 'shared/utils/matchType';
import { BasePoint } from 'shared/view/charts/MultiLineChart/utils/point';

type OutlierDetectionChartDataType = OutlierDetectionChartData['type'];

export type OutlierDetectionChartData =
  | OutlierDetectionChartPoint
  | NormalAreaData;

export type OutlierDetectionChartPoint =
  | OutlierDetectionChartDataPoint
  | MedianData
  | MeanData;
const outlierDetectionChartPointTypes = exhaustiveStringTuple<
  OutlierDetectionChartPoint['type']
>()('mean', 'median', 'inlier', 'outlier');

export type OutlierDetectionChartDataPoint = OutlierData | InlierData;
const outlierDetectionChartDataPointTypes = exhaustiveStringTuple<
  OutlierDetectionChartDataPoint['type']
>()('inlier', 'outlier');

interface BaseDataPoint extends BasePoint {
  sigma: number;
  modelVersion: string;
}

export interface OutlierData extends BaseDataPoint {
  type: 'outlier';
}

export interface InlierData extends BaseDataPoint {
  type: 'inlier';
}

export interface MeanData extends BasePoint {
  type: 'mean';
}

export interface MedianData extends BasePoint {
  type: 'median';
}

export interface NormalAreaData {
  type: 'normalArea';
  x: number;
  y0: number;
  y1: number;
}

type OutlierDetectionChartDataByType = {
  [K in OutlierDetectionChartDataType]: Array<
    Extract<OutlierDetectionChartData, { type: K }>
  >;
};

export const groupOutlierChartData = (
  data: OutlierDetectionChartData[]
): Partial<OutlierDetectionChartDataByType> => {
  return groupBy((d) => d.type, data) as OutlierDetectionChartDataByType;
};

export const outlierDetectionChartDataTypes =
  exhaustiveStringTuple<OutlierDetectionChartDataType>()(
    'outlier',
    'mean',
    'normalArea',
    'inlier',
    'median'
  );

export const getColorByOutlierDetectionChartDataType = (
  type: OutlierDetectionChartDataType
) =>
  matchType(
    {
      mean: () => MainMuiTheme.palette.primary.main,
      inlier: () => 'rgb(48, 131, 255)',
      outlier: () => '#ffb600',
      normalArea: () => 'rgb(48, 131, 255, 0.3)',
      median: () => '#d230ff',
    },
    type
  );

export const getDataLabelByOutlierDetectionChartDataType = (
  type: OutlierDetectionChartDataType
) =>
  matchType(
    {
      mean: () => 'Mean',
      inlier: () => 'Inliers',
      outlier: () => 'Outliers',
      normalArea: () => 'Normal area',
      median: () => 'Median',
    },
    type
  );

export const isOutlierDetectionChartDataPoint = (
  data: OutlierDetectionChartData
): data is OutlierDetectionChartDataPoint => {
  return outlierDetectionChartDataPointTypes.some((d) => d === data.type);
};

export const isOutlierDetectionChartPoint = (
  data: OutlierDetectionChartData
): data is OutlierDetectionChartPoint => {
  return outlierDetectionChartPointTypes.some((d) => d === data.type);
};
