import React from 'react';
import cn from 'classnames';
import { Typography } from '@mui/material';

import InfoIcon from 'shared/view/elements/InfoIcon/InfoIcon';

import { RecordsSectionContainer, RecordContainer } from './styles';

interface DataTests {
  label?: string;
  value?: string;
  root?: string;
}

type LabelAlign = 'top' | 'center' | 'withInput';

interface CommonRecord {
  label: React.ReactNode;
  dataTests?: DataTests;
  labelAlign?: LabelAlign;
  info?: string;
}

interface RecordWithContent extends CommonRecord {
  children: React.ReactNode;
}
interface RecordWithNestedRecords extends CommonRecord {
  children: React.ReactNode;
}
type IRecord = RecordWithContent | RecordWithNestedRecords;

export type Spacing = 'default' | 'min' | 'max';

interface ILocalProps {
  spacing?: Spacing;
  labelColumnMinWidth?: number;
  children: React.ReactNode;
}

const RecordsSection: React.FC<React.PropsWithChildren<ILocalProps>> = (
  props
) => {
  const { spacing = 'default', children } = props;

  return (
    <RecordsSectionContainer
      $spacing={spacing}
      $labelColumnMinWidth={props.labelColumnMinWidth}
    >
      {children}
    </RecordsSectionContainer>
  );
};

export const NestedRecordInfo: React.FC<
  React.PropsWithChildren<RecordWithNestedRecords>
> = (record) => {
  return (
    <RecordContainer className="record" dataTest={record.dataTests?.root}>
      <RecordInfoLabel record={record} />
      <div className="stub" />
      <div className="record__records">{record.children}</div>
    </RecordContainer>
  );
};

export const RecordInfo: React.FC<
  React.PropsWithChildren<RecordWithContent>
> = (record) => {
  return (
    <RecordContainer className="record" dataTest={record.dataTests?.root}>
      <RecordInfoLabel record={record} />
      <div
        className="record__content"
        data-test={
          record.dataTests?.value || `${record.dataTests?.root || ''}-value`
        }
      >
        {record.children}
      </div>
    </RecordContainer>
  );
};

const RecordInfoLabel: React.FC<
  React.PropsWithChildren<{ record: IRecord }>
> = ({ record }) => {
  return (
    <Typography
      variant="body2"
      className={cn('record__label', {
        record__label_align_top: record.labelAlign === 'top',
        record__label_align_center: record.labelAlign === 'center',
        record__label_align_withInput: record.labelAlign === 'withInput',
      })}
      data-test={
        record.dataTests?.label || `${record.dataTests?.root || ''}-label`
      }
    >
      <span className="record__labelContent">
        <span>{record.label}</span>
        {record.info ? <InfoIcon tooltip={record.info} /> : null}
      </span>
    </Typography>
  );
};

export default RecordsSection;
