import { Field, FieldArray, FieldProps, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';
import Stack from '@mui/material/Stack';

import { CanaryRule } from 'generated/types';
import { combineValidators, validateNotEmpty } from 'shared/utils/validators';
import Select from 'shared/view/elements/Selects/Select/Select';
import SubSection from 'features/deployment/canary/shared/SubSection/SubSection';
import Placeholder from 'shared/view/elements/Placeholder/Placeholder';
import { validateCanaryRuleInterval } from 'shared/models/Deployment/canary/CanaryRules/CanaryRuleInterval';
import { validateCanaryRuleStep } from 'shared/models/Deployment/canary/CanaryRules/CanaryRuleStep';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import generateId from 'shared/utils/generateId';
import AutocompleteField from 'shared/view/formComponents/formikFields/AutocompleteField';
import { getRuleParameterValidator } from 'shared/models/Deployment/canary/CanaryRules/CanaryRuleParameter';
import Button from 'shared/view/elements/Button/Button';
import { componentsSizes } from 'shared/mui/themes/CommonMuiThemeOptions';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import {
  getCanaryRuleOptions,
  getCanaryRuleOptionValue,
} from './CanaryRuleOptions';
import { getFieldName } from '../settings/getFieldName';
import {
  makeEmptyCanaryRule,
  SelectedCanaryRule,
  SelectedCanaryRuleParameter,
} from '../settings/CanaryStrategyForm';

const CanaryRules: FC<
  React.PropsWithChildren<{
    canaryRules: CanaryRule[];
  }>
> = ({ canaryRules }) => {
  return (
    <SubSection title="Select the canary rules">
      {canaryRules.length === 0 ? (
        <Placeholder>Environment doesn't have any rules</Placeholder>
      ) : (
        <FormStack dataTest="canary-settings">
          <AutocompleteField
            isRequired={true}
            name={getFieldName({ interval: null })}
            validate={validateCanaryRuleInterval}
            options={intervalOptions}
            label="Interval"
          />
          <AutocompleteField
            isRequired={true}
            name={getFieldName({ step: null })}
            validate={validateCanaryRuleStep}
            options={stepOptions}
            label="Step"
          />
          <Field name={getFieldName({ selectedRules: null })}>
            {({ meta, field }: FieldProps<SelectedCanaryRule[]>) => (
              <FieldArray name={field.name}>
                {({ push, remove }) => (
                  <>
                    {meta.value.map((selectedRule, index) => (
                      <SelectedCanaryRuleView
                        key={selectedRule.id}
                        index={index}
                        selectedRule={selectedRule}
                        remove={remove}
                        rules={canaryRules}
                        canBeRemoved={meta.value.length > 1}
                      />
                    ))}
                    <Button
                      variant={'text'}
                      dataTest="add-rule"
                      isLoading={false}
                      onClick={() => {
                        push(makeEmptyCanaryRule(generateId()));
                      }}
                    >
                      + Add Rule
                    </Button>
                  </>
                )}
              </FieldArray>
            )}
          </Field>
        </FormStack>
      )}
    </SubSection>
  );
};

const intervalOptions = ['30s', '1m', '5m', '15m', '30m', '1h'].map(
  (duration) => ({
    label: duration,
    value: duration,
  })
);

const stepOptions = ['1', '5', '10', '20', '50'].map((step) => ({
  label: `${step}%`,
  value: `${step}%`,
}));

const SelectedCanaryRuleView: FC<
  React.PropsWithChildren<{
    rules: CanaryRule[];
    selectedRule: SelectedCanaryRule;
    canBeRemoved: boolean;
    index: number;
    remove: (index: number) => void;
  }>
> = ({ rules, selectedRule, canBeRemoved, index, remove }) => {
  const { validateForm } = useFormikContext();
  // the canary rules shown only when a user select the canary update strategy
  // Formik don't validate conditional fields after showing and hiding, so we have to do it manually
  useEffect(() => {
    validateForm();
    return () => {
      setTimeout(() => validateForm());
    };
  }, [validateForm]);
  const fieldName = getFieldName({
    selectedRules: { [index]: null },
  });
  const fieldIdName = getFieldName({
    selectedRules: { [index]: { canonicalRule: { id: null } } },
  });

  return (
    <Stack direction={'column'} data-test={`rule-${index}`}>
      <Stack direction={'row'} alignItems={'center'}>
        <span
          style={{ width: '100%', maxWidth: componentsSizes.input.maxWidth }}
        >
          <Field name={fieldIdName} validate={validateNotEmpty('Rule')}>
            {({ field, meta, form }: FieldProps<string>) => (
              <Select
                value={getCanaryRuleOptionValue(selectedRule)}
                dataTest={fieldIdName}
                label={`Rule ${index + 1}`}
                options={getCanaryRuleOptions(rules)}
                onChange={(option) => {
                  form.setFieldValue(fieldName, {
                    id: selectedRule.id,
                    canonicalRule: option.value,
                    parameters: option.value.parameters.map((p) => ({
                      ...p,
                      value: '',
                    })),
                  });
                }}
                errorMessage={meta.touched ? meta.error : undefined}
                endAdornmentIcons={[
                  <IconAwesomeClickable
                    key={0}
                    icon={ICONS.trashCan}
                    disabled={!canBeRemoved}
                    onClick={() => remove(index)}
                  />,
                ]}
                onBlur={field.onBlur}
              />
            )}
          </Field>
        </span>
      </Stack>
      {(selectedRule.parameters || []).map((parameter, parameterIndex) => (
        <CanaryRuleParameter
          key={parameterIndex}
          parameter={parameter}
          parameterIndex={parameterIndex}
          ruleIndex={index}
        />
      ))}
    </Stack>
  );
};

function CanaryRuleParameter({
  parameterIndex,
  parameter,
  ruleIndex,
}: {
  parameterIndex: number;
  parameter: SelectedCanaryRuleParameter;
  ruleIndex: number;
}): JSX.Element {
  const name = getFieldName({
    selectedRules: {
      [ruleIndex]: {
        parameters: { [parameterIndex]: { value: null } },
      },
    },
  });
  const { validateForm } = useFormikContext();
  useEffect(() => {
    validateForm();
  }, [validateForm]);
  return (
    <TextInputField
      name={name}
      label={`${parameter.description} for rule ${ruleIndex + 1}`}
      value={parameter.value}
      validate={combineValidators([
        validateNotEmpty('Threshold'),
        getRuleParameterValidator({
          name: parameter.name,
          type: parameter.type,
        }),
      ])}
    />
  );
}

export default CanaryRules;
