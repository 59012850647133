import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';

import DatasetsPage from './DatasetsPage/DatasetsPage';
import { datasetDetailsPages } from './DatasetDetailPages';

export const datasetPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.datasets.getPath(),
      element: <DatasetsPage />,
    },
    ...datasetDetailsPages.getRoutes(),
  ],
};
