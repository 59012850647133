import { gql } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import { CUSTOM_ATTRIBUTE_CATEGORY_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeCategory';
import { ExtractByTypename } from 'shared/utils/types';

import {
  CREATE_OR_UPDATE_CUSTOM_ATTRIBUTE_CATEGORY_QUERY,
  CreateOrUpdateCustomAttributeCategory,
  CreateOrUpdateCustomAttributeCategoryVariables,
} from '../graphql';
import { organizationV2Fragment } from './graphql-types/useCreateOrUpdateCustomAttributeCategory.generated';

type CustomAttributeCategories = ExtractByTypename<
  organizationV2Fragment['customAttributeCategories'],
  'CustomAttributeCategories'
>;

const ORGANIZATION_CUSTOM_ATTRIBUTES_CATEGORIES_FRAGMENT = gql`
  fragment organizationV2Fragment on OrganizationV2 {
    id
    customAttributeCategories {
      ... on CustomAttributeCategories {
        customAttributeCategories {
          id
          ...CustomAttributeCategoryData
        }
      }
    }
  }
  ${CUSTOM_ATTRIBUTE_CATEGORY_FRAGMENT}
`;

type Props = {
  onCompleted: () => void;
};

export const useCreateOrUpdateCustomAttributeCategory = ({
  onCompleted,
}: Props) => {
  const [createOrUpdate, communication] = useCustomMutation<
    CreateOrUpdateCustomAttributeCategory,
    CreateOrUpdateCustomAttributeCategoryVariables
  >(
    CREATE_OR_UPDATE_CUSTOM_ATTRIBUTE_CATEGORY_QUERY,
    {
      context: 'creating or updating custom attribute category',
    },
    {
      onCompleted,
      update: (cache, { data }) => {
        if (
          isNotError(data?.organizationV2) &&
          isNotError(
            data?.organizationV2.createOrUpdateCustomAttributeCategory
          ) &&
          data?.organizationV2.createOrUpdateCustomAttributeCategory !==
            undefined
        ) {
          const category =
            data.organizationV2.createOrUpdateCustomAttributeCategory;

          const organization: organizationV2Fragment | null =
            cache.readFragment({
              id: `OrganizationV2:${category.organizationId}`,
              fragment: ORGANIZATION_CUSTOM_ATTRIBUTES_CATEGORIES_FRAGMENT,
              fragmentName: 'organizationV2Fragment',
            });

          if (
            organization &&
            isNotError(organization.customAttributeCategories) &&
            organization.customAttributeCategories.customAttributeCategories !==
              null &&
            // just update cache if is a new record. Apollo cache binds automatically for existing elements
            !Boolean(
              organization.customAttributeCategories.customAttributeCategories.find(
                (cat) => cat.id === category.id
              )
            )
          ) {
            const newCustomAttributeCategories: CustomAttributeCategories['customAttributeCategories'] =
              [
                ...organization.customAttributeCategories
                  .customAttributeCategories,
                category,
              ];
            cache.writeFragment({
              id: `OrganizationV2:${category.organizationId}`,
              fragment: ORGANIZATION_CUSTOM_ATTRIBUTES_CATEGORIES_FRAGMENT,
              fragmentName: 'organizationV2Fragment',
              data: {
                ...organization,
                customAttributeCategories: {
                  ...organization.customAttributeCategories,
                  customAttributeCategories: newCustomAttributeCategories,
                },
              },
            });
          }
        }
      },
    }
  );

  return {
    createOrUpdate,
    communication,
  };
};
