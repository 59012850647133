import { useMemo } from 'react';

import { MonitoringModelRegisteredModelVersion } from 'shared/models/Monitoring/MonitoringModel/MonitoringModelRegisteredModelVersion';
import { formatDateWithTime } from 'shared/utils/formatters/dateTime';
import Select from 'shared/view/elements/Selects/Select/Select';

interface Props {
  selectedRegisteredModelVersion:
    | MonitoringModelRegisteredModelVersion
    | undefined;
  registeredModelVersions: MonitoringModelRegisteredModelVersion[];
  onChange(x: MonitoringModelRegisteredModelVersion): void;
}

const MonitoringModelVersionSelect = ({
  registeredModelVersions,
  selectedRegisteredModelVersion,
  onChange,
}: Props) => {
  const options = useMemo(
    () =>
      registeredModelVersions.map((m) => {
        return {
          label: `${m.version}, release date: ${formatDateWithTime(
            m.dateUpdated
          )}`,
          value: m,
        };
      }),
    [registeredModelVersions]
  );

  return (
    <Select
      value={options.find(
        (o) => o.value.id === selectedRegisteredModelVersion?.id
      )}
      options={options}
      onChange={(o) => onChange(o.value)}
      placeholder="Model version"
      withoutError
    />
  );
};

export default MonitoringModelVersionSelect;
