import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeIsEnableDarkMode } from './actions';
import { selectIsEnableDarkMode } from './selectors';
import { darkModeStateStorageManager } from './storage';

export const useDarkMode = () => {
  const isEnableDarkMode = useSelector(selectIsEnableDarkMode);

  useEffect(() => {
    darkModeStateStorageManager.write(isEnableDarkMode);
  }, [isEnableDarkMode]);

  const dispatch = useDispatch();

  const changeIsEnableDarkModeHandler = (value: boolean) => {
    dispatch(changeIsEnableDarkMode(value));
  };

  return {
    isEnableDarkMode,
    changeIsEnableDarkModeHandler,
  };
};
