import { useState } from 'react';
import {
  Box,
  Stack,
  FormControl,
  FormControlLabel,
  Typography,
} from '@mui/material';

import Popup from 'shared/view/elements/Popup/Popup';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import { DataTypeSelector } from 'shared/view/domain/Catalog/DataTypeSelector';
import { TaskTypeSelector } from 'shared/view/domain/Catalog/TaskTypeSelector';
import { TaskType, DataType, OwnerType } from 'generated/types';
import { useUpdateModel } from 'features/catalog/shared/store/useUpdateModel/useUpdateModel';
import { RequiredOwner } from 'features/editResourceInformation/store/useEditResourceInformation';
import OwnerSelector from 'features/editResourceInformation/view/EditResourceInformation/OwnerSelector';
import { RegisteredModel } from 'pages/authorized/CatalogPages/ModelPages/shared/Layout/store';
import { Switch } from 'shared/view/elements/Switch/Switch';
import Alert from 'shared/view/elements/Alert/Alert';
import { trackEvent, RegisteredModel_Edited } from 'setup/app/analytics';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

interface Props {
  isOpen: boolean;
  registeredModel: RegisteredModel;
  isEditable: boolean;
  onClose(): void;
}

type ModelInput = {
  name: string;
  description: string;
  dataType: DataType;
  taskType: TaskType;
  pii: boolean;
  ownerType: RequiredOwner['__typename'];
  ownerId: string;
};

const getEditModelInfoDataForSegmentEvent = (
  oldValues: RegisteredModel,
  newValues: ModelInput,
  organizationId?: string
): RegisteredModel_Edited['data'] => {
  const data: RegisteredModel_Edited['data'] = {
    organizationId,
    name: newValues.name,
    id: oldValues.id,
    changed: {},
  };

  if (oldValues.name !== newValues.name) data.changed.name = true;
  if (oldValues.description !== newValues.description)
    data.changed.description = true;
  if (oldValues.pii !== newValues.pii) data.changed.pii = true;
  if (oldValues.dataType !== newValues.dataType) data.changed.dataType = true;
  if (oldValues.taskType !== newValues.taskType) data.changed.taskType = true;
  if (oldValues.owner.id !== newValues.ownerId) data.changed.ownership = true;

  return data;
};

const ModelEditor = (props: Props) => {
  const { isOpen, onClose, isEditable, registeredModel } = props;
  const { name, description, dataType, taskType, id, owner, pii } =
    registeredModel;
  const organizationId = useCurrentOrganizationV2();

  const [internalState, setInternalState] = useState<ModelInput>({
    name,
    description,
    dataType,
    taskType,
    pii,
    ownerId: owner.id,
    ownerType: owner.__typename,
  });

  const [piiUpdated, setPiiUpdated] = useState(false);

  const [updateModel] = useUpdateModel();

  const handleUpdate = (newState: ModelInput) => {
    setInternalState(newState);
  };

  const togglePii = () => {
    handleUpdate({
      ...internalState,
      pii: !internalState.pii,
    });
    setPiiUpdated((value) => !value);
  };

  const onConfirm = () => {
    const newValues = {
      id,
      fields: {
        pii: internalState.pii,
        dataType: internalState.dataType,
        description: internalState.description,
        name: internalState.name,
        taskType: internalState.taskType,
      },
      ownerInput: {
        id: internalState.ownerId,
        type: internalState.ownerType as OwnerType,
      },
    };

    updateModel(newValues);
    trackEvent({
      type: 'catalog.registered_model.edited',
      data: getEditModelInfoDataForSegmentEvent(
        registeredModel,
        internalState,
        organizationId
      ),
    });
    onClose();
  };

  const onCancel = () => {
    setInternalState({
      name,
      description,
      dataType,
      taskType,
      pii,
      ownerId: owner.id,
      ownerType: owner.__typename,
    });
    onClose();
  };

  return (
    <Popup
      title="Edit model information"
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      dataTest="model-editor-popup"
      buttons={{
        mainButtonProps: {
          disabled: !isEditable,
          type: 'button',
          dataTest: 'confirm',
          children: 'Save',
          onClick: onConfirm,
          isLoading: false,
        },
        secondaryButtonProps: {
          dataTest: 'confirm-cancel-button',
          children: 'Cancel',
          onClick: onCancel,
          isLoading: false,
        },
      }}
    >
      <Stack spacing={3} pt={1}>
        <Box width="100%">
          <TextInput
            disabled={!isEditable}
            maxWidth="100%"
            hint="Max. 250 characters"
            isRequired
            value={internalState.name}
            meta={{
              touched: true,
              error:
                internalState.name.length >= 250
                  ? 'maximum character limit reached'
                  : undefined,
            }}
            label="Model name"
            onChange={(value) =>
              handleUpdate({ ...internalState, name: value })
            }
            name="model-editor-name-input"
            data-test="model-editor-name-input"
            withoutError={internalState.name.length < 250}
          />
        </Box>
        <Box width="100%">
          <TextInput
            disabled={!isEditable}
            maxWidth="100%"
            rows={5}
            hint="Max. 250 characters"
            value={internalState.description}
            multiline
            meta={{
              touched: true,
              error:
                internalState.description.length >= 250
                  ? 'maximum character limit reached'
                  : undefined,
            }}
            label="Model description"
            onChange={(value) =>
              handleUpdate({ ...internalState, description: value })
            }
            name="description"
            data-test="description"
          />
        </Box>
        <Stack direction="row" spacing={2}>
          <Box width="50%">
            <DataTypeSelector
              disabled={!isEditable}
              value={internalState.dataType}
              onChange={(option) =>
                handleUpdate({
                  ...internalState,
                  dataType: option.value as DataType,
                })
              }
            />
          </Box>
          <Box width="50%">
            <TaskTypeSelector
              disabled={!isEditable}
              value={internalState.taskType}
              onChange={(option) =>
                handleUpdate({
                  ...internalState,
                  taskType: option.value as TaskType,
                })
              }
            />
          </Box>
        </Stack>

        <Stack spacing={1}>
          <Typography variant="subtitle2" color="text.secondary">
            Model ingests PII
          </Typography>
          <Stack direction="row">
            <Typography variant="body2" color="text.primary">
              Toggle on to mark that this model's versions ingest personal
              identifiable information (PII). Verta Endpoints running this model
              will not have their prediction input data stored in model audit
              logs.
            </Typography>
            <FormControlLabel
              labelPlacement="start"
              label="ENABLE"
              control={
                <Switch
                  checked={internalState.pii}
                  size="small"
                  onChange={togglePii}
                />
              }
            />
          </Stack>
          {piiUpdated && (
            <Alert severity="warning" title="Attention">
              All live endpoints associated with this model must be redeployed
              in order to get the new value of PII.
            </Alert>
          )}
        </Stack>
        <Box>
          <FormControl fullWidth>
            <Stack direction="row" alignItems="center" width="100%"></Stack>
          </FormControl>
        </Box>

        <OwnerSelector
          ownerId={internalState.ownerId}
          ownerType={internalState.ownerType}
          onChangeOwnerId={(ownerId) =>
            handleUpdate({
              ...internalState,
              ownerId,
            })
          }
          onChangeOwnerType={(ownerType) =>
            handleUpdate({
              ...internalState,
              ownerType,
            })
          }
          resource={props.registeredModel}
        />
      </Stack>
    </Popup>
  );
};

export default ModelEditor;
