import { fromPairs } from 'ramda';
import { TypedFieldDef } from 'vega-lite/build/src/channeldef';
import { StandardType } from 'vega-lite/build/src/type';

import { makeGetFieldName } from 'shared/utils/getFieldName';
import { IKeyValuePair } from 'shared/models/KeyValuePair';
import matchBy from 'shared/utils/matchBy';
import matchType from 'shared/utils/matchType';
import {
  ExperimentRunWithDisplayedFields,
  RequiredExperimentRun,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import { DataSpec } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/DataSpec';
import {
  KeyValueDataRealType,
  KeyValueType,
  keyValueTypeToPluralForm,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/KeyValueSpec';
import { OmitStrict } from 'shared/utils/types';

import { getDataSpecKey } from './dataSpecKey';

export type ExperimentRunWithKeyValueFieldsAsObjects = OmitStrict<
  RequiredExperimentRun,
  `${KeyValueType}s`
> &
  Record<`${KeyValueType}s`, Record<string, KeyValueDataRealType>>;

export const getExperimentRunWithKeyValueFieldsAsObjectsFunctions = () => {
  return {
    getExperimentRunsForSpec,
    getFieldName: makeGetFieldName<ExperimentRunWithKeyValueFieldsAsObjects>(),
    getDataSpecFields,
  };
};

export const getExperimentRunsForSpec = (
  experimentRuns: ExperimentRunWithDisplayedFields[]
): ExperimentRunWithKeyValueFieldsAsObjects[] => {
  const keyValuesToObject = (pairs: IKeyValuePair<KeyValueDataRealType>[]) =>
    fromPairs(pairs.map(({ key, value }) => [key, value]));
  return experimentRuns.map((run) => {
    return {
      ...run,
      attributes: keyValuesToObject(run.attributes),
      hyperparameters: keyValuesToObject(run.hyperparameters),
      metrics: keyValuesToObject(run.metrics),
    };
  });
};

export const makeGetDataSpecFields = <
  T,
>(): T extends ExperimentRunWithKeyValueFieldsAsObjects
  ? typeof getDataSpecFields
  : never => getDataSpecFields as ReturnType<typeof makeGetDataSpecFields>;

const getDataSpecFields = (dataSpec: DataSpec) => {
  const getFieldName =
    makeGetFieldName<ExperimentRunWithKeyValueFieldsAsObjects>();
  return matchBy(
    dataSpec,
    'type'
  )<
    OmitStrict<TypedFieldDef<string, StandardType>, 'field'> & { field: string }
  >({
    dateUpdated: (dateSpec) => ({
      field: getFieldName({ dateUpdated: null }),
      type: 'temporal',
      timeUnit: 'monthdatehoursminutes',
      title: getDataSpecKey(dateSpec),
    }),
    keyValue: (keyValue) => {
      return {
        title: getDataSpecKey(keyValue),
        field: getFieldName({
          [keyValueTypeToPluralForm(keyValue.subtype)]: {
            [keyValue.key]: null,
          },
        }),
        type: matchType(
          {
            number: () => 'quantitative',
            string: () => 'nominal',
          },
          keyValue.valueType
        ),
      };
    },
  });
};
