import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { selectFlags } from 'features/flags';
import { useMonitoringModelContext } from 'features/monitoring/models/model/store/state/context';
import { overviewDashboardId } from 'shared/models/Monitoring/MonitoringModel/defaultModelState/presetDashboards/presetOverviewDashboard';
import routes from 'shared/routes';

const MonitoringModelPageRedirect = () => {
  const { model } = useMonitoringModelContext();
  const { monitoring } = useSelector(selectFlags);

  return (
    <Navigate
      to={
        monitoring.isEnableNewMonitoringUX
          ? routes.monitoringOverview.getRedirectPath({
              workspaceName: model.workspaceName,
              monitoringModelId: model.id,
            })
          : routes.monitoringDashboard.getRedirectPath({
              dashboardId: overviewDashboardId,
              monitoringModelId: model.id,
              workspaceName: model.workspaceName,
            })
      }
    />
  );
};

export default MonitoringModelPageRedirect;
