import { useMemo } from 'react';
import { scaleOrdinal } from '@visx/scale';
import { uniq } from 'ramda';
import moment from 'moment';
import { Stack, Typography } from '@mui/material';

import { Size } from 'shared/utils/charts/chartSizes';
import { OnSetReset } from 'shared/utils/charts/zoom/useZoomReset';
import { chartsDataColors } from 'shared/view/charts/shared/colors';
import { formatDateNumber } from 'shared/utils/charts/formatDateNumber';
import { MonitoringAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import { getLinearDateScaleDomain } from 'shared/utils/charts/getLinearDateScaleDomain';
import { useActiveKeys } from 'shared/utils/activeKeys';
import { useChartLegendProps } from 'shared/utils/charts/chartLegendProps/useChartLegendProps';
import ChartWithLegendContainer from 'shared/view/charts/ChartWithLegendContainer/ChartWithLegendContainer';
import TimeSeriesChartAlertDecorations from 'features/monitoring/widgets/view/MonitoringWidgetView/TimeSeriesWidget/shared/TimeSeriesChart/TimeSeriesChartAlertDecorations/TimeSeriesChartAlertDecorations';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { formatWithDefaultPrecision } from 'shared/utils/formatters/formatWithDefaultPrecision';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';
import NewMultiLineChart, {
  MultiLineChartRenderAdditionalContentProps,
} from 'shared/view/charts/NewMultiLineChart/NewMultiLineChart';
import { MultiLineChartData } from 'shared/view/charts/MultiLineChart/utils/types';
import { MultiLineChartTooltipData } from 'shared/view/charts/MultiLineChart/utils/tooltip';

const makeRenderTooltipContent =
  (props: { alert: MonitoringAlert | undefined; metricName: string }) =>
  (d: MultiLineChartTooltipData<TimeSeriesChartData>) => {
    return (
      <div>
        <Typography variant="caption">
          {d.closestPoint.meta.modelVersion}
        </Typography>
        <Typography
          letterSpacing="1px"
          fontWeight="500"
          textTransform="uppercase"
          fontSize="12px"
        >
          {props.metricName}
        </Typography>
        <Typography variant="h6">
          {Number(
            formatWithDefaultPrecision(
              parseGraphQLNumber(d.closestPoint.meta.value)
            )
          )}
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack direction="row" alignItems="center">
            <IconAwesomeInfo icon={ICONS.calendar} size="1x" />
            <Typography variant="caption">
              {moment(d.closestPoint.meta.time).format('MM/DD/yyyy')}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <IconAwesomeInfo icon={ICONS.clock} size="1x" />
            <Typography variant="caption">
              {moment(d.closestPoint.meta.time).format('hh:mm A')}
            </Typography>
          </Stack>
        </Stack>
      </div>
    );
  };

export interface TimeSeriesChartData {
  key: string;
  time: Date;
  value: number;
  modelVersion: string;
}

const makeRenderAlertDecorations = (alert: MonitoringAlert | undefined) => {
  if (alert) {
    return (props: MultiLineChartRenderAdditionalContentProps) => {
      return (
        <TimeSeriesChartAlertDecorations
          alerter={alert.alerter}
          innerWidth={props.chartSizes.innerWidth}
          yScale={props.yScale}
        />
      );
    };
  }

  return undefined;
};

interface Props {
  id: string;
  size: Size;
  data: TimeSeriesChartData[];
  metricName: string;
  onSetReset: OnSetReset;
  alert: MonitoringAlert | undefined;
}

const NewTimeSeriesChart = (props: Props) => {
  const data = useMemo(
    () => props.data.map(convertData).filter((d) => isFinite(d.y)),
    [props.data]
  );

  const renderAlertDecorations = useMemo(
    () => makeRenderAlertDecorations(props.alert),
    [props.alert]
  );

  const renderTooltipContent = useMemo(
    () =>
      makeRenderTooltipContent({
        alert: props.alert,
        metricName: props.metricName,
      }),
    [props.alert, props.metricName]
  );

  const xDomain = useMemo(
    () => getLinearDateScaleDomain(data.map((d) => d.x)),
    [data]
  );

  const keys = useMemo(() => uniq(data.map((d) => d.key)), [data]);

  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: keys,
        range: chartsDataColors,
      }),
    [keys]
  );

  const { activeKeys, toggleKey } = useActiveKeys(keys);
  const legendProps = useChartLegendProps({
    activeKeys,
    toggleKey,
    legendPosition: 'right',
    colorScale,
  });

  const filteredData = useMemo(
    () => data.filter((d) => activeKeys.includes(d.key)),
    [data, activeKeys]
  );

  const renderChart = (size: Size) => (
    <NewMultiLineChart
      margin={{
        bottom: 60,
        left: 60,
        right: 32,
        top: 8,
      }}
      data={filteredData}
      id={props.id}
      width={size.width}
      height={size.height}
      colorScale={colorScale}
      onSetReset={props.onSetReset}
      xTickFormat={formatDateNumber}
      xDomain={xDomain}
      xNumTicks={3}
      renderTooltipContent={renderTooltipContent}
      renderAdditionalContent={renderAlertDecorations}
      renderXTickLabel={(value, x) => {
        return (
          <>
            <tspan x={x} textAnchor="middle">
              {moment(value).format('MM/DD/yyyy')}
            </tspan>
            <tspan x={x} dy="1.5em">
              {moment(value).format('hh:mm A')}
            </tspan>
          </>
        );
      }}
    />
  );

  if (keys.length > 1) {
    return (
      <ChartWithLegendContainer
        legendProps={legendProps}
        widgetSize={props.size}
      >
        {renderChart}
      </ChartWithLegendContainer>
    );
  }

  return renderChart(props.size);
};

const convertData = (
  data: TimeSeriesChartData
): MultiLineChartData<TimeSeriesChartData> => {
  return {
    key: data.key,
    meta: data,
    y: data.value,
    x: data.time.getTime(),
  };
};

export default NewTimeSeriesChart;
