import isNotNil from './isNotNill';

export const formatBytes = (bytes: string | number, decimals?: number) => {
  if (bytes === '' || bytes === 0) return '0 Bytes';
  const k = 1024;
  let dm;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(Number(bytes)) / Math.log(k));

  if (isNotNil(decimals)) {
    dm = decimals <= 0 ? 0 : decimals || 2;
  } else {
    dm = 2;
  }
  return `${parseFloat((Number(bytes) / Math.pow(k, i)).toFixed(dm))} ${
    sizes[i]
  }`;
};
