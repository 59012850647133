export type Bounds<XInput = never, YInput = never> = {
  x0: number;
  x1: number;
  xValues?: XInput[];
  y0: number;
  y1: number;
  yValues?: YInput[];
};

type OrdinalScale<Input = any> = {
  domain(): Input[];
  range(): number[];
  step: () => number;
};
type LinearScale<Input = any, Output = any> = {
  domain(): Input[];
  range(): number[];
  invert: (output: Output) => number;
};
export type Scale<Input = any, Output = any> =
  | OrdinalScale<Input>
  | LinearScale<Input, Output>;

export const isOrdinalScale = (
  x: Scale<unknown, unknown>
): x is OrdinalScale<unknown> => {
  const res: OrdinalScale<unknown> | undefined = !('invert' in x)
    ? x
    : undefined;
  return Boolean(res);
};

export const isLinearScale = (
  x: Scale<unknown, unknown>
): x is LinearScale<unknown, unknown> => {
  const res: LinearScale<unknown, unknown> | undefined =
    'invert' in x ? x : undefined;
  return Boolean(res);
};
