import React from 'react';
import { keys } from 'lodash';

import { canUserUpdateRegisteredModelVersion } from 'shared/models/Registry/RegisteredModelVersion/Access';
import TagsManager from 'shared/view/domain/Labels/TagsManager/TagsManager';
import { trackEvent } from 'setup/app/analytics';
import { RegisteredModelVersion as Version } from 'features/catalog/registeredModelVersion/summary/store/useModelVersionSummary';
import {
  useAddLabelMutation,
  useDeleteLabelMutation,
} from 'features/catalog/registeredModelVersion/summary/view/LabelsManager/store/modelVersionLabelsManager/modelVersionLabelsManager';
import { Evaluation } from 'features/evaluations/evaluation/summary/hooks/useEvaluationSummary';
import { useUpdateEvaluationAttributes } from 'features/evaluations/evaluation/summary/hooks/useUpdateEvaluationAttributes';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import {
  EvaluationAttributeKey,
  EvaluationHeaderKey,
  buildDefaultFromLabels,
} from 'features/evaluations/shared/utils/evaluation';
import { combineCommunications } from 'shared/utils/redux/communication/combineCommunications';
import { ChipProps } from 'shared/view/elements/Chip/Chip';

type Props = Pick<Version, 'id' | 'labels'> &
  Pick<Version['registeredModel'], 'allowedActions'> &
  Pick<Evaluation, 'rows'> & {
    mode?: 'evaluate' | 'infostack';
    onTagClicked?(e: React.MouseEvent<Element, MouseEvent>, tag: string): void;
    getTagProps?(tag: string): ChipProps;
  };

const LabelsManager: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  labels,
  allowedActions,
  rows,
  mode = 'infostack',
  onTagClicked,
  getTagProps,
}) => {
  const [addLabel, addingLabel] = useAddLabelMutation();
  const [deleteLabel, deletingLabel] = useDeleteLabelMutation();
  const { updateEvaluationAttributes, updatingEvaluationAttributes } =
    useUpdateEvaluationAttributes();

  const handleLabelsModified = (_id: Props['id'], _labels: Props['labels']) => {
    const defaultLabels = buildDefaultFromLabels(_labels);
    const newAttributes = rows.map((row) => {
      const newLabels = { ...defaultLabels };
      keys(defaultLabels).forEach((label) => {
        newLabels[label] = Boolean(row[EvaluationHeaderKey.LABELS][label]);
      });

      return {
        ...row,
        [EvaluationHeaderKey.LABELS]: newLabels,
      };
    });

    const attributes = newAttributes.map((attr) => ({
      key: `${EvaluationAttributeKey.ROW}_${attr[EvaluationHeaderKey.ID]}`,
      value: JSON.stringify(attr),
    }));

    updateEvaluationAttributes({ id, attributes });
  };

  const communication = combineCommunications([
    addingLabel,
    deletingLabel,
    updatingEvaluationAttributes,
  ]);

  return (
    <TagsManager
      tags={labels}
      getTagProps={getTagProps}
      tagProps={{ color: 'primary' }}
      isEditable={canUserUpdateRegisteredModelVersion(allowedActions)}
      isUpdating={communication.isRequesting}
      renderPlaceholder={mode === 'infostack'}
      onTagClicked={onTagClicked}
      onAddTag={(label) => {
        trackEvent({ type: 'catalog.registered_model_version.label_added' });
        return addLabel(
          { id, label },
          {
            onCompleted: ({ registeredModelVersion }) => {
              if (isNotError(registeredModelVersion)) {
                handleLabelsModified(
                  id,
                  registeredModelVersion.addLabels.labels
                );
              }
            },
          }
        );
      }}
      onRemoveTag={(label) => {
        trackEvent({ type: 'catalog.registered_model_version.label_deleted' });
        return deleteLabel(
          { id, label },
          {
            onCompleted: ({ registeredModelVersion }) => {
              if (isNotError(registeredModelVersion)) {
                handleLabelsModified(
                  id,
                  registeredModelVersion.deleteLabels.labels
                );
              }
            },
          }
        );
      }}
    />
  );
};

export default LabelsManager;
