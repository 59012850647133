import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getCrossRunDashboardsOrDefault } from 'shared/models/CrossRunDashboard/AttributeWithCrossRunDashboards';
import * as CrossRunDashboard from 'shared/models/CrossRunDashboard/CrossRunDashboard';
import * as NonEmptyArray from 'shared/utils/opaqueTypes/NonEmptyArray';
import { CrossRunDashboardsRequiredData } from 'features/experimentRuns/crossRunDashboard/store/crossRunDashboardManager/CrossRunDashboardsRequiredData';

export const useCrossRunDashboards = (
  props: CrossRunDashboardsRequiredData
) => {
  const crossRunDashboards = getCrossRunDashboardsOrDefault({
    ...props.project,
    experimentRuns: props.experimentRuns,
  });

  const [selectedCrossRunDashboard, changeSelectedCrossRunDashboard] =
    useSelectedDashboard({ ...props, crossRunDashboards });

  const initialSelectedCrossRunDashboard =
    selectedCrossRunDashboard &&
    crossRunDashboards.find((x) => x.id === selectedCrossRunDashboard.id);

  return {
    selectedCrossRunDashboard,
    changeSelectedCrossRunDashboard,
    initialSelectedCrossRunDashboard,
    crossRunDashboards,
    project: props.project,
    experimentRuns: props.experimentRuns,
  };
};

const useSelectedDashboard = (
  props: CrossRunDashboardsRequiredData & {
    crossRunDashboards: NonEmptyArray.NonEmptyArray<CrossRunDashboard.CrossRunDashboard>;
  }
) => {
  const [selectedCrossRunDashboard, __changeSelectedCrossRunDashboard] =
    React.useState<CrossRunDashboard.CrossRunDashboard | undefined>(
      props.dashboardFromURL
        ? props.crossRunDashboards.find(
            (x) => x.name === props.dashboardFromURL
          )
        : NonEmptyArray.head(props.crossRunDashboards)
    );
  const navigate = useNavigate();
  const location = useLocation();
  const changeSelectedCrossRunDashboard = (
    dashboard: CrossRunDashboard.CrossRunDashboard
  ) => {
    __changeSelectedCrossRunDashboard(dashboard);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('dashboard', dashboard.name);
    navigate({
      ...location,
      search: String(searchParams),
    });
  };

  return [selectedCrossRunDashboard, changeSelectedCrossRunDashboard] as const;
};
