// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { Line } from '@visx/shape';
import { ScaleLinear } from 'd3-scale';
import { useMemo } from 'react';
import { head } from 'ramda';
import Stack from '@mui/material/Stack';

import { ChartSizes } from 'shared/utils/charts/chartSizes';
import { findClosestPoint } from 'shared/view/charts/MultiLineChart/utils/point';
import CustomTooltipWithBounds from 'shared/view/charts/shared/CustomTooltipWithBound/CustomTooltipWithBound';
import DefaultTooltipContent from 'shared/view/charts/shared/DefaultTooltipContent/DefaultTooltipContent';
import { withScientificNotationOrRounded } from 'shared/utils/formatters/number';
import { multiLineChartTooltipXOffset } from 'shared/view/charts/MultiLineChart/utils/tooltip';
import { useChartSizeScales } from 'shared/utils/charts/sizeScale/useChartSizeScales';
import capitalize from 'shared/utils/capitalize';

import {
  groupOutlierChartData,
  isOutlierDetectionChartDataPoint,
  isOutlierDetectionChartPoint,
  OutlierDetectionChartData,
  OutlierDetectionChartPoint,
  OutlierDetectionChartDataPoint,
  MedianData,
  MeanData,
} from './types';
import { outlierDetectionChartColorScale } from './colorScale';

type OutlierDetectionTooltipData = {
  closestPoint: OutlierDetectionChartDataPoint;
  points: Partial<{
    median: MedianData;
    mean: MeanData;
  }>;
};

export function useOutlierDetectionChartTooltipWithView(props: {
  data: OutlierDetectionChartData[];
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  chartSizes: ChartSizes;
}) {
  const points = useMemo(
    () => props.data.filter(isOutlierDetectionChartPoint),
    [props.data]
  );
  const { handleTooltip, hideTooltip, tooltipData, tooltipLeft } =
    useOutlierDetectionChartTooltip({
      chartSizes: props.chartSizes,
      xScale: props.xScale,
      yScale: props.yScale,
      points,
    });

  const tooltipView = tooltipData && (
    <CustomTooltipWithBounds
      top={20}
      left={Number(tooltipLeft) + multiLineChartTooltipXOffset}
    >
      <Stack>
        <DefaultTooltipContent
          label={capitalize(tooltipData.closestPoint.type)}
          labelColor={outlierDetectionChartColorScale(
            tooltipData.closestPoint.type
          )}
        >
          {withScientificNotationOrRounded(tooltipData.closestPoint.y)}
        </DefaultTooltipContent>
        <DefaultTooltipContent label="Model version">
          {tooltipData.closestPoint.modelVersion}
        </DefaultTooltipContent>
        <DefaultTooltipContent label="Std dev">
          {withScientificNotationOrRounded(tooltipData.closestPoint.sigma)}
        </DefaultTooltipContent>
        {tooltipData.points.mean ? (
          <DefaultTooltipContent label="Mean">
            {withScientificNotationOrRounded(tooltipData.points.mean.y)}
          </DefaultTooltipContent>
        ) : null}

        {tooltipData.points.median ? (
          <DefaultTooltipContent label="Median">
            {withScientificNotationOrRounded(tooltipData.points.median.y)}
          </DefaultTooltipContent>
        ) : null}
      </Stack>
    </CustomTooltipWithBounds>
  );

  const tooltipIndicator = tooltipData && (
    <g>
      <Line
        from={{ x: tooltipLeft, y: 0 }}
        to={{ x: tooltipLeft, y: props.chartSizes.innerHeight }}
        stroke={outlierDetectionChartColorScale(tooltipData.closestPoint.type)}
        strokeWidth={1.5}
        pointerEvents="none"
        strokeDasharray="1.5 1.5"
      />

      <circle
        cx={props.xScale(tooltipData.closestPoint.x)}
        cy={props.yScale(tooltipData.closestPoint.y)}
        r={4}
        fill={outlierDetectionChartColorScale(tooltipData.closestPoint.type)}
        stroke={outlierDetectionChartColorScale(tooltipData.closestPoint.type)}
        strokeWidth={4}
        pointerEvents="none"
      />
    </g>
  );

  return {
    tooltipView,
    tooltipIndicator,
    handleTooltip,
    hideTooltip,
    tooltipData,
  };
}

function useOutlierDetectionChartTooltip({
  points,
  xScale,
  yScale,
  chartSizes,
}: {
  points: OutlierDetectionChartPoint[];
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  chartSizes: ChartSizes;
}) {
  const tooltip = useTooltip<OutlierDetectionTooltipData>();

  const { scaleChartX, scaleChartY } = useChartSizeScales({ chartSizes });

  const dataPoints = useMemo(
    () => points.filter(isOutlierDetectionChartDataPoint),
    [points]
  );

  const handleTooltip = (event: React.MouseEvent<SVGGElement>) => {
    if (dataPoints.length === 0) {
      return;
    }

    const { x, y } = localPoint(event) || { x: 0, y: 0 };
    const scaledX = scaleChartX(x);
    const scaledY = scaleChartY(y);

    const invertedX = xScale.invert(scaledX);
    const invertedY = yScale.invert(scaledY);

    const closestPoint = findClosestPoint(
      { x: invertedX, y: invertedY },
      dataPoints
    );

    const activePoints = points
      .filter((p) => p.x === closestPoint.x)
      .sort((a, b) => b.y - a.y);

    const groupedPoints = groupOutlierChartData(activePoints);

    tooltip.showTooltip({
      tooltipData: {
        points: {
          mean: groupedPoints.mean ? head(groupedPoints.mean) : undefined,
          median: groupedPoints.median ? head(groupedPoints.median) : undefined,
        },
        closestPoint,
      },
      tooltipLeft: xScale(closestPoint.x),
    });
  };

  return { ...tooltip, handleTooltip };
}
