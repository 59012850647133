import { ICommunication } from 'shared/utils/redux/communication/types';
import { AppError } from 'shared/models/Error';

/**
 * todo Bug was here, fixed (look history to ensure). With current ICommunication approach you can met situation,
 *  when isRequesting and isSuccess is true at the same time;
 *  It will be better to replace ICommunication with that type (this approach helps to avoid situation above
 *  and inspired by https://redux.js.org/tutorials/fundamentals/part-7-standard-patterns#loading-state-enum-values):
 *  export type ICommunication<E = AppError> =
    | { type: 'idle' }
    | { type: 'loading' }
    | { type: 'succeeded' }
    | { type: 'failed'; error: E | undefined };
 */
export const combineCommunications = <E = AppError>(
  communications: ICommunication<E>[]
): ICommunication<E> => {
  const initial = {
    isRequesting: false,
    isSuccess: false,
    error: undefined,
    isPolling: false,
  };
  if (communications.length === 0) return initial;

  const withError = communications.find((value) => value.error);
  if (withError) return withError;

  const withRequesting = communications.find((value) => value.isRequesting);
  if (withRequesting) return withRequesting;

  const withSuccess = communications.every((value) => value.isSuccess)
    ? communications[0]
    : undefined;
  if (withSuccess) return withSuccess;

  return initial;
};
