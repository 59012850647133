/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { ACTIVITY_FRAGMENT } from 'shared/graphql/registry/stage/Activity';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';

export const EVALUATION_SUMMARY = gql`
  query EvaluationSummary($evaluationId: ID!) {
    registeredModelVersion(id: $evaluationId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        version
        dateCreated
        dateUpdated
        labels
        description
        attributes {
          ...AttributeData
        }
        owner {
          ...OwnerData
        }
        registeredModel {
          id
          name
          allowedActions {
            ...AllowedActionsData
            lock
            unlock
            updateRedact
          }
          workspace {
            id
            ...WorkspaceData
          }
        }
        activities {
          id
          ...ActivityFragment
          __typename
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
`;

export const MODEL_VERSION_ACTIVITIES_DATA_QUERY = gql`
  query ModelVersionActivitiesData($id: ID!) {
    registeredModelVersion(id: $id) {
      ... on Error {
        ...ErrorData
        __typename
      }
      ... on RegisteredModelVersion {
        id
        activities {
          id
          ...ActivityFragment
          __typename
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
`;

export const UPDATE_EVALUATION_ROW = gql`
  mutation UpdateEvaluationRow($id: ID!, $attribute: StringKeyValueInput!) {
    registeredModelVersion(id: $id) {
      ... on Error {
        ...ErrorData
        __typename
      }
      ... on RegisteredModelVersion {
        id

        updateAttribute(keyValue: $attribute) {
          id
          attributes {
            ...AttributeData
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
`;

export const UPDATE_EVALUATION_ATTRIBUTES = gql`
  mutation UpdateEvaluationAttributes(
    $id: ID!
    $attributes: [StringKeyValueInput!]!
  ) {
    registeredModelVersion(id: $id) {
      ... on Error {
        ...ErrorData
        __typename
      }
      ... on RegisteredModelVersion {
        id

        updateAttributes(keyValues: $attributes) {
          id
          attributes {
            ...AttributeData
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
`;

export const EDIT_EVALUATION_BASIC_INFO = gql`
  mutation EditEvaluationBasicInfo(
    $evaluationId: ID!
    $input: RegisteredModelVersionInput
    $attributes: [StringKeyValueInput!]!
  ) {
    registeredModelVersion(id: $evaluationId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        updateRegisteredModelVersion(fields: $input) {
          id
          version
          description
          updateAttributes(keyValues: $attributes) {
            id
            attributes {
              ...AttributeData
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
`;
