/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { WorkspaceName } from 'shared/models/Workspace';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';

import * as RegisteredModelsTypes from './graphql-types/useRegisteredModelsForBuild.generated';

const REGISTERED_MODELS_FOR_BUILD = gql`
  query RegisteredModelsForBuild($workspaceName: String!, $organizationId: ID) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        registeredModels {
          models {
            id
            name
            allowedActions {
              deploy
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

const useRegisteredModels = ({
  workspaceName,
  organizationId,
}: {
  workspaceName: WorkspaceName;
  organizationId: string | undefined;
}) => {
  const query = useCustomQuery<
    RegisteredModelsTypes.RegisteredModelsForBuild,
    RegisteredModelsTypes.RegisteredModelsForBuildVariables
  >(REGISTERED_MODELS_FOR_BUILD, {
    variables: {
      workspaceName,
      organizationId,
    },
  });

  const { data: registeredModels, communication: loadingRegisteredModels } =
    resultToCommunicationWithData(
      (res) => mapDataOrError(res.workspace, (x) => x.registeredModels.models),
      query
    );

  // TODO: filter models
  // const filteredRegisteredModels = registeredModels
  //   ? registeredModels.filter(m => m.allowedActions.deploy)
  //   : undefined;

  return {
    registeredModels,
    loadingRegisteredModels,
  };
};

export default useRegisteredModels;
