import { head, sortBy } from 'ramda';

import { MonitoringDataType, MonitoringIOType } from 'generated/types';
import matchType from 'shared/utils/matchType';

import { getAvailableIODescriptionsForDrift } from '../Distribution/Drift';

export interface MonitoringIODescription {
  dataType: MonitoringDataType;
  ioType: MonitoringIOType;
  name: string;
}

export const getDefaultIODescriptions = (
  ioDescriptions: MonitoringIODescription[]
) => {
  const sortedIoDescriptions = sortBy((a) => a.name, ioDescriptions);
  const defaultIODescription = head(
    getAvailableIODescriptionsForDrift(sortedIoDescriptions)
  );
  const defaultInput = head(
    getAvailableIODescriptionsForDrift(sortedIoDescriptions).filter(
      (d) => d.ioType === MonitoringIOType.INPUT
    )
  );
  const defaultOutput = head(
    sortedIoDescriptions.filter((d) => d.ioType === MonitoringIOType.OUTPUT)
  );

  return { defaultInput, defaultOutput, defaultIODescription };
};

export const getDefaultIODescriptionsOrThrowError = (
  ioDescriptions: MonitoringIODescription[]
) => {
  const { defaultInput, defaultOutput } =
    getDefaultIODescriptions(ioDescriptions);

  if (defaultInput && defaultOutput) {
    return { defaultInput, defaultOutput };
  }

  throw new Error('I/O descriptions not found');
};

export const getLabelByIOType = (ioType: MonitoringIOType) =>
  matchType(
    {
      [MonitoringIOType.INPUT]: () => 'input',
      [MonitoringIOType.OUTPUT]: () => 'output',
    },
    ioType
  );
