import { EndpointEnvironmentStatus } from 'generated/types';

import { compareKeyedItemsProperties } from './keyedItemsPropertiesDiff';

export type RequiredEndpoint = {
  id: string;
  status: EndpointEnvironmentStatus;
  path: string;
  workspaceName: string;
};

export const compareEndpoint = (
  entities: Array<{
    id: string;
    endpoints: Array<RequiredEndpoint>;
  }>
) => {
  return compareKeyedItemsProperties(
    ({ path }) => path,
    ['status', 'path'],
    entities.map(({ endpoints }) => endpoints)
  );
};
