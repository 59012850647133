import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import { styled } from '@mui/material';

import CheckboxField from 'shared/view/formComponents/formikFields/CheckboxField/CheckboxField';
import { selectFlags } from 'features/flags';
import RecordsSection, {
  RecordInfo,
} from 'shared/view/elements/RecordsSection/RecordsSection';

import MemoryField from './MemoryField/MemoryField';
import CpuField from './CpuField/CpuField';
import { getMachineConfigSettingsFieldName } from '../settings/getMachineConfigSettingsFieldName';
import GpuTypeField from './GpuField/GpuTypeField';
import GpuQuantityField from './GpuField/GpuQuantityField';

const ResourcesSection: FC<React.PropsWithChildren<unknown>> = () => {
  const {
    deployment: { isEnableMachineConfigGpu },
  } = useSelector(selectFlags);
  const [{ value: resourcesEnabled }] = useField<boolean>(
    getMachineConfigSettingsFieldName({ resourcesEnabled: null })
  );
  const isFormDisabled = !resourcesEnabled;
  const [{ value: isGpuEnabled }] = useField<boolean>(
    getMachineConfigSettingsFieldName({ resources: { isGpuEnabled: null } })
  );
  return (
    <>
      <CheckboxField
        name={getMachineConfigSettingsFieldName({
          resourcesEnabled: null,
        })}
        dataTest="resources-enable"
        label="Enable"
        withoutError
      />
      {isEnableMachineConfigGpu && (
        <CheckboxField
          name={getMachineConfigSettingsFieldName({
            resources: { isGpuEnabled: null },
          })}
          disabled={isFormDisabled}
          label="Use GPU"
        />
      )}
      <div style={{ width: '650px' }}>
        <RecordsSection>
          {isEnableMachineConfigGpu ? (
            <RecordInfo label="GPU" labelAlign="withInput">
              <RecordInfoContainer>
                <GpuQuantityField
                  disabled={isFormDisabled || isGpuEnabled === false}
                />
              </RecordInfoContainer>
            </RecordInfo>
          ) : null}
          {isEnableMachineConfigGpu ? (
            <RecordInfo label="GPU Hardware" labelAlign="withInput">
              <GpuTypeField />
            </RecordInfo>
          ) : null}
          <RecordInfo label="CPU" labelAlign="withInput">
            <RecordInfoContainer>
              <CpuField disabled={isFormDisabled} />
            </RecordInfoContainer>
          </RecordInfo>
          <RecordInfo label="Memory" labelAlign="withInput">
            <RecordInfoContainer>
              <MemoryField disabled={isFormDisabled} />
            </RecordInfoContainer>
          </RecordInfo>
        </RecordsSection>
      </div>
    </>
  );
};

const RecordInfoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '40px',
});

export default ResourcesSection;
