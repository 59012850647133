import { useSelector } from 'react-redux';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useModelVersionRelease } from 'features/catalog/registeredModelVersion/govern/hooks/useModelVersionRelease';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import { selectCurrentWorkspace } from 'features/workspaces';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

const ModelVersionGovern = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ModelVersionGovern' */ 'features/catalog/registeredModelVersion/govern/view/govern'
    )
);

const GovernPage = () => {
  const { versionId } = useRouteParams(routes.modelVersionGovern);
  const workspace = useSelector(selectCurrentWorkspace);

  const { version, loadingVersion } = useModelVersionRelease({
    versionId,
    workspaceId: workspace.id,
    organizationId:
      workspace.type === 'organization' ? workspace.organizationId : '',
  });

  return (
    <DefaultMatchRemoteDataOrError
      communication={loadingVersion}
      data={version}
      context="loading model version"
    >
      {(loadedModelVersion) => (
        <ModelVersionGovern
          version={loadedModelVersion}
          checklistTemplates={
            isNotError(loadedModelVersion.checklistTemplates)
              ? loadedModelVersion.checklistTemplates
              : []
          }
          customAttributeDefinitions={
            isNotError(loadedModelVersion.customAttributeDefinitions)
              ? loadedModelVersion.customAttributeDefinitions
              : []
          }
          groups={
            isNotError(loadedModelVersion.groups)
              ? loadedModelVersion.groups
              : []
          }
        />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default GovernPage;
