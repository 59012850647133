import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { convertTimeRangeToDateRange } from 'shared/utils/TimeRange';
import { MonitoringDriftMetricType } from 'generated/types';
import { toGraphQLDate } from 'shared/utils/graphql/toGraphQLDate';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { convertMonitoringFilterToGraphQL } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';
import { MONITORING_MODEL_REGISTERED_MODEL_VERSION_FRAGMENT } from 'shared/graphql/monitoring/MonitoringModel/MonitoringModelRegisteredModelVersion';

import {
  DriftOverTimeQuery,
  DriftOverTimeQueryVariables,
} from './graphql-types/useDriftOverTime.generated';
import { convertIODescriptionToQuery } from '../shared/convertIODescriptionToQuery';

const DRIFT_OVER_TIME_QUERY = gql`
  query DriftOverTimeQuery(
    $monitoredEntityId: ID!
    $driftQuery: MonitoringDriftQuery!
    $aggregationMilliseconds: Int!
  ) {
    monitoredEntity(id: $monitoredEntityId) {
      ... on Error {
        ...ErrorData
      }
      ... on MonitoredEntity {
        id
        metrics {
          driftOverTime(
            query: {
              base: $driftQuery
              aggregationMilliseconds: $aggregationMilliseconds
            }
          ) {
            ioType
            name
            time
            values
            modelVersionId
            modelVersion {
              ... on Error {
                ...ErrorData
              }
              ... on RegisteredModelVersion {
                id
                ...MonitoringModelRegisteredModelVersionFragment
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${MONITORING_MODEL_REGISTERED_MODEL_VERSION_FRAGMENT}
`;

interface Props {
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  driftMetricType: MonitoringDriftMetricType;
  ioDescription: MonitoringIODescription;
}

export const useDriftOverTimeQuery = (props: Props) => {
  const variables = useMemo((): DriftOverTimeQueryVariables => {
    const dateRange = convertTimeRangeToDateRange(
      props.widgetExternalDeps.timeRange
    );
    return {
      monitoredEntityId: props.widgetExternalDeps.monitoredEntityId,
      driftQuery: {
        base: {
          startDate: toGraphQLDate(dateRange.from),
          endDate: toGraphQLDate(dateRange.to),
          ioDescriptions: [convertIODescriptionToQuery(props.ioDescription)],
          filters: props.widgetExternalDeps.filters.map(
            convertMonitoringFilterToGraphQL
          ),
        },
        types: [props.driftMetricType],
      },
      aggregationMilliseconds: props.widgetExternalDeps.aggregation.timeWindow,
    };
  }, [
    props.widgetExternalDeps.timeRange,
    props.widgetExternalDeps.monitoredEntityId,
    props.widgetExternalDeps.aggregation.timeWindow,
    props.widgetExternalDeps.filters,
    props.ioDescription,
    props.driftMetricType,
  ]);

  const query = useCustomQuery<DriftOverTimeQuery, DriftOverTimeQueryVariables>(
    DRIFT_OVER_TIME_QUERY,
    {
      variables,
    }
  );

  const memoizedConvert = useCallback(
    (res: DriftOverTimeQuery) => {
      return mapDataOrError(res.monitoredEntity, (monitoredEntity) => {
        return monitoredEntity.metrics.driftOverTime.filter((d) =>
          props.widgetExternalDeps.registeredModelVersionIds.includes(
            d.modelVersionId
          )
        );
      });
    },
    [props.widgetExternalDeps.registeredModelVersionIds]
  );

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert,
    queryResult: query,
  });
};
