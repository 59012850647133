import {
  monitoringDriftMetricTypes,
  getLabelByMonitoringDriftMetricType,
} from 'shared/models/Monitoring/MonitoringDriftMetricType';
import { validateNotEmpty } from 'shared/utils/validators';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';

const driftMetricOptions = monitoringDriftMetricTypes.map((metric) => ({
  label: getLabelByMonitoringDriftMetricType(metric),
  value: metric,
}));

const MonitoringDriftMetricTypeField = (props: { name: string }) => {
  return (
    <SelectField
      label={'Distance detection'}
      name={props.name}
      options={driftMetricOptions}
      validate={validateNotEmpty('distance detection')}
    />
  );
};

export default MonitoringDriftMetricTypeField;
