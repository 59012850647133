import { Box, Stack, Typography } from '@mui/material';

import image from '../images/release-and-compare-results.png';
import { Circle } from '../Circle';

export const ReleaseAndCompareResults = () => (
  <Stack direction="row" spacing={0} alignItems="center">
    <Box width="65%" mr={4}>
      <img
        src={image}
        width="100%"
        height="auto"
        alt="leaderboard comparing prompts results"
      />
    </Box>
    <Stack width="35%" maxWidth="306px" display="flex" alignItems="flex-start">
      <Circle>3</Circle>
      <Typography variant="h6" color="text.primary">
        Release & compare results
      </Typography>
      <Box>
        <Typography
          mb={2}
          fontFamily="DM Sans"
          variant="body2"
          color="text.primary"
        >
          Models and prompts compete for use on the Leaderboard, ranking your
          hero and challengers. Monitor human evaluations, compare metrics,
          change LLMs and approve releases—all from one location.
        </Typography>
      </Box>
    </Stack>
  </Stack>
);
