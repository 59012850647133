// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MUIButton from '@mui/material/Button';

import { ButtonContent } from 'shared/view/elements/Button/shared/ButtonContent';
import {
  useButtonCommonProps,
  ButtonCommonProps,
} from 'shared/view/elements/Button/shared/useButtonCommonProps';
import { OmitStrict } from 'shared/utils/types';
import { trackEvent } from 'setup/app/analytics';

type ResetButtonProps = OmitStrict<ButtonCommonProps, 'children'>;

export default function ResetButton(props: ResetButtonProps) {
  const { buttonProps, contentProps } = useButtonCommonProps({
    ...props,
    children: 'Cancel',
  });
  return (
    <span>
      <MUIButton
        {...buttonProps}
        type={'reset'}
        onClick={(event) => {
          props.trackEvent && trackEvent(props.trackEvent);
          props.onClick?.(event);
        }}
      >
        <ButtonContent {...contentProps} isLoading={false} />
      </MUIButton>
    </span>
  );
}
