import ConfirmAction, {
  ConfirmActionBasicProps,
} from 'shared/view/elements/ConfirmAction/ConfirmAction';

import getConfirmationProps from './getConfirmationProps';

export enum ConfirmType {
  delete = 'Delete',
  update = 'Update',
  save = 'Save',
  leave = 'Leave',
  disable = 'Disable',
  enable = 'Enable',
  mark = 'Mark',
}

export interface DefaultConfirmActionProps {
  // if you arrive here from outside trying to understand what this 'description'
  // means, then it should be definitely renamed into entityDescription or similar.
  description: string;
  type: ConfirmType;
  customBasicText?: string;
  additionalText?: string;
  entityName?: string;
  entitiesQuantity?: number;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

const DefaultConfirmAction: React.FC<
  DefaultConfirmActionProps & ConfirmActionBasicProps
> = (props) => {
  const defaults = getConfirmationProps({ ...props });
  return (
    <ConfirmAction {...props} {...defaults}>
      {props.children}
    </ConfirmAction>
  );
};
export default DefaultConfirmAction;
