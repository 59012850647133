import matchBy from 'shared/utils/matchBy';

import { TimeSeriesChartAlertDecorationsProps } from './shared/TimeSeriesChartAlertDecorationsProps';
import TimeSeriesChartViolationFixed from './TimeSeriesChartViolationFixed';
import TimeSeriesChartViolationRange from './TimeSeriesChartViolationRange';

const TimeSeriesChartAlertDecorations = (
  props: TimeSeriesChartAlertDecorationsProps
) => {
  return matchBy(
    props.alerter,
    'type'
  )({
    fixed: (alerter) => (
      <TimeSeriesChartViolationFixed {...props} alerter={alerter} />
    ),
    range: (alerter) => (
      <TimeSeriesChartViolationRange {...props} alerter={alerter} />
    ),
  });
};

export default TimeSeriesChartAlertDecorations;
