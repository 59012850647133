/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { useStore } from 'react-redux';
import { useApolloClient } from '@apollo/client';

import { IApplicationState, ThunkArgs } from 'setup/store/store';
import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';
import makeObserver from 'shared/utils/observer';
import { AuthenticationService } from 'services/auth';
import { OmitStrict } from 'shared/utils/types';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as GraphqlTypes from './graphql-types/useCreateOrganization.generated';

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization(
    $name: String!
    $description: String!
    $namespace: String
    $baselinePermissions: OrganizationBaselinePermissionsInput!
  ) {
    createOrganization(
      name: $name
      description: $description
      namespace: $namespace
      baselinePermissions: $baselinePermissions
    ) {
      id
      name
      namespace
      workspace {
        id
        name
        namespace
      }
      owner {
        id
      }
    }
  }
`;

export const createdOrganizationObserver = makeObserver<{
  createdOrganization: {
    id: string;
    workspace: { id: string; name: string; namespace: string | null };
    updatedCurrentUser: CurrentUserData;
  };
  thunkArgs: OmitStrict<ThunkArgs, 'deps'>;
}>();

export const useCreateOrganization = (
  onCompleted: (org: { id: string; name: string }) => void
) => {
  const store = useStore<IApplicationState>();
  const apolloClient = useApolloClient();

  const [createOrganization, creatingOrganization] = useCustomMutation<
    GraphqlTypes.CreateOrganization,
    GraphqlTypes.CreateOrganizationVariables
  >(
    CREATE_ORGANIZATION,
    { context: 'creating organization' },
    {
      onCompleted: async (response) => {
        const maybeUpdatedCurrentUser =
          await AuthenticationService.loadCurrentUser(apolloClient);
        onCompleted(response.createOrganization);
        createdOrganizationObserver.notify({
          createdOrganization: {
            id: response.createOrganization.id,
            workspace: response.createOrganization.workspace,
            updatedCurrentUser: maybeUpdatedCurrentUser.self,
          },
          thunkArgs: store,
        });
      },
    }
  );

  return {
    createOrganization,
    creatingOrganization,
  };
};
