import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import { FC, ReactNode, useMemo } from 'react';
import { equals } from 'ramda';

import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import IconButton from 'shared/view/elements/IconButton/IconButton';
import { FilterDefinition } from 'shared/models/Filters/FilterDefinition';
import { usePopoverManager } from 'shared/view/hooks/usePopoverManager';
import {
  Filter,
  getOperatorLabel,
  getOperators,
  makeFormattedFilterString,
} from 'shared/models/Filters/Filter';
import { useFiltersForm } from 'features/filters/store/useFiltersForm';
import Select from 'shared/view/elements/Selects/Select/Select';
import { getSelectedOption } from 'shared/view/elements/Selects/Select/helpers';
import FiltersList from 'shared/view/domain/Filters/FiltersList/FiltersList';
import BaseFilterItem from 'shared/view/domain/Filters/BaseFilterItem/BaseFilterItem';
import FiltersPopoverContent from 'shared/view/domain/Filters/FiltersPopupContent/FiltersPopoverContent';

import { ValueInput } from './ValueInput';

interface Props {
  filterDefinitions: FilterDefinition[];
  filters: Filter[];
  applyFilters: (filters: Filter[]) => void;
}

const Filters: FC<React.PropsWithChildren<Props>> = (props) => {
  const { anchorEl, closePopover, isOpen, openPopover } = usePopoverManager();

  const removeFilter = (id: number) =>
    props.applyFilters(props.filters.filter((f) => f.id !== id));

  const formattedFilters = useMemo(
    () =>
      props.filters.map((f) => ({
        id: f.id,
        formattedString: makeFormattedFilterString(f),
      })),
    [props.filters]
  );

  return (
    <>
      <Stack direction="row" alignItems="center">
        <IconButton
          dataTest="open-filters-form"
          icon={ICONS.filter}
          onClick={openPopover}
        />
        <FiltersList filters={formattedFilters} removeFilter={removeFilter} />
      </Stack>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <FiltersPopupContent {...props} onCancel={closePopover} />
      </Popover>
    </>
  );
};

const FiltersPopupContent = (props: Props & { onCancel(): void }) => {
  const filtersFormApi = useFiltersForm({
    initialFilters: props.filters,
    filterDefinitions: props.filterDefinitions,
  });

  return (
    <FiltersPopoverContent
      {...filtersFormApi}
      applyFilters={props.applyFilters}
      onCancel={props.onCancel}
      renderFilterItem={(filter, plusIcon) => (
        <FilterItem
          removeFilter={filtersFormApi.removeFilter}
          updateFilter={filtersFormApi.updateFilter}
          plusIcon={plusIcon}
          key={filter.id}
          filter={filter}
          filterDefinitions={props.filterDefinitions}
        />
      )}
    />
  );
};

const FilterItem = (props: {
  filter: Filter;
  plusIcon: ReactNode;
  removeFilter: (id: number) => void;
  updateFilter: (filter: Filter) => void;
  filterDefinitions: FilterDefinition[];
}) => {
  const filterDefinitionOptions = props.filterDefinitions.map((f) => ({
    label: f.label,
    value: f,
  }));
  const operatorOptions = getOperators(props.filter.type).map((op) => ({
    label: getOperatorLabel(op),
    value: op,
  }));

  return (
    <BaseFilterItem
      id={props.filter.id}
      plusIcon={props.plusIcon}
      onRemove={() => props.removeFilter(props.filter.id)}
      columnField={
        <Select
          dataTest="filter-name"
          value={filterDefinitionOptions.find((f) =>
            equals(f.value.name, props.filter.name)
          )}
          options={filterDefinitionOptions}
          label={'Column'}
          withoutError={true}
          onChange={({ value }) =>
            props.updateFilter({ ...props.filter, ...value } as Filter)
          }
        />
      }
      operatorField={
        <Select
          value={getSelectedOption(props.filter.operator, operatorOptions)}
          dataTest="filter-operator"
          options={operatorOptions}
          label={'Operator'}
          withoutError={true}
          onChange={({ value }) =>
            props.updateFilter({ ...props.filter, operator: value } as Filter)
          }
        />
      }
      valueField={
        <ValueInput filter={props.filter} updateFilter={props.updateFilter} />
      }
    />
  );
};

export default Filters;
