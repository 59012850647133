import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useTheme } from '@mui/material';

import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import LearnHowLinkButton from 'shared/view/elements/LearnHowLinkButton/LearnHowLinkButton';

interface Props {
  text: string;
  docLink: string;
  icon: IconDefinition;
}

const HomepageWidgetEmptyContent = (props: Props) => {
  const theme = useTheme();
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <IconAwesomeCustomizable
        icon={props.icon}
        nativeProps={{ color: theme.palette.additionalBackground.inactive }}
        size="6x"
      />
      <div style={{ width: '370px', textAlign: 'center' }}>
        <Typography color="text.secondary">{props.text}</Typography>
      </div>
      <LearnHowLinkButton docLink={props.docLink} />
    </Stack>
  );
};

export default HomepageWidgetEmptyContent;
