import { BoxPlotSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/BoxPlotSettings';
import { mainBlueColor } from 'shared/view/charts/shared/colors';

import { getAxisesDomains, getScaleType } from './shared/Scale';
import { config } from './shared/config';
import { getExperimentRunWithKeyValueFieldsAsObjectsFunctions } from './shared/ExperimentRunWithKeyValueFieldsAsObjects';
import {
  getDefaultDataField,
  WidgetVisualizationSpec,
} from './shared/WidgetVisualizationSpec';

// ts-unused-exports:disable-next-line
export const {
  getExperimentRunsForSpec: getExperimentRunsForBoxPlotSpec,
  getDataSpecFields,
} = getExperimentRunWithKeyValueFieldsAsObjectsFunctions();

export const getBoxPlotSpec = (
  dataSourceName: string,
  settings: BoxPlotSettings
): WidgetVisualizationSpec => {
  const yAxisDataSpecFields = getDataSpecFields(settings.yAxis.spec);
  const xAxisDataSpecFields = getDataSpecFields(settings.xAxis.spec);
  const { xAxisDomainFields, yAxisDomainFields, isNeedToClipMark } =
    getAxisesDomains(settings);

  return {
    config,
    data: getDefaultDataField(dataSourceName),
    mark: { type: 'boxplot', clip: isNeedToClipMark, color: mainBlueColor },
    encoding: {
      x: {
        ...xAxisDataSpecFields,
        axis: {
          grid: false,
        },
        scale: {
          type: getScaleType(settings.xAxis.isLogScale),
          ...xAxisDomainFields?.scale,
        },
      },
      y: {
        ...yAxisDataSpecFields,
        scale: {
          zero: false,
          type: getScaleType(settings.yAxis.isLogScale),
          ...yAxisDomainFields?.scale,
        },
      },
    },
  };
};
