import {
  useBreadcrumbItem,
  useAppBreadcrumbs,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import routes from 'shared/routes';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import RegisteredModelTypeInfo from 'shared/view/domain/Catalog/RegisteredModelTypeInfo';
import { ModelLayout } from 'pages/authorized/CatalogPages/ModelPages/shared/Layout/store';

import { useCatalogsPageBreadcrumbs } from './useCatalogsPageBreadcrumbs';

export const useModelBreadcrumbs = ({
  model,
}: {
  model?: ModelLayout['registeredModel'];
}) => {
  const topEnhancer = isNotError(model) ? (
    <RegisteredModelTypeInfo
      kind="title"
      modelId={model.id}
      taskType={model.taskType}
      dataType={model.dataType}
      versions={model.versions.versions}
    />
  ) : undefined;

  return useAppBreadcrumbs(
    useCatalogsPageBreadcrumbs(),
    useBreadcrumbItem({
      route: routes.registeredModel,
      hasTopEnhancer: true,
      ...(isNotError(model)
        ? { name: model.name, topEnhancer }
        : { isLoading: true }),
    })
  );
};
