import { ApolloLink } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { RetryLink } from '@apollo/client/link/retry';
import { SentryLink } from 'apollo-link-sentry';
import * as Sentry from '@sentry/react';
import { setContext } from '@apollo/client/link/context';

import { retryCondition } from 'shared/utils/api/retrying';
import { traverseJsonObjects } from 'shared/utils/json';

import { createRestrictedGraphqlErrorLink } from './createRestrictedGraphqlErrorLink';
import { createRequestResponseLinkWithInterceptors } from './GraphqlRequestResponseInterceptor';

export const orgIdLink = (orgId?: string) =>
  setContext((_, { headers }) => {
    if (!orgId) {
      return {
        headers: {
          ...(headers as { [key: string]: string }),
        },
      };
    }
    return {
      headers: {
        ...(headers as { [key: string]: string }),
        'Grpc-Metadata-organization-id': orgId,
      },
    };
  });

const removeTypenameFromRequest = new ApolloLink((operation, forward) => {
  if ('variables' in operation) {
    const omitTypename = (key: string, value: unknown) =>
      key === '__typename' ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    ) as Record<string, any>;
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const fixWorkspaceNameForPermissionV2FromResponse = new ApolloLink(
  (operation, forward) => {
    return forward(operation).map((data) => {
      traverseJsonObjects((obj) => {
        if (
          typeof obj.__typename === 'string' &&
          obj.__typename === 'Workspace' &&
          typeof obj.name === 'string' &&
          obj.name.includes('/')
        ) {
          const [_, ...workspaceName] = obj.name.split(/\/+/);
          obj.name = workspaceName.join('/');
        }
      }, data.data);
      return data;
    });
  }
);
const sentryLink = new SentryLink({
  setTransaction: false,
  setFingerprint: false,
  attachBreadcrumbs: {
    includeError: true,
  },
});

const createApolloLink = (getHttpLink: () => ApolloLink = createHttpLink) => {
  const { addErrorInterceptor, errorLink } = createErrorLink();
  const requestResponseLinkWithInterceptors =
    createRequestResponseLinkWithInterceptors();
  const restrictedGraphqlErrorLink = createRestrictedGraphqlErrorLink();
  const httpLink = getHttpLink();
  const retryLink = createRetryLink();

  return {
    addErrorInterceptor,
    addRequestResponseInterceptor:
      requestResponseLinkWithInterceptors.addInterceptor,
    addRestrictedGraphqlErrorInterceptor:
      restrictedGraphqlErrorLink.addInterceptor,
    // order is important because http link do not chain following items
    link: ApolloLink.from([
      removeTypenameFromRequest,
      fixWorkspaceNameForPermissionV2FromResponse,
      errorLink,
      requestResponseLinkWithInterceptors.link,
      restrictedGraphqlErrorLink.link,
      retryLink,
      sentryLink,
      httpLink,
    ]),
  };
};

const sentryGraphqlErrorInterceptor: GraphqlErrorInterceptor = (props) => {
  const { operation, graphQLErrors, networkError } = props;

  Sentry.withScope((scope) => {
    scope.setTransactionName(operation.operationName);
    scope.setContext('apolloGraphQLOperation', {
      operationName: operation.operationName,
      variables: JSON.stringify(operation.variables),
      extensions: operation.extensions,
    });

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        Sentry.captureMessage(error.message, {
          level: 'error',
          fingerprint: ['{{ default }}', '{{ transaction }}'],
          contexts: {
            apolloGraphQLError: {
              error: JSON.stringify(error),
              message: error.message,
              extensions: JSON.stringify(error.extensions),
            },
          },
        });
      });
    }

    if (networkError) {
      Sentry.captureMessage(networkError.message, {
        level: 'error',
        contexts: {
          apolloNetworkError: {
            error: JSON.stringify(networkError),
            extensions: JSON.stringify((networkError as any).extensions),
          },
        },
      });
    }
  });
};

export type GraphqlErrorInterceptor = (errorResponse: ErrorResponse) => void;
const createErrorLink = () => {
  const interceptors: GraphqlErrorInterceptor[] = [
    sentryGraphqlErrorInterceptor,
  ];
  const addErrorInterceptor = (f: GraphqlErrorInterceptor) => {
    interceptors.push(f);
  };

  return {
    addErrorInterceptor,
    errorLink: onError((response) => interceptors.forEach((f) => f(response))),
  };
};

const createHttpLink = () =>
  new HttpLink({
    uri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ':' + window.location.port : ''
    }/api/v1/graphql/query`,
  });

const createRetryLink = () =>
  new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => {
        const errorStatus = error?.statusCode as undefined | number;
        return retryCondition({ errorStatus });
      },
    },
  });

export default createApolloLink;
