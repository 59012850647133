import matchType from 'shared/utils/matchType';

export type DeleteEntityType =
  | 'project'
  | 'dataset'
  | 'endpoint'
  | 'registeredModel'
  | 'modelVersion'
  | 'monitoringModel'
  | 'datasetVersion';

export const getConfirmDescriptionByDeleteEntityType = (
  type: DeleteEntityType
) => {
  return matchType(
    {
      project: () => 'project and its experiments',
      dataset: () => 'dataset',
      datasetVersion: () => 'dataset version',
      endpoint: () => 'data associated with this endpoint',
      modelVersion: () => 'registered model version (RMV)',
      registeredModel: () => 'registered model',
      monitoringModel: () => 'monitored model',
    },
    type
  );
};

export const getConfirmAdditionalTextByDeleteEntityType = (
  type: DeleteEntityType
) => {
  return matchType(
    {
      project: () =>
        'Any models promoted from experiments will no longer have reproducibility information.',
      dataset: () =>
        'Models connected to this dataset will no longer show it as linked.',
      datasetVersion: () =>
        'Its versions, metadata and history will be deleted. This action cannot be undone.',
      endpoint: () =>
        'Any services calling this rest endpoint will not receive model results anymore. This action is not reversible.',
      modelVersion: () =>
        'All metadata and history associated with the version will be deleted. This action cannot be undone.',
      registeredModel: () =>
        'Its versions, metadata and history will be deleted. This action cannot be undone.',
      monitoringModel: () =>
        'All the dashboards and monitoring history will be deleted and you won’t be able to recreate the monitored model. This action cannot be undone.',
    },
    type
  );
};

export const getDeleteEntityNameByType = (type: DeleteEntityType) => {
  return matchType(
    {
      project: () => 'project',
      dataset: () => 'dataset',
      datasetVersion: () => 'dataset version',
      endpoint: () => 'endpoint',
      modelVersion: () => 'registered model version',
      monitoringModel: () => 'monitored model',
      registeredModel: () => 'registered model',
    },
    type
  );
};
