import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import {
  AddModelVersionLabel,
  AddModelVersionLabelVariables,
  DeleteModelVersionLabel,
  DeleteModelVersionLabelVariables,
} from './graphql-types/modelVersionLabelsManager.generated';

const ADD_LABEL = gql`
  mutation AddModelVersionLabel($id: ID!, $label: String!) {
    registeredModelVersion(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        addLabels(labels: [$label]) {
          id
          labels
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

const DELETE_LABEL = gql`
  mutation DeleteModelVersionLabel($id: ID!, $label: String!) {
    registeredModelVersion(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        deleteLabels(labels: [$label]) {
          id
          labels
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useAddLabelMutation = () =>
  useCustomMutation<AddModelVersionLabel, AddModelVersionLabelVariables>(
    ADD_LABEL,
    {
      context: 'adding label',
    }
  );

export const useDeleteLabelMutation = () =>
  useCustomMutation<DeleteModelVersionLabel, DeleteModelVersionLabelVariables>(
    DELETE_LABEL,
    {
      context: 'deleting label',
    }
  );
