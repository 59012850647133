import { map, pipe, take, xprod, equals as ramdaEquals } from 'ramda';

import { OmitStrict } from 'shared/utils/types';
import isNotNil from 'shared/utils/isNotNill';
import {
  getExperimentRunsWithDisplayedFields,
  getKeyValueSpecs,
  RequiredExperimentRun,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import {
  generateMetricsCharts,
  generateParallelCoordinateChart,
} from 'shared/models/CrossRunDashboard/defaultWidgets';
import { KeyValueSpec } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/KeyValueSpec';
import {
  BoxPlotSettings,
  getBoxPlotAxisesDataSpecs,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/BoxPlotSettings';
import { emptyCustomDomain } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/shared/CustomDomain';
import { CrossRunWidget } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunWidget';
import { removeNillProperties, updateById } from 'shared/utils/collection';
import { WidgetSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/WidgetSettings';

export const crossRunDashboardForComparingName = 'default-dashboard';

export type CrossRunDashboardForComparing = {
  id: string;
  name: typeof crossRunDashboardForComparingName;
  widgets: CrossRunWidget[];
  layout: LayoutItem[];
};

export type EmptyCrossRunDashboardForComparing = OmitStrict<
  CrossRunDashboardForComparing,
  'widgets'
> & { widgets: [] };

export const makeDefaultCrossRunDashboardForComparing = (
  experimentRuns: RequiredExperimentRun[]
) =>
  addDefaultWidgets(
    {
      id: 'default-dashboard-id',
      name: crossRunDashboardForComparingName,
      widgets: [],
      layout: [],
    },
    experimentRuns
  );

const addDefaultWidgets = (
  dashboard: EmptyCrossRunDashboardForComparing,
  experimentRuns: RequiredExperimentRun[]
): CrossRunDashboardForComparing => {
  // to get rid of side effets and make this function more predictable, we generate id from index
  return pipe(
    () =>
      generateDefaultCustomWidgetsSettings(
        experimentRuns
      ).reduce<CrossRunDashboardForComparing>(
        (resDashboard, settings, index) =>
          addCustomWidget(resDashboard, settings, String(index)),
        dashboard
      ),
    (x) =>
      experimentRuns.some(({ observations }) => observations.length > 0)
        ? addObservationChartsWidget(String(x.widgets.length), x)
        : x
  )();
};

const generateDefaultCustomWidgetsSettings = (
  experimentRuns: RequiredExperimentRun[]
) => {
  const experimentRunsWithDisplayedFields =
    getExperimentRunsWithDisplayedFields(experimentRuns);
  const keyValueSpecs = getKeyValueSpecs(experimentRunsWithDisplayedFields);

  return [
    ...generateMetricsCharts(keyValueSpecs),
    ...generateMetricVsHyperparameterCharts(keyValueSpecs),
    generateParallelCoordinateChart(
      keyValueSpecs,
      experimentRunsWithDisplayedFields
    ),
  ].filter(isNotNil);
};

const generateMetricVsHyperparameterCharts = (
  keyValueSpecs: KeyValueSpec[]
): BoxPlotSettings[] => {
  const chartsLimit = 15;

  const chartDataSpecs = getBoxPlotAxisesDataSpecs(keyValueSpecs);
  const yAxisMetrics = chartDataSpecs.yAxis.filter(
    ({ subtype }) => subtype === 'metric'
  );
  const xAxisHyperparameters = chartDataSpecs.xAxis.keyValue.filter(
    ({ subtype }) => subtype === 'hyperparameter'
  );

  return pipe(
    () => xprod(yAxisMetrics, xAxisHyperparameters),
    (x) => take(chartsLimit, x),
    (x) =>
      map(
        ([yAxisMetric, xAxisHyperparameter]): BoxPlotSettings => ({
          name: `${yAxisMetric.key} vs ${xAxisHyperparameter.key}`,
          type: 'boxPlot',
          yAxis: {
            domain: emptyCustomDomain,
            isLogScale: false,
            spec: yAxisMetric,
          },
          xAxis: {
            domain: emptyCustomDomain,
            isLogScale: false,
            spec: xAxisHyperparameter,
          },
        }),
        x
      )
  )();
};

export const defaultMinSize = {
  minW: 6,
  minH: 8,
};

type LayoutItem = {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
};

export const layoutSettings = {
  cols: 12,
  rowHeight: 30,
  isResizable: true,
  isDraggable: true,
};

export const deleteWidget = (
  dashboard: CrossRunDashboardForComparing,
  widgetId: string
): CrossRunDashboardForComparing => {
  return {
    ...dashboard,
    widgets: dashboard.widgets.filter((widget) => widget.id !== widgetId),
    layout: dashboard.layout.filter((item) => item.i !== widgetId),
  };
};

export const updateCustomWidget = (
  dashboard: CrossRunDashboardForComparing,
  widgetId: string,
  widgetSettings: WidgetSettings
): CrossRunDashboardForComparing => {
  return {
    ...dashboard,
    widgets: updateById(
      (widget) => ({
        ...widget,
        settings: widgetSettings,
      }),
      widgetId,
      dashboard.widgets
    ),
  };
};

export const addCustomWidget = (
  dashboard: CrossRunDashboardForComparing,
  widgetSettings: WidgetSettings,
  id: string
): CrossRunDashboardForComparing => {
  const newWidget = {
    id,
    settings: widgetSettings,
    type: 'custom' as const,
  };
  return addWidget(dashboard, newWidget);
};

export const addWidget = (
  dashboard: CrossRunDashboardForComparing,
  widget: CrossRunWidget
): CrossRunDashboardForComparing => {
  const initialHeight = 10;
  return {
    ...dashboard,
    widgets: dashboard.widgets.concat(widget),
    layout: [
      ...dashboard.layout,
      {
        i: widget.id,
        x: 0,
        y:
          dashboard.layout.length === 0
            ? 0
            : Math.max(...dashboard.layout.map(({ y }) => y)) + initialHeight, // put at the bottom
        w: layoutSettings.cols,
        h: initialHeight,
        ...defaultMinSize,
      },
    ],
  };
};

export const equals = (
  dashboard1: CrossRunDashboardForComparing,
  dashboard2: CrossRunDashboardForComparing
): boolean => {
  // we have to remove properties with null or undefined because after saving dashboard on the backend they are removed
  // for example, { domain: { min: undefined; max: undefined } } saved as { domain: {} } so a local copy of a dashboard is not equal to the version from the backend
  return ramdaEquals(
    removeNillProperties(dashboard1),
    removeNillProperties(dashboard2)
  );
};

export const updateLayout = (
  dashboard: CrossRunDashboardForComparing,
  layout: CrossRunDashboardForComparing['layout']
): CrossRunDashboardForComparing => ({
  ...dashboard,
  layout,
});

export const addObservationChartsWidget = (
  id: string,
  crossRunDashboard: CrossRunDashboardForComparing
): CrossRunDashboardForComparing => {
  return addWidget(crossRunDashboard, {
    id,
    type: 'custom',
    settings: {
      type: 'observationCharts',
      name: 'Observations',
    },
  });
};
