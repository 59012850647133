import { Box, Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import docsUsePrompts from '../images/docs-use-prompts.png';

export const DocsUsePrompts = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Generate AI assisted content
    </Typography>
    <Typography variant="body2" mb={3}>
      To utilize the pre-engineered prompts: Input a few lines as directed into
      each prompt and generate editable text for your documentation.
    </Typography>
    <Box>
      <img
        src={docsUsePrompts}
        width="100%"
        height="auto"
        alt="homepage invite users button modal"
      />
    </Box>
  </WizardStepContent>
);
