import { useState, useEffect } from 'react';
import { Box, styled, Typography, Card as MuiCard } from '@mui/material';
import { isEmpty, keys } from 'lodash';
import { BarChart } from '@mui/x-charts/BarChart';
import { useResizeDetector } from 'react-resize-detector';

import {
  EmptyFilteredList,
  EmptyGridContainer,
} from 'shared/view/elements/EmptyGrid/EmptyGrid';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import { ComparedEvaluation } from '../hooks/useEvaluationsForComparing';

const Card = styled(MuiCard)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  height: '286px',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

type Props = Pick<ComparedEvaluation, 'labelsDistribution'>;

const MIN_BAR_GROUP_SIZE = 54;

export const LabelDistributionChart = (props: Props) => {
  const { labelsDistribution } = props;
  const { ref, width = 415 } = useResizeDetector();
  const [columnWidth, setColumnWidth] = useState(MIN_BAR_GROUP_SIZE);

  const dataX = keys(labelsDistribution);
  const data = dataX.map((key) => labelsDistribution[key]);

  useEffect(() => {
    if (width / dataX.length < MIN_BAR_GROUP_SIZE) {
      setColumnWidth(MIN_BAR_GROUP_SIZE);
    } else {
      setColumnWidth(width - 16 / dataX.length);
    }
  }, [dataX.length, width]);

  return (
    <Card variant="outlined">
      <Typography variant="overline" color="text.secondary">
        EVALUATION DISTRIBUTION BY LABELS
      </Typography>
      {!isEmpty(dataX) ? (
        <Box
          ref={ref}
          sx={{
            overflowX: 'auto',
            overflowY: 'hidden',
            height: '220px',
          }}
        >
          <Box
            sx={{
              //workaround to position the svg canvas in right place
              ml: -2,
              mt: -10,
              maxWidth: width,
            }}
          >
            <BarChart
              height={320}
              width={
                columnWidth === MIN_BAR_GROUP_SIZE
                  ? columnWidth * dataX.length + 90
                  : width
              }
              bottomAxis={{
                axisId: 'label',
                slots: {
                  axisTickLabel: (_props) => {
                    let label = _props.children as string;
                    if (columnWidth / label.length < 7) {
                      label = label.slice(0, 3) + '...';
                    }

                    return <text {..._props}>{label}</text>;
                  },
                },
              }}
              slotProps={{
                axisLine: { style: { stroke: '#E0E0E0' } },
                axisTick: { style: { stroke: '#E0E0E0' } },
                axisTickLabel: {
                  style: {
                    fill: '#666',
                    fontSize: '12px',
                    letterSpacing: '0.4px',
                  },
                },
                bar: { width: 10 },
              }}
              xAxis={[
                {
                  scaleType: 'band',
                  data: dataX,
                  // @ts-ignore
                  categoryGapRatio: 0.9,
                  barGapRatio: 0.1,
                  id: 'label',
                },
              ]}
              series={[
                {
                  data,
                  color: '#20A8C9',
                  id: 'label',
                  highlightScope: { highlighted: 'item', faded: 'series' },
                },
              ]}
              legend={{ hidden: true }}
              tooltip={{ trigger: 'axis' }}
              axisHighlight={{ x: 'none', y: 'none' }}
            />
          </Box>
        </Box>
      ) : (
        <EmptyGridContainer sx={{ height: 220 }}>
          <EmptyFilteredList
            message="You don't have any labels."
            icon={ICONS.tag}
            iconSize="3x"
            textWidth={200}
          />
        </EmptyGridContainer>
      )}
    </Card>
  );
};
