import generateArrayPath from 'shared/utils/generateArrayPath';
import { RecordPath } from 'shared/utils/types';

const isNumeric = (num: any): boolean => {
  return !isNaN(num);
};

export const makeGetFieldName =
  <T extends Record<any, any> = never>() =>
  (recordPath: RecordPath<T>): string => {
    return generateArrayPath(recordPath)
      .map((segment, i) => {
        if (i === 0) {
          return segment;
        }
        if (isNumeric(segment)) {
          return `[${segment}]`;
        }
        return `.${segment}`;
      })
      .join('');
  };
