import { NonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';
import { RecordFromUnion } from 'shared/utils/types';

import { dateUpdatedSpec, DateUpdatedSpec } from '../DataSpec';
import { KeyValueSpec } from '../KeyValueSpec';
import { CommonWidgetSettings } from './shared/CommonWidgetSettings';
import { CustomDomain } from './shared/CustomDomain';

export type LineChartXAxisDataSpec = KeyValueSpec | DateUpdatedSpec;
export type LineChartYAxisDataSpec = KeyValueSpec;

export interface LineChartSettings extends CommonWidgetSettings<'lineChart'> {
  xAxis: {
    spec: LineChartXAxisDataSpec;
    isLogScale: boolean;
    domain: CustomDomain;
  };
  yAxis: {
    specs: NonEmptyArray<LineChartYAxisDataSpec>;
    isLogScale: boolean;
    domain: CustomDomain;
  };
}

export interface LineChartAxisesDataSpec {
  xAxis: RecordFromUnion<
    LineChartXAxisDataSpec['type'],
    {
      dateUpdated: DateUpdatedSpec;
      keyValue: Array<KeyValueSpec>;
    }
  >;
  yAxis: LineChartYAxisDataSpec[];
}

export const getLineChartAxisesDataSpecs = (
  keyValueSpecs: KeyValueSpec[]
): LineChartAxisesDataSpec => {
  return {
    xAxis: {
      dateUpdated: dateUpdatedSpec,
      keyValue: keyValueSpecs,
    },
    yAxis: keyValueSpecs,
  };
};
