import { useMemo } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { isNil } from 'ramda';

import { TaskType, DataType } from 'generated/types';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

type PropsType = {
  taskType?: TaskType;
  dataType?: DataType;
};

type UseTaskTypeProps = {
  icon: IconDefinition;
  text: string | undefined;
};

export const useTaskType = ({
  taskType,
  dataType,
}: PropsType): UseTaskTypeProps => {
  const icon = useMemo(
    () =>
      isNil(dataType)
        ? ICONS.dataType[DataType.UNKNOWN]
        : ICONS.dataType[dataType],
    [dataType]
  );

  const text = useMemo(
    () => mountTaskTypeTitle({ taskType, dataType }),
    [taskType, dataType]
  );

  return { icon, text };
};

const mountTaskTypeTitle = ({
  taskType,
  dataType,
}: {
  taskType?: TaskType;
  dataType?: DataType;
}) => {
  const terms = [];
  let joinChar = ' ';
  if (!isNil(dataType) && dataType !== DataType.UNKNOWN) {
    if (dataType === DataType.OTHER) {
      joinChar = ',';
      terms.push(`${dataType} DATA`);
    } else {
      terms.push(dataType);
    }
  }

  if (!isNil(taskType) && taskType !== TaskType.UNKNOWN) {
    if (taskType === TaskType.OTHER) {
      joinChar = ', ';
      terms.push(`${taskType} TASK`);
    } else {
      terms.push(taskType);
    }
  }

  return terms.length > 0 ? `${terms.join(joinChar)} |` : undefined;
};
