/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';

import { EndpointWithKafkaConfigurations } from 'features/deployment/canary/shared/EndpointWithKafkaConfigurations';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { KAFKA_CONFIG_FRAGMENT } from 'shared/graphql/Deployment/Endpoint/KafkaConfig/KafkaConfig';
import { KAFKA_CONFIGURATIONS_SIMPLIFIED_LIST_FRAGMENT } from 'shared/graphql/Deployment/Endpoint/KafkaConfig/KafkaConfiguration';
import {
  mapDataOrError,
  RestrictedGraphqlErrorOrData,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { PERMISSION_FRAGMENT } from 'shared/graphql/Permission/Permission';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { OmitStrict } from 'shared/utils/types';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { ExtractByTypename } from 'shared/utils/types';

import * as GraphqlTypes from './graphql-types/endpointSettings.generated';

type Workspace = ExtractByTypename<
  GraphqlTypes.EndpointSettings['workspace'],
  'Workspace'
>;
export type Endpoint = ExtractByTypename<Workspace['endpoint'], 'Endpoint'>;

const QUERY = gql`
  query EndpointSettings(
    $id: ID!
    $workspaceName: String!
    $organizationId: ID
  ) {
    kafkaConfigurations {
      id
      ...KafkaConfigurationsSimplifiedListData
    }
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoint(id: $id) {
          ... on Error {
            ...ErrorData
          }
          ... on Endpoint {
            id
            path
            visibility
            owner {
              ...OwnerData
            }
            description
            workspace {
              id
              ...WorkspaceData
            }
            customPermission {
              ...PermissionData
            }
            environments {
              id
              authzPredictionEnabled
              tokensEnabled
              tokens {
                id
                value
              }
              kafka {
                ...KafkaConfigData
              }
            }
            allowedActions {
              ...AllowedActionsData
            }
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${PERMISSION_FRAGMENT}
  ${KAFKA_CONFIG_FRAGMENT}
  ${KAFKA_CONFIGURATIONS_SIMPLIFIED_LIST_FRAGMENT}
`;

const useEndpointSettings = (
  variables: GraphqlTypes.EndpointSettingsVariables,
  options?: OmitStrict<
    QueryHookOptions<
      GraphqlTypes.EndpointSettings,
      GraphqlTypes.EndpointSettingsVariables
    >,
    'variables'
  >
) => {
  const queryResult = useCustomQuery<
    GraphqlTypes.EndpointSettings,
    GraphqlTypes.EndpointSettingsVariables
  >(QUERY, { ...options, variables });

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult,
  });
};

function convert(
  res: GraphqlTypes.EndpointSettings
): RestrictedGraphqlErrorOrData<EndpointWithKafkaConfigurations<Endpoint>> {
  return mapDataOrError(
    res.workspace,
    (x) => x.endpoint,
    (endpoint) => ({
      endpoint,
      kafkaConfigurations: res.kafkaConfigurations.configurations,
    })
  );
}

export default useEndpointSettings;
