import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { SYSTEM_ADMIN_USER_FRAGMENT } from 'shared/graphql/SystemAdmin/SystemAdmin';
import { useToastCommunicationErrorWatcher } from 'shared/view/elements/Notification/Notification';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';

import { SystemAdmins } from './graphql-types/useSystemAdminsList.generated';

const QUERY = gql`
  query SystemAdmins {
    systemAdmins {
      id
      admins {
        id
        allowedActions {
          ...AllowedActionsData
        }
        user {
          id
          ...SystemAdminUserData
        }
      }
    }
  }
  ${SYSTEM_ADMIN_USER_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;

export const useSystemAdminsList = () => {
  const result = useCustomQuery<SystemAdmins>(QUERY);

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert: convertSystemAdmins,
    queryResult: result,
  });

  useToastCommunicationErrorWatcher(communication, {
    context: 'loading system admins',
  });

  return {
    data,
    communication,
  };
};

function convertSystemAdmins(result: SystemAdmins) {
  return result.systemAdmins.admins;
}
