import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';
import { InformationalTile } from 'features/homepage/view/shared/InformationalTile';
import ReleaseChecklistsSVG from 'features/homepage/images/release-checklists.svg?react';
import { useAdministratedOrganization } from 'features/shared/useAdministratedOrganization';
import { Button } from 'shared/view/elements/Button';
import routes from 'shared/routes';

export const CreateNewChecklistAction = (props: WizardStepContentProps) => {
  const administratedOrganizationApi = useAdministratedOrganization();
  const organizationName = administratedOrganizationApi.data?.name || undefined;
  const navigate = useNavigate();

  return (
    <WizardStepContent
      {...props}
      buttons={{ next: { children: 'Complete tutorial' } }}
    >
      <Typography variant="subtitle1" mb={2}>
        Now it's your turn
      </Typography>
      <Typography variant="body2" mb={3}>
        Ready to see it in action? Give it a try!
      </Typography>
      <InformationalTile
        title="Create a governance checklist"
        description="Create a custom checklist to encapsulate your business processes and governance requirements into a straightforward to-do list."
        svgComponent={<ReleaseChecklistsSVG />}
      >
        {organizationName !== undefined ? (
          <Button
            size="small"
            variant="outlined"
            isLoading={false}
            onClick={() =>
              navigate(
                routes.organizationCustomAttributes.getRedirectPath({
                  name: organizationName,
                })
              )
            }
          >
            Create new checklist
          </Button>
        ) : null}
      </InformationalTile>
    </WizardStepContent>
  );
};
