import { gql } from '@apollo/client';

export const EXTERNAL_DEPLOY_FRAGMENT = gql`
  fragment ExternalDeploymentData on ExternalDeployment {
    id
    locationDeployed
    urlPathTitle
    description
    locationType
    cloudProvider
  }
`;
