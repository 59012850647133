import { Box, Stack, Typography } from '@mui/material';

import Chip from 'shared/view/elements/Chip/Chip';

import image from '../images/deploy-feature.png';
import { Circle } from '../Circle';

export const DeployFeature = () => (
  <Stack direction="row" spacing={0}>
    <Box width="35%" display="flex" alignItems="center" mr={4}>
      <Box>
        <Circle mb={5}>6</Circle>
        <Typography mb="12px" variant="subtitle1" color="text.secondary">
          Deploy or Delegate
        </Typography>
        <Box maxWidth="220px">
          <Typography mb={2} variant="body2">
            Deploy on Verta or entrust us with your multi-cloud releases.
          </Typography>
        </Box>
        <Chip
          variant="outlined"
          label="Advanced tier feature"
          color="info"
          size="small"
        />
      </Box>
    </Box>
    <Box width="65%">
      <img
        src={image}
        width="100%"
        height="auto"
        alt="deploy feature example"
      />
    </Box>
  </Stack>
);
