import { toString } from 'ramda';

const matchType = <Union extends string | number, Result>(
  matchers: { [T in Union]: (type: T) => Result },
  type: Union
): Result => {
  if (type in matchers) {
    return matchers[type](type);
  } else {
    const expectedTypes = Object.keys(matchers)
      .map((t) => `'${t}'`)
      .join(', ');
    throw new Error(
      `No match. Expects ${expectedTypes} but got ${toString(type)}`
    );
  }
};

export default matchType;
