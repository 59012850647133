/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { sortBy } from 'ramda';

import { IPrimitiveKeyValue } from 'shared/models/PrimitiveKeyValue';
import {
  ARTIFACT_FRAGMENT,
  ARTIFACT_WITH_DATASET_VERSION_FRAGMENT,
} from 'shared/graphql/Artifact/Artifact';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import { convertGraphqlAttributeWithWidgetBlob } from 'shared/graphql/Attribute/converters';
import { IEnvironmentBlob } from 'shared/models/Versioning/Blob/EnvironmentBlob';
import { IAttributeWithWidgetBlob } from 'shared/models/AttributeWithWidgetBlob';
import { ExperimentRunData } from 'shared/graphql/ExperimentRunDetails/graphql-types/ExperimentRunDetails.generated';
import { AllowedActionsData } from 'shared/graphql/collaborators/graphql-types/fragments.generated';
import { convertGraphqlPrimitiveKeyValue } from 'shared/graphql/PrimitiveKeyValue/converters';
import { PRIMITIVE_KEY_VALUE_FRAGMENT } from 'shared/graphql/PrimitiveKeyValue/PrimitiveKeyValue';
import { convertEnvironmentBlob } from 'shared/models/Versioning/Blob/EnvironmentBlob/converters';
import { ReplaceProps } from 'shared/utils/types';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';

import { CODE_VERSION_BLOB_FRAGMENT } from '../CodeVersion/CodeVersionFromBlob';
import { OBSERVATION_FRAGMENT } from './Observation/Observation';

export const EXPERIMENT_RUN_FRAGMENT = gql`
  fragment ExperimentRunData on ExperimentRun {
    id
    experiment {
      id
      name
    }
    owner {
      id
      email
      username
      picture
    }
    codeVersions {
      ...CodeVersionBlob
    }
    projectId
    project {
      id
      allowedActions {
        ...AllowedActionsData
      }
      workspace {
        id
        name
      }
    }
    hyperparameters {
      ...PrimitiveKeyValueData
    }
    attributes {
      ...AttributeData
    }
    metrics {
      ...PrimitiveKeyValueData
    }
    experimentId
    name
    description
    tags
    environment
    observations {
      ...Observation
    }
    dateCreated
    dateUpdated
    artifacts {
      ...ArtifactData
    }
    datasets {
      ...ArtifactWithDatasetVersionData
    }
  }
  ${OBSERVATION_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${ARTIFACT_FRAGMENT}
  ${ARTIFACT_WITH_DATASET_VERSION_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
  ${PRIMITIVE_KEY_VALUE_FRAGMENT}
  ${CODE_VERSION_BLOB_FRAGMENT}
`;

export type ExperimentRunDetails = ReplaceProps<
  ExperimentRunData,
  {
    dateCreated: Date;
    dateUpdated: Date;
    environment: IEnvironmentBlob | undefined;
    hyperparameters: IPrimitiveKeyValue[];
    metrics: IPrimitiveKeyValue[];
    attributes: IAttributeWithWidgetBlob[];
  }
> & {
  allowedActions: AllowedActionsData;
};

const sortFunc = <T extends { key: string }>({ key }: T) => key.toUpperCase();
const stringSortByKey = sortBy(sortFunc);

export const convertGraphqlExperimentRun = (
  run: ExperimentRunData
): ExperimentRunDetails => ({
  ...run,
  allowedActions: run.project.allowedActions,
  environment: convertEnvironmentBlob(run.environment) || undefined,
  dateCreated: new Date(run.dateCreated),
  dateUpdated: new Date(run.dateUpdated),
  hyperparameters: stringSortByKey(
    run.hyperparameters.map(convertGraphqlPrimitiveKeyValue)
  ),
  metrics: stringSortByKey(run.metrics.map(convertGraphqlPrimitiveKeyValue)),
  attributes: stringSortByKey(
    run.attributes.map(convertGraphqlAttributeWithWidgetBlob)
  ),
  artifacts: stringSortByKey(run.artifacts),
  datasets: stringSortByKey(run.datasets),
});
