import { gql } from '@apollo/client';

import { CUSTOM_ATTRIBUTE_CATEGORY_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeCategory';
import { CUSTOM_ATTRIBUTE_DEFINITION_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeDefinition';
import { CUSTOM_ATTRIBUTE_VALUE_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeValue';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

export const LOAD_CUSTOM_ATTRIBUTE_DEFINITIONS = gql`
  query LoadCustomAttributeDefinitions($organizationId: ID!) {
    organizationV2(id: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        customAttributeDefinitions {
          ... on Error {
            ...ErrorData
          }
          ... on CustomAttributeDefinitions {
            customAttributeDefinitions {
              id
              ...CustomAttributeDefinitionData
            }
          }
        }
      }
    }
  }
  ${CUSTOM_ATTRIBUTE_DEFINITION_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const LOAD_CUSTOM_ATTRIBUTE_DEFINITIONS_WITH_CATEGORIES = gql`
  query LoadCustomAttributeDefinitionsWithCategories($organizationId: ID!) {
    organizationV2(id: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        customAttributeCategories {
          ... on CustomAttributeCategories {
            customAttributeCategories {
              id
              ...CustomAttributeCategoryData
            }
          }
        }
        customAttributeDefinitions {
          ... on Error {
            ...ErrorData
          }
          ... on CustomAttributeDefinitions {
            customAttributeDefinitions {
              id
              ...CustomAttributeDefinitionData
            }
          }
        }
      }
    }
  }
  ${CUSTOM_ATTRIBUTE_DEFINITION_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_CATEGORY_FRAGMENT}
`;

export const LOAD_MODEL_CUSTOM_ATTRIBUTE_VALUES = gql`
  query LoadModelCustomAttributeValues($targetId: ID!) {
    registeredModel(id: $targetId) {
      ... on RegisteredModel {
        id
        customAttributeValues {
          id
          ...CustomAttributeValueData
        }
      }
    }
  }
  ${CUSTOM_ATTRIBUTE_VALUE_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const LOAD_MODEL_VERSION_CUSTOM_ATTRIBUTE_VALUES = gql`
  query LoadModelVersionCustomAttributeValues($targetId: ID!) {
    registeredModelVersion(id: $targetId) {
      ... on RegisteredModelVersion {
        id
        customAttributeValues {
          id
          ...CustomAttributeValueData
        }
      }
    }
  }
  ${CUSTOM_ATTRIBUTE_VALUE_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const LOAD_CURRENT_ORGANIZATION_GROUPS = gql`
  query LoadCurrentOrganizationGroups($organizationId: ID!) {
    organizationV2(id: $organizationId) {
      ... on OrganizationV2 {
        id
        groups {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_MODEL_README = gql`
  mutation UpdateModelReadme($id: ID!, $readmeText: String!) {
    registeredModel(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        updateReadmeText(readme: $readmeText) {
          id
          readmeText
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const UPDATE_MODEL_VERSION_README = gql`
  mutation UpdateModelVersionReadme($id: ID!, $readmeText: String!) {
    registeredModelVersion(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        updateReadmeText(readme: $readmeText) {
          id
          readmeText
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const CREATE_OR_UPDATE_CUSTOM_ATTRIBUTE_VALUE = gql`
  mutation CreateOrUpdateCustomAttributeValue(
    $id: ID!
    $input: CustomAttributeValueInput!
  ) {
    registeredModel(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        createOrUpdateCustomAttributeValue(input: $input) {
          ... on Error {
            ...ErrorData
          }
          ... on CustomAttributeValue {
            id
            customAttributeDefinitionId
            targetId
            targetType
            selectedValue
            timeCreated
            timeUpdated
            source
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;
export const CREATE_OR_UPDATE_VERSION_CUSTOM_ATTRIBUTE_VALUE = gql`
  mutation CreateOrUpdateVersionCustomAttributeValue(
    $id: ID!
    $input: CustomAttributeValueInput!
  ) {
    registeredModelVersion(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        createOrUpdateCustomAttributeValue(input: $input) {
          ... on Error {
            ...ErrorData
          }
          ... on CustomAttributeValue {
            id
            customAttributeDefinitionId
            targetId
            targetType
            selectedValue
            timeCreated
            timeUpdated
            source
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const JOB_STATUS = gql`
  query JobsStatus($orgId: ID!, $workspaceId: ID!) {
    jobs(organizationId: $orgId, workspaceId: $workspaceId) {
      id
      completionTimestamp
      resultJson
    }
  }
`;
