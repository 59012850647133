import { Box, Stack, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import { noop } from 'lodash';

import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { UploadFileContainer } from 'features/artifactManager/view/UploadArtifactsPopup/UploadArtifactsPopup';
import { FileItem } from 'features/artifactManager/view/UploadArtifactsPopup/FileItem';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import useUploadArtifact from 'features/artifactManager/store/hooks/useUploadArtifact';

export const UploadDatasetConfiguration = () => {
  const [dragEnter, setDragEnter] = useState(false);
  const { uploadingArtifact, cancelRequest, uploadProgress } =
    useUploadArtifact();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: noop,
    onDragEnter: () => setDragEnter(true),
    onDragLeave: () => setDragEnter(false),
  });
  return (
    <>
      <UploadFileContainer {...getRootProps({ isFocused: dragEnter })}>
        <input {...getInputProps()} />
        <Stack alignItems="center" spacing={0}>
          <Box sx={{ color: 'action.disabled' }}>
            <IconAwesomeCustomizable icon={ICONS.fileArrowUp} size="2x" />
          </Box>
          <Typography variant="body2" color="text.secondary" mt={0.5}>
            Drag and drop or{' '}
            <InlineLink simple onClick={noop} to="">
              choose a file
            </InlineLink>{' '}
            to upload.
          </Typography>
          <Typography variant="caption" color="text.disabled" mt={0}>
            Max. file size: 10MB
          </Typography>
        </Stack>
      </UploadFileContainer>
      {acceptedFiles.length ? (
        <Stack mt={2}>
          <FileItem
            file={acceptedFiles[0]}
            key={acceptedFiles[0].name}
            uploadingArtifact={uploadingArtifact}
            isValidFile={true}
            cancelRequest={cancelRequest}
            uploadProgress={uploadProgress}
          />
        </Stack>
      ) : null}
    </>
  );
};
