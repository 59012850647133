import { uniq } from 'lodash';

import isNotNil from 'shared/utils/isNotNill';
import matchBy from 'shared/utils/matchBy';

type CommonBuildInfo = {
  id: string;
  dateCreated: number;
  dateUpdated: number;
};

export type ExperimentRunRequiredBuild = {
  source: 'experimentRun';
  experimentRun: {
    id: string;
    name: string;
    project: { name: string; id: string };
  };
  modelVersion: null;
} & CommonBuildInfo;

type ModelVersionRequiredBuild = {
  source: 'modelVersion';
  modelVersion: {
    id: string;
    version: string;
    registeredModel: { name: string; id: string };
  };
  experimentRun: null;
} & CommonBuildInfo;

export type RequiredBuild =
  | ExperimentRunRequiredBuild
  | ModelVersionRequiredBuild;

export const getSourceId = (build: RequiredBuild): string => {
  return matchBy(
    build,
    'source'
  )({
    experimentRun: ({ experimentRun }) => experimentRun.id,
    modelVersion: ({ modelVersion }) => modelVersion.id,
  });
};

export const getAvailableSourceIds = (environmentBuilds: RequiredBuild[]) => {
  return uniq(
    environmentBuilds
      .map((build) =>
        matchBy(
          build,
          'source'
        )({
          experimentRun: ({ experimentRun: { id } }) => id,
          modelVersion: ({ modelVersion: { id } }) => id,
        })
      )
      .filter(isNotNil)
  );
};
