import { uniqBy } from 'ramda';

import { HttpActions } from 'services/HttpActions';
import {
  convertServerComment,
  ServerComment,
} from 'services/serverModel/Comments/converters';
import {
  convertServerUser,
  unknownUser,
} from 'services/serverModel/User/converters';
import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';
import { UserData } from 'shared/graphql/User/graphql-types/User.generated';
import { EntityId, IComment } from 'shared/models/Comment';

export default class CommentsService {
  static async addComment(
    currentUser: CurrentUserData,
    entityId: EntityId,
    message: string
  ): Promise<IComment> {
    const response = await HttpActions.post({
      url: '/v1/modeldb/comment/addExperimentRunComment',
      data: {
        message,
        entity_id: entityId,
      },
    });
    return convertServerComment(response.data.comment, currentUser);
  }

  static async deleteComment(entityId: EntityId, id: string): Promise<void> {
    await HttpActions.delete({
      url: '/v1/modeldb/comment/deleteExperimentRunComment',
      config: { params: { id, entity_id: entityId } },
    });
  }

  static async updateComment(
    entityId: EntityId,
    id: string,
    message: string
  ): Promise<void> {
    await HttpActions.post({
      url: '/v1/modeldb/comment/updateExperimentRunComment',
      data: {
        id,
        message,
        entity_id: entityId,
      },
    });
  }

  static async loadComments(entityId: string): Promise<IComment[]> {
    return HttpActions.get<{ comments?: Array<ServerComment> }>({
      url: `/v1/modeldb/comment/getExperimentRunComments`,
      config: { params: { entity_id: entityId } },
    }).then(({ data }) => {
      return Promise.all(
        uniqBy(({ verta_id }) => verta_id, data.comments || []).map(
          ({ verta_id }) => this.loadUser(verta_id)
        )
      ).then((users) => {
        return (data.comments || []).map((serverComment: any) => {
          return convertServerComment(
            serverComment,
            users.find((user) => user.id === serverComment.verta_id) ||
              unknownUser
          );
        });
      });
    });
  }

  private static async loadUser(id: string): Promise<UserData> {
    try {
      const response = await HttpActions.get({
        url: `/v1/uac-proxy/uac/getUser`,
        config: { params: { user_id: id } },
      });
      return convertServerUser(response.data);
    } catch {
      return unknownUser;
    }
  }
}
