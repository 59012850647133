import { QueryHookOptions } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';

import {
  ORGANIZATION_CUSTOM_ATTRIBUTES_QUERY,
  OrganizationCustomAttributes,
  OrganizationCustomAttributesVariables,
} from '../graphql';

const convertResponse = ({
  organizationV2ByName,
  workspaces,
}: OrganizationCustomAttributes) =>
  mapDataOrError(organizationV2ByName, (organization) => ({
    organization,
    workspaces,
  }));

export const useOrganizationCustomAttributes = (
  {
    organizationName,
  }: {
    organizationName: string;
  },
  options?: QueryHookOptions<
    OrganizationCustomAttributes,
    OrganizationCustomAttributesVariables
  >
) => {
  const query = useCustomQuery<
    OrganizationCustomAttributes,
    OrganizationCustomAttributesVariables
  >(ORGANIZATION_CUSTOM_ATTRIBUTES_QUERY, {
    ...options,
    variables: { organizationName },
  });

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return { data, communication, reload: query.refetch };
};
