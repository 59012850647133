import { Box, Stack, styled, Typography } from '@mui/material';
import { alpha } from '@mui/material';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';

import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { docsLinks } from 'shared/utils/globalConstants/vertaDocLinks';
import LearnHowLinkButton from 'shared/view/elements/LearnHowLinkButton/LearnHowLinkButton';

export const EmptyGridContainer = styled(Box)(({ theme }) => ({
  height: 480,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.additionalBackground.codeBlock,
  border: `1px dashed ${alpha('#000', 0.12)}`,
}));

const EmptyList = () => (
  <Stack textAlign="center" spacing={4}>
    <Box sx={{ color: 'additionalBackground.inactive' }}>
      <IconAwesomeCustomizable icon={ICONS.emptyTiles} size="5x" />
    </Box>

    <Stack spacing={1}>
      <Box width={256} margin="auto">
        <Typography variant="body2" color="text.secondary">
          No models registered.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Start registering models to your catalog.
        </Typography>
      </Box>
      <Box>
        <LearnHowLinkButton docLink={docsLinks.easySteps.registry} />
      </Box>
    </Stack>
  </Stack>
);

export const EmptyFilteredList = ({
  message,
  icon = ICONS.magnifyingGlass,
  iconSize = '5x',
  textWidth = 280,
}: {
  message?: string;
  icon?: IconDefinition | false;
  iconSize?: SizeProp;
  textWidth?: number;
}) => (
  <Stack textAlign="center" spacing={3}>
    {typeof icon === 'object' ? (
      <Box sx={{ color: 'additionalBackground.inactive' }}>
        <IconAwesomeCustomizable icon={icon} size={iconSize} />
      </Box>
    ) : null}
    <Box width={textWidth} margin="auto">
      <Typography variant="body2" color="text.secondary">
        {message ||
          `We couldn't find any models with the specified search or filter
        criteria.`}
      </Typography>
    </Box>
  </Stack>
);

const EmptyGrid = (props: { isFiltering: boolean }) => {
  return (
    <EmptyGridContainer>
      {props.isFiltering ? <EmptyFilteredList /> : <EmptyList />}
    </EmptyGridContainer>
  );
};

export default EmptyGrid;
