import {
  DataGridPro,
  DataGridProProps,
  GridDensity,
  GridInitialState,
  GridPaginationModel,
  GridRowOrderChangeParams,
  LicenseInfo,
} from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import { CSSProperties, MutableRefObject, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { Box } from '@mui/material';

import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { parseAdditionalConfigurationProps } from 'shared/view/elements/DataGrid/configuration/helpers/parseAdditionalConfigurationProps';
import { selectFlags } from 'features/flags';
import { DEFAULT_PAGE_SIZE, IPagination } from 'shared/models/Pagination';
import { ICommunication } from 'shared/utils/redux/communication';
import { matchRemoteData } from 'shared/utils/redux/communication/remoteData';
import InlineCommunicationError from 'shared/view/elements/Errors/InlineCommunicationError/InlineCommunicationError';
import matchType from 'shared/utils/matchType';
import { PartialUnion } from 'shared/utils/types';

import {
  DataGridSelectionProps,
  useDataGridSelectionProps,
} from './hooks/useDataGridSelectionProps';
import {
  DataGridHighlightRowsProps,
  useDataGridHighlightRowsProps,
} from './hooks/useDataGridHighlightRows';
import {
  DataGridToolbarProps,
  useDataGridToolbarProps,
  useDataGridNoRowsOverlay,
} from './hooks/useDataGridToolbarProps';

type DataWithID<Data> = Data & {
  id: string;
};

// ts-unused-exports:disable-next-line
export type GridHeightType =
  | 'pageHeight'
  | 'parentHeight'
  | 'autoHeightButMax5Rows';

export type DataGridPivotProps<Data> =
  | {
      pivotEnabled?: undefined;
      columns: DataGridColumn<Data>[];
    }
  | {
      pivotEnabled: true;
      columns: DataGridColumn<Partial<Data>>[];
    };

export type DataGridSortProps = PartialUnion<{
  sortModel: DataGridProProps['sortModel'];
  sortingMode: DataGridProProps['sortingMode'];
  onSortModelChange: DataGridProProps['onSortModelChange'];
}>;

type Props<Data> = {
  density?: GridDensity;
  customRowHeight?: number;
  rows: DataWithID<Data>[] | undefined | null;
  heightType: GridHeightType;
  initialState?: GridInitialState;
  getRowClassName?: DataGridProProps['getRowClassName'];
  getRowHeight?: DataGridProProps['getRowHeight'];
  apiRef?: MutableRefObject<GridApiPro> | undefined;
  customNoRowsOverlay?: string;
  withFooter?: boolean;
  slotProps?: DataGridProProps['slotProps'];
  disableVirtualization?: boolean;
  loading?: boolean;
} & PartialUnion<{
  pagination: IPagination;
  onPageChange(newPage: number): void;
  onPageSizeChange?(newPageSize: number): void;
  pageSizeOptions?: number[];
}> &
  PartialUnion<{
    communication: ICommunication;
    context: string;
  }> &
  PartialUnion<{
    columnVisibilityModel: DataGridProProps['columnVisibilityModel'];
    onColumnVisibilityModelChange: DataGridProProps['onColumnVisibilityModelChange'];
  }> &
  PartialUnion<{
    pinnedColumns: DataGridProProps['pinnedColumns'];
    onPinnedColumnsChange: DataGridProProps['onPinnedColumnsChange'];
  }> &
  PartialUnion<{
    rowReordering: DataGridProProps['rowReordering'];
    onRowOrderChange: (params: GridRowOrderChangeParams) => void;
  }> &
  DataGridSortProps &
  DataGridToolbarProps &
  DataGridPivotProps<Data> &
  DataGridSelectionProps &
  DataGridHighlightRowsProps;

const getHeights = (
  heightType: GridHeightType,
  density: GridDensity,
  withFooter: boolean | undefined
) =>
  density === 'compact'
    ? {
        row: 35,
        header: 35,
        footer: 0,
      }
    : {
        row: 50,
        header: 50,
        footer:
          (heightType === 'autoHeightButMax5Rows' ||
            heightType === 'parentHeight') &&
          withFooter !== true
            ? 0
            : 55,
      };
const SCROLL_HEIGHT = 15;
const EMPTY_GRID_HEIGHT_IN_ROWS = 3;
const COMPARABLE_TABLE_TOOLBAR_HEIGHT = 36;

const calculateGridHeight = ({
  rowsCount,
  additionalElementsHeight,
  density,
  heightType,
  withFooter,
}: {
  rowsCount: number;
  additionalElementsHeight: number;
  density: GridDensity;
  heightType: GridHeightType;
  withFooter: boolean | undefined;
}) => {
  const heights = getHeights(heightType, density, withFooter);
  return (
    rowsCount * heights.row +
    heights.header +
    heights.footer +
    SCROLL_HEIGHT +
    additionalElementsHeight
  );
};

const makeGridContainerStyle = ({
  heightType,
  maxRowsPerPage,
  rowsCount,
  density,
  additionalElementsHeight,
  withFooter,
}: {
  heightType: GridHeightType;
  maxRowsPerPage: number;
  rowsCount: number;
  density: GridDensity;
  additionalElementsHeight: number;
  withFooter: boolean | undefined;
}): CSSProperties =>
  matchType<GridHeightType, CSSProperties>(
    {
      pageHeight: () => ({
        height: calculateGridHeight({
          rowsCount: maxRowsPerPage,
          additionalElementsHeight,
          density,
          heightType,
          withFooter,
        }),
        maxHeight: '70vh',
      }),
      parentHeight: () => ({ height: '100%' }),
      autoHeightButMax5Rows: () => ({
        height: calculateGridHeight({
          rowsCount: rowsCount === 0 ? EMPTY_GRID_HEIGHT_IN_ROWS : rowsCount,
          additionalElementsHeight,
          density,
          heightType,
          withFooter,
        }),
        maxHeight: calculateGridHeight({
          rowsCount: 5,
          additionalElementsHeight,
          density,
          heightType,
          withFooter,
        }),
      }),
    },
    heightType
  );

// todo update DataGrid version from beta to latest
export function DataGridWithTypes<Data>(props: Props<Data>) {
  const density = props.density || 'standard';
  const { muiDataGridLicense } = useSelector(selectFlags);
  if (muiDataGridLicense) {
    LicenseInfo.setLicenseKey(muiDataGridLicense);
  }

  const columns = useMemo(
    () => props.columns.map(parseAdditionalConfigurationProps),
    [props.columns]
  );

  const paginationProps = getPaginationProps({
    pagination: props.pagination,
    onPageChange: props.onPageChange,
    onPageSizeChange: props.onPageSizeChange,
    pageSizeOptions: props.pageSizeOptions,
  });

  const loadingProps = getLoadingProps({
    rows: props.rows,
    communication: props.communication,
    context: props.context,
  });

  const selectionProps = useDataGridSelectionProps(props);

  const highlightRowsProps = useDataGridHighlightRowsProps(props);

  const toolbarProps = useDataGridToolbarProps(props);

  const noRowsOverlayProps = useDataGridNoRowsOverlay(
    props.customNoRowsOverlay
  );

  const heights = getHeights(props.heightType, density, props.withFooter);

  const commonProps: DataGridProProps = {
    ...props,
    ...paginationProps,
    ...loadingProps,
    ...selectionProps,
    ...highlightRowsProps,
    columns,
    slots: {
      ...toolbarProps,
      ...loadingProps.slots,
      ...noRowsOverlayProps,
    },
    autoHeight: false,
    density,
    columnHeaderHeight: heights.header,
    rowHeight: props.customRowHeight ?? heights.row,
    hideFooter: heights.footer === 0,
    disableVirtualization: props.disableVirtualization,
  };

  return (
    <Box
      sx={{
        ...makeGridContainerStyle({
          heightType: props.heightType,
          rowsCount: props.rows?.length ?? 0,
          maxRowsPerPage: props.pagination?.pageSize ?? DEFAULT_PAGE_SIZE,
          density,
          withFooter: props.withFooter,
          additionalElementsHeight: props.toolbar
            ? COMPARABLE_TABLE_TOOLBAR_HEIGHT
            : 0,
        }),
        '& .MuiSelect-icon': { right: 0 },
      }}
      data-test="data-grid"
    >
      {props.communication?.error ? (
        <InlineCommunicationError error={props.communication.error} />
      ) : (
        <DataGridPro {...commonProps} />
      )}
    </Box>
  );
}

function getPaginationProps(props: {
  pagination?: IPagination;
  onPageChange?(newPage: number): void;
  onPageSizeChange?(newPageSize: number): void;
  pageSizeOptions?: number[];
}) {
  if (props.pagination && props.onPageChange) {
    const handlePaginationModelChange = ({
      page,
      pageSize,
    }: GridPaginationModel) => {
      props.onPageChange?.(page);
      props.onPageSizeChange?.(pageSize);
    };
    return {
      page: props.pagination.currentPage,
      onPageChange: props.onPageChange,
      pageSize: props.pagination.pageSize,
      rowCount: props.pagination.totalCount,
      onPageSizeChange: props.onPageSizeChange,
      pageSizeOptions: props.pageSizeOptions || [props.pagination.pageSize],
      paginationMode: 'server' as const,
      pagination: true,
      paginationModel: {
        page: props.pagination.currentPage,
        pageSize: props.pagination.pageSize,
      },
      onPaginationModelChange: handlePaginationModelChange,
    };
  }
  return {
    pagination: false,
  };
}

function getLoadingProps<Data>({
  rows,
  communication,
  context,
}: {
  rows: DataWithID<Data>[] | undefined | null;
  communication?: ICommunication;
  context?: string;
}) {
  const defaultRows = rows ?? [];
  return communication
    ? matchRemoteData(communication, rows, {
        notAsked: (): Pick<DataGridProProps, 'rows' | 'loading' | 'slots'> => ({
          rows: defaultRows,
        }),
        requesting: () => ({
          rows: defaultRows,
          loading: true,
        }),
        success: (loadedRows) => ({
          rows: loadedRows,
        }),
        error: (errorData) => ({
          rows: errorData.data ?? [],
          error: errorData.error,
          context,
        }),
      })
    : { rows: defaultRows };
}
