import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import routes from 'shared/routes';

const LoginProblemPage = () => {
  const location = useLocation();
  const queryParams = routes.loginProblem.parseQueryParams(location.search);

  return (
    <Navigate
      to={routes.login.getRedirectPathWithQueryParams({
        params: {},
        queryParams: {
          ...queryParams,
        },
      })}
    />
  );
};

export default LoginProblemPage;
