import { PopupStateProps } from 'shared/view/elements/Popup/PopupButtons';

import { OrganizationGroups } from '../store/useOrganizationGroups';
import { useCreateGroup } from '../store/useCreateGroup';
import GroupPopup from './GroupPopup';

export const CreateGroupPopup = (
  props: {
    organization: {
      id: string;
      users: OrganizationGroups['users'];
    };
  } & PopupStateProps
) => {
  const createGroupApi = useCreateGroup(props.onClose);

  return (
    <GroupPopup
      title="Create new group"
      {...props}
      users={props.organization.users.users}
      builtIn={false}
      submitButton={{
        children: 'Create',
        isLoading: createGroupApi.communication.isRequesting,
        onSubmit: (form) =>
          createGroupApi.run({
            ...form,
            organizationId: props.organization.id,
          }),
      }}
      initialSettings={{
        description: '',
        name: '',
        memberIds: [],
      }}
    />
  );
};
