import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';
import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';
import matchType from 'shared/utils/matchType';

import { MonitoringWidget } from './MonitoringWidget';
import { MonitoredModelType } from '../../MonitoredModelType';
import TimeSeriesSVG from './img/timeseries_chart.svg?react';
import SingleMetricsSVG from './img/single_metrics_chart.svg?react';
import ScatterPlotSVG from './img/scatter_plot_chart.svg?react';
import HistogramSVG from './img/histogram_chart.svg?react';
import CurvesSVG from './img/curves_chart.svg?react';
import ConfusionMatrixSVG from './img/confusion_matrix_chart.svg?react';
import BarChartSVG from './img/bar_chart.svg?react';
import TableSVG from './img/table_icon.svg?react';

export type MonitoringWidgetType = MonitoringWidget['type'];

export type InitializedMonitoringWidgetType = Exclude<
  MonitoringWidget['type'],
  'notInitialized'
>;

const initializedMonitoringWidgetTypes =
  exhaustiveStringTuple<InitializedMonitoringWidgetType>()(
    'singleMetric',
    'timeSeries',
    'scatterPlot',
    'curve',
    'histograms',
    'barChart',
    'table',
    'confusionMatrix'
  );

export const getLabelByMonitoringWidgetType = (
  type: InitializedMonitoringWidgetType
) => {
  switch (type) {
    case 'singleMetric':
      return 'Single Metric';
    case 'timeSeries':
      return 'Time Series';
    case 'scatterPlot':
      return 'Scatter Plot';
    case 'confusionMatrix':
      return 'Confusion Matrix';
    case 'table':
      return 'Table';
    case 'histograms':
      return 'Histograms';
    case 'barChart':
      return 'Bar chart';
    case 'curve':
      return 'Curve chart';
    default:
      exhaustiveCheck(type);
  }
};

export const getDescriptionByMonitoringWidgetType = (
  type: InitializedMonitoringWidgetType
) => {
  switch (type) {
    case 'singleMetric':
      return 'Single metric or summary stat value chart.';
    case 'timeSeries':
      return 'Visualize series of data points for one or more variables in the x-axis (e.g. accuracy metric) against y-axis variable (typically time duration, step/epoch etc.).';
    case 'scatterPlot':
      return 'Plot a variable in the y-axis against another variable in the x-axis represented as data points to depict patterns and correlation between the variables. ';
    case 'confusionMatrix':
      return 'Tabular description of the performance of a classification model using correct and incorrect predictions.';
    case 'curve':
      return 'Plot and interpret the trade-off in performance for different threshold values (e.g. PR curve with recall (x-axis) vs precision (y-axis), ROC curve with false positive rate (x-axis) vs. true positive rate (y-axis).';
    case 'table':
      return 'A tabular view of features with statistics.';
    case 'histograms':
      return 'Summarized discrete or continuous data that are measured on an interval scale to depict distribution of data.';
    case 'barChart':
      return 'Visualize categorical data with rectangular bars with heights or lengths proportional to the values that they represent.';
    default:
      exhaustiveCheck(type);
  }
};

export const getImageByMonitoringWidgetType = (
  type: InitializedMonitoringWidgetType
) => {
  switch (type) {
    case 'singleMetric':
      return <SingleMetricsSVG />;
    case 'timeSeries':
      return <TimeSeriesSVG />;
    case 'scatterPlot':
      return <ScatterPlotSVG />;
    case 'confusionMatrix':
      return <ConfusionMatrixSVG />;
    case 'curve':
      return <CurvesSVG />;
    case 'table':
      return <TableSVG />;
    case 'histograms':
      return <HistogramSVG />;
    case 'barChart':
      return <BarChartSVG />;
    default:
      exhaustiveCheck(type);
  }
};

export const getAvailableWidgetTypesByMonitoredModelType = (
  monitoredModelType: MonitoredModelType
) => {
  return matchType(
    {
      CLASSIFICATION: () => initializedMonitoringWidgetTypes,
      REGRESSION: () =>
        initializedMonitoringWidgetTypes.filter(
          isAvailableWidgetTypeForRegressionMonitoredModel
        ),
    },
    monitoredModelType
  );
};

const isAvailableWidgetTypeForRegressionMonitoredModel = (
  widgetType: InitializedMonitoringWidgetType
) => {
  return matchType(
    {
      barChart: () => true,
      confusionMatrix: () => false,
      curve: () => false,
      histograms: () => true,
      scatterPlot: () => true,
      singleMetric: () => true,
      table: () => true,
      timeSeries: () => true,
    },
    widgetType
  );
};
