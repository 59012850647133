import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { ExtractByTypename } from 'shared/utils/types';

import * as Types from './graphql-types/useOrganizationRoles.generated';

export type OrganizationRolesV2 = ExtractByTypename<
  Types.OrganizationRolesV2['organizationV2ByName'],
  'OrganizationV2'
>;

export type Role = OrganizationRolesV2['roles'][0];
export type EnabledResourceActions =
  OrganizationRolesV2['enabledResourceActions'];

export { Types };

export const QUERY = gql`
  query OrganizationRolesV2($organizationName: String!) {
    organizationV2ByName(name: $organizationName) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        name
        enabledResourceActions {
          resourceType
          allowedActions
        }
        roles {
          __typename
          orgId
          id
          name
          description
          builtIn
          allowedActions {
            ...AllowedActionsData
          }
          resourceActions {
            resourceType
            allowedActions
          }
        }
      }
    }
  }
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

const useOrganizationRoles = (organizationName: string) => {
  const queryResult = useCustomQuery<
    Types.OrganizationRolesV2,
    Types.OrganizationRolesV2Variables
  >(QUERY, {
    variables: {
      organizationName,
    },
  });

  const communicationWithData = useMemoizedResultToCommunicationWithData({
    memoizedConvert: (x) => x.organizationV2ByName,
    queryResult,
  });

  return {
    communicationWithData,
  };
};

export default useOrganizationRoles;
