import moment from 'moment';

const formatDateString = 'MM/DD/yyyy';
export const formatDateTimeString = formatDateString.concat(', hh:mm a');

export const formatDate = (
  dt: Date | string | number | undefined,
  formatString = formatDateString
) => {
  if (dt === undefined) {
    return '';
  }

  return moment(Number(dt)).format(formatString);
};

export const formatDateWithTime = (dt: Date | string | number | undefined) => {
  return formatDate(dt, formatDateTimeString);
};
