import { useAppBreadcrumbs } from 'features/layout/view/Layout/AppBreadcrumbs';
import { useMonitoringModelContext } from 'features/monitoring/models/model/store/state/context';
import MonitoringOverview from 'features/monitoring/overview/view/MonitoringOverview/MonitoringOverview';

import { useMonitoringModelPageBreadcrumbs } from '../shared/useMonitoringModelPageBreadcrumbs';
import MonitoringOverviewPageLayout from './MonitoringOverviewPageLayout/MonitoringOverviewPageLayout';

const MonitoringOverviewPage = () => {
  const { model, updateModelState } = useMonitoringModelContext();

  const breadcrumbs = useAppBreadcrumbs(
    useMonitoringModelPageBreadcrumbs({
      modelName: model.name,
    })
  );

  return (
    <MonitoringOverviewPageLayout
      model={model}
      updateModelState={updateModelState}
      breadcrumbs={breadcrumbs}
    >
      <MonitoringOverview model={model} />
    </MonitoringOverviewPageLayout>
  );
};

export default MonitoringOverviewPage;
