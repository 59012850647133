import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';

import {
  alertConditionOperators,
  getLabelByAlertConditionOperator,
} from '../shared/AlertCondition';

interface Props {
  name: string;
}

const options = alertConditionOperators.map((operator) => ({
  label: getLabelByAlertConditionOperator(operator),
  value: operator,
}));

const AlertConditionOperatorSelect = (props: Props) => {
  return (
    <SelectField
      name={props.name}
      options={options}
      label={'Predicate operator'}
    />
  );
};

export default AlertConditionOperatorSelect;
