import { useMemo, useState } from 'react';
import {
  Box,
  BoxProps,
  Autocomplete as MuiAutocomplete,
  FormControl,
  Paper,
  Popper,
  TextField,
  styled,
} from '@mui/material';

import FormHelperText from 'shared/view/elements/FormHelperText/FormHelperText';
import { SelectIconComponent } from 'shared/view/elements/Selects/shared/view/SelectIconComponent';
import { getWidthsFromProps } from 'shared/utils/getWidthsFromProps';

import DynamicTypography from '../DynamicTypography/DynamicTypography';
import FieldBox from '../FieldBox/FieldBox';

// ts-unused-exports:disable-next-line
export interface AutocompleteProps
  extends Pick<BoxProps, 'maxWidth' | 'minWidth'> {
  value: string;
  options: IAutocompleteOption[] | IAutocompleteGroupedOptions;
  label: string;
  withoutError?: boolean;
  withoutLabelPadding?: boolean;
  disabled?: boolean;
  name: string;
  error?: string;
  onChange(value: string): void;
  onKeyUp?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onBlur?(e: React.FocusEvent): void;
  dataTestError?: string;
  isRequired?: boolean;
  creatable?: boolean;
}

// ts-unused-exports:disable-next-line
export type IAutocompleteOption = {
  value: string;
  label: string;
  disabled?: boolean;
};

type IAutocompleteGroupedOptions = {
  type: 'groupedOptions';
  groups: Array<{
    label: string;
    options: Array<IAutocompleteOption>;
  }>;
};

const Autocomplete = ({
  value,
  withoutError,
  withoutLabelPadding,
  isRequired,
  dataTestError,
  onChange,
  creatable = true,
  ...muiProps
}: AutocompleteProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const options: Array<IAutocompleteOption & { group?: string }> = useMemo(
    () =>
      'type' in muiProps.options
        ? muiProps.options.groups.flatMap((group) =>
            group.options.map((o) => ({ ...o, group: group.label }))
          )
        : muiProps.options,
    [muiProps.options]
  );
  const groupBy =
    'type' in muiProps.options
      ? (o: IAutocompleteOption & { group?: string }) => o.group || ''
      : undefined;

  const labelAppend = isRequired ? ' *' : '';
  const label = `${muiProps.label}${labelAppend}`;

  const handleInputOnChange = (newValue: string) => {
    const _value =
      options.find((opt) => opt.value === value)?.label ?? newValue;
    setInputValue(_value);
    if (creatable) onChange(_value);
  };
  return (
    <FieldBox
      {...getWidthsFromProps(muiProps)}
      pt={Boolean(withoutLabelPadding) ? 0 : 1}
    >
      <FormControl fullWidth error={Boolean(muiProps.error)} size={'small'}>
        <MuiAutocomplete
          {...muiProps}
          options={options}
          groupBy={groupBy}
          renderOption={defaultRenderAutocompleteOption}
          getOptionDisabled={(opt) => Boolean(opt.disabled)}
          freeSolo={true}
          forcePopupIcon
          inputValue={inputValue}
          disableClearable={true}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(muiProps.error)}
              label={label}
              name={muiProps.name}
              size="small"
            />
          )}
          onChange={(_, newValue) =>
            onChange(
              typeof newValue === 'string' ? newValue : newValue.value || ''
            )
          }
          onInputChange={(_, newValue) => handleInputOnChange(newValue)}
          popupIcon={<SelectIconComponent />}
        />
        {!withoutError ? (
          <FormHelperText
            error
            errorMessage={muiProps.error}
            dataTestError={dataTestError}
          />
        ) : null}
      </FormControl>
    </FieldBox>
  );
};

export const defaultRenderAutocompleteOption = (
  optionProps: React.HTMLAttributes<HTMLLIElement>,
  option: { label: string }
) => (
  <Box component="li" {...optionProps}>
    <DynamicTypography value={option.label} />
  </Box>
);

export const AutocompletePopper = styled(Popper, {
  name: 'MuiAutocomplete',
  slot: 'Popper',
  overridesResolver: (_props, styles) => styles.popper,
})(({ theme }) => ({
  zIndex: theme.zIndex.modal,
  position: 'absolute',
}));

export const AutocompletePaper = styled(Paper, {
  name: 'MuiAutocomplete',
  slot: 'Paper',
  overridesResolver: (_props, styles) => styles.paper,
})(({ theme }) => ({
  ...theme.typography.body2,
  overflow: 'auto',
}));

export const AutocompleteNoOptions = styled(Box, {
  name: 'MuiAutocomplete',
  slot: 'NoOptions',
  overridesResolver: (_props, styles) => styles.noOptions,
})(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: '14px 16px',
}));

export default Autocomplete;
