import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { useOrganizationsList } from 'features/organizations/organizations/store/useOrganizationsList';
import CreateOrganizationPopup from 'features/organizations/organizations/view/CreateOrganizationPopup';
import Organizations from 'features/organizations/organizations/view/Organizations';
import routes from 'shared/routes';
import Button from 'shared/view/elements/Button/Button';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';

import SystemAdminPagesLayout from '../shared/SystemAdminPagesLayout/SystemAdminPagesLayout';

const NewSystemAdminOrganizationsPage = () => {
  const breadcrumbs = useAppBreadcrumbs(
    useBreadcrumbItem({
      name: 'System admin',
      route: routes.systemAdmin.$.organizations,
    })
  );
  const organizationsApi = useOrganizationsList();

  return (
    <SystemAdminPagesLayout
      actions={[
        <ButtonWithPopup
          key={0}
          button={(props) => (
            <Button {...props} dataTest="create">
              Create
            </Button>
          )}
          popup={(props) => <CreateOrganizationPopup {...props} />}
        />,
      ]}
      breadcrumbs={breadcrumbs}
    >
      <Organizations {...organizationsApi} />
    </SystemAdminPagesLayout>
  );
};

const ButtonWithPopup = (props: {
  popup: (props: { isOpen: boolean; onClose(): void }) => React.ReactNode;
  button: (props: { isLoading: boolean; onClick(): void }) => React.ReactNode;
}) => {
  const popupApi = usePopupManager();
  return (
    <>
      {popupApi.isPopupOpen
        ? props.popup({
            isOpen: popupApi.isPopupOpen,
            onClose: popupApi.closePopup,
          })
        : null}
      {props.button({ isLoading: false, onClick: popupApi.openPopup })}
    </>
  );
};

export default NewSystemAdminOrganizationsPage;
