import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import checklistCreateRestrictGroup from '../images/checklist-create-restrict-group.png';

export const CreateChecklistRestrictGroup = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Create a new checklist
    </Typography>
    <Typography variant="body2" mb={2}>
      Each item is assigned a name, and you have the option to provide a
      description to remind everyone of the expectations. You can designate each
      item as required and request evidence to be provided, which can be in the
      form of text or numbers.
    </Typography>
    <Typography variant="body2" mb={3}>
      You can limit the ability to complete each item to a specific user group.
      For instance, you might grant only product managers the authority to
      schedule a release date.
    </Typography>
    <img
      src={checklistCreateRestrictGroup}
      width="90%"
      height="auto"
      alt="checklist create restrict group"
    />
  </WizardStepContent>
);
