import { pipe } from 'ramda';

import { makeReservedAttributeKey } from 'shared/models/ReservedAttribute';
import { IAttribute } from 'shared/models/Attribute';
import { RequiredExperimentRun } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';

import * as CrossRunDashboardForComparing from './CrossRunDashboardForComparing';

const attributeWithCrossRunDashboardForComparingKey = makeReservedAttributeKey(
  'dashboardForComparing'
);

const makeAttribute = (
  dashboard: CrossRunDashboardForComparing.CrossRunDashboardForComparing
): IAttribute<string> => ({
  key: attributeWithCrossRunDashboardForComparingKey,
  value: JSON.stringify(dashboard),
});

const getCrossRunDashboardForComparing = ({
  attributes,
}: {
  attributes: IAttribute[];
}): CrossRunDashboardForComparing.CrossRunDashboardForComparing | undefined => {
  const targetAttribute = attributes.find(
    ({ key }) => key === attributeWithCrossRunDashboardForComparingKey
  );
  return typeof targetAttribute?.value === 'string'
    ? (JSON.parse(
        targetAttribute.value
      ) as CrossRunDashboardForComparing.CrossRunDashboardForComparing)
    : undefined;
};

export const getCrossRunDashboardForComparingOrDefault = (
  project: {
    attributes: IAttribute[];
  },
  experimentRuns: RequiredExperimentRun[]
): CrossRunDashboardForComparing.CrossRunDashboardForComparing => {
  return pipe(
    () => getCrossRunDashboardForComparing(project),
    (x) =>
      x
        ? x
        : CrossRunDashboardForComparing.makeDefaultCrossRunDashboardForComparing(
            experimentRuns
          )
  )();
};

export const saveDashboardChangesAsAttribute = (
  updatedDashboard: CrossRunDashboardForComparing.CrossRunDashboardForComparing
) => makeAttribute(updatedDashboard);
