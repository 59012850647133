import { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { formatDateNumber } from 'shared/utils/charts/formatDateNumber';
import { withScientificNotationOrRounded } from 'shared/utils/formatters/number';
import { MonitoringAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import matchBy from 'shared/utils/matchBy';
import isNotNil from 'shared/utils/isNotNill';
import { checkIsAlertingByAlerter } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/Alerter';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

const ERROR_COLOR = 'error.main';

interface AlertDecorationValue {
  modelVersion: string;
  value: number;
}

const TooltipWithAlertDecorations = (props: {
  date: Date | undefined;
  alert: MonitoringAlert | undefined;
  values: Array<AlertDecorationValue>;
  label: string;
  children: ReactNode | undefined;
}) => {
  const isAlerting = props.values.some(
    (v) =>
      isNotNil(props.alert) &&
      checkIsAlertingByAlerter(v.value, props.alert.alerter)
  );

  return (
    <Stack spacing={0}>
      <Stack direction="row" justifyContent="flex-end">
        {isAlerting ? (
          <IconAwesomeInfo
            size="lg"
            infoType="error"
            icon={ICONS.circleExclamation}
          />
        ) : null}
      </Stack>

      {isNotNil(props.children) ? <div>{props.children}</div> : null}

      {props.date ? (
        <TooltipRecord label="Date">
          {formatDateNumber(props.date)}
        </TooltipRecord>
      ) : null}

      {props.values.map((v) => (
        <TooltipRecord
          key={v.modelVersion}
          label={`${props.label} (${v.modelVersion})`}
          color={
            props.alert &&
            checkIsAlertingByAlerter(v.value, props.alert.alerter)
              ? ERROR_COLOR
              : undefined
          }
        >
          {withScientificNotationOrRounded(v.value)}
        </TooltipRecord>
      ))}

      {isAlerting && props.alert
        ? matchBy(
            props.alert.alerter,
            'type'
          )({
            fixed: (alerter) => (
              <TooltipRecord label="Threshold" color={ERROR_COLOR}>
                {withScientificNotationOrRounded(alerter.threshold)}
              </TooltipRecord>
            ),
            range: (alerter) => (
              <>
                <TooltipRecord label="Upper bound" color={ERROR_COLOR}>
                  {withScientificNotationOrRounded(alerter.upperBound)}
                </TooltipRecord>
                <TooltipRecord label="Lower bound" color={ERROR_COLOR}>
                  {withScientificNotationOrRounded(alerter.lowerBound)}
                </TooltipRecord>
              </>
            ),
          })
        : null}
    </Stack>
  );
};

const TooltipRecord = (props: {
  label: string;
  color?: string;
  children: string | number;
}) => {
  return (
    <Typography
      color={props.color}
      variant="caption"
    >{`${props.label}: ${props.children}`}</Typography>
  );
};

export default TooltipWithAlertDecorations;
