import { useCallback } from 'react';

import TextInput from 'shared/view/elements/TextInput/TextInput';
import * as UsernameOrEmail from 'shared/models/UsernameOrEmail';
import { ICommunication } from 'shared/utils/redux/communication';
import { InviteFormField } from 'shared/view/elements/InvitePopup/useInvitePopup';
import Popup from 'shared/view/elements/Popup/Popup';

interface Props {
  popupKey: string;
  isOpen: boolean;
  onClose(): void;
  addUser(usernameOrEmail: string): void;
  addingUser: ICommunication;
  userEntityName: string;
  usernameOrEmailField: InviteFormField;
  setUsernameOrEmailField(value: InviteFormField): void;
}

export default function InvitePopup(props: Props) {
  const {
    isOpen,
    onClose,
    addUser,
    addingUser,
    userEntityName,
    popupKey,
    usernameOrEmailField,
    setUsernameOrEmailField,
  } = props;

  const addSelectedUser = useCallback(() => {
    addUser(usernameOrEmailField.value);
  }, [addUser, usernameOrEmailField.value]);

  return (
    <Popup
      key={popupKey}
      isOpen={isOpen}
      title={`Add new ${userEntityName}`}
      onClose={onClose}
      buttons={{
        mainButtonProps: {
          type: 'button',
          children: 'Add',
          dataTest: 'send-invitation-button',
          isLoading: addingUser.isRequesting,
          disabled:
            !usernameOrEmailField.value || Boolean(usernameOrEmailField.error),
          onClick: addSelectedUser,
        },
      }}
    >
      <TextInput
        isRequired={true}
        name="username-or-email-input"
        value={usernameOrEmailField.value}
        label="Username or email"
        onChange={(value) =>
          setUsernameOrEmailField({
            ...usernameOrEmailField,
            value,
            error: UsernameOrEmail.validate(value),
          })
        }
        onBlur={() =>
          setUsernameOrEmailField({
            ...usernameOrEmailField,
            touched: true,
          })
        }
        meta={usernameOrEmailField}
      />
    </Popup>
  );
}
