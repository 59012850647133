import React, { useCallback, useEffect, useState } from 'react';

import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import Popup from 'shared/view/elements/Popup/Popup';
import { WorkspaceName } from 'shared/models/Workspace';
import RecordsSection, {
  RecordInfo,
} from 'shared/view/elements/RecordsSection/RecordsSection';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import CodeBlock from 'shared/view/elements/CodeBlock/CodeBlock';

import { useBuildError } from '../../store/endpointErrors/buildError';
import styles from './BuildErrorPopup.module.css';
import { IErrorInfo } from '../../store/types';

interface ILocalProps {
  isOpen: boolean;
  errorInfo: IErrorInfo;
  workspaceName: WorkspaceName;
  organizationId: string | undefined;
  onClose(): void;
}

const BuildErrorPopup: React.FC<React.PropsWithChildren<ILocalProps>> = ({
  errorInfo,
  workspaceName,
  organizationId,
  onClose,
}) => {
  const isOpen = Boolean(errorInfo);
  const { data, loadingBuildError, loadBuildError } = useBuildError({
    errorInfo,
  });
  const [selectedErrorIndex, setSelectedErrorIndex] = useState(0);

  useEffect(() => {
    if (isOpen) {
      loadBuildError({
        workspaceName,
        id: errorInfo.endpointId,
        organizationId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, workspaceName, organizationId, errorInfo.endpointId]);

  const title = isNotNullableRestrictedGraphqlError(data)
    ? data[selectedErrorIndex].context
    : '...';
  const next = useCallback(() => {
    if (isNotNullableRestrictedGraphqlError(data) && data.length !== 0) {
      let newSelectedErrorIndex = selectedErrorIndex + 1;
      if (newSelectedErrorIndex > data.length - 1) {
        newSelectedErrorIndex = 0;
      }
      setSelectedErrorIndex(newSelectedErrorIndex);
    }
  }, [setSelectedErrorIndex, selectedErrorIndex, data]);

  return (
    <Popup title={title} isOpen={isOpen} onClose={onClose}>
      <DefaultMatchRemoteDataOrError
        communication={loadingBuildError}
        data={data}
        context="loading build error"
      >
        {(buildError) => {
          const viewData = buildError[selectedErrorIndex]?.message;
          return (
            <div className={styles.root}>
              {buildError.length && <button onClick={next}>next</button>}
              <RecordsSection>
                <RecordInfo label="Build id">{errorInfo.buildId}</RecordInfo>
              </RecordsSection>
              <div className={styles.message}>
                <CodeBlock withCopy>{viewData}</CodeBlock>
              </div>
            </div>
          );
        }}
      </DefaultMatchRemoteDataOrError>
    </Popup>
  );
};

export default BuildErrorPopup;
