import { useMemo } from 'react';
import { uniq } from 'ramda';

import { useOutlierDetectionQuery } from 'features/monitoring/widgets/store/outlierDetection/useOutlierDetection';
import CellRendererNumeric from 'shared/view/elements/DataGrid/columns/CellRendererNumeric';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import { OutlierDetectionTableWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/TableWidget';
import { typeSafeConfiguration } from 'shared/view/elements/DataGrid/configuration/helpers/typeSafeConfiguration';
import { OutlierDetectionQuery } from 'features/monitoring/widgets/store/outlierDetection/graphql-types/useOutlierDetection.generated';
import { getLabelByIOType } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import isNotNil from 'shared/utils/isNotNill';
import { ExtractByTypename } from 'shared/utils/types';

import { MonitoringWidgetProps } from '../../shared/types';
import { useIODescriptionDataGridSelection } from '../shared/useIODescriptionDataGridSelection';
import { useFeatureTableSortingProps } from '../shared/useFeatureTableSortingProps';
import { modelVersionColumn } from '../../../shared/modelVersionColumn';

const FEATURE_FIELD = 'Feature';

type OutlierDetection = ExtractByTypename<
  OutlierDetectionQuery['monitoredEntity'],
  'MonitoredEntity'
>['metrics']['outliers'];

const makeColumns = (
  showModelVersionColumn: boolean
): (DataGridColumn<OutlierDetection[0]> | null)[] => [
  {
    field: FEATURE_FIELD,
    minWidth: 120,
    flex: 2,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'string',
      (params) => params.row.name
    ),
    renderCell: (params) => <CellRendererString value={params.row.name} />,
  },
  {
    field: 'Type',
    minWidth: 100,
    flex: 1,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'string',
      (params) => getLabelByIOType(params.row.ioType)
    ),
    renderCell: (params) => (
      <CellRendererString value={getLabelByIOType(params.row.ioType)} />
    ),
  },
  showModelVersionColumn ? modelVersionColumn : null,
  {
    field: 'Outliers count',
    minWidth: 120,
    flex: 1,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'number',
      (params) => params.row.totalOutliers
    ),
    renderCell: (params) => (
      <CellRendererNumeric value={params.row.totalOutliers} />
    ),
  },
  {
    field: 'Std Dev',
    flex: 1,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'number',
      (params) => parseGraphQLNumber(params.row.sigma)
    ),
    renderCell: (params) => (
      <CellRendererNumeric value={parseGraphQLNumber(params.row.sigma)} />
    ),
  },
  {
    field: 'Median',
    flex: 1,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'number',
      (params) => parseGraphQLNumber(params.row.median)
    ),
    renderCell: (params) => (
      <CellRendererNumeric value={parseGraphQLNumber(params.row.median)} />
    ),
  },
  {
    field: 'Mean',
    flex: 1,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'number',
      (params) => parseGraphQLNumber(params.row.mean)
    ),
    renderCell: (params) => (
      <CellRendererNumeric value={parseGraphQLNumber(params.row.mean)} />
    ),
  },
  {
    field: 'Min',
    flex: 1,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'number',
      (params) => parseGraphQLNumber(params.row.min)
    ),
    renderCell: (params) => (
      <CellRendererNumeric value={parseGraphQLNumber(params.row.min)} />
    ),
  },
  {
    field: 'Max',
    flex: 1,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'number',
      (params) => parseGraphQLNumber(params.row.max)
    ),
    renderCell: (params) => (
      <CellRendererNumeric value={parseGraphQLNumber(params.row.max)} />
    ),
  },
];

const OutlierDetectionTableWidgetView = (
  props: MonitoringWidgetProps<OutlierDetectionTableWidget>
) => {
  const { communication, data } = useOutlierDetectionQuery({
    widgetExternalDeps: props.widgetExternalDeps,
  });

  const dataWithIds = useMemo(
    () =>
      (isNotNullableRestrictedGraphqlError(data) ? data : []).map((d) => ({
        ...d,
        id: d.ioType + d.name,
      })),
    [data]
  );

  const selectionApi = useIODescriptionDataGridSelection({
    ioDescriptions: props.widgetExternalDeps.ioDescriptions,
    changeIODescription: props.widget.changeIODescription,
    ioDescription: props.widget.ioDescription,
    rows: dataWithIds,
  });

  const sortingProps = useFeatureTableSortingProps({
    featureField: FEATURE_FIELD,
    changeIODescription: props.widget.changeIODescription,
    ioDescriptions: props.widgetExternalDeps.ioDescriptions,
  });

  const modelVersionIds = useMemo(
    () => uniq(dataWithIds.map((d) => d.modelVersionId)),
    [dataWithIds]
  );

  const columns = useMemo(
    () => makeColumns(modelVersionIds.length > 1).filter(isNotNil),
    [modelVersionIds]
  );

  return (
    <div style={props.size}>
      <DataGridWithTypes
        columns={columns}
        rows={dataWithIds}
        communication={communication}
        context="loading outlier detection"
        heightType="parentHeight"
        {...selectionApi}
        {...sortingProps}
      />
    </div>
  );
};

export default OutlierDetectionTableWidgetView;
