import { gql, QueryHookOptions } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';
import { CHECKLIST_TEMPLATE_FRAGMENT } from 'shared/graphql/Checklist/ChecklistTemplate';
import { CHECKLIST_TEMPLATE_ITEM_FRAGMENT } from 'shared/graphql/Checklist/ChecklistTemplateItem';

import {
  LoadChecklistTemplates,
  LoadChecklistTemplatesVariables,
} from './graphql-types/index.generated';

export type { LoadChecklistTemplates, LoadChecklistTemplatesVariables };

const LOAD_CHECKLIST_TEMPLATES = gql`
  query LoadChecklistTemplates(
    $workspaceName: String!
    $organizationId: String!
  ) {
    checklistTemplates(
      query: { workspaceName: $workspaceName, organizationId: $organizationId }
    ) {
      checklistTemplates {
        id
        ...ChecklistTemplateData
        checklistTemplateItems {
          id
          ...ChecklistTemplateItemData
        }
      }
    }
  }
  ${CHECKLIST_TEMPLATE_FRAGMENT}
  ${CHECKLIST_TEMPLATE_ITEM_FRAGMENT}
`;

type Props = {
  workspaceName: string;
  organizationId: string;
};

export const useLoadChecklistTemplates = (
  props: Props,
  options?: QueryHookOptions<
    LoadChecklistTemplates,
    LoadChecklistTemplatesVariables
  >
) => {
  const { workspaceName, organizationId } = props;

  const query = useCustomQuery<
    LoadChecklistTemplates,
    LoadChecklistTemplatesVariables
  >(LOAD_CHECKLIST_TEMPLATES, {
    ...options,
    variables: {
      workspaceName,
      organizationId,
    },
  });

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return { data, communication };
};

const convertResponse = ({ checklistTemplates }: LoadChecklistTemplates) =>
  mapDataOrError(
    checklistTemplates,
    (response) => response.checklistTemplates || []
  );
