import * as React from 'react';

const usePromptBeforeLeavingSite = (
  isNeedToShowPrompt: boolean,
  message: string
) => {
  React.useEffect(() => {
    if (isNeedToShowPrompt) {
      const handler = function (e: BeforeUnloadEvent | undefined) {
        if (e) {
          e.returnValue = message;
        } else if (window.event) {
          window.event.returnValue = Boolean(message);
        }
        return message; //Gecko + Webkit, Safari, Chrome etc.
      };
      window.addEventListener('beforeunload', handler);
      return () => {
        window.removeEventListener('beforeunload', handler);
      };
    }
  }, [isNeedToShowPrompt, message]);
};

export default usePromptBeforeLeavingSite;
