import { gql } from '@apollo/client';

import {
  ALERT_FRAGMENT,
  convertAlert,
} from 'shared/graphql/monitoring/Alert/Alert';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';

import {
  AlertQuery,
  AlertQueryVariables,
} from './graphql-types/useAlert.generated';

const ALERT = gql`
  query AlertQuery($alertId: ID!) {
    alert(id: $alertId) {
      ... on Error {
        ...ErrorData
      }
      ... on Alert {
        id
        ...AlertFragment
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ALERT_FRAGMENT}
`;

export const useAlert = (props: { alertId: string }) => {
  const queryResult = useCustomQuery<AlertQuery, AlertQueryVariables>(ALERT, {
    variables: { alertId: props.alertId },
  });

  const { communication: loadingAlert, data: alert } =
    useMemoizedResultToCommunicationWithData({
      queryResult,
      memoizedConvert: convert,
    });

  return {
    loadingAlert,
    alert,
  };
};

const convert = (res: AlertQuery) => {
  return mapDataOrError(res.alert, convertAlert);
};
