import { TimeSeriesWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/TimeSeriesWidget';
import matchBy from 'shared/utils/matchBy';

import { MonitoringWidgetProps } from '../shared/types';
import TimeSeriesDriftWidgetView from './TimeSeriesDriftWidget';
import TimeSeriesMetricWidget from './TimeSeriesMetricWidget';

const TimeSeriesMetricWidgetView = (
  props: MonitoringWidgetProps<TimeSeriesWidget>
) => {
  return matchBy(
    props.widget.variant,
    'type'
  )({
    metric: (variant) => (
      <TimeSeriesMetricWidget
        {...props}
        widget={{ ...props.widget, variant }}
      />
    ),
    drift: (variant) => (
      <TimeSeriesDriftWidgetView
        {...props}
        widget={{ ...props.widget, variant }}
      />
    ),
  });
};

export default TimeSeriesMetricWidgetView;
