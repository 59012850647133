import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';

import ProjectsPage from './ProjectsPage/ProjectsPage';
import { projectDetailsPages } from './ProjectDetailsPages';

export const projectsPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.projects.getPath(),
      element: <ProjectsPage />,
    },
    ...projectDetailsPages.getRoutes(),
  ],
};
