import Box from '@mui/material/Box';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { IWorkspace } from 'shared/models/Workspace';
import { IChangeWorkspaceByUIParams } from 'features/workspaces';
import { getUrlWithChangedWorkspace } from 'shared/routes/shared/getUrlWithChangedWorkspace';
import { ICommunication } from 'shared/utils/redux/communication';
import { ButtonMenuItem } from 'shared/view/elements/Menu/MenuItem';
import DefaultMatchRemoteMutation from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteMutation';

import { DefaultWorkspaceSelector } from './DefaultWorkspaceSelector/DefaultWorkspaceSelector';

interface ILocalProps {
  workspace: IWorkspace & { displayName: string };
  isActive: boolean;
  isDefaultWorkspace: boolean;
  onChangeWorkspace: (params: IChangeWorkspaceByUIParams) => void;
  changingDefaultWorkspace: ICommunication;
  onChangeDefaultWorkspace: (workspace: IWorkspace, redirectTo: string) => void;
  isDefaultWorkspaceSelector?: boolean;
}

type AllProps = ILocalProps;

const WorkspaceMenuItem = React.forwardRef<any, AllProps>(
  (
    {
      workspace,
      isActive,
      isDefaultWorkspace,
      onChangeWorkspace,
      onChangeDefaultWorkspace,
      changingDefaultWorkspace,
      isDefaultWorkspaceSelector,
    },
    ref
  ) => {
    const { pathname } = useLocation();
    const workspacePath = useMemo(() => {
      return getUrlWithChangedWorkspace({
        workspaceName: workspace.name,
        pathname,
      });
    }, [pathname, workspace.name]);

    const handleWorkspaceSelector = (
      event: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      if (isDefaultWorkspaceSelector) {
        event.preventDefault();
        event.stopPropagation();
        onChangeDefaultWorkspace(workspace, workspacePath);
      } else {
        onChangeWorkspace({
          event,
          workspace,
          to: workspacePath,
        });
      }
    };

    return (
      <ButtonMenuItem
        sx={{
          '&:hover .default-workspace-selector': {
            opacity: 1,
          },
        }}
        variant="body2"
        data-is-current={isActive}
        dataTest="workspace-menu-item"
        onClick={(event) => handleWorkspaceSelector(event)}
        rightContent={
          <Box
            ref={ref}
            className={'default-workspace-selector'}
            pl={1}
            sx={{
              opacity: isDefaultWorkspace ? 1 : 0,
            }}
          >
            {isDefaultWorkspaceSelector ? (
              <DefaultMatchRemoteMutation
                communication={changingDefaultWorkspace}
              >
                <></>
              </DefaultMatchRemoteMutation>
            ) : (
              <DefaultWorkspaceSelector
                isActive={isDefaultWorkspace}
                workspace={workspace}
                changing={changingDefaultWorkspace}
                onChange={(defaultWorkspace, e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onChangeDefaultWorkspace(defaultWorkspace, workspacePath);
                }}
              />
            )}
          </Box>
        }
        textColorizationStyle={{ fontWeight: isActive ? 'bold' : undefined }}
      >
        {workspace.displayName}
      </ButtonMenuItem>
    );
  }
);

export default React.memo(WorkspaceMenuItem);
