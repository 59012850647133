import 'normalize.css';
import { ApolloProvider } from '@apollo/client';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { useMemo } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import { selectIsEnableDarkMode } from 'features/darkMode';
import DarkMuiTheme from 'shared/mui/themes/DarkMuiTheme';
import cleanLocalStorageForNewVersion from 'shared/utils/cleanLocalStorageForNewVersion';
import DarkModeProvider from 'features/darkMode/view/DarkModeProvider/DarkModeProvider';
import MainMuiTheme from 'shared/mui/themes/MainMuiTheme';
import setup from 'setup/setup';
import { anonymousPages, authorizedPages, nestedAuthorizedPages } from 'pages';
import GlobalPreloader from 'shared/view/elements/GlobalPreloader/GlobalPreloader';

import App from './setup/app/App';

import './index.css';

const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)([
  {
    path: '/',
    element: <App />,
    children: [...anonymousPages, ...authorizedPages, ...nestedAuthorizedPages],
  },
]);

const ThemedContent = () => {
  const darkMode = useSelector(selectIsEnableDarkMode);

  const theme = useMemo(
    () => (darkMode ? DarkMuiTheme : MainMuiTheme),
    [darkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <DarkModeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <RouterProvider
            router={router}
            fallbackElement={<GlobalPreloader />}
          />
        </LocalizationProvider>
      </DarkModeProvider>
    </ThemeProvider>
  );
};

const localStorageVersion = '1.0.28';
cleanLocalStorageForNewVersion(localStorageVersion);

const { store, apolloClient } = setup({ router });

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('Failed to find the root element');

const root = createRoot(rootElement);

root.render(
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <ThemedContent />
    </Provider>
  </ApolloProvider>
);
