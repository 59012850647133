import { gql } from '@apollo/client';

import { useLoadUserWorkspaces } from 'features/organizations/hooks/useLoadUserWorkspaces';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { combineCommunications } from 'shared/utils/redux/communication/combineCommunications';

import * as Types from './graphql-types/useDeleteWorkspace.generated';

export { Types };

export const QUERY = gql`
  mutation DeleteWorkspaceToOrganizationV2(
    $organizationId: ID!
    $workspaceId: ID!
  ) {
    organizationV2(id: $organizationId) {
      ... on OrganizationV2 {
        id
        workspace(id: $workspaceId) {
          id
          delete {
            ... on OrganizationV2 {
              id
              workspaces {
                orgId
                id
                name
                description
                createdTimestamp
                updatedTimestamp
                builtIn
                namespace
                allowedActions {
                  ...AllowedActionsData
                }
                permissions {
                  role {
                    id
                    name
                    description
                    builtIn
                  }
                  group {
                    id
                    name
                    description
                    builtIn
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${ALLOWED_ACTIONS_FRAGMENT}
`;

export const useDeleteWorkspace = () => {
  const [run, communication] = useCustomMutation<
    Types.DeleteWorkspaceToOrganizationV2,
    Types.DeleteWorkspaceToOrganizationV2Variables
  >(
    QUERY,
    {
      context: 'deleting workspace',
    },
    {
      onCompleted: () => loadUserWorkspacesApi.load(),
    }
  );

  const loadUserWorkspacesApi = useLoadUserWorkspaces();

  return {
    run,
    communication: combineCommunications([
      communication,
      loadUserWorkspacesApi.communication,
    ]),
  };
};
