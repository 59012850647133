import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import routes from 'shared/routes';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { useCatalog } from 'features/catalog/registeredModel/catalogList/store/catalog/catalog';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { TabsLayout } from 'pages/authorized/shared/TabsLayout';
import { useJobStatusPolling } from 'features/catalog/shared/hooks/useJobStatusPolling';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { selectCurrentWorkspace } from 'features/workspaces';

const Catalog = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'Catalog' */ 'features/catalog/registeredModel/catalogList/view/Catalog/Catalog'
    )
);

const CatalogPage = () => {
  const workspaceName: string = useWorkspaceNameFromURL();
  const { id: workspaceId } = useSelector(selectCurrentWorkspace);
  const organizationId = useCurrentOrganizationV2() || '';
  const catalogApi = useCatalog({ workspaceName });

  const breadcrumbs = useAppBreadcrumbs(
    useBreadcrumbItem({ name: 'Model Catalog', route: routes.catalog })
  );

  const { startPolling } = useJobStatusPolling({
    organizationId: organizationId,
    workspaceId: workspaceId,
    workspaceName: workspaceName,
  });

  useEffect(() => {
    startPolling();
  }, [startPolling]);

  return (
    <TabsLayout breadcrumbs={breadcrumbs} pageEntityOrError={catalogApi.data}>
      <Catalog {...catalogApi} />
    </TabsLayout>
  );
};

export default CatalogPage;
