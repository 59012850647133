import { URLWithPagination } from 'shared/models/Pagination/PaginationInURL';
import {
  param,
  queryParams,
  route,
  zeroOrMoreSegmentsParam,
} from 'shared/routes/shared/routesTreeBuilder';

const modelVersion = (() => {
  return param()('versionId', {
    tag: 'usecasesRegisteredModelVersion',
  })(
    route('summary', {
      tag: 'usecasesModelVersionSummary',
    })(),
    route('reproduce', {
      tag: 'usecasesModelVersionReproduce',
    })(),
    route('integrate', {
      tag: 'usecasesModelVersionIntegrate',
    })(),
    route('documentation', {
      tag: 'usecasesModelVersionDocumentation',
    })(),
    route('govern', {
      tag: 'usecasesModelVersionGovern',
    })(),
    route('release', {
      tag: 'usecasesModelVersionRelease',
    })(),
    route('workflows', {
      tag: 'usecasesModelVersionWorkflows',
    })(),
    route('fairness', {
      tag: 'usecasesFairnessPage',
    })()
  );
})();

const modelVersions = route('versions', {
  tag: 'usecasesModelVersions',
  queryParams: queryParams<{ experimentRunId: string }>(),
})(
  modelVersion,
  route('create', {
    tag: 'usecasesCreateRegisteredModelVersion',
    queryParams: queryParams<{ experimentRunId: string }>(),
  })(),
  route(
    'compare',
    undefined
  )(
    zeroOrMoreSegmentsParam()('ids', {
      tag: 'usecasesRegisteredModelVersionsCompare',
    })()
  )
);

export const privateRoutes = route('usecases', {
  tag: 'usecases',
  queryParams: queryParams<URLWithPagination>(),
})(
  route('create', {
    tag: 'usecasesCreateRegisteredModel',
  })(),
  param()('registeredModelId', {
    tag: 'usecasesRegisteredModel',
  })(
    route('overview', {
      tag: 'usecasesModelOverview',
    })(),
    route('documentation', {
      tag: 'usecasesModelDocumentation',
    })(),
    route('activities', {
      tag: 'usecasesModelActivities',
    })(),
    route('actions', {
      tag: 'usecasesModelActions',
    })(),
    modelVersions
  )
);
