import { isNonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';
import ConditionalWarningWrapper from 'shared/view/elements/ConditionalWarningWrapper/ConditionalWarningWrapper';
import { EndpointKafkaTopicFields } from 'features/deployment/canary/endpointSettings/view/EndpointKafkaTopicFields';

import { kafkaConfigurations as KafkaConfigurations } from '../hooks/graphql-types/useKafkaConfigurationTopics.generated';
import {
  EndpointKafkaChangeFieldKey,
  EndpointKafkaChangeFieldValue,
  EndpointKafkaFormValues,
} from '../store/types';

type Props = {
  changeField(
    fieldName: EndpointKafkaChangeFieldKey,
    value: EndpointKafkaChangeFieldValue
  ): void;
  values: EndpointKafkaFormValues;
  resetKafkaFields(fieldsToIgnore: EndpointKafkaChangeFieldKey[]): void;
  kafkaConfigurations: KafkaConfigurations;
};

export function EndpointKafkaClusterFields({
  changeField,
  values,
  kafkaConfigurations,
}: Props) {
  const topics = kafkaConfigurations.kafkaTopicsList.topics;
  return (
    <ConditionalWarningWrapper
      conditions={[topics.length > 0]}
      message={'There is no kafka topics. Please contact system administrator'}
    >
      {isNonEmptyArray(topics) ? (
        <EndpointKafkaTopicFields
          values={values}
          topics={topics}
          changeField={changeField}
          kafkaId={kafkaConfigurations.kafkaConfigurations.configurations[0].id}
        />
      ) : null}
    </ConditionalWarningWrapper>
  );
}
