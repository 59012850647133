import isNotNil from 'shared/utils/isNotNill';

import { Tab } from './PagesTabs';

export default function getTabs<Union extends string, Params = unknown>(
  routes: Record<
    Union,
    {
      getRedirectPath(params: Params): string;
    }
  >
) {
  return function <T extends Array<[Union, (u: Union) => string | null]>>(
    items: T,
    params: Params
  ): [Union] extends [T[number][0]]
    ? Tab[]
    : { __compileError: 'You don`t handle all cases' } {
    const cases: Array<{
      label: string;
      type: Union;
    }> = items
      .map(([type, f]) => {
        const label = f(type);
        return label ? { label, type } : undefined;
      })
      .filter(isNotNil);

    const result: Tab[] = cases.map((value) => {
      return {
        label: value.label,
        to: routes[value.type].getRedirectPath(params),
      };
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result as any;
  };
}
