import { useTheme } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { defaultStyles, TooltipWithBounds } from '@visx/tooltip';

const CustomTooltipWithBounds = (
  props: React.ComponentProps<typeof TooltipWithBounds>
) => {
  const theme = useTheme();
  return (
    <TooltipWithBounds
      {...props}
      style={{
        ...defaultStyles,
        padding: '12px 12px 12px 16px',
        background: theme.palette.background.default,
        border: `1px solid ${theme.palette.charts.accentColor}`,
        fontSize: '12px',
      }}
    />
  );
};

export default CustomTooltipWithBounds;
