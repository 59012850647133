import { MonitoringIODescription } from '../../../MonitoringIODescription';
import { BaseMonitoringWidget } from './shared/BaseMonitoringWidget';
import { BaseNotInitializedWidget } from './shared/BaseNotInitializedWidget';

interface BaseCurveChartWidget extends BaseMonitoringWidget {
  type: 'curve';
  output: MonitoringIODescription;
}

export interface RocWidget extends BaseCurveChartWidget {
  variant: 'roc';
}

export interface PrecisionRecallWidget extends BaseCurveChartWidget {
  variant: 'precisionRecall';
}

export type CurveChartWidget = RocWidget | PrecisionRecallWidget;

export type CurveChartWidgetVariant = CurveChartWidget['variant'];
export const defaultCurveChartWidgetVariant: CurveChartWidgetVariant = 'roc';

export type NotInitializedCurveChartWidget = BaseNotInitializedWidget<{
  type: CurveChartWidget['type'];
  variant: CurveChartWidget['variant'];
}>;
