import { MonitoringDriftMetricType } from 'generated/types';
import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';
import matchType from 'shared/utils/matchType';

export const monitoringDriftMetricTypes =
  exhaustiveStringTuple<MonitoringDriftMetricType>()(
    MonitoringDriftMetricType.COSINE_DISTANCE,
    MonitoringDriftMetricType.KL_DIVERGENCE
  );

export const defaultMonitoringDriftMetricType =
  MonitoringDriftMetricType.COSINE_DISTANCE;

export const getLabelByMonitoringDriftMetricType = (
  metric: MonitoringDriftMetricType
) => {
  return matchType(
    {
      [MonitoringDriftMetricType.COSINE_DISTANCE]: () => 'cosine-distance',
      [MonitoringDriftMetricType.KL_DIVERGENCE]: () => 'kl-divergence',
    },
    metric
  );
};
