const makeObserver = <Payload>() => {
  class Observer {
    private listeners: Array<(payload: Payload) => void> = [];

    subscribe = (f: (payload: Payload) => void) => {
      this.listeners.push(f);
    };

    unsubscribe = (f: (payload: Payload) => void) => {
      this.listeners = this.listeners.filter((x) => x !== f);
    };

    unsubscribeAll = () => {
      this.listeners = [];
    };

    notify = (payload: Payload) => {
      this.listeners.forEach((f) => f(payload));
    };
  }

  return new Observer();
};

export default makeObserver;
