import { FC, useCallback } from 'react';
import Stack from '@mui/material/Stack';

import CopyButton from 'shared/view/elements/CopyButton/CopyButton';
import { useRemoveAccessToken } from 'features/deployment/canary/accessTokensManager/store/accessTokensManager/accessTokensManager';
import { Token } from 'shared/models/Deployment/canary/Token';
import DefaultConfirmAction, {
  ConfirmType,
} from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import Preloader from 'shared/view/elements/Preloader/Preloader';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

interface Props {
  endpointId: string;
  environmentId: string;
  accessTokens: Token[];
  hasAccess: boolean;
  tokensEnabled: boolean;
  workspaceName: string;
}

const AccessTokens: FC<React.PropsWithChildren<Props>> = ({
  endpointId,
  environmentId,
  accessTokens,
  hasAccess,
  tokensEnabled,
  workspaceName,
}) => {
  return (
    <Stack direction="column">
      {accessTokens.map((accessToken) => (
        <AccessToken
          endpointId={endpointId}
          environmentId={environmentId}
          accessToken={accessToken}
          hasAccess={hasAccess}
          key={accessToken.id}
          tokensEnabled={tokensEnabled}
          workspaceName={workspaceName}
        />
      ))}
    </Stack>
  );
};

const AccessToken: FC<
  React.PropsWithChildren<{
    endpointId: string;
    environmentId: string;
    accessToken: Token;
    hasAccess: boolean;
    tokensEnabled: boolean;
    workspaceName: string;
  }>
> = ({
  endpointId,
  environmentId,
  accessToken,
  hasAccess,
  tokensEnabled,
  workspaceName,
}) => {
  const organizationId = useCurrentOrganizationV2();
  const [removeAccessToken, removingAccessToken] = useRemoveAccessToken();

  const handleRemove = useCallback(() => {
    removeAccessToken({
      endpointId,
      environmentId,
      workspaceName,
      organizationId,
      tokenId: accessToken.id,
    });
  }, [
    organizationId,
    accessToken.id,
    endpointId,
    environmentId,
    removeAccessToken,
    workspaceName,
  ]);

  return (
    <Stack direction="row" alignItems="center" data-test="token">
      <DynamicTypography value={accessToken.value} dataTest="token-value" />
      <Stack direction="row" style={{ marginLeft: 'auto' }}>
        {removingAccessToken.isRequesting ? (
          <Preloader size="small" />
        ) : (
          <>
            <CopyButton copyText={accessToken.value} />
            {tokensEnabled ? (
              <DefaultConfirmAction
                description="Token"
                type={ConfirmType.delete}
                additionalText="Deleting this will remove access to this endpoint for all of this token’s users"
              >
                {(withConfirm) => (
                  <IconAwesomeClickable
                    disabled={!hasAccess}
                    icon={ICONS.trashCan}
                    dataTest="remove-token"
                    onClick={withConfirm(handleRemove)}
                  />
                )}
              </DefaultConfirmAction>
            ) : null}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default AccessTokens;
