import cn from 'classnames';

import { Size } from 'shared/utils/charts/chartSizes';
import matchType from 'shared/utils/matchType';
import WhenNotNil from 'shared/view/charts/WhenNotNil/WhenNotNil';

import styles from './SingleMetric.module.css';

interface Props {
  value: number;
  size: Size;
  color: 'green' | 'red';
}

const calculateContentFontSize = ({
  content,
  size,
}: {
  content: string;
  size: Size;
}) => {
  const FONT_SIZE_TO_HEIGHT_RATIO = 3;
  const fontSizeByHeight = size.height / FONT_SIZE_TO_HEIGHT_RATIO;
  const FONT_WIDTH_TO_FONT_HEIGHT_RATIO = 1.5;
  const fontSizeByWidth =
    (size.width / content.length) * FONT_WIDTH_TO_FONT_HEIGHT_RATIO;
  return Math.min(fontSizeByWidth, fontSizeByHeight);
};

const SingleMetric = (props: Props) => {
  return (
    <WhenNotNil
      width={props.size.width}
      height={props.size.height}
      isNil={isNaN(props.value)}
    >
      <div
        className={cn(
          styles.root,
          matchType(
            {
              green: () => styles.color_green,
              red: () => styles.color_red,
            },
            props.color
          )
        )}
        style={{
          minWidth: props.size.width,
          minHeight: props.size.height,
        }}
      >
        <div
          className={styles.value}
          style={{
            fontSize: calculateContentFontSize({
              size: props.size,
              content: props.value.toString(),
            }),
          }}
        >
          {props.value}
        </div>
      </div>
    </WhenNotNil>
  );
};

export default SingleMetric;
