import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';

import { useEditDatasetVersionDescriptionMutation } from 'features/datasetVersions/store/editDatasetVersionDescription/editDatasetVersionDescription';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import Popup from 'shared/view/elements/Popup/Popup';
import { PopupStateProps } from 'shared/view/elements/Popup/PopupButtons';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';

interface Props extends PopupStateProps {
  datasetVersion: { id: string; description: string };
}

type FormValues = {
  description: string;
};

const getFieldName = makeGetFieldName<FormValues>();

const DatasetVersionEditor = (props: Props) => {
  const initialValues: FormValues = useMemo(() => {
    return {
      description: props.datasetVersion.description,
    };
  }, [props.datasetVersion.description]);

  const [editDescription, editingDescription] =
    useEditDatasetVersionDescriptionMutation({
      onCompleted: props.onClose,
    });

  return (
    <PresetFormik
      initialValues={initialValues}
      onSubmit={(form) => {
        editDescription({
          description: form.description,
          id: props.datasetVersion.id,
        });
      }}
    >
      {({ submitForm }) => (
        <Popup
          {...props}
          title="Edit model information"
          maxWidth="xs"
          fullWidth
          dataTest="model-editor-popup"
          buttons={{
            mainButtonProps: {
              type: 'button',
              dataTest: 'confirm',
              children: 'Save',
              onClick: submitForm,
              isLoading: editingDescription.isRequesting,
            },
            secondaryButtonProps: {
              dataTest: 'confirm-cancel-button',
              children: 'Cancel',
              onClick: props.onClose,
              isLoading: false,
            },
          }}
        >
          <Stack spacing={3} pt={1}>
            <Box width="100%">
              <TextInputField
                maxWidth="100%"
                hint="Max. 250 characters"
                multiline
                rows={4}
                label="Version description"
                name={getFieldName({ description: null })}
              />
            </Box>
          </Stack>
        </Popup>
      )}
    </PresetFormik>
  );
};

export default DatasetVersionEditor;
