import { isEqual, omit } from 'lodash';

type Value<V> = V & { enabled: boolean };

export default function useSaveDisabled<V>({
  values,
  initialValues,
  isSaving,
  isValid,
  isConfigurationTested,
  isExtendedFormShown,
}: {
  values: Value<V>;
  initialValues: Value<V>;
  isSaving: boolean;
  isValid: boolean;
  isConfigurationTested: boolean;
  isExtendedFormShown: boolean;
}) {
  const valuesWithoutEnabled = omit(values, 'enabled');
  const initialValuesWithoutEnabled = omit(initialValues, 'enabled');
  const isValuesWithoutEnabledEqual = isEqual(
    valuesWithoutEnabled,
    initialValuesWithoutEnabled
  );

  const isEnabledSwitchedOn = !initialValues.enabled && values.enabled;

  const isTestRequired =
    isExtendedFormShown &&
    (isValuesWithoutEnabledEqual
      ? isEnabledSwitchedOn && !isConfigurationTested
      : !isConfigurationTested);

  return {
    saveIsDisabled: Boolean(
      !isValid || isEqual(initialValues, values) || isTestRequired
    ),
    saveIsLoading: isSaving,
  };
}
