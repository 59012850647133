import DefaultMatchRemoteData from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { isNonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';
import { useCrossRunDashboards } from 'features/experimentRuns/crossRunDashboard/store/crossRunDashboardManager/useCrossRunDashboards';
import { useCrossRunDashboard } from 'features/experimentRuns/crossRunDashboard/store/crossRunDashboardManager/useCrossRunDashboard';
import { CrossRunDashboardsRequiredData } from 'features/experimentRuns/crossRunDashboard/store/crossRunDashboardManager/CrossRunDashboardsRequiredData';
import { OmitStrict } from 'shared/utils/types';
import { RequiredExperimentRun } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';

type CrossRunDashboardCase =
  | UnknownDashboardCase
  | DashboardIsSelectedCase
  | { type: 'emptyExperimentRuns' };

type UnknownDashboardCase = { type: 'unknownDashboard' } & ReturnType<
  typeof useCrossRunDashboards
>;

export type DashboardIsSelectedCase = {
  type: 'dashboardIsSelected';
} & ReturnType<typeof useCrossRunDashboard>;

// todo need to fix next comment behaviour (unmounting components) to use hooks correctly here
// we have to render this component in all cases to prevent unmounting components
const CaseImpl = (caseImplProps: { f: () => JSX.Element }) => {
  return caseImplProps.f();
};

type LocalProps = OmitStrict<
  CrossRunDashboardsRequiredData,
  'experimentRuns'
> & {
  experimentRuns: RequiredExperimentRun[];
  children: (_case: CrossRunDashboardCase) => JSX.Element;
};

export const CrossRunDashboardManager = (props: LocalProps) => {
  return (
    <DefaultMatchRemoteData
      data={props.experimentRuns}
      communication={props.loadingInitialExperimentRuns}
      context="loading experiment runs"
    >
      {(loadedExperimentRuns) => {
        if (!isNonEmptyArray(loadedExperimentRuns)) {
          return (
            <CaseImpl
              f={() => {
                return props.children({ type: 'emptyExperimentRuns' });
              }}
            />
          );
        }
        return (
          <DashboardWithNonEmptyExperimentRuns
            {...props}
            experimentRuns={loadedExperimentRuns}
          />
        );
      }}
    </DefaultMatchRemoteData>
  );
};

function DashboardWithNonEmptyExperimentRuns(
  props: CrossRunDashboardsRequiredData & {
    children: (_case: CrossRunDashboardCase) => JSX.Element;
  }
) {
  const crossRunDashboardsRes = useCrossRunDashboards(props);
  const {
    initialSelectedCrossRunDashboard,
    selectedCrossRunDashboard,
    experimentRuns,
  } = crossRunDashboardsRes;

  if (initialSelectedCrossRunDashboard && selectedCrossRunDashboard) {
    return (
      <CaseImpl
        f={function Case() {
          return props.children({
            type: 'dashboardIsSelected',
            ...useCrossRunDashboard({
              ...crossRunDashboardsRes,
              ...props,
              experimentRuns,
              initialSelectedCrossRunDashboard,
              selectedCrossRunDashboard,
            }),
          });
        }}
      />
    );
  }
  return (
    <CaseImpl
      f={() => {
        return props.children({
          type: 'unknownDashboard',
          ...crossRunDashboardsRes,
        });
      }}
    />
  );
}
