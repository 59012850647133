import { MonitoringDashboard } from '../../MonitoringDashboard/MonitoringDashboard';
import { MakeDefaultModelStateProps } from '../shared/MakeDefaultModelStateProps';

export const STATIC_OUTLIER_DETECTION_WIDGET_ID = 'outlier-detection';
export const STATIC_OUTLIER_DETECTION_TABLE_WIDGET_ID =
  'outlier-detection-table';

export const makePresetOutliersDashboard = (
  props: MakeDefaultModelStateProps
): MonitoringDashboard => ({
  createdBy: props.owner,
  dateCreated: new Date().toISOString(),
  id: 'outliers',
  name: 'Outliers',
  panels: [
    {
      id: 'ds2fas3erf-7adf-470b-a08b-7f0441811aad',
      type: 'outliers',
      name: 'Outliers',
      isOpen: true,
      ioDescription: props.defaultIODescription,
      layout: [
        {
          w: 12,
          h: 11,
          x: 0,
          y: 0,
          i: STATIC_OUTLIER_DETECTION_TABLE_WIDGET_ID,
          minW: 3,
          minH: 3,
          moved: false,
          static: false,
        },
        {
          w: 12,
          h: 11,
          x: 0,
          y: 11,
          i: STATIC_OUTLIER_DETECTION_WIDGET_ID,
          minW: 3,
          minH: 3,
          moved: false,
          static: false,
        },
      ],
    },
  ],
});
