import { useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useToast } from 'features/toast/store/hooks';
import {
  DeleteEntityType,
  getConfirmAdditionalTextByDeleteEntityType,
  getConfirmDescriptionByDeleteEntityType,
  getDeleteEntityNameByType,
} from 'shared/models/DeleteEntity';
import { MenuAction } from 'shared/view/elements/ActionsMenu/ActionsMenu';
import { ConfirmType } from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';

import { useDeleteEntity } from './useDeleteEntity';

interface Props {
  entityType: DeleteEntityType;
  redirectUrl: string | null;
  isNotAllowedToDelete: boolean | null;
  id: string | null;
}

export const useDeleteEntityAction = (props: Props): MenuAction => {
  const toast = useToast();
  const navigate = useNavigate();
  const onCompleted = useCallback(() => {
    if (props.redirectUrl) navigate(props.redirectUrl, { replace: true });

    toast(
      `Success: ${getDeleteEntityNameByType(
        props.entityType
      )} has been successfully deleted.`,
      'success'
    );
  }, [navigate, props.entityType, props.redirectUrl, toast]);

  const { deleteEntity, deletingEntity } = useDeleteEntity({
    onCompleted,
    type: props.entityType,
  });

  const onDelete = useCallback(() => {
    if (props.id) deleteEntity(props.id);
  }, [deleteEntity, props.id]);

  const { palette } = useTheme();

  return {
    type: 'button',
    label: 'Delete ' + getDeleteEntityNameByType(props.entityType),
    onClick: onDelete,
    dataTest: 'delete-action',
    disabled: Boolean(props.isNotAllowedToDelete),
    labelColor: palette.error.main,
    isLoading: deletingEntity.isRequesting,
    confirmAction: {
      type: ConfirmType.delete,
      description: getConfirmDescriptionByDeleteEntityType(props.entityType),
      additionalText: getConfirmAdditionalTextByDeleteEntityType(
        props.entityType
      ),
      longPressConfirmation: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'delete',
      maxWidth: 'xs',
    },
  };
};
