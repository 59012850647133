import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MuiAvatar from '@mui/material/Avatar';

import matchType from 'shared/utils/matchType';

interface Props {
  name: string;
  picture?: string | null;
  size?: 'small' | 'large';
}

const getInitials = (name: string) => {
  return name
    .split(' ')
    .map((s) => s[0])
    .slice(0, 2);
};

function nameToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

const Avatar: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  picture,
  size = 'small',
}) => {
  return (
    <MuiAvatar
      alt={name}
      src={picture ?? undefined}
      sx={{
        ...matchType(
          {
            large: () => ({
              width: '144px',
              height: '144px',
              fontSize: '48px',
              fontWeight: 400,
            }),
            small: () => ({}),
          },
          size
        ),
        bgcolor: picture ? undefined : nameToColor(name),
      }}
    >
      {getInitials(name)}
    </MuiAvatar>
  );
};

export default Avatar;
