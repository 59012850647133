import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';

import {
  MODEL_OVERVIEW_DATA_QUERY,
  ModelOverviewData,
  ModelOverviewDataVariables,
} from '../graphql';

type Props = ModelOverviewDataVariables;

export const useModelOverview = ({
  id,
  isEnableDeployment,
  isEnableMonitoring,
}: Props) => {
  const query = useCustomQuery<ModelOverviewData, ModelOverviewDataVariables>(
    MODEL_OVERVIEW_DATA_QUERY,
    { variables: { id, isEnableMonitoring, isEnableDeployment } }
  );

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return {
    data,
    communication,
    reload: query.refetch,
  };
};

const convertResponse = ({ registeredModel }: ModelOverviewData) =>
  mapDataOrError(registeredModel, (model) => model);
