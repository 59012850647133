import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import GlobalPreloader from 'shared/view/elements/GlobalPreloader/GlobalPreloader';
import { loadFlags } from 'features/flags';
import { checkUserAuthentication } from 'features/user/store';
import ErrorsPanel from 'features/errorReporter/view/ErrorsPanel/ErrorsPanel';
import ErrorReporterModal from 'features/errorReporter/view/ErrorReporterModal/ErrorReporterModal';
import ToastContainer from 'features/toast/view/ToastContainer/ToastContainer';
import { selectFlags } from 'features/flags';

import ErrorBoundary from './ErrorBoundary';
import { AnalyticsBoundary } from './analytics';
import * as analytics from './analytics';
import { initSentry } from './initSentry';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isSentryEnabled,
    segmentWriteKey,
    deployment: { isEnable: isDeploymentEnabled },
  } = useSelector(selectFlags);

  useEffect(() => {
    const loadInitialData = async () => {
      await dispatch(loadFlags());
      await dispatch(checkUserAuthentication());
      setIsLoading(false);
    };

    loadInitialData();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && isSentryEnabled) initSentry();
  }, [isLoading, isSentryEnabled]);

  useEffect(() => {
    if (!isLoading && segmentWriteKey) analytics.load(segmentWriteKey);
  }, [isLoading, segmentWriteKey]);

  useEffect(() => {
    if (location.pathname === '/') {
      if (isDeploymentEnabled) {
        navigate('/homepage');
      }
    }
  }, [location.pathname, navigate, isDeploymentEnabled]);

  return (
    <ToastContainer>
      <div>
        <ErrorsPanel />
        <ErrorReporterModal />
        <ErrorBoundary>
          <AnalyticsBoundary>
            {isLoading ? <GlobalPreloader /> : <Outlet />}
          </AnalyticsBoundary>
        </ErrorBoundary>
      </div>
    </ToastContainer>
  );
};

// ts-unused-exports:disable-next-line
export { App };
export default App;
