import { AxiosError } from 'axios';
import { Store, AnyAction } from 'redux';

import routes from 'shared/routes';
import { IApplicationState } from 'setup/store/store';
import { selectIsUserAuthenticated } from 'features/user/store';
import { isServerParseError } from 'shared/utils/graphql/apolloError';

const makeUnauthorizedErrorInterceptorErrorInterceptor = (
  store: Store<IApplicationState, AnyAction>
) => {
  return (error: AxiosError) => {
    if (
      selectIsUserAuthenticated(store.getState()) &&
      ((error.response && error.response.status === 401) ||
        isServerParseError(error))
    ) {
      // 401 Unauthorized
      const redirectBack = window.location.pathname;
      window.location.replace(routes.login.getRedirectPath({ redirectBack }));
    }
  };
};
export default makeUnauthorizedErrorInterceptorErrorInterceptor;
