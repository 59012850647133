import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import routes from 'shared/routes';
import { selectFlags } from 'features/flags';
import Button from 'shared/view/elements/Button/Button';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { authErrorMessages } from 'shared/utils/customErrorMessages';
import { loginByEmail, selectCommunications } from 'features/user/store';
import { useToastCommunicationError } from 'features/toast/store/hooks';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import { validateNotEmpty } from 'shared/utils/validators';
import { makeGetFieldName } from 'shared/utils/getFieldName';

import SocialLoginButtons from '../SocialLoginButtons';

interface ILocalProps {
  goToSignUp(): void;
  goToActiveDirectory(): void;
}

type ILoginByEmailForm = {
  email: string;
  password: string;
};

const initialForm: ILoginByEmailForm = {
  email: '',
  password: '',
};

const getFieldName = makeGetFieldName<ILoginByEmailForm>();

const LoginForm: React.FC<React.PropsWithChildren<ILocalProps>> = ({
  goToSignUp,
  goToActiveDirectory,
}) => {
  const location = useLocation();
  const queryParams = routes.login.parseQueryParams(location.search);

  const dispatch = useDispatch();
  const toastCommunicationError = useToastCommunicationError();

  const { isEnableCustomUsers } = useSelector(selectFlags);
  const { loginingByEmail } = useSelector(selectCommunications);

  useEffect(() => {
    if (loginingByEmail.error) {
      toastCommunicationError(loginingByEmail.error, {
        context: 'logging in',
        customErrorMessageByType: {
          invalidEmailOrPassword: authErrorMessages.invalidEmailOrPassword,
        },
      });
    }
  }, [loginingByEmail.error, toastCommunicationError]);

  const onSubmit = (values: ILoginByEmailForm) =>
    dispatch(
      loginByEmail({
        data: values,
        redirectBack: queryParams?.redirect_back,
      })
    );

  return (
    <>
      <Typography variant="h4">Welcome</Typography>
      <Typography variant="h6" mb="16px">
        Login
      </Typography>
      <PresetFormik<ILoginByEmailForm>
        initialValues={initialForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ submitForm }) => (
          <Box>
            <TextInputField
              name={getFieldName({ email: null })}
              validate={validateNotEmpty('Email')}
              label="Email"
              isRequired={true}
              size="medium"
            />
            <TextInputField
              name={getFieldName({ password: null })}
              validate={validateNotEmpty('Password')}
              label="Password"
              isRequired={true}
              size="medium"
              type="password"
            />
            <Box
              display="flex"
              alignItems="center"
              flexDirection="row"
              mt="16px"
              mb="32px"
            >
              <Box mr={2}>
                <Button
                  onClick={submitForm}
                  isLoading={loginingByEmail.isRequesting}
                  size="medium"
                  variant="contained"
                  iconEnd={ICONS.arrowRight}
                >
                  Login
                </Button>
              </Box>
              {isEnableCustomUsers ? (
                <Button
                  onClick={goToSignUp}
                  isLoading={false}
                  size="medium"
                  variant="text"
                >
                  Sign up
                </Button>
              ) : null}
            </Box>
          </Box>
        )}
      </PresetFormik>
      <SocialLoginButtons goToActiveDirectory={goToActiveDirectory} />
    </>
  );
};

export default LoginForm;
