import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';

import { selectCurrentUserOrThrowError, updateUser } from 'features/user';
import AuthenticationService from 'services/auth/AuthenticationService';
import {
  ICommunication,
  initialCommunication,
} from 'shared/utils/redux/communication';
import {
  getDefaultWorkspace,
  IUserWorkspaces,
  IWorkspace,
} from 'shared/models/Workspace';
import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';
import {
  changeWorkspaceWithPageReloading,
  selectWorkspaces,
} from 'features/workspaces';
import { useRestMutation } from 'shared/utils/react/useRestMutation';

const useDefaultWorkspaceManager = () => {
  const currentUser = useSelector(selectCurrentUserOrThrowError);
  const userWorkspaces = useSelector(selectWorkspaces);

  const {
    changeDefaultWorkspace,
    changingDefaultWorkspace,
    getChangingDefaultWorkspaceFor,
  } = useChangeDefaultWorkspace(currentUser, userWorkspaces);

  const isDefaultWorkspace = (workspace: IWorkspace) =>
    workspace.id === currentUser.defaultWorkspaceId;

  return {
    changingDefaultWorkspace,
    isDefaultWorkspace,
    getChangingDefaultWorkspaceFor,
    changeDefaultWorkspace,
  };
};

const useChangeDefaultWorkspace = (
  currentUser: CurrentUserData,
  userWorkspaces: IUserWorkspaces
) => {
  const dispatch = useDispatch();

  const [newDefaultWorkspaceId, setNewDefaultWorkspaceId] = React.useState<
    string | null
  >(null);
  const apolloClient = useApolloClient();

  const { communication, mutate } = useRestMutation(
    async (workspace: IWorkspace, _redirectToOnSuccess: string) => {
      setNewDefaultWorkspaceId(workspace.id);
      return AuthenticationService.updateDefaultWorkspace(
        apolloClient,
        currentUser.id,
        workspace.id
      );
    },
    { context: 'updating default workspace' },
    {
      onSuccess: (defaultWorkspaceId, [_, redirectToOnSuccess]) => {
        setNewDefaultWorkspaceId(null);
        dispatch(
          updateUser({
            ...currentUser,
            defaultWorkspaceId,
          })
        );
        const defaultWorkspace = getDefaultWorkspace(
          defaultWorkspaceId,
          userWorkspaces
        );
        changeWorkspaceWithPageReloading({
          workspace: defaultWorkspace,
          event: undefined,
          to: redirectToOnSuccess,
        });
      },
      onError: () => setNewDefaultWorkspaceId(null),
    }
  );
  const changeDefaultWorkspace = (
    workspace: IWorkspace,
    redirectToOnSuccess: string
  ) => {
    if (
      !communication.isRequesting &&
      currentUser.defaultWorkspaceId !== workspace.id
    ) {
      mutate(workspace, redirectToOnSuccess);
    }
  };

  const getChangingDefaultWorkspaceFor = (
    workspace: IWorkspace
  ): ICommunication =>
    workspace.id === newDefaultWorkspaceId
      ? communication
      : initialCommunication;

  return {
    changeDefaultWorkspace,
    getChangingDefaultWorkspaceFor,
    changingDefaultWorkspace: communication,
  };
};

export default useDefaultWorkspaceManager;
