import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useEndpointQuery } from 'features/deployment/canary/endpoints/store/endpointQuery/endpointQuery';
import { getEndpointPollingInterval } from 'shared/models/Deployment/canary/Endpoint';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

import EndpointDetailsLayout from '../shared/EndpointDetailsLayout/EndpointDetailsLayout';
import EndpointSettingsPageContent from './EndpointSettingsPageContent';

const EndpointSettingsPage = () => {
  const params = useRouteParams(routes.endpointSettings);
  const organizationId = useCurrentOrganizationV2();
  const { data, communication } = useEndpointQuery(
    {
      id: params.endpointId,
      workspaceName: params.workspaceName,
      organizationId,
    },
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      returnPartialData: true,
      pollInterval: getEndpointPollingInterval(),
    }
  );

  return (
    <EndpointDetailsLayout
      data={data}
      communication={communication}
      endpointId={params.endpointId}
    >
      {(loaded) => (
        <EndpointSettingsPageContent
          endpoint={loaded.endpoint}
          kafkaConfigurations={loaded.kafkaConfigurations}
        />
      )}
    </EndpointDetailsLayout>
  );
};

export default EndpointSettingsPage;
