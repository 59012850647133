import { gql } from '@apollo/client';

export const CUSTOM_ATTRIBUTE_VALUE_FRAGMENT = gql`
  fragment CustomAttributeValueData on CustomAttributeValue {
    id
    customAttributeDefinitionId
    targetId
    targetType
    selectedValue
    source
    timeCreated
    timeUpdated
    updatedBy {
      id
    }
  }
`;
