import isNotNil from '../isNotNill';

export const retryCondition = ({
  errorStatus,
  errorCode,
}: {
  errorStatus: number | undefined;
  errorCode?: string;
}) => {
  return (
    errorCode === 'ECONNABORTED' ||
    Boolean(
      isNotNil(errorStatus) && [502, 503, 504, 429, 403].includes(errorStatus)
    )
  );
};

export const maxRetries = 3;

export const getRetryDelay = ({ retryNumber }: { retryNumber: number }) => {
  const delays: Record<number, number> = {
    1: 500,
    2: 1000,
    3: 2000,
  };
  return delays[retryNumber] || 3000;
};
