import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import routes from 'shared/routes';
import { validateNotEmpty } from 'shared/utils/validators';
import Button from 'shared/view/elements/Button/Button';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { selectCurrentWorkspace } from 'features/workspaces';
import { validateDescription } from 'shared/models/Description';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

import { useCreateRegisteredModel } from './useCreateRegisteredModel';

interface RegisteredModelCreationForm {
  name: string;
  description: string;
}

const initialSettings: RegisteredModelCreationForm = {
  name: '',
  description: '',
};

const getFieldName = makeGetFieldName<RegisteredModelCreationForm>();

const RegisteredModelCreation: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const workspace = useSelector(selectCurrentWorkspace);

  const navigate = useNavigate();
  const { createRegisteredModel, creatingRegisteredModel } =
    useCreateRegisteredModel({
      onSuccess: ({ id }) => {
        navigate(
          routes.registeredModel.getRedirectPath({
            workspaceName: workspace.name,
            registeredModelId: id,
          })
        );
      },
    });

  const organizationId = useCurrentOrganizationV2();

  const onSubmit = useCallback(
    (values: RegisteredModelCreationForm) => {
      createRegisteredModel({
        name: values.name,
        description: values.description,
        workspaceName: workspace.name,
        organizationId,
      });
    },
    [createRegisteredModel, workspace.name, organizationId]
  );

  return (
    <PresetFormik<RegisteredModelCreationForm>
      initialValues={initialSettings}
      onSubmit={onSubmit}
    >
      {({ submitForm, isValid }) => (
        <FormStack>
          <TextInputField
            name={getFieldName({ name: null })}
            validate={validateNotEmpty('registered model name')}
            label="Model name"
            isRequired={true}
          />
          <TextInputField
            name={getFieldName({ description: null })}
            validate={validateDescription}
            label="Model description"
          />
          <Button
            onClick={submitForm}
            isLoading={creatingRegisteredModel.isRequesting}
            disabled={!isValid}
          >
            Create
          </Button>
        </FormStack>
      )}
    </PresetFormik>
  );
};

export default RegisteredModelCreation;
