import { isNil } from 'ramda';

import { MachineConfigInfo } from 'shared/graphql/Deployment/Endpoint/MachineConfig/graphql-types/MachineConfigInfo.generated';
import { OmitStrict } from 'shared/utils/types';

export type MachineConfigInfoWithoutTypename = OmitStrict<
  MachineConfigInfo,
  '__typename'
>;

export const mapMachineConfigWhenNotEmpty =
  (machineConfig: MachineConfigInfoWithoutTypename) =>
  <R>(mappers: {
    [K in keyof MachineConfigInfoWithoutTypename]: (
      notEmptyValue: NonNullable<MachineConfigInfoWithoutTypename[K]>
    ) => R;
  }) => {
    const results: Record<keyof MachineConfigInfoWithoutTypename, R | null> = {
      autoscaling: isAutoscalingEmpty(machineConfig.autoscaling)
        ? null
        : mappers.autoscaling(machineConfig.autoscaling),
      resources: machineConfig.resources
        ? mappers.resources(machineConfig.resources)
        : null,
      env:
        machineConfig.env && machineConfig.env.length > 0
          ? mappers.env(machineConfig.env)
          : null,
      isolation: !isNil(machineConfig.isolation)
        ? mappers.isolation(machineConfig.isolation)
        : null,
    };

    return Object.values(results).every((v) => v === null) ? null : results;
  };

const isAutoscalingEmpty = (
  autoscaling: MachineConfigInfoWithoutTypename['autoscaling']
): autoscaling is null => {
  const isQuantitiesEmpty = (() => {
    if (autoscaling?.quantities) {
      const { __typename, ...onlyQuantities } = autoscaling.quantities;
      return Object.values(onlyQuantities).every((v) => v === null);
    }
    return true;
  })();

  return (autoscaling?.metrics.length ?? 0) === 0 && isQuantitiesEmpty;
};
