export type FieldName = {
  field: string;
  key?: string;
};

export const fieldNameToKey = (fieldName: FieldName) =>
  fieldName.key ? `${fieldName.field}.${fieldName.key}` : `${fieldName.field}`;

export const parseFieldNameFromKey = (str: string): FieldName => {
  const [field, key] = str.includes('.') ? str.split('.') : [str, undefined];
  return { field, key };
};
