import { useMemo } from 'react';

import { MonitoringIOType } from 'generated/types';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';

import MonitoringIODescriptionField from '../MonitoringIODescriptionField/MonitoringIODescriptionField';

interface Props {
  ioDescriptions: MonitoringIODescription[];
  name: string;
}

const MonitoringOutputDescriptionField = (props: Props) => {
  const outputs = useMemo(
    () =>
      props.ioDescriptions.filter((d) => d.ioType === MonitoringIOType.OUTPUT),
    [props.ioDescriptions]
  );

  return (
    <MonitoringIODescriptionField
      label="Output"
      name={props.name}
      ioDescriptions={outputs}
    />
  );
};

export default MonitoringOutputDescriptionField;
