import { MonitoringMetricType } from 'generated/types';
import matchBy from 'shared/utils/matchBy';

import { MonitoringWidget } from './MonitoringPanel/MonitoringWidget/MonitoringWidget';

type MonitoringModelStatusItem = 'ground_truth' | 'live' | 'reference';

export type MonitoringModelStatusCheck =
  | {
      type: 'required';
      items: MonitoringModelStatusItem[];
    }
  | {
      type: 'oneOf';
      items: MonitoringModelStatusItem[];
    };

export type MonitoringModelStatus = MonitoringModelStatusItem[];

export const getStatusCheckByWidget = (
  widget: MonitoringWidget
): MonitoringModelStatusCheck | null => {
  return matchBy(
    widget,
    'type'
  )<MonitoringModelStatusCheck | null>({
    barChart: () => ({ type: 'required', items: ['live'] }),
    confusionMatrix: () => ({
      type: 'required',
      items: ['live', 'ground_truth'],
    }),
    curve: () => ({ type: 'required', items: ['live', 'ground_truth'] }),
    histograms: () => ({ type: 'required', items: ['live'] }),
    notInitialized: () => null,
    scatterPlot: () => ({ type: 'required', items: ['live'] }),
    singleMetric: (singleMetricWidget) => {
      if (singleMetricWidget.variant.type === 'metric') {
        return singleMetricWidget.variant.metricType !==
          MonitoringMetricType.PREDICTION_COUNT
          ? { type: 'required', items: ['live', 'ground_truth'] }
          : { type: 'required', items: ['live'] };
      }

      return null;
    },
    table: () => null,
    timeSeries: (timeSeriesWidget) =>
      matchBy(
        timeSeriesWidget.variant,
        'type'
      )<MonitoringModelStatusCheck>({
        drift: () => ({ type: 'required', items: ['live', 'reference'] }),
        metric: (variant) =>
          variant.metricType !== MonitoringMetricType.PREDICTION_COUNT
            ? { type: 'required', items: ['live', 'ground_truth'] }
            : { type: 'required', items: ['live'] },
      }),
  });
};
