import { ComponentProps, ReactElement } from 'react';

import Tooltip from './Tooltip';

interface Props {
  showTooltip: boolean;
  children: ReactElement;
  title: string;
  type: ComponentProps<typeof Tooltip>['type'];
}

const OptionalTooltip = (props: Props) => {
  return props.showTooltip ? (
    <Tooltip title={props.title} type={props.type}>
      {props.children}
    </Tooltip>
  ) : (
    props.children
  );
};

export default OptionalTooltip;
