import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';

import * as Types from './graphql-types/useKafkaConfigurations.generated';

const QUERY = gql`
  query KafkaConfigurations {
    kafkaConfigurations {
      id
      configurations {
        id
        name
        brokerAddresses
        kerberos {
          conf
          keytab
          clientName
          serviceName
        }
        tls {
          certificate
        }
        enabled
        kafkaTopics {
          topics {
            name
          }
        }
      }
    }
  }
`;

const useKafkaConfigurations = () => {
  const queryResult = useCustomQuery<Types.KafkaConfigurations>(QUERY);

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult,
  });
};

const convert = (x: Types.KafkaConfigurations) =>
  x.kafkaConfigurations.configurations;

export default useKafkaConfigurations;
