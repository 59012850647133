import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useModelVersionIntegrate } from 'features/catalog/registeredModelVersion/integrate/store/useModelVersionIntegrate';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

const ModelVersionIntegrate = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ModelVersionIntegrate' */ 'features/catalog/registeredModelVersion/integrate/view/integrate'
    )
);

const IntegratePage = () => {
  const { versionId } = useRouteParams(routes.modelVersionIntegrate);

  const { version, loadingVersion } = useModelVersionIntegrate({
    versionId,
  });

  return (
    <DefaultMatchRemoteDataOrError
      communication={loadingVersion}
      data={version}
      context="loading model version"
    >
      {(loadedModelVersion) => (
        <ModelVersionIntegrate version={loadedModelVersion} />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default IntegratePage;
