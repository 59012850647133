import { useMemo } from 'react';
import { sortBy } from 'ramda';

import {
  groupObservationsByAttributeKey,
  isObservationWithEpoch,
} from 'shared/models/Observation';
import { ObservationAttribute } from 'shared/graphql/ExperimentRunDetails/Observation/graphql-types/Observation.generated';

import { ObservationChartXAxisType } from './settings';
import { ChartObservation, ChartExperimentRun } from './types';
import {
  convertObservationToObservationsWithCorrectValue,
  convertExperimentRunsToObservationsWithExperimentRun,
  groupObservationsByExperimentRun,
} from './converters';

export const useChartObservations = (
  experimentRuns: ChartExperimentRun[]
): ChartObservation[] => {
  const observations = useMemo(
    () => convertExperimentRunsToObservationsWithExperimentRun(experimentRuns),
    [experimentRuns]
  );

  return useMemo(
    () => convertObservationToObservationsWithCorrectValue(observations),
    [observations]
  );
};

export const useGroupedObservations = <T extends ObservationAttribute>({
  observations,
}: {
  observations: T[];
}) => {
  return useMemo(
    () => groupObservationsByAttributeKey(observations),
    [observations]
  );
};

export const useObservationChartData = ({
  observations,
  xAxisType,
}: {
  observations: ChartObservation[];
  xAxisType: ObservationChartXAxisType;
}) => {
  const sortedObservations = useMemo(
    () => sortBy((obs) => Number(obs[xAxisType]), observations),
    [observations, xAxisType]
  );
  const sortedObservationsWithEpoch = useMemo(
    () => sortedObservations.filter(isObservationWithEpoch),
    [sortedObservations]
  );
  const groupedObservationsWithEpoch = useMemo(
    () => groupObservationsByExperimentRun(sortedObservationsWithEpoch),
    [sortedObservationsWithEpoch]
  );
  const groupedObservations = useMemo(
    () => groupObservationsByExperimentRun(sortedObservations),
    [sortedObservations]
  );

  return {
    groupedObservations,
    groupedObservationsWithEpoch,
    sortedObservations,
    sortedObservationsWithEpoch,
  };
};
