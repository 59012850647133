import { JsonData } from 'shared/utils/json';
import NA from 'shared/view/elements/PageComponents/NA/NA';

export type IEndpointInfo = INotActiveEndpointInfo | IActiveEndpointInfo;
export type INotActiveEndpointInfo = {
  isActive: false;
  path: string;
  fullUrl?: string | null;
  token: string | null;
};
export type IActiveEndpointInfo = {
  isActive: true;
  path: string;
  fullUrl?: string | null;
  token: string | null;
};

export interface IDeployedExperimentRunInfo {
  signature: IDeployedExperimentRunSignature;
  inputExample: JsonData | string;
}
export interface IDeployedExperimentRunSignature {
  description: string;
  args: string;
  returns: string;
  inputDescription: string;
  outputDescription: string;
  method: string;
}

export const getPredictionEndpoint = (
  endpointPath: string,
  endpointFullUrl?: string | null
) => {
  if (endpointFullUrl) {
    return endpointFullUrl;
  }

  const formattedEndpointPath = `/${endpointPath}`.replace(/\/+/g, '/');
  return `${window.origin}/api/v1/predict${formattedEndpointPath}`;
};

export const defaultDeployedExperimentRunInfo = {
  inputExample: {},
  signature: {
    args: '',
    description: '',
    inputDescription: '',
    method: '',
    outputDescription: '',
    returns: '',
  },
};

export const makeSignatureDisplayValue = ({
  signature: { method, args, returns },
}: IDeployedExperimentRunInfo) => {
  if (method && returns) {
    return `${method}(${args}): ${returns}`;
  }
  return NA;
};
