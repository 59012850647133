import { useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';

import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';

import { kafkaConfigurations } from '../hooks/graphql-types/useKafkaConfigurationTopics.generated';
import {
  EndpointKafkaChangeFieldKey,
  EndpointKafkaChangeFieldValue,
  EndpointKafkaFormValues,
} from '../store/types';
import { getEndpointKafkaFieldName } from '../store/helpers';

type Props = {
  topics: kafkaConfigurations['kafkaTopicsList']['topics'];
  values: EndpointKafkaFormValues;
  kafkaId: string;
  changeField(
    fieldName: EndpointKafkaChangeFieldKey,
    value: EndpointKafkaChangeFieldValue
  ): void;
};

export function EndpointKafkaTopicFields({
  topics,
  values,
  changeField,
  kafkaId,
}: Props) {
  const { topicsOptions } = useTopicFields({
    topics,
    values,
    changeField,
    kafkaId,
  });

  return (
    <Stack pl={4}>
      <SelectField
        required
        withoutError
        label="Input topic"
        name={getEndpointKafkaFieldName('inputTopic')}
        options={topicsOptions}
        onChange={(option) => {
          changeField('inputTopic', option.value);
        }}
      />
      <SelectField
        required
        withoutError
        label="Output topic"
        name={getEndpointKafkaFieldName('outputTopic')}
        options={topicsOptions}
        onChange={(option) => {
          changeField('outputTopic', option.value);
        }}
      />
      <SelectField
        required
        withoutError
        label="Error topic"
        name={getEndpointKafkaFieldName('errorTopic')}
        options={topicsOptions}
        onChange={(option) => {
          changeField('errorTopic', option.value);
        }}
      />
    </Stack>
  );
}

function useTopicFields({ topics, values, changeField, kafkaId }: Props) {
  const topicsOptions = useMemo(
    () =>
      topics.map((topic) => ({
        label: topic,
        value: topic,
      })),
    [topics]
  );

  useEffect(() => {
    if (!values.kafkaReq?.inputTopic) {
      changeField('inputTopic', topicsOptions[0].value);
    }
    if (!values.kafkaReq?.outputTopic) {
      changeField('outputTopic', topicsOptions[0].value);
    }
    if (!values.kafkaReq?.errorTopic) {
      changeField('errorTopic', topicsOptions[0].value);
    }
    changeField('kafkaConfigId', kafkaId);
  }, [
    changeField,
    topicsOptions,
    values.kafkaReq?.inputTopic,
    values.kafkaReq?.outputTopic,
    values.kafkaReq?.errorTopic,
    kafkaId,
  ]);

  return {
    topicsOptions,
  };
}
