import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import { LinkButton } from '../../../Button/LinkButton';
import { DataGridColumn } from '../../DataGridColumn';
import styles from './linkColumn.module.css';

const makeLinkColumn = <T extends any>(
  getLink: (row: T) => string,
  onClick?: () => void
): DataGridColumn<T> => ({
  field: ' ',
  cellClassName: () => styles.linkColumn,
  width: 78,
  renderCell: (params) => (
    <div className={styles.linkColumnContent}>
      <LinkButton
        to={getLink(params.row)}
        variant="text"
        iconEnd={ICONS.arrowRight}
        onClick={onClick}
      >
        View
      </LinkButton>
    </div>
  ),
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  resizable: false,
});

export default makeLinkColumn;
