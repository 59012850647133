import { VisualizationSpec } from 'react-vega';
import { Transforms } from 'vega';

import { OmitStrict } from 'shared/utils/types';

type Data = { name: string; transform?: Transforms[] };
export type WidgetVisualizationSpec = ChangeDataType<VisualizationSpec, Data>;
type ChangeDataType<
  V extends VisualizationSpec,
  Type,
> = V extends VisualizationSpec
  ? OmitStrict<V, 'data'> & {
      data: Type;
    }
  : never;

export const getDefaultDataField = (dataSourceName: string): Data => ({
  name: dataSourceName,
});
