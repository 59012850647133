import * as React from 'react';

interface Props {
  renderError: (error: Error) => React.ReactNode;
}

class BaseErrorBoundary extends React.Component<
  React.PropsWithChildren<Props>,
  { error: Error | undefined }
> {
  constructor(props: Props) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error);
    return { error };
  }

  private unlistenCallback: any | undefined = undefined;

  public componentDidMount() {
    // this.unlistenCallback = this.props.history.listen(() => {
    //   this.setState({ error: undefined });
    // });
  }

  public componentWillUnmount() {
    // if (this.unlistenCallback) {
    //   this.unlistenCallback();
    // }
  }

  public componentDidCatch() {
    // do nothing
  }

  public render() {
    if (this.state.error) {
      return this.props.renderError(this.state.error);
    }

    return this.props.children;
  }
}

export default BaseErrorBoundary;
