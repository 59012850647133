import { Box, styled, Stack } from '@mui/material';
import { dissoc, equals, uniq } from 'ramda';

import routes from 'shared/routes';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import noop from 'shared/utils/noop';
import { OmitStrict } from 'shared/utils/types';
import { validateEmail, validateNotEmpty } from 'shared/utils/validators';
import { Button } from 'shared/view/elements/Button';
import { Chip } from 'shared/view/elements/Chip/Chip';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import Popup from 'shared/view/elements/Popup/Popup';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';

type Props = {
  isOpen: boolean;
  initialForm: OrganizationForm;
  title: string;
  submitButton: {
    isLoading: boolean;
    children: 'Create' | 'Update';
    disabled?: boolean;
    onSubmit(form: OmitStrict<OrganizationForm, 'adminEmail'>): void;
  };
  onClose(): void;
};

export type OrganizationForm = {
  name: string;
  description: string;
  adminEmail: string;
  adminEmails: string[];
};

const getFieldName = makeGetFieldName<OrganizationForm>();

const OrganizationPopup = (props: Props) => {
  return (
    <PresetFormik
      initialValues={props.initialForm}
      enableReinitialize={true}
      onSubmit={(form) =>
        props.submitButton.onSubmit(dissoc('adminEmail', form))
      }
    >
      {({ submitForm, values, isValid, errors, setFieldValue }) => {
        const disabled =
          !isValid ||
          equals(
            dissoc('adminEmail', values),
            dissoc('adminEmail', props.initialForm)
          ) ||
          values.adminEmails.length === 0 ||
          props.submitButton.disabled;

        return (
          <Popup
            isOpen={props.isOpen}
            title={props.title}
            maxWidth="xs"
            fullWidth
            buttons={{
              secondaryButtonProps: {
                isLoading: false,
                children: 'Cancel',
                onClick: props.onClose,
              },
              mainButtonProps: {
                ...props.submitButton,
                type: 'button',
                disabled: disabled,
                onClick: submitForm,
              },
            }}
            onClose={props.onClose}
          >
            <FormStack>
              <TextInput
                label="URL name for organization will be"
                value={routes.systemAdminOrganization.getRedirectPath({
                  organizationName: values.name || '_',
                })}
                disabled={true}
                onChange={noop}
              />
              <TextInputField
                label="Organization name"
                name={getFieldName({ name: null })}
                isRequired={true}
                validate={validateNotEmpty('name')}
              />
              <TextInputField
                label="Organization description"
                name={getFieldName({ description: null })}
              />

              <Stack direction="row">
                <div style={{ flexGrow: 1 }}>
                  <TextInputField
                    label="Invite org admin using email"
                    name={getFieldName({ adminEmail: null })}
                    isRequired={true}
                    validate={(v) => (v !== '' ? validateEmail(v) : undefined)}
                    withoutLabelPadding={true}
                  />
                </div>
                <Button
                  isLoading={false}
                  size="medium"
                  disabled={Boolean(!values.adminEmail || errors.adminEmail)}
                  dataTest="add-admin"
                  onClick={() => {
                    setFieldValue(
                      getFieldName({ adminEmails: null }),
                      uniq(values.adminEmails.concat(values.adminEmail))
                    );
                    setFieldValue(getFieldName({ adminEmail: null }), '');
                  }}
                >
                  Add
                </Button>
              </Stack>
              <ContentWrapper>
                {values.adminEmails.map((adminEmail) => {
                  return (
                    <Chip
                      key={adminEmail}
                      size="medium"
                      variant="outlined"
                      color="primary"
                      label={adminEmail}
                      onDelete={() =>
                        setFieldValue(
                          getFieldName({ adminEmails: null }),
                          values.adminEmails.filter((v) => v !== adminEmail)
                        )
                      }
                    />
                  );
                })}
              </ContentWrapper>
            </FormStack>
          </Popup>
        );
      }}
    </PresetFormik>
  );
};

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  fontSize: '15px',
  gap: '4px',
});

export default OrganizationPopup;
