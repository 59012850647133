import { MarkdownWidgetSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/MarkdownWidgetSettings';
import { Result, makeSuccess } from 'shared/utils/result';

export type MarkdownWidgetSettingsForm = MarkdownWidgetSettings;

export const validateMarkdownSettingsForm = (
  form: MarkdownWidgetSettingsForm
): Result<MarkdownWidgetSettings, string> => {
  return makeSuccess(form);
};

export const getInitialMarkdownWidgetSettings = (
  name: string
): MarkdownWidgetSettingsForm => {
  return {
    type: 'markdown',
    name,
    markdown: [
      '# {{Template}} Model Experiment Summary',
      '## Overview',
      '- {{What does this model do?}}',
      '- {{What iterations did you perform?}}',
      '- {{What performance results and trends did you see?}}',
      '## Additional Information',
      '- {{}}',
      '- {{}}',
      '## Conclusion',
      '- {{}}',
      '- {{}}',
    ].join('\n'),
  };
};
