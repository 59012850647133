import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import customAttrDashboard from '../images/custom-attributes-dashboard.png';

export const CustomAttrDashboard = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Edit model or version attribute
    </Typography>
    <Typography variant="body2" mb={3}>
      Once custom attributes are created, they can fuel dashboards, which are
      available in the advanced tier.
    </Typography>
    <img
      src={customAttrDashboard}
      alt="custom attributes powered dashboard"
      width="90%"
      height="auto"
    />
  </WizardStepContent>
);
