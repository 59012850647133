import React, { useMemo } from 'react';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTheme } from '@mui/material';

import { getChartSizes } from 'shared/utils/charts/chartSizes';
import { removeSpacesFromString } from 'shared/utils/removeSpacesFromString';
import { getLinearScaleDomain } from 'shared/utils/charts/getLinearScaleDomain';

import { BarChartData, getKey } from './utils/model';
import CustomAxisLeftWithGridRows from '../Axis/CustomAxisLeftWithGridRows/CustomAxisLeftWithGridRows';
import AxisBottomWithRotatedTicks from '../Axis/AxisBottomWithRotatedTicks/AxisBottomWithRotatedTicks';
import { useBarTooltip } from '../shared/barTooltip';
import { defaultChartMargin } from '../shared/margin';
import ChartSVG from '../shared/ChartSVG/ChartSVG';
import CustomBarView from '../shared/shapes/CustomBarView/CustomBarView';
import { GetBarSettingsArguments, BarSettings } from '../shared/getBarSettings';

export interface BaseBarChartProps<AdditionalData> {
  chartId: string;
  data: Array<BarChartData<AdditionalData>>;
  width: number;
  height: number;
  getColorByData: (d: BarChartData<AdditionalData>) => string;
  yAxisLabel?: string;
  xScaleDomain: string[];
  getBarSettings: (args: GetBarSettingsArguments) => BarSettings;
  renderTooltipContent: (
    d: BarChartData<AdditionalData>,
    color: string
  ) => React.ReactElement;
}

const getYScaleDomain = (data: Array<BarChartData<unknown>>) => {
  const values = data.map((d) => d.value);

  return getLinearScaleDomain(values);
};

function BaseBarChart<AdditionalData>(
  props: BaseBarChartProps<AdditionalData>
) {
  const { width, height, innerHeight, innerWidth, margin } = getChartSizes({
    margin: defaultChartMargin,
    width: props.width,
    height: props.height,
  });

  const xScale = useMemo(
    () =>
      scaleBand({
        domain: props.xScaleDomain,
        range: [0, innerWidth],
        padding: 0,
      }),
    [props.xScaleDomain, innerWidth]
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: getYScaleDomain(props.data),
        range: [innerHeight, 0],
        round: true,
        zero: true,
      }),
    [innerHeight, props.data]
  );

  const { tooltip, makeHandlers } = useBarTooltip<BarChartData<AdditionalData>>(
    {
      margin,
      renderTooltipContent: (d) =>
        props.renderTooltipContent(d, props.getColorByData(d)),
    }
  );

  const theme = useTheme();

  return (
    <ChartSVG
      width={width}
      height={height}
      margin={margin}
      outSvgContent={tooltip}
      isNilData={props.data.length === 0}
    >
      <CustomAxisLeftWithGridRows
        innerWidth={innerWidth}
        scale={yScale}
        label={props.yAxisLabel}
        numTicks={4}
      />
      <AxisBottomWithRotatedTicks
        scale={xScale}
        top={innerHeight}
        stroke={theme.palette.charts.accentColor}
        tickStroke={theme.palette.charts.accentColor}
      />
      {props.data.map((d) => {
        const { onMouseLeave, onMouseMove } = makeHandlers(d);
        const barId = removeSpacesFromString(
          `bar-${getKey(d)}-${props.chartId}`
        );
        const settings = props.getBarSettings({
          d,
          innerHeight,
          yScale,
          xScale,
        });

        return (
          <CustomBarView
            {...settings}
            id={barId}
            key={barId}
            padding={0.15}
            innerHeight={innerHeight}
            innerWidth={innerWidth}
            fill={props.getColorByData(d)}
            onMouseMove={(e) => onMouseMove(e, settings)}
            onMouseLeave={onMouseLeave}
          />
        );
      })}
    </ChartSVG>
  );
}

export default BaseBarChart;
