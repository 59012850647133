import { default as DefaultLinkify } from 'linkify-react';

import { OmitStrict } from 'shared/utils/types';

import InlineLink, { InlineProps } from '../InlineLink/InlineLink';

interface Props {
  children: React.ReactNode;
}

type DecoratorProps = OmitStrict<InlineProps, 'to' | 'children'> & {
  attributes: { [attr: string]: any };
  content: string;
};

const renderLink = ({ attributes, content, ...props }: DecoratorProps) => {
  const { href } = attributes;
  return (
    <InlineLink
      onClick={(e) => e.stopPropagation()}
      to={href as string}
      isExternal
      simple
      {...props}
    >
      {content}
    </InlineLink>
  );
};

const Linkify = (props: Props) => {
  return (
    <DefaultLinkify options={{ render: renderLink }} {...props}>
      {props.children}
    </DefaultLinkify>
  );
};

export default Linkify;
