import { createAction } from 'typesafe-actions';

import { RequestResponse } from '../requestResponse/RequestResponse';
import { ErrorWithInfo } from './types';

export const addRequestResponse = createAction(
  '@@errorReporter/ADD_REQUEST_RESPONSE'
)<RequestResponse>();

export const addError = createAction(
  '@@errorReporter/ADD_ERROR',
  (payload: {
    error: ErrorWithInfo['error'];
    context: string;
    pathname: string;
  }) => ({
    ...payload,
    createdAt: new Date(),
  })
)();

export const removeError = createAction('@@errorReporter/REMOVE_ERROR')<
  ErrorWithInfo['error']
>();
export const removeAllErrors = createAction(
  '@@errorReporter/REMOVE_ALL_ERRORS'
)();

export const openErrorsPanel = createAction(
  '@@errorReporter/OPEN_ERRORS_PANEL'
)();
export const closeErrorsPanel = createAction(
  '@@errorReporter/CLOSE_ERRORS_PANEL'
)();

export const openErrorReporterModal = createAction(
  '@@errorReporter/OPEN_ERROR_REPORTER_MODAL'
)<ErrorWithInfo | undefined>();
export const closeErrorReporterModal = createAction(
  '@@errorReporter/CLOSE_ERROR_REPORTER_MODAL'
)();
