import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import routes from 'shared/routes';
import {
  selectCurrentUser,
  selectCurrentUserOrganizations,
} from 'features/user';
import { selectFlags } from 'features/flags';

export const AnonymousLayout = () => {
  const currentUser = useSelector(selectCurrentUser);
  const currentUserOrganizations = useSelector(selectCurrentUserOrganizations);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { isEnableFreeTier } = useSelector(selectFlags);

  if (currentUser) {
    // an user with empty organizations means that he need to complete the signup
    if (isEmpty(currentUserOrganizations) && isEnableFreeTier) {
      if (routes.signUpPersonalInfoForm.getMatch(location.pathname)) {
        return <Outlet />;
      }
      // Redirect them to the /sign-up/personal-info-form page

      return <Navigate to={routes.signUpPersonalInfoForm.getPath()} />;
    }

    const pathname =
      searchParams.get('redirect_back') || routes.homepage.getPath();

    return <Navigate to={{ pathname }} />;
  }

  return <Outlet />;
};
