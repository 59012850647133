import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as GraphqlTypes from './graphql-types/useCreateExperiment.generated';

const MUTATION = gql`
  mutation CreateExperiment(
    $projectId: ID!
    $name: String!
    $tags: [String!]!
    $description: String!
  ) {
    project(id: $projectId) {
      ... on Error {
        ...ErrorData
      }
      ... on Project {
        id
        createExperiment(name: $name, tags: $tags, description: $description) {
          id
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useCreateExperiment = (onCompleted: () => void) => {
  const [createExperiment, creatingExperiment] = useCustomMutation<
    GraphqlTypes.CreateExperiment,
    GraphqlTypes.CreateExperimentVariables
  >(
    MUTATION,
    { context: 'creating experiment' },
    {
      onCompleted,
    }
  );

  return {
    createExperiment,
    creatingExperiment,
  };
};
