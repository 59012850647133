import {
  IconDefinition,
  IconPrefix,
  IconName,
} from '@fortawesome/fontawesome-svg-core';

export const JsonIcon: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'json' as IconName,
  icon: [
    18,
    18,
    [],
    'e001',
    'M9.98775 13.4562C14.4318 10.0789 11.3802 3.08879 7.54395 3.68771C0.327493 4.81434 2.13252 14.8438 4.5368 16.7083C0.774914 14.8496 -0.788431 10.2381 0.380431 6.31898C1.30641 3.21423 4.19369 0.139305 8.56787 0.0214598C15.4714 -0.608235 17.5282 12.8241 9.98775 13.4562V13.4562ZM7.70791 4.57731C3.75987 4.77867 2.90831 8.76645 3.23283 11.6477C3.70633 15.8516 6.37028 18.5811 10.5297 17.8944C16.9202 16.8394 22.1981 6.68923 13.217 1.01889C16.5256 4.87286 16.5563 12.6807 11.1209 14.0664C6.0942 15.5817 3.80572 7.46673 7.70791 4.57731V4.57731Z',
  ],
};
