import { useMemo } from 'react';
import { head } from 'ramda';

import { makeFloatDistributionIntervals } from 'shared/models/Monitoring/Distribution/DistributionDescription';
import { Distribution } from 'shared/models/Monitoring/Distribution/Distribution';
import GroupedIntervalBarChart, {
  GroupedIntervalBarChartData,
} from 'shared/view/charts/GroupedBarChart/GroupedIntervalBarChart';
import { normalizeDistribution } from 'shared/models/Monitoring/Distribution/NormalizedDistribution';

import { DistributionBarChartProps } from '../shared/DistributionBarChartProps';
import BaseDistributionBarChart, {
  DistributionBarChartMeta,
} from '../shared/BaseDistributionBarChart';
import { makeDistributionBarsDataBuilder } from '../shared/makeDistributionBarData';

type FloatDistributionBarChartData =
  GroupedIntervalBarChartData<DistributionBarChartMeta>;

const FloatDistributionBarChart = (props: DistributionBarChartProps) => {
  const chartData = useMemo(
    () =>
      convertData({
        liveDistributions: props.liveDistributions,
        referenceDistributions: props.referenceDistributions,
      }),
    [props.liveDistributions, props.referenceDistributions]
  );

  return (
    <BaseDistributionBarChart
      size={props.size}
      chartData={chartData}
      withoutTooltipAndLegend={props.withoutTooltipAndLegend}
    >
      {({ data, size, renderTooltipContent, getColorByData }) => (
        <GroupedIntervalBarChart
          chartId={props.id}
          data={data}
          getColorByData={getColorByData}
          width={size.width}
          height={size.height}
          renderTooltipContent={renderTooltipContent}
        />
      )}
    </BaseDistributionBarChart>
  );
};

const convertData = (props: {
  liveDistributions: Distribution[];
  referenceDistributions: Distribution[];
}): Array<FloatDistributionBarChartData> => {
  const makeBarsData = makeDistributionBarsDataBuilder(props);

  const distribution = head(props.referenceDistributions);
  const intervals = distribution
    ? makeFloatDistributionIntervals(normalizeDistribution(distribution))
    : [];

  return intervals.map((interval, index): FloatDistributionBarChartData => {
    return {
      ...interval,
      barsData: makeBarsData(index),
    };
  });
};

export default FloatDistributionBarChart;
