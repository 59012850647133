import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppBar, Box, Stack, Toolbar } from '@mui/material';

import {
  getDefaultWorkspace,
  IUserWorkspaces,
  IWorkspace,
} from 'shared/models/Workspace';
import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';
import { getUrlWithChangedWorkspace } from 'shared/routes/shared/getUrlWithChangedWorkspace';
import {
  changeWorkspaceWithPageReloading,
  selectCurrentWorkspace,
  selectWorkspaces,
} from 'features/workspaces';
import HeaderSearch from 'features/highLevelSearch/view/HeaderSearch/HeaderSearch';
import { selectFlags } from 'features/flags';
import routes from 'shared/routes';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { selectCurrentUserOrThrowError } from 'features/user';
import { LinkButton } from 'shared/view/elements/Button/LinkButton';
import { componentsSizes } from 'shared/mui/themes/CommonMuiThemeOptions';
import { Switch } from 'shared/view/elements/Switch/Switch';
import { useDarkMode } from 'features/darkMode/store/hook';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { VertaLogo } from 'shared/view/elements/VertaLogo';

import WorkspacesBar from './WorkspacesBar/WorkspacesBar';

const Header: FC<React.PropsWithChildren<unknown>> = () => {
  const { pathname } = useLocation();
  const { isEnableDarkMode, changeIsEnableDarkModeHandler } = useDarkMode();
  const currentUser = useSelector(selectCurrentUserOrThrowError);
  const workspaces = useSelector(selectWorkspaces);
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const {
    errorReporting: { isEnablePlayground },
    isEnableDarkModeControl,
  } = useSelector(selectFlags);

  const { logoRedirect, onLogoClick } = useLogoManager({
    pathname,
    currentUser,
    currentWorkspace,
    workspaces,
  });
  const workspaceName = useWorkspaceNameFromURL();

  return (
    <Box sx={{ height: componentsSizes.appBar.height }}>
      <AppBar
        position="fixed"
        sx={{ color: 'text.primary', height: componentsSizes.appBar.height }}
      >
        <Toolbar>
          <a
            style={{ textDecoration: 'none' }}
            href={logoRedirect}
            onClick={onLogoClick}
          >
            <VertaLogo color="text.primary" onClickRedirect={false} />
          </a>

          {isEnablePlayground ? (
            <LinkButton
              to={routes.errorReporter.getRedirectPath({
                workspaceName,
              })}
            >
              Go to Error reporter playground
            </LinkButton>
          ) : null}

          <Stack
            spacing={2}
            alignItems="center"
            direction="row"
            marginLeft="auto"
          >
            {isEnableDarkModeControl ? (
              <Stack
                spacing={0.5}
                direction="row"
                display="flex"
                alignItems="center"
              >
                <IconAwesomeInfo
                  icon={ICONS.sun}
                  size="lg"
                  color={isEnableDarkMode ? undefined : 'primary.main'}
                />
                <Switch
                  checked={isEnableDarkMode}
                  onChange={(e) =>
                    changeIsEnableDarkModeHandler(e.target.checked)
                  }
                />
                <IconAwesomeInfo
                  icon={ICONS.moonCloud}
                  size="lg"
                  color={
                    isEnableDarkMode
                      ? 'primary.main'
                      : 'additionalBackground.inactive'
                  }
                />
              </Stack>
            ) : null}
            <HeaderSearch currentWorkspaceName={currentWorkspace.name} />
            <WorkspacesBar />
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

const useLogoManager = ({
  currentUser,
  currentWorkspace,
  workspaces,
  pathname,
}: {
  workspaces: IUserWorkspaces;
  currentWorkspace: IWorkspace;
  currentUser: CurrentUserData;
  pathname: string;
}) => {
  const defaultWorkspace = getDefaultWorkspace(
    currentUser.defaultWorkspaceId,
    workspaces
  );
  const logoRedirect = (() => {
    if (currentWorkspace.name !== defaultWorkspace.name) {
      return getUrlWithChangedWorkspace({
        workspaceName: defaultWorkspace.name,
        pathname,
      });
    }
    return '/';
  })();

  const onLogoClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    changeWorkspaceWithPageReloading({
      event,
      to: logoRedirect,
      workspace: defaultWorkspace,
    });
  };

  return { onLogoClick, logoRedirect };
};

export default Header;
