import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import { pick } from 'lodash';

import { selectCurrentWorkspaceName } from 'features/workspaces';
import { selectCurrentUserOrThrowError } from 'features/user';
import { selectFlags } from 'features/flags';
import { useOnboardingContext } from 'features/homepage/hooks/useOnboarding';
import AddNewUserButton from 'features/organizations/users/view/AddNewUserPopup/AddNewUserPopup';
import { trackEvent } from 'setup/app/analytics';
import { LinkButton } from 'shared/view/elements/Button';
import routes from 'shared/routes';

import WelcomeBanner from './WelcomeBanner';
import FirstTimeUserWidget from './FirstTimeUserWidget/FirstTimeUserWidget';
import { HomepageMonitoringWidget } from './HomepageMonitoringWidget';
import HomepageCatalogWidget from './HomepageCatalogWidget/HomepageCatalogWidget';
import { DiscoverPlatform } from './DiscoverPlatform/DiscoverPlatform';
import { AssistedDocumentationBanner } from './AssistedDocumentationBanner';
import { PlatformMonitoringWidget } from './PlatformMonitoringWidget';

export default function HomePageContent() {
  const {
    monitoring,
    isEnableRegistry,
    homepage: { isEnableCatalogWidget, isEnableMonitoringWidget },
    catalog: { isEnableMLAssistance },
    deployment: { isEnable: isEnableDeployment },
  } = useSelector(selectFlags);
  const workspaceName = useSelector(selectCurrentWorkspaceName);
  const currentUser = useSelector(selectCurrentUserOrThrowError);
  const { isHiddenGettingStarted } = useOnboardingContext();

  if (!isEnableDeployment) {
    window.location.replace(
      `${window.location.origin}${routes.llmProjectsList.getPath()}`
    );
  }

  return (
    <Grid container>
      <Grid
        item
        xxl={9}
        xl={11}
        md={12}
        margin="0 auto"
        sx={{ minWidth: '1232px' }}
      >
        <Grid container gap={4}>
          <Stack
            direction="row"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <Box>
              <WelcomeBanner />
            </Box>
            <Box>
              <AddNewUserButton
                popupProps={{
                  title: 'Invite users',
                  linkLabel: 'Org link',
                  linkDescription:
                    'Share this link with team members with existing Verta accounts.',
                  inputDescription:
                    'Invite new people to create an account with Verta and join your team.',
                  mainButtonProps: { children: 'Invite' },
                }}
                buttonProps={{
                  onClick: () =>
                    trackEvent({ type: 'homepage.invite_user_clicked' }),
                  children: 'Invite users',
                }}
                onSuccess={(emails) => {
                  trackEvent({
                    type: 'homepage.user_invited',
                    data: {
                      inviter: pick(currentUser, [
                        'id',
                        'email',
                        'firstName',
                        'lastName',
                        'jobTitle',
                        'company',
                      ]),
                      invitees: { emails },
                    },
                  });
                }}
              />
              <LinkButton
                to={routes.import.getRedirectPath({ workspaceName })}
                sx={{ ml: 2 }}
                variant="contained"
              >
                Import models
              </LinkButton>
            </Box>
          </Stack>
          {isEnableMLAssistance ? <AssistedDocumentationBanner /> : null}
          {!isHiddenGettingStarted ? <FirstTimeUserWidget /> : null}
          <PlatformMonitoringWidget />
          {monitoring.isEnable && isEnableMonitoringWidget ? (
            <HomepageMonitoringWidget
              currentUserId={currentUser.id}
              workspaceName={workspaceName}
            />
          ) : null}
          {isEnableRegistry && isEnableCatalogWidget ? (
            <HomepageCatalogWidget workspaceName={workspaceName} />
          ) : null}
          <DiscoverPlatform />
        </Grid>
      </Grid>
    </Grid>
  );
}
