interface OutlierDetection {
  inliers: number[];
  mean: number;
  median: number;
  outlierSigmaThreshold: number;
  outliers: number[];
  sigma: number;
  modelVersion: string;
}

export interface OutlierDetectionOverTime extends OutlierDetection {
  time: Date;
}

export const OUTLIER_DETECTION_MAX_SAMPLES = 100;
