import { gql } from '@apollo/client';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { IPagination } from 'shared/models/Pagination';
import { DATASET_VERSION_FRAGMENT } from 'shared/graphql/Datasets/DatasetVersion/DatasetVersion';
import { convertGraphqlDatasetVersion } from 'shared/graphql/Datasets/DatasetVersion/converters';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';

import * as GraphqlTypes from './graphql-types/datasetVersions.generated';

const DATASET_VERSIONS = gql`
  query DatasetVersions($datasetId: ID!, $query: DatasetVersionsQuery!) {
    dataset(id: $datasetId) {
      ... on Error {
        ...ErrorData
      }
      ... on Dataset {
        id
        name
        dateUpdated
        description
        allowedActions {
          ...AllowedActionsData
        }
        description
        owner {
          ...OwnerData
        }
        datasetVersions(query: $query) {
          datasetVersions {
            id
            ...DatasetVersionData
          }
          total
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${DATASET_VERSION_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;

export const useDatasetVersions = () => {
  const [loadQuery, query] = useCustomLazyQuery<
    GraphqlTypes.DatasetVersions,
    GraphqlTypes.DatasetVersionsVariables
  >(DATASET_VERSIONS);

  const { data, communication: loadingDatasetVersions } =
    resultToCommunicationWithData(convertResponse, query);

  const loadDatasetVersions = async ({
    datasetId,
    pagination,
  }: {
    datasetId: string;
    pagination: IPagination;
  }) => {
    return loadQuery({
      variables: {
        datasetId,
        query: {
          pagination: {
            page: pagination.currentPage + 1,
            limit: pagination.pageSize,
          },
        },
      },
    });
  };

  return {
    data,
    loadDatasetVersions,
    loadingDatasetVersions,
  };
};

const convertResponse = (res: {
  dataset: GraphqlTypes.DatasetVersions['dataset'];
}) => {
  return mapDataOrError(res.dataset, (x) => ({
    datasetVersions: x.datasetVersions.datasetVersions.map(
      convertGraphqlDatasetVersion
    ),
    total: x.datasetVersions.total,
    dataset: x,
  }));
};
