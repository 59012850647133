import { ArtifactWithDatasetVersionData } from 'shared/graphql/Artifact/graphql-types/Artifact.generated';

import { compareKeyedItemsProperties } from './keyedItemsPropertiesDiff';

export type DatasetsDiff = ReturnType<typeof compareDatasets>;

export const compareDatasets = (
  entities: Array<{
    id: string;
    datasets: Array<
      Pick<
        ArtifactWithDatasetVersionData,
        'key' | 'path' | 'linkedDatasetVersion'
      >
    >;
  }>
) => {
  return compareKeyedItemsProperties(
    (dataset) => dataset.key,
    ['key', 'path', 'linkedDatasetVersion', 'dataset'],
    entities.map(({ datasets }) =>
      datasets.map((dataset) => ({
        ...dataset,
        linkedDatasetVersion:
          dataset.linkedDatasetVersion?.__typename === 'DatasetVersion'
            ? {
                ...dataset.linkedDatasetVersion,
                dataset: {
                  id: dataset.linkedDatasetVersion.dataset.id,
                  name: dataset.linkedDatasetVersion.dataset.name,
                  workspaceName:
                    dataset.linkedDatasetVersion.dataset.workspace.name,
                },
              }
            : undefined,
        dataset:
          dataset.linkedDatasetVersion?.__typename === 'DatasetVersion'
            ? {
                id: dataset.linkedDatasetVersion.dataset.id,
                name: dataset.linkedDatasetVersion.dataset.name,
                workspaceName:
                  dataset.linkedDatasetVersion.dataset.workspace.name,
              }
            : undefined,
      }))
    )
  );
};
