import { isNil } from 'lodash';

import {
  formatDate,
  formatDateWithTime,
} from 'shared/utils/formatters/dateTime';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import CellRendererEmpty from 'shared/view/elements/DataGrid/columns/CellRendererEmpty';

type Props = {
  value: Date | number | undefined | null;
  showTime: boolean;
};

export default function CellRendererDate(props: Props) {
  if (isNil(props.value)) {
    return <CellRendererEmpty />;
  }

  const value = props.showTime
    ? formatDateWithTime(props.value)
    : formatDate(props.value);
  return <CellRendererString value={value} />;
}
