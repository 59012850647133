import { Box, Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import docsCopyResponse from '../images/docs-copy-response.png';

export const DocsCopyResponse = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Generate AI assisted content
    </Typography>
    <Typography variant="body2" mb={3}>
      Once you see the results, you can experiment with another prompt input or
      copy the text you prefer into the documentation editor.
    </Typography>
    <Box>
      <img
        src={docsCopyResponse}
        width="100%"
        height="auto"
        alt="homepage invite users button modal"
      />
    </Box>
  </WizardStepContent>
);
