import { Grid, Typography } from '@mui/material';

import { CustomDashboardChart } from 'generated/types';

import { DonutChartCard, IncidentType } from '../../shared/DonutChartCard';

interface IncidentsProps {
  isLoading: boolean;
  charts: CustomDashboardChart[];
}

export const Incidents = (props: IncidentsProps) => {
  const { charts, isLoading } = props;
  const incidentData = charts.map((chart) => {
    const [title, generatedBy] = chart.name.split(' by ');
    const type = chart.name.includes('Incident')
      ? IncidentType.INCIDENTS
      : IncidentType.MODEL;
    return {
      id: chart.name,
      title: title,
      type: type,
      generatedBy: generatedBy,
      values: chart.chartValues.map((value) => ({
        id: value.name,
        label: value.name,
        value: value.value,
      })),
    };
  });

  return (
    <Grid container direction="row" gap={3} wrap="nowrap" mt={5}>
      {incidentData.map((incident, incidentIndex) => (
        <Grid xs={3} item key={incident.id}>
          <Typography variant="h6" mb={3} height="38px">
            {incidentIndex === 0 || incidentIndex === 3 ? incident.type : null}
          </Typography>
          <DonutChartCard
            key={incident.id}
            data={incident.values}
            title={incident.title}
            dataType={incident.generatedBy}
            isLoading={isLoading}
            chartType={incident.type}
          />
        </Grid>
      ))}
    </Grid>
  );
};
