import React from 'react';
import { useSelector } from 'react-redux';

import Button from 'shared/view/elements/Button/Button';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { selectFlags } from 'features/flags';
import OptionalTooltip from 'shared/view/elements/Tooltip/OptionalTooltip';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import { RegisteredModelWithExperimentRunCreationForm } from 'features/registry/registeredModel/registeredModelWithExperimentRunCreation/store/createRegisteredModelVersionWithExistingOrNewModel/createRegisteredModelVersionWithExistingOrNewModel';
import {
  RegisteredModelWithExperimentRunFields,
  useForm,
} from 'features/registry/registeredModel/registeredModelWithExperimentRunCreation/view/RegisteredModelWithExperimentRunCreationPopup/RegisteredModelWithExperimentRunCreationPopup';

interface ILocalProps {
  experimentRunId: string;
}

const RegisteredModelWithExperimentRunCreation: React.FC<
  React.PropsWithChildren<ILocalProps>
> = ({ experimentRunId }) => {
  const { isEnableRegisteredModelVersionsLock } = useSelector(selectFlags);

  const { create, creating, initialSettings, registeredModels } = useForm({
    experimentRunId,
  });

  return (
    <PresetFormik<RegisteredModelWithExperimentRunCreationForm>
      initialValues={initialSettings}
      onSubmit={create}
    >
      {({ submitForm, isValid, setFieldValue, values }) => {
        const selectedRegisteredModel = registeredModels.find(
          (m) => m.name === values.modelName
        );
        const isHavePermissionToCreateVersion: boolean =
          selectedRegisteredModel?.allowedActions.update !== undefined
            ? selectedRegisteredModel.allowedActions.update
            : true;

        return (
          <FormStack>
            <RegisteredModelWithExperimentRunFields
              registeredModels={registeredModels}
              values={values}
              isEnableRegisteredModelVersionsLock={
                isEnableRegisteredModelVersionsLock
              }
              setFieldValue={setFieldValue}
            />

            <span>
              <OptionalTooltip
                showTooltip={!isHavePermissionToCreateVersion}
                type={'withSpan'}
                title={'You do not write access to create'}
              >
                <Button
                  onClick={submitForm}
                  isLoading={creating.isRequesting}
                  disabled={!isValid || !isHavePermissionToCreateVersion}
                >
                  Create
                </Button>
              </OptionalTooltip>
            </span>
          </FormStack>
        );
      }}
    </PresetFormik>
  );
};

export default RegisteredModelWithExperimentRunCreation;
