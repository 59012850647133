/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { ARTIFACT_FRAGMENT } from 'shared/graphql/Artifact/Artifact';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { ACTIVITY_FRAGMENT } from 'shared/graphql/registry/stage/Activity';

export const EVALUATION_FOR_COMPARING = gql`
  query EvaluationForComparing($versionId: ID!) {
    registeredModelVersion(id: $versionId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        version
        description
        dateCreated
        dateUpdated
        labels
        attributes {
          ...AttributeData
        }
        artifacts {
          ...ArtifactData
        }
        owner {
          ...OwnerData
        }
        registeredModel {
          id
          name
          workspace {
            id
            name
          }
        }
        activities {
          id
          ...ActivityFragment
          __typename
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
  ${ARTIFACT_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
`;
