import { Box, Stack, Typography } from '@mui/material';

import image from '../images/evaluate-and-refine.png';
import { Circle } from '../Circle';

export const EvaluateAndRefine = () => (
  <Stack direction="row" spacing={0} alignItems="center">
    <Box width="35%">
      <Stack maxWidth="306px" display="flex" alignItems="flex-start">
        <Circle>2</Circle>
        <Typography variant="h6" color="text.primary">
          Evaluate & refine
        </Typography>
        <Box>
          <Typography mb={2} fontFamily="DM Sans" variant="body2">
            Compare and evaluate outputs, refine your prompts with AI-Assisted
            suggestions and try every option at once to quickly achieve the best
            results.
            <br />
            <br />
            Verta prioritizes a human-in-the-loop approach to model evaluation
            at key points in the iteration cycle. Automation speeds up the
            iteration cycle. Ship with confidence and differentiate your GenAI
            products.
          </Typography>
        </Box>
      </Stack>
    </Box>
    <Box width="65%" ml={4}>
      <img
        src={image}
        width="100%"
        height="auto"
        alt="leaderboard comparing prompts results"
      />
    </Box>
  </Stack>
);
