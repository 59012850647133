import { mergeDeepRight } from 'ramda';
import {
  createTheme,
  Theme,
  ThemeOptions,
  Components,
  PaletteOptions,
  SimplePaletteColorOptions,
} from '@mui/material';

/**
 *  When using TypeScript 4.x and above
 *  according to https://mui.com/components/data-grid/getting-started/#typescript
 */
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { OmitStrict } from 'shared/utils/types';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import { muiThemeTypography } from './muiThemeFonts';

declare module '@mui/material/styles/createPalette' {
  interface TypeAdditionalBackground {
    content: string;
    tabs: string;
    breadcrumbs: string;
    pageContent: string;
    inactive: string;
    sidebar: string;
    sidebarContrast: string;
    sidebarActive: string;
    codeBlock: string;
    loginBackground: string;
    stepBackground: string;
    skeleton?: string;
    assistedDocs?: string;
    evaluationInput?: string;
    evaluationMetadata?: string;
    evaluationOutput?: string;
    evaluationGroundTruth?: string;
    evaluationTrace?: string;
  }

  interface TypeGridLayout {
    placeholder: {
      background: string;
      border: string;
      boxShadow: string;
    };
  }

  interface TypeCharts {
    accentColor: string;
    backgroundColor: string;
  }

  interface TypeBackground {
    default: string;
    paper: string;
    level1: string;
    level2: string;
    level3: string;
  }

  interface TypeBorder {
    default: string;
    alternative: string;
  }

  interface TypeRating {
    active: string;
  }

  interface PaletteOptions {
    additionalBackground: TypeAdditionalBackground;
    gridLayout: TypeGridLayout;
    default: SimplePaletteColorOptions;
    charts: TypeCharts;
    border: TypeBorder;
    rating: TypeRating;
  }
  interface Palette {
    additionalBackground: TypeAdditionalBackground;
    gridLayout: TypeGridLayout;
    default: SimplePaletteColorOptions;
    charts: TypeCharts;
    border: TypeBorder;
  }
  interface TypeObject {
    additionalBackground: TypeAdditionalBackground;
    gridLayout: TypeGridLayout;
    default: SimplePaletteColorOptions;
    charts: TypeCharts;
    border: TypeBorder;
  }
}
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxs: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

export const componentsSizes = {
  input: {
    maxWidth: 375,
    minWidth: 100,
  },
  appBar: {
    height: 63,
  },
  sidebar: {
    width: 72,
  },
  layout: {
    content: { padding: 32 },
  },
};

declare module '@mui/material/styles/createTheme' {
  interface ThemeOptions {
    componentsSizes: typeof componentsSizes;
  }
}

export interface ColorsOptions extends PaletteOptions {
  primary?: SimplePaletteColorOptions;
  success?: SimplePaletteColorOptions;
  warning?: SimplePaletteColorOptions;
  stepper?: SimplePaletteColorOptions;
  error?: SimplePaletteColorOptions;
}

const NOT_ALLOWED = 'not-allowed';

const breakpoints = {
  values: {
    xxs: 0, // **NEW**
    xs: 600, // MUI xs -> 0px
    sm: 960, // MUI sm -> 600px
    md: 1024, // MUI md -> 900px
    lg: 1200, // MUI lg -> 1200px
    xl: 1440, // MUI xl -> 1536px
    xxl: 1728, // **NEW**
  },
};

// Components
const MuiAccordion: Components['MuiAccordion'] = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      borderWidth: '1px',
      borderStyle: 'solid',
    },
  },
};

const MuiAccordionDetails: Components['MuiAccordionDetails'] = {
  styleOverrides: {
    root: {
      paddingTop: 0,
    },
  },
};

const MuiAccordionSummary: Components['MuiAccordionSummary'] = {
  defaultProps: {
    expandIcon: <IconAwesomeInfo icon={ICONS.angleDown} size="lg" />,
  },
  styleOverrides: {
    root: {
      gap: 8,
      '& .Mui-expanded': {
        minHeight: '0',
      },
    },
    content: {
      width: '100%',
      minWidth: 0,
      margin: '16px 0 !important',
    },
  },
};

const MuiAlert: Components['MuiAlert'] = {
  styleOverrides: {
    root: {
      padding: '8px 12px',
      '& .MuiAlert-icon': {
        color: 'inherit',
      },
    },
    // hide the original component and use the selector "& .MuiAlert-icon"
    icon: {
      display: 'none',
    },
    message: {
      padding: '0',
    },
    action: {
      paddingTop: '0',
      paddingLeft: '12px',
      marginRight: '0',
      '& .MuiButtonBase-root': {
        padding: '0',
      },
    },
  },
};

const MuiAlertTitle: Components['MuiAlertTitle'] = {
  styleOverrides: {
    root: {
      ...muiThemeTypography.subtitle2,
    },
  },
};

const MuiAppBar: Components['MuiAppBar'] = {
  styleOverrides: {
    root: {
      height: `${componentsSizes.appBar.height}px`,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      boxShadow: 'none',
      backgroundImage: 'none',
    },
  },
};

const MuiAutocomplete: Components['MuiAutocomplete'] = {
  styleOverrides: {
    input: {
      ...muiThemeTypography.body2,
    },
    noOptions: {
      ...muiThemeTypography.body2,
    },
    option: {
      ...muiThemeTypography.body2,
    },
    endAdornment: {
      right: '16px !important',
      height: '100%',
      top: 0,
      display: 'flex',
      alignItems: 'center',
    },
    popupIndicator: {
      fontSize: '16px',
    },
  },
};

const MuiAvatar: Components['MuiAvatar'] = {
  styleOverrides: {
    root: {
      width: 32,
      height: 32,
      fontSize: 'unset',
    },
  },
};

const MuiBadge: Components['MuiBadge'] = {
  styleOverrides: {
    badge: {
      right: -8,
      top: -4,
      height: '18px',
      minWidth: '18px',
    },
  },
};

const MuiBreadcrumbs: Components['MuiBreadcrumbs'] = {
  styleOverrides: {
    ol: {
      gap: '14px',
    },
    separator: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
};

const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    sizeMedium: {
      height: '40px',
    },
    root: {
      textTransform: 'none',
      boxShadow: 'unset',
    },
  },
};

const MuiCard: Components['MuiCard'] = {
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    root: {
      borderWidth: '1px',
      borderStyle: 'solid',
    },
  },
};

const MuiCheckbox: Components['MuiCheckbox'] = {
  styleOverrides: {
    root: {
      padding: 0,
      '&.Mui-disabled': {
        cursor: NOT_ALLOWED,
        pointerEvents: 'all',
      },
    },
  },
  defaultProps: {
    color: 'primary',
  },
};

const MuiChip: Components['MuiChip'] = {
  styleOverrides: {
    root: { paddingLeft: '8px' },
    icon: {
      marginRight: '8px',
      display: 'flex',
    },
    label: {
      paddingLeft: '0px',
      lineHeight: 1.5,
    },
  },
};

const MuiDataGrid: Components['MuiDataGrid'] = {
  styleOverrides: {
    root: {
      border: 'none',
    },
  },
};

const MuiDialog: Components['MuiDialog'] = {
  styleOverrides: {
    paper: {
      minWidth: 400,
    },
  },
};

const MuiDialogActions: Components['MuiDialogActions'] = {
  styleOverrides: {
    root: {
      padding: '24px',
      paddingTop: 0,
    },
  },
};

const MuiDrawer: Components['MuiDrawer'] = {
  defaultProps: {
    // https://github.com/mui-org/material-ui/issues/16518#issuecomment-625218550
    // without this prop when the drawer is opened, focus for inputs don't work
  },
  styleOverrides: {
    paper: {
      width: '353px',
    },
  },
};

const MuiFormControlLabel: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      marginLeft: 0,
      gap: 4,
      marginRight: 0,
    },
    label: {
      ...muiThemeTypography.body2,
    },
  },
};

const MuiFormHelperText: Components['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      ...muiThemeTypography.helperText,
    },
  },
};

const MuiFormLabel: Components['MuiFormLabel'] = {
  styleOverrides: {
    root: {
      ...muiThemeTypography.body2,
    },
  },
};

const MuiIconButton: Components['MuiIconButton'] = {
  styleOverrides: {
    root: {
      '&$disabled': {
        pointerEvents: 'none',
      },
    },
  },
};

const MuiInputBase: Components['MuiInputBase'] = {
  styleOverrides: {
    root: {
      '& .Mui-disabled': {
        cursor: NOT_ALLOWED,
      },
    },
    input: {
      ...muiThemeTypography.body2,
      '& .Mui-disabled': {
        cursor: NOT_ALLOWED,
      },
    },
    sizeSmall: {
      height: 'auto',
      minHeight: '40px',
    },
    multiline: {
      height: 'auto',
    },
  },
};

const MuiInputLabel: Components['MuiInputLabel'] = {
  styleOverrides: {
    root: {
      ...muiThemeTypography.body2,
    },
  },
};

const MuiListItemIcon: Components['MuiListItemIcon'] = {
  styleOverrides: {
    root: {
      minWidth: 0,
    },
  },
};

const MuiMenu: Components['MuiMenu'] = {
  styleOverrides: {
    paper: {
      marginTop: '8px',
      minWidth: 180,
    },
  },
};

const MuiOutlinedInput: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    notchedOutline: {
      ...muiThemeTypography.body2,
    },
  },
};

const MuiPaper = {
  styleOverrides: {
    root: {
      borderWidth: '1px',
      borderStyle: 'solid',
      backgroundImage: 'none',
    },
  },
};

const MuiPopover: Components['MuiPopover'] = {
  defaultProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
};

const MuiRadio: Components['MuiRadio'] = {
  styleOverrides: {
    root: {
      color: '#3f51b5',
    },
  },
};

const MuiSelect: Components['MuiSelect'] = {
  styleOverrides: {
    icon: {
      right: '16px',
      height: '100%',
      top: 0,
      display: 'flex',
      alignItems: 'center',
    },
  },
};

const MuiStack: Components['MuiStack'] = {
  defaultProps: {
    spacing: 1,
  },
};

const MuiTab: Components['MuiTab'] = {
  styleOverrides: {
    root: {
      textTransform: 'none',
    },
  },
};

const MuiTabs: Components['MuiTabs'] = {
  styleOverrides: {
    root: {
      height: 48,
      minHeight: 48,
    },
  },
};

const MuiToggleButton: Components['MuiToggleButton'] = {
  styleOverrides: {
    root: {
      textTransform: 'none',
      padding: '9px 11px',
    },
    sizeMedium: {
      height: '40px',
    },
  },
};

const MuiToolbar: Components['MuiToolbar'] = {
  styleOverrides: {
    root: {
      paddingLeft: '20px',
      paddingRight: componentsSizes.layout.content.padding,
    },
  },
};

const MuiTooltip: Components['MuiTooltip'] = {
  defaultProps: {
    placement: 'top-start',
    arrow: true,
  },
  styleOverrides: {
    tooltip: {
      fontFamily: 'Roboto, sans-serif',
      border: '1px solid #fff',
      borderRadius: '4px',
      color: '#fff',
      maxWidth: '290px',
      padding: '8px 16px',
      fontSize: '12px',
      fontWeight: 400,
    },
    arrow: {
      '&::before': {
        position: 'relative',
        border: '1px solid #fff',
      },
    },
    popper: {
      whiteSpace: 'pre-line',
    },
  },
};

// when we move to MUI complete, use this approach https://mui.com/customization/dark-mode/
const CommonMuiThemeOptions: ThemeOptions = {
  breakpoints,
  componentsSizes,
  components: {
    MuiAccordion,
    MuiAccordionDetails,
    MuiAccordionSummary,
    MuiAlert,
    MuiAlertTitle,
    MuiAppBar,
    MuiAutocomplete,
    MuiAvatar,
    MuiBadge,
    MuiBreadcrumbs,
    MuiButton,
    MuiCard,
    MuiCheckbox,
    MuiChip,
    MuiDataGrid,
    MuiDialog,
    MuiDialogActions,
    MuiDrawer,
    MuiFormControlLabel,
    MuiFormHelperText,
    MuiFormLabel,
    MuiIconButton,
    MuiInputBase,
    MuiInputLabel,
    MuiListItemIcon,
    MuiMenu,
    MuiOutlinedInput,
    MuiPaper,
    MuiPopover,
    MuiRadio,
    MuiSelect,
    MuiStack,
    MuiTab,
    MuiTabs,
    MuiToggleButton,
    MuiToolbar,
    MuiTooltip,
  },
};

export function createMuiThemeFromCommon(
  specificThemeOptions: OmitStrict<ThemeOptions, 'componentsSizes'>
): Theme {
  return createTheme(
    mergeDeepRight(CommonMuiThemeOptions, specificThemeOptions) as ThemeOptions
  );
}
