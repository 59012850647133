import { pipe } from 'ramda';

import { Primitive } from 'shared/utils/types';

import { isDifferentIfAtleastOneItemIsDifferentOrMissed } from '../helpers';

export type EntitiesPrimitivesDiff<E, V extends Primitive> = {
  isDifferent: boolean;
  primitivesWithEntities: Array<{
    primitive: V;
    entity: E;
  }>;
};

export const compareEntitiesPrimitives = <E, V extends Primitive>(
  getPrimitive: (entity: E) => V,
  entities: E[]
): EntitiesPrimitivesDiff<E, V> => {
  return pipe(
    () =>
      entities.map((entity) => ({ entity, primitive: getPrimitive(entity) })),
    (primitivesWithEntities) => ({
      isDifferent: isDifferentIfAtleastOneItemIsDifferentOrMissed(
        (x) => x,
        primitivesWithEntities.map(({ primitive }) => primitive)
      ),
      primitivesWithEntities,
    })
  )();
};
