import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { normalizeErrorFromUnknown } from 'shared/utils/normalizeError';
import { IApplicationState, IThunkActionDependencies } from 'setup/store/store';
import { toastCommunicationErrorActionCreator } from 'features/toast/store/actions';

import {
  IResetableAsyncAction,
  GetActionCreatorPayload,
  AsyncActionResult,
} from './types';

const makeSimpleApiRequest =
  <
    State extends IApplicationState,
    Deps extends IThunkActionDependencies,
    ResetableAsyncAction extends IResetableAsyncAction<any, any, any, any>,
  >(
    resetableAsyncAction: ResetableAsyncAction,
    errorToast:
      | { context: string; customErrorMessageByType?: Record<string, string> }
      | undefined
  ) =>
  (
    requestApi: (params: {
      payload: GetActionCreatorPayload<ResetableAsyncAction['request']>;
      dispatch: Dispatch;
      getState: () => State;
      dependencies: Deps;
    }) => Promise<GetActionCreatorPayload<ResetableAsyncAction['success']>>,
    handlers?: {
      onSuccess: (params: {
        requestPayload: GetActionCreatorPayload<
          ResetableAsyncAction['request']
        >;
        successPayload: GetActionCreatorPayload<
          ResetableAsyncAction['success']
        >;
        dispatch: Dispatch;
        getState: () => State;
        dependencies: Deps;
      }) => Promise<any>;
    },
    getFailureActionPayload?: ({
      requestPayload,
      error,
    }: {
      requestPayload: GetActionCreatorPayload<ResetableAsyncAction['request']>;
      error: GetActionCreatorPayload<ResetableAsyncAction['failure']>['error'];
      rawError: Error;
    }) => GetActionCreatorPayload<ResetableAsyncAction['failure']>
  ) => {
    return (
        requestPayload: GetActionCreatorPayload<ResetableAsyncAction['request']>
      ): ThunkAction<
        AsyncActionResult<ResetableAsyncAction['success']>,
        State,
        Deps,
        any
      > =>
      async (dispatch, getState, dependencies) => {
        dispatch(resetableAsyncAction.request(requestPayload));

        try {
          const res = await requestApi({
            payload: requestPayload,
            dispatch,
            getState,
            dependencies,
          });
          dispatch(resetableAsyncAction.success(res));
          handlers?.onSuccess({
            requestPayload,
            successPayload: res,
            dependencies,
            dispatch,
            getState,
          });
          return { type: 'success', payload: res };
          // eslint-disable-next-line @typescript-eslint/no-implicit-any-catch
        } catch (e: unknown) {
          const normalizedError = normalizeErrorFromUnknown(e);
          const errorResult = getFailureActionPayload
            ? getFailureActionPayload({
                error: normalizedError,
                requestPayload,
                rawError: e as Error,
              })
            : normalizedError;
          dispatch(resetableAsyncAction.failure(errorResult));
          if (errorToast) {
            dispatch(
              toastCommunicationErrorActionCreator(normalizedError, errorToast)
            );
          }
          return { type: 'error', payload: errorResult };
        }
      };
  };

export default makeSimpleApiRequest;
