import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client';
import { DocumentNode } from 'graphql';

import { OmitStrict } from 'shared/utils/types';

import { useCustomQuery } from './useCustomQuery';
import { useSubscribeOnVisibilityApiIsChanged } from './useSubscribeOnVisibilityApiIsChanged';

export default function usePollingQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode,
  options: OmitStrict<QueryHookOptions<TData, TVariables>, 'pollInterval'> & {
    pollInterval: number;
    subscribeOnVisibility?: boolean;
  }
): QueryResult<TData, TVariables> {
  const res = useCustomQuery<TData, TVariables>(query, options);
  const { subscribeOnVisibility = true } = options;
  useSubscribeOnVisibilityApiIsChanged((isHidden) => {
    if (subscribeOnVisibility) {
      if (isHidden) {
        res.stopPolling();
      } else if (!options.skip) {
        res.startPolling(options.pollInterval);
      }
    }
  });
  return res;
}
