import { gql } from '@apollo/client';
import { useCallback } from 'react';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import {
  convertMonitoringModel,
  MONITORING_MODEL_FRAGMENT,
} from 'shared/graphql/monitoring/MonitoringModel/MonitoringModel';
import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import {
  mapDataOrError,
  RestrictedGraphqlErrorOrData,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { useToast } from 'features/toast/store/hooks';
import { MONITORING_IO_DESCRIPTION_FRAGMENT } from 'shared/graphql/monitoring/MonitoringIODescription/MonitoringIODescription';
import { parseOrGetDefaultMonitoringModelState } from 'shared/graphql/monitoring/MonitoringModel/parseOrGetDefaultMonitoringModelState';
import {
  ALERT_SETTINGS_FRAGMENT,
  convertAlertSettings,
} from 'shared/graphql/monitoring/Alert/AlertSettings';

import {
  MonitoringModelQuery,
  MonitoringModelQueryVariables,
} from './graphql-types/useMonitoringModel.generated';

const MONITORING_MODEL_QUERY = gql`
  query MonitoringModelQuery($monitoringModelId: ID!) {
    monitoredModel(id: $monitoringModelId) {
      ... on Error {
        ...ErrorData
      }
      ... on MonitoredModel {
        id
        ...MonitoringModelFragment

        monitoredEntity {
          id
          metrics {
            ioDescriptions {
              ...MonitoringIODescriptionFragment
            }
          }
          alerts {
            alerts {
              id
              status
              featureName
              settings {
                ...AlertSettingsFragment
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${MONITORING_MODEL_FRAGMENT}
  ${MONITORING_IO_DESCRIPTION_FRAGMENT}
  ${ALERT_SETTINGS_FRAGMENT}
`;

export const useMonitoringModelQuery = ({
  monitoringModelId,
}: {
  monitoringModelId: string;
}) => {
  const queryResult = useCustomQuery<
    MonitoringModelQuery,
    MonitoringModelQueryVariables
  >(MONITORING_MODEL_QUERY, {
    variables: {
      monitoringModelId,
    },
  });

  const toast = useToast();

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert: useCallback(
      (res: MonitoringModelQuery) =>
        convert(res, (message) => toast(message, 'warning')),
      [toast]
    ),
    queryResult,
  });
};

const convert = (
  res: MonitoringModelQuery,
  toastWarning: (message: string) => void
): RestrictedGraphqlErrorOrData<MonitoringModel> => {
  return mapDataOrError(res.monitoredModel, (monitoredModel) => {
    const ioDescriptions =
      monitoredModel.monitoredEntity.metrics.ioDescriptions;

    const monitoringModel = convertMonitoringModel(monitoredModel);

    const state = parseOrGetDefaultMonitoringModelState({
      json: monitoredModel.json,
      ioDescriptions,
      registeredModelVersions: monitoringModel.registeredModelVersions,
      monitoringModel,
      toastWarning,
    });

    return {
      ...monitoringModel,
      alerts: monitoredModel.monitoredEntity.alerts.alerts.map((a) => ({
        ...a,
        settings: convertAlertSettings(a.settings),
      })),
      ioDescriptions,
      state,
    };
  });
};
