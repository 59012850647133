import {
  useBreadcrumbItem,
  useAppBreadcrumbs,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import routes from 'shared/routes';
import { useMonitoringModelContext } from 'features/monitoring/models/model/store/state/context';
import CreateMonitoringAlert from 'features/monitoring/alerts/view/CreateMonitoringAlert/CreateMonitoringAlert';

import { useMonitoringModelPageBreadcrumbs } from '../shared/useMonitoringModelPageBreadcrumbs';
import MonitoringAlertsLayout from '../shared/MonitoringAlertsLayout/MonitoringAlertsLayout';

const MonitoringAlertCreationPage = () => {
  const { model, updateModelState } = useMonitoringModelContext();
  const breadcrumbs = useAppBreadcrumbs(
    useMonitoringModelPageBreadcrumbs({
      modelName: model.name,
    }),
    useBreadcrumbItem({
      name: 'Alerts: Create',
      route: routes.monitoringAlertCreation,
    })
  );

  return (
    <MonitoringAlertsLayout
      model={model}
      updateModelState={updateModelState}
      breadcrumbs={breadcrumbs}
    >
      <CreateMonitoringAlert
        ioDescriptions={model.ioDescriptions}
        model={model}
      />
    </MonitoringAlertsLayout>
  );
};

export default MonitoringAlertCreationPage;
