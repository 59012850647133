import { head, isNil, pipe, values } from 'ramda';

import { ArtifactData } from 'shared/graphql/Artifact/graphql-types/Artifact.generated';
import { CodeVersion } from 'shared/graphql/CodeVersion/graphql-types/CodeVersion.generated';
import { CodeVersionBlob } from 'shared/graphql/CodeVersion/graphql-types/CodeVersionFromBlob.generated';
import { CodeVersionGit } from 'shared/graphql/CodeVersion/graphql-types/CodeVersion.generated';
import {
  MaybeGitInfoFromUrl,
  parseGitInfoFromUrl,
} from 'shared/utils/gitUrl/parseGitInfoFromUrl';

import compareItemsWithTheSameKey from '../compareItemsWithTheSameKey';
import { artifactComparedProps } from './artifactsComparing';
import {
  compareItemsProperties,
  ItemsPropertiesDiff,
} from './itemsPropertiesComparing';

export type CodeVersionsDiff = Array<{
  isDifferent: boolean;
  key: string;
  data: {
    git: GitDiff | undefined;
    artifact: ArtifactDiff | undefined;
  };
}>;

type CodeVersionGitForDiff = Pick<
  CodeVersionGit,
  'branch' | 'isDirty' | 'hash'
> & {
  remoteRepoUrl: MaybeGitInfoFromUrl;
  execPath: string | undefined;
};

export type GitDiff = ItemsPropertiesDiff<
  CodeVersionGitForDiff,
  keyof CodeVersionGitForDiff
>;
export type ArtifactDiff = ItemsPropertiesDiff<
  ArtifactData,
  (typeof artifactComparedProps)[number]
>;

type EntityWithCodeVersions = {
  codeVersions: CodeVersionBlob[];
};

export const compareCodeVersions = (
  entities: Array<EntityWithCodeVersions>
): CodeVersionsDiff => {
  return compareItemsWithTheSameKey(
    (x) => x.key,
    (codeVersionsByKey, key): CodeVersionsDiff[number] => {
      const data = {
        artifact: compareArtifactCodeVersions(codeVersionsByKey),
        git: compareGitCodeVersions(codeVersionsByKey),
      };
      return {
        key,
        data,
        isDifferent: values(data).some((x) => x?.isDifferent),
      };
    },
    entities.map(({ codeVersions }) =>
      codeVersions.map(({ key, codeVersion }) => ({ ...codeVersion, key }))
    )
  );
};

const compareArtifactCodeVersions = (
  codeVersionsByKey: Array<CodeVersion | undefined>
): ArtifactDiff | undefined => {
  const artifactsForComparing = pipe(
    () =>
      codeVersionsByKey.map((codeVersion) =>
        codeVersion?.__typename === 'CodeVersionArchive'
          ? codeVersion.artifact
          : undefined
      ),
    (x) => (x.every(isNil) ? undefined : x)
  )();
  return (
    artifactsForComparing &&
    compareItemsProperties(artifactsForComparing, artifactComparedProps)
  );
};

const compareGitCodeVersions = (
  codeVersionsByKey: Array<CodeVersion | undefined>
): GitDiff | undefined => {
  const gitCodeVersionsForComparing = pipe(
    () =>
      codeVersionsByKey.map((codeVersion) =>
        codeVersion?.__typename === 'CodeVersionGit'
          ? {
              hash: codeVersion.hash,
              branch: codeVersion.branch,
              execPath: head(codeVersion.filepaths),
              remoteRepoUrl: parseGitInfoFromUrl(codeVersion.repo),
              isDirty: codeVersion.isDirty,
            }
          : undefined
      ),
    (x) => (x.every(isNil) ? undefined : x)
  )();

  return (
    gitCodeVersionsForComparing &&
    compareItemsProperties(gitCodeVersionsForComparing, [
      'hash',
      'execPath',
      'isDirty',
      'remoteRepoUrl',
      'branch',
    ])
  );
};
