import { isArray, isNil } from 'lodash';

import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';
import { ExtractByTypename } from 'shared/utils/types';
import { PredicateOperator, SortingQuery } from 'generated/types';
import { VERTA_EVALUATION_LABEL } from 'features/evaluations/shared/utils/evaluation';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';

import {
  EVALUATIONS_PROJECTS_QUERY,
  EvaluationProjectsList,
  EvaluationProjectsListVariables,
} from '../graphql';

type EvaluationProjects = ExtractByTypename<
  EvaluationProjectsList['workspace'],
  'Workspace'
>['registeredModels']['models'];

export type EvaluationProject = EvaluationProjects[0];

type Props = Pick<
  EvaluationProjectsListVariables,
  'organizationId' | 'workspaceName' | 'stringPredicates'
> & {
  sorting?: EvaluationProjectsListVariables['sorting'];
};

export const useWorkspaceEvaluationProjects = (props: Props) => {
  const {
    organizationId = '',
    workspaceName,
    stringPredicates,
    sorting = {
      ascending: true,
      key: 'time_updated',
    } as SortingQuery,
  } = props;

  const _stringPredicates = [
    {
      key: 'label',
      operator: PredicateOperator.EQ,
      value: VERTA_EVALUATION_LABEL,
    },
  ];
  if (isArray(stringPredicates)) {
    _stringPredicates.concat(stringPredicates);
  } else if (!isNil(stringPredicates)) {
    _stringPredicates.push(stringPredicates);
  }
  const result = useCustomQuery<
    EvaluationProjectsList,
    EvaluationProjectsListVariables
  >(EVALUATIONS_PROJECTS_QUERY, {
    variables: {
      organizationId,
      workspaceName,
      stringPredicates: _stringPredicates,
      sorting,
    },
  });
  return resultToCommunicationWithData(convert, result);
};

const convert = (res: EvaluationProjectsList) =>
  mapDataOrError(
    res.workspace,
    (workspaceData) => workspaceData.registeredModels.models
  );
