import { NavigateFunction } from 'react-router-dom';

import { Filter } from 'shared/models/Filters/Filter';

import { saveFiltersInUrl, getFiltersFromUrl } from './filtersInURL';
import {
  saveFiltersInLocalStorage,
  getFiltersFromLocalStorage,
  EntityInfo,
} from './filtersInLocalStorage';

export function saveFiltersInStorages(
  entityInfo: EntityInfo,
  navigate: NavigateFunction,
  filters: Filter[]
) {
  saveFiltersInUrl(navigate, filters);
  saveFiltersInLocalStorage(entityInfo, filters);
}

export function getFiltersFromStorages(entityInfo: EntityInfo) {
  const filtersFromUrl = getFiltersFromUrl();
  const filtersFromLocalStorage = getFiltersFromLocalStorage(entityInfo);
  if (filtersFromUrl.length > 0) {
    return filtersFromUrl;
  }
  return filtersFromLocalStorage;
}
