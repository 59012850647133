import ExperimentRunsDataService from 'services/experimentRuns/ExperimentRunsDataService';
import ExperimentsDataService from 'services/experiments/ExperimentsDataService';
import ProjectDataService from 'services/projects/ProjectDataService';
import RegisteredModelVersionService from 'services/registry/registeredModelVersion/RegisteredModelVersionService';
import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';

import { EntityWithArtifactsType, ILoadArtifactUrlService } from './types';

export const getLoadArtifactUrlService = ({
  entityType,
}: {
  entityType: EntityWithArtifactsType;
}): ILoadArtifactUrlService => {
  switch (entityType) {
    case 'experiment':
      return ExperimentsDataService;
    case 'experimentRun':
      return ExperimentRunsDataService;
    case 'project':
      return ProjectDataService;
    case 'registeredModelVersion':
      return RegisteredModelVersionService;
    default:
      return exhaustiveCheck(entityType);
  }
};
