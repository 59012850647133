import {
  convertServerDatasetBlobToClient,
  ServerDatasetBlob,
} from 'services/serverModel/Versioning/RepositoryData/Blob/DatasetBlob';
import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';

interface IServerQueryBlob {
  components: Array<IServerQueryComponent>;
}

interface IServerQueryComponent {
  query: string;
  data_source_uri: string;
  execution_timestamp: string;
  num_records: number;
}

export const convertDatasetVersionBlob = (blob: string) => {
  const data = JSON.parse(blob) as {
    query?: IServerQueryBlob;
  } & Partial<ServerDatasetBlob>;

  if (data.query) {
    return {
      type: 'query' as const,
      info: {
        category: 'dataset' as const,
        type: 'query' as const,
        data: {
          components: data.query.components.map(convertGraphqlQueryComponent),
        },
      },
    };
  }

  const info = convertServerDatasetBlobToClient(data);

  switch (info.type) {
    case 'path':
      return {
        type: 'path' as const,
        info,
      };
    case 's3':
      return {
        type: 's3' as const,
        info,
      };
    default: {
      exhaustiveCheck(info);
    }
  }
};

const convertGraphqlQueryComponent = (
  serverComponent: IServerQueryComponent
) => {
  return {
    query: serverComponent.query,
    dataSourceUri: serverComponent.data_source_uri,
    executionTimestamp: +serverComponent.execution_timestamp,
    numRecords: serverComponent.num_records,
  };
};
