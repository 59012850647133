import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';

import MonitoringPage from './MonitoringPage/MonitoringPage';
import MonitoringModelPages from './MonitoringModelPages/MonitoringModelPagesWithErrorHandling';
import MonitoringOverviewPage from './MonitoringModelPages/MonitoringOverviewPage/MonitoringOverviewPage';
import MonitoringDashboardPage from './MonitoringModelPages/MonitoringDashboardPage/MonitoringDashboardPage';
import MonitoringPerformancePage from './MonitoringModelPages/MonitoringPerformancePage/MonitoringPerformancePage';
import MonitoringComparePage from './MonitoringModelPages/MonitoringComparePage/MonitoringComparePage';
import MonitoringAlertsPage from './MonitoringModelPages/MonitoringAlertsPage/MonitoringAlertsPage';
import MonitoringAlertCreationPage from './MonitoringModelPages/MonitoringAlertCreationPage/MonitoringAlertCreationPage';
import MonitoringAlertPage from './MonitoringModelPages/MonitoringAlertPage/MonitoringAlertPage';
import MonitoringAlertHistoryPage from './MonitoringModelPages/MonitoringAlertHistoryPage/MonitoringAlertHistoryPage';
import MonitoringModelPageRedirect from './MonitoringModelPages/MonitoringModelPageRedirect/MonitoringModelPageRedirect';

export const monitoringPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.monitoring.getPath(),
      element: <MonitoringPage />,
    },
    {
      path: routes.monitoringModel.getPath(),
      element: <MonitoringModelPages />,
      children: [
        {
          path: routes.monitoringDashboard.getPath(),
          element: <MonitoringDashboardPage />,
        },
        {
          path: routes.monitoringOverview.getPath(),
          element: <MonitoringOverviewPage />,
        },
        {
          path: routes.monitoringPerformance.getPath(),
          element: <MonitoringPerformancePage />,
        },
        {
          path: routes.monitoringCompare.getPath(),
          element: <MonitoringComparePage />,
        },
        {
          path: routes.monitoringAlerts.getPath(),
          element: <MonitoringAlertsPage />,
        },
        {
          path: routes.monitoringAlertCreation.getPath(),
          element: <MonitoringAlertCreationPage />,
        },
        {
          path: routes.monitoringAlert.getPath(),
          element: <MonitoringAlertPage />,
        },
        {
          path: routes.monitoringAlertHistory.getPath(),
          element: <MonitoringAlertHistoryPage />,
        },
        {
          path: routes.monitoringModel.getPath(),
          element: <MonitoringModelPageRedirect />,
        },
      ],
    },
  ],
};
