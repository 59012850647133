/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { WorkspaceName } from 'shared/models/Workspace';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { BUILD_OR_ERROR_FRAGMENT } from 'shared/graphql/Deployment/Endpoint/Endpoint';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import usePollingQuery from 'shared/view/hooks/apollo/usePollingQuery';
import { getEndpointPollingInterval } from 'shared/models/Deployment/canary/Endpoint';
import { KAFKA_CONFIGURATIONS_SIMPLIFIED_LIST_FRAGMENT } from 'shared/graphql/Deployment/Endpoint/KafkaConfig/KafkaConfiguration';
import { EndpointWithKafkaConfigurations } from 'features/deployment/canary/shared/EndpointWithKafkaConfigurations';
import {
  mapDataOrError,
  RestrictedGraphqlErrorOrData,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { ExtractByTypename } from 'shared/utils/types';

import * as GraphqlTypes from './graphql-types/useEndpointBuilds.generated';

type Workspace = ExtractByTypename<
  GraphqlTypes.EndpointBuilds['workspace'],
  'Workspace'
>;
export type Endpoint = ExtractByTypename<Workspace['endpoint'], 'Endpoint'>;

const ENDPOINT_BUILDS = gql`
  query EndpointBuilds(
    $workspaceName: String!
    $organizationId: ID
    $endpointId: ID!
  ) {
    kafkaConfigurations {
      id
      ...KafkaConfigurationsSimplifiedListData
    }
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoint(id: $endpointId) {
          ... on Error {
            ...ErrorData
          }
          ... on Endpoint {
            id
            path
            allowedActions {
              ...AllowedActionsData
            }
            owner {
              ...OwnerData
            }
            description
            environments {
              id
              components {
                buildID
                status
                build {
                  ...BuildOrErrorData
                }
              }
              rolloutStatus {
                time
                components {
                  buildID
                  ratio
                }
              }
            }
            workspace {
              id
              ...WorkspaceData
            }
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${BUILD_OR_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${KAFKA_CONFIGURATIONS_SIMPLIFIED_LIST_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;

const useEndpointBuilds = ({
  endpointId,
  workspaceName,
  organizationId,
}: {
  endpointId: string;
  workspaceName: WorkspaceName;
  organizationId: string | undefined;
}) => {
  const queryResult = usePollingQuery<
    GraphqlTypes.EndpointBuilds,
    GraphqlTypes.EndpointBuildsVariables
  >(ENDPOINT_BUILDS, {
    variables: {
      endpointId,
      workspaceName,
      organizationId,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    returnPartialData: true,
    pollInterval: getEndpointPollingInterval(),
  });

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert,
    queryResult,
  });
};

function memoizedConvert(
  res: GraphqlTypes.EndpointBuilds
): RestrictedGraphqlErrorOrData<EndpointWithKafkaConfigurations<Endpoint>> {
  return mapDataOrError(
    res.workspace,
    (x) => x.endpoint,
    (endpoint) => ({
      endpoint,
      kafkaConfigurations: res.kafkaConfigurations.configurations,
    })
  );
}

export default useEndpointBuilds;
