import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';
import {
  useOnboardingContext,
  AdvancedFeaturesContent,
} from 'features/homepage/hooks/useOnboarding';

import customAttrModel from '../images/custom-attribute-on-model.png';

export const CustomAttrModel = (props: WizardStepContentProps) => {
  const { setSelectedAdvancedFeaturesContent } = useOnboardingContext();

  return (
    <WizardStepContent
      {...props}
      buttons={{
        back: {
          onClick: () =>
            setSelectedAdvancedFeaturesContent(
              AdvancedFeaturesContent.DISCOVER_FEATURES
            ),
          skipWizardBack: true,
        },
      }}
    >
      <Typography variant="subtitle1" mb={2}>
        Create custom attributes
      </Typography>
      <Typography variant="body2" mb={2}>
        Custom attributes are completely customizable model and version
        metadata, allowing every team member to record, document, and track what
        matters most to them.
      </Typography>
      <Typography variant="body2" mb={3}>
        This transforms your catalog into an accurate system of record for any
        model-related detail, seamlessly bridging technical and business
        tracking tools. You can then feed this data into any dashboards or
        planning processes.
      </Typography>
      <img
        src={customAttrModel}
        alt="custom attributes on model"
        width="90%"
        height="auto"
      />
    </WizardStepContent>
  );
};
