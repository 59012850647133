import { createAction } from 'typesafe-actions';

import { ActionResult } from 'setup/store/store';
import { AppError } from 'shared/models/Error';
import normalizeError from 'shared/utils/normalizeError';
import * as RecentSearches from 'shared/models/HighLevelSearch/RecentSearches';
import { ISearchSettings } from 'shared/models/HighLevelSearch/SearchSettings';
import { getAvailableSearchEntities } from 'shared/models/HighLevelSearch/SearchEntities';
import makeHighLevelSearchService, {
  ILoadEntitiesByTypeResult,
} from 'services/highLevelSearch/HighLevelSearchService';
import { selectFlags } from 'features/flags';

import { paginationSettings } from '../constants';
import { updateRecentSearchesToLocalStorage } from '../recentSearchesFromLocalStorage';

export const loadEntitiesByTypeActions = {
  success: createAction(
    '@@highLevelSearch/LOAD_ENTITIES_BY_TYPE_SUCCESS'
  )<ILoadEntitiesByTypeResult>(),
  failure: createAction(
    '@@highLevelSearch/LOAD_ENTITIES_BY_TYPE_FAILURE'
  )<AppError>(),
};
export const loadEntitiesRequest = createAction(
  '@@highLevelSearch/LOAD_ENTITIES_REQUEST'
)<
  ISearchSettings & {
    pageSize: number;
    loadType: 'activeEntitiesAndUpdateOthers' | 'onlyActive';
  }
>();

export const loadEntities =
  (
    payload: ISearchSettings & {
      pageSize: number;
      loadType: 'activeEntitiesAndUpdateOthers' | 'onlyActive';
    }
  ): ActionResult =>
  async (dispatch, getState, { apolloClient }) => {
    dispatch(loadEntitiesRequest(payload));

    const state = getState();
    const isEnableRegistry = selectFlags(state).isEnableRegistry;

    const settings = {
      pagination: {
        currentPage: payload.currentPage + 1,
        pageSize: payload.pageSize,
      },
      searchSettings: payload,
      workspaceName: payload.workspaceName,
      organizationId: payload.organizationId,
      availableSearchEntities: getAvailableSearchEntities({
        isEnableRegistry,
      }),
    };

    if (payload.loadType === 'activeEntitiesAndUpdateOthers') {
      await makeHighLevelSearchService(
        apolloClient
      ).loadFullEntitiesByTypeAndUpdateOthersCounts(
        {
          onSuccess: (data) => {
            dispatch(loadEntitiesByTypeActions.success(data));
          },
          onError: (error) => {
            dispatch(loadEntitiesByTypeActions.failure(normalizeError(error)));
          },
        },
        settings
      );
    } else {
      const data =
        await makeHighLevelSearchService(apolloClient).loadFullEntitiesByType(
          settings
        );
      dispatch(loadEntitiesByTypeActions.success(data));
    }
  };

export const setSearchValue =
  (searchSettings: ISearchSettings, value: string): ActionResult =>
  async (dispatch) => {
    if (!value) {
      return;
    }
    dispatch(
      loadEntities({
        loadType: 'activeEntitiesAndUpdateOthers',
        ...searchSettings,
        pageSize: paginationSettings.pageSize,
      })
    );

    addNewRecentSearch(value);
  };

export const addNewRecentSearch = (value: string) => {
  updateRecentSearchesToLocalStorage((recentSearches) =>
    RecentSearches.addRecentSearch(value, recentSearches)
  );
};

export const setFilter =
  (searchSettings: ISearchSettings): ActionResult =>
  async (dispatch) => {
    dispatch(
      loadEntities({
        loadType: 'onlyActive',
        ...searchSettings,
        pageSize: paginationSettings.pageSize,
      })
    );
  };
