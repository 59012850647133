import {
  combineValidators,
  validateNotEmpty,
  skipWhenEmpty,
} from 'shared/utils/validators';

export const makeOrganizationName = (str: string): string => {
  return str
    .replace(/[^a-zA-Z0-9-._!]+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

export const validateOrganizationName = (str: string): string | undefined => {
  const validateSymbols = () =>
    /^[a-zA-Z0-9-._!]+/.test(str)
      ? undefined
      : `name can contain A-Z, a-z, 0-9, or the following symbols ("!", " ", ".", "_", "-")`;

  return combineValidators([validateNotEmpty('name'), validateSymbols])(str);
};

const validateAlphaNumHyphen = (str: string, field: string) =>
  /^[a-zA-Z0-9-]*$/.test(str)
    ? undefined
    : `The ${field} may only contain alphanumeric characters or hyphens`;
export const validateOrganizationNamespace = (
  str: string
): string | undefined => {
  const validateSymbols = () => validateAlphaNumHyphen(str, 'namespace');
  const validateNamespaceFormat = () => {
    const terms = str.split('--');
    if (
      terms.length === 2 &&
      /^.*[^-]$/.test(terms[0]) &&
      /^[^-].*$/.test(terms[1])
    ) {
      return undefined;
    }

    return 'Namespace must follow the format {cluster--namespace} with one, and only one, set of "--" double dashes';
  };

  return combineValidators([
    skipWhenEmpty(validateSymbols),
    skipWhenEmpty(validateNamespaceFormat),
  ])(str);
};
