import { route, param } from 'shared/routes/shared/routesTreeBuilder';

export const privateRoutes = route('organization', {
  tag: 'org',
})(
  param()('name', {
    tag: 'organization',
  })(
    route('users', {
      tag: 'organizationUsers',
    })(),
    route('groups', {
      tag: 'organizationGroups',
    })(),
    route('roles-and-permissions', {
      tag: 'organizationRolesAndPermissions',
    })(),
    route('workspaces', {
      tag: 'organizationWorkspaces',
    })(),
    route('governance', {
      tag: 'organizationGovernance',
    })(),
    route('custom-attributes', {
      tag: 'organizationCustomAttributes',
    })()
  )
);
