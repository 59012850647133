import { MonitoringFilter } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';
import { TimeRange } from 'shared/utils/TimeRange';

import { MonitoredModelType } from '../../MonitoredModelType';
import { MonitoringAggregation } from '../../MonitoringAggregation';
import { getAggregationByAlertAggregationWindow } from '../../MonitoringAlert/AlertAggregationWindow';
import { MonitoringAlert } from '../../MonitoringAlert/MonitoringAlert';
import { MonitoringIODescription } from '../../MonitoringIODescription';
import { MonitoringModel } from '../../MonitoringModel';
import { MonitoringModelStatus } from '../../MonitoringModelStatus';

export interface MonitoringWidgetExternalDeps {
  timeRange: TimeRange;
  aggregation: MonitoringAggregation;
  monitoredEntityId: string;
  ioDescriptions: MonitoringIODescription[];
  workspaceName: string;
  monitoringModelId: string;
  registeredModelVersionIds: string[];
  alerts: MonitoringModel['alerts'];
  monitoredModelType: MonitoredModelType;
  filters: MonitoringFilter[];
  status: MonitoringModelStatus;
}

export const getMonitoringWidgetExternalDepsFromModel = (
  model: MonitoringModel
): MonitoringWidgetExternalDeps => {
  return {
    aggregation: model.state.aggregation,
    alerts: model.alerts,
    ioDescriptions: model.ioDescriptions,
    monitoredEntityId: model.monitoredEntity.id,
    monitoringModelId: model.id,
    workspaceName: model.workspaceName,
    registeredModelVersionIds: [model.state.registeredModelVersion.id],
    timeRange: model.state.timeRange,
    monitoredModelType: model.type,
    filters: model.state.filters,
    status: model.status,
  };
};

export const getAlertWidgetExternalDeps = (props: {
  model: MonitoringModel;
  alert: MonitoringAlert;
}) => {
  return {
    ...getMonitoringWidgetExternalDepsFromModel(props.model),
    aggregation: getAggregationByAlertAggregationWindow(
      props.alert.aggregationWindow
    ),
  };
};
