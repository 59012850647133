import { useEffect, useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Select as MUISelect, SelectChangeEvent } from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useTheme } from '@mui/material';
import { Stack } from '@mui/material';

import isNotNil from 'shared/utils/isNotNill';
import VertaSelectBase, {
  SelectCommonProps,
  useSelectBaseCommonProps,
} from 'shared/view/elements/Selects/shared/view/VertaSelectBase';
import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import { useVertaSelectOptions } from 'shared/view/elements/Selects/shared/hooks/useVertaSelectOptions';
import { OmitStrict } from 'shared/utils/types';
import {
  IGroupedOptions,
  IOptionType,
} from 'shared/view/elements/Selects/shared/types';

import { IconAwesomeInfo } from '../../IconAwesome/IconAwesomeInfo';

type Props<T> = SelectCommonProps & {
  options: Array<IOptionType<T>> | IGroupedOptions<T>;
  value: IOptionType<T> | undefined;
  onChange(value: IOptionType<T>): void;
  icon?: IconDefinition;
};

const Select = <T extends unknown = string>(props: Props<T>) => {
  const { ref } = useResizeDetector();

  const { firstOption, children } = useVertaSelectOptions(
    props.options,
    props.withDescription ?? false
  );
  const commonProps = useSelectBaseCommonProps(props, !isNotNil(firstOption));

  useAutomaticallySelectSingleOptionForRequired(props, firstOption);

  /**
   * Workaround. MUI Select component have rule that to drop his value do unselected state you need to pass empty string ""
   * We work with IOptionType<T>, in this case we handle value under cover of this component to provide empty string to
   * MUI component if IOptionType<T> value === undefined/null; In this case value for MUI component will be substituted
   * with empty string and component will be dropped to default state
   */
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const value: IOptionType<T> | undefined = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => (isNotNil(props.value?.value) ? props.value : ('' as any)),
    [props.value]
  );

  /**
   * Workaround typecasting. The reason is that onChange type from MUI Select provides union with generic T and string.
   * But our Select component works only with IOptionType, so it will be impossible to get event with string
   */

  const { spacing } = useTheme();

  const onChange = (event: SelectChangeEvent<IOptionType<T>>) => {
    props.onChange(event.target.value as IOptionType<T>);
  };

  return (
    <>
      <VertaSelectBase ref={ref} {...props} label={commonProps.label}>
        <MUISelect
          {...commonProps}
          multiple={false}
          startAdornment={
            props.icon ? (
              <div
                style={{
                  paddingRight: spacing(1),
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IconAwesomeInfo icon={props.icon} />
              </div>
            ) : undefined
          }
          value={value}
          onChange={onChange}
          endAdornment={
            props.endAdornmentIcons ? (
              <Stack direction="row" alignItems="center" mr={2}>
                {props.endAdornmentIcons}
              </Stack>
            ) : (
              commonProps.endAdornment
            )
          }
          // Added padding below to avoid overflowing ellipsis
          renderValue={(option: IOptionType<T> | string | undefined) => {
            let label = props.placeholder ?? '';

            if (typeof option === 'object' && option.label) {
              label = option.label;
            }

            return (
              <div
                style={{
                  paddingRight: props.withoutArrow ? '6px' : spacing(2),
                  textAlign: props.withoutArrow ? 'center' : undefined,
                }}
              >
                {typeof option === 'object' && Boolean(option.element) ? (
                  option.element
                ) : (
                  <DynamicTypography
                    dataTest={'selected-item'}
                    value={label}
                    sx={{
                      color: !Boolean(option) ? 'text.secondary' : undefined,
                    }}
                  />
                )}
              </div>
            );
          }}
          displayEmpty={true}
          MenuProps={{
            ...commonProps.MenuProps,
            MenuListProps: {
              ...commonProps.MenuProps?.MenuListProps,
              sx: {
                ...commonProps.MenuProps?.MenuListProps?.sx,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                width: ref.current?.children[0]?.clientWidth,
              },
            },
          }}
        >
          {children}
        </MUISelect>
      </VertaSelectBase>
    </>
  );
};
function useAutomaticallySelectSingleOptionForRequired<T>(
  props: OmitStrict<Props<T>, 'options'>,
  firstOption: IOptionType<T> | null
) {
  const { required, value, onChange } = props;

  useEffect(() => {
    if (required && !isNotNil(value) && isNotNil(firstOption)) {
      onChange(firstOption);
    }
  }, [onChange, required, value, firstOption]);
}

export default Select;
