/*
  This error is suppressed by chrome and firefox but not by cypress and sentry. We need to explicitly suppress 
  it to avoid false positives errors.
  ref:
    - https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
    - https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
*/

export const RESIZE_OBSERVER_LOOP_ERROR_REGEX =
  /.*ResizeObserver loop limit exceeded*/;
