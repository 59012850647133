import { FetchResult, Operation } from '@apollo/client';
import { GraphQLError } from 'graphql';

import { JsonData } from 'shared/utils/json';

export type GraphqlRequestResponse = {
  type: 'graphql';
  request: {
    path: string;
    payload: {
      variables: Record<string, JsonData>;
      operationName: string;
    };
  };
  response: {
    statusInfo: {
      text: string | undefined;
      code: number | undefined;
    };
    payload:
      | {
          // todo: rename
          type: 'errorOrOk';
          data: JsonData;
          errors: ReadonlyArray<GraphQLError> | undefined;
        }
      | {
          // todo: rename
          type: 'serverError';
          data: unknown;
        };
  };
};

const getRequestFromOperation = (
  operation: Operation
): GraphqlRequestResponse['request'] => ({
  path: (operation.getContext().response?.url as string | undefined) || '',
  payload: {
    operationName: operation.operationName,
    variables: operation.variables,
  },
});

function getStatusInfo(operation: Operation): {
  text: string | undefined;
  code: number | undefined;
} {
  return {
    code: operation.getContext().response?.status as number | undefined,
    text: operation.getContext().response?.statusText as string | undefined,
  };
}

// todo: rename
export const makeErrorOrOk = (
  result: FetchResult<
    {
      [key: string]: any;
    },
    Record<string, any>,
    Record<string, any>
  >,
  operation: Operation
): GraphqlRequestResponse => {
  return {
    type: 'graphql',
    request: getRequestFromOperation(operation),
    response: {
      payload: {
        type: 'errorOrOk',
        data: result.data,
        errors: result.errors,
      },
      statusInfo: getStatusInfo(operation),
    },
  };
};

// todo: rename
export const makeServerError = (
  error: { result: unknown },
  operation: Operation
): GraphqlRequestResponse => {
  return {
    type: 'graphql',
    request: getRequestFromOperation(operation),
    response: {
      payload: {
        type: 'serverError',
        data: error.result,
      },
      statusInfo: {
        code: operation.getContext().response?.status as number | undefined,
        text: operation.getContext().response?.statusText as string | undefined,
      },
    },
  };
};
