import { Dispatch, SetStateAction, useState } from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import { useDropzone, FileWithPath } from 'react-dropzone';

import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import Popup from 'shared/view/elements/Popup/Popup';
import useUploadArtifact from 'features/artifactManager/store/hooks/useUploadArtifact';
import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useModelVersionIntegrate } from 'features/catalog/registeredModelVersion/integrate/store/useModelVersionIntegrate';
import { trackEvent } from 'setup/app/analytics';

import { FileItem } from './FileItem';

const MAX_SIZE = 10000000;

export const UploadFileContainer = styled('div')<{ isFocused: boolean }>(
  ({ isFocused, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    border: `1px dashed ${
      isFocused ? theme.palette.primary.main : theme.palette.divider
    }`,
    height: '197px',
    borderRadius: '4px',
    backgroundColor: isFocused
      ? theme.palette.additionalBackground.stepBackground
      : theme.palette.background.default,
  })
);

const isValidFile = (file: FileWithPath) => {
  return file.size < MAX_SIZE;
};

const uploadFiles = async (
  acceptedFiles: FileWithPath[],
  entityId: string,
  uploadArtifact: ({
    entityId,
    artifact,
  }: {
    entityId: string;
    key: string;
    artifact: File;
  }) => void,
  setDragEnter: Dispatch<SetStateAction<boolean>>,
  reloadVersion: () => void
) => {
  setDragEnter(false);
  if (isValidFile(acceptedFiles[0])) {
    await uploadArtifact({
      entityId: entityId,
      artifact: acceptedFiles[0],
      key: acceptedFiles[0]?.name,
    });
    trackEvent({
      type: 'catalog.registered_model_version.integrate.artifact_uploaded',
    });
    reloadVersion();
  }
};

const UploadArtifactsPopup = ({
  onClose,
  entityId,
}: {
  onClose: () => void;
  entityId: string;
}) => {
  const [dragEnter, setDragEnter] = useState(false);
  const { versionId } = useRouteParams(routes.modelVersionIntegrate);

  const { reloadVersion } = useModelVersionIntegrate({
    versionId,
  });
  const { uploadArtifact, uploadingArtifact, cancelRequest, uploadProgress } =
    useUploadArtifact();

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (e) =>
      uploadFiles(e, entityId, uploadArtifact, setDragEnter, reloadVersion),
    onDragEnter: () => setDragEnter(true),
    onDragLeave: () => setDragEnter(false),
  });

  return (
    <Popup
      title="Upload artifact"
      isOpen={true}
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      buttons={{
        secondaryButtonProps: {
          dataTest: 'close',
          children: 'Close',
          onClick: onClose,
          isLoading: false,
        },
      }}
    >
      <>
        <UploadFileContainer {...getRootProps({ isFocused: dragEnter })}>
          <input {...getInputProps()} />
          <Stack alignItems="center" spacing={0}>
            <Box sx={{ color: 'action.disabled' }}>
              <IconAwesomeCustomizable icon={ICONS.fileArrowUp} size="2x" />
            </Box>
            <Typography variant="body2" color="text.secondary" mt={0.5}>
              Drag and drop or{' '}
              <InlineLink simple onClick={open} to="">
                chose file
              </InlineLink>{' '}
              to upload.
            </Typography>
            <Typography variant="caption" color="text.disabled" mt={0}>
              Max. file size: 10MB
            </Typography>
          </Stack>
        </UploadFileContainer>
        {acceptedFiles.length ? (
          <Stack mt={2}>
            {/* // TODO accept multiple files
            {acceptedFiles.map((file: FileWithPath) => (
              <FileItem
                file={file}
                key={file.path}
                uploadingArtifact={uploadingArtifact}
                isValidFile={isValidFile(file)}
                cancelRequest={cancelRequest}
                uploadProgress={uploadProgress}
              />
            ))} */}
            <FileItem
              file={acceptedFiles[0]}
              key={acceptedFiles[0].name}
              uploadingArtifact={uploadingArtifact}
              isValidFile={isValidFile(acceptedFiles[0])}
              cancelRequest={cancelRequest}
              uploadProgress={uploadProgress}
            />
          </Stack>
        ) : null}
      </>
    </Popup>
  );
};

export default UploadArtifactsPopup;
