import { IAttribute, IAttributeValue } from 'shared/models/Attribute';
import matchBy from 'shared/utils/matchBy';
import { IAttributeWithWidgetBlob } from 'shared/models/AttributeWithWidgetBlob';
import { GraphqlAttributeData } from 'shared/graphql/Attribute/Attribute';
import { convertAttributeWithBlob } from 'services/serverModel/AttributeWithWidgetBlob/converters';

export const convertGraphqlAttribute = (attribute: GraphqlAttributeData) => {
  return matchBy(
    attribute,
    '__typename'
  )<IAttribute>({
    FloatKeyValue: ({ floatValue, key }) => ({ key, value: floatValue }),
    StringKeyValue: ({ stringValue, key }) => ({ key, value: stringValue }),
    BoolKeyValue: ({ boolValue, key }) => {
      // todo replace after implementation of bool value support in IAttributeValue
      return { key, value: String(boolValue) };
    },
    JsonKeyValue: ({ content, key }) => ({
      key,
      value: JSON.parse(content) as IAttributeValue,
    }),
  });
};

export const convertGraphqlAttributeWithWidgetBlob = (
  attribute: GraphqlAttributeData
): IAttributeWithWidgetBlob => {
  return convertAttributeWithBlob(convertGraphqlAttribute(attribute));
};
