import { getResultMachineConfig } from 'features/deployment/canary/machineConfig/view/settings';
import { EndpointEnvironmentUpdateStrategy } from 'generated/types';
import matchBy from 'shared/utils/matchBy';
import { AppError } from 'shared/models/Error';
import { Endpoint } from 'features/deployment/canary/endpoints/store/endpointQuery/endpointQuery';

import {
  UpdateWithCreatingNewBuildSettings,
  useUpdateWithCreatingNewBuild,
} from './useUpdateWithCreatingNewBuild';
import { RollbackSettings, useRollback } from './useRollback';
import { EndpointUpdateSettingsForm } from '../../view/UpdateEndpointSection/settings';

type EndpointUpdateSettings =
  | UpdateWithCreatingNewBuildSettings
  | RollbackSettings;

type RawEndpointUpdateSettings = {
  endpoint: Endpoint;
  settings: EndpointUpdateSettingsForm;
  environmentId: string;
  isValid: boolean;
  organizationId: string | undefined;
};

export const useUpdate = (
  rawEndpointUpdateSettings: RawEndpointUpdateSettings,
  {
    onUpdated,
    onError,
  }: { onUpdated: () => void; onError: (error: AppError) => void }
) => {
  const rollbackMutation = useRollback({
    onCompleted: onUpdated,
    onError,
  });

  const updateWithCreatingNewBuildMutation = useUpdateWithCreatingNewBuild({
    onCompleted: onUpdated,
    onError,
  });

  const endpointUpdateSettings = getEndpointUpdateSettings(
    rawEndpointUpdateSettings
  );
  return endpointUpdateSettings
    ? matchBy(
        endpointUpdateSettings,
        'buildType'
      )({
        existedBuild: (settings) => ({
          update: async () => {
            rollbackMutation.rollback(settings);
          },
          updating: rollbackMutation.rollbacking,
        }),
        newBuild: (settings) => ({
          update: () =>
            updateWithCreatingNewBuildMutation.updateWithCreatingNewBuild(
              settings
            ),
          updating:
            updateWithCreatingNewBuildMutation.updatingWithCreatingNewBuild
              .communication,
        }),
      })
    : undefined;
};

const getEndpointUpdateSettings = (
  rawEndpointUpdateSettings: RawEndpointUpdateSettings
): EndpointUpdateSettings | undefined => {
  const { endpoint, environmentId, settings, isValid } =
    rawEndpointUpdateSettings;
  if (!isValid) {
    return undefined;
  }
  if (settings.build?.type === 'existedBuild') {
    return {
      buildType: 'existedBuild',
      buildId: settings.build.id,
      endpointId: endpoint.id,
      environmentId,
      workspaceName: endpoint.workspace.name,
      strategy: settings.updateStrategyType,
      isolation: settings.machineConfiguration.isolation,
      organizationId: rawEndpointUpdateSettings.organizationId,
      machineConfig: getResultMachineConfig(settings.machineConfiguration),
      canary:
        settings.updateStrategyType === EndpointEnvironmentUpdateStrategy.CANARY
          ? settings
          : undefined,
    };
  }
  if (settings.build?.type === 'newBuild' && settings.source.id) {
    return {
      buildType: 'newBuild',
      sourceType: settings.source.type,
      sourceId: settings.source.id,
      endpointId: endpoint.id,
      environmentId,
      organizationId: rawEndpointUpdateSettings.organizationId,
      workspaceName: endpoint.workspace.name,
      strategy: settings.updateStrategyType,
      machineConfig: getResultMachineConfig(settings.machineConfiguration),
      canary:
        settings.updateStrategyType === EndpointEnvironmentUpdateStrategy.CANARY
          ? settings
          : undefined,
    };
  }
  return undefined;
};
