const docsAPILink = 'https://docs.verta.ai/verta';
const autogenDocsLink = 'https://verta.readthedocs.io';
export const pythonDocsLinks =
  'https://verta.readthedocs.io/en/main/python.html';

const baseExperimentRunApiDocLink = `${autogenDocsLink}/en/master/_autogen/verta.tracking.entities.ExperimentRun.html`;
export const experimentRunApiLinks = {
  log_artifact: `${baseExperimentRunApiDocLink}#verta.tracking.entities.ExperimentRun.log_artifact`,
  log_metric: `${baseExperimentRunApiDocLink}#verta.tracking.entities.ExperimentRun.log_metric`,
  log_hyperparameters: `${baseExperimentRunApiDocLink}#verta.tracking.entities.ExperimentRun.log_hyperparameters`,
  log_observations: `${baseExperimentRunApiDocLink}#verta.tracking.entities.ExperimentRun.log_observations`,
  log_dataset: `${baseExperimentRunApiDocLink}#verta.tracking.entities.ExperimentRun.log_dataset`,
  log_tags: `${baseExperimentRunApiDocLink}#verta.tracking.entities.ExperimentRun.log_tags`,
  log_attribute: `${baseExperimentRunApiDocLink}#verta.tracking.entities.ExperimentRun.log_attribute`,
  log_code: `${baseExperimentRunApiDocLink}#verta.tracking.entities.ExperimentRun.log_code`,
  log_model: `${baseExperimentRunApiDocLink}#verta.tracking.entities.ExperimentRun.log_model`,
};

const baseRegisteredModelApiDocLink = `${autogenDocsLink}/en/master/_autogen/verta.registry.entities.RegisteredModelVersion.html`;
export const registeredModelApiLinks = {
  create_standard_model: `${baseRegisteredModelApiDocLink}#verta.registry.entities.RegisteredModel.create_standard_model`,
  environments: `${autogenDocsLink}/en/main/_autogen/verta.environment.Python.html?highlight=requirements`,
  model_api: `${autogenDocsLink}/en/master/_autogen/verta.utils.ModelAPI.html#verta.utils.ModelAPI`,
  log_artifact: `${baseRegisteredModelApiDocLink}#verta.registry.entities.RegisteredModelVersion.log_artifact`,
  log_attribute: `${baseRegisteredModelApiDocLink}#verta.registry.entities.RegisteredModelVersion.add_attribute`,
  log_training_data: `${baseRegisteredModelApiDocLink}#verta.registry.entities.RegisteredModelVersion.log_training_data`,
  log_code_version: `${baseRegisteredModelApiDocLink}#verta.registry.entities.RegisteredModelVersion.log_code_version`,
  log_model: `${baseRegisteredModelApiDocLink}#verta.registry.entities.RegisteredModelVersion.log_model`,
};

export const docsLinks = {
  monitoring: {
    quickStart: `${docsAPILink}/monitoring/quickstart`,
  },
  registry: {
    guides: {
      home: `${docsAPILink}/registry/guides`,
      bringYourOwnContainer: {
        dockerMetadata: `${docsAPILink}/registry/guides/bring-your-own-container#docker-metadata`,
        dockerEnvironment: `${docsAPILink}/registry/guides/bring-your-own-container#docker-environment`,
      },
      modelImmutability: `${docsAPILink}/registry/guides/model-immutability`,
      transitionModelStages: `${docsAPILink}/registry/guides/transition-model-stages`,
      createModelVersion: `${docsAPILink}/registry/guides/registered-model-version`,
    },
    videos: {
      buildModel: 'https://www.youtube.com/watch?v=UlUv1TnpPbY',
      scanVulnerabilities: 'https://youtu.be/PFbWDUg_Sy8',
      shareWithUsers: 'https://youtu.be/lYyvQHrQZ3U',
    },
  },
  deployment: {
    concepts: {
      deployFromCatalog: `${docsAPILink}/deployment/deployment_concepts#deploy-from-catalog`,
    },
  },
  easySteps: {
    experimentManagement: `${docsAPILink}/experiment-management/quickstart#2.-setup-the-verta-client`,
    registry: `${docsAPILink}/registry/quickstart`,
    deployment: `${docsAPILink}/deployment/quickstart`,
  },
  administration: {
    kafka: `${docsAPILink}/administration/kafka-integration`,
    pypi: `${docsAPILink}/administration/pypi-integration`,
  },
};
