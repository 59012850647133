import { FormikErrors, validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from 'yup';

import { EndpointKafkaChangeFieldKey, EndpointKafkaFormValues } from './types';

const validationSchema = Yup.object<EndpointKafkaFormValues>().shape({
  kafkaReq: Yup.object()
    .shape({
      kafkaConfigId: Yup.string().required(),
      inputTopic: Yup.string().required(),
      outputTopic: Yup.string().required(),
      errorTopic: Yup.string().required(),
      reprocessFromScratch: Yup.boolean(),
      reprocessFromIndex: Yup.number()
        .integer('Must be integer')
        .min(0, `Can't be negative`),
      // todo how to validate graphql date???
      reprocessFromTimestamp: Yup.string(),
      // reprocessFromTimestamp: Yup.date(),
      disabled: Yup.boolean(),
    })
    .nullable(),
});

export function getEndpointKafkaFormErrors(
  values: EndpointKafkaFormValues
): FormikErrors<EndpointKafkaFormValues> {
  try {
    // todo await?
    validateYupSchema(values, validationSchema, true);
  } catch (err: unknown) {
    //for rendering validation errors
    return yupToFormErrors<EndpointKafkaFormValues>(err);
  }
  // no errors
  return {};
}

export function getEndpointKafkaFieldName(
  field: EndpointKafkaChangeFieldKey
): string {
  return `kafkaReq.${field}`;
}
