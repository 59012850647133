import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { ExtractByTypename } from 'shared/utils/types';
import { namedOperations } from 'generated/named-operations';

import {
  EDIT_EVALUATION_PROJECT,
  EditEvaluationProject,
  EditEvaluationProjectVariables,
} from '../graphql';

type ResponseModel = ExtractByTypename<
  EditEvaluationProject['registeredModel'],
  'RegisteredModel'
>['updateRegisteredModel'];

export const useEditEvaluationProject = ({
  onSuccess,
}: {
  onSuccess: (registeredModel: ResponseModel) => void;
}) => {
  const [editEvaluationProject, editingEvaluationProject] = useCustomMutation<
    EditEvaluationProject,
    EditEvaluationProjectVariables
  >(
    EDIT_EVALUATION_PROJECT,
    { context: 'editing registered model' },
    {
      onCompleted: (_res) =>
        isNotNullableRestrictedGraphqlError(_res.registeredModel) &&
        onSuccess(_res.registeredModel.updateRegisteredModel),
      refetchQueries: [namedOperations.Query.EvaluationProjectsList],
    }
  );

  return {
    editEvaluationProject,
    editingEvaluationProject,
  };
};
