import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import invite from '../../../images/invite-users.png';

export const InviteUser = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Invite Users
    </Typography>
    <Typography variant="body2" mb={3}>
      Use the Invite Users button to add as many emails as you need.
    </Typography>
    <Typography variant="body2" mb={3}>
      Once you hit send, they will all receive email invites to set up an
      account and join your organization.
    </Typography>
    <img
      src={invite}
      width="90%"
      height="auto"
      alt="homepage invite users button modal"
    />
  </WizardStepContent>
);
