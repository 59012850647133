import { Dispatch } from 'redux';
import type {} from 'redux-thunk/extend-redux';

import { AppError } from 'shared/models/Error';
import { IApplicationState, IThunkActionDependencies } from 'setup/store/store';

import makeCommunicationThunk from './makeCommunicationThunk';
import makeSimpleApiRequest from './makeSimpleApiRequest';

export default function makeThunkApiRequest<
  State extends IApplicationState,
  Deps extends IThunkActionDependencies,
  Namespace extends string,
  Action extends string,
>(
  namespace: Namespace,
  action: Action,
  errorToast:
    | { context: string; customErrorMessageByType?: Record<string, string> }
    | undefined
) {
  return <RequestPayload, SuccessPayload, FailurePayload, ResetPayload = void>(
    requestApi: (params: {
      payload: RequestPayload;
      dispatch: Dispatch;
      getState: () => State;
      dependencies: Deps;
    }) => Promise<SuccessPayload>,
    handlers?: {
      onSuccess: (params: {
        requestPayload: RequestPayload;
        successPayload: SuccessPayload;
        dispatch: Dispatch;
        getState: () => State;
        dependencies: Deps;
      }) => Promise<any>;
    },
    getFailureActionPayload?: ({
      requestPayload,
      error,
    }: {
      requestPayload: RequestPayload;
      error: FailurePayload extends { error: AppError }
        ? FailurePayload['error']
        : never;
      rawError: Error;
    }) => FailurePayload
  ) => {
    return makeCommunicationThunk(
      `${namespace}/${action}_REQUEST`,
      `${namespace}/${action}_SUCCESS`,
      `${namespace}/${action}_FAILURE`,
      `${namespace}/${action}_RESET`
    )<RequestPayload, SuccessPayload, FailurePayload, ResetPayload>(
      (comm) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        makeSimpleApiRequest(comm, errorToast)(
          requestApi as any,
          handlers as any,
          getFailureActionPayload as any
        ) as any
    );
  };
}
