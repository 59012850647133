import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import Chip from 'shared/view/elements/Chip/Chip';
import { IntegrationsListItemType } from 'pages/authorized/SystemAdmin/shared/types/IntegrationsListItemType';
import IntegrationItemLogo from 'features/systemAdmin/integrations/list/view/item/IntegrationItemLogo';
import IntegrationItemButton from 'features/systemAdmin/integrations/list/view/item/IntegrationItemButton';
import IntegrationItemStatus from 'features/systemAdmin/integrations/shared/IntegrationItemStatus';

export default function SystemIntegrationsItem({
  integration,
  reload,
}: {
  integration: IntegrationsListItemType;
  reload: () => void;
}) {
  return (
    <Card>
      <Grid
        container={true}
        direction={'row'}
        spacing={2}
        alignItems={'center'}
        justifyContent={'space-between'}
        padding="35px"
      >
        <Grid item={true}>
          <IntegrationItemLogo type={integration.type} />
        </Grid>
        <Grid item={true} minWidth={'auto'}>
          <Grid container={true} alignItems={'center'}>
            {integration.chipProps ? (
              <Grid item={true} minWidth={100}>
                <Chip {...integration.chipProps} />
              </Grid>
            ) : null}
            <Grid item={true} minWidth={100}>
              <IntegrationItemStatus status={integration.status} />
            </Grid>
            <Grid item={true} minWidth={'auto'}>
              <IntegrationItemButton
                reload={reload}
                status={integration.status}
                type={integration.type}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
