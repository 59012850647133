import { useMemo } from 'react';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { isEmpty } from 'ramda';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';

import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { formatDateWithTime } from 'shared/utils/formatters/dateTime';
import { getNewOwnerDisplayedName } from 'shared/models/Owner';
import { AllowedActionsData } from 'shared/graphql/collaborators/graphql-types/fragments.generated';
import matchType from 'shared/utils/matchType';
import { OwnerData } from 'shared/graphql/graphql-types/OwnerFragment.generated';
import { OmitStrict } from 'shared/utils/types';

import { RightPartFields } from './RightPartFields';

interface Props {
  entity: SummaryEntity;
  type: SummaryEntityType;
  labelsManager: JSX.Element;
  leftPartAdditionalFields?: JSX.Element[];
  rightPartFields: JSX.Element[];
}

export interface SummaryEntity {
  id: string;
  description: string;
  owner: OwnerData;
  allowedActions: AllowedActionsData;
  dateCreated?: number | Date;
  dateUpdated?: number | Date;
}

export type SummaryEntityType =
  | 'project'
  | 'dataset'
  | 'registeredModel'
  | 'endpoint'
  | 'datasetVersion';

const DISABLE_COLOR = 'text.disable';
const SECONDARY_COLOR = 'text.secondary';

const SummaryGrid = (props: Props) => {
  return (
    <Grid container spacing={3} mb={2}>
      <Grid item xs={12} lg={6}>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Typography variant="subtitle2" color={SECONDARY_COLOR}>
              {getEntityLabelByType(props.type)} description
            </Typography>
            <Typography
              variant="body2"
              data-test="description-text"
              color={
                isEmpty(props.entity.description)
                  ? DISABLE_COLOR
                  : 'text.primary'
              }
            >
              {isEmpty(props.entity.description)
                ? 'No description. Click the gear icon to edit model information and add one.'
                : props.entity.description}
            </Typography>
          </Stack>
          {props.labelsManager}
          {props.leftPartAdditionalFields}
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6} width="100%">
        <RightPartFields fields={props.rightPartFields} />
      </Grid>
    </Grid>
  );
};

export const ModelSummaryGrid = (
  props: OmitStrict<Props, 'rightPartFields'>
) => {
  return (
    <Grid container spacing={3} mb={2}>
      <Grid item xs={12}>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Typography variant="subtitle1" color={SECONDARY_COLOR}>
              {getEntityLabelByType(props.type)} Description
            </Typography>
            <Typography
              variant="body2"
              data-test="description-text"
              color={
                isEmpty(props.entity.description)
                  ? DISABLE_COLOR
                  : 'text.primary'
              }
            >
              {isEmpty(props.entity.description)
                ? 'No description. Click the gear icon to edit model information and add one.'
                : props.entity.description}
            </Typography>
          </Stack>
          <Stack spacing={1.5} mb={1}>
            <OwnerSummaryField owner={props.entity.owner} />
            <CreatedAtSummaryRecordInfo
              createdAt={new Date(props.entity.dateCreated ?? '')}
            />
            <UpdatedAtSummaryRecordInfo
              dateUpdated={new Date(props.entity.dateUpdated ?? '')}
            />
          </Stack>
          {props.leftPartAdditionalFields}
          {props.labelsManager}
        </Stack>
      </Grid>
    </Grid>
  );
};

const getEntityLabelByType = (type: SummaryEntityType) => {
  return matchType(
    {
      dataset: () => 'Data',
      project: () => 'Project',
      endpoint: () => 'Endpoint',
      registeredModel: () => 'Model',
      datasetVersion: () => 'Data version',
    },
    type
  );
};

export const OwnerSummaryField = ({
  owner,
}: {
  owner: SummaryEntity['owner'];
}) => {
  return (
    <SummaryTextRecordInfo
      icon={matchType(
        {
          Group: () => ICONS.group,
          User: () => ICONS.user,
        },
        owner.__typename
      )}
      label="Owner"
    >
      {getNewOwnerDisplayedName(owner)}
    </SummaryTextRecordInfo>
  );
};

export const CreatedAtSummaryRecordInfo = ({
  createdAt,
}: {
  createdAt: Date;
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box width="18px" display="flex">
        <IconAwesomeInfo icon={ICONS.clock} size="sm" />
      </Box>
      <DynamicTypography
        value={`Created at ${formatDateWithTime(createdAt)}`}
        variant="body2"
        sx={{ color: SECONDARY_COLOR }}
      />
    </Stack>
  );
};

const UpdatedAtSummaryRecordInfo = ({ dateUpdated }: { dateUpdated: Date }) => {
  const updatedAt = useMemo(() => moment(dateUpdated).fromNow(), [dateUpdated]);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box width="18px" display="flex">
        <IconAwesomeInfo icon={ICONS.arrowsRotate} size="sm" />
      </Box>
      <DynamicTypography
        value={`Updated ${updatedAt}`}
        variant="body2"
        sx={{ color: SECONDARY_COLOR }}
      />
    </Stack>
  );
};

export const SummaryRecordInfo = (props: {
  icon?: IconDefinition;
  label: string;
  children: React.ReactNode;
  textVariant?: TypographyProps['variant'];
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {props.icon ? (
        <Box width="18px" display="flex">
          <IconAwesomeInfo icon={props.icon} size="sm" />
        </Box>
      ) : null}
      <Box sx={{ color: SECONDARY_COLOR }}>
        <Stack direction="row" spacing={0.5}>
          <Typography
            variant={props.textVariant || 'body2'}
            color={SECONDARY_COLOR}
          >
            {props.label}:
          </Typography>
          {props.children}
        </Stack>
      </Box>
    </Stack>
  );
};

export const SummaryTextRecordInfo = (props: {
  icon?: IconDefinition;
  label: string;
  children: string;
  color?: string;
  textVariant?: TypographyProps['variant'];
}) => {
  return (
    <SummaryRecordInfo {...props}>
      <Box>
        <DynamicTypography
          value={props.children}
          variant={props.textVariant || 'body2'}
          color={props.color || SECONDARY_COLOR}
        />
      </Box>
    </SummaryRecordInfo>
  );
};

export default SummaryGrid;
