const withCancelPreviousQuery = <
  T extends (context: {
    fetchOptions: { signal: AbortSignal };
  }) => (...args: any[]) => unknown,
>(
  f: T
): ReturnType<T> => {
  let abortController: AbortController | null = null;

  return ((...args: any[]) => {
    if (abortController) {
      abortController.abort();
    }

    abortController = new window.AbortController();
    return f({
      fetchOptions: {
        signal: abortController.signal,
      },
    })(...args);
  }) as ReturnType<T>;
};

export default withCancelPreviousQuery;
