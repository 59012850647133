import routes from 'shared/routes';
import HomePage from 'pages/authorized/Homepage/HomePage';

import { RoutesGenerator } from '../types';

export const homePage: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.homepage.getPath(),
      element: <HomePage />,
    },
  ],
};
