import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { isNotRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import PagesTabs from 'shared/view/pages/PagesTabs/PagesTabs';
import { TabsLayout } from 'pages/authorized/shared/TabsLayout';
import AddNewUserButton from 'features/organizations/users/view/AddNewUserPopup/AddNewUserPopup';

import ModelPageSettings from '../../../../../../../features/catalog/registeredModel/settings/view/ModelPageSettings/ModelPageSettings';
import { useModelBreadcrumbs } from '../../../../shared/useModelBreadcrumbs';
import useModelTabs from '../../useModelTabs';
import { useModelLayout } from '../store';

export const Layout = () => {
  const { registeredModelId } = useRouteParams(routes.registeredModelVersion);
  const { data } = useModelLayout({ modelId: registeredModelId });
  // we load the attrs once and use cache for next interactions

  const model = isNotError(data) ? data : undefined;
  const breadcrumbs = useModelBreadcrumbs({ model: data });

  const tabs = useModelTabs({
    registeredModelId,
  });

  return (
    <TabsLayout
      breadcrumbs={breadcrumbs}
      pageEntityOrError={undefined}
      tabsElement={
        <PagesTabs
          tabs={tabs}
          rightContent={
            <Stack direction="row">
              <AddNewUserButton
                popupProps={{
                  title: 'Share model',
                  linkLabel: 'Model link',
                  linkDescription:
                    'Share this link with team members with existing Verta accounts.',
                  inputDescription:
                    'Invite new people to create an account with Verta and join your team.',
                }}
                iconButtonProps={{
                  onClick: () => undefined,
                }}
              />
              <ModelPageSettings model={model} />
            </Stack>
          }
        />
      }
      actions={[]}
    >
      <Outlet />
    </TabsLayout>
  );
};
