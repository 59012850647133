import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';

import RegisteredModelCreationPage from './RegisteredModelCreationPage/RegisteredModelCreationPage';

export const registryPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.createRegisteredModel.getPath(),
      element: <RegisteredModelCreationPage />,
    },
  ],
};
