import * as React from 'react';
import Popover from '@mui/material/Popover';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material';

import { removeProtocols } from 'shared/utils/formatters/urlFormatter';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';

import CopyButton from '../CopyButton/CopyButton';
import DynamicTypography from '../DynamicTypography/DynamicTypography';
import { ICONS } from '../IconAwesome/ICONS';

interface ILocalProps {
  link: string;
}

const ShareLink = (props: ILocalProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();

  return (
    <>
      <IconAwesomeClickable
        icon={ICONS.shareNodes}
        onClick={handleClick}
        size={'lg'}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Card>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: theme.spacing(2),
            }}
          >
            <div style={{ maxWidth: '240px' }}>
              <DynamicTypography value={removeProtocols(props.link)} />
            </div>
            <CopyButton copyText={removeProtocols(props.link)} />
            <IconAwesomeClickable icon={ICONS.xMark} onClick={handleClose} />
          </div>
        </Card>
      </Popover>
    </>
  );
};

export default ShareLink;
