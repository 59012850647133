import isNotNil from 'shared/utils/isNotNill';

import { MonitoringModelState } from '../MonitoringModel';
import { sortModelVersionsDescend } from '../MonitoringModelRegisteredModelVersion';
import { MonitoringPanel } from '../MonitoringPanel/MonitoringPanel';
import { makeDecisionsPanel } from './presetDashboards/shared/makeDecisionsPanel';
import { makeModelMetricsPanel } from './presetDashboards/shared/makeModelMetricsPanel';
import { makePredictionsPanel } from './presetDashboards/shared/makePredictionsPanel';
import { MakeDefaultModelStateProps } from './shared/MakeDefaultModelStateProps';

export const makeDefaultCompareState = (
  props: MakeDefaultModelStateProps
): MonitoringModelState['compare'] => {
  return {
    dashboard: {
      createdBy: props.owner,
      dateCreated: new Date().toISOString(),
      id: 'compare',
      name: 'Compare',
      panels: [
        makePredictionsPanel(props),
        makeModelMetricsPanel(props),
        makeDecisionsPanel(props),
        {
          id: 'initial',
          type: 'custom',
          isOpen: true,
          layout: [],
          name: 'New panel',
          widgets: [],
        },
      ].filter(isNotNil) as MonitoringPanel[],
    },
    registeredModelVersions: sortModelVersionsDescend(
      props.registeredModelVersions
    ).slice(0, 2),
  };
};
