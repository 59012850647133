import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { convertTimeRangeToDateRange } from 'shared/utils/TimeRange';
import { toGraphQLDate } from 'shared/utils/graphql/toGraphQLDate';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import {
  isNotNullableRestrictedGraphqlError,
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { MonitoringDriftMetricType } from 'generated/types';
import { convertMonitoringFilterToGraphQL } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';
import { getAvailableIODescriptionsForDrift } from 'shared/models/Monitoring/Distribution/Drift';
import { MONITORING_MODEL_REGISTERED_MODEL_VERSION_FRAGMENT } from 'shared/graphql/monitoring/MonitoringModel/MonitoringModelRegisteredModelVersion';

import {
  DriftQuery,
  DriftQueryVariables,
} from './graphql-types/useDrift.generated';

const DRIFT_QUERY = gql`
  query DriftQuery(
    $monitoredEntityId: ID!
    $driftQuery: MonitoringDriftQuery!
  ) {
    monitoredEntity(id: $monitoredEntityId) {
      ... on Error {
        ...ErrorData
      }
      ... on MonitoredEntity {
        id
        metrics {
          drift(query: $driftQuery) {
            ioType
            name
            value
            modelVersionId
            modelVersion {
              ... on Error {
                ...ErrorData
              }
              ... on RegisteredModelVersion {
                id
                ...MonitoringModelRegisteredModelVersionFragment
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${MONITORING_MODEL_REGISTERED_MODEL_VERSION_FRAGMENT}
`;

interface Props {
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  driftMetricType: MonitoringDriftMetricType;
}

export const useDriftQuery = (props: Props) => {
  const variables = useMemo((): DriftQueryVariables => {
    const dateRange = convertTimeRangeToDateRange(
      props.widgetExternalDeps.timeRange
    );
    return {
      monitoredEntityId: props.widgetExternalDeps.monitoredEntityId,
      driftQuery: {
        base: {
          startDate: toGraphQLDate(dateRange.from),
          endDate: toGraphQLDate(dateRange.to),
          ioDescriptions: getAvailableIODescriptionsForDrift(
            props.widgetExternalDeps.ioDescriptions
          ).map((d) => ({
            ioType: d.ioType,
            name: [d.name],
          })),
          filters: props.widgetExternalDeps.filters.map(
            convertMonitoringFilterToGraphQL
          ),
        },
        types: [props.driftMetricType],
      },
    };
  }, [
    props.driftMetricType,
    props.widgetExternalDeps.ioDescriptions,
    props.widgetExternalDeps.monitoredEntityId,
    props.widgetExternalDeps.timeRange,
    props.widgetExternalDeps.filters,
  ]);

  const query = useCustomQuery<DriftQuery, DriftQueryVariables>(DRIFT_QUERY, {
    variables,
  });

  const convert = useCallback(
    (res: DriftQuery) => {
      return mapDataOrError(res.monitoredEntity, (x) =>
        x.metrics.drift.filter(
          (d) =>
            isNotNullableRestrictedGraphqlError(d.modelVersion) &&
            props.widgetExternalDeps.registeredModelVersionIds.includes(
              d.modelVersion.id
            )
        )
      );
    },
    [props.widgetExternalDeps.registeredModelVersionIds]
  );

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult: query,
  });
};
