import { Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';

import Tooltip from 'shared/view/elements/Tooltip/Tooltip';
import Menu from 'shared/view/elements/Menu/Menu';
import { LinkMenuItem } from 'shared/view/elements/Menu/MenuItem';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { ButtonMenuItem } from 'shared/view/elements/Menu/MenuItem';
import { useOnboardingContext } from 'features/homepage/hooks/useOnboarding';
import routes from 'shared/routes';
import { trackEvent } from 'setup/app/analytics';

import { ReminderPopper } from './ReminderPopper';

const SupportMenu = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { hideGettingStared, isShowingReminder, setIsShowingReminder } =
    useOnboardingContext();
  const showGettingStarted = () => {
    hideGettingStared(false);
  };
  const ref = useRef();

  return (
    <Box style={{ cursor: 'pointer' }} ref={ref}>
      <Menu
        renderAnchor={(onClick) => (
          <ReminderPopper
            onClose={() => setIsShowingReminder(false)}
            showReminder={isShowingReminder}
            anchorEl={ref.current}
          >
            <Tooltip type="withDiv" title="Documentation" placement="right">
              <IconAwesomeClickable
                size="lg"
                icon={ICONS.book}
                reversedColors={true}
                onClick={(evt) => {
                  onClick(evt as React.MouseEvent<HTMLElement>);
                  trackEvent({
                    type: 'homepage.sidebar.documentation_clicked',
                  });
                }}
              />
            </Tooltip>
          </ReminderPopper>
        )}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <LinkMenuItem
          isExternal={true}
          to="https://docs.verta.ai/verta"
          onClick={() =>
            trackEvent({
              type: 'homepage.sidebar.documentation.documentation_clicked',
            })
          }
        >
          Documentation
        </LinkMenuItem>
        <LinkMenuItem
          isExternal={true}
          to="https://www.youtube.com/c/VertaMLOps?app=desktop"
          onClick={() =>
            trackEvent({
              type: 'homepage.sidebar.documentation.documentation_videos_clicked',
            })
          }
        >
          Video tutorials
        </LinkMenuItem>
        <LinkMenuItem
          isExternal={true}
          to="https://discord.gg/AqZEvgNQrs"
          onClick={() =>
            trackEvent({
              type: 'homepage.sidebar.documentation.documentation_community_clicked',
            })
          }
        >
          Community
        </LinkMenuItem>
        <ButtonMenuItem
          onClick={async () => {
            await showGettingStarted();
            navigate(routes.homepage.getPath());
            trackEvent({
              type: 'homepage.sidebar.documentation.unhide_onboarding_clicked',
            });
          }}
          sx={{ borderTop: `1px solid ${theme.palette.divider}`, pt: 1 }}
        >
          Discover Verta ✨
        </ButtonMenuItem>
      </Menu>
    </Box>
  );
};

export default SupportMenu;
