import { ReactElement } from 'react';

import { ICommunication } from 'shared/utils/redux/communication';

import Preloader, { PreloaderSize } from '../Preloader/Preloader';

interface Props {
  communication: ICommunication;
  preloaderSize?: PreloaderSize;
  children: ReactElement;
}

const DefaultMatchRemoteMutation = (props: Props) => {
  if (props.communication.isRequesting) {
    return <Preloader size={props.preloaderSize} />;
  }

  return props.children;
};

export default DefaultMatchRemoteMutation;
