import { MouseEvent } from 'react';

import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from 'shared/view/elements/ToggleButtonGroup/ToggleButtonGroup';
import ToggleButton from 'shared/view/elements/ToggleButton/ToggleButton';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

export type ToggleViewOptions = 'accordion' | 'data-grid';

type ToggleViewProps = Pick<ToggleButtonGroupProps<any>, 'size'> & {
  value: ToggleViewOptions;
  onChange: (event: MouseEvent, arg: ToggleViewOptions) => void;
};

const ToggleView = (props: ToggleViewProps) => {
  const { value, onChange, size = 'medium', ...other } = props;

  return (
    <ToggleButtonGroup<ToggleViewOptions>
      value={value}
      exclusive
      onChange={onChange}
      aria-label="toggle view"
      {...other}
      size={size}
    >
      <ToggleButton<ToggleViewOptions>
        value="accordion"
        aria-label="accordion view"
        size={size}
        color="primary"
        title="Card view"
      >
        <IconAwesomeCustomizable icon={ICONS.grid2} size="lg" />
      </ToggleButton>
      <ToggleButton<ToggleViewOptions>
        value="data-grid"
        aria-label="data-grid view"
        size={size}
        color="primary"
        title="List view"
      >
        <IconAwesomeCustomizable icon={ICONS.listUl} size="lg" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleView;
