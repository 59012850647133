import { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { sortBy, uniqBy } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import {
  changeWorkspaceWithPageReloading,
  selectCurrentWorkspace,
} from 'features/workspaces/store';
import useDefaultWorkspaceManager from 'features/workspaces/store/hooks/useDefaultWorkspaceManager';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import Menu from 'shared/view/elements/Menu/Menu';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IApplicationState } from 'setup/store/store';
import { useAdministratedOrganization } from 'features/shared/useAdministratedOrganization';
import { IWorkspace } from 'shared/models/Workspace';

import WorkspaceMenuItem from './WorkspaceMenuItem/WorkspaceMenuItem';
import { ScrollToElementOnMount } from './ScrollToElementOnMount';

interface ILocalProps {
  isDefaultWorkspaceSelector?: boolean;
}

interface IWorkspaceTitleProps {
  currentWorkspaceAux: IWorkspace;
  administratedOrganizationApiAux: ReturnType<
    typeof useAdministratedOrganization
  >;
  isDefaultWorkspaceSelectorAux?: boolean;
}

const WorkspaceTitle = ({
  currentWorkspaceAux,
  administratedOrganizationApiAux,
  isDefaultWorkspaceSelectorAux,
}: IWorkspaceTitleProps) => {
  return (
    <>
      {currentWorkspaceAux.organizationName &&
      administratedOrganizationApiAux.data &&
      !isDefaultWorkspaceSelectorAux ? (
        <Typography variant="body1">
          {currentWorkspaceAux.organizationName}/{currentWorkspaceAux.name}
        </Typography>
      ) : (
        <Typography variant="body1">{currentWorkspaceAux.name}</Typography>
      )}
    </>
  );
};

const WorkspacesBar = ({ isDefaultWorkspaceSelector }: ILocalProps) => {
  const administratedOrganizationApi = useAdministratedOrganization();

  const {
    getChangingDefaultWorkspaceFor,
    isDefaultWorkspace,
    changeDefaultWorkspace,
  } = useDefaultWorkspaceManager();

  const selectWorkspacesLocal = (state: IApplicationState) => {
    const orgWorkspaces = state.workspaces.data.organizations;
    if (
      uniqBy(({ organizationId }) => organizationId, orgWorkspaces).length > 1
    ) {
      return orgWorkspaces.map((w) => ({
        ...w,
        displayName: w.organizationName
          ? `${w.organizationName}/${w.name}`
          : w.name,
      }));
    }
    return orgWorkspaces.map((w) => ({
      ...w,
      displayName: w.name,
    }));
  };

  const workspaces = useSelector(
    createSelector([selectWorkspacesLocal], (_workspaces) => _workspaces)
  );
  const sortedWorkspaces = useMemo(
    () => sortBy(({ displayName }) => displayName, workspaces),
    [workspaces]
  );
  const currentWorkspace = useSelector(selectCurrentWorkspace);

  let displayWorkspaceDropdown = currentWorkspace.name !== 'unknown';

  displayWorkspaceDropdown =
    displayWorkspaceDropdown && sortedWorkspaces.length > 1;

  // this flag is used on profile page. here we force always show the selector on profilePage
  if (isDefaultWorkspaceSelector) {
    displayWorkspaceDropdown = isDefaultWorkspaceSelector;
  }

  return displayWorkspaceDropdown ? (
    <Menu
      renderAnchor={(onClick) => (
        <Stack
          direction="row"
          alignItems="center"
          data-test="workspaces-menu"
          sx={{
            cursor: 'pointer',
            color: 'text.primary',
            fontSize: '14px',
            maxWidth: 'max-content',
          }}
          onClick={onClick}
        >
          <WorkspaceTitle
            currentWorkspaceAux={currentWorkspace}
            administratedOrganizationApiAux={administratedOrganizationApi}
            isDefaultWorkspaceSelectorAux={isDefaultWorkspaceSelector}
          />
          <IconAwesomeInfo icon={ICONS.angleDown} color="text.primary" />
        </Stack>
      )}
    >
      {sortedWorkspaces.map((workspace) => {
        const isActive = currentWorkspace.id === workspace.id;
        return (
          <ScrollToElementOnMount enabled={isActive} key={workspace.id}>
            {(ref) => (
              <WorkspaceMenuItem
                ref={ref}
                workspace={workspace}
                isActive={isActive}
                onChangeWorkspace={changeWorkspaceWithPageReloading}
                onChangeDefaultWorkspace={changeDefaultWorkspace}
                changingDefaultWorkspace={getChangingDefaultWorkspaceFor(
                  workspace
                )}
                isDefaultWorkspace={isDefaultWorkspace(workspace)}
                isDefaultWorkspaceSelector={isDefaultWorkspaceSelector}
              />
            )}
          </ScrollToElementOnMount>
        );
      })}
    </Menu>
  ) : null;
};

export default WorkspacesBar;
