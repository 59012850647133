/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { ARTIFACT_FRAGMENT } from 'shared/graphql/Artifact/Artifact';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { ACTIVITY_FRAGMENT } from 'shared/graphql/registry/stage/Activity';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { CUSTOM_ATTRIBUTE_VALUE_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeValue';

export const MODEL_OVERVIEW_DATA_QUERY = gql`
  query ModelOverviewData(
    $id: ID!
    $isEnableMonitoring: Boolean!
    $isEnableDeployment: Boolean!
  ) {
    registeredModel(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        name
        pii
        dateCreated
        dateUpdated
        description
        labels
        taskType
        dataType
        versions {
          versions {
            id
            version
            labels
            stage
            version
            dateCreated
            dateUpdated
            redirectMetadata
            inputDescription
            outputDescription
            hideInputLabel
            hideOutputLabel
            owner {
              ...OwnerData
            }
            registeredModel {
              id
            }
            artifacts {
              ...ArtifactData
            }
            monitoredModels @include(if: $isEnableMonitoring) {
              models {
                id
                name
              }
            }
            deployments @include(if: $isEnableDeployment) {
              endpoint {
                path
                id
                workspace {
                  id
                  name
                }
                monitoredEntities @include(if: $isEnableMonitoring) {
                  entities {
                    id
                    monitoredModels {
                      models {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
        activities {
          id
          ...ActivityFragment
        }
        allowedActions {
          ...AllowedActionsData
        }
        owner {
          ...OwnerData
        }
        customAttributeValues {
          id
          ...CustomAttributeValueData
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ARTIFACT_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_VALUE_FRAGMENT}
`;
