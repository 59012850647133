import { EnabledResourceActions } from 'features/organizations/roles/store/useOrganizationRoles';

import { useUpdateRole } from '../store/useUpdateRole';
import RolePopup, { RoleSettings } from './RolePopup';

interface Props {
  role: RoleSettings & { id: string; orgId: string };
  enabledResourceActions: EnabledResourceActions;
  onClose(): void;
}

const EditRolePopup = (props: Props) => {
  const api = useUpdateRole(props.onClose);
  return (
    <RolePopup
      title="Edit role"
      initialSettings={props.role}
      enabledResourceActions={props.enabledResourceActions}
      submitButton={{
        isLoading: api.communication.isRequesting,
        children: 'Update',
        onSubmit: ({ description, name, resourceActions }) =>
          api.run({
            input: {
              name,
              description,
              resourceActions: resourceActions.map((r) => ({
                allowedActions: r.allowedActions,
                resourceType: r.resourceType,
              })),
            },
            organizationId: props.role.orgId,
            roleId: props.role.id,
          }),
      }}
      onClose={props.onClose}
    />
  );
};

export default EditRolePopup;
