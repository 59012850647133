import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import Button from 'shared/view/elements/Button/Button';
import Checkbox from 'shared/view/elements/Checkbox/Checkbox';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import MultiAutocomplete from 'shared/view/elements/MultiAutocomplete/MultiAutocomplete';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import routes from 'shared/routes';
import useOrganizationUsers, {
  OrganizationUsersContextProvider,
} from 'features/organizations/users/store/useOrganizationUsers';
import { ServiceAccountCreatePopup } from 'features/organizations/users/view/ServiceAccountsCreate/ServiceAccountCreatePopup';
import OrganizationUsersTable from 'features/organizations/users/view/OrganizationUsersTable';
import AddUserPopup from 'features/organizations/users/view/AddUserPopup/AddUserPopup';

import OrganizationLayout from '../shared/OrganizationLayout';

const OrganizationUsersPage = () => {
  const { name } = useRouteParams(routes.organization);
  const organizationUsersApi = useOrganizationUsers(name);

  return (
    <OrganizationUsersContextProvider
      usersApi={{
        communication: organizationUsersApi.communication,
        reload: organizationUsersApi.reloadUsers,
        data: organizationUsersApi.data,
      }}
    >
      <OrganizationLayout
        pageEntityOrError={undefined}
        actions={(() => {
          const org = organizationUsersApi.data;
          return org && org.__typename === 'OrganizationV2'
            ? [
                <ButtonWithPopup
                  key={0}
                  button={(props) => (
                    <Button
                      {...props}
                      dataTest="open-new-service-account-popup"
                      variant="outlined"
                    >
                      Add service account
                    </Button>
                  )}
                  popup={(props) => (
                    <ServiceAccountCreatePopup
                      {...props}
                      groups={org.groups}
                      organizationId={org.id}
                    />
                  )}
                />,
                <ButtonWithPopup
                  key={1}
                  button={(props) => (
                    <Button {...props} dataTest="open-new-user-popup">
                      Add user
                    </Button>
                  )}
                  popup={(props) => (
                    <AddUserPopup {...props} organizationId={org.id} />
                  )}
                />,
              ]
            : [];
        })()}
      >
        <Stack direction="column" spacing={2}>
          <FiltersBar filtersApi={organizationUsersApi.filtersApi} />
          <DefaultMatchRemoteDataOrError
            {...organizationUsersApi}
            context="loading users"
          >
            {(loadedData) => (
              <OrganizationUsersTable
                data={loadedData}
                pagination={organizationUsersApi.paginationApi.pagination}
                onPageChange={
                  organizationUsersApi.paginationApi.onCurrentPageChange
                }
              />
            )}
          </DefaultMatchRemoteDataOrError>
        </Stack>
      </OrganizationLayout>
    </OrganizationUsersContextProvider>
  );
};

const FiltersBar = ({
  filtersApi,
}: {
  filtersApi: ReturnType<typeof useOrganizationUsers>['filtersApi'];
}) => {
  const groupsSelectOptions = filtersApi.availableData.groups.map((group) => ({
    label: group.name,
    value: group.id,
  }));
  const workspacesSelectOptions: Array<{ label: string; value: string }> =
    filtersApi.availableData.workspaces.map((workspace) => ({
      label: workspace.name,
      value: workspace.id,
    }));

  return (
    <Grid container={true}>
      <Grid item={true} style={{ width: '270px' }}>
        <TextInput
          label="Search by name or email"
          name="search"
          value={filtersApi.filters.search}
          withoutError={true}
          withoutLabelPadding={true}
          startAdornmentIcons={[
            <IconAwesomeInfo key={0} icon={ICONS.magnifyingGlass} />,
          ]}
          onChange={filtersApi.setSearch}
        />
      </Grid>
      <Grid marginLeft="auto" item={true}>
        <Stack
          alignSelf="flex-end"
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Checkbox
            value={filtersApi.filters.onlyServiceAccounts}
            label="Only service accounts"
            name="only-service-accounts"
            onChange={filtersApi.setOnlyServiceAccounts}
          />
          <div style={{ width: '265px' }}>
            <MultiAutocomplete
              name="groups-select"
              label="Filter by group"
              options={groupsSelectOptions}
              value={groupsSelectOptions.filter((g) =>
                filtersApi.filters.groupIds.includes(g.value)
              )}
              onChange={(options) => {
                filtersApi.setGroupIds(options.map((o) => o.value));
              }}
            />
          </div>

          <div style={{ width: '265px' }}>
            <MultiAutocomplete
              name="workspaces-select"
              label="Filter by workspace"
              onChange={(options) =>
                filtersApi.setWorkspaceIds(options.map((o) => o.value))
              }
              options={workspacesSelectOptions}
              value={workspacesSelectOptions.filter((g) =>
                filtersApi.filters.workspaceIds.includes(g.value)
              )}
            />
          </div>

          <Button
            variant="outlined"
            isLoading={false}
            disabled={!filtersApi.isClearFiltersAvailable}
            onClick={filtersApi.clearFilters}
            dataTest="clear-filters"
          >
            Clear filters
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default OrganizationUsersPage;
