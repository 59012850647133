import {
  getPredictionEndpoint,
  IActiveEndpointInfo,
  IDeployedExperimentRunInfo,
} from 'shared/models/Deployment/DeployedExperimentRun';
import { isHttpError } from 'shared/models/Error';
import { normalizeErrorFromUnknown } from 'shared/utils/normalizeError';
import { OmitStrict } from 'shared/utils/types';
import { JsonData } from 'shared/utils/json';
import { HttpActions } from 'services/HttpActions';

import * as DeploymentApiError from '../../shared/DeploymentApiError';

export default class DeployedExperimentRunService {
  static async loadDeployedExperimentRunInfo(
    endpointInfo: OmitStrict<IActiveEndpointInfo, 'token'>
  ): Promise<IDeployedExperimentRunInfo> {
    try {
      const describePromise = HttpActions.get<
        Partial<{
          method: string;
          description: string;
          input_description: string;
          output_description: string;
          args: string;
          returns: string;
        }>
      >(
        DeploymentApiError.addHandlingDeploymentErrorToRequestConfig({
          url: `/v1/predict/${
            endpointInfo.fullUrl ? endpointInfo.fullUrl : endpointInfo.path
          }/describe`.replace(/\/+/g, '/'),
        })
      );
      const examplePromise = HttpActions.get<JsonData>(
        DeploymentApiError.addHandlingDeploymentErrorToRequestConfig({
          url: `/v1/predict/${
            endpointInfo.fullUrl ? endpointInfo.fullUrl : endpointInfo.path
          }/example`.replace(/\/+/g, '/'),
        })
      );
      const [describeResponse, exampleResponse] = await Promise.all([
        describePromise,
        examplePromise,
      ]);
      return {
        signature: {
          method: this.fixIndentation(describeResponse.data.method || ''),
          description: this.fixIndentation(
            describeResponse.data.description || ''
          ),
          args: this.fixIndentation(describeResponse.data.args || ''),
          inputDescription: this.fixIndentation(
            describeResponse.data.input_description || ''
          ),
          returns: this.fixIndentation(describeResponse.data.returns || ''),
          outputDescription: this.fixIndentation(
            describeResponse.data.output_description || ''
          ),
        },
        inputExample: exampleResponse.data,
      };
    } catch (e: unknown) {
      const normalizedError = normalizeErrorFromUnknown(e);
      if (isHttpError(normalizedError) && normalizedError.status === 404) {
        return {
          signature: {
            method: '',
            description: '',
            args: '',
            inputDescription: '',
            returns: '',
            outputDescription: '',
          },
          inputExample: '',
        };
      }
      throw e;
    }
  }

  static async runPrediction(
    { path, fullUrl, token }: IActiveEndpointInfo,
    input: string
  ): Promise<JsonData> {
    const response = await HttpActions.post<JsonData, 'invalidInput'>({
      url: getPredictionEndpoint(path, fullUrl),
      data: input,
      config: {
        headers: token
          ? { 'Access-Token': token, 'Content-type': 'application/json' }
          : {
              'Content-type': 'application/json',
            },
      },
      getErrorMessage: (error) =>
        (error.response as any)?.data?.message as string | undefined,
    });
    return response.data;
  }

  private static fixIndentation(s: string): string {
    if (s === '') {
      return s;
    }

    let parts = s.split('\n');
    while (parts[0] === '') {
      parts = parts.slice(1);
      if (parts.length === 0) {
        return '';
      }
    }

    const count = parts[0].search(/\S/);
    parts = parts.map((l) => {
      for (let i = 0; i < count; i++) {
        if (l[i] !== parts[0][i]) {
          return l.slice(i - 1);
        }
      }
      return l.slice(count);
    });

    return parts.join('\n');
  }
}
