import matchBy from 'shared/utils/matchBy';
import MonitoringModelStatusHandler from 'shared/view/domain/Monitoring/MonitoringModel/MonitoringModelStatusHandler/MonitoringModelStatusHandler';
import { getStatusCheckByWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringModelStatus';

import ConfusionMatrixWidget from './ConfusionMatrixWidget/ConfusionMatrixWidget';
import SingleMetricWidget from './SingleMetricWidget/SingleMetricWidget';
import TimeSeriesWidget from './TimeSeriesWidget/TimeSeriesWidget';
import DistributionWidget from './HistogramsWidget/HistogramsWidget';
import { MonitoringWidgetProps } from './shared/types';
import BarChartWidget from './DistributionBarChartWidget/DistributionBarChartWidget';
import TableWidgetView from './TableWidget/TableWidget';
import CurveChartWidgetView from './CurveChartWidget/CurveChartWidget';
import ScatterPlotWidgetView from './ScatterPlotWidget/ScatterPlotWidget';
import NotInitializedWidget from './NotInitializedWidget/NotInitializedWidget';

const MonitoringWidgetView = (props: MonitoringWidgetProps) => {
  const widgetNode = matchBy(
    props.widget,
    'type'
  )({
    singleMetric: (widget) => <SingleMetricWidget {...props} widget={widget} />,
    timeSeries: (widget) => <TimeSeriesWidget {...props} widget={widget} />,
    scatterPlot: (widget) => (
      <ScatterPlotWidgetView {...props} widget={widget} />
    ),
    confusionMatrix: (widget) => (
      <ConfusionMatrixWidget {...props} widget={widget} />
    ),
    curve: (widget) => <CurveChartWidgetView {...props} widget={widget} />,
    table: (widget) => <TableWidgetView {...props} widget={widget} />,
    histograms: (widget) => <DistributionWidget {...props} widget={widget} />,
    barChart: (widget) => <BarChartWidget {...props} widget={widget} />,
    notInitialized: (widget) => (
      <NotInitializedWidget {...props} widget={widget} />
    ),
  });

  return (
    <MonitoringModelStatusHandler
      status={props.widgetExternalDeps.status}
      statusCheck={getStatusCheckByWidget(props.widget)}
    >
      {widgetNode}
    </MonitoringModelStatusHandler>
  );
};

export default MonitoringWidgetView;
