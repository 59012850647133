import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

export const ADD_ATTRIBUTES = gql`
  mutation EvaluationAddAttributes(
    $evaluationId: ID!
    $attributes: [StringKeyValueInput!]!
  ) {
    registeredModelVersion(id: $evaluationId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        addAttributes(keyValues: $attributes) {
          id
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;
