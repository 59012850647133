import { ApolloClient } from '@apollo/client';

import * as apollo from 'shared/graphql/apollo/apollo';

import createApolloLink from './createApolloLink';

const createApolloClient = () => {
  const { link, ...interceptors } = createApolloLink();
  const apolloClient = new ApolloClient({
    cache: apollo.makeCache(),
    link,
  });

  apolloClient.defaultOptions.watchQuery = {
    ...(apolloClient.defaultOptions.watchQuery || {}),
    ...apollo.defaultOptions.watchQuery,
  };

  return { apolloClient, ...interceptors };
};

export default createApolloClient;
