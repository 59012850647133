import { useMemo } from 'react';

import { MonitoringConfidenceMetricType } from 'generated/types';
import { Roc } from 'shared/models/Monitoring/Roc';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';
import {
  isNotNullableRestrictedGraphqlError,
  isNotRestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import isNotNil from 'shared/utils/isNotNill';
import { ExtractByTypename } from 'shared/utils/types';

import { ConfidenceMetrics } from '../confidenceMetrics/graphql-types/useConfidenceMetrics.generated';
import { useConfidenceMetrics } from '../confidenceMetrics/useConfidenceMetrics';

interface Props {
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  output: MonitoringIODescription;
}

const metricTypes = [
  MonitoringConfidenceMetricType.TPR,
  MonitoringConfidenceMetricType.FPR,
];

export const useRoc = (props: Props) => {
  const { communication, data } = useConfidenceMetrics({
    widgetExternalDeps: props.widgetExternalDeps,
    metricTypes,
    output: props.output,
  });

  const convertedData = useMemo(
    () => (isNotNullableRestrictedGraphqlError(data) ? convert(data) : null),
    [data]
  );

  return {
    communication,
    data: convertedData,
  };
};

const convert = (
  data: ExtractByTypename<
    ConfidenceMetrics['monitoredEntity'],
    'MonitoredEntity'
  >['metrics']['confidenceMetric']
): Roc[] => {
  const truePositiveRates = data.filter(
    (d) => d.type === MonitoringConfidenceMetricType.TPR
  );
  const falsePositiveRates = data.filter(
    (d) => d.type === MonitoringConfidenceMetricType.FPR
  );

  return truePositiveRates
    .map((metric) => {
      const falsePositiveRatesMetric = falsePositiveRates.find(
        (d) => d.modelVersionId === metric.modelVersionId
      );
      return falsePositiveRatesMetric
        ? {
            truePositiveRates: metric.values.map(parseGraphQLNumber),
            falsePositiveRates:
              falsePositiveRatesMetric.values.map(parseGraphQLNumber),
            modelVersion: isNotRestrictedGraphqlError(metric.modelVersion)
              ? metric.modelVersion.version
              : metric.modelVersionId,
          }
        : null;
    })
    .filter(isNotNil);
};
