import { last } from 'ramda';
import { useSelector } from 'react-redux';

import { selectFlags } from 'features/flags';
import { EndpointEnvironmentUpdateStrategy } from 'generated/types';
import {
  getEndpointEnvironmentComponentsWithBuilds,
  getEnvironment,
} from 'shared/models/Deployment/canary/Endpoint';
import {
  MachineConfigSettings,
  defaultInitialMachineConfig,
  GetInitialMachineConfig,
} from 'features/deployment/canary/machineConfig/view/settings';
import { Endpoint } from 'features/deployment/canary/endpoints/store/endpointQuery/endpointQuery';
import matchBy from 'shared/utils/matchBy';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';

import {
  CanaryStrategyForm,
  getInitialCanaryStrategyForm,
} from './CanaryStrategyForm';

export type EndpointUpdateSettingsForm =
  | (CommonSettings & {
      updateStrategyType: EndpointEnvironmentUpdateStrategy.ROLLOUT;
    })
  | (CommonSettings & CanaryStrategyForm);

type CommonSettings = {
  source: Source;
  build: BuildOption | undefined;
  machineConfiguration: MachineConfigSettings;
};

export type BuildOption =
  | { type: 'newBuild' }
  | { type: 'existedBuild'; id: string };

export type Source =
  | {
      type: 'experimentRun';
      id?: string | undefined;
    }
  | {
      type: 'modelVersion';
      id: string | undefined;
      registeredModelId: string | undefined;
    };

export const GetInitialSettings = ({
  endpoint,
}: {
  endpoint: Endpoint;
}): EndpointUpdateSettingsForm => {
  const {
    deployment: { defaultCpuValue, defaultMemoryValue },
  } = useSelector(selectFlags);
  const defaultInitialSettings: EndpointUpdateSettingsForm = {
    source: {
      type: 'modelVersion',
      id: undefined,
      registeredModelId: undefined,
    },
    build: undefined,
    updateStrategyType: EndpointEnvironmentUpdateStrategy.ROLLOUT,
    machineConfiguration: defaultInitialMachineConfig({
      defaultCpuValue,
      defaultMemoryValue,
    }),
  };

  const lastBuild = last(getEndpointEnvironmentComponentsWithBuilds(endpoint))
    ?.build;
  if (lastBuild) {
    const environment = getEnvironment(endpoint);
    const canaryStrategy = environment?.updateRequest?.canaryStrategy;
    const canonicalCanaryRules = environment?.canaryRules || null;
    const source = isNotNullableRestrictedGraphqlError(lastBuild.source)
      ? matchBy(
          lastBuild.source,
          '__typename'
        )<Source>({
          ExperimentRun: (experimentRun) => ({
            type: 'experimentRun' as const,
            id: experimentRun.id,
          }),
          RegisteredModelVersion: (modelVersion) => ({
            type: 'modelVersion' as const,
            id: modelVersion.id,
            registeredModelId: modelVersion.registeredModel.id,
          }),
        })
      : defaultInitialSettings.source;

    return {
      source,
      ...(canaryStrategy
        ? getInitialCanaryStrategyForm(canaryStrategy, canonicalCanaryRules)
        : {
            updateStrategyType: EndpointEnvironmentUpdateStrategy.ROLLOUT,
          }),
      build: undefined,
      machineConfiguration: GetInitialMachineConfig(endpoint),
    };
  }
  return defaultInitialSettings;
};
