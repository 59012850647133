import { useCallback } from 'react';

import { trackEvent } from 'setup/app/analytics';
import ConfirmPopup from 'shared/view/elements/ConfirmAction/ConfirmPopup/ConfirmPopup';

import { useDeleteEvaluationProject } from '../hooks/useDeleteEvaluationProject';

export const DeleteProjectPopup = ({
  onClose,
  isOpen,
  projectId,
  projectName,
}: {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  projectName: string;
}) => {
  const { deleteEvaluationProject, deletingEvaluationProject } =
    useDeleteEvaluationProject({
      onSuccess: async () => {
        trackEvent({
          type: 'catalog.registered_model.deleted',
          data: {
            id: projectId,
            name: projectName,
          },
        });
        onClose();
      },
    });

  const onSubmit = useCallback(() => {
    deleteEvaluationProject({
      projectId,
    });
  }, [projectId, deleteEvaluationProject]);

  return (
    <ConfirmPopup
      title="Delete Project"
      confirmButtonText="Delete"
      confirmButtonLoading={deletingEvaluationProject.isRequesting}
      cancelButtonText="Cancel"
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={onSubmit}
    >
      Are you sure you want to delete project "{projectName}"?
    </ConfirmPopup>
  );
};
