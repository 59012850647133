import { useMemo } from 'react';
import Stack from '@mui/material/Stack';

import Chip, { ChipProps } from 'shared/view/elements/Chip/Chip';
import DefaultConfirmAction, {
  ConfirmType,
} from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';
import isNotNil from 'shared/utils/isNotNill';
import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import { PartialUnion } from 'shared/utils/types';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

export type TagOptionalProps = PartialUnion<{
  isEditable: boolean;
  isUpdating: boolean;
  withConfirmation?: boolean;
  onDelete(value: string): void;
  onClick?(e: React.MouseEvent<Element, MouseEvent>, tag: string): void;
  getTagProps?(tag: string): ChipProps;
}>;

type Props = {
  value: string;
} & TagOptionalProps;

export default function Tag(props: Props) {
  const {
    value,
    onDelete,
    isUpdating,
    isEditable,
    withConfirmation = true,
    onClick,
    getTagProps,
    ...other
  } = props;

  const handleDelete = useMemo(
    () =>
      isNotNil(onDelete)
        ? () => {
            onDelete(value);
          }
        : undefined,
    [value, onDelete]
  );

  return (
    <DefaultConfirmAction
      description={`label "${value}"`}
      type={ConfirmType.delete}
      additionalText="This label will be removed."
      maxWidth="xs"
      fullWidth
      confirmButtonText="Delete"
      cancelButtonText="Cancel"
    >
      {(withConfirm) => (
        <div data-test="tags-manager-tag">
          <Chip
            data-test={`tags-manager-tag-${value}`}
            size="small"
            variant="outlined"
            onClick={(e) => onClick?.(e, value)}
            onDelete={
              isEditable && handleDelete
                ? withConfirmation
                  ? withConfirm(handleDelete)
                  : handleDelete
                : undefined
            }
            deleteIcon={isEditable && handleDelete ? undefined : <></>}
            disabled={isUpdating}
            label={
              <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                {isUpdating ? (
                  <IconAwesomeInfo icon={ICONS.circleNotch} spin={true} />
                ) : null}
                <DynamicTypography value={value} />
              </Stack>
            }
            {...getTagProps?.(value)}
            {...other}
          />
        </div>
      )}
    </DefaultConfirmAction>
  );
}
