import { useCallback, useMemo } from 'react';

import { MonitoringIOType } from 'generated/types';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';

interface Row {
  id: string;
  ioType: MonitoringIOType;
  name: string;
}

interface Props {
  ioDescriptions: MonitoringIODescription[];
  ioDescription: MonitoringIODescription | undefined;
  changeIODescription:
    | ((ioDescription: MonitoringIODescription) => void)
    | undefined;
  rows: Row[];
}

export const useIODescriptionDataGridSelection = (props: Props) => {
  const selectedRow = useMemo(
    () =>
      props.rows.find(
        (d) =>
          d.ioType === props.ioDescription?.ioType &&
          d.name === props.ioDescription.name
      ),
    [props.ioDescription?.ioType, props.ioDescription?.name, props.rows]
  );

  const { rows, changeIODescription } = props;
  const onSelectionChange = useCallback(
    (id: string) => {
      const row = rows.find((d) => d.id === id);
      if (changeIODescription && row) {
        const ioDescription = props.ioDescriptions.find(
          (d) => d.name === row.name && d.ioType === row.ioType
        );
        if (ioDescription) changeIODescription(ioDescription);
      }
    },
    [rows, changeIODescription, props.ioDescriptions]
  );

  return {
    selectionType: 'single' as const,
    selection: selectedRow?.id,
    onSelectionChange,
  };
};
