import {
  MonitoringStringArrayFilter,
  MonitoringStringFilter,
} from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';
import matchBy from 'shared/utils/matchBy';
import MultiValueTextInput from 'shared/view/elements/MultiValueTextInput/MultiValueTextInput';
import TextInput from 'shared/view/elements/TextInput/TextInput';

interface Props {
  filter: MonitoringStringFilter | MonitoringStringArrayFilter;
  updateFilter: (
    filter: MonitoringStringFilter | MonitoringStringArrayFilter
  ) => void;
}

const MonitoringStringFilterValueInput = (props: Props) => {
  return matchBy(
    props.filter,
    'valueType'
  )({
    string: (f) => (
      <TextInput
        label="Value"
        value={f.value}
        onChange={(value) => props.updateFilter({ ...f, value })}
      />
    ),
    stringArray: (f) => (
      <MultiValueTextInput
        value={f.value}
        onChange={(value) => props.updateFilter({ ...f, value })}
      />
    ),
  });
};

export default MonitoringStringFilterValueInput;
