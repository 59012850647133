// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MuiBadge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material';

import { OmitStrict } from 'shared/utils/types';

interface Props {
  value: number;
  isLoading?: boolean;
  dataTest?: string;
  children: Exclude<React.ReactNode, null | undefined>;
  color: BadgeProps['color'];
  onClick?: () => void;
  renderValue?: (v: string | number) => React.ReactNode;
}

const getCommonProps = (props: OmitStrict<Props, 'children'>) => {
  const renderValue = props.renderValue || ((x: string | number) => x);
  return {
    badgeContent: props.isLoading
      ? '...'
      : renderValue(formatBadgeValue(props.value)),
    max: 1000,
    color: props.color,
    'data-test': props.dataTest,
    onClick: props.onClick,
    style: props.onClick ? { cursor: 'pointer' } : undefined,
    showZero: false,
  };
};

const Badge = (props: Props) => {
  return <MuiBadge {...getCommonProps(props)}>{props.children}</MuiBadge>;
};

const MuiBadgeBlock = styled(MuiBadge)({
  '& .MuiBadge-badge': {
    transform: 'unset',
    position: 'unset',
    zIndex: 0,
  },
});

export const BadgeBlock = (props: OmitStrict<Props, 'children'>) => {
  return <MuiBadgeBlock {...getCommonProps(props)} />;
};

export const formatBadgeValue = (value: number) => {
  const digitsNumber = getDigits(value);
  if (digitsNumber.length < 4) {
    return value;
  }
  if (digitsNumber.length === 4) {
    return `${digitsNumber[0]}K`;
  }
  return `9.9K+`;
};

const getDigits = (number: number) => Math.floor(number).toString().split('');

export default Badge;
