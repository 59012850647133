/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';

export const ENDPOINT_FOR_SHORT_ENDPOINTS_TABLE = gql`
  fragment EndpointForShortEndpointsTable on Endpoint {
    id
    path
    description
    environments {
      id
      status
    }
    labels
    dateUpdated
    workspace {
      id
      ...WorkspaceData
    }
    owner {
      ...OwnerData
    }
  }
  ${OWNER_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
`;
