import { useMemo } from 'react';

import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { MenuAction } from 'shared/view/elements/ActionsMenu/ActionsMenu';
import PageSettingsIcon from 'shared/view/elements/PageSettingsIcon/PageSettingsIcon';
import { useDeleteEntityAction } from 'features/shared/DeleteEntityIcon/useDeleteEntityAction';
import routes from 'shared/routes';
import { isNotRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { ModelVersionLayout } from 'pages/authorized/CatalogPages/ModelVersionPages/shared/Layout/graphql/graphql-types/query.generated';

import ModelVersionEditor from './ModelVersionEditor/ModelVersionEditor';
import LockLevelEditor from './LockLevelEditor/LockLevelEditor';

interface Props {
  version?: ModelVersionLayout['registeredModelVersion'];
}

const ModelVersionPageSettings = (props: Props) => {
  const version = isNotRestrictedGraphqlError(props.version)
    ? props.version
    : undefined;

  const workspaceName = useWorkspaceNameFromURL();

  const editorManager = usePopupManager();
  const lockLevelManager = usePopupManager();

  const editDisabled = useMemo(
    () => !version?.registeredModel.allowedActions.update,
    [version]
  );

  const lockDisabled = useMemo(
    () =>
      !version ||
      !(
        version.registeredModel.allowedActions.lock ||
        version.registeredModel.allowedActions.unlock ||
        version.registeredModel.allowedActions.updateRedact
      ),
    [version]
  );

  const editVersionAction: MenuAction = {
    type: 'button',
    label: 'Edit version information',
    onClick: editorManager.openPopup,
    disabled: editDisabled,
    additionalContent: version ? (
      <ModelVersionEditor
        isOpen={editorManager.isPopupOpen}
        version={version}
        onClose={editorManager.closePopup}
        isEditable={version.registeredModel.allowedActions.update}
      />
    ) : undefined,
  };

  const editLockLevelAction: MenuAction = {
    type: 'button',
    label: 'Edit lock level',
    disabled: lockDisabled,
    onClick: lockLevelManager.openPopup,
    additionalContent: version ? (
      <LockLevelEditor
        isOpen={lockLevelManager.isPopupOpen}
        version={version}
        onClose={lockLevelManager.closePopup}
        isEditable={version.registeredModel.allowedActions.update}
      />
    ) : undefined,
  };

  const deleteAction = useDeleteEntityAction({
    id: version?.id ?? null,
    redirectUrl: routes.catalog.getRedirectPath({
      workspaceName,
    }),
    entityType: 'modelVersion',
    isNotAllowedToDelete: !version?.registeredModel.allowedActions.update,
  });

  return (
    <PageSettingsIcon
      actions={[editVersionAction, editLockLevelAction, deleteAction]}
    />
  );
};

export default ModelVersionPageSettings;
