import { equals } from 'ramda';
import { useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import {
  MonitoringBooleanFilterOperator,
  MonitoringDoubleFilterOperator,
  MonitoringStringFilterOperator,
} from 'generated/types';
import { MonitoringFilter } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';
import { getMonitoringFilterTypeByDataType } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilterType';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import matchType from 'shared/utils/matchType';
import { defaultRenderAutocompleteOption } from 'shared/view/elements/Autocomplete/Autocomplete';

const MonitoringFilterIOSelect = (props: {
  ioDescriptions: MonitoringIODescription[];
  filter: MonitoringFilter;
  updateFilter: (filter: MonitoringFilter) => void;
}) => {
  const options = props.ioDescriptions.map((ioDesc) => ({
    label: `${ioDesc.ioType.toString().toLowerCase()}.${ioDesc.name}`,
    value: ioDesc,
  }));
  const { filter, updateFilter } = props;
  const onChange = useCallback(
    (ioDescription: MonitoringIODescription) => {
      const type = getMonitoringFilterTypeByDataType(ioDescription.dataType);
      if (type === filter.type) {
        updateFilter({
          ...filter,
          ioDescription,
        });
      } else {
        matchType(
          {
            number: () =>
              updateFilter({
                ...filter,
                type: 'number',
                operator: MonitoringDoubleFilterOperator.GT,
                value: 0,
                ioDescription,
              }),
            boolean: () =>
              updateFilter({
                ...filter,
                type: 'boolean',
                operator: MonitoringBooleanFilterOperator.EQ,
                value: true,
                ioDescription,
              }),
            string: () =>
              updateFilter({
                ...filter,
                type: 'string',
                valueType: 'string',
                ioDescription,
                value: '',
                operator: MonitoringStringFilterOperator.LIKE,
              }),
          },
          type
        );
      }
    },
    [filter, updateFilter]
  );

  return (
    <Box pt={1}>
      <Autocomplete
        disableClearable
        value={options.find((opt) =>
          equals(opt.value, props.filter.ioDescription)
        )}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Feature"
            size="small"
            InputProps={{
              style: {
                paddingRight: '32px',
              },
              ...params.InputProps,
            }}
          />
        )}
        onChange={(_, opt) => onChange(opt.value)}
        renderOption={defaultRenderAutocompleteOption}
      />
    </Box>
  );
};

export default MonitoringFilterIOSelect;
