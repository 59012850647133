import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';

import { ProjectDetailsLayoutWithDefaultProjectLoading } from '../shared/layouts/ProjectDetailsLayout';

const ExperimentRuns = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ExperimentRuns' */ 'features/experimentRuns/list/view/ExperimentRuns'
    )
);

function ExperimentRunsPage() {
  return (
    <ProjectDetailsLayoutWithDefaultProjectLoading>
      {({ project }) => <ExperimentRuns project={project} />}
    </ProjectDetailsLayoutWithDefaultProjectLoading>
  );
}

export default ExperimentRunsPage;
