import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/deleteDatasetVersion.generated';

const DELETE_DATASET_VERSION = gql`
  mutation DeleteDatasetVersion($id: ID!) {
    datasetVersion(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on DatasetVersion {
        id
        delete
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useDeleteDatasetVersion = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [deleteEntity, deletingEntity] = useCustomMutation<
    Types.DeleteDatasetVersion,
    Types.DeleteDatasetVersionVariables
  >(
    DELETE_DATASET_VERSION,
    { context: 'deleting the dataset version' },
    { onCompleted }
  );

  return {
    deleteEntity,
    deletingEntity,
  };
};
