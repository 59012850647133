import { head } from 'ramda';

import { RecordPath } from './types';

export default function generateArrayPath<T extends Record<any, any> = never>(
  recordPath: RecordPath<T>,
  path: string[] = []
): string[] {
  const keyValue = head(Object.entries(recordPath));
  if (!keyValue) {
    return path;
  }
  const [key, value] = keyValue;
  if (value === null) {
    return path.concat(key);
  }
  return generateArrayPath(value as never, path.concat(keyValue[0]));
}
