import moment from 'moment';

import {
  validateDuration,
  parseDurationMs,
  formatDurationMs,
} from '../duration';

export const validateLatency = (name: string) =>
  validateDuration(name, {
    min: moment.duration({ seconds: 0 }).asMilliseconds(),
    max: moment.duration({ hours: 24 }).asMilliseconds(),
  });

export const parseLatency = (latency: string) =>
  parseDurationMs(latency) / 1000;

export const formatLatency = (latency: string) =>
  formatDurationMs(parseFloat(latency) * 1000);
