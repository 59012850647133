import { FormikErrors } from 'formik';
import { isEmpty, isNil } from 'ramda';
import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import { compact } from 'lodash';

import Checkbox from 'shared/view/elements/Checkbox/Checkbox';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import {
  ChecklistTemplateInput,
  ChecklistTemplateItemInput,
  CustomAttributeValueInput,
  Stage,
} from 'generated/types';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import {
  validateNotEmpty,
  validateNotEqual,
  combineValidators,
  validateMaxLength,
} from 'shared/utils/validators';
import Popup from 'shared/view/elements/Popup/Popup';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { useCreateOrUpdateChecklistTemplate } from 'features/organizations/governance/store/useCreateOrUpdateChecklistTemplate';
import { stageToView } from 'shared/view/domain/Registry/ModelVersion/RegisteredModelVersionStage/stageToView';

import {
  ChecklistTemplateItemManager,
  EMPTY_CHECKLIST_TEMPLATE_ITEM_INPUT,
} from './ChecklistTemplateItemManager';
import { OrganizationGovernance } from '../../store/useOrganizationGovernance';
import { CustomAttributeValueManager } from './CustomAttributeValueManager';

const EMPTY_CHECKLIST_TEMPLATE_INPUT: ChecklistTemplateInput = {
  name: '',
  desiredStage: Stage.UNKNOWN,
  checklistTemplateItems: [EMPTY_CHECKLIST_TEMPLATE_ITEM_INPUT],
};

type Props = {
  title: string;
  checklistTemplate: ChecklistTemplateInput | undefined;
  submitButton: {
    children: 'Create checklist' | 'Save changes';
  };
  organizationId: string;
  initialExpandedItemIndex?: number;
  onClose: () => void;
  onCompleted: () => void;
  groups: OrganizationGovernance['groups'];
};

interface ChecklistTemplateStageOpt {
  value: Stage;
}

const STAGE_OPTS: ChecklistTemplateStageOpt[] = [
  {
    value: Stage.DEVELOPMENT,
  },
  {
    value: Stage.STAGING,
  },
  {
    value: Stage.PRODUCTION,
  },
  {
    value: Stage.ARCHIVED,
  },
];

export const ChecklistTemplatePopup = (props: Props) => {
  const {
    title,
    checklistTemplate,
    onCompleted,
    submitButton,
    onClose,
    organizationId,
    initialExpandedItemIndex,
    groups,
  } = props;
  const initialValues =
    checklistTemplate === undefined
      ? EMPTY_CHECKLIST_TEMPLATE_INPUT
      : cleanInitialChecklistTemplate(checklistTemplate);

  const { createOrUpdate, communication } = useCreateOrUpdateChecklistTemplate({
    onCompleted: () => {
      onCompleted();
      onClose();
    },
  });

  const stageOptionsWithViews = STAGE_OPTS.map((stageOpt) => {
    let label = `Move to ${stageToView(stageOpt.value)}`;

    if (stageOpt.value === Stage.ARCHIVED) {
      label = 'Archive version';
    }

    return {
      ...stageOpt,
      label,
    };
  });

  const [isStaged, setIsStaged] = useState(
    Boolean(initialValues.desiredStage !== Stage.UNKNOWN)
  );

  return (
    <PresetFormik<ChecklistTemplateInput>
      initialValues={initialValues}
      onSubmit={(values) =>
        createOrUpdate({
          input: values,
          organizationId,
        })
      }
      validateOnChange={false}
      validateOnMount={false}
      validateOnBlur={false}
    >
      {({ values, setFieldValue, submitForm, errors }) => {
        const handleDesiredStage = () => {
          if (isStaged) {
            setFieldValue(getField({ desiredStage: null }), undefined);
          }
          setIsStaged(!isStaged);
        };

        return (
          <Popup
            isOpen={true}
            title={title}
            fullWidth={true}
            maxWidth="xs"
            buttons={{
              secondaryButtonProps: {
                children: 'Cancel',
                isLoading: false,
                onClick: onClose,
              },
              mainButtonProps: {
                ...submitButton,
                isLoading: communication.isRequesting,
                type: 'button',
                onClick: submitForm,
              },
            }}
            onClose={onClose}
          >
            <FormStack>
              <TextInputField
                label="Checklist name"
                name={getField({ name: null })}
                isRequired
                validate={combineValidators([
                  validateNotEmpty('Checklist name'),
                  validateMaxLength(250),
                ])}
                withoutError={!Boolean(errors.name)}
                errorMessage={errors.name}
              />
              <Grid item xxs={12}>
                <Checkbox
                  onChange={handleDesiredStage}
                  dataTest="restricted"
                  label="Apply checklist only to a specific stage change"
                  value={isStaged}
                  maxWidth="100%"
                  color="text.primary"
                />
                {isStaged && (
                  <Box ml="30px" mt={1} mb={2}>
                    <SelectField
                      label="Select stage change"
                      name={getField({ desiredStage: null })}
                      options={stageOptionsWithViews}
                      required
                      withoutError={!Boolean(errors.desiredStage)}
                      validate={validateNotEqual(Stage.UNKNOWN, 'Stage')}
                    />
                  </Box>
                )}
              </Grid>

              <Grid item xxs={12}>
                <CustomAttributeValueManager
                  organizationId={organizationId}
                  values={values.customAttributeValues}
                  errors={
                    errors.customAttributeValues as unknown as FormikErrors<CustomAttributeValueInput>[]
                  }
                />
              </Grid>
            </FormStack>
            <ChecklistTemplateItemManager
              groups={groups}
              values={values.checklistTemplateItems}
              initialExpandedItemIndex={initialExpandedItemIndex}
              errors={
                errors.checklistTemplateItems as unknown as FormikErrors<ChecklistTemplateItemInput>[]
              }
            />
          </Popup>
        );
      }}
    </PresetFormik>
  );
};

export const getField = makeGetFieldName<ChecklistTemplateInput>();

const cleanInitialChecklistTemplate = (
  checklistTemplate: ChecklistTemplateInput
) => {
  const cleaned: ChecklistTemplateInput = {
    id: checklistTemplate.id,
    name: checklistTemplate.name,
    blocksDeployment: checklistTemplate.blocksDeployment,
    desiredStage: checklistTemplate.desiredStage,
    applyToRiskLevel: checklistTemplate.applyToRiskLevel,
    organizationId: checklistTemplate.organizationId,
    sort: checklistTemplate.sort,
  };

  cleaned.checklistTemplateItems =
    isEmpty(checklistTemplate.checklistTemplateItems) ||
    isNil(checklistTemplate.checklistTemplateItems)
      ? []
      : checklistTemplate.checklistTemplateItems.map((e) => ({
          id: e.id,
          name: e.name,
          description: e.description,
          itemType: e.itemType,
          editable: e.editable,
          required: e.required,
          requireEvidence: e.requireEvidence,
          userGroupId: e.userGroupId === '0' ? null : e.userGroupId,
          checklistTemplateId: e.checklistTemplateId,
          sort: e.sort,
        }));

  cleaned.customAttributeValues =
    isEmpty(checklistTemplate.customAttributeValues) ||
    isNil(checklistTemplate.customAttributeValues)
      ? []
      : compact(
          checklistTemplate.customAttributeValues.map((e) => {
            if (e) {
              return {
                id: e.id,
                customAttributeDefinitionId: e.customAttributeDefinitionId,
                selectedValue: e.selectedValue,
                targetId: e.targetId,
                targetType: e.targetType,
              };
            }
            return undefined;
          })
        );

  return cleaned;
};
