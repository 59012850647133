import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';
import { pick } from 'lodash';

import routes from 'shared/routes';
import PagesTabs from 'shared/view/pages/PagesTabs/PagesTabs';
import { useRouteParams } from 'shared/utils/router/useRouteParams';
import { useLoadChecklistTemplates } from 'features/catalog/shared/store/useLoadChecklistTemplates';
import { selectCurrentWorkspace } from 'features/workspaces';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import ModelVersionPageSettings from 'features/catalog/registeredModelVersion/settings/view/ModelVersionPageSettings';
import { TabsLayout } from 'pages/authorized/shared/TabsLayout';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { useLoadCustomAttributeDefinitions } from 'features/catalog/shared/hooks/useLoadCustomAttributeDefinitions';
import AddNewUserButton from 'features/organizations/users/view/AddNewUserPopup/AddNewUserPopup';
import { trackEvent } from 'setup/app/analytics';
import { selectCurrentUserOrThrowError } from 'features/user';
import { useJobStatusPolling } from 'features/catalog/shared/hooks/useJobStatusPolling';
import { selectFlags } from 'features/flags';

import TransitionRequesterButton from './TransitionRequesterButton/TransitionRequesterButton';
import { useModelVersionPageBreadcrumbs } from '../../../../shared/useModelVersionPageBreadcrumbs';
import useModelVersionTabs from '../hooks/useModelVersionTabs';
import { useModelVersionLayout } from '../hooks/useModelVersionLayout';
import { FineTuningButton } from './FineTuningButton/FineTuningButton';

export const Layout = () => {
  const organizationId = useCurrentOrganizationV2() || '';
  const currentUser = useSelector(selectCurrentUserOrThrowError);
  const { registeredModelId, versionId, workspaceName } = useRouteParams(
    routes.registeredModelVersion
  );
  const {
    catalog: { isEnableFineFineTuning },
  } = useSelector(selectFlags);
  const { data } = useModelVersionLayout({ versionId });

  // we load the attrs once and use cache for next interactions
  const _customAttrs = useLoadCustomAttributeDefinitions({ organizationId });

  const workspace = useSelector(selectCurrentWorkspace);

  const breadcrumbs = useModelVersionPageBreadcrumbs({
    version: data,
  });
  const activities = useMemo(
    () => (isNotError(data) ? data.activities : undefined),
    [data]
  );

  const { data: checklistTemplatesData } = useLoadChecklistTemplates({
    workspaceName,
    organizationId:
      workspace.type === 'organization' ? workspace.organizationId : '',
  });

  const checklistTemplates = useMemo(
    () =>
      checklistTemplatesData && isNotError(checklistTemplatesData)
        ? checklistTemplatesData
        : null,
    [checklistTemplatesData]
  );

  const tabs = useModelVersionTabs({
    registeredModelId,
    versionId,
    activities,
  });

  const { startPolling } = useJobStatusPolling({
    organizationId: organizationId,
    workspaceId: workspace.id,
    workspaceName: workspace.name,
  });

  useEffect(() => {
    startPolling();
  }, [startPolling]);

  return (
    <TabsLayout
      breadcrumbs={breadcrumbs}
      pageEntityOrError={data}
      actions={[
        <>
          {isEnableFineFineTuning ? (
            <FineTuningButton key="fine-tune-button" />
          ) : null}
        </>,
        <TransitionRequesterButton
          key="transition-requester-button"
          version={isNotError(data) ? data : null}
          checklistTemplates={checklistTemplates}
        />,
      ]}
      tabsElement={
        <PagesTabs
          tabs={tabs}
          rightContent={
            <Stack direction="row">
              <AddNewUserButton
                popupProps={{
                  title: 'Share version',
                  linkLabel: 'Version link',
                  linkDescription:
                    'Share this link with team members with existing Verta accounts.',
                  inputDescription:
                    'Invite new people to create an account with Verta and join your team.',
                }}
                iconButtonProps={{
                  onClick: () =>
                    trackEvent({
                      type: 'catalog.registered_model_version.share_icon_clicked',
                    }),
                }}
                onSuccess={(emails) => {
                  trackEvent({
                    type: 'catalog.registered_model_version.shared',
                    data: {
                      inviter: pick(currentUser, [
                        'id',
                        'email',
                        'firstName',
                        'lastName',
                        'jobTitle',
                        'company',
                      ]),
                      invitees: { emails },
                    },
                  });
                }}
              />
              <ModelVersionPageSettings version={data} />
            </Stack>
          }
        />
      }
    >
      <Outlet />
    </TabsLayout>
  );
};

export default Layout;
