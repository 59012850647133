import {
  compareKeyValuePairs,
  KeyValuePairsDiff,
} from './keyValuePairsComparing';

export type LabelsDiff = KeyValuePairsDiff<string>;

export const compareLabels = <E extends { id: string }>(
  entities: E[],
  getLabels: (e: E) => string[]
) =>
  compareKeyValuePairs(entities, (e) =>
    getLabels(e).map((label) => ({ key: label, value: label }))
  );
