import { HttpError, AppError, isAppError } from 'shared/models/Error';

import { hasKey } from './collection';
import {
  defaultErrorMessages,
  commonAPIErrorMessages,
} from './customErrorMessages';
import { exhaustiveCheckWithDef } from './exhaustiveCheck';
import isNotNil from './isNotNill';

const normalizeApiErrorMessage = (
  apiError: HttpError<string | undefined>
): string => {
  if (apiError.type && hasKey(apiError.type, commonAPIErrorMessages)) {
    return commonAPIErrorMessages[apiError.type];
  }
  if (apiError.isMessageUserFriendly && apiError.message) {
    return apiError.message || defaultErrorMessages.default_api_error;
  }
  switch (apiError.category) {
    case 'clientError':
      return getErrorByHttpErrorStatus(apiError);
    case 'serverError':
      return defaultErrorMessages.server_error_5xx;
    default:
      return defaultErrorMessages.default_api_error;
  }
};

export const normalizeAppErrorMessage = (
  appError: AppError | Error
): string => {
  if (isAppError(appError)) {
    switch (appError.name) {
      case 'graphqlError': {
        const httpCode = appError.getErrors()[0].extensions?.['http-code'];
        if (isNotNil(httpCode) && httpCode >= 500) {
          return defaultErrorMessages.server_error_5xx;
        }

        if (isNotNil(httpCode)) {
          return (
            appError.getErrors()[0].message ||
            defaultErrorMessages.default_api_error
          );
        }

        return defaultErrorMessages.default_api_error;
      }
      case 'apiError':
        return normalizeApiErrorMessage(appError);
      case 'codeError':
        return defaultErrorMessages.default_code_error;
      case 'customError':
        return appError.message;
      default:
        return exhaustiveCheckWithDef(
          appError,
          defaultErrorMessages.unknown_error
        );
    }
  }
  return appError.message;
};

function getErrorByHttpErrorStatus(apiError: HttpError<string | undefined>) {
  switch (apiError.status) {
    case 401:
      return defaultErrorMessages.client_error_401;
    case 403:
      return defaultErrorMessages.client_error_403;
    case 404:
      return defaultErrorMessages.client_error_404;
    default:
      return defaultErrorMessages.client_error_4xx;
  }
}
