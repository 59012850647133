import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';

import { IntegrationsListItemType } from 'pages/authorized/SystemAdmin/shared/types/IntegrationsListItemType';
import { SupportedIntegrations } from 'shared/models/SystemAdmin/SupportedIntegrations';
import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';
import useQueryFromFunction from 'shared/utils/graphql/useQueryFromFunction';
import matchType from 'shared/utils/matchType';
import noop from 'shared/utils/noop';
import { selectFlags } from 'features/flags';
import { SystemIntegrationsWithKafka } from 'features/systemAdmin/integrations/list/store/graphql-types/useSystemIntegrationsList.generated';
import { SystemIntegrationsWithoutKafka } from 'features/systemAdmin/integrations/list/store/graphql-types/useSystemIntegrationsList.generated';
import { useFirstRenderEffect } from 'shared/view/hooks/useFirstRenderEffect';

const QUERY_WITHOUT_KAFKA = gql`
  query SystemIntegrationsWithoutKafka {
    activeDirectory {
      id
      configuration {
        id
        enabled
        token
      }
    }
    pypiConfigurations {
      id
      configurations {
        id
        enabled
      }
    }
  }
`;

const QUERY_WITH_KAFKA = gql`
  query SystemIntegrationsWithKafka {
    activeDirectory {
      id
      configuration {
        id
        enabled
        token
      }
    }
    pypiConfigurations {
      id
      configurations {
        id
        enabled
      }
    }
    kafkaConfigurations {
      id
      configurations {
        id
        enabled
      }
    }
  }
`;

export const useSystemIntegrationsList = () => {
  const {
    deployment: { isEnableKafka },
  } = useSelector(selectFlags);

  const [fetch, communication, data] = useQueryFromFunction(
    (apolloClient) =>
      async ({
        variables,
        context,
      }: {
        variables: { isEnableKafka: boolean };
        context: string;
      }) => {
        return matchType(
          {
            true: async () => {
              const res = await apolloClient.query<SystemIntegrationsWithKafka>(
                {
                  query: QUERY_WITH_KAFKA,
                  context: { context },
                }
              );
              return convertSystemIntegrations(
                res.data,
                variables.isEnableKafka
              );
            },
            false: async () => {
              const res =
                await apolloClient.query<SystemIntegrationsWithoutKafka>({
                  query: QUERY_WITHOUT_KAFKA,
                  context: { context },
                });
              return convertSystemIntegrations(
                res.data,
                variables.isEnableKafka
              );
            },
          },
          String(variables.isEnableKafka)
        );
      },
    { onCompleted: noop, onError: noop }
  );

  useFirstRenderEffect(() => {
    fetch({
      variables: { isEnableKafka },
      context: 'loading system integrations',
    });
  });

  return {
    data,
    communication,
    reload: fetch,
  };
};

type PartlyOptionalResult = SystemIntegrationsWithoutKafka & {
  kafkaConfigurations?: SystemIntegrationsWithKafka['kafkaConfigurations'];
};

function convertSystemIntegrations(
  result: PartlyOptionalResult,
  isEnableKafka: boolean
): IntegrationsListItemType[] {
  const integrations: IntegrationsListItemType[] =
    exhaustiveStringTuple<SupportedIntegrations>()(
      'Pypi',
      'Kafka',
      'ActiveDirectory'
    ).map((type) => ({
      type,
      status: getStatus(result, type),
      chipProps:
        type === 'ActiveDirectory'
          ? {
              label: 'BETA',
              color: 'error',
            }
          : undefined,
    }));
  return integrations.filter(
    (value) => value.type !== 'Kafka' || isEnableKafka
  );
}

function getStatus(
  {
    activeDirectory,
    kafkaConfigurations,
    pypiConfigurations,
  }: PartlyOptionalResult,
  type: SupportedIntegrations
): 'active' | 'inactive' {
  const status = (condition: boolean) => {
    return condition ? 'active' : 'inactive';
  };

  return matchType(
    {
      Pypi: () =>
        status(
          pypiConfigurations.configurations.some(({ enabled }) => enabled)
        ),
      Kafka: () =>
        status(
          Boolean(
            kafkaConfigurations?.configurations.some(({ enabled }) => enabled)
          )
        ),
      ActiveDirectory: () =>
        status(Boolean(activeDirectory.configuration?.enabled)),
    },
    type
  );
}
