import { useState } from 'react';
import { Typography } from '@mui/material';

import Popup from 'shared/view/elements/Popup/Popup';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { useNewConfiguration } from 'features/systemAdmin/integrations/shared/useNewConfiguration';
import SwitchField from 'shared/view/formComponents/formikFields/SwitchField/SwitchField';
import Alert from 'shared/view/elements/Alert/Alert';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import { KafkaConfigurationInput } from 'generated/types';

import { useDeleteKafkaConfiguration } from '../../hooks/useDeleteKafkaConfiguration';
import { useTestKafkaConfiguration } from '../../hooks/useTestKafkaConfiguration';
import { useSaveDisabledInternal } from '../../hooks/useSaveDisabledInternal';
import KafkaConfigurationForm from '../form/KafkaConfigurationForm/KafkaConfigurationForm';

const getFieldName = makeGetFieldName<KafkaConfigurationInput>();

const textAlertDelete = `
  Are you sure you want to delete this Kafka integration? Any
  active endpoints using the integration will stop working. This
  action cannot be undone.`;

enum AlertType {
  'DELETING',
  'DISABLING',
  'CLOSING',
}

type AlertProps = {
  showAlert: boolean;
  alertText?: string;
  alertType?: AlertType;
};

interface KafkaFormProps {
  onClose: () => void;
  initialValues: KafkaConfigurationInput;
  deleteIsDisabled: boolean;
  isSaving: boolean;
  configurationId?: string;
  handleSubmit: (values: KafkaConfigurationInput, id?: string) => void;
  onCompletedSuccessfully: () => void;
}

export const HandleKafkaForm = (props: KafkaFormProps) => {
  const {
    initialValues,
    onClose,
    deleteIsDisabled,
    configurationId,
    isSaving,
    onCompletedSuccessfully,
    handleSubmit,
  } = props;
  const [alertConfig, setAlertConfig] = useState<AlertProps>({
    alertText: '',
    alertType: undefined,
    showAlert: false,
  });
  const { deleteKafkaConfiguration, deletingKafkaConfiguration } =
    useDeleteKafkaConfiguration({
      onCompleted: onCompletedSuccessfully,
    });

  const handleClose = (dirty: boolean) => {
    if (!dirty || alertConfig.alertType === AlertType.CLOSING) {
      onClose();
    }
    if (dirty) {
      setAlertConfig({
        showAlert: true,
        alertText: `You haven't saved any of the changes.`,
        alertType: AlertType.CLOSING,
      });
    }
  };

  const closeAlert = () =>
    setAlertConfig({
      alertText: '',
      alertType: undefined,
      showAlert: false,
    });

  const handleDisable = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLFormElement;
    if (!target.checked && initialValues.enabled) {
      setAlertConfig({
        showAlert: true,
        alertText: `Any live endpoint using the current Kafka setup will stop working 
        if you disable the integration. Click on save to continue.`,
        alertType: AlertType.DISABLING,
      });
    } else {
      closeAlert();
    }
  };

  return (
    <PresetFormik<KafkaConfigurationInput>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values, configurationId)}
    >
      {function Content({ isValid, submitForm, values, dirty }) {
        const { isConfigurationTested, setConfigurationAsTested } =
          useNewConfiguration(values);
        const { testKafkaConfiguration, testingKafkaConfiguration } =
          useTestKafkaConfiguration({
            onSuccess: setConfigurationAsTested,
          });
        const { saveIsDisabled, saveIsLoading } = useSaveDisabledInternal({
          isConfigurationTested,
          isValid,
          values,
          initialValues: initialValues,
          isSaving: isSaving,
        });
        return (
          <Popup
            title="Kafka integration set up"
            isOpen={true}
            maxWidth="md"
            fullWidth
            onClose={() => handleClose(dirty)}
            headerAdornment={
              <SwitchField
                onClick={handleDisable}
                label={<Typography variant="overline">enable</Typography>}
                labelPlacement="start"
                name={getFieldName({ enabled: null })}
              />
            }
            buttons={{
              mainButtonProps: {
                disabled: saveIsDisabled,
                type: 'button',
                dataTest: 'save-kafka-setup-button',
                children: 'Save',
                onClick: submitForm,
                isLoading: saveIsLoading,
              },
              secondaryButtonProps: {
                disabled: deleteIsDisabled,
                dataTest: 'delete-kafka-setup-button',
                children: 'Delete',
                onClick: () =>
                  setAlertConfig({
                    alertType: AlertType.DELETING,
                    alertText: textAlertDelete,
                    showAlert: true,
                  }),
                isLoading: deletingKafkaConfiguration.isRequesting,
              },
            }}
          >
            <>
              {alertConfig.showAlert ? (
                <Alert
                  severity="warning"
                  title={
                    alertConfig.alertType === AlertType.DELETING
                      ? 'Warning'
                      : undefined
                  }
                  onClose={closeAlert}
                  actions={
                    alertConfig.alertType === AlertType.DELETING
                      ? [
                          {
                            children: 'Proceed with deletion',
                            variant: 'contained',
                            isLoading: deletingKafkaConfiguration.isRequesting,
                            onClick: async () => [
                              await deleteKafkaConfiguration({
                                id: configurationId ?? '',
                              }),
                              onClose(),
                            ],
                          },
                        ]
                      : undefined
                  }
                >
                  {alertConfig.alertText}
                </Alert>
              ) : null}
              <KafkaConfigurationForm
                initialValues={initialValues}
                isValid={isValid}
                testingKafkaConfigurationLoading={
                  testingKafkaConfiguration.isRequesting
                }
                testKafkaConfiguration={() =>
                  void testKafkaConfiguration({ config: values })
                }
              />
            </>
          </Popup>
        );
      }}
    </PresetFormik>
  );
};
