/* eslint-disable rulesdir/no-deprecated-fields */
import { ApolloError } from '@apollo/client';
import { gql } from '@apollo/client';
import { useCallback } from 'react';

import noop from 'shared/utils/noop';
import matchType from 'shared/utils/matchType';
import { makeEndpointPath } from 'shared/models/Deployment/canary/Endpoint';
import { BuildSource } from 'shared/models/Deployment/canary/Build';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import {
  isNotNullableRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';

import { CreateEndpointSettingsWithWorkspaceName } from './types';
import * as Types from './graphql-types/createEndpoint.generated';

const CREATE_ENDPOINT = gql`
  mutation CreateEndpoint(
    $workspaceName: String!
    $organizationId: ID
    $path: String
    $description: String
    $labels: [String!]!
    $environmentName: String!
    $visibility: Visibility
    $modelId: ID
    $modelVersionId: ID
    $tokensEnabled: Boolean!
    $skipCreatingBuild: Boolean!
    $enableAuthzPrediction: Boolean!
    $kafkaReq: KafkaConfigUpdateRequestInput
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        createEndpoint(
          path: $path
          description: $description
          permission: { visibility: $visibility }
        ) {
          id
          description
          createEnvironment(
            name: $environmentName
            enableAccessTokenPrediction: $tokensEnabled
            enableAuthzPrediction: $enableAuthzPrediction
            kafkaReq: $kafkaReq
          ) {
            id
            createBuild(runId: $modelId, modelVersion: $modelVersionId)
              @skip(if: $skipCreatingBuild) {
              id
            }
            id
          }
          addLabels(labels: $labels) {
            id
            labels
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export function useCreateEndpointMutation(handlers: {
  onError?: (error: ApolloError) => void;
  onCompleted: ({ endpointId }: { endpointId: string }) => void;
}) {
  const [mutate, creatingEndpoint] = useCustomMutation<
    Types.CreateEndpoint,
    Types.CreateEndpointVariables
  >(
    CREATE_ENDPOINT,
    { context: 'creating endpoint' },
    {
      onCompleted: (res) => {
        if (isNotNullableRestrictedGraphqlError(res.workspace)) {
          handlers.onCompleted({
            endpointId: res.workspace.createEndpoint.id,
          });
        }
      },
      onError: handlers.onError || noop,
    }
  );

  const createEndpoint = useCallback(
    (variables: CreateEndpointSettingsWithWorkspaceName) => {
      const path = variables.path && makeEndpointPath(variables.path);
      mutate({
        environmentName: variables.environmentName,
        organizationId: variables.organizationId,
        labels: variables.labels,
        workspaceName: variables.workspaceName,
        description: variables.description,
        visibility: variables.visibility,
        tokensEnabled: variables.tokensEnabled,
        skipCreatingBuild: !variables.sourceId,
        path,
        enableAuthzPrediction: variables.authzPredictionEnabled,
        ...matchType<
          BuildSource,
          { modelId?: string; modelVersionId?: string }
        >(
          {
            experimentRun: () => ({ modelId: variables.sourceId }),
            modelVersion: () => ({ modelVersionId: variables.sourceId }),
            noModel: () => ({}),
          },
          variables.source
        ),
        kafkaReq: variables.kafkaReq,
      });
    },
    [mutate]
  );

  return { createEndpoint, creatingEndpoint };
}
