import { AlertStatus } from 'generated/types';
import { MonitoringModelAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import routes from 'shared/routes';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import LinkWrapper from 'shared/view/elements/LinkWrapper/LinkWrapper';
import Tooltip from 'shared/view/elements/Tooltip/Tooltip';

const AlertAction = (props: {
  alert: MonitoringModelAlert | undefined;
  monitoringModelId: string;
  workspaceName: string;
}) => {
  return props.alert?.status === AlertStatus.ALERTING ? (
    <Tooltip type="withSpan" title="Alerting">
      <LinkWrapper
        to={routes.monitoringAlert.getRedirectPath({
          alertId: props.alert.id,
          monitoringModelId: props.monitoringModelId,
          workspaceName: props.workspaceName,
        })}
      >
        <IconAwesomeInfo infoType="error" icon={ICONS.circleExclamation} />
      </LinkWrapper>
    </Tooltip>
  ) : null;
};

export default AlertAction;
