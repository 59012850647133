import { useEffect, useState } from 'react';
import { Box, Grid, Divider, Stack, Typography, useTheme } from '@mui/material';
import { isEmpty, keys, padStart } from 'lodash';

import {
  EvaluationData,
  EvaluationAttributeKey,
  EvaluationHeaderKey,
  buildDefaultFromLabels,
} from 'features/evaluations/shared/utils/evaluation';
import { Button } from 'shared/view/elements/Button';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import { ICommunication } from 'shared/utils/redux/communication';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import AddTagModal from 'shared/view/domain/Labels/TagsManager/AddTagModal/AddTagModal';
import { canUserUpdateRegisteredModelVersion } from 'shared/models/Registry/RegisteredModelVersion/Access';
import {
  useAddLabelMutation,
  useDeleteLabelMutation,
} from 'features/catalog/registeredModelVersion/summary/view/LabelsManager/store/modelVersionLabelsManager/modelVersionLabelsManager';
import { useUpdateEvaluationAttributes } from 'features/evaluations/evaluation/summary/hooks/useUpdateEvaluationAttributes';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import { combineCommunications } from 'shared/utils/redux/communication/combineCommunications';
import Chip from 'shared/view/elements/Chip/Chip';
import isNotNil from 'shared/utils/isNotNill';

import { Accordion } from './Accordion';
import { MetadataList } from './MetadataList';
import { Evaluation } from '../../hooks/useEvaluationSummary';

type Props = Pick<Evaluation, 'id' | 'registeredModel' | 'rows' | 'labels'> & {
  row: EvaluationData;
  currentRowIdx: number;
  totalRows: number;
  handleMoveToRowClick: (idx: number) => void;
} & (
    | {
        isReadOnly?: true;
        communication?: undefined;
        handleUpdateResult?: undefined;
        handleUpdateLabel?: undefined;
        handleUpdateFeedback?: undefined;
      }
    | {
        isReadOnly?: false;
        communication: ICommunication;
        handleUpdateResult: (result: boolean) => void;
        handleUpdateLabel: (key: string) => void;
        handleUpdateFeedback: (value: string) => void;
      }
  );

export const EvaluateRow = (props: Props) => {
  const {
    id,
    rows,
    registeredModel: { allowedActions },
    row,
    currentRowIdx,
    totalRows,
    handleMoveToRowClick,
    isReadOnly = false,
    communication,
    handleUpdateResult,
    handleUpdateLabel,
    handleUpdateFeedback,
  } = props;

  const [buttonClicked, setButtonClicked] = useState<
    'back' | 'next' | undefined
  >(undefined);

  const backIsLoading = isReadOnly
    ? false
    : buttonClicked === 'back' && Boolean(communication?.isRequesting);
  const backIsDisabled = communication?.isRequesting || currentRowIdx === 0;

  const nextIsLoading = isReadOnly
    ? false
    : buttonClicked === 'next' && Boolean(communication?.isRequesting);
  const nextIsDisabled =
    communication?.isRequesting ||
    currentRowIdx >= totalRows ||
    (isReadOnly && currentRowIdx === totalRows - 1);
  const wasAccepted = row[EvaluationHeaderKey.RESULT];

  const [addLabel, addingLabel] = useAddLabelMutation();
  const [deleteLabel, deletingLabel] = useDeleteLabelMutation();
  const { updateEvaluationAttributes, updatingEvaluationAttributes } =
    useUpdateEvaluationAttributes();
  const theme = useTheme();

  const handleLabelsModified = (_id: Props['id'], _labels: Props['labels']) => {
    const defaultLabels = buildDefaultFromLabels(_labels);
    const newAttributes = rows.map((_row) => {
      const newLabels = { ...defaultLabels };
      keys(defaultLabels).forEach((label) => {
        newLabels[label] = Boolean(_row[EvaluationHeaderKey.LABELS][label]);
      });

      return {
        ..._row,
        [EvaluationHeaderKey.LABELS]: newLabels,
      };
    });

    const attributes = newAttributes.map((attr) => ({
      key: `${EvaluationAttributeKey.ROW}_${attr[EvaluationHeaderKey.ID]}`,
      value: JSON.stringify(attr),
    }));

    updateEvaluationAttributes({ id, attributes });
  };

  const onRemoveLabel = (label: string) =>
    deleteLabel(
      { id, label },
      {
        onCompleted: ({ registeredModelVersion }) => {
          if (isNotError(registeredModelVersion)) {
            const result = registeredModelVersion.deleteLabels;
            handleLabelsModified(id, result.labels);
          }
        },
      }
    );

  const onAddLabel = (label: string) =>
    addLabel(
      { id, label },
      {
        onCompleted: ({ registeredModelVersion }) => {
          if (isNotError(registeredModelVersion)) {
            const result = registeredModelVersion.addLabels;
            handleLabelsModified(id, result.labels);
          }
        },
      }
    );

  const _communication = combineCommunications(
    [
      communication,
      addingLabel,
      deletingLabel,
      updatingEvaluationAttributes,
    ].filter(isNotNil)
  );

  useEffect(() => {
    if (communication?.isRequesting === false) {
      setButtonClicked(undefined);
    }
  }, [communication?.isRequesting]);

  return (
    <>
      <Stack
        direction="row"
        sx={{ height: 'calc(100% - 64px)', pt: 2 }}
        spacing={0.5}
      >
        <Box sx={{ overflow: 'auto', width: '66%', pr: 3 }}>
          <Box>
            {isReadOnly ? null : (
              <SubTitle evaluation={row} dataSize={totalRows} />
            )}
            <Stack spacing={2}>
              <Accordion
                title="INPUT"
                color="primary.main"
                backgroundColor="additionalBackground.evaluationInput"
                backgroundColorOpacity={0.02}
                defaultExpanded
              >
                {row.input}
              </Accordion>
              <Accordion
                title="metadata"
                color="info.main"
                backgroundColor="additionalBackground.evaluationMetadata"
              >
                <MetadataList row={row} />
              </Accordion>
              <Accordion
                title="output"
                color="warning.main"
                backgroundColor="additionalBackground.evaluationOutput"
                backgroundColorOpacity={0.02}
                defaultExpanded
              >
                {row.output}
              </Accordion>
              {row.groundtruth ? (
                <Accordion
                  title="Ground truth"
                  color="success.main"
                  backgroundColor="additionalBackground.evaluationGroundTruth"
                  backgroundColorOpacity={0.02}
                  defaultExpanded
                >
                  {row.groundtruth}
                </Accordion>
              ) : null}
              {row.trace ? (
                <Accordion
                  title="trace"
                  color="error.main"
                  backgroundColor="additionalBackground.evaluationTrace"
                  backgroundColorOpacity={0.02}
                  defaultExpanded
                >
                  {row.trace}
                </Accordion>
              ) : null}
            </Stack>
          </Box>
        </Box>
        <Divider orientation="vertical" />
        <Box sx={{ overflow: 'auto', width: '34%', pl: 2.5 }}>
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Typography variant="overline">annotate this row</Typography>
              <Stack direction="row" spacing={1}>
                <Button
                  isLoading={false}
                  color="success"
                  icon={ICONS.check}
                  variant={wasAccepted ? 'contained' : 'outlined'}
                  onClick={() => handleUpdateResult?.(true)}
                  disabled={!wasAccepted && isReadOnly}
                  sx={
                    isReadOnly
                      ? {
                          padding: '4px 10px',
                          '&:hover': {
                            cursor: 'not-allowed',
                            boxShadow: 'none',
                            backgroundColor: theme.palette.success.main,
                          },
                        }
                      : {
                          padding: '4px 10px',
                        }
                  }
                >
                  Accept
                </Button>
                <Button
                  isLoading={false}
                  color="error"
                  icon={ICONS.xMark}
                  variant={wasAccepted === false ? 'contained' : 'outlined'}
                  onClick={() => handleUpdateResult?.(false)}
                  disabled={wasAccepted === undefined && isReadOnly}
                  sx={
                    isReadOnly
                      ? {
                          padding: '4px 10px',
                          '&:hover': {
                            cursor: 'not-allowed',
                            boxShadow: 'none',
                            backgroundColor: theme.palette.error.main,
                          },
                        }
                      : {
                          padding: '4px 10px',
                        }
                  }
                >
                  Reject
                </Button>
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="overline">labels</Typography>
                {!isReadOnly ? (
                  <ButtonWithPopup
                    key={0}
                    button={(buttonProps) => (
                      <Button
                        {...buttonProps}
                        isLoading={false}
                        variant="text"
                        size="small"
                        icon={ICONS.pencil}
                      >
                        Edit labels
                      </Button>
                    )}
                    popup={(popupProps) => (
                      <AddTagModal
                        {...popupProps}
                        tags={props.labels}
                        isUpdating={_communication.isRequesting}
                        isEditable={canUserUpdateRegisteredModelVersion(
                          allowedActions
                        )}
                        onRemove={onRemoveLabel}
                        onAdd={onAddLabel}
                      />
                    )}
                  />
                ) : null}
              </Stack>
              <Box>
                <Grid
                  container={true}
                  direction="row"
                  alignItems={'flex-start'}
                  style={{ width: '100%' }}
                  gap={1}
                >
                  {isEmpty(row[EvaluationHeaderKey.LABELS]) ? (
                    <Typography variant="body2" color="text.disabled">
                      No labels yet.
                    </Typography>
                  ) : (
                    keys(row[EvaluationHeaderKey.LABELS]).map((key) => {
                      const isActive = row[EvaluationHeaderKey.LABELS][key];
                      return (
                        <Grid item key={key} style={{ maxWidth: '100%' }}>
                          <Chip
                            label={key}
                            variant={isActive ? 'filled' : 'outlined'}
                            size="small"
                            color="primary"
                            disabled={!isActive && isReadOnly}
                            clickable={!isReadOnly}
                            onClick={() => handleUpdateLabel?.(key)}
                          />
                        </Grid>
                      );
                    })
                  )}
                </Grid>
              </Box>
            </Stack>
            <Stack>
              <Typography variant="overline">feedback</Typography>
              {isReadOnly ? (
                <Typography
                  variant="body2"
                  color={
                    row[EvaluationHeaderKey.FEEDBACK]
                      ? 'text.primary'
                      : 'text.disabled'
                  }
                >
                  {row[EvaluationHeaderKey.FEEDBACK] || 'No feedback yet.'}
                </Typography>
              ) : (
                <TextInput
                  maxWidth="100%"
                  multiline
                  rows={8}
                  withoutError
                  sx={{
                    '.MuiInputBase-root': {
                      borderBottomRightRadius: '0px',
                      borderBottomLeftRadius: '0px',
                    },
                  }}
                  onChange={(val) => handleUpdateFeedback?.(val)}
                  value={row[EvaluationHeaderKey.FEEDBACK]}
                />
              )}
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <Box pt={3} display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          isLoading={backIsLoading}
          icon={ICONS.arrowLeft}
          onClick={() => {
            setButtonClicked('back');
            handleMoveToRowClick(currentRowIdx - 1);
          }}
          disabled={backIsDisabled}
        >
          Previous
        </Button>
        <Stack direction="row" alignItems="center" spacing={2}>
          {isReadOnly ? null : (
            <Typography variant="caption" color="text.secondary">
              All information is saved once you hit Next or Previous
            </Typography>
          )}
          <Button
            variant="outlined"
            isLoading={nextIsLoading}
            iconEnd={ICONS.arrowRight}
            onClick={() => {
              setButtonClicked('next');
              handleMoveToRowClick(currentRowIdx + 1);
            }}
            disabled={nextIsDisabled}
          >
            {currentRowIdx < totalRows - 1 ? 'Next' : 'Finish'}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

const SubTitle = ({
  evaluation,
  dataSize,
}: {
  evaluation: EvaluationData;
  dataSize: number;
}) => {
  const padNum = String(dataSize).length;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={1.25}
    >
      <Typography variant="subtitle1">
        {`ID ${padStart(evaluation[EvaluationHeaderKey.ID], padNum, '0')}`}
      </Typography>
      <Typography variant="body2">
        {`${padStart(
          evaluation[EvaluationHeaderKey.ID],
          padNum,
          '0'
        )}/${dataSize}`}
      </Typography>
    </Box>
  );
};
