import { gql } from '@apollo/client';

import { USER_FRAGMENT } from 'shared/graphql/User/User';

export const ACTIVITY_FRAGMENT = gql`
  fragment ActivityFragment on Activity {
    id
    author {
      id
      ...UserData
    }
    activityState
    comment
    transition {
      id
      desiredStage
      sourceStage
      state
    }
    timeCreated
  }
  ${USER_FRAGMENT}
`;

export const ACTIVITY_AFTER_UPDATING_FRAGMENT = gql`
  fragment ActivityAfterUpdatingFragment on Activity {
    id
    version {
      id
      stage
      activities {
        id
        ...ActivityFragment
      }
    }
  }
  ${ACTIVITY_FRAGMENT}
  ${USER_FRAGMENT}
`;
