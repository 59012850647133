import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Typography, Stack } from '@mui/material';

import { AuthExternalServiceProvider } from 'shared/models/Authorization';
import routes from 'shared/routes';
import { authenticateUserByExternalService } from 'features/user/store';
import IconButton from 'shared/view/elements/IconButton/IconButton';
import { trackEvent } from 'setup/app/analytics';

export const ExternalSignUpServiceButton: React.FC<
  React.PropsWithChildren<{
    icon: IconDefinition;
    provider: AuthExternalServiceProvider;
    providerName: string;
    redirectBack?: string;
  }>
> = ({ icon, provider, providerName, redirectBack }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const authenticateViaExternalService = useCallback(() => {
    const currentQueryParams = routes.login.parseQueryParams(location.search);
    dispatch(
      authenticateUserByExternalService({
        authExternalServiceProvider: provider,
        redirectBack: redirectBack || currentQueryParams?.redirect_back,
      })
    );
    trackEvent({
      type: 'sign_up.with_third_party',
      data: {
        provider: provider,
      },
    });
  }, [dispatch, location.search, provider, redirectBack]);

  return (
    <Stack width="fit-content" textAlign="center" spacing={0.5}>
      <IconButton
        withPadding
        icon={icon}
        onClick={authenticateViaExternalService}
      />
      <Typography variant="caption" color="text.secondary">
        {providerName}
      </Typography>
    </Stack>
  );
};
