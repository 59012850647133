import { useMemo } from 'react';

import { useMetricOverTime } from 'features/monitoring/widgets/store/metricOverTime/useMetricOverTime';
import { getLabelByMonitoringMetricType } from 'shared/models/Monitoring/MonitoringMetricType';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { TimeSeriesMetricWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/TimeSeriesWidget';
import { OnSetReset } from 'shared/utils/charts/zoom/useZoomReset';
import { Size } from 'shared/utils/charts/chartSizes';
import { MetricOverTime } from 'features/monitoring/widgets/store/metricOverTime/graphql-types/useMetricOverTime.generated';
import parseGraphqlDate from 'shared/utils/graphql/parseGraphqlDate';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';
import { MonitoringAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import { MonitoringMetricType } from 'generated/types';
import { predictionCountOutput } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/SingleMetricWidget';
import { isNotRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { ExtractByTypename } from 'shared/utils/types';

import TimeSeriesChart, {
  TimeSeriesChartData,
} from './shared/TimeSeriesChart/TimeSeriesChart';
import { MonitoringWidgetProps } from '../shared/types';

const TimeSeriesMetricWidgetView = (
  props: MonitoringWidgetProps<TimeSeriesMetricWidget>
) => {
  const { communication, data } = useMetricOverTime({
    widgetExternalDeps: props.widgetExternalDeps,
    metricType: props.widget.variant.metricType,
    output:
      props.widget.variant.metricType !== MonitoringMetricType.PREDICTION_COUNT
        ? props.widget.variant.output
        : predictionCountOutput,
  });

  return (
    <DefaultMatchRemoteDataOrError
      data={data}
      communication={communication}
      context="loading time series"
    >
      {(loadedData) => (
        <TimeSeriesMetricChart
          id={props.id}
          metricOverTime={loadedData}
          size={props.size}
          yLabel={getLabelByMonitoringMetricType(
            props.widget.variant.metricType
          )}
          onSetReset={props.onSetReset}
          alert={props.widget.alert}
        />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

const TimeSeriesMetricChart = (props: {
  id: string;
  size: Size;
  onSetReset: OnSetReset;
  metricOverTime: ExtractByTypename<
    MetricOverTime['monitoredEntity'],
    'MonitoredEntity'
  >['metrics']['metricOverTime'];
  alert: MonitoringAlert | undefined;
  yLabel: string;
}) => {
  const data = useMemo(
    () => props.metricOverTime.flatMap(convertData),
    [props.metricOverTime]
  );

  return (
    <TimeSeriesChart
      id={props.id}
      data={data}
      size={props.size}
      yLabel={props.yLabel}
      onSetReset={props.onSetReset}
      alert={props.alert}
    />
  );
};

const convertData = (
  data: ExtractByTypename<
    MetricOverTime['monitoredEntity'],
    'MonitoredEntity'
  >['metrics']['metricOverTime'][0]
): TimeSeriesChartData[] => {
  return data.values.map((value, index) => ({
    value: parseGraphQLNumber(value),
    key: isNotRestrictedGraphqlError(data.modelVersion)
      ? data.modelVersion.version
      : data.modelVersionId,
    time: parseGraphqlDate(data.time[index]),
    modelVersion: isNotRestrictedGraphqlError(data.modelVersion)
      ? data.modelVersion.version
      : data.modelVersionId,
  }));
};

export default TimeSeriesMetricWidgetView;
