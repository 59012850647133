import {
  Box,
  LinearProgress,
  Paper as MuiPaper,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material';
import { FileWithPath } from 'react-dropzone';

import { ICONS, ICONS_SOLID } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICommunication } from 'shared/utils/redux/communication';
import { formatBytes } from 'shared/utils/formatBytes';

import { getArtifactIcon } from '../Artifact';

const Paper = styled(MuiPaper)({
  padding: '12px 16px',
  height: '84px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const InfoFile = ({ file }: { file: FileWithPath }) => {
  const splitByDot = file.name.split('.');
  const extension = splitByDot[splitByDot.length - 1];
  const icon = getArtifactIcon(extension);

  return (
    <Stack>
      <Stack spacing={1} direction="row">
        <IconAwesomeInfo icon={icon} size="lg" />
        <Typography variant="body2" color="text.primary">
          {file.name}
        </Typography>
      </Stack>
      <Box mt={0.5}>
        <Typography variant="caption" color="text.secondary">
          .{extension} •{' '}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {formatBytes(file.size)}
        </Typography>
      </Box>
    </Stack>
  );
};

const StatusUploadIcon = ({
  uploadingArtifact,
  cancelRequest,
}: {
  uploadingArtifact?: ICommunication;
  cancelRequest: () => void;
}) => {
  return uploadingArtifact?.isRequesting ? (
    <IconAwesomeClickable
      icon={ICONS.xMark}
      size="lg"
      onClick={cancelRequest}
      sx={{ height: '20px', width: '20px' }}
    />
  ) : (
    <IconAwesomeInfo
      infoType={uploadingArtifact?.isSuccess ? 'success' : 'error'}
      icon={
        uploadingArtifact?.isSuccess
          ? ICONS_SOLID.circleCheck
          : ICONS_SOLID.circleXmark
      }
      size="lg"
    />
  );
};

export const FileItem = ({
  file,
  uploadingArtifact,
  isValidFile,
  cancelRequest,
  uploadProgress,
}: {
  file: FileWithPath;
  uploadingArtifact: ICommunication;
  isValidFile: boolean;
  cancelRequest: () => void;
  uploadProgress: number;
}) => {
  return (
    <Paper variant="outlined">
      <Stack spacing={0} width="100%">
        <Stack direction="row" justifyContent="space-between">
          <InfoFile file={file} />
          <Stack alignItems="flex-end">
            <StatusUploadIcon
              uploadingArtifact={uploadingArtifact}
              cancelRequest={cancelRequest}
            />
            {!isValidFile || uploadingArtifact.error ? (
              <Typography variant="caption" color="text.secondary" mt={0.5}>
                {!isValidFile
                  ? 'Error: file exceeds maximum file size of 10MB'
                  : uploadingArtifact.error?.message}
              </Typography>
            ) : (
              <Typography variant="caption" color="text.secondary" mt={0.5}>
                {uploadProgress}%
              </Typography>
            )}
          </Stack>
        </Stack>
        <Box
          sx={{ width: '100%' }}
          marginTop={0.5}
          color={uploadingArtifact.error ? 'action.disabled' : 'info.main'}
        >
          <LinearProgress
            variant="determinate"
            value={uploadProgress}
            color="inherit"
          />
        </Box>
      </Stack>
    </Paper>
  );
};
