import { gql } from '@apollo/client';

import { OwnerInput } from 'generated/types';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import matchBy from 'shared/utils/matchBy';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';

type ResourceType =
  | 'Project'
  | 'Endpoint'
  | 'RegisteredModel'
  | 'RegisteredModelVersion'
  | 'Dataset'
  | 'MonitoredEntity';

export type RequiredOwner =
  | { __typename: 'User'; id: string }
  | {
      __typename: 'Group';
      id: string;
    };

export type RequiredResource = {
  __typename: ResourceType;
  id: string;
  description: string;
  owner: RequiredOwner;
  allowedActions: { update: boolean };
};

const PROJECT_MUTATION = gql`
  mutation EditProjectInformation(
    $id: ID!
    $description: String!
    $ownerInput: OwnerInput!
  ) {
    project(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on Project {
        id
        changeDescription(description: $description) {
          id
          description
        }
        updateOwner(input: $ownerInput) {
          id
          owner {
            ...OwnerData
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

const DATASET_MUTATION = gql`
  mutation EditDatasetInformation(
    $id: ID!
    $description: String!
    $ownerInput: OwnerInput!
  ) {
    dataset(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on Dataset {
        id
        changeDescription(description: $description) {
          id
          description
        }
        updateOwner(input: $ownerInput) {
          id
          owner {
            ...OwnerData
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${OWNER_FRAGMENT}
`;

const REGISTERED_MODEL_MUTATION = gql`
  mutation EditRegisteredModelInformation(
    $id: ID!
    $description: String!
    $ownerInput: OwnerInput!
  ) {
    registeredModel(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        updateDescription(description: $description) {
          id
          description
        }
        updateOwner(input: $ownerInput) {
          id
          owner {
            ...OwnerData
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

const REGISTERED_MODEL_VERSION_MUTATION = gql`
  mutation EditRegisteredModelVersionInformation(
    $id: ID!
    $ownerInput: OwnerInput!
  ) {
    registeredModelVersion(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        updateRegisteredModelVersion(fields: { owner: $ownerInput }) {
          id
          description
          owner {
            ...OwnerData
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

const ENDPOINT_MUTATION = gql`
  mutation EditEndpointInformation(
    $id: ID!
    $description: String!
    $ownerInput: OwnerInput!
  ) {
    endpoint(id: $id) {
      ... on Endpoint {
        id
        changeDescription(description: $description) {
          id
          description
        }
        updateOwner(input: $ownerInput) {
          id
          owner {
            ...OwnerData
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
`;

const MONITORED_ENTITY_MUTATION = gql`
  mutation EditMonitoredEntityInformation($id: ID!, $ownerInput: OwnerInput!) {
    monitoredEntity(id: $id) {
      ... on MonitoredEntity {
        id
        updateOwner(input: $ownerInput) {
          id
          owner {
            ...OwnerData
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
`;

export function useEditResourceInformation(
  resource: RequiredResource,
  onCompleted: () => void
) {
  const mutation = matchBy(
    resource,
    '__typename'
  )({
    Dataset: () => DATASET_MUTATION,
    Endpoint: () => ENDPOINT_MUTATION,
    Project: () => PROJECT_MUTATION,
    RegisteredModel: () => REGISTERED_MODEL_MUTATION,
    RegisteredModelVersion: () => REGISTERED_MODEL_VERSION_MUTATION,
    MonitoredEntity: () => MONITORED_ENTITY_MUTATION,
  });

  const [run, communication] = useCustomMutation<
    unknown,
    { description: string | undefined; ownerInput: OwnerInput; id: string }
  >(
    mutation,
    { context: 'editing info' },
    {
      onCompleted,
    }
  );

  return {
    communication,
    run,
  };
}
