import { gql } from '@apollo/client';

export const KAFKA_CONFIGURATIONS_SIMPLIFIED_LIST_FRAGMENT = gql`
  fragment KafkaConfigurationsSimplifiedListData on KafkaConfigurations {
    id
    configurations {
      id
      name
      enabled
    }
  }
`;
