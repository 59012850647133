import { gql } from '@apollo/client';

export const CHECKLIST_TEMPLATE_ITEM_FRAGMENT = gql`
  fragment ChecklistTemplateItemData on ChecklistTemplateItem {
    id
    name
    description
    itemType
    editable
    required
    requireEvidence
    userGroupId
    checklistTemplateId
    timeCreated
    timeUpdated
    sort
  }
`;
