import { QueryHookOptions } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';

import {
  LOAD_CUSTOM_ATTRIBUTE_DEFINITIONS,
  LoadCustomAttributeDefinitions,
  LoadCustomAttributeDefinitionsVariables,
} from '../graphql';

export const useLoadCustomAttributeDefinitions = (
  {
    organizationId,
  }: {
    organizationId: string;
  },
  options?: QueryHookOptions<
    LoadCustomAttributeDefinitions,
    LoadCustomAttributeDefinitionsVariables
  >
) => {
  const query = useCustomQuery<
    LoadCustomAttributeDefinitions,
    LoadCustomAttributeDefinitionsVariables
  >(LOAD_CUSTOM_ATTRIBUTE_DEFINITIONS, {
    ...options,
    variables: { organizationId },
  });

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return { data, communication };
};

const convertResponse = ({ organizationV2 }: LoadCustomAttributeDefinitions) =>
  mapDataOrError(organizationV2, (organization) =>
    mapDataOrError(
      organization.customAttributeDefinitions,
      (response) => response.customAttributeDefinitions
    )
  );
