import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import DefaultConfirmAction, {
  ConfirmType,
} from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';

import { ICONS } from '../IconAwesome/ICONS';

interface ILocalProps {
  description: string;
  customBasicText?: string;
  additionalConfirmText?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  entityName?: string;
  faiDataTest?: string;
  disabled?: boolean;
  size?: SizeProp;
  onDelete(): void;
  longPressConfirmation?: boolean;
}

export default function DeleteIconButton({
  faiDataTest,
  description,
  customBasicText,
  additionalConfirmText,
  confirmButtonText,
  cancelButtonText,
  disabled,
  entityName,
  size,
  onDelete,
  longPressConfirmation,
}: ILocalProps) {
  return (
    <DefaultConfirmAction
      description={description}
      type={ConfirmType.delete}
      customBasicText={customBasicText}
      additionalText={additionalConfirmText}
      confirmButtonText={confirmButtonText}
      cancelButtonText={cancelButtonText}
      entityName={entityName}
      longPressConfirmation={longPressConfirmation}
    >
      {(withConfirmAction) => (
        <IconAwesomeClickable
          icon={ICONS.trashCan}
          onClick={withConfirmAction(onDelete)}
          disabled={disabled}
          dataTest={faiDataTest || 'delete-button'}
          size={size ?? 'lg'}
        />
      )}
    </DefaultConfirmAction>
  );
}
