import { FC } from 'react';

import {
  getPredictionEndpoint,
  IEndpointInfo,
} from 'shared/models/Deployment/DeployedExperimentRun';
import { makeCurl } from 'shared/utils/curl';
import CodeBlock from 'shared/view/elements/CodeBlock/CodeBlock';

const CurlExampleRecord: FC<
  React.PropsWithChildren<{
    endpointInfo: IEndpointInfo;
    inputValue: string;
    disabled: boolean;
  }>
> = (props) => {
  const url = getPredictionEndpoint(
    props.endpointInfo.path,
    props.endpointInfo.fullUrl
  );

  const curl = makeCurl([
    props.endpointInfo.token
      ? ['-H', `"Access-Token: ${props.endpointInfo.token}"`]
      : undefined,
    ['-X', `POST ${url}`],
    ['-d', `'${props.inputValue.replace(/\s+/g, ' ')}'`],
    ['-H', `"Content-Type: application/json"`],
  ]);

  return (
    <CodeBlock withCopy my={2} disabledCopy={props.disabled}>
      {curl}
    </CodeBlock>
  );
};

export default CurlExampleRecord;
