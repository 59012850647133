import { usePopupManager } from 'shared/view/hooks/usePopupManager';

const ButtonWithPopup = (props: {
  popup: (props: { isOpen: boolean; onClose(): void }) => React.ReactNode;
  button: (props: { isLoading: boolean; onClick(): void }) => React.ReactNode;
}) => {
  const popupApi = usePopupManager();
  return (
    <>
      {popupApi.isPopupOpen
        ? props.popup({
            isOpen: popupApi.isPopupOpen,
            onClose: popupApi.closePopup,
          })
        : null}
      {props.button({ isLoading: false, onClick: popupApi.openPopup })}
    </>
  );
};

export default ButtonWithPopup;
