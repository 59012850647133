import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { SelectProps as MUISelectProps } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import { BoxProps } from '@mui/material/Box';
import { forwardRef } from 'react';

import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import isNotNil from 'shared/utils/isNotNill';
import { SELECT_HELPER_LABEL_ID } from 'shared/view/elements/Selects/shared/helpers/consts';
import VertaSelectBaseAdornment from 'shared/view/elements/Selects/shared/view/VertaSelectBaseAdornment';
import FormHelperText from 'shared/view/elements/FormHelperText/FormHelperText';
import FieldBox from 'shared/view/elements/FieldBox/FieldBox';
import { SelectIconComponent } from 'shared/view/elements/Selects/shared/view/SelectIconComponent';
import { getWidthsFromProps } from 'shared/utils/getWidthsFromProps';

export type SelectCommonProps = Pick<MUISelectProps, 'size' | 'MenuProps'> &
  Pick<BoxProps, 'ref' | 'maxWidth' | 'minWidth' | 'sx'> & {
    placeholder?: string;
    name?: string;
    label?: string;
    errorMessage?: string;
    withoutError?: boolean;
    withDescription?: boolean;
    withoutLabelPadding?: boolean;
    withoutArrow?: boolean;
    disabled?: boolean;
    isLoading?: boolean;
    required?: boolean;
    onClose?: () => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    dataTest?: string;
    dataTestError?: string;
    endAdornmentIcons?: JSX.Element[];
  };

type Props = SelectCommonProps & {
  children: JSX.Element;
};

const VertaSelectBase = forwardRef<unknown, Props>((props, ref) => {
  return (
    <FieldBox
      {...getWidthsFromProps(props)}
      pt={Boolean(props.withoutLabelPadding) ? 0 : 1}
      sx={props.sx}
      ref={ref}
    >
      <FormControl
        fullWidth
        error={isNotNil(props.errorMessage)}
        size={props.size || 'small'}
      >
        {isNotNil(props.label) ? (
          <InputLabel
            disabled={props.disabled}
            id={SELECT_HELPER_LABEL_ID}
            sx={{ paddingRight: 2 }}
          >
            <DynamicTypography value={props.label} />
          </InputLabel>
        ) : null}
        {props.children}
        {!props.withoutError ? (
          <FormHelperText
            error
            errorMessage={props.errorMessage}
            dataTestError={props.dataTestError}
          />
        ) : null}
      </FormControl>
    </FieldBox>
  );
});

const VertaSelectBaseOutlinedInput = styled(OutlinedInput)({
  '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
    paddingRight: 8,
  },
});

export function useSelectBaseCommonProps(
  props: SelectCommonProps,
  isEmpty: boolean
): Pick<
  MUISelectProps,
  | 'onBlur'
  | 'disabled'
  | 'labelId'
  | 'endAdornment'
  | 'input'
  | 'placeholder'
  | 'name'
  | 'onClose'
  | 'IconComponent'
  | 'size'
  | 'MenuProps'
> & { 'data-testid': string | undefined; label?: string } {
  const { isLoading, name, onBlur, onClose } = props;
  const disabled = isEmpty || Boolean(isLoading) || Boolean(props.disabled);

  const labelAppend = props.required ? ' *' : '';
  const label = props.label ? `${props.label}${labelAppend}` : undefined;

  return {
    onBlur,
    disabled,
    onClose,
    label,
    labelId: SELECT_HELPER_LABEL_ID,
    placeholder: props.placeholder,
    size: props.size,
    endAdornment: props.withoutArrow ? undefined : (
      <VertaSelectBaseAdornment
        optionsEmpty={isEmpty}
        isLoading={Boolean(isLoading)}
      />
    ),
    name,
    'data-testid': props.dataTest,
    input: (
      <VertaSelectBaseOutlinedInput
        label={label}
        sx={{ paddingRight: props.withoutArrow ? 0 : undefined }}
      />
    ),
    IconComponent: (p) =>
      props.withoutArrow ? null : <SelectIconComponent {...p} />,
    MenuProps: props.MenuProps,
  };
}

export default VertaSelectBase;
