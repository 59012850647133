import {
  getDefaultIODescriptions,
  MonitoringIODescription,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import {
  defaultTimeRange,
  makeDefaultModelState,
} from 'shared/models/Monitoring/MonitoringModel/defaultModelState/makeDefaultModelState';
import {
  MonitoringModelState,
  CURRENT_MONITORING_MODEL_VERSION,
  MonitoringNotInitializedError,
  MonitoringModelWithoutState,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import {
  getLastUpdatedRegisteredModelVersion,
  getSelectedRegisteredModelVersion,
  MonitoringModelRegisteredModelVersion,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringModelRegisteredModelVersion';
import { safeParseJson } from 'shared/utils/json';
import { getDefaultAggregation } from 'shared/models/Monitoring/MonitoringModel/MonitoringAggregation';
import { DEFAULT_MONITORED_MODEL_TYPE } from 'shared/models/Monitoring/MonitoringModel/MonitoredModelType';

import { getMonitoredModelTypeFromModelVersion } from './MonitoringModel';

export const parseOrGetDefaultMonitoringModelState = ({
  registeredModelVersions,
  json,
  ioDescriptions,
  monitoringModel,
  toastWarning,
}: {
  registeredModelVersions: MonitoringModelRegisteredModelVersion[];
  json: string;
  monitoringModel: MonitoringModelWithoutState;
  ioDescriptions: MonitoringIODescription[];
  toastWarning: (message: string) => void;
}): MonitoringModelState => {
  const state = safeParseJson<Partial<MonitoringModelState>>(json);

  const { defaultIODescription, defaultOutput } =
    getDefaultIODescriptions(ioDescriptions);

  if (!defaultIODescription || !defaultOutput) {
    toastWarning('Notice: Waiting for data to arrive, please check back later');
  }

  const registeredModelVersion = getLastUpdatedRegisteredModelVersion(
    registeredModelVersions
  );

  const monitoredModelType = registeredModelVersion
    ? getMonitoredModelTypeFromModelVersion(registeredModelVersion)
    : DEFAULT_MONITORED_MODEL_TYPE;

  if (
    state?.version === CURRENT_MONITORING_MODEL_VERSION &&
    state.dashboards &&
    state.aggregation &&
    state.registeredModelVersion &&
    state.compare &&
    state.stateMonitoredModelType === monitoredModelType
  ) {
    return {
      dashboards: state.dashboards,
      // Temporary solution to disable time range persisting
      timeRange: defaultTimeRange,
      aggregation: getDefaultAggregation(defaultTimeRange),
      version: state.version,
      registeredModelVersion: getSelectedRegisteredModelVersion(
        state.registeredModelVersion,
        registeredModelVersions
      ),
      filters: state.filters ?? [],
      compare: state.compare,
      stateMonitoredModelType: monitoredModelType,
    };
  }

  if (registeredModelVersion) {
    return makeDefaultModelState({
      owner: monitoringModel.owner,
      defaultIODescription,
      defaultOutput,
      registeredModelVersion,
      monitoredModelType: monitoringModel.type,
      registeredModelVersions,
    });
  }

  throw new MonitoringNotInitializedError(monitoringModel);
};
