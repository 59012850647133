import { Grid, Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';
import { useRegisteringModelContext } from 'features/homepage/hooks/useRegisteringModel';

import {
  CardsModel,
  LibraryType,
  LocationType,
  SourceType,
  TaskType,
} from '../CardsModel';

export const cards = [
  {
    id: 0,
    modelName: 'My own model',
    task: TaskType.MISCELLANEOUS,
    library: LibraryType.ANY,
    location: LocationType.LOCALLY,
    description:
      'Use your own model and register it using your local environment',
  },
  {
    id: 1,
    modelName: 'Census Income Classification',
    task: TaskType.TABULAR_CLASSIFICATION,
    source: SourceType.VERTA_COLLAB,
    library: LibraryType.SCIKIT,
    location: LocationType.CLOUD,
    description:
      'KNearest Neighbor Classifier trained on Adult Census Dataset to predict annual income',
    link: 'https://colab.research.google.com/github/VertaAI/examples/blob/registry_examples/registry/sklearn/sklearn-register-model.ipynb',
  },
  {
    id: 2,
    modelName: 'EfficientNetV2M ImageNet1k Classifier',
    task: TaskType.COMPUTER_VISION,
    source: SourceType.VERTA_COLLAB,
    library: LibraryType.KERAS,
    location: LocationType.CLOUD,
    description:
      'EfficientNetV2M model trained on ImageNet dataset of 1000 classes by Google',
    link: 'https://colab.research.google.com/github/VertaAI/examples/blob/registry_examples/registry/tensorflow/keras-imagenet-model.ipynb',
  },
  {
    id: 3,
    modelName: 'Wine Quality Regressor',
    task: TaskType.REGRESSION,
    source: SourceType.VERTA_COLLAB,
    library: LibraryType.XGBOOST,
    location: LocationType.CLOUD,
    description:
      'XGBoost Regressor trained to score from 0-10 on Wine Quality Dataset based on physiochemical features',
    link: 'https://colab.research.google.com/github/VertaAI/examples/blob/registry_examples/registry/xgboost/xgboost-registered-model.ipynb',
  },
  {
    id: 4,
    modelName: 'roBERTa Tweet Sentiment Analysis (2022)',
    task: TaskType.NLP,
    source: SourceType.HUGGING_FACE,
    library: LibraryType.PYTORCH_TS,
    location: LocationType.CLOUD,
    description:
      'A roBERTa-base model trained on ~58M tweets and finetuned for sentiment analysis with the TweetEval benchmark',
    link: 'https://colab.research.google.com/github/VertaAI/examples/blob/registry_examples/registry/huggingface/roberta-tweet-sentiment.ipynb',
  },
  {
    id: 5,
    modelName: 'EfficientNetV2M ImageNet1k Classifier',
    task: TaskType.COMPUTER_VISION,
    source: SourceType.VERTA_COLLAB,
    library: LibraryType.PYTORCH,
    location: LocationType.CLOUD,
    description:
      'EfficientNetV2M model trained on ImageNet dataset of 1000 classes by Google',
    link: 'https://colab.research.google.com/github/VertaAI/examples/blob/registry_examples/registry/pytorch/pytorch-imagenet-model.ipynb',
  },
  {
    id: 6,
    modelName: 'Movie Review Text Classification',
    task: TaskType.NLP,
    source: SourceType.VERTA_COLLAB,
    library: LibraryType.KERAS,
    location: LocationType.CLOUD,
    description:
      'Binary text classifier to classify movie reviews as positive or negative',
    link: 'https://colab.research.google.com/github/VertaAI/examples/blob/registry_examples/registry/tensorflow/keras-imdb-model.ipynb',
  },
];

export const SelectModel = (props: WizardStepContentProps) => {
  const { cardSelected } = useRegisteringModelContext();

  const handleNext = () => {
    if (cardSelected?.location === LocationType.CLOUD) {
      props.next({ jumpTo: 4 });
    } else {
      props.next();
    }
  };

  return (
    <WizardStepContent
      {...props}
      buttons={{
        next: {
          skipWizardNext: true,
          onClick: handleNext,
          disabled: cardSelected === undefined,
        },
      }}
    >
      <Typography variant="subtitle1" mb={2}>
        Select a model
      </Typography>
      <Typography variant="body2">
        Select a model below to get started:
      </Typography>
      <Grid container columnSpacing={3}>
        <CardsModel cards={cards} />
      </Grid>
    </WizardStepContent>
  );
};
