import { Stack } from '@mui/material';

import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import CellRendererDate from 'shared/view/elements/DataGrid/columns/CellRendererDate';
import CellRendererNumeric from 'shared/view/elements/DataGrid/columns/CellRendererNumeric';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { EvaluationProject } from 'features/evaluations/evaluationsList/hooks/useWorkspaceEvaluationProjects';
import { useVirtualPagination } from 'shared/hooks/useVirtualPagination';
import CellRendererActions from 'shared/view/elements/DataGrid/columns/CellRendererActions';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';

import { EditProjectPopup } from './EditProject';
import { DeleteProjectPopup } from './DeleteProject';

type Props = {
  projects: EvaluationProject[];
  workspaceName: string;
};

const Actions = ({
  projectId,
  projectName,
}: {
  projectId: string;
  projectName: string;
}) => {
  const editPopupApi = usePopupManager();
  const deletePopupApi = usePopupManager();

  return (
    <>
      {editPopupApi.isPopupOpen ? (
        <EditProjectPopup
          projectId={projectId}
          previousValues={{ name: projectName }}
          isOpen={editPopupApi.isOpen}
          onClose={editPopupApi.closePopup}
        />
      ) : null}
      {deletePopupApi.isPopupOpen && (
        <DeleteProjectPopup
          projectId={projectId}
          projectName={projectName}
          isOpen={deletePopupApi.isOpen}
          onClose={deletePopupApi.closePopup}
        />
      )}
      <CellRendererActions
        actions={[
          {
            icon: ICONS.pencil,
            label: 'Edit Project',
            onClick: () => editPopupApi.openPopup(),
            type: 'icon',
          },
          {
            icon: ICONS.trashCan,
            label: 'Delete Project',
            onClick: () => deletePopupApi.openPopup(),
            type: 'icon',
          },
        ]}
      />
    </>
  );
};

const EvaluationProjects = ({ projects }: Props) => {
  const columns: DataGridColumn<EvaluationProject>[] = [
    {
      field: 'Project',
      flex: 2,
      renderCell: (params) => <CellRendererString value={params.row.name} />,
    },
    {
      field: 'Evaluations',
      minWidth: 70,
      renderCell: (params) => (
        <CellRendererNumeric value={params.row.versions.versions.length} />
      ),
    },
    {
      field: 'Created at',
      minWidth: 170,
      renderCell: (params) => (
        <CellRendererDate value={new Date(params.row.dateCreated)} showTime />
      ),
    },
    {
      field: 'Actions',
      renderCell: (params) => (
        <Actions projectId={params.row.id} projectName={params.row.name} />
      ),
    },
  ];

  const { paginatedValues, pagination } =
    useVirtualPagination<EvaluationProject>({
      totalCount: projects.length,
      values: projects,
      pageSize: 12,
    });

  return (
    <Stack height="100%">
      <DataGridWithTypes
        rows={paginatedValues}
        columns={columns}
        heightType="pageHeight"
        pagination={pagination.pagination}
        onPageChange={pagination.onCurrentPageChange}
        onPageSizeChange={pagination.onCurrentPageSizeChange}
      />
    </Stack>
  );
};

export default EvaluationProjects;
