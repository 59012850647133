/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import {
  ENDPOINT_FRAGMENT,
  BUILD_OR_ERROR_FRAGMENT,
} from 'shared/graphql/Deployment/Endpoint/Endpoint';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import {
  MACHINE_CONFIG_INFO_QUERY,
  AUTOSCALING_METRICS_QUERY,
} from 'shared/graphql/Deployment/Endpoint/MachineConfig/MachineConfigInfo';
import { getEndpointPollingInterval } from 'shared/models/Deployment/canary/Endpoint';
import usePollingQuery from 'shared/view/hooks/apollo/usePollingQuery';
import { KAFKA_CONFIGURATIONS_SIMPLIFIED_LIST_FRAGMENT } from 'shared/graphql/Deployment/Endpoint/KafkaConfig/KafkaConfiguration';
import { EndpointWithKafkaConfigurations } from 'features/deployment/canary/shared/EndpointWithKafkaConfigurations';
import {
  mapDataOrError,
  RestrictedGraphqlErrorOrData,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { ExtractByTypename } from 'shared/utils/types';

import * as GraphqlTypes from './graphql-types/endpointMonitoring.generated';

type Workspace = ExtractByTypename<
  GraphqlTypes.EndpointMonitoring['workspace'],
  'Workspace'
>;
type Endpoint = ExtractByTypename<Workspace['endpoint'], 'Endpoint'>;
export type Environments = Endpoint['environments'];
export type Monitoring = NonNullable<Environments[0]['monitoring']>[0];
export type Build = ExtractByTypename<
  Environments[0]['components'][0]['build'],
  'Build'
>;

export type EndpointWithMonitoring = Endpoint;
export type ICanaryStrategy = NonNullable<
  Environments[0]['updateRequest']
>['canaryStrategy'];
export type ICanaryRule = Environments[0]['canaryRules'][0];
export type BuildMonitoringLatency = Monitoring['latency'];
export type BuildMonitoringThroughput = Monitoring['throughput'][0];

const ENDPOINT_MONITORING = gql`
  query EndpointMonitoring(
    $workspaceName: String!
    $organizationId: ID
    $id: ID!
  ) {
    kafkaConfigurations {
      id
      ...KafkaConfigurationsSimplifiedListData
    }
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoint(id: $id) {
          ... on Error {
            ...ErrorData
          }
          ... on Endpoint {
            id
            ...EndpointData
            allowedActions {
              ...AllowedActionsData
            }
            description
            owner {
              ...OwnerData
            }
            workspace {
              id
              name
            }
            environments {
              id
              status
              components {
                build {
                  ...BuildOrErrorData
                }
                status
                buildID
                ratio
              }
              rolloutStatus {
                time
                components {
                  buildID
                  ratio
                  build {
                    ...BuildOrErrorData
                  }
                }
              }
              updateRequest {
                canaryStrategy {
                  progressInterval
                  progressStep
                  rules {
                    id
                    parameters {
                      name
                      value
                    }
                  }
                }
                ...MachineConfigInfo
              }
              canaryRules {
                id
                description
                name
                parameters {
                  name
                  description
                  type
                }
              }
              ...AutoscalingMetricsData
              monitoring {
                build {
                  ...BuildOrErrorData
                }
                time
                latency {
                  average
                  p90
                  p99
                }
                throughput {
                  code
                  value
                }
              }
            }
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${BUILD_OR_ERROR_FRAGMENT}
  ${ENDPOINT_FRAGMENT}
  ${MACHINE_CONFIG_INFO_QUERY}
  ${AUTOSCALING_METRICS_QUERY}
  ${KAFKA_CONFIGURATIONS_SIMPLIFIED_LIST_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;

export const useEndpointMonitoring = ({
  workspaceName,
  id,
  organizationId,
}: GraphqlTypes.EndpointMonitoringVariables) => {
  const queryResult = usePollingQuery<
    GraphqlTypes.EndpointMonitoring,
    GraphqlTypes.EndpointMonitoringVariables
  >(ENDPOINT_MONITORING, {
    variables: {
      workspaceName,
      id,
      organizationId,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    returnPartialData: true,
    pollInterval: getEndpointPollingInterval(),
  });

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert,
    queryResult,
  });
};

function memoizedConvert(
  res: GraphqlTypes.EndpointMonitoring
): RestrictedGraphqlErrorOrData<EndpointWithKafkaConfigurations<Endpoint>> {
  return mapDataOrError(
    res.workspace,
    (x) => x.endpoint,
    (endpoint) => ({
      endpoint,
      kafkaConfigurations: res.kafkaConfigurations.configurations,
    })
  );
}
