import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';
import AiAssistedDocsSVG from 'features/homepage/view/AdvancedFeatures/AiAssistedDocs/images/ai-assisted-docs.svg?react';

import { InformationalTile } from '../../../shared/InformationalTile';
import { TryNowButton } from '../../../shared/TryNowButton';

export const TrySampleModel = (props: WizardStepContentProps) => (
  <WizardStepContent
    {...props}
    buttons={{ next: { children: 'Complete tutorial' } }}
  >
    <Typography variant="subtitle1" mb={2}>
      Now it's your turn
    </Typography>
    <Typography variant="body2" mb={3}>
      Ready to see it in action? Give it a try!
    </Typography>
    <InformationalTile
      title="Try it on a sample model"
      description="Try out our AI Assisted Documentation feature to help you write great model documentation quickly and easily."
      svgComponent={<AiAssistedDocsSVG />}
    >
      <TryNowButton variant="outlined" />
    </InformationalTile>
  </WizardStepContent>
);
