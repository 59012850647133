import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import checklistCompleteItems from '../images/checklist-complete-items.png';

export const ChecklistCompleteItems = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Complete items on a checklist
    </Typography>
    <Typography variant="body2" mb={3}>
      Configured checklists are visible on the model version's release pages.
      All applicable checklists for that model will be shown. You can open any
      checklist to mark items as complete. A progress bar tracks the completion
      status of each checklist, as well as the overall fulfillment of
      requirements.
    </Typography>
    <img
      src={checklistCompleteItems}
      width="90%"
      height="auto"
      alt="checklist complete items"
    />
  </WizardStepContent>
);
