import { monitoringWidgetMinSize } from 'features/monitoring/panel/store/useCustomMonitoringPanel';
import generateId from 'shared/utils/generateId';

import { MonitoredModelType } from '../../../MonitoredModelType';
import { MonitoringPanel } from '../../../MonitoringPanel/MonitoringPanel';
import { MakeDefaultModelStateProps } from '../../shared/MakeDefaultModelStateProps';

export const makeDecisionsPanel = (
  props: MakeDefaultModelStateProps
): MonitoringPanel | null => {
  const decisionsLayout = [
    {
      minH: monitoringWidgetMinSize,
      minW: monitoringWidgetMinSize,
      h: 9,
      i: 'e1f7cf25-a2d8-427d-ae1e-6c68ca055368',
      w: 6,
      x: 0,
      y: 0,
    },
    {
      minH: monitoringWidgetMinSize,
      minW: monitoringWidgetMinSize,
      h: 9,
      i: '5af5371d-1703-488b-af6d-848c401022bb',
      w: 6,
      x: 6,
      y: 0,
    },
    {
      minH: monitoringWidgetMinSize,
      minW: monitoringWidgetMinSize,
      h: 13,
      i: 'e79db720-8673-4926-9340-58cf7062f6f9',
      w: 6,
      x: 0,
      y: 9,
    },
  ];

  return props.monitoredModelType === MonitoredModelType.CLASSIFICATION
    ? {
        id: generateId(),
        name: 'Decisions',
        type: 'custom',
        isOpen: false,
        layout: decisionsLayout,
        widgets: props.defaultOutput
          ? [
              {
                id: decisionsLayout[0].i,
                type: 'curve',
                variant: 'roc' as const,
                title: 'RoC',
                output: props.defaultOutput,
              },
              {
                id: decisionsLayout[1].i,
                type: 'curve',
                variant: 'precisionRecall',
                title: 'Precision recall',
                output: props.defaultOutput,
              },
              {
                id: decisionsLayout[2].i,
                type: 'confusionMatrix',
                title: 'Confusion matrix',
                output: props.defaultOutput,
              },
            ]
          : [
              {
                id: decisionsLayout[0].i,
                title: 'RoC',
                type: 'notInitialized',
                settings: {
                  type: 'curve',
                  variant: 'roc' as const,
                },
              },
              {
                id: decisionsLayout[1].i,
                type: 'notInitialized',
                title: 'Precision recall',
                settings: {
                  type: 'curve',
                  variant: 'precisionRecall',
                },
              },
              {
                id: decisionsLayout[2].i,
                type: 'notInitialized',
                title: 'Confusion matrix',
                settings: {
                  type: 'confusionMatrix',
                },
              },
            ],
      }
    : null;
};
