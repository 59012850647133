import { ActionType, createReducer } from 'typesafe-actions';

import { EntityId, IComment } from 'shared/models/Comment';

import * as actions from '../actions';
import { ICommentsState } from '../types';

const updateComments = (
  f: (x: IComment[] | undefined) => IComment[] | undefined,
  id: EntityId,
  state: ICommentsState['data']
): ICommentsState['data'] => {
  return {
    ...state,
    entitiesComments: {
      ...state.entitiesComments,
      [id]: f(state.entitiesComments[id]),
    },
  };
};

const dataReducer = createReducer<
  ICommentsState['data'],
  ActionType<typeof actions>
>({
  entitiesComments: {},
})
  .handleAction(actions.loadComments.success, (state, { payload }) =>
    updateComments(() => payload.data, payload.entityId, state)
  )
  .handleAction(actions.addComment.success, (state, { payload }) =>
    updateComments(
      (comments) => (comments || []).concat(payload.comment),
      payload.entityId,
      state
    )
  )
  .handleAction(actions.deleteComment.success, (state, { payload }) =>
    updateComments(
      (comments) =>
        (comments || []).filter((comment) => comment.id !== payload.commentId),
      payload.entityId,
      state
    )
  );

export default dataReducer;
