import { compareLabels } from 'shared/models/CompareEntities/new/labelsComparing';
import { compareEntitiesPrimitives } from 'shared/models/CompareEntities/new/primitivesComparing';
import { compareKeyValuePairs } from 'shared/models/CompareEntities/new/keyValuePairsComparing';
import { compareArtifacts } from 'shared/models/CompareEntities/new/artifactsComparing';
import { compareDatasets } from 'shared/models/CompareEntities/new/datasetsComparing';
import { compareEnvironments } from 'shared/models/CompareEntities/new/environmentsComparing';
import { compareAttributes } from 'shared/models/CompareEntities/new/attributesComparing';
import { compareCodeVersions } from 'shared/models/CompareEntities/new/codeVersionsComparing';

import { ComparedExperimentRuns } from './comparedExperimentRuns';

export const compareExperimentRuns = (
  experimentRuns: ComparedExperimentRuns
) => {
  return {
    name: compareEntitiesPrimitives(({ name }) => name, experimentRuns),
    description: compareEntitiesPrimitives(
      ({ description }) => description,
      experimentRuns
    ),
    experiment: compareEntitiesPrimitives(
      ({ experimentId }) => experimentId,
      experimentRuns
    ),
    tags: compareLabels(experimentRuns, ({ tags }) => tags),
    metrics: compareKeyValuePairs(experimentRuns, (run) => run.metrics),
    hyperparameters: compareKeyValuePairs(
      experimentRuns,
      (run) => run.hyperparameters
    ),
    artifacts: compareArtifacts(experimentRuns),
    datasets: compareDatasets(experimentRuns),
    attributes: compareAttributes(experimentRuns),
    environments: compareEnvironments(experimentRuns),
    codeVersions: compareCodeVersions(experimentRuns),
  };
};
