import { Box, Stack, styled, Typography } from '@mui/material';

import MLScreensSVG from './ml-screens.svg?react';
import { TryNowButton } from '../shared/TryNowButton';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.additionalBackground.assistedDocs,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(4),
}));

export const AssistedDocumentationBanner = () => {
  return (
    <Container>
      <Stack spacing={3} py={2}>
        <Stack spacing={2} maxWidth="430px">
          <Typography variant="h6">
            New: <strong>AI Assisted Documentation</strong>
          </Typography>
          <Typography variant="inputText">
            Try out our <strong>AI Assisted Documentation</strong> feature to
            help you write great model documentation quickly and easily.
          </Typography>
        </Stack>
        <TryNowButton />
      </Stack>
      <Box>
        <MLScreensSVG />
      </Box>
    </Container>
  );
};
