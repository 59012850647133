import { gql, MutationHookOptions } from '@apollo/client';
import { isEmpty } from 'lodash';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/useAddUsers.generated';

export { Types };

export const QUERY = gql`
  mutation AddUsersToOrganizationV2($id: ID!, $emails: [String!]!) {
    organizationV2(id: $id) {
      ... on OrganizationV2 {
        id
        addUsers(emails: $emails) {
          id
        }
      }
    }
  }
`;

export const useAddUsers = (
  options?: MutationHookOptions<
    Types.AddUsersToOrganizationV2,
    Types.AddUsersToOrganizationV2Variables
  >,
  hideToastError?: boolean
) => {
  const [run, communication] = useCustomMutation<
    Types.AddUsersToOrganizationV2,
    Types.AddUsersToOrganizationV2Variables
  >(
    QUERY,
    {
      context: 'adding users',
      hideToastError,
    },
    options
  );

  if (communication.error) {
    const emails = communication.error.message.split(':')[1].split(',');
    const last = emails.pop();
    const text = isEmpty(emails)
      ? last
      : `${emails.join(', ')} and ${last || ''}`;

    communication.error.message = `${
      text || ''
    } could not be added to your organization. Reach out to Verta support for further questions.`;
  }

  return {
    run,
    communication,
  };
};
