import routes from 'shared/routes';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { useCatalog } from 'features/usecases/registeredModel/catalogList/store/catalog/catalog';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { TabsLayout } from 'pages/authorized/shared/TabsLayout';

const Catalog = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'Catalog' */ 'features/usecases/registeredModel/catalogList/view/Catalog/Catalog'
    )
);

const CatalogPage = () => {
  const workspaceName: string = useWorkspaceNameFromURL();
  const catalogApi = useCatalog({ workspaceName });

  const breadcrumbs = useAppBreadcrumbs(
    useBreadcrumbItem({ name: 'AI Use Cases', route: routes.usecases })
  );

  return (
    <TabsLayout breadcrumbs={breadcrumbs} pageEntityOrError={catalogApi.data}>
      <Catalog {...catalogApi} />
    </TabsLayout>
  );
};

export default CatalogPage;
