import {
  applyMiddleware,
  legacy_createStore as createStore,
  PreloadedState,
  Store,
} from 'redux';
import reduxThunk from 'redux-thunk';

import {
  IApplicationState,
  IThunkActionDependencies,
  ConfigureStore,
  createRootReducer,
} from '../store';

const configureStore: ConfigureStore = ({
  initialState,
  extraMiddlewares,
  apolloClient,
  router,
}) => {
  const reduxThunkExtraArgument: IThunkActionDependencies = {
    apolloClient,
    router,
  };

  return createStore<IApplicationState, any, any, any>(
    createRootReducer(),
    initialState as PreloadedState<IApplicationState>,
    applyMiddleware(
      ...extraMiddlewares,
      reduxThunk.withExtraArgument(reduxThunkExtraArgument)
    )
  ) as Store<IApplicationState>;
};

// ts-unused-exports:disable-next-line
export default configureStore;
