import { useCallback, useMemo } from 'react';

import Select from 'shared/view/elements/Selects/Select/Select';
import { MonitoringStringArrayFilterOperator } from 'generated/types';
import {
  MonitoringFilter,
  MonitoringStringArrayFilter,
  MonitoringStringFilter,
} from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';
import {
  booleanOperators,
  getLabelByMonitoringFilterOperator,
  MonitoringFilterOperator,
  numberOperators,
  stringOperators,
} from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilterOperator';
import { IOptionType } from 'shared/view/elements/Selects/shared/types';
import matchBy from 'shared/utils/matchBy';

const makeFilterOperatorOption = <Operator extends MonitoringFilterOperator>(
  operator: Operator
): IOptionType<Operator> => ({
  label: getLabelByMonitoringFilterOperator(operator),
  value: operator,
});

const MonitoringFilterOperatorSelect = (props: {
  filter: MonitoringFilter;
  updateFilter(filter: MonitoringFilter): void;
}) => {
  return matchBy(
    props.filter,
    'type'
  )({
    number: (f) => (
      <OperatorSelectBase
        operators={numberOperators}
        value={f.operator}
        onChange={(operator) => props.updateFilter({ ...f, operator })}
      />
    ),
    boolean: (f) => (
      <OperatorSelectBase
        operators={booleanOperators}
        value={f.operator}
        onChange={(operator) => props.updateFilter({ ...f, operator })}
      />
    ),
    string: (f) => (
      <StringFilterOperatorSelect
        filter={f}
        updateFilter={props.updateFilter}
      />
    ),
  });
};

const OperatorSelectBase = <Operator extends MonitoringFilterOperator>(props: {
  value: Operator;
  operators: Operator[];
  onChange: (operator: Operator) => void;
}) => {
  const options = useMemo(
    () => props.operators.map(makeFilterOperatorOption),
    [props.operators]
  );
  return (
    <Select<Operator>
      value={options.find((o) => o.value === props.value)}
      options={options}
      onChange={(o) => props.onChange(o.value)}
      label="Operator"
    />
  );
};

const StringFilterOperatorSelect = ({
  filter,
  updateFilter,
}: {
  filter: MonitoringStringFilter | MonitoringStringArrayFilter;
  updateFilter: (filter: MonitoringFilter) => void;
}) => {
  const onChange = useCallback(
    (operator: (typeof filter)['operator']) => {
      if (
        operator === MonitoringStringArrayFilterOperator.CONTAINS ||
        operator === MonitoringStringArrayFilterOperator.NOT_CONTAINS
      ) {
        if (filter.valueType === 'stringArray') {
          updateFilter({
            ...filter,
            operator,
          });
        } else {
          updateFilter({
            ...filter,
            operator,
            valueType: 'stringArray',
            value: [],
          });
        }
      } else {
        if (filter.valueType === 'string') {
          updateFilter({
            ...filter,
            operator,
          });
        } else {
          updateFilter({
            ...filter,
            operator,
            valueType: 'string',
            value: '',
          });
        }
      }
    },
    [filter, updateFilter]
  );

  return (
    <OperatorSelectBase
      operators={stringOperators}
      value={filter.operator}
      onChange={onChange}
    />
  );
};

export default MonitoringFilterOperatorSelect;
