import { ScaleOrdinal } from 'd3-scale';
import { useMemo } from 'react';

import { useActiveKeys } from 'shared/utils/activeKeys';
import { LegendPosition } from 'shared/view/charts/CustomLegend/CustomLegend';

import { useChartLegendProps } from '../chartLegendProps/useChartLegendProps';
import { DataWithKey } from '../DataWithKey';

export const useChartManager = <
  ChartData extends DataWithKey,
  ColorScaleDomain extends string,
>(props: {
  chartData: ChartData[];
  legendPosition: LegendPosition | undefined;
  colorScale: ScaleOrdinal<ColorScaleDomain, string>;
  keys: string[];
}) => {
  const { activeKeys, toggleKey } = useActiveKeys(props.keys);

  const filteredData = useMemo(
    () => props.chartData.filter((d) => activeKeys.includes(d.key)),
    [activeKeys, props.chartData]
  );

  const legendProps = useChartLegendProps({
    activeKeys,
    toggleKey,
    colorScale: props.colorScale,
    legendPosition: props.legendPosition,
  });

  return {
    data: filteredData,
    legendProps,
  };
};
