import { useCallback, useState, useMemo } from 'react';

import { ISorting } from 'shared/models/Sorting';

type UseSortingProps = {
  sorting: ISorting;
  ascending: boolean;
  key: string;
  onSortingChange: (p: ISorting) => void;
};

export const useSorting = (
  initialSort: ISorting,
  additionalActionOnSortingChange?: (value: ISorting | undefined) => void
): UseSortingProps => {
  const [sorting, setSorting] = useState<ISorting>(initialSort);

  const onSortingChange = useCallback(
    (newSorting: ISorting) => {
      setSorting(newSorting);
      additionalActionOnSortingChange?.(newSorting);
    },
    [additionalActionOnSortingChange]
  );

  const ascending = useMemo(() => sorting.direction === 'asc', [sorting]);
  const key = useMemo(() => sorting.field, [sorting]);

  return { sorting, onSortingChange, ascending, key };
};
