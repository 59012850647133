import { useMemo } from 'react';

import OrganizationPopup, { OrganizationForm } from './OrganizationPopup';
import { OrganizationsV2List } from '../store/graphql-types/useOrganizationsList.generated';
import { useUpdateOrganization } from '../store/useUpdateOrganization';

type Props = {
  organization: OrganizationsV2List['organizationsV2']['organizations'][0];
  isOpen: boolean;
  onClose(): void;
};

const UpdateOrganizationPopup = (props: Props) => {
  const updateOrganizationApi = useUpdateOrganization(props.onClose);

  const initialForm: OrganizationForm = useMemo(
    () => ({
      name: props.organization.name,
      description: props.organization.description,
      adminEmail: '',
      adminEmails: props.organization.admins.map((a) => a.email),
    }),
    [props.organization]
  );

  return (
    <OrganizationPopup
      {...props}
      initialForm={initialForm}
      title="Edit"
      submitButton={{
        children: 'Update',
        isLoading: updateOrganizationApi.communication.isRequesting,
        disabled: !props.organization.allowedActions.update,
        onSubmit: (form) =>
          updateOrganizationApi.run({
            ...form,
            organizationId: props.organization.id,
          }),
      }}
    />
  );
};

export default UpdateOrganizationPopup;
