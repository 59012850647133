import { FieldConfig, Field, FieldProps } from 'formik';

import { OmitStrict } from 'shared/utils/types';
import NumberInput, {
  NumberInputProps,
} from 'shared/view/elements/TextInput/NumberInput';

type Props = FieldConfig &
  OmitStrict<
    NumberInputProps,
    'value' | 'onChange' | 'onBlur' | 'name' | 'meta'
  >;

export default function NumberInputField(props: Props) {
  return (
    <Field {...props}>
      {({ field, meta, form }: FieldProps<number>) => (
        <NumberInput
          {...props}
          value={field.value}
          onChange={(v) => {
            if (Number.isFinite(v)) {
              form.setFieldValue(field.name, v);
            }
          }}
          onBlur={field.onBlur}
          meta={meta}
        />
      )}
    </Field>
  );
}
