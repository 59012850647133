import React, { useEffect } from 'react';
import { init } from 'ramda';

import { getLastButOne } from 'shared/utils/collection';
import isNotNil from 'shared/utils/isNotNill';

import { Bounds } from './types';

export function useZoom<T>(props: {
  onSetReset(f: (() => void) | null): void;
  getDomain(bounds: Bounds): T;
}) {
  const [selectedDomain, setSelectedDomain] = React.useState<T | null>(null);

  const zoomLevelsApi = useZoomLevel<T>();

  const onZoomIn = (bounds: Bounds | null) => {
    if (!bounds) return;
    const newSelectedDomain = props.getDomain(bounds);
    setSelectedDomain(newSelectedDomain);
    zoomLevelsApi.push(newSelectedDomain);
  };

  const onZoomOut = () => {
    zoomLevelsApi.pop(setSelectedDomain);
  };

  const onZoomReset = () => {
    setSelectedDomain(null);
    zoomLevelsApi.reset();
  };

  useEffect(() => {
    if (isNotNil(selectedDomain)) {
      props.onSetReset(onZoomReset);
    } else {
      props.onSetReset(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDomain]);

  return {
    selectedDomain,
    onZoomOut,
    onZoomIn,
    onZoomReset,
  };
}

function useZoomLevel<T>() {
  const [zoomLevelsHistory, setZoomLevelsHistory] = React.useState<T[]>([]);

  return {
    push: (domain: T) => {
      setZoomLevelsHistory(zoomLevelsHistory.concat(domain));
    },
    pop: (onChangeDomain: (domain: T | null) => void) => {
      onChangeDomain(getLastButOne(zoomLevelsHistory) ?? null);
      setZoomLevelsHistory(init(zoomLevelsHistory));
    },
    reset: () => {
      setZoomLevelsHistory([]);
    },
  };
}
