import routes from 'shared/routes';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';

const SearchResults = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'SearchResults' */ 'features/highLevelSearch/view/SearchResults/SearchResults'
    )
);

const HighLevelSearchPage = () => {
  const breadcrumbs = useAppBreadcrumbs(
    useBreadcrumbItem({
      route: routes.highLevelSearch,
      name: 'Search',
    })
  );

  return (
    <AuthorizedLayout pageEntityOrError={undefined} breadcrumbs={breadcrumbs}>
      <div>
        <SearchResults />
      </div>
    </AuthorizedLayout>
  );
};

export default HighLevelSearchPage;
