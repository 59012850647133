import { equals } from 'ramda';

import { GitInfoFromUrl } from './parseGitInfoFromUrl';

export const makeRepoUrl = (gitInfo: GitInfoFromUrl) => {
  if (gitInfo.baseUrl) {
    return `https://${gitInfo.baseUrl}/${gitInfo.repositoryFullName}`;
  }

  return gitInfo.repositoryFullName;
};

export const makeRepoBlobUrl = (
  gitInfo: GitInfoFromUrl,
  { commitHash, execPath }: { commitHash: string; execPath: string }
) => {
  return `${makeRepoUrl(gitInfo)}/blob/${commitHash}/${execPath}`;
};

export const makeCompareCommitsUrl = ({
  repoWithCommitHash1,
  repoWithCommitHash2,
}: {
  repoWithCommitHash1: {
    url: GitInfoFromUrl;
    commitHash: string;
  };
  repoWithCommitHash2: {
    url: GitInfoFromUrl;
    commitHash: string;
  };
}) => {
  if (equals(repoWithCommitHash1.url, repoWithCommitHash2.url)) {
    const shortCommit1 = repoWithCommitHash1.commitHash.slice(0, 6);
    const shortCommit2 = repoWithCommitHash2.commitHash.slice(0, 6);
    return `${makeRepoUrl(
      repoWithCommitHash1.url
    )}/compare/${shortCommit1}..${shortCommit2}`;
  }
};

export const makeRepoShortName = (gitInfo: GitInfoFromUrl) => {
  return gitInfo.repositoryFullName;
};

export const makeRepoUrlWithRepoShortName = (
  components: GitInfoFromUrl
): { url: string; shortName: string } => {
  return {
    url: makeRepoUrl(components),
    shortName: makeRepoShortName(components),
  };
};

export const makeCommitUrl = (
  gitInfo: GitInfoFromUrl,
  commitHash: string
): string => {
  return `${makeRepoUrl(gitInfo)}/commit/${commitHash}`;
};

export const makeTagUrl = (gitInfo: GitInfoFromUrl, tag: string) => {
  return `${makeRepoUrl(gitInfo)}/tree/${tag}`;
};

export const makeBranchUrl = (gitInfo: GitInfoFromUrl, branch: string) => {
  return `${makeRepoUrl(gitInfo)}/tree/${branch}`;
};
