import { useContext } from 'react';

import {
  MonitoringModel,
  MonitoringModelState,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import createContext from 'shared/utils/react/createContext';

interface MonitoringModelContext {
  model: MonitoringModel;
  updateModelState: (state: MonitoringModelState) => void;
}

const [monitoringModelContext, MonitoringModelContextProvider] =
  createContext<MonitoringModelContext>((props) => props);

export const useMonitoringModelContext = () => {
  return useContext(monitoringModelContext);
};

export { MonitoringModelContextProvider };
