import { chartsDataColors } from 'shared/view/charts/shared/colors';

const textColor = '#444444';

// : NonNullable<VisualizationSpec['config']>
export const config = {
  range: {
    category: chartsDataColors,
    diverging: chartsDataColors,
    heatmap: chartsDataColors,
    ordinal: chartsDataColors,
    ramp: chartsDataColors,
    symbol: chartsDataColors,
  },
  padding: 10,
  background: '#f9fafc',
  legend: {
    orient: 'top',
    labelFontSize: 11,
    labelColor: textColor,
  },
  axis: {
    gridColor: '#dedede',
    gridWidth: 0.5,
    labelColor: textColor,
    labelFontSize: 11,
    domainColor: '#bcbcbc',
    tickColor: '#bcbcbc',
    titleFontSize: 13,
    titleColor: textColor,
    titleFontWeight: 'normal',
    titlePadding: 15,
  },
  axisX: {
    labelAngle: -45,
  },
} as const;
