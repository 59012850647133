import { useCallback } from 'react';

import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { ConfirmType } from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';
import { typeSafeConfiguration } from 'shared/view/elements/DataGrid/configuration/helpers/typeSafeConfiguration';
import { SystemAdmins } from 'features/systemAdmin/admins/list/store/graphql-types/useSystemAdminsList.generated';
import { useDeleteSystemAdmin } from 'features/systemAdmin/admins/list/store/useDeleteSystemAdmin';
import CellRendererActions from 'shared/view/elements/DataGrid/columns/CellRendererActions';
import isNotNil from 'shared/utils/isNotNill';
import makeActionsColumn from 'shared/view/elements/DataGrid/columns/makeActionsColumn';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

type Props = {
  admins: SystemAdmins['systemAdmins']['admins'];
};

export default function SystemAdminsList(props: Props) {
  const { admins } = props;

  const { deleteSystemAdmin, deletingSystemAdmin } = useDeleteSystemAdmin();
  const getOnDelete = useCallback(
    (usernameOrEmail: string) => () => deleteSystemAdmin({ usernameOrEmail }),
    [deleteSystemAdmin]
  );

  const columns: (DataGridColumn<(typeof admins)[number]> | null)[] = [
    {
      field: 'Name',
      flex: 1,
      minWidth: 200,
      additionalConfiguration: typeSafeConfiguration(
        ['sort', 'filter'],
        'string',
        (params) => params.row.user.name
      ),
      renderCell: (params) => (
        <CellRendererString value={params.row.user.name} />
      ),
    },
    {
      field: 'Email',
      flex: 1,
      minWidth: 200,
      additionalConfiguration: typeSafeConfiguration(
        ['sort', 'filter'],
        'string',
        (params) => params.row.user.email
      ),
      renderCell: (params) => (
        <CellRendererString value={params.row.user.email} />
      ),
    },
    makeActionsColumn({
      renderCell: (params) => {
        const disabled = !params.row.allowedActions.delete;
        const isLoading = deletingSystemAdmin.isRequesting;
        const onClick = getOnDelete(params.row.user.email);

        return (
          <CellRendererActions
            actions={[
              {
                type: 'icon',
                icon: ICONS.trashCan,
                label: 'Delete',
                dataTest: 'delete',
                onClick,
                isLoading,
                disabled,
                tooltipTitle: disabled
                  ? 'Root admins cannot be deleted'
                  : undefined,
                confirmAction: {
                  type: ConfirmType.delete,
                  description: 'System admin',
                },
              },
            ]}
          />
        );
      },
    }),
  ];

  return (
    <div data-test="system-admins">
      <DataGridWithTypes
        rows={admins}
        columns={columns.filter(isNotNil)}
        heightType="pageHeight"
      />
    </div>
  );
}
