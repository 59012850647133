import Stack from '@mui/material/Stack';
import { sortBy } from 'ramda';

import Accordion from 'shared/view/elements/Accordion/Accordion';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import DeleteIconButton from 'shared/view/elements/DeleteIconButton/DeleteIconButton';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { trackEvent } from 'setup/app/analytics';

import EditWorkspacePopup from './EditWorkspacePopup';
import { OrganizationWorkspacesV2 } from '../store/useOrganizationWorkspaces';
import { useDeleteWorkspace } from '../store/useDeleteWorkspace';
import WorkspacePermissionsTable from './WorkspacePermissionsTable';

export function OrganizationWorkspaces({
  loadedData,
}: {
  loadedData: OrganizationWorkspacesV2;
}) {
  const deleteWorkspaceApi = useDeleteWorkspace();
  return (
    <Stack direction="column">
      {sortBy((w) => !w.builtIn, loadedData.workspaces).map((w) => (
        <Accordion
          key={w.name}
          title={w.builtIn ? `${w.name} (Locked)` : w.name}
          subtitle={w.description}
          summaryRightContent={
            <Stack direction="row" alignItems="center">
              <ButtonWithPopup
                button={(props) => (
                  <IconAwesomeClickable
                    {...props}
                    onClick={() => {
                      trackEvent({
                        type: 'organization.workspaces.edit_clicked',
                      });
                      props.onClick();
                    }}
                    dataTest="edit-workspace"
                    icon={ICONS.pencil}
                  />
                )}
                popup={(props) => (
                  <EditWorkspacePopup
                    {...props}
                    workspace={w}
                    data={loadedData}
                  />
                )}
              />
              <DeleteIconButton
                description="workspace"
                entityName={w.name}
                disabled={
                  !w.allowedActions.delete ||
                  deleteWorkspaceApi.communication.isRequesting
                }
                onDelete={() => {
                  trackEvent({
                    type: 'organization.workspaces.delete_clicked',
                  });
                  deleteWorkspaceApi.run({
                    organizationId: loadedData.id,
                    workspaceId: w.id,
                  });
                }}
              />
            </Stack>
          }
        >
          <WorkspacePermissionsTable permissions={w.permissions} />
        </Accordion>
      ))}
    </Stack>
  );
}
