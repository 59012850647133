import React from 'react';
import cn from 'classnames';

import matchType from 'shared/utils/matchType';
import { Size } from 'shared/utils/charts/chartSizes';
import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';

import CustomLegend, {
  LegendProps,
  LegendPosition,
} from '../CustomLegend/CustomLegend';
import styles from './ChartWithLegendContainer.module.css';

interface IChartWithLegendContainerProps<DomainType extends string> {
  children: (chartSize: Size) => React.ReactElement;
  legendProps: LegendProps<DomainType>;
  widgetSize: Size;
  legendWidth?: number;
}

const DEFAULT_LEGEND_WIDTH = 200;

const getChartSize = ({
  widgetSize,
  legendPosition,
  legendWidth,
}: {
  widgetSize: Size;
  legendPosition: LegendPosition;
  legendWidth: number;
}): Size => {
  switch (legendPosition) {
    case 'top':
    case 'bottom':
      return widgetSize;
    case 'right':
      return {
        width: widgetSize.width - legendWidth,
        height: widgetSize.height,
      };
    default:
      exhaustiveCheck(legendPosition);
  }
};

function ChartWithLegendContainer<DomainType extends string>(
  props: IChartWithLegendContainerProps<DomainType>
) {
  return (
    <div
      className={cn(
        styles.root,
        matchType(
          {
            right: () => '',
            top: () => styles.legendPosition_top,
            bottom: () => styles.legendPosition_bottom,
          },
          props.legendProps.position
        )
      )}
      style={{ maxWidth: props.widgetSize.width }}
    >
      <>
        <div className={styles.chart}>
          {props.children(
            getChartSize({
              widgetSize: props.widgetSize,
              legendPosition: props.legendProps.position,
              legendWidth: props.legendWidth ?? DEFAULT_LEGEND_WIDTH,
            })
          )}
        </div>
        <div
          className={styles.legend}
          style={{ maxHeight: props.widgetSize.height }}
        >
          <CustomLegend {...props.legendProps} />
        </div>
      </>
    </div>
  );
}

export default ChartWithLegendContainer;
