import { FC } from 'react';

import { ComparedExperimentRun } from 'features/experimentRuns/compare/store/utils/comparedExperimentRuns';
import Widgets from 'features/experimentRuns/crossRunDashboard/view/Widgets/Widgets';
import { isNotEmptyArray } from 'shared/utils/collection';
import Placeholder from 'shared/view/elements/Placeholder/Placeholder';

import {
  CrossRunDashboardForComparingProject,
  useCrossRunDashboardForComparing,
} from '../../store/useCrossRunDashboardForComparing';
import styles from './CrossRunDashboardForComparing.module.css';
import Panel from './Panel/Panel';

interface Props {
  project: CrossRunDashboardForComparingProject;
  experimentRuns: Array<ComparedExperimentRun>;
}

const CrossRunDashboardForComparingView: FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const api = useCrossRunDashboardForComparing(props);
  return (
    <div>
      <Panel {...api} />
      <div className={styles.widgets}>
        {isNotEmptyArray(api.currentDashboard.widgets) ? (
          <Widgets
            widgets={api.currentDashboard.widgets}
            dashboardLayout={api.currentDashboard.layout}
            experimentRuns={api.experimentRuns}
            onDelete={api.deleteWidget}
            onEdit={api.editWidget}
            onLayoutChange={api.updateLayout}
          />
        ) : (
          <Placeholder>No data for charts</Placeholder>
        )}
      </div>
    </div>
  );
};

export default CrossRunDashboardForComparingView;
