import { styled } from '@mui/material';
import * as React from 'react';
import ReactGridLayout from 'react-grid-layout';
import { WidthProvider } from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {
  CrossRunDashboardPanel,
  defaultMinSize,
  layoutSettings,
} from 'shared/models/CrossRunDashboard/CrossRunDashboard';

interface ILocalProps {
  draggableHandleClassName: string;
  layout: CrossRunDashboardPanel['layout'];
  children: React.ReactNode[];
  onLayoutChange(layout: CrossRunDashboardPanel['layout']): void;
}

const GridLayoutWithAutoWidth = WidthProvider(ReactGridLayout);

const GridLayout = (props: ILocalProps) => {
  return (
    <Root>
      <GridLayoutWithAutoWidth
        {...props}
        {...layoutSettings}
        onLayoutChange={(layout) =>
          props.onLayoutChange(
            // the library add additional properties so we use only those that are really declared in the type
            layout.map((l) => ({
              ...defaultMinSize,
              h: l.h,
              i: l.i,
              w: l.w,
              x: l.x,
              y: l.y,
            }))
          )
        }
        draggableHandle={`.${props.draggableHandleClassName}`}
        useCSSTransforms={true}
        containerPadding={[0, 0]}
      />
    </Root>
  );
};

const Root = styled('div')(({ theme }) => {
  return {
    '& .react-grid-item.react-grid-placeholder': {
      ...theme.palette.gridLayout.placeholder,
      position: 'absolute',
      boxSizing: 'border-box',
      opacity: 1,
      borderRadius: '4px',
    },
  };
});

export default GridLayout;
