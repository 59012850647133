import { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import Typography, { TypographyProps } from '@mui/material/Typography';

import isNotNil from 'shared/utils/isNotNill';
import Tooltip from 'shared/view/elements/Tooltip/Tooltip';

const DYNAMIC_TYPOGRAPHY_VARIANT = 'body2';

export type DynamicTypographyProps = {
  value: string;
  dataTest?: string;
  maxLines?: number;
  color?: TypographyProps['color'];
  variant?: TypographyProps['variant'];
  sx?: React.CSSProperties;
};

export default function DynamicTypography(props: DynamicTypographyProps) {
  const { hoverDisabled, ref } = useDisableTooltipHoverOnTruncate(props.value);
  const maxLines = props.maxLines ?? 1;

  return (
    <span style={{ display: 'block', width: '100%', overflowX: 'auto' }}>
      <Tooltip
        title={props.value}
        type="withSpan"
        disableHoverListener={hoverDisabled}
      >
        <span data-test={props.dataTest} style={{ display: 'grid' }}>
          <Typography
            ref={ref}
            color={props.color}
            sx={
              maxLines === 1
                ? {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    ...props.sx,
                  }
                : {
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitLineClamp: maxLines,
                    WebkitBoxOrient: 'vertical',
                    ...props.sx,
                  }
            }
            variant={props.variant ?? DYNAMIC_TYPOGRAPHY_VARIANT}
          >
            {props.value}
          </Typography>
        </span>
      </Tooltip>
    </span>
  );
}

export const useDisableTooltipHoverOnTruncate = (value?: string) => {
  const [hoverDisabled, setHoverDisabled] = useState(true);
  const { width, height, ref } = useResizeDetector<HTMLDivElement>({
    refreshMode: 'debounce',
    refreshRate: 100,
  });

  useEffect(() => {
    const { current } = ref;
    if (
      isNotNil(current) &&
      (current.offsetWidth < current.scrollWidth ||
        current.offsetHeight < current.scrollHeight)
    ) {
      setHoverDisabled(false);
    } else {
      setHoverDisabled(true);
    }
    // on value change also should re-check
  }, [ref, height, width, value]);

  return { ref, hoverDisabled };
};
