import { gql } from '@apollo/client';

import { AttributeData } from './graphql-types/Attribute.generated';

export type GraphqlAttributeData = AttributeData;

// todo rename according to PRIMITIVE_KEY_VALUE_FRAGMENT
export const ATTRIBUTE_FRAGMENT = gql`
  fragment AttributeData on KeyValue {
    ... on FloatKeyValue {
      key
      floatValue: value
    }
    ... on StringKeyValue {
      key
      stringValue: value
    }
    ... on BoolKeyValue {
      key
      boolValue: value
    }
    ... on JsonKeyValue {
      key
      content
    }
  }
`;
