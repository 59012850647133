import { Formik, FormikConfig, Form, FormikProps, FormikValues } from 'formik';
import * as React from 'react';

import { OmitStrict } from 'shared/utils/types';

import { defaultFormikSettings } from '../defaultFormikSettings';

type Props<Values> = OmitStrict<FormikConfig<Values>, 'children'> & {
  children: (props: FormikProps<Values>) => React.ReactNode;
};

export default class PresetFormik<
  Values extends FormikValues = FormikValues,
> extends React.Component<Props<Values>> {
  public render() {
    return (
      <Formik
        {...this.props}
        validateOnMount={
          this.props.validateOnMount !== undefined
            ? this.props.validateOnMount
            : defaultFormikSettings.validateOnMount
        }
        validateOnBlur={
          this.props.validateOnBlur !== undefined
            ? this.props.validateOnBlur
            : defaultFormikSettings.validateOnBlur
        }
      >
        {(childrenProps) => <Form>{this.props.children(childrenProps)}</Form>}
      </Formik>
    );
  }
}
