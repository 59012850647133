import { Stack, Typography } from '@mui/material';

import { LoginByExternalServiceButton } from '../SocialLoginButtons';

const Auth0 = () => {
  return (
    <>
      <Typography variant="h4" color="text.primary" mb={1}>
        Hi, we are Verta!
      </Typography>
      <Typography variant="body1" color="text.primary" mb={4}>
        Get started now.
      </Typography>
      <Stack direction="row" spacing={1} display="flex" alignItems="center">
        <LoginByExternalServiceButton
          provider="auth0"
          providerName="Signup"
          signUp
        />
        <LoginByExternalServiceButton
          provider="auth0"
          providerName="Login"
          variant="outlined"
        />
      </Stack>
    </>
  );
};

export default Auth0;
