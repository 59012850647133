import Placeholder from 'shared/view/elements/Placeholder/Placeholder';
import { IShortProject } from 'shared/models/Project';
import { ComparedExperimentRuns } from 'features/experimentRuns/compare/store/utils/comparedExperimentRuns';
import {
  minNumberOfEntitiesForComparing,
  isEnoughComparedEntities,
} from 'shared/models/CompareEntities/new/comparedEntities';
import { ExperimentRunDetails } from 'shared/graphql/ExperimentRunDetails/ExperimentRunDetails';

import styles from './ExperimentRunsCompare.module.css';
import ExperimentRunsCompareTable from './ExperimentRunsCompareTable';
import CrossRunDashboardForComparingView from '../../crossRunDashboardForComparing/view/CrossRunDashboardForComparing/CrossRunDashboardForComparing';
import { CrossRunDashboardForComparingProject } from '../../crossRunDashboardForComparing/store/useCrossRunDashboardForComparing';

interface ILocalProps {
  experimentRuns: ExperimentRunDetails[];
  project: CrossRunDashboardForComparingProject & IShortProject;
}

const ExperimentRunsCompare = ({ experimentRuns, project }: ILocalProps) => {
  return isEnoughComparedEntities(experimentRuns) ? (
    <CompareTableWithObservationsChart
      experimentRuns={experimentRuns}
      project={project}
    />
  ) : (
    <Placeholder>
      You should select at least {minNumberOfEntitiesForComparing} for comparing
    </Placeholder>
  );
};

// ts-unused-exports:disable-next-line
export function CompareTableWithObservationsChart({
  experimentRuns,
  project,
}: {
  project: CrossRunDashboardForComparingProject;
  experimentRuns: ComparedExperimentRuns;
}): JSX.Element | null {
  return (
    <div className={styles.content}>
      <ExperimentRunsCompareTable
        experimentRuns={experimentRuns}
        charts={(comparedExperimentRuns) => (
          <div className={styles.charts}>
            <CrossRunDashboardForComparingView
              experimentRuns={comparedExperimentRuns}
              project={project}
            />
          </div>
        )}
      />
    </div>
  );
}

export default ExperimentRunsCompare;
