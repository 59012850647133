import { Box, Stack, StackProps, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { OmitStrict } from 'shared/utils/types';

type Props = OmitStrict<StackProps, 'children'>;

const pairs = [
  ['collaborative', 'organized'],
  ['transparent', 'structured'],
  ['complex', 'streamlined'],
  ['cooperative', 'impactful'],
  ['reliable', 'agile'],
  ['innovative', 'precise'],
];

const AnimatedBold = styled('strong')(() => ({
  display: 'inline-block',
  animationDuration: '1s',
  animationFillMode: 'both',
  '@keyframes fadeInDown': {
    from: {
      opacity: 0,
      transform: 'translate3d(0, -10%, 0)',
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
  },
  '@keyframes fadeOutDown': {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
      transform: 'translate3d(0, 10%, 0)',
    },
  },
}));

const WordChanger = ({
  words,
  animationIn = 'fadeInDown',
  animationOut = 'fadeOutDown',
  animationOutDuration = 750,
  duration = 4000,
  startDelay = 0,
}: {
  words: string[];
  animationIn?: 'fadeInDown';
  animationOut?: 'fadeOutDown';
  animationOutDuration?: number;
  duration?: number;
  startDelay?: number;
}) => {
  const [addDelay, setAddDelay] = useState(true);
  const [index, setIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState(words[index]);
  const [animation, setAnimation] = useState<string>(animationIn);

  useEffect(() => {
    setCurrentWord(words[index]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  useEffect(() => {
    setTimeout(
      () => {
        setAnimation(animationIn);
        setAddDelay(false);
        setTimeout(() => {
          setAnimation(animationOut);
          setTimeout(() => {
            setIndex(index === words.length - 1 ? 0 : index + 1);
          }, animationOutDuration);
        }, duration);
      },
      addDelay ? startDelay : 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWord]);

  return (
    <AnimatedBold style={{ animationName: animation }}>
      {currentWord}
    </AnimatedBold>
  );
};

export const Title = (props: Props) => {
  return (
    <Stack spacing={0} {...props}>
      <Typography variant="h1" color="primary.dark">
        ML is <WordChanger words={pairs.map((pair) => pair[0])} />
      </Typography>
      <Box display="flex">
        <Typography variant="h1" color="primary.dark">
          and <WordChanger words={pairs.map((pair) => pair[1])} /> with{' '}
          <strong>Verta</strong>
        </Typography>
      </Box>
    </Stack>
  );
};
