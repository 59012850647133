import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { useDatasetsQuery } from 'features/datasets/datasets/store/datasetsQuery/datasetsQuery';
import { isRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { useAppBreadcrumbs } from 'features/layout/view/Layout/AppBreadcrumbs';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import { DatasetCreationPopup } from 'features/datasets/datasetCreation';
import Button from 'shared/view/elements/Button/Button';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

import { useDatasetsPageBreadcrumb } from '../shared/useDatasetsPageBreadcrumbs';

const Datasets = makeLazyLoadComponentWithPreloader(() =>
  import(/* webpackChunkName: 'Datasets' */ 'features/datasets/datasets').then(
    (m) => ({
      default: m.Datasets,
    })
  )
);

const DatasetsPage = () => {
  const workspaceName = useWorkspaceNameFromURL();
  const organizationId = useCurrentOrganizationV2();
  const { data, loadingDatasets, pagination, onCurrentPageChange } =
    useDatasetsQuery({ workspaceName, organizationId });

  return (
    <AuthorizedLayout
      pageEntityOrError={data}
      breadcrumbs={useAppBreadcrumbs(useDatasetsPageBreadcrumb())}
      actions={[
        <ButtonWithPopup
          key={0}
          button={(props) => (
            <Button {...props} dataTest="open-create-popup">
              Create
            </Button>
          )}
          popup={(props) => <DatasetCreationPopup {...props} />}
        />,
      ]}
    >
      <Datasets
        datasets={isRestrictedGraphqlError(data) ? undefined : data?.datasets}
        communication={loadingDatasets}
        onPageChange={onCurrentPageChange}
        pagination={pagination}
      />
    </AuthorizedLayout>
  );
};

export default DatasetsPage;
