import { useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { pipe } from 'ramda';
import Stack from '@mui/material/Stack';

import CrossRunDashboardView from 'features/experimentRuns/crossRunDashboard/view/CrossRunDashboard/CrossRunDashboard';
import routes from 'shared/routes';
import { useExperimentRunsLazyLoad } from 'features/experimentRuns/shared/hooks/useExperimentRunsLazyLoad';
import useFilters from 'features/filters/store/useFilters';
import {
  addFilterDefinitionsFromFilters,
  FilterDefinition,
} from 'shared/models/Filters/FilterDefinition';
import { ExperimentRunDetails } from 'shared/graphql/ExperimentRunDetails/ExperimentRunDetails';
import { getExperimentRunFields } from 'shared/models/ExperimentRunField';
import isNotNil from 'shared/utils/isNotNill';
import Filters from 'features/filters/view/Filters/Filters';
import { useRouteParams } from 'shared/utils/router/useRouteParams';

import { ProjectDetailsLayoutWithDefaultProjectLoading } from '../shared/layouts/ProjectDetailsLayout';

function ChartsPage() {
  const { projectId } = useRouteParams(routes.charts);
  const location = useLocation();
  const queryParams = routes.charts.parseQueryParams(location.search);

  const {
    experimentRuns,
    loadingInitialExperimentRuns,
    loadingRestExperimentRuns,
    lazyLoad,
  } = useExperimentRunsLazyLoad({ projectId });

  const chartsFilters = useChartsFilters({
    projectId,
    experimentRuns,
  });

  useEffect(() => {
    lazyLoad(chartsFilters.filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartsFilters.filters]);

  return (
    <ProjectDetailsLayoutWithDefaultProjectLoading>
      {({ project }) => (
        <Stack direction="column">
          <Filters {...chartsFilters} />
          <CrossRunDashboardView
            dashboardFromURL={queryParams?.dashboard}
            experimentRuns={experimentRuns}
            loadingInitialExperimentRuns={loadingInitialExperimentRuns}
            loadingRestExperimentRuns={loadingRestExperimentRuns}
            project={project}
          />
        </Stack>
      )}
    </ProjectDetailsLayoutWithDefaultProjectLoading>
  );
}

const useChartsFilters = ({
  projectId,
  experimentRuns,
}: {
  projectId: string;
  experimentRuns: ExperimentRunDetails[];
}) => {
  const filtersApi = useFilters({
    id: projectId,
    type: 'project',
  });

  const filterDefinitions = useMemo(
    () =>
      pipe(
        () =>
          getExperimentRunFields({
            hyperparemeters: experimentRuns.flatMap((e) => e.hyperparameters),
            metrics: experimentRuns.flatMap((e) => e.metrics),
          }),
        (fields) =>
          addFilterDefinitionsFromFilters(
            filtersApi.filters,
            fields
              .map((field): FilterDefinition | undefined =>
                field.valueType === 'number' || field.valueType === 'string'
                  ? {
                      label: field.caption,
                      name: field.fieldName,
                      type: field.valueType,
                    }
                  : undefined
              )
              .filter(isNotNil)
          )
      )(),
    [experimentRuns, filtersApi.filters]
  );

  return { ...filtersApi, filterDefinitions };
};

export default ChartsPage;
