/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';
import isNotNil from 'shared/utils/isNotNill';
import { ExtractByTypename } from 'shared/utils/types';

import * as Types from './graphql-types/useWorkspaceModelVersions.generated';

export type RegisteredModelVersions = ExtractByTypename<
  Types.WorkspaceModelVersionsCases['workspace'],
  'Workspace'
>['registeredModels']['models'][0]['versions'];

export const useWorkspaceModelVersions = () => {
  const [load, queryResult] = useCustomLazyQuery<
    Types.WorkspaceModelVersionsCases,
    Types.WorkspaceModelVersionsCasesVariables
  >(QUERY);
  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert,
    queryResult,
  });

  return {
    data,
    communication,
    load,
  };
};

const memoizedConvert = (res: Types.WorkspaceModelVersionsCases) =>
  mapDataOrError(res.workspace, (workspaceData) => ({
    models: workspaceData.registeredModels.models.filter(isNotNil),
  }));

const QUERY = gql`
  query WorkspaceModelVersionsCases(
    $organizationId: ID
    $workspaceName: String!
    $pagination: PaginationQuery!
    $stringPredicates: [StringPredicate!]
    $sorting: SortingQuery!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Workspace {
        id
        registeredModels(
          query: {
            pagination: $pagination
            stringPredicates: $stringPredicates
            sorting: $sorting
          }
        ) {
          models {
            id
            versions(query: { pagination: { limit: 0 } }) {
              versions {
                id
                stage
                version
                deployments {
                  endpoint {
                    id
                  }
                }
              }
              pagination {
                totalRecords
              }
            }
          }
        }
      }
    }
  }
`;
