import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import checklistCreateStageChange from '../images/checklist-create-stage-change.png';

export const CreateChecklistStageChange = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Create a new checklist
    </Typography>
    <Typography variant="body2" mb={3}>
      A checklist can be employed to ensure that a model is prepared to
      transition into a different lifecycle stage. For instance, if you create a
      checklist that pertains to "move to production," all items marked as
      required must be fulfilled before the version can be promoted to the
      production stage.
    </Typography>
    <img
      src={checklistCreateStageChange}
      width="70%"
      height="auto"
      alt="checklist create stage change"
    />
  </WizardStepContent>
);
