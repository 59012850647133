import { combineReducers } from 'redux';

import { IFlagsState } from '../types';
import communications from './communications';
import data from './data';

export default combineReducers<IFlagsState>({
  data,
  communications,
});
