import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useRegisteredModelVersionsForComparing } from 'features/catalog/registeredModel/compareVersions/store/registeredModelVersionsForComparing/useRegisteredModelVersionsForComparing';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import {
  isEnoughComparedEntities,
  minNumberOfEntitiesForComparing,
} from 'shared/models/CompareEntities/new/comparedEntities';
import { isNotRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import RegisteredModelVersionsCompareTable from 'features/catalog/registeredModel/compareVersions/view/RegisteredModelVersionsCompareTable/RegisteredModelVersionsCompareTable';
import Placeholder from 'shared/view/elements/Placeholder/Placeholder';

const ModelVersionsComparePage = () => {
  const { ids } = useRouteParams(routes.registeredModelVersionsCompare);

  const { loadingRegisteredModelVersions, registeredModelVersions } =
    useRegisteredModelVersionsForComparing(ids.split(','));

  return (
    <DefaultMatchRemoteDataOrError
      communication={loadingRegisteredModelVersions}
      data={registeredModelVersions?.filter(isNotRestrictedGraphqlError)}
      context="loading registered model versions"
    >
      {(loadedRegisteredModelVersions) => {
        return isEnoughComparedEntities(loadedRegisteredModelVersions) ? (
          <RegisteredModelVersionsCompareTable
            registeredModelVersions={loadedRegisteredModelVersions}
          />
        ) : (
          <Placeholder>
            You should select at least {minNumberOfEntitiesForComparing} for
            comparing
          </Placeholder>
        );
      }}
    </DefaultMatchRemoteDataOrError>
  );
};

export default ModelVersionsComparePage;
