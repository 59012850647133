/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import { ENDPOINT_FRAGMENT } from 'shared/graphql/Deployment/Endpoint/Endpoint';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import {
  BuildScanSafetyStatus,
  BuildStatus,
  EndpointEnvironmentComponentStatus,
} from 'generated/types';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { getEnvironment } from 'shared/models/Deployment/canary/Endpoint';
import { ExtractByTypename } from 'shared/utils/types';

import * as GraphqlTypes from './graphql-types/buildError.generated';
import { IErrorInfo, IErrorModel } from '../types';

type Workspace = ExtractByTypename<
  GraphqlTypes.EndpointErrors['workspace'],
  'Workspace'
>;
type Endpoint = ExtractByTypename<Workspace['endpoint'], 'Endpoint'>;
type Build = ExtractByTypename<
  Endpoint['environments'][0]['components'][0]['build'],
  'Build'
>;

const ENDPOINT_ERRORS = gql`
  query EndpointErrors($workspaceName: String!, $organizationId: ID, $id: ID!) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoint(id: $id) {
          ... on Error {
            ...ErrorData
          }
          ... on Endpoint {
            id
            ...EndpointData
            environments {
              id
              components {
                build {
                  ... on Error {
                    ...ErrorData
                  }
                  ... on Build {
                    id
                    message
                    status
                    scanStatus {
                      ... on BuildScanStatusUnscanned {
                        __typename
                      }
                      ... on BuildScanStatusError {
                        systemErrorLog
                        __typename
                      }
                      ... on BuildScanStatusScanning {
                        __typename
                      }
                      ... on BuildScanStatusScanned {
                        safetyStatus
                      }
                    }
                  }
                }
                buildID
                status
                message
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ENDPOINT_FRAGMENT}
`;

const NO_LOGS_RETRIEVED_MESSAGE = 'No logs retrieved';

export const useBuildError = ({
  errorInfo,
}: {
  errorInfo: IErrorInfo | null;
}) => {
  const [loadQuery, res] = useCustomLazyQuery<
    GraphqlTypes.EndpointErrors,
    GraphqlTypes.EndpointErrorsVariables
  >(ENDPOINT_ERRORS);

  const { data, communication: loadingBuildError } =
    resultToCommunicationWithData((response) => {
      return mapDataOrError(
        response.workspace,
        (x) => x.endpoint,
        (endpoint) => {
          const component = getEnvironment(endpoint)?.components.find(
            (currComponent) => currComponent.buildID === errorInfo?.buildId
          );

          if (component?.build.__typename === 'Build') {
            return [
              ...getBuildErrors({
                buildStatus: component.build.status,
                buildMessage: component.build.message,
              }),
              ...getDeployErrors({
                componentStatus: component.status,
                componentMessage: component.message,
              }),
              ...getScanErrors({
                buildScanStatus: component.build.scanStatus,
                buildMessage: component.build.message,
              }),
            ];
          }
        }
      );
    }, res);

  const loadBuildError = (variables: GraphqlTypes.EndpointErrorsVariables) =>
    loadQuery({
      variables,
    });

  return {
    data,
    loadingBuildError,
    loadBuildError,
  };
};

const getBuildErrors = ({
  buildStatus,
  buildMessage,
}: {
  buildStatus: BuildStatus | undefined;
  buildMessage: string | undefined;
}): IErrorModel[] => {
  if (buildStatus === BuildStatus.ERROR) {
    return [
      {
        context: 'build error',
        message: buildMessage || NO_LOGS_RETRIEVED_MESSAGE,
      },
    ];
  }
  return [];
};

const getDeployErrors = ({
  componentStatus,
  componentMessage,
}: {
  componentStatus: EndpointEnvironmentComponentStatus | undefined;
  componentMessage: string | undefined;
}): IErrorModel[] => {
  if (
    componentStatus === EndpointEnvironmentComponentStatus.ERROR ||
    componentStatus === EndpointEnvironmentComponentStatus.INTERNAL_ERROR
  ) {
    return [
      {
        context: 'deploy error',
        message: componentMessage || NO_LOGS_RETRIEVED_MESSAGE,
      },
    ];
  }
  return [];
};

const getScanErrors = ({
  buildScanStatus,
  buildMessage,
}: {
  buildScanStatus: Build['scanStatus'] | undefined;
  buildMessage: string | undefined;
}): IErrorModel[] => {
  const errors: IErrorModel[] = [];
  if (buildScanStatus?.__typename === 'BuildScanStatusError') {
    errors.push({
      context: 'scan error',
      message: buildMessage || NO_LOGS_RETRIEVED_MESSAGE,
    });
  }
  if (
    buildScanStatus?.__typename === 'BuildScanStatusScanned' &&
    buildScanStatus.safetyStatus === BuildScanSafetyStatus.UNSAFE
  ) {
    errors.push({
      context: 'scan failed',
      message: buildMessage || NO_LOGS_RETRIEVED_MESSAGE,
    });
  }

  return errors;
};
