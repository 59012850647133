import FormControlLabel from '@mui/material/FormControlLabel';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { KafkaConfigStatus } from 'generated/types';
import matchType from 'shared/utils/matchType';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import Popup from 'shared/view/elements/Popup/Popup';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

interface Props {
  status: KafkaConfigStatus;
  message: string | undefined | null;
}

const size: SizeProp = 'lg';

export default function StreamingKafkaBlock(props: Props): JSX.Element {
  const { isPopupOpen, openPopup, closePopup } = usePopupManager();
  const popupMessage = props.message ?? 'Unknown error';

  return (
    <>
      {matchType(
        {
          [KafkaConfigStatus.ACTIVE]: () => (
            <FormControlLabel
              sx={{ cursor: 'default' }}
              control={
                <IconAwesomeInfo
                  icon={ICONS.circleCheck}
                  infoType={'success'}
                  size={size}
                />
              }
              label={'Active'}
            />
          ),
          [KafkaConfigStatus.ERROR]: () => (
            <FormControlLabel
              sx={{ cursor: 'default', color: 'error.main' }}
              control={
                <IconAwesomeClickable
                  icon={ICONS.circleExclamation}
                  onClick={openPopup}
                  size={size}
                />
              }
              label={'Error'}
            />
          ),
          [KafkaConfigStatus.INACTIVE]: () => (
            <FormControlLabel
              sx={{ cursor: 'default' }}
              control={<IconAwesomeInfo icon={ICONS.circleMinus} size={size} />}
              label={'Not connected'}
            />
          ),
          [KafkaConfigStatus.UPDATING]: () => (
            <FormControlLabel
              sx={{ cursor: 'default' }}
              control={
                <IconAwesomeInfo icon={ICONS.spinner} size={size} spin={true} />
              }
              label={'Loading'}
            />
          ),
        },
        // todo temp solution, wait for backend fix;
        props.status.toUpperCase()
      )}
      <Popup title="Kafka error" isOpen={isPopupOpen} onClose={closePopup}>
        <div>{popupMessage}</div>
      </Popup>
    </>
  );
}
