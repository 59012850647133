import { useState } from 'react';

import Popup from 'shared/view/elements/Popup/Popup';
import { trackEvent } from 'setup/app/analytics';
import { Wizard, WizardStepProps } from 'shared/view/elements/Wizard';
import { RegisteringModelContext } from 'features/homepage/hooks/useRegisteringModel';
import { useOnboardingContext } from 'features/homepage/hooks/useOnboarding';

import { CardProps } from './CardsModel';
import * as slides from './slides';

const steps: WizardStepProps[] = [
  {
    stepLabel: { icon: '0', text: 'Before you start' },
    slides: [slides.BeforeStart],
  },
  {
    stepLabel: { icon: '1', text: 'Select a model' },
    slides: [slides.SelectModel],
  },
  {
    stepLabel: { icon: '2', text: 'Install the Verta Python client' },
    slides: [slides.VertaPythonClient],
  },
  {
    stepLabel: { icon: '3', text: 'Obtain your verta credential' },
    slides: [slides.VertaCredentials],
  },
  {
    stepLabel: { icon: '4', text: 'Run the notebook' },
    slides: [slides.RunNotebook],
  },
];

export const RegisterModelPopup = (props: {
  isPopupOpen: boolean;
  closePopup: () => void;
  onCompleted: () => void;
}) => {
  const { isPopupOpen, closePopup, onCompleted } = props;

  const { updateOnboardingSteps, stepsValues } = useOnboardingContext();
  const [cardSelected, setCardSelected] = useState<CardProps>();
  const [hostname, setHostname] = useState<string>(window.location.host);

  const handleOnCompleted = async () => {
    await updateOnboardingSteps({ ...stepsValues, step2: true });
    trackEvent({ type: 'homepage.registration_tutorial.completed' });
    closePopup();
    onCompleted();
  };

  return (
    <RegisteringModelContext.Provider
      value={{
        cardSelected,
        selectCard: setCardSelected,
        hostname,
        saveHostname: setHostname,
      }}
    >
      <Popup
        title="Registering a model"
        isOpen={isPopupOpen}
        onClose={() => {
          trackEvent({ type: 'homepage.registration_tutorial.abandoned' });
          closePopup();
        }}
        maxWidth="lg"
        fullWidth
        dialogContentProps={{ height: '1050px' }}
      >
        <Wizard steps={steps} onCompleted={handleOnCompleted} />
      </Popup>
    </RegisteringModelContext.Provider>
  );
};
