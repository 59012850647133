import cn from 'classnames';

import { IEntitiesAttributesDiff } from 'shared/models/CompareEntities/new/attributesComparing';
import matchBy from 'shared/utils/matchBy';

import styles from './AttributesDiff.module.css';
import {
  makeExpansionPropertyDescription,
  makeRowDiffDescription,
} from '../../CompareTable/PropertyDiffDescriptions/PropertyDiffDescription';

export function makeAttributesDiff(diff: IEntitiesAttributesDiff) {
  return makeExpansionPropertyDescription({
    name: 'Attributes',
    children: diff.map((attributeDiffByKey) =>
      makeRowDiffDescription({
        name: attributeDiffByKey.key,
        isDifferent: attributeDiffByKey.isDifferent,
        data: attributeDiffByKey.diffs,
        getValue: (x) => x.attribute?.value,
        render: (attributeDiff) =>
          matchBy(
            attributeDiff,
            'type'
          )<JSX.Element | string | undefined>({
            primitive: ({ attribute }) => String(attribute.value),
            list: ({ values }) => (
              <div className={styles.listDiff}>
                {values.map(({ value, isDifferent }, i) => (
                  <div
                    key={i}
                    className={cn(styles.listDiff__item, {
                      [styles.listDiff__differentItem]: isDifferent,
                    })}
                  >
                    {String(value)}
                  </div>
                ))}
              </div>
            ),
            object: ({ attribute }) => JSON.stringify(attribute, null, 2),
            missed: () => undefined,
          }),
      })
    ),
  });
}
