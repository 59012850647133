import { Box, Stack, Typography } from '@mui/material';

import {
  passwordRuleValidator,
  PASSWORD_RULES,
  PasswordRuleType,
} from 'shared/utils/validators';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

const DEFAULT_PASSWORD_RULES_TEXTS: Record<PasswordRuleType['type'], string> = {
  length: `Characters length is between ${PASSWORD_RULES.length.meta.min} and ${PASSWORD_RULES.length.meta.max} characters`,
  shouldContainAtLeast1Digit: `One digit`,
  shouldContainAtLeast1LowerCaseChar: `One lowercase character`,
  shouldContainAtLeast1SpecialCharacter: `One special character from ${PASSWORD_RULES.shouldContainAtLeast1SpecialCharacter.meta.join(
    ''
  )}`,
  shouldContainAtLeast1UpperCaseChar: `One uppercase character`,
};

type Props = {
  password: string;
  passwordRulesTexts?: Record<PasswordRuleType['type'], string>;
};

export const ValidPasswordHelper = ({
  password,
  passwordRulesTexts = DEFAULT_PASSWORD_RULES_TEXTS,
}: Props) => (
  <Stack spacing={0}>
    {Object.values(PASSWORD_RULES).map((rule) => {
      const isValid = passwordRuleValidator[rule.type](password) === undefined;

      return (
        <Stack key={rule.type} direction="row" spacing={0.5}>
          <Box minWidth="16px" display="flex" alignItems="center">
            <IconAwesomeInfo
              icon={isValid ? ICONS.check : ICONS.xMark}
              infoType={isValid ? 'success' : 'error'}
            />
          </Box>
          <Typography
            variant="caption"
            color={isValid ? 'text.primary' : 'text.secondary'}
          >
            {passwordRulesTexts[rule.type]}
          </Typography>
        </Stack>
      );
    })}
  </Stack>
);
