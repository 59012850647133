import { useState } from 'react';

import * as CrossRunDashboard from 'shared/models/CrossRunDashboard/CrossRunDashboard';
import generateId from 'shared/utils/generateId';

import { IUseCrossRunDashboardProps } from './IUseCrossRunDashboardProps';

export const usePanelActions = (props: IUseCrossRunDashboardProps) => {
  const addPanel = (name: string) =>
    props.changeSelectedCrossRunDashboard(
      CrossRunDashboard.addPanel(props.selectedCrossRunDashboard, {
        name,
        id: generateId(),
      })
    );

  const updatePanels = (panels: CrossRunDashboard.CrossRunDashboardPanel[]) => {
    props.changeSelectedCrossRunDashboard({
      ...props.selectedCrossRunDashboard,
      panels,
    });
  };

  const [panelsExpansions, setPanelsExpansions] = useState<
    Record<string, boolean>
  >({});
  const setPanelExpansion = (panelId: string, isOpen: boolean) => {
    setPanelsExpansions({
      ...panelsExpansions,
      [panelId]: isOpen,
    });
  };
  const isPanelOpened = (panelId: string) => {
    return !(panelId in panelsExpansions) || panelsExpansions[panelId];
  };

  const deletePanel = (panelId: string) =>
    props.changeSelectedCrossRunDashboard(
      CrossRunDashboard.deletePanel(props.selectedCrossRunDashboard, panelId)
    );

  const renamePanel = (panelId: string, name: string) => {
    props.changeSelectedCrossRunDashboard(
      CrossRunDashboard.updatePanel(
        props.selectedCrossRunDashboard,
        panelId,
        (p) => ({ ...p, name })
      )
    );
  };

  const updatePanelLayout = (
    panelId: string,
    layout: CrossRunDashboard.CrossRunDashboardPanel['layout']
  ) =>
    props.changeSelectedCrossRunDashboard(
      CrossRunDashboard.updatePanelLayout(
        props.selectedCrossRunDashboard,
        panelId,
        layout
      )
    );

  return {
    addPanel,
    updatePanels,
    setPanelExpansion,
    isPanelOpened,
    deletePanel,
    renamePanel,
    updatePanelLayout,
  };
};
