import { useContext, useEffect, useState } from 'react';

import createContext from 'shared/utils/react/createContext';
import { StorageManager } from 'shared/utils/storage';

export type OnboardingProps = {
  step1: boolean;
  step2: boolean;
  step3: boolean;
  step4: boolean;
};

const mockOnboardingValues = {
  step1: false,
  step2: false,
  step3: false,
  step4: false,
};

export enum AdvancedFeaturesContent {
  DISCOVER_FEATURES = 'Discover our most valuable features',
  GOVERNANCE_CHECKLISTS = 'Governance checklists',
  AI_ASSISTED_DOCS = 'AI assisted model documentation',
  CUSTOM_ATTRIBUTES = 'Custom attributes',
  VULNERABILITY_SCANNING = 'Vulnerability scanning',
}

const storageSteps = new StorageManager<OnboardingProps>(
  'onboardingSteps',
  localStorage
);

const storageGettingStarted = new StorageManager<boolean>(
  'isHiddenGettingStarted',
  localStorage
);

interface OnboardingContext {
  stepsValues: OnboardingProps;
  updateOnboardingSteps: (state: OnboardingProps) => void;
  isHiddenGettingStarted: boolean;
  hideGettingStared: (state: boolean) => void;
  isShowingReminder: boolean;
  setIsShowingReminder: (state: boolean) => void;
  selectedAdvancedFeaturesContent: AdvancedFeaturesContent;
  setSelectedAdvancedFeaturesContent: (state: AdvancedFeaturesContent) => void;
}

const [onboardingContext, OnboardingContextProvider] =
  createContext<OnboardingContext>((props) => props);

export const useOnboardingContext = () => useContext(onboardingContext);

export { OnboardingContextProvider };

export const useOnboardingSteps = () => {
  const [onboardingStep, setOnboardingStep] = useState<OnboardingProps>(() => {
    const fromStorage = storageSteps.read();
    if (fromStorage) return fromStorage;
    return mockOnboardingValues;
  });

  const [isHiddenGettingStarted, setIsHiddenGettingStarted] = useState<boolean>(
    () => {
      const fromStorage = storageGettingStarted.read();
      if (fromStorage) return fromStorage;
      return false;
    }
  );

  const [isShowingReminder, setIsShowingReminder] = useState<boolean>(false);

  const [selectedAdvancedFeaturesContent, setSelectedAdvancedFeaturesContent] =
    useState<AdvancedFeaturesContent>(
      AdvancedFeaturesContent.DISCOVER_FEATURES
    );

  useEffect(() => {
    storageSteps.write(onboardingStep);
  }, [onboardingStep]);

  useEffect(() => {
    const fromStorage = storageGettingStarted.read();
    if (fromStorage !== isHiddenGettingStarted) {
      storageGettingStarted.write(isHiddenGettingStarted);
      if (isHiddenGettingStarted) {
        setIsShowingReminder(true);
      }
    }
  }, [isHiddenGettingStarted]);

  return {
    onboardingStep,
    setOnboardingStep,
    isHiddenGettingStarted,
    setIsHiddenGettingStarted,
    isShowingReminder,
    setIsShowingReminder,
    selectedAdvancedFeaturesContent,
    setSelectedAdvancedFeaturesContent,
  };
};
