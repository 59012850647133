import React, { useCallback, useEffect, useState } from 'react';

import {
  Filter,
  NumericFilter,
  StringFilter,
} from 'shared/models/Filters/Filter';
import matchBy from 'shared/utils/matchBy';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import NumberInput from 'shared/view/elements/TextInput/NumberInput';

export const ValueInput = (props: {
  filter: Filter;
  updateFilter: (filter: Filter) => void;
}) => {
  return matchBy(
    props.filter,
    'type'
  )({
    string: (stringFilter) => (
      <StringValueInput
        value={stringFilter.value}
        onSave={(value) => props.updateFilter({ ...stringFilter, value })}
      />
    ),
    number: (numericFilter) => (
      <NumericValueInput
        value={numericFilter.value}
        onSave={(value) => props.updateFilter({ ...numericFilter, value })}
      />
    ),
  });
};

const NumericValueInput = makeValueInput<NumericFilter>((props) => (
  <NumberInput
    {...props}
    withoutError={true}
    name="filter-item-value"
    label="Value"
  />
));

const StringValueInput = makeValueInput<StringFilter>((props) => (
  <TextInput
    {...props}
    withoutError={true}
    dataTest="filter-item-value"
    label={'Value'}
  />
));

function makeValueInput<F extends Filter>(
  renderInput: (props: {
    value: F['value'];
    onChange(value: F['value']): void;
    onKeyUp(event: React.KeyboardEvent<HTMLInputElement>): void;
    onBlur(): void;
  }) => JSX.Element
) {
  return function ValueInputComponent(props: {
    value: F['value'];
    onSave(value: F['value']): void;
  }) {
    const [newValue, changeNewValue] = useState<F['value']>(props.value);

    useEffect(() => {
      changeNewValue(props.value);
    }, [props.value]);

    const onSubmit = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          props.onSave(newValue);
        }
      },
      [props, newValue]
    );

    return renderInput({
      value: newValue,
      onBlur: () => props.onSave(newValue),
      onChange: changeNewValue,
      onKeyUp: onSubmit,
    });
  };
}
