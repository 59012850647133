import { useCatalog } from 'features/catalog/registeredModel/catalogList/store/catalog/catalog';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import DefaultMatchRemoteData from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';

const getField = makeGetFieldName<any>();

const ModelDestinationField = ({
  data,
}: Pick<ReturnType<typeof useCatalog>, 'data'>) => {
  const modelOptions = isNotNullableRestrictedGraphqlError(data)
    ? data.models.map((model) => ({
        label: model.name,
        value: model.id,
      }))
    : [];
  return (
    <SelectField
      label="Select existing model"
      name={getField({ model: null })}
      options={modelOptions}
      required
      withoutError
    />
  );
};

export const ExistingModelDestination = () => {
  const workspaceName: string = useWorkspaceNameFromURL();
  const { communication, data } = useCatalog({ workspaceName });
  return (
    <DefaultMatchRemoteData
      communication={communication}
      data={data}
      context={'getting destinations'}
    >
      {(models) =>
        isNotNullableRestrictedGraphqlError(models) ? (
          <ModelDestinationField data={data} />
        ) : (
          <></>
        )
      }
    </DefaultMatchRemoteData>
  );
};
