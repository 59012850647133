import { gql } from '@apollo/client';

import {
  isNotNullableRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/createRegisteredModelVersion.generated';

const CREATE_REGISTERED_MODEL_VERSION = gql`
  mutation CreateRegisteredModelVersion(
    $name: String!
    $description: String!
    $experimentRunId: ID!
    $registeredModelId: ID!
    $labels: [String!]!
    $lockLevel: RegisteredModelVersionLockLevel!
  ) {
    registeredModel(id: $registeredModelId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        createVersion(
          description: $description
          experimentRunId: $experimentRunId
          name: $name
          lockLevel: $lockLevel
        ) {
          id
          addLabels(labels: $labels) {
            id
            labels
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useCreateRegisteredModelVersion = ({
  onSuccess,
}: {
  onSuccess: (res: { versionId: string; registeredModelId: string }) => void;
}) => {
  const [createVersion, creatingVersion] = useCustomMutation<
    Types.CreateRegisteredModelVersion,
    Types.CreateRegisteredModelVersionVariables
  >(
    CREATE_REGISTERED_MODEL_VERSION,
    { context: 'creating registered model version' },
    {
      onCompleted: (_res) =>
        isNotNullableRestrictedGraphqlError(_res.registeredModel) &&
        onSuccess({
          versionId: _res.registeredModel.createVersion.id,
          registeredModelId: _res.registeredModel.id,
        }),
    }
  );

  return {
    creatingVersion,
    createVersion,
  };
};
