import matchType from 'shared/utils/matchType';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { MonitoringMetricType } from 'generated/types';

export const getIconByMonitoringMetricType = (
  metricType: MonitoringMetricType
) => {
  return matchType(
    {
      ACCURACY: () => ICONS.bullseyeArrow,
      PRECISION: () => ICONS.compassDrafting,
      RECALL: () => ICONS.radar,
      F1: () => ICONS.diagramVenn,

      // todo: setup icons for regression metrics
      FN: () => ICONS.bullseyeArrow,
      FP: () => ICONS.bullseyeArrow,
      FPR: () => ICONS.bullseyeArrow,
      MAE: () => ICONS.bullseyeArrow,
      MSE: () => ICONS.bullseyeArrow,
      PREDICTION_COUNT: () => ICONS.bullseyeArrow,
      R2: () => ICONS.bullseyeArrow,
      RMSE: () => ICONS.bullseyeArrow,
      TN: () => ICONS.bullseyeArrow,
      TP: () => ICONS.bullseyeArrow,
      TPR: () => ICONS.bullseyeArrow,
    },
    metricType
  );
};
