import routes from 'shared/routes';
import { OrganizationCustomAttributesScreen } from 'features/organizations/custom-attributes/view/OrganizationCustomAttribute';
import { useRouteParams } from 'shared/utils/router/useRouteParams';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { useOrganizationCustomAttributes } from 'features/organizations/custom-attributes/hooks';

import OrganizationLayout from '../shared/OrganizationLayout';

const OrganizationCustomAttributesPage = () => {
  const { name } = useRouteParams(routes.organization);
  const organizationCustomAttributesApi = useOrganizationCustomAttributes({
    organizationName: name,
  });

  return (
    <OrganizationLayout pageEntityOrError={undefined}>
      <DefaultMatchRemoteDataOrError
        {...organizationCustomAttributesApi}
        context="loading custom-attributes"
      >
        {(loadedOrganization) => (
          <OrganizationCustomAttributesScreen
            {...organizationCustomAttributesApi}
            organization={loadedOrganization.organization}
            workspaces={loadedOrganization.workspaces}
          />
        )}
      </DefaultMatchRemoteDataOrError>
    </OrganizationLayout>
  );
};

export default OrganizationCustomAttributesPage;
