import { Grid, Stack, Typography } from '@mui/material';
import { noop } from 'lodash';
import { useState } from 'react';

import { makeGetFieldName } from 'shared/utils/getFieldName';
import Popup from 'shared/view/elements/Popup/Popup';
import ToggleButton from 'shared/view/elements/ToggleButton/ToggleButton';
import ToggleButtonGroup from 'shared/view/elements/ToggleButtonGroup/ToggleButtonGroup';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';

import { ExistingDatasetConfiguration } from './ExistingDatasetConfiguration';
import { UploadDatasetConfiguration } from './UploadDatasetConfiguration';
import { ExistingModelDestination } from './ExistingModelDestination';

type Props = {
  onClose: () => void;
};

enum DATASET_TYPE {
  USE_EXIST,
  UPLOAD_NEW,
}

enum DESTINATION_TYPE {
  USE_EXIST,
  CREATE_NEW,
}

type FineTuningVersionInput = {
  trainDataset: string;
  evaluationDataset: string;
  testDataset: string;
  lora: {
    alpha: number;
    dropout: number;
    r: number;
  };
  model: string;
  versionName: string;
};

const getField = makeGetFieldName<any>();

const initialValues: FineTuningVersionInput = {
  trainDataset: '',
  evaluationDataset: '',
  testDataset: '',
  lora: {
    alpha: 32,
    dropout: 0,
    r: 8,
  },
  model: '',
  versionName: '',
};

export const FineTuningModal = ({ onClose }: Props) => {
  const [datasetType, setDatasetType] = useState<DATASET_TYPE>(
    DATASET_TYPE.USE_EXIST
  );
  const [destinationType, setDestinationType] = useState<DESTINATION_TYPE>(
    DESTINATION_TYPE.USE_EXIST
  );

  const handleDatasetType = (
    _: React.MouseEvent<HTMLElement>,
    newDatasetType: DATASET_TYPE
  ) => {
    setDatasetType(newDatasetType);
  };

  const handleDestinationType = (
    _: React.MouseEvent<HTMLElement>,
    newDatasetType: DESTINATION_TYPE
  ) => {
    setDestinationType(newDatasetType);
  };
  return (
    <PresetFormik<FineTuningVersionInput>
      initialValues={initialValues}
      onSubmit={noop}
      validateOnChange={false}
      validateOnMount={false}
      validateOnBlur={false}
    >
      {({ submitForm }) => {
        return (
          <Popup
            isOpen={true}
            title="Fine-tune version"
            fullWidth={true}
            maxWidth="xs"
            buttons={{
              secondaryButtonProps: {
                children: 'Cancel',
                isLoading: false,
                onClick: onClose,
              },
              mainButtonProps: {
                isLoading: false,
                type: 'button',
                onClick: submitForm,
                children: 'Trigger fine-tuning',
              },
            }}
            onClose={onClose}
          >
            <Stack spacing={3}>
              <Typography variant="subtitle2" color="text.secondary">
                Dataset configuration
              </Typography>
              <ToggleButtonGroup
                color="primary"
                exclusive
                value={datasetType}
                onChange={handleDatasetType}
              >
                <ToggleButton size="medium" value={DATASET_TYPE.USE_EXIST}>
                  <Typography variant="overline">
                    USE EXISTING DATASET
                  </Typography>
                </ToggleButton>
                <ToggleButton size="medium" value={DATASET_TYPE.UPLOAD_NEW}>
                  <Typography variant="overline">UPLOAD NEW DATASET</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
              {datasetType === DATASET_TYPE.USE_EXIST ? (
                <ExistingDatasetConfiguration />
              ) : (
                <UploadDatasetConfiguration />
              )}
              <Typography variant="subtitle2" color="text.secondary">
                Lora parameters
              </Typography>
              <Grid container gap={2} wrap="nowrap">
                <Grid item xs={4}>
                  <TextInputField
                    label="Alpha"
                    name={getField({ lora: { alpha: null } })}
                    withoutError
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInputField
                    label="Dropout"
                    name={getField({ lora: { dropout: null } })}
                    withoutError
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInputField
                    label="R"
                    name={getField({ lora: { r: null } })}
                    withoutError
                  />
                </Grid>
              </Grid>
              <Typography variant="subtitle2" color="text.secondary">
                Destination
              </Typography>
              <ToggleButtonGroup
                color="primary"
                exclusive
                value={destinationType}
                onChange={handleDestinationType}
              >
                <ToggleButton size="medium" value={DESTINATION_TYPE.USE_EXIST}>
                  <Typography variant="overline">
                    USE EXISTING MODEL(RM)
                  </Typography>
                </ToggleButton>
                <ToggleButton size="medium" value={DESTINATION_TYPE.CREATE_NEW}>
                  <Typography variant="overline" noWrap>
                    CREATE NEW MODEL
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
              {destinationType === DESTINATION_TYPE.USE_EXIST ? (
                <Stack my={1} spacing={1}>
                  <ExistingModelDestination />
                  <TextInputField
                    label="Fine-tuned version name"
                    name={getField({ versionName: null })}
                    hint="Max. 100 characters"
                  />
                </Stack>
              ) : (
                <Stack my={1} spacing={1}>
                  <TextInputField
                    label="New model name"
                    name={getField({ versionName: null })}
                    hint="Max. 100 characters"
                    isRequired
                  />
                  <TextInputField
                    label="Fine-tuned version name"
                    name={getField({ versionName: null })}
                    hint="Max. 100 characters"
                    isRequired
                  />
                </Stack>
              )}
            </Stack>
          </Popup>
        );
      }}
    </PresetFormik>
  );
};
