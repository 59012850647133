import isNotNil from 'shared/utils/isNotNill';
import { CustomDomain } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/shared/CustomDomain';

export const getAxisesDomains = ({
  xAxis,
  yAxis,
}: {
  xAxis: { domain: CustomDomain };
  yAxis: { domain: CustomDomain };
}) => {
  const yAxisDomainFields = getDomainFields(yAxis.domain);
  const xAxisDomainFields = getDomainFields(xAxis.domain);

  return {
    xAxisDomainFields,
    yAxisDomainFields,
    isNeedToClipMark:
      xAxisDomainFields?.mark.clip || yAxisDomainFields?.mark.clip,
  };
};

export const getScaleType = (isLogScale: boolean) =>
  isLogScale ? ('log' as const) : undefined;

const getDomainFields = (domain: CustomDomain) => {
  if (isNotNil(domain.min) && isNotNil(domain.max)) {
    return {
      mark: {
        clip: true,
      },
      scale: {
        domain: [domain.min, domain.max],
      },
    };
  }
  if (isNotNil(domain.min)) {
    return {
      mark: {
        clip: true,
      },
      scale: {
        domainMin: domain.min,
      },
    };
  }
  if (isNotNil(domain.max)) {
    return {
      mark: {
        clip: true,
      },
      scale: {
        domainMax: domain.max,
      },
    };
  }
};
