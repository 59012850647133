import { gql } from '@apollo/client';

import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/useUpdateRole.generated';

export { Types };

export const QUERY = gql`
  mutation UpdateRoleToOrganizationV2(
    $organizationId: ID!
    $roleId: ID!
    $input: RoleV2Input!
  ) {
    organizationV2(id: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        role(id: $roleId) {
          id
          update(id: $roleId, input: $input) {
            __typename
            orgId
            id
            name
            description
            builtIn
            allowedActions {
              ...AllowedActionsData
            }
            resourceActions {
              resourceType
              allowedActions
            }
          }
        }
      }
    }
  }
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useUpdateRole = (onCompleted: () => void) => {
  const [run, communication] = useCustomMutation<
    Types.UpdateRoleToOrganizationV2,
    Types.UpdateRoleToOrganizationV2Variables
  >(
    QUERY,
    {
      context: 'updating role',
    },
    { onCompleted }
  );

  return {
    run,
    communication,
  };
};
