export const makeNoSkillPoints = ({ y1, y2 }: { y1: number; y2: number }) =>
  [
    {
      x: 0,
      y: y1,
    },
    {
      x: 1,
      y: y2,
    },
  ].map((point) => ({
    ...point,
    meta: undefined,
    key: 'No Skill',
    lineType: 'dashed' as const,
  }));
