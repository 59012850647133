import { TableWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/TableWidget';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import { validateNotEmpty } from 'shared/utils/validators';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { defaultMonitoringDriftMetricType } from 'shared/models/Monitoring/MonitoringDriftMetricType';
import { OmitStrict } from 'shared/utils/types';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';

import MonitoringWidgetFormPopupContent from '../../MonitoringWidgetFormPopup/MonitoringWidgetFormPopupContent/MonitoringWidgetFormPopupContent';
import { BaseMonitoringWidgetFormProps } from '../shared/types/BaseMonitoringWidgetFormProps';
import MonitoringDriftMetricTypeField from '../shared/MonitoringDriftMetricTypeField/MonitoringDriftMetricTypeField';
import VariantFields, {
  VariantMatchers,
} from '../shared/VariantFields/VariantFields';

type Form = OmitStrict<TableWidget, 'id'>;

const getFieldName = makeGetFieldName<Form>();

interface Props extends BaseMonitoringWidgetFormProps {
  onSubmit: (form: Form) => void;
  initialValues: Form | null;
}

const variants: VariantMatchers<Form['variant']> = {
  drift: {
    initialValue: {
      driftMetricType: defaultMonitoringDriftMetricType,
      type: 'drift',
    },
    label: 'Drift',
    render: () => (
      <MonitoringDriftMetricTypeField
        name={getFieldName({ variant: { driftMetricType: null } })}
      />
    ),
  },
  outlierDetection: {
    initialValue: {
      type: 'outlierDetection',
    },
    label: 'Outlier detection',
    render: () => <></>,
  },
};

const initialValues: Form = {
  type: 'table',
  title: '',
  variant: variants.drift.initialValue,
  changeIODescription: undefined,
  ioDescription: undefined,
};

const TableWidgetForm = (props: Props) => {
  return (
    <PresetFormik initialValues={initialValues} onSubmit={props.onSubmit}>
      {({ isValid, values, handleReset, setFieldValue }) => (
        <MonitoringWidgetFormPopupContent
          isValid={isValid}
          onBack={props.onBack}
          onReset={handleReset}
          submitText={props.submitText}
          widgetPreview={values}
          widgetExternalDeps={props.widgetExternalDeps}
          onSubmitClick={() => {
            props.onSubmit(values);
          }}
        >
          <TextInputField
            isRequired={true}
            label="Title"
            name={getFieldName({ title: null })}
            validate={validateNotEmpty('title')}
          />

          <VariantFields
            variantTypeFieldName={getFieldName({ variant: { type: null } })}
            variants={variants}
            value={values.variant}
            onChange={(value) =>
              setFieldValue(getFieldName({ variant: null }), value)
            }
          />
        </MonitoringWidgetFormPopupContent>
      )}
    </PresetFormik>
  );
};

export default TableWidgetForm;
