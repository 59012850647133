import { useMemo } from 'react';

import { IOptionType } from 'shared/view/elements/Selects/shared/types';

const SELECT_NA_ITEM = { label: 'N/a', value: undefined };

export default function useVertaSelectOptionsWithClear<T>(
  options: IOptionType<T>[]
): IOptionType<T | undefined>[] {
  return useMemo(() => [SELECT_NA_ITEM, ...options], [options]);
}
