import { Box, Skeleton, Stack, Typography } from '@mui/material';

import { Button } from 'shared/view/elements/Button';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import Chip from 'shared/view/elements/Chip/Chip';
import { useOrganizationUsersCount } from 'features/homepage/hooks/useOrganizationUsersCount';
import { AddNewUserPopup } from 'features/organizations/users/view/AddNewUserPopup/AddNewUserPopup';

import { Container, IconWrapper } from './styled';

export const UsersSummary = (props: {
  organizationId: string | undefined;
  isFreetier: boolean;
}) => {
  const { organizationId = '', isFreetier } = props;
  const { closePopup, isPopupOpen, openPopup } = usePopupManager();
  const { data, communication } = useOrganizationUsersCount({ organizationId });
  const usersCount = isNotError(data) ? data : 0;

  return (
    <>
      <Container spacing={4}>
        <Stack direction="row" justifyContent="space-between">
          <IconWrapper icon={ICONS.users} color="primary.main" />
          {isFreetier ? (
            <Chip
              label="Unlimited"
              variant="outlined"
              color="primary"
              size="small"
            />
          ) : null}
        </Stack>
        <Box display="flex" flexDirection="column" height="100%">
          <Box flex="1 1 auto" mb={2} display="flex" flexDirection="column">
            <Typography variant="h2">
              {communication.isRequesting ? (
                <Skeleton variant="text" width={50} />
              ) : (
                usersCount
              )}
            </Typography>

            <Typography variant="subtitle1" color="text.secondary">
              {communication.isRequesting ? (
                <Skeleton variant="text" width={100} />
              ) : (
                'Users & collaborators'
              )}
            </Typography>
          </Box>
          <Box flex="0 1 auto" display="flex">
            {communication.isRequesting ? (
              <Skeleton variant="rectangular" width={115} height={30} />
            ) : (
              <Button
                variant="outlined"
                onClick={openPopup}
                size="small"
                isLoading={false}
              >
                Add Users
              </Button>
            )}
          </Box>
        </Box>
      </Container>
      <AddNewUserPopup
        isOpen={isPopupOpen}
        onClose={closePopup}
        title="Invite users"
        linkLabel="Org link"
        linkDescription="Share this link with team members with existing Verta accounts."
        inputDescription="Invite new people to create an account with Verta and join your team."
        mainButtonProps={{ children: 'Invite' }}
      />
    </>
  );
};
