import { monitoringWidgetMinSize } from 'features/monitoring/panel/store/useCustomMonitoringPanel';
import { MonitoringMetricType } from 'generated/types';
import generateId from 'shared/utils/generateId';
import capitalize from 'shared/utils/capitalize';

import { MonitoringDashboard } from '../../MonitoringDashboard/MonitoringDashboard';
import { makePresetSummaryPanel } from './shared/presetSummaryPanel';
import { MakeDefaultModelStateProps } from '../shared/MakeDefaultModelStateProps';
import { makePresetMetricRow } from './shared/makePresetMetricRow';
import { SingleMetricWidget } from '../../MonitoringPanel/MonitoringWidget/Widgets/SingleMetricWidget';
import { AlertSettingsType } from '../../MonitoringAlert/AlertSettings';

export const overviewDashboardId = 'overview';

const makeModelHealthItem = (alertType: AlertSettingsType, index: number) => {
  const widget: SingleMetricWidget = {
    id: generateId(),
    title: `${capitalize(alertType)} alerts`,
    type: 'singleMetric',
    variant: {
      alertType,
      type: 'health',
    },
  };

  const width = 6;

  const layout = {
    minH: monitoringWidgetMinSize,
    minW: monitoringWidgetMinSize,
    h: 6,
    i: widget.id,
    w: width,
    x: index % 2 === 1 ? width : 0,
    y: 0,
  };

  return {
    widget,
    layout,
  };
};

export const makePresetOverviewDashboard = (
  props: MakeDefaultModelStateProps
): MonitoringDashboard => {
  const predictionCountRow = makePresetMetricRow(
    MonitoringMetricType.PREDICTION_COUNT,
    props.defaultOutput,
    0
  );
  const modelHealthItems = ['drift' as const, 'metric' as const].map(
    (alertType, index) => makeModelHealthItem(alertType, index)
  );

  return {
    createdBy: props.owner,
    dateCreated: new Date().toISOString(),
    id: overviewDashboardId,
    name: 'Overview',
    panels: [
      {
        id: generateId(),
        name: 'Model usage',
        type: 'custom',
        isOpen: true,
        layout: predictionCountRow.layout,
        widgets: predictionCountRow.widgets,
      },
      {
        id: generateId(),
        name: 'Model health',
        type: 'custom',
        isOpen: true,
        layout: modelHealthItems.map((d) => d.layout),
        widgets: modelHealthItems.map((d) => d.widget),
      },
      makePresetSummaryPanel(props, 'Model metrics'),
    ],
  };
};
