import { gql } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/useUpdateUser.generated';
import { useOrganizationUsersContext } from './useOrganizationUsers';

export { Types };

export const QUERY = gql`
  mutation UpdateUserV2($organizationId: ID!, $input: UpdateUserInput!) {
    organizationV2(id: $organizationId) {
      ... on OrganizationV2 {
        id
        updateUser(input: $input) {
          id
        }
      }
    }
  }
`;

export const useUpdateUser = () => {
  const [run, communication] = useCustomMutation<
    Types.UpdateUserV2,
    Types.UpdateUserV2Variables
  >(QUERY, {
    context: 'updating user',
  });
  const { usersApi } = useOrganizationUsersContext();

  return {
    run: (v: Types.UpdateUserV2Variables) =>
      run(v).then(() => usersApi.reload()),
    communication,
  };
};
