// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MUICheckbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import { useCallback } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import isNotNil from 'shared/utils/isNotNill';
import InfoIcon from 'shared/view/elements/InfoIcon/InfoIcon';

import FieldBox from '../FieldBox/FieldBox';

type ClickableProps =
  | {
      onChange?: undefined;
      disabled: true;
    }
  | {
      onChange(value: boolean): void;
      disabled?: boolean;
    };

export type ICheckboxProps = Pick<BoxProps, 'minWidth' | 'maxWidth'> &
  ClickableProps & {
    value: boolean;
    label: string | React.ReactNode;
    info?: string;
    name?: string;
    dataTest?: string;
    color?: string;
  };

const Checkbox = (props: ICheckboxProps) => {
  const { value, dataTest, name, disabled, onChange, ...other } = props;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.checked);
    },
    [onChange]
  );

  return (
    <FieldBox
      {...other}
      sx={{ height: '32px', display: 'flex', alignItems: 'center' }}
    >
      <FormControl fullWidth>
        <Stack direction="row" alignItems="center" width="100%">
          <FormControlLabel
            disabled={disabled}
            control={
              <MUICheckbox
                disableRipple={true}
                checked={value}
                onChange={handleChange}
                disabled={disabled}
                name={name}
                data-test={dataTest}
              />
            }
            label={
              typeof props.label === 'string' ? (
                <Box width="100%" display="grid">
                  <DynamicTypography
                    value={props.label}
                    color={props.color || 'text.secondary'}
                  />
                </Box>
              ) : (
                props.label
              )
            }
          />
          {isNotNil(props.info) ? <InfoIcon tooltip={props.info} /> : null}
        </Stack>
      </FormControl>
    </FieldBox>
  );
};

export default Checkbox;
