import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Markdown } from 'shared/utils/types';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { downloadBlobFile } from 'shared/utils/downloadFile';
import CardLayout from 'shared/view/elements/CardLayout/CardLayout';

import MarkdownView from './shared/Markdown/Markdown';
import MarkdownEditor from './MarkdownEditor/MarkdownEditor';

interface ILocalProps {
  title: string;
  initialValue: Markdown;
  isEditDisabled: boolean;
  isDownloadDisabled: boolean;
  isEmbeded?: boolean;
  onSaveChanges(value: Markdown): void;
  isSaving: boolean;
}

const TitleWrapper = ({ title }: { title: string }) => (
  <Stack spacing={1} direction="row">
    <Box display="flex" alignItems="center">
      <IconAwesomeInfo icon={ICONS.bookOpen} size="sm" />
    </Box>
    <Typography variant="subtitle2">{title}</Typography>
  </Stack>
);

const MarkdownManager = (props: ILocalProps) => {
  const {
    title,
    initialValue,
    isEmbeded = false,
    isEditDisabled,
    isDownloadDisabled,
    isSaving,
    onSaveChanges,
  } = props;

  const [editorOpen, setEditorOpen] = useState(false);
  // Hack to use a local image
  const updatedInitialValue = initialValue.replace(
    '[Like Rothko](https://uploads5.wikiart.org/images/mark-rothko/no-2-no-7-and-no-2-1951.jpg)',
    '[Sample Chart](/ReadMeTemplate-Chart-v1.png)'
  );

  const onClose = () => setEditorOpen(false);
  const onEdit = () => setEditorOpen(true);
  const onDownload = () => {
    const blob = new Blob([updatedInitialValue], { type: 'text/plain' });

    downloadBlobFile({
      name: 'readme',
      content: blob,
      extension: 'md',
    });
  };

  return (
    <>
      <CardLayout
        isEmbeded={isEmbeded}
        header={{
          leftContent: !isEmbeded ? <TitleWrapper title={title} /> : null,
          rightContent: (
            <Stack direction="row">
              <IconAwesomeClickable
                icon={ICONS.pencil}
                onClick={onEdit}
                size="lg"
                dataTest="open-markdown-editor"
                disabled={isEditDisabled}
              />
              <IconAwesomeClickable
                icon={ICONS.arrowDownToBracket}
                onClick={onDownload}
                size="lg"
                dataTest="download-readme-md"
                disabled={isDownloadDisabled}
              />
            </Stack>
          ),
        }}
      >
        <MarkdownView>{updatedInitialValue}</MarkdownView>
      </CardLayout>
      <MarkdownEditor
        title={title}
        initialValue={updatedInitialValue}
        isOpen={editorOpen}
        isSaving={isSaving}
        onClose={onClose}
        onSave={onSaveChanges}
      />
    </>
  );
};

export default MarkdownManager;
