import matchBy from 'shared/utils/matchBy';
import { ScatterChartSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/ScatterChartSettings';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import routes from 'shared/routes';
import { ExperimentRunWithDisplayedFields } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';

import { getAxisesDomains, getScaleType } from './shared/Scale';
import { config } from './shared/config';
import {
  ExperimentRunWithKeyValueFieldsAsObjects,
  getExperimentRunsForSpec,
  makeGetDataSpecFields,
} from './shared/ExperimentRunWithKeyValueFieldsAsObjects';
import {
  getDefaultDataField,
  WidgetVisualizationSpec,
} from './shared/WidgetVisualizationSpec';

type ExperimentRunForSpec = ExperimentRunWithKeyValueFieldsAsObjects & {
  url: string;
};

export const getExperimentRunsForScatterChartSpec = (
  experimentRuns: ExperimentRunWithDisplayedFields[]
): ExperimentRunForSpec[] =>
  getExperimentRunsForSpec(experimentRuns).map((x) => ({
    ...x,
    url: routes.experimentRun.getRedirectPath({
      experimentRunId: x.id,
      projectId: x.project.id,
      workspaceName: x.project.workspace.name,
    }),
  }));

const getFieldName = makeGetFieldName<ExperimentRunForSpec>();

const getDataSpecFields = makeGetDataSpecFields<ExperimentRunForSpec>();

export const getScatterChartSpec = (
  dataSourceName: string,
  settings: ScatterChartSettings
): WidgetVisualizationSpec => {
  const yDataSpecFields = getDataSpecFields(settings.yAxis.spec);

  const xDataSpecFields = getDataSpecFields(settings.xAxis.spec);

  const zDataSpecFields = matchBy(
    settings.zAxis.spec,
    'type'
  )({
    keyValue: (keyValueSpec) => getDataSpecFields(keyValueSpec),
    experimentRunStringField: (experimentRunStringFieldSpec) =>
      matchBy(
        experimentRunStringFieldSpec,
        'name'
      )({
        experiment: () => ({
          title: 'experiment',
          field: getFieldName({ experiment: { name: null } }),
        }),
        owner: () => ({
          title: 'owner',
          field: getFieldName({ owner: { username: null } }),
        }),
      }),
  });

  const { xAxisDomainFields, yAxisDomainFields, isNeedToClipMark } =
    getAxisesDomains(settings);

  return {
    config,
    data: getDefaultDataField(dataSourceName),
    mark: {
      type: 'point',
      filled: true,
      tooltip: true,
      size: 75,
      clip: isNeedToClipMark,
    },
    encoding: {
      x: {
        ...xDataSpecFields,
        axis: {
          grid: false,
        },
        scale: {
          zero: false,
          type: getScaleType(settings.xAxis.isLogScale),
          ...xAxisDomainFields?.scale,
        },
      },
      y: {
        ...yDataSpecFields,
        scale: {
          zero: false,
          type: getScaleType(settings.yAxis.isLogScale),
          ...yAxisDomainFields?.scale,
        },
      },
      color: zDataSpecFields,
      tooltip: [
        { title: 'name', field: getFieldName({ name: null }), type: 'nominal' },
        { title: 'tags', field: getFieldName({ tags: null }), type: 'nominal' },
        yDataSpecFields,
        xDataSpecFields,
        zDataSpecFields,
      ],
      href: { field: getFieldName({ url: null }), type: 'nominal' },
    },
  };
};
