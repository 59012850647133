/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { DataWithPagination } from 'shared/models/Pagination';
import { IHighLevelGraphqlSearchSettings } from 'services/highLevelSearch/shared/types';
import { apolloErrorFromUnknownToAppError } from 'shared/utils/graphql/apolloErrorToAppError';
import { convertExperimentRunsCommonVariables } from 'features/experimentRuns/shared/converters';
import {
  ExperimentRunsByWorkspace,
  ExperimentRunsByWorkspaceVariables,
} from 'services/highLevelSearch/loadExperimentRunsListByWorkspace/graphql-types/loadExperimentRunsListByWorkspace.generated';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { USER_FRAGMENT } from 'shared/graphql/User/User';
import { ExperimentRunResult } from 'shared/models/HighLevelSearch/SearchResult';
import { OmitStrict } from 'shared/utils/types';
import {
  isRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';

import convertHighLevelSearchFilterToGraphql from '../shared/converters';

const EXPERIMENT_RUNS_BY_WORKSPACE = gql`
  query ExperimentRunsByWorkspace(
    $name: String
    $organizationId: ID
    $query: ExperimentRunsQuery
  ) {
    workspace(name: $name, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        runs(query: $query) {
          runs {
            id
            name
            experiment {
              id
              name
            }
            project {
              id
              name
              workspace {
                id
                ...WorkspaceData
              }
            }
            tags
            dateCreated
            dateUpdated
            owner {
              id
              ...UserData
            }
          }
          pagination {
            totalRecords
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const loadExperimentRunsListByWorkspace = async ({
  apolloClient,
  filter,
  pagination,
  sorting,
  workspaceName,
}: IHighLevelGraphqlSearchSettings): Promise<
  DataWithPagination<OmitStrict<ExperimentRunResult, 'entityType'>>
> => {
  try {
    const result = await apolloClient.query<
      ExperimentRunsByWorkspace,
      ExperimentRunsByWorkspaceVariables
    >({
      query: EXPERIMENT_RUNS_BY_WORKSPACE,
      fetchPolicy: 'no-cache',
      variables: {
        name: workspaceName,
        query: convertExperimentRunsCommonVariables({
          convertedFilters: {
            stringPredicates: [
              convertHighLevelSearchFilterToGraphql(filter, {
                tagFilterToLabelFilter: false,
              }),
            ],
          },
          sorting,
          pagination,
        }),
      },
    });

    if (
      isRestrictedGraphqlError(result.data.workspace) ||
      (result.errors || []).length > 0
    ) {
      return { totalCount: 0, data: [] };
    }

    return {
      totalCount: result.data.workspace.runs.pagination.totalRecords,
      data: result.data.workspace.runs.runs.map((run) => ({
        ...run,
        workspace: run.project.workspace,
        dateCreated: new Date(run.dateCreated),
        dateUpdated: new Date(run.dateUpdated),
      })),
    };
  } catch (e: unknown) {
    throw apolloErrorFromUnknownToAppError(e);
  }
};
