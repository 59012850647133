import { equals, isEmpty, isNil, uniq } from 'ramda';
import moment from 'moment';

import { StringPredicate, PredicateOperator } from 'generated/types';

import {
  HandlerProps,
  LabelOptionsProps,
  OwnerOptionsProps,
  CLEAR_VALUE,
} from '.';

const PRODUCTION_PREDICATE: StringPredicate = {
  key: 'stage',
  operator: PredicateOperator.EQ,
  value: 'PRODUCTION',
};

export const handleHasProductionVersions = ({
  currentFilters,
  stringPredicates,
  value,
  setCurrentFilters,
  setStringPredicates,
}: HandlerProps & { value: boolean }) => {
  const filtered = stringPredicates.filter(
    (predicate) => !equals(predicate, PRODUCTION_PREDICATE)
  );

  if (value === true) filtered.push(PRODUCTION_PREDICATE);

  setCurrentFilters({
    ...currentFilters,
    hasProductionVersions: value,
  });
  setStringPredicates(filtered);
};

export const handleTaskType = ({
  currentFilters,
  stringPredicates,
  value,
  setCurrentFilters,
  setStringPredicates,
}: HandlerProps & { value?: string }) => {
  const filtered = stringPredicates.filter(
    (predicate) => predicate.key !== 'task_type'
  );

  if (value !== undefined && value !== CLEAR_VALUE) {
    filtered.push({
      key: 'task_type',
      operator: PredicateOperator.EQ,
      value: String(value),
    });
  }

  setCurrentFilters({
    ...currentFilters,
    taskType: value as string,
  });
  setStringPredicates(filtered);
};

export const handleDataType = ({
  currentFilters,
  stringPredicates,
  value,
  setCurrentFilters,
  setStringPredicates,
}: HandlerProps & { value?: string }) => {
  const filtered = stringPredicates.filter(
    (predicate) => predicate.key !== 'data_type'
  );

  if (value !== undefined && value !== CLEAR_VALUE) {
    filtered.push({
      key: 'data_type',
      operator: PredicateOperator.EQ,
      value: String(value),
    });
  }

  setCurrentFilters({
    ...currentFilters,
    dataType: value as string,
  });
  setStringPredicates(filtered);
};

export const handleLabels = ({
  currentFilters,
  stringPredicates,
  value,
  setCurrentFilters,
  setStringPredicates,
}: HandlerProps & { value: LabelOptionsProps }) => {
  const filtered = stringPredicates.filter(
    (predicate) => predicate.key !== 'label'
  );

  let labels = currentFilters.labels || [];

  if (value.checked) {
    labels.push(value);
  } else {
    labels = labels.filter((el) => el.value !== value.value);
  }

  labels.forEach((label) =>
    filtered.push({
      key: 'label',
      operator: PredicateOperator.EQ,
      value: label.value,
    })
  );

  setCurrentFilters({
    ...currentFilters,
    labels,
  });
  setStringPredicates(filtered);
};

export const handleDateUpdated = ({
  currentFilters,
  stringPredicates,
  value,
  setCurrentFilters,
  setStringPredicates,
}: HandlerProps & { value?: string }) => {
  const filtered = stringPredicates.filter(
    (predicate) => predicate.key !== 'time_updated'
  );

  if (value !== undefined && value !== CLEAR_VALUE) {
    const filterDate = moment().subtract(Number(value), 'days').startOf('day');

    filtered.push({
      key: 'time_updated',
      operator: PredicateOperator.GTE,
      value: String(filterDate.valueOf()),
    });
  }

  setCurrentFilters({
    ...currentFilters,
    dateUpdated: value as string,
  });
  setStringPredicates(filtered);
};

export const handleSearch = ({
  currentFilters,
  stringPredicates,
  value,
  setCurrentFilters,
  setStringPredicates,
}: HandlerProps & { value?: string }) => {
  const filtered = stringPredicates.filter(
    (predicate) => predicate.key !== 'search'
  );

  if (!isNil(value) && !isEmpty(value)) {
    filtered.push({
      key: 'search',
      operator: PredicateOperator.CONTAIN,
      value,
    });
  }

  setCurrentFilters({
    ...currentFilters,
    search: value as string,
  });
  setStringPredicates(filtered);
};

export const handleOwner = ({
  currentFilters,
  stringPredicates,
  value,
  setCurrentFilters,
  setStringPredicates,
}: HandlerProps & { value: OwnerOptionsProps }) => {
  const { owners: initOwners } = currentFilters;

  let owners: OwnerOptionsProps[] = initOwners || [];
  let newPredicates = stringPredicates.filter((p) => p.key !== 'owner');

  if (value.checked) {
    owners = owners.concat(value);
  } else {
    owners = owners.filter((el) => el.value !== value.value);
  }

  if (!isEmpty(owners)) {
    newPredicates = newPredicates.concat({
      key: 'owner',
      operator: PredicateOperator.IN,
      value: owners.map((owner) => owner.id).join(','),
    });
  }

  setCurrentFilters({
    ...currentFilters,
    owners,
  });
  setStringPredicates(newPredicates);
};

export const handleHasEndpoints = ({
  currentFilters,
  stringPredicates,
  value,
  setCurrentFilters,
  setStringPredicates,
}: HandlerProps & { value: string[] | undefined }) => {
  const filtered = stringPredicates.filter(
    (predicate) =>
      !(
        predicate.key === 'registered_model_id' &&
        predicate.operator === PredicateOperator.IN
      )
  );

  let modelIds: string[] = [];
  const hasValueWithEmptySelectedIds = !isEmpty(value);

  if (hasValueWithEmptySelectedIds) {
    modelIds = value ?? [];
  }

  if (!isEmpty(modelIds)) {
    filtered.push({
      key: 'registered_model_id',
      operator: PredicateOperator.IN,
      value: uniq(modelIds).join(','),
    });
  }

  setCurrentFilters({
    ...currentFilters,
    hasEndpoints: value,
  });
  setStringPredicates(filtered);
};

export const handleHasNoEndpoints = ({
  currentFilters,
  stringPredicates,
  value,
  setCurrentFilters,
  setStringPredicates,
}: HandlerProps & { value: string[] | undefined }) => {
  const filtered = stringPredicates.filter(
    (predicate) =>
      !(
        predicate.key === 'registered_model_id' &&
        predicate.operator === PredicateOperator.NOT_IN
      )
  );

  if (!isEmpty(value) && !isNil(value)) {
    filtered.push({
      key: 'registered_model_id',
      operator: PredicateOperator.NOT_IN,
      value: uniq(value).join(','),
    });
  }

  setCurrentFilters({
    ...currentFilters,
    hasNoEndpoints: value,
  });
  setStringPredicates(filtered);
};
