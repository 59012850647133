const downloadFile = ({
  name,
  extension,
  content,
}: {
  name: string;
  extension: string;
  content: string;
}) => {
  const hiddenElement = document.createElement('a');
  hiddenElement.href =
    `data:text/${extension};charset=utf-8,` + encodeURI(content);
  hiddenElement.target = '_blank';
  hiddenElement.download = `${name}.${extension}`;
  hiddenElement.click();
};

const downloadBlobFile = ({
  name,
  extension,
  content,
}: {
  name: string;
  extension: string;
  content: Blob;
}) => {
  const url = URL.createObjectURL(content);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${name}.${extension}`;
  link.click();
  URL.revokeObjectURL(link.href);
};

const downloadFileFromLink = ({ name, url }: { name: string; url: string }) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.click();
  URL.revokeObjectURL(link.href);
};

export { downloadFile, downloadBlobFile, downloadFileFromLink };
