import { Box, styled, useTheme } from '@mui/material';
import React from 'react';

interface ILocalProps {
  error: JSX.Element | string;
  additionalClassName?: string;
  dataTest?: string;
  style?: React.CSSProperties;
}

const StyledBox = styled(Box)({
  marginTop: ' 8px',
  wordBreak: 'break-word',
  wordWrap: 'break-word',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineHeight: '1.1em',
});

const InlineErrorView = (props: ILocalProps) => {
  return (
    <BaseInlineErrorView
      style={props.style}
      additionalClassName={props.additionalClassName}
      dataTest={props.dataTest || 'error'}
      error={props.error}
    />
  );
};

const BaseInlineErrorView = (props: ILocalProps) => {
  const theme = useTheme();
  return (
    <StyledBox
      data-test={props.dataTest}
      style={{ ...props.style, color: theme.palette.error.main }}
    >
      {props.error}
    </StyledBox>
  );
};

export default InlineErrorView;
