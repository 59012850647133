import { NavigateFunction, Location } from 'react-router-dom';

import { INITIAL_PAGINATION_PAGE, DEFAULT_PAGE_SIZE } from '..';

export interface URLWithPagination {
  page?: string;
  pageSize?: string;
}

export const urlPageParam: keyof URLWithPagination = 'page';
export const urlPageSizeParam: keyof URLWithPagination = 'pageSize';

const isPageNeedChange = (search: string, newPage: number) => {
  if (newPage === 0) {
    return Boolean(search);
  }

  return new URLSearchParams(search).get('page') !== String(newPage + 1);
};

export const changePageInURL = (navigate: NavigateFunction, page: number) => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  if (page === INITIAL_PAGINATION_PAGE) {
    urlSearchParams.delete(urlPageParam);
  } else {
    urlSearchParams.set(urlPageParam, String(page + 1));
  }

  if (isPageNeedChange(window.location.search, page)) {
    navigate({
      search: String(urlSearchParams),
    });
  }
};

export const changePageSizeInURL = (
  navigate: NavigateFunction,
  location: Location,
  pageSize: number
) => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  if (pageSize === DEFAULT_PAGE_SIZE) {
    urlSearchParams.delete(urlPageSizeParam);
  } else {
    urlSearchParams.set(urlPageSizeParam, String(pageSize));
  }

  if (isPageNeedChange(location.search, pageSize)) {
    navigate({
      ...location,
      search: String(urlSearchParams),
    });
  }
};

export const getPageFromURL = (search: string): number => {
  const urlParams = new URLSearchParams(search);

  const page = Number(urlParams.get(urlPageParam));
  if (page) {
    return page - 1;
  }

  return INITIAL_PAGINATION_PAGE;
};

export const getPageSizeFromURL = (
  search: string,
  defaultPageSize?: number
): number => {
  const urlParams = new URLSearchParams(search);

  const pageSize = Number(urlParams.get(urlPageSizeParam));
  if (pageSize) {
    return pageSize;
  }

  if (defaultPageSize !== undefined && !isNaN(defaultPageSize)) {
    return defaultPageSize;
  }

  return DEFAULT_PAGE_SIZE;
};
