import { FC } from 'react';

import { makeEntitiesPrimitivesDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/EntitiesPrimitivesDiffView';
import { makeEntitiesLabelsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/EntityLabelsDiffView';
import CompareTable from 'shared/view/domain/CompareEntities/New/CompareTable/CompareTable';
import routes from 'shared/routes';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { makeKeyValuePairsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/KeyValuePairsDiffView';
import { makeAttributesDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/AttributesDiff/AttributesDiff';
import { makeArtifactsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/ArtifactsDiff';
import { makeDatasetsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/DatasetsDiff';
import { makeEnvironmentsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/EnvironmentsDiffView';
import { makeCodeVersionsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/CodeVersionDiffView';
import * as PropertyDiffDescriptions from 'shared/view/domain/CompareEntities/New/CompareTable/PropertyDiffDescriptions';

import { ComparedExperimentRuns } from '../../store/utils/comparedExperimentRuns';
import { compareExperimentRuns } from '../../store/utils/experimentRunsComparing';

interface Props {
  experimentRuns: ComparedExperimentRuns;
  charts: (comparedExperimentRuns: ComparedExperimentRuns) => React.ReactNode;
}

const ExperimentRunsCompareTable: FC<React.PropsWithChildren<Props>> = (
  props
) => {
  return (
    <CompareTable
      entities={props.experimentRuns}
      compareEntities={compareExperimentRuns}
      getRemovedEntityName={(entity) => entity.name}
      columnEntityName="Experiment run"
      getPropertiesDiffDescriptions={getPropertiesDiffs}
      children={props.charts}
    />
  );
};

function getPropertiesDiffs(
  experimentRunsDiff: ReturnType<typeof compareExperimentRuns>
) {
  return PropertyDiffDescriptions.makePropertyDiffDescriptions([
    makeEntitiesPrimitivesDiff({
      name: 'Name',
      diff: experimentRunsDiff.name,
      render: (name, { project, id }) => (
        <InlineLink
          to={routes.experimentRun.getRedirectPath({
            experimentRunId: id,
            projectId: project.id,
            workspaceName: project.workspace.name,
          })}
        >
          {name}
        </InlineLink>
      ),
    }),
    makeEntitiesPrimitivesDiff({
      name: 'Description',
      diff: experimentRunsDiff.description,
      render: (x) => x,
    }),
    makeEntitiesPrimitivesDiff({
      name: 'Experiment',
      diff: experimentRunsDiff.experiment,
      render: (_, { experiment }) => experiment.name,
    }),
    makeEntitiesLabelsDiff({
      name: 'Labels',
      diff: experimentRunsDiff.tags,
    }),
    makeKeyValuePairsDiff({
      name: 'Hyperparameters',
      diff: experimentRunsDiff.hyperparameters,
    }),
    makeKeyValuePairsDiff({
      name: 'Metrics',
      diff: experimentRunsDiff.metrics,
    }),
    makeAttributesDiff(experimentRunsDiff.attributes),
    makeArtifactsDiff(experimentRunsDiff.artifacts),
    makeDatasetsDiff(experimentRunsDiff.datasets),
    makeEnvironmentsDiff(experimentRunsDiff.environments),
    makeCodeVersionsDiff(experimentRunsDiff.codeVersions),
  ]);
}

export default ExperimentRunsCompareTable;
