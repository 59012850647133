import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useModelOverview } from 'features/usecases/registeredModel/overview/hooks';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

const ModelOverview = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ModelOverview' */ 'features/usecases/registeredModel/overview/view/overview'
    )
);

const ModelOverviewPage = () => {
  const { registeredModelId } = useRouteParams(routes.modelOverview);

  const { data, communication } = useModelOverview({ id: registeredModelId });

  return (
    <DefaultMatchRemoteDataOrError
      communication={communication}
      data={data}
      context="loading model"
    >
      {(model) => <ModelOverview model={model} />}
    </DefaultMatchRemoteDataOrError>
  );
};

export default ModelOverviewPage;
