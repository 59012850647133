import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';
import isNotNil from 'shared/utils/isNotNill';
import { ExtractByTypename } from 'shared/utils/types';

import {
  EVALUATIONS_MODELS_VERSIONS_QUERY,
  EvaluationsModelsVersions,
  EvaluationsModelsVersionsVariables,
} from '../graphql';

type RegisteredModels = ExtractByTypename<
  EvaluationsModelsVersions['workspace'],
  'Workspace'
>['registeredModels']['models'];

type Version = ExtractByTypename<
  RegisteredModels[0]['versions']['versions'][0],
  'RegisteredModelVersion'
>;
export type VersionWithWorkspace = Version &
  Pick<RegisteredModels[0], 'workspace'>;

export const useWorkspaceModels = () => {
  const [load, queryResult] = useCustomLazyQuery<
    EvaluationsModelsVersions,
    EvaluationsModelsVersionsVariables
  >(EVALUATIONS_MODELS_VERSIONS_QUERY);
  const { data, communication } = resultToCommunicationWithData(
    convert,
    queryResult
  );

  return {
    data,
    communication,
    load,
  };
};

const convert = (res: EvaluationsModelsVersions) =>
  mapDataOrError(res.workspace, (workspaceData) => {
    const versions = workspaceData.registeredModels.models
      .filter(isNotNil)
      .map((model) =>
        model.versions.versions.map((version) => ({
          ...version,
          workspace: model.workspace,
        }))
      )
      .flat();
    return {
      versions,
      totalCount: versions.length,
    };
  });
