import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';

import Chip from 'shared/view/elements/Chip/Chip';
import isNotNil from 'shared/utils/isNotNill';
import { SelectIconComponent } from 'shared/view/elements/Selects/shared/view/SelectIconComponent';

import FieldBox from '../FieldBox/FieldBox';
import { ICONS } from '../IconAwesome/ICONS';
import { IconAwesomeClickable } from '../IconAwesome/IconAwesomeClickable';

interface Props {
  value: string[];
  onChange: (value: string[]) => void;
}

const MultiValueTextInput = (props: Props) => {
  const [inputValue, changeInputValue] = useState('');
  const options: string[] = [];

  return (
    <FieldBox pt={1}>
      <FormControl fullWidth size={'small'}>
        <Autocomplete
          forcePopupIcon
          options={options}
          multiple
          value={props.value}
          inputValue={inputValue}
          onInputChange={(_, value) => changeInputValue(value)}
          freeSolo
          disableClearable
          onChange={(_, value) => props.onChange(value as string[])}
          renderTags={(value: readonly string[], getTagProps) => (
            <div style={{ width: '100%' }}>
              {value.map((option: string, index: number) => (
                // key already provided by 'getTagProps'
                // eslint-disable-next-line react/jsx-key
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))}
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Value"
              size="small"
              InputProps={{
                ...params.InputProps,
                sx: { height: 'auto', minHeight: '40px' },
                endAdornment: (
                  <InputAdornment position="start">
                    <IconAwesomeClickable
                      onClick={() => {
                        isNotNil(params.inputProps.value) &&
                          props.onChange([
                            ...props.value,
                            params.inputProps.value.toString(),
                          ]);

                        changeInputValue('');
                      }}
                      icon={ICONS.plus}
                      size="sm"
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
          popupIcon={<SelectIconComponent />}
        />
      </FormControl>
    </FieldBox>
  );
};

export default MultiValueTextInput;
