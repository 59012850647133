import { useTheme } from '@mui/material';
import { transpose, repeat } from 'ramda';
import { CSSProperties, FC, Fragment } from 'react';

import styles from './RecordsGridSection.module.css';

interface Props {
  columns: Array<Array<{ label: string; content: React.ReactNode }>>;
  dataTest?: string;
}

const RecordsGridSection: FC<React.PropsWithChildren<Props>> = (props) => {
  const theme = useTheme();
  return (
    <div
      className={styles.root}
      style={generateGridTemplateColumns(props.columns)}
      data-test={props.dataTest}
    >
      {transpose(props.columns).map((columnRows) =>
        columnRows.map((c) => (
          <Fragment key={c.label}>
            <div
              className={styles.label}
              style={{ color: theme.palette.text.secondary }}
            >
              {c.label}
            </div>
            <div className={styles.content}>{c.content}</div>
          </Fragment>
        ))
      )}
    </div>
  );
};

const generateGridTemplateColumns = (
  columns: Props['columns']
): CSSProperties => {
  return {
    gridTemplateColumns: repeat('max-content 1fr', columns.length).join(' '),
  };
};

// ts-unused-exports:disable-next-line
export default RecordsGridSection;
