/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import routes from 'shared/routes';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { PERMISSION_FRAGMENT } from 'shared/graphql/Permission/Permission';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';

import { DatasetDetailsLayoutWithLoading } from '../LoadGraphqlDataset';
import * as GraphqlTypes from './graphql-types/DatasetOverviewPage.generated';

const DatasetOverviewContent = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'DatasetOverviewContent' */ './DatasetOverviewContent'
    )
);

const QUERY = gql`
  query DatasetOverview($id: ID!) {
    dataset(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on Dataset {
        id
        name
        allowedActions {
          ...AllowedActionsData
        }
        description
        tags
        dateCreated
        dateUpdated
        attributes {
          ...AttributeData
        }
        owner {
          ...OwnerData
        }
        workspace {
          id
          ...WorkspaceData
        }
        visibility
        customPermission {
          ...PermissionData
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${PERMISSION_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;

const DatasetOverviewPage = () => {
  const { datasetId } = useRouteParams(routes.datasetOverview);
  const queryResult = useCustomQuery<
    GraphqlTypes.DatasetOverview,
    GraphqlTypes.DatasetOverviewVariables
  >(QUERY, {
    variables: {
      id: datasetId,
    },
  });
  const communicationWithData = resultToCommunicationWithData(
    (response) => response.dataset,
    queryResult
  );

  return (
    <DatasetDetailsLayoutWithLoading
      data={communicationWithData.data}
      loadingDataset={communicationWithData.communication}
      reload={queryResult.refetch}
      pageEntityOrError={communicationWithData.data}
    >
      {({ dataset }) => (
        <div>
          <DatasetOverviewContent dataset={dataset} />
        </div>
      )}
    </DatasetDetailsLayoutWithLoading>
  );
};

export default DatasetOverviewPage;
