/* eslint-disable @typescript-eslint/no-restricted-imports */
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFolders } from '@fortawesome/pro-light-svg-icons/faFolders';
import { faRocket } from '@fortawesome/pro-light-svg-icons/faRocket';
import { faListCheck } from '@fortawesome/pro-light-svg-icons/faListCheck';
import { faChartWaterfall } from '@fortawesome/pro-light-svg-icons/faChartWaterfall';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons/faCircleQuestion';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faXmark as solidXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons/faCircleNotch';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons/faCircleXmark';
import { faRectangleList } from '@fortawesome/pro-light-svg-icons/faRectangleList';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass';
import { faGrid2Plus } from '@fortawesome/pro-light-svg-icons/faGrid2Plus';
import { faMagnifyingGlassChart } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlassChart';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faCircleDashed } from '@fortawesome/pro-light-svg-icons/faCircleDashed';
import { faCircleMinus } from '@fortawesome/pro-light-svg-icons/faCircleMinus';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons/faCircleCheck';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons/faShieldCheck';
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons/faCircleExclamation';
import { faArrowRotateRight } from '@fortawesome/pro-light-svg-icons/faArrowRotateRight';
import { faChartSimple } from '@fortawesome/pro-light-svg-icons/faChartSimple';
import { faChartPie } from '@fortawesome/pro-light-svg-icons/faChartPie';
import { faWaveformLines } from '@fortawesome/pro-light-svg-icons/faWaveformLines';
import { faTableList } from '@fortawesome/pro-light-svg-icons/faTableList';
import { faTextSize } from '@fortawesome/pro-light-svg-icons/faTextSize';
import { faVideo } from '@fortawesome/pro-light-svg-icons/faVideo';
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons/faArrowsRotate';
import { faCirclePlus } from '@fortawesome/pro-light-svg-icons/faCirclePlus';
import { faBookOpen } from '@fortawesome/pro-light-svg-icons/faBookOpen';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faArrowDownToBracket } from '@fortawesome/pro-light-svg-icons/faArrowDownToBracket';
import { faArrowDownToBracket as solidArrowDownToBracket } from '@fortawesome/pro-solid-svg-icons/faArrowDownToBracket';
import { faGear } from '@fortawesome/pro-light-svg-icons/faGear';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { faLayerPlus } from '@fortawesome/pro-light-svg-icons/faLayerPlus';
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChartPieSimple } from '@fortawesome/pro-light-svg-icons/faChartPieSimple';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faCube } from '@fortawesome/pro-light-svg-icons/faCube';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faX } from '@fortawesome/pro-light-svg-icons/faX';
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { faCodeCommit } from '@fortawesome/pro-light-svg-icons/faCodeCommit';
import { faShareNodes } from '@fortawesome/pro-light-svg-icons/faShareNodes';
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons/faTriangleExclamation';
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { faUserLock } from '@fortawesome/pro-light-svg-icons/faUserLock';
import { faBookmark } from '@fortawesome/pro-light-svg-icons/faBookmark';
import { faArrowRotateLeft } from '@fortawesome/pro-light-svg-icons/faArrowRotateLeft';
import { faClone } from '@fortawesome/pro-light-svg-icons/faClone';
import { faExpand } from '@fortawesome/pro-light-svg-icons/faExpand';
import { faGripDots } from '@fortawesome/pro-light-svg-icons/faGripDots';
import { faClose } from '@fortawesome/pro-light-svg-icons/faClose';
import { faCirclePause } from '@fortawesome/pro-light-svg-icons/faCirclePause';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp';
import { faStar } from '@fortawesome/pro-light-svg-icons/faStar';
import { faDiagramProject } from '@fortawesome/pro-light-svg-icons/faDiagramProject';
import { faLifeRing } from '@fortawesome/pro-light-svg-icons/faLifeRing';
import { faMagnifyingGlassMinus } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlassMinus';
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faSquareEnvelope } from '@fortawesome/pro-light-svg-icons/faSquareEnvelope';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faPeopleGroup } from '@fortawesome/pro-light-svg-icons/faPeopleGroup';
import { faBitbucket } from '@fortawesome/free-brands-svg-icons/faBitbucket';
import { faHeart } from '@fortawesome/pro-light-svg-icons/faHeart';
import { faMedium } from '@fortawesome/free-brands-svg-icons/faMedium';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faBellOn } from '@fortawesome/pro-light-svg-icons/faBellOn';
import { faRobot } from '@fortawesome/pro-light-svg-icons/faRobot';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons/faCircleInfo';
import { faHouseBlank } from '@fortawesome/pro-light-svg-icons/faHouseBlank';
import { faRectangleVerticalHistory } from '@fortawesome/pro-light-svg-icons/faRectangleVerticalHistory';
import { faFlask } from '@fortawesome/pro-light-svg-icons/faFlask';
import { faChartColumn } from '@fortawesome/pro-light-svg-icons/faChartColumn';
import { faDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase';
import { faImage } from '@fortawesome/pro-light-svg-icons/faImage';
import { faCodepen } from '@fortawesome/free-brands-svg-icons/faCodepen';
import { faTableCellsLarge } from '@fortawesome/pro-light-svg-icons/faTableCellsLarge';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faUnlock } from '@fortawesome/pro-light-svg-icons/faUnlock';
import { faShieldKeyhole } from '@fortawesome/pro-light-svg-icons/faShieldKeyhole';
import { faGripDotsVertical } from '@fortawesome/pro-light-svg-icons/faGripDotsVertical';
import { faEllipsisVertical } from '@fortawesome/pro-light-svg-icons/faEllipsisVertical';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { faArrowUpRight } from '@fortawesome/pro-light-svg-icons/faArrowUpRight';
import { faCircleNodes } from '@fortawesome/pro-light-svg-icons/faCircleNodes';
import { faDocker } from '@fortawesome/free-brands-svg-icons/faDocker';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare';
import { faBarcodeRead } from '@fortawesome/pro-light-svg-icons/faBarcodeRead';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';
import { faLoader } from '@fortawesome/pro-light-svg-icons/faLoader';
import { faBoxTaped } from '@fortawesome/pro-light-svg-icons/faBoxTaped';
import { faPython } from '@fortawesome/free-brands-svg-icons/faPython';
import { faFileCode } from '@fortawesome/pro-light-svg-icons/faFileCode';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faArrowsFromLine } from '@fortawesome/pro-light-svg-icons/faArrowsFromLine';
import { faArrowsToLine } from '@fortawesome/pro-light-svg-icons/faArrowsToLine';
import { faObjectGroup } from '@fortawesome/pro-light-svg-icons/faObjectGroup';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faGrid2 } from '@fortawesome/pro-light-svg-icons/faGrid2';
import { faListUl } from '@fortawesome/pro-light-svg-icons/faListUl';
import { faObjectExclude } from '@fortawesome/pro-light-svg-icons/faObjectExclude';
import { faBullseyeArrow } from '@fortawesome/pro-light-svg-icons/faBullseyeArrow';
import { faCompassDrafting } from '@fortawesome/pro-light-svg-icons/faCompassDrafting';
import { faRadar } from '@fortawesome/pro-light-svg-icons/faRadar';
import { faDiagramVenn } from '@fortawesome/pro-light-svg-icons/faDiagramVenn';
import { faSensorTriangleExclamation } from '@fortawesome/pro-light-svg-icons/faSensorTriangleExclamation';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faSlack } from '@fortawesome/free-brands-svg-icons/faSlack';
import { faSun } from '@fortawesome/pro-light-svg-icons/faSun';
import { faMoonCloud } from '@fortawesome/pro-light-svg-icons/faMoonCloud';
import { faArrowsUpDown } from '@fortawesome/pro-light-svg-icons/faArrowsUpDown';
import { faLanguage } from '@fortawesome/pro-light-svg-icons/faLanguage';
import { faChartScatter } from '@fortawesome/pro-light-svg-icons/faChartScatter';
import { faCloud } from '@fortawesome/pro-light-svg-icons/faCloud';
import { faLaptop } from '@fortawesome/pro-light-svg-icons/faLaptop';
import { faBooks } from '@fortawesome/pro-light-svg-icons/faBooks';
import { faFileArrowUp } from '@fortawesome/pro-light-svg-icons/faFileArrowUp';
import { faClipboard } from '@fortawesome/pro-light-svg-icons/faClipboard';
import { faChartPyramid } from '@fortawesome/pro-light-svg-icons/faChartPyramid';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faLockKeyhole } from '@fortawesome/pro-light-svg-icons/faLockKeyhole';
import { faSquareCode } from '@fortawesome/pro-light-svg-icons/faSquareCode';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faShieldExclamation } from '@fortawesome/pro-light-svg-icons/faShieldExclamation';
import { faChartLineUp } from '@fortawesome/pro-light-svg-icons/faChartLineUp';
import { faCode } from '@fortawesome/pro-light-svg-icons/faCode';
import { faDiagramSubtask } from '@fortawesome/pro-light-svg-icons/faDiagramSubtask';
import { faDiamondExclamation } from '@fortawesome/pro-light-svg-icons/faDiamondExclamation';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faSitemap } from '@fortawesome/pro-light-svg-icons/faSitemap';
import { faChartMixed } from '@fortawesome/pro-light-svg-icons/faChartMixed';
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faCodeCompare } from '@fortawesome/pro-light-svg-icons/faCodeCompare';
import { faUserTag } from '@fortawesome/pro-light-svg-icons/faUserTag';
import { faNetworkWired } from '@fortawesome/pro-light-svg-icons/faNetworkWired';
import { faChartSimpleHorizontal } from '@fortawesome/pro-light-svg-icons/faChartSimpleHorizontal';
import { faFlag } from '@fortawesome/pro-light-svg-icons/faFlag';
import { faCodeBranch } from '@fortawesome/pro-light-svg-icons/faCodeBranch';
import { faUserShield } from '@fortawesome/pro-light-svg-icons/faUserShield';
import { faCoins } from '@fortawesome/pro-light-svg-icons/faCoins';
import { faCalendarDays } from '@fortawesome/pro-light-svg-icons/faCalendarDays';
import { faTimeline } from '@fortawesome/pro-light-svg-icons/faTimeline';
import { faChartGantt } from '@fortawesome/pro-light-svg-icons/faChartGantt';
import { faFlagPennant } from '@fortawesome/pro-light-svg-icons/faFlagPennant';
import { faCodeFork } from '@fortawesome/pro-light-svg-icons/faCodeFork';
import { faUserGear } from '@fortawesome/pro-light-svg-icons/faUserGear';
import { faCircleUser } from '@fortawesome/pro-light-svg-icons/faCircleUser';
import { faTag } from '@fortawesome/pro-light-svg-icons/faTag';
import { faSliders } from '@fortawesome/pro-light-svg-icons/faSliders';
import { faClockThree } from '@fortawesome/pro-light-svg-icons/faClockThree';
import { faNoteSticky } from '@fortawesome/pro-light-svg-icons/faNoteSticky';
import { faBriefcaseBlank } from '@fortawesome/pro-light-svg-icons/faBriefcaseBlank';
import { faToggleOff } from '@fortawesome/pro-light-svg-icons/faToggleOff';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip';
import { faSlidersSimple } from '@fortawesome/pro-light-svg-icons/faSlidersSimple';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { faBookOpenCover } from '@fortawesome/pro-light-svg-icons/faBookOpenCover';
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons/faFolderOpen';
import { faBarsProgress } from '@fortawesome/pro-light-svg-icons/faBarsProgress';
import { faSparkles } from '@fortawesome/pro-light-svg-icons/faSparkles';
import { faShapes } from '@fortawesome/pro-light-svg-icons/faShapes';
import { faDrawCircle } from '@fortawesome/pro-light-svg-icons/faDrawCircle';
import { faRouter } from '@fortawesome/pro-light-svg-icons/faRouter';
import { faTriangleExclamation as solidTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation';
import { faCircleInfo as solidFaCircleInfo } from '@fortawesome/pro-solid-svg-icons/faCircleInfo';
import { faCircleCheck as solidFaCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faCircleXmark as solidFaCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark';
import { faCirclePlus as solidFaCirclePlus } from '@fortawesome/pro-solid-svg-icons/faCirclePlus';
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay';
import { faFileLines } from '@fortawesome/pro-light-svg-icons/faFileLines';
import { faFileLines as regularFaFileLines } from '@fortawesome/pro-regular-svg-icons/faFileLines';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faBox } from '@fortawesome/pro-light-svg-icons/faBox';
import { faBoxesStacked } from '@fortawesome/pro-light-svg-icons/faBoxesStacked';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faFileShield } from '@fortawesome/pro-light-svg-icons/faFileShield';
import { faArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowDown';
import { faArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp';
import { faArrowTurnDownLeft } from '@fortawesome/pro-light-svg-icons/faArrowTurnDownLeft';
import { faArrowTurnDownRight } from '@fortawesome/pro-light-svg-icons/faArrowTurnDownRight';
import { faBold } from '@fortawesome/pro-light-svg-icons/faBold';
import { faBold as solidFaBolid } from '@fortawesome/pro-solid-svg-icons/faBold';
import { faItalic } from '@fortawesome/pro-light-svg-icons/faItalic';
import { faUnderline } from '@fortawesome/pro-light-svg-icons/faUnderline';
import { faStrikethrough } from '@fortawesome/pro-light-svg-icons/faStrikethrough';
import { faSubscript } from '@fortawesome/pro-light-svg-icons/faSubscript';
import { faSuperscript } from '@fortawesome/pro-light-svg-icons/faSuperscript';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { faAlignLeft } from '@fortawesome/pro-light-svg-icons/faAlignLeft';
import { faAlignCenter } from '@fortawesome/pro-light-svg-icons/faAlignCenter';
import { faAlignRight } from '@fortawesome/pro-light-svg-icons/faAlignRight';
import { faAlignJustify } from '@fortawesome/pro-light-svg-icons/faAlignJustify';
import { faOutdent } from '@fortawesome/pro-light-svg-icons/faOutdent';
import { faIndent } from '@fortawesome/pro-light-svg-icons/faIndent';
import { faFontCase } from '@fortawesome/pro-light-svg-icons/faFontCase';
import { faFillDrip } from '@fortawesome/pro-light-svg-icons/faFillDrip';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faFillDrip as solidFaFillDrip } from '@fortawesome/pro-solid-svg-icons/faFillDrip';
import { faFont as solidFaFont } from '@fortawesome/pro-solid-svg-icons/faFont';
import { faBookmark as solidBookmark } from '@fortawesome/pro-solid-svg-icons/faBookmark';
import { faFont } from '@fortawesome/pro-light-svg-icons/faFont';
import { faCrystalBall } from '@fortawesome/pro-light-svg-icons/faCrystalBall';
import { faMicrochip } from '@fortawesome/pro-light-svg-icons/faMicrochip';
import { faFaceSmilingHands } from '@fortawesome/pro-light-svg-icons/faFaceSmilingHands';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
import { faRectangleVerticalHistory as regularRectangleVertical } from '@fortawesome/pro-regular-svg-icons/faRectangleVerticalHistory';
import { faArrowUpRightDots } from '@fortawesome/pro-light-svg-icons/faArrowUpRightDots';
import { faPiggyBank } from '@fortawesome/pro-light-svg-icons/faPiggyBank';
import { faConveyorBelt } from '@fortawesome/pro-regular-svg-icons/faConveyorBelt';
import { faHexagonExclamation } from '@fortawesome/pro-regular-svg-icons/faHexagonExclamation';
import { faCircleArrowDown } from '@fortawesome/pro-light-svg-icons/faCircleArrowDown';
import { faTrianglePersonDigging } from '@fortawesome/pro-light-svg-icons/faTrianglePersonDigging';
import { faFileCsv } from '@fortawesome/pro-light-svg-icons/faFileCsv';
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload';
import { faMicroscope } from '@fortawesome/pro-light-svg-icons/faMicroscope';
import { faTableRows } from '@fortawesome/pro-light-svg-icons/faTableRows';
import { faLightbulbOn } from '@fortawesome/pro-solid-svg-icons/faLightbulbOn';
import { faCauldron } from '@fortawesome/pro-solid-svg-icons/faCauldron';
import { faTrophy } from '@fortawesome/pro-light-svg-icons/faTrophy';
import { faTrophyStar } from '@fortawesome/pro-light-svg-icons/faTrophyStar';
import { faLoader as solidFaLoader } from '@fortawesome/pro-solid-svg-icons/faLoader';
import { faPartyHorn } from '@fortawesome/pro-solid-svg-icons/faPartyHorn';
import { faAbacus } from '@fortawesome/pro-light-svg-icons/faAbacus';
import { faThumbsUp } from '@fortawesome/pro-light-svg-icons/faThumbsUp';
import { faThumbsUp as solidFaThumbsUp } from '@fortawesome/pro-solid-svg-icons/faThumbsUp';
import { faOctagonCheck } from '@fortawesome/pro-solid-svg-icons/faOctagonCheck';
import { faMessageDots } from '@fortawesome/pro-light-svg-icons/faMessageDots';
import { faMessageLines } from '@fortawesome/pro-light-svg-icons/faMessageLines';
import { faMessageText } from '@fortawesome/pro-light-svg-icons/faMessageText';
import { faList } from '@fortawesome/pro-light-svg-icons/faList';
import { faCircleMinus as solidFaCircleMinus } from '@fortawesome/pro-solid-svg-icons/faCircleMinus';
import { faArrowRight as faArrowRightSolid } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faWandMagicSparkles } from '@fortawesome/pro-regular-svg-icons/faWandMagicSparkles';

import { OmitStrict } from 'shared/utils/types';

import { JsonIcon, JupyterNotebookIcon, KafkaIcon } from './customIcons';

export type IconsProps = OmitStrict<typeof ICONS, 'dataType'> & {
  dataType: {
    UNKNOWN: IconDefinition;
    OTHER: IconDefinition;
    AUDIO: IconDefinition;
    IMAGE: IconDefinition;
    TABULAR: IconDefinition;
    TEXT: IconDefinition;
    VIDEO: IconDefinition;
  };
};

export const ICONS = {
  crystalBall: faCrystalBall,
  clipboard: faClipboard,
  envelope: faEnvelope,
  chartPyramid: faChartPyramid,
  listCheck: faListCheck,
  globe: faGlobe,
  file: faFile,
  briefcaseBlank: faBriefcaseBlank,
  lockKeyhole: faLockKeyhole,
  squareCode: faSquareCode,
  users: faUsers,
  shieldExclamation: faShieldExclamation,
  noteSticky: faNoteSticky,
  chartLineUp: faChartLineUp,
  bookmark: faBookmark,
  code: faCode,
  diagramSubtask: faDiagramSubtask,
  diamondExclamation: faDiamondExclamation,
  phone: faPhone,
  sitemap: faSitemap,
  chartMixed: faChartMixed,
  key: faKey,
  codeCompare: faCodeCompare,
  userTag: faUserTag,
  bookOpenCover: faBookOpenCover,
  calendar: faCalendar,
  networkWired: faNetworkWired,
  chartSimpleHorizontal: faChartSimpleHorizontal,
  flag: faFlag,
  codeBranch: faCodeBranch,
  userShield: faUserShield,
  coins: faCoins,
  calendarDays: faCalendarDays,
  timeline: faTimeline,
  chartGantt: faChartGantt,
  flagPennant: faFlagPennant,
  codeFork: faCodeFork,
  userGear: faUserGear,
  magnifyingGlassChart: faMagnifyingGlassChart,
  bell: faBell,
  circleNodes: faCircleNodes,
  circleUser: faCircleUser,
  tag: faTag,
  sliders: faSliders,
  clockThree: faClockThree,
  toggleOff: faToggleOff,
  eye: faEye,
  paperclip: faPaperclip,
  slidersSimple: faSlidersSimple,
  star: faStar,
  layerGroup: faLayerGroup,
  diagramProject: faDiagramProject,
  bullseyeArrow: faBullseyeArrow,
  folderOpen: faFolderOpen,
  barsProgress: faBarsProgress,
  sparkles: faSparkles,
  shapes: faShapes,
  drawCircle: faDrawCircle,
  router: faRouter,

  cube: faCube,
  youtube: faYoutube,
  slack: faSlack,
  project: faFolders,
  registry: faListCheck,
  endpoints: faRocket,
  rectangleList: faRectangleList,
  monitoring: faChartWaterfall,
  circleQuestion: faCircleQuestion,
  mail: faEnvelope,
  xMark: faXmark,
  circleXmark: faCircleXmark,
  circleNotch: faCircleNotch,
  user: faUser,
  clock: faClock,
  server: faServer,
  magnifyingGlass: faMagnifyingGlass,
  group: faPeopleGroup,
  arrowDownToBracket: faArrowDownToBracket,
  emptyTiles: faGrid2Plus,
  arrowUpRightFromSquare: faArrowUpRightFromSquare,
  arrowRight: faArrowRight,
  arrowLeft: faArrowLeft,
  arrowRotateRight: faArrowRotateRight,
  arrowsRotate: faArrowsRotate,
  bookOpen: faBookOpen,
  circleDashed: faCircleDashed,
  circleMinus: faCircleMinus,
  circlePlus: faCirclePlus,
  circleCheck: faCircleCheck,
  circleExclamation: faCircleExclamation,
  circlePause: faCirclePause,
  circleInfo: faCircleInfo,
  shieldCheck: faShieldCheck,
  chartPie: faChartPie,
  dataType: {
    UNKNOWN: faChartSimple,
    OTHER: faChartSimple,
    AUDIO: faWaveformLines,
    IMAGE: faImage,
    TABULAR: faTableList,
    TEXT: faTextSize,
    VIDEO: faVideo,
  },
  pencil: faPen,
  gear: faGear,
  trashCan: faTrashCan,
  layerPlus: faLayerPlus,
  filter: faFilter,
  chevronRight: faChevronRight,
  chevronLeft: faChevronLeft,
  chevronDown: faChevronDown,
  chartPieSimple: faChartPieSimple,
  plus: faPlus,
  check: faCheck,
  x: faX,
  codeCommit: faCodeCommit,
  shareNodes: faShareNodes,
  comment: faComment,
  triangleExclamation: faTriangleExclamation,
  spinner: faSpinner,
  lock: faLock,
  userLock: faUserLock,
  arrowRotateLeft: faArrowRotateLeft,
  clone: faClone,
  expand: faExpand,
  gripDots: faGripDots,
  close: faClose,
  github: faGithub,
  angleDown: faAngleDown,
  angleUp: faAngleUp,
  angleRight: faAngleRight,
  angleLeft: faAngleLeft,
  lifeRing: faLifeRing,
  magnifyingGlassMinus: faMagnifyingGlassMinus,
  copy: faCopy,
  squareEnvelope: faSquareEnvelope,
  google: faGoogle,
  bitbucket: faBitbucket,
  heart: faHeart,
  medium: faMedium,
  twitter: faTwitter,
  linkedin: faLinkedin,
  bellOn: faBellOn,
  robot: faRobot,
  houseBlank: faHouseBlank,
  rectangleVerticalHistory: faRectangleVerticalHistory,
  flask: faFlask,
  chartColumn: faChartColumn,
  database: faDatabase,
  image: faImage,
  codepen: faCodepen,
  tableCellsLarge: faTableCellsLarge,
  eyeSlash: faEyeSlash,
  unlock: faUnlock,
  shieldKeyhole: faShieldKeyhole,
  gripDotsVertical: faGripDotsVertical,
  ellipsisVertical: faEllipsisVertical,
  arrowUpRight: faArrowUpRight,
  docker: faDocker,
  barcodeRead: faBarcodeRead,
  spinnerThird: faSpinnerThird,
  loader: faLoader,
  boxTaped: faBoxTaped,
  box: faBox,
  boxesStacked: faBoxesStacked,
  python: faPython,
  json: JsonIcon,
  jupyterNotebook: JupyterNotebookIcon,
  fileCode: faFileCode,
  arrowsFromLine: faArrowsFromLine,
  arrowsToLine: faArrowsToLine,
  objectGroup: faObjectGroup,
  grid2: faGrid2,
  listUl: faListUl,
  objectExclude: faObjectExclude,
  compassDrafting: faCompassDrafting,
  radar: faRadar,
  diagramVenn: faDiagramVenn,
  sensorTriangleExclamation: faSensorTriangleExclamation,
  sun: faSun,
  moonCloud: faMoonCloud,
  arrowsUpDown: faArrowsUpDown,
  language: faLanguage,
  chartScatter: faChartScatter,
  cloud: faCloud,
  laptop: faLaptop,
  books: faBooks,
  play: faPlay,
  fileLines: faFileLines,
  regularFileLines: regularFaFileLines,
  fileArrowUp: faFileArrowUp,
  book: faBook,
  link: faLink,
  kafka: KafkaIcon,
  fileShield: faFileShield,
  arrowDown: faArrowDown,
  arrowUp: faArrowUp,
  turnArrowDownLeft: faArrowTurnDownLeft,
  turnArrowDownRight: faArrowTurnDownRight,
  bold: faBold,
  italic: faItalic,
  underline: faUnderline,
  strikethrough: faStrikethrough,
  subscript: faSubscript,
  superscript: faSuperscript,
  minus: faMinus,
  alignLeft: faAlignLeft,
  alignCenter: faAlignCenter,
  alignRight: faAlignRight,
  alignJustify: faAlignJustify,
  outdent: faOutdent,
  indent: faIndent,
  fontCase: faFontCase,
  fillDrip: faFillDrip,
  font: faFont,
  microchip: faMicrochip,
  faceSmilingHands: faFaceSmilingHands,
  briefcase: faBriefcase,
  regularRectangleVertical: regularRectangleVertical,
  arrowUpRightDots: faArrowUpRightDots,
  piggyBank: faPiggyBank,
  conveyorBelt: faConveyorBelt,
  hexagonExclamation: faHexagonExclamation,
  circleArrowDown: faCircleArrowDown,
  trianglePersonDigging: faTrianglePersonDigging,
  fileCsv: faFileCsv,
  upload: faUpload,
  microscope: faMicroscope,
  tableRows: faTableRows,
  trophy: faTrophy,
  trophyStar: faTrophyStar,
  abacus: faAbacus,
  thumbsUp: faThumbsUp,
  messageDots: faMessageDots,
  messageLines: faMessageLines,
  messageText: faMessageText,
  list: faList,
  wandMagicSparkles: faWandMagicSparkles,
};

export const ICONS_SOLID = {
  triangleExclamation: solidTriangleExclamation,
  circleInfo: solidFaCircleInfo,
  circleCheck: solidFaCircleCheck,
  circleXmark: solidFaCircleXmark,
  circlePlus: solidFaCirclePlus,
  circleMinus: solidFaCircleMinus,
  bold: solidFaBolid,
  fillDrip: solidFaFillDrip,
  font: solidFaFont,
  faBookmark: solidBookmark,
  xMark: solidXmark,
  lightbulb: faLightbulbOn,
  arrowDownToBracket: solidArrowDownToBracket,
  cauldron: faCauldron,
  loader: solidFaLoader,
  partyHorn: faPartyHorn,
  thumbsUp: solidFaThumbsUp,
  octagonCheck: faOctagonCheck,
  arrowRight: faArrowRightSolid,
};
