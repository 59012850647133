import { PredicateOperator } from 'generated/types';
import matchType from 'shared/utils/matchType';

export const getLabelByPredicateOperator = (operator: PredicateOperator) => {
  return matchType(
    {
      CONTAIN: () => 'contains',
      EQ: () => '=',
      GT: () => '>',
      GTE: () => '>=',
      IN: () => 'in',
      LT: () => '<',
      LTE: () => '<=',
      NE: () => '!=',
      NOT_CONTAIN: () => 'not contains',
      NOT_IN: () => 'not in',
    },
    operator
  );
};
