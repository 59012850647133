import { styled } from '@mui/material/styles';
import React from 'react';

type ILocalProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  innerHeight: number;
  innerWidth: number;
  id: string;
  fill: string;
  padding: number;
} & Required<
  Pick<React.SVGProps<SVGRectElement>, 'onMouseLeave' | 'onMouseMove'>
>;

const getRadiusByWidth = (width: number) => width / 10;

const CustomBarView = (props: ILocalProps) => {
  const barWidth = (1 - props.padding) * props.width;
  const barXOffset = (props.padding / 2) * props.width;
  const barRadius = getRadiusByWidth(props.width);

  const clipPathId = `round-corner-${props.id}`;

  return (
    <g>
      <HoverRect
        x={props.x}
        width={props.width}
        height={props.innerHeight}
        onMouseMove={props.onMouseMove}
        onMouseLeave={props.onMouseLeave}
        fill="transparent"
      />
      <rect
        style={{ pointerEvents: 'none' }}
        fill={props.fill}
        clipPath={`url(#${clipPathId})`}
        height={props.height}
        width={barWidth}
        x={props.x + barXOffset}
        y={props.y}
      />
      <clipPath id={clipPathId}>
        <rect
          x={props.x + barXOffset}
          y={props.y}
          width={barWidth}
          height={barRadius + props.height}
          rx={barRadius}
          ry={barRadius}
        />
      </clipPath>
    </g>
  );
};

const HoverRect = styled('rect')(({ theme }) => ({
  '&:hover': {
    fill: theme.palette.charts.accentColor,
  },
}));

export default CustomBarView;
