import { ApolloClient } from '@apollo/client';
import {
  Action,
  AnyAction,
  combineReducers,
  Dispatch,
  Store,
  Middleware,
} from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createBrowserRouter } from 'react-router-dom';

import * as ErrorReporter from 'features/errorReporter';
import * as Comments from 'features/comments';
import * as BuildErrorTracker from 'features/deployment/canary/buildErrorTracker';
import * as DeployedExperimentRunPlayground from 'features/deployment/deployedExperimentRunPlayground';
import * as Flags from 'features/flags';
import * as HighLevelSearch from 'features/highLevelSearch';
import * as User from 'features/user';
import * as SystemAdmins from 'features/systemAdmin/isAdmin/store';
import * as Workspaces from 'features/workspaces';
import * as DarkMode from 'features/darkMode';
import * as Toast from 'features/toast/store';
import { restrictedGraphqlErrorReducer } from 'features/restrictedGraphqlError/reducer';
import { RestrictedGraphqlErrorState } from 'features/restrictedGraphqlError/types';

export interface IApplicationState {
  comments: Comments.ICommentsState;
  deployedExperimentRunPlayground: DeployedExperimentRunPlayground.types.IDeployedExperimentRunPlaygroundState;
  user: User.IUserState;
  flags: Flags.IFlagsState;
  workspaces: Workspaces.IWorkspaces;
  highLevelSearch: HighLevelSearch.types.IHighLevelSearchState;
  buildErrorTracker: BuildErrorTracker.types.IBuildErrorTrackerState;
  darkMode: DarkMode.DarkModeState;
  errorReporter: ErrorReporter.ErrorReporterState;
  systemAdmins: SystemAdmins.ISystemAdmins;
  graphqlError: RestrictedGraphqlErrorState;
  toast: Toast.types.ToastState;
}

export const createRootReducer = () =>
  combineReducers<IApplicationState>({
    errorReporter: ErrorReporter.reducer,
    deployedExperimentRunPlayground: DeployedExperimentRunPlayground.reducer,
    comments: Comments.reducer,
    user: User.reducer,
    flags: Flags.reducer,
    workspaces: Workspaces.reducer,
    highLevelSearch: HighLevelSearch.reducer,
    buildErrorTracker: BuildErrorTracker.reducer,
    darkMode: DarkMode.reducer,
    systemAdmins: SystemAdmins.reducer,
    graphqlError: restrictedGraphqlErrorReducer,
    toast: Toast.reducer,
  });

export interface IThunkActionDependencies {
  apolloClient: ApolloClient<unknown>;
  router: ReturnType<typeof createBrowserRouter>;
}

export type ActionResult<R = void, A extends Action = AnyAction> = ThunkAction<
  R,
  IApplicationState,
  IThunkActionDependencies,
  A
>;
export type ThunkArgs = {
  dispatch: Dispatch<AnyAction>;
  getState: () => IApplicationState;
  deps: IThunkActionDependencies;
};

export type ConfigureStore = (deps: {
  initialState: Partial<IApplicationState> | undefined;
  extraMiddlewares: Middleware[];
  apolloClient: ApolloClient<unknown>;
  router: ReturnType<typeof createBrowserRouter>;
}) => Store<IApplicationState>;
