/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { DataWithPagination } from 'shared/models/Pagination';
import { RegisteredModelResult } from 'shared/models/HighLevelSearch/SearchResult';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { convertSortingToGraphql } from 'shared/graphql/sorting/converters';
import { changeSortingDateFieldName } from 'shared/models/Sorting';
import {
  isRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';

import * as GraphqlTypes from './graphql-types/loadRegisteredModelResults.generated';
import convertHighLevelSearchFilterToGraphql from '../shared/converters';
import { IHighLevelGraphqlSearchSettings } from '../shared/types';

const REGISTERED_MODEL_RESULTS = gql`
  query RegisteredModelResult(
    $workspaceName: String!
    $organizationId: ID
    $query: RegisteredModelsQuery
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        registeredModels(query: $query) {
          models {
            name
            id
            dateCreated
            dateUpdated
            labels
            owner {
              ...OwnerData
            }
            workspace {
              id
              ...WorkspaceData
            }
          }
          pagination {
            totalRecords
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${OWNER_FRAGMENT}
`;

export const loadRegisteredModelResults = async ({
  apolloClient,
  filter,
  pagination,
  organizationId,
  sorting,
  workspaceName,
}: IHighLevelGraphqlSearchSettings): Promise<
  DataWithPagination<RegisteredModelResult>
> => {
  const res = await apolloClient.query<
    GraphqlTypes.RegisteredModelResult,
    GraphqlTypes.RegisteredModelResultVariables
  >({
    query: REGISTERED_MODEL_RESULTS,
    fetchPolicy: 'no-cache',
    variables: {
      workspaceName,
      organizationId,
      query: {
        stringPredicates: [
          convertHighLevelSearchFilterToGraphql(filter, {
            labelKey: 'label',
          }),
        ],
        sorting: sorting
          ? convertSortingToGraphql(
              changeSortingDateFieldName(
                { date_created: 'time_created', date_updated: 'time_updated' },
                sorting
              )
            )
          : undefined,
        pagination: {
          limit: pagination.pageSize,
          page: pagination.currentPage,
        },
      },
    },
  });
  if (
    isRestrictedGraphqlError(res.data.workspace) ||
    (res.errors || []).length > 0
  ) {
    return { totalCount: 0, data: [] };
  }
  return {
    data: res.data.workspace.registeredModels.models.map((model) => ({
      ...model,
      dateCreated: new Date(model.dateCreated),
      dateUpdated: new Date(model.dateUpdated),
      owner: model.owner,
      entityType: 'registeredModels',
    })),
    totalCount: res.data.workspace.registeredModels.pagination.totalRecords,
  };
};
