import {
  AlertStatus,
  AlertAggregationWindow,
  AlertEvaluationFrequency,
} from 'generated/types';

import { MonitoringIODescription } from '../MonitoringIODescription';
import { Alerter } from './Alerter';
import { AlertSettings } from './AlertSettings';

export interface MonitoringAlert {
  id: string;
  name: string;
  status: AlertStatus;
  featureName: string;
  dateCreated: Date;
  dateUpdated: Date;
  alerter: Alerter;
  evaluationFrequency: AlertEvaluationFrequency;
  aggregationWindow: AlertAggregationWindow;
  settings: AlertSettings;
}

export const findAlertIODescription = (props: {
  alert: Pick<MonitoringAlert, 'featureName'>;
  ioDescriptions: MonitoringIODescription[];
}) => {
  const [ioType, name] = props.alert.featureName.split('.');

  return props.ioDescriptions.find(
    (ioDescription) =>
      ioDescription.ioType.toLowerCase() === ioType &&
      ioDescription.name === name
  );
};
