import {
  Box,
  CardActionArea,
  Card,
  CardContent,
  Stack,
  Typography,
  Grid,
  styled,
} from '@mui/material';

import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { useRegisteringModelContext } from 'features/homepage/hooks/useRegisteringModel';

import { CardTags } from './CardsTags';

import { LibraryType, LocationType, SourceType, TaskType } from '.';

const StyledCardContent = styled(CardContent)<{ selected: boolean }>(
  ({ selected }) => ({
    padding: selected ? '14px 10px 10px 14px' : '16px 12px 12px 16px',
    height: '100%',
  })
);

const StyledCard = styled(Card)<{ selected: boolean }>(
  ({ selected, theme }) => ({
    height: '167px',
    border: selected ? `2px solid ${theme.palette.primary.dark}` : '',
    backgroundColor: selected ? theme.palette.action.hover : '',
  })
);

const TaskIcon = ({ taskType }: { taskType: TaskType }) => {
  switch (taskType) {
    case TaskType.COMPUTER_VISION:
      return <IconAwesomeCustomizable icon={ICONS.image} />;
    case TaskType.MISCELLANEOUS:
      return <IconAwesomeCustomizable icon={ICONS.diagramProject} />;
    case TaskType.NLP:
      return <IconAwesomeCustomizable icon={ICONS.language} />;
    case TaskType.TABULAR_CLASSIFICATION:
      return <IconAwesomeCustomizable icon={ICONS.dataType.TABULAR} />;
    case TaskType.REGRESSION:
      return <IconAwesomeCustomizable icon={ICONS.chartScatter} />;
    default:
      return <IconAwesomeCustomizable icon={ICONS.chartScatter} />;
  }
};

export type CardProps = {
  id: number | undefined;
  modelName: string;
  task: TaskType;
  description: string;
  link?: string;
  source?: SourceType;
  library: LibraryType;
  location: LocationType;
};

const CardModel = ({
  card,
  selectCard,
  cardSelected,
}: {
  card: CardProps;
  selectCard: (card: CardProps) => void;
  cardSelected: CardProps | undefined;
}) => {
  const { task, modelName, description, library, source, location, link, id } =
    card;

  return (
    <Grid item xs={6} mt={2} pl={2}>
      <StyledCard selected={cardSelected?.id === id}>
        <CardActionArea
          onClick={() => selectCard(card)}
          onAuxClick={() => selectCard(card)}
          disableRipple
          sx={{ height: '100%' }}
        >
          <StyledCardContent selected={cardSelected?.id === id}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ color: 'text.secondary' }}
            >
              <TaskIcon taskType={task} />
              <Typography variant="overline">{task}</Typography>
            </Stack>
            {link ? (
              <Box display="flex">
                <InlineLink
                  to={link}
                  variant="subtitle2"
                  simple
                  isExternal
                  withHover
                  withoutArrow
                >
                  <Stack
                    direction="row"
                    spacing={0.5}
                    alignItems="center"
                    width="fit-content"
                  >
                    <IconAwesomeCustomizable icon={ICONS.link} />
                    <Box>{modelName}</Box>
                  </Stack>
                </InlineLink>
              </Box>
            ) : (
              <Typography variant="subtitle2" color="primary.main">
                {modelName}
              </Typography>
            )}
            <CardTags library={library} source={source} location={location} />
            <Typography variant="body2" mt={1}>
              {description}
            </Typography>
          </StyledCardContent>
        </CardActionArea>
      </StyledCard>
    </Grid>
  );
};

export const CardsModel = ({ cards }: { cards: Array<CardProps> }) => {
  const { selectCard, cardSelected } = useRegisteringModelContext();
  return (
    <>
      {cards.map((card: CardProps) => (
        <CardModel
          card={card}
          selectCard={selectCard}
          cardSelected={cardSelected}
          key={card.id}
        />
      ))}
    </>
  );
};
