import { useCallback, useEffect } from 'react';

import { isGraphqlNotFoundError } from 'shared/models/Error';
import { ICommunication } from 'shared/utils/redux/communication';
import { useToastCommunicationError as useToastCommunicationErrorAction } from 'features/toast/store/hooks';
import { GraphqlErrorContext } from 'setup/apollo/createRestrictedGraphqlErrorLink';

export const useToastCommunicationErrorIgnoringNotFound = () => {
  const toastCommunicationError = useToastCommunicationErrorAction();

  return useCallback(
    (...args: Parameters<typeof toastCommunicationError>) => {
      const [communicationError, options] = args;
      if (isGraphqlNotFoundError(communicationError)) {
        return null;
      }
      return toastCommunicationError(communicationError, options);
    },
    [toastCommunicationError]
  );
};

export const useToastCommunicationErrorWatcher = (
  communication: ICommunication,
  options: GraphqlErrorContext
) => {
  const toastCommunicationError = useToastCommunicationErrorAction();
  useEffect(() => {
    if (communication.error && !options.hideToastError) {
      toastCommunicationError(communication.error, options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communication.isRequesting, options.hideToastError]);
};
