import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import * as actions from '../actions';
import { ISystemAdmins } from '../types';

const initial: ISystemAdmins['data'] = {
  isSystemAdmin: false,
};

const dataReducer: Reducer<
  ISystemAdmins['data'],
  ActionType<typeof actions>
> = (state = initial, action) => {
  switch (action.type) {
    case getType(actions.setIsSystemAdmin):
      return {
        ...state,
        isSystemAdmin: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
