import { difference, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { MLFlowJobResult } from 'features/catalog/importModels/importedModels/hooks/useGetAllJobs';
import { useToastCommunicationErrorWatcher } from 'shared/view/elements/Notification/Notification';
import usePollingQuery from 'shared/view/hooks/apollo/usePollingQuery';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { useToast } from 'features/toast/store/hooks';
import routes from 'shared/routes';

import { JOB_STATUS } from '../graphql';
import {
  JobsStatus,
  JobsStatusVariables,
} from '../graphql/graphql-types/queries.generated';

interface Props {
  workspaceId: string;
  workspaceName: string;
  organizationId: string | undefined;
}

const POLL_INTERVAL = 15000;
export const useJobStatusPolling = ({
  workspaceId,
  workspaceName,
  organizationId,
}: Props) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [runningJobs, setRunningJobs] = useState<string[]>([]);

  const results = usePollingQuery<JobsStatus, JobsStatusVariables>(JOB_STATUS, {
    pollInterval: POLL_INTERVAL,
    variables: {
      workspaceId: workspaceId,
      orgId: organizationId ?? '',
    },
  });

  const { communication, data } = resultToCommunicationWithData((res) => {
    if (isNotNullableRestrictedGraphqlError(res)) {
      return res;
    }
  }, results);

  const toastAction = (label: string) => ({
    children: label,
    isLoading: false,
    onClick: () =>
      navigate(routes.importedModels.getRedirectPath({ workspaceName })),
  });

  const createToast = (completedJobs: JobsStatus['jobs']) => {
    completedJobs.forEach((job) => {
      try {
        const { error_message, models, total_models } = JSON.parse(
          job.resultJson
        ) as MLFlowJobResult;
        if (error_message) {
          toast(
            <span>
              <strong>Model import failed</strong> due to {error_message}.
            </span>,
            'error',
            {
              title: 'Error',
              persist: true,
              actions: [toastAction('Go to Model import page')],
            }
          );
        } else {
          const failedModels = models.filter((model) => !model.imported);
          const failedModelsCount = failedModels.length;
          const successModelsCount = total_models - failedModelsCount;

          toast(
            <span>
              Model import is completed.
              {successModelsCount ? (
                <>
                  <strong>{successModelsCount} models and its versions</strong>{' '}
                  were successfully imported.{' '}
                </>
              ) : (
                ''
              )}
              {failedModelsCount ? (
                <>
                  <strong>{failedModelsCount} models failed to import</strong>
                </>
              ) : (
                ''
              )}
              . Go to <u>Imported Models page</u> to check the details.
            </span>,
            'info',
            {
              title: 'Info',
              persist: true,
              actions: [toastAction('Click here to check details')],
            }
          );
        }
      } catch {
        toast(
          <span>
            Model import is completed. Go to <u>Imported Models page</u> to
            check the details.
          </span>,
          'info',
          {
            title: 'Info',
            persist: true,
            actions: [toastAction('Click here to check details')],
          }
        );
      }
    });
  };

  useEffect(() => {
    if (data && !isEmpty(data.jobs)) {
      const incompleteJobs = data.jobs
        .filter((job) => job.completionTimestamp === 0)
        .map((job) => job.id);

      const completedIds = difference(runningJobs, incompleteJobs);
      createToast(data.jobs.filter((job) => completedIds.includes(job.id)));

      setRunningJobs(incompleteJobs);
      if (incompleteJobs.length === 0) {
        results.stopPolling();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useToastCommunicationErrorWatcher(communication, {
    context: 'loading job status',
  });

  const handleStartPolling = () => {
    results.stopPolling();

    results.startPolling(POLL_INTERVAL);
  };

  return {
    communication,
    runningJobs,
    startPolling: handleStartPolling,
    stopPolling: results.stopPolling,
  };
};
