import { FC } from 'react';

import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { IconAwesomeProps } from 'shared/view/elements/IconAwesome/IconAwesomeProps';
import { useIconAwesomeColorPalette } from 'shared/view/elements/IconAwesome/hooks/useIconAwesomeColorPalette';

import styles from './IconAwesomeDraggable.module.css';

interface Props extends IconAwesomeProps {
  reversedColors?: boolean;
}

// todo create folder 'draggable' (or similar) and move this file there
// todo after removing Icon components rename this one into IconDraggable
export const IconAwesomeDraggable: FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { colors } = useIconAwesomeColorPalette({
    reversedColors: props.reversedColors,
  });

  return (
    <span className={draggableHandleClassName}>
      <IconAwesomeCustomizable
        {...props}
        nativeProps={{ color: colors.clickable.active }}
      />
    </span>
  );
};

export const draggableHandleClassName = styles.draggableHandle;
