import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import routes from 'shared/routes';
import { validateNotEmpty } from 'shared/utils/validators';
import { validateDescription } from 'shared/models/Description';
import TagsField from 'shared/view/formComponents/formikFields/TagsFieldWithTopLabel/TagsFieldWithTopLabel';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import { WorkspaceData } from 'shared/graphql/Workspace/graphql-types/Workspace.generated';
import {
  getDefaultLockLevelForm,
  ILockLevelFormDeps,
} from 'shared/view/domain/Registry/ModelVersion/LockLevel/LockLevelForm/LockLevelFormModel';
import FeatureLockLevelSelect, {
  IVersionCreationForm,
} from 'features/registry/registeredModelVersion/shared/FeatureLockLevelSelect/FeatureLockLevelSelect';
import { selectFlags } from 'features/flags';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import FormHelperText from 'shared/view/elements/FormHelperText/FormHelperText';
import PopupForm from 'shared/view/formComponents/PopupForm';
import { PopupStateProps } from 'shared/view/elements/Popup/PopupButtons';

import { useCreateRegisteredModelVersion } from '../../store/createRegisteredModelVersion/createRegisteredModelVersion';

interface ILocalProps extends PopupStateProps {
  registeredModel: {
    id: string;
    name: string;
    allowedActions: {
      update: boolean;
    } & ILockLevelFormDeps['allowedActions'];
    workspace: WorkspaceData;
  };
  experimentRunId?: string;
}

const getFieldName = makeGetFieldName<IVersionCreationForm>();

const CreateRegisteredModelVersionPopup: FC<
  React.PropsWithChildren<ILocalProps>
> = ({ registeredModel, experimentRunId, ...popupStateProps }) => {
  const navigate = useNavigate();
  const { isEnableRegisteredModelVersionsLock } = useSelector(selectFlags);

  const initialSettings: IVersionCreationForm = useMemo(
    () => ({
      description: '',
      name: '',
      labels: [],
      experimentRunId: experimentRunId || '',
      ...getDefaultLockLevelForm(),
    }),
    [experimentRunId]
  );

  const { createVersion, creatingVersion } = useCreateRegisteredModelVersion({
    onSuccess: ({ versionId }) =>
      navigate(
        routes.registeredModelVersion.getRedirectPath({
          registeredModelId: registeredModel.id,
          versionId,
          workspaceName: registeredModel.workspace.name,
        })
      ),
  });

  const onSubmit = useCallback(
    (form: IVersionCreationForm) => {
      createVersion({ ...form, registeredModelId: registeredModel.id });
    },
    [createVersion, registeredModel.id]
  );

  return (
    <PopupForm
      initialValues={initialSettings}
      title="Create model version"
      communication={creatingVersion}
      onSubmit={onSubmit}
      {...popupStateProps}
    >
      {({ values, setFieldValue }) =>
        CreateRegisteredModelVersionFields({
          lockLevel: values.lockLevel,
          setFieldValue,
          experimentRunId,
          isEnableRegisteredModelVersionsLock,
          registeredModel,
        })
      }
    </PopupForm>
  );
};

export function CreateRegisteredModelVersionFields({
  lockLevel,
  setFieldValue,
  experimentRunId,
  isEnableRegisteredModelVersionsLock,
  registeredModel,
}: {
  lockLevel: IVersionCreationForm['lockLevel'];
  setFieldValue(field: string, value: unknown): void;
  experimentRunId: string | undefined;
  isEnableRegisteredModelVersionsLock: boolean;
  registeredModel: {
    id: string;
    name: string;
    allowedActions: {
      update: boolean;
    } & ILockLevelFormDeps['allowedActions'];
    workspace: WorkspaceData;
  };
}) {
  return (
    <>
      <TextInputField
        name={getFieldName({ name: null })}
        validate={validateNotEmpty('version name')}
        label="Version name"
        isRequired={true}
      />
      <TextInputField
        name={getFieldName({ experimentRunId: null })}
        label="Experiment run ID"
        disabled={Boolean(experimentRunId)}
      />
      <TextInputField
        name={getFieldName({ description: null })}
        validate={validateDescription}
        label="Description"
        dataTest="description"
      />
      <TagsField name={getFieldName({ labels: null })} />
      {isEnableRegisteredModelVersionsLock ? (
        <div>
          <FeatureLockLevelSelect
            fieldName={getFieldName({ lockLevel: null })}
            allowedActions={registeredModel.allowedActions}
            setFieldValue={setFieldValue}
            value={lockLevel}
          />
          <FormHelperText errorMessage="" />
        </div>
      ) : null}
    </>
  );
}

export default CreateRegisteredModelVersionPopup;
