import React from 'react';
import { Stack } from '@mui/material';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useDatasetVersion } from 'features/datasetVersions/store/datasetVersion/datasetVersion';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { useDatasetPageBreadcrumbs } from 'pages/authorized/DatasetPages/DatasetDetailPages/shared/DatasetDetailsLayout/DatasetDetailsLayout';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { useDatasetVersionSettingsActions } from 'features/datasetVersions/view/DatasetVersion/useDatasetVersionSettingsActions';
import PageSettingsIcon from 'shared/view/elements/PageSettingsIcon/PageSettingsIcon';
import { UpdatedAtTag } from 'pages/authorized/CatalogPages/shared/UpdatedAtTag';

const DatasetVersion = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'DatasetVersion' */ 'features/datasetVersions/view/DatasetVersion/DatasetVersion'
    )
);

const DatasetVersionPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { datasetId, datasetVersionId } = useRouteParams(routes.datasetVersion);

  const { data, loadingDatasetVersion } = useDatasetVersion({
    datasetId,
    id: datasetVersionId,
  });

  const breadcrumbs = useAppBreadcrumbs(
    useDatasetPageBreadcrumbs({
      datasetId,
    }),
    useBreadcrumbItem({
      name: 'Versions',
      route: routes.datasetVersions,
    }),
    useBreadcrumbItem({
      route: routes.datasetVersion,
      ...(isNotNullableRestrictedGraphqlError(data)
        ? { name: `Version ${data.datasetVersion.version}` }
        : { isLoading: true }),
    })
  );

  const settingsActions = useDatasetVersionSettingsActions({
    dataset: isNotNullableRestrictedGraphqlError(data) ? data.dataset : null,
    datasetVersion: isNotNullableRestrictedGraphqlError(data)
      ? data.datasetVersion
      : null,
  });

  return (
    <AuthorizedLayout
      actions={
        isNotNullableRestrictedGraphqlError(data) &&
        data.datasetVersion.allowedActions.delete
          ? [
              <Stack direction="column" key={0}>
                <UpdatedAtTag dateUpdated={data.datasetVersion.dateUpdated} />
                <div style={{ marginLeft: 'auto' }}>
                  <PageSettingsIcon actions={settingsActions} />
                </div>
              </Stack>,
            ]
          : null
      }
      pageEntityOrError={data}
      breadcrumbs={breadcrumbs}
    >
      <DefaultMatchRemoteDataOrError
        communication={loadingDatasetVersion}
        data={data}
        context={'loading dataset version'}
      >
        {({ dataset, datasetVersion, experimentRuns }) => (
          <DatasetVersion
            dataset={dataset}
            datasetVersion={datasetVersion}
            experimentRuns={experimentRuns}
          />
        )}
      </DefaultMatchRemoteDataOrError>
    </AuthorizedLayout>
  );
};

export default DatasetVersionPage;
