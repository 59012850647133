import { FC } from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import DynamicTypography from '../../DynamicTypography/DynamicTypography';

interface Props {
  title?: string;
  titleDataTest?: string;
  children: React.ReactNode;
  dataTest?: string;
  additionalClassName?: string;
}

const PageCard: FC<React.PropsWithChildren<Props>> = (props) => (
  <Paper data-test={props.dataTest} sx={{ boxShadow: 'none', padding: 4 }}>
    <Stack direction="column" spacing={2}>
      {props.title ? (
        <Stack direction="row" alignItems="center">
          <div style={{ width: 'auto', minWidth: 0 }}>
            <DynamicTypography
              dataTest={props.titleDataTest}
              value={props.title}
              variant="h5"
              sx={{ color: 'var(--main-text-color4)', fontWeight: 500 }}
            />
          </div>
        </Stack>
      ) : null}
      {props.children}
    </Stack>
  </Paper>
);

export default PageCard;
