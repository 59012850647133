import { useAppBreadcrumbs } from 'features/layout/view/Layout/AppBreadcrumbs';
import { useMonitoringModelContext } from 'features/monitoring/models/model/store/state/context';
import MonitoringPerformance from 'features/monitoring/performance/view/MonitoringPerformance/MonitoringPerformance';

import { useMonitoringModelPageBreadcrumbs } from '../shared/useMonitoringModelPageBreadcrumbs';
import MonitoringPerformancePageLayout from './MonitoringPerformancePageLayout/MonitoringPerformancePageLayout';

const MonitoringPerformancePage = () => {
  const { model, updateModelState } = useMonitoringModelContext();

  const breadcrumbs = useAppBreadcrumbs(
    useMonitoringModelPageBreadcrumbs({
      modelName: model.name,
    })
  );

  return (
    <MonitoringPerformancePageLayout
      model={model}
      updateModelState={updateModelState}
      breadcrumbs={breadcrumbs}
    >
      <MonitoringPerformance model={model} />
    </MonitoringPerformancePageLayout>
  );
};

export default MonitoringPerformancePage;
