import { useEffect, createRef } from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-objectivec';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-rust';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-swift';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-markup';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/themes/prism.css';

import { SyntaxHighlighterProps } from './SyntaxHighlighter';

export const PrismComponent = ({
  children,
  language,
  style,
  additionalClassnames,
}: SyntaxHighlighterProps) => {
  const ref = createRef<HTMLInputElement>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (ref.current) Prism.highlightAll();
  }, [ref]);

  return (
    <pre className={additionalClassnames} style={style} ref={ref}>
      <code className={`language-${language}`}>{children}</code>
    </pre>
  );
};
