import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import {
  AnalyticsTrackEvent,
  AnalyticsTrackEventVariables,
} from 'shared/graphql/Analitics/graphql-types/queries.generated';
import { TRACK_EVENT } from 'shared/graphql/Analitics/queries';

export const useAnalyticsTrack = () =>
  useCustomMutation<AnalyticsTrackEvent, AnalyticsTrackEventVariables>(
    TRACK_EVENT,
    { context: 'analytics track event' }
  );
