import { CrossRunDashboard } from 'shared/models/CrossRunDashboard/CrossRunDashboard';
import Select from 'shared/view/elements/Selects/Select/Select';

interface ILocalProps {
  dashboardId: string | undefined;
  dashboards: CrossRunDashboard[];
  disabled: boolean;
  onChange(id: CrossRunDashboard): void;
}

const DashboardSelector = (props: ILocalProps) => {
  const options = props.dashboards.map((dashboard) => ({
    label: dashboard.name as string,
    value: dashboard,
  }));

  return (
    <Select
      label={'Dashboard'}
      disabled={props.disabled}
      value={options.find((opt) => opt.value.id === props.dashboardId)}
      options={options}
      withoutError={true}
      onChange={({ value }) => props.onChange(value)}
    />
  );
};

export default DashboardSelector;
