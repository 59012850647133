import routes from 'shared/routes';
import SystemAdminPagesLayout from 'pages/authorized/SystemAdmin/shared/SystemAdminPagesLayout/SystemAdminPagesLayout';
import SystemIntegrationsList from 'features/systemAdmin/integrations/list/view/SystemIntegrationsList';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { useSystemIntegrationsList } from 'features/systemAdmin/integrations/list/store/useSystemIntegrationsList';
import DefaultMatchRemoteData from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

export default function IntegrationsPage() {
  const { data, communication, reload } = useSystemIntegrationsList();

  const breadcrumbs = useAppBreadcrumbs(
    useBreadcrumbItem({
      name: 'System admin',
      route: routes.systemAdmin,
    })
  );

  const reloadData = () => {
    reload({
      variables: { isEnableKafka: true },
      context: 'loading system integrations',
    });
  };
  return (
    <SystemAdminPagesLayout breadcrumbs={breadcrumbs}>
      <DefaultMatchRemoteData
        data={data}
        communication={communication}
        context="loading system integrations"
      >
        {(integrations) => (
          <SystemIntegrationsList
            integrations={integrations}
            reload={reloadData}
          />
        )}
      </DefaultMatchRemoteData>
    </SystemAdminPagesLayout>
  );
}
