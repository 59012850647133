import { last } from 'ramda';
import { scaleLinear, scalePoint } from '@visx/scale';
import { useCallback, useMemo } from 'react';

import isNotNil from 'shared/utils/isNotNill';
import { getLinearScaleDomain } from 'shared/utils/charts/getLinearScaleDomain';

import {
  ParallelCoordinateChartData,
  ParallelCoordinateChartDataValue,
} from '../../ParallelCoordinateChart/utils/types';

const buildLinearDomain = (extent: [number, number], length: number) => {
  const extentDiff = (extent[1] - extent[0]) / (length - 1);
  return new Array(length).fill(0).map((_, i) => extent[0] + i * extentDiff);
};

const interpolateExtent: [number, number] = [0, 100000];

const getGradientScale = (
  values: ParallelCoordinateChartDataValue[],
  colors: string[]
) => {
  const isScalePoint = values.some(
    (value) => typeof value === 'string' || typeof value === 'boolean'
  );

  if (isScalePoint) {
    return {
      interpolationScale: scalePoint({
        domain: values.map(String).sort(),
        range: interpolateExtent,
      }),
      scale: scaleLinear({
        domain: buildLinearDomain(interpolateExtent, colors.length),
        range: colors,
      }),
      type: 'point' as const,
    };
  }

  return {
    scale: scaleLinear({
      domain: buildLinearDomain(
        getLinearScaleDomain(values.map(Number)),
        colors.length
      ),
      range: colors,
    }),
    type: 'linear' as const,
  };
};

export const useGradientScale = ({
  data,
  keys,
  colors,
}: {
  data: ParallelCoordinateChartData[];
  keys: string[];
  colors: string[];
}) => {
  const lastKey = last(keys);

  const values = useMemo(
    () => (lastKey ? data.map((d) => d[lastKey]).filter(isNotNil) : []),
    [lastKey, data]
  );

  const gradientScale = useMemo(
    () => getGradientScale(values, colors),
    [values, colors]
  );

  const getColorByData = useCallback(
    (d: ParallelCoordinateChartData) => {
      if (lastKey) {
        if (gradientScale.type === 'linear') {
          return gradientScale.scale(Number(d[lastKey]));
        }

        return gradientScale.scale(
          gradientScale.interpolationScale(String(d[lastKey])) ?? 0
        );
      }

      return '#ccc';
    },
    [gradientScale, lastKey]
  );

  return {
    getColorByData,
  };
};
