import {
  MonitoringDriftMetricType,
  MonitoringMetricType,
} from 'generated/types';
import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';

interface DriftAlertSettings {
  type: 'drift';
  driftMetricType: MonitoringDriftMetricType;
}

interface MetricAlertSettings {
  type: 'metric';
  metricType: MonitoringMetricType;
}

export type AlertSettings = DriftAlertSettings | MetricAlertSettings;

export type AlertSettingsType = AlertSettings['type'];

export const alertSettingsTypes = exhaustiveStringTuple<AlertSettingsType>()(
  'drift',
  'metric'
);
