import { identity } from 'ramda';

import { Stage } from 'generated/types';

export const getAvailableStages = (currentState: Stage): Stage[] => {
  return identity<Record<Stage, Stage[]>>({
    DEVELOPMENT: [Stage.STAGING, Stage.PRODUCTION, Stage.ARCHIVED],
    STAGING: [Stage.DEVELOPMENT, Stage.PRODUCTION, Stage.ARCHIVED],
    PRODUCTION: [Stage.DEVELOPMENT, Stage.STAGING, Stage.ARCHIVED],
    ARCHIVED: [Stage.DEVELOPMENT, Stage.STAGING, Stage.PRODUCTION],
    UNASSIGNED: [
      Stage.DEVELOPMENT,
      Stage.STAGING,
      Stage.PRODUCTION,
      Stage.ARCHIVED,
    ],
    UNKNOWN: [],
  })[currentState];
};
