import { head } from 'ramda';
import { useMemo } from 'react';

import { OmitStrict } from 'shared/utils/types';

import BaseMultiLevelBarChart, {
  BaseMultiLevelBarChartProps,
} from './BaseMultiLevelBarChart';
import { ChartInterval, makeIntervals } from '../shared/intervals';

interface ChartData {
  levelKey: string;
  groups: Array<
    {
      bars: Array<{
        barKey: string;
        value: number;
      }>;
    } & ChartInterval
  >;
}

interface Props
  extends OmitStrict<
    BaseMultiLevelBarChartProps,
    'data' | 'groupKeys' | 'getGroupLeftOffset'
  > {
  data: ChartData[];
}

const MultiLevelIntervalBarChart = (props: Props) => {
  const groupKeys = useMemo(() => {
    const first = head(props.data);
    return makeIntervals(first?.groups ?? []).map(String);
  }, [props.data]);

  const data = useMemo(
    (): BaseMultiLevelBarChartProps['data'] =>
      props.data.map((d) => ({
        ...d,
        groups: d.groups.map((g) => ({
          bars: g.bars,
          groupKey: g.intervalStart.toString(),
        })),
      })),
    [props.data]
  );

  return (
    <BaseMultiLevelBarChart
      {...props}
      data={data}
      groupKeys={groupKeys}
      getGroupLeftOffset={({ groupScale }) => groupScale.bandwidth() / 2}
    />
  );
};

export default MultiLevelIntervalBarChart;
