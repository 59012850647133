import { Dimension } from './dimensions';
import { makePoints, ParallelCoordinateChartPoint } from './points';
import { ParallelCoordinateChartData } from './types';

interface ParallelCoordinateChartPath {
  color: string;
  points: ParallelCoordinateChartPoint[];
  data: ParallelCoordinateChartData;
}

export const makePath = (
  d: ParallelCoordinateChartData,
  {
    dimensions,
    getColorByData,
  }: {
    dimensions: Dimension[];
    getColorByData: (d: ParallelCoordinateChartData) => string;
  }
): ParallelCoordinateChartPath | null => {
  const color = getColorByData(d);
  const points = makePoints(d, dimensions);
  return points
    ? {
        color,
        points,
        data: d,
      }
    : null;
};
