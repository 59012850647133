import { equals as ramdaEquals } from 'ramda';

import { Brand } from 'shared/utils/Brand/Brand';
import { removeNillProperties, updateById } from 'shared/utils/collection';
import { makeError, makeSuccess, Result } from 'shared/utils/result';
import { combineValidators, validateNotEmpty } from 'shared/utils/validators';

import { CrossRunWidget } from './CrossRunWidget/CrossRunWidget';
import { WidgetSettings } from './CrossRunWidget/CrossRunCustomWidget/WidgetSettings/WidgetSettings';

export const defaultMinSize = {
  minW: 6,
  minH: 8,
};

export type LayoutItem = {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
};

export interface CrossRunDashboard {
  id: string;
  name: CrossRunDashboardName;
  panels: CrossRunDashboardPanel[];
}

export interface CrossRunDashboardPanel {
  id: string;
  name: string;
  widgets: CrossRunWidget[];
  layout: LayoutItem[];
}

export const layoutSettings = {
  cols: 12,
  rowHeight: 30,
  isResizable: true,
  isDraggable: true,
};

declare const CrossRunDashboardName: unique symbol;
export type CrossRunDashboardName = Brand<
  string,
  'CrossRunDashboardName',
  typeof CrossRunDashboardName
>;
export const makeCrossRunDashboardName = (
  dashboards: CrossRunDashboard[],
  str: string
): Result<CrossRunDashboard['name'], string> => {
  const validateUnique = () => {
    return dashboards.some((dashboard) => dashboard.name === str)
      ? 'the dashboard name should be unique'
      : undefined;
  };

  const validateSymbols = () => {
    return /^[a-zA-Z0-9-]*$/.test(str)
      ? undefined
      : 'The name may only contain alphanumeric characters or hyphens';
  };

  const error = combineValidators([
    validateNotEmpty('name'),
    validateUnique,
    validateSymbols,
  ])(str);
  return error
    ? makeError(error)
    : makeSuccess(str as CrossRunDashboard['name']);
};

export const equals = (
  dashboard1: CrossRunDashboard,
  dashboard2: CrossRunDashboard
): boolean => {
  // we have to remove properties with null or undefined because after saving dashboard on the backend they are removed
  // for example, { domain: { min: undefined; max: undefined } } saved as { domain: {} } so a local copy of a dashboard is not equal to the version from the backend
  return ramdaEquals(
    removeNillProperties(dashboard1),
    removeNillProperties(dashboard2)
  );
};

export const addPanel = (
  dashboard: CrossRunDashboard,
  { name, id }: { name: string; id: string }
): CrossRunDashboard => {
  const panel: CrossRunDashboardPanel = {
    id,
    name,
    widgets: [],
    layout: [],
  };
  return {
    ...dashboard,
    panels: [panel].concat(dashboard.panels),
  };
};

export const deletePanel = (
  dashboard: CrossRunDashboard,
  panelId: string
): CrossRunDashboard => ({
  ...dashboard,
  panels: dashboard.panels.filter((p) => p.id !== panelId),
});

export const updatePanelLayout = (
  dashboard: CrossRunDashboard,
  panelId: string,
  layout: CrossRunDashboardPanel['layout']
): CrossRunDashboard => ({
  ...dashboard,
  panels: updateById(
    (p) => ({
      ...p,
      layout,
    }),
    panelId,
    dashboard.panels
  ),
});

export const updatePanel = (
  dashboard: CrossRunDashboard,
  panelId: string,
  f: (panel: CrossRunDashboardPanel) => CrossRunDashboardPanel
): CrossRunDashboard => ({
  ...dashboard,
  panels: updateById(f, panelId, dashboard.panels),
});

export const deleteWidget = (
  dashboard: CrossRunDashboard,
  panelId: string,
  widgetId: string
): CrossRunDashboard => {
  return {
    ...dashboard,
    panels: updateById(
      (panel) => ({
        ...panel,
        widgets: panel.widgets.filter((widget) => widget.id !== widgetId),
        layout: panel.layout.filter((l) => l.i !== widgetId),
      }),
      panelId,
      dashboard.panels
    ),
  };
};

export const updateWidget = (
  dashboard: CrossRunDashboard,
  panelId: string,
  widgetId: string,
  widgetSettings: WidgetSettings
): CrossRunDashboard => {
  return {
    ...dashboard,
    panels: updateById(
      (panel) => ({
        ...panel,
        widgets: updateById(
          (widget) => ({
            ...widget,
            settings: widgetSettings,
          }),
          widgetId,
          panel.widgets
        ),
      }),
      panelId,
      dashboard.panels
    ),
  };
};

export const addWidget = (
  dashboard: CrossRunDashboard,
  panelId: string,
  widgetSettings: WidgetSettings,
  id: string
): CrossRunDashboard => {
  const newWidget = {
    id,
    settings: widgetSettings,
    type: 'custom' as const,
  };

  const initialHeight = 10;

  return {
    ...dashboard,
    panels: updateById(
      (panel) => ({
        ...panel,
        widgets: panel.widgets.concat(newWidget),
        layout: [
          ...panel.layout,
          {
            i: newWidget.id,
            x: 0,
            y:
              panel.layout.length === 0
                ? 0
                : Math.max(...panel.layout.map(({ y }) => y)) + initialHeight, // put at the bottom
            w: layoutSettings.cols,
            h: initialHeight,
            ...defaultMinSize,
          },
        ],
      }),
      panelId,
      dashboard.panels
    ),
  };
};
