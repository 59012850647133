import {
  MonitoringBooleanFilterOperator,
  MonitoringStringArrayFilterOperator,
  MonitoringStringFilterOperator,
  MonitoringDoubleFilterOperator,
  MonitoringFilterInput,
} from 'generated/types';
import matchBy from 'shared/utils/matchBy';
import matchType from 'shared/utils/matchType';

import { MonitoringIODescription } from '../MonitoringModel/MonitoringIODescription';

export type MonitoringFilter =
  | MonitoringNumberFilter
  | MonitoringBooleanFilter
  | MonitoringStringFilter
  | MonitoringStringArrayFilter;

interface MonitoringNumberFilter {
  id: number;
  type: 'number';
  ioDescription: MonitoringIODescription;
  operator: MonitoringDoubleFilterOperator;
  value: number;
}

export interface MonitoringBooleanFilter {
  id: number;
  type: 'boolean';
  ioDescription: MonitoringIODescription;
  operator: MonitoringBooleanFilterOperator;
  value: boolean;
}

export interface MonitoringStringFilter {
  id: number;
  type: 'string';
  valueType: 'string';
  ioDescription: MonitoringIODescription;
  operator: MonitoringStringFilterOperator;
  value: string;
}

export interface MonitoringStringArrayFilter {
  id: number;
  type: 'string';
  valueType: 'stringArray';
  ioDescription: MonitoringIODescription;
  operator: MonitoringStringArrayFilterOperator;
  value: string[];
}

export const getNextFilterId = (filters: MonitoringFilter[]) =>
  filters.reduce((r, f) => Math.max(r, f.id), 0) + 1;

export const convertMonitoringFilterToGraphQL = (
  filter: MonitoringFilter
): MonitoringFilterInput => {
  return matchBy(
    filter,
    'type'
  )({
    number: (f) => ({
      double: {
        ioDescription: f.ioDescription,
        operator: f.operator,
        value: f.value,
      },
    }),
    boolean: (f) => ({
      boolean: {
        ioDescription: f.ioDescription,
        operator: f.operator,
        value: f.value,
      },
    }),
    string: (stringFilter) =>
      matchBy(
        stringFilter,
        'valueType'
      )<MonitoringFilterInput>({
        string: (f) => ({
          string: {
            ioDescription: f.ioDescription,
            operator: f.operator,
            value: f.value,
          },
        }),
        stringArray: (f) => ({
          stringArray: {
            ioDescription: f.ioDescription,
            operator: f.operator,
            value: f.value,
          },
        }),
      }),
  });
};

export const makeFormattedMonitoringFilterString = (
  filter: MonitoringFilter
): string => {
  const ioDescriptionString = `${
    filter.ioDescription.name
  }.${filter.ioDescription.ioType.toLowerCase()}`;

  const operatorString = matchBy(
    filter,
    'type'
  )({
    boolean: (f) => getMonitoringBooleanOperatorString(f.operator),
    number: (f) => getMonitoringDoubleOperatorString(f.operator),
    string: (f) =>
      matchBy(
        f,
        'valueType'
      )({
        string: (stringFilter) =>
          getMonitoringStringOperatorString(stringFilter.operator),
        stringArray: (stringArrayFilter) =>
          getMonitoringStringArrayOperatorString(stringArrayFilter.operator),
      }),
  });

  const valueString = matchBy(
    filter,
    'type'
  )({
    boolean: (f) => (f.value ? 'true' : 'false'),
    number: (f) => String(f.value),
    string: (f) =>
      matchBy(
        f,
        'valueType'
      )({
        string: (stringFilter) => stringFilter.value,
        stringArray: (stringArrayFilter) => stringArrayFilter.value.join(', '),
      }),
  });

  return `${ioDescriptionString} ${operatorString} ${valueString}`;
};

const getMonitoringBooleanOperatorString = (
  operator: MonitoringBooleanFilterOperator
) => {
  return matchType(
    {
      EQ: () => 'equals',
      NE: () => 'not equals',
    },
    operator
  );
};

const getMonitoringDoubleOperatorString = (
  operator: MonitoringDoubleFilterOperator
) => {
  return matchType(
    {
      EQ: () => 'equals',
      NE: () => 'not equals',
      GT: () => 'greater than',
      GTE: () => 'equals or greater than',
      LT: () => 'less than',
      LTE: () => 'equals or less than',
    },
    operator
  );
};

const getMonitoringStringOperatorString = (
  operator: MonitoringStringFilterOperator
) => {
  return matchType(
    {
      EQ: () => 'equals',
      NE: () => 'not equals',
      LIKE: () => 'like',
      NOT_LIKE: () => 'not like',
    },
    operator
  );
};

const getMonitoringStringArrayOperatorString = (
  operator: MonitoringStringArrayFilterOperator
) => {
  return matchType(
    {
      NOT_CONTAINS: () => 'contains one of',
      CONTAINS: () => 'not contains any of',
    },
    operator
  );
};
