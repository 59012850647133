import { dissoc, keys, pipe } from 'ramda';

import { SearchResult } from './SearchResult';

export type SearchEntities = SearchResult['entityType'];

export const SearchEntities: { [T in SearchEntities]: T } = {
  projects: 'projects',
  endpoints: 'endpoints',
  experiments: 'experiments',
  experimentRuns: 'experimentRuns',
  datasets: 'datasets',
  datasetVersions: 'datasetVersions',
  registeredModels: 'registeredModels',
  registeredModelVersions: 'registeredModelVersions',
};

export const getAvailableSearchEntities = ({
  isEnableRegistry,
}: {
  isEnableRegistry: boolean;
}) => {
  return pipe(
    () => SearchEntities,
    (x) => (isEnableRegistry ? x : dissoc('registeredModels', x)),
    (x) => (isEnableRegistry ? x : dissoc('registeredModelVersions', x)),
    (x) => keys(x)
  )();
};
