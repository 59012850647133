import {
  IconDefinition,
  IconPrefix,
  IconName,
} from '@fortawesome/fontawesome-svg-core';

export const JupyterNotebookIcon: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'jupyter-notebook' as IconName,
  icon: [
    18,
    18,
    [],
    'e001',
    [
      'M13.4437 2.79826C12.141 1.89076 10.686 1.43701 9.23023 1.43701C7.69798 1.43701 6.24223 1.89076 5.01673 2.72251C3.79123 3.63001 2.87173 4.76476 2.33548 6.20176C3.71473 4.61326 6.24298 3.63076 9.23098 3.63076C12.1425 3.63076 14.7472 4.68976 16.1265 6.20176C15.5887 4.84051 14.6692 3.63001 13.4437 2.79826ZM13.4437 14.445C14.6692 13.6133 15.5887 12.4028 16.125 11.0415C14.7457 12.5543 12.141 13.6133 9.22948 13.6133C6.31798 13.6133 3.71323 12.5543 2.33398 11.0415C2.87023 12.4028 3.78973 13.6133 5.01523 14.445C6.24073 15.2768 7.69649 15.7305 9.22873 15.7305C10.761 15.7305 12.2175 15.2775 13.4437 14.445Z',
      'M2.6415 3.17606C3.06483 3.17606 3.408 2.83759 3.408 2.42006C3.408 2.00254 3.06483 1.66406 2.6415 1.66406C2.21817 1.66406 1.875 2.00254 1.875 2.42006C1.875 2.83759 2.21817 3.17606 2.6415 3.17606Z',
      'M3.7905 17.9998C4.55224 17.9998 5.16975 17.3904 5.16975 16.6386C5.16975 15.8868 4.55224 15.2773 3.7905 15.2773C3.02877 15.2773 2.41125 15.8868 2.41125 16.6386C2.41125 17.3904 3.02877 17.9998 3.7905 17.9998Z',
      'M15.6653 1.059C15.6653 0.47475 15.1853 0 14.5928 0C14.0003 0 13.5203 0.474 13.5203 1.059C13.5203 1.644 14.001 2.118 14.5928 2.118C15.1853 2.118 15.6653 1.64325 15.6653 1.059Z',
    ],
  ],
};
