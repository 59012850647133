import { gql } from '@apollo/client';

export const CHECKLIST_ITEM_VALUE_FRAGMENT = gql`
  fragment ChecklistItemValueData on ChecklistItemValue {
    id
    completed
    evidence
    checklistTemplateId
    checklistTemplateItemId
    timeCreated
    timeUpdated
  }
`;
