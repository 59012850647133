import * as React from 'react';
import { useApolloClient } from '@apollo/client';
import { gql } from '@apollo/client';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import {
  useBreadcrumbItem,
  useAppBreadcrumbs,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import PagesTabs from 'shared/view/pages/PagesTabs/PagesTabs';
import routes from 'shared/routes';
import { useDatasetsPageBreadcrumb } from 'pages/authorized/DatasetPages/shared/useDatasetsPageBreadcrumbs';
import getTabs from 'shared/view/pages/PagesTabs/getTabs';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
import {
  isNotNullableRestrictedGraphqlError,
  RestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';
import PageSettingsIcon from 'shared/view/elements/PageSettingsIcon/PageSettingsIcon';
import { useDatasetSettingsActions } from 'features/datasets/datasetSettings/store/useDatasetSettingsActions';
import { UpdatedAtTagGraphql } from 'pages/authorized/CatalogPages/shared/UpdatedAtTag';
import IdTopEnhancer from 'shared/view/elements/IdTopEnhancer/IdTopEnhancer';

import { BaseDataset } from '../../LoadGraphqlDataset';
import { DatasetBreadcrumb } from './graphql-types/DatasetDetailsLayout.generated';

type Props = {
  children: JSX.Element;
  dataset: BaseDataset | RestrictedGraphqlError | undefined;
} & Pick<React.ComponentProps<typeof AuthorizedLayout>, 'pageEntityOrError'>;

const DatasetDetailsLayout: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { children, pageEntityOrError } = props;

  const { datasetId, workspaceName } = useRouteParams(routes.datasetOverview);

  const dataset = isNotNullableRestrictedGraphqlError(props.dataset)
    ? props.dataset
    : null;
  const breadcrumbs = useDatasetPageBreadcrumbs({ datasetId });

  const settingsActions = useDatasetSettingsActions({
    workspaceName: workspaceName,
    dataset,
  });

  return (
    <AuthorizedLayout
      actions={[
        <UpdatedAtTagGraphql
          key="updated-at-tag"
          dateUpdated={dataset?.dateUpdated}
        />,
      ]}
      pageEntityOrError={pageEntityOrError}
      breadcrumbs={breadcrumbs}
      tabsElement={
        <PagesTabs
          tabs={getTabs(routes.dataset.$)(
            [
              ['overview', () => 'Overview'],
              ['versions', () => 'Versions'],
            ],
            { datasetId, workspaceName }
          )}
          rightContent={<PageSettingsIcon actions={settingsActions} />}
        />
      }
    >
      <div>{children}</div>
    </AuthorizedLayout>
  );
};

export const useDatasetPageBreadcrumbs = ({
  datasetId,
}: {
  datasetId: string;
}) => {
  const apolloClient = useApolloClient();
  const dataset = (() => {
    try {
      return apolloClient.readFragment<DatasetBreadcrumb>({
        id: `Dataset:${datasetId}`,
        fragment: gql`
          fragment DatasetBreadcrumb on Dataset {
            id
            name
          }
        `,
      });
    } catch {
      return null;
    }
  })();

  const topEnhancer = <IdTopEnhancer id={datasetId} />;

  return useAppBreadcrumbs(
    useDatasetsPageBreadcrumb(),
    useBreadcrumbItem({
      route: routes.datasetOverview,
      hasTopEnhancer: true,
      ...(dataset ? { name: dataset.name, topEnhancer } : { isLoading: true }),
    })
  );
};
export default DatasetDetailsLayout;
