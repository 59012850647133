import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material';
import { MouseEventHandler } from 'react';

import { OmitStrict } from 'shared/utils/types';

import { ButtonIcon } from '../Button/shared/ButtonContent';

export interface IconButtonProps extends OmitStrict<ButtonProps, 'size'> {
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon: IconDefinition;
  dataTest?: string;
  disabled?: boolean;
  withPadding?: boolean;
  size?: SizeProp;
  rotate?: string;
}

const ButtonS = styled(Button)({
  minHeight: '40px',
  minWidth: '40px',
});

const IconButton = (props: IconButtonProps) => {
  const {
    onClick,
    dataTest,
    icon,
    withPadding = false,
    size,
    rotate,
    sx,
    ...other
  } = props;
  return (
    <ButtonS
      variant="outlined"
      onClick={onClick}
      data-test={dataTest}
      {...other}
      sx={{
        padding: withPadding ? undefined : 0,
        transform: rotate ? `rotate(${rotate})` : undefined,
        ...sx,
      }}
    >
      <ButtonIcon
        icon={icon}
        reversedColors={other.variant === 'contained'}
        size={size || 'xl'}
        {...other}
      />
    </ButtonS>
  );
};

export default IconButton;
