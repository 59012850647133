import { useEffect, useState } from 'react';
import { findLastIndex } from 'lodash';

import Popup, { PopupProps } from 'shared/view/elements/Popup/Popup';
import {
  EvaluationAttributeKey,
  EvaluationData,
  EvaluationHeaderKey,
} from 'features/evaluations/shared/utils/evaluation';

import { Evaluation } from '../../hooks/useEvaluationSummary';
import { useUpdateEvaluationRow } from '../../hooks/useUpdateEvaluationRow';
import { EvaluateRow } from '../EvaluateRow/EvaluateRow';

type Props = Pick<PopupProps, 'onClose' | 'isOpen'> & {
  evaluation: Evaluation;
};

export const EvaluatePopup = (props: Props) => {
  const { evaluation, ...rest } = props;

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedEvaluation, setSelectedEvaluation] = useState<
    EvaluationData | undefined
  >(evaluation.rows[selectedIndex]);

  const { updateEvaluationRow, updatingEvaluationRow } =
    useUpdateEvaluationRow();

  useEffect(() => {
    const idx = findLastIndex(
      evaluation.rows,
      (row) => row[EvaluationHeaderKey.RESULT] !== undefined
    );

    if (idx >= 0 && idx + 1 !== evaluation.rows.length) {
      setSelectedIndex(idx + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedIndex === evaluation.rows.length) {
      props.onClose?.();
      setSelectedIndex(0);
      setSelectedEvaluation(evaluation.rows[0]);
    }
  }, [evaluation.rows, evaluation.rows.length, props, selectedIndex]);

  useEffect(() => {
    setSelectedEvaluation(evaluation.rows[selectedIndex]);
  }, [evaluation.rows, selectedIndex]);

  const handleEvaluate = (newIdx: number) => {
    if (selectedEvaluation) {
      const key = `${EvaluationAttributeKey.ROW}_${
        selectedEvaluation[EvaluationHeaderKey.ID]
      }`;
      const value = JSON.stringify(selectedEvaluation);

      updateEvaluationRow(
        {
          id: evaluation.id,
          attribute: { key, value },
        },
        {
          onCompleted: () => {
            setSelectedIndex(newIdx);
            setSelectedEvaluation(evaluation.rows[newIdx]);
          },
        }
      );
    }
  };

  const handleUpdateResult = (result: boolean) => {
    if (selectedEvaluation) {
      const _evaluation = { ...selectedEvaluation };
      _evaluation[EvaluationHeaderKey.RESULT] = result;

      setSelectedEvaluation(_evaluation);
    }
  };

  const handleUpdateLabel = (key: string) => {
    if (selectedEvaluation) {
      const _evaluation = { ...selectedEvaluation };
      _evaluation[EvaluationHeaderKey.LABELS][key] =
        !selectedEvaluation[EvaluationHeaderKey.LABELS][key];

      setSelectedEvaluation(_evaluation);
    }
  };

  const handleUpdateFeedback = (value: string) => {
    if (selectedEvaluation) {
      const _evaluation = { ...selectedEvaluation };
      _evaluation[EvaluationHeaderKey.FEEDBACK] = value;

      setSelectedEvaluation(_evaluation);
    }
  };

  return (
    <Popup
      {...rest}
      maxWidth="xl"
      fullWidth
      title="Evaluation"
      dialogContentProps={{ height: 'calc(100vh - 144px)' }}
    >
      {selectedEvaluation ? (
        <EvaluateRow
          {...evaluation}
          row={selectedEvaluation}
          currentRowIdx={selectedIndex}
          totalRows={evaluation.rows.length}
          handleMoveToRowClick={handleEvaluate}
          communication={updatingEvaluationRow}
          handleUpdateResult={handleUpdateResult}
          handleUpdateLabel={handleUpdateLabel}
          handleUpdateFeedback={handleUpdateFeedback}
        />
      ) : null}
    </Popup>
  );
};
