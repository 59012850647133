import { useMemo } from 'react';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import ExperimentRunsCompare from 'features/experimentRuns/compare/view/ExperimentRunsCompare/ExperimentRunsCompare';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { useLoadingExperimentRunsForComparing } from 'features/experimentRuns/compare/store/useLoadingExperimentRunsForComparing/useLoadingExperimentRunsForComparing';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';

function ExperimentRunsComparePage() {
  const { experimentRunIds } = useRouteParams(routes.experimentRunsCompare);

  const breadcrumbs = useAppBreadcrumbs(
    useBreadcrumbItem({
      route: routes.experimentRuns,
      name: 'Experiment runs',
    }),
    useBreadcrumbItem({
      route: routes.experimentRunsCompare,
      name: 'Compare',
    })
  );

  const comparedExperimentRunIds = useMemo(
    () => experimentRunIds.split(','),
    [experimentRunIds]
  );

  const { data, loadingData } = useLoadingExperimentRunsForComparing({
    comparedExperimentRunIds,
  });

  return (
    <AuthorizedLayout pageEntityOrError={data} breadcrumbs={breadcrumbs}>
      <DefaultMatchRemoteDataOrError
        communication={loadingData}
        data={data}
        context={'loading experiment runs'}
      >
        {({ project, experimentRuns }) => (
          <ExperimentRunsCompare
            project={project}
            experimentRuns={experimentRuns}
          />
        )}
      </DefaultMatchRemoteDataOrError>
    </AuthorizedLayout>
  );
}

export default ExperimentRunsComparePage;
