import { useCallback } from 'react';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';

import { PypiConfigurationFormValues } from 'features/systemAdmin/integrations/pypi/view/form/types/PypiConfigurationFormValues';
import Button from 'shared/view/elements/Button/Button';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import CheckboxField from 'shared/view/formComponents/formikFields/CheckboxField/CheckboxField';
import { validateNotEmpty, validateUrl } from 'shared/utils/validators';
import DefaultConfirmAction, {
  ConfirmType,
} from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';
import { useTestPypiConfiguration } from 'features/systemAdmin/integrations/pypi/store/useTestPypiConfiguration';
import { OmitStrict } from 'shared/utils/types';
import { selectFlags } from 'features/flags';
import Tooltip from 'shared/view/elements/Tooltip/Tooltip';
import { CONFIGURATION_TEST_TOOLTIP_TEXT } from 'features/systemAdmin/integrations/shared/configurationsCommonMessages';
import { useNewConfiguration } from 'features/systemAdmin/integrations/shared/useNewConfiguration';
import useSaveDisabled from 'features/systemAdmin/integrations/shared/useSaveDisabled';
import SubmitButton from 'shared/view/elements/Button/SubmitButton';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import InfoIcon from 'shared/view/elements/InfoIcon/InfoIcon';

type Props = {
  initialValues: PypiConfigurationFormValues;
  onDelete(): void;
  isDeleting: boolean;
  onSave(value: PypiConfigurationFormValues): void;
  isSaving: boolean;
};

const getFieldName = makeGetFieldName<PypiConfigurationFormValues>();

export default function PypiConfigurationsForm(props: Props) {
  return (
    <PresetFormik initialValues={props.initialValues} onSubmit={props.onSave}>
      {({ isValid, values }) => (
        <FormFields
          values={values}
          initialValues={props.initialValues}
          isValid={isValid}
          isSaving={props.isSaving}
          isDeleting={props.isDeleting}
          onDelete={props.onDelete}
        />
      )}
    </PresetFormik>
  );
}

function FormFields(
  props: {
    values: PypiConfigurationFormValues;
    isValid: boolean;
  } & OmitStrict<Props, 'onSave'>
) {
  const { pypiDisableConfigNameMarker } = useSelector(selectFlags);

  const { isConfigurationTested, setConfigurationAsTested } =
    useNewConfiguration(props.values);

  const { testPypiConfiguration, testingPypiConfiguration } =
    useTestPypiConfiguration({ onSuccess: setConfigurationAsTested });

  const { values, isValid } = props;
  const isExtendedFormShown = !Boolean(
    values.name === pypiDisableConfigNameMarker
  );

  const onClickTest = useCallback(() => {
    testPypiConfiguration(values);
  }, [testPypiConfiguration, values]);

  const { saveIsDisabled, saveIsLoading } = useSaveDisabled({
    isConfigurationTested,
    isExtendedFormShown,
    isValid,
    values,
    initialValues: props.initialValues,
    isSaving: props.isSaving,
  });

  return (
    <FormStack>
      {isExtendedFormShown ? <ExtendedFormFields /> : null}
      <CheckboxField name={getFieldName({ enabled: null })} label={'Enabled'} />
      <Stack direction={'row'}>
        {isExtendedFormShown ? (
          <ExtendedFormButtons
            onClickTest={onClickTest}
            isValid={isValid}
            isTesting={testingPypiConfiguration.isRequesting}
            isDeleting={props.isDeleting}
            onDelete={props.onDelete}
          />
        ) : null}
        <SubmitButton
          key={'Save'}
          disabled={saveIsDisabled}
          isLoading={saveIsLoading}
        >
          Save
        </SubmitButton>
      </Stack>
    </FormStack>
  );
}

function ExtendedFormFields() {
  return (
    <>
      <TextInputField
        label={'Name'}
        isRequired={true}
        name={getFieldName({ name: null })}
        validate={validateNotEmpty('Name')}
      />
      <TextInputField
        label={'Repository URL'}
        isRequired={true}
        endAdornmentIcons={[
          <InfoIcon key={0} tooltip="https://pypi.example.com:1234/path/" />,
        ]}
        name={getFieldName({ url: null })}
        validate={validateUrl}
      />
      <TextInputField
        label={'Username'}
        isRequired={true}
        name={getFieldName({ username: null })}
        validate={validateNotEmpty('Username')}
      />
      <TextInputField
        label={'Password'}
        isRequired={true}
        validate={validateNotEmpty('Password')}
        type={'password'}
        name={getFieldName({ password: null })}
      />
    </>
  );
}

function ExtendedFormButtons({
  onClickTest,
  isValid,
  isTesting,
  isDeleting,
  onDelete,
}: {
  onClickTest(): void;
  isValid: boolean;
  isTesting: boolean;
  isDeleting: boolean;
  onDelete(): void;
}) {
  return (
    <>
      <Tooltip type="withSpan" title={CONFIGURATION_TEST_TOOLTIP_TEXT}>
        <Button
          key={'Test'}
          onClick={onClickTest}
          disabled={!isValid}
          isLoading={isTesting}
        >
          Test
        </Button>
      </Tooltip>
      <DefaultConfirmAction
        type={ConfirmType.delete}
        description={'PyPI integration'}
      >
        {(withConfirmAction) => (
          <Button
            key={'Delete'}
            onClick={withConfirmAction(onDelete)}
            isLoading={isDeleting}
          >
            Delete
          </Button>
        )}
      </DefaultConfirmAction>
    </>
  );
}
