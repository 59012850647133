import { ScaleBand, ScalePoint } from 'd3-scale';
import { useMemo } from 'react';

import { Alerter } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/Alerter';
import { ChartSizes } from 'shared/utils/charts/chartSizes';

import { HistogramsChartData } from '../HistogramsChart';
import ViolatingSampleAreas from '../ViolatingSampleAreas/ViolatingSampleAreas';
import { makeViolatingHistogramAreas } from './makeViolatingHistogramAreas';

interface Props {
  xScale: ScaleBand<string> | ScalePoint<string>;
  chartSizes: ChartSizes;
  data: HistogramsChartData[];
  alerter: Alerter;
}

const HistogramsChartAlertDecorations = (props: Props) => {
  const areas = useMemo(
    () =>
      makeViolatingHistogramAreas({
        xScale: props.xScale,
        data: props.data,
        innerWidth: props.chartSizes.innerWidth,
        alerter: props.alerter,
      }),
    [props.alerter, props.chartSizes.innerWidth, props.data, props.xScale]
  );

  return (
    <ViolatingSampleAreas
      innerHeight={props.chartSizes.innerHeight}
      areas={areas}
    />
  );
};

export default HistogramsChartAlertDecorations;
