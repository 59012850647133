import routes from 'shared/routes';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import CellRendererLink from 'shared/view/elements/DataGrid/columns/CellRendererLink';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import CellRendererNumeric from 'shared/view/elements/DataGrid/columns/CellRendererNumeric';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import { typeSafeConfiguration } from 'shared/view/elements/DataGrid/configuration/helpers/typeSafeConfiguration';
import CellRendererActions from 'shared/view/elements/DataGrid/columns/CellRendererActions';
import CellRendererDate from 'shared/view/elements/DataGrid/columns/CellRendererDate';
import noop from 'shared/utils/noop';
import { ConfirmType } from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import makeActionsColumn from 'shared/view/elements/DataGrid/columns/makeActionsColumn';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { changeWorkspaceWithPageReloading } from 'features/workspaces/store';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { getUrlWithChangedWorkspace } from 'shared/routes/shared/getUrlWithChangedWorkspace';

import { useOrganizationsList } from '../store/useOrganizationsList';
import { OrganizationsV2List } from '../store/graphql-types/useOrganizationsList.generated';
import { useOrganizationDelete } from '../store/useDeleteOrganization';
import EditOrganizationPopup from './EditOrganizationPopup';
import { CellRendererList } from '../../users/view/OrganizationUsersTable';

type ColumnType = OrganizationsV2List['organizationsV2']['organizations'][0] & {
  stats?: OrganizationsV2List['organizationsV2']['organizationsStats'][0];
} & {
  defaultWorkspace?: OrganizationsV2List['organizationsV2']['organizations'][0]['workspaces'][0];
};

const columns: DataGridColumn<ColumnType>[] = [
  {
    field: 'Name',
    flex: 3,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'string',
      (params) => params.row.name
    ),
    renderCell: (params) => {
      return (
        <CellRendererLink
          dataTest="organization-name"
          label={params.row.name}
          to={routes.organizationUsers.getRedirectPath({
            name: params.row.name,
          })}
        />
      );
    },
  },
  {
    field: 'Description',
    flex: 2,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'string',
      (params) => params.row.description
    ),
    renderCell: (params) => (
      <CellRendererString
        dataTest="organization-description"
        value={params.row.description}
      />
    ),
  },
  {
    field: 'LLMApp',
    flex: 1,
    renderCell: (params) => {
      return (
        <InlineLink
          onClick={(event) =>
            changeWorkspaceWithPageReloading({
              event,
              workspace: {
                type: 'organization',
                id: params.row.defaultWorkspace?.id || '',
                name: params.row.defaultWorkspace?.name || '',
                organizationId: params.row.id,
                organizationName: params.row.name,
                namespace: null,
              },
              to: routes.llmProjectsList.getPath(),
              preventTrack: true,
            })
          }
          to="#"
        >
          List
        </InlineLink>
      );
    },
  },
  {
    field: 'Default workspace',
    flex: 2,
    renderCell: (params) => {
      const path = getUrlWithChangedWorkspace({
        workspaceName: params.row.defaultWorkspace?.name || '',
        pathname: routes.catalog.getPath(),
      });

      return (
        <InlineLink
          onClick={(event) =>
            changeWorkspaceWithPageReloading({
              event,
              workspace: {
                type: 'organization',
                id: params.row.defaultWorkspace?.id || '',
                name: params.row.defaultWorkspace?.name || '',
                organizationId: params.row.id,
                organizationName: params.row.name,
                namespace: null,
              },
              to: path,
              preventTrack: true,
            })
          }
          to="#"
        >
          {params.row.defaultWorkspace?.name}
        </InlineLink>
      );
    },
  },

  {
    field: 'Admins',
    flex: 2,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'number',
      (params) => params.row.admins.length
    ),
    renderCell: (params) => (
      <CellRendererList items={params.row.admins.map((admin) => admin.email)} />
    ),
  },
  {
    field: 'Users',
    flex: 1,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'number',
      (params) => params.row.stats?.numUsers
    ),
    renderCell: (params) => (
      <CellRendererNumeric
        dataTest="organization-users-number"
        value={params.row.stats?.numUsers}
      />
    ),
  },
  {
    field: 'Models',
    flex: 1,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'number',
      (params) => params.row.stats?.numRegisteredModels
    ),
    renderCell: (params) => (
      <CellRendererNumeric
        dataTest="organization-models-number"
        value={params.row.stats?.numRegisteredModels}
      />
    ),
  },
  {
    field: 'Endpoints',
    flex: 1,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'number',
      (params) => params.row.stats?.numEndpoints
    ),
    renderCell: (params) => (
      <CellRendererNumeric
        dataTest="organization-endpoints-number"
        value={params.row.stats?.numEndpoints}
      />
    ),
  },

  {
    field: 'ID',
    minWidth: 100,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'string',
      (params) => params.row.id
    ),
    renderCell: (params) => (
      <CellRendererString
        dataTest="organization-org-id"
        value={String(params.row.id)}
      />
    ),
  },
  {
    field: 'timeCreated',
    headerName: 'Created at',
    flex: 1,
    minWidth: 200,
    additionalConfiguration: typeSafeConfiguration(
      ['sort'],
      'number',
      (params) => params.row.timeCreated
    ),
    renderCell: (params) => (
      <CellRendererDate value={params.row.timeCreated} showTime />
    ),
  },
  makeActionsColumn({
    width: 100,
    renderCell: function Actions(params) {
      const deleteApi = useOrganizationDelete(noop);
      const editPopup = usePopupManager();

      return (
        <>
          {editPopup.isPopupOpen ? (
            <EditOrganizationPopup
              isOpen={true}
              organization={params.row}
              onClose={editPopup.closePopup}
            />
          ) : null}
          <CellRendererActions
            actions={[
              {
                type: 'icon',
                icon: ICONS.pencil,
                label: 'Edit',
                dataTest: 'edit',
                onClick: editPopup.openPopup,
              },
              {
                type: 'icon',
                icon: ICONS.trashCan,
                label: 'Delete',
                dataTest: 'delete',
                disabled: !params.row.allowedActions.delete,
                isLoading: deleteApi.communication.isRequesting,
                confirmAction: {
                  description: 'organization',
                  type: ConfirmType.delete,
                },
                onClick: () => deleteApi.run({ organizationId: params.row.id }),
              },
            ]}
          />
        </>
      );
    },
  }),
];
const Organizations = (props: ReturnType<typeof useOrganizationsList>) => {
  return (
    <div data-test="organizations">
      <DataGridWithTypes
        columns={columns}
        heightType="pageHeight"
        rows={props.data || []}
        communication={props.communication}
        context="loading organizations"
      />
    </div>
  );
};

export default Organizations;
