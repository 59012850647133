import { RequiredOwner } from 'features/editResourceInformation/store/useEditResourceInformation';
import { useEditResourceInformationMenuAction } from 'features/editResourceInformation/view/EditResourceInformation/EditResourceInformation';
import { useDeleteEntityAction } from 'features/shared/DeleteEntityIcon/useDeleteEntityAction';
import routes from 'shared/routes';
import isNotNil from 'shared/utils/isNotNill';

import { useRedirectToPermissionAction } from '../EndpointPermissions';

interface Props {
  workspaceName: string;
  organizationId: string | undefined;
  endpointId: string;
  endpoint: {
    __typename: 'Endpoint';
    allowedActions: { delete: boolean; update: boolean };
    id: string;
    description: string;
    owner: RequiredOwner;
  } | null;
}

export const useEndpointSettingsActions = (props: Props) => {
  return [
    useRedirectToPermissionAction({
      endpointId: props.endpointId,
      workspaceName: props.workspaceName,
    }),
    useEditResourceInformationMenuAction(props.endpoint),
    useDeleteEntityAction({
      entityType: 'endpoint',
      id: props.endpoint?.id ?? null,
      isNotAllowedToDelete: !props.endpoint?.allowedActions.delete,
      redirectUrl: routes.endpoints.getRedirectPath({
        workspaceName: props.workspaceName,
      }),
    }),
  ].filter(isNotNil);
};
