import { useMemo } from 'react';
import { gql } from '@apollo/client';
import { useApolloClient } from '@apollo/client';

import {
  useBreadcrumbItem,
  useAppBreadcrumbs,
} from 'shared/routes/layouts/AuthorizedLayout/AppBreadcrumbs';
import routes from 'shared/routes';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import RegisteredModelTypeInfo from 'shared/view/domain/Catalog/RegisteredModelTypeInfo';

import { useCatalogsPageBreadcrumbs } from './useCatalogsPageBreadcrumbs';
import { ModelBreadcrumbData } from './graphql-types/useModelPageBreadcrumbs.generated';

const MODEL_BREADCRUMBS_FRAGMENT = gql`
  fragment ModelBreadcrumbData on RegisteredModel {
    id
    name
    taskType
    dataType
    versions {
      versions {
        id
        stage
      }
    }
  }
`;

export const useModelPageBreadcrumbs = ({
  registeredModelId,
}: {
  registeredModelId: string;
}) => {
  const apolloClient = useApolloClient();

  const model = (() => {
    try {
      return apolloClient.readFragment<ModelBreadcrumbData>({
        id: `RegisteredModel:${registeredModelId}`,
        fragment: MODEL_BREADCRUMBS_FRAGMENT,
      });
    } catch {
      return null;
    }
  })();

  const topEnhancer = useMemo(
    () =>
      isNotError(model) ? (
        <RegisteredModelTypeInfo
          kind="title"
          modelId={registeredModelId}
          taskType={model.taskType}
          dataType={model.dataType}
          versions={model.versions.versions}
        />
      ) : undefined,
    [model, registeredModelId]
  );

  return useAppBreadcrumbs(
    useCatalogsPageBreadcrumbs(),
    useBreadcrumbItem({
      route: routes.registeredModel,
      hasTopEnhancer: true,
      ...(isNotError(model)
        ? { name: model.name, topEnhancer }
        : { isLoading: true }),
    })
  );
};
