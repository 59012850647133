import GitUrlParse from 'git-url-parse';

export interface GitInfoFromUrl {
  baseUrl: string | undefined;
  repositoryFullName: string;
}

export type MaybeGitInfoFromUrl =
  | { type: 'git'; value: GitInfoFromUrl }
  | { type: 'unknown'; value: string };

export const parseGitInfoFromUrl = (
  remoteRepoUrl: string
): MaybeGitInfoFromUrl => {
  try {
    const parsed = GitUrlParse(remoteRepoUrl);

    if (parsed.full_name || parsed.pathname) {
      return {
        type: 'git',
        value: {
          baseUrl:
            parsed.resource && remoteRepoUrl.includes(parsed.resource)
              ? parsed.resource
              : undefined,
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          repositoryFullName: parsed.full_name ?? parsed.pathname ?? '',
        },
      };
    }

    return { type: 'unknown', value: remoteRepoUrl };
  } catch {
    return { type: 'unknown', value: remoteRepoUrl };
  }
};
