/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { useMemo } from 'react';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import {
  convertGraphqlExperimentRun,
  EXPERIMENT_RUN_FRAGMENT,
} from 'shared/graphql/ExperimentRunDetails/ExperimentRunDetails';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { convertDefaultProject } from 'pages/authorized/ProjectsPages/ProjectDetailsPages/shared/layouts/useDefaultProjects/useDefaultProject';
import routes from 'shared/routes';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';

import * as GraphqlTypes from './graphql-types/useLoadingExperimentRunsForComparing.generated';

const EXPERIMENT_RUNS_FOR_COMPARING = gql`
  query ExperimentRunsForComparing($projectId: ID!, $ids: [ID!]!) {
    project(id: $projectId) {
      ... on Error {
        ...ErrorData
      }
      ... on Project {
        id
        name
        description
        dateUpdated
        allowedActions {
          ...AllowedActionsData
        }
        attributes {
          ...AttributeData
        }
        workspace {
          id
          ...WorkspaceData
        }
        description
        owner {
          ... on Group {
            id
            name
          }
          ... on User {
            id
            username
            fullName
          }
        }
        runs(query: { ids: $ids }) {
          runs {
            id
            ...ExperimentRunData
          }
          pagination {
            totalRecords
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${EXPERIMENT_RUN_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
`;

export const useLoadingExperimentRunsForComparing = ({
  comparedExperimentRunIds,
}: {
  comparedExperimentRunIds: string[];
}) => {
  const params = useRouteParams(routes.project);
  const projectId = useMemo(() => params.projectId, [params.projectId]);

  const queryResult = useCustomQuery<
    GraphqlTypes.ExperimentRunsForComparing,
    GraphqlTypes.ExperimentRunsForComparingVariables
  >(EXPERIMENT_RUNS_FOR_COMPARING, {
    variables: {
      ids: comparedExperimentRunIds,
      projectId,
    },
  });

  const { data, communication: loadingData } =
    useMemoizedResultToCommunicationWithData({
      memoizedConvert,
      queryResult,
    });

  return {
    data,
    loadingData,
  };
};

function memoizedConvert(res: GraphqlTypes.ExperimentRunsForComparing) {
  return mapDataOrError(res.project, (x) => ({
    project: convertDefaultProject(x),
    experimentRuns: x.runs.runs.map(convertGraphqlExperimentRun),
  }));
}
