/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { pipe } from 'ramda';

import { DataWithPagination } from 'shared/models/Pagination';
import { RegisteredModelVersionResult } from 'shared/models/HighLevelSearch/SearchResult';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { convertSortingToGraphql } from 'shared/graphql/sorting/converters';
import { changeSortingDateFieldName } from 'shared/models/Sorting';
import matchType from 'shared/utils/matchType';
import {
  isRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';

import {
  IHighLevelGraphqlSearchSettings,
  IHighLevelSearchFilter,
} from '../shared/types';
import * as GraphqlTypes from './graphql-types/loadRegisteredModelVersions.generated';
import convertHighLevelSearchFilterToGraphql from '../shared/converters';

const REGISTERED_MODEL_VERSION_RESULTS = gql`
  query RegisteredModelVersionResult(
    $workspaceName: String!
    $organizationId: ID
    $query: RegisteredModelVersionsQuery
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        registeredModelVersions(query: $query) {
          versions {
            id
            version
            dateCreated
            dateUpdated
            labels
            owner {
              ...OwnerData
            }
            registeredModel {
              id
              name
              workspace {
                id
                ...WorkspaceData
              }
            }
          }
          pagination {
            totalRecords
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
`;

export const loadRegisteredModelVersionResults = async ({
  apolloClient,
  filter,
  pagination,
  sorting,
  organizationId,
  workspaceName,
}: IHighLevelGraphqlSearchSettings): Promise<
  DataWithPagination<RegisteredModelVersionResult>
> => {
  const res = await apolloClient.query<
    GraphqlTypes.RegisteredModelVersionResult,
    GraphqlTypes.RegisteredModelVersionResultVariables
  >({
    query: REGISTERED_MODEL_VERSION_RESULTS,
    fetchPolicy: 'no-cache',
    variables: {
      workspaceName,
      organizationId,
      query: {
        stringPredicates: [convertHighLevelSearchFilter(filter)],
        sorting: sorting
          ? convertSortingToGraphql(
              changeSortingDateFieldName(
                { date_created: 'time_created', date_updated: 'time_updated' },
                sorting
              )
            )
          : undefined,
        pagination: {
          limit: pagination.pageSize,
          page: pagination.currentPage,
        },
      },
    },
  });
  if (
    isRestrictedGraphqlError(res.data.workspace) ||
    (res.errors || []).length > 0
  ) {
    return { totalCount: 0, data: [] };
  }
  return {
    data: res.data.workspace.registeredModelVersions.versions.map(
      (modelVersion) => {
        const result: RegisteredModelVersionResult = {
          entityType: 'registeredModelVersions',
          id: modelVersion.id,
          labels: modelVersion.labels,
          registeredModel: modelVersion.registeredModel,
          workspace: modelVersion.registeredModel.workspace,
          name: modelVersion.version,
          dateCreated: new Date(modelVersion.dateCreated),
          dateUpdated: new Date(modelVersion.dateUpdated),
          owner: modelVersion.owner,
        };
        return result;
      }
    ),
    totalCount:
      res.data.workspace.registeredModelVersions.pagination.totalRecords,
  };
};

const convertHighLevelSearchFilter = (filter: IHighLevelSearchFilter) => {
  return pipe(
    () =>
      convertHighLevelSearchFilterToGraphql(filter, {
        tagFilterToLabelFilter: true,
      }),
    (converted) =>
      matchType(
        {
          name: () => ({ ...converted, key: 'version' }),
          tag: () => converted,
        },
        filter.type
      )
  )();
};
