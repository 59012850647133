/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { DataWithPagination } from 'shared/models/Pagination';
import { ExperimentResult } from 'shared/models/HighLevelSearch/SearchResult';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { convertSortingToGraphql } from 'shared/graphql/sorting/converters';
import { OmitStrict } from 'shared/utils/types';
import {
  isRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';

import * as GraphqlTypes from './graphql-types/loadExperiments.generated';
import convertHighLevelSearchFilterToGraphql from '../shared/converters';
import { IHighLevelGraphqlSearchSettings } from '../shared/types';

const EXPERIMENT_RESULTS = gql`
  query ExperimentResults(
    $workspaceName: String!
    $organizationId: ID
    $query: ExperimentsQuery!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        experiments(query: $query) {
          pagination {
            totalRecords
          }
          experiments {
            name
            id
            dateCreated
            dateUpdated
            tags
            owner {
              id
              username
            }
            project {
              id
              name
              workspace {
                id
                ...WorkspaceData
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
`;

export const loadExperimentResults = async ({
  apolloClient,
  filter,
  pagination,
  sorting,
  organizationId,
  workspaceName,
}: IHighLevelGraphqlSearchSettings): Promise<
  DataWithPagination<OmitStrict<ExperimentResult, 'entityType'>>
> => {
  const res = await apolloClient.query<
    GraphqlTypes.ExperimentResults,
    GraphqlTypes.ExperimentResultsVariables
  >({
    query: EXPERIMENT_RESULTS,
    fetchPolicy: 'no-cache',
    variables: {
      workspaceName,
      organizationId,
      query: {
        stringPredicates: [
          convertHighLevelSearchFilterToGraphql(filter, {
            tagFilterToLabelFilter: false,
          }),
        ],
        sorting: sorting ? convertSortingToGraphql(sorting) : undefined,
        pagination: {
          limit: pagination.pageSize,
          page: pagination.currentPage,
        },
      },
    },
  });
  if (
    isRestrictedGraphqlError(res.data.workspace) ||
    (res.errors || []).length > 0
  ) {
    return { totalCount: 0, data: [] };
  }
  return {
    data: res.data.workspace.experiments.experiments.map((experiment) => ({
      ...experiment,
      dateCreated: new Date(experiment.dateCreated),
      dateUpdated: new Date(experiment.dateUpdated),
      owner: experiment.owner,
    })),
    totalCount: res.data.workspace.experiments.pagination.totalRecords,
  };
};
