import { gql } from '@apollo/client';

import useMutationFromFunction from 'shared/utils/graphql/useMutationFromFunction';
import matchType from 'shared/utils/matchType';
import { RegisteredModelVersionLockLevelWithoutUnknown } from 'shared/models/Registry/RegisteredModelVersion/LockLevel';
import noop from 'shared/utils/noop';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

import {
  OpenModelVersion,
  OpenModelVersionVariables,
} from './graphql-types/useChangeLockLevel.generated';

const LOCK_LEVEL_AND_ALLOWED_ACTIONS = gql`
  fragment LockLevelAndAllowedActions on RegisteredModelVersion {
    id
    lockLevel
    registeredModel {
      id
      allowedActions {
        unlock
        lock
        updateRedact
      }
    }
  }
`;

const CLOSE = gql`
  mutation CloseModelVersion($id: ID!) {
    registeredModelVersion(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        closeAndLoad {
          id
          ...LockLevelAndAllowedActions
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${LOCK_LEVEL_AND_ALLOWED_ACTIONS}
`;

const OPEN = gql`
  mutation OpenModelVersion($id: ID!) {
    registeredModelVersion(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        openAndLoad {
          id
          ...LockLevelAndAllowedActions
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${LOCK_LEVEL_AND_ALLOWED_ACTIONS}
`;

const REDACT = gql`
  mutation RedactModelVersion($id: ID!) {
    registeredModelVersion(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        redactAndLoad {
          id
          ...LockLevelAndAllowedActions
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${LOCK_LEVEL_AND_ALLOWED_ACTIONS}
`;

export const useChangeLockLevel = () =>
  useMutationFromFunction(
    (apolloClient) =>
      async ({
        registeredModelVersionId,
        lockLevel,
      }: {
        registeredModelVersionId: string;
        lockLevel: RegisteredModelVersionLockLevelWithoutUnknown;
      }) => {
        const mutation = matchType(
          {
            CLOSED: () => CLOSE,
            OPEN: () => OPEN,
            REDACT: () => REDACT,
          },
          lockLevel
        );
        return await apolloClient.mutate<
          OpenModelVersion,
          OpenModelVersionVariables
        >({ mutation, variables: { id: registeredModelVersionId } });
      },
    { context: 'changing lock level' },
    { onCompleted: noop, onError: noop }
  );
