import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '@mui/material';

import styles from './SubSection.module.css';

const SubSection = ({
  title,
  children,
}: {
  title?: string;
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  return (
    <div
      className={cn(styles.root)}
      style={{ color: theme.palette.text.primary }}
    >
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </div>
  );
};

export default SubSection;
