import { useTheme } from '@mui/material';
import cn from 'classnames';

import FormHelperText from 'shared/view/elements/FormHelperText/FormHelperText';

import InfoIcon from '../InfoIcon/InfoIcon';
import styles from './FieldWithTopLabel.module.css';

const FieldWithTopLabel = ({
  label,
  isRequired,
  children,
  meta,
  dataTest,
  withoutMarginBottom,
  info,
}: {
  label: string;
  meta?: { touched: boolean; error?: string };
  isRequired?: boolean;
  dataTest?: string;
  children: React.ReactNode;
  withoutMarginBottom?: boolean;
  info?: string;
}) => {
  const error: string | undefined =
    meta?.touched && meta.error ? meta.error : undefined;
  const theme = useTheme();

  return (
    <div
      className={cn(styles.labeledField, {
        [styles.required]: isRequired,
        [styles.withoutMarginBottom]: withoutMarginBottom,
      })}
    >
      <div
        className={styles.labeledField__label}
        style={{ color: theme.palette.text.primary }}
      >
        {label}
      </div>
      {info && (
        <div style={{ display: 'inline-block', marginLeft: '5px' }}>
          <InfoIcon tooltip={info} />
        </div>
      )}
      <div className={styles.labeledField__control}>{children}</div>
      <FormHelperText
        errorMessage={error}
        dataTestError={`${dataTest || 'field'}-error`}
        error={true}
      />
    </div>
  );
};

export default FieldWithTopLabel;
