import React from 'react';

import Select from 'shared/view/elements/Selects/Select/Select';

const VersionField = ({
  changeVersionId,
  modelVersions,
  versionId,
  isLoading,
}: {
  modelVersions: { version: string; id: string }[] | undefined;
  changeVersionId: (id: string) => void;
  versionId: string | undefined;
  isLoading?: boolean;
}) => {
  const options = (modelVersions || []).map((v) => ({
    label: v.version,
    value: v.id,
  }));

  const selectedOption = options.find((opt) => opt.value === versionId);

  return (
    <Select
      required={true}
      options={options}
      value={selectedOption}
      dataTest="registered-model-version"
      onChange={({ value }) => changeVersionId(value)}
      label="Select model version"
      isLoading={isLoading}
    />
  );
};

export default VersionField;
