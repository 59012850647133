import React from 'react';
import cn from 'classnames';
import { ScaleOrdinal } from 'd3-scale';
import { styled } from '@mui/material';

import matchType from 'shared/utils/matchType';

import styles from './CustomLegend.module.css';

export type LegendPosition = 'top' | 'right' | 'bottom';

export interface LegendProps<DomainType extends string> {
  scale: ScaleOrdinal<DomainType, string>;
  position: LegendPosition;
  title?: string;
  renderItem?: (item: DomainType, color: string) => React.ReactNode;
}

const defaultRenderItem = (item: string, color: string) => (
  <LegendItem label={item} color={color} />
);

function CustomLegend<DomainType extends string>({
  title,
  scale,
  position,
  renderItem = defaultRenderItem,
}: LegendProps<DomainType>) {
  const scaleDomain = scale.domain();

  return (
    <div
      className={cn(
        styles.root,
        matchType(
          {
            top: () => styles.position_top,
            right: () => '',
            bottom: () => styles.position_bottom,
          },
          position
        )
      )}
    >
      {title ? <div className={styles.title}>{title}</div> : null}
      <div className={styles.items}>
        {scaleDomain.map((item) => (
          <React.Fragment key={item}>
            {renderItem(item, scale(item))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export const LegendColorShape = styled('div')(
  (props: { color: string }) => `
  background-color: ${props.color};
  width: 1em;
  height: 1em;
  border-radius: 0.1em;
  margin-right: 10px;
  flex-shrink: 0;
`
);

export const LegendItem: React.FC<
  React.PropsWithChildren<{
    label: string;
    color: string;
    onClick?: () => void;
    isUnActive?: boolean;
  }>
> = ({ color, label, onClick, isUnActive }) => {
  return (
    <div
      className={cn(styles.legendItem, {
        [styles.legendItem_unActive]: isUnActive,
      })}
      onClick={onClick}
    >
      <LegendColorShape color={isUnActive ? '#bbb' : color} />
      <span
        className={styles.legendItem__label}
        title={label}
        data-test="legend-item-label"
      >
        {label}
      </span>
    </div>
  );
};

export default CustomLegend;
