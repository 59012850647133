/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { CUSTOM_ATTRIBUTE_DEFINITION_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeDefinition';
import { CUSTOM_ATTRIBUTE_CATEGORY_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeCategory';

export const ORGANIZATION_CUSTOM_ATTRIBUTES_QUERY = gql`
  query OrganizationCustomAttributes($organizationName: String!) {
    organizationV2ByName(name: $organizationName) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        name
        customAttributeCategories {
          ... on CustomAttributeCategories {
            customAttributeCategories {
              id
              ...CustomAttributeCategoryData
            }
          }
        }
        customAttributeDefinitions {
          ... on CustomAttributeDefinitions {
            customAttributeDefinitions {
              id
              ...CustomAttributeDefinitionData
            }
          }
        }
      }
    }
    workspaces {
      id
      registeredModels {
        models {
          id
          customAttributeValues {
            id
            customAttributeDefinitionId
            selectedValue
          }
        }
      }
      registeredModelVersions {
        versions {
          id
          customAttributeValues {
            id
            customAttributeDefinitionId
            selectedValue
          }
        }
      }
    }
  }
  ${CUSTOM_ATTRIBUTE_DEFINITION_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_CATEGORY_FRAGMENT}
`;

export const CREATE_OR_UPDATE_CUSTOM_ATTRIBUTE_DEFINITION_QUERY = gql`
  mutation CreateOrUpdateCustomAttributeDefinition(
    $organizationId: ID!
    $input: CustomAttributeDefinitionInput!
  ) {
    organizationV2(id: $organizationId) {
      ... on OrganizationV2 {
        id
        createOrUpdateCustomAttributeDefinition(input: $input) {
          ... on CustomAttributeDefinition {
            id
            ...CustomAttributeDefinitionData
          }
          ... on Error {
            ...ErrorData
          }
        }
      }
    }
  }
  ${CUSTOM_ATTRIBUTE_DEFINITION_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const DELETE_CUSTOM_ATTRIBUTE_QUERY = gql`
  mutation DeleteCustomAttribute(
    $organizationId: ID!
    $customAttributeId: ID!
  ) {
    organizationV2(id: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        deleteCustomAttributeDefinition(id: $customAttributeId) {
          id
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const CREATE_OR_UPDATE_CUSTOM_ATTRIBUTE_CATEGORY_QUERY = gql`
  mutation CreateOrUpdateCustomAttributeCategory(
    $organizationId: ID!
    $input: CustomAttributeCategoryInput!
  ) {
    organizationV2(id: $organizationId) {
      ... on OrganizationV2 {
        id
        createOrUpdateCustomAttributeCategory(input: $input) {
          ... on CustomAttributeCategory {
            id
            ...CustomAttributeCategoryData
          }
          ... on Error {
            ...ErrorData
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_CATEGORY_FRAGMENT}
`;

export const DELETE_CUSTOM_ATTRIBUTE_CATEGORY_MUTATION = gql`
  mutation DeleteCustomAttributeCategory(
    $organizationId: ID!
    $categoryId: ID!
  ) {
    organizationV2(id: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        deleteCustomAttributeCategory(id: $categoryId) {
          id
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;
