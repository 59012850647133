import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CreateRegisteredModelVersionFields } from 'features/registry/registeredModelVersion/createRegisteredModelVersion/view/CreateRegisteredModelVersionPopup/CreateRegisteredModelVersionPopup';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import routes from 'shared/routes';
import { WorkspaceData } from 'shared/graphql/Workspace/graphql-types/Workspace.generated';
import {
  getDefaultLockLevelForm,
  ILockLevelFormDeps,
} from 'shared/view/domain/Registry/ModelVersion/LockLevel/LockLevelForm/LockLevelFormModel';
import { IVersionCreationForm } from 'features/registry/registeredModelVersion/shared/FeatureLockLevelSelect/FeatureLockLevelSelect';
import { selectFlags } from 'features/flags';
import SubmitButton from 'shared/view/elements/Button/SubmitButton';
import OptionalTooltip from 'shared/view/elements/Tooltip/OptionalTooltip';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import { useCreateRegisteredModelVersion } from 'features/registry/registeredModelVersion/createRegisteredModelVersion/store/createRegisteredModelVersion/createRegisteredModelVersion';

interface ILocalProps {
  registeredModel: {
    id: string;
    name: string;
    allowedActions: {
      update: boolean;
    } & ILockLevelFormDeps['allowedActions'];
    workspace: WorkspaceData;
  };
}

const CreateRegisteredModelVersion: FC<
  React.PropsWithChildren<ILocalProps>
> = ({ registeredModel }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isEnableRegisteredModelVersionsLock } = useSelector(selectFlags);

  const queryParams = routes.createRegisteredModelVersion.parseQueryParams(
    location.search
  );

  const experimentRunIdQueryParam = queryParams?.experimentRunId;
  const initialSettings: IVersionCreationForm = useMemo(
    () => ({
      description: '',
      name: '',
      labels: [],
      experimentRunId: experimentRunIdQueryParam || '',
      ...getDefaultLockLevelForm(),
    }),
    [experimentRunIdQueryParam]
  );

  const { createVersion, creatingVersion } = useCreateRegisteredModelVersion({
    onSuccess: ({ versionId }) =>
      navigate(
        routes.registeredModelVersion.getRedirectPath({
          registeredModelId: registeredModel.id,
          versionId,
          workspaceName: registeredModel.workspace.name,
        })
      ),
  });

  const onSubmit = useCallback(
    (form: IVersionCreationForm) => {
      createVersion({ ...form, registeredModelId: registeredModel.id });
    },
    [createVersion, registeredModel.id]
  );

  return (
    <PresetFormik<IVersionCreationForm>
      initialValues={initialSettings}
      onSubmit={onSubmit}
    >
      {({ isValid, values, setFieldValue }) => (
        <FormStack>
          {CreateRegisteredModelVersionFields({
            lockLevel: values.lockLevel,
            setFieldValue,
            experimentRunId: experimentRunIdQueryParam,
            isEnableRegisteredModelVersionsLock,
            registeredModel,
          })}
          <span>
            <OptionalTooltip
              showTooltip={!registeredModel.allowedActions.update}
              type={'withSpan'}
              title={'You do not write access to create'}
            >
              <SubmitButton
                isLoading={creatingVersion.isRequesting}
                disabled={!isValid || !registeredModel.allowedActions.update}
                dataTest="create"
              >
                Create version
              </SubmitButton>
            </OptionalTooltip>
          </span>
        </FormStack>
      )}
    </PresetFormik>
  );
};

export default CreateRegisteredModelVersion;
