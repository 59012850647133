import { AddNullToArray } from 'shared/utils/types';

import {
  makeExpansionPropertyDescription,
  makeNestedExpansionPropertyDescription,
  NestedExpansionPropertyDescription,
} from '../CompareTable/PropertyDiffDescriptions/PropertyDiffDescription';

export function makeKeyedItemsDiff<D extends { key: string }>(settings: {
  diff: Array<D>;
  render(x: D): AddNullToArray<NestedExpansionPropertyDescription['children']>;
  name: string;
}) {
  return makeExpansionPropertyDescription({
    name: settings.name,
    children: settings.diff.map((keyDiff) =>
      makeNestedExpansionPropertyDescription({
        name: keyDiff.key,
        children: settings.render(keyDiff),
      })
    ),
  });
}
