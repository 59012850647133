import Grid from '@mui/material/Unstable_Grid2';
import { head } from 'ramda';

import { MonitoringMetricType } from 'generated/types';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import isNotNil from 'shared/utils/isNotNill';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { useZoomReset } from 'shared/utils/charts/zoom/useZoomReset';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import TimeSeriesFullScreenAction from 'features/monitoring/shared/TimeSeriesFullScreenAction/TimeSeriesFullScreenAction';
import NewTimeSeries from 'features/monitoring/shared/NewTimeSeries/NewTimeSeries';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { OverviewWidgetWrapper } from 'features/monitoring/overview/view/MonitoringOverview/shared/OverviewWidget/OverviewWidget';
import { useHover } from 'shared/utils/react/useHover';
import { useFullMetricQuery } from 'features/monitoring/widgets/store/fullMetric/useFullMetric';

interface Props {
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  output: MonitoringIODescription;
  metricType: MonitoringMetricType;
}

const MonitoringPerformanceWidget = (props: Props) => {
  const { handlers, isHovered } = useHover();

  const { data, communication } = useFullMetricQuery({
    metricType: props.metricType,
    output: props.output,
    widgetExternalDeps: props.widgetExternalDeps,
  });

  const { onReset, onSetReset } = useZoomReset();

  return (
    <OverviewWidgetWrapper {...handlers} minHeight="568px">
      <div style={{ width: '100%' }}>
        <Grid container>
          <DefaultMatchRemoteDataOrError
            data={data}
            communication={communication}
            context="loading prediction count time series"
          >
            {({ metricOverTime, metric }) => (
              <div style={{ width: '100%', height: '568px' }}>
                <NewTimeSeries
                  id="performance-metric-widget"
                  onSetReset={onSetReset}
                  metricOverTime={metricOverTime}
                  isHovered={isHovered}
                  metricType={props.metricType}
                  actions={[
                    <TimeSeriesFullScreenAction
                      key="fullscreen"
                      metricValue={parseGraphQLNumber(
                        isNotNullableRestrictedGraphqlError(metric)
                          ? head(metric)?.value ?? 'NaN'
                          : 'NaN'
                      )}
                      metricOverTime={metricOverTime}
                      metricType={props.metricType}
                      widgetExternalDeps={props.widgetExternalDeps}
                    />,
                    onReset ? (
                      <IconAwesomeClickable
                        key="zoom"
                        icon={ICONS.magnifyingGlassMinus}
                        size="lg"
                        onClick={onReset}
                      />
                    ) : null,
                  ].filter(isNotNil)}
                />
              </div>
            )}
          </DefaultMatchRemoteDataOrError>
        </Grid>
      </div>
    </OverviewWidgetWrapper>
  );
};

export default MonitoringPerformanceWidget;
