import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { SYSTEM_ADMIN_USER_FRAGMENT } from 'shared/graphql/SystemAdmin/SystemAdmin';
import {
  SystemAdminsDelete,
  SystemAdminsDeleteVariables,
} from 'features/systemAdmin/admins/list/store/graphql-types/useDeleteSystemAdmin.generated';
import routes from 'shared/routes';
import { setIsSystemAdmin } from 'features/systemAdmin/isAdmin/store';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';

const MUTATION = gql`
  mutation SystemAdminsDelete($usernameOrEmail: String!) {
    systemAdmins {
      id
      removeSystemAdmin(usernameOrEmail: $usernameOrEmail) {
        id
        selfIsSystemAdmin
        admins {
          id
          allowedActions {
            ...AllowedActionsData
          }
          user {
            id
            ...SystemAdminUserData
          }
        }
      }
    }
  }
  ${SYSTEM_ADMIN_USER_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;

export const useDeleteSystemAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteSystemAdmin, deletingSystemAdmin] = useCustomMutation<
    SystemAdminsDelete,
    SystemAdminsDeleteVariables
  >(
    MUTATION,
    { context: 'deleting system admin' },
    {
      onCompleted: (data) => {
        if (!data.systemAdmins.removeSystemAdmin.selfIsSystemAdmin) {
          dispatch(setIsSystemAdmin(false));
          navigate(routes.root.getRedirectPath({}));
        }
      },
    }
  );

  return {
    deleteSystemAdmin,
    deletingSystemAdmin,
  };
};
