import { Stack, Typography } from '@mui/material';

import {
  makeWidgetTitle,
  MonitoringWidget,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidget';
import { Size } from 'shared/utils/charts/chartSizes';
import isNotNil from 'shared/utils/isNotNill';
import { IconAwesomeDraggable } from 'shared/view/elements/IconAwesome/IconAwesomeDraggable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import BaseWidgetWrapper from '../BaseWidgetWrapper/BaseWidgetWrapper';
import { WidgetInfoIcon } from '../WidgetInfoIcon/WidgetInfoIcon';
import styles from './MonitoringWidgetWrapper.module.css';

interface Props {
  widget: MonitoringWidget | null;
  children: (size: Size) => JSX.Element;
  actions: (JSX.Element | null)[];
  draggable: boolean;
}

const MonitoringWidgetWrapper = (props: Props) => {
  const title = props.widget ? makeWidgetTitle(props.widget) : '';
  return (
    <BaseWidgetWrapper
      dataTest="widget"
      dataWidgetType={props.widget?.type}
      header={
        <div className={styles.caption}>
          <Typography
            variant="body1"
            title={title}
            data-test="widget-title"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </Typography>
          <div>
            {props.draggable ? (
              <IconAwesomeDraggable size="lg" icon={ICONS.gripDots} />
            ) : null}
          </div>
          <div className={styles.actions}>
            <Stack direction="row" alignItems="center" justifyContent="end">
              {[
                props.widget ? (
                  <WidgetInfoIcon
                    key="widget-info"
                    widgetType={props.widget.type}
                  />
                ) : null,
                ...props.actions,
              ].filter(isNotNil)}
            </Stack>
          </div>
        </div>
      }
    >
      {props.children}
    </BaseWidgetWrapper>
  );
};

export default MonitoringWidgetWrapper;
