export type NonEmptyArray<T> = [T, ...T[]];

export const head = <T>(a: NonEmptyArray<T>): T => a[0];

export const isNonEmptyArray = <T>(a: T[]): a is NonEmptyArray<T> =>
  a.length > 0;

export const isNonEmptyArrayOrUndefined = <T>(
  a: T[] | undefined
): a is NonEmptyArray<T> => Boolean(a && a.length > 0);
