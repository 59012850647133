import Stack from '@mui/material/Stack';

import Chip from 'shared/view/elements/Chip/Chip';
import { useAppBreadcrumbs } from 'features/layout/view/Layout/AppBreadcrumbs';
import { useMonitoringModelContext } from 'features/monitoring/models/model/store/state/context';
import MonitoringDashboardView from 'features/monitoring/dashboards/dashboard/view/MonitoringDashboardView/MonitoringDashboardView';
import { useMonitoringModelStateManager } from 'features/monitoring/models/model/store/state/useMonitoringModelStateManager';
import { getMonitoringWidgetExternalDepsFromModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import { MonitoringModelRegisteredModelVersion } from 'shared/models/Monitoring/MonitoringModel/MonitoringModelRegisteredModelVersion';

import { useMonitoringModelPageBreadcrumbs } from '../shared/useMonitoringModelPageBreadcrumbs';
import MonitoringDashboardLayout from '../MonitoringDashboardPage/MonitoringDashboardLayout/MonitoringDashboardLayout';

const MonitoringComparePage = () => {
  const { model, updateModelState } = useMonitoringModelContext();

  const {
    updateCompareDashboard,
    updateComparableModelVersions,
    createDashboard,
  } = useMonitoringModelStateManager({
    modelState: model.state,
    updateModelState,
    registeredModelVersions: model.registeredModelVersions,
  });

  const breadcrumbs = useAppBreadcrumbs(
    useMonitoringModelPageBreadcrumbs({
      modelName: model.name,
    })
  );

  return (
    <MonitoringDashboardLayout
      model={model}
      updateModelState={updateModelState}
      dashboard={model.state.compare.dashboard}
      updateDashboard={updateCompareDashboard}
      breadcrumbs={breadcrumbs}
      createDashboard={createDashboard}
    >
      <Stack spacing={2}>
        <div>
          <CompareVersionsSelection
            versions={model.registeredModelVersions}
            comparableVersions={model.state.compare.registeredModelVersions}
            updateComparableVersions={updateComparableModelVersions}
          />
        </div>
        <MonitoringDashboardView
          widgetExternalDeps={{
            ...getMonitoringWidgetExternalDepsFromModel(model),
            registeredModelVersionIds:
              model.state.compare.registeredModelVersions.map((v) => v.id),
          }}
          dashboard={model.state.compare.dashboard}
          updateDashboard={updateCompareDashboard}
        />
      </Stack>
    </MonitoringDashboardLayout>
  );
};

const CompareVersionsSelection = (props: {
  versions: MonitoringModelRegisteredModelVersion[];
  comparableVersions: MonitoringModelRegisteredModelVersion[];
  updateComparableVersions: (
    versions: MonitoringModelRegisteredModelVersion[]
  ) => void;
}) => {
  return (
    <Stack direction="row">
      {props.versions.map((version) => {
        const isActive = props.comparableVersions
          .map((v) => v.id)
          .includes(version.id);
        return (
          <Chip
            key={version.id}
            onClick={() => {
              if (isActive) {
                props.updateComparableVersions(
                  props.comparableVersions.filter((v) => v.id !== version.id)
                );
              } else {
                props.updateComparableVersions(
                  props.comparableVersions.concat(version)
                );
              }
            }}
            label={<DynamicTypography value={version.version} />}
            variant="outlined"
            color={isActive ? 'primary' : 'default'}
          />
        );
      })}
    </Stack>
  );
};

export default MonitoringComparePage;
