import ReactMarkdown from 'react-markdown';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Markdown } from 'shared/utils/types';

import 'github-markdown-css/github-markdown-light.css';

type Mode = 'default' | 'preview';

const MarkdownWrapper = styled(Box)<{ mode: Mode }>(
  ({ mode, theme }) => `
    .markdown-body {
      box-sizing: border-box;
      min-width: 200px;
      margin: 0 auto;
      padding: ${mode === 'default' ? '20px 40px' : '0px'};
      overflow: auto;
      color: ${theme.palette.text.primary};
      background-color: ${theme.palette.background.paper};
      ol, ul {
        list-style: initial;
      }
      blockquote, q {
        quotes: initial;
      }
    }

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      .markdown-body {
        padding: 5px 15px;
      }
    }
  `
);

interface ILocalProps {
  children: Markdown;
  mode?: Mode;
}

const MarkdownView = ({ children, mode = 'default' }: ILocalProps) => {
  return (
    <MarkdownWrapper mode={mode} data-test="markdown-text">
      <ReactMarkdown className={'markdown-body'}>{children}</ReactMarkdown>
    </MarkdownWrapper>
  );
};

export default MarkdownView;
