import { useCallback } from 'react';

import PypiConfigurationsForm from 'features/systemAdmin/integrations/pypi/view/form/shared/PypiConfigurationsForm';
import { useUpdatePypiConfiguration } from 'features/systemAdmin/integrations/pypi/store/useUpdatePypiConfiguration';
import { useDeletePypiConfiguration } from 'features/systemAdmin/integrations/pypi/store/useDeletePypiConfiguration';
import { PypiConfigurationFormValues } from 'features/systemAdmin/integrations/pypi/view/form/types/PypiConfigurationFormValues';

type Props = {
  id: string;
  values: PypiConfigurationFormValues;
};

export default function PypiConfigurationsFormUpdate(props: Props) {
  const { id, values } = props;

  const { updatePypiConfiguration, updatingPypiConfiguration } =
    useUpdatePypiConfiguration();

  const { deletePypiConfiguration, deletingPypiConfiguration } =
    useDeletePypiConfiguration();

  // todo refactoring: common issue for our WebApp - we need to handle all promises
  const handleSubmit = useCallback(
    (value: PypiConfigurationFormValues) => {
      updatePypiConfiguration({ ...value, id });
    },
    [updatePypiConfiguration, id]
  );

  const handleDelete = useCallback(() => {
    deletePypiConfiguration({ id });
  }, [deletePypiConfiguration, id]);

  return (
    <PypiConfigurationsForm
      initialValues={values}
      onDelete={handleDelete}
      isDeleting={deletingPypiConfiguration.isRequesting}
      onSave={handleSubmit}
      isSaving={updatingPypiConfiguration.isRequesting}
    />
  );
}
