import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { head } from 'ramda';

import { MonitoringMetricType } from 'generated/types';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { predictionCountOutput } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/SingleMetricWidget';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import isNotNil from 'shared/utils/isNotNill';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { useZoomReset } from 'shared/utils/charts/zoom/useZoomReset';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { formatWithDefaultPrecision } from 'shared/utils/formatters/formatWithDefaultPrecision';
import routes from 'shared/routes';
import { useHover } from 'shared/utils/react/useHover';
import NewTimeSeries from 'features/monitoring/shared/NewTimeSeries/NewTimeSeries';
import TimeSeriesFullScreenAction from 'features/monitoring/shared/TimeSeriesFullScreenAction/TimeSeriesFullScreenAction';
import { useFullMetricQuery } from 'features/monitoring/widgets/store/fullMetric/useFullMetric';

import { OverviewWidgetWrapperHoverable } from '../shared/OverviewWidget/OverviewWidget';

interface Props {
  widgetExternalDeps: MonitoringWidgetExternalDeps;
}

const MonitoringOverviewPredictionCountWidget = (props: Props) => {
  const { data, communication } = useFullMetricQuery({
    output: predictionCountOutput,
    metricType: MonitoringMetricType.PREDICTION_COUNT,
    widgetExternalDeps: props.widgetExternalDeps,
  });

  const { onReset, onSetReset } = useZoomReset();

  const { handlers, isHovered } = useHover();

  return (
    <OverviewWidgetWrapperHoverable {...handlers} minHeight="450px">
      <DefaultMatchRemoteDataOrError
        data={data}
        communication={communication}
        context="loading prediction count metric"
      >
        {({ metric, metricOverTime }) => {
          const predictionCountMetric = head(metric);
          return (
            <div style={{ width: '100%', paddingLeft: '12px' }}>
              <Grid container spacing={4}>
                <Grid xs={3}>
                  {predictionCountMetric ? (
                    <Stack sx={{ pt: '12px' }}>
                      <Typography variant="subtitle1">
                        Prediction count
                      </Typography>
                      <Typography variant="h3">
                        {Number(
                          formatWithDefaultPrecision(
                            parseGraphQLNumber(predictionCountMetric.value)
                          )
                        )}
                      </Typography>
                      <div style={{ opacity: isHovered ? 1 : 0 }}>
                        <InlineLink
                          to={routes.monitoringPerformance.getRedirectPath({
                            monitoringModelId:
                              props.widgetExternalDeps.monitoringModelId,
                            workspaceName:
                              props.widgetExternalDeps.workspaceName,
                          })}
                        >
                          Go to performance
                        </InlineLink>
                      </div>
                    </Stack>
                  ) : null}
                </Grid>
                <Grid xs={9}>
                  <div style={{ height: '422px' }}>
                    <NewTimeSeries
                      id="prediction-count-widget"
                      onSetReset={onSetReset}
                      metricOverTime={metricOverTime}
                      isHovered={isHovered}
                      metricType={MonitoringMetricType.PREDICTION_COUNT}
                      actions={[
                        <TimeSeriesFullScreenAction
                          key="fullscreen"
                          metricValue={parseGraphQLNumber(
                            head(metric)?.value ?? 'NaN'
                          )}
                          metricOverTime={metricOverTime}
                          metricType={MonitoringMetricType.PREDICTION_COUNT}
                          widgetExternalDeps={props.widgetExternalDeps}
                        />,
                        onReset ? (
                          <IconAwesomeClickable
                            key="zoom"
                            icon={ICONS.magnifyingGlassMinus}
                            size="lg"
                            onClick={onReset}
                          />
                        ) : null,
                      ].filter(isNotNil)}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          );
        }}
      </DefaultMatchRemoteDataOrError>
    </OverviewWidgetWrapperHoverable>
  );
};

export default MonitoringOverviewPredictionCountWidget;
