import { gql } from '@apollo/client';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import noop from 'shared/utils/noop';
import { combineCommunications } from 'shared/utils/redux/communication/combineCommunications';

import { QUERY as ORGANIZATION_GROUPS_QUERY } from './useOrganizationGroups';
import * as GraphqlTypes from './graphql-types/useDeleteGroup.generated';

const QUERY = gql`
  mutation DeleteGroup($organizationName: String!, $groupId: ID!) {
    organizationV2ByName(name: $organizationName) {
      ... on OrganizationV2 {
        id
        group(id: $groupId) {
          ... on Group {
            id
            delete
          }
        }
      }
    }
  }
`;

export const useDeleteGroup = (onCompleted?: () => void) => {
  const [run__, communication] = useCustomMutation<
    GraphqlTypes.DeleteGroup,
    GraphqlTypes.DeleteGroupVariables
  >(QUERY, {
    context: 'delete group',
  });

  const [reloadOrganizationGroups, reloadOrganizationGroupsRes] =
    useCustomLazyQuery(ORGANIZATION_GROUPS_QUERY, {
      onCompleted,
    });

  const run = (variables: GraphqlTypes.DeleteGroupVariables) => {
    run__(variables).then(() => {
      return reloadOrganizationGroups({
        variables: { name: variables.organizationName },
      });
    });
  };

  return {
    run,
    communication: combineCommunications([
      communication,
      resultToCommunicationWithData(noop, reloadOrganizationGroupsRes)
        .communication,
    ]),
  };
};
