import { unnest } from 'ramda';

import type * as GraphqlSchema from 'generated/types';
import { PickByValue, RecordValues } from 'shared/utils/types';

import { FieldObfuscator } from './graphqlRequestResponseObfuscator/graphqlRequestResponseObfuscator';
import {
  numberFieldObfuscator,
  arrayStringFieldObfuscator,
  stringFieldObfuscator,
  stringRecordFieldObfuscator,
  objectFieldObfuscator,
} from './graphqlRequestResponseObfuscator/obfuscators';
import * as RequestVariableMatchers from './graphqlRequestResponseObfuscator/requestVariableMatchers';

type GraphqlTypeByEntityTypename = {
  User: GraphqlSchema.User;
  Project: GraphqlSchema.Project;
  Experiment: GraphqlSchema.Experiment;
  ExperimentRun: GraphqlSchema.ExperimentRun;
  Dataset: GraphqlSchema.Dataset;
  DatasetVersion: GraphqlSchema.DatasetVersion;
  RegisteredModel: GraphqlSchema.RegisteredModel;
  RegisteredModelVersion: GraphqlSchema.RegisteredModelVersion;
  Endpoint: GraphqlSchema.Endpoint;
  PypiConfiguration: GraphqlSchema.PypiConfiguration;
};

type Match<T, P> = RecordValues<PickByValue<T, P>>;

const entityNames = [
  stringFieldObfuscator<Match<GraphqlTypeByEntityTypename, { name: string }>>({
    field: 'name',
    typenames: {
      Dataset: 'Dataset',
      Experiment: 'Experiment',
      ExperimentRun: 'ExperimentRun',
      Project: 'Project',
      RegisteredModel: 'RegisteredModel',
      PypiConfiguration: 'PypiConfiguration',
    },
    requestVariablesMatchers: [
      RequestVariableMatchers.whenVariableIsEqualToField,
    ],
  }),
  numberFieldObfuscator<
    Match<GraphqlTypeByEntityTypename, { version: number }>
  >({
    field: 'version',
    typenames: { DatasetVersion: 'DatasetVersion' },
    requestVariablesMatchers: [],
  }),
  stringFieldObfuscator<
    Match<GraphqlTypeByEntityTypename, { version: string }>
  >({
    field: 'version',
    typenames: { RegisteredModelVersion: 'RegisteredModelVersion' },
    requestVariablesMatchers: [
      RequestVariableMatchers.whenVariableIsEqualTo('modelVersion'),
    ],
  }),
  stringFieldObfuscator<Match<GraphqlTypeByEntityTypename, { path: string }>>({
    field: 'path',
    typenames: { Endpoint: 'Endpoint' },
    requestVariablesMatchers: [
      RequestVariableMatchers.whenVariableIsEqualToField,
    ],
  }),
];

const descriptions = stringFieldObfuscator<
  Match<GraphqlTypeByEntityTypename, { description: string }>
>({
  field: 'description',
  typenames: {
    Dataset: 'Dataset',
    DatasetVersion: 'DatasetVersion',
    Endpoint: 'Endpoint',
    Experiment: 'Experiment',
    ExperimentRun: 'ExperimentRun',
    Project: 'Project',
    RegisteredModel: 'RegisteredModel',
    RegisteredModelVersion: 'RegisteredModelVersion',
    User: 'User',
  },
  requestVariablesMatchers: [
    RequestVariableMatchers.whenVariableIsEqualToField,
  ],
});

const readme = stringFieldObfuscator<
  Match<GraphqlTypeByEntityTypename, { readmeText: string }>
>({
  field: 'readmeText',
  typenames: {
    Project: 'Project',
    RegisteredModel: 'RegisteredModel',
    RegisteredModelVersion: 'RegisteredModelVersion',
  },
  requestVariablesMatchers: [
    RequestVariableMatchers.whenVariableIsEqualTo('readme'),
  ],
});

const devKeys = [
  stringFieldObfuscator<Match<GraphqlTypeByEntityTypename, { devKey: string }>>(
    {
      field: 'devKey',
      typenames: {
        User: 'User',
      },
      requestVariablesMatchers: [
        RequestVariableMatchers.whenVariableIsEqualToField,
      ],
    }
  ),
  stringFieldObfuscator<
    Match<GraphqlTypeByEntityTypename, { secondaryDevKey?: string | null }>
  >({
    field: 'secondaryDevKey',
    typenames: {
      User: 'User',
    },
    requestVariablesMatchers: [
      RequestVariableMatchers.whenVariableIsEqualToField,
    ],
  }),
];

const usernames = [
  stringFieldObfuscator<{ __typename: string; username: string }>({
    field: 'username',
    typenames: 'any',
    requestVariablesMatchers: [
      RequestVariableMatchers.whenVariableIsEqualToField,
    ],
  }),
  stringFieldObfuscator<{ __typename: string; usernameOrEmail: string }>({
    field: 'usernameOrEmail',
    typenames: 'any',
    requestVariablesMatchers: [
      RequestVariableMatchers.whenVariableIsEqualToField,
    ],
  }),
];

const password = [
  stringFieldObfuscator<
    Match<GraphqlTypeByEntityTypename, { password: string }>
  >({
    field: 'password',
    typenames: {
      PypiConfiguration: 'PypiConfiguration',
    },
    requestVariablesMatchers: [
      RequestVariableMatchers.whenVariableIsEqualToField,
    ],
  }),
];

const tagsOrLabels = [
  arrayStringFieldObfuscator<
    Match<GraphqlTypeByEntityTypename, { tags: string[] }>
  >({
    field: 'tags',
    typenames: {
      Dataset: 'Dataset',
      DatasetVersion: 'DatasetVersion',
      Experiment: 'Experiment',
      ExperimentRun: 'ExperimentRun',
      Project: 'Project',
    },
    requestVariablesMatchers: [
      { name: 'tags', type: 'array' },
      { name: 'tag', type: 'single' },
    ],
  }),
  arrayStringFieldObfuscator<
    Match<GraphqlTypeByEntityTypename, { labels: string[] }>
  >({
    field: 'labels',
    typenames: {
      Endpoint: 'Endpoint',
      RegisteredModel: 'RegisteredModel',
      RegisteredModelVersion: 'RegisteredModelVersion',
    },
    requestVariablesMatchers: [
      { name: 'labels', type: 'array' },
      { name: 'label', type: 'single' },
    ],
  }),
];

// artifacts, metrics, hyperparameters, attributes
const keyedRecords = stringFieldObfuscator<
  GraphqlSchema.Artifact | GraphqlSchema.KeyValue
>({
  field: 'key',
  typenames: {
    Artifact: 'Artifact',
    BoolKeyValue: 'BoolKeyValue',
    FloatKeyValue: 'FloatKeyValue',
    JsonKeyValue: 'JsonKeyValue',
    StringKeyValue: 'StringKeyValue',
  },
  requestVariablesMatchers: [
    RequestVariableMatchers.whenVariableIsEqualToField,
  ],
});

const environments = [
  stringRecordFieldObfuscator<GraphqlSchema.ExperimentRun>({
    field: 'environment',
    typenames: {
      ExperimentRun: 'ExperimentRun',
    },
    requestVariablesMatchers: [],
  }),
  stringRecordFieldObfuscator<GraphqlSchema.RegisteredModelVersion>({
    field: 'environmentBlob',
    typenames: {
      RegisteredModelVersion: 'RegisteredModelVersion',
    },
    requestVariablesMatchers: [],
  }),
];

const codeVersion = objectFieldObfuscator<GraphqlSchema.CodeVersionBlob>({
  field: 'codeVersion',
  typenames: {
    CodeVersionBlob: 'CodeVersionBlob',
  },
  requestVariablesMatchers: [],
});

export const appObfuscators: FieldObfuscator[] = unnest([
  entityNames,
  descriptions,
  readme,
  devKeys,
  tagsOrLabels,
  keyedRecords,
  environments,
  password,
  usernames,
  codeVersion,
]);
