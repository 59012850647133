import { AxiosResponse } from 'axios';
import { Store, AnyAction } from 'redux';

import routes from 'shared/routes';
import { IApplicationState } from 'setup/store/store';
import { selectFlags } from 'features/flags';

const makeResponseParseErrorInterceptor = (
  store: Store<IApplicationState, AnyAction>
) => {
  return (response: AxiosResponse<unknown>) => {
    if (selectFlags(store.getState()).isEnableExternalAuth) {
      const contentType = response.headers['content-type'] as
        | string
        | undefined;
      if (contentType?.startsWith('text/html')) {
        const redirectBack = window.location.pathname;
        window.location.replace(routes.login.getRedirectPath({ redirectBack }));
      }
    }
    return response;
  };
};
export default makeResponseParseErrorInterceptor;
