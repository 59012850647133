import { Box, Stack, Typography } from '@mui/material';

import Chip from 'shared/view/elements/Chip/Chip';

import image from '../images/dashboard-feature.png';
import { Circle } from '../Circle';

export const DashboardFeature = () => (
  <Stack direction="row" spacing={0}>
    <Box width="65%" mr={4}>
      <img
        src={image}
        width="100%"
        height="auto"
        alt="dashboard feature example"
      />
    </Box>
    <Box width="35%" display="flex" alignItems="center">
      <Box>
        <Circle mb={5}>5</Circle>
        <Typography mb="12px" variant="subtitle1" color="text.secondary">
          Unified ML Dashboard
        </Typography>
        <Box maxWidth="306px">
          <Typography mb={2} variant="body2">
            Unify model tracking, performance monitoring and business impact in
            a single dashboard.
          </Typography>
        </Box>
        <Chip
          variant="outlined"
          label="Advanced tier feature"
          color="info"
          size="small"
        />
      </Box>
    </Box>
  </Stack>
);
