/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
  RestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { ExtractByTypename } from 'shared/utils/types';

import {
  CatalogWidget,
  CatalogWidgetVariables,
} from '../hooks/graphql-types/useHomepageCatalogWidget.generated';

type Workspace = ExtractByTypename<CatalogWidget['workspace'], 'Workspace'>;

const CATALOG_WIDGET = gql`
  query CatalogWidget(
    $workspaceName: String!
    $organizationId: ID
    $pagination: PaginationQuery!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        productionRegisteredModels: registeredModels(
          query: {
            stringPredicates: [
              { key: "stage", operator: EQ, value: "PRODUCTION" }
            ]
          }
        ) {
          pagination {
            totalRecords
          }
        }
        registeredModels(
          query: {
            sorting: { key: "time_updated", ascending: false }
            pagination: $pagination
          }
        ) {
          models {
            id
            name
            dateUpdated
            workspace {
              id
              name
            }
          }
          pagination {
            totalRecords
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

type UseHomepageCatalogWidgetProps = {
  data:
    | RestrictedGraphqlError
    | {
        models: Workspace['registeredModels']['models'];
        totalRecords: number;
        productionTotalRecords: number;
      }
    | undefined;
};

export const useHomepageCatalogWidget = (props: {
  workspaceName: string;
  organizationId: string | undefined;
  pagination?: { limit: number };
}) => {
  const { workspaceName, pagination = { limit: 3 } } = props;

  const query = useCustomQuery<CatalogWidget, CatalogWidgetVariables>(
    CATALOG_WIDGET,
    {
      variables: {
        workspaceName,
        pagination,
        organizationId: props.organizationId,
      },
    }
  );

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult: query,
  });
};

const convert = (res: CatalogWidget): UseHomepageCatalogWidgetProps['data'] => {
  return mapDataOrError(res.workspace, (workspace) => ({
    models: workspace.registeredModels.models,
    totalRecords: workspace.registeredModels.pagination.totalRecords,
    productionTotalRecords:
      workspace.productionRegisteredModels.pagination.totalRecords,
  }));
};
