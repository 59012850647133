import { useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MUIButton from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material/styles';
import { useLongPress, LongPressReactEvents } from 'use-long-press';

import { ButtonContent } from 'shared/view/elements/Button/shared/ButtonContent';
import {
  useButtonCommonProps,
  ButtonCommonProps,
} from 'shared/view/elements/Button/shared/useButtonCommonProps';
import { transientOptions } from 'shared/utils/transientOptions';
import { trackEvent } from 'setup/app/analytics';

export type ProgressButtonProps = ButtonCommonProps & {
  pressConfirmationInterval?: number;
  onClick?: (e: any) => void;
};

const Container = styled(
  Box,
  transientOptions
)<{
  $opacity: number;
  $variant: ProgressButtonProps['variant'];
}>(({ theme, $opacity: opacity, $variant: variant }) => ({
  backgroundColor:
    variant === 'outlined' || variant === 'text'
      ? 'transparent'
      : alpha(theme.palette.primary.main, opacity),
  borderRadius: theme.shape.borderRadius,
  width: 'fit-content',
}));

const Filler = styled(
  Box,
  transientOptions
)<{ $animate: boolean; $duration: number }>(
  ({ theme, $animate, $duration }) => ({
    width: $animate ? '100%' : '0%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    WebkitTransition: $animate ? `width ${$duration}s ease-in-out` : '',
    MozTransition: $animate ? `width ${$duration}s ease-in-out` : '',
    OTransition: $animate ? `width ${$duration}s ease-in-out` : '',
    transition: $animate ? `width ${$duration}s ease-in-out` : '',
  })
);

const ButtonContentWrapper = styled(
  Box,
  transientOptions
)(({ $adjustTop }: { $adjustTop?: number }) => ({
  position: 'relative',
  top: `-${$adjustTop ?? 0}px`,
  width: '100%',
  display: 'flex',
  whiteSpace: 'nowrap',
}));

export const ProgressButton = (props: ProgressButtonProps) => {
  const { height, ref } = useResizeDetector();
  const { height: buttonHeight, ref: buttonRef } = useResizeDetector();
  const { pressConfirmationInterval = 2500, onClick } = props;
  const { buttonProps, contentProps } = useButtonCommonProps(props);
  const { onClick: _onClick, children, ...other } = contentProps;

  const [animateFiller, setAnimateFiller] = useState<boolean>(false);

  const handleLongPressClick = (e: LongPressReactEvents) => {
    if (onClick) onClick(e);
    if (props.trackEvent) trackEvent(props.trackEvent);
  };

  const longPressOptions = {
    threshold: pressConfirmationInterval,
    onStart: () => setAnimateFiller(true),
    onCancel: () => setAnimateFiller(false),
  };

  const longPress = useLongPress(handleLongPressClick, longPressOptions);

  return (
    <span>
      <Container
        $opacity={!animateFiller ? 1 : 0.4}
        height={buttonHeight}
        $variant={props.variant}
      >
        <Filler
          ref={ref}
          $animate={animateFiller}
          $duration={pressConfirmationInterval / 1000}
          height={buttonHeight}
        />
        <ButtonContentWrapper $adjustTop={height} ref={buttonRef}>
          <MUIButton
            {...buttonProps}
            {...longPress()}
            type={'reset'}
            disableRipple
            sx={{
              color:
                (props.variant === 'outlined' || props.variant === 'text') &&
                animateFiller
                  ? 'primary.contrastText'
                  : undefined,
              background: 'transparent',
              '&:hover': { background: 'transparent' },
            }}
          >
            <ButtonContent {...other} isLoading={false}>
              Long press to {children}
            </ButtonContent>
          </MUIButton>
        </ButtonContentWrapper>
      </Container>
    </span>
  );
};

export default ProgressButton;
