import cn from 'classnames';
import { isNil } from 'ramda';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Box } from '@mui/material';

import { Primitive } from 'shared/utils/types';
import NA from 'shared/view/elements/PageComponents/NA/NA';
import { CustomAttributeType } from 'generated/types';
import { IAttribute } from 'shared/models/Attribute';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';

// eslint-disable-next-line postcss-modules/no-unused-class
import styles from '../CompareTable.module.css';
import { UserRecordInfo } from '../CustomAttributesCells/UserRecordInfo';
import { GroupRecordInfo } from '../CustomAttributesCells/GroupRecordInfo';

export type RowDiffRenderResult = Primitive | JSX.Element;

export default function RowDiff<T>(props: {
  name: string;
  data: Array<
    T & {
      attribute?: IAttribute & { type?: string; icon?: keyof typeof ICONS };
    }
  >;
  render: (data: T) => RowDiffRenderResult;
  isDifferent: boolean;
}) {
  return (
    <tr
      className={cn(styles.rowDiff, {
        [styles.different]: props.isDifferent,
      })}
    >
      <td className={styles.rowDiff__name}>
        {props.name}
        {props.data.length > 0 && props.data[0].attribute?.icon ? (
          <Box width="18px" display="inline-flex" ml={0.5}>
            <IconAwesomeInfo
              icon={ICONS[props.data[0].attribute.icon] as IconDefinition}
              size="sm"
            />
          </Box>
        ) : null}
      </td>
      {props.data.map((datum, i) => {
        const renderedDatum = (() => {
          if (datum.attribute?.type === CustomAttributeType.USER) {
            return <UserRecordInfo id={datum.attribute.value} />;
          }

          if (datum.attribute?.type === CustomAttributeType.GROUP) {
            return <GroupRecordInfo id={datum.attribute.value} />;
          }

          if (datum.attribute?.type === CustomAttributeType.CUSTOM_LIST) {
          }

          const x = props.render(datum);
          return typeof x === 'boolean' ? x.toString() : x;
        })();
        return (
          <td className={styles.rowDiff__value} key={i}>
            {isNil(renderedDatum) || renderedDatum === '' ? NA : renderedDatum}
          </td>
        );
      })}
    </tr>
  );
}
