import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import manage2 from '../../../images/manage-users-2.png';

export const ManageUserAdmins = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Manage Users
    </Typography>
    <Typography variant="body2" mb={3}>
      Admins can add other users to the admin list after they sign up.
    </Typography>
    <img src={manage2} width="90%" height="auto" alt="edit user action" />
  </WizardStepContent>
);
