import { Typography, Stack } from '@mui/material';
import { last } from 'lodash';

import { Endpoint } from 'features/deployment/canary/endpoints/store/endpointQuery/endpointQuery';
import SubSection from 'features/deployment/canary/shared/SubSection/SubSection';
import {
  getBuildWithCanaryRolloutPercentage,
  getEndpointEnvironmentComponentsWithBuilds,
  getEnvironment,
} from 'shared/models/Deployment/canary/Endpoint';
import CanaryStrategy from 'shared/view/domain/Deployment/CanaryStrategy/CanaryStrategy';
import MachineConfigInfoView from 'features/deployment/canary/endpoints/view/EndpointUsage/MachineConfigInfo/MachineConfigInfo';
import Placeholder from 'shared/view/elements/Placeholder/Placeholder';
import UpdateEndpointSection from 'features/deployment/canary/updateEndpoint/view/UpdateEndpointSection/UpdateEndpointSection';
import PageCard from 'shared/view/elements/PageComponents/PageCard/PageCard';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { KafkaConfigurations } from 'generated/types';
import CardLayout from 'shared/view/elements/CardLayout/CardLayout';
import { ModelServing } from 'shared/view/domain/Deployment/ModelServing/ModelServing';
import InfoIcon from 'shared/view/elements/InfoIcon/InfoIcon';

const EndpointSettingsPageContent = ({
  endpoint,
  kafkaConfigurations,
}: {
  endpoint: Endpoint;
  kafkaConfigurations: Pick<
    KafkaConfigurations['configurations'][0],
    'id' | 'name' | 'enabled'
  >[];
}) => {
  const environment = getEnvironment(endpoint);
  const canaryStrategy = environment?.updateRequest?.canaryStrategy;

  const canaryRules = environment?.canaryRules;
  const machineConfig = environment?.updateRequest;
  const autoscalingMetrics = environment?.autoscalingMetrics;

  const organizationId = useCurrentOrganizationV2();

  const lastBuild = last(
    getEndpointEnvironmentComponentsWithBuilds(endpoint).map((c) => ({
      ...c,
      build: getBuildWithCanaryRolloutPercentage(
        c.build,
        getEnvironment(endpoint)?.rolloutStatus
      ),
    }))
  )?.build;

  return (
    <Stack direction={'column'} spacing={3}>
      <CardLayout
        hasPadding
        header={{
          leftContent: (
            <Stack direction="row" alignItems="center">
              <Typography variant="subtitle2" color="text.primary">
                Model serving
              </Typography>
              <InfoIcon
                tooltip="Update the registered model and/or version for the
                  endpoint to initiate a new rollout. You will be able
                  to trigger a new build or select from existing ones."
                tooltipPlacement="bottom"
              />
            </Stack>
          ),
        }}
      >
        <ModelServing lastBuild={lastBuild} />
      </CardLayout>
      {canaryStrategy && canaryRules ? (
        <PageCard>
          <SubSection title="Rules">
            <CanaryStrategy
              canaryStrategy={canaryStrategy}
              canaryRules={canaryRules}
            />
          </SubSection>
        </PageCard>
      ) : null}
      {machineConfig && autoscalingMetrics ? (
        <MachineConfigInfoView
          machineConfig={machineConfig}
          canonicalAutoscalingMetrics={autoscalingMetrics}
          render={(x) => <PageCard>{x}</PageCard>}
        />
      ) : null}
      {environment ? (
        <UpdateEndpointSection
          kafkaConfigurations={kafkaConfigurations}
          organizationId={organizationId}
          endpoint={endpoint}
          environment={environment}
        />
      ) : (
        <Placeholder>You don't have an environment</Placeholder>
      )}
    </Stack>
  );
};

export default EndpointSettingsPageContent;
