import { useZoomReset } from 'shared/utils/charts/zoom/useZoomReset';
import MonitoringWidgetView from 'features/monitoring/widgets/view/MonitoringWidgetView/MonitoringWidgetView';
import MonitoringWidgetWrapper from 'shared/view/domain/Monitoring/MonitoringWidget/MonitoringWidgetWrapper/MonitoringWidgetWrapper';
import ZoomResetAction from 'features/monitoring/widgets/view/shared/actions/ZoomResetAction/ZoomResetAction';
import { CommonMonitoringWidgetProps } from 'features/monitoring/widgets/view/MonitoringWidgetView/shared/types';

import MonitoringWidgetFullScreenAction from '../shared/actions/MonitoringWidgetFullScreenAction/MonitoringWidgetFullScreenAction';
import AlertAction from '../shared/actions/AlertAction/AlertAction';
import { useAssociatedAlert } from '../shared/useAssociatedAlert';

const StaticMonitoringWidget = (props: CommonMonitoringWidgetProps) => {
  const { onReset, onSetReset } = useZoomReset();

  const alert = useAssociatedAlert({
    alerts: props.widgetExternalDeps.alerts,
    ioDescriptions: props.widgetExternalDeps.ioDescriptions,
    widget: props.widget,
  });

  return (
    <MonitoringWidgetWrapper
      widget={props.widget}
      draggable={true}
      actions={[
        <AlertAction
          key="alert"
          alert={alert}
          monitoringModelId={props.widgetExternalDeps.monitoringModelId}
          workspaceName={props.widgetExternalDeps.workspaceName}
        />,
        <ZoomResetAction key="reset" onZoomReset={onReset} />,
        <MonitoringWidgetFullScreenAction
          key="fullScreen"
          widgetExternalDeps={props.widgetExternalDeps}
          widget={props.widget}
          updateWidget={undefined}
        />,
      ]}
    >
      {(size) => (
        <MonitoringWidgetView
          {...props}
          id={props.widget.id}
          onSetReset={onSetReset}
          size={size}
        />
      )}
    </MonitoringWidgetWrapper>
  );
};

export default StaticMonitoringWidget;
