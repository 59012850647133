import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';
import { CustomRedirect } from 'shared/routes/shared/CustomRedirect';

import OrganizationGroupsPage from './OrganizationGroupsPage/OrganizationGroupsPage';
import OrganizationRolesPage from './OrganizationRolesPage/OrganizationRolesPage';
import OrganizationUsersPage from './OrganizationUsersPage/OrganizationUsersPage';
import OrganizationWorkspacesPage from './OrganizationWorkspacesPage/OrganizationWorkspacesPage';
import OrganizationGovernancePage from './OrganizationGovernancePage/OrganizationGovernancePage';
import OrganizationCustomAttributesPage from './OrganizationCustomAttributesPage/OrganizationCustomAttributesPage';

export const organizationPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.organizationUsers.getPath(),
      element: <OrganizationUsersPage />,
    },
    {
      path: routes.organizationGroups.getPath(),
      element: <OrganizationGroupsPage />,
    },
    {
      path: routes.organizationRolesAndPermissions.getPath(),
      element: <OrganizationRolesPage />,
    },
    {
      path: routes.organizationWorkspaces.getPath(),
      element: <OrganizationWorkspacesPage />,
    },
    {
      path: routes.organizationGovernance.getPath(),
      element: <OrganizationGovernancePage />,
    },
    {
      path: routes.organizationCustomAttributes.getPath(),
      element: <OrganizationCustomAttributesPage />,
    },
    {
      path: `${routes.organization.getPath()}/*`,
      element: <CustomRedirect route={routes.organizationUsers} />,
    },
  ],
};
