export enum StepName {
  KEY_CONCEPTS = 'Key concepts',
  MODEL_VERSION = 'Model versions',
  MODEL_CARD = 'Model card',
  MODEL_CATALOG = 'Model catalog',
}

type CopyProps = {
  [K in StepName]: string[] | JSX.Element[];
};

export const firstCopy: CopyProps = {
  'Key concepts': [
    `Welcome to Verta! Let's start your journey by understanding key concepts for a successful model catalog
implementation.\n
Our platform simplifies the model lifecycle, from experiment tracking to deployment and monitoring, with 
integrated dashboards. With our Model Catalog, everything is seamlessly connected.`,
    `The Model Catalog is accessible to all users of our platform.\n
The two key concepts in the catalog are Models (also known as model cards) and Model Versions.`,
    `The Model Catalog homepage is your AI hub, featuring a collection of your model cards. As you build your catalog, 
it becomes the centralized record and source of truth for every model in your entire organization.`,
  ],
  'Model versions': [
    <>
      Most detailed records are stored on model versions, containing technical
      details and relevant information
      <br />
      about the model. Scientists and engineers will find this most familiar.
    </>,
    <>
      The Reproduce page on model versions stores all lineage information,
      preferred by data/ML scientists.
      <br />
      <br />
      You can record model environment details, code versions, datasets,
      experiment runs that led to this
      <br />
      model, and a range of visualizable custom metrics, including model test
      results. Most of this information
      <br />
      is recorded during version registration via the Verta client.
    </>,
    <>
      The Integrate page on model versions provides engineers with key
      information about the model.
      <br />
      <br />
      Here, you can access the model's API contract (what it expects and
      returns) and find the model's
      <br />
      artifacts. This information is captured during registration via the
      client. Additionally, you can conveniently
      <br />
      upload any file you need to attach to this model version as an artifact
      via the UI at a later time.
    </>,
    <>
      The Release page is where teams collaborate to address two vital
      questions: Is this model being used anywhere? And if not, is it ready for
      publication?
      <br />
      <br />
      This page tracks all releases, whether they are Verta endpoints or
      deployments in another system.
      <br />
      Vulnerability scanning and release checklists determine the model's
      readiness for the next development
      <br />
      stage.
    </>,
    <>
      Each model version is assigned a stage for easy visibility of its current
      usage and potential replacements. The lifecycle stages include{' '}
      <strong>development</strong>, <strong>staging</strong>,{' '}
      <strong>production</strong>, and <strong>archived.</strong>
      <br />
      <br />
      To ensure your team stays aligned on model usage and minimizes risks,
      accurately tracking and <br />
      maintaining model stages is crucial.
    </>,
  ],
  'Model card': [
    <>
      All versions of a model will be collated into an automatically generated{' '}
      <strong>model card</strong>.
      <br />
      <br />
      The model card prominently displays the currently live model's API and
      status, directing users to the most important version at a glance.
    </>,
    <>
      <strong>Documentation</strong>, including AI-assisted model documentation,
      is available for every version and
      <br />
      conveniently collated on the model card for fast access.
    </>,
    <>
      The <strong>activity log</strong> on your model card summarizes all the
      activity and changes happening across the
      <br />
      versions. You can filter, sort, and download the activity log for status
      reporting and auditing purposes.
    </>,
  ],
  'Model catalog': [
    `Once all your model cards are populated, your catalog becomes a powerful view into all the models, their
status, and details across your entire organization.\n
All current and prior ML work is neatly organized, linked, reproducible, searchable, and filterable – quite
impressive, right? That's the Model Catalog.`,
  ],
};

export const footerCopy = {
  'Key concepts': [
    `Explore the core catalog functionalities for now. Later, you'll dive into enterprise features like RBAC,
integrations, and webhooks.`,
    `Models contain multiple versions. Each model serves as an ML task specifically designed to solve
a business problem. Versions act as the releasable units, encompassing stages, endpoint publishing, 
lineage records, model testing results, and more.`,
    `Clicking a model card offers a crash course on that model with insights, like its API contract and problem-
solving focus. More on this later.`,
  ],
};
