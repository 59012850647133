import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import {
  DELETE_CUSTOM_ATTRIBUTE_CATEGORY_MUTATION,
  DeleteCustomAttributeCategory,
  DeleteCustomAttributeCategoryVariables,
} from '../graphql';

type Props = {
  onCompleted: () => void;
};

export const useDeleteCustomAttributeCategory = ({ onCompleted }: Props) => {
  const [deleteCustomAttributeCategory, communication] = useCustomMutation<
    DeleteCustomAttributeCategory,
    DeleteCustomAttributeCategoryVariables
  >(
    DELETE_CUSTOM_ATTRIBUTE_CATEGORY_MUTATION,
    {
      context: 'deleting custom attribute category',
    },
    {
      onCompleted,
      update: (cache, _data, { variables }) => {
        if (variables?.categoryId) {
          const normalizedId = cache.identify({
            id: variables.categoryId,
            __typename: 'CustomAttributeCategory',
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        }
      },
    }
  );

  return {
    deleteCustomAttributeCategory,
    communication,
  };
};
