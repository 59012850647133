import { Box, BoxProps, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { transientOptions } from 'shared/utils/transientOptions';

export const HeatmapCell = styled(
  (props: BoxProps & { $value: string }) => <Box {...props} />,
  transientOptions
)(({ theme, $value }) => {
  const backgroundColor: string =
    $value === 'Degraded'
      ? alpha(theme.palette.common.black, 0.08)
      : $value === 'Unhealthy'
        ? theme.palette.error.main
        : alpha(theme.palette.primary.light, 0.5);
  return {
    minWidth: 47,
    minHeight: 47,
    borderRadius: 4,
    backgroundColor,
  };
});
