import { head } from 'ramda';
import { useState } from 'react';

import {
  FilterDefinition,
  getDefaultOperator,
} from 'shared/models/Filters/FilterDefinition';
import { Filter, getNewFilterId } from 'shared/models/Filters/Filter';
import { updateById } from 'shared/utils/collection';
import matchBy from 'shared/utils/matchBy';

interface Props {
  filterDefinitions: FilterDefinition[];
  initialFilters: Filter[];
}

export const useFiltersForm = (props: Props) => {
  const [filters, setFilters] = useState(props.initialFilters);

  const addFilter = () => {
    const first = head(props.filterDefinitions);
    if (first) {
      const defaultFilter = {
        ...first,
        id: getNewFilterId(filters),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        operator: getDefaultOperator(first) as any,
        value: matchBy(
          first,
          'type'
        )<Filter['value']>({
          number: () => 0,
          string: () => '',
        }),
      } as Filter;
      setFilters(filters.concat(defaultFilter));
    }
  };

  const removeFilter = (id: number) =>
    setFilters(filters.filter((f) => f.id !== id));

  const updateFilter = (filter: Filter) =>
    setFilters(updateById(() => filter, filter.id, filters));

  return {
    filters,
    addFilter,
    removeFilter,
    updateFilter,
  };
};
