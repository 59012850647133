import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import checklistCompleteTimestamp from '../images/checklist-complete-timestamp.png';

export const ChecklistCompleteTimestamp = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Complete items on a checklist
    </Typography>
    <Typography variant="body2" mb={3}>
      Each time an item is completed, we log the user and timestamp of the
      acknowledgment. You can easily view this information by hovering over
      completed items.
    </Typography>
    <img
      src={checklistCompleteTimestamp}
      width="100%"
      height="auto"
      alt="checklist complete shows timestamp"
    />
  </WizardStepContent>
);
