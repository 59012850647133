import { KeyValuePairsDiff } from 'shared/models/CompareEntities/new/keyValuePairsComparing';
import { Primitive } from 'shared/utils/types';

import {
  makeExpansionPropertyDescription,
  makeRowDiffDescription,
} from '../CompareTable/PropertyDiffDescriptions/PropertyDiffDescription';

export const makeKeyValuePairsDiff = (settings: {
  name: string;
  diff: KeyValuePairsDiff<Primitive>;
}) => {
  return makeExpansionPropertyDescription({
    name: settings.name,
    children: settings.diff.map((keyValueDiff) =>
      makeRowDiffDescription({
        isDifferent: keyValueDiff.isDifferent,
        data: keyValueDiff.properties.value.valuesWithItems,
        name: keyValueDiff.key,
        render: ({ value }) => value,
      })
    ),
  });
};
