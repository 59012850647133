import { pipe } from 'ramda';

import isNotNil from 'shared/utils/isNotNill';
import { NonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';

import { CrossRunDashboard, addWidget, addPanel } from './CrossRunDashboard';
import {
  getExperimentRunsWithDisplayedFields,
  getKeyValueSpecs,
  RequiredExperimentRun,
} from './CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import { KeyValueSpec } from './CrossRunWidget/CrossRunCustomWidget/KeyValueSpec';
import {
  BoxPlotSettings,
  getBoxPlotAxisesDataSpecs,
} from './CrossRunWidget/CrossRunCustomWidget/WidgetSettings/BoxPlotSettings';
import { emptyCustomDomain } from './CrossRunWidget/CrossRunCustomWidget/WidgetSettings/shared/CustomDomain';
import {
  generateMetricsCharts,
  generateParallelCoordinateChart,
} from './defaultWidgets';
import { defaultPanelSettings } from './defaultPanel';
import { ObservationChartsSettings } from './CrossRunWidget/CrossRunCustomWidget/WidgetSettings/ObservationChartsSettings';

export const defaultCrossRunDashboardName =
  'default-dashboard' as CrossRunDashboard['name'];

export const make = (
  id: string,
  experimentRuns: NonEmptyArray<RequiredExperimentRun>
): CrossRunDashboard =>
  addDefaultWidgets(
    {
      id,
      name: defaultCrossRunDashboardName,
      panels: [],
    },
    experimentRuns
  );

const addDefaultWidgets = (
  dashboard: CrossRunDashboard,
  experimentRuns: RequiredExperimentRun[]
): CrossRunDashboard => {
  return pipe(
    () => addPanel(dashboard, defaultPanelSettings),
    (x) =>
      generateDefaultCustomWidgetsSettings(
        experimentRuns
      ).reduce<CrossRunDashboard>(
        (resDashboard, settings, index) =>
          addWidget(
            resDashboard,
            defaultPanelSettings.id,
            settings,
            String(index)
          ),
        x
      )
  )();
};

const generateDefaultCustomWidgetsSettings = (
  experimentRuns: RequiredExperimentRun[]
) => {
  const experimentRunsWithDisplayedFields =
    getExperimentRunsWithDisplayedFields(experimentRuns);
  const keyValueSpecs = getKeyValueSpecs(experimentRunsWithDisplayedFields);

  return [
    ...generateMetricsCharts(keyValueSpecs),
    generateMetricVsHyperparameterChart(keyValueSpecs),
    generateParallelCoordinateChart(
      keyValueSpecs,
      experimentRunsWithDisplayedFields
    ),
    generateObservationCharts(experimentRuns),
  ].filter(isNotNil);
};

const generateObservationCharts = (
  experimentRuns: RequiredExperimentRun[]
): ObservationChartsSettings | undefined =>
  experimentRuns.some(({ observations }) => observations.length > 0)
    ? {
        type: 'observationCharts',
        name: 'Observations',
      }
    : undefined;

const generateMetricVsHyperparameterChart = (
  keyValueSpecs: KeyValueSpec[]
): BoxPlotSettings | undefined => {
  const chartDataSpecs = getBoxPlotAxisesDataSpecs(keyValueSpecs);

  const yAxisMetric = chartDataSpecs.yAxis.find(
    ({ subtype }) => subtype === 'metric'
  );
  const xAxisHyperparameter = chartDataSpecs.xAxis.keyValue.find(
    ({ subtype }) => subtype === 'hyperparameter'
  );

  return yAxisMetric && xAxisHyperparameter
    ? {
        name: `${yAxisMetric.key} vs ${xAxisHyperparameter.key}`,
        type: 'boxPlot',
        yAxis: {
          domain: emptyCustomDomain,
          isLogScale: false,
          spec: yAxisMetric,
        },
        xAxis: {
          domain: emptyCustomDomain,
          isLogScale: false,
          spec: xAxisHyperparameter,
        },
      }
    : undefined;
};
