import {
  Box,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material';

import {
  OnboardingProps,
  useOnboardingContext,
} from 'features/homepage/hooks/useOnboarding';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS, ICONS_SOLID } from 'shared/view/elements/IconAwesome/ICONS';

const CircleIconWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  width: '32px',
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `2px solid ${theme.palette.action.disabled}`,
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  // Change styles for the className only inside the styled components
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    borderTop: 'none',
    borderBottom: `1px dashed ${theme.palette.action.disabled}`,
  },
}));

const steps = ['step1', 'step2', 'step3', 'step4'];

const StepIcon = ({ isDone }: { isDone: boolean }) =>
  isDone ? (
    <IconAwesomeInfo
      icon={ICONS_SOLID.circleCheck}
      size="2xl"
      color="success.main"
    />
  ) : (
    <CircleIconWrapper>
      <IconAwesomeInfo icon={ICONS.check} size="sm" />
    </CircleIconWrapper>
  );

export const HomepageStepper = () => {
  const { stepsValues } = useOnboardingContext();

  return (
    <Stepper alternativeLabel connector={<ColorlibConnector />}>
      {steps.map((step) => {
        const isDone = stepsValues[step as keyof OnboardingProps];
        return (
          <Step key={step}>
            <StepLabel StepIconComponent={() => StepIcon({ isDone })} />
          </Step>
        );
      })}
    </Stepper>
  );
};
