import { equals, isNil, map, mergeAll, pipe } from 'ramda';
import { useState, useEffect, useCallback, useMemo } from 'react';

import { filterByType, makeObjectFromArray } from 'shared/utils/collection';
import { usePrevious } from 'shared/utils/react/usePrevious';

import { PropertyDiffDescriptions } from './PropertyDiffDescriptions';

type PropertiesExpansions = Record<
  string,
  Record<string, boolean | undefined> | undefined
>;

export function usePropertiesExpansions(
  comparedEntitiesNumber: number,
  propertiesDiffDescriptions: PropertyDiffDescriptions
) {
  const [propertiesExpansions, setPropertiesExpansions] =
    useState<PropertiesExpansions>(
      expandAllProperties(propertiesDiffDescriptions)
    );

  const toggle = useCallback(
    (name: string) =>
      setPropertiesExpansions({
        ...propertiesExpansions,
        [name]: propertiesExpansions[name] ? undefined : {},
      }),
    [propertiesExpansions]
  );

  const checkIsExpanded = useCallback(
    (name: string) => Boolean(propertiesExpansions[name]),
    [propertiesExpansions]
  );

  const getNestedExpansions = useCallback(
    (name: string) =>
      Object.entries(propertiesExpansions[name] || {})
        .filter(([_, isExpanded]) => isExpanded)
        .map(([nestedName]) => nestedName),
    [propertiesExpansions]
  );

  const toggleNestedExpansion = useCallback(
    (name: string, nestedName: string) => {
      return setPropertiesExpansions({
        ...propertiesExpansions,
        [name]: {
          ...propertiesExpansions[name],
          [nestedName]: !propertiesExpansions[name]?.[nestedName],
        },
      });
    },
    [propertiesExpansions]
  );
  const collapseAll = useCallback(
    () => setPropertiesExpansions({}),
    [setPropertiesExpansions]
  );
  const collapseAllPropertiesButton = useMemo(
    () => ({
      isAllPropertiesCollapsed:
        Object.values(propertiesExpansions).every(isNil) ||
        Object.values(propertiesExpansions).length === 0,
      collapseAll,
    }),
    [propertiesExpansions, collapseAll]
  );

  const allExpandedProperties = useMemo(
    () => expandAllProperties(propertiesDiffDescriptions),
    [propertiesDiffDescriptions]
  );
  const expandAll = useCallback(
    () => setPropertiesExpansions(allExpandedProperties),
    [allExpandedProperties, setPropertiesExpansions]
  );
  const expandAllPropertiesButton = useMemo(
    () => ({
      isAllPropertiesExpanded: equals(
        allExpandedProperties,
        propertiesExpansions
      ),
      expandAll,
    }),
    [expandAll, allExpandedProperties, propertiesExpansions]
  );

  const isAllPreviousPropertiesExpanded = usePrevious(
    expandAllPropertiesButton.isAllPropertiesExpanded
  );
  useEffect(() => {
    if (isAllPreviousPropertiesExpanded) {
      expandAllPropertiesButton.expandAll();
    }
    // we should not add isAllPreviousPropertiesExpanded to dependencies because this useEffect should run only when an entity is deleted or added
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparedEntitiesNumber]);

  return {
    propertyExpansionApi: {
      checkIsExpanded,
      toggle,
      toggleNestedExpansion,
      getNestedExpansions,
    },
    collapseAllPropertiesButton,
    expandAllPropertiesButton,
  };
}

const expandAllProperties = (
  propertiesDiffDescriptions: PropertyDiffDescriptions
): PropertiesExpansions => {
  return pipe(
    () => filterByType(propertiesDiffDescriptions, 'expansionProperty'),
    map((expansionProperty) => {
      const nestedExpansionProperties = filterByType(
        expansionProperty.children,
        'expansionProperty'
      ).map((x) => x.name);
      return expandProperty(expansionProperty.name, nestedExpansionProperties);
    }),
    mergeAll
  )() as PropertiesExpansions;
};

const expandProperty = (
  name: string,
  nestedProperties: string[]
): PropertiesExpansions => ({
  [name]: makeObjectFromArray(() => true, nestedProperties),
});
