import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useCallback } from 'react';

import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import IconAwesomeClickableWithLabel from 'shared/view/elements/IconAwesome/withLabel/IconAwesomeClickableWithLabel';

import { WidgetInfo } from '../WidgetBuilderTabs';
import styles from './WidgetBuilderTab.module.css';

interface Props<T> {
  info: WidgetInfo<T>;
  changeType: (type: T) => void;
}

function WidgetBuilderTab<T>({ info, changeType }: Props<T>) {
  const onClick = useCallback(
    () => changeType(info.type),
    [changeType, info.type]
  );

  return (
    <Paper>
      <div className={styles.root} data-test="widget-builder-tab">
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={2}>
            <div className={styles.img}>{info.image}</div>
          </Grid>

          <Grid item xs={2} style={{ paddingRight: '4px' }}>
            <span className={styles.label} data-test="widget-builder-tab-label">
              {info.label}
            </span>
          </Grid>

          <Grid item xs={6}>
            <span className={styles.text}>{info.description}</span>
          </Grid>

          <Grid item xs={2}>
            <div className={styles.create}>
              <IconAwesomeClickableWithLabel
                icon={ICONS.angleRight}
                onClick={onClick}
                label={'Create widget'}
                dataTest="widget-builder-tab-icon"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}

export default WidgetBuilderTab;
