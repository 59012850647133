import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Box, Paper as MuiPaper, Stack } from '@mui/material';
import { styled } from '@mui/material';

import { IconAwesomeInfo } from '../IconAwesome/IconAwesomeInfo';

interface NewPileProps {
  icon: IconDefinition;
  content: JSX.Element;
  rightContent?: JSX.Element;
  buttons: JSX.Element[];
  dataTest?: string;
}

const Pile = (props: NewPileProps) => {
  return (
    <Paper variant="outlined" data-test={props.dataTest}>
      <Stack direction="row" spacing={1}>
        <Box>
          <IconAwesomeInfo icon={props.icon} size="xl" />
        </Box>
        {props.content}
      </Stack>
      <Stack direction="row" spacing={1}>
        {props.rightContent}

        <Stack direction="row">{props.buttons}</Stack>
      </Stack>
    </Paper>
  );
};

const Paper = styled(MuiPaper)({
  paddingTop: '12px',
  paddingRight: '24px',
  paddingBottom: '16px',
  paddingLeft: '16px',
  height: '72px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export default Pile;
