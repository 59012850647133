import { last } from 'ramda';

import { FieldName } from 'shared/models/FieldName';
import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';
import matchType from 'shared/utils/matchType';

type CommonFilter<
  Type extends string,
  Value,
  Operator extends FilterOperator,
> = {
  id: number;
  type: Type;
  label: string;
  operator: Operator;
  name: FieldName;
  value: Value;
};
export type NumericFilter = CommonFilter<
  'number',
  number,
  NumberFilterOperator
>;
export type StringFilter = CommonFilter<'string', string, StringFilterOperator>;

export type Filter = NumericFilter | StringFilter;

type NumberFilterOperator = Exclude<FilterOperator, 'LIKE'>;
const numericFilterOperators = exhaustiveStringTuple<NumberFilterOperator>()(
  'EQUALS',
  'GREATER',
  'GREATER_OR_EQUALS',
  'LESS',
  'LESS_OR_EQUALS',
  'NOT_EQUALS'
);
type StringFilterOperator = Extract<
  FilterOperator,
  'EQUALS' | 'LIKE' | 'NOT_EQUALS'
>;
const stringFilterOperators = exhaustiveStringTuple<StringFilterOperator>()(
  'EQUALS',
  'LIKE',
  'NOT_EQUALS'
);
export type FilterOperator =
  | 'GREATER'
  | 'GREATER_OR_EQUALS'
  | 'EQUALS'
  | 'NOT_EQUALS'
  | 'LESS'
  | 'LESS_OR_EQUALS'
  | 'LIKE';

export const getNewFilterId = (filters: Filter[]) => {
  const lastItem = last(filters);
  return lastItem ? lastItem.id + 1 : defaultFilterId;
};

export const getOperators = (type: Filter['type']) =>
  matchType<Filter['type'], FilterOperator[]>(
    {
      number: () => numericFilterOperators,
      string: () => stringFilterOperators,
    },
    type
  );

export const makeDefaultExperimentFilter = (
  value: string,
  id: number
): Filter => ({
  label: 'Experiment',
  id,
  name: { field: 'experiment', key: 'name' },
  operator: 'EQUALS',
  type: 'string',
  value,
});

export const getOperatorLabel = (operator: FilterOperator): string =>
  matchType(
    {
      EQUALS: () => '=',
      GREATER: () => '>',
      GREATER_OR_EQUALS: () => '>=',
      LESS: () => '<',
      LESS_OR_EQUALS: () => '<=',
      LIKE: () => 'like',
      NOT_EQUALS: () => '!=',
    },
    operator
  );

const getOperatorString = (operator: FilterOperator): string =>
  matchType(
    {
      EQUALS: () => 'equals',
      GREATER: () => 'greater than',
      GREATER_OR_EQUALS: () => 'equals or greater than',
      LESS: () => 'less than',
      LESS_OR_EQUALS: () => 'equals or less than',
      LIKE: () => 'like',
      NOT_EQUALS: () => 'not equals',
    },
    operator
  );

export const makeFormattedFilterString = (filter: Filter): string => {
  const operator = getOperatorString(filter.operator);

  return `${filter.label} ${operator} ${filter.value}`;
};

export const defaultFilterId = 1;
