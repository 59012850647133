import NA from 'shared/view/elements/PageComponents/NA/NA';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import BaseOrganizationRolesTable from './BaseOrganizationRolesTable';
import { EnabledResourceActions, Role } from '../store/useOrganizationRoles';

type Props = {
  data: Role;
  enabledResourceActions: EnabledResourceActions;
};

const OrganizationRolesTable = (props: Props) => {
  return (
    <BaseOrganizationRolesTable
      renderCell={({ resourceType, actionType }) => {
        const isEnabled = props.enabledResourceActions
          .find((r) => r.resourceType === resourceType)
          ?.allowedActions.some((a) => a === actionType);
        if (isEnabled) {
          const isAllowed = props.data.resourceActions
            .find((r) => r.resourceType === resourceType)
            ?.allowedActions.some((a) => a === actionType);
          return isAllowed ? (
            <IconAwesomeInfo
              icon={ICONS.circleCheck}
              size="lg"
              infoType={'success'}
            />
          ) : (
            <IconAwesomeInfo
              icon={ICONS.circleMinus}
              size="lg"
              infoType={'error'}
            />
          );
        }
        return NA;
      }}
    />
  );
};

export default OrganizationRolesTable;
