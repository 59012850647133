import mainMuiTheme from 'shared/mui/themes/MainMuiTheme';

export const mainBlueColor = mainMuiTheme.palette.primary.main;

export const chartsDataColors = [
  mainBlueColor,
  '#b3c419',
  '#1ECBE1',
  '#E11ECB',
  '#e6ab02',
  '#a6761d',
  '#666666',

  'rgb(31, 119, 180)',
  'rgb(255, 127, 14)',
  'rgb(44, 160, 44)',
  'rgb(214, 39, 40)',
  'rgb(23, 190, 207)',
  'rgb(227, 119, 194)',
  'rgb(140, 86, 75)',
];

export const chartsDataMainColor = chartsDataColors[0];
