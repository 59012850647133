import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import customAttrEdit2 from '../images/custom-attributes-edit-2.png';

export const CustomAttrEditApi = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Edit model or version attribute
    </Typography>
    <Typography variant="body2" mb={3}>
      Anyone with edit rights can keep the attributes up to date. APIs and
      client support are coming soon to allow for automated updates.
    </Typography>
    <img
      src={customAttrEdit2}
      alt="custom attributes edit"
      width="90%"
      height="auto"
    />
  </WizardStepContent>
);
