import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import { isNumber } from 'lodash';

export const ContentGrid = styled(Grid)((props) => {
  const paddingBottom = isNumber(props.pb)
    ? props.theme.spacing(props.pb)
    : '40px';
  return {
    paddingTop: '16px',
    paddingBottom,
  };
});
