import { useSelector } from 'react-redux';
import { pick, isEmpty } from 'lodash';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import Popup from 'shared/view/elements/Popup/Popup';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { trackEvent } from 'setup/app/analytics';
import { selectCurrentUserOrThrowError } from 'features/user';
import Chip from 'shared/view/elements/Chip/Chip';
import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import { validateEmail } from 'shared/utils/validators';
import { useToast } from 'features/toast/store/hooks';

import { useAddUsers } from '../../store/useAddUsers';
import { useOrganizationUsersContext } from '../../store/useOrganizationUsers';
import { ContentWrapper } from '../styled-components';

const initialValues: { emails: string[] } = { emails: [] };

const AddUserPopup = (props: { organizationId: string; onClose(): void }) => {
  const currentUser = useSelector(selectCurrentUserOrThrowError);
  const { usersApi } = useOrganizationUsersContext();
  const addUsersApi = useAddUsers(
    {
      onCompleted: () => {
        usersApi.reload();
        props.onClose();
      },
    },
    true
  );
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (addUsersApi.communication.error?.message) {
      toast(addUsersApi.communication.error.message, 'error', {
        title: 'Error',
      });
    }
  }, [addUsersApi.communication.error, toast]);

  const handleEnter = (emailArray: FieldArrayRenderProps | string[]) => {
    if (validateEmail(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
      setEmail('');
      emailArray.push(email);
    }
  };

  return (
    <PresetFormik
      initialValues={initialValues}
      onSubmit={(form) => {
        if (form.emails.length === 0 && email) handleEnter(form.emails);
        if (form.emails.length > 0) {
          addUsersApi.run({ emails: form.emails, id: props.organizationId });
          trackEvent({
            type: 'organization.users.user_added',
            data: {
              inviter: pick(currentUser, [
                'id',
                'email',
                'firstName',
                'lastName',
                'jobTitle',
                'company',
              ]),
              invitees: {
                emails: form.emails,
              },
            },
          });
        }
      }}
    >
      {({ submitForm, values }) => (
        <Popup
          title="Add user"
          isOpen={true}
          fullWidth={true}
          maxWidth={'xs'}
          buttons={{
            secondaryButtonProps: {
              isLoading: false,
              children: 'Cancel',
              onClick: props.onClose,
            },
            mainButtonProps: {
              children: 'Add',
              isLoading: addUsersApi.communication.isRequesting,
              disabled: isEmpty(values.emails) && isEmpty(email),
              type: 'button',
              onClick: submitForm,
            },
          }}
          onClose={props.onClose}
        >
          <Stack spacing={3}>
            <Typography>Invite new members to your team at Verta.</Typography>
            <FieldArray
              name="emails"
              render={(arrayHelpers) => (
                <>
                  <Stack direction="row" spacing={1}>
                    <TextInput
                      maxWidth="100%"
                      value={email}
                      data-test="add-emails-input"
                      meta={{
                        touched: true,
                        error: emailError ? 'Email is invalid' : undefined,
                      }}
                      label="New user email"
                      onChange={setEmail}
                      onEnter={() => handleEnter(arrayHelpers)}
                      name="email"
                      minWidth="100%"
                      hint='To add multiple users, press the "enter" key after each email.'
                    />
                  </Stack>
                  <ContentWrapper>
                    {values.emails.map((_email, index) => (
                      <Chip
                        key={`add-emails-chip-${_email}`}
                        data-test={`add-emails-chip-${_email}`}
                        size="small"
                        variant="outlined"
                        color="primary"
                        onDelete={() => arrayHelpers.remove(index)}
                        label={
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            spacing={0.5}
                          >
                            <DynamicTypography value={_email} />
                          </Stack>
                        }
                      />
                    ))}
                  </ContentWrapper>
                </>
              )}
            />
          </Stack>
        </Popup>
      )}
    </PresetFormik>
  );
};

export default AddUserPopup;
