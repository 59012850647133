import { gql } from '@apollo/client';

import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';

import * as Types from './graphql-types/useAdministratedOrganization.generated';

export { Types };

export const QUERY = gql`
  query AdministratedOrganizationV2 {
    administratedOrganizationV2 {
      id
      name
    }
  }
`;

export const useAdministratedOrganization = () => {
  const queryResult = useCustomQuery<Types.AdministratedOrganizationV2>(QUERY, {
    fetchPolicy: 'cache-first',
  });

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult,
  });

  return {
    data,
    communication,
    reload: queryResult.refetch,
  };
};

const convert = ({
  administratedOrganizationV2,
}: Types.AdministratedOrganizationV2) => administratedOrganizationV2;
