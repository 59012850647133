import { toString } from 'ramda';

const matchBy = <D extends Record<any, any>, Prop extends keyof D>(
  data: D,
  prop: Prop
): (<Result>(matchers: {
  [K in D[Prop]]: (data: Extract<D, Record<Prop, K>>) => Result;
}) => Result) => {
  return (matchers) => {
    const type = data[prop];
    if (type in matchers) {
      return matchers[type](data as any);
    } else {
      const expectedTypes = Object.keys(matchers).map(toString).join(', ');
      throw new Error(
        `No match. Expects ${expectedTypes} but got ${toString(type)}`
      );
    }
  };
};

export default matchBy;
