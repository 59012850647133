import { enumCardsDocumentationTypes } from './DocumentationCardsType';

export const cardsCatalog = [
  {
    type: enumCardsDocumentationTypes.ARTICLE,
    title: 'Introduction to creating registered model versions',
    body: 'This guide explains the basic concepts behind registering model versions',
    link: 'https://docs.verta.ai/verta/registry/guides/registered-model-version',
    key: 'card1',
  },
  {
    type: enumCardsDocumentationTypes.ARTICLE,
    title: 'Create registered model versions from common libraries',
    body: 'Find examples of creating RMVs for Scikit-learn, Keras, PyTorch and XGBoost',
    link: 'https://docs.verta.ai/verta/registry/guides/registered-model-versions-from-common-libraries',
    key: 'card2',
  },
  {
    type: enumCardsDocumentationTypes.ARTICLE,
    title: 'Create registered model versions from artifacts',
    body: 'Learn how to use artifacts within your model class definition',
    link: 'https://docs.verta.ai/verta/registry/guides/registered-model-versions-with-artifacts',
    key: 'card3',
  },
];

export const cardsDeploy = [
  {
    type: enumCardsDocumentationTypes.ARTICLE,
    title: 'Introduction to model version stages',
    body: "Verta's approach to model version lifecycle management",
    link: 'https://docs.verta.ai/verta/registry/guides/model-version-stages',
    key: 'card4',
  },
  {
    type: enumCardsDocumentationTypes.ARTICLE,
    title: 'Introduction to release checklists',
    body: 'Learn how to manage model releases with checklists',
    link: 'https://docs.verta.ai/verta/administration/checklists',
    key: 'card5',
  },
  {
    type: enumCardsDocumentationTypes.ARTICLE,
    title: 'Verta platform release notes',
    body: 'Find our monthly release notes here',
    link: 'https://docs.verta.ai/verta/resources/release-notes',
    key: 'card6',
  },
];
