export type Result<T, Err> = SuccessResult<T> | ErrorResult<Err>;

type SuccessResult<T> = { type: 'success'; data: T };

type ErrorResult<Err> = { type: 'error'; error: Err };

export const makeSuccess = <T, Err = unknown>(data: T): Result<T, Err> => ({
  type: 'success',
  data,
});

export const makeError = <T, Err = unknown>(error: Err): Result<T, Err> => ({
  type: 'error',
  error,
});

export const isSuccess = <T, Err>(
  result: Result<T, Err>
): result is SuccessResult<T> => result.type === 'success';

const isError = <T, Err>(result: Result<T, Err>): result is ErrorResult<Err> =>
  result.type === 'error';

export const getError = <T, Err>(result: Result<T, Err>): Err | undefined =>
  isError(result) ? result.error : undefined;

export const getSuccess = <T, Err>(result: Result<T, Err>): T | undefined =>
  isSuccess(result) ? result.data : undefined;

export const withDefault = <T, Err>(def: T, res: Result<T, Err>): T =>
  res.type === 'success' ? res.data : def;
