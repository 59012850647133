/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { ActiveDirectoryConfiguration } from 'features/systemAdmin/integrations/active-directory/store/activeDirectoryConfiguration/graphql-types/useActiveDirectoryConfiguration.generated';

const QUERY = gql`
  query ActiveDirectoryConfiguration {
    activeDirectory {
      id
      configuration {
        id
        enabled
        url
        token
      }
    }
    allOrganizations(externalOnly: true) {
      id
      name
      provisioning {
        role
        groupName
      }
    }
  }
`;

export default function useActiveDirectoryConfiguration() {
  const queryResult = useCustomQuery<ActiveDirectoryConfiguration>(QUERY);

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult,
  });
}

const convert = (x: ActiveDirectoryConfiguration) => {
  return x.activeDirectory.configuration
    ? {
        configuration: x.activeDirectory.configuration,
        organizations: x.allOrganizations,
      }
    : null;
};
