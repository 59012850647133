import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { convertMonitoringFilterToGraphQL } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { OUTLIER_DETECTION_MAX_SAMPLES } from 'shared/models/Monitoring/OutlierDetection';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { toGraphQLDate } from 'shared/utils/graphql/toGraphQLDate';
import isNotNil from 'shared/utils/isNotNill';
import { convertTimeRangeToDateRange } from 'shared/utils/TimeRange';

import { convertIODescriptionToQuery } from '../shared/convertIODescriptionToQuery';
import {
  OutlierDetectionQuery,
  OutlierDetectionQueryVariables,
} from './graphql-types/useOutlierDetection.generated';

const OUTLIER_DETECTION_QUERY = gql`
  query OutlierDetectionQuery(
    $monitoredEntityId: ID!
    $outlierQuery: MonitoringOutlierQuery!
  ) {
    monitoredEntity(id: $monitoredEntityId) {
      ... on Error {
        ...ErrorData
      }
      ... on MonitoredEntity {
        id
        metrics {
          outliers(query: $outlierQuery) {
            name
            modelVersionId
            modelVersion {
              ... on Error {
                ...ErrorData
              }
              ... on RegisteredModelVersion {
                id
                version
              }
            }
            ioType
            mean
            median
            sigma
            totalInliers
            totalOutliers
            min
            max
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

interface Props {
  widgetExternalDeps: MonitoringWidgetExternalDeps;
}

export const useOutlierDetectionQuery = (props: Props) => {
  const variables = useMemo((): OutlierDetectionQueryVariables => {
    const dateRange = convertTimeRangeToDateRange(
      props.widgetExternalDeps.timeRange
    );
    return {
      monitoredEntityId: props.widgetExternalDeps.monitoredEntityId,
      outlierQuery: {
        startDate: toGraphQLDate(dateRange.from),
        endDate: toGraphQLDate(dateRange.to),
        ioDescriptions: props.widgetExternalDeps.ioDescriptions.map(
          convertIODescriptionToQuery
        ),
        maxSamples: OUTLIER_DETECTION_MAX_SAMPLES,
        filters: props.widgetExternalDeps.filters.map(
          convertMonitoringFilterToGraphQL
        ),
      },
    };
  }, [
    props.widgetExternalDeps.timeRange,
    props.widgetExternalDeps.ioDescriptions,
    props.widgetExternalDeps.monitoredEntityId,
    props.widgetExternalDeps.filters,
  ]);

  const query = useCustomQuery<
    OutlierDetectionQuery,
    OutlierDetectionQueryVariables
  >(OUTLIER_DETECTION_QUERY, {
    variables,
  });

  const convert = useCallback(
    (res: OutlierDetectionQuery) => {
      return mapDataOrError(res.monitoredEntity, (x) =>
        x.metrics.outliers
          .filter(isNotNil)
          .filter((o) =>
            props.widgetExternalDeps.registeredModelVersionIds.includes(
              o.modelVersionId
            )
          )
      );
    },
    [props.widgetExternalDeps.registeredModelVersionIds]
  );

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult: query,
  });
};
