import { compareLabels } from 'shared/models/CompareEntities/new/labelsComparing';
import { compareEntitiesPrimitives } from 'shared/models/CompareEntities/new/primitivesComparing';
import { compareAttributes } from 'shared/models/CompareEntities/new/attributesComparing';

import { ComparedEvaluations } from './comparedEvaluations';

export const compareEvaluations = (evaluations: ComparedEvaluations) => {
  return {
    version: compareEntitiesPrimitives(({ version }) => version, evaluations),
    description: compareEntitiesPrimitives(
      ({ description }) => description,
      evaluations
    ),
    owner: compareEntitiesPrimitives(
      ({ owner }) =>
        owner.__typename === 'User' ? owner.username : owner.name,
      evaluations
    ),
    project: compareEntitiesPrimitives(
      ({ registeredModel }) => registeredModel.name,
      evaluations
    ),

    dateUpdated: compareEntitiesPrimitives(
      ({ dateUpdated }) => dateUpdated,
      evaluations
    ),

    labels: compareLabels(evaluations, ({ labels }) => labels),
    attributes: compareAttributes(evaluations),
    overallComment: compareEntitiesPrimitives(
      ({ activities }) => activities[0]?.comment,
      evaluations
    ),
    approvedRows: compareEntitiesPrimitives(
      ({ approvedRows }) => approvedRows.length,
      evaluations
    ),
    rejectedRows: compareEntitiesPrimitives(
      ({ rejectedRows }) => rejectedRows.length,
      evaluations
    ),
    configuration: compareEntitiesPrimitives(
      ({ configuration }) => configuration,
      evaluations
    ),
    modelId: compareEntitiesPrimitives(({ modelId }) => modelId, evaluations),
    rows: compareEntitiesPrimitives(({ rows }) => rows.length, evaluations),

    labelsDistribution: compareEntitiesPrimitives(
      ({ labelsDistribution }) => JSON.stringify(labelsDistribution),
      evaluations
    ),
  };
};
