import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { ACTIVITY_FRAGMENT } from 'shared/graphql/registry/stage/Activity';

export const MODEL_ACTIVITIES_DATA_QUERY = gql`
  query ModelActivitiesData($id: ID!) {
    registeredModel(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        versions {
          versions {
            id
            version
            stage
            activities {
              id
              ...ActivityFragment
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
`;
