/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import isNotNil from 'shared/utils/isNotNill';
import { WorkspaceName } from 'shared/models/Workspace';
import { RequiredBuildSource } from 'shared/models/Deployment/canary/Build';
import matchType from 'shared/utils/matchType';
import noop from 'shared/utils/noop';
import useQueryFromFunction from 'shared/utils/graphql/useQueryFromFunction';
import {
  isNotNullableRestrictedGraphqlError,
  RestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';

import * as GraphqlTypes from './graphql-types/useSourceBuilds.generated';
import { RequiredBuild } from '../../RequiredBuild';

export type LoadSourceBuildsSettings = {
  source: RequiredBuildSource;
  sourceId: string;
  workspaceName: WorkspaceName;
  organizationId: string | undefined;
};

export const useSourceBuilds = ({
  onCompleted,
}: {
  onCompleted: (builds: RequiredBuild[]) => void;
}) => {
  const [loadSourceBuilds, loadingSourceBuilds, sourceBuilds] =
    useQueryFromFunction(
      (apolloClient) =>
        async ({
          variables,
          context,
        }: {
          variables: LoadSourceBuildsSettings;
          context: Record<string, unknown>;
        }) => {
          return matchType(
            {
              experimentRun: async () => {
                const res = await apolloClient.query<
                  GraphqlTypes.ModelBuilds,
                  GraphqlTypes.ModelBuildsVariables
                >({
                  query: MODEL_BUILDS,
                  variables: {
                    workspaceName: variables.workspaceName,
                    modelId: variables.sourceId,
                    organizationId: variables.organizationId,
                  },
                  context,
                });
                return convertModelBuildsResponse(variables.sourceId, res.data);
              },
              modelVersion: async () => {
                const res = await apolloClient.query<
                  GraphqlTypes.ModelVersionBuilds,
                  GraphqlTypes.ModelVersionBuildsVariables
                >({
                  query: MODEL_VERSION_BUILDS,
                  variables: {
                    workspaceName: variables.workspaceName,
                    modelVersionId: variables.sourceId,
                    organizationId: variables.organizationId,
                  },
                  context,
                });
                return convertModelVersionBuildsResponse(
                  variables.sourceId,
                  res.data
                );
              },
            },
            variables.source
          );
        },
      {
        onCompleted: (res) =>
          isNotNullableRestrictedGraphqlError(res) && onCompleted(res),
        onError: noop,
      }
    );

  return { loadSourceBuilds, loadingSourceBuilds, sourceBuilds };
};

// todo rename
export const MODEL_BUILDS = gql`
  query ModelBuilds(
    $workspaceName: String!
    $organizationId: ID
    $modelId: ID!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        builds(runId: $modelId) {
          builds {
            id
            source {
              ... on Error {
                ...ErrorData
              }
              ... on ExperimentRun {
                id
                name
                project {
                  id
                  name
                }
              }
            }
            dateCreated
            dateUpdated
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

const MODEL_VERSION_BUILDS = gql`
  query ModelVersionBuilds(
    $workspaceName: String!
    $organizationId: ID
    $modelVersionId: ID!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        builds(modelVersion: $modelVersionId) {
          builds {
            id
            source {
              ... on RegisteredModelVersion {
                id
                version
                run {
                  ... on Error {
                    ...ErrorData
                  }
                  ... on ExperimentRun {
                    id
                    projectId
                  }
                }
                registeredModel {
                  id
                  name
                }
              }
            }
            dateCreated
            dateUpdated
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

const convertModelBuildsResponse = (
  modelId: string,
  response: GraphqlTypes.ModelBuilds
): RequiredBuild[] | RestrictedGraphqlError =>
  isNotNullableRestrictedGraphqlError(response.workspace)
    ? response.workspace.builds.builds
        .map((build) => {
          const requiredBuild: RequiredBuild | undefined =
            isNotNullableRestrictedGraphqlError(build.source) &&
            build.source.__typename === 'ExperimentRun'
              ? {
                  source: 'experimentRun',
                  id: build.id,
                  experimentRun: build.source,
                  modelVersion: null,
                  dateCreated: build.dateCreated,
                  dateUpdated: build.dateUpdated,
                }
              : undefined;
          return requiredBuild;
        })
        .filter(isNotNil)
        .filter((build) => build.experimentRun.id === modelId)
    : (response.workspace as RestrictedGraphqlError);

const convertModelVersionBuildsResponse = (
  modelVersionId: string,
  response: GraphqlTypes.ModelVersionBuilds
): RequiredBuild[] | RestrictedGraphqlError =>
  isNotNullableRestrictedGraphqlError(response.workspace)
    ? response.workspace.builds.builds
        .map((build) => {
          const requiredBuild: RequiredBuild | undefined =
            build.source?.__typename === 'RegisteredModelVersion'
              ? {
                  id: build.id,
                  source: 'modelVersion',
                  modelVersion: build.source,
                  experimentRun: null,
                  dateCreated: build.dateCreated,
                  dateUpdated: build.dateUpdated,
                }
              : undefined;
          return requiredBuild;
        })
        .filter(isNotNil)
        .filter((build) => build.modelVersion.id === modelVersionId)
    : (response.workspace as RestrictedGraphqlError);
