import { GridSortModel, useGridApiRef } from '@mui/x-data-grid-pro';
// eslint-disable-next-line no-restricted-imports
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { head } from 'ramda';
import { MutableRefObject, useState } from 'react';

import { MonitoringIOType } from 'generated/types';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { useUpdateEffect } from 'shared/view/hooks/useUpdateEffect';
import { DataGridSortProps } from 'shared/view/elements/DataGrid/DataGridWithTypes';

interface Row {
  ioType: MonitoringIOType;
  name: string;
}

export const useFeatureTableSortingProps = (props: {
  featureField: string;
  changeIODescription:
    | ((ioDescription: MonitoringIODescription) => void)
    | undefined;
  ioDescriptions: MonitoringIODescription[];
}): DataGridSortProps & { apiRef: MutableRefObject<GridApiPro> } => {
  const [sortModel, onSortModelChange] = useState<GridSortModel>([
    {
      field: props.featureField,
      sort: 'asc',
    },
  ]);

  const apiRef = useGridApiRef();

  useUpdateEffect(() => {
    const firstRow = head(apiRef.current.getSortedRows() as Row[]);
    const ioDescription = props.ioDescriptions.find(
      (io) => io.name === firstRow?.name && io.ioType === firstRow.ioType
    );
    if (props.changeIODescription && ioDescription) {
      props.changeIODescription(ioDescription);
    }
  }, [sortModel]);

  return {
    sortModel,
    onSortModelChange,
    apiRef,
    sortingMode: 'client' as const,
  };
};
