import { FieldArray, FormikErrors } from 'formik';
import { useState } from 'react';
import { isEmpty, isNil } from 'ramda';

import FormStack from 'shared/view/elements/FormStack/FormStack';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import Button from 'shared/view/elements/Button/Button';
import {
  Editable,
  ChecklistTemplateItemType,
  ChecklistTemplateItemInput,
} from 'generated/types';

import { getField } from './ChecklistTemplatePopup';
import { ChecklistTemplateItem } from './ChecklistTemplateItem';
import { OrganizationGovernance } from '../../store/useOrganizationGovernance';

export const EMPTY_CHECKLIST_TEMPLATE_ITEM_INPUT = {
  itemType: ChecklistTemplateItemType.CHECKBOX,
  name: '',
  editable: Editable.EDITABLE,
  required: false,
  requireEvidence: false,
  description: '',
};

type Props = {
  values: ChecklistTemplateItemInput[] | null | undefined;
  errors?: FormikErrors<ChecklistTemplateItemInput>[];
  initialExpandedItemIndex?: number;
  groups: OrganizationGovernance['groups'];
};

export const ChecklistTemplateItemManager = ({
  groups,
  values,
  errors,
  initialExpandedItemIndex = 0,
}: Props) => {
  const [activeIndexes, setActiveIndexes] = useState<number[]>([
    initialExpandedItemIndex,
  ]);

  const handleOnOpen = async (idx: number) => {
    setActiveIndexes([...activeIndexes, idx]);
  };

  return (
    <FieldArray name={getField({ checklistTemplateItems: null })}>
      {({ push, remove, replace }) => (
        <FormStack pt={3}>
          {!isNil(values) &&
            values.map((item, i) => {
              const error = errors ? errors[i] : undefined;
              return (
                <ChecklistTemplateItem
                  value={item}
                  key={`checklist-template-item-${i}`}
                  index={i}
                  expanded={activeIndexes.includes(i)}
                  onOpen={handleOnOpen}
                  onRemove={(idx) => remove(idx)}
                  deletable={Boolean(values.length > 1)}
                  error={error}
                  groups={groups}
                  replace={replace}
                />
              );
            })}

          <Button
            onClick={async () => {
              push(EMPTY_CHECKLIST_TEMPLATE_ITEM_INPUT);
              const idx = isEmpty(values) || isNil(values) ? 0 : values.length;
              setActiveIndexes([...activeIndexes, idx]);
            }}
            dataTest="add-checklist-item"
            isLoading={false}
            size="medium"
            variant="text"
            icon={ICONS.circlePlus}
          >
            Add checklist item
          </Button>
        </FormStack>
      )}
    </FieldArray>
  );
};
