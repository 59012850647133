import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

function useUpdateEffect(
  callback: EffectCallback,
  conditions: DependencyList
): void {
  const hasMountedRef = useRef(false);
  if (conditions.length === 0) {
    console.error(
      'Using [] as the second argument makes useUpdateEffect a noop. The second argument should either be an array of length greater than 0.'
    );
  }

  useEffect(() => {
    if (hasMountedRef.current) {
      return callback();
    } else {
      hasMountedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, conditions);
}

export { useUpdateEffect };
