import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import checklistEdit from '../images/checklist-edit.png';

export const EditChecklists = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      About checklists
    </Typography>
    <Typography variant="body2" mb={3}>
      Verta offers default checklists that you can utilize or edit as a starting
      point. You have the freedom to add any number of checklists. Please note
      that only organization admins have the authority to edit checklists.
    </Typography>
    <img src={checklistEdit} width="100%" height="auto" alt="checklist edit" />
  </WizardStepContent>
);
