import { useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import moment from 'moment';
import { descend, sort } from 'ramda';
import { useCallback } from 'react';

import {
  selectErrorsWithInfo,
  selectIsErrorsPanelOpened,
} from 'features/errorReporter/store/selectors';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { normalizeAppErrorMessage } from 'shared/utils/normalizeErrorData';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import {
  closeErrorsPanel,
  openErrorReporterModal,
  removeError,
  removeAllErrors,
} from 'features/errorReporter/store/actions';
import Button from 'shared/view/elements/Button/Button';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import styles from './ErrorsPanel.module.css';

const ErrorsPanel = () => {
  const isOpened = useSelector(selectIsErrorsPanelOpened);
  const dispatch = useDispatch();
  const errorsWithInfo = useSelector(selectErrorsWithInfo);

  const onClose = useCallback(() => dispatch(closeErrorsPanel()), [dispatch]);

  const onClearAll = useCallback(() => {
    dispatch(removeAllErrors());
    onClose();
  }, [onClose, dispatch]);

  const theme = useTheme();

  return (
    <Drawer anchor="right" open={isOpened} onClose={onClose}>
      <div className={styles.root}>
        <div className={styles.header}>
          <Button variant={'text'} onClick={onClearAll} isLoading={false}>
            Clear all
          </Button>
          <div className={styles.close}>
            <IconAwesomeClickable
              icon={ICONS.circleXmark}
              size="lg"
              onClick={onClose}
            />
          </div>
        </div>
        {sort(
          descend(({ createdAt }) => +createdAt),
          errorsWithInfo
        ).map((errorWithInfo, i) => (
          <div
            className={styles.error}
            style={{ borderBottom: theme.palette.border.default }}
            key={i}
          >
            <div className={styles.error__header}>
              <IconAwesomeInfo
                icon={ICONS.triangleExclamation}
                infoType="error"
              />{' '}
              <span
                title={errorWithInfo.context}
                className={styles.error__context}
              >
                {errorWithInfo.context}
              </span>
              <span className={styles.error__createdAt}>
                {moment(errorWithInfo.createdAt).fromNow()}
              </span>
              <div className={styles.error__actions}>
                <IconAwesomeClickable
                  icon={ICONS.shareNodes}
                  onClick={() =>
                    dispatch(openErrorReporterModal(errorWithInfo))
                  }
                />
                <IconAwesomeClickable
                  icon={ICONS.trashCan}
                  onClick={() => dispatch(removeError(errorWithInfo.error))}
                />
              </div>
            </div>
            <span className={styles.error__message}>
              {normalizeAppErrorMessage(errorWithInfo.error)}
            </span>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default ErrorsPanel;
