import isNotNil from 'shared/utils/isNotNill';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import {
  ExperimentRunWithDisplayedFields,
  getValueByKeyValueSpec,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import { chartsDataColors } from 'shared/view/charts/shared/colors';
import { BarChartSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/BarChartSettings';

import { WidgetVisualizationSpec } from './shared/WidgetVisualizationSpec';
import { config } from './shared/config';
import { getDataSpecKey } from './shared/dataSpecKey';

type RequiredExperimentRunForSpec = {
  x: number | string;
  y: number | string;
  x2: string;
};

export const getExperimentRunsForBarChartSpec = (
  settings: Pick<BarChartSettings, 'xAxis' | 'yAxis'>,
  experimentRuns: ExperimentRunWithDisplayedFields[]
): RequiredExperimentRunForSpec[] => {
  return experimentRuns
    .flatMap((experimentRun) =>
      settings.yAxis.specs.map((spec) => {
        const y = getValueByKeyValueSpec(spec, experimentRun);
        const x =
          settings.xAxis.spec.type === 'dateUpdated'
            ? +experimentRun.dateUpdated
            : getValueByKeyValueSpec(settings.xAxis.spec, experimentRun);

        return isNotNil(x) && isNotNil(y)
          ? {
              x,
              x2: spec.key,
              y: y,
            }
          : null;
      })
    )
    .filter(isNotNil);
};

const getFieldName = makeGetFieldName<RequiredExperimentRunForSpec>();

export const getBarChartSpec = (
  dataSourceName: string,
  settings: BarChartSettings
): WidgetVisualizationSpec => {
  return {
    data: {
      name: dataSourceName,
      transform: settings.yAxis.aggregationType
        ? [
            {
              type: 'joinaggregate',
              groupby: [getFieldName({ x: null }), getFieldName({ x2: null })],
              fields: [getFieldName({ y: null })],
              ops: [settings.yAxis.aggregationType],
              as: [getFieldName({ y: null })],
            },
          ]
        : undefined,
    },
    scales: [
      {
        name: 'xscale',
        type: 'band',
        domain: { data: dataSourceName, field: getFieldName({ x: null }) },
        range: 'width',
        padding: 0.2,
      },
      {
        name: 'yscale',
        type: 'linear',
        domain: { data: dataSourceName, field: getFieldName({ y: null }) },
        domainMin: settings.yAxis.domain.min,
        domainMax: settings.yAxis.domain.max,
        range: 'height',
        round: true,
        nice: true,
      },
      {
        name: 'color',
        type: 'ordinal',
        domain: { data: dataSourceName, field: getFieldName({ x2: null }) },
        range: chartsDataColors,
      },
    ],
    axes: [
      {
        orient: 'left',
        scale: 'yscale',
      },
      {
        orient: 'bottom',
        scale: 'xscale',
        tickSize: 0,
        labelPadding: 4,
        title: getDataSpecKey(settings.xAxis.spec),
        zindex: 1,
        ...(settings.xAxis.spec.type === 'dateUpdated'
          ? {
              formatType: 'time',
              format: '%b %d %H:%M',
            }
          : undefined),
        encode: {
          labels: {
            update: {
              angle: { value: -50 },
              fontSize: { value: 10 },
              align: { value: 'right' },
            },
          },
        },
      },
    ],
    marks: [
      {
        type: 'group',
        clip: true,
        from: {
          facet: {
            data: dataSourceName,
            name: 'facet',
            groupby: getFieldName({ x: null }),
          },
        },
        encode: {
          enter: { x: { scale: 'xscale', field: getFieldName({ x: null }) } },
        },
        signals: [{ name: 'width', update: "bandwidth('xscale')" }],
        scales: [
          {
            name: 'pos',
            type: 'band',
            range: 'width',
            domain: { data: 'facet', field: getFieldName({ x2: null }) },
          },
        ],
        marks: [
          {
            name: 'bars',
            from: { data: 'facet' },
            type: 'rect',
            encode: {
              enter: {
                x: { scale: 'pos', field: getFieldName({ x2: null }) },
                width: { scale: 'pos', band: 1 },
                y: { scale: 'yscale', field: getFieldName({ y: null }) },
                y2: { scale: 'yscale', value: 0 },
                fill: { scale: 'color', field: getFieldName({ x2: null }) },
                tooltip: [{ field: getFieldName({ y: null }) }],
              },
            },
          },
        ],
      },
    ],
    legends: [{ fill: 'color', direction: 'horizontal', symbolType: 'square' }],
    config,
  };
};
