import React, { useCallback } from 'react';
import Stack from '@mui/material/Stack';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { substractPaginationTotalCount } from 'shared/models/Pagination';
import { useDatasetVersions } from 'features/datasetVersions/store/datasetVersions/datasetVersions';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { usePagination } from 'shared/view/hooks/usePagination';
import {
  isNotNullableRestrictedGraphqlError,
  isRestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';

import DatasetDetailsLayout from '../shared/DatasetDetailsLayout/DatasetDetailsLayout';

const DatasetVersions = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'DatasetVersions' */ 'features/datasetVersions/view/DatasetVersions/DatasetVersions'
    )
);

const DatasetVersionsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { datasetId } = useRouteParams(routes.datasetVersions);

  const { data, loadDatasetVersions, loadingDatasetVersions } =
    useDatasetVersions();

  const { pagination, onCurrentPageChange } = usePagination({
    totalCount: isNotNullableRestrictedGraphqlError(data) ? data.total : 0,
  });

  React.useEffect(() => {
    loadDatasetVersions({
      datasetId,
      pagination,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage]);

  const reload = useCallback(() => {
    loadDatasetVersions({
      datasetId,
      pagination,
    });
  }, [loadDatasetVersions, datasetId, pagination]);

  const dataWithoutError = isRestrictedGraphqlError(data) ? undefined : data;

  return (
    <DatasetDetailsLayout
      pageEntityOrError={data}
      dataset={dataWithoutError?.dataset}
    >
      <Stack>
        <DatasetVersions
          allowedActions={dataWithoutError?.dataset.allowedActions}
          datasetVersions={dataWithoutError?.datasetVersions}
          datasetId={datasetId}
          onCurrentPageChange={onCurrentPageChange}
          pagination={pagination}
          loadingDatasetVersions={loadingDatasetVersions}
          onCompletedDeleteDatasetVersions={(ids: any[]) => {
            const newPagination = substractPaginationTotalCount(
              ids.length,
              pagination
            );
            if (newPagination.currentPage !== pagination.currentPage) {
              onCurrentPageChange(newPagination.currentPage);
            } else {
              reload();
            }
          }}
        />
      </Stack>
    </DatasetDetailsLayout>
  );
};

export default DatasetVersionsPage;
