import { extentWithDefault } from 'shared/utils/charts/d3/extentWithDefault';

const DEFAULT_MAX_DOMAIN = 1;
const DOMAIN_MAX_MULTIPLIER = 1.05;

export const getLinearScaleDomain = (values: number[]): [number, number] => {
  const [extentMin, extentMax] = extentWithDefault(values.filter(isFinite), 0);

  if (extentMin === 0 && extentMax === 0) {
    return [extentMin, DEFAULT_MAX_DOMAIN];
  }

  if (extentMin === extentMax) {
    return extentWithDefault([0, extentMax * DOMAIN_MAX_MULTIPLIER], 0);
  }

  return [extentMin, extentMax * DOMAIN_MAX_MULTIPLIER];
};
