import { reduce } from 'lodash';
import Papa from 'papaparse';
import * as yup from 'yup';

const requiredHeadersSchema = yup.object().shape({
  input: yup
    .string()
    .required(
      'File does not follow the specified format. <strong>Input</strong> column is missing.'
    ),
  output: yup
    .string()
    .required(
      'File does not follow the specified format. <strong>Output</strong> column is missing.'
    ),
});

const schema = yup.array().of(
  yup.object().shape({
    input: yup.string().required(({ path }: { path: string }) => {
      const number = parseInt(path.replace(/\D/g, '')) + 1;

      return `File does not follow the specified format. Input on line ${number} is empty.`;
    }),
    output: yup.string().required(({ path }: { path: string }) => {
      const number = parseInt(path.replace(/\D/g, '')) + 1;

      return `File does not follow the specified format. Output on line ${number} is empty.`;
    }),
  })
);

const transformObjectKeys = (arr: unknown[]): Record<string, any>[] => {
  return arr.map((obj: any) => {
    const result: Record<string, any> = {};
    for (const key in obj) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (obj.hasOwnProperty(key)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        result[key.toLowerCase().replace(/\s/g, '')] = obj[key];
      }
    }
    return result;
  });
};

const transformArrayToObject = (keys: string[]): Record<string, boolean> =>
  reduce(
    keys,
    (acc, key) => ({ ...acc, [key.toLowerCase().replace(/\s/g, '')]: 'true' }),
    {}
  );

export const verifyCSVFile = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!file.name.toLowerCase().endsWith('.csv')) {
      resolve('File is not a CSV');
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target?.result as string;

      Papa.parse(fileContent, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results.meta.fields === undefined) {
            const err = new Error('Please add a valid file');
            return reject(err);
          }
          requiredHeadersSchema
            .validate(transformArrayToObject(results.meta.fields))
            .then(() => {
              schema
                .validate(transformObjectKeys(results.data))
                .then(() => {
                  resolve('CSV is valid');
                })
                .catch((error: Error) => {
                  return reject(error);
                });
            })
            .catch((error: Error) => {
              return reject(error);
            });
        },
        error: (error: Error) => {
          return reject(error);
        },
      });
    };

    reader.readAsText(file);
  });
};
