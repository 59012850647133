import ResizeDetector from 'react-resize-detector';
import { useMemo, useState } from 'react';

import { componentsSizes } from 'shared/mui/themes/CommonMuiThemeOptions';

// eslint-disable-next-line postcss-modules/no-unused-class
import styles from './CompareTable.module.css';

const Wrapper = ({
  children,
  comparedEntitiesCount,
}: {
  comparedEntitiesCount: number;
  children: (columnWidths: ColumnWidths) => React.ReactNode;
}) => {
  const wrapperHeightApi = useWrapperHeight();
  const columnWidthsApi = useColumnWidths(comparedEntitiesCount);

  return (
    <div
      className={styles.wrapper}
      ref={(r) => wrapperHeightApi.setWrapperHeight(r)}
      style={{
        maxHeight: wrapperHeightApi.wrapperHeight,
      }}
    >
      <ResizeDetector
        handleWidth={true}
        onResize={columnWidthsApi.setWrapperWidth}
      />
      {children(columnWidthsApi.columnWidths)}
    </div>
  );
};

const useWrapperHeight = () => {
  const [wrapperHeight, setWrapperHeight__] = useState<number | undefined>(
    undefined
  );
  const setWrapperHeight = (r: HTMLDivElement | null) => {
    if (wrapperHeight === undefined && r) {
      const wrapperCoords = getCoordsRelativeDocument(r);
      setWrapperHeight__(
        window.innerHeight -
          wrapperCoords.top -
          componentsSizes.layout.content.padding
      );
    }
  };

  return {
    wrapperHeight: wrapperHeight === undefined ? 'auto' : `${wrapperHeight}px`,
    setWrapperHeight,
  };
};

type ColumnWidths = {
  firstColumn: string;
  entityColumns: string | undefined;
};

const useColumnWidths = (comparedEntitiesCount: number) => {
  const [wrapperWidth, setWrapperWidth] = useState<undefined | number>(
    undefined
  );
  const columnWidths: ColumnWidths = useMemo(() => {
    const firstColumnWidth = 300;
    return {
      firstColumn: `${firstColumnWidth}px`,
      entityColumns: (() => {
        const columnsCountWithoutHorizontalScroll =
          comparedEntitiesCount === 2 ? 2 : 3;
        return wrapperWidth === undefined
          ? undefined
          : `${
              (wrapperWidth - firstColumnWidth) /
              columnsCountWithoutHorizontalScroll
            }px`;
      })(),
    };
  }, [comparedEntitiesCount, wrapperWidth]);

  return { columnWidths, setWrapperWidth };
};

function getCoordsRelativeDocument(elem: Element) {
  const box = elem.getBoundingClientRect();

  return {
    top: box.top + window.pageYOffset,
    left: box.left + window.pageXOffset,
  };
}

export default Wrapper;
