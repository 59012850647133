import { head } from 'ramda';

import between from 'shared/utils/between';
import { TIME_UNITS_IN_MILLIS } from 'shared/utils/duration';
import { convertTimeRangeToDateRange, TimeRange } from 'shared/utils/TimeRange';

export interface MonitoringAggregation {
  timeWindow: number;
}

const DEFAULT_DIVIDER = 10;

export function getDefaultAggregation(
  timeRange: TimeRange
): MonitoringAggregation {
  const availableOptions = getAvailableTimeWindowsWithLabels(timeRange);

  const firstOption = head(availableOptions);

  if (firstOption) {
    return { timeWindow: firstOption.value };
  }

  const dateRange = convertTimeRangeToDateRange(timeRange);
  const dateRangeDifferenceInMillis =
    dateRange.to.valueOf() - dateRange.from.valueOf();

  return {
    timeWindow: dateRangeDifferenceInMillis / DEFAULT_DIVIDER,
  };
}

export const timeWindowsWithLabels = [
  {
    label: '1 minute',
    value: TIME_UNITS_IN_MILLIS.minute,
  },
  {
    label: '5 minutes',
    value: TIME_UNITS_IN_MILLIS.minute * 5,
  },
  {
    label: '30 minutes',
    value: TIME_UNITS_IN_MILLIS.minute * 30,
  },
  {
    label: '1 hour',
    value: TIME_UNITS_IN_MILLIS.hour,
  },
  {
    label: '12 hours',
    value: TIME_UNITS_IN_MILLIS.hour * 12,
  },
  {
    label: '1 day',
    value: TIME_UNITS_IN_MILLIS.day,
  },
  {
    label: '7 days',
    value: TIME_UNITS_IN_MILLIS.day * 7,
  },
  {
    label: '1 month',
    value: TIME_UNITS_IN_MILLIS.month,
  },
];

const MAX_AGGREGATED_POINTS = 100;

export const getAvailableTimeWindowsWithLabels = (timeRange: TimeRange) => {
  const dateRange = convertTimeRangeToDateRange(timeRange);
  const dateRangeDifferenceInMillis =
    dateRange.to.valueOf() - dateRange.from.valueOf();

  return timeWindowsWithLabels.filter((opt) =>
    between(
      opt.value,
      dateRangeDifferenceInMillis / MAX_AGGREGATED_POINTS,
      dateRangeDifferenceInMillis
    )
  );
};
