import { PrecisionRecallWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/CurveChartWidget';
import DefaultMatchRemoteData from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { usePrecisionRecall } from 'features/monitoring/widgets/store/precisionRecall/usePrecisionRecall';

import { MonitoringWidgetProps } from '../../../shared/types';
import PrecisionRecallChart from './PrecisionRecallChart/PrecisionRecallChart';

const PrecisionRecallWidgetView = (
  props: MonitoringWidgetProps<PrecisionRecallWidget>
) => {
  const { data: precisionRecall, communication: loadingPrecisionRecall } =
    usePrecisionRecall({
      widgetExternalDeps: props.widgetExternalDeps,
      output: props.widget.output,
    });

  return (
    <DefaultMatchRemoteData
      data={precisionRecall}
      communication={loadingPrecisionRecall}
      context="loading precision recall"
    >
      {(loadedData) => (
        <PrecisionRecallChart
          id={props.id}
          precisionRecall={loadedData.precisionRecall}
          totalPositives={loadedData.totalPositives}
          totalPredictions={loadedData.totalPredictions}
          onSetReset={props.onSetReset}
          widgetSize={props.size}
        />
      )}
    </DefaultMatchRemoteData>
  );
};

export default PrecisionRecallWidgetView;
