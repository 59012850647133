import { useEffect } from 'react';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { Button, LinkButton } from 'shared/view/elements/Button';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import Chip from 'shared/view/elements/Chip/Chip';
import { useWorkaceEndpointCount } from 'features/homepage/hooks/useWorkaceEndpointCount';
import { selectFlags } from 'features/flags';
import pluralize from 'shared/utils/pluralize';
import { getEnvironment } from 'shared/models/Deployment/canary/Endpoint';
import { EndpointEnvironmentStatus } from 'generated/types';
import routes from 'shared/routes';

import { Container, IconWrapper } from './styled';

const DISABLE_COLOR = 'text.disabled';

const BottomSide = (props: {
  isRequesting: boolean;
  isFreetier: boolean;
  handleRequestContact: (s: string) => void;
  workspaceName: string;
}) => (
  <>
    {props.isRequesting ? (
      <Skeleton variant="rectangular" width={115} height={30} />
    ) : (
      <>
        {props.isFreetier ? (
          <Button
            variant="text"
            onClick={() => props.handleRequestContact('deployment')}
            size="small"
            isLoading={false}
          >
            Upgrade to access
          </Button>
        ) : (
          <LinkButton
            to={routes.endpoints.getRedirectPath({
              workspaceName: props.workspaceName,
            })}
            variant="outlined"
            size="small"
          >
            Deploy a Model
          </LinkButton>
        )}
      </>
    )}
  </>
);

export const EndpointsSummary = (props: {
  workspaceName: string;
  organizationId: string | undefined;
  isFreetier: boolean;
  handleRequestContact: (s: string) => void;
}) => {
  const { organizationId, workspaceName, isFreetier, handleRequestContact } =
    props;
  const { deployment } = useSelector(selectFlags);

  const { data, communication, load } = useWorkaceEndpointCount();

  useEffect(() => {
    if (deployment.isEnable) {
      load({ variables: { workspaceName, organizationId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const endpointsCount: number = isNotError(data)
    ? data.endpoints.endpoints.filter(
        (endpoint) =>
          getEnvironment(endpoint)?.status === EndpointEnvironmentStatus.ACTIVE
      ).length
    : 0;

  const externalCount = isNotError(data)
    ? data.registeredModelVersions.versions.reduce(
        (sum, version) => sum + version.externalDeployments.length,
        0
      )
    : 0;

  return (
    <Container spacing={4}>
      <Stack direction="row" justifyContent="space-between">
        <IconWrapper icon={ICONS.endpoints} isDisabled={isFreetier} />
        {isFreetier && (
          <Chip
            label="Not available"
            variant="filled"
            color="default"
            size="small"
          />
        )}
      </Stack>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex="1 1 auto" mb={2} display="flex" flexDirection="column">
          <Typography
            variant="h2"
            color={isFreetier ? DISABLE_COLOR : 'text.primary'}
          >
            {communication.isRequesting ? (
              <Skeleton variant="text" width={50} />
            ) : isFreetier ? (
              '-'
            ) : (
              endpointsCount
            )}
          </Typography>

          <Typography
            variant="subtitle1"
            color={isFreetier ? DISABLE_COLOR : 'text.secondary'}
          >
            {communication.isRequesting ? (
              <Skeleton variant="text" width={100} />
            ) : isFreetier ? (
              `Number of ${pluralize(
                'enpoint',
                endpointsCount,
                false
              )} in Verta`
            ) : (
              `Number of ${pluralize('deploy', endpointsCount, false)} in Verta`
            )}
          </Typography>

          <Typography
            variant="body2"
            color={isFreetier ? DISABLE_COLOR : 'text.secondary'}
          >
            {communication.isRequesting ? (
              <Skeleton variant="text" width={150} />
            ) : (
              <>
                {isFreetier ? (
                  <>
                    <strong>+ 0</strong> External deploys
                  </>
                ) : (
                  <>
                    <strong>{externalCount}</strong>{' '}
                    {pluralize('External deploy', endpointsCount, false)}
                  </>
                )}
              </>
            )}
          </Typography>
        </Box>
        <Box flex="0 1 auto" display="flex">
          <BottomSide
            isRequesting={communication.isRequesting}
            isFreetier={isFreetier}
            handleRequestContact={handleRequestContact}
            workspaceName={workspaceName}
          />
        </Box>
      </Box>
    </Container>
  );
};
