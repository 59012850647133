import matchBy from 'shared/utils/matchBy';

export const getNewOwnerDisplayedName = (
  data:
    | { __typename: 'User'; username: string; fullName?: string }
    | { __typename: 'Group'; name: string }
) => {
  return matchBy(
    data,
    '__typename'
  )({
    User: ({ username, fullName }) => fullName || username,
    Group: ({ name }) => name,
  });
};
