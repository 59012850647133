import cn from 'classnames';
import html2 from 'html2pdf.js';
import { useState } from 'react';

import IconAwesomeClickableWithLabel from 'shared/view/elements/IconAwesome/withLabel/IconAwesomeClickableWithLabel';
import { DashboardIsSelectedCase } from 'features/experimentRuns/crossRunDashboard/store/crossRunDashboardManager/crossRunDashboardManager';
import DashboardSelector from 'features/experimentRuns/crossRunDashboard/view/CrossRunDashboard/DashboardSelector/DashboardSelector';
import CloneDashboardModal from 'features/experimentRuns/crossRunDashboard/view/CrossRunDashboard/ActionsPanel/CloneDashboardModal/CloneDashboardModal';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import NameFormPopup, {
  NameFormPopupType,
} from 'shared/view/domain/Dashboards/NameForm/NameFormPopup';
import Button from 'shared/view/elements/Button/Button';
import DefaultConfirmAction, {
  ConfirmType,
} from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';
import DefaultMatchRemoteMutation from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteMutation';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import styles from './ActionsPanel.module.css';

function ActionsPanel(props: DashboardIsSelectedCase) {
  const deleteDashboardAction = props.deleteDashboardAction;
  return (
    <div className={styles.panel}>
      <div className={styles.panel__leftActions}>
        <div className={cn(styles.dashboard__action, styles.dashboardSelector)}>
          <DashboardSelector
            disabled={props.selectDashboardAction.disabled}
            dashboardId={props.selectedCrossRunDashboard.id}
            dashboards={props.crossRunDashboards}
            onChange={props.selectDashboardAction.selectDashboard}
          />
        </div>
      </div>
      <div className={styles.panel__rightActions}>
        {props.dashboardChangesActions ? (
          <DefaultMatchRemoteMutation
            communication={props.dashboardChangesActions.save.savingDashboard}
          >
            <>
              <div className={styles.dashboard__action}>
                <IconAwesomeClickableWithLabel
                  icon={ICONS.bookmark}
                  disabled={props.dashboardChangesActions.save.disabled}
                  onClick={
                    props.dashboardChangesActions.save.saveDashboardChanges
                  }
                  label={'Save Changes'}
                />
              </div>
              <div className={styles.dashboard__action}>
                <IconAwesomeClickableWithLabel
                  onClick={
                    props.dashboardChangesActions.reset.resetDashboardChanges
                  }
                  icon={ICONS.arrowRotateLeft}
                  label={'Reset Changes'}
                />
              </div>
            </>
          </DefaultMatchRemoteMutation>
        ) : null}
        {props.cloneDashboardAction && (
          <div className={styles.dashboard__action}>
            <IconAwesomeClickableWithLabel
              icon={ICONS.clone}
              onClick={props.cloneDashboardAction.openCloneDashboard}
              label={'Clone'}
            />
            {props.cloneDashboardAction.isCloneDashboardOpen && (
              <CloneDashboardModal
                makeCrossRunDashboardName={
                  props.cloneDashboardAction.makeCrossRunDashboardName
                }
                cloning={props.cloneDashboardAction.cloningDashboard}
                onClone={props.cloneDashboardAction.cloneDashboard}
                isOpen={props.cloneDashboardAction.isCloneDashboardOpen}
                onRequestClose={props.cloneDashboardAction.closeCloneDashboard}
              />
            )}
          </div>
        )}
        {deleteDashboardAction && (
          <DefaultMatchRemoteMutation
            communication={deleteDashboardAction.deletingDashboard}
          >
            <DefaultConfirmAction
              description="dashboard"
              type={ConfirmType.delete}
            >
              {(withConfirm) => (
                <div className={styles.dashboard__action}>
                  <IconAwesomeClickableWithLabel
                    icon={ICONS.trashCan}
                    onClick={withConfirm(deleteDashboardAction.deleteDashboard)}
                    label={'Delete'}
                  />
                </div>
              )}
            </DefaultConfirmAction>
          </DefaultMatchRemoteMutation>
        )}

        <div className={styles.dashboard__action}>
          <DownloadDashboardAsPdf />
        </div>
        <div className={styles.dashboard__action}>
          <CreatePanelAction onCreate={props.addPanel} />
        </div>
      </div>
    </div>
  );
}

const DownloadDashboardAsPdf = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <IconAwesomeClickableWithLabel
      icon={ICONS.arrowDownToBracket}
      disabled={isDownloading}
      label={'Download PDF'}
      onClick={() => {
        setIsDownloading(true);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        html2(document.getElementById('dashboard'), {
          filename: 'dashboard.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: {
            orientation: 'p',
            unit: 'px',
            format: [1070, 2534 + 100],
            putOnlyUsedFonts: true,
            floatPrecision: 16,
          },
        }).then(() => {
          setIsDownloading(false);
        });
      }}
    />
  );
};

const CreatePanelAction = ({ onCreate }: { onCreate(name: string): void }) => {
  const { openPopup, isPopupOpen, closePopup } = usePopupManager();

  return (
    <div>
      <Button variant="outlined" onClick={openPopup} isLoading={false}>
        Add panel
      </Button>
      <NameFormPopup
        entityName="panel"
        type={NameFormPopupType.Add}
        isOpen={isPopupOpen}
        initialValues={{
          name: '',
        }}
        onSubmit={({ name }) => {
          onCreate(name);
          closePopup();
        }}
        closePopup={closePopup}
      />
    </div>
  );
};

export default ActionsPanel;
