import { Box, Stack, styled, Typography } from '@mui/material';

import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import HuggingFaceSVG from 'features/homepage/images/hugging-face.svg?react';
import VertaSVG from 'features/homepage/images/verta.svg?react';
import ColabSVG from 'features/homepage/images/colab.svg?react';

import { LibraryType, LocationType, SourceType } from './CardsTypes';

const StyleBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

interface TagsProps {
  source?: SourceType;
  library: LibraryType;
  location: LocationType;
}

const HandleSourceIcon = ({ source }: { source: SourceType }) => {
  switch (source) {
    case SourceType.HUGGING_FACE:
      return <HuggingFaceSVG />;
    case SourceType.VERTA_NOTEBOOK:
      return <VertaSVG />;
    default:
      return <ColabSVG />;
  }
};

export const CardTags = (props: TagsProps) => {
  const { library, source, location } = props;

  const tags = [];

  if (source) {
    tags.push({
      tagType: source,
      icon: <HandleSourceIcon source={source} />,
    });
  }

  tags.push(
    {
      tagType: library,
      icon: <IconAwesomeCustomizable icon={ICONS.books} size="xs" />,
    },
    {
      tagType: location,
      icon: (
        <IconAwesomeCustomizable
          icon={location === LocationType.CLOUD ? ICONS.cloud : ICONS.laptop}
          size="xs"
        />
      ),
    }
  );

  return (
    <Stack direction="row" spacing={1}>
      {tags.map((tag) => (
        <StyleBox color="text.secondary" key={tag.tagType}>
          {tag.icon}
          <Typography ml="3px" variant="caption">
            {tag.tagType}
          </Typography>
        </StyleBox>
      ))}
    </Stack>
  );
};
