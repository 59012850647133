// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { Line } from '@visx/shape';
import { ScaleLinear } from 'd3-scale';

import { ChartSizes } from 'shared/utils/charts/chartSizes';
import { useChartSizeScales } from 'shared/utils/charts/sizeScale/useChartSizeScales';

import CustomTooltipWithBounds from '../../shared/CustomTooltipWithBound/CustomTooltipWithBound';
import { MultiLineChartData } from '../../MultiLineChart/utils/types';
import { findClosestPoint } from '../../MultiLineChart/utils/point';

export interface MultiLineChartTooltipData<Meta> {
  points: MultiLineChartData<Meta>[];
  closestPoint: MultiLineChartData<Meta>;
}

export const multiLineChartTooltipXOffset = 70;

export function useMultiLineChartTooltipWithView<Meta>(props: {
  data: MultiLineChartData<Meta>[];
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  colorScale: (key: string) => string;
  chartSizes: ChartSizes;
  renderTooltipContent?: (
    tooltipData: MultiLineChartTooltipData<Meta>
  ) => React.ReactNode;
  onTooltipAreaClick?: (
    tooltipData: MultiLineChartTooltipData<Meta> | undefined
  ) => void;
}) {
  const { handleTooltip, hideTooltip, tooltipData, tooltipLeft } =
    useMultiLineChartTooltip(props);

  const tooltipView = tooltipData && props.renderTooltipContent && (
    <CustomTooltipWithBounds
      top={20}
      left={Number(tooltipLeft) + multiLineChartTooltipXOffset}
    >
      {props.renderTooltipContent(tooltipData)}
    </CustomTooltipWithBounds>
  );

  const tooltipIndicator = tooltipData && (
    <g>
      <Line
        from={{ x: tooltipLeft, y: 0 }}
        to={{ x: tooltipLeft, y: props.chartSizes.innerHeight }}
        stroke={props.colorScale(tooltipData.closestPoint.key)}
        strokeWidth={1.5}
        pointerEvents="none"
        strokeDasharray="1.5 1.5"
      />

      <circle
        cx={props.xScale(tooltipData.closestPoint.x)}
        cy={props.yScale(tooltipData.closestPoint.y)}
        r={3}
        fill={props.colorScale(tooltipData.closestPoint.key)}
        stroke={props.colorScale(tooltipData.closestPoint.key)}
        strokeWidth={3}
        pointerEvents="none"
      />
    </g>
  );

  return {
    tooltipView,
    tooltipIndicator,
    tooltipAreaClick: () => props.onTooltipAreaClick?.(tooltipData),
    handleTooltip,
    hideTooltip,
    tooltipData,
  };
}

function useMultiLineChartTooltip<Meta>({
  data,
  xScale,
  yScale,
  chartSizes,
}: {
  data: MultiLineChartData<Meta>[];
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  chartSizes: ChartSizes;
}) {
  const tooltip = useTooltip<MultiLineChartTooltipData<Meta>>();

  const { scaleChartX, scaleChartY } = useChartSizeScales({ chartSizes });

  const handleTooltip = (event: React.MouseEvent<SVGGElement>) => {
    if (data.length === 0) {
      return;
    }

    const { x, y } = localPoint(event) || { x: 0, y: 0 };
    const scaledX = scaleChartX(x);
    const scaledY = scaleChartY(y);

    const invertedX = xScale.invert(scaledX);
    const invertedY = yScale.invert(scaledY);

    const closestPoint = findClosestPoint({ x: invertedX, y: invertedY }, data);

    const activePoints = data
      .filter((p) => p.x === closestPoint.x)
      .sort((a, b) => b.y - a.y);

    tooltip.showTooltip({
      tooltipData: {
        points: activePoints,
        closestPoint,
      },
      tooltipLeft: xScale(closestPoint.x),
    });
  };

  return { ...tooltip, handleTooltip };
}
