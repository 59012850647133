import LockLevelSelector from 'shared/view/domain/Registry/ModelVersion/LockLevel/LockLevelSelector/LockLevelSelector';
import {
  getChangingLockLevelsAccessForNewVersion,
  RegisteredModelVersionLockLevelWithoutUnknown,
} from 'shared/models/Registry/RegisteredModelVersion/LockLevel';

import { ILockLevelFormDeps, ILockLevelForm } from './LockLevelFormModel';

interface ILocalProps extends ILockLevelForm, ILockLevelFormDeps {
  disabled?: boolean;
  onChange(lockLevel: RegisteredModelVersionLockLevelWithoutUnknown): void;
}

const LockLevelForm = (props: ILocalProps) => {
  return (
    <LockLevelSelector
      isChanging={false}
      disabled={props.disabled}
      lockLevel={props.lockLevel}
      changingLockLevelsAccess={getChangingLockLevelsAccessForNewVersion(
        props.allowedActions
      )}
      onChange={props.onChange}
    />
  );
};

export default LockLevelForm;
