import { ReactNode, useMemo } from 'react';

import WidgetFormPopupContent from 'shared/view/domain/Dashboards/WidgetFormPopupContent/WidgetFormPopupContent';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';

import MonitoringWidgetPreview, {
  MonitoringWidgetPreviewType,
} from './MonitoringWidgetPreview/MonitoringWidgetPreview';

interface Props<Widget extends MonitoringWidgetPreviewType> {
  widgetPreview: Widget | null;
  onReset: () => void;
  onBack?: () => void;
  children: ReactNode;
  submitText: string;
  isValid: boolean;
  onSubmitClick(): void;
  widgetExternalDeps: MonitoringWidgetExternalDeps;
}

const MonitoringWidgetFormPopupContent = <
  Widget extends MonitoringWidgetPreviewType,
>(
  props: Props<Widget>
) => {
  const submitButtonProps = useMemo(
    () => ({
      onClick: props.onSubmitClick,
      isLoading: false,
    }),
    [props.onSubmitClick]
  );

  return (
    <WidgetFormPopupContent
      {...props}
      submitButtonProps={submitButtonProps}
      widgetPreview={
        <MonitoringWidgetPreview
          widgetPreview={props.widgetPreview}
          widgetExternalDeps={props.widgetExternalDeps}
        />
      }
    />
  );
};

export default MonitoringWidgetFormPopupContent;
