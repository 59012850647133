import { gql } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { USERV2_FRAGMENT } from 'shared/graphql/User/User';

import * as GraphqlTypes from './graphql-types/useUpdateOrganization.generated';

const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganizationV2(
    $organizationId: ID!
    $name: String!
    $description: String!
    $adminEmails: [String!]!
  ) {
    organizationV2(id: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        update(
          input: {
            adminEmails: $adminEmails
            description: $description
            name: $name
          }
        ) {
          id
          name
          description
          admins {
            id
            ...UserV2Data
          }
        }
      }
    }
  }
  ${USERV2_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useUpdateOrganization = (onCompleted?: () => void) => {
  const [run, communication] = useCustomMutation<
    GraphqlTypes.UpdateOrganizationV2,
    GraphqlTypes.UpdateOrganizationV2Variables
  >(UPDATE_ORGANIZATION, { context: 'updating organization' }, { onCompleted });

  return {
    run,
    communication,
  };
};
