import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { FieldArray } from 'formik';
import { equals } from 'ramda';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useState } from 'react';

import { makeGetFieldName } from 'shared/utils/getFieldName';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import { validateNotEmpty } from 'shared/utils/validators';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import InfoIcon from 'shared/view/elements/InfoIcon/InfoIcon';
import Popup from 'shared/view/elements/Popup/Popup';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import Button from 'shared/view/elements/Button/Button';
import isNotNil from 'shared/utils/isNotNill';
import { validateOrganizationNamespace } from 'shared/models/Organization/Organization';

import { OrganizationWorkspacesV2 } from '../store/useOrganizationWorkspaces';

const getValidPermissions = (
  permissions: Array<{
    groupId: string | undefined;
    roleId: string | undefined;
  }>
): Array<{
  groupId: string;
  roleId: string;
}> =>
  permissions
    .map((p) =>
      p.groupId && p.roleId
        ? { groupId: p.groupId, roleId: p.roleId }
        : undefined
    )
    .filter(isNotNil);

const WorkspacePopup = (props: {
  title: string;
  settings: WorkspaceSettingsForm;
  roles: OrganizationWorkspacesV2['roles'];
  groups: OrganizationWorkspacesV2['groups'];
  submitButton: {
    children: 'Create' | 'Update';
    isLoading: boolean;
    onSubmit(w: WorkspaceSettingsForm): void;
  };
  onClose(): void;
}) => {
  return (
    <PresetFormik
      initialValues={props.settings}
      onSubmit={(form) =>
        props.submitButton.onSubmit({
          ...form,
          permissions: getValidPermissions(form.permissions),
        })
      }
    >
      {({ values, isValid, submitForm }) => (
        <Popup
          isOpen={true}
          title={props.title}
          fullWidth={true}
          maxWidth="xs"
          buttons={{
            secondaryButtonProps: {
              children: 'Cancel',
              isLoading: false,
              onClick: props.onClose,
            },
            mainButtonProps: {
              ...props.submitButton,
              disabled:
                !isValid ||
                equals(values, props.settings) ||
                getValidPermissions(values.permissions).length === 0,
              type: 'button',
              onClick: submitForm,
            },
          }}
          onClose={props.onClose}
          dialogContentProps={{ sx: { scrollbarGutter: 'stable' } }}
        >
          <FormStack>
            <TextInputField
              isRequired={true}
              label="Name"
              name={getField({ name: null })}
              validate={validateNotEmpty('name')}
            />
            <TextInputField
              label="Description"
              name={getField({ description: null })}
            />
            <TextInputField
              label="Namespace"
              name={getField({ namespace: null })}
              validate={validateOrganizationNamespace}
            />
            <PermissionsField
              values={values}
              groups={props.groups}
              roles={props.roles}
            />
          </FormStack>
        </Popup>
      )}
    </PresetFormik>
  );
};

const PermissionsField = (props: {
  values: WorkspaceSettingsForm;
  roles: OrganizationWorkspacesV2['roles'];
  groups: OrganizationWorkspacesV2['groups'];
}) => {
  const [isShowRoleDescriptions, setIsShowRoleDescriptions] = useState(false);
  const groupOptions = props.groups.map((role) => ({
    label: role.name,
    value: role.id,
  }));
  const roleOptions = props.roles.map((group) => ({
    label: group.name,
    value: group.id,
  }));

  return (
    <FieldArray
      name={getField({ permissions: null })}
      render={(arrayHelpers) => (
        <Stack alignItems="end">
          {props.values.permissions.map((permission, i) => (
            <Grid container columnSpacing={2} key={i}>
              <Grid item xs={5}>
                <Stack direction="column" flexGrow={1} spacing={2}>
                  <Stack direction="row" alignItems="center">
                    <span>Step 1: Select a group</span>
                    {i === 0 ? (
                      <InfoIcon
                        iconSize="sm"
                        tooltip="Select one of the default groups that you want associated for this workspace. If a new group is required you must navigate to the “Groups” tab and create it first."
                      />
                    ) : null}
                  </Stack>
                  <SelectField
                    label="Select group"
                    name={getField({ permissions: { [i]: { groupId: null } } })}
                    dataTest={getField({
                      permissions: { [i]: { groupId: null } },
                    })}
                    options={groupOptions}
                    validate={validateNotEmpty('group')}
                  />
                </Stack>
              </Grid>
              <Grid item xs={7}>
                <Stack flexGrow={1} spacing={2}>
                  <Stack direction="row" alignItems="center">
                    <span>Step 2: Assign a role to the group</span>
                    {i === 0 ? (
                      <InfoIcon
                        iconSize="sm"
                        tooltip="Select one of the default roles that you want assigned to the selected group. If a new role is required you must navigate to the “Roles” tab and create it first."
                      />
                    ) : null}
                  </Stack>
                  <Stack direction="row">
                    <Stack direction="column" flexGrow={1} spacing={0.5}>
                      <SelectField
                        label="Assign role"
                        name={getField({
                          permissions: { [i]: { roleId: null } },
                        })}
                        dataTest={getField({
                          permissions: { [i]: { roleId: null } },
                        })}
                        options={roleOptions}
                        disabled={!permission.groupId}
                        withoutError={true}
                        validate={validateNotEmpty('role')}
                      />
                      {i === 0 ? (
                        <Button
                          variant="text"
                          size="small"
                          isLoading={false}
                          onClick={() =>
                            setIsShowRoleDescriptions(!isShowRoleDescriptions)
                          }
                        >
                          {isShowRoleDescriptions
                            ? 'Hide role descriptions'
                            : 'Show role descriptions'}
                        </Button>
                      ) : null}
                    </Stack>
                    <Stack direction="row" paddingTop={2}>
                      <IconAwesomeClickable
                        icon={ICONS.circlePlus}
                        dataTest="add-permission"
                        onClick={() => {
                          const data: WorkspaceSettingsForm['permissions'][number] =
                            {
                              groupId: undefined,
                              roleId: undefined,
                            };
                          arrayHelpers.push(data);
                        }}
                      />
                      {props.values.permissions.length > 1 ? (
                        <IconAwesomeClickable
                          icon={ICONS.trashCan}
                          dataTest="remove-permission"
                          onClick={() => {
                            arrayHelpers.remove(i);
                          }}
                        />
                      ) : null}
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          ))}
          {isShowRoleDescriptions ? (
            <Stack direction="column" spacing={3} alignSelf="start">
              {props.roles.map((role) => (
                <Stack key={role.name} spacing={0.5}>
                  <Typography variant="inputText" fontWeight={500}>
                    {role.name} {role.builtIn ? '(Locked)' : ''}
                  </Typography>
                  <Typography variant="inputText">
                    {role.description}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          ) : null}
        </Stack>
      )}
    />
  );
};

const getField = makeGetFieldName<WorkspaceSettingsForm>();

export type WorkspaceSettingsForm = {
  name: string;
  description: string;
  namespace: string;
  permissions: Array<{
    groupId: string | undefined;
    roleId: string | undefined;
  }>;
};

export default WorkspacePopup;
