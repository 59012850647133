import { useTheme } from '@mui/material/styles';
import React from 'react';
import { toPairs } from 'ramda';
import { NumberValue, ScaleOrdinal } from 'd3-scale';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { curveLinear } from '@visx/curve';
import { GridRows } from '@visx/grid';

import { ChartSizes } from 'shared/utils/charts/chartSizes';
import LinePathOrPoint from 'shared/view/charts/shared/shapes/LinePathOrPoint/LinePathOrPoint';
import AxisBottomWithRotatedTicks from 'shared/view/charts/Axis/AxisBottomWithRotatedTicks/AxisBottomWithRotatedTicks';
import { getLinearScaleDomain } from 'shared/utils/charts/getLinearScaleDomain';

import { useObservationChartTooltip } from '../utils/tooltip';
import {
  formatObservationValue,
  formatObservationTimestamp,
} from '../utils/helpers';
import {
  getObservationValue,
  getObservationTimestamp,
  getObservationEpochNumber,
} from '../utils/accessors';
import { useObservationChartData } from '../utils/dataHooks';
import {
  useXAxisSettings,
  matchObservationChartAxisSettings,
  ObservationChartXAxisType,
} from '../utils/settings';
import { ChartObservation } from '../utils/types';
import styles from './ObservationChart.module.css';

interface ILocalProps {
  observations: ChartObservation[];
  attributeKey: string;
  sizes: ChartSizes;
  xAxisType: ObservationChartXAxisType;
  colorScale: ScaleOrdinal<string, string>;
  colors: {
    accentColor: string;
  };
}

const tickFormatValue = (n: NumberValue) => formatObservationValue(n.valueOf());

const ObservationChart: React.FC<React.PropsWithChildren<ILocalProps>> = ({
  observations,
  attributeKey,
  sizes,
  colors: { accentColor },
  colorScale,
  xAxisType,
}) => {
  const {
    groupedObservations,
    groupedObservationsWithEpoch,
    sortedObservations,
    sortedObservationsWithEpoch,
  } = useObservationChartData({ observations, xAxisType });

  const { width, height, innerWidth, innerHeight, margin } = sizes;

  const valueScale = scaleLinear({
    domain: getLinearScaleDomain(
      Object.values(groupedObservations).flat().map(getObservationValue)
    ),
    range: [innerHeight, 0],
    nice: true,
  });

  const xAxisSettings = useXAxisSettings({
    chartSizes: sizes,
    observations,
    xAxisType,
  });

  const { tooltip, tooltipSVGLine, handlingSVGBar } =
    useObservationChartTooltip({
      xAxisSettings,
      colorScale,
      valueScale,
      chartData: {
        observations: sortedObservations,
        observationsWithEpoch: sortedObservationsWithEpoch,
      },
      renderTooltipContent: (obs) =>
        obs.slice(0, 10).map((observation) => (
          <div
            style={{
              color: colorScale(observation.experimentRun.id),
              marginBottom: '3px',
            }}
            key={observation.experimentRun.id}
          >
            {`${observation.experimentRun.name}: `}
            <b>{formatObservationValue(getObservationValue(observation))}</b>
          </div>
        )),
      chartSizes: sizes,
    });

  const theme = useTheme();

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: theme.palette.charts.backgroundColor }}
    >
      <h6 className={styles.title} title={attributeKey}>
        {attributeKey}
      </h6>
      <div
        className={styles.chartContent}
        style={{ position: 'relative', width }}
      >
        <svg width={width} height={height}>
          <Group left={margin.left} top={margin.top}>
            <AxisLeft
              scale={valueScale}
              stroke={accentColor}
              tickStroke={accentColor}
              strokeWidth={0}
              hideTicks={true}
              numTicks={4}
              tickFormat={(v) => formatObservationValue(v.valueOf())}
            />
            <GridRows
              scale={valueScale}
              width={innerWidth}
              stroke={accentColor}
              pointerEvents="none"
              numTicks={4}
              strokeWidth={0.5}
            />

            {matchObservationChartAxisSettings(
              {
                timestamp: ({ scale }) => (
                  <>
                    <AxisBottomWithRotatedTicks
                      top={innerHeight}
                      scale={scale}
                      stroke={accentColor}
                      tickStroke={accentColor}
                      tickFormat={(v) =>
                        formatObservationTimestamp(new Date(Number(v)))
                      }
                      label="Timestamp"
                    />

                    {toPairs(groupedObservations).map(
                      ([experimentRunId, data]) => (
                        <LinePathOrPoint
                          key={experimentRunId}
                          curve={curveLinear}
                          data={data}
                          x={(d) => scale(getObservationTimestamp(d))}
                          y={(d) => valueScale(getObservationValue(d))}
                          stroke={colorScale(experimentRunId)}
                          strokeWidth={2}
                        />
                      )
                    )}
                  </>
                ),
                epochNumber: ({ scale }) => (
                  <>
                    {tickFormatValue.length > 3 ? (
                      <AxisBottomWithRotatedTicks
                        top={innerHeight}
                        scale={scale}
                        stroke={accentColor}
                        tickStroke={accentColor}
                        strokeWidth={0}
                        label="Epoch number"
                        tickFormat={tickFormatValue}
                      />
                    ) : (
                      <AxisBottom
                        top={innerHeight}
                        scale={scale}
                        stroke={accentColor}
                        tickStroke={accentColor}
                        strokeWidth={0}
                        label="Epoch number"
                        tickFormat={tickFormatValue}
                      />
                    )}
                    {toPairs(groupedObservationsWithEpoch).map(
                      ([experimentRunId, data]) => (
                        <LinePathOrPoint
                          key={experimentRunId}
                          curve={curveLinear}
                          data={data}
                          x={(d) => scale(getObservationEpochNumber(d)) || 0}
                          y={(d) => valueScale(Number(getObservationValue(d)))}
                          stroke={colorScale(experimentRunId)}
                          strokeWidth={2}
                        />
                      )
                    )}
                  </>
                ),
              },
              xAxisSettings
            )}

            {tooltipSVGLine}
          </Group>
          {handlingSVGBar}
        </svg>
        {tooltip}
      </div>
    </div>
  );
};

export default ObservationChart;
