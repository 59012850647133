import { useMemo } from 'react';
import { scaleOrdinal } from '@visx/scale';
import { Stack, Typography } from '@mui/material';

import { Size } from 'shared/utils/charts/chartSizes';
import { OnSetReset } from 'shared/utils/charts/zoom/useZoomReset';
import { MultiLineChartData } from 'shared/view/charts/MultiLineChart/utils/types';
import ChartWithLegendContainer from 'shared/view/charts/ChartWithLegendContainer/ChartWithLegendContainer';
import { chartsDataColors } from 'shared/view/charts/shared/colors';
import { useChartManager } from 'shared/utils/charts/chartManager/useChartManager';
import MultiLineChart from 'shared/view/charts/MultiLineChart/MultiLineChart';
import { LegendColorShape } from 'shared/view/charts/CustomLegend/CustomLegend';

import { makeLogisticPoints } from '../makeLogisticPoints';
import { makeNoSkillPoints } from '../makeNoSkillPoints';

interface Axis {
  label: string;
  groups: Array<{
    modelVersion: string;
    values: number[];
  }>;
}

interface Props {
  id: string;
  widgetSize: Size;
  onSetReset: OnSetReset;
  xAxis: Axis;
  yAxis: Axis;
  noSkillY1: number;
  noSkillY2: number;
}

const BaseRocChart = (props: Props) => {
  const chartData = useMemo(
    () =>
      convertData({
        xAxis: props.xAxis,
        yAxis: props.yAxis,
        noSkillY1: props.noSkillY1,
        noSkillY2: props.noSkillY2,
      }),
    [props.noSkillY1, props.noSkillY2, props.xAxis, props.yAxis]
  );

  const xMinMax = useMemo(
    () => getMinMax(props.xAxis.groups.flatMap((v) => v.values)),
    [props.xAxis]
  );

  const yMinMax = useMemo(
    () =>
      getMinMax([
        ...props.yAxis.groups.flatMap((v) => v.values),
        props.noSkillY1,
        props.noSkillY2,
      ]),
    [props.noSkillY1, props.noSkillY2, props.yAxis.groups]
  );

  const keys = useMemo(() => chartData.map((d) => d.key), [chartData]);

  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        range: chartsDataColors,
        domain: keys,
      }),
    [keys]
  );

  const { data, legendProps } = useChartManager({
    legendPosition: 'right',
    chartData,
    colorScale,
    keys,
  });

  return (
    <ChartWithLegendContainer
      widgetSize={props.widgetSize}
      legendProps={legendProps}
    >
      {(chartSize: Size) => (
        <MultiLineChart
          id={props.id}
          data={data}
          onSetReset={props.onSetReset}
          width={chartSize.width}
          height={chartSize.height}
          colorScale={colorScale}
          xDomain={xMinMax}
          yDomain={yMinMax}
          xLabel={props.xAxis.label}
          yLabel={props.yAxis.label}
          xNumTicks={6}
          hidePoints
          renderTooltipContent={(d) => (
            <Stack>
              <Stack direction="row">
                <LegendColorShape color={colorScale(d.closestPoint.key)} />
                <Typography variant="tooltip">{d.closestPoint.key}</Typography>
              </Stack>
              <Typography variant="tooltip">{`${props.yAxis.label}: ${d.closestPoint.y}`}</Typography>
              <Typography variant="tooltip">{`${props.xAxis.label}: ${d.closestPoint.x}`}</Typography>
            </Stack>
          )}
        />
      )}
    </ChartWithLegendContainer>
  );
};

const getMinMax = (values: number[]): [number, number] => {
  const min = Math.min(...values);
  const max = Math.max(...values);
  return [min, max];
};

const convertData = ({
  xAxis,
  yAxis,
  noSkillY1,
  noSkillY2,
}: Pick<
  Props,
  'xAxis' | 'yAxis' | 'noSkillY1' | 'noSkillY2'
>): MultiLineChartData<undefined>[] => {
  const logistic = makeLogisticPoints({
    xGroups: xAxis.groups,
    yGroups: yAxis.groups,
  });
  const noSkill = makeNoSkillPoints({ y1: noSkillY1, y2: noSkillY2 });

  if (logistic.length > 0) {
    return [...logistic, ...noSkill];
  }

  return [];
};

export default BaseRocChart;
