import { useCallback, useState } from 'react';
import { DragStart, DragUpdate, DropResult } from 'react-beautiful-dnd';

import isNotNil from 'shared/utils/isNotNill';

export interface PlaceholderProps {
  clientHeight: number;
  clientWidth: number;
  clientY: number;
  clientX: number;
}

const getDraggedDom = (draggableId: string) => {
  const domQuery = `[data-rbd-draggable-id='${draggableId}']`;
  return document.querySelector(domQuery);
};

export const usePanelsDragAndDrop = ({
  onReorder,
}: {
  onReorder: (
    draggableId: string,
    startIndex: number,
    endIndex: number
  ) => void;
}) => {
  const [placeholderProps, changePlaceholderProps] =
    useState<PlaceholderProps | null>(null);

  const handleDragStart = (event: DragStart) => {
    const draggedDOM = getDraggedDom(event.draggableId);

    if (draggedDOM?.parentElement) {
      const { clientHeight, clientWidth } = draggedDOM;
      const sourceIndex = event.source.index;
      const clientY =
        parseFloat(
          window.getComputedStyle(draggedDOM.parentElement).paddingTop
        ) +
        [...draggedDOM.parentElement.children]
          .slice(0, sourceIndex)
          .reduce((total, curr) => {
            const style = window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.clientHeight + marginBottom;
          }, 0);

      changePlaceholderProps({
        clientHeight,
        clientWidth,
        clientY,
        clientX: parseFloat(
          window.getComputedStyle(draggedDOM.parentElement).paddingLeft
        ),
      });
    }
  };

  const handleDragUpdate = (event: DragUpdate) => {
    const draggedDOM = getDraggedDom(event.draggableId);

    if (event.destination && draggedDOM?.parentElement) {
      const { clientHeight, clientWidth } = draggedDOM;
      const destinationIndex = event.destination.index;
      const sourceIndex = event.source.index;

      const childrenArray = [...draggedDOM.parentElement.children];
      const movedItem = childrenArray[sourceIndex];
      childrenArray.splice(sourceIndex, 1);

      const updatedArray = [
        ...childrenArray.slice(0, destinationIndex),
        movedItem,
        ...childrenArray.slice(destinationIndex + 1),
      ];

      const clientY =
        parseFloat(
          window.getComputedStyle(draggedDOM.parentElement).paddingTop
        ) +
        updatedArray
          .slice(0, destinationIndex)
          .filter(isNotNil)
          .reduce((total, curr) => {
            const style = window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.clientHeight + marginBottom;
          }, 0);

      changePlaceholderProps({
        clientHeight,
        clientWidth,
        clientY,
        clientX: parseFloat(
          window.getComputedStyle(draggedDOM.parentElement).paddingLeft
        ),
      });
    }
  };

  const handleDragEnd = useCallback(
    (event: DropResult) => {
      if (event.destination) {
        onReorder(
          event.draggableId,
          event.source.index,
          event.destination.index
        );
      }
    },
    [onReorder]
  );

  return { handleDragStart, handleDragUpdate, handleDragEnd, placeholderProps };
};
