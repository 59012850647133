import { useMemo } from 'react';
import { ChipProps, alpha, useTheme } from '@mui/material';

import Chip from 'shared/view/elements/Chip/Chip';

import { RiskLevelType } from '../view/portfolio/TopModelsTable';

interface Props extends ChipProps {
  riskLevel?: string;
}

export const TypeChip = (props: Props) => {
  const { riskLevel } = props;
  const theme = useTheme();

  const chipStyle = useMemo(() => {
    switch (riskLevel) {
      case RiskLevelType.HIGH:
        return {
          backgroundColor: alpha(`${theme.palette.error.light}`, 0.1),
          color: theme.palette.error.main,
        };
      case RiskLevelType.MEDIUM:
        return {
          backgroundColor: alpha(`${theme.palette.warning.light}`, 0.1),
          color: theme.palette.warning.main,
        };
      default:
        return undefined;
    }
  }, [riskLevel, theme.palette]);

  return <Chip label={riskLevel} size="small" sx={chipStyle} />;
};
