import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';

import { BaseMonitoringWidget } from './shared/BaseMonitoringWidget';
import { BaseNotInitializedWidget } from './shared/BaseNotInitializedWidget';

interface BaseScatterPlotWidget extends BaseMonitoringWidget {
  type: 'scatterPlot';
}

export interface OutlierDetectionChartWidget extends BaseScatterPlotWidget {
  variant: 'outlierDetection';
  ioDescription: MonitoringIODescription;
}

export type ScatterPlotWidget = OutlierDetectionChartWidget;

export type ScatterPlotWidgetVariant = ScatterPlotWidget['variant'];
export const defaultScatterPlotWidgetVariant: ScatterPlotWidgetVariant =
  'outlierDetection';

export type NotInitializedScatterPlotWidget = BaseNotInitializedWidget<{
  type: ScatterPlotWidget['type'];
  variant: ScatterPlotWidget['variant'];
}>;
