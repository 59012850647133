/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { useCallback } from 'react';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import {
  convertGraphqlExperimentRun,
  EXPERIMENT_RUN_FRAGMENT,
} from 'shared/graphql/ExperimentRunDetails/ExperimentRunDetails';
import {
  ExperimentRun,
  ExperimentRunVariables,
} from 'features/experimentRuns/details/store/graphql-types/useExperimentRun.generated';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import { convertDefaultProject } from 'pages/authorized/ProjectsPages/ProjectDetailsPages/shared/layouts/useDefaultProjects/useDefaultProject';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';

const EXPERIMENT_RUN = gql`
  query ExperimentRun($runId: ID!) {
    run(id: $runId) {
      ... on Error {
        ...ErrorData
      }
      ... on ExperimentRun {
        id
        ...ExperimentRunData
        project {
          id
          name
          description
          dateUpdated
          allowedActions {
            ...AllowedActionsData
          }
          attributes {
            ...AttributeData
          }
          workspace {
            id
            ...WorkspaceData
          }
          owner {
            ... on Group {
              id
              name
            }
            ... on User {
              id
              username
              fullName
            }
          }
          description
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
  ${EXPERIMENT_RUN_FRAGMENT}
`;

export const useExperimentRun = () => {
  const [loadQuery, queryResult] = useCustomLazyQuery<
    ExperimentRun,
    ExperimentRunVariables
  >(EXPERIMENT_RUN, {
    fetchPolicy: 'cache-first',
  });

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert,
    queryResult,
  });

  const loadExperimentRun = useCallback(
    ({ runId }: { runId: string }) => loadQuery({ variables: { runId } }),
    [loadQuery]
  );

  return {
    data,
    communication,
    loadExperimentRun,
  };
};

function memoizedConvert({ run }: ExperimentRun) {
  return mapDataOrError(run, (x) => ({
    run: convertGraphqlExperimentRun(x),
    project: convertDefaultProject(x.project),
  }));
}
