import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { IncidentType } from 'features/dashboard/dashboard/shared/DonutChartCard';
import Dot from 'shared/view/elements/Dot/Dot';
import { OverflowPopover } from 'shared/view/elements/OverflowPopover/OverflowPopover';

type LabelItemProps = {
  color: string;
  label?: string;
  id?: string | number;
};

const LabelItem = ({ color, label }: LabelItemProps) => (
  <Stack direction="row" spacing={1} width="max-content" alignItems="center">
    <Dot color={color} />
    <Typography variant="caption" color="black">
      {label}
    </Typography>
  </Stack>
);

type LabelsContainerProps = {
  labels: LabelItemProps[];
  chartType?: IncidentType;
};

export const LabelsContainer = ({
  labels,
  chartType,
}: LabelsContainerProps) => {
  const maxLabels = 8;
  const [visibleLabels, setVisibleLabels] = useState<LabelItemProps[]>(labels);
  const [overflowLabels, setOverflowLabels] = useState<LabelItemProps[]>([]);

  useEffect(() => {
    const hasManyLabels = labels.length > maxLabels;
    if (hasManyLabels) {
      setOverflowLabels(labels.slice(maxLabels - 1));
      setVisibleLabels(labels.slice(0, maxLabels - 1));
    }
  }, [labels]);

  const buildLabel = (label: LabelItemProps) => (
    <Box key={label.id}>
      <LabelItem color={label.color} label={label.label} />
    </Box>
  );

  return (
    <Box
      display="flex"
      gap={2}
      flexWrap="wrap"
      height="100%"
      justifyContent="center"
    >
      {visibleLabels.map((label) => (label.label ? buildLabel(label) : null))}
      {overflowLabels.length > 0 && (
        <OverflowPopover
          overflowItems={overflowLabels.map((label) => buildLabel(label))}
          itemsLabel={
            chartType === IncidentType.INCIDENTS
              ? 'Business Units'
              : 'Lifecycle Stages'
          }
        />
      )}
    </Box>
  );
};
