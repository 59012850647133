// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';

import { GetRouteParams } from 'shared/routes';
import { Route } from 'shared/routes/shared/routesTreeBuilder';

export const useRouteParams = <R extends Route<any, any, any, any, any>>(
  route: R
) => {
  return useParams() as GetRouteParams<typeof route>;
};
