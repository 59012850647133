import { Text, TextProps } from '@visx/text';
import { FC } from 'react';

import truncateString from 'shared/utils/truncateString';

interface Props extends TextProps {
  maxCharCount: number;
  children: string;
}

const SvgTextWithTruncation: FC<React.PropsWithChildren<Props>> = ({
  maxCharCount,
  children,
  ...props
}) => {
  return (
    <>
      <title>{children}</title>
      <Text {...props}>{truncateString(String(children), maxCharCount)}</Text>
    </>
  );
};

export default SvgTextWithTruncation;
