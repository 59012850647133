import { dissoc, mergeAll, mergeRight, pipe, values } from 'ramda';
import { useSelector } from 'react-redux';

import { selectFlags } from 'features/flags';
import { IEnvironmentVariable } from 'shared/models/Deployment/canary/EndpointMachineConfiguration';
import {
  AutoscalingMetricParameterType,
  EndpointEnvironmentIsolation,
} from 'generated/types';
import {
  IResources,
  cpuInfo,
  gpuInfo,
  gpuQuantityInfo,
  memoryInfo,
} from 'shared/models/Deployment/canary/EndpointMachineConfiguration/Resources';
import { getEnvironment } from 'shared/models/Deployment/canary/Endpoint';
import { mapMachineConfigWhenNotEmpty } from 'shared/models/Deployment/canary/EndpointMachineConfiguration/mapMachineConfigWhenNotEmpty';
import { Endpoint } from 'features/deployment/canary/endpoints/store/endpointQuery/endpointQuery';
import { mapObj } from 'shared/utils/collection';
import isNotNil from 'shared/utils/isNotNill';
import { withDefault } from 'shared/utils/result';
import { autoscalingMetricParameterInfo } from 'shared/models/Deployment/canary/EndpointMachineConfiguration/Autoscaling/Autoscaling';
import { MachineConfigInfo } from 'shared/graphql/Deployment/Endpoint/MachineConfig/graphql-types/MachineConfigInfo.generated';

export type MachineConfigSettings = {
  autoscaling: IAutoscalingSettings;
  autoscalingEnabled: boolean;
  env: IEnvironmentVariable[];
  resources: IResources;
  resourcesEnabled: boolean;
  isolation: EndpointEnvironmentIsolation;
};

export interface IAutoscalingSettings {
  metrics: IAutoscalingMetricForm[];
  quantities: {
    maxReplicas: string | undefined;
    maxScale: string | undefined;
    minReplicas: string | undefined;
    minScale: string | undefined;
  };
}
export interface IAutoscalingMetricForm {
  id?: string | null;
  parameters: IAutoscalingMetricParameterForm[];
}
export interface IAutoscalingMetricParameterForm {
  name: string;
  value: string;
  type: AutoscalingMetricParameterType;
}

type defaultMachineConfigValuesProps = {
  defaultCpuValue: number | undefined;
  defaultMemoryValue: string | undefined;
};

export const defaultInitialMachineConfig = ({
  defaultCpuValue,
  defaultMemoryValue,
}: defaultMachineConfigValuesProps): MachineConfigSettings => {
  return {
    env: [],
    autoscaling: {
      metrics: [
        {
          parameters: [],
        },
      ],
      quantities: {
        maxReplicas: undefined,
        minReplicas: '1',
        maxScale: '10',
        minScale: '0.2',
      },
    },
    autoscalingEnabled: false,
    resources: {
      gpu: {
        number: null,
      },
      cpu: withDefault(2, cpuInfo.format(defaultCpuValue)),
      memory: withDefault(
        { amount: 6, measure: 'Mi' },
        memoryInfo.parse(defaultMemoryValue || '')
      ),
    },
    isolation: EndpointEnvironmentIsolation.SHARED,
    resourcesEnabled: false,
  };
};

export const GetInitialMachineConfig = (
  endpoint: Endpoint
): MachineConfigSettings => {
  const machineConfig = getEnvironment(endpoint)?.updateRequest;

  const {
    deployment: { defaultCpuValue, defaultMemoryValue },
  } = useSelector(selectFlags);

  const canonicalAutoscalingMetrics =
    getEnvironment(endpoint)?.autoscalingMetrics || null;
  if (!machineConfig) {
    return defaultInitialMachineConfig({ defaultCpuValue, defaultMemoryValue });
  }

  const userMachineConfig: Partial<MachineConfigSettings> | undefined = pipe(
    () =>
      mapMachineConfigWhenNotEmpty(machineConfig)<
        Partial<MachineConfigSettings>
      >({
        autoscaling: (autoscaling): Partial<MachineConfigSettings> => ({
          autoscalingEnabled: true,
          autoscaling: {
            metrics: getDefaultAutoscalingMetricForms(
              autoscaling,
              canonicalAutoscalingMetrics
            ),
            quantities: mapObj(
              (x) => (isNotNil(x) ? String(x) : undefined),
              dissoc('__typename', autoscaling.quantities)
            ),
          },
        }),
        env: (env): Partial<MachineConfigSettings> => ({
          env: env.map((e) => ({ name: e.name, value: e.value })),
        }),
        resources: (resources): Partial<MachineConfigSettings> => ({
          resources: {
            cpu: withDefault(
              defaultInitialMachineConfig({
                defaultCpuValue,
                defaultMemoryValue,
              }).resources.cpu,
              cpuInfo.format(resources.cpuMillis)
            ),
            memory: withDefault(
              defaultInitialMachineConfig({
                defaultCpuValue,
                defaultMemoryValue,
              }).resources.memory,
              memoryInfo.parse(resources.memory)
            ),
            isGpuEnabled: gpuInfo.format(resources.nvidiaGpu) === 'Enabled',
            gpu: {
              number: gpuQuantityInfo.format(resources.nvidiaGpu?.number ?? 1),
            },
          },
          resourcesEnabled: true,
        }),
        isolation: (isolation): Partial<MachineConfigSettings> => ({
          isolation,
        }),
      }),
    (x) => (x ? mergeAll(values(x).filter(isNotNil)) : undefined)
  )();

  return userMachineConfig
    ? mergeRight(
        defaultInitialMachineConfig({ defaultCpuValue, defaultMemoryValue }),
        userMachineConfig
      )
    : defaultInitialMachineConfig({ defaultCpuValue, defaultMemoryValue });
};

const getDefaultAutoscalingMetricForms = (
  autoscaling: NonNullable<MachineConfigInfo['autoscaling']>,
  canonicalAutoscalingMetrics:
    | Endpoint['environments'][0]['autoscalingMetrics']
    | null
): IAutoscalingMetricForm[] => {
  return autoscaling.metrics.map((metric) => ({
    id: metric.id,
    parameters: metric.parameters
      .map((p) => {
        const targetParameter = canonicalAutoscalingMetrics
          ?.find((m) => m.id === metric.id)
          ?.parameters.find((m) => m.name === p.name);
        return targetParameter
          ? {
              name: p.name,
              value: autoscalingMetricParameterInfo.format(
                targetParameter.type,
                p.value
              ),
              type: targetParameter.type,
            }
          : undefined;
      })
      .filter(isNotNil),
  }));
};

export type ResultMachineConfigSettings = {
  type: 'resultMachineConfigSettings';
  autoscaling?: IAutoscalingSettings;
  env?: IEnvironmentVariable[];
  resources?: IResources;
  isolation: EndpointEnvironmentIsolation;
};

export const getResultMachineConfig = (
  machineConfig: MachineConfigSettings
): ResultMachineConfigSettings => {
  return {
    type: 'resultMachineConfigSettings',
    autoscaling: machineConfig.autoscalingEnabled
      ? machineConfig.autoscaling
      : undefined,
    env: machineConfig.env,
    resources: machineConfig.resourcesEnabled
      ? machineConfig.resources
      : undefined,
    isolation: machineConfig.isolation,
  };
};
