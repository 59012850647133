import { groupBy, pipe, toPairs } from 'ramda';

import { IPrimitiveKeyValue } from 'shared/models/PrimitiveKeyValue';
import isNotNil from 'shared/utils/isNotNill';
import { OmitStrict } from 'shared/utils/types';

import { FieldName, fieldNameToKey } from './FieldName';

export type ExperimentRunField =
  | CommonExperimentRunField<'id', 'string'>
  | KeyValueField
  | CommonExperimentRunField<'experiment', 'string'>
  | CommonExperimentRunField<'owner', 'string'>
  | CommonExperimentRunField<'tags', 'string'>
  | CommonExperimentRunField<'timestamp', 'dateTime'>;

export type KeyValueField =
  | CommonExperimentRunField<'metrics', 'string' | 'number' | undefined>
  | CommonExperimentRunField<
      'hyperparameters',
      'string' | 'number' | undefined
    >;
type CommonExperimentRunField<
  T extends string,
  V extends 'string' | 'number' | 'dateTime' | undefined,
> = {
  type: T;
  valueType: V;
  fieldName: FieldName;
  fieldNameAsKey: string;
  caption: string;
};

export function getExperimentRunFields({
  metrics,
  hyperparemeters,
}: {
  metrics: IPrimitiveKeyValue[];
  hyperparemeters: IPrimitiveKeyValue[];
}): ExperimentRunField[] {
  return (
    [
      {
        type: 'id',
        valueType: 'string',
        caption: 'Run ID',
        fieldName: { field: 'id', key: undefined },
      },
      ...getKeyValueFields('metrics', metrics),
      ...getKeyValueFields('hyperparameters', hyperparemeters),
      {
        type: 'experiment',
        valueType: 'string',
        caption: 'Experiment',
        fieldName: { field: 'experiment', key: 'name' },
      },
      {
        type: 'timestamp',
        caption: 'Timestamp',
        fieldName: { field: 'date_created', key: undefined },
        valueType: 'dateTime',
      },
      {
        type: 'tags',
        caption: 'Labels',
        fieldName: { field: 'tags', key: undefined },
        valueType: 'string',
      },
    ] as const
  ).map((f) => ({
    ...f,
    fieldNameAsKey: fieldNameToKey(f.fieldName),
  }));
}

function getKeyValueFields(
  type: 'metrics' | 'hyperparameters',
  keyValuePairs: IPrimitiveKeyValue[]
) {
  return pipe(
    () => groupBy((m) => m.key, keyValuePairs),
    (groupedKeyValuePairs) =>
      toPairs(groupedKeyValuePairs).flatMap(
        ([key, keyValuePairsByKey]): OmitStrict<
          KeyValueField,
          'fieldNameAsKey'
        > => {
          return {
            type,
            valueType: getValueTypeFromKeyValuePairs(keyValuePairsByKey),
            caption: key,
            fieldName: { field: type, key },
          };
        }
      )
  )();
}

function getValueTypeFromKeyValuePairs(
  keyValuePairsByKey: IPrimitiveKeyValue[]
) {
  const type = typeof keyValuePairsByKey.find(isNotNil)?.value as
    | 'string'
    | 'number'
    | 'boolean'
    | 'undefined';
  if (type === 'undefined') {
    return undefined;
  }
  if (type === 'number') {
    return type;
  }
  return 'string';
}
