/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { REGISTERED_MODEL_VERSION_FRAGMENT } from 'shared/graphql/registry/RegisteredModelVersion';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';

import {
  MACHINE_CONFIG_INFO_QUERY,
  AUTOSCALING_METRICS_QUERY,
} from './MachineConfig/MachineConfigInfo';

export const ENDPOINT_FRAGMENT = gql`
  fragment EndpointData on Endpoint {
    id
    path
  }
`;

export const UPDATE_REQUEST_FRAGMENT = gql`
  fragment UpdateRequestData on EndpointEnvironment {
    id
    ...AutoscalingMetricsData
    updateRequest {
      ...MachineConfigInfo
      isolation
      canaryStrategy {
        progressInterval
        progressStep
        rules {
          id
          parameters {
            name
            value
          }
        }
      }
    }
  }
  ${MACHINE_CONFIG_INFO_QUERY}
  ${AUTOSCALING_METRICS_QUERY}
`;

const BUILD_FRAGMENT = gql`
  fragment BuildData on Build {
    id
    status
    message
    source {
      ... on Error {
        ...ErrorData
      }
      ... on ExperimentRun {
        id
        name
        projectId
        project {
          id
          name
          workspace {
            id
            ...WorkspaceData
          }
        }
      }
      ... on RegisteredModelVersion {
        id
        ...RegisteredModelVersionFragment
      }
    }
    dateCreated
    dateUpdated
  }
  ${WORKSPACE_FRAGMENT}
  ${REGISTERED_MODEL_VERSION_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const BUILD_OR_ERROR_FRAGMENT = gql`
  fragment BuildOrErrorData on BuildOrError {
    ... on Error {
      ...ErrorData
    }
    ... on Build {
      id
      ...BuildData
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${BUILD_FRAGMENT}
`;
