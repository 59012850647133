import { pipe } from 'ramda';

import compareItemsWithTheSameKey from '../compareItemsWithTheSameKey';
import {
  compareItemsProperties,
  ItemsPropertiesDiff,
} from './itemsPropertiesComparing';

export type KeyedItemsPropertiesDiff<Item, K extends keyof Item> = Array<
  {
    key: string;
  } & ItemsPropertiesDiff<Item, K>
>;

export const compareKeyedItemsProperties = <Item, K extends keyof Item>(
  getItemKey: (item: Item) => string,
  comparedKeys: K[],
  entitiesItems: (Item[] | undefined)[]
): KeyedItemsPropertiesDiff<Item, K> => {
  return pipe(() =>
    compareItemsWithTheSameKey(
      getItemKey,
      (items, key) => ({
        key,
        ...compareItemsProperties(items, comparedKeys),
      }),
      entitiesItems
    )
  )();
};
