import { gql } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { PYPI_CONFIGURATION_FRAGMENT } from 'shared/graphql/SystemAdmin/PypiIntegration';
import {
  DeletePypiConfiguration,
  DeletePypiConfigurationVariables,
} from 'features/systemAdmin/integrations/pypi/store/graphql-types/useDeletePypiConfiguration.generated';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

const MUTATION = gql`
  mutation DeletePypiConfiguration($id: ID!) {
    pypiConfiguration(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on PypiConfiguration {
        id
        delete {
          id
          configurations {
            id
            ...PypiConfigurationData
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${PYPI_CONFIGURATION_FRAGMENT}
`;

export const useDeletePypiConfiguration = () => {
  const [deletePypiConfiguration, deletingPypiConfiguration] =
    useCustomMutation<
      DeletePypiConfiguration,
      DeletePypiConfigurationVariables
    >(MUTATION, { context: 'deleting PyPI configuration' });

  return {
    deletePypiConfiguration,
    deletingPypiConfiguration,
  };
};
