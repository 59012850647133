import { createContext, useContext } from 'react';

import { CardProps } from '../view/shared/RegisterModelPopup/CardsModel';

export type RegisteringModelContext = {
  cardSelected?: CardProps;
  selectCard: (card: CardProps) => void;
  hostname: string;
  saveHostname: (hostname: string) => void;
};

export const RegisteringModelContext = createContext<RegisteringModelContext>({
  cardSelected: undefined,
  selectCard: () => undefined,
  hostname: '',
  saveHostname: () => undefined,
});

export const useRegisteringModelContext = () =>
  useContext(RegisteringModelContext);
