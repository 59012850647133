import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';
import CodeBlock from 'shared/view/elements/CodeBlock/CodeBlock';

export const VertaPythonClient = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Install the Verta Python client
    </Typography>
    <Typography variant="body2">
      The Verta client supports Python 3.7-3.10
    </Typography>
    <CodeBlock withCopy my={2} language="python">
      {`# via pip\npython -m pip install verta`}
    </CodeBlock>
    <Typography variant="body2">
      The Verta client is installable through conda, as well.
    </Typography>
    <CodeBlock withCopy my={2} language="python">
      {`# via conda\nconda install verta -c conda-forge`}
    </CodeBlock>
  </WizardStepContent>
);
