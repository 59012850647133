import { Field } from 'formik';
import Stack from '@mui/material/Stack';

import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import { IOptionType } from 'shared/view/elements/Selects/shared/types';

const GpuTypeField = () => {
  return (
    <Field name="GpuHardware">
      {() => (
        <Stack
          direction={'row'}
          spacing={2}
          sx={{ width: '100%' }}
          alignItems={'center'}
        >
          <div style={{ width: '100%' }}>
            <SelectField
              name="GpuHardware"
              options={options}
              disabled
              placeholder="V100"
            />
          </div>
        </Stack>
      )}
    </Field>
  );
};

const options: IOptionType[] = [
  { value: 'T4', label: 'T4' },
  { value: 'V100', label: 'V100' },
  { value: 'Any', label: 'Any' },
];

export default GpuTypeField;
