import { ReactNode } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Button, { ButtonProps } from '@mui/material/Button';
import ButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Menu from 'shared/view/elements/Menu/Menu';
import { ButtonMenuItem } from 'shared/view/elements/Menu/MenuItem';

type ItemProps = { label: string; disabled?: boolean; value: string };

type Props = ButtonGroupProps & {
  mainButton: ButtonProps & { text: ReactNode };
  arrowButton?: ButtonProps;
  items: ItemProps[];
  onItemClick: (p: any) => void;
};

const SplitButton = (props: Props) => {
  const { mainButton, arrowButton, items, onItemClick, color, ...other } =
    props;

  const handleMenuItemClick = (_: unknown, value: any) => {
    onItemClick(value);
  };

  return (
    <Menu
      elevation={8}
      variant="menu"
      renderAnchor={(onClick) => (
        <ButtonGroup
          size="medium"
          variant="contained"
          aria-label="split button"
          color={color}
          {...other}
        >
          <MainButton
            color={color}
            {...mainButton}
            data-test="split-button-value"
          />
          <Button
            data-test="split-button-toggler"
            size="small"
            aria-label="select merge strategy"
            aria-haspopup="menu"
            {...arrowButton}
            onClick={onClick}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      )}
    >
      {items.map((option) => (
        <ButtonMenuItem
          key={option.value}
          disabled={option.disabled}
          dataTest="split-button-item"
          onClick={(event) => handleMenuItemClick(event, option.value)}
        >
          {option.label}
        </ButtonMenuItem>
      ))}
    </Menu>
  );
};

export default SplitButton;

const MainButton = ({ text, color, ...props }: Props['mainButton']) => {
  return (
    <Button
      disableRipple
      sx={{
        '& .MuiButton-contained': {
          ':hover': {
            backgroundColor: color ? `${color}.main` : 'primary.main',
            cursor: 'default',
          },
        },
      }}
      {...props}
    >
      {text}
    </Button>
  );
};
