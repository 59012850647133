import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';
import {
  useOnboardingContext,
  AdvancedFeaturesContent,
} from 'features/homepage/hooks/useOnboarding';

import checklistAbout from '../images/checklist-about.png';

export const AboutChecklists = (props: WizardStepContentProps) => {
  const { setSelectedAdvancedFeaturesContent } = useOnboardingContext();

  return (
    <WizardStepContent
      {...props}
      buttons={{
        back: {
          onClick: () =>
            setSelectedAdvancedFeaturesContent(
              AdvancedFeaturesContent.DISCOVER_FEATURES
            ),
          skipWizardBack: true,
        },
      }}
    >
      <Typography variant="subtitle1" mb={2}>
        About checklists
      </Typography>
      <Typography variant="body2" mb={3}>
        Checklists ensure that no detail is missed whenever models are changing
        stages or being deployed. Whether they are simple or expansive and
        detailed, checklists eliminate easily preventable errors, increase
        accountability and transparency, and standardize processes.
      </Typography>
      <img
        src={checklistAbout}
        width="90%"
        height="auto"
        alt="checklist about"
      />
    </WizardStepContent>
  );
};
