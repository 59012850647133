import { Grid, Stack, Typography } from '@mui/material';

import { Button } from 'shared/view/elements/Button';
import card1 from 'features/homepage/images/release-checklists.svg';
import card2 from 'features/homepage/images/ai-assisted-docs.svg';
import card3 from 'features/homepage/images/custom-attributes.svg';
import card4 from 'features/homepage/images/vulnerability-scanning.svg';
import { AdvancedFeaturesContent } from 'features/homepage/hooks/useOnboarding';

const cardsContent = [
  {
    image: card1,
    title: 'Governance checklists',
    description:
      'Record, track, and enforce requirements and policies effortlessly. Gather evidence, prevent model promotion, and maintain auditable logs with ease.',
    setContent: AdvancedFeaturesContent.GOVERNANCE_CHECKLISTS,
  },
  {
    image: card2,
    title: 'AI assisted model docs',
    description:
      'Go from registered to fully documented 10x faster. Anyone can create comprehensive model documentation with ease.',
    setContent: AdvancedFeaturesContent.AI_ASSISTED_DOCS,
  },
  {
    image: card3,
    title: 'Custom attributes',
    description:
      'Encode, store, and track any attribute or value you need for models or versions in your catalog. Enjoy a user-friendly UI or robust API.',
    setContent: AdvancedFeaturesContent.CUSTOM_ATTRIBUTES,
  },
  {
    image: card4,
    title: 'Vulnerability scanning',
    description:
      'Easily scan your deployable model containers with a single click to detect potential crises before release.',
    setContent: AdvancedFeaturesContent.VULNERABILITY_SCANNING,
  },
];

export const AdvancedFeatures = ({
  setSelectedContent,
}: {
  setSelectedContent: (content: AdvancedFeaturesContent) => void;
}) => {
  return (
    <Grid container spacing={2}>
      {cardsContent.map((card, index) => (
        <Grid item xs={12 / cardsContent.length} key={index}>
          <Stack
            height="100%"
            spacing={2}
            alignItems="center"
            sx={{ backgroundColor: 'action.hover', py: 3, px: 2 }}
          >
            <img
              height="146px"
              width="auto"
              src={card.image}
              alt={card.title}
            />
            <Stack spacing={1} flex={1}>
              <Typography variant="subtitle2" align="center">
                {card.title}
              </Typography>
              <Typography variant="caption" align="center">
                {card.description}
              </Typography>
            </Stack>
            <Button
              isLoading={false}
              onClick={() => setSelectedContent(card.setContent)}
            >
              Explore
            </Button>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};
