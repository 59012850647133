import { isEmpty } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';

import * as Workspace from 'shared/models/Workspace';
import { IApplicationState } from 'setup/store/store';
import { selectCurrentUserOrThrowError } from 'features/user/store';
import { IWorkspaces } from 'features/workspaces/store/types';
import { IWorkspace } from 'shared/models/Workspace';

const selectState = (state: IApplicationState): IWorkspaces => state.workspaces;

export const selectUserWorkspace = (
  state: IApplicationState
): Workspace.IUserWorkspace => {
  const currentUser = selectCurrentUserOrThrowError(state);
  return Workspace.currentUserToWorkspace(currentUser);
};

export const selectWorkspaceOrganizations = (
  state: IApplicationState
): Workspace.IOrganizationWorkspace[] => {
  return selectState(state).data.organizations;
};

export const selectWorkspaces = createSelector(
  [selectUserWorkspace, selectWorkspaceOrganizations],
  (user, organizations) => {
    return {
      user,
      organizations,
    };
  }
);

export const selectCurrentWorkspaceName = (state: IApplicationState) => {
  return selectCurrentWorkspace(state).name;
};

export const selectCurrentWorkspace = (state: IApplicationState) => {
  const shortWorkspace = selectState(state).data.currentWorkspace;

  const workspacesV2 = state.workspaces.data.organizations;
  if (isEmpty(workspacesV2)) {
    return {
      id: '',
      name: 'unknown',
      type: 'organization',
      namespace: null,
      organizationId: '',
      organizationName: '',
    } as IWorkspace;
  }

  return (
    workspacesV2.find(
      (w) =>
        w.id === (shortWorkspace.type === 'organization' && shortWorkspace.id)
    ) || workspacesV2[0]
  );
};

export const selectWorkspaceByName = (state: IApplicationState) => {
  const name = selectCurrentWorkspace(state).name;
  return state.workspaces.data.organizations.find((w) => w.name === name);
};

export const selectCurrentWorkspaceNameWithError = (
  state: IApplicationState
) => {
  const user = state.user.data.user;

  if (user) {
    return selectCurrentWorkspace(state).name;
  }
  return null;
};
