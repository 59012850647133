import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Filter } from 'shared/models/Filters/Filter';
import { useUpdateEffect } from 'shared/view/hooks/useUpdateEffect';

import { EntityInfo } from './storage/filtersInLocalStorage';
import {
  getFiltersFromStorages,
  saveFiltersInStorages,
} from './storage/storage';

const useFilters = (
  entityInfo: EntityInfo,
  {
    onFiltersChanged,
  }: {
    onFiltersChanged?: (filters: Filter[]) => void;
  } = {}
) => {
  const [filters, setFilters] = useState<Filter[]>(
    getFiltersFromStorages(entityInfo)
  );

  const navigate = useNavigate();
  useEffect(() => {
    saveFiltersInStorages(entityInfo, navigate, filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, navigate]);

  useUpdateEffect(() => {
    onFiltersChanged?.(filters);
  }, [filters]);

  return {
    filters,
    applyFilters: setFilters,
  };
};

export default useFilters;
