import { LayerSpec } from 'vega-lite/build/src/spec';
import { expressionFunction } from 'vega';

import { LineChartSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/LineChartSettings';

import { getAxisesDomains, getScaleType } from './shared/Scale';
import { config } from './shared/config';
import { getExperimentRunWithKeyValueFieldsAsObjectsFunctions } from './shared/ExperimentRunWithKeyValueFieldsAsObjects';
import {
  getDefaultDataField,
  WidgetVisualizationSpec,
} from './shared/WidgetVisualizationSpec';
import { getFilterInvalidDatum } from './shared/filterInvalidFields';

// ts-unused-exports:disable-next-line
export const {
  getExperimentRunsForSpec: getExperimentRunsForLineChartSpec,
  getDataSpecFields,
} = getExperimentRunWithKeyValueFieldsAsObjectsFunctions();

export const getLineChartSpec = (
  dataSourceName: string,
  settings: LineChartSettings
): WidgetVisualizationSpec => {
  const yAxisDataSpecsFields = settings.yAxis.specs.map(getDataSpecFields);

  const xAxisDataSpecFields = getDataSpecFields(settings.xAxis.spec);

  const { xAxisDomainFields, yAxisDomainFields, isNeedToClipMark } =
    getAxisesDomains(settings);

  return {
    config,
    data: getDefaultDataField(dataSourceName),
    transform: [getFilterInvalidDatum(yAxisDataSpecsFields)],
    encoding: {
      x: {
        ...xAxisDataSpecFields,
        axis: {
          grid: false,
        },
        scale: {
          type: getScaleType(settings.xAxis.isLogScale),
          ...xAxisDomainFields?.scale,
        },
      },
    },
    layer: [
      ...yAxisDataSpecsFields.map(
        (yDataSpecFields): LayerSpec<string> => ({
          encoding: {
            y: {
              ...yDataSpecFields,
              scale: {
                type: getScaleType(settings.yAxis.isLogScale),
                ...yAxisDomainFields?.scale,
              },
              title: null,
            },
            color: { datum: yDataSpecFields.field },
          },
          layer: [
            { mark: { type: 'line', clip: isNeedToClipMark, point: true } },
            {
              transform: [{ filter: { param: 'hover', empty: false } }],
              mark: { type: 'point', clip: true },
            },
          ],
        })
      ),
      {
        mark: { type: 'rule', size: 2 },
        params: [
          {
            name: 'hover',
            select: {
              type: 'point',
              fields: ['dateUpdated'],
              nearest: true,
              on: 'mouseover',
              clear: 'mouseout',
            },
          },
        ],
        encoding: {
          tooltip: yAxisDataSpecsFields.map((x) => ({
            ...x,
            formatType: withoutInvalidValue,
          })),
          opacity: {
            value: 0,
            condition: { value: 0.3, param: 'hover', empty: false },
          },
        },
      },
    ],
  };
};

const withoutInvalidValue = 'withoutInvalidValue';
// This custom format type allow to remove invalid values from the tooltip. Otherwise you get `val_acc: NaN` if val_acc is missed
// eslint-disable-next-line @typescript-eslint/no-unsafe-return
expressionFunction(withoutInvalidValue, (x: any) => x);
