import { Field, FieldConfig, useField, FieldValidator } from 'formik';
import { useCallback, useMemo, useEffect } from 'react';
import { equals, isEmpty } from 'ramda';

import { OmitStrict } from 'shared/utils/types';
import Select from 'shared/view/elements/Selects/Select/Select';
import { validateNotEmpty } from 'shared/utils/validators';
import { SelectCommonProps } from 'shared/view/elements/Selects/shared/view/VertaSelectBase';
import {
  IGroupedOptions,
  IOptionType,
} from 'shared/view/elements/Selects/shared/types';

type Props<T> = OmitStrict<FieldConfig, 'value'> &
  SelectCommonProps & {
    options: Array<IOptionType<T>> | IGroupedOptions<T>;
    onChange?(option: IOptionType<T>): void;
  };

export default function SelectField<T>(props: Props<T>) {
  const {
    extendedProps,
    findValue,
    errorMessage,
    inputName,
    inputOnBlur,
    handleChange,
  } = useVertaCommonSelectField(props);

  const value = useMemo(
    () =>
      'type' in props.options
        ? findValue(props.options.groups.flatMap((opt) => opt.options))
        : findValue(props.options),
    [findValue, props.options]
  );

  let validate = undefined;
  if (props.required) {
    validate = validateNotEmpty(props.label ?? 'This select');
  }

  if (props.validate) {
    validate = props.validate;
  }

  return (
    <Field
      maxWidth="100%"
      {...extendedProps}
      validate={validate}
      onChange={handleChange}
      inputOnBlur={inputOnBlur}
      inputName={inputName}
      errorMessage={errorMessage}
      component={Select}
      value={value}
      dataTest={props.name}
    />
  );
}

type VertaCommonSelectFieldProps<Name extends string, T> = SelectCommonProps & {
  name: Name;
  onChange?(option: IOptionType<T>): void;
  validate?: FieldValidator;
};

function useVertaCommonSelectField<Name extends string, T>(
  props: VertaCommonSelectFieldProps<Name, T>
) {
  const [_, { value, error }, { setValue, setTouched }] = useField<T>(
    props.name
  );

  useEffect(() => {
    if (isEmpty(value)) {
      setTouched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { onChange, required } = props;

  const handleChange = useCallback(
    (newValue: IOptionType<T>) => {
      setValue(newValue.value);
      setTouched(true);
      onChange?.(newValue);
    },
    [onChange, setTouched, setValue]
  );

  const onClose = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  const inputOnBlur = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  const findValue = useCallback(
    (options: IOptionType<T | undefined>[]) =>
      options.find((option) => equals(option.value, value)) || {
        value: undefined,
      },
    [value]
  );

  const errorMessage = useMemo(() => error?.toString(), [error]);

  const extendedProps: VertaCommonSelectFieldProps<Name, T> = useMemo(
    () => ({
      ...props,
      onClose,
    }),
    [onClose, props]
  );

  return {
    extendedProps,
    handleChange,
    inputOnBlur,
    findValue,
    errorMessage,
    inputName: props.name,
    name: props.name,
    required,
  };
}
