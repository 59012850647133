export interface BaseBarChartData {
  value: number;
  key: string;
}
export interface BarChartData<AdditionalData> extends BaseBarChartData {
  additionalData: AdditionalData;
}

export const getValue = (d: BaseBarChartData) => d.value;
export const getKey = (d: BaseBarChartData) => d.key;
