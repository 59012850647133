import Popover from '@mui/material/Popover';
import { FC, ReactNode } from 'react';
import { styled } from '@mui/material';

import NumberInput from 'shared/view/elements/TextInput/NumberInput';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { MonitoringFilter } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';
import matchBy from 'shared/utils/matchBy';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import IconButton from 'shared/view/elements/IconButton/IconButton';
import { usePopoverManager } from 'shared/view/hooks/usePopoverManager';
import BaseFilterItem from 'shared/view/domain/Filters/BaseFilterItem/BaseFilterItem';
import FiltersPopoverContent from 'shared/view/domain/Filters/FiltersPopupContent/FiltersPopoverContent';

import { useMonitoringFilters } from '../../store/useMonitoringFilters';
import MonitoringFilterOperatorSelect from './MonitoringFilterOperatorSelect';
import MonitoringFilterIOSelect from './MonitoringFilterIOSelect';
import MonitoringBooleanFilterValueSelect from './MonitoringBooleanFilterValueSelect';
import MonitoringStringFilterValueInput from './MonitoringStringFilterValueInput';

type Props = {
  applyFilters: (filters: MonitoringFilter[]) => void;
  filters: MonitoringFilter[];
  ioDescriptions: MonitoringIODescription[];
};

const ScrollableWrapper = styled('div')({
  maxHeight: '50vh',
  overflowY: 'auto',
});

const MonitoringFilters: FC<React.PropsWithChildren<Props>> = (props) => {
  const { anchorEl, closePopover, isOpen, openPopover } = usePopoverManager();

  return (
    <div>
      <IconButton icon={ICONS.filter} onClick={openPopover} />

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ScrollableWrapper>
          <MonitoringFiltersPopupContent {...props} onCancel={closePopover} />
        </ScrollableWrapper>
      </Popover>
    </div>
  );
};

const MonitoringFiltersPopupContent = (props: Props & { onCancel(): void }) => {
  const filtersApi = useMonitoringFilters({
    initialFilters: props.filters,
    ioDescriptions: props.ioDescriptions,
  });

  return (
    <FiltersPopoverContent
      {...filtersApi}
      applyFilters={props.applyFilters}
      onCancel={props.onCancel}
      renderFilterItem={(filter, plusIcon) => (
        <FilterItem
          removeFilter={filtersApi.removeFilter}
          updateFilter={filtersApi.updateFilter}
          plusIcon={plusIcon}
          key={filter.id}
          filter={filter}
          ioDescriptions={props.ioDescriptions}
        />
      )}
    />
  );
};

const FilterItem = (props: {
  filter: MonitoringFilter;
  ioDescriptions: MonitoringIODescription[];
  plusIcon: ReactNode;
  removeFilter: (id: number) => void;
  updateFilter: (filter: MonitoringFilter) => void;
}) => {
  return (
    <BaseFilterItem
      id={props.filter.id}
      plusIcon={props.plusIcon}
      onRemove={() => props.removeFilter(props.filter.id)}
      columnField={
        <MonitoringFilterIOSelect
          filter={props.filter}
          updateFilter={props.updateFilter}
          ioDescriptions={props.ioDescriptions}
        />
      }
      operatorField={
        <MonitoringFilterOperatorSelect
          filter={props.filter}
          updateFilter={props.updateFilter}
        />
      }
      valueField={matchBy(
        props.filter,
        'type'
      )({
        number: (f) => (
          <NumberInput
            value={f.value}
            onChange={(v) =>
              props.updateFilter({
                ...f,
                value: v,
              })
            }
            label="Value"
          />
        ),
        boolean: (f) => (
          <MonitoringBooleanFilterValueSelect
            filter={f}
            updateFilter={props.updateFilter}
          />
        ),
        string: (f) => (
          <MonitoringStringFilterValueInput
            filter={f}
            updateFilter={props.updateFilter}
          />
        ),
      })}
    />
  );
};

export default MonitoringFilters;
