import Stack from '@mui/material/Stack';

import SectionHeader from 'shared/view/elements/SectionComponents/SectionHeader/SectionHeader';

interface ILocalProps {
  additionalSections?: AdditionalSection[];
}

export type AdditionalSection = {
  title: string;
  content: React.ReactNode;
};

const SettingsSections = ({ additionalSections }: ILocalProps) => {
  return (
    <Stack direction={'column'} spacing={3}>
      {additionalSections?.map((additionalSection, i) => (
        <div key={i}>
          <SectionHeader label={additionalSection.title} />
          <div>{additionalSection.content}</div>
        </div>
      ))}
    </Stack>
  );
};

export default SettingsSections;
