import { GridRowId } from '@mui/x-data-grid-pro';
import { head } from 'ramda';
import { useMemo } from 'react';

import isNotNil from 'shared/utils/isNotNill';
import matchBy from 'shared/utils/matchBy';
import { PartialUnion } from 'shared/utils/types';

import { DataGridPivotProps } from '../DataGridWithTypes';

export type DataGridSelectionProps =
  | PartialUnion<{
      selectionType: 'single';
      selection: string | undefined;
      onSelectionChange(selection: string): void;
    }>
  | PartialUnion<{
      selectionType: 'multiple';
      selection: string[];
      onSelectionChange(selection: string[]): void;
    }>;

export const useDataGridSelectionProps = <Data>(
  props: DataGridSelectionProps & DataGridPivotProps<Data>
) => {
  const rowSelectionModel = useMemo(
    () =>
      props.selectionType === 'single'
        ? [props.selection].filter(isNotNil)
        : props.selection,
    [props.selection, props.selectionType]
  );

  const onRowSelectionModelChange = (newSelectionModel: GridRowId[]) => {
    if (props.selectionType) {
      matchBy(
        props,
        'selectionType'
      )({
        multiple: (p) => p.onSelectionChange(newSelectionModel.map(String)),
        single: (p) => {
          const selected = head(newSelectionModel);
          if (isNotNil(selected)) p.onSelectionChange(String(selected));
        },
      });
    }
  };

  return {
    disableRowSelectionOnClick: props.selectionType !== 'single',
    checkboxSelection: Boolean(
      props.selectionType === 'multiple' && !props.pivotEnabled
    ),
    rowSelectionModel,
    onRowSelectionModelChange,
  };
};
