import {
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Switch as MUISwitch,
  SwitchProps as MUISwitchProps,
} from '@mui/material';

// for some reason storybook is not getting this type right.
// I pick some key properties to document here
// ts-unused-exports:disable-next-line
export type SwitchProps = {
  checked?: MUISwitchProps['checked'];
  checkedIcon?: MUISwitchProps['checkedIcon'];
  color?: MUISwitchProps['color'];
  defaultChecked?: MUISwitchProps['defaultChecked'];
  disabled?: MUISwitchProps['disabled'];
  icon?: MUISwitchProps['icon'];
  onChange?: MUISwitchProps['onChange'];
  required?: MUISwitchProps['required'];
  size?: MUISwitchProps['size'];
  sx?: MUISwitchProps['sx'];
  value?: MUISwitchProps['value'];
} & MUISwitchProps;
//@ts-ignore
// ts-unused-exports:disable-next-line
export const Switch = (props: SwitchProps) => <MUISwitch {...props} />;

// ts-unused-exports:disable-next-line
export default Switch;
