import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useTheme } from '@mui/material';

import { config } from 'shared/view/domain/CrossRunDashboard/CustomWidgetView/VegaVisualization/shared/config';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { IconAwesomeDraggable } from 'shared/view/elements/IconAwesome/IconAwesomeDraggable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import styles from './WidgetWrapper.module.css';

interface ILocalProps {
  title: string;
  actions: JSX.Element[];
  children: JSX.Element;
}

const WidgetWrapper: React.FC<React.PropsWithChildren<ILocalProps>> = ({
  children,
  actions,
  title,
}) => {
  const theme = useTheme();
  return (
    <div
      className={styles.root}
      style={{ border: theme.palette.border.default }}
    >
      <div
        className={styles.caption}
        style={{
          border: theme.palette.border.default,
          color: theme.palette.default.dark,
        }}
      >
        <div className={styles.title} title={title}>
          {title}
        </div>
        <IconAwesomeDraggable icon={ICONS.gripDots} />
        <div className={styles.actions}>
          {actions.map((action, i) => (
            <div className={styles.actionContainer} key={i}>
              {action}
            </div>
          ))}
        </div>
      </div>
      <div
        className={styles.content}
        style={{ backgroundColor: config.background }}
      >
        {children}
      </div>
    </div>
  );
};

export const Action = (props: { onClick(): void; icon: IconDefinition }) => {
  const theme = useTheme();
  return (
    <div
      className={styles.action}
      style={{ color: theme.palette.primary.main }}
    >
      <IconAwesomeClickable icon={props.icon} onClick={props.onClick} />
    </div>
  );
};

export const widgetWrapperCssVars = {
  widgetWrapperCaptionHeight: {
    px: styles.widget_wrapper_caption_height,
    number: parseInt(styles.widget_wrapper_caption_height),
  },
};

export default WidgetWrapper;
