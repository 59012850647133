import { BoxProps } from '@mui/material/Box';
import { has } from 'ramda';

import { componentsSizes } from 'shared/mui/themes/CommonMuiThemeOptions';

type Props = Record<string, any> & Pick<BoxProps, 'maxWidth' | 'minWidth'>;

export const getWidthsFromProps = (props: Props) => {
  let minWidth: Props['minWidth'] = componentsSizes.input.minWidth;
  let maxWidth: Props['maxWidth'] = componentsSizes.input.maxWidth;
  if (has('maxWidth')(props)) {
    maxWidth = props.maxWidth;
  }
  if (has('minWidth')(props)) {
    minWidth = props.minWidth;
  }
  return { maxWidth, minWidth };
};
