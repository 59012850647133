import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Box, Typography, Stack } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import { validateNotEmpty } from 'shared/utils/validators';
import { UpdateUserV2 } from 'shared/models/Authorization';
import { updateUserInfo, selectCommunications } from 'features/user/store';
import Button from 'shared/view/elements/Button/Button';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import { selectRegistrationUser } from 'features/user';
import routes from 'shared/routes';
import { ICommunication } from 'shared/utils/redux/communication';
import { isHttpError } from 'shared/models/Error';
import { RegistrationByEmailErrors } from 'shared/models/Authorization';
import { AppError } from 'shared/models/Error';
import { useToast } from 'features/toast/store/hooks';
import Checkbox from 'shared/view/elements/Checkbox/Checkbox';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { logoutUser } from 'features/user/store/actions';
import { useAnalyticsTrack } from 'shared/hooks';

interface ILocalProps {}

const VERTA_TERMS_OF_SERVICE = 'https://www.verta.ai/terms-of-service';
const VERTA_PRIVACY_POLICY = 'https://www.verta.ai/privacy-policy';

const initialForm: UpdateUserV2 = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  email: '',
  companyName: '',
};

const getFieldName = makeGetFieldName<UpdateUserV2>();

const RequestErrorObserver = (props: {
  communication: ICommunication<
    AppError<keyof typeof RegistrationByEmailErrors>
  >;
}) => {
  const { setErrors } = useFormikContext();
  const toast = useToast();

  useEffect(() => {
    const { error } = props.communication;

    if (isHttpError(error) && error.type) {
      setErrors({ company: true });
      toast(
        'Contact support to join an existing organization on our platform with this name and collaborate with your peers.',
        'error'
      );
    }
  }, [props.communication, setErrors, toast]);

  return null;
};

export const SignUpPersonalInfoForm: React.FC<
  React.PropsWithChildren<ILocalProps>
> = () => {
  const [agreedWithTerms, setAgreedWithTerms] = useState<boolean>(false);

  const user = useSelector(selectRegistrationUser);
  const dispatch = useDispatch<Dispatch<any>>();
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();

  const provider =
    params.get('provider') || (location.state?.provider as string);

  const { updatingUserInfo } = useSelector(selectCommunications);
  const [trackEvent] = useAnalyticsTrack();

  const onSubmit = useCallback(
    (values: UpdateUserV2) => {
      if (user === undefined) {
        navigate(routes.login.getPath());
      }
      if (user !== undefined) {
        const handleOnSuccess = () => {
          trackEvent({
            input: {
              userId: user.id,
              event: 'sign_up.completed',
              properties: JSON.stringify({
                provider,
                email: user.email,
                firstName: values.firstName,
                lastName: values.lastName,
                company: user.email,
                position: values.jobTitle,
              }),
            },
          });

          navigate(routes.homepage.getPath());
        };

        dispatch(
          updateUserInfo({
            values: {
              ...values,
              firstName: isEmpty(values.firstName)
                ? user.firstName
                : values.firstName,
              lastName: isEmpty(values.lastName)
                ? user.lastName
                : values.lastName,
              fullName: `${values.firstName} ${values.lastName}`,
              id: user.id,
              email: user.email,
              company: user.email,
            },
            onSuccess: handleOnSuccess,
          })
        );
      }
    },
    [dispatch, navigate, provider, trackEvent, user]
  );

  return (
    <Box display="flex" flexDirection="column" flex="1" alignItems="stretch">
      <Typography variant="body2" color="text.primary" mb={2}>
        Please fill in a few more details to finish your signup process.
      </Typography>
      <Box flex="1">
        <PresetFormik<UpdateUserV2>
          initialValues={initialForm}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnMount={false}
          validateOnBlur={false}
        >
          {({ submitForm }) => {
            return (
              <FormStack>
                {user?.firstName ? null : (
                  <TextInputField
                    name={getFieldName({ firstName: null })}
                    validate={validateNotEmpty('first name')}
                    label="First name"
                    isRequired={true}
                    size="medium"
                  />
                )}
                {user?.lastName ? null : (
                  <TextInputField
                    name={getFieldName({ lastName: null })}
                    validate={validateNotEmpty('last name')}
                    label="Last name"
                    isRequired={true}
                    size="medium"
                  />
                )}

                <TextInputField
                  name={getFieldName({ companyName: null })}
                  label="Company"
                  size="medium"
                />
                <TextInputField
                  name={getFieldName({ jobTitle: null })}
                  label="Job Title"
                  size="medium"
                />

                <Checkbox
                  onChange={() => setAgreedWithTerms(!agreedWithTerms)}
                  value={agreedWithTerms}
                  label={
                    <Typography variant="body2">
                      I agree to Verta{' '}
                      <InlineLink
                        simple
                        isExternal
                        withoutArrow
                        to={VERTA_TERMS_OF_SERVICE}
                        color="text.primary"
                        sx={{ textDecoration: 'underline' }}
                      >
                        Terms
                      </InlineLink>{' '}
                      and{' '}
                      <InlineLink
                        simple
                        isExternal
                        withoutArrow
                        to={VERTA_PRIVACY_POLICY}
                        color="text.primary"
                        sx={{ textDecoration: 'underline' }}
                      >
                        Privacy Policy.
                      </InlineLink>
                    </Typography>
                  }
                />

                <Box pb={4} pt={3}>
                  <Button
                    onClick={submitForm}
                    isLoading={updatingUserInfo.isRequesting}
                    size="medium"
                    variant="contained"
                    disabled={!agreedWithTerms}
                  >
                    Complete Sign Up
                  </Button>
                </Box>
                <RequestErrorObserver communication={updatingUserInfo} />
              </FormStack>
            );
          }}
        </PresetFormik>
      </Box>

      {user ? (
        <Stack spacing={0}>
          <Typography variant="caption" color="text.secondary">
            You are logged in as <strong>{user.email}</strong>
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Click here to{' '}
            <InlineLink simple onClick={() => dispatch(logoutUser({}))} to="#">
              log in with another account
            </InlineLink>
          </Typography>
        </Stack>
      ) : null}
    </Box>
  );
};
