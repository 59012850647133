import { IApplicationState } from 'setup/store/store';

import { ISystemAdmins } from './types';

const selectState = (state: IApplicationState): ISystemAdmins =>
  state.systemAdmins;

export const selectIsCurrentUserSystemAdmin = (
  state: IApplicationState
): boolean => {
  return selectState(state).data.isSystemAdmin;
};
