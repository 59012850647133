import { Box, Popover, Stack, Typography } from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { styled, useTheme } from '@mui/material';

import { useMetricOverTime } from 'features/monitoring/widgets/store/metricOverTime/useMetricOverTime';
import { MonitoringMetricType } from 'generated/types';
import { getLabelByMonitoringMetricType } from 'shared/models/Monitoring/MonitoringMetricType';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { useZoomReset } from 'shared/utils/charts/zoom/useZoomReset';
import isNotNil from 'shared/utils/isNotNill';
import { Button } from 'shared/view/elements/Button';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import routes from 'shared/routes';
import { formatWithDefaultPrecision } from 'shared/utils/formatters/formatWithDefaultPrecision';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';
import NewTimeSeries from 'features/monitoring/shared/NewTimeSeries/NewTimeSeries';
import TimeSeriesFullScreenAction from 'features/monitoring/shared/TimeSeriesFullScreenAction/TimeSeriesFullScreenAction';

import { OverviewWidgetWrapper } from '../../shared/OverviewWidget/OverviewWidget';

interface Props {
  id: string;
  anchorEl: HTMLElement | undefined;
  metricValue: number;
  metricType: MonitoringMetricType;
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  output: MonitoringIODescription;
  icon: IconDefinition;
  isLast: boolean;
}

const MonitoringOverviewCardDetails = (props: Props) => {
  const { closePopup, isPopupOpen, openPopup } = usePopupManager();

  return (
    <div>
      <Button variant="text" onClick={openPopup} isLoading={false}>
        See details
      </Button>
      {isPopupOpen ? (
        <Popover
          open={isPopupOpen}
          anchorEl={props.anchorEl}
          onClose={closePopup}
          anchorOrigin={{
            horizontal: props.isLast ? 'right' : 'left',
            vertical: 'top',
          }}
          transformOrigin={{
            horizontal: props.isLast ? 'right' : 'left',
            vertical: 'top',
          }}
        >
          <DetailsContent {...props} onClose={closePopup} />
        </Popover>
      ) : null}
    </div>
  );
};

const IconWrapper = styled(Box)({
  padding: '12px',
  backgroundColor: '#fff',
  borderRadius: '10px',
  display: 'inline-block',
});

const DetailsContent = (props: Props & { onClose: () => void }) => {
  const metricOverTime = useMetricOverTime({
    metricType: props.metricType,
    widgetExternalDeps: props.widgetExternalDeps,
    output: props.output,
  });

  const { onReset, onSetReset } = useZoomReset();

  const { palette } = useTheme();

  return (
    <OverviewWidgetWrapper padding="12px 12px 12px 24px">
      <Stack direction="row" spacing={4}>
        <Stack spacing={4} sx={{ mt: '20px' }}>
          <div>
            <IconWrapper>
              <IconAwesomeCustomizable
                size="lg"
                icon={props.icon}
                nativeProps={{
                  color: palette.primary.main,
                }}
              />
            </IconWrapper>
          </div>

          <div>
            <Typography variant="h2">
              {Number(
                formatWithDefaultPrecision(
                  parseGraphQLNumber(props.metricValue)
                )
              )}
            </Typography>

            <Typography variant="subtitle1">
              {getLabelByMonitoringMetricType(props.metricType)}
            </Typography>

            <InlineLink
              to={routes.monitoringPerformance.getRedirectPath({
                monitoringModelId: props.widgetExternalDeps.monitoringModelId,
                workspaceName: props.widgetExternalDeps.workspaceName,
              })}
            >
              Go to performance
            </InlineLink>
          </div>
        </Stack>

        <DefaultMatchRemoteDataOrError
          data={metricOverTime.data}
          communication={metricOverTime.communication}
          context="loading prediction count time series"
        >
          {(data) => (
            <div style={{ width: '40vw', height: '422px' }}>
              <NewTimeSeries
                id={props.id}
                onSetReset={onSetReset}
                metricOverTime={data}
                isHovered={true}
                metricType={props.metricType}
                actions={[
                  <IconAwesomeClickable
                    key="close"
                    icon={ICONS.close}
                    size="lg"
                    onClick={props.onClose}
                  />,
                  <TimeSeriesFullScreenAction
                    key="fullscreen"
                    metricValue={props.metricValue}
                    metricOverTime={data}
                    metricType={props.metricType}
                    widgetExternalDeps={props.widgetExternalDeps}
                  />,
                  onReset ? (
                    <IconAwesomeClickable
                      key="zoom"
                      icon={ICONS.magnifyingGlassMinus}
                      size="lg"
                      onClick={onReset}
                    />
                  ) : null,
                ].filter(isNotNil)}
              />
            </div>
          )}
        </DefaultMatchRemoteDataOrError>
      </Stack>
    </OverviewWidgetWrapper>
  );
};

export default MonitoringOverviewCardDetails;
