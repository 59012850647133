import { sum } from 'ramda';

import isNotNil from 'shared/utils/isNotNill';

import * as PropertyDiffDescription from './PropertyDiffDescription';

export type PropertyDiffDescriptions = Array<
  | PropertyDiffDescription.ExpansionPropertyDescription
  | PropertyDiffDescription.RowDiffDescription<any>
>;

export const makePropertyDiffDescriptions = (
  descriptions: Array<
    | PropertyDiffDescription.ExpansionPropertyDescription
    | PropertyDiffDescription.RowDiffDescription<any>
    | null
  >
): PropertyDiffDescriptions =>
  descriptions
    .filter(isNotNil)
    .filter((p) => PropertyDiffDescription.getItemsCount(p) > 0);

// - 1 because we don't count the first row with names.
export const getTotalDiffCount = (p: PropertyDiffDescriptions): number =>
  sum(p.map(PropertyDiffDescription.getDiffCount)) - 1;

export const getTotalItemsCount = (p: PropertyDiffDescriptions): number =>
  sum(p.map(PropertyDiffDescription.getItemsCount)) - 1;
