import { makeKeyedItemsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/KeyedItemsDiff';
import { ArtifactsDiff } from 'shared/models/CompareEntities/new/artifactsComparing';

import makeItemPropertyDiffView from './ItemPropertyDiffView';

export const makeArtifactsDiff = (diff: ArtifactsDiff) =>
  makeKeyedItemsDiff({
    name: 'Artifacts',
    diff,
    render: (artifactsByKeyDiff) =>
      getArtifactsDiffProperties(artifactsByKeyDiff.properties),
  });

export const getArtifactsDiffProperties = (
  propertiesDiff: ArtifactsDiff[number]['properties']
) => {
  return [
    makeItemPropertyDiffView({
      name: 'Key',
      diff: propertiesDiff.key,
      render: (key) => key,
    }),
    makeItemPropertyDiffView({
      name: 'Type',
      diff: propertiesDiff.type,
      render: (type) => type,
    }),
    makeItemPropertyDiffView({
      name: 'Path',
      diff: propertiesDiff.path,
      render: (path) => path,
    }),
    makeItemPropertyDiffView({
      name: 'Upload completed',
      diff: propertiesDiff.uploadCompleted,
      render: (uploadCompleted) => uploadCompleted,
    }),
  ];
};
