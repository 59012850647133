import { ReactNode } from 'react';
import { styled } from '@mui/material';

import { Size } from 'shared/utils/charts/chartSizes';

import MonitoringWidgetSizeDetector from '../MonitoringWidgetSizeDetector/MonitoringWidgetSizeDetector';
import styles from './BaseWidgetWrapper.module.css';

interface Props {
  header: ReactNode;
  children: (size: Size) => JSX.Element;
  dataTest?: string;
  dataWidgetType?: string;
}

const Wrapper = styled('div')(({ theme }) => ({
  display: 'block',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '2px',
  width: '100%',
  height: '100%',
  background: theme.palette.background.paper,
}));

const Header = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  height: '32px',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  padding: '0 10px',
  backgroundColor: theme.palette.background.level2,
}));

const BaseWidgetWrapper = (props: Props) => {
  return (
    <Wrapper data-test={props.dataTest} data-widget-type={props.dataWidgetType}>
      <Header>{props.header}</Header>
      <div className={styles.content}>
        <MonitoringWidgetSizeDetector>
          {props.children}
        </MonitoringWidgetSizeDetector>
      </div>
    </Wrapper>
  );
};

export default BaseWidgetWrapper;
