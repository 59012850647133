import { useSelector } from 'react-redux';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';

import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';
import { selectCurrentUserOrThrowError } from 'features/user/store';
import { IApplicationState } from 'setup/store/store';
import { selectFlags } from 'features/flags';
import DarkModeControl from 'features/darkMode/view/DarkModeControl/DarkModeControl';
import Section from 'shared/view/elements/SectionComponents/Section/Section';
import Avatar from 'shared/view/elements/Avatar/Avatar';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import DefaultMatchRemoteData from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import WorkspacesBar from 'shared/routes/layouts/AuthorizedLayout/Header/WorkspacesBar/WorkspacesBar';
import { useUserDevKeys } from 'features/developerKeysManager/useUserDevKeys';
import DevKeysManager from 'features/developerKeysManager/view/DevKeysManager';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

export const ProfilePageContent = () => {
  const {
    flags: { isEnableDarkModeControl },
    user,
  } = useSelector((state: IApplicationState) => {
    return {
      flags: selectFlags(state),
      user: selectCurrentUserOrThrowError(state),
    };
  });

  return (
    <Stack direction={'column'} spacing={6}>
      <AvatarWithUserInfo user={user} />
      <ProfileDevKeysManager userId={user.id} />
      {isEnableDarkModeControl ? (
        <Section label="Settings">
          <DarkModeControl />
        </Section>
      ) : null}

      <Section label="Workspaces">
        <Box display="flex" flexDirection="row">
          <Typography mr="10px">Select your default workspace: </Typography>
          <WorkspacesBar isDefaultWorkspaceSelector />
        </Box>
      </Section>
    </Stack>
  );
};

const AvatarWithUserInfo = ({ user }: { user: CurrentUserData }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={5}>
      <Avatar
        name={user.fullName || user.username}
        picture={user.picture}
        size="large"
      />
      <Stack direction="column">
        <Typography variant="h4">{user.fullName || user.username}</Typography>
        <div>
          <Stack direction="row" alignItems="center">
            <IconAwesomeInfo icon={ICONS.mail} />
            <Typography variant="body2" color="text.secondary">
              {user.email}
            </Typography>
          </Stack>
        </div>
      </Stack>
    </Stack>
  );
};

const ProfileDevKeysManager = ({ userId }: { userId: string }) => {
  const {
    userDevKeys: data,
    loadingUserDevKeys: communication,
    reload,
  } = useUserDevKeys({ userId });
  const organizationId = useCurrentOrganizationV2();

  return (
    <Stack direction="column">
      <Stack direction="row">
        <Typography variant="subtitle2" color="text.secondary">
          Developer keys
        </Typography>
      </Stack>
      <Card>
        <CardContent>
          <DefaultMatchRemoteData
            data={data}
            communication={communication}
            context="loading dev keys"
          >
            {(developerKeysData) => (
              <DevKeysManager
                userId={userId}
                organizationId={organizationId || ''}
                primaryKey={developerKeysData.devKey || ''}
                secondaryKey={developerKeysData.secondaryDevKey || ''}
                onChangeDevKeys={() => reload()}
              />
            )}
          </DefaultMatchRemoteData>
        </CardContent>
      </Card>
    </Stack>
  );
};
