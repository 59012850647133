import { useEditResourceInformationMenuAction } from 'features/editResourceInformation/view/EditResourceInformation/EditResourceInformation';
import { useDeleteEntityAction } from 'features/shared/DeleteEntityIcon/useDeleteEntityAction';
import { BaseProject } from 'pages/authorized/ProjectsPages/ProjectDetailsPages/shared/layouts/ProjectDetailsLayout';
import routes from 'shared/routes';
import isNotNil from 'shared/utils/isNotNill';

interface Props {
  project: BaseProject | null;
  workspaceName: string;
}

export const useProjectSettingsActions = (props: Props) => {
  return [
    useEditResourceInformationMenuAction(props.project),
    useDeleteEntityAction({
      entityType: 'project',
      id: props.project?.id ?? null,
      redirectUrl: routes.projects.getRedirectPath({
        workspaceName: props.workspaceName,
      }),
      isNotAllowedToDelete: !props.project?.allowedActions.delete,
    }),
  ].filter(isNotNil);
};
