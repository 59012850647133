import {
  MonitoringBooleanFilterOperator,
  MonitoringDoubleFilterOperator,
  MonitoringStringArrayFilterOperator,
  MonitoringStringFilterOperator,
} from 'generated/types';
import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';
import matchType from 'shared/utils/matchType';

import { MonitoringFilter } from './MonitoringFilter';

export type MonitoringFilterOperator = MonitoringFilter['operator'];

export const numberOperators =
  exhaustiveStringTuple<MonitoringDoubleFilterOperator>()(
    MonitoringDoubleFilterOperator.EQ,
    MonitoringDoubleFilterOperator.NE,
    MonitoringDoubleFilterOperator.GT,
    MonitoringDoubleFilterOperator.GTE,
    MonitoringDoubleFilterOperator.LT,
    MonitoringDoubleFilterOperator.LTE
  );

export const booleanOperators =
  exhaustiveStringTuple<MonitoringBooleanFilterOperator>()(
    MonitoringBooleanFilterOperator.EQ,
    MonitoringBooleanFilterOperator.NE
  );

export const stringOperators = exhaustiveStringTuple<
  MonitoringStringFilterOperator | MonitoringStringArrayFilterOperator
>()(
  MonitoringStringFilterOperator.EQ,
  MonitoringStringFilterOperator.NE,
  MonitoringStringFilterOperator.LIKE,
  MonitoringStringFilterOperator.NOT_LIKE,
  MonitoringStringArrayFilterOperator.CONTAINS,
  MonitoringStringArrayFilterOperator.NOT_CONTAINS
);

export const getLabelByMonitoringFilterOperator = (
  operator: MonitoringFilterOperator
) => {
  return matchType(
    {
      EQ: () => '=',
      NE: () => '!=',
      GT: () => '>',
      GTE: () => '>=',
      LT: () => '<',
      LTE: () => '<=',
      CONTAINS: () => 'contains',
      NOT_CONTAINS: () => 'not contains',
      LIKE: () => 'like',
      NOT_LIKE: () => 'not like',
    },
    operator
  );
};
