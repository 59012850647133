import { Stage } from 'generated/types';
import matchType from 'shared/utils/matchType';

export const stageToView = (stage: Stage, isEnablePromotion?: boolean) => {
  return matchType(
    {
      ARCHIVED: () => 'archived',
      DEVELOPMENT: () => 'development',
      PRODUCTION: () =>
        isEnablePromotion ? 'production (cross-cluster)' : 'production',
      STAGING: () => 'staging',
      UNKNOWN: () => 'unknown',
      UNASSIGNED: () => 'unassigned',
    },
    stage
  );
};
