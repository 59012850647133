interface ViolatingSampleArea {
  x1: number;
  x2: number;
}

const ViolatingSampleAreas = (props: {
  areas: ViolatingSampleArea[];
  innerHeight: number;
}) => {
  return (
    <>
      {props.areas.map((area, index) => (
        <ViolatingSampleArea
          key={index}
          x1={area.x1}
          x2={area.x2}
          innerHeight={props.innerHeight}
        />
      ))}
    </>
  );
};

const ViolatingSampleArea = (props: {
  x1: number;
  x2: number;
  innerHeight: number;
}) => {
  return (
    <rect
      height={props.innerHeight}
      x={props.x1}
      width={props.x2 - props.x1}
      fill="red"
      fillOpacity={0.25}
      strokeWidth={1}
      stroke="red"
    />
  );
};

export default ViolatingSampleAreas;
