import { useRef, useState, useEffect, useCallback } from 'react';
import { Box, Stack } from '@mui/material';

import { usePrevious } from 'shared/utils/react/usePrevious';
import { useUpdateEffect } from 'shared/view/hooks/useUpdateEffect';
import isNotNil from 'shared/utils/isNotNill';
import { useElementMeasure } from 'shared/utils/react/useElementMeasure';

function OverflowList<T extends { id: string }>(props: {
  items: T[];
  renderVisibleItem: (item: T, index: number) => JSX.Element;
  renderOverflowItems: (items: T[]) => JSX.Element;
  additionalActions?: JSX.Element;
}) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const stackRef = useRef<HTMLDivElement>(null);

  const [visibleItems, setVisibleItems] = useState<T[]>([]);
  const [overflowItems, setOverflowItems] = useState<T[]>(props.items);
  const [isHitOverflow, setIsHitOverflow] = useState(false);

  useEffect(() => {
    if (
      overflowItems.length !== 0 &&
      overflowItems.length === props.items.length
    ) {
      const [head, ...rest] = overflowItems;
      setOverflowItems(rest);
      setVisibleItems([...visibleItems, head]);
    }
  }, [overflowItems, props.items.length, visibleItems]);

  const fitLabels = useCallback(
    (widthChanged?: boolean) => {
      if (visibleItems.length && wrapperRef.current && stackRef.current) {
        const isStackOverflowed =
          wrapperRef.current.clientWidth < stackRef.current.scrollWidth;

        const isHittingOverflowForFirstTime =
          isStackOverflowed && !isHitOverflow;
        if (isHittingOverflowForFirstTime) setIsHitOverflow(true);

        const shouldReduceVisibleItems =
          isStackOverflowed && visibleItems.length > 1;

        if (shouldReduceVisibleItems) {
          const head = visibleItems.slice(0, -1);
          const [tail] = visibleItems.slice(-1);
          setOverflowItems([tail, ...overflowItems]);
          setVisibleItems([...head]);
        }

        const shouldIncreaseVisibleItems =
          !isStackOverflowed &&
          (!isHitOverflow || widthChanged) &&
          overflowItems.length >= 1;
        if (shouldIncreaseVisibleItems) {
          const [head, ...rest] = overflowItems;
          setOverflowItems(rest);
          setVisibleItems([...visibleItems, head]);
        }
      }
    },
    [isHitOverflow, overflowItems, visibleItems]
  );

  useEffect(fitLabels, [fitLabels]);

  const measure = useElementMeasure(wrapperRef);
  const previousWidth = usePrevious(measure?.width);

  useUpdateEffect(() => {
    if (
      isNotNil(measure?.width) &&
      isNotNil(previousWidth) &&
      measure?.width !== previousWidth
    ) {
      fitLabels(true);
    }
  }, [previousWidth]);

  return (
    <Box overflow="hidden" ref={wrapperRef}>
      <Stack ref={stackRef} direction="row" alignItems="center">
        {visibleItems.map((item, index) => (
          <Box
            key={item.id}
            overflow="hidden"
            flexShrink={index === 0 && visibleItems.length === 1 ? 1 : 0}
          >
            {props.renderVisibleItem(item, index)}
          </Box>
        ))}

        {isHitOverflow && overflowItems.length > 0
          ? props.renderOverflowItems(overflowItems)
          : null}

        {props.additionalActions}
      </Stack>
    </Box>
  );
}

export default OverflowList;
