import { uniqBy } from 'ramda';

import matchType from 'shared/utils/matchType';
import { OmitStrict } from 'shared/utils/types';

import { Filter, FilterOperator } from './Filter';

export type FilterDefinition = OmitStrict<Filter, 'value' | 'id' | 'operator'>;

export const getDefaultOperator = (f: FilterDefinition) => {
  return matchType<FilterDefinition['type'], FilterOperator>(
    {
      number: () => 'EQUALS',
      string: () => 'EQUALS',
    },
    f.type
  );
};

export const addFilterDefinitionsFromFilters = (
  filters: Filter[],
  filterDefinitions: FilterDefinition[]
): FilterDefinition[] => {
  return uniqBy(
    (f) => f.name,
    filterDefinitions.concat(
      filters.map(
        (f): FilterDefinition => ({
          label: f.label,
          name: f.name,
          type: f.type,
        })
      )
    )
  );
};
