import { gql } from '@apollo/client';

import { SYSTEM_ADMIN_USER_FRAGMENT } from 'shared/graphql/SystemAdmin/SystemAdmin';
import {
  SystemAdminsAdd,
  SystemAdminsAddVariables,
} from 'features/systemAdmin/admins/add/store/graphql-types/useAddSystemAdmin.generated';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';

const MUTATION = gql`
  mutation SystemAdminsAdd($usernameOrEmail: String!) {
    systemAdmins {
      id
      addSystemAdmin(usernameOrEmail: $usernameOrEmail) {
        id
        admins {
          id
          allowedActions {
            ...AllowedActionsData
          }
          user {
            id
            ...SystemAdminUserData
          }
        }
      }
    }
  }
  ${SYSTEM_ADMIN_USER_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;

export const useAddSystemAdmin = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [addSystemAdmin, addingSystemAdmin] = useCustomMutation<
    SystemAdminsAdd,
    SystemAdminsAddVariables
  >(MUTATION, { context: 'adding system admin' }, { onCompleted });

  return {
    addSystemAdmin,
    addingSystemAdmin,
  };
};
