export interface IField<Name, Value = string> {
  name: Name;
  error: string | undefined;
  value: string;
  isTouched: boolean;
  validate: (value: Value) => string | undefined;
}

export const makeField = <Field extends IField<unknown, string>>({
  name,
  value,
  validate,
}: Pick<Field, 'name' | 'value' | 'validate'>): Field => {
  return {
    name,
    value,
    isTouched: false,
    validate,
    error: validate(value),
  } as Field;
};
