import { Box, Card, Stack, Typography, Grid } from '@mui/material';

import { CardsDocumentation } from './CardsDocumentation';
import { documentationType } from './values/DocumentationCardsType';
import { cardsCatalog, cardsDeploy } from './values/DocumentationCardsValues';

export const DiscoverPlatform = () => (
  <Grid item xs={12}>
    <Card>
      <Box padding="32px">
        <Stack direction="column" spacing={4}>
          <Typography variant="h5" textAlign="left">
            📘 Discover the platform
          </Typography>
          <Typography
            variant="inputText"
            color="text.secondary"
            textAlign="left"
          >
            There's more to discover! Uncover other possibilities Verta offers.
          </Typography>
          <CardsDocumentation
            type={documentationType.CATALOG}
            cards={cardsCatalog}
          />
          <CardsDocumentation
            type={documentationType.VERTA}
            cards={cardsDeploy}
          />
        </Stack>
      </Box>
    </Card>
  </Grid>
);
