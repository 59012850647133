import { AxisLeft, AxisScale } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { styled, useTheme } from '@mui/material';

import { CustomAxisLeftProps } from '../CustomAxisLeft/CustomAxisLeft';
import SvgTextWithTruncation from '../../shared/SvgTextWithTruncation/SvgTextWithTruncation';

const Wrapper = styled('g')(({ theme }) => ({
  '.label': { fill: theme.palette.text.primary },
  '.tick': { fill: theme.palette.text.primary },
}));

const NewCustomAxisLeftWithGridRows = <Scale extends AxisScale>(
  props: CustomAxisLeftProps<Scale> & {
    innerWidth: number;
    maxCharCount?: number;
  }
) => {
  const theme = useTheme();

  const { fontFamily, fontSize, fontWeight, lineHeight } =
    theme.typography.body2;

  return (
    <Wrapper>
      <AxisLeft
        scale={props.scale}
        stroke={theme.palette.charts.accentColor}
        tickStroke={theme.palette.charts.accentColor}
        hideAxisLine={true}
        numTicks={props.numTicks}
        tickComponent={({ formattedValue, ...p }) => (
          <SvgTextWithTruncation
            {...p}
            className="tick"
            fontSize="12px"
            fontWeight="500"
            fontFamily="Roboto"
            fill={theme.palette.text.primary}
            maxCharCount={props.maxCharCount ?? 10}
            x="-14"
          >
            {formattedValue ?? ''}
          </SvgTextWithTruncation>
        )}
        labelOffset={50}
        tickFormat={props.tickFormat}
        label={props.label}
        labelProps={{
          fontFamily,
          fontSize,
          fontWeight,
          lineHeight,
          fill: theme.palette.text.secondary,
          textAnchor: 'middle',
        }}
      />
      <GridRows
        scale={props.scale}
        width={props.innerWidth}
        stroke={theme.palette.charts.accentColor}
        numTicks={props.numTicks}
        strokeWidth={1}
      />
    </Wrapper>
  );
};

export default NewCustomAxisLeftWithGridRows;
