import { scaleLinear, scalePoint } from '@visx/scale';
import { ScaleLinear, ScalePoint } from 'd3-scale';

import { getLinearScaleDomain } from 'shared/utils/charts/getLinearScaleDomain';

import { ParallelCoordinateChartDataValue } from './types';

export type DimensionScale =
  | {
      scale: ScaleLinear<number, number>;
      type: 'linear';
    }
  | {
      scale: ScalePoint<string>;
      type: 'point';
    };

export const getDimensionScale = (
  values: ParallelCoordinateChartDataValue[],
  { innerHeight }: { innerHeight: number }
): DimensionScale => {
  const isScalePoint = values.some(
    (value) => typeof value === 'string' || typeof value === 'boolean'
  );

  if (isScalePoint) {
    return {
      scale: scalePoint({
        domain: values.map(String).sort(),
        range: [innerHeight, 0],
      }),
      type: 'point',
    };
  }

  return {
    scale: scaleLinear({
      domain: getLinearScaleDomain(values.map(Number)),
      range: [innerHeight, 0],
    }),
    type: 'linear',
  };
};
