import { Box, alpha } from '@mui/material';
import { get } from 'lodash';

import BaseAccordion, {
  AccordionProps,
} from 'shared/view/elements/Accordion/Accordion';

type Props = AccordionProps & {
  color: string;
  backgroundColor?: string;
  backgroundColorOpacity?: number;
};

export const Accordion = ({
  children,
  color,
  backgroundColor = 'common.white',
  backgroundColorOpacity = 0.05,
  ...props
}: Props) => (
  <BaseAccordion
    {...props}
    slots={{
      AccordionSummary: {
        sx: {
          height: '48px',
          '&.Mui-expanded': {
            minHeight: 'unset',
          },
        },
        ...props.slots?.AccordionSummary,
      },
      Title: {
        color,
        variant: 'overline',
        ...props.slots?.Title,
      },
      Accordion: {
        ...props.slots?.Accordion,
        sx: {
          borderColor: color,
          borderRadius: '4px',
          ...props.slots?.Accordion?.sx,
        },
      },
      AccordionDetails: {
        ...props.slots?.AccordionDetails,
        sx: {
          paddingRight: 0,
          paddingLeft: 0,
          paddingBottom: 0,
          backgroundColor: (theme) => {
            return alpha(
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `${get(theme.palette, backgroundColor)}`,
              backgroundColorOpacity
            );
          },

          ...props.slots?.AccordionDetails?.sx,
        },
      },
      Divider: {
        ...props.slots?.Divider,
        sx: { borderColor: color, ...props.slots?.Divider?.sx },
      },
    }}
  >
    <Box padding={2}>{children}</Box>
  </BaseAccordion>
);
