import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';

import EndpointUsagePage from './EndpointUsagePage/EndpointUsagePage';
import EndpointOverviewPage from './EndpointOverviewPage/EndpointOverviewPage';
import EndpointSettingsPage from './EndpointSettingsPage/EndpointSettingsPage';
import EndpointBuildsPage from './EndpointBuildsPage/EndpointBuildsPage';

export const endpointDetailsPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.endpointOverview.getPath(),
      element: <EndpointOverviewPage />,
    },
    {
      path: routes.endpointUsage.getPath(),
      element: <EndpointUsagePage />,
    },
    {
      path: routes.endpointSettings.getPath(),
      element: <EndpointSettingsPage />,
    },
    {
      path: routes.endpointBuilds.getPath(),
      element: <EndpointBuildsPage />,
    },
  ],
};
