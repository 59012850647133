import EndpointPermissionsPage, {
  PermissionsProps,
} from 'pages/authorized/EndpointsPages/EndpointDetailsPages/EndpointPermissionsPage/EndpointPermissionsPage';
import { MenuAction } from 'shared/view/elements/ActionsMenu/ActionsMenu';
import Popup from 'shared/view/elements/Popup/Popup';
import { PopupStateProps } from 'shared/view/elements/Popup/PopupButtons';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';

interface PermissionsModalProps extends PopupStateProps, PermissionsProps {}

const PermissionsModal = (props: PermissionsModalProps) => {
  return (
    <Popup
      {...props}
      maxWidth="md"
      fullWidth
      title="Permissions"
      buttons={{
        mainButtonProps: {
          children: 'Cancel',
          isLoading: false,
          onClick: props.onClose,
          type: 'button',
        },
      }}
    >
      <EndpointPermissionsPage {...props} />
    </Popup>
  );
};

export const useRedirectToPermissionAction = (
  props: PermissionsProps
): MenuAction => {
  const popupManager = usePopupManager();

  return {
    dataTest: 'goToPermission-action',
    label: 'Permission',
    onClick: popupManager.openPopup,
    type: 'button',
    additionalContent: popupManager.isPopupOpen ? (
      <PermissionsModal {...props} isOpen onClose={popupManager.closePopup} />
    ) : null,
  };
};
