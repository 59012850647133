import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  title: string;
  titleRightContent?: ReactNode;
}

const Root = styled('div')(({ theme }) => ({
  padding: '8px 16px',
  borderRadius: '4px',
  border: theme.palette.border.default,
  height: '100%',
}));

const HomepageWidgetCardWrapper = (props: Props) => {
  return (
    <Root>
      <Stack style={{ height: '100%' }} spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1">{props.title}</Typography>
          <div>{props.titleRightContent}</div>
        </Stack>
        {props.children}
      </Stack>
    </Root>
  );
};

export default HomepageWidgetCardWrapper;
