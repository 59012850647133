import { uniqBy } from 'ramda';

import { IAttribute } from 'shared/models/Attribute';
import { IKeyValuePair } from 'shared/models/KeyValuePair';
import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';
import isNotNil from 'shared/utils/isNotNill';
import { IPrimitiveKeyValue } from 'shared/models/PrimitiveKeyValue';
import { OmitStrict } from 'shared/utils/types';
import { Observation } from 'shared/graphql/ExperimentRunDetails/Observation/graphql-types/Observation.generated';

import {
  KeyValueSpec,
  KeyValueType,
  keyValueTypeToPluralForm,
  KeyValueSpecWithValue,
  checkValueTypeOfKeyValuePair,
} from './KeyValueSpec';

export type RequiredExperimentRun = {
  id: string;
  tags: string[];
  dateUpdated: Date;
  name: string;
  attributes: IAttribute[];
  hyperparameters: IPrimitiveKeyValue[];
  metrics: IPrimitiveKeyValue[];
  owner: { id: string; username: string };
  experiment: { name: string };
  project: { id: string; workspace: { name: string } };
  observations: Observation[];
};

export type ExperimentRunWithDisplayedFields = OmitStrict<
  RequiredExperimentRun,
  `${KeyValueType}s`
> & {
  [K in KeyValueType as `${KeyValueType}s`]: Array<KeyValueSpecWithValue<K>>;
};

export const getExperimentRunsWithDisplayedFields = (
  experimentRuns: RequiredExperimentRun[]
): ExperimentRunWithDisplayedFields[] => {
  const keyValuesToKeyValuesWithSpec = <T extends KeyValueType>(
    subtype: T,
    keyValues: Array<IKeyValuePair<unknown>>
  ): Array<KeyValueSpecWithValue<T>> => {
    return keyValues
      .map((keyValue) => {
        const valueInfo = checkValueTypeOfKeyValuePair(keyValue);
        return valueInfo
          ? {
              subtype,
              type: 'keyValue' as const,
              key: keyValue.key,
              ...valueInfo,
            }
          : undefined;
      })
      .filter(isNotNil);
  };

  return experimentRuns.map((experimentRun) => ({
    ...experimentRun,
    attributes: keyValuesToKeyValuesWithSpec(
      'attribute',
      experimentRun.attributes
    ),
    hyperparameters: keyValuesToKeyValuesWithSpec(
      'hyperparameter',
      experimentRun.hyperparameters
    ),
    metrics: keyValuesToKeyValuesWithSpec('metric', experimentRun.metrics),
  }));
};

export const getKeyValueSpecs = (
  experimentRuns: ExperimentRunWithDisplayedFields[]
): KeyValueSpec[] => {
  return uniqBy(
    ({ key }) => key,
    experimentRuns.flatMap((experimentRun) =>
      exhaustiveStringTuple<KeyValueType>()(
        'attribute',
        'hyperparameter',
        'metric'
      )
        .map(keyValueTypeToPluralForm)
        .flatMap((keyValueType) => experimentRun[keyValueType])
        .map(({ value: _value, ...keyValueSpec }) => keyValueSpec)
    )
  );
};

export const getKeyValueBySpec = (
  spec: KeyValueSpec,
  experimentRun: ExperimentRunWithDisplayedFields
) => {
  return experimentRun[keyValueTypeToPluralForm(spec.subtype)].find(
    (keyValuePair) => keyValuePair.key === spec.key
  );
};

export const getValueByKeyValueSpec = (
  spec: KeyValueSpec,
  experimentRun: ExperimentRunWithDisplayedFields
) => {
  return getKeyValueBySpec(spec, experimentRun)?.value;
};
