import isNotNil from 'shared/utils/isNotNill';

import { Dimension } from './dimensions';
import { ParallelCoordinateChartData } from './types';

export interface ParallelCoordinateChartPoint {
  x: number;
  y: number;
}

export const makePoints = (
  d: ParallelCoordinateChartData,
  dimensions: Dimension[]
): ParallelCoordinateChartPoint[] | null => {
  let withMissingValues = false;
  const points = dimensions
    .map((dimension) => {
      const value = d[dimension.key];
      if (typeof value === 'undefined') {
        withMissingValues = true;
        return null;
      }
      return {
        x: dimension.x,
        y: dimension.getY(value),
      };
    })
    .filter(isNotNil);

  // todo probably legacy code and can produce malfunctions. Need to be checked and fixed;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return withMissingValues ? null : points;
};
