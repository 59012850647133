import { SortingDirection } from '../Sorting';
import { WorkspaceName } from '../Workspace';
import { SearchEntities } from './SearchEntities';
import { EntityResultCommonData } from './SearchResult';

export interface IResultsSorting {
  field: keyof Pick<EntityResultCommonData, 'dateCreated' | 'dateUpdated'>;
  direction: SortingDirection;
}
export const defaultResultsSorting: IResultsSorting = {
  direction: 'desc',
  field: 'dateUpdated',
};
export type ActiveFilter = SearchEntities;

export interface ISearchSettings {
  nameOrTag: string;
  type: ActiveFilter;
  currentPage: number;
  sorting: IResultsSorting | undefined;
  workspaceName: WorkspaceName;
  organizationId: string | undefined;
}
