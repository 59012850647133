import { useRouteParams } from 'shared/utils/router/useRouteParams';
import { useModelActivities } from 'features/catalog/registeredModel/activities/hooks';
import routes from 'shared/routes';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

const ModelActivities = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'RegisteredModelActivities' */ 'features/catalog/registeredModel/activities/view/activities'
    )
);

const ActivitiesPage = () => {
  const { registeredModelId, workspaceName } = useRouteParams(
    routes.modelActivities
  );

  const { data, communication } = useModelActivities({ id: registeredModelId });

  return (
    <DefaultMatchRemoteDataOrError
      communication={communication}
      data={data}
      context="loading model"
    >
      {({ versions }) => (
        <ModelActivities
          modelId={registeredModelId}
          workspaceName={workspaceName}
          versions={versions.versions}
        />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default ActivitiesPage;
