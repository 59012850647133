import { useSelector } from 'react-redux';
import { Box, styled } from '@mui/material';
import { DataGridPro, GridColDef, LicenseInfo } from '@mui/x-data-grid-pro';

import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { selectFlags } from 'features/flags';
import { HeatmapCell } from 'features/dashboard/dashboard/shared/HeatmapCell';
import { Model } from 'features/dashboard/hooks/useCustomDashboardTopModelsWithAttrs';

import { getCellNumber, getCellValue } from './portfolio/TopModelsTable';

const columns: GridColDef[] = [
  {
    field: 'Production Incidents',
    headerName: 'Production Incid.',
    width: 47,
    sortable: false,
    renderCell: (params) => {
      return <CellRendererString value={getCellNumber(params)} />;
    },
  },
  {
    field: 'Compliance Incidents',
    headerName: 'Compliance Incid.',
    width: 47,
    sortable: false,
    renderCell: (params) => (
      <CellRendererString value={getCellNumber(params)} />
    ),
  },
  {
    field: 'Model Service Status',
    headerName: 'M. Service',
    width: 47,
    sortable: false,
    headerClassName: 'smallerHeader',
    renderCell: (params) => <HeatmapCell $value={getCellValue(params) || ''} />,
  },
  {
    field: 'Response Time Status',
    headerName: 'Resp. Time',
    width: 47,
    sortable: false,
    headerClassName: 'smallerHeader',
    renderCell: (params) => <HeatmapCell $value={getCellValue(params) || ''} />,
  },
  {
    field: 'Data Drift',
    headerName: 'Data Drift',
    width: 47,
    sortable: false,
    headerClassName: 'smallerHeader',
    renderCell: (params) => <HeatmapCell $value={getCellValue(params) || ''} />,
  },
  {
    field: 'Fairness Checks',
    headerName: 'Fairness',
    width: 47,
    sortable: false,
    headerClassName: 'smallerHeader',
    renderCell: (params) => <HeatmapCell $value={getCellValue(params) || ''} />,
  },
  {
    field: 'Compliance Checks',
    headerName: 'Compliance',
    width: 47,
    sortable: false,
    headerClassName: 'smallerHeader',
    renderCell: (params) => <HeatmapCell $value={getCellValue(params) || ''} />,
  },
];

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  border: 'none',
  '.MuiDataGrid-columnHeaders': {
    maxHeight: 'fit-content !important',
    backgroundColor: 'transparent',
    borderBottom: 'none',
    '[class$="columnHeaderRow"]': {
      gap: '4px',
    },
    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '.MuiDataGrid-columnHeadersInner': {
      height: '110px',
      justifyContent: 'flex-end',
    },
    '.MuiDataGrid-columnHeader': {
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.secondary,
      transform: 'rotate(-55deg) translate(-5px, -5px)',

      '.MuiDataGrid-columnHeaderTitleContainer': {
        overflow: 'visible',
      },
      '.MuiDataGrid-columnHeaderTitleContainerContent': {
        overflow: 'visible',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    '.smallerHeaders': {
      ...theme.typography.caption,
    },
  },
  '.MuiDataGrid-row': {
    gap: '4px',
  },
  '.MuiDataGrid-cell': {
    borderBottom: 'none',
    padding: 0,
    '&:focus': {
      outline: 'none',
    },
  },
}));

export const ModelsHeatmap = ({ data }: { data: Model[] }) => {
  const { muiDataGridLicense } = useSelector(selectFlags);
  if (muiDataGridLicense) {
    LicenseInfo.setLicenseKey(muiDataGridLicense);
  }

  return (
    <Box>
      <StyledDataGrid
        autoHeight
        disableColumnFilter
        disableChildrenSorting
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        disableColumnResize
        disableVirtualization
        hideFooter
        rows={data}
        columns={columns}
      />
    </Box>
  );
};
