import { equals, isNil } from 'ramda';

import isNotNil from 'shared/utils/isNotNill';

export const isDifferentIfAtleastOneItemIsDifferentOrMissedBy = <T>(
  isDifferent: (firstItem: T, anotherItem: T) => boolean,
  items: Array<T | undefined>
) => {
  if (items.length === 0 || items.every(isNil)) {
    return false;
  }

  if (items.some(isNil)) {
    return true;
  }

  const [firstItem, ...rest] = items;
  return rest.some(
    (anotherItem) =>
      isNotNil(firstItem) &&
      isNotNil(anotherItem) &&
      isDifferent(firstItem, anotherItem)
  );
};

export const isDifferentIfAtleastOneItemIsDifferentOrMissed = <T, B>(
  getValue: (v: T) => B,
  items: Array<T | undefined>
) =>
  isDifferentIfAtleastOneItemIsDifferentOrMissedBy(
    (firstItem, anotherItem) =>
      !equals(getValue(firstItem), getValue(anotherItem)),
    items
  );

export const isDifferentIfAtleastOneItemIsDifferentBy = <T>(
  isDifferent: (firstItem: T, anotherItem: T) => boolean,
  items: T[]
) => {
  if (items.length === 0) {
    return false;
  }

  const [firstItem, ...rest] = items;
  return rest.some((anotherItem) => isDifferent(firstItem, anotherItem));
};
