import { RequiredResource } from 'features/editResourceInformation/store/useEditResourceInformation';
import matchType from 'shared/utils/matchType';

export const resourceTypeToView = (type: RequiredResource['__typename']) =>
  matchType(
    {
      Dataset: () => 'dataset',
      Endpoint: () => 'endpoint',
      Project: () => 'project',
      RegisteredModel: () => 'model',
      RegisteredModelVersion: () => 'model version',
      MonitoredEntity: () => 'monitor',
    },
    type
  );
