import Stack from '@mui/material/Stack';

import { AppError } from 'shared/models/Error';
import CreateErrorReportButtonWrapper from 'features/errorReporter/view/CreateErrorReportButtonWrapper/CreateErrorReportButtonWrapper';

import { appErrorToString } from '../helpers';
import InlineErrorView from '../InlineErrorView/InlineErrorView';
import Button from '../../Button/Button';

interface ILocalProps {
  error: AppError | Error | string;
  dataTest?: string;
  context?: string;
}

const getErrorMessage = (props: ILocalProps) => {
  if (props.context) {
    return appErrorToString(props.error, props.context);
  }

  return typeof props.error === 'string' ? props.error : props.error.message;
};

const InlineCommunicationError = (props: ILocalProps) => {
  const errorMessage = getErrorMessage(props);
  return (
    <InlineErrorView
      error={
        <Stack
          alignItems="center"
          justifyContent="center"
          style={{ height: '100%' }}
        >
          <span>{errorMessage}</span>
          {typeof props.error !== 'string' && (
            <div>
              <Stack alignItems="center" justifyContent="center">
                <CreateErrorReportButtonWrapper
                  error={props.error}
                  context={props.context || ''}
                >
                  {(text, onClick) => (
                    <Button onClick={onClick} isLoading={false}>
                      {text}
                    </Button>
                  )}
                </CreateErrorReportButtonWrapper>
              </Stack>
            </div>
          )}
        </Stack>
      }
      dataTest={props.dataTest}
    />
  );
};

export default InlineCommunicationError;
