import React from 'react';

import { ICommunication } from 'shared/utils/redux/communication';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import {
  NotNullableRestrictedGraphqlError,
  RestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';
import { RequiredOwner } from 'features/editResourceInformation/store/useEditResourceInformation';

import DatasetDetailsLayout from './shared/DatasetDetailsLayout/DatasetDetailsLayout';

export interface BaseDataset {
  __typename: 'Dataset';
  id: string;
  name: string;
  description: string;
  owner: RequiredOwner;
  dateUpdated: number;
  allowedActions: {
    delete: boolean;
    update: boolean;
  };
}

export function DatasetDetailsLayoutWithLoading<T extends BaseDataset>({
  data,
  loadingDataset,
  children,
  ...layoutProps
}: {
  data: T | RestrictedGraphqlError | undefined;
  loadingDataset: ICommunication;
  reload: () => void;
  children: ({
    dataset,
  }: {
    dataset: NotNullableRestrictedGraphqlError<T>;
  }) => JSX.Element;
} & Pick<
  React.ComponentProps<typeof DatasetDetailsLayout>,
  'pageEntityOrError'
>) {
  return (
    <DatasetDetailsLayout
      {...layoutProps}
      pageEntityOrError={data}
      dataset={data}
    >
      <DefaultMatchRemoteDataOrError
        communication={loadingDataset}
        data={data}
        context={'loading dataset'}
      >
        {(loadedData) => <div>{children({ dataset: loadedData })}</div>}
      </DefaultMatchRemoteDataOrError>
    </DatasetDetailsLayout>
  );
}
