import noop from 'shared/utils/noop';

import TextInput from '../TextInput/TextInput';

type Props = {
  value: string;
  label: string;
  width?: string;
  dataTest?: string;
};

export default function ReadonlyInputWithSecret(props: Props) {
  const { width, dataTest, label, value } = props;
  return (
    <div style={{ width: width ? width : '350px' }}>
      <TextInput
        withoutError
        dataTest={dataTest}
        readOnly
        type="password"
        label={label}
        value={value}
        onChange={noop}
      />
    </div>
  );
}
