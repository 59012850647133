import {
  equals,
  fromPairs,
  includes,
  toPairs,
  groupBy as ramdaGroupBy,
  move,
  values,
} from 'ramda';

import isNotNil from './isNotNill';
import makeTuple from './makeTuple';

export const getArrayItem = <T>(index: number, arr: T[]): T | undefined =>
  arr[index];

const mapObjWithKey = <T extends Record<string, any>, B>(
  f: (key: keyof T, value: T[keyof T]) => B,
  obj: T
): { [K in keyof T]: B } => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return fromPairs(
    toPairs(obj).map(([key, value]) => [key, f(key, value)])
  ) as any;
};
export const mapObj = <T extends Record<string, any>, B>(
  f: (value: T[keyof T], i: number) => B,
  obj: T
): { [K in keyof T]: B } => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return fromPairs(
    toPairs(obj).map(([key, value], i) => [key, f(value, i)])
  ) as any;
};

export const updateById = <T extends { id: string | number }>(
  f: (x: T) => T,
  id: string | number,
  array: T[]
): T[] => {
  return array.map((el) => (el.id === id ? f(el) : el));
};

export const updateBy = <T>(
  pred: (x: T) => boolean,
  f: (x: T) => T,
  array: T[]
): T[] => {
  return array.map((el) => (pred(el) ? f(el) : el));
};

export function updateByOrDefault<T>(
  pred: (v: T) => boolean,
  update: (x: T) => T,
  def: T,
  items: T[]
): T[] {
  if (items.some(pred)) {
    return items.map((v) => (pred(v) ? update(v) : v));
  } else {
    return items.concat(def);
  }
}

export const toggle = <T>(array: T[], value: T) => {
  if (includes(value, array)) {
    return array.filter((i) => !equals(i, value));
  }
  return [...array, value];
};

export function groupBy<T, U extends string>(
  fn: (a: T) => U,
  list: ReadonlyArray<T>
): Record<U, T[]> {
  return ramdaGroupBy(fn, list);
}

export const moveFirstItem = <T>(
  pred: (item: T) => boolean,
  to: number,
  items: T[]
): T[] => {
  const targetItemIndex = items.findIndex(pred);
  return move(targetItemIndex, to, items);
};

export const hasKey = <B extends Record<any, any>>(
  key: keyof B | string | undefined,
  obj: B
): key is keyof B => {
  return isNotNil(key) ? key in obj : false;
};

export const isNotEmptyArray = <T>(items: readonly T[]): items is [T, ...T[]] =>
  items.length > 0;

export const areThereAtLeast2Items = <T>(array: T[]): array is [T, T] =>
  array.length >= 2;

export const every = <T, S extends T>(
  pred: (value: T, index: number) => value is S,
  items: T[]
): items is S[] => items.every(pred);

export const makeObjectFromArray = <K extends string | number | symbol, V>(
  getKeyValue: (key: K) => V,
  array: K[]
): Record<K, V> =>
  fromPairs(
    array.map((key) => makeTuple(key, getKeyValue(key))) as any
  ) as Record<K, V>;

export const weakGroupBy = <T, Key extends string>(
  getter: (item: T) => Key,
  items: T[]
): Record<Key, T[] | undefined> => ramdaGroupBy(getter, items);

export function mapObjectKeysToArray<Obj extends Record<string, any>>(
  object: Obj
) {
  function f<R>(matchers: { [K in keyof Obj]: (value: Obj[K]) => R }) {
    return values(mapObjWithKey((key, value) => matchers[key](value), object));
  }
  return f;
}

export function removeNillProperties<T extends Record<any, any>>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) ? removeNillProperties(v) : v])
  ) as T;
}

export function getLastButOne<T>(array: T[]): T | undefined {
  return array[array.length - 2];
}

export function filterByType<
  I extends { type: string },
  K extends I['type'] = I['type'],
>(items: I[], targetType: K): Array<Extract<I, { type: K }>> {
  return items.filter((i) => i.type === targetType) as Array<
    Extract<I, { type: K }>
  >;
}

export const reorder = <T>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const toKebabCase = (string: string) =>
  string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

export const isArrayWithLength2 = <T>(array: T[]): array is [T, T] => {
  return array.length === 2;
};
