import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Divider, Stack, styled } from '@mui/material';

import { IconAwesomeLink } from 'shared/view/elements/IconAwesome/IconAwesomeLink';
import Tooltip from 'shared/view/elements/Tooltip/Tooltip';
import { selectCurrentWorkspaceName } from 'features/workspaces';
import Popup from 'shared/view/elements/Popup/Popup';
import { componentsSizes } from 'shared/mui/themes/CommonMuiThemeOptions';
import { selectIsEnableDarkMode } from 'features/darkMode/store/selectors';
import { useOnboardingContext } from 'features/homepage/hooks/useOnboarding';
import Chip from 'shared/view/elements/Chip/Chip';
import { NavRouteItem } from 'pages/authorized/shared/hooks/useNavRouteItems';
import { selectFlags } from 'features/flags';

import UserBar from '../Header/UserBar/UserBar';
import SupportMenu from './SupportMenu';
import FeatureFlagManager from '../../shared/FeatureFlagManager';

interface Props {
  navRouteItems: NavRouteItem[];
}

const paddingBottom = 16;
const RootWrapper = styled(Box)(({ theme }) => ({
  zIndex: theme.zIndex.appBar - 1,
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  bottom: '0',
  width: componentsSizes.sidebar.width,
  backgroundColor: theme.palette.additionalBackground.sidebar,
  height: '100%',
  userSelect: 'none',
  paddingTop: componentsSizes.appBar.height + paddingBottom * 2,
  paddingBottom: paddingBottom,
  borderRigthColor: theme.palette.divider,
  borderRigthStyle: 'solid',
}));

const ActiveRouteBar = styled(Box)(({ theme }) => ({
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  position: 'absolute',
  height: '38px',
  width: '4px',
  borderRadius: '2px',
  backgroundColor: theme.palette.additionalBackground.sidebarActive,
}));

const StyleChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  height: '24px',
}));

const Sidebar = React.memo((props: Props) => {
  const currentWorkspaceName = useSelector(selectCurrentWorkspaceName);
  const isEnableDarkMode = useSelector(selectIsEnableDarkMode);
  const {
    deployment: { isEnable: isEnableDeployment },
  } = useSelector(selectFlags);
  const [flagModalOpen, setFlagModalOpen] = useState(false);
  const { stepsValues, isHiddenGettingStarted } = useOnboardingContext();

  const location = useLocation();
  const isRouteActive = (route: NavRouteItem['route']) =>
    Boolean(route.getMatch(location.pathname));

  return (
    <RootWrapper borderRight={isEnableDarkMode ? 1 : undefined}>
      <Stack direction="column" alignItems="center" spacing={3}>
        {props.navRouteItems
          .filter(({ isHidden }) => !isHidden)
          .map((route, i) => (
            <Stack direction="column" alignItems="center" width="100%" key={i}>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip
                  type="withDiv"
                  title={route.tooltip}
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      style: { fontSize: '16px', fontWeight: 400 },
                    },
                  }}
                >
                  <IconAwesomeLink
                    size="lg"
                    icon={route.icon}
                    to={route.route.getRedirectPath({
                      workspaceName: currentWorkspaceName,
                    })}
                    reversedColors={true}
                  />
                </Tooltip>
                {isRouteActive(route.route) ? <ActiveRouteBar /> : null}
              </div>
              {i === 0 && !isHiddenGettingStarted && isEnableDeployment ? (
                <StyleChip
                  variant="filled"
                  label={`${
                    Object.values(stepsValues).filter((item) => item).length
                  } of 4`}
                />
              ) : null}
            </Stack>
          ))}
      </Stack>
      <Stack
        spacing={3}
        direction="column"
        alignItems="center"
        style={{
          marginTop: 'auto',
        }}
        divider={
          <Divider
            sx={{
              width: '60%',
              height: '1px',
              borderColor: 'additionalBackground.sidebarContrast',
            }}
          />
        }
      >
        <SupportMenu />
        <UserBar setFlagModalOpen={setFlagModalOpen} />
      </Stack>
      <Popup
        title="Feature Flag Manager"
        isOpen={flagModalOpen}
        onClose={() => setFlagModalOpen(false)}
        fullScreen
      >
        <FeatureFlagManager onClose={() => setFlagModalOpen(false)} />
      </Popup>
    </RootWrapper>
  );
});

export default Sidebar;
