// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Radio from '@mui/material/Radio';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { BoxProps } from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import isNotNil from 'shared/utils/isNotNill';
import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import { NonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';
import { OmitStrict } from 'shared/utils/types';

import FieldBox from '../FieldBox/FieldBox';

// ts-unused-exports:disable-next-line
export type RadioButtonOption<T> = {
  label: string;
  value: T;
  name: string;
  hint?: string;
  disabled?: boolean;
};

export type RadioButtonsProps<T> = OmitStrict<BoxProps, 'onChange'> & {
  value: T;
  options: NonEmptyArray<RadioButtonOption<T>>;
  onChange(value: T): void;
  label?: string;
  disabled?: boolean;
  row?: boolean;
};

const RadioButtons = <T extends unknown>({
  value,
  options,
  onChange,
  disabled,
  label,
  row = true,
  ...other
}: RadioButtonsProps<T>) => {
  /**
   * Workaround typecasting. The reason is that onChange type
   * from MUI RadioGroup provides value as string, but works
   * with our T correctly.
   */
  const handleChange = (_: unknown, v: unknown) => {
    onChange(v as T);
  };

  return (
    <FieldBox {...other}>
      <FormControl fullWidth disabled={disabled} size={'small'}>
        {isNotNil(label) ? (
          <FormLabel id="demo-radio-buttons-group-label">
            <DynamicTypography value={label} color="text.primary" />
          </FormLabel>
        ) : null}

        <RadioGroup value={value} onChange={handleChange} row={Boolean(row)}>
          <Stack
            direction={Boolean(row) ? 'row' : 'column'}
            spacing={Boolean(row) ? 3 : 2}
          >
            {options.map((option) => (
              <FormControlLabel
                sx={{
                  alignItems: Boolean(option.hint) ? 'start' : 'center',
                  color: 'text.primary',
                }}
                key={option.label}
                value={option.value}
                disabled={option.disabled}
                control={
                  <Radio name={option.name} style={{ pointerEvents: 'auto' }} />
                }
                label={
                  option.hint ? (
                    <OptionLabelWithHint
                      label={option.label}
                      hint={option.hint}
                      disabled={option.disabled}
                    />
                  ) : (
                    option.label
                  )
                }
              />
            ))}
          </Stack>
        </RadioGroup>
      </FormControl>
    </FieldBox>
  );
};

const OptionLabelWithHint = ({
  label,
  hint,
  disabled,
}: {
  label: string;
  hint: string;
  disabled?: boolean;
}) => {
  return (
    <Stack spacing="0" pt={1}>
      <Typography
        variant="body2"
        color={disabled ? 'text.disabled' : 'text.primary'}
      >
        {label}
      </Typography>
      <Typography
        variant="body2"
        color={disabled ? 'text.disabled' : 'text.secondary'}
      >
        {hint}
      </Typography>
    </Stack>
  );
};

export default RadioButtons;
