import { useMemo } from 'react';

import MonitoringWidgetWrapper from 'shared/view/domain/Monitoring/MonitoringWidget/MonitoringWidgetWrapper/MonitoringWidgetWrapper';
import MonitoringWidgetView from 'features/monitoring/widgets/view/MonitoringWidgetView/MonitoringWidgetView';
import { MonitoringWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidget';
import { useZoomReset } from 'shared/utils/charts/zoom/useZoomReset';
import { OmitStrict } from 'shared/utils/types';
import ZoomResetAction from 'features/monitoring/widgets/view/shared/actions/ZoomResetAction/ZoomResetAction';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';

import styles from './MonitoringWidgetPreview.module.css';

export type MonitoringWidgetPreviewType = OmitStrict<MonitoringWidget, 'id'>;

const MonitoringWidgetPreview = (props: {
  widgetPreview: MonitoringWidgetPreviewType | null;
  widgetExternalDeps: MonitoringWidgetExternalDeps;
}) => {
  const { onReset, onSetReset } = useZoomReset();

  const widget = useMemo(
    () =>
      props.widgetPreview
        ? { ...props.widgetPreview, id: 'preview-widget' }
        : null,
    [props.widgetPreview]
  );

  return widget ? (
    <MonitoringWidgetWrapper
      widget={widget}
      draggable={false}
      actions={[<ZoomResetAction key="reset" onZoomReset={onReset} />]}
    >
      {(size) => (
        <MonitoringWidgetView
          id={widget.id}
          widget={widget}
          widgetExternalDeps={props.widgetExternalDeps}
          onSetReset={onSetReset}
          size={size}
          updateWidget={undefined}
        />
      )}
    </MonitoringWidgetWrapper>
  ) : (
    <EmptyWidget />
  );
};

const EmptyWidget = () => {
  return (
    <MonitoringWidgetWrapper widget={null} draggable={false} actions={[]}>
      {(size) => (
        <div className={styles.emptyWidget} style={size}>
          Please fill all settings to see preview
        </div>
      )}
    </MonitoringWidgetWrapper>
  );
};

export default MonitoringWidgetPreview;
