import { isEmpty, sortBy, reverse, head } from 'ramda';

import { Activity, Transition, Stage, TransitionState } from 'generated/types';
import { makeError, makeSuccess, Result } from 'shared/utils/result';
import { ChangePropertyType } from 'shared/utils/types';
import isNotNil from 'shared/utils/isNotNill';

export type ActivityWithTransitionPendingApproval<
  T extends Pick<Activity, 'transition'> = Activity,
> = ChangePropertyType<
  T,
  'transition',
  ChangePropertyType<Transition, 'state', 'PENDING'>
>;

export const getActivitiesWithTransitionsPendingApproval = <
  T extends Pick<Activity, 'id' | 'transition'>,
>(
  activities: T[]
): ActivityWithTransitionPendingApproval<T>[] =>
  activities
    .map((activity) =>
      activity.transition &&
      activity.transition.state === TransitionState.PENDING
        ? {
            ...activity,
            transition: {
              ...activity.transition,
              state: 'PENDING' as const,
            },
          }
        : null
    )
    .filter(isNotNil);

export const isThereTransitionsPendingApproval = <
  T extends Pick<Activity, 'id' | 'transition'>,
>(
  activities: T[]
): boolean =>
  getActivitiesWithTransitionsPendingApproval(activities).length > 0;

export const validateAccessToAproveTransition = ({
  currentUserId,
  allowedActions,
  activity,
  stage,
}: {
  currentUserId: string;
  allowedActions: { update: boolean; updateRedact: boolean };
  stage: Stage;
  activity: Pick<ActivityWithTransitionPendingApproval, 'transition'> & {
    author: Pick<ActivityWithTransitionPendingApproval['author'], 'id'>;
  };
}): Result<true, string> => {
  const action = 'approve this request';
  return combineValidators([
    () =>
      validateDesiredStageIsValid({ stage, transition: activity.transition }),
    () =>
      validateIsNotSelfAllowedAction(action)({
        currentUserId,
        activityAuthorId: activity.author.id,
      }),
    () => validateCurrentUserHasReadWriteAccess(action)(allowedActions),
    () => validateCurrentUserHasPromoteAccess(action)(allowedActions),
  ]);
};

const validateDesiredStageIsValid = ({
  transition,
  stage,
}: {
  stage: Stage;
  transition: ActivityWithTransitionPendingApproval['transition'];
}): Result<true, string> => {
  return transition.sourceStage === stage
    ? makeSuccess(true)
    : makeError('the change request is no longer valid.');
};

export const validateAccessToRejectTransition = ({
  currentUserId,
  allowedActions,
  activity,
}: {
  currentUserId: string;
  allowedActions: { update: boolean; updateRedact: boolean };
  activity: Pick<ActivityWithTransitionPendingApproval, 'transition'> & {
    author: Pick<ActivityWithTransitionPendingApproval['author'], 'id'>;
  };
}): Result<true, string> => {
  const action = 'reject this request';
  return combineValidators([
    () =>
      validateIsNotSelfAllowedAction(action)({
        currentUserId,
        activityAuthorId: activity.author.id,
      }),
    () => validateCurrentUserHasReadWriteAccess(action)(allowedActions),
    () => validateCurrentUserHasPromoteAccess(action)(allowedActions),
  ]);
};

const validateIsNotSelfAllowedAction =
  (action: string) =>
  ({
    activityAuthorId,
    currentUserId,
  }: {
    currentUserId: string;
    activityAuthorId: string;
  }): Result<true, string> =>
    currentUserId !== activityAuthorId
      ? makeSuccess(true)
      : makeError(`You don't have the necessary permission to ${action}.`);

const combineValidators = (
  validators: Array<() => Result<true, string>>
): Result<true, string> =>
  validators.reduce<Result<true, string>>(
    (result, validate) => (result.type === 'error' ? result : validate()),
    makeSuccess(true)
  );

const validateCurrentUserHasReadWriteAccess =
  (action: string) =>
  ({ update }: { update: boolean }): Result<true, string> =>
    update
      ? makeSuccess(true)
      : makeError(`You don't have the necessary permission to ${action}.`);

const validateCurrentUserHasPromoteAccess =
  (action: string) =>
  ({ updateRedact }: { updateRedact: boolean }): Result<true, string> =>
    updateRedact
      ? makeSuccess(true)
      : makeError(`You don't have the necessary permission to ${action}.`);

export const validateAccessToCloseTransition =
  validateCurrentUserHasReadWriteAccess('close transition');

export type ActionOverTransitionPendingApproval =
  | 'close'
  | 'reject'
  | 'approve';

export const getPerviousStageWhenArchived = <
  T extends Pick<Activity, 'id' | 'transition'>,
>(props: {
  currentStage?: Stage;
  activities?: T[];
}) => {
  const { currentStage, activities } = props;
  if (currentStage === Stage.ARCHIVED && activities && !isEmpty(activities)) {
    const onlyCommitToArchived = activities.filter(checkCommitedAndArchived);
    const sorted = reverse(sortBy((x) => x.id, onlyCommitToArchived));
    const lastTransition = head(sorted);

    if (lastTransition) {
      return lastTransition.transition?.sourceStage;
    }
  }
  return undefined;
};

const checkCommitedAndArchived = ({
  transition,
}: Pick<Activity, 'id' | 'transition'>): boolean =>
  transition !== null &&
  transition !== undefined &&
  transition.desiredStage === Stage.ARCHIVED &&
  transition.state === TransitionState.COMMITTED;
