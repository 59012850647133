/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import {
  isNotNullableRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import onCompletedUpdate from 'shared/utils/graphql/onCompletedUpdate';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as GraphqlTypes from './graphql-types/projectCreation.generated';

const CREATE_PROJECT = gql`
  mutation CreateProject(
    $workspaceName: String!
    $organizationId: ID
    $name: String!
    $tags: [String!]!
    $isTagsEmpty: Boolean!
    $description: String!
    $isDescriptionEmpty: Boolean!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        createProject(name: $name) {
          id
          name
          addTags(tags: $tags) @skip(if: $isTagsEmpty) {
            id
            tags
          }
          changeDescription(description: $description)
            @skip(if: $isDescriptionEmpty) {
            id
            description
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export interface IProjectCreationSettings {
  name: string;
  tags: string[];
  description: string;
  workspaceName: string;
  organizationId: string | undefined;
}

export const useCreateProject = () => {
  const [runMutation, creatingProject] = useCustomMutation<
    GraphqlTypes.CreateProject,
    GraphqlTypes.CreateProjectVariables
  >(CREATE_PROJECT, { context: 'creating project' });

  const createProject = (
    variables: IProjectCreationSettings,
    onSuccess: (id: string) => void
  ) => {
    runMutation(
      {
        name: variables.name,
        workspaceName: variables.workspaceName,

        tags: variables.tags,
        isTagsEmpty: variables.tags.length === 0,

        description: variables.description,
        isDescriptionEmpty: variables.description.length === 0,

        organizationId: variables.organizationId,
      },
      {
        update: onCompletedUpdate((createdProject) => {
          if (
            createdProject &&
            isNotNullableRestrictedGraphqlError(createdProject.workspace)
          ) {
            onSuccess(createdProject.workspace.createProject.id);
          }
        }),
      }
    );
  };

  return {
    createProject,
    creatingProject,
  };
};
