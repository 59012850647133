import { Stack } from '@mui/material';

import { OnSetReset } from 'shared/utils/charts/zoom/useZoomReset';
import { MetricOverTime } from 'features/monitoring/widgets/store/metricOverTime/graphql-types/useMetricOverTime.generated';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';
import { isNotRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import parseGraphqlDate from 'shared/utils/graphql/parseGraphqlDate';
import { MonitoringMetricType } from 'generated/types';
import { getLabelByMonitoringMetricType } from 'shared/models/Monitoring/MonitoringMetricType';
import MonitoringWidgetSizeDetector from 'shared/view/domain/Monitoring/MonitoringWidget/MonitoringWidgetSizeDetector/MonitoringWidgetSizeDetector';
import { ExtractByTypename } from 'shared/utils/types';

import NewTimeSeriesChart, {
  TimeSeriesChartData,
} from './NewTimeSeriesChart/NewTimeSeriesChart';

type MonitoredEntity = ExtractByTypename<
  MetricOverTime['monitoredEntity'],
  'MonitoredEntity'
>;

interface Props {
  id: string;
  metricOverTime: MonitoredEntity['metrics']['metricOverTime'];
  metricType: MonitoringMetricType;
  isHovered: boolean;
  actions: JSX.Element[];
  onSetReset: OnSetReset;
}

const NewTimeSeries = (props: Props) => {
  return (
    <Stack
      direction="row"
      style={{ background: 'white', padding: '16px', height: '100%' }}
      justifyContent="space-between"
    >
      <div
        style={{
          height: '100%',
          width: 'calc(100% - 36px)',
        }}
      >
        <MonitoringWidgetSizeDetector>
          {(size) => (
            <NewTimeSeriesChart
              id={props.id}
              onSetReset={props.onSetReset}
              size={size}
              data={props.metricOverTime.flatMap(convertData)}
              metricName={getLabelByMonitoringMetricType(props.metricType)}
              alert={undefined}
            />
          )}
        </MonitoringWidgetSizeDetector>
      </div>
      <div style={{ opacity: props.isHovered ? 1 : 0 }}>
        <Stack spacing={3}>{props.actions}</Stack>
      </div>
    </Stack>
  );
};

const convertData = (
  data: MonitoredEntity['metrics']['metricOverTime'][0]
): TimeSeriesChartData[] => {
  return data.values.map((value, index) => ({
    value: parseGraphQLNumber(value),
    key: isNotRestrictedGraphqlError(data.modelVersion)
      ? data.modelVersion.version
      : data.modelVersionId,
    time: parseGraphqlDate(data.time[index]),
    modelVersion: isNotRestrictedGraphqlError(data.modelVersion)
      ? data.modelVersion.version
      : data.modelVersionId,
  }));
};

export default NewTimeSeries;
