import { Box, Popper, keyframes, styled } from '@mui/material';

import { transientOptions } from 'shared/utils/transientOptions';

export const PopperContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: '16px',
  borderRadius: '8px',
  boxShadow: `0px 5px 15px rgba(0, 0, 0, 0.45)`,
  display: 'flex',
  flexDirection: 'column',
  height: '140px',
  width: '330px',
  position: 'absolute',
  top: '-128px',
  left: '30px',
}));

const fadeInAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const StyledPopper = styled(Popper)({
  zIndex: 1,
  width: '330px',
  animation: `${fadeInAnimation} ease .5s`,
  animationIterationCount: 1,
  animationFillMode: 'forwards',
});

export const WrapperWithCustomBorder = styled(
  Box,
  transientOptions
)(({ $isReminderOpen }: { $isReminderOpen: boolean }) => ({
  boxShadow: $isReminderOpen
    ? '0 0 0 4px rgba(255, 255, 255, 0.7), 0 0 0 8px rgba(255, 255, 255, 0.5), 0 0 0 12px rgba(255, 255, 255, 0.3)'
    : 'unset',
  borderRadius: '4px',
}));
