import { keys, pick, toPairs } from 'lodash';
import { Typography, styled } from '@mui/material';

import { EvaluationData } from 'features/evaluations/shared/utils/evaluation';

const Ul = styled('ul')(() => ({}));
const Li = styled('li')(() => ({ marginBottom: '16px' }));

export const MetadataList = ({ row }: { row: EvaluationData }) => {
  const metadataKeys = keys(row).filter((key) => key.includes('(Metadata)'));
  const metadata = toPairs(pick(row, metadataKeys));

  return (
    <Ul>
      {metadata.map(([key, value]) => (
        <Li key={key}>
          <Typography>
            <strong>{key}</strong>
          </Typography>
          <Typography>{value}</Typography>
        </Li>
      ))}
    </Ul>
  );
};
