// Note: No text formatting applied on UI to maintain case-sensitivity
// to be consistent with this catalog, please apply text case here to translate into UI

const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const defaultErrorMessages = {
  // error codes
  default_client_error_code: 404,
  default_server_error_code: 500,

  // global default messages
  unknown_error: 'Unknown Error',
  page_error: 'Page not found',

  // api errors

  client_error_4xx: `Bad Request`,
  client_error_401: `Unauthorized`,
  client_error_403: 'Forbidden',
  client_error_404: `Not Found`,
  server_error_5xx: `An error occurred`,
  default_api_error: 'An error occurred',

  // code error
  default_code_error: `Something went wrong`,
};

export const commonAPIErrorMessages = {
  userNotFound: 'User not found',

  // Entity error messages
  accessDeniedToEntity: 'Access denied to entity',
  entityNotFound: 'Entity not found',
};

// todo remove
export const artifactErrorMessages = {
  // Custom component error message to give more context to the user
  // Artifacts
  artifact_download: 'Error in downloading file',
  artifact_deleting: 'Error in deleting file',
  artifact_preview: 'Error in loading preview',
};

export const UIValidationErrorMessage = {
  common: {
    empty: (field: string) => `${capitalize(field)} is empty`,
    required: (field?: string) =>
      `${field ? capitalize(field) : 'This field is required'} is required`,
    notSameAs: (field: string) => `Should not be the same as ${field}`,
    email: `Invalid email`,
    notNumber: 'Not a number',
    notPositiveNumber: 'Should be positive number',
    invalidURL: 'Must be valid URL',
    minLength: (min: number) =>
      `Length should be at least ${min} characters long`,
    maxLength: (max: number) =>
      `Length should not be more than ${max} characters long`,
    containAtLeastOne: (symbol: string) =>
      `Should contain at least one ${symbol}`,
    lengthLessOrEqualThen: (n: number, field: string) =>
      `${capitalize(field)} must be less ${n} characters`,
    lengthLessThen: (n: number, field: string) =>
      `Max length of ${field} is ${n} chars`,
    symbols: (symbolNames: string[]) =>
      `Username can only contain ${symbolNames.join(', ')}`,
  },
  // date: {
  //   notDate: 'Not a valid date',
  //   notFuture: 'Should not be a future selection',
  // },
};

export const authErrorMessages = {
  invalidEmailOrPassword: 'Invalid Email or Password.',
  emailAlreadyExists: 'Email already exists.',
  companyAlreadyExists: 'Company already exists.',
  usernameAlreadyExists: 'Username already exists.',
  permissionDenied: 'Permission denied.',
};

export const profileErrorMessages = {
  userNameIsEquals: `User name must be different`,
  usernameAlreadyExists: `Chosen username already exists. Please pick a different username.`,
};

export const deploymentErrorMessages = {
  deploySettings: {
    invalidPath:
      'Path can contain only alphanumerical char, dash or underscore',
    invalidToken:
      'Token can contain only alphanumerical char, dash or underscore',
  },
};
