// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MUIButton from '@mui/material/Button';

import { ButtonContent } from 'shared/view/elements/Button/shared/ButtonContent';
import {
  useButtonCommonProps,
  ButtonCommonProps,
} from 'shared/view/elements/Button/shared/useButtonCommonProps';
import { trackEvent } from 'setup/app/analytics';

export type SubmitButtonProps = ButtonCommonProps & {
  isLoading: boolean;
};

export default function SubmitButton(props: SubmitButtonProps) {
  const { buttonProps, contentProps } = useButtonCommonProps(props);
  return (
    <span>
      <MUIButton
        {...buttonProps}
        type={'submit'}
        onClick={(event) => {
          props.trackEvent && trackEvent(props.trackEvent);
          props.onClick?.(event);
        }}
      >
        <ButtonContent {...contentProps} isLoading={props.isLoading} />
      </MUIButton>
    </span>
  );
}
