import { GraphQLError } from 'graphql';

import { defaultErrorMessages } from 'shared/utils/customErrorMessages';

export type AppError<
  CustomErrorType extends string | undefined = string | undefined,
> = CodeError | AppApolloError | HttpError<CustomErrorType> | CustomError;

export function isAppError(error: Error): error is AppError {
  const names: { [K in AppError['name']]: K } = {
    apiError: 'apiError',
    codeError: 'codeError',
    graphqlError: 'graphqlError',
    customError: 'customError',
  };
  return Boolean('name' in error && Object.keys(names).includes(error.name));
}

export const createCodeErrorFromError = (error: Error): CodeError => {
  const codeError = new CodeError({ message: error.message });
  codeError.stack = error.stack;
  return codeError;
};

export class CustomError extends Error {
  public name: 'customError';

  public constructor(message: string) {
    super(message);
    this.name = 'customError';
  }

  public toString() {
    return this.message;
  }
}

export const createCodeError = (message: string): CodeError =>
  new CodeError({ message });

class CodeError extends Error {
  public name: 'codeError';

  public constructor({ message }: { message: string }) {
    super(message || defaultErrorMessages.unknown_error);
    this.name = 'codeError';
  }

  public toString() {
    return this.message;
  }
}

type AppGraphQLError = {
  extensions?: IGraphqlErrorExtension | null | undefined;
  message: string;
};
interface IGraphqlErrorExtension {
  [string: string]: unknown | undefined;
  'http-code': number | undefined;
}
const isAppApolloError = (error: AppError): error is AppApolloError =>
  error.name === 'graphqlError';
export class AppApolloError extends Error {
  public name: 'graphqlError';
  public errors: [AppGraphQLError, ...AppGraphQLError[]];

  public constructor({
    errors,
  }: {
    errors: [GraphQLError, ...GraphQLError[]];
  }) {
    const errorsWithSanitizedMessages = errors.map((error) => {
      const res: AppGraphQLError = {
        extensions: error.extensions as AppGraphQLError['extensions'],
        message: convertErrors(error.message),
      };
      return res;
    }) as [AppGraphQLError, ...AppGraphQLError[]];
    super(errorsWithSanitizedMessages.map((n) => n.message).join('\n'));
    this.name = 'graphqlError';
    this.errors = errorsWithSanitizedMessages;
  }

  public toString() {
    return this.message;
  }

  public getErrors() {
    return this.errors;
  }
}

const convertErrors = (message: string) => {
  const isOrgError = message
    .toLocaleLowerCase()
    .includes('org id is not specified');

  if (isOrgError) {
    return ERROR_CONVERTER.orgError;
  }

  return message.replace(/GraphQL\s+error:\s*/i, '').trim();
};

const ERROR_CONVERTER = {
  orgError:
    'You are not currently a member of an organization/workspace. Contact help@verta.ai or your Verta contact to be added to one.',
};

export class HttpError<
  CustomApiErrorType extends string | undefined,
> extends Error {
  public name: 'apiError';
  public category: 'clientError' | 'serverError';
  public isMessageUserFriendly?: boolean;

  public type?: CustomApiErrorType;

  public status: number;

  public constructor({
    type,
    status,
    message,
    isMessageUserFriendly = false,
  }: {
    status: number;
    type?: CustomApiErrorType;
    message?: string;
    isMessageUserFriendly?: boolean;
  }) {
    super(message || defaultErrorMessages.unknown_error);
    this.name = 'apiError';
    this.category =
      status >= 400 && status < 500 ? 'clientError' : 'serverError';
    this.type = type;
    this.status = status;
    this.isMessageUserFriendly = isMessageUserFriendly;
  }
}

export function isHttpError<T extends string | undefined = undefined>(
  error: AppError<T> | undefined
): error is HttpError<T> {
  return Boolean(error && 'name' in error && error.name === 'apiError');
}

export const isErrorWithStatus = <T extends Error & { status?: number }>(
  error: T
): error is T & { status: number } =>
  'status' in error && typeof error['status'] === 'number';

export const isGraphqlNotFoundError = (error: AppError) => {
  return (
    isAppApolloError(error) &&
    error.errors.some(({ extensions }) => extensions?.['http-code'] === 404)
  );
};
