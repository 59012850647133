import { Box, Stack, Typography } from '@mui/material';

import TagsField from 'shared/view/formComponents/formikFields/TagsField/TagsField';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import {
  validateMaxLength,
  combineValidators,
  validateNotEmpty,
} from 'shared/utils/validators';
import AutocompleteField from 'shared/view/formComponents/formikFields/AutocompleteField';
import { Button } from 'shared/view/elements/Button';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import InfoIcon from 'shared/view/elements/InfoIcon/InfoIcon';

import { CreateEvaluationProps, getFieldName } from './CreateEvaluation';
import { CreateProjectPopup } from './CreateProject';

type Props = Pick<CreateEvaluationProps, 'evaluationProjects'>;

const FORM_WIDTH = { xxs: '100%', lg: '80%' };

export const EvaluationCreateInputs = ({ evaluationProjects }: Props) => {
  return (
    <Stack direction={'column'} spacing={1}>
      <Box width={FORM_WIDTH}>
        <TextInputField
          name={getFieldName({ name: null })}
          label="Evaluation name"
          isRequired
          withoutLabelPadding
          validate={combineValidators([
            validateNotEmpty('Evaluation name'),
            validateMaxLength(100),
          ])}
        />
      </Box>
      <Box width={FORM_WIDTH}>
        <TextInputField
          name={getFieldName({ description: null })}
          label="Evaluation description"
          validate={validateMaxLength(100)}
          withoutLabelPadding
        />
      </Box>
      <Box width={FORM_WIDTH}>
        <Typography variant="subtitle2" color="text.secondary">
          Choose or create project
        </Typography>
        <Typography variant="body2" mt={1}>
          A project is a collection of evaluations for a given LLM application
          or experiment. Evaluations can be compared if they belong to the same
          project.
        </Typography>
        <Stack direction="row" spacing={2} mt={2}>
          <Box flex={1}>
            <AutocompleteField
              name={getFieldName({ evaluationProjectId: null })}
              label="Project"
              options={evaluationProjects.map((ep) => ({
                ...ep,
                value: ep.id,
                label: ep.name,
              }))}
              isRequired
              validate={validateNotEmpty('Project')}
              creatable={false}
            />
          </Box>
          <ButtonWithPopup
            key={0}
            button={(buttonProps) => (
              <Button
                {...buttonProps}
                isLoading={false}
                sx={{ mt: 1 }}
                variant="text"
              >
                Create Project
              </Button>
            )}
            popup={(popupProps) => <CreateProjectPopup {...popupProps} />}
          />
        </Stack>
      </Box>
      <Stack direction="row" width={FORM_WIDTH} spacing={2}>
        <Box flex={1}>
          <TextInputField
            name={getFieldName({ modelId: null })}
            label="Model/Pipeline Identifier"
            validate={validateMaxLength(100)}
            withoutLabelPadding
          />
        </Box>
        <Box pt="10px">
          <InfoIcon
            color="primary.main"
            tooltip="This refers to the LLM model, 
            pipeline and agents whose output is
            being evaluated. This can be an external 
            URL reference or a reference to a 
            model/pipeline cataloged in Verta."
          />
        </Box>
      </Stack>
      <Stack direction="row" width={FORM_WIDTH} spacing={2}>
        <Box flex={1}>
          <TextInputField
            name={getFieldName({ configuration: null })}
            label="Configuration"
            validate={validateMaxLength(4000)}
            withoutLabelPadding
          />
        </Box>
        <Box pt="10px">
          <InfoIcon
            color="primary.main"
            tooltip="The configuration includes the prompt
            configuration and information about theagents 
            used to generate the output for the evaluation."
          />
        </Box>
      </Stack>
      <Box width={FORM_WIDTH}>
        <Typography variant="subtitle2" color="text.secondary">
          Create labels
        </Typography>
        <Typography variant="body2" mt={1} mb={1}>
          These are configured to used to annotate the particular LLM output
          (for example; “completely correct”, “partially correct but verbose”,
          “incorrect”)
        </Typography>
        <TagsField name={getFieldName({ labels: null })} label="Labels" />
      </Box>
    </Stack>
  );
};
