import { isNil } from 'lodash';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import {
  getBuildWithCanaryRolloutPercentage,
  getEndpointEnvironmentComponentsWithBuilds,
  getEnvironment,
} from 'shared/models/Deployment/canary/Endpoint';
import {
  VersionTitle,
  ModelTitle,
} from 'shared/view/domain/Registry/ModelVersion/VersionTitle/VersionTitle';
import ExperimentRunTitle from 'shared/view/domain/ExperimentRun/ExperimentRunTitle/ExperimentRunTitle';
import BuildStatusWithError from 'shared/view/domain/Deployment/BuildStatusWithError/BuildStatusWithError';
import matchBy from 'shared/utils/matchBy';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import CardLayout from 'shared/view/elements/CardLayout/CardLayout';
import Chip from 'shared/view/elements/Chip/Chip';
import isNotNil from 'shared/utils/isNotNill';
import { formatCanaryRolloutPercentage } from 'shared/view/domain/Deployment/BuildsInfo/BuildsInfoSection/BuildsInfo';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { RequiredComponentWithBuild } from 'shared/view/domain/Deployment/BuildsInfo/BuildsInfoSection/BuildInfo';

import {
  Endpoint,
  ICanaryStrategy,
} from '../../store/endpointOverview/endpointOverview';
import { StyledTableCell } from './EndpointOverview';

const HandleTrafficIcon = ({
  index,
  componentsWithBuilds,
}: {
  index: number;
  componentsWithBuilds: RequiredComponentWithBuild[];
}) => {
  const { palette } = useTheme();
  if (componentsWithBuilds.length > 1) {
    return (
      <IconAwesomeCustomizable
        nativeProps={{
          color: palette.primary.main,
        }}
        icon={index === 0 ? ICONS.arrowDown : ICONS.arrowUp}
      />
    );
  }
  return null;
};

const HandleTrafficCell = ({
  componentsWithBuilds,
  canaryStrategy,
}: {
  componentsWithBuilds: RequiredComponentWithBuild[];
  canaryStrategy?: ICanaryStrategy | null;
}) => {
  if (componentsWithBuilds.length > 1) {
    return (
      <StyledTableCell>
        {isNil(canaryStrategy) ? 'Direct' : 'Canary'} Roll Out(traffic)
      </StyledTableCell>
    );
  }
  return <StyledTableCell>Traffic</StyledTableCell>;
};

export const BuildsInfo = ({
  endpoint,
  canaryStrategy,
}: {
  endpoint: Endpoint;
  canaryStrategy?: ICanaryStrategy | null;
}) => {
  const componentsWithBuilds = getEndpointEnvironmentComponentsWithBuilds(
    endpoint
  ).map((c) => ({
    ...c,
    build: getBuildWithCanaryRolloutPercentage(
      c.build,
      getEnvironment(endpoint)?.rolloutStatus
    ),
  }));

  const organizationId = useCurrentOrganizationV2();
  return (
    <TableContainer component={CardLayout}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Model</StyledTableCell>
            <StyledTableCell>Version</StyledTableCell>
            <StyledTableCell>Version stage</StyledTableCell>
            <StyledTableCell>Build ID</StyledTableCell>
            <HandleTrafficCell
              componentsWithBuilds={componentsWithBuilds}
              canaryStrategy={canaryStrategy}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {componentsWithBuilds.map((component, i) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              key={i}
            >
              <TableCell scope="row">
                {component.build.source &&
                  matchBy(
                    component.build.source,
                    '__typename'
                  )({
                    Error: () => null,
                    // TODO test with experimentRun
                    ExperimentRun: (experimentRun) => (
                      <ExperimentRunTitle experimentRun={experimentRun} />
                    ),
                    RegisteredModelVersion: (modelVersion) => (
                      <ModelTitle
                        id={modelVersion.id}
                        version={modelVersion.version}
                        registeredModel={modelVersion.registeredModel}
                        withRegisteredModelName
                      />
                    ),
                  })}
              </TableCell>
              <TableCell>
                {component.build.source &&
                  matchBy(
                    component.build.source,
                    '__typename'
                  )({
                    Error: () => null,
                    // TODO test with experimentRun
                    ExperimentRun: (experimentRun) => (
                      <ExperimentRunTitle experimentRun={experimentRun} />
                    ),
                    RegisteredModelVersion: (modelVersion) => (
                      <VersionTitle
                        id={modelVersion.id}
                        version={modelVersion.version}
                        registeredModel={modelVersion.registeredModel}
                        withRegisteredModelName
                      />
                    ),
                  })}
              </TableCell>
              <TableCell>
                {component.build.source &&
                  matchBy(
                    component.build.source,
                    '__typename'
                  )({
                    Error: () => null,
                    // TODO test with experimentRun
                    ExperimentRun: (experimentRun) => (
                      <ExperimentRunTitle experimentRun={experimentRun} />
                    ),
                    RegisteredModelVersion: (modelVersion) => {
                      const version =
                        modelVersion.registeredModel.versions.versions.filter(
                          (item) => item.version === modelVersion.version
                        );
                      return (
                        <Chip
                          label={version[0].stage}
                          color="success"
                          size="small"
                        />
                      );
                    },
                  })}
              </TableCell>
              <TableCell>
                <BuildStatusWithError
                  organizationId={organizationId}
                  data={{
                    build: component.build,
                    status: component.status,
                    endpointId: endpoint.id,
                  }}
                  workspaceName={endpoint.workspace.name}
                />
              </TableCell>
              <TableCell>
                <Stack direction="row" alignItems="center">
                  <HandleTrafficIcon
                    index={i}
                    componentsWithBuilds={componentsWithBuilds}
                  />
                  <Typography variant="body2">
                    {isNotNil(component.build.canaryRolloutPercentage)
                      ? formatCanaryRolloutPercentage(
                          component.build.canaryRolloutPercentage
                        )
                      : null}
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
