import { Wizard, WizardStepProps } from 'shared/view/elements/Wizard';

import * as slides from './slides';

const steps: WizardStepProps[] = [
  {
    stepLabel: { icon: '1', text: 'Create custom attributes' },
    slides: [
      slides.CustomAttrModel,
      slides.CustomAttrPage,
      slides.CustomAttrList,
    ],
  },
  {
    stepLabel: { icon: '2', text: 'Edit model or version attribute' },
    slides: [
      slides.CustomAttrEditOrg,
      slides.CustomAttrEditApi,
      slides.CustomAttrDashboard,
    ],
  },
  {
    stepLabel: false,
    slides: [slides.CreateCustomAttribute],
  },
];

export const CustomAttributes = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  return <Wizard steps={steps} onCompleted={onCompleted} />;
};
