import { ScaleBand, ScaleLinear } from 'd3-scale';

import { getValue, getKey, BaseBarChartData } from '../BarChart/utils/model';

export interface BarSettings {
  width: number;
  height: number;
  x: number;
  y: number;
}

export interface GetBarSettingsArguments {
  d: BaseBarChartData;
  xScale: ScaleBand<string>;
  yScale: ScaleLinear<number, number>;
  innerHeight: number;
}

export function getBarSettings({
  d,
  xScale,
  yScale,
  innerHeight,
}: GetBarSettingsArguments): BarSettings {
  const width = xScale.bandwidth();
  // todo probably legacy code and can produce malfunctions. Need to be checked and fixed;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const height = innerHeight - (yScale(getValue(d)) ?? 0);
  const x = xScale(getKey(d)) ?? 0;
  const y = innerHeight - height;
  return {
    width,
    height,
    x,
    y,
  };
}
