import React, { useCallback } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { Bar, Line } from '@visx/shape';
import { ScaleLinear, ScaleOrdinal } from 'd3-scale';
import { bisector } from 'd3-array';

import { IObservationWithEpoch } from 'shared/models/Observation';
import { ChartSizes } from 'shared/utils/charts/chartSizes';
import isNotNil from 'shared/utils/isNotNill';
import CustomTooltipWithBounds from 'shared/view/charts/shared/CustomTooltipWithBound/CustomTooltipWithBound';
import { getArrayItem } from 'shared/utils/collection';
import { useChartSizeScales } from 'shared/utils/charts/sizeScale/useChartSizeScales';

import {
  getObservationTimestamp,
  getObservationEpochNumber,
  getObservationValue,
} from './accessors';
import { matchObservationXAxisType } from './axisType';
import {
  IObservationChartXAxisSettings,
  matchObservationChartAxisSettings,
  ObservationChartXAxisType,
} from './settings';
import { ChartObservation } from './types';

interface IChartData {
  observations: ChartObservation[];
  observationsWithEpoch: Array<IObservationWithEpoch<ChartObservation>>;
}

const bisectDate = bisector<ChartObservation, Date>(
  getObservationTimestamp
).left;
const bisectEpoch = bisector<IObservationWithEpoch<ChartObservation>, number>(
  (d) => d.epochNumber
).left;

type TooltipData = Pick<ChartObservation, 'timestamp' | 'epochNumber'>;
interface ICoordinates {
  tooltipTop: number;
  tooltipLeft: number;
}

export const useObservationChartTooltip = ({
  chartSizes,
  chartData,
  valueScale,
  renderTooltipContent,
  colorScale,
  xAxisSettings,
}: {
  chartSizes: ChartSizes;
  chartData: IChartData;
  valueScale: ScaleLinear<number, number>;
  colorScale: ScaleOrdinal<string, string>;
  renderTooltipContent: (observations: ChartObservation[]) => React.ReactNode;
  xAxisSettings: IObservationChartXAxisSettings;
}) => {
  const { showTooltip, hideTooltip, tooltipData } = useTooltip<TooltipData>();

  const { scaleChartX } = useChartSizeScales({ chartSizes });

  const handleTooltip = useCallback(
    (
      event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>
    ) => {
      const { x } = localPoint(event) || { x: 0 };
      const scaledX = scaleChartX(x);

      const data: IObservationWithEpoch<ChartObservation> | ChartObservation =
        matchObservationChartAxisSettings(
          {
            timestamp: ({ scale }) => {
              const x0 = scale.invert(scaledX);
              const index = bisectDate(chartData.observations, x0, 1);
              const d0 = chartData.observations[index - 1];

              const d1 = getArrayItem(index, chartData.observations);
              if (d1) {
                return x0.valueOf() - getObservationTimestamp(d0).valueOf() >
                  getObservationTimestamp(d1).valueOf() - x0.valueOf()
                  ? d1
                  : d0;
              }
              return d0;
            },
            epochNumber: ({ scale }) => {
              const x0 = scale.invert(scaledX);
              const index = bisectEpoch(chartData.observationsWithEpoch, x0, 1);
              const d0 = chartData.observationsWithEpoch[index - 1];
              const d1 = getArrayItem(index, chartData.observationsWithEpoch);

              if (d1 && getObservationEpochNumber(d1)) {
                return x0 - getObservationEpochNumber(d0) >
                  getObservationEpochNumber(d1) - x0
                  ? d1
                  : d0;
              }
              return d0;
            },
          },
          xAxisSettings
        );

      showTooltip({
        tooltipData: {
          epochNumber: data.epochNumber,
          timestamp: data.timestamp,
        },
      });
    },
    [
      scaleChartX,
      xAxisSettings,
      showTooltip,
      chartData.observations,
      chartData.observationsWithEpoch,
    ]
  );

  const observations = getObservationsByTooltipData({
    xAxisType: xAxisSettings.type,
    chartData,
    tooltipData,
  });

  const coordinates = getCoordinatesByObservation({
    observations,
    xAxisSettings,
    valueScale,
  });

  const handlingSVGBar = (
    <Bar
      width={chartSizes.width}
      height={chartSizes.height}
      fill="transparent"
      rx={14}
      onTouchStart={handleTooltip}
      onTouchMove={handleTooltip}
      onMouseMove={handleTooltip}
      onMouseLeave={() => hideTooltip()}
    />
  );

  const tooltipSVGLine = coordinates &&
    observations &&
    observations.length !== 0 && (
      <g>
        <Line
          from={{ x: coordinates.tooltipLeft, y: 0 }}
          to={{ x: coordinates.tooltipLeft, y: chartSizes.innerHeight }}
          stroke={colorScale(observations[0].experimentRun.id)}
          strokeWidth={1.5}
          pointerEvents="none"
          strokeDasharray="1.5 1.5"
        />
        {observations.map((obs) => (
          <circle
            key={obs.experimentRun.id}
            cx={coordinates.tooltipLeft}
            cy={valueScale(getObservationValue(obs))}
            r={3.5}
            fill={colorScale(obs.experimentRun.id)}
            pointerEvents="none"
          />
        ))}
      </g>
    );

  const tooltip = coordinates && observations && observations.length !== 0 && (
    <div>
      <CustomTooltipWithBounds
        key={Math.random()}
        top={10}
        left={coordinates.tooltipLeft + 50}
      >
        {renderTooltipContent(observations)}
      </CustomTooltipWithBounds>
    </div>
  );

  return {
    tooltip,
    tooltipSVGLine,
    handlingSVGBar,
  };
};

const getObservationsByTooltipData = ({
  tooltipData,
  chartData,
  xAxisType,
}: {
  tooltipData: TooltipData | undefined;
  chartData: IChartData;
  xAxisType: ObservationChartXAxisType;
}): ChartObservation[] | undefined => {
  if (!tooltipData) {
    return undefined;
  }

  return matchObservationXAxisType(
    {
      timestamp: () =>
        chartData.observations.filter(
          (d) => d.timestamp.valueOf() === tooltipData.timestamp.valueOf()
        ),
      epochNumber: () =>
        chartData.observationsWithEpoch.filter(
          (d) => d.epochNumber === tooltipData.epochNumber
        ),
    },
    xAxisType
  );
};

const getCoordinatesByObservation = ({
  observations,
  valueScale,
  xAxisSettings,
}: {
  observations: ChartObservation[] | undefined;
  xAxisSettings: IObservationChartXAxisSettings;
  valueScale: ScaleLinear<number, number>;
}) => {
  if (!observations || observations.length === 0) {
    return undefined;
  }

  const observation = observations[0];

  const tooltipTop = valueScale(getObservationValue(observation));

  const coordinates: ICoordinates | undefined =
    matchObservationChartAxisSettings(
      {
        timestamp: ({ scale }) => {
          const tooltipLeft = scale(getObservationTimestamp(observation));
          return {
            tooltipLeft,
            tooltipTop,
          };
        },
        epochNumber: ({ scale }) => {
          const epochNumber = getObservationEpochNumber(observation);
          return isNotNil(epochNumber)
            ? {
                tooltipLeft: scale(epochNumber) || 0,
                tooltipTop,
              }
            : undefined;
        },
      },
      xAxisSettings
    );

  return coordinates;
};
