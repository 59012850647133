import { Box, BoxProps, Theme, styled } from '@mui/material';

import { OmitStrict } from 'shared/utils/types';
import { transientOptions } from 'shared/utils/transientOptions';
import CopyButton from 'shared/view/elements/CopyButton/CopyButton';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import {
  SyntaxHighlighter,
  SyntaxHighlighterProps,
} from '../SyntaxHighlighter';

// ts-unused-exports:disable-next-line
export type CodeBlockProps = {
  freeSolo?: boolean;
  withCopy?: boolean;
  disabledCopy?: boolean;
  style?: React.CSSProperties;
  language?: SyntaxHighlighterProps['language'];
  children: SyntaxHighlighterProps['children'];
} & OmitStrict<BoxProps, 'children'>;

export const getCodeBlockWrapperStyle = (theme: Theme) => ({
  background: `${theme.palette.additionalBackground.codeBlock} !important`,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  fontFamily: 'Courier',
  lineHeight: '1.2em',
  fontSize: '0.9rem',
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  height: '100%',
});

export const getCodeBlockStyle = (theme: Theme) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Courier',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '22px',
  wordBreak: 'break-word !important',
  overflowWrap: 'break-word',
  whiteSpace: 'pre-wrap',
});

const StyledWrapper = styled(
  (props: BoxProps & { $isFreeSolo: boolean }) => <Box {...props} />,
  transientOptions
)(({ theme, $isFreeSolo }) => {
  return $isFreeSolo
    ? {}
    : {
        maxHeight: '300px',
        position: 'relative',
        flexGrow: 1,
        '& pre': getCodeBlockWrapperStyle(theme),
        '& code': getCodeBlockStyle(theme),
      };
});

// ts-unused-exports:disable-next-line
export const CodeBlock = (props: CodeBlockProps) => {
  const {
    freeSolo,
    withCopy,
    children,
    disabledCopy,
    style,
    language = 'bash',
    ...rest
  } = props;

  const customStyle: React.CSSProperties = freeSolo
    ? style || {}
    : {
        margin: 0,
        padding: '8px 16px',
        color: 'inhert',
        ...style,
      };

  return (
    <StyledWrapper $isFreeSolo={Boolean(freeSolo)} {...rest}>
      <Box sx={{ overflow: 'auto', height: '100%' }}>
        <SyntaxHighlighter language={language} style={customStyle}>
          {children}
        </SyntaxHighlighter>
        {withCopy ? (
          <Box
            sx={{
              position: 'absolute',
              top: '9px',
              right: '9px',
              padding: '8px',
              backgroundColor: 'additionalBackground.codeBlock',
            }}
          >
            <CopyButton
              icon={ICONS.clone}
              disabled={disabledCopy ?? false}
              copyText={children}
            />
          </Box>
        ) : null}
      </Box>
    </StyledWrapper>
  );
};

export default CodeBlock;
