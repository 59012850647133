import { MutationHookOptions, gql } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { CUSTOM_ATTRIBUTE_VALUE_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeValue';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';

import {
  UpdateModelVersion,
  UpdateModelVersionVariables,
} from './graphql-types/useUpdateModelVersion.generated';

const UPDATE_MODEL_VERSION = gql`
  mutation UpdateModelVersion($id: ID!, $fields: RegisteredModelVersionInput) {
    registeredModelVersion(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        updateRegisteredModelVersion(fields: $fields) {
          id
          inputDescription
          hideInputLabel
          outputDescription
          hideOutputLabel
          version
          description
          owner {
            ...OwnerData
          }
          customAttributeValues {
            id
            ...CustomAttributeValueData
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_VALUE_FRAGMENT}
`;

export const useUpdateModelVersion = (
  options?: MutationHookOptions<UpdateModelVersion, UpdateModelVersionVariables>
) =>
  useCustomMutation<UpdateModelVersion, UpdateModelVersionVariables>(
    UPDATE_MODEL_VERSION,
    { context: 'updating model version' },
    { ...options }
  );
