import { RecordFromUnion } from 'shared/utils/types';

import { DataSpec, dateUpdatedSpec, DateUpdatedSpec } from '../DataSpec';
import { KeyValueSpec } from '../KeyValueSpec';
import { AggregationType } from './shared/Aggregation';
import { CommonWidgetSettings } from './shared/CommonWidgetSettings';
import { CustomDomain } from './shared/CustomDomain';

export type BarChartXAxisDataSpec = DataSpec;
export type BarChartYAxisDataSpec = KeyValueSpec;

export interface BarChartSettings extends CommonWidgetSettings<'barChart'> {
  xAxis: {
    spec: BarChartXAxisDataSpec;
  };
  yAxis: {
    specs: [BarChartYAxisDataSpec, ...BarChartYAxisDataSpec[]];
    domain: CustomDomain;
    aggregationType: AggregationType | undefined;
  };
}

export interface BarChartAxisesDataSpec {
  xAxis: RecordFromUnion<
    BarChartXAxisDataSpec['type'],
    {
      dateUpdated: DateUpdatedSpec;
      keyValue: Array<Extract<BarChartXAxisDataSpec, { type: 'keyValue' }>>;
    }
  >;
  yAxis: BarChartYAxisDataSpec[];
}

export const getBarChartAxisesDataSpecs = (
  keyValueSpecs: KeyValueSpec[]
): BarChartAxisesDataSpec => {
  return {
    xAxis: {
      dateUpdated: dateUpdatedSpec,
      keyValue: keyValueSpecs,
    },
    yAxis: keyValueSpecs,
  };
};
