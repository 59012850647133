import ActionsMenu, { MenuAction } from '../ActionsMenu/ActionsMenu';
import { IconAwesomeClickable } from '../IconAwesome/IconAwesomeClickable';
import { ICONS } from '../IconAwesome/ICONS';

interface Props {
  actions: MenuAction[];
}

const PageSettingsIcon = (props: Props) => {
  return (
    <ActionsMenu
      actions={props.actions}
      renderAnchor={(onClick) => (
        <IconAwesomeClickable
          icon={ICONS.gear}
          onClick={onClick}
          size="lg"
          dataTest="open-settings"
        />
      )}
    />
  );
};

export default PageSettingsIcon;
