import { useCallback } from 'react';

import { useChangeAlertStatus } from 'features/monitoring/alerts/store/changeAlertStatus/useChangeAlertStatus';
import { AlertStatus } from 'generated/types';
import { MonitoringAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { useDeleteAlert } from 'features/monitoring/alerts/store/deleteAlert/useDeleteAlert';
import { useToast } from 'features/toast/store/hooks';
import { MenuAction } from 'shared/view/elements/ActionsMenu/ActionsMenu';
import { ConfirmType } from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';
import routes from 'shared/routes';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { useUpdateAlert } from 'features/monitoring/alerts/store/updateAlert/useUpdateAlert';
import Popup from 'shared/view/elements/Popup/Popup';
import CellRendererActions from 'shared/view/elements/DataGrid/columns/CellRendererActions';
import { MonitoredModelType } from 'shared/models/Monitoring/MonitoringModel/MonitoredModelType';

import { MonitoringAlertFormType } from '../../../shared/MonitoringAlertForm/shared/MonitoringAlertFormType';
import { UpdateAlertForm } from '../../../shared/UpdateAlertAction/UpdateAlertAction';

interface Props {
  workspaceName: string;
  alert: MonitoringAlert;
  monitoringModelId: string;
  monitoredModelType: MonitoredModelType;
  ioDescriptions: MonitoringIODescription[];
  allowedActions: { update: boolean };
}

const MonitoringAlertsTableActions = (props: Props) => {
  const updateAlertAction = useUpdateAlertAction({
    alert: props.alert,
    allowedActions: props.allowedActions,
    ioDescriptions: props.ioDescriptions,
    monitoredModelType: props.monitoredModelType,
  });

  const changeAlertStatusAction = useChangeAlertStatusAction({
    alert: props.alert,
  });

  const deleteAlertAction = useDeleteAlertAction({
    alert: props.alert,
    monitoringModelId: props.monitoringModelId,
    workspaceName: props.workspaceName,
  });

  const historyAlertAction = useHistoryAlertAction({
    alert: props.alert,
    monitoringModelId: props.monitoringModelId,
    workspaceName: props.workspaceName,
  });

  return (
    <CellRendererActions
      actions={[
        updateAlertAction,
        changeAlertStatusAction,
        deleteAlertAction,
        historyAlertAction,
      ]}
    />
  );
};

const makeOnCompletedMessage = ({
  actionDescription,
  alertName,
}: {
  alertName: string;
  actionDescription: string;
}) => `Alert "${alertName}" successfully ${actionDescription}`;

const useUpdateAlertAction = (props: {
  alert: MonitoringAlert;
  monitoredModelType: MonitoredModelType;
  ioDescriptions: MonitoringIODescription[];
  allowedActions: { update: boolean };
}): MenuAction => {
  const { closePopup, isPopupOpen, openPopup } = usePopupManager();

  const toast = useToast();

  const onCompleted = useCallback(() => {
    closePopup();
    toast('Alert updated', 'success');
  }, [closePopup, toast]);

  const { updateAlert, updatingAlert } = useUpdateAlert({
    onCompleted,
  });

  const onSubmit = useCallback(
    (form: MonitoringAlertFormType) => {
      updateAlert({
        alert: form,
        alertId: props.alert.id,
      });
    },
    [props.alert.id, updateAlert]
  );

  return {
    type: 'button',
    onClick: openPopup,
    label: 'Update',
    disabled: !props.allowedActions.update,
    additionalContent: (
      <Popup
        title="Edit alert rule"
        isOpen={isPopupOpen}
        onClose={closePopup}
        maxWidth={false}
      >
        <UpdateAlertForm
          alert={props.alert}
          monitoredModelType={props.monitoredModelType}
          isLoading={updatingAlert.isRequesting}
          onSubmit={onSubmit}
          ioDescriptions={props.ioDescriptions}
        />
      </Popup>
    ),
    isLoading: updatingAlert.isRequesting,
  };
};

const useDeleteAlertAction = (props: {
  alert: MonitoringAlert;
  monitoringModelId: string;
  workspaceName: string;
}): MenuAction => {
  const toast = useToast();
  const onCompleted = useCallback(
    () =>
      toast(
        makeOnCompletedMessage({
          alertName: props.alert.name,
          actionDescription: 'deleted',
        }),
        'success'
      ),
    [props.alert.name, toast]
  );
  const { communication, mutate } = useDeleteAlert({ onCompleted });

  const onDeleteAlert = useCallback(
    () =>
      mutate({
        alertId: props.alert.id,
      }),
    [mutate, props.alert.id]
  );

  return {
    type: 'button',
    onClick: onDeleteAlert,
    label: 'Delete',
    isLoading: communication.isRequesting,
    confirmAction: {
      description: 'Alert',
      type: ConfirmType.delete,
    },
  };
};

const useChangeAlertStatusAction = (props: {
  alert: MonitoringAlert;
}): MenuAction => {
  const toast = useToast();
  const onCompleted = useCallback(
    () =>
      toast(
        makeOnCompletedMessage({
          alertName: props.alert.name,
          actionDescription: 'updated',
        }),
        'success'
      ),
    [props.alert.name, toast]
  );

  const { communication, mutate } = useChangeAlertStatus({
    onCompleted,
  });

  const onClick = useCallback(
    () =>
      mutate({
        alertId: props.alert.id,
        status:
          props.alert.status === AlertStatus.PAUSED
            ? AlertStatus.OK
            : AlertStatus.PAUSED,
      }),
    [mutate, props.alert.id, props.alert.status]
  );

  return {
    type: 'button',
    label: props.alert.status === AlertStatus.PAUSED ? 'Resume' : 'Pause',
    onClick,
    isLoading: communication.isRequesting,
  };
};

const useHistoryAlertAction = (props: {
  alert: MonitoringAlert;
  monitoringModelId: string;
  workspaceName: string;
}): MenuAction => {
  return {
    type: 'link',
    label: 'History',
    to: routes.monitoringAlertHistory.getRedirectPath({
      alertId: props.alert.id,
      monitoringModelId: props.monitoringModelId,
      workspaceName: props.workspaceName,
    }),
  };
};

export default MonitoringAlertsTableActions;
