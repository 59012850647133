import { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { keys } from 'ramda';
import { isEmpty } from 'lodash';

import { EndpointKafkaClusterFields } from 'features/deployment/canary/endpointSettings/view/EndpointKafkaClusterFields';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import SwitchField from 'shared/view/formComponents/formikFields/SwitchField/SwitchField';

import { kafkaConfigurations as KafkaConfigurations } from '../hooks/graphql-types/useKafkaConfigurationTopics.generated';
import {
  EndpointKafkaChangeFieldKey,
  EndpointKafkaChangeFieldValue,
  EndpointKafkaFormValues,
} from '../store/types';
import {
  getEndpointKafkaFieldName,
  getEndpointKafkaFormErrors,
} from '../store/helpers';

type Props = {
  initialValues: EndpointKafkaFormValues;
  kafkaConfigurations: KafkaConfigurations;
};

export default function EndpointKafkaFormFields({
  initialValues,
  kafkaConfigurations,
}: Props) {
  const {
    isKafkaEnabled,
    hideOrShowFields,
    changeField,
    values,
    resetKafkaFields,
  } = useFormFields({
    initialValues,
  });

  return (
    <FormStack>
      <SwitchField
        label="Enable Kafka Integration"
        name="isKafkaEnabled"
        iconSize="lg"
        checked={isKafkaEnabled}
        onClick={hideOrShowFields}
        info={`Turn on Kafka for asynchronous model. 
          Enabling it for your endpoint, model will be 
          able to take inputs from a Kafka topic and
          push outputs to a Kafka topic.`}
      />
      {isKafkaEnabled &&
      !isEmpty(kafkaConfigurations.kafkaConfigurations.configurations) ? (
        <EndpointKafkaClusterFields
          changeField={changeField}
          values={values}
          kafkaConfigurations={kafkaConfigurations}
          resetKafkaFields={resetKafkaFields}
        />
      ) : null}
    </FormStack>
  );
}

function useFormFields({ initialValues }: Pick<Props, 'initialValues'>) {
  const { setFieldValue, setFieldTouched, validateForm, values, setErrors } =
    useFormikContext<EndpointKafkaFormValues>();

  useEffect(() => {
    setErrors(getEndpointKafkaFormErrors(values));
  }, [setErrors, values]);

  const [isKafkaEnabled, setIsKafkaEnabled] = useState(
    Boolean(initialValues.kafkaReq?.disabled === false) || false
  );

  const changeField = useCallback(
    (
      fieldName: EndpointKafkaChangeFieldKey,
      value: EndpointKafkaChangeFieldValue
    ) => {
      setFieldValue(getEndpointKafkaFieldName(fieldName), value);
      setFieldTouched(getEndpointKafkaFieldName(fieldName), true);
      // we have to mark hidden fields as not touched and validate form otherwise the fields errors will affects to the form even if they are not displayed
      setTimeout(() => {
        validateForm();
      });
    },
    [setFieldValue, setFieldTouched, validateForm]
  );

  const disableKafkaForm = useCallback(() => {
    // for create we cleanup everything, for update -> just disable it
    setFieldValue(
      'kafkaReq',
      values.kafkaReq
        ? {
            ...values.kafkaReq,
            disabled: true,
          }
        : undefined
    );
  }, [setFieldValue, values.kafkaReq]);

  const resetKafkaFields = useCallback(
    (fieldsToIgnore: EndpointKafkaChangeFieldKey[]) => {
      const initial = initialValues.kafkaReq;
      if (initial && values.kafkaReq) {
        keys(values.kafkaReq)
          .filter((key) => !fieldsToIgnore.includes(key))
          .forEach((key) => {
            setFieldValue(getEndpointKafkaFieldName(key), initial[key]);
            setFieldTouched(getEndpointKafkaFieldName(key), false);
          });
      }
    },
    [initialValues, setFieldTouched, setFieldValue, values]
  );

  const hideOrShowFields = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLFormElement;
    setIsKafkaEnabled(target.checked);
    changeField('disabled', !target.checked);
    if (!target.checked) {
      disableKafkaForm();
    }
    // we have to mark hidden fields as not touched and validate form otherwise the fields errors will affects to the form even if they are not displayed
    setTimeout(() => {
      validateForm();
    });
  };

  return {
    isKafkaEnabled,
    hideOrShowFields,
    changeField,
    values,
    resetKafkaFields,
  };
}
