import { Stack, Typography } from '@mui/material';

import routes from 'shared/routes';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import CellRendererLink from 'shared/view/elements/DataGrid/columns/CellRendererLink';
import { Model } from 'features/dashboard/hooks/useCustomDashboardTopModelsWithAttrs';
import { RenderCellParams } from 'shared/view/elements/DataGrid/renderCell/RenderCellParams';

import { TypeChip } from '../../shared/HandleRiskLevelChip';

export enum RiskLevelType {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low risk',
}

export const getCellValue = (params: RenderCellParams<Model>) => {
  const customAttrValue = params.row.customAttributeValues.find(
    (attr) => attr.customAttributeData?.name === params.field
  );
  return customAttrValue
    ? customAttrValue.customAttributeData?.customAttributeListOptions?.find(
        (option) => option.id === customAttrValue.selectedValue
      )?.name
    : '';
};

export const getCellNumber = (params: RenderCellParams<Model>) => {
  const customAttrValue = params.row.customAttributeValues.find(
    (attr) => attr.customAttributeData?.name === params.field
  );

  if (customAttrValue) {
    const formatter = Intl.NumberFormat('en', { notation: 'compact' });
    return formatter.format(+customAttrValue.selectedValue);
  }

  return '';
};

export const TopModelsTable = ({ data }: { data: Model[] }) => {
  const columns: DataGridColumn<Model>[] = [
    {
      field: 'id',
      headerName: '',
      filterable: false,
      minWidth: 50,
      width: 50,
      align: 'right',
      renderCell: (params) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      field: 'Model',
      sortable: false,
      flex: 1,
      minWidth: 240,
      renderCell: (params) => {
        return (
          <CellRendererLink
            to={routes.modelOverview.getRedirectPath({
              registeredModelId: params.row.id,
              workspaceName: params.row.workspace.name,
            })}
            label={params.row.name}
            dataTest="path"
          />
        );
      },
    },
    {
      field: 'Business Unit',
      sortable: false,
      minWidth: 156,
      renderCell: (params) => (
        <CellRendererString value={getCellValue(params)} />
      ),
    },
    {
      field: 'Business Value Delivered',
      headerName: 'Business Value',
      sortable: false,
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return <CellRendererString value={getCellNumber(params)} />;
      },
    },
    {
      field: 'Risk Level',
      sortable: false,
      flex: 1,
      minWidth: 30,
      renderCell: (params) => <TypeChip riskLevel={getCellValue(params)} />,
    },
    {
      field: 'Model Predictions',
      headerName: 'Prediction Count',
      sortable: false,
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <CellRendererString value={getCellNumber(params)} />
      ),
    },
  ];

  return (
    <Stack spacing={0}>
      <Typography variant="h6">Top 10 models</Typography>
      <Typography variant="body1" color="text.secondary" mb={3}>
        Sorted by Business Value
      </Typography>
      <DataGridWithTypes
        rows={data}
        heightType="parentHeight"
        customRowHeight={52}
        density="standard"
        columns={columns}
        withFooter={false}
      />
    </Stack>
  );
};
