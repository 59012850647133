import { useCallback, useState } from 'react';

export const usePopoverManager = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openPopover = useCallback(
    (e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget),
    []
  );

  const closePopover = useCallback(() => setAnchorEl(null), []);

  const isOpen = Boolean(anchorEl);

  return {
    isOpen,
    anchorEl,
    openPopover,
    closePopover,
  };
};
