import {
  Box,
  BoxProps,
  styled,
  Typography,
  Card as MuiCard,
} from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import pluralize from 'pluralize';

import { transientOptions } from 'shared/utils/transientOptions';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { Button } from 'shared/view/elements/Button';

const IconWrapper = styled(
  ({
    icon,
    isDisabled,
    ...props
  }: BoxProps & { icon: IconDefinition; isDisabled?: boolean }) => (
    <Box {...props}>
      <IconAwesomeInfo
        icon={icon}
        size="xl"
        color={isDisabled ? 'text.disabled' : 'primary.main'}
      />
    </Box>
  ),
  transientOptions
)(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: theme.palette.action.hover,
  padding: '12px',
  width: 'fit-content',
  display: 'flex',
}));

const Card = styled(MuiCard)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

type Props = {
  total: number;
  done: number;
  startEvaluation: () => void;
};

export const Counter = (props: Props) => {
  const { total, done, startEvaluation } = props;
  return (
    <Card variant="outlined">
      <Box display="flex" justifyContent="space-between">
        <IconWrapper icon={ICONS.tableRows} />
        <Button onClick={startEvaluation} isLoading={false} variant="outlined">
          Start evaluation
        </Button>
      </Box>
      <Box flex={1} display="flex" flexDirection="column" justifyContent="end">
        <Typography variant="h2">{done}</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {`Completed ${pluralize('evaluations', done, false)}`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          out of <strong>{total}</strong>{' '}
          {pluralize('data points', total, false)}
        </Typography>
      </Box>
    </Card>
  );
};
