import { WorkspaceV2Input } from 'generated/types';

import { OrganizationWorkspacesV2 } from '../store/useOrganizationWorkspaces';
import { useUpdateWorkspace } from '../store/useUpdateWorkspace';
import WorkspacePopup from './WorkspacePopup';

const EditWorkspacePopup = (props: {
  data: Pick<OrganizationWorkspacesV2, 'id' | 'groups' | 'roles'>;
  workspace: Pick<
    OrganizationWorkspacesV2['workspaces'][0],
    'id' | 'description' | 'name' | 'permissions' | 'namespace'
  >;
  onClose(): void;
}) => {
  const api = useUpdateWorkspace(props.onClose);
  return (
    <WorkspacePopup
      groups={props.data.groups}
      roles={props.data.roles}
      settings={{
        description: props.workspace.description,
        name: props.workspace.name,
        namespace: props.workspace.namespace || '',
        permissions:
          props.workspace.permissions.length > 0
            ? props.workspace.permissions.map(({ group, role }) => ({
                groupId: group.id,
                roleId: role.id,
              }))
            : [
                {
                  groupId: undefined,
                  roleId: undefined,
                },
              ],
      }}
      submitButton={{
        isLoading: api.communication.isRequesting,
        children: 'Update',
        onSubmit: (form) => {
          api.run({
            organizationId: props.data.id,
            workspaceId: props.workspace.id,
            input: form as WorkspaceV2Input,
          });
        },
      }}
      title="Edit workspace"
      onClose={props.onClose}
    />
  );
};

export default EditWorkspacePopup;
