import { useState } from 'react';

export function useExpanded() {
  const [expandedId, setExpandedId] = useState('');

  const handleExpandById = (panel: string) => (isExpanded: boolean) => {
    setExpandedId(isExpanded ? panel : '');
  };

  return {
    expandedId,
    handleExpandById,
  };
}
