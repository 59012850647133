/* eslint-disable rulesdir/no-deprecated-fields */
import { useEffect } from 'react';
import { groupBy, pipe } from 'ramda';
import { gql } from '@apollo/client';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';
import { mapObj } from 'shared/utils/collection';
import isNotNil from 'shared/utils/isNotNill';

import * as Types from './graphql-types/useWorkspaceEndpoints.generated';

export const useWorkspaceEndpoints = ({
  workspaceName,
  organizationId,
}: Types.EndpointsVariables) => {
  const [load, queryResult] = useCustomLazyQuery<
    Types.Endpoints,
    Types.EndpointsVariables
  >(QUERY);

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert,
    queryResult,
  });

  useEffect(() => {
    load({ variables: { workspaceName, organizationId } });
  }, [load, workspaceName, organizationId]);

  return {
    data,
    communication,
    load,
  };
};

const memoizedConvert = (endpoint: Types.Endpoints) =>
  mapDataOrError(endpoint.workspace, (workspaceData) => {
    const {
      endpoints: { endpoints },
    } = workspaceData;

    const registeredModelIdsWithEndpointIds = endpoints.flatMap(
      ({ id, environments }) =>
        environments.flatMap(({ components }) =>
          components
            .flatMap(({ build }) =>
              build.__typename === 'Build' &&
              build.source?.__typename === 'RegisteredModelVersion'
                ? {
                    registeredModelId: build.source.registeredModel.id,
                    endpointId: id,
                  }
                : undefined
            )
            .filter(isNotNil)
        )
    );
    return pipe(
      () =>
        groupBy(
          ({ registeredModelId }) => registeredModelId,
          registeredModelIdsWithEndpointIds
        ),
      (res) => mapObj((r) => r.map(({ endpointId }) => endpointId), res)
    )();
  });

const QUERY = gql`
  query Endpoints($workspaceName: String!, $organizationId: ID) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Workspace {
        id
        endpoints {
          endpoints {
            id
            environments {
              id
              components {
                build {
                  ... on Build {
                    id
                    source {
                      ... on RegisteredModelVersion {
                        id
                        registeredModel {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
