import { useMemo } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { DialogProps } from '@mui/material/Dialog';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import Popup from 'shared/view/elements/Popup/Popup';
import isNotNil from 'shared/utils/isNotNill';
import { useUpdateEffect } from 'shared/view/hooks/useUpdateEffect';

export interface ConfirmBasicProps
  extends Pick<DialogProps, 'maxWidth' | 'fullWidth'> {
  confirmButtonText: string;
  confirmButtonIcon?: IconDefinition;
  cancelButtonText?: string;
  cancelButtonIcon?: IconDefinition;
  longPressConfirmation?: boolean;
  confirmButtonLoading?: boolean;
  pressConfirmationInterval?: number;
  onConfirmOpenChange?: (isOpen: boolean) => void;
}

interface Props extends ConfirmBasicProps {
  isOpen: boolean;
  title: string;
  children: Exclude<React.ReactNode, null | undefined>;
  onConfirm(): void;
  onCancel(): void;
}

const ConfirmPopup = (props: Props) => {
  const {
    children,
    isOpen,
    title,
    onConfirm,
    onCancel,
    confirmButtonText,
    cancelButtonText,
    maxWidth,
    fullWidth,
    longPressConfirmation,
    pressConfirmationInterval,
    confirmButtonIcon,
    cancelButtonIcon,
    onConfirmOpenChange,
    confirmButtonLoading,
  } = props;

  useUpdateEffect(() => {
    if (onConfirmOpenChange) {
      onConfirmOpenChange(isOpen);
    }
  }, [isOpen]);

  const isLongPressConfirmation = useMemo(
    () =>
      Boolean(longPressConfirmation) ||
      (isNotNil(pressConfirmationInterval) && pressConfirmationInterval > 0),
    [longPressConfirmation, pressConfirmationInterval]
  );

  return (
    <Popup
      title={title}
      isOpen={isOpen}
      onClose={onCancel}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      buttons={{
        mainButtonProps: {
          type: isLongPressConfirmation ? 'progress-button' : 'button',
          icon: confirmButtonIcon,
          dataTest: isLongPressConfirmation
            ? 'confirm-with-long-press'
            : 'confirm',
          children: confirmButtonText,
          onClick: onConfirm,
          isLoading: Boolean(confirmButtonLoading),
          pressConfirmationInterval,
        },

        secondaryButtonProps: isNotNil(cancelButtonText)
          ? {
              dataTest: 'confirm-cancel-button',
              children: cancelButtonText,
              icon: cancelButtonIcon,
              onClick: onCancel,
              isLoading: false,
            }
          : undefined,
      }}
    >
      <>{children}</>
    </Popup>
  );
};

export default ConfirmPopup;
