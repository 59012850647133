import { useMemo } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  getDefaultIODescriptionsOrThrowError,
  MonitoringIODescription,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import { OmitStrict } from 'shared/utils/types';
import { validateNotEmpty } from 'shared/utils/validators';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { defaultAggregationWindow } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertAggregationWindow';
import { defaultEvaluationFrequency } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertEvaluationFrequency';
import MonitoringWidgetPreview from 'features/monitoring/shared/MonitoringWidgetFormPopup/MonitoringWidgetFormPopupContent/MonitoringWidgetPreview/MonitoringWidgetPreview';
import TypeSelectFieldName from 'features/monitoring/shared/MonitoringWidgetForm/shared/TypeSelect/TypeSelectFieldName';
import MonitoringIODescriptionField from 'features/monitoring/shared/MonitoringWidgetForm/shared/MonitoringIODescriptionField/MonitoringIODescriptionField';
import { AlertStatus } from 'generated/types';
import {
  AlertSettingsType,
  alertSettingsTypes,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertSettings';
import { defaultMonitoringDriftMetricType } from 'shared/models/Monitoring/MonitoringDriftMetricType';
import { defaultMonitoringMetricType } from 'shared/models/Monitoring/MonitoringMetricType';
import MonitoringMetricTypeField from 'features/monitoring/shared/MonitoringWidgetForm/shared/MonitoringMetricTypeField/MonitoringMetricTypeField';
import MonitoringDriftMetricTypeField from 'features/monitoring/shared/MonitoringWidgetForm/shared/MonitoringDriftMetricTypeField/MonitoringDriftMetricTypeField';
import {
  defaultAlertLowerBound,
  defaultAlertOperator,
  defaultAlertThreshold,
  defaultAlertUpperBound,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/Alerter';
import matchType from 'shared/utils/matchType';
import { MonitoringWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidget';
import { getAlertWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { useMonitoringModelContext } from 'features/monitoring/models/model/store/state/context';
import MonitoringOutputDescriptionField from 'features/monitoring/shared/MonitoringWidgetForm/shared/MonitoringOutputDescriptionField/MonitoringOutputDescriptionField';
import PopupButtons from 'shared/view/elements/Popup/PopupButtons';
import isNotNil from 'shared/utils/isNotNill';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import NumberInputField from 'shared/view/formComponents/formikFields/NumberInputField/NumberInputField';
import WidgetFormPopupContentContainer from 'shared/view/domain/Dashboards/WidgetFormPopupContent/WidgetFormPopupContentContainer/WidgetFormPopupContentContainer';
import { getAvailableIODescriptionsForDrift } from 'shared/models/Monitoring/Distribution/Drift';
import { MonitoredModelType } from 'shared/models/Monitoring/MonitoringModel/MonitoredModelType';

import AggregationWindowSelect from './AggregationWindowSelect/AggregationWindowSelect';
import EvaluationFrequencySelect from './EvaluationFrequencySelect/EvaluationFrequencySelect';
import AlertConditionOperatorSelect from './AlertConditionOperatorSelect/AlertConditionOperatorSelect';
import {
  convertAlertFormToPreviewAlert,
  MonitoringAlertFormType,
} from './shared/MonitoringAlertFormType';
import { isRangeAlertConditionOperator } from './shared/AlertCondition';

const getFieldName = makeGetFieldName<MonitoringAlertFormType>();

interface Props {
  monitoredModelType: MonitoredModelType;
  initialValues: MonitoringAlertFormType | null;
  onSubmit: (form: MonitoringAlertFormType) => void;
  submitText: string;
  ioDescriptions: MonitoringIODescription[];
  isLoading: boolean;
  onCancel?: () => void;
}

const makeInitialValuesOrThrowError = (props: {
  ioDescriptions: MonitoringIODescription[];
}): MonitoringAlertFormType => {
  const { defaultInput, defaultOutput } = getDefaultIODescriptionsOrThrowError(
    props.ioDescriptions
  );

  return {
    name: '',
    ioDescription: defaultInput,
    output: defaultOutput,
    type: 'drift',
    condition: {
      threshold: defaultAlertThreshold,
      lowerBound: defaultAlertLowerBound,
      upperBound: defaultAlertUpperBound,
      operator: defaultAlertOperator,
    },
    aggregationWindow: defaultAggregationWindow,
    evaluationFrequency: defaultEvaluationFrequency,
    driftMetricType: defaultMonitoringDriftMetricType,
    metricType: defaultMonitoringMetricType,
    status: AlertStatus.OK,
  };
};

const MonitoringAlertForm = (props: Props) => {
  const { model } = useMonitoringModelContext();
  const initialValues = useMemo(
    () =>
      props.initialValues ??
      makeInitialValuesOrThrowError({ ioDescriptions: props.ioDescriptions }),
    [props.ioDescriptions, props.initialValues]
  );

  return (
    <PresetFormik initialValues={initialValues} onSubmit={props.onSubmit}>
      {({ isValid, values, resetForm }) => (
        <div>
          <WidgetFormPopupContentContainer
            widgetPreview={<Preview form={values} />}
          >
            <TextInputField
              label="Name"
              isRequired={true}
              name={getFieldName({ name: null })}
              validate={validateNotEmpty('Name')}
            />

            <TypeSelectFieldName
              label="Type"
              name={getFieldName({ type: null })}
              types={alertSettingsTypes}
            />

            {matchType(
              {
                drift: () => (
                  <>
                    <MonitoringIODescriptionField
                      label="Feature"
                      name={getFieldName({ ioDescription: null })}
                      ioDescriptions={getAvailableIODescriptionsForDrift(
                        props.ioDescriptions
                      )}
                    />
                    <MonitoringDriftMetricTypeField
                      name={getFieldName({ driftMetricType: null })}
                    />
                  </>
                ),
                metric: () => (
                  <>
                    <MonitoringOutputDescriptionField
                      name={getFieldName({ output: null })}
                      ioDescriptions={props.ioDescriptions}
                    />
                    <MonitoringMetricTypeField
                      monitoredModelType={props.monitoredModelType}
                      name={getFieldName({ metricType: null })}
                    />
                  </>
                ),
              },
              values.type
            )}

            <AggregationWindowSelect
              name={getFieldName({ aggregationWindow: null })}
            />
            <EvaluationFrequencySelect
              name={getFieldName({ evaluationFrequency: null })}
            />

            <Typography variant="subtitle1">Condition</Typography>

            <Typography variant="caption">Trigger when</Typography>

            <AlertConditionOperatorSelect
              name={getFieldName({ condition: { operator: null } })}
            />

            {isRangeAlertConditionOperator(values.condition.operator) ? (
              <Stack direction="row">
                <NumberInputField
                  label="Lower bound"
                  name={getFieldName({ condition: { lowerBound: null } })}
                  min={0}
                  step={0.01}
                />

                <NumberInputField
                  label="Upper bound"
                  name={getFieldName({ condition: { upperBound: null } })}
                  min={0}
                  step={0.01}
                />
              </Stack>
            ) : (
              <NumberInputField
                label="Threshold"
                name={getFieldName({ condition: { threshold: null } })}
                min={0}
                step={0.01}
              />
            )}
          </WidgetFormPopupContentContainer>

          <DialogActions>
            <PopupButtons
              mainButtonProps={{
                type: 'button',
                disabled:
                  !isValid || !model.monitoredEntity.allowedActions.update,
                isLoading: props.isLoading,
                children: props.submitText,
                onClick: () => {
                  props.onSubmit(values);
                },
              }}
              secondaryButtonProps={
                isNotNil(props.onCancel)
                  ? getSecondaryButtonProps({
                      resetForm,
                      onCancel: props.onCancel,
                    })
                  : undefined
              }
            />
          </DialogActions>
        </div>
      )}
    </PresetFormik>
  );
};

function getSecondaryButtonProps({
  resetForm,
  onCancel,
}: {
  resetForm(): void;
  onCancel(): void;
}) {
  return {
    onClick: () => {
      onCancel();
      resetForm();
    },
    isLoading: false,
    children: 'Cancel' as const,
  };
}

const Preview = (props: { form: MonitoringAlertFormType }) => {
  const widget = matchType<
    AlertSettingsType,
    OmitStrict<MonitoringWidget, 'id'>
  >(
    {
      drift: () => ({
        type: 'timeSeries',
        variant: {
          type: 'drift',
          driftMetricType: props.form.driftMetricType,
          ioDescription: props.form.ioDescription,
        },
        title: props.form.name,
        alert: convertAlertFormToPreviewAlert(props.form),
      }),
      metric: () => ({
        type: 'timeSeries',
        variant: {
          type: 'metric',
          metricType: props.form.metricType,
          output: props.form.output,
        },
        title: props.form.name,
        alert: convertAlertFormToPreviewAlert(props.form),
      }),
    },
    props.form.type
  );

  const { model } = useMonitoringModelContext();

  return (
    <MonitoringWidgetPreview
      widgetPreview={widget}
      widgetExternalDeps={getAlertWidgetExternalDeps({
        model,
        alert: convertAlertFormToPreviewAlert(props.form),
      })}
    />
  );
};

export default MonitoringAlertForm;
