import { MonitoringMetricType } from 'generated/types';
import generateId from 'shared/utils/generateId';

import { MonitoringPanel } from '../../../MonitoringPanel/MonitoringPanel';
import { MakeDefaultModelStateProps } from '../../shared/MakeDefaultModelStateProps';
import { makePresetMetricRow } from './makePresetMetricRow';

export const makePredictionsPanel = (
  props: MakeDefaultModelStateProps
): MonitoringPanel => {
  const predictionCountRow = makePresetMetricRow(
    MonitoringMetricType.PREDICTION_COUNT,
    props.defaultOutput,
    0
  );

  return {
    id: generateId(),
    name: 'Predictions',
    type: 'custom',
    isOpen: true,
    layout: predictionCountRow.layout,
    widgets: predictionCountRow.widgets,
  };
};
