import { Stack, Typography } from '@mui/material';

import { VertaInfra } from './VertaInfra';
import { VertaClients } from './VertaClients';

export const VertaFooter = () => (
  <>
    <Stack spacing={5} mb={10}>
      <Typography variant="h6" color="text.primary">
        Verta is adapted to your needs
      </Typography>
      <VertaInfra />
    </Stack>
    <Stack spacing={3} mb={15}>
      <Typography variant="h6" color="text.primary">
        Trusted by industry leading brands
      </Typography>
      <VertaClients />
    </Stack>
  </>
);
