import { apply } from 'ramda';
import { useMemo } from 'react';

const debounce = <T>(
  fn: (...args: T[]) => void,
  timeMs: number,
  immediate?: boolean
) => {
  let timeout: NodeJS.Timeout | null;

  return (...args: T[]) => {
    const later = () => {
      timeout = null;

      if (!immediate) {
        apply(fn, args);
      }
    };

    const callNow = immediate && !timeout;

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, timeMs);

    if (callNow) {
      apply(fn, args);
    }

    return timeout;
  };
};

export const useDebounced = <T>(
  fn: (...args: T[]) => void,
  timeMs: number,
  immediate?: boolean
) => {
  return useMemo(
    () => debounce(fn, timeMs, immediate),
    [fn, immediate, timeMs]
  );
};
