import { ComponentProps } from 'react';
import { Stack } from '@mui/material';

import { isNonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';
import { OmitStrict } from 'shared/utils/types';
import matchBy from 'shared/utils/matchBy';

import ActionsMenu, { MenuAction } from '../../ActionsMenu/ActionsMenu';
import Button from '../../Button/Button';
import { LinkButton } from '../../Button/LinkButton';
import DefaultConfirmAction from '../../ConfirmAction/DefaultConfirmAction';
import { IconAwesomeClickable } from '../../IconAwesome/IconAwesomeClickable';
import { ICONS } from '../../IconAwesome/ICONS';
import OptionalTooltip from '../../Tooltip/OptionalTooltip';
import CellRendererEmpty from './CellRendererEmpty';

type Props = OmitStrict<ComponentProps<typeof ActionsMenu>, 'renderAnchor'> & {
  dataTest?: string;
};

const CellRendererActions = (props: Props) => {
  if (!isNonEmptyArray(props.actions)) {
    return <CellRendererEmpty />;
  }

  if (props.actions.length <= 2) {
    return <DualActionButton actions={props.actions} />;
  }

  return (
    <ActionsMenu
      {...props}
      renderAnchor={(onClick) => (
        <IconAwesomeClickable
          icon={ICONS.ellipsisVertical}
          dataTest={props.dataTest || 'actions'}
          disabled={props.actions.some((action) => Boolean(action.isLoading))}
          onClick={onClick}
        />
      )}
    />
  );
};

const DualActionButton = (props: { actions: MenuAction[] }) => {
  return (
    <Stack direction="row">
      {props.actions.map((action) => {
        if (
          (action.type === 'button' || action.type === 'icon') &&
          action.confirmAction
        ) {
          return (
            <DefaultConfirmAction key={action.label} {...action.confirmAction}>
              {(withConfirmAction) => (
                <CommonSingleActionButton
                  {...action}
                  onClick={withConfirmAction(action.onClick)}
                />
              )}
            </DefaultConfirmAction>
          );
        }

        return <CommonSingleActionButton key={action.label} {...action} />;
      })}
    </Stack>
  );
};

const CommonSingleActionButton = (props: MenuAction) => {
  let tooltipText = props.tooltipTitle ?? '';

  if (props.type === 'icon') {
    tooltipText = props.label;
  }

  return (
    <div>
      {props.additionalContent}
      <OptionalTooltip
        showTooltip={Boolean(tooltipText)}
        title={tooltipText}
        type="withSpan"
      >
        {matchBy(
          props,
          'type'
        )({
          button: (params) => (
            <Button
              variant="text"
              disabled={params.disabled}
              dataTest={params.dataTest}
              isLoading={Boolean(params.isLoading)}
              onClick={params.onClick}
            >
              {props.label}
            </Button>
          ),
          link: (params) => (
            <LinkButton
              variant="text"
              to={params.to}
              disabled={params.disabled || params.isLoading}
              dataTest={params.dataTest}
            >
              {props.label}
            </LinkButton>
          ),
          icon: (params) => (
            <IconAwesomeClickable
              icon={params.icon}
              dataTest={props.dataTest}
              disabled={params.disabled || params.isLoading}
              onClick={params.onClick}
            />
          ),
        })}
      </OptionalTooltip>
    </div>
  );
};

export default CellRendererActions;
