import { gql } from '@apollo/client';

export const SERVICE_FLAGS_QUERY = gql`
  query ServiceFlags {
    serviceFlags {
      enableRegistry
      enableEndpoints
      enableDatasets
      enableDeployment
      enableMonitoring
      enableMonitoredEntity
      enableNotificationChannel
      enableProjects
      enableRegistry
      enableRegisteredModel
      enableDashboard
      enablePromotionApprovalRequired
    }
  }
`;
