import { Box, Stack, Typography } from '@mui/material';

import PaperLink from 'features/catalog/registeredModelVersion/shared/view/PaperLink';
import { Button } from 'shared/view/elements/Button';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';

import { ConfigPopup } from './ConfigPopup';

const Config = ({ configuration }: { configuration?: string }) => {
  const { openPopup, isPopupOpen, closePopup } = usePopupManager();

  if (!configuration) {
    return (
      <PaperLink icon={ICONS.code} sx={{ width: 416, p: 2, height: 74.91 }}>
        <Typography variant="caption" color="text.secondary">
          No configuration
        </Typography>
      </PaperLink>
    );
  }

  return (
    <PaperLink icon={ICONS.code} sx={{ width: 416, p: 2 }}>
      <Stack direction="row" width="100%">
        <Box width={245}>
          <DynamicTypography value={configuration} variant="body2" />
          <Typography variant="caption" color="text.secondary">
            Configuration
          </Typography>
        </Box>
        <Button
          sx={{ width: 100 }}
          isLoading={false}
          variant="outlined"
          onClick={openPopup}
        >
          See more
        </Button>
      </Stack>
      <ConfigPopup
        isPopupOpen={isPopupOpen}
        closePopup={closePopup}
        configuration={configuration}
      />
    </PaperLink>
  );
};

export default Config;
