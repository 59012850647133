import { scaleOrdinal } from '@visx/scale';

import {
  getColorByOutlierDetectionChartDataType,
  outlierDetectionChartDataTypes,
} from './types';

export const outlierDetectionChartColorScale = scaleOrdinal({
  domain: outlierDetectionChartDataTypes,
  range: outlierDetectionChartDataTypes.map(
    getColorByOutlierDetectionChartDataType
  ),
});
