import { Grid, Typography, Stack } from '@mui/material';

import useOrganizationGovernance, {
  OrganizationGovernance,
} from 'features/organizations/governance/store/useOrganizationGovernance';
import { useLoadCustomAttributeDefinitions } from 'features/catalog/shared/hooks/useLoadCustomAttributeDefinitions';

import { ChecklistTemplate } from './ChecklistTemplate';

type Props = ReturnType<typeof useOrganizationGovernance> & {
  organization: OrganizationGovernance;
};

export const OrganizationGovernanceScreen = ({
  organization,
  reload,
}: Props) => {
  const checklistTemplates = organization.checklistTemplates || [];

  // load customAttr in the first load
  const _customAttrs = useLoadCustomAttributeDefinitions({
    organizationId: organization.id,
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" my="24px" color="text.primary">
          Governance checklist
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2}>
          {checklistTemplates.map((checklistTemplate) => (
            <ChecklistTemplate
              key={`checklist-template-${checklistTemplate.id}`}
              checklistTemplate={checklistTemplate}
              reload={reload}
              groups={organization.groups}
            />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};
