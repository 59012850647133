import { pipe, tail, takeLast } from 'ramda';
import { ActionType, createReducer } from 'typesafe-actions';

import { ErrorReporterState } from './types';
import * as actions from './actions';

const size = 10;

const initialState: ErrorReporterState = {
  isErrorsPanelOpened: false,
  errorReporterModal: {
    errorWithInfo: undefined,
    isOpened: false,
  },
  requestResponses: [],
  errorsWithInfo: [],
};

export const reducer = createReducer<
  ErrorReporterState,
  ActionType<typeof actions>
>(initialState)
  .handleAction(actions.addRequestResponse, (state, action) => ({
    ...state,
    requestResponses: pipe(
      () => state.requestResponses.concat(action.payload),
      (res) => (res.length > size ? tail(state.requestResponses) : res)
    )(),
  }))
  .handleAction(actions.addError, (state, action) => ({
    ...state,
    errorsWithInfo: state.errorsWithInfo
      // If an error is already in the list, we replace it with the newer one
      .filter(
        (error) =>
          !(
            error.error.message === action.payload.error.message &&
            error.pathname === action.payload.pathname &&
            error.context === action.payload.context
          )
      )
      .concat({
        ...action.payload,
        requestResponses: takeLast(size, state.requestResponses),
      }),
  }))
  .handleAction(actions.removeError, (state, action) => ({
    ...state,
    errorsWithInfo: state.errorsWithInfo.filter(
      (x) => x.error !== action.payload
    ),
  }))
  .handleAction(actions.openErrorsPanel, (state) => ({
    ...state,
    isErrorsPanelOpened: true,
  }))
  .handleAction(actions.closeErrorsPanel, (state) => ({
    ...state,
    isErrorsPanelOpened: false,
  }))
  .handleAction(actions.openErrorReporterModal, (state, action) => ({
    ...state,
    errorReporterModal: {
      isOpened: true,
      errorWithInfo: action.payload,
    },
  }))
  .handleAction(actions.closeErrorReporterModal, (state) => ({
    ...state,
    errorReporterModal: {
      isOpened: false,
      errorWithInfo: undefined,
    },
  }))
  .handleAction(actions.removeAllErrors, (state) => ({
    ...state,
    errorsWithInfo: [],
  }));
