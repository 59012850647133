import routes from '../routes';

export const getUrlWithChangedWorkspace = ({
  pathname,
  workspaceName,
}: {
  pathname: string;
  workspaceName: string;
}) => {
  const appropriatedRoot = Object.values(routes.workspace.$).find((x) =>
    x.getMatch(pathname)
  );

  return appropriatedRoot
    ? appropriatedRoot.getRedirectPath({ workspaceName })
    : routes.homepage.getRedirectPath({ workspaceName });
};
