import { FC, PropsWithChildren, ReactNode } from 'react';
import { Box, Stack } from '@mui/material';

import { OmitStrict } from 'shared/utils/types';
import matchBy from 'shared/utils/matchBy';

import { InternalWizardStepProps } from './Wizard';
import { Button, ButtonProps, ProgressButton, SubmitButton } from '../Button';

export type ActiveStepChangeProps = {
  forceComplete?: boolean;
  forceActive?: boolean;
  jumpTo?: number;
};

export type WizardStepContentProps = OmitStrict<
  InternalWizardStepProps,
  'children'
> & {
  children?: ReactNode | FC<WizardStepContentProps>;
  next: (p?: ActiveStepChangeProps) => void;
  back: (p?: ActiveStepChangeProps) => void;
  activeStep: number;
  slots?: {
    content?: (p: WizardStepContentProps) => FC;
    buttons?: (p: WizardStepContentProps) => FC;
  };
  buttons?: {
    next?: Partial<ButtonProps> & {
      skipWizardNext?: boolean;
      type?: 'button' | 'submit' | 'progress-button';
    };
    back?: Partial<ButtonProps> & {
      skipWizardBack?: boolean;
      type?: 'button' | 'submit' | 'progress-button';
    };
  };
};

export const WizardStepContent: FC<
  PropsWithChildren<WizardStepContentProps>
> = (props) => {
  const { next, back, slots, buttons, activeStep, children } = props;

  const ButtonsContainer = () => {
    const handleBack: ButtonProps['onClick'] = (e) => {
      buttons?.back?.onClick?.(e);
      !buttons?.back?.skipWizardBack && back();
    };

    const handleNext: ButtonProps['onClick'] = (e) => {
      buttons?.next?.onClick?.(e);
      !buttons?.next?.skipWizardNext && next();
    };

    return (
      <Stack
        spacing={1}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {activeStep !== 0 || buttons?.back ? (
          <FooterButton
            type="button"
            isLoading={false}
            variant="text"
            children="Back"
            {...buttons?.back}
            onClick={handleBack}
          />
        ) : null}
        <FooterButton
          type="button"
          isLoading={false}
          variant="contained"
          children="Next"
          {...buttons?.next}
          onClick={handleNext}
        />
      </Stack>
    );
  };

  return (
    <Box
      display="flex"
      height="100%"
      sx={{ flexFlow: 'column' }}
      maxHeight="100%"
    >
      {slots?.content ? (
        <>slots.content(props)</>
      ) : (
        <Box flex="1 1 auto" sx={{ overflowY: 'auto' }}>
          {children}
        </Box>
      )}

      {slots?.buttons ? (
        <>slots.buttons(props)</>
      ) : (
        <Box flex="0 1 64px" pt={3}>
          <ButtonsContainer />
        </Box>
      )}
    </Box>
  );
};

const FooterButton = (
  props: NonNullable<NonNullable<WizardStepContentProps['buttons']>['next']> &
    Pick<ButtonProps, 'onClick' | 'isLoading'>
) =>
  matchBy(
    props,
    'type'
  )({
    submit: () => <SubmitButton {...props} />,
    button: () => <Button {...props} />,
    'progress-button': () => <ProgressButton {...props} />,
  });
