import { AlertStatus } from 'generated/types';
import matchType from 'shared/utils/matchType';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

interface Props {
  status: AlertStatus;
}

const MonitoringAlertStatus = (props: Props) => {
  return matchType(
    {
      OK: () => (
        <IconAwesomeInfo
          icon={ICONS.circleCheck}
          infoType="success"
          size="lg"
        />
      ),
      ALERTING: () => (
        <IconAwesomeInfo
          icon={ICONS.circleExclamation}
          infoType="error"
          size="lg"
        />
      ),
      NODATA: () => <IconAwesomeInfo icon={ICONS.circleMinus} size="lg" />,
      PAUSED: () => <IconAwesomeInfo icon={ICONS.circlePause} size="lg" />,
      UNKNOWN: () => <IconAwesomeInfo icon={ICONS.circleMinus} size="lg" />,
    },
    props.status
  );
};

export default MonitoringAlertStatus;
