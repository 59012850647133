import { PieChart as MuiPieChart } from '@mui/x-charts';
// eslint-disable-next-line no-restricted-imports
import { PieChartProps } from '@mui/x-charts/PieChart/PieChart';

import { OmitStrict } from 'shared/utils/types';

type DataProps = PieChartProps['series'][0]['data'];

export type DonutChartProps = OmitStrict<
  PieChartProps,
  'series' | 'legend' | 'width' | 'height'
> & {
  data: DataProps;
  radius: number;
  stroke?: number;
  showLegend?: boolean;
};

export const DonutChart = (props: DonutChartProps) => {
  const { data, radius, stroke = 16, showLegend = false, ...others } = props;

  return (
    <MuiPieChart
      series={[
        {
          data: data.map((d) => ({ ...d, label: d.label })),
          innerRadius: radius - stroke,
          outerRadius: radius - 2,
          cx: radius - 4,
          cy: radius - 4,
        },
      ]}
      width={radius * 2}
      height={radius * 2}
      sx={{
        '.MuiChartsLegend-root': {
          display: showLegend ? 'unset' : 'none',
        },
      }}
      {...others}
    />
  );
};

export default DonutChart;
