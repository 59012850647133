/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { EXTERNAL_DEPLOY_FRAGMENT } from 'shared/graphql/ExternalDeployment/ExternalDeployment';
import { CHECKLIST_TEMPLATE_FRAGMENT } from 'shared/graphql/Checklist/ChecklistTemplate';
import { CHECKLIST_TEMPLATE_ITEM_FRAGMENT } from 'shared/graphql/Checklist/ChecklistTemplateItem';
import { CHECKLIST_ITEM_VALUES_FRAGMENT } from 'shared/graphql/Checklist/ChecklistItemValues';
import { ENDPOINT_FOR_SHORT_ENDPOINTS_TABLE } from 'shared/view/domain/Deployment/Endpoints/ShortEndpointsTable/ShortEndpointsTableQuery';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { ExtractByTypename } from 'shared/utils/types';
import { CUSTOM_ATTRIBUTE_VALUE_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeValue';
import { CUSTOM_ATTRIBUTE_DEFINITION_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeDefinition';

import {
  ModelVersionRelease,
  ModelVersionReleaseVariables,
} from './graphql-types/useModelVersionRelease.generated';

export type RegisteredModelVersion = ExtractByTypename<
  ModelVersionRelease['registeredModelVersion'],
  'RegisteredModelVersion'
>;

type OrganizationV2 = ExtractByTypename<
  ModelVersionRelease['organizationV2'],
  'OrganizationV2'
>;

export type CustomAttributeDefinitions = ExtractByTypename<
  OrganizationV2['customAttributeDefinitions'],
  'CustomAttributeDefinitions'
>;

export type ChecklistTemplate =
  ModelVersionRelease['checklistTemplates']['checklistTemplates'];

export type Groups = ModelVersionRelease['selfV2']['groups'];

const MODEL_VERSION_RELEASE = gql`
  query ModelVersionRelease(
    $versionId: ID!
    $organizationId: ID!
    $organizationIdString: String!
    $workspaceId: ID!
  ) {
    selfV2 {
      ... on UserV2 {
        id
        groups {
          id
          name
        }
      }
      ... on ServiceAccountV2 {
        id
        groups {
          id
          name
        }
      }
    }
    registeredModelVersion(id: $versionId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        version
        stage
        redirectMetadata
        deployments {
          endpoint {
            id
            ...EndpointForShortEndpointsTable
          }
        }
        externalDeployments {
          id
          ...ExternalDeploymentData
        }
        checklistItemValues {
          ...ChecklistItemValuesData
        }
        customAttributeValues {
          id
          ...CustomAttributeValueData
        }
        registeredModel {
          id
          name
          allowedActions {
            ...AllowedActionsData
            lock
            unlock
            updateRedact
          }
          workspace {
            id
            ...WorkspaceData
          }
        }
      }
    }
    checklistTemplates(
      query: {
        organizationId: $organizationIdString
        workspaceId: $workspaceId
      }
    ) {
      checklistTemplates {
        id
        ...ChecklistTemplateData
        checklistTemplateItems {
          id
          ...ChecklistTemplateItemData
        }
        customAttributeValues {
          id
          ...CustomAttributeValueData
        }
      }
    }
    organizationV2(id: $organizationId) {
      ... on OrganizationV2 {
        id
        customAttributeDefinitions {
          ... on CustomAttributeDefinitions {
            customAttributeDefinitions {
              id
              ...CustomAttributeDefinitionData
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ENDPOINT_FOR_SHORT_ENDPOINTS_TABLE}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${EXTERNAL_DEPLOY_FRAGMENT}
  ${CHECKLIST_TEMPLATE_FRAGMENT}
  ${CHECKLIST_TEMPLATE_ITEM_FRAGMENT}
  ${CHECKLIST_ITEM_VALUES_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_VALUE_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_DEFINITION_FRAGMENT}
`;

export const useModelVersionRelease = ({
  workspaceId,
  versionId,
  organizationId,
}: {
  workspaceId: string;
  versionId: string;
  organizationId: string;
}) => {
  const query = useCustomQuery<
    ModelVersionRelease,
    ModelVersionReleaseVariables
  >(MODEL_VERSION_RELEASE, {
    variables: {
      workspaceId,
      versionId,
      organizationId,
      organizationIdString: organizationId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: version, communication: loadingVersion } =
    resultToCommunicationWithData(convertResponse, query);

  return {
    version,
    loadingVersion,
    reloadVersion: query.refetch,
  };
};

const convertResponse = (res: ModelVersionRelease) => {
  return {
    ...mapDataOrError(res.registeredModelVersion, (x) => x),
    groups: mapDataOrError(res.selfV2, (x) => x.groups),
    checklistTemplates: mapDataOrError(res.checklistTemplates, (x) =>
      x.checklistTemplates !== null ? x.checklistTemplates : []
    ),
    customAttributeDefinitions: mapDataOrError(
      res.organizationV2,
      (x) => x.customAttributeDefinitions
    ),
  };
};
