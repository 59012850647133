import { Group } from '@visx/group';

import styles from './ChartSVG.module.css';
import WhenNotNil from '../../WhenNotNil/WhenNotNil';

interface ILocalProps {
  width: number;
  height: number;
  margin: { left: number; top: number };
  children: React.ReactNode;
  outSvgContent?: React.ReactNode;
  isNilData: boolean;
}

const ChartSVG = (props: ILocalProps) => {
  return (
    <WhenNotNil
      isNil={props.isNilData}
      width={props.width}
      height={props.height}
    >
      <div className={styles.root}>
        {props.outSvgContent}
        <svg width={props.width} height={props.height}>
          <Group left={props.margin.left} top={props.margin.top}>
            {props.children}
          </Group>
        </svg>
      </div>
    </WhenNotNil>
  );
};

export default ChartSVG;
