import { NavigateFunction } from 'react-router-dom';

import { Filter } from 'shared/models/Filters/Filter';
import { Brand } from 'shared/utils/Brand/Brand';

const URLFiltersParam: keyof IURLWithFilters = 'filters';

declare const URLFiltersSymbol: unique symbol;

type URLFilters = Brand<string, 'URLFilters', typeof URLFiltersSymbol>;
export interface IURLWithFilters {
  filters: URLFilters;
}

export const makeURLFilters = (filters: Filter[]): URLFilters => {
  return (
    filters.length === 0 ? '' : encodeURIComponent(JSON.stringify(filters))
  ) as URLFilters;
};

export function saveFiltersInUrl(
  navigate: NavigateFunction,
  filters: Filter[]
) {
  const urlParams = new URLSearchParams(window.location.search);
  if (filters.length === 0) {
    urlParams.delete(URLFiltersParam);
  } else {
    urlParams.set(URLFiltersParam, makeURLFilters(filters));
  }

  navigate({
    search: String(urlParams),
  });
}

export function getFiltersFromUrl(): Filter[] {
  const filtersFromUrl = new URLSearchParams(window.location.search).get(
    URLFiltersParam
  );
  return filtersFromUrl
    ? (JSON.parse(decodeURIComponent(filtersFromUrl)) as Filter[])
    : [];
}
