import { gql } from '@apollo/client';

export const KafkaConfigurationFragment = gql`
  fragment KafkaConfigurationFragment on KafkaConfiguration {
    id
    name
    brokerAddresses
    tls {
      certificate
    }
    kerberos {
      conf
      keytab
      clientName
      serviceName
    }
    enabled
  }
`;
