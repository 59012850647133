import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import checklistCompleteEvidence from '../images/checklist-complete-evidence.png';

export const ChecklistCompleteEvidence = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Complete items on a checklist
    </Typography>
    <Typography variant="body2" mb={2}>
      When evidence is necessary for an item, a text box is provided for you to
      enter it. This text box allows you to input ticket IDs, links, or any
      other relevant information you need to record alongside the checklist.
    </Typography>
    <Typography variant="body2" mb={3}>
      If you lack permissions to complete an item it will appear grayed out to
      you.
    </Typography>
    <img
      src={checklistCompleteEvidence}
      width="100%"
      height="auto"
      alt="checklist complete items with additional evidence"
    />
  </WizardStepContent>
);
