import { EnvironmentsDiff } from 'shared/models/CompareEntities/new/environmentsComparing';

import { makeExpansionPropertyDescription } from '../CompareTable/PropertyDiffDescriptions/PropertyDiffDescription';
import { makeEntitiesPrimitivesDiff } from './EntitiesPrimitivesDiffView';
import { makeKeyValuePairsDiff } from './KeyValuePairsDiffView';

export const makeEnvironmentsDiff = (diff: EnvironmentsDiff) => {
  return makeExpansionPropertyDescription({
    name: 'Environments',
    children: [
      makeKeyValuePairsDiff({
        name: 'Variables',
        diff: diff.variablesDiff,
      }),
      makeKeyValuePairsDiff({
        name: 'Apt packages',
        diff: diff.aptPackagesDiff,
      }),
      makeEntitiesPrimitivesDiff({
        name: 'Docker',
        diff: diff.dockersDiff,
        render: (x) => x,
      }),
      makeKeyValuePairsDiff({
        name: '(Python) Constraints',
        diff: diff.pythonsDiff.constraints,
      }),
      makeEntitiesPrimitivesDiff({
        name: '(Python) Requirements',
        diff: diff.pythonsDiff.requirements,
        render: (x) => x,
      }),
      makeEntitiesPrimitivesDiff({
        name: '(Python) Version',
        diff: diff.pythonsDiff.pythonVersions,
        render: (x) => x,
      }),
    ],
  });
};
