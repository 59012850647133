/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { WORKSPACE_ORGANIZATION_FRAGMENT } from 'shared/graphql/Workspace';

export const LOAD_INITIAL_AUTHORIZED_APP_DATA = gql`
  query LoadInitialAuthorizedAppData {
    workspaces {
      id
      ...WorkspaceOrganizationData
    }
    systemAdmins {
      id
      selfIsSystemAdmin
    }
    administratedOrganizationV2 {
      id
      name
    }
  }
  ${WORKSPACE_ORGANIZATION_FRAGMENT}
`;
