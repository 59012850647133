import {
  Card,
  Stack,
  CardContent as MUICardContent,
  styled,
} from '@mui/material';

export const CardContent = styled(MUICardContent)({
  height: '224px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const SeeMoreCard = styled(Stack)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  background: theme.palette.background.default,
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: '4px',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CardsList = styled(Stack)({
  display: 'flex',
  marginTop: '16px',
});

export const CardType = styled(Stack)({
  display: 'flex',
  alignItems: 'center',
});

export const WrapperCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.action.hover,
  border: 'none',
  '&:hover': {
    outline: `1px solid ${theme.palette.action.disabled}`,
    background: theme.palette.action.disabledBackground,
  },
  borderRadius: '4px',
  height: '224px',
  maxWidth: '25%',
  width: '100%',
}));
