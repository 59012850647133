import { gql } from '@apollo/client';
import { useEffect } from 'react';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { combineCommunications } from 'shared/utils/redux/communication/combineCommunications';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import noop from 'shared/utils/noop';

import {
  OrganizationV2Delete,
  OrganizationV2DeleteVariables,
} from './graphql-types/useDeleteOrganization.generated';
import { QUERY } from './useOrganizationsList';
import { useLoadUserWorkspaces } from '../../hooks/useLoadUserWorkspaces';

const ORGANIZATION_DELETE = gql`
  mutation OrganizationV2Delete($organizationId: ID!) {
    organizationV2(id: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        delete
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export function useOrganizationDelete(onCompleted: () => void) {
  const [run__, communication] = useCustomMutation<
    OrganizationV2Delete,
    OrganizationV2DeleteVariables
  >(ORGANIZATION_DELETE, { context: 'deleting organization' }, { onCompleted });

  const [reloadOrganizations, reloadOrganizationsRes] = useCustomLazyQuery(
    QUERY,
    {}
  );
  const reloadOrganizationsCommunication = resultToCommunicationWithData(
    noop,
    reloadOrganizationsRes
  ).communication;

  const loadUserWorkspacesApi = useLoadUserWorkspaces();

  const run = (variables: OrganizationV2DeleteVariables) => {
    run__(variables).then(() => {
      reloadOrganizations();
      loadUserWorkspacesApi.load();
    });
  };

  const resCommunication = combineCommunications([
    communication,
    reloadOrganizationsCommunication,
    loadUserWorkspacesApi.communication,
  ]);
  useEffect(() => {
    if (resCommunication.isSuccess) {
      onCompleted();
    }
  }, [onCompleted, resCommunication.isSuccess]);

  return {
    run,
    communication: resCommunication,
  };
}
