import { getArrayItem } from 'shared/utils/collection';
import { Result } from 'shared/utils/result';
import {
  ScatterChartAxisesDataSpec,
  ScatterChartSettings,
  ScatterChartXAxisDataSpec,
  ScatterChartYAxisDataSpec,
  ScatterChartZAxisDataSpec,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/ScatterChartSettings';
import { emptyCustomDomain } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/shared/CustomDomain';
import { OmitStrict } from 'shared/utils/types';

export type ScatterChartSettingsForm = {
  name: string;
  type: 'scatterChart';
  xAxis: OmitStrict<ScatterChartSettings['xAxis'], 'spec'> & {
    spec?: ScatterChartXAxisDataSpec;
  };
  yAxis: OmitStrict<ScatterChartSettings['yAxis'], 'spec'> & {
    spec?: ScatterChartYAxisDataSpec;
  };
  zAxis: OmitStrict<ScatterChartSettings['zAxis'], 'spec'> & {
    spec?: ScatterChartZAxisDataSpec;
  };
};

export const getInitialScatterChartSettingsForm = (
  name: string,
  axisesSpec: ScatterChartAxisesDataSpec
): ScatterChartSettingsForm => {
  const xAxisSpec = axisesSpec.xAxis.dateUpdated;
  const yAxisSpec = getArrayItem(0, axisesSpec.yAxis);
  const zAxisSpec = getArrayItem(0, axisesSpec.zAxis.experimentRunStringField);

  return {
    type: 'scatterChart',
    name,
    xAxis: {
      spec: xAxisSpec,
      isLogScale: false,
      domain: emptyCustomDomain,
    },
    yAxis: {
      spec: yAxisSpec,
      isLogScale: false,
      domain: emptyCustomDomain,
    },
    zAxis: {
      spec: zAxisSpec,
    },
  };
};

export const validateScatterChartSettingsForm = (
  form: ScatterChartSettingsForm
): Result<ScatterChartSettings, string> => {
  if (form.xAxis.spec && form.yAxis.spec && form.zAxis.spec) {
    return {
      type: 'success',
      data: {
        name: form.name,
        type: 'scatterChart',
        xAxis: {
          spec: form.xAxis.spec,
          isLogScale: form.xAxis.isLogScale,
          domain: form.xAxis.domain,
        },
        yAxis: {
          spec: form.yAxis.spec,
          isLogScale: form.yAxis.isLogScale,
          domain: form.yAxis.domain,
        },
        zAxis: {
          spec: form.zAxis.spec,
        },
      },
    };
  }
  return {
    type: 'error',
    error: 'Select a metric to visualize in this scatter chart.',
  };
};
