import React from 'react';
import { FieldProps, Field } from 'formik';
import { equals } from 'ramda';

import Select from 'shared/view/elements/Selects/Select/Select';
import { ICommunication } from 'shared/utils/redux/communication';
import { validateNotEmpty } from 'shared/utils/validators';
import {
  getSourceId,
  RequiredBuild,
} from 'features/deployment/canary/shared/RequiredBuild';
import { formatDateWithTime } from 'shared/utils/formatters/dateTime';
import isNotNil from 'shared/utils/isNotNill';
import { IGroupedOptions } from 'shared/view/elements/Selects/shared/types';

import { BuildOption } from '../settings';
import { getFieldName } from '../settings/getFieldName';

export const BuildField = ({
  value,
  sourceId,
  sourceBuilds,
  environmentSourceBuilds,
  loading,
  onChange,
}: {
  value: BuildOption | undefined;
  sourceId: string | undefined;
  sourceBuilds: RequiredBuild[] | undefined;
  environmentSourceBuilds: RequiredBuild[];
  loading: ICommunication;
  onChange: (option: BuildOption) => void;
}) => {
  const buildOptions =
    sourceId && sourceBuilds
      ? getBuildOptions({
          sourceId,
          sourceBuilds,
          environmentSourceBuilds,
        })
      : undefined;
  const name = getFieldName({ build: null });

  return (
    <Field name={name} validate={validateNotEmpty('Build')}>
      {({ field, meta }: FieldProps) => (
        <Select<BuildOption>
          name={name}
          label="Select a build"
          errorMessage={meta.touched ? meta.error : undefined}
          disabled={
            loading.isRequesting ||
            !isNotNil(buildOptions) ||
            value?.type === 'newBuild'
          }
          isLoading={loading.isRequesting}
          value={buildOptions && getSelectedBuildOption(value, buildOptions)}
          options={buildOptions || []}
          dataTest={name}
          onChange={(option) => onChange(option.value)}
          onBlur={field.onBlur}
        />
      )}
    </Field>
  );
};

const getSelectedBuildOption = (
  selectedOption: BuildOption | undefined,
  options: IGroupedOptions<BuildOption>
) =>
  options.groups
    .flatMap(({ options: groupSettings }) => groupSettings)
    .find(({ value }) => equals(value, selectedOption));

const getBuildOptions = ({
  sourceId,
  environmentSourceBuilds,
  sourceBuilds,
}: {
  sourceId: string;
  environmentSourceBuilds: RequiredBuild[];
  sourceBuilds: RequiredBuild[];
}): IGroupedOptions<BuildOption> => {
  return {
    type: 'groupedOptions',
    groups: [
      {
        label: 'Current builds',
        options: environmentSourceBuilds
          .filter((build) => getSourceId(build) === sourceId)
          .map(getOptionByBuild),
      },
      {
        label: 'Previous builds',
        options: sourceBuilds
          .filter(
            (sourceBuild) =>
              sourceId === getSourceId(sourceBuild) &&
              environmentSourceBuilds.every(
                (envBuild) => envBuild.id !== sourceBuild.id
              )
          )
          .map(getOptionByBuild),
      },
    ].filter(({ options }) => options.length > 0),
  };
};

export const getOptionByBuild = (build: RequiredBuild) => {
  const buildDate = new Date(build.dateCreated);
  return {
    label: `${formatDateWithTime(buildDate)} - ${build.id}`,
    value: { type: 'existedBuild' as const, id: build.id },
  };
};
