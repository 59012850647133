import matchType from 'shared/utils/matchType';
import { EntityTypeWithCollaborators } from 'shared/models/Collaborators/CollaboratorsPermissions';

export const entityTypeWithCollaboratorsToString = (
  type: EntityTypeWithCollaborators
) =>
  matchType(
    {
      project: () => 'project',
      dataset: () => 'dataset',
      endpoint: () => 'endpoint',
      registeredModel: () => 'registered model',
      monitoredEntity: () => 'monitored entity',
    },
    type
  );
