// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MUIToggleButton, {
  ToggleButtonProps as MUIToggleButtonProps,
} from '@mui/material/ToggleButton';
import { styled } from '@mui/material';
import { alpha } from '@mui/material';

import Tooltip from 'shared/view/elements/Tooltip/Tooltip';

type ToggleButtonProps<T> = MUIToggleButtonProps & {
  title?: React.ReactNode;
  value: T;
};

const InnerToggleButton = <T,>(props: ToggleButtonProps<T>) => {
  const { title, ...other } = props;

  return title !== undefined ? (
    <Tooltip type="default" title={title} placement="bottom">
      <MUIToggleButton disableRipple {...other} />
    </Tooltip>
  ) : (
    <MUIToggleButton disableRipple {...other} />
  );
};

const ToggleButton = styled(InnerToggleButton)(
  ({ theme, selected, color = 'primary' }) => {
    if (color !== 'standard') {
      return {
        borderColor: alpha(theme.palette[color].main, 0.5),
        borderWidth: '1px',
        color: theme.palette[color].main,
        backgroundColor: selected
          ? `${alpha(theme.palette[color].main, 0.08)} !important`
          : 'transparent',
        '&:hover': {
          borderColor: theme.palette[color].main,
        },
      };
    }
  }
) as typeof InnerToggleButton;

export default ToggleButton;
