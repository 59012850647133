export interface Flags {
  errorReporting: {
    supportChannelLink: string | undefined;
    isEnableAttachingRequestResponses: boolean;
    isEnableObfuscation: boolean;
    isEnablePlayground: boolean;
  };
  deployment: {
    isEnable: boolean;
    isEnableMonitoring: boolean;
    isEnableMachineConfigGpu: boolean;
    isEnableKafka: boolean;
    isEnableExpandedEndpointPermissions: boolean;
    isEnableDedicatedResources: boolean;
    defaultCpuValue: number | undefined;
    defaultMemoryValue: string | undefined;
    maxCpuValue: number | undefined;
    maxMemoryValue: string | undefined;
  };
  isEnableDatasets: boolean;
  isEnableProjects: boolean;
  isEnableAttributesVisualization: boolean;
  isEnableCustomUsers: boolean;
  isEnableExternalSecurityScanning: boolean;
  isEnableAuth0Login: boolean;
  isEnableSAMLLogin: boolean;
  isEnableLdapLogin: boolean;
  isEnableRegistry: boolean;
  isEnableExternalAuth: boolean;
  isEnableRegisteredModelVersionsLock: boolean;
  isDefaultRegistryLockedEnabled: boolean;
  defaultRegistryLockedContent?: 'open' | 'closed' | 'redact';
  isEnableBaselinePermission: boolean;
  isEnableDarkModeControl: boolean;
  isEnableCustomContainer: boolean;
  isEnableIncompleteArtifact: boolean;
  isEnablePromotion: boolean;
  isEnableWorkflows: boolean;
  isEnableDashboard: boolean;
  isEnableCustomDashboard: boolean;
  pypiDisableConfigNameMarker: string | undefined;
  muiDataGridLicense: string | null;
  segmentWriteKey: string | null;
  isEnableServiceAccounts: boolean;
  isSentryEnabled: boolean;
  isEnableChatWidget: boolean;
  catalog: {
    isEnableNewEditor: boolean;
    isEnableMLAssistance: boolean;
    isEnableMLInline: boolean;
    isEnableFineFineTuning: boolean;
    isEnablePromotionApprovalRequired: boolean;
  };
  monitoring: {
    isEnable: boolean;
    isEnableMonitoringAlerts: boolean;
    isEnableMonitoringCompare: boolean;
    isEnableNewMonitoringUX: boolean;
  };
  homepage: {
    isEnableMonitoringWidget: boolean;
    isEnableCatalogWidget: boolean;
  };
  evaluation: {
    isEnabled: boolean;
  };
  createUserToken: string;
  isEnableFreeTier: boolean;
  isEnableFairness: boolean;
  isEnableUseCases: boolean;
}

export const defaultFlags: Flags = {
  errorReporting: {
    supportChannelLink: undefined,
    isEnableAttachingRequestResponses: false,
    isEnableObfuscation: false,
    isEnablePlayground: false,
  },
  deployment: {
    isEnable: false,
    isEnableDedicatedResources: false,
    isEnableMonitoring: false,
    isEnableMachineConfigGpu: false,
    isEnableKafka: false,
    isEnableExpandedEndpointPermissions: false,
    defaultCpuValue: 2,
    defaultMemoryValue: '6Mi',
    maxCpuValue: 16,
    maxMemoryValue: '64Gi',
  },
  isEnableAttributesVisualization: false,
  isEnableCustomUsers: false,
  isEnableAuth0Login: false,
  isEnableSAMLLogin: false,
  isEnableLdapLogin: false,
  isEnableRegistry: false,
  isEnableDatasets: true,
  isEnableProjects: true,
  isEnableExternalAuth: false,
  isEnableRegisteredModelVersionsLock: false,
  isDefaultRegistryLockedEnabled: false,
  isEnableBaselinePermission: false,
  isEnableDarkModeControl: false,
  isEnableCustomContainer: false,
  isEnableExternalSecurityScanning: false,
  isEnableWorkflows: false,
  isEnableDashboard: false,
  isEnableCustomDashboard: false,
  isEnablePromotion: false,
  muiDataGridLicense: null,
  segmentWriteKey: null,
  isEnableServiceAccounts: false,
  isSentryEnabled: false,
  isEnableIncompleteArtifact: false,
  pypiDisableConfigNameMarker: undefined,
  isEnableChatWidget: false,
  catalog: {
    isEnableNewEditor: true,
    isEnableMLAssistance: false,
    isEnableMLInline: false,
    isEnableFineFineTuning: false,
    isEnablePromotionApprovalRequired: true,
  },
  monitoring: {
    isEnable: false,
    isEnableMonitoringAlerts: false,
    isEnableMonitoringCompare: false,
    isEnableNewMonitoringUX: false,
  },
  homepage: {
    isEnableCatalogWidget: false,
    isEnableMonitoringWidget: false,
  },
  evaluation: {
    isEnabled: false,
  },
  createUserToken: '',
  isEnableFreeTier: false,
  isEnableFairness: false,
  isEnableUseCases: false,
};
