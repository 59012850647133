import { Box, Stack, Typography } from '@mui/material';

import { Button } from 'shared/view/elements/Button';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import Chip from 'shared/view/elements/Chip/Chip';

import { Container, IconWrapper } from './styled';

type Props = {
  handleRequestContact: (s: string) => void;
  isFreetier: boolean;
};

export const PredictionsSummary = ({
  handleRequestContact,
  isFreetier,
}: Props) => {
  return (
    <Container spacing={4}>
      <Stack direction="row" justifyContent="space-between">
        <IconWrapper icon={ICONS.crystalBall} isDisabled />
        <Chip
          label={isFreetier ? 'Not available' : 'Coming soon'}
          variant="filled"
          color="default"
          size="small"
        />
      </Stack>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex="1 1 auto" mb={2} display="flex" flexDirection="column">
          <Typography variant="h2" color={'text.disabled'}>
            -
          </Typography>

          <Typography variant="subtitle1" color={'text.disabled'}>
            Number of predictions
          </Typography>

          <Typography variant="body2" color={'text.disabled'}>
            served with Verta deploys
          </Typography>
        </Box>
        <Box flex="0 1 auto" display="flex">
          {isFreetier ? (
            <Button
              variant="text"
              onClick={() => handleRequestContact('predictions')}
              size="small"
              isLoading={false}
            >
              Upgrade to access
            </Button>
          ) : null}
        </Box>
      </Box>
    </Container>
  );
};
