import { EntitiesPrimitivesDiff } from 'shared/models/CompareEntities/new/primitivesComparing';
import { IEntity } from 'shared/models/CompareEntities/types';
import { Primitive } from 'shared/utils/types';

import { RowDiffRenderResult } from '../CompareTable/BaseComponents/RowDiff';
import { makeRowDiffDescription } from '../CompareTable/PropertyDiffDescriptions/PropertyDiffDescription';

export function makeEntitiesPrimitivesDiff<
  Entity extends IEntity,
  P extends Primitive,
>(props: {
  name: string;
  diff: EntitiesPrimitivesDiff<Entity, P>;
  render(primitive: P, entity: Entity): RowDiffRenderResult;
}) {
  return makeRowDiffDescription({
    data: props.diff.primitivesWithEntities,
    name: props.name,
    isDifferent: props.diff.isDifferent,
    getValue: (x) => x.primitive,
    render: ({ entity, primitive }) => props.render(primitive, entity),
  });
}
