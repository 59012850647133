import { gql } from '@apollo/client';
import { isEmpty } from 'lodash';

import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import matchType from 'shared/utils/matchType';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';

import {
  DatasetOwnerCandidates,
  EndpointOwnerCandidates,
  MonitoringOwnerCandidates,
  ProjectOwnerCandidates,
  ProjectOwnerCandidatesVariables,
  RegisteredModelOwnerCandidates,
} from './graphql-types/useOwnerCandidates.generated';
import { RequiredResource } from './useEditResourceInformation';

const PROJECT_QUERY = gql`
  query ProjectOwnerCandidates($id: ID!, $organizationId: ID!) {
    project(id: $id) {
      ... on Project {
        id
        ownerV2Candidates(organizationId: $organizationId) {
          users {
            ... on UserV2 {
              id
              fullName
              firstName
              lastName
              email
            }
            ... on ServiceAccountV2 {
              id
              fullName
              firstName
              lastName
              email
            }
          }
          groups {
            id
            name
          }
        }
      }
    }
  }
`;

const DATASET_QUERY = gql`
  query DatasetOwnerCandidates($id: ID!, $organizationId: ID!) {
    dataset(id: $id) {
      ... on Dataset {
        id
        ownerV2Candidates(organizationId: $organizationId) {
          users {
            ... on UserV2 {
              id
              fullName
              firstName
              lastName
              email
            }
            ... on ServiceAccountV2 {
              id
              fullName
              firstName
              lastName
              email
            }
          }
          groups {
            id
            name
          }
        }
      }
    }
  }
`;

const ENDPOINT_QUERY = gql`
  query EndpointOwnerCandidates($id: ID!, $organizationId: ID!) {
    endpoint(id: $id) {
      ... on Endpoint {
        id
        ownerV2Candidates(organizationId: $organizationId) {
          users {
            ... on UserV2 {
              id
              fullName
              firstName
              lastName
              email
            }
            ... on ServiceAccountV2 {
              id
              fullName
              firstName
              lastName
              email
            }
          }
          groups {
            id
            name
          }
        }
      }
    }
  }
`;

const REGISTERED_MODEL_QUERY = gql`
  query RegisteredModelOwnerCandidates($id: ID!, $organizationId: ID!) {
    registeredModel(id: $id) {
      ... on RegisteredModel {
        id
        ownerV2Candidates(organizationId: $organizationId) {
          users {
            ... on UserV2 {
              id
              fullName
              firstName
              lastName
              email
            }
            ... on ServiceAccountV2 {
              id
              fullName
              firstName
              lastName
              email
            }
          }
          groups {
            id
            name
          }
        }
      }
    }
  }
`;

const MONITORING_QUERY = gql`
  query MonitoringOwnerCandidates($id: ID!, $organizationId: ID!) {
    monitoredEntity(id: $id) {
      ... on MonitoredEntity {
        id
        ownerV2Candidates(organizationId: $organizationId) {
          users {
            ... on UserV2 {
              id
              fullName
              firstName
              lastName
              email
            }
            ... on ServiceAccountV2 {
              id
              fullName
              firstName
              lastName
              email
            }
          }
          groups {
            id
            name
          }
        }
      }
    }
  }
`;

const useOwnerCandidates = (resource: {
  __typename: RequiredResource['__typename'];
  id: string;
}) => {
  const organizationId = useCurrentOrganizationV2() ?? '';

  const queryResult = useCustomQuery<any, ProjectOwnerCandidatesVariables>(
    matchType(
      {
        Dataset: () => DATASET_QUERY,
        Endpoint: () => ENDPOINT_QUERY,
        MonitoredEntity: () => MONITORING_QUERY,
        Project: () => PROJECT_QUERY,
        RegisteredModel: () => REGISTERED_MODEL_QUERY,
        RegisteredModelVersion: () => REGISTERED_MODEL_QUERY,
      },
      resource.__typename
    ),
    { variables: { id: resource.id, organizationId } }
  );

  return useMemoizedResultToCommunicationWithData({
    queryResult,
    memoizedConvert: (res) => {
      const entity = (() => {
        switch (resource.__typename) {
          case 'Dataset': {
            return (res as DatasetOwnerCandidates).dataset;
          }
          case 'Endpoint': {
            return (res as EndpointOwnerCandidates).endpoint;
          }
          case 'MonitoredEntity': {
            return (res as MonitoringOwnerCandidates).monitoredEntity;
          }
          case 'Project': {
            return (res as ProjectOwnerCandidates).project;
          }
          case 'RegisteredModel': {
            return (res as RegisteredModelOwnerCandidates).registeredModel;
          }
          case 'RegisteredModelVersion': {
            return (res as RegisteredModelOwnerCandidates).registeredModel;
          }
          default: {
            return exhaustiveCheck(resource.__typename);
          }
        }
      })();

      return entity.__typename === 'Error'
        ? {
            users: [],
            groups: [],
          }
        : buildOwnerV2Candidates(entity.ownerV2Candidates);
    },
  });
};

type OwnerCandidates = {
  users: {
    id: string;
    fullName?: string;
    firstName?: string;
    lastName?: string;
    email: string;
  }[];
  groups: { __typename: 'Group'; id: string; name: string }[];
};

const buildOwnerV2Candidates = (ownerV2Candidates?: OwnerCandidates) => {
  const users = ownerV2Candidates?.users.map((elem) => ({
    ...elem,
    fullName: isEmpty(elem.fullName)
      ? `${elem.firstName || ''} ${elem.lastName || ''}`
      : elem.fullName,
  }));

  return { ...ownerV2Candidates, users };
};

export default useOwnerCandidates;
