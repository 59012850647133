import { useCallback, useEffect } from 'react';
import { useState } from 'react';

import {
  MonitoringModel,
  MonitoringModelState,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import { useDebounced } from 'shared/utils/debounce';

import { useUpdateMonitoringModel } from '../graphql/useUpdateMonitoringModel';

export const useMonitoringModelState = (props: { model: MonitoringModel }) => {
  const [model, updateModel] = useState(() => props.model);

  const { updateMonitoringModel } = useUpdateMonitoringModel();

  const updateMonitoringModelDebounced = useDebounced(
    updateMonitoringModel,
    1000
  );

  useEffect(() => {
    if (model.monitoredEntity.allowedActions.update) {
      updateMonitoringModelDebounced(model);
    }
  }, [model, updateMonitoringModelDebounced]);

  const updateModelState = useCallback(
    (state: MonitoringModelState) => {
      updateModel({
        ...model,
        state,
      });
    },
    [model]
  );

  return {
    model,
    updateModelState,
  };
};
