import { useState, useCallback, useMemo } from 'react';
import { partition } from 'ramda';

import {
  ComparedEntities,
  minNumberOfEntitiesForComparing,
} from 'shared/models/CompareEntities/new/comparedEntities';

export const useComparedEntites = <T extends { id: string }>(
  initialComparedEntities: ComparedEntities<T>
) => {
  const [comparedEntityIds, setComparedEntityIds] = useState(
    initialComparedEntities.map(({ id }) => id)
  );

  const removeComparedEntity = useMemo(() => {
    return comparedEntityIds.length > minNumberOfEntitiesForComparing
      ? (id: string) => {
          setComparedEntityIds(
            comparedEntityIds.filter((currId) => currId !== id)
          );
        }
      : undefined;
  }, [comparedEntityIds]);

  const cancelComparedEntityRemoving = useCallback(
    (id: string) => {
      const newComparedEntityIds = comparedEntityIds.concat(id);
      setComparedEntityIds(
        // filter initialComparedEntities to preserve initial order
        initialComparedEntities
          .filter((e) => newComparedEntityIds.includes(e.id))
          .map((e) => e.id)
      );
    },
    [comparedEntityIds, initialComparedEntities]
  );

  const [comparedEntities, removedComparedEntities] = useMemo(
    () =>
      partition(
        (e) => comparedEntityIds.includes(e.id),
        initialComparedEntities.map((x, i) => ({ ...x, entityIndex: i }))
      ) as [
        ComparedEntities<T & { entityIndex: number }>,
        Array<T & { entityIndex: number }>,
      ],
    [comparedEntityIds, initialComparedEntities]
  );

  return {
    comparedEntities,
    removedComparedEntities,
    removeComparedEntity,
    cancelComparedEntityRemoving,
  };
};
