import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';

import { BarChartWidget } from './Widgets/BarChartWidget';
import { ConfusionMatrixWidget } from './Widgets/ConfusionMatrixWidget';
import { CurveChartWidget } from './Widgets/CurveChartWidget';
import { HistogramsWidget } from './Widgets/HistogramsWidget';
import { NotInitializedWidget } from './Widgets/NotInitializedWidget';
import { ScatterPlotWidget } from './Widgets/ScatterPlotWidget';
import { SingleMetricWidget } from './Widgets/SingleMetricWidget';
import { TableWidget } from './Widgets/TableWidget';
import { TimeSeriesWidget } from './Widgets/TimeSeriesWidget';

export type MonitoringWidget =
  | CurveChartWidget
  | ConfusionMatrixWidget
  | SingleMetricWidget
  | TimeSeriesWidget
  | ScatterPlotWidget
  | HistogramsWidget
  | BarChartWidget
  | TableWidget
  | NotInitializedWidget;

export type InitializedMonitoringWidget = Exclude<
  MonitoringWidget,
  NotInitializedWidget
>;

export const makeWidgetTitle = (widget: MonitoringWidget) => {
  if (widget.title) {
    switch (widget.type) {
      case 'timeSeries':
      case 'confusionMatrix':
      case 'singleMetric':
      case 'table':
      case 'curve':
      case 'notInitialized':
        return widget.title;
      case 'histograms':
      case 'scatterPlot':
      case 'barChart':
        return `${widget.title} - ${widget.ioDescription.name}`;
      default:
        exhaustiveCheck(widget);
    }
  }

  return '';
};
