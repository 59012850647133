import * as React from 'react';

import WidgetBuilder from 'features/experimentRuns/crossRunDashboard/view/WidgetBuilder/WidgetBuilder';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { RequiredExperimentRun } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import { WidgetSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/WidgetSettings';
import WidgetBuilderTabs from 'shared/view/domain/Dashboards/WidgetBuilderTabs/WidgetBuilderTabs';
import Popup from 'shared/view/elements/Popup/Popup';
import { CrossRunWidgetType } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunWidget';

import { widgetsInfo } from './widgetsInfo';

interface ILocalProps {
  experimentRuns: RequiredExperimentRun[];
  children: (onClick: () => void) => React.ReactNode;
  onCreate(widgetSettings: WidgetSettings): void;
}

const WidgetCreator = (props: ILocalProps) => {
  const {
    closePopup: closeWidgetCreator,
    isPopupOpen: isWidgetCreatorOpen,
    openPopup: openWidgetCreator,
  } = usePopupManager();
  const [widgetType, changeWidgetType] = React.useState<
    CrossRunWidgetType | undefined
  >(undefined);
  React.useEffect(() => {
    if (!isWidgetCreatorOpen) {
      changeWidgetType(undefined);
    }
  }, [isWidgetCreatorOpen]);

  return (
    <div>
      {isWidgetCreatorOpen &&
        (!widgetType ? (
          <Popup
            title="Create widget"
            isOpen={isWidgetCreatorOpen}
            onClose={closeWidgetCreator}
          >
            <WidgetBuilderTabs
              changeType={changeWidgetType}
              infos={widgetsInfo}
            />
          </Popup>
        ) : (
          <WidgetBuilder
            isOpen={isWidgetCreatorOpen}
            initialSettings={undefined}
            type={widgetType}
            submitText="Create"
            experimentRuns={props.experimentRuns}
            onBack={() => changeWidgetType(undefined)}
            onClose={closeWidgetCreator}
            onSubmit={(widgetSettings) => {
              closeWidgetCreator();
              props.onCreate(widgetSettings);
            }}
          />
        ))}
      {props.children(openWidgetCreator)}
    </div>
  );
};

export default WidgetCreator;
