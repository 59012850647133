import { ParentSize } from '@visx/responsive';
import { useCallback, useMemo } from 'react';

import {
  ExperimentRunWithDisplayedFields,
  getExperimentRunsWithDisplayedFields,
  RequiredExperimentRun,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import { Size } from 'shared/utils/charts/chartSizes';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { WidgetSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/WidgetSettings';
import { CrossRunDashboardPanel } from 'shared/models/CrossRunDashboard/CrossRunDashboard';
import { CrossRunWidget } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunWidget';
import WidgetView from 'shared/view/domain/CrossRunDashboard/CustomWidgetView/WidgetView';
import WidgetBuilder from 'features/experimentRuns/crossRunDashboard/view/WidgetBuilder/WidgetBuilder';
import WidgetWrapper, {
  Action,
  widgetWrapperCssVars,
} from 'features/experimentRuns/crossRunDashboard/view/Widgets/WidgetWrapper/WidgetWrapper';
import FullScreenWidget from 'features/experimentRuns/crossRunDashboard/view/Widgets/FullScreenWidget/FullScreenWidget';
import GridLayout from 'features/experimentRuns/crossRunDashboard/view/Widgets/GridLayout/GridLayout';
import { draggableHandleClassName } from 'shared/view/elements/IconAwesome/IconAwesomeDraggable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

interface ILocalProps {
  widgets: CrossRunWidget[];
  experimentRuns: RequiredExperimentRun[];
  dashboardLayout: CrossRunDashboardPanel['layout'];
  onDelete(widgetId: string): void;
  onEdit(widgetId: string, widgetSettings: WidgetSettings): void;
  onLayoutChange(layout: CrossRunDashboardPanel['layout']): void;
}

const Widgets = (props: ILocalProps) => {
  const experimentRunsWithDisplayedFields = useMemo(
    () => getExperimentRunsWithDisplayedFields(props.experimentRuns),
    [props.experimentRuns]
  );

  return (
    <GridLayout
      draggableHandleClassName={draggableHandleClassName}
      layout={props.dashboardLayout}
      onLayoutChange={props.onLayoutChange}
    >
      {props.widgets.map((widget) => (
        <div key={widget.id}>
          <ParentSize debounceTime={100}>
            {(parent) => (
              <Widget
                size={parent}
                widget={widget}
                experimentRuns={experimentRunsWithDisplayedFields}
                onDelete={props.onDelete}
                onEdit={props.onEdit}
              />
            )}
          </ParentSize>
        </div>
      ))}
    </GridLayout>
  );
};

const Widget = (props: {
  widget: CrossRunWidget;
  experimentRuns: ExperimentRunWithDisplayedFields[];
  size: Size;
  onDelete(widgetId: string): void;
  onEdit(widgetId: string, widgetSettings: WidgetSettings): void;
}) => {
  const { onDelete, widget } = props;

  const {
    closePopup: closeWidgetEditor,
    isPopupOpen: isWidgetEditorOpen,
    openPopup: openWidgetEditor,
  } = usePopupManager();

  const {
    closePopup: closeFullScreenWidget,
    isPopupOpen: isFullScreenWidgetOpen,
    openPopup: openFullScreenWidget,
  } = usePopupManager();

  const handleDelete = useCallback(() => {
    onDelete(widget.id);
  }, [onDelete, widget.id]);

  return (
    <WidgetWrapper
      title={props.widget.settings.name}
      actions={[
        <>
          <Action icon={ICONS.pencil} onClick={openWidgetEditor} />
          {isWidgetEditorOpen && (
            <WidgetBuilder
              experimentRuns={props.experimentRuns}
              isOpen={isWidgetEditorOpen}
              initialSettings={props.widget.settings}
              type={props.widget.settings.type}
              submitText="OK"
              onClose={closeWidgetEditor}
              onSubmit={(settings) => {
                closeWidgetEditor();
                props.onEdit(props.widget.id, settings);
              }}
            />
          )}
        </>,
        <>
          <Action icon={ICONS.trashCan} onClick={handleDelete} />
        </>,
        <>
          <Action icon={ICONS.expand} onClick={openFullScreenWidget} />
          {isFullScreenWidgetOpen && (
            <FullScreenWidget
              isOpen={isFullScreenWidgetOpen}
              widget={props.widget}
              experimentRuns={props.experimentRuns}
              onRequestClose={closeFullScreenWidget}
              editWidget={(settings) => props.onEdit(props.widget.id, settings)}
            />
          )}
        </>,
      ]}
    >
      <WidgetView
        id={props.widget.id}
        widgetSettings={props.widget.settings}
        experimentRuns={props.experimentRuns}
        height={
          props.size.height -
          widgetWrapperCssVars.widgetWrapperCaptionHeight.number
        }
        width={props.size.width}
        editWidget={(settings) => props.onEdit(props.widget.id, settings)}
      />
    </WidgetWrapper>
  );
};

export default Widgets;
