import { Box, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';
import {
  useRegisteringModelContext,
  RegisteringModelContext,
} from 'features/homepage/hooks/useRegisteringModel';
import { selectCurrentUserOrThrowError } from 'features/user';
import CodeBlock from 'shared/view/elements/CodeBlock/CodeBlock';
import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';
import { LinkButton } from 'shared/view/elements/Button/LinkButton';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { docsLinks } from 'shared/utils/globalConstants/vertaDocLinks';
import routes from 'shared/routes';

import { LocationType } from '../CardsModel';

const InitClientBlock = ({
  hostname,
  email,
  devKey,
}: {
  hostname: string;
  email: string;
  devKey: string;
}) => (
  <CodeBlock withCopy my={2} language="python">
    {`from verta  import Client

client = Client(
    host="${hostname}",
    email="${email}",
    dev_key="${devKey}",
)`}
  </CodeBlock>
);

type StepProps = RegisteringModelContext & { user: CurrentUserData };

const CREATE_MODEL = `from verta.registry import VertaModelBase

class MyModel(VertaModelBase):
    def __init__(self, artifacts):
        # load artifact files such as weights here
        pass

    def predict(self, batch_input):
        result = []
        for single_input in batch_input:
            result.append(single_input * single_input)
        return result`;

const REGISTER_MODEL = `registered_model = client.create_registered_model(
  name="MyModel",
  labels=["research", "team-a"],
)
`;

const CREATE_MODEL_VERSION = `from verta.environment import Python

model_version = registered_model.create_standard_model(
  model_cls=MyModel,
  environment=Python(requirements=[]),
  name="v0",
  labels=["prototype"],)`;

const ExternalStep = (props: StepProps) => {
  const currentUser = useSelector(selectCurrentUserOrThrowError);

  const { hostname, cardSelected } = props;
  return (
    <Stack spacing={2}>
      <Typography variant="body2">
        Click on this link to open the notebook:
      </Typography>
      <LinkButton
        to={cardSelected?.link || '#'}
        isExternal
        variant="outlined"
        color="info"
      >
        <Stack
          direction="row"
          spacing={0.5}
          alignItems="center"
          width="fit-content"
        >
          <IconAwesomeCustomizable icon={ICONS.link} />
          <Box>{cardSelected?.modelName}</Box>
        </Stack>
      </LinkButton>
      <Stack spacing={1}>
        <Typography variant="body2">
          In the notebook is code to register the sample model and version.
          Update the code with your credentials (that can also access on your
          <InlineLink to={routes.profile.getRedirectPath({})} simple>
            {' '}
            profile
          </InlineLink>
          ) by <strong>copying this block</strong> and pasting it into the colab
          notebook where indicated.
        </Typography>
        <InitClientBlock
          hostname={hostname}
          email={currentUser.email}
          devKey={currentUser.devKey}
        />
        <Typography variant="body2">
          Run the entire Colab notebook by clicking the play button to the left
          of the cells for every cell.
        </Typography>
        <Typography variant="body2">
          Once you are done running, return here, click on complete tutorial and
          see your new model on your catalog.
        </Typography>
      </Stack>
    </Stack>
  );
};

const InternalStep = ({ hostname, user }: StepProps) => (
  <Stack spacing={2}>
    <Typography variant="body2">
      To register your models, you will connect to Verta via our Python client.
    </Typography>
    <Typography variant="body2">
      Depending on your model and how you choose to register it, the exact code
      can look different. Here is a simple model as a reference.
    </Typography>
    <Stack spacing={1} pb={1}>
      <Typography variant="body2">
        First, create a connection with the Verta server.
      </Typography>
      <InitClientBlock
        hostname={hostname}
        email={user.email}
        devKey={user.devKey}
      />
      <Typography variant="body2">
        Second, define your model (here's a simple example):
      </Typography>
      <CodeBlock withCopy my={2} language="python">
        {CREATE_MODEL}
      </CodeBlock>
      <Typography variant="body2">Next, register the model:</Typography>
      <CodeBlock withCopy my={2} language="python">
        {REGISTER_MODEL}
      </CodeBlock>
      <Typography variant="body2">
        And finally, register the model version:
      </Typography>
      <CodeBlock withCopy my={2} language="python">
        {CREATE_MODEL_VERSION}
      </CodeBlock>
    </Stack>
    <Typography variant="body2">
      That’s it! You can catalog a variety of models with varying degrees of
      complexity (e.g., deep learning models with GBs of weights) using this
      pattern.
    </Typography>
    <Typography variant="body2">
      To learn more, check out the full{' '}
      <InlineLink
        to={docsLinks.registry.guides.createModelVersion}
        isExternal
        simple
      >
        documentation
      </InlineLink>
    </Typography>
    <Typography variant="body2">
      If you have any trouble, reach out to support.
    </Typography>
  </Stack>
);

export const RunNotebook = (props: WizardStepContentProps) => {
  const currentUser = useSelector(selectCurrentUserOrThrowError);
  const modelContext = useRegisteringModelContext();
  const isCollabModel =
    modelContext.cardSelected?.location === LocationType.CLOUD;

  const handleBack = () => {
    if (isCollabModel) {
      props.back({ jumpTo: 1 });
    } else {
      props.back();
    }
  };

  return (
    <WizardStepContent
      {...props}
      buttons={{
        next: { children: 'Complete tutorial' },
        back: {
          skipWizardBack: true,
          onClick: handleBack,
        },
      }}
    >
      <Typography variant="subtitle1" mb={2}>
        4. {isCollabModel ? 'Run the' : 'Build a'} notebook to register one
        model and version
      </Typography>
      {isCollabModel ? (
        <ExternalStep {...modelContext} user={currentUser} />
      ) : (
        <InternalStep {...modelContext} user={currentUser} />
      )}
    </WizardStepContent>
  );
};
