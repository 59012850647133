import { styled, Stack, Typography, Box } from '@mui/material';

import { useOnboardingContext } from 'features/homepage/hooks/useOnboarding';
import { trackEvent } from 'setup/app/analytics';
import { Button } from 'shared/view/elements/Button';

const StyledBox = styled(Box)({
  position: 'absolute',
  right: '32px',
  top: '16px',
});

export const WelcomeMessage = () => {
  const { hideGettingStared } = useOnboardingContext();
  return (
    <>
      <Stack direction="column" spacing={1}>
        <Typography variant="h5" textAlign="center">
          Discover Verta ✨
        </Typography>
        <Typography variant="body1" textAlign="center" color="text.secondary">
          Understand key concepts, follow simple tutorials.
        </Typography>
        <StyledBox>
          <Button
            variant="text"
            isLoading={false}
            size="small"
            onClick={() => {
              trackEvent({ type: 'homepage.discover_widget.hide_clicked' });
              hideGettingStared(true);
            }}
          >
            Hide it
          </Button>
        </StyledBox>
      </Stack>
    </>
  );
};
