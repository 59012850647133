import { Filter } from 'shared/models/Filters/Filter';

export type EntityInfo = { type: string; id: string };

const getLocalStorageFiltersKey = (entityInfo: EntityInfo) =>
  `${entityInfo.type}_${entityInfo.id}_global_filters`;

export function saveFiltersInLocalStorage(
  entityInfo: EntityInfo,
  filters: Filter[]
) {
  if (filters.length === 0) {
    localStorage.removeItem(getLocalStorageFiltersKey(entityInfo));
  } else {
    localStorage.setItem(
      getLocalStorageFiltersKey(entityInfo),
      JSON.stringify(filters)
    );
  }
}

export function getFiltersFromLocalStorage(entityInfo: EntityInfo): Filter[] {
  const confData = localStorage.getItem(getLocalStorageFiltersKey(entityInfo));
  if (confData) {
    return JSON.parse(confData) as Filter[];
  }
  return [];
}
