import { Stack, Typography, Grid, Box } from '@mui/material';
import React, { useState, MouseEvent } from 'react';

import { Evaluation } from 'features/evaluations/evaluation/summary/hooks/useEvaluationSummary';
import { ContentGrid } from 'features/catalog/shared/view/ContentGrid';
import ToggleView, {
  ToggleViewOptions,
} from 'shared/view/elements/ToggleView/ToggleView';
import { Button } from 'shared/view/elements/Button';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';

import InfoStack from './InfoStack/InfoStack';
import { EvaluationsTable } from './EvaluationsTable/EvaluationsTable';
import CommentCard from './CommentCard/CommentCard';
import { EvaluatePopup } from './EvaluatePopup';
import { EvaluationsSlider } from './EvaluationsSlider/EvaluationsSlider';
import PipelineLink from './InfoStack/PipelineLink';
import Config from './InfoStack/Config';
import { SummarySection } from './SummarySection';

interface Props {
  evaluation: Evaluation;
}

const Summary: React.FC<React.PropsWithChildren<Props>> = ({ evaluation }) => {
  const [view, setView] = useState<ToggleViewOptions>('data-grid');
  const popupApi = usePopupManager();

  const onViewChange = (_e: MouseEvent, newView: ToggleViewOptions | null) => {
    if (newView !== null) setView(newView);
  };

  return (
    <ContentGrid container spacing={2.75}>
      <Grid item xs={6} lg={6}>
        <InfoStack
          id={evaluation.id}
          description={evaluation.description}
          labels={evaluation.labels}
          owner={evaluation.owner}
          dateCreated={evaluation.dateCreated}
          dateUpdated={evaluation.dateUpdated}
          registeredModel={evaluation.registeredModel}
          rows={evaluation.rows}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5}>
        <CommentCard id={evaluation.id} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" color="text.secondary">
          General information
        </Typography>
        <ContentGrid container spacing={2} pb={1.25}>
          <Grid item>
            <Config configuration={evaluation.configuration} />
          </Grid>
          <Grid item>
            <PipelineLink modelId={evaluation.modelId} />
          </Grid>
        </ContentGrid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" mb={3}>
          Summary
        </Typography>
        <SummarySection {...evaluation} startEvaluation={popupApi.openPopup} />
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={3}>
          <Box display="flex" justifyContent="space-between">
            <Stack direction="row" spacing={4} alignContent="center">
              <Typography variant="h6">Evaluations</Typography>
              <ToggleView value={view} onChange={onViewChange} />
            </Stack>
            <Stack direction="row" spacing={2} alignContent="center">
              <Button
                onClick={() => undefined}
                isLoading={false}
                variant="outlined"
              >
                Download
              </Button>
              <Button
                onClick={popupApi.openPopup}
                isLoading={false}
                variant="contained"
              >
                Evaluate
              </Button>
            </Stack>
          </Box>
          {view === 'data-grid' ? (
            <EvaluationsTable evaluation={evaluation} />
          ) : null}
          {view === 'accordion' ? (
            <EvaluationsSlider evaluation={evaluation} />
          ) : null}
        </Stack>
      </Grid>
      <EvaluatePopup
        onClose={popupApi.closePopup}
        isOpen={popupApi.isPopupOpen}
        evaluation={evaluation}
      />
    </ContentGrid>
  );
};

export default Summary;
