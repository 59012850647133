/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

export const WORKSPACE_ORGANIZATION_FRAGMENT = gql`
  fragment WorkspaceOrganizationData on Workspace {
    id
    name
    oldId
    namespace
    type
    organizationV2 {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        name
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;
