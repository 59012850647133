import { Outlet } from 'react-router-dom';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { isNotRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import { TabsLayout } from 'pages/authorized/shared/TabsLayout';
import { UpdatedAtTagGraphql } from 'pages/authorized/CatalogPages/shared/UpdatedAtTag';
import PageSettingsIcon from 'shared/view/elements/PageSettingsIcon/PageSettingsIcon';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import EditEvaluationPopup from 'features/evaluations/evaluation/summary/view/EditEvaluation/EditEvaluation';
import { useEvaluationSummary } from 'features/evaluations/evaluation/summary/hooks/useEvaluationSummary';

import { useEvaluationBreadcrumbs } from '../../useEvaluationBreadcrumbs';

export const Layout = () => {
  const { evaluationId } = useRouteParams(routes.evaluationSummary);
  const { evaluation } = useEvaluationSummary({ evaluationId });

  const evaluationData = isNotError(evaluation) ? evaluation : undefined;
  const breadcrumbs = useEvaluationBreadcrumbs({ evaluation });

  const editPopupApi = usePopupManager();

  return (
    <TabsLayout
      breadcrumbs={breadcrumbs}
      pageEntityOrError={undefined}
      actions={[
        <UpdatedAtTagGraphql
          key="updated-at-tag"
          dateUpdated={evaluationData?.dateUpdated}
        />,
      ]}
      titleAdditionalContent={
        <PageSettingsIcon
          actions={[
            {
              type: 'button',
              label: 'Edit basic information',
              onClick: () => editPopupApi.openPopup(),
            },
          ]}
        />
      }
    >
      {editPopupApi.isPopupOpen ? (
        <EditEvaluationPopup
          evaluationId={evaluationId}
          isOpen={editPopupApi.isOpen}
          onClose={editPopupApi.closePopup}
          evaluation={evaluationData}
        />
      ) : null}
      <Outlet />
    </TabsLayout>
  );
};
