import { useSelector } from 'react-redux';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import routes from 'shared/routes';
import { selectFlags } from 'features/flags';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { AnyRoute } from 'shared/routes';

export type NavRouteItem = {
  route: AnyRoute;
  icon: IconDefinition;
  tooltip: string;
  isHidden?: boolean;
};

export const useNavRouteItems = () => {
  const {
    deployment,
    monitoring,
    isEnableRegistry,
    isEnableDatasets,
    isEnableProjects,
    isEnableDashboard,
    isEnableUseCases,
    evaluation,
  } = useSelector(selectFlags);

  if (!deployment.isEnable) {
    return [
      {
        icon: ICONS.houseBlank,
        route: routes.llmProjectsList,
        tooltip: 'Home',
      },
      {
        icon: ICONS.shapes,
        route: routes.llmProjectsList,
        tooltip: 'Project List',
      },
    ] as NavRouteItem[];
  }

  const navRouteItems: NavRouteItem[] = [
    {
      icon: ICONS.houseBlank,
      route: routes.homepage,
      tooltip: 'Home',
    },
    {
      route: routes.usecases,
      icon: ICONS.microchip,
      tooltip: 'Use cases',
      isHidden: !isEnableUseCases,
    },
    {
      route: routes.catalog,
      icon: ICONS.rectangleVerticalHistory,
      tooltip: 'Catalog',
      isHidden: !isEnableRegistry,
    },
    {
      route: routes.endpoints,
      tooltip: 'Deploy',
      isHidden: !deployment.isEnable,
      icon: ICONS.endpoints,
    },
    {
      route: routes.monitoring,
      tooltip: 'Monitor',
      isHidden: !monitoring.isEnable,
      icon: ICONS.chartColumn,
    },
    {
      route: routes.projects,
      icon: ICONS.project,
      tooltip: 'Projects',
      isHidden: !isEnableProjects,
    },
    {
      route: routes.datasets,
      icon: ICONS.database,
      tooltip: 'Data',
      isHidden: !isEnableDatasets,
    },
    {
      route: routes.dashboard,
      icon: ICONS.chartPie,
      tooltip: 'Dashboard',
      isHidden: !isEnableDashboard,
    },
    {
      route: routes.evaluations,
      icon: ICONS.microscope,
      tooltip: 'Evaluation',
      isHidden: !evaluation.isEnabled,
    },
  ];

  return navRouteItems;
};
