import {
  ParallelCoordinateChartAxisesDataSpec,
  ParallelCoordinateChartYAxisDataSpec,
  ParallelCoordinateChartSettings,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/ParallelCoordinateChartSettings';
import { getArrayItem, isNotEmptyArray } from 'shared/utils/collection';
import { defaultPrecision } from 'shared/utils/formatters/formatWithDefaultPrecision';
import { Result } from 'shared/utils/result';

export type ParallelCoordinateChartSettingsForm = {
  name: string;
  type: 'parallelCoordinateChart';
  yAxis: {
    specs: ParallelCoordinateChartYAxisDataSpec[];
  };
  decimalPrecision: number;
};

export const getInitialParallelCoordinateChartSettingsForm = (
  name: string,
  axisesSpec: ParallelCoordinateChartAxisesDataSpec
): ParallelCoordinateChartSettingsForm => {
  const yAxisSpec = getArrayItem(0, axisesSpec.yAxis);

  return {
    type: 'parallelCoordinateChart',
    name,
    yAxis: {
      specs: yAxisSpec ? [yAxisSpec] : [],
    },
    decimalPrecision: defaultPrecision,
  };
};

export const validateParallelCoordinateChartSettingsForm = (
  form: ParallelCoordinateChartSettingsForm
): Result<ParallelCoordinateChartSettings, string> => {
  if (isNotEmptyArray(form.yAxis.specs)) {
    return {
      type: 'success',
      data: {
        name: form.name,
        type: 'parallelCoordinateChart',
        yAxis: {
          specs: form.yAxis.specs,
        },
        decimalPrecision: form.decimalPrecision,
      },
    };
  }
  return {
    type: 'error',
    error: 'Select a metric to visualize in this parallel coordinate chart.',
  };
};
