import { scaleOrdinal } from '@visx/scale';
import { head, keys } from 'ramda';
import { useMemo } from 'react';

import { isNotRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { Distribution } from 'shared/models/Monitoring/Distribution/Distribution';
import {
  discreteDistributionKeys,
  makeFloatDistributionIntervals,
} from 'shared/models/Monitoring/Distribution/DistributionDescription';
import {
  NormalizedDistribution,
  normalizeDistribution,
} from 'shared/models/Monitoring/Distribution/NormalizedDistribution';
import { Size } from 'shared/utils/charts/chartSizes';
import isNotNil from 'shared/utils/isNotNill';
import ChartWithLegendContainer from 'shared/view/charts/ChartWithLegendContainer/ChartWithLegendContainer';
import MultiLevelBarChart from 'shared/view/charts/MultiLevelBarChart/MultiLevelBarChart';
import MultiLevelIntervalBarChart from 'shared/view/charts/MultiLevelBarChart/MultiLevelIntervalBarChart';
import { chartsDataColors } from 'shared/view/charts/shared/colors';

import { liveReferenceLegendWidth } from '../../../shared/liveReferenceLegendWidth';

interface Props {
  // todo use it
  id: string;
  liveDistributions: Distribution[];
  referenceDistributions: Distribution[];
  size: Size;
}

interface LiveReferenceDistribution {
  modelVersion: string;
  live: NormalizedDistribution;
  reference: NormalizedDistribution;
}

const colorScale = scaleOrdinal({
  domain: ['live', 'reference'],
  range: chartsDataColors,
});

const LiveReferenceCompareChart = (props: Props) => {
  const liveReferenceDistributions = useMemo(
    () =>
      makeLiveReferenceDistributions({
        liveDistributions: props.liveDistributions,
        referenceDistributions: props.referenceDistributions,
      }),
    [props.liveDistributions, props.referenceDistributions]
  );

  const base = head(liveReferenceDistributions);

  return (
    <ChartWithLegendContainer
      widgetSize={props.size}
      legendWidth={liveReferenceLegendWidth}
      legendProps={{
        scale: colorScale,
        position: 'bottom',
      }}
    >
      {(size: Size) => {
        if (base?.reference.type === 'discrete') {
          return (
            <DiscreteChart
              id={props.id}
              liveReferenceDistributions={liveReferenceDistributions}
              size={size}
            />
          );
        }

        return (
          <FloatChart
            id={props.id}
            liveReferenceDistributions={liveReferenceDistributions}
            size={size}
          />
        );
      }}
    </ChartWithLegendContainer>
  );
};

const DiscreteChart = (props: {
  id: string;
  size: Size;
  liveReferenceDistributions: LiveReferenceDistribution[];
}) => {
  const data = useMemo(
    () => props.liveReferenceDistributions.map(convertDiscreteData),
    [props.liveReferenceDistributions]
  );
  return (
    <MultiLevelBarChart
      data={data}
      getColorByBarKey={colorScale}
      size={props.size}
    />
  );
};

const FloatChart = (props: {
  id: string;
  size: Size;
  liveReferenceDistributions: LiveReferenceDistribution[];
}) => {
  const data = useMemo(
    () => props.liveReferenceDistributions.map(convertFloatData),
    [props.liveReferenceDistributions]
  );
  return (
    <MultiLevelIntervalBarChart
      data={data}
      getColorByBarKey={colorScale}
      size={props.size}
    />
  );
};

const makeLiveReferenceDistributions = (props: {
  liveDistributions: Distribution[];
  referenceDistributions: Distribution[];
}): LiveReferenceDistribution[] => {
  return props.liveDistributions
    .map((live) => {
      const reference = props.referenceDistributions.find(
        (d) => d.modelVersionId === live.modelVersionId
      );

      return reference
        ? {
            live: normalizeDistribution(live),
            reference: normalizeDistribution(reference),
            modelVersion: isNotRestrictedGraphqlError(live.modelVersion)
              ? live.modelVersion.version
              : live.modelVersionId,
          }
        : null;
    })
    .filter(isNotNil);
};

const convertDiscreteData = (
  liveReferenceDistribution: LiveReferenceDistribution
) => {
  const liveReference = {
    live: liveReferenceDistribution.live,
    reference: liveReferenceDistribution.reference,
  };
  return {
    levelKey: liveReferenceDistribution.modelVersion,
    groups: discreteDistributionKeys.map((key, index) => ({
      groupKey: key,
      bars: keys(liveReference).map((type) => ({
        barKey: type,
        value: liveReference[type].normalizedValues[index],
      })),
    })),
  };
};

const convertFloatData = (
  liveReferenceDistribution: LiveReferenceDistribution
) => {
  const liveReference = {
    live: liveReferenceDistribution.live,
    reference: liveReferenceDistribution.reference,
  };

  const intervals = makeFloatDistributionIntervals(
    liveReferenceDistribution.live
  );

  return {
    levelKey: liveReferenceDistribution.modelVersion,
    groups: intervals.map((interval, index) => ({
      ...interval,
      bars: keys(liveReference).map((type) => ({
        barKey: type,
        value: liveReference[type].normalizedValues[index],
      })),
    })),
  };
};

export default LiveReferenceCompareChart;
