import { useRouteParams } from 'shared/utils/router/useRouteParams';
import {
  useBreadcrumbItem,
  useAppBreadcrumbs,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import MonitoringAlertDetails from 'features/monitoring/alerts/view/MonitoringAlertDetails/MonitoringAlertDetails';
import { useMonitoringModelContext } from 'features/monitoring/models/model/store/state/context';
import routes from 'shared/routes';
import { useAlert } from 'features/monitoring/alerts/store/alert/useAlert';
import { isRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

import { useMonitoringModelPageBreadcrumbs } from '../shared/useMonitoringModelPageBreadcrumbs';
import MonitoringAlertsLayout from '../shared/MonitoringAlertsLayout/MonitoringAlertsLayout';

const MonitoringAlertPage = () => {
  const { alertId } = useRouteParams(routes.monitoringAlert);

  const { model, updateModelState } = useMonitoringModelContext();

  const { alert, loadingAlert } = useAlert({
    alertId,
  });

  const alertName = isRestrictedGraphqlError(alert) ? undefined : alert?.name;

  const breadcrumbs = useAppBreadcrumbs(
    useMonitoringModelPageBreadcrumbs({
      modelName: model.name,
    }),
    useBreadcrumbItem({
      name: 'Alerts' + (alertName ? `: ${alertName}` : ''),
      route: routes.monitoringAlertCreation,
    })
  );

  return (
    <MonitoringAlertsLayout
      model={model}
      updateModelState={updateModelState}
      breadcrumbs={breadcrumbs}
    >
      <DefaultMatchRemoteDataOrError
        data={alert}
        communication={loadingAlert}
        context="loading alert"
      >
        {(loadedAlert) => (
          <MonitoringAlertDetails alert={loadedAlert} model={model} />
        )}
      </DefaultMatchRemoteDataOrError>
    </MonitoringAlertsLayout>
  );
};

export default MonitoringAlertPage;
