import { URLWithPagination } from 'shared/models/Pagination/PaginationInURL';
import {
  param,
  queryParams,
  route,
  zeroOrMoreSegmentsParam,
} from 'shared/routes/shared/routesTreeBuilder';

const modelVersion = (() => {
  return param()('versionId', {
    tag: 'registeredModelVersion',
  })(
    route('summary', {
      tag: 'modelVersionSummary',
    })(),
    route('reproduce', {
      tag: 'modelVersionReproduce',
    })(),
    route('integrate', {
      tag: 'modelVersionIntegrate',
    })(),
    route('documentation', {
      tag: 'modelVersionDocumentation',
    })(),
    route('govern', {
      tag: 'modelVersionGovern',
    })(),
    route('release', {
      tag: 'modelVersionRelease',
    })(),
    route('workflows', {
      tag: 'modelVersionWorkflows',
    })(),
    route('fairness', {
      tag: 'fairnessPage',
    })()
  );
})();

const modelVersions = route('versions', {
  tag: 'modelVersions',
  queryParams: queryParams<{ experimentRunId: string }>(),
})(
  modelVersion,
  route('create', {
    tag: 'createRegisteredModelVersion',
    queryParams: queryParams<{ experimentRunId: string }>(),
  })(),
  route(
    'compare',
    undefined
  )(
    zeroOrMoreSegmentsParam()('ids', {
      tag: 'registeredModelVersionsCompare',
    })()
  )
);

export const privateRoutes = route('catalog', {
  tag: 'catalog',
  queryParams: queryParams<URLWithPagination>(),
})(
  route('create', {
    tag: 'createRegisteredModel',
  })(),
  route('imported-models', {
    tag: 'importedModels',
  })(
    route('import', {
      tag: 'import',
    })()
  ),
  param()('registeredModelId', {
    tag: 'registeredModel',
  })(
    route('overview', {
      tag: 'modelOverview',
    })(),
    route('documentation', {
      tag: 'modelDocumentation',
    })(),
    route('activities', {
      tag: 'modelActivities',
    })(),
    route('actions', {
      tag: 'modelActions',
    })(),
    modelVersions
  )
);
