import { useMemo } from 'react';

import {
  getLabelByIOType,
  MonitoringIODescription,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import { IOptionType } from 'shared/view/elements/Selects/shared/types';

interface Props {
  name: string;
  label: string;
  ioDescriptions: MonitoringIODescription[];
}

const MonitoringIODescriptionField = (props: Props) => {
  const options = useMemo(
    () => makeOptions(props.ioDescriptions),
    [props.ioDescriptions]
  );

  return (
    <SelectField label={props.label} name={props.name} options={options} />
  );
};

const makeOptions = (
  ioDescriptions: MonitoringIODescription[]
): Array<IOptionType<MonitoringIODescription>> => {
  return ioDescriptions.map((description) => ({
    label: `${getLabelByIOType(description.ioType)}.${description.name}`,
    value: description,
  }));
};

export default MonitoringIODescriptionField;
