import { useCallback, useState } from 'react';

import { OmitStrict } from 'shared/utils/types';
import ConfirmPopup, {
  ConfirmBasicProps,
} from 'shared/view/elements/ConfirmAction/ConfirmPopup/ConfirmPopup';

export interface ConfirmActionBasicProps
  extends OmitStrict<ConfirmBasicProps, 'confirmButtonText'> {
  children: (
    withConfirmAction: <T extends unknown[]>(
      f: (...args: T) => unknown
    ) => (...args: T) => void
  ) => React.ReactNode;
}

interface Props
  extends ConfirmActionBasicProps,
    Pick<ConfirmBasicProps, 'confirmButtonText'> {
  title: string;
  text: JSX.Element | string;
}

const ConfirmAction = (props: Props) => {
  const [isShowConfirm, changeIsShowConfirm] = useState(false);

  const [callback, saveCallback] = useState<(() => void) | null>(null);

  const withConfirm = useCallback(
    <T extends unknown[]>(action: (...args: T) => unknown) => {
      return (...args: T) => {
        saveCallback(() => () => action(...args));
        changeIsShowConfirm(true);
      };
    },
    []
  );

  const onConfirm = useCallback(() => {
    if (callback) {
      callback();
    } else {
      console.error('this.action is undefined');
    }
    changeIsShowConfirm(false);
  }, [callback]);

  return (
    <>
      {props.children(withConfirm)}
      <ConfirmPopup
        isOpen={isShowConfirm}
        onCancel={() => changeIsShowConfirm(false)}
        onConfirm={onConfirm}
        {...props}
      >
        {props.text}
      </ConfirmPopup>
    </>
  );
};

export default ConfirmAction;
