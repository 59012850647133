import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as Types from './graphql-types/useDeleteRegisteredModel.generated';

const MUTATION = gql`
  mutation DeleteRegisteredModel($id: ID!) {
    registeredModel(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        delete
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useDeleteRegisteredModel = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [deleteEntity, deletingEntity] = useCustomMutation<
    Types.DeleteRegisteredModel,
    Types.DeleteRegisteredModelVariables
  >(MUTATION, { context: 'deleting registered model' }, { onCompleted });

  return { deleteEntity, deletingEntity };
};
