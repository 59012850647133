import React from 'react';

import Select from 'shared/view/elements/Selects/Select/Select';

const RegisteredModelField = ({
  changeSelectedModelId,
  registeredModels,
  selectedModelId,
  isLoading,
}: {
  registeredModels?: { id: string; name: string }[];
  selectedModelId: string | null | undefined;
  changeSelectedModelId: (id: string) => void;
  isLoading?: boolean;
}) => {
  const options = registeredModels
    ? registeredModels.map((m) => ({ value: m.id, label: m.name }))
    : [];

  const selectedOption = options.find((opt) => opt.value === selectedModelId);

  return (
    <Select
      options={options}
      value={selectedOption}
      dataTest="registered-model"
      onChange={({ value }) => changeSelectedModelId(value)}
      label="Select registered model"
      isLoading={isLoading}
    />
  );
};

export default RegisteredModelField;
