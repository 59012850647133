import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';

import * as Types from './graphql-types/useKafkaConfigurationTopics.generated';

const QUERY = gql`
  query kafkaConfigurations {
    kafkaConfigurations {
      id
      configurations {
        id
      }
    }
    kafkaTopicsList {
      topics
    }
  }
`;

const useKafkaConfigurationTopics = () => {
  const queryResult = useCustomQuery<Types.kafkaConfigurations>(QUERY);

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult,
  });
};

const convert = (x: Types.kafkaConfigurations) => x;

export default useKafkaConfigurationTopics;
