import { FC, useEffect } from 'react';

import { useDarkMode } from 'features/darkMode/store/hook';

interface Props {
  children: JSX.Element;
}

const DarkModeProvider: FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const { isEnableDarkMode } = useDarkMode();

  useEffect(() => {
    if (isEnableDarkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
  }, [isEnableDarkMode]);

  return <div>{children}</div>;
};

export default DarkModeProvider;
