import { gql } from '@apollo/client';

import { ARTIFACT_FRAGMENT } from 'shared/graphql/Artifact/Artifact';

const CODE_VERSION_GIT_FRAGMENT = gql`
  fragment CodeVersionGit on CodeVersionGit {
    filepaths
    isDirty
    hash
    repo
    branch
  }
`;

const CODE_VERSION_ARCHIVE_FRAGMENT = gql`
  fragment CodeVersionArchive on CodeVersionArchive {
    artifact {
      ...ArtifactData
    }
    filenameExtension
    pathOnly
    path
    type
    key
  }
  ${ARTIFACT_FRAGMENT}
`;

export const CODE_VERSION_FRAGMENT = gql`
  fragment CodeVersion on CodeVersion {
    ... on CodeVersionGit {
      ...CodeVersionGit
    }
    ... on CodeVersionArchive {
      ...CodeVersionArchive
    }
  }
  ${ARTIFACT_FRAGMENT}
  ${CODE_VERSION_GIT_FRAGMENT}
  ${CODE_VERSION_ARCHIVE_FRAGMENT}
`;
