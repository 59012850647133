import { Box, Popover, Stack } from '@mui/material';
import { equals } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import {
  DateRangeCalendar,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { convertTimeRangeToDateRange, TimeRange } from 'shared/utils/TimeRange';
import Button from 'shared/view/elements/Button/Button';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import IconButton from 'shared/view/elements/IconButton/IconButton';
import { usePopoverManager } from 'shared/view/hooks/usePopoverManager';
import isNotNil from 'shared/utils/isNotNill';

interface Props {
  timeRange: TimeRange;
  updateTimeRange: (timeRange: TimeRange) => void;
}

type DateRange = Date | (Date | null | undefined)[] | null | undefined;

const MonitoringTimeRangeControl = (props: Props) => {
  const { isOpen, anchorEl, closePopover, openPopover } = usePopoverManager();

  return (
    <div>
      <IconButton icon={ICONS.calendar} onClick={openPopover} />

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        container={document.getElementById('deep-root')}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MonitoringTimeRangeControlContent {...props} onCancel={closePopover} />
      </Popover>
    </div>
  );
};

const convertTimeRangeToDateRangeArray = (
  timeRange: TimeRange
): [Date, Date] => {
  const converted = convertTimeRangeToDateRange(timeRange);
  return [converted.from, converted.to];
};

const DateTimeRangePicker = ({
  dateRange,
  changeDateRange,
}: {
  dateRange: DateRange;
  changeDateRange: React.Dispatch<React.SetStateAction<DateRange>>;
}) => {
  const startDate = Array.isArray(dateRange) ? dateRange[0] : dateRange;
  const endDate = Array.isArray(dateRange) ? dateRange[1] : dateRange;

  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangeCalendar
        value={[startDate, endDate]}
        onChange={(newValue) => {
          changeDateRange(newValue);
        }}
        sx={{ justifyContent: 'center' }}
        calendars={1}
      />
      <Stack spacing={3}>
        <TimePicker
          open={openStart}
          value={startDate}
          onOpen={() => setOpenStart(true)}
          onClose={() => setOpenStart(false)}
          onChange={(newValue) => {
            const newTimeRange = [newValue, endDate];
            changeDateRange(newTimeRange);
          }}
          slotProps={{
            textField: {
              onClick: () => setOpenStart(true),
            },
          }}
          label="Start time"
        />
        <TimePicker
          open={openEnd}
          value={endDate}
          onOpen={() => setOpenEnd(true)}
          onClose={() => setOpenEnd(false)}
          onChange={(newValue) => {
            const newTimeRange = [startDate, newValue];
            changeDateRange(newTimeRange);
          }}
          slotProps={{
            textField: {
              onClick: () => setOpenEnd(true),
            },
          }}
          label="End time"
        />
      </Stack>
    </LocalizationProvider>
  );
};

const MonitoringTimeRangeControlContent = (
  props: Props & { onCancel: () => void }
) => {
  const [dateRange, changeDateRange] = useState<DateRange>(
    convertTimeRangeToDateRangeArray(props.timeRange)
  );

  const { updateTimeRange, onCancel } = props;
  const onApply = useCallback(() => {
    if (Array.isArray(dateRange)) {
      const dateRangeArray = dateRange.filter(isNotNil);

      if (dateRangeArray.length === 2) {
        updateTimeRange({
          start: dateRangeArray[0].toISOString(),
          end: dateRangeArray[1].toISOString(),
        });
      }
    }

    onCancel();
  }, [dateRange, updateTimeRange, onCancel]);

  const disabled = useMemo(() => {
    const isEqual = equals(
      convertTimeRangeToDateRangeArray(props.timeRange),
      dateRange
    );
    const isValid =
      Array.isArray(dateRange) && dateRange.filter(isNotNil).length === 2;
    return isEqual || !isValid;
  }, [dateRange, props.timeRange]);

  return (
    <Box sx={{ padding: '16px' }}>
      <Stack spacing={2}>
        <DateTimeRangePicker
          dateRange={dateRange}
          changeDateRange={changeDateRange}
        />
        <Stack direction="row" justifyContent="flex-end">
          <Button variant="outlined" isLoading={false} onClick={props.onCancel}>
            Cancel
          </Button>
          <Button onClick={onApply} disabled={disabled} isLoading={false}>
            Apply
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default MonitoringTimeRangeControl;
