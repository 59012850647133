import { MutationHookOptions } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import {
  CREATE_EVALUATION,
  CreateEvaluation,
  CreateEvaluationVariables,
} from '../graphql';

export const useCreateEvaluation = (
  options?: MutationHookOptions<CreateEvaluation, CreateEvaluationVariables>
) => {
  const [createEvaluation, creatingEvaluation] = useCustomMutation<
    CreateEvaluation,
    CreateEvaluationVariables
  >(CREATE_EVALUATION, { context: 'creating evaluation' }, { ...options });

  return { createEvaluation, creatingEvaluation };
};
