import { HttpActions } from 'services/HttpActions';
import { IUploadArtifactService } from 'features/artifactManager/store/types';

const ArtifactDataService: IUploadArtifactService = {
  async uploadArtifact(
    versionId,
    artifact,
    key,
    controller,
    onUploadProgress,
    orgId
  ): Promise<void> {
    await HttpActions.post<void>({
      url: `v1/artifact-upload/model_versions/${versionId}/artifacts/${key}`,
      data: artifact,
      config: {
        headers: {
          'Content-Disposition': `attachment; filename="${key}"`,
          'Grpc-Metadata-organization-id': orgId,
        },
        signal: controller.signal,
        onUploadProgress,
      },
    });
  },
};

export default ArtifactDataService;
