import { Stack } from '@mui/material';

import Chip from 'shared/view/elements/Chip/Chip';

interface FilterItemType {
  id: number;
  formattedString: string;
}

interface Props {
  filters: FilterItemType[];
  removeFilter: (filterId: number) => void;
}

const FiltersList = (props: Props) => {
  return (
    <Stack direction="row" flexWrap="wrap" spacing={0} gap={1}>
      {props.filters.map((filter) => (
        <FilterItem
          key={filter.id}
          filter={filter}
          removeFilter={props.removeFilter}
        />
      ))}
    </Stack>
  );
};

const FilterItem = (props: {
  filter: FilterItemType;
  removeFilter: (filterId: number) => void;
}) => {
  return (
    <Chip
      onDelete={() => props.removeFilter(props.filter.id)}
      label={props.filter.formattedString}
      color="primary"
      variant="outlined"
    />
  );
};

export default FiltersList;
