import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';

import {
  MODEL_VERSION_ACTIVITIES_DATA_QUERY,
  ModelVersionActivitiesData,
  ModelVersionActivitiesDataVariables,
} from '../graphql';

type Props = ModelVersionActivitiesDataVariables;

export const useModelVersionActivities = ({ id }: Props) => {
  const query = useCustomQuery<
    ModelVersionActivitiesData,
    ModelVersionActivitiesDataVariables
  >(MODEL_VERSION_ACTIVITIES_DATA_QUERY, {
    variables: { id },
  });

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return {
    data,
    communication,
    reload: query.refetch,
  };
};

const convertResponse = ({
  registeredModelVersion,
}: ModelVersionActivitiesData) =>
  mapDataOrError(registeredModelVersion, (version) => version.activities);
