import { MouseEvent } from 'react';

import ToggleButtonGroup from 'shared/view/elements/ToggleButtonGroup/ToggleButtonGroup';
import ToggleButton from 'shared/view/elements/ToggleButton/ToggleButton';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

export type ToggleOrgOptions = true | false;

type ToggleOrgProps = {
  value: ToggleOrgOptions;
  onChange: (event: MouseEvent, arg: ToggleOrgOptions) => void;
};

const ToggleOrg = (props: ToggleOrgProps) => {
  const { value, onChange, ...other } = props;

  return (
    <ToggleButtonGroup<ToggleOrgOptions>
      value={value}
      exclusive
      onChange={onChange}
      aria-label="toogle org"
      {...other}
    >
      <ToggleButton<ToggleOrgOptions>
        value={false}
        aria-label="current org"
        size="medium"
        color="primary"
        title="Current Org"
      >
        <IconAwesomeCustomizable icon={ICONS.box} size="lg" />
      </ToggleButton>
      <ToggleButton<ToggleOrgOptions>
        value={true}
        aria-label="all orgs"
        size="medium"
        color="primary"
        title="All Orgs"
      >
        <IconAwesomeCustomizable icon={ICONS.boxesStacked} size="lg" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleOrg;
