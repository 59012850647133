import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { TabsLayout } from 'pages/authorized/shared/TabsLayout';

const ImportModels = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ImportModels' */ 'features/catalog/importModels/import/view/import'
    )
);

const ImportPage = () => {
  const { workspaceName } = useRouteParams(routes.import);

  const breadcrumbs = useAppBreadcrumbs(
    useBreadcrumbItem({ name: 'Model catalog', route: routes.catalog }),
    useBreadcrumbItem({
      name: 'Imported models page',
      route: routes.importedModels,
    }),
    useBreadcrumbItem({ name: 'Import models', route: routes.import })
  );

  return (
    <TabsLayout breadcrumbs={breadcrumbs} pageEntityOrError={undefined}>
      <ImportModels workspaceName={workspaceName} />
    </TabsLayout>
  );
};

export default ImportPage;
