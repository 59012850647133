import { useCallback, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';

import routes from 'shared/routes';
import PagesTabs, { Tab } from 'shared/view/pages/PagesTabs/PagesTabs';
import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import { AppBreadcrumbs } from 'features/layout/view/Layout/AppBreadcrumbs';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import Alert from 'shared/view/elements/Alert/Alert';
import { StorageManager } from 'shared/utils/storage';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
import MonitoringModelInfoPopover from 'shared/view/domain/Monitoring/MonitoringModel/MonitoringModelInfoPopover/MonitoringModelInfoPopover';
import CreateDashboardAction from 'features/monitoring/dashboards/dashboardSettings/view/CreateDashboardAction/CreateDashboardAction';
import { MonitoringDashboard } from 'shared/models/Monitoring/MonitoringModel/MonitoringDashboard/MonitoringDashboard';
import { selectFlags } from 'features/flags';
import isNotNil from 'shared/utils/isNotNill';
import { AlertStatus } from 'generated/types';
import PageSettingsIcon from 'shared/view/elements/PageSettingsIcon/PageSettingsIcon';
import { MenuAction } from 'shared/view/elements/ActionsMenu/ActionsMenu';

interface Props {
  model: MonitoringModel;
  breadcrumbs: AppBreadcrumbs;
  pageSettingsActions: MenuAction[];
  children: JSX.Element;
  layoutActions?: JSX.Element[];
  createDashboard?: (dashboard: MonitoringDashboard) => void;
}

const monitoringAlertIsOpenStorage = new StorageManager<boolean>(
  'monitoringAlertIsOpen',
  localStorage
);

const useMonitoringModelTabs = (props: { model: MonitoringModel }) => {
  const { monitoring } = useSelector(selectFlags);

  const alertingAlertsCount = useMemo(
    () =>
      props.model.alerts.filter((a) => a.status === AlertStatus.ALERTING)
        .length,
    [props.model.alerts]
  );

  return useMemo(() => {
    const dashboardsTabs: Array<Tab> = props.model.state.dashboards.map(
      (d) => ({
        label: d.name,
        to: routes.monitoringDashboard.getRedirectPath({
          workspaceName: props.model.workspaceName,
          monitoringModelId: props.model.id,
          dashboardId: d.id,
        }),
        dataTest: 'dashboard-tab',
      })
    );

    const newUxTabs = [
      {
        label: 'Overview',
        to: routes.monitoringOverview.getRedirectPath({
          workspaceName: props.model.workspaceName,
          monitoringModelId: props.model.id,
        }),
      },
      {
        label: 'Performance',
        to: routes.monitoringPerformance.getRedirectPath({
          workspaceName: props.model.workspaceName,
          monitoringModelId: props.model.id,
        }),
      },
    ];

    const tabs: Array<Tab> = [
      ...(monitoring.isEnableNewMonitoringUX ? newUxTabs : dashboardsTabs),
      monitoring.isEnableMonitoringAlerts
        ? {
            label: 'Alerts',
            to: routes.monitoringAlerts.getRedirectPath({
              workspaceName: props.model.workspaceName,
              monitoringModelId: props.model.id,
            }),
            counter: {
              value: alertingAlertsCount,
              color: 'error' as const,
            },
          }
        : null,
      monitoring.isEnableMonitoringCompare
        ? {
            label: 'Compare',
            to: routes.monitoringCompare.getRedirectPath({
              monitoringModelId: props.model.id,
              workspaceName: props.model.workspaceName,
            }),
          }
        : null,
    ].filter(isNotNil);

    return tabs;
  }, [
    props.model.state.dashboards,
    props.model.workspaceName,
    props.model.id,
    monitoring.isEnableNewMonitoringUX,
    monitoring.isEnableMonitoringAlerts,
    monitoring.isEnableMonitoringCompare,
    alertingAlertsCount,
  ]);
};

const MonitoringModelDetailsLayout = (props: Props) => {
  const { isPopupOpen, closePopup } = usePopupManager(
    monitoringAlertIsOpenStorage.read() ?? true
  );

  const onCloseAlert = useCallback(() => {
    monitoringAlertIsOpenStorage.write(false);
    closePopup();
  }, [closePopup]);

  const tabs = useMonitoringModelTabs({
    model: props.model,
  });

  return (
    <AuthorizedLayout
      {...props}
      pageEntityOrError={props.model}
      breadcrumbs={props.breadcrumbs}
      titleAdditionalContent={
        <MonitoringModelInfoPopover model={props.model} />
      }
      tabsElement={
        <PagesTabs
          tabs={tabs}
          rightContent={
            <PageSettingsIcon actions={props.pageSettingsActions} />
          }
        />
      }
      actions={[
        props.createDashboard ? (
          <CreateDashboardAction
            key="create-dashboard"
            createDashboard={props.createDashboard}
            model={props.model}
          />
        ) : null,
        ...(props.layoutActions ?? []),
      ].filter(isNotNil)}
    >
      <Stack spacing={2}>
        {!props.model.monitoredEntity.allowedActions.update && isPopupOpen ? (
          <Alert title="User permissions" onClose={onCloseAlert}>
            Changes are not available for saving based on your permissions for
            this entity.
          </Alert>
        ) : null}
        <div>{props.children}</div>
      </Stack>
    </AuthorizedLayout>
  );
};

export default MonitoringModelDetailsLayout;
