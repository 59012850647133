import { FC } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import WidgetCreator from 'features/experimentRuns/crossRunDashboard/view/WidgetCreator/WidgetCreator';
import IconAwesomeClickableWithLabel from 'shared/view/elements/IconAwesome/withLabel/IconAwesomeClickableWithLabel';
import DefaultMatchRemoteMutation from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteMutation';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import { UseCrossRunDashboardForComparingResult } from '../../../store/useCrossRunDashboardForComparing';

type Props = UseCrossRunDashboardForComparingResult;

const Panel: FC<React.PropsWithChildren<Props>> = (props) => {
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <AddWidgetAction {...props} />
      </Grid>
      <Grid item>
        {props.dashboardChangesActions ? (
          <DefaultMatchRemoteMutation
            communication={
              props.dashboardChangesActions.save.savingDashboardChanges
            }
          >
            <Stack direction="row" spacing={1}>
              <IconAwesomeClickableWithLabel
                onClick={
                  props.dashboardChangesActions.save.saveDashboardChanges
                }
                icon={ICONS.bookmark}
                label={'Save Changes'}
              />
              <IconAwesomeClickableWithLabel
                onClick={
                  props.dashboardChangesActions.reset.resetDashboardChanges
                }
                icon={ICONS.arrowRotateLeft}
                label={'Reset Changes'}
              />
            </Stack>
          </DefaultMatchRemoteMutation>
        ) : null}
      </Grid>
    </Grid>
  );
};

const AddWidgetAction = (props: UseCrossRunDashboardForComparingResult) => {
  return (
    <WidgetCreator
      experimentRuns={props.experimentRuns}
      onCreate={props.createWidget}
    >
      {(openWidgetCreator) => (
        <IconAwesomeClickableWithLabel
          icon={ICONS.circlePlus}
          label={'Charts'}
          onClick={openWidgetCreator}
          type={'header'}
        />
      )}
    </WidgetCreator>
  );
};

export default Panel;
