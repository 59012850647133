import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { MenuAction } from 'shared/view/elements/ActionsMenu/ActionsMenu';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { useDeleteEntityAction } from 'features/shared/DeleteEntityIcon/useDeleteEntityAction';
import routes from 'shared/routes';
import PageSettingsIcon from 'shared/view/elements/PageSettingsIcon/PageSettingsIcon';
import { ModelLayout } from 'pages/authorized/CatalogPages/ModelPages/shared/Layout/store';
import { isNotRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';

import ModelEditor from './ModelEditor/ModelEditor';

interface Props {
  model?: ModelLayout['registeredModel'];
}

const ModelPageSettings = (props: Props) => {
  const workspaceName = useWorkspaceNameFromURL();

  const registeredModel = isNotRestrictedGraphqlError(props.model)
    ? props.model
    : undefined;

  const editorPopup = usePopupManager();

  const editAction: MenuAction = {
    type: 'button',
    label: 'Edit model information',
    disabled: !registeredModel?.allowedActions.update,
    dataTest: 'edit-info-menu-item',
    onClick: editorPopup.openPopup,
    additionalContent: registeredModel ? (
      <ModelEditor
        isOpen={editorPopup.isPopupOpen}
        registeredModel={registeredModel}
        onClose={editorPopup.closePopup}
        isEditable={registeredModel.allowedActions.update}
      />
    ) : null,
  };

  const deleteAction = useDeleteEntityAction({
    entityType: 'registeredModel',
    id: registeredModel?.id ?? null,
    redirectUrl: routes.catalog.getRedirectPath({
      workspaceName,
    }),
    isNotAllowedToDelete: !registeredModel?.allowedActions.delete,
  });

  return <PageSettingsIcon actions={[editAction, deleteAction]} />;
};

export default ModelPageSettings;
