import { Box, BoxProps } from '@mui/material';

import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { IconAwesomeProps } from 'shared/view/elements/IconAwesome/IconAwesomeProps';

type Props = BoxProps & Pick<IconAwesomeProps, 'size'>;

export const SelectIconComponent = (props: Props) => (
  <Box display="flex" {...props}>
    <IconAwesomeCustomizable icon={ICONS.chevronDown} size="1x" {...props} />
  </Box>
);
