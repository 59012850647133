import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  MonitoringDashboard,
  makeNewMonitoringDashboard,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringDashboard/MonitoringDashboard';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import Button from 'shared/view/elements/Button/Button';
import { selectCurrentUserOrThrowError } from 'features/user';
import NameFormPopup, {
  NameFormPopupType,
  NameFormType,
} from 'shared/view/domain/Dashboards/NameForm/NameFormPopup';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import routes from 'shared/routes';
import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';

interface Props {
  model: MonitoringModel;
  createDashboard: (dashboard: MonitoringDashboard) => void;
}

const initialValues: NameFormType = {
  name: '',
};

const CreateDashboardAction = ({ createDashboard, model }: Props) => {
  const { openPopup, isPopupOpen, closePopup } = usePopupManager();

  const navigate = useNavigate();

  const currentUser = useSelector(selectCurrentUserOrThrowError);

  const onSubmit = useCallback(
    (form: NameFormType) => {
      const newDashboard = makeNewMonitoringDashboard({
        name: form.name,
        currentUser,
      });
      createDashboard(newDashboard);
      navigate(
        routes.monitoringDashboard.getRedirectPath({
          dashboardId: newDashboard.id,
          monitoringModelId: model.id,
          workspaceName: model.workspaceName,
        })
      );
      closePopup();
    },
    [
      currentUser,
      createDashboard,
      navigate,
      model.id,
      model.workspaceName,
      closePopup,
    ]
  );

  return (
    <div>
      <Button
        onClick={openPopup}
        isLoading={false}
        dataTest="add-dashboard"
        variant="outlined"
        icon={ICONS.circlePlus}
      >
        Dashboard
      </Button>
      {isPopupOpen ? (
        <NameFormPopup
          entityName="dashboard"
          type={NameFormPopupType.Add}
          isOpen={isPopupOpen}
          initialValues={initialValues}
          onSubmit={onSubmit}
          closePopup={closePopup}
        />
      ) : null}
    </div>
  );
};

export default CreateDashboardAction;
