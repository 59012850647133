import { useApolloClient } from '@apollo/client';
import { gql } from '@apollo/client';

import {
  useBreadcrumbItem,
  useAppBreadcrumbs,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { ICommunication } from 'shared/utils/redux/communication';
import routes from 'shared/routes';
import { useProjectsPageBreadcrumb } from 'pages/authorized/ProjectsPages/shared/projectsPageBreadcrumb';
import { OmitStrict } from 'shared/utils/types';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
import {
  isNotNullableRestrictedGraphqlError,
  NotNullableRestrictedGraphqlError,
  RestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import PageSettingsIcon from 'shared/view/elements/PageSettingsIcon/PageSettingsIcon';
import { useProjectSettingsActions } from 'features/projects/projectSettings/store/useProjectSettingsActions';
import { UpdatedAtTagGraphql } from 'pages/authorized/CatalogPages/shared/UpdatedAtTag';
import IdTopEnhancer from 'shared/view/elements/IdTopEnhancer/IdTopEnhancer';
import { useRouteParams } from 'shared/utils/router/useRouteParams';

import ProjectPageTabs from '../ProjectPageTabs/ProjectPageTabs';
import useDefaultProject, {
  IWithDefaultProject,
} from './useDefaultProjects/useDefaultProject';
import * as ProjectBreadcrumbGraphql from './graphql-types/ProjectDetailsLayout.generated';

export interface BaseProject {
  __typename: 'Project';
  id: string;
  name: string;
  owner: { __typename: 'Group' | 'User'; id: string };
  description: string;
  dateUpdated: number;
  allowedActions: {
    delete: boolean;
    update: boolean;
  };
}

export function ProjectDetailsLayoutWithLoading<T extends BaseProject>({
  data,
  projectId,
  projectName,
  loadingData,
  children,
  ...layoutProps
}: {
  projectId: string;
  projectName: string | undefined;
  data: T | RestrictedGraphqlError | null | undefined;
  loadingData: ICommunication;
  children: ({
    loadedData,
  }: {
    loadedData: NotNullableRestrictedGraphqlError<T>;
  }) => JSX.Element;
}) {
  const workspaceName = useWorkspaceNameFromURL();

  const actions = useProjectSettingsActions({
    workspaceName,
    project: isNotNullableRestrictedGraphqlError(data) ? data : null,
  });

  return (
    <Layout
      {...layoutProps}
      tabsElement={
        <ProjectPageTabs
          projectId={projectId}
          rightContent={<PageSettingsIcon actions={actions} />}
        />
      }
      pageEntityOrError={data}
      projectName={projectName}
      actions={[
        <UpdatedAtTagGraphql
          key="updated-at-tag"
          dateUpdated={
            isNotNullableRestrictedGraphqlError(data)
              ? data.dateUpdated
              : undefined
          }
        />,
      ]}
    >
      <DefaultMatchRemoteDataOrError
        communication={loadingData}
        data={data}
        context={'loading project'}
      >
        {(loadedData) => children({ loadedData })}
      </DefaultMatchRemoteDataOrError>
    </Layout>
  );
}

export function ProjectDetailsLayoutWithDefaultProjectLoading({
  children,
  ...restLayoutProps
}: {
  children: (data: IWithDefaultProject) => JSX.Element;
  actions?: JSX.Element[] | null;
}) {
  const params = useRouteParams(routes.project);
  const { project, loadingProject } = useDefaultProject();

  return (
    <ProjectDetailsLayoutWithLoading
      data={project || null}
      loadingData={loadingProject}
      projectId={params.projectId}
      projectName={
        isNotNullableRestrictedGraphqlError(project) ? project.name : undefined
      }
      {...restLayoutProps}
    >
      {({ loadedData }) => children({ project: loadedData })}
    </ProjectDetailsLayoutWithLoading>
  );
}

const Layout = (
  props: OmitStrict<
    React.ComponentProps<typeof AuthorizedLayout>,
    'breadcrumbs'
  > & {
    projectName: string | undefined;
  }
) => {
  const { projectId } = useRouteParams(routes.project);
  const apolloClient = useApolloClient();
  const projectName = (() => {
    try {
      const res = apolloClient.readFragment<
        ProjectBreadcrumbGraphql.ProjectBreadcrumb,
        unknown
      >({
        id: `Project:${projectId}`,
        fragment: gql`
          fragment ProjectBreadcrumb on Project {
            id
            name
          }
        `,
      });
      return res?.name;
    } catch {
      return props.projectName;
    }
  })();

  const topEnhancer = <IdTopEnhancer id={projectId} />;

  // todo fix with middle breadcrumb Summary/Experiments/ERs etc
  const breadcrumbs = useAppBreadcrumbs(
    useProjectsPageBreadcrumb(),
    useBreadcrumbItem({
      route: routes.projectOverview,
      hasTopEnhancer: true,
      ...(projectName
        ? { name: projectName, topEnhancer }
        : { isLoading: true }),
    })
  );

  return <AuthorizedLayout {...props} breadcrumbs={breadcrumbs} />;
};
