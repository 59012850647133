import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import { IOptionType } from 'shared/view/elements/Selects/shared/types';

const VariantTypeField = (props: {
  name: string;
  options: Array<IOptionType>;
}) => {
  return (
    <SelectField label="Variant" name={props.name} options={props.options} />
  );
};

export default VariantTypeField;
