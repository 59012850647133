import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';
import { CustomRedirect } from 'shared/routes/shared/CustomRedirect';

import SummaryPage from './SummaryPage/SummaryPage';
import ReleasePage from './ReleasePage/ReleasePage';
import IntegratePage from './IntegratePage/IntegratePage';
import ReproducePage from './ReproducePage/ReproducePage';
import WorkflowsPage from './WorkflowsPage/WorkflowsPage';
import FairnessPage from './FairnessPage/FairnessPage';
import RegisteredModelVersionCreationPage from './ModelVersionCreationPage/ModelVersionCreationPage';
import { Layout } from './shared/Layout/view';
import ModelVersionDocumentationPage from './DocumentationPage/DocumentationPage';
import GovernPage from './GovernPage/GovernPage';

export const modelVersionPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.registeredModel.getPath(),
      element: <Layout />,
      children: [
        {
          path: routes.modelVersionSummary.getPath(),
          element: <SummaryPage />,
        },
        {
          path: routes.modelVersionDocumentation.getPath(),
          element: <ModelVersionDocumentationPage />,
        },
        {
          path: routes.modelVersionReproduce.getPath(),
          element: <ReproducePage />,
        },
        {
          path: routes.modelVersionIntegrate.getPath(),
          element: <IntegratePage />,
        },
        {
          path: routes.modelVersionGovern.getPath(),
          element: <GovernPage />,
        },
        {
          path: routes.modelVersionRelease.getPath(),
          element: <ReleasePage />,
        },
        {
          path: routes.modelVersionWorkflows.getPath(),
          element: <WorkflowsPage />,
        },
        {
          path: routes.fairnessPage.getPath(),
          element: <FairnessPage />,
        },

        {
          path: `${routes.registeredModelVersion.getPath()}/*`,
          element: (
            <CustomRedirect route={routes.modelVersionSummary} replace />
          ),
        },
      ],
    },
    {
      path: routes.registeredModel.getPath(),
      children: [
        {
          path: routes.createRegisteredModelVersion.getPath(),
          element: <RegisteredModelVersionCreationPage />,
        },
      ],
    },
  ],
};
