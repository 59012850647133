import { useEffect, useState } from 'react';

import capitalize from 'shared/utils/capitalize';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { Stage } from 'generated/types';
import { useToast } from 'features/toast/store/hooks';
import { trackEvent } from 'setup/app/analytics';
import { LoadChecklistTemplates } from 'features/catalog/shared/store/useLoadChecklistTemplates/graphql-types/index.generated';

import { RegisteredModelVersion } from '../../graphql';
import { useCrossClusterVersion } from '../../hooks/useCrossClusterVersion';
import SplitButton from './SplitButton';
import TransitionRequestPopup from './TransitionRequestPopup';

interface Props {
  version: RegisteredModelVersion | null;
  checklistTemplates:
    | LoadChecklistTemplates['checklistTemplates']['checklistTemplates']
    | null;
}

const TransitionRequesterButton = (props: Props) => {
  const alertToast = useToast();
  const { version, checklistTemplates } = props;
  const [desiredStage, setDesiredStage] = useState<Stage>(Stage.UNASSIGNED);

  const { stageLabel, items, hasCrossClusterLink, crossClusterInProd } =
    useCrossClusterVersion(version, checklistTemplates);
  const popupManager = usePopupManager();

  const handleItemClick = (value: Stage) => {
    setDesiredStage(value);
    trackEvent({ type: 'catalog.registered_model_version.stage_changed' });
    popupManager.openPopup();
  };

  useEffect(() => {
    if (crossClusterInProd && !hasCrossClusterLink)
      alertToast(
        'Warning: The stage change request will take a while based on the cross-cluster process',
        'warning'
      );
  }, [alertToast, crossClusterInProd, hasCrossClusterLink]);

  return version ? (
    <>
      <SplitButton
        mainButton={{ text: capitalize(stageLabel) }}
        color={version.stage === Stage.PRODUCTION ? 'success' : 'primary'}
        onItemClick={handleItemClick}
        items={items}
        disabled={Boolean(crossClusterInProd) && !hasCrossClusterLink}
      />
      <TransitionRequestPopup
        isOpen={popupManager.isPopupOpen}
        versionId={version.id}
        onClose={popupManager.closePopup}
        isEditable={version.registeredModel.allowedActions.update}
        stage={version.stage}
        desiredStage={desiredStage}
      />
    </>
  ) : null;
};

export default TransitionRequesterButton;
