import { gql } from '@apollo/client';
import { useCallback } from 'react';

import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { MONITORING_MODEL_FRAGMENT } from 'shared/graphql/monitoring/MonitoringModel/MonitoringModel';

import {
  UpdateMonitoringModel,
  UpdateMonitoringModelVariables,
} from './graphql-types/useUpdateMonitoringModel.generated';

const UPDATE_MONITORING_MODEL = gql`
  mutation UpdateMonitoringModel($monitoringModelId: ID!, $json: String!) {
    monitoredModel(id: $monitoringModelId) {
      ... on Error {
        ...ErrorData
      }
      ... on MonitoredModel {
        id
        updateJson(json: $json) {
          id
          json
          ...MonitoringModelFragment
        }
      }
    }
  }
  ${MONITORING_MODEL_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useUpdateMonitoringModel = () => {
  const [mutate, communication] = useCustomMutation<
    UpdateMonitoringModel,
    UpdateMonitoringModelVariables
  >(UPDATE_MONITORING_MODEL, {
    context: 'updating model',
  });

  const updateMonitoringModel = useCallback(
    (model: MonitoringModel) => {
      mutate({
        monitoringModelId: model.id,
        json: JSON.stringify(model.state),
      });
    },
    [mutate]
  );

  return {
    updateMonitoringModel,
    communication,
  };
};
