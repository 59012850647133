import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { ACTIVITY_FRAGMENT } from 'shared/graphql/registry/stage/Activity';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { CUSTOM_ATTRIBUTE_VALUE_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeValue';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import { CHECKLIST_ITEM_VALUES_FRAGMENT } from 'shared/graphql/Checklist/ChecklistItemValues';
import { ACTIVITY_AFTER_UPDATING_FRAGMENT } from 'shared/graphql/registry/stage/Activity';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';

const MODEL_VERSION_BASIC_FRAGMENT = gql`
  fragment ModelVersionBasicData on RegisteredModelVersion {
    id
    version
    description
    lockLevel
    stage
    redirectMetadata
  }
`;

export const MODEL_VERSION_LAYOUT = gql`
  query ModelVersionLayout($versionId: ID!) {
    registeredModelVersion(id: $versionId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        ...ModelVersionBasicData
        attributes {
          ...AttributeData
        }
        activities {
          id
          ...ActivityFragment
        }
        owner {
          ...OwnerData
        }
        registeredModel {
          id
          name
          dataType
          taskType
          allowedActions {
            ...AllowedActionsData
          }
          versions {
            versions {
              id
              version
              stage
            }
          }
        }
        customAttributeValues {
          id
          ...CustomAttributeValueData
        }
        checklistItemValues {
          ...ChecklistItemValuesData
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${MODEL_VERSION_BASIC_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_VALUE_FRAGMENT}
  ${CHECKLIST_ITEM_VALUES_FRAGMENT}
  ${OWNER_FRAGMENT}
`;

export const CREATE_TRANSITION_QUERY = gql`
  mutation CreateTransition(
    $registeredModelVersionId: ID!
    $desiredStage: Stage!
    $comment: String
  ) {
    registeredModelVersion(id: $registeredModelVersionId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        createTransition(desiredStage: $desiredStage, comment: $comment) {
          id
          ...ActivityAfterUpdatingFragment
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ACTIVITY_AFTER_UPDATING_FRAGMENT}
`;

export const UPDATE_STAGE_QUERY = gql`
  mutation UpdateStage(
    $registeredModelVersionId: ID!
    $desiredStage: Stage!
    $comment: String
  ) {
    registeredModelVersion(id: $registeredModelVersionId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        updateStage(
          versionId: $registeredModelVersionId
          stage: $desiredStage
          comment: $comment
        ) {
          id
          ...ActivityAfterUpdatingFragment
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ACTIVITY_AFTER_UPDATING_FRAGMENT}
`;
