import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';

import { selectFlags, updateFeatureFlag } from 'features/flags';
import Button from 'shared/view/elements/Button/Button';

const FeatureFlagManager = ({ onClose }: { onClose: () => void }) => {
  const featureFlags = useSelector(selectFlags);
  const dispatch = useDispatch();
  const [flagsJson, setFlagsJson] = useState(
    JSON.stringify(featureFlags, null, 2)
  );

  const updateFlags = () => {
    dispatch(updateFeatureFlag(JSON.parse(flagsJson)));
    onClose();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFlagsJson(e.target.value);
  };

  return (
    <Box
      sx={{
        height: '80vh',
        width: '80vw',
        padding: '2rem',
        margin: 'auto',
      }}
    >
      <Box display="flex" flexDirection="column">
        <TextField
          id="outlined-multiline-static"
          name="flags"
          label="Feature Flags"
          multiline
          rows={25}
          value={flagsJson}
          onChange={handleChange}
          margin="normal"
        />
        <Box display="flex" gap="10px">
          <Button isLoading={false} onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button isLoading={false} onClick={updateFlags}>
            Update flags
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FeatureFlagManager;
