import { AllowedActionsData } from 'shared/graphql/collaborators/graphql-types/fragments.generated';
import { OmitStrict } from 'shared/utils/types';
import { TimeRange } from 'shared/utils/TimeRange';
import { OwnerData } from 'shared/graphql/graphql-types/OwnerFragment.generated';

import { MonitoringAggregation } from './MonitoringAggregation';
import { MonitoringAlert } from './MonitoringAlert/MonitoringAlert';
import { MonitoringDashboard } from './MonitoringDashboard/MonitoringDashboard';
import { MonitoringIODescription } from './MonitoringIODescription';
import { MonitoringModelRegisteredModelVersion } from './MonitoringModelRegisteredModelVersion';
import { MonitoringFilter } from '../MonitoringFilters/MonitoringFilter';
import { MonitoredModelType } from './MonitoredModelType';
import { MonitoringModelStatus } from './MonitoringModelStatus';

interface MonitoringModelEndpoint {
  id: string;
  path: string;
  dateUpdated: string;
}

interface MonitoringModelMonitoredEntity {
  id: string;
  name: string;
  allowedActions: AllowedActionsData;
}

export interface MonitoringModelWithoutState {
  id: string;
  name: string;
  type: MonitoredModelType;
  createdAt: string;
  workspaceName: string;
  monitoredEntity: MonitoringModelMonitoredEntity;
  endpoint: MonitoringModelEndpoint | undefined;
  owner: OwnerData;
  alerts: Array<Pick<MonitoringModelAlert, 'id' | 'status'>>;
  registeredModelVersions: MonitoringModelRegisteredModelVersion[];
  status: MonitoringModelStatus;
}

export interface MonitoringModel
  extends OmitStrict<MonitoringModelWithoutState, 'alerts'> {
  state: MonitoringModelState;
  ioDescriptions: MonitoringIODescription[];
  alerts: MonitoringModelAlert[];
}

export type MonitoringModelAlert = Pick<
  MonitoringAlert,
  'id' | 'status' | 'settings' | 'featureName'
>;

export interface MonitoringModelState {
  stateMonitoredModelType: MonitoredModelType;
  registeredModelVersion: MonitoringModelRegisteredModelVersion;
  aggregation: MonitoringAggregation;
  dashboards: MonitoringDashboard[];
  timeRange: TimeRange;
  version: string;
  filters: MonitoringFilter[];
  compare: {
    registeredModelVersions: MonitoringModelRegisteredModelVersion[];
    dashboard: MonitoringDashboard;
  };
}

export const CURRENT_MONITORING_MODEL_VERSION = '1.0.24';

export class MonitoringNotInitializedError extends Error {
  constructor(
    public model?: Pick<MonitoringModel, 'name' | 'workspaceName' | 'id'>
  ) {
    const message = 'Waiting for data to arrive. Try to visit this page later.';
    super(message);
  }
}
