import TextField from '@mui/material/TextField';
import { useCallback } from 'react';

import {
  InputBase,
  InputCommonProps,
  useInputCommonProps,
} from 'shared/view/elements/TextInput/shared/InputBase';
import isNotNil from 'shared/utils/isNotNill';

import InfoIcon from '../InfoIcon/InfoIcon';

type NumberInputSpecificProps = {
  value?: number;
  onChange(value: number): void;
  min?: number;
  max?: number;
  step?: number;
};

export type NumberInputProps = InputCommonProps & NumberInputSpecificProps;

export default function NumberInput(props: NumberInputProps) {
  const { min, max, step, value, onChange } = props;
  const infoWithMinMaxStep = getInfoWithMinMaxStep(props);
  const { baseProps, fieldProps } = useInputCommonProps(
    {
      ...props,
      endAdornmentIcons: infoWithMinMaxStep
        ? [<InfoIcon key={0} tooltip={infoWithMinMaxStep} />]
        : undefined,
    },
    { min, max, step }
  );

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const inputValue = e.target.value;

      onChange(Number(inputValue));
    },
    [onChange]
  );

  return (
    <InputBase {...baseProps}>
      <TextField
        {...fieldProps}
        onChange={onChangeInput}
        value={String(value)}
        type="number"
      />
    </InputBase>
  );
}

function getInfoWithMinMaxStep({
  min,
  max,
  step,
}: Pick<NumberInputProps, 'min' | 'max' | 'step'>): string | undefined {
  const additionalInfo = [
    getAdditionalInfoItem('Min', min),
    getAdditionalInfoItem('Max', max),
    getAdditionalInfoItem('Step', step),
  ]
    .filter(isNotNil)
    .join(', ');

  return (
    [additionalInfo ? `(${additionalInfo})` : null]
      // do not use isNotNil because empty string also not allowed
      .filter((value) => value)
      .join('. ')
  );
}

function getAdditionalInfoItem(
  label: string,
  value?: number
): string | undefined {
  return isNotNil(value) ? `${label}: ${value}` : undefined;
}
