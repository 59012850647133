import { Grid } from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import {
  validateNotEmpty,
  combineValidators,
  validateMaxLength,
} from 'shared/utils/validators';
import Popup from 'shared/view/elements/Popup/Popup';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { ICONS, IconsProps } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { CustomAttributeCategoryInput } from 'generated/types';
import Alert from 'shared/view/elements/Alert/Alert';

import { useCreateOrUpdateCustomAttributeCategory } from '../../hooks/useCreateOrUpdateCustomAttributeCategory';

const EMPTY_CUSTOM_ATTRIBUTE_TEMPLATE_INPUT: CustomAttributeCategoryInput = {
  name: '',
  icon: '',
  description: '',
  sort: 1,
  organizationId: '',
};

type Props = {
  title: string;
  category: CustomAttributeCategoryInput | undefined;
  submitButton: { children: 'Create category' | 'Save changes' };
  isCategoryInUse: boolean;
  organizationId: string;
  onClose: () => void;
};

const ICON_NAMES = [
  'clipboard',
  'envelope',
  'chartPyramid',
  'link',
  'listCheck',
  'globe',
  'shieldCheck',
  'file',
  'briefcaseBlank',
  'chartPie',
  'lockKeyhole',
  'squareCode',
  'users',
  'shieldExclamation',
  'fileLines',
  'noteSticky',
  'chartLineUp',
  'bookmark',
  'code',
  'diagramSubtask',
  'diamondExclamation',
  'phone',
  'sitemap',
  'chartMixed',
  'key',
  'codeCompare',
  'userTag',
  'bookOpenCover',
  'calendar',
  'networkWired',
  'chartSimpleHorizontal',
  'flag',
  'codeBranch',
  'userShield',
  'coins',
  'calendarDays',
  'timeline',
  'chartGantt',
  'flagPennant',
  'codeFork',
  'userGear',
  'book',
  'magnifyingGlassChart',
  'bell',
  'cube',
  'circleNodes',
  'circleUser',
  'tag',
  'sliders',
  'clockThree',
  'toggleOff',
  'eye',
  'paperclip',
  'slidersSimple',
  'star',
  'layerGroup',
  'diagramProject',
  'bullseyeArrow',
  'folderOpen',
  'barsProgress',
  'sparkles',
  'shapes',
  'drawCircle',
  'router',
];

const ICON_OPTIONS = ICON_NAMES.map((iconName) => {
  const icon = ICONS[iconName as keyof IconsProps] as IconDefinition;

  return {
    value: iconName,
    element: <IconAwesomeInfo key={iconName} icon={icon} size="sm" />,
  };
});

export const CustomAttributeCategoryPopup = (props: Props) => {
  const {
    title,
    category,
    submitButton,
    onClose,
    organizationId,
    isCategoryInUse = false,
  } = props;

  const initialValues =
    category === undefined
      ? {
          ...EMPTY_CUSTOM_ATTRIBUTE_TEMPLATE_INPUT,
          organizationId,
        }
      : cleanCustomAttributeCategory(category);

  const { createOrUpdate, communication } =
    useCreateOrUpdateCustomAttributeCategory({ onCompleted: onClose });

  return (
    <PresetFormik<CustomAttributeCategoryInput>
      initialValues={initialValues}
      onSubmit={(values) => createOrUpdate({ input: values, organizationId })}
      validateOnChange={false}
      validateOnMount={false}
      validateOnBlur={false}
    >
      {({ submitForm, errors }) => {
        return (
          <Popup
            isOpen={true}
            title={title}
            fullWidth={true}
            maxWidth="xs"
            buttons={{
              secondaryButtonProps: {
                children: 'Cancel',
                isLoading: false,
                onClick: onClose,
              },
              mainButtonProps: {
                ...submitButton,
                isLoading: communication.isRequesting,
                type: 'button',
                onClick: submitForm,
              },
            }}
            onClose={onClose}
          >
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <TextInputField
                  label="Attribute category name"
                  name={getField({ name: null })}
                  isRequired
                  validate={combineValidators([
                    validateNotEmpty('Attribute name*'),
                    validateMaxLength(250),
                  ])}
                  withoutError={!Boolean(errors.name)}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectField
                  label="Icon"
                  name={getField({ icon: null })}
                  options={ICON_OPTIONS}
                  required
                  withoutError={!Boolean(errors.icon)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextInputField
                  label="Description"
                  name={getField({ description: null })}
                  validate={validateMaxLength(2500)}
                  withoutError={!Boolean(errors.description)}
                  multiline
                  rows={5}
                />
              </Grid>
            </Grid>
            {isCategoryInUse ? (
              <Alert severity="warning" title="Attention" sx={{ mt: 1 }}>
                Please be aware that{' '}
                <u>
                  any changes made to a custom attribute category will be
                  immediately reflected across all other custom attributes using
                  the same category
                </u>
                . Make sure that you have carefully considered the implications
                before saving any changes.
              </Alert>
            ) : null}
          </Popup>
        );
      }}
    </PresetFormik>
  );
};

const getField = makeGetFieldName<any>();

const cleanCustomAttributeCategory = (
  category: CustomAttributeCategoryInput
) => {
  return {
    id: category.id,
    name: category.name,
    description: category.description,
    icon: category.icon,
    organizationId: category.organizationId,
    sort: category.sort,
  };
};
