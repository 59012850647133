import Stack from '@mui/material/Stack';

import Chip from 'shared/view/elements/Chip/Chip';
import { NonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';
import CellRendererDate from 'shared/view/elements/DataGrid/columns/CellRendererDate';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import CellRendererActions from 'shared/view/elements/DataGrid/columns/CellRendererActions';
import { ConfirmType } from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import {
  ListItem,
  ListWithExpandButton,
} from 'shared/view/elements/DataGrid/columns/CellRendererList';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { AllowedActionsData } from 'shared/graphql/collaborators/graphql-types/fragments.generated';
import { IPagination } from 'shared/models/Pagination';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import makeActionsColumn from 'shared/view/elements/DataGrid/columns/makeActionsColumn';
import { trackEvent } from 'setup/app/analytics';

import { useDeleteUser } from '../store/useDeleteUser';
import UserDetailsPopup from './UserDetailsPopup/UserDetailsPopup';
import { OrganizationV2Users } from '../store/useOrganizationUsers';

type Props = {
  data: OrganizationV2Users;
  pagination: IPagination;
  onPageChange: (value: number) => void;
};

const OrganizationUsersTable = (props: Props) => {
  const columns: Array<
    DataGridColumn<OrganizationV2Users['users']['users'][0]>
  > = [
    {
      field: 'Name',
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          {params.row.__typename === 'ServiceAccountV2' ? (
            <IconAwesomeInfo icon={ICONS.robot} />
          ) : null}
          <CellRendererString value={params.row.fullName} />
        </Stack>
      ),
    },
    {
      field: 'Email',
      flex: 1,
      renderCell: (params) => <CellRendererString value={params.row.email} />,
    },
    {
      field: 'Last signed in',
      flex: 1,
      renderCell: (params) => (
        <CellRendererDate
          showTime={false}
          value={new Date(params.row.lastSignedIn)}
        />
      ),
    },
    {
      field: 'Groups',
      flex: 1,
      renderCell: (params) => (
        <CellRendererList items={params.row.groups.map((x) => x.name)} />
      ),
    },
    {
      field: 'Workspaces',
      flex: 1,
      renderCell: (params) => (
        <CellRendererList items={params.row.workspaces.map((w) => w.name)} />
      ),
    },
    makeActionsColumn({
      renderCell: (params) => (
        <Actions
          organizationId={props.data.id}
          organizationName={props.data.name}
          groups={props.data.groups}
          user={params.row}
        />
      ),
    }),
  ];

  return (
    <DataGridWithTypes
      heightType="pageHeight"
      rows={props.data.users.users.filter((u) => u.id)}
      columns={columns}
      pagination={props.pagination}
      onPageChange={props.onPageChange}
    />
  );
};

const Actions = (props: {
  user: OrganizationV2Users['users']['users'][0];
  groups: Array<{
    id: string;
    name: string;
    allowedActions: AllowedActionsData;
  }>;
  organizationId: string;
  organizationName: string;
}) => {
  const deleteUserApi = useDeleteUser();
  const popupApi = usePopupManager();
  return (
    <>
      {popupApi.isPopupOpen ? (
        <UserDetailsPopup
          organizationId={props.organizationId}
          user={props.user}
          groups={props.groups}
          onClose={popupApi.closePopup}
        />
      ) : null}
      <CellRendererActions
        actions={[
          {
            type: 'icon',
            icon: ICONS.pencil,
            dataTest: 'edit',
            label: 'Edit',
            onClick: () => {
              trackEvent({ type: 'organization.users.edit_clicked' });
              popupApi.openPopup();
            },
          },
          {
            type: 'icon',
            icon: ICONS.trashCan,
            label: 'Delete',
            dataTest: 'delete',
            onClick: () => {
              trackEvent({ type: 'organization.users.delete_clicked' });
              deleteUserApi.run({
                organizationId: props.organizationId,
                userId: props.user.id,
              });
            },
            isLoading: deleteUserApi.communication.isRequesting,
            confirmAction: {
              type: ConfirmType.delete,
              description: 'user',
            },
          },
        ]}
      />
    </>
  );
};

export const CellRendererList = (props: { items: string[] }) => {
  return (
    <Stack width={'100%'} direction="row" alignItems="center">
      {props.items.length > 2 ? (
        <>
          {props.items.slice(0, 1).map((item) => (
            <Chip key={item} label={item} />
          ))}
          <Chip label={`+${props.items.length - 1}`} />
          <div style={{ marginLeft: 'auto' }}>
            <ListWithExpandButton
              columnKey="key"
              withoutFirstElement={true}
              list={
                props.items.map((value) => ({
                  id: value,
                  value,
                })) as NonEmptyArray<ListItem<string>>
              }
              renderer={(item: string) => <Chip label={item} />}
            />
          </div>
        </>
      ) : (
        props.items.map((item) => <Chip key={item} label={item} />)
      )}
    </Stack>
  );
};

export default OrganizationUsersTable;
