import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { PypiConfigurationsList } from 'features/systemAdmin/integrations/pypi/store/graphql-types/usePypiConfigurationsList.generated';
import { PYPI_CONFIGURATION_FRAGMENT } from 'shared/graphql/SystemAdmin/PypiIntegration';

const PYPI_CONFIGURATIONS = gql`
  query PypiConfigurationsList {
    pypiConfigurations {
      id
      configurations {
        id
        ...PypiConfigurationData
      }
    }
  }
  ${PYPI_CONFIGURATION_FRAGMENT}
`;

export const usePypiConfigurationsList = () => {
  const queryResult =
    useCustomQuery<PypiConfigurationsList>(PYPI_CONFIGURATIONS);

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert,
    queryResult,
  });

  return {
    data,
    communication,
  };
};

function memoizedConvert(result: PypiConfigurationsList) {
  return result.pypiConfigurations.configurations;
}
