/* eslint-disable no-useless-escape */
import { isWebUri } from 'valid-url';

import { makeEndpointPath } from 'shared/models/Deployment/canary/Endpoint';
import isNotNil from 'shared/utils/isNotNill';

import {
  deploymentErrorMessages,
  UIValidationErrorMessage,
} from '../customErrorMessages';

export const validateNoop =
  (_field?: string) => (_value: string | null | undefined) => {
    return validate({
      isValid: true,
      errorMessage: '',
    });
  };

// todo replace handmade validators with Yup (https://github.com/jquense/yup)
export const validateNotEmpty =
  (type: string) => (value: string | null | undefined) => {
    const isEmptyString: boolean =
      typeof value === 'string' && (value === '' || /^\s+$/.test(value));

    return validate({
      isValid: !isEmptyString && isNotNil(value),
      errorMessage: UIValidationErrorMessage.common.empty(type),
    });
  };

export const validateIsRequired =
  (field?: string) => (value: string | null | undefined) => {
    const isEmptyString: boolean =
      typeof value === 'string' && (value === '' || /^\s+$/.test(value));
    return validate({
      isValid: !isEmptyString && isNotNil(value),
      errorMessage: UIValidationErrorMessage.common.required(field),
    });
  };

export const validateNotSame =
  (compareTo: string, type: string) => (value: string | null | undefined) =>
    validate({
      isValid: typeof value !== 'string' || value === '' || value !== compareTo,
      errorMessage: UIValidationErrorMessage.common.notSameAs(type),
    });

export const validateNotEqual =
  (string1: string, string2: string) => (value: string | null | undefined) =>
    validate({
      isValid: typeof value !== 'string' || value === '' || value !== string1,
      errorMessage: UIValidationErrorMessage.common.empty(string2),
    });

const validPathRegex = /^\/?([a-zA-Z\-_0-9])*$/;

export const validateIsUrlPath = (type: string) =>
  validate({
    isValid: Boolean(makeEndpointPath(type).match(validPathRegex)),
    errorMessage: deploymentErrorMessages.deploySettings.invalidPath,
  });

export const validateUrl = (url: string) =>
  validate({
    isValid: Boolean(isWebUri(url)),
    errorMessage: UIValidationErrorMessage.common.invalidURL,
  });

const checkUrl = (url: string) => {
  try {
    new URL(url);
  } catch {
    return false;
  }
  return true;
};

export const validateUrlRegex = (url: string) =>
  validate({
    isValid: checkUrl(url),
    errorMessage: UIValidationErrorMessage.common.invalidURL,
  });

export const validateEmailNotEmpty = validateNotEmpty('Email');

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validate({
    isValid: re.test(String(email).toLowerCase()),
    errorMessage: UIValidationErrorMessage.common.email,
  });
};

// number
const validateNumber = (value: unknown) =>
  validate({
    isValid: !Number.isNaN(Number(value)),
    errorMessage: UIValidationErrorMessage.common.notNumber,
  });

// export const validateGraphQLDate = (value: unknown) =>
//   validate({
//     isValid: parseGraphqlDate(String(value).toString()) instanceof Date,
//     errorMessage: UIValidationErrorMessage.date.notDate,
//   });
//
// export const validateDate = (value: unknown) =>
//   validate({
//     isValid: value instanceof Date,
//     errorMessage: UIValidationErrorMessage.date.notDate,
//   });
//
// const validateNotFuture = (date: Date) =>
//   validate({
//     isValid: date <= new Date(),
//     errorMessage: UIValidationErrorMessage.date.notFuture,
//   });
//
// export const validateNotFutureDate = combineValidators<unknown, string>([
//   validateDate,
//   (n) => {
//     console.log('validateNotFutureDate', n, validateNotFuture(n as Date));
//     return validateNotFuture(n as Date);
//   },
// ]);

const validatePositive = (number: number) =>
  validate({
    isValid: number >= 0,
    errorMessage: UIValidationErrorMessage.common.notPositiveNumber,
  });

export const validatePositiveNumber = combineValidators<string, string>([
  validateNumber,
  (n) => validatePositive(Number(n)),
]);

export function skipWhenEmpty<Value extends string, Err = string>(
  validator: Validator<Value, Err>
) {
  return (value: Value) => {
    return validateNotEmpty('')(value) ? undefined : validator(value);
  };
}

export function combineValidators<Value, Err = string>(
  validators: Array<Validator<Value, Err>>
): Validator<Value, Err> {
  return (value: Value) =>
    validators.reduce(
      (maybeError, validator) => {
        return maybeError !== undefined ? maybeError : validator(value);
      },
      undefined as Err | undefined
    );
}

const validateMinLength = (min: number) => (value: string) =>
  validate({
    isValid: value.length >= min,
    errorMessage: UIValidationErrorMessage.common.minLength(min),
  });

export const validateMaxLength = (max: number) => (value: string) =>
  validate({
    isValid: value.length < max,
    errorMessage: UIValidationErrorMessage.common.maxLength(max),
  });

const validateContainAtLeastOne =
  (type: string, regexp: RegExp) => (value: string) =>
    validate({
      isValid: new RegExp(regexp, 'g').test(value),
      errorMessage: UIValidationErrorMessage.common.containAtLeastOne(type),
    });

export const validateSymbols = (symbolNames: string[]) => (value: string) =>
  validate({
    isValid: /^[a-zA-Z0-9_-]*$/.test(value),
    errorMessage: UIValidationErrorMessage.common.symbols(symbolNames),
  });

export const validate = ({
  isValid,
  errorMessage,
}: {
  isValid: boolean;
  errorMessage: string;
}): string | undefined => {
  return isValid ? undefined : errorMessage;
};

type Validator<Value, Error = string> = (value: Value) => Error | undefined;

// password rule

export const PASSWORD_RULES = {
  length: { type: 'length', meta: { min: 8, max: 128 } },
  shouldContainAtLeast1Digit: { type: 'shouldContainAtLeast1Digit' },
  shouldContainAtLeast1UpperCaseChar: {
    type: 'shouldContainAtLeast1UpperCaseChar',
  },
  shouldContainAtLeast1LowerCaseChar: {
    type: 'shouldContainAtLeast1LowerCaseChar',
  },
  shouldContainAtLeast1SpecialCharacter: {
    type: 'shouldContainAtLeast1SpecialCharacter',
    meta: ['@', '#', '$', '%', '!', '.'],
  },
} as const;

export const passwordRuleValidator: Record<
  PasswordRuleType['type'],
  Validator<string>
> = {
  length: combineValidators([
    validateMinLength(PASSWORD_RULES.length.meta.min),
    validateMaxLength(PASSWORD_RULES.length.meta.max),
  ]),
  shouldContainAtLeast1Digit: validateContainAtLeastOne('digit', /[0-9]/),
  shouldContainAtLeast1LowerCaseChar: validateContainAtLeastOne(
    'lower case character',
    /[a-z]/
  ),
  shouldContainAtLeast1UpperCaseChar: validateContainAtLeastOne(
    'upper case character',
    /[A-Z]/
  ),
  shouldContainAtLeast1SpecialCharacter: (() => {
    const specialCharacters =
      PASSWORD_RULES.shouldContainAtLeast1SpecialCharacter.meta;
    return validateContainAtLeastOne(
      `special character from ${specialCharacters.join('')}`,
      new RegExp(`[${specialCharacters.join('')}]`)
    );
  })(),
};

export type PasswordRuleType =
  (typeof PASSWORD_RULES)[keyof typeof PASSWORD_RULES];

export const validatePassword = combineValidators(
  Object.values(passwordRuleValidator)
);
