import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { RESIZE_OBSERVER_LOOP_ERROR_REGEX } from 'setup/utils/ignored_errors';

export const initSentry = () => {
  const tracesSampleRate = window.location.host.includes('dev.verta.ai')
    ? 1.0
    : 0.3;

  try {
    Sentry.init({
      dsn: 'https://78f9b973e3e54b0db2e2f426a0aad50d@o4504362475782144.ingest.sentry.io/4504284151283712',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ],
      tracesSampleRate,
      environment: String(process.env.NODE_ENV),
      ignoreErrors: [RESIZE_OBSERVER_LOOP_ERROR_REGEX],
    });
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.log('Unable to load sentry\n', error);
  }
};
