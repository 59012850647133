/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import { Token } from 'shared/models/Deployment/canary/Token';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

import * as GraphqlTypes from './graphql-types/enableTokens.generated';

const ENABLE_TOKENS = gql`
  mutation EnableTokens(
    $workspaceName: String!
    $organizationId: ID
    $endpointId: ID!
    $environmentId: ID!
    $tokensEnabled: Boolean!
    $token: String!
    $skipCreatingToken: Boolean!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoint(id: $endpointId) {
          ... on Error {
            ...ErrorData
          }
          ... on Endpoint {
            id
            environment(id: $environmentId) {
              id
              enableTokens(enable: $tokensEnabled) {
                id
                tokensEnabled
              }
              createToken(value: $token) @skip(if: $skipCreatingToken) {
                id
                tokens {
                  id
                  value
                }
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useEnableTokens = () => {
  const [mutate, enablingTokens] = useCustomMutation<
    GraphqlTypes.EnableTokens,
    GraphqlTypes.EnableTokensVariables
  >(ENABLE_TOKENS, { context: 'enabling tokens' });

  const enableTokens = (
    tokensEnabled: boolean,
    props: {
      environmentId: string;
      endpointId: string;
      workspaceName: string;
      tokens: Token[];
    }
  ) => {
    mutate({
      ...props,
      tokensEnabled,
      token: uuidv4(),
      skipCreatingToken: !tokensEnabled || props.tokens.length > 0,
    });
  };

  return {
    enableTokens,
    enablingTokens,
  };
};
