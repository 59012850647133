import { URLWithPagination } from 'shared/models/Pagination/PaginationInURL';
import {
  zeroOrMoreSegmentsParam,
  param,
  route,
  queryParams,
} from 'shared/routes/shared/routesTreeBuilder';
import { IURLWithFilters } from 'features/filters/store/storage/filtersInURL';

const experiments = route('experiments', {
  tag: 'experiments',
  queryParams: queryParams<IURLWithFilters>(),
})();

const experimentRuns = route('exp-runs', {
  tag: 'experimentRuns',
  queryParams: queryParams<IURLWithFilters & URLWithPagination>(),
})(
  route(
    'compare',
    undefined
  )(
    zeroOrMoreSegmentsParam()('experimentRunIds', {
      tag: 'experimentRunsCompare',
    })()
  ),
  param()('experimentRunId', {
    tag: 'experimentRun',
  })()
);

export const publicRoutes = route('projects', {
  tag: 'projects',
})(
  param()('projectId', {
    tag: 'project',
  })(
    route('overview', {
      tag: 'projectOverview',
    })(),
    route('charts', {
      tag: 'charts',
      queryParams: queryParams<IURLWithFilters & { dashboard: string }>(),
    })(),
    experiments,
    experimentRuns
  )
);
