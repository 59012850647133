import { identity, pipe } from 'ramda';

import { isNotEmptyArray, updateById } from 'shared/utils/collection';
import generateId from 'shared/utils/generateId';
import { NonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';

import { IAttribute } from '../Attribute';
import { makeReservedAttributeKey } from '../ReservedAttribute';
import * as CrossRunDashboard from './CrossRunDashboard';
import { RequiredExperimentRun } from './CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import * as DefaultCrossRunDashboard from './DefaultCrossRunDashboard';

const attributeWithCrossRunDashboardsKey =
  makeReservedAttributeKey('dashboards');

const dashboardsVersion = '0.0.1';

interface AttributeParsedValue {
  version: string;
  dashboards: CrossRunDashboard.CrossRunDashboard[];
}

const makeAttribute = (dashboards: CrossRunDashboard.CrossRunDashboard[]) => {
  const value: AttributeParsedValue = {
    dashboards,
    version: dashboardsVersion,
  };
  return {
    key: attributeWithCrossRunDashboardsKey,
    value: JSON.stringify(value),
  };
};

const getCrossRunDashboards = ({
  attributes,
}: {
  attributes: IAttribute[];
}) => {
  const targetAttribute = attributes.find(
    ({ key }) => key === attributeWithCrossRunDashboardsKey
  );
  const value =
    typeof targetAttribute?.value === 'string'
      ? (JSON.parse(targetAttribute.value) as Partial<AttributeParsedValue>)
      : undefined;
  return value?.dashboards && value.version === dashboardsVersion
    ? value.dashboards
    : undefined;
};

const defaultCrossRunDashboardId = generateId();
export const getCrossRunDashboardsOrDefault = (project: {
  attributes: IAttribute[];
  experimentRuns: NonEmptyArray<RequiredExperimentRun>;
}): NonEmptyArray<CrossRunDashboard.CrossRunDashboard> => {
  return pipe(
    () => getCrossRunDashboards(project),
    (x) =>
      x && isNotEmptyArray(x)
        ? x
        : identity<NonEmptyArray<CrossRunDashboard.CrossRunDashboard>>([
            DefaultCrossRunDashboard.make(
              defaultCrossRunDashboardId,
              project.experimentRuns
            ),
          ])
  )();
};

export const saveDashboardChanges = (
  updatedDashboard: CrossRunDashboard.CrossRunDashboard,
  dashboards: CrossRunDashboard.CrossRunDashboard[]
) =>
  makeAttribute(
    updateById(() => updatedDashboard, updatedDashboard.id, dashboards)
  );

export const deleteDashboard = ({
  dashboardId,
  dashboards,
}: {
  dashboardId: string;
  dashboards: [
    CrossRunDashboard.CrossRunDashboard,
    CrossRunDashboard.CrossRunDashboard,
    ...CrossRunDashboard.CrossRunDashboard[],
  ];
}) => {
  const newDashboards = dashboards.filter(
    (dashboard) => dashboard.id !== dashboardId
  );
  return {
    attribute: makeAttribute(
      dashboards.filter((dashboard) => dashboard.id !== dashboardId)
    ),
    newDashboards,
  };
};

export const cloneDashboard = ({
  dashboardId,
  dashboards,
  newDashboardName,
}: {
  dashboardId: string;
  newDashboardName: CrossRunDashboard.CrossRunDashboard['name'];
  dashboards: CrossRunDashboard.CrossRunDashboard[];
}) => {
  const dashboard = dashboards.find((x) => x.id === dashboardId);
  const newDashboard = dashboard
    ? {
        ...dashboard,
        id: generateId(),
        name: newDashboardName,
      }
    : undefined;
  return newDashboard
    ? {
        attribute: makeAttribute([...dashboards, newDashboard]),
        newDashboard,
      }
    : undefined;
};
