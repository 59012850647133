import { defaultMonitoringDriftMetricType } from 'shared/models/Monitoring/MonitoringDriftMetricType';

import { MonitoringDashboard } from '../../MonitoringDashboard/MonitoringDashboard';
import { MakeDefaultModelStateProps } from '../shared/MakeDefaultModelStateProps';

export const STATIC_DISTRIBUTION_OVER_TIME_WIDGET_ID = 'distribution-over-time';
export const STATIC_DISTRIBUTION_WIDGET_ID = 'distribution';
export const STATIC_DRIFT_TABLE_WIDGET_ID = 'drift-table';

export const makePresetDriftDashboard = (
  props: MakeDefaultModelStateProps
): MonitoringDashboard => ({
  id: 'drift',
  name: 'Drift',
  createdBy: props.owner,
  dateCreated: new Date().toISOString(),
  panels: [
    {
      id: '2973e0c0-55f7-4a47-8eb1-26308c00327b',
      type: 'drift',
      name: 'Drift',
      isOpen: true,
      ioDescription: props.defaultIODescription,
      driftMetricType: defaultMonitoringDriftMetricType,
      layout: [
        {
          w: 12,
          h: 11,
          x: 0,
          y: 0,
          i: STATIC_DISTRIBUTION_OVER_TIME_WIDGET_ID,
          minW: 3,
          minH: 3,
          moved: false,
          static: false,
        },
        {
          w: 12,
          h: 10,
          x: 0,
          y: 11,
          i: STATIC_DISTRIBUTION_WIDGET_ID,
          minW: 3,
          minH: 3,
          moved: false,
          static: false,
        },
        {
          w: 12,
          h: 11,
          x: 0,
          y: 0,
          i: STATIC_DRIFT_TABLE_WIDGET_ID,
          minW: 3,
          minH: 3,
          moved: false,
          static: false,
        },
      ],
    },
  ],
});
