import { Box, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';

import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { validateIsRequired } from 'shared/utils/validators';
import { AppError } from 'shared/models/Error';

import UploadArtifacts from './FileUpload';
import { getFieldName } from './CreateEvaluation';

const handleDownloadTemplate = () => {
  const csvData = `ID,input,output,groundtruth,trace,location (Metadata 1),Metadata 2
  1,"The quaint village of Willowbrook, nestled in a lush valley, is known for its idyllic scenery and friendly residents. The town's main street is lined with charming boutiques, cafes, and flower-filled window boxes. Visitors can explore the nearby forest trails, which lead to a pristine waterfall hidden deep within the woods. At the heart of Willowbrook is a centuries-old oak tree, a symbol of strength and community, where the townsfolk gather for annual festivals and celebrations. Overall, Willowbrook offers a perfect retreat for those seeking both natural beauty and small-town charm.""","""Willowbrook, a charming village with a scenic main street, forest trails, and a historic oak tree, is an ideal retreat for nature enthusiasts and small-town aficionados.""
  ", ,,USA,
  2,"""Traveling to new destinations is an exciting and enriching experience. It allows you to immerse yourself in different cultures, taste unique cuisines, and explore breathtaking landscapes. Meeting locals and fellow travelers can lead to meaningful connections, and it's an opportunity to step out of your comfort zone and grow as a person. Whether you're backpacking through Europe, embarking on a road trip, or jet-setting to exotic locations, travel opens up a world of possibilities for discovery and adventure.""","""Traveling broadens horizons through culture, cuisine, landscapes, connections, and personal growth.""", ,,All,
  3,"""Paris, often referred to as the 'City of Love,' is a captivating travel destination. Its iconic landmarks, such as the Eiffel Tower and Louvre Museum, offer rich historical and artistic experiences. Strolling along the Seine River, indulging in delicious pastries at charming cafes, and exploring the quaint streets of Montmartre are just a few of the pleasures awaiting visitors. With its romantic ambiance, world-class cuisine, and cultural treasures, Paris continues to enchant travelers from around the globe.""","""Paris, the 'City of Love,' beckons with iconic landmarks, scenic river walks, delightful cafes, and a rich tapestry of art and culture.""", ,,Europe,
  , , , ,,,
  `;

  const blob = new Blob([csvData], { type: 'text/csv' });

  const blobUrl = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = 'example_data.csv';
  link.click();

  window.URL.revokeObjectURL(blobUrl);
};

export const UploadDataset = ({
  uploadingArtifactError,
  uploadProgress,
}: {
  uploadingArtifactError?: AppError;
  uploadProgress: number;
}) => {
  return (
    <Box>
      <Typography variant="subtitle1">Upload Data set</Typography>
      <Typography
        pt={1}
        variant="caption"
        color="text.secondary"
        display="block"
      >
        Follow{' '}
        <InlineLink simple onClick={handleDownloadTemplate} to="">
          this template
        </InlineLink>{' '}
        to get started with.
      </Typography>
      <Typography
        pt={1}
        variant="caption"
        color="text.secondary"
        whiteSpace="pre-wrap"
      >
        Data set has the set of LLM inputs and outputs to be used for
        evaluation. We expect the dataset to follow a specific schema and column
        naming format:
        <ul style={{ margin: 0 }}>
          <li>
            <strong>The required columns are input and output.</strong>
          </li>
          <li>Optional columns are ground truth and trace.</li>
          <li> Any other columns are considered metadata.</li>
        </ul>
      </Typography>

      <Box pt={2}>
        <Field
          name={getFieldName({ file: null })}
          validate={validateIsRequired('File')}
        >
          {({ field, meta, form }: FieldProps<File>) => {
            return (
              <UploadArtifacts
                {...field}
                hasError={meta.touched && meta.error}
                uploadingArtifactError={uploadingArtifactError}
                uploadProgress={uploadProgress}
                handleUpload={(value) => {
                  form.setFieldValue(field.name, value);
                }}
                maxSize={1000000}
              />
            );
          }}
        </Field>
      </Box>
    </Box>
  );
};
