import { gql } from '@apollo/client';

import { Alerter } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/Alerter';
import { formatWithDefaultPrecision } from 'shared/utils/formatters/formatWithDefaultPrecision';
import matchBy from 'shared/utils/matchBy';

import { AlerterFragment } from './graphql-types/Alerter.generated';

export const ALERTER_FRAGMENT = gql`
  fragment AlerterFragment on Alerter {
    ... on AlerterFixed {
      threshold
      operator
    }
    ... on AlerterRange {
      upperBound
      lowerBound
      alertIfOutsideRange
    }
  }
`;

export const convertAlerter = (data: AlerterFragment): Alerter => {
  return matchBy(
    data,
    '__typename'
  )<Alerter>({
    AlerterFixed: (alerter) => ({
      threshold: Number(formatWithDefaultPrecision(alerter.threshold)),
      operator: alerter.operator,
      type: 'fixed',
    }),
    AlerterRange: (alerter) => ({
      type: 'range',
      alertIfOutsideRange: alerter.alertIfOutsideRange,
      lowerBound: Number(formatWithDefaultPrecision(alerter.lowerBound)),
      upperBound: Number(formatWithDefaultPrecision(alerter.upperBound)),
    }),
  });
};
