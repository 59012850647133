import { Grid } from '@mui/material';

import IntelLogo from '../images/intel-logo.svg?react';
import ZulilyLogo from '../images/zulily-logo.svg?react';
import MercuryLogo from '../images/mercury-logo.svg?react';
import ScribdLogo from '../images/scribd-logo.svg?react';
import LeadCrunchLogo from '../images/leadcrunch-logo.svg?react';
import UnusualLogo from '../images/unusual-logo.svg?react';
import GeneralCatalystLogo from '../images/general-catalyst-logo.svg?react';

const logos = [
  { key: 'IntelLogo', image: <IntelLogo /> },
  { key: 'ZulilyLogo', image: <ZulilyLogo /> },
  { key: 'MercuryLogo', image: <MercuryLogo /> },
  { key: 'ScribdLogo', image: <ScribdLogo /> },
  { key: 'LeadCrunchLogo', image: <LeadCrunchLogo /> },
  { key: 'UnusualLogo', image: <UnusualLogo /> },
  { key: 'GeneralCatalystLogo', image: <GeneralCatalystLogo /> },
];
export const VertaClients = () => (
  <Grid container spacing={2} height="100%">
    {logos.map((logo) => (
      <Grid
        item
        xxs
        key={logo.key}
        sx={{ alignItems: 'center', display: 'flex' }}
      >
        {logo.image}
      </Grid>
    ))}
  </Grid>
);
