import { RouteObject } from 'react-router-dom';

import { RoutesGenerator } from 'pages/authorized/types';
import routes from 'shared/routes';
import {
  CustomRedirect,
  DefaultRedirect,
  WindowLocationRedirect,
} from 'shared/routes/shared/CustomRedirect';
import { AuthorizedLayout, TempAuthorizedLayout } from 'shared/routes/layouts';

import NotFoundPage from './NotFoundPage/NotFoundPage';
import { datasetPages } from './DatasetPages';
import { endpointsPages } from './EndpointsPages';
import { highLevelSearchPage } from './HighLevelSearchPage';
import { profilePage } from './ProfilePage';
import { systemAdminPage } from './SystemAdmin';
import { homePage } from './Homepage';
import { projectsPages } from './ProjectsPages';
import { catalogPages, modelVersionPages } from './CatalogPages';
import { monitoringPages } from './Monitoring';
import { organizationPages } from './Organization';
import errorReporterPlaygroundPage from './ErrorReporterPlaygroundPage';
import { registryPages } from './RegistryPages';
import { DashboardPages } from './DashboardPages';
import { useCasesPages } from './UseCasesPages';
import { evaluationsPages } from './EvaluationsPages';

const rootAuthorizedPages: RoutesGenerator = {
  getRoutes: () => [
    { path: routes.root.getPath(), element: <DefaultRedirect /> },
  ],
};

const redirectPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: '/llm-app/*',
      element: <WindowLocationRedirect />,
    },
    {
      path: '/llm-app/projects', // since we have this route declared, we need to force the redirect here
      element: <WindowLocationRedirect />,
    },
    {
      path: '/new-organization/*',
      element: <CustomRedirect route={routes.org} redirectAll />,
    },
    {
      path: `${routes.workspace.getPath()}/registry/*`,
      element: <CustomRedirect route={routes.catalog} redirectAll />,
    },
    {
      path: `${routes.workspace.getPath()}/endpoints/:endpointId/summary`,
      element: <CustomRedirect route={routes.endpointOverview} redirectAll />,
    },
    {
      path: `${routes.workspace.getPath()}/projects/:projectId/summary`,
      element: <CustomRedirect route={routes.projectOverview} redirectAll />,
    },
    {
      path: `${routes.workspace.getPath()}/datasets/:datasetId/summary`,
      element: <CustomRedirect route={routes.datasetOverview} redirectAll />,
    },
    {
      path: routes.workspace.getPath(),
      element: <DefaultRedirect />,
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ],
};

// pages should be mapped only one time
// we need to migrate all pages to new nested layout
const rawAuthorizedPages = [
  rootAuthorizedPages,
  registryPages,
  highLevelSearchPage,
  datasetPages,
  profilePage,
  systemAdminPage,
  homePage,
  projectsPages,
  endpointsPages,
  monitoringPages,
  organizationPages,
  errorReporterPlaygroundPage,
  redirectPages,
];

export const authorizedPages: RouteObject[] = [
  {
    path: '/',
    element: <TempAuthorizedLayout />,
    children: rawAuthorizedPages.map(({ getRoutes }) => getRoutes()).flat(),
  },
];

// Routes with proper nested layouts
const rawNestedAuthorizedPages = [
  catalogPages,
  modelVersionPages,
  DashboardPages,
  useCasesPages,
  evaluationsPages,
];

export const nestedAuthorizedPages: RouteObject[] = [
  {
    path: '',
    element: (
      <TempAuthorizedLayout>
        <AuthorizedLayout />
      </TempAuthorizedLayout>
    ),
    children: rawNestedAuthorizedPages
      .map(({ getRoutes }) => getRoutes())
      .flat(),
  },
];
