import { Stack, Typography, Box, styled } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MuiTooltip, {
  TooltipProps,
  tooltipClasses,
} from '@mui/material/Tooltip';

const EXPAND_SIZE = {
  maxHeight: 300,
  minWidth: 120,
  maxWidth: 300,
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    border: theme.palette.border.default,
    maxWidth: '5000px',
  },
}));

export const OverflowPopover = ({
  overflowItems,
  itemsLabel,
}: {
  overflowItems: JSX.Element[];
  itemsLabel: string;
}) => (
  <LightTooltip
    title={
      <Stack spacing={1} maxHeight={EXPAND_SIZE.maxHeight}>
        {overflowItems}
      </Stack>
    }
    placement="bottom-start"
    arrow={false}
  >
    <Box display="flex">
      <Typography
        variant="caption"
        color="text.disabled"
        sx={{ cursor: 'default' }}
      >
        {`+${overflowItems.length} ${itemsLabel}`}
      </Typography>
    </Box>
  </LightTooltip>
);
