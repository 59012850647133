import { FC } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import Button from 'shared/view/elements/Button/Button';
import { validateNotEmpty } from 'shared/utils/validators';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import SubmitButton from 'shared/view/elements/Button/SubmitButton';
import { componentsSizes } from 'shared/mui/themes/CommonMuiThemeOptions';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

import {
  useCreateAccessTokenMutation,
  useCreateRandomAccessTokenMutation,
} from '../../../store/accessTokensManager/accessTokensManager';

interface Props {
  disabled: boolean;
  endpointId: string;
  environmentId: string;
  hasAccess: boolean;
  workspaceName: string;
}

const initialValues = { token: '' };
const validateAccessToken = validateNotEmpty('access token');

const CreateAccessTokenForm: FC<React.PropsWithChildren<Props>> = ({
  endpointId,
  environmentId,
  hasAccess,
  disabled,
  workspaceName,
}) => {
  const [createAccessToken, creatingAccessToken] =
    useCreateAccessTokenMutation();

  const [createRandomAccessToken, creatingRandomAccessToken] =
    useCreateRandomAccessTokenMutation();

  const organizationId = useCurrentOrganizationV2();

  return (
    <Stack direction={'column'}>
      <PresetFormik
        initialValues={initialValues}
        onSubmit={(values) => {
          createAccessToken({
            ...values,
            workspaceName,
            endpointId,
            environmentId,
          });
        }}
      >
        {({ isValid }) => (
          <>
            <Grid container spacing={2}>
              <Grid item width={componentsSizes.input.maxWidth}>
                <TextInputField
                  name="token"
                  label="Access token"
                  validate={validateAccessToken}
                  disabled={!hasAccess || disabled}
                />
              </Grid>
              <Grid item pt={1}>
                <Box pt={1}>
                  <SubmitButton
                    disabled={
                      !hasAccess ||
                      !isValid ||
                      creatingRandomAccessToken.isRequesting ||
                      disabled
                    }
                    isLoading={creatingAccessToken.isRequesting}
                    dataTest="create"
                  >
                    Create
                  </SubmitButton>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </PresetFormik>
      <div>
        <Button
          disabled={!hasAccess || creatingAccessToken.isRequesting || disabled}
          isLoading={creatingRandomAccessToken.isRequesting}
          onClick={() => {
            createRandomAccessToken({
              workspaceName,
              organizationId,
              endpointId,
              environmentId,
            });
          }}
        >
          Create random
        </Button>
      </div>
    </Stack>
  );
};

export default CreateAccessTokenForm;
