import React from 'react';

import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { useEndpointsListWithPollingQuery } from 'features/deployment/canary/endpoints/store/endpointsListWithPolling/endpointsListWithPolling';
import { useAppBreadcrumbs } from 'features/layout/view/Layout/AppBreadcrumbs';
import Button from 'shared/view/elements/Button/Button';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import CreateEndpointPopup from 'features/deployment/canary/createEndpoint/view/CreateEndpointPopup/CreateEndpointPopup';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

import { useEndpointsPageBreadcrumb } from '../useEndpointsPageBreadcrumbs';

const Endpoints = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'Endpoints' */ 'features/deployment/canary/endpoints/view/Endpoints/Endpoints'
    )
);

const EndpointsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const workspaceName = useWorkspaceNameFromURL();
  const organizationId = useCurrentOrganizationV2();
  const endpointsListApi = useEndpointsListWithPollingQuery({
    workspaceName,
    organizationId,
  });

  return (
    <AuthorizedLayout
      breadcrumbs={useAppBreadcrumbs(useEndpointsPageBreadcrumb())}
      pageEntityOrError={undefined}
      actions={[
        <ButtonWithPopup
          key={0}
          button={(props) => (
            <Button {...props} dataTest="open-create-popup">
              Create
            </Button>
          )}
          popup={(props) => <CreateEndpointPopup {...props} />}
        />,
      ]}
    >
      <Endpoints {...endpointsListApi} />
    </AuthorizedLayout>
  );
};

export default EndpointsPage;
