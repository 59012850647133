// eslint-disable-next-line no-restricted-imports
import { MutationHookOptions, useMutation, FetchResult } from '@apollo/client';
import { MutationFunctionOptions } from '@apollo/client/react';
import { DocumentNode } from 'graphql';
import { useCallback } from 'react';

import {
  GraphqlErrorContext,
  GraphqlErrorContextWithProp,
} from 'setup/apollo/createRestrictedGraphqlErrorLink';
import { useMemoizedMutationResultToCommunication } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { ICommunication } from 'shared/utils/redux/communication';
import { OmitStrict } from 'shared/utils/types';
import { useToastCommunicationErrorWatcher } from 'shared/view/elements/Notification/Notification';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

export type CustomMutate<TData, TVariables> = (
  variables: TVariables,
  options?: OmitStrict<MutationFunctionOptions<TData, TVariables>, 'variables'>
) => Promise<FetchResult<TData>>;

function useCustomMutation<TData, TVariables>(
  mutation: DocumentNode,
  errorOptions: GraphqlErrorContext,
  options?: MutationHookOptions<TData, TVariables>
): [CustomMutate<TData, TVariables>, ICommunication, TData | null | undefined] {
  const orgId = useCurrentOrganizationV2();

  const [mutate__, res] = useMutation<TData, TVariables>(mutation, {
    ...options,
    context: {
      ...options?.context,
      headers: {
        ...(options?.context?.headers as { [key: string]: string }),
        'Grpc-Metadata-organization-id': orgId,
      },
      errorOptions,
    } as GraphqlErrorContextWithProp,
  });

  const communication = useMemoizedMutationResultToCommunication(res);
  useToastCommunicationErrorWatcher(communication, errorOptions);

  const mutate: CustomMutate<TData, TVariables> = useCallback(
    (variables, mutationOptions) => mutate__({ variables, ...mutationOptions }),
    [mutate__]
  );

  return [mutate, communication, res.data];
}

export default useCustomMutation;
