import * as CrossRunDashboard from 'shared/models/CrossRunDashboard/CrossRunDashboard';
import * as AttributeWithCrossRunDashboards from 'shared/models/CrossRunDashboard/AttributeWithCrossRunDashboards';
import { useUpdateAttributeWithDashboards } from 'features/experimentRuns/crossRunDashboard/store/updateAttributeWithDashboards/updateAttributeWithDashboards';

export const useDeleteDashboard = () => {
  const { updateAttributeWithDashboards, updating } =
    useUpdateAttributeWithDashboards('deleting dashboard', undefined);
  const deleteDashboard = (
    onCompleted: (dashboards: CrossRunDashboard.CrossRunDashboard[]) => void,
    data: {
      projectId: string;
      dashboardId: string;
      dashboards: [
        CrossRunDashboard.CrossRunDashboard,
        CrossRunDashboard.CrossRunDashboard,
        ...CrossRunDashboard.CrossRunDashboard[],
      ];
    }
  ) => {
    const { attribute, newDashboards } =
      AttributeWithCrossRunDashboards.deleteDashboard(data);
    return updateAttributeWithDashboards({
      projectId: data.projectId,
      attribute: attribute,
    }).then(({ errors }) => {
      if (errors && errors.length > 0) {
        return;
      }
      onCompleted(newDashboards);
    });
  };

  return {
    deleteDashboard,
    deletingDashboard: updating,
  };
};
