import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import routes from 'shared/routes';
import { validateNotEmpty } from 'shared/utils/validators';
import { selectCurrentWorkspace } from 'features/workspaces';
import { validateDescription } from 'shared/models/Description';
import TagsField from 'shared/view/formComponents/formikFields/TagsFieldWithTopLabel/TagsFieldWithTopLabel';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import { getDefaultLockLevelForm } from 'shared/view/domain/Registry/ModelVersion/LockLevel/LockLevelForm/LockLevelFormModel';
import FeatureLockLevelSelect from 'features/registry/registeredModelVersion/shared/FeatureLockLevelSelect/FeatureLockLevelSelect';
import { selectFlags } from 'features/flags';
import AutocompleteField from 'shared/view/formComponents/formikFields/AutocompleteField';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import PopupForm from 'shared/view/formComponents/PopupForm';
import { PopupStateProps } from 'shared/view/elements/Popup/PopupButtons';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { ExtractByTypename } from 'shared/utils/types';

import { useRegisteredModels } from '../../store/registeredModels/registeredModels';
import {
  useCreateRegisteredModelVersionWithExistingOrNewModel,
  RegisteredModelWithExperimentRunCreationForm,
} from '../../store/createRegisteredModelVersionWithExistingOrNewModel/createRegisteredModelVersionWithExistingOrNewModel';
import { RegisteredModels } from '../../store/registeredModels/graphql-types/registeredModels.generated';

interface ILocalProps extends PopupStateProps {
  experimentRunId: string;
}

const getFieldName =
  makeGetFieldName<RegisteredModelWithExperimentRunCreationForm>();

const RegisteredModelWithExperimentRunCreationPopup: React.FC<
  React.PropsWithChildren<ILocalProps>
> = ({ experimentRunId, ...popupStateProps }) => {
  const { isEnableRegisteredModelVersionsLock } = useSelector(selectFlags);

  const { create, creating, initialSettings, registeredModels } = useForm({
    experimentRunId,
  });

  return (
    <PopupForm
      initialValues={initialSettings}
      onSubmit={create}
      communication={creating}
      title="Register"
      getMainButtonProps={(values) => {
        const selectedRegisteredModel = registeredModels.find(
          (m) => m.name === values.modelName
        );
        const isHavePermissionToCreateVersion =
          selectedRegisteredModel?.allowedActions.update !== undefined
            ? selectedRegisteredModel.allowedActions.update
            : true;
        return { disabled: !isHavePermissionToCreateVersion };
      }}
      {...popupStateProps}
    >
      {({ setFieldValue, values }) => (
        <RegisteredModelWithExperimentRunFields
          registeredModels={registeredModels}
          values={values}
          isEnableRegisteredModelVersionsLock={
            isEnableRegisteredModelVersionsLock
          }
          setFieldValue={setFieldValue}
        />
      )}
    </PopupForm>
  );
};

const ModelField = ({
  registeredModels,
  name,
}: {
  registeredModels: {
    id: string;
    name: string;
  }[];
  name: string;
}) => {
  const options = registeredModels.map((m) => ({
    value: m.name,
    label: m.name,
  }));

  return (
    <AutocompleteField
      name={name}
      options={options}
      label="Select a registered model or create new one"
      validate={validateNotEmpty('registered model')}
    />
  );
};

export function useForm({ experimentRunId }: { experimentRunId: string }) {
  const workspace = useSelector(selectCurrentWorkspace);
  const organizationId = useCurrentOrganizationV2();

  const { registeredModels } = useRegisteredModels({
    workspaceName: workspace.name,
    organizationId,
  });

  const initialSettings: RegisteredModelWithExperimentRunCreationForm =
    React.useMemo(() => {
      return {
        modelName: '',
        versionName: '',
        versionDescription: '',
        versionLabels: [],
        experimentRunId,
        ...getDefaultLockLevelForm(),
      };
    }, [experimentRunId]);

  const navigate = useNavigate();

  const { create, creating } =
    useCreateRegisteredModelVersionWithExistingOrNewModel({
      registeredModels,
      onSuccess: ({ registeredModelId, versionId }) =>
        navigate(
          routes.registeredModelVersion.getRedirectPath({
            workspaceName: workspace.name,
            registeredModelId,
            versionId,
          })
        ),
    });

  return { initialSettings, create, creating, registeredModels };
}

export function RegisteredModelWithExperimentRunFields({
  registeredModels,
  values,
  isEnableRegisteredModelVersionsLock,
  setFieldValue,
}: {
  registeredModels: ExtractByTypename<
    RegisteredModels['workspace'],
    'Workspace'
  >['registeredModels']['models'];
  values: RegisteredModelWithExperimentRunCreationForm;
  isEnableRegisteredModelVersionsLock: boolean;
  setFieldValue: (field: string, value: any) => void;
}) {
  const selectedRegisteredModel = registeredModels.find(
    (m) => m.name === values.modelName
  );

  return (
    <>
      <ModelField
        name={getFieldName({ modelName: null })}
        registeredModels={registeredModels}
      />
      <TextInputField
        name={getFieldName({ experimentRunId: null })}
        validate={validateNotEmpty('experiment run ID')}
        label="Experiment run ID"
        isRequired={true}
        disabled={true}
      />
      <TextInputField
        name={getFieldName({ versionName: null })}
        validate={validateNotEmpty('version name')}
        label="Version name"
        isRequired={true}
      />
      <TextInputField
        name={getFieldName({ versionDescription: null })}
        validate={validateDescription}
        label="Description"
      />
      <TagsField name={getFieldName({ versionLabels: null })} />
      {isEnableRegisteredModelVersionsLock ? (
        <FeatureLockLevelSelect
          fieldName={getFieldName({ lockLevel: null })}
          allowedActions={
            selectedRegisteredModel
              ? selectedRegisteredModel.allowedActions
              : { lock: true }
          }
          setFieldValue={setFieldValue}
          value={values.lockLevel}
        />
      ) : null}
    </>
  );
}

export default RegisteredModelWithExperimentRunCreationPopup;
