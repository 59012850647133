import { gql } from '@apollo/client';

import { ARTIFACT_FRAGMENT } from 'shared/graphql/Artifact/Artifact';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';

import {
  Observation,
  ObservationAttribute,
} from './graphql-types/Observation.generated';

const OBSERVATION_ATTRIBUTE_FRAGMENT = gql`
  fragment ObservationAttribute on ObservationAttribute {
    attribute {
      ...AttributeData
    }
    timestamp
    epochNumber
  }
  ${ATTRIBUTE_FRAGMENT}
`;

const OBSERVATION_ARTIFACT_FRAGMENT = gql`
  fragment ObservationArtifact on ObservationArtifact {
    artifact {
      ...ArtifactData
    }
    timestamp
    epochNumber
  }
  ${ARTIFACT_FRAGMENT}
`;

export const OBSERVATION_FRAGMENT = gql`
  fragment Observation on Observation {
    ... on ObservationArtifact {
      ...ObservationArtifact
    }

    ... on ObservationAttribute {
      ...ObservationAttribute
    }
  }
  ${OBSERVATION_ATTRIBUTE_FRAGMENT}
  ${OBSERVATION_ARTIFACT_FRAGMENT}
`;

export const isObservationAttribute = (
  observation: Observation
): observation is ObservationAttribute =>
  observation.__typename === 'ObservationAttribute';
