/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { useMemo } from 'react';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import { IAttribute } from 'shared/models/Attribute';
import { convertGraphqlAttribute } from 'shared/graphql/Attribute/converters';
import { OmitStrict } from 'shared/utils/types';
import routes from 'shared/routes';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { useRouteParams } from 'shared/utils/router/useRouteParams';
import { ExtractByTypename } from 'shared/utils/types';

import * as GraphqlTypes from './graphql-types/useDefaultProject.generated';

type Project = ExtractByTypename<
  GraphqlTypes.DefaultProject['project'],
  'Project'
>;

type DefaultProject = OmitStrict<Project, 'attributes'> & {
  attributes: IAttribute[];
};
export type IWithDefaultProject = { project: DefaultProject };

const DEFAULT_PROJECT = gql`
  query DefaultProject($projectId: ID!) {
    project(id: $projectId) {
      ... on Error {
        ...ErrorData
      }
      ... on Project {
        id
        name
        description
        dateUpdated
        allowedActions {
          ...AllowedActionsData
        }
        attributes {
          ...AttributeData
        }
        workspace {
          id
          ...WorkspaceData
        }
        owner {
          ... on Group {
            id
            name
          }
          ... on User {
            id
            fullName
            username
          }
        }
        description
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
`;

const useDefaultProject = () => {
  const params = useRouteParams(routes.project);
  const projectId = useMemo(() => params.projectId, [params.projectId]);

  const queryResult = useCustomQuery<
    GraphqlTypes.DefaultProject,
    GraphqlTypes.DefaultProjectVariables
  >(DEFAULT_PROJECT, {
    fetchPolicy: 'cache-first',
    variables: { projectId },
  });

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert,
    queryResult,
  });

  return {
    project: data,
    loadingProject: communication,
    refetchProject: queryResult.refetch,
  };
};

function memoizedConvert(res: GraphqlTypes.DefaultProject) {
  return mapDataOrError(res.project, convertDefaultProject);
}

export function convertDefaultProject(project: Project) {
  return {
    ...project,
    attributes: project.attributes.map(convertGraphqlAttribute),
  };
}

export default useDefaultProject;
