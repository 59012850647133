import { ApolloLink, Operation } from '@apollo/client';

import {
  RestrictedGraphqlError,
  isRestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';
import { traverseJsonObjects } from 'shared/utils/json';

export const createRestrictedGraphqlErrorLink = () => {
  const handlers: Array<
    (graphqlError: RestrictedGraphqlError, operation: Operation) => void
  > = [];
  return {
    link: new ApolloLink((operation, forward) => {
      const observable = forward(operation);
      observable.subscribe({
        next: (result) => {
          traverseJsonObjects((obj) => {
            if (isRestrictedGraphqlError(obj)) {
              handlers.forEach((h) => {
                h(obj, operation);
              });
            }
          }, result.data);
        },
      });
      return observable;
    }),
    addInterceptor: (
      h: (graphqlError: RestrictedGraphqlError, operation: Operation) => void
    ) => {
      handlers.push(h);
    },
  };
};

export interface GraphqlErrorContextWithProp {
  errorOptions: GraphqlErrorContext;
  headers: {
    'Grpc-Metadata-organization-id': string;
    [key: string]: string;
  };
}

export interface GraphqlErrorContext {
  context: string;
  hideToastError?: boolean;
}
