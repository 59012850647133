import { Middleware } from 'redux';
import { createBrowserRouter } from 'react-router-dom';

import { IApplicationState } from 'setup/store/store';

import createApolloClient from './apollo/createApolloClient';
import configureStore from './store/configureStore';
import addInterceptors from './interceptors/addInterceptors';

export default function setup({
  initialState,
  storeMiddlewares,
  router,
}: {
  initialState?: Partial<IApplicationState>;
  storeMiddlewares?: Middleware[];
  router: ReturnType<typeof createBrowserRouter>;
}) {
  const {
    addErrorInterceptor: addGraphqlErrorInterceptor,
    addRequestResponseInterceptor: addGraphqlRequestResponseInterceptor,
    addRestrictedGraphqlErrorInterceptor,
    apolloClient,
  } = createApolloClient();

  const store = configureStore({
    apolloClient,
    initialState: initialState,
    extraMiddlewares: storeMiddlewares || [],
    router,
  });

  addInterceptors({
    store,
    router,
    addGraphqlErrorInterceptor,
    addGraphqlRequestResponseInterceptor,
    addRestrictedGraphqlErrorInterceptor,
  });

  return { store, apolloClient };
}
