import {
  countBy,
  flatten,
  groupBy,
  isEmpty,
  isNil,
  keys,
  merge,
  pickBy,
  reduce,
  sortBy,
} from 'lodash';

import { GraphqlAttributeData } from 'shared/graphql/Attribute/Attribute';
import { convertGraphqlAttribute } from 'shared/graphql/Attribute/converters';
import { ActivityFragment } from 'shared/graphql/registry/stage/graphql-types/Activity.generated';
import { AttributeData } from 'shared/graphql/Attribute/graphql-types/Attribute.generated';

import { Header } from './parseCsvFileToAttributeData';

export const VERTA_EVALUATION_LABEL = '__VERTA_EVALUATION';

export enum EvaluationAttributeKey {
  MODEL_ID = VERTA_EVALUATION_LABEL + '_MODEL_ID',
  CONFIGURATION = VERTA_EVALUATION_LABEL + '_CONFIGURATION',
  HEADERS = VERTA_EVALUATION_LABEL + '_HEADERS',
  ROW = VERTA_EVALUATION_LABEL + '_ROW',
}

export enum EvaluationHeaderKey {
  ID = '__VERTA_HEADER_ID',
  RESULT = '__VERTA_HEADER_RESULT',
  LABELS = '__VERTA_HEADER_LABELS',
  FEEDBACK = '__VERTA_HEADER_FEEDBACK',
}

interface EvaluationSummaryFields {
  labels: Array<string>;
  attributes: AttributeData[];
  activities: ActivityFragment[];
}

export type Evaluation<T extends EvaluationSummaryFields> = T & {
  rows: EvaluationData[];
  approvedRows: EvaluationData[];
  rejectedRows: EvaluationData[];
  labelsDistribution: Record<string, number>;
  headers: Header[];
  modelId?: string;
  configuration?: string;
};

export interface EvaluationData extends Record<string, any> {
  input: string;
  output: string;
  trace?: string;
  groundtruth?: string;
  [EvaluationHeaderKey.ID]: string;
  [EvaluationHeaderKey.RESULT]?: boolean;
  [EvaluationHeaderKey.LABELS]: Record<string, boolean>;
  [EvaluationHeaderKey.FEEDBACK]: string;
}

const buildEvaluationAttribute = <T>({
  attributes,
  key,
}: {
  attributes: GraphqlAttributeData[];
  key:
    | EvaluationAttributeKey.CONFIGURATION
    | EvaluationAttributeKey.HEADERS
    | EvaluationAttributeKey.MODEL_ID;
}) => {
  const attribute = attributes.find((attr) => attr.key === key);

  if (isNil(attribute)) {
    return undefined;
  }

  const value = convertGraphqlAttribute(attribute).value as string;
  if (key === EvaluationAttributeKey.HEADERS) {
    try {
      return JSON.parse(value) as T;
    } catch {
      return undefined;
    }
  }

  return value as T;
};

export const buildDefaultFromLabels = (
  labels: string[]
): Record<string, boolean> =>
  reduce(labels, (acc, key) => ({ ...acc, [key]: false }), {});

const buildEvaluationData = ({
  attributes,
  labels,
}: {
  attributes: GraphqlAttributeData[];
  labels: string[];
}): EvaluationData[] => {
  const unsortedDataAttributes = attributes.filter((attr) =>
    attr.key.includes(EvaluationAttributeKey.ROW)
  );

  if (isEmpty(unsortedDataAttributes)) {
    return [];
  }

  const sortedDataAttributes = sortBy(unsortedDataAttributes, ({ key }) =>
    parseInt(key.replace(`${EvaluationAttributeKey.ROW}_`, ''))
  );

  try {
    const attributesValues = sortedDataAttributes.map((attr) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      JSON.parse(convertGraphqlAttribute(attr).value as string)
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const data = attributesValues.map((attr) => {
      const _labels = (
        isEmpty(attr[EvaluationHeaderKey.LABELS])
          ? buildDefaultFromLabels(labels)
          : attr[EvaluationHeaderKey.LABELS]
      ) as Record<string, boolean>;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...attr,
        id: attr[EvaluationHeaderKey.ID] as string,
        [EvaluationHeaderKey.LABELS]: _labels,
      };
    });

    return data as EvaluationData[];
  } catch {
    return [];
  }
};

const extractAppliedLabels = (labels: Record<string, boolean>) =>
  keys(pickBy(labels, (isApplied) => isApplied));

const buildLabelsDistribution = <T extends EvaluationSummaryFields>({
  labels,
  rows,
}: Pick<Evaluation<T>, 'labels' | 'rows'>) => {
  const _defaultDistribution = reduce(
    labels,
    (acc, label) => {
      acc[label] = 0;
      return acc;
    },
    {} as Record<string, number>
  );
  const labelsApplied = rows.map((row) =>
    extractAppliedLabels(row[EvaluationHeaderKey.LABELS])
  );
  const labelsDistribution = countBy(flatten(labelsApplied));

  return merge(_defaultDistribution, labelsDistribution);
};

export const convertVersionToEvaluation = <T extends EvaluationSummaryFields>(
  version: T
): Evaluation<T> => {
  const rows = buildEvaluationData({
    attributes: version.attributes as unknown as GraphqlAttributeData[],
    labels: version.labels,
  });
  const headers =
    buildEvaluationAttribute<Header[]>({
      attributes: version.attributes as unknown as GraphqlAttributeData[],
      key: EvaluationAttributeKey.HEADERS,
    }) ?? [];
  const configuration = buildEvaluationAttribute<string>({
    attributes: version.attributes as unknown as GraphqlAttributeData[],
    key: EvaluationAttributeKey.CONFIGURATION,
  });
  const modelId = buildEvaluationAttribute<string>({
    attributes: version.attributes as unknown as GraphqlAttributeData[],
    key: EvaluationAttributeKey.MODEL_ID,
  });

  const { true: approvedRows, false: rejectedRows } = groupBy(
    rows,
    (row) => row[EvaluationHeaderKey.RESULT]
  );

  const labelsDistribution = buildLabelsDistribution({
    labels: version.labels,
    rows,
  });

  return {
    ...version,
    rows,
    headers,
    configuration,
    modelId,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    approvedRows: approvedRows ?? [],
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    rejectedRows: rejectedRows ?? [],
    labelsDistribution,
  } as Evaluation<T>;
};
