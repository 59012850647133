import { useSelector } from 'react-redux';
import React from 'react';
import Box from '@mui/material/Box';

import {
  cpuInfo,
  gpuQuantityInfo,
  memoryInfo,
} from 'shared/models/Deployment/canary/EndpointMachineConfiguration/Resources';
import { autoscalingMetricParameterInfo } from 'shared/models/Deployment/canary/EndpointMachineConfiguration/Autoscaling/Autoscaling';
import {
  AutoscalingMetricParameterType,
  EndpointEnvironmentIsolation,
} from 'generated/types';
import { mapMachineConfigWhenNotEmpty } from 'shared/models/Deployment/canary/EndpointMachineConfiguration/mapMachineConfigWhenNotEmpty';
import { MachineConfigInfo } from 'shared/graphql/Deployment/Endpoint/MachineConfig/graphql-types/MachineConfigInfo.generated';
import { AutoscalingMetricsData } from 'shared/graphql/Deployment/Endpoint/MachineConfig/graphql-types/MachineConfigInfo.generated';
import {
  IAutoscaling,
  IResources,
  IEnvironmentVariable,
} from 'shared/graphql/Deployment/Endpoint/MachineConfig/MachineConfigInfo';
import RecordsSection, {
  RecordInfo,
  NestedRecordInfo,
} from 'shared/view/elements/RecordsSection/RecordsSection';
import { selectFlags } from 'features/flags';
import { getSuccess } from 'shared/utils/result';

import styles from './MachineConfigInfo.module.css';

interface ILocalProps {
  machineConfig: MachineConfigInfo;
  canonicalAutoscalingMetrics: AutoscalingMetricsData['autoscalingMetrics'];
  render(views: JSX.Element): JSX.Element;
}

const MachineConfigInfoView = ({
  machineConfig,
  canonicalAutoscalingMetrics,
  render,
}: ILocalProps) => {
  const views = mapMachineConfigWhenNotEmpty(machineConfig)({
    autoscaling: (x) => (
      <AutoscalingInfo
        autoscaling={x}
        canonicalAutoscalingMetrics={canonicalAutoscalingMetrics}
      />
    ),
    resources: (x) => <ResourcesInfo resources={x} />,
    env: (x) => <EnvironmentInfo env={x} />,
    isolation: (x) => <IsolationInfo isolation={x} />,
  });

  let viewsFalseFlag = true;

  if (
    views?.autoscaling == null &&
    views?.resources == null &&
    views?.env == null &&
    views?.isolation?.props.isolation === 'SHARED'
  ) {
    viewsFalseFlag = false;
  }

  if (!viewsFalseFlag) {
    return null;
  }

  return render(
    <div data-test="machine-config-info">
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr"
        columnGap="15px"
        rowGap="20px"
      >
        {views?.autoscaling}
        {views?.resources}
        {views?.env}
        {views?.isolation}
      </Box>
    </div>
  );
};

const AutoscalingInfo: React.FC<
  React.PropsWithChildren<{
    autoscaling: IAutoscaling;
    canonicalAutoscalingMetrics: AutoscalingMetricsData['autoscalingMetrics'];
  }>
> = ({ autoscaling, canonicalAutoscalingMetrics }) => {
  const getQuantityDataTest = (type: keyof IAutoscaling['quantities']) => ({
    root: '',
    value: `machine-config-info-${type}-value`,
  });

  return (
    <Section title="Autoscaling">
      <RecordsSection>
        <NestedRecordInfo label="Quantities">
          <RecordInfo
            label="Max Replicas"
            dataTests={getQuantityDataTest('maxReplicas')}
          >
            {autoscaling.quantities.maxReplicas}
          </RecordInfo>
          <RecordInfo
            label="Min Replicas"
            dataTests={getQuantityDataTest('minReplicas')}
          >
            {autoscaling.quantities.minReplicas}
          </RecordInfo>
          <RecordInfo
            label="Max scale"
            dataTests={getQuantityDataTest('maxScale')}
          >
            {autoscaling.quantities.maxScale}
          </RecordInfo>
          <RecordInfo
            label="Min scale"
            dataTests={getQuantityDataTest('minScale')}
          >
            {autoscaling.quantities.minScale}
          </RecordInfo>
        </NestedRecordInfo>
        <NestedRecordInfo label="Metrics">
          {getFullMetrics(autoscaling.metrics, canonicalAutoscalingMetrics).map(
            (metric, metricIndex) => (
              <NestedRecordInfo
                key={metricIndex}
                label={metric.description}
                dataTests={{
                  label: 'machine-config-info-metric-description',
                  root: 'machine-config-info-metric',
                }}
              >
                {metric.parameters.map((parameter, parameterIndex) => (
                  <RecordInfo
                    key={parameterIndex}
                    label={parameter.name}
                    dataTests={{
                      value: 'machine-config-info-metric-parameter-value',
                    }}
                  >
                    {autoscalingMetricParameterInfo.format(
                      parameter.type,
                      parameter.value
                    )}
                  </RecordInfo>
                ))}
              </NestedRecordInfo>
            )
          )}
        </NestedRecordInfo>
      </RecordsSection>
    </Section>
  );
};

const getFullMetrics = (
  metrics: IAutoscaling['metrics'],
  canonicalMetrics: AutoscalingMetricsData['autoscalingMetrics']
) => {
  return metrics.map((metric) => {
    const description =
      canonicalMetrics.find((m) => m.id === metric.id)?.description ||
      'unknown';
    return {
      ...metric,
      description,
      parameters: metric.parameters.map((param) => {
        const type =
          canonicalMetrics
            .find((c) => c.id === metric.id)
            ?.parameters.find((p) => p.name === param.name)?.type ??
          AutoscalingMetricParameterType.UNKNOWN;
        return {
          ...param,
          type,
        };
      }),
    };
  });
};

const ResourcesInfo: React.FC<
  React.PropsWithChildren<{ resources: IResources }>
> = ({ resources }) => {
  const {
    deployment: { isEnableMachineConfigGpu },
  } = useSelector(selectFlags);

  return (
    <Section title="Resources">
      <RecordsSection>
        <RecordInfo
          label="CPU"
          dataTests={{
            value: 'machine-config-info-cpu',
          }}
        >
          {getSuccess(cpuInfo.format(resources.cpuMillis)) ?? '0'}
        </RecordInfo>
        <RecordInfo
          label="Memory"
          dataTests={{ value: 'machine-config-info-memory' }}
        >
          {memoryInfo.format(resources.memory)}
        </RecordInfo>
        {isEnableMachineConfigGpu && (
          <RecordInfo label="GPU">
            {resources.nvidiaGpu?.number != null
              ? gpuQuantityInfo.format(resources.nvidiaGpu.number)
              : 'Disabled'}
          </RecordInfo>
        )}
      </RecordsSection>
    </Section>
  );
};

const EnvironmentInfo: React.FC<
  React.PropsWithChildren<{ env: IEnvironmentVariable }>
> = ({ env }) => {
  return (
    <Section title="Environment variables">
      <RecordsSection>
        {env.map((variable) => (
          <RecordInfo
            key={variable.name}
            label={variable.name}
            dataTests={{
              value: 'machine-config-info-environment-variable-value',
            }}
          >
            {variable.value}
          </RecordInfo>
        ))}
      </RecordsSection>
    </Section>
  );
};

const IsolationInfo: React.FC<
  React.PropsWithChildren<{ isolation: EndpointEnvironmentIsolation }>
> = ({ isolation }) => {
  if (isolation === 'SHARED') {
    return null;
  }

  return (
    <Section title="Performance">
      <RecordsSection>
        <RecordInfo
          label={'Performance'}
          dataTests={{
            value: 'machine-config-info-isolation',
          }}
        >
          Dedicated
        </RecordInfo>
      </RecordsSection>
    </Section>
  );
};

const Section: React.FC<
  React.PropsWithChildren<{ title: string; children: React.ReactNode }>
> = ({ title, children }) => {
  return (
    <div className={styles.section}>
      <div className={styles.section__title}>{title}</div>
      <div className={styles.section__content}>{children}</div>
    </div>
  );
};

export default MachineConfigInfoView;
