import { scaleLinear } from '@visx/scale';
import { useMemo } from 'react';

import { ChartSizes } from '../chartSizes';

export const useChartSizeScales = ({
  chartSizes,
}: {
  chartSizes: ChartSizes;
}) => {
  const scaleChartX = useMemo(
    () =>
      scaleLinear({
        domain: [
          chartSizes.margin.left,
          chartSizes.innerWidth + chartSizes.margin.left,
        ],
        range: [0, chartSizes.innerWidth],
      }),
    [chartSizes.innerWidth, chartSizes.margin.left]
  );

  const scaleChartY = useMemo(
    () =>
      scaleLinear({
        domain: [
          chartSizes.margin.top,
          chartSizes.innerHeight + chartSizes.margin.top,
        ],
        range: [0, chartSizes.innerHeight],
      }),
    [chartSizes.innerHeight, chartSizes.margin.top]
  );

  return {
    scaleChartX,
    scaleChartY,
  };
};
