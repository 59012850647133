import { useCallback } from 'react';

import { MonitoringFilter } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';
import {
  getDefaultAggregation,
  MonitoringAggregation,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAggregation';
import { MonitoringDashboard } from 'shared/models/Monitoring/MonitoringModel/MonitoringDashboard/MonitoringDashboard';
import { MonitoringModelState } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import { MonitoringModelRegisteredModelVersion } from 'shared/models/Monitoring/MonitoringModel/MonitoringModelRegisteredModelVersion';
import { updateById } from 'shared/utils/collection';
import { TimeRange } from 'shared/utils/TimeRange';

export const useMonitoringModelStateManager = ({
  modelState,
  updateModelState,
}: {
  registeredModelVersions: MonitoringModelRegisteredModelVersion[];
  modelState: MonitoringModelState;
  updateModelState: (model: MonitoringModelState) => void;
}) => {
  const updateDashboard = useCallback(
    (dashboard: MonitoringDashboard) => {
      updateModelState({
        ...modelState,
        dashboards: updateById(
          () => dashboard,
          dashboard.id,
          modelState.dashboards
        ),
      });
    },
    [modelState, updateModelState]
  );

  const updateDashboards = useCallback(
    (dashboards: MonitoringDashboard[]) => {
      updateModelState({
        ...modelState,
        dashboards,
      });
    },
    [modelState, updateModelState]
  );

  const deleteDashboard = useCallback(
    (dashboardId: string) => {
      updateModelState({
        ...modelState,
        dashboards: modelState.dashboards.filter((d) => d.id !== dashboardId),
      });
    },
    [modelState, updateModelState]
  );

  const createDashboard = useCallback(
    (dashboard: MonitoringDashboard) => {
      updateModelState({
        ...modelState,
        dashboards: modelState.dashboards.concat(dashboard),
      });
    },
    [modelState, updateModelState]
  );

  const updateTimeRange = useCallback(
    (timeRange: TimeRange) => {
      updateModelState({
        ...modelState,
        timeRange,
        aggregation: getDefaultAggregation(timeRange),
      });
    },
    [modelState, updateModelState]
  );

  const updateAggregation = useCallback(
    (aggregation: MonitoringAggregation) => {
      updateModelState({ ...modelState, aggregation });
    },
    [modelState, updateModelState]
  );

  const selectRegisteredModelVersion = useCallback(
    (registeredModelVersion: MonitoringModelRegisteredModelVersion) => {
      updateModelState({ ...modelState, registeredModelVersion });
    },
    [modelState, updateModelState]
  );

  const updateFilters = useCallback(
    (filters: MonitoringFilter[]) => {
      updateModelState({
        ...modelState,
        filters,
      });
    },
    [modelState, updateModelState]
  );

  const updateCompareDashboard = useCallback(
    (dashboard: MonitoringDashboard) =>
      updateModelState({
        ...modelState,
        compare: {
          ...modelState.compare,
          dashboard,
        },
      }),
    [modelState, updateModelState]
  );

  const updateComparableModelVersions = useCallback(
    (registeredModelVersions: MonitoringModelRegisteredModelVersion[]) =>
      updateModelState({
        ...modelState,
        compare: {
          ...modelState.compare,
          registeredModelVersions,
        },
      }),
    [modelState, updateModelState]
  );

  return {
    selectRegisteredModelVersion,
    updateDashboard,
    deleteDashboard,
    createDashboard,
    updateTimeRange,
    updateDashboards,
    updateFilters,
    updateAggregation,
    updateCompareDashboard,
    updateComparableModelVersions,
  };
};
