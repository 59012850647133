import { descend, head, pipe, sort } from 'ramda';

import { TaskType } from 'generated/types';

export interface MonitoringModelRegisteredModelVersion {
  id: string;
  version: string;
  dateUpdated: Date;
  registeredModel: {
    id: string;
    name: string;
    taskType: TaskType;
  };
}

export const getSelectedRegisteredModelVersion = (
  actual: MonitoringModelRegisteredModelVersion,
  registeredModelVersions: MonitoringModelRegisteredModelVersion[]
): MonitoringModelRegisteredModelVersion => {
  if (registeredModelVersions.some((r) => r.id === actual.id)) {
    return actual;
  }

  const lastUpdated = getLastUpdatedRegisteredModelVersion(
    registeredModelVersions
  );

  if (lastUpdated) {
    return lastUpdated;
  }

  return actual;
};

export const getLastUpdatedRegisteredModelVersion = (
  mvs: MonitoringModelRegisteredModelVersion[]
) => pipe(sortModelVersionsDescend, (x) => head(x))(mvs);

export const sortModelVersionsDescend = (
  modelVersions: MonitoringModelRegisteredModelVersion[]
) =>
  sort(
    descend((x) => x.dateUpdated),
    modelVersions
  );
