import { selectCurrentUserOrThrowError } from 'features/user';
import { EntityId, IComment } from 'shared/models/Comment';
import { makeThunkApiRequest } from 'shared/utils/redux/actions';
import { AppError } from 'shared/models/Error';
import CommentsService from 'services/comments/CommentsService';

const namespace = 'comments';

export const loadComments = makeThunkApiRequest(
  namespace,
  'LOAD_COMMENTS',
  undefined
)<
  { entityId: EntityId },
  { entityId: EntityId; data: IComment[] },
  { entityId: string; error: AppError },
  { entityId: EntityId }
>(
  async ({ payload }) => {
    const comments = await CommentsService.loadComments(payload.entityId);
    return { entityId: payload.entityId, data: comments };
  },
  undefined,
  ({ requestPayload, error }) => ({ entityId: requestPayload.entityId, error })
);

export const addComment = makeThunkApiRequest(namespace, 'ADD_COMMENT', {
  context: 'adding comment',
})<
  { entityId: EntityId; message: string },
  { entityId: string; comment: IComment },
  AppError
>(async ({ payload, getState }) => {
  const user = selectCurrentUserOrThrowError(getState());
  const comment = await CommentsService.addComment(
    user,
    payload.entityId,
    payload.message
  );
  return { entityId: payload.entityId, comment };
});

export const deleteComment = makeThunkApiRequest(namespace, 'DELETE_COMMENT', {
  context: 'deleting comment',
})<
  { entityId: EntityId; commentId: string },
  { entityId: EntityId; commentId: string },
  { commentId: string; error: AppError },
  { commentId: string }
>(async ({ payload }) => {
  await CommentsService.deleteComment(payload.entityId, payload.commentId);
  return payload;
});
