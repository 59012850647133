import { Box, Stack, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { EvaluationProject } from 'features/evaluations/evaluationsList/hooks/useWorkspaceEvaluationProjects';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import Button from 'shared/view/elements/Button/Button';
import useUploadArtifact, {
  UploadArtifactProps,
} from 'features/artifactManager/store/hooks/useUploadArtifact';
import {
  RegisteredModelVersionLockLevel,
  StringKeyValueInput,
} from 'generated/types';
import { selectCurrentWorkspace } from 'features/workspaces';
import {
  EvaluationAttributeKey,
  EvaluationHeaderKey,
} from 'features/evaluations/shared/utils/evaluation';
import routes from 'shared/routes';
import { combineCommunications } from 'shared/utils/redux/communication/combineCommunications';
import { useAddAttributes } from 'features/evaluations/shared/hooks/useAddAttributes';

import { useCreateEvaluation } from '../hooks';
import { UploadDataset } from './UploadFileField';
import { EvaluationCreateInputs } from './EvaluationCreateInputs';
import { parseCsvFileToAttributeData } from '../../shared/utils/parseCsvFileToAttributeData';

export type CreateEvaluationProps = {
  evaluationProjects: EvaluationProject[];
};

type EvaluationCreateInput = {
  name: string; // version
  description: string;
  labels: string[];
  modelId: string; // EvaluationAttributeKey.CONFIGURATION
  configuration: string; // EvaluationAttributeKey.MODEL_ID
  evaluationProjectId: string; // registeredModelId
  file: File | undefined;
};
export const getFieldName = makeGetFieldName<EvaluationCreateInput>();

const INITIAL_VALUES = {
  name: '',
  description: '',
  labels: [],
  modelId: '',
  configuration: '',
  evaluationProjectId: '',
  file: undefined,
};

export const CreateEvaluation = (props: CreateEvaluationProps) => {
  const { evaluationProjects } = props;
  const [file, setFile] = useState<File | undefined>(undefined);
  const navigate = useNavigate();
  const currentWorkspace = useSelector(selectCurrentWorkspace);

  const { addAttributes, addingAttributes } = useAddAttributes({
    onCompleted: ({ registeredModelVersion }) => {
      if (isNotError(registeredModelVersion)) {
        navigate(
          routes.evaluationSummary.getRedirectPath({
            evaluationId: registeredModelVersion.id,
            workspaceName: currentWorkspace.name,
          })
        );
      }
    },
  });

  const handleUploadSuccess = async (
    _: void,
    artifacts: UploadArtifactProps[]
  ) => {
    const [artifact] = artifacts;

    const { headers, data } = await parseCsvFileToAttributeData(
      artifact.artifact
    );

    const attributes: StringKeyValueInput[] = [
      {
        key: EvaluationAttributeKey.HEADERS,
        value: JSON.stringify(headers),
      },
    ];
    data.forEach((attribute) => {
      attributes.push({
        key: `${EvaluationAttributeKey.ROW}_${
          attribute[EvaluationHeaderKey.ID]
        }`,
        value: JSON.stringify(attribute),
      });
    });

    addAttributes({ evaluationId: artifact.entityId, attributes });
  };

  const { uploadArtifact, uploadingArtifact, uploadProgress } =
    useUploadArtifact({
      onSuccess: handleUploadSuccess,
    });

  const { createEvaluation, creatingEvaluation } = useCreateEvaluation({
    onCompleted: async ({ registeredModel }) => {
      if (isNotError(registeredModel) && file) {
        const entityId = registeredModel.createVersion.id;
        uploadArtifact({
          entityId,
          key: file.name,
          artifact: file,
        });
      }
    },
  });

  const onSubmit = (values: EvaluationCreateInput) => {
    const {
      name,
      description,
      labels,
      modelId,
      configuration,
      evaluationProjectId,
      file: _file,
    } = values;
    setFile(_file);

    createEvaluation({
      name,
      description,
      labels,
      evaluationProjectId,
      lockLevel: RegisteredModelVersionLockLevel.OPEN,
      attributes: [
        {
          key: EvaluationAttributeKey.CONFIGURATION,
          value: configuration,
        },
        { key: EvaluationAttributeKey.MODEL_ID, value: modelId },
      ],
    });
  };

  const communication = combineCommunications([
    creatingEvaluation,
    uploadingArtifact,
    addingAttributes,
  ]);

  return (
    <Box pt={2}>
      <PresetFormik<EvaluationCreateInput>
        enableReinitialize
        initialValues={INITIAL_VALUES}
        onSubmit={onSubmit}
        validateOnChange
        validateOnMount
        validateOnBlur
      >
        {({ submitForm }) => {
          return (
            <Grid container spacing={3} height="100%">
              <Grid item xxs={4}>
                <UploadDataset
                  uploadingArtifactError={uploadingArtifact.error}
                  uploadProgress={uploadProgress}
                />
              </Grid>
              <Grid item xxs={8}>
                <Stack spacing={2}>
                  <Typography variant="subtitle1">
                    Configure Evaluation
                  </Typography>
                  <EvaluationCreateInputs
                    evaluationProjects={evaluationProjects}
                  />
                  <Stack
                    direction="row"
                    alignItems="baseline"
                    justifyContent="flex-end"
                  >
                    <Button
                      onClick={submitForm}
                      isLoading={communication.isRequesting}
                    >
                      Create
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          );
        }}
      </PresetFormik>
    </Box>
  );
};
