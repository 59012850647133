import { PureComponent, useState } from 'react';

// the initial version is https://github.com/konforti/react-truncate
// the code is translated to the ts
// adjust calculating of text width when parent size is dynamic

interface Props {
  text: string;
}

interface State {
  truncating: boolean;
  truncatedString: string | null;
}

interface Measurements {
  component: number;
  text: number;
  ellipsis: number;
}

class TruncateInMiddle extends PureComponent<Props, State> {
  private componentRef: HTMLElement | null = null;
  private ellipsisRef: HTMLElement | null = null;
  private textRef: HTMLElement | null = null;

  public state: State = {
    truncating: true,
    truncatedString: null,
  };

  getTruncateString(text: string) {
    const measurements: Measurements = {
      component: this.componentRef?.offsetWidth ?? 0,
      ellipsis: this.ellipsisRef?.offsetWidth ?? 0,
      text: this.textRef?.offsetWidth ?? 0,
    };

    return truncateString({
      measurements,
      text,
    });
  }

  resetTruncate = () => {
    // this renders the original string so we can measure it
    this.setState({ truncating: true }, () => {
      // now we render again with the truncated string
      const truncatedString = this.getTruncateString(this.props.text);
      this.setState({ truncatedString, truncating: false });
    });
  };

  componentDidMount() {
    // calculate  truncatedString and set state to render again with the truncated string
    const truncatedString = this.getTruncateString(this.props.text);
    this.setState({ truncatedString, truncating: false });

    window.addEventListener('resize', this.resetTruncate);
  }

  componentDidUpdate = (_: Props, prevState: State) => {
    /*
    Yes, we are using an anti-pattern here. 
    We want to render two times:
      one to display and measure the input string 
      one to display the truncated
    */
    this.state.truncating === prevState.truncating && this.resetTruncate();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resetTruncate);
  }

  setComponentRef = (element: HTMLElement | null) => {
    this.componentRef = element;
  };

  setTextRef = (element: HTMLElement) => {
    this.textRef = element;
  };

  setEllipsisRef = (element: HTMLElement) => {
    this.ellipsisRef = element;
  };

  render() {
    const { text, ...otherProps } = this.props;
    const { truncatedString, truncating } = this.state;

    const componentStyle = {
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    } as const;

    return (
      <div ref={this.setComponentRef} style={componentStyle} {...otherProps}>
        {truncating && <span ref={this.setTextRef}>{text}</span>}
        {truncating && <span ref={this.setEllipsisRef}>{ellipsisString}</span>}
        {!truncating && truncatedString}
      </div>
    );
  }
}

// this component is used for cases when we need responsive width, for example parent has max-width in 125px
// so we have to render text to check real width
const TruncateInMiddleWithFullWidth = ({ text }: { text: string }) => {
  const [width, setWidth] = useState<undefined | number>(undefined);
  return (
    <>
      {width === undefined && (
        <span
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          ref={(r) => {
            if (r) {
              setWidth(r.clientWidth);
            }
          }}
        >
          {text}
        </span>
      )}
      {width !== undefined && (
        <span style={{ width: `${width}px` }}>
          <TruncateInMiddle text={text} />
        </span>
      )}
    </>
  );
};

const ellipsisString = '...';

const truncateString = ({
  text,
  measurements,
}: {
  text: string;
  measurements: Measurements;
}) => {
  if (measurements.text > measurements.component) {
    const size = (percentage: number) =>
      measurements.component * (percentage / 100);

    const portion = (currSize: number) =>
      Math.floor((text.length * currSize) / measurements.text);

    const left = text.slice(0, Math.max(0, portion(size(50))) - 1);

    const right = text.slice(text.length - portion(size(50)) + 1, text.length);

    return `${left}${ellipsisString}${right}`;
  } else {
    return text;
  }
};

export { TruncateInMiddleWithFullWidth };
