import {
  QueryResult,
  MutationResult,
  NetworkStatus,
  OperationVariables,
} from '@apollo/client';
import { useMemo } from 'react';

import {
  ICommunication,
  requestingCommunication,
  successfulCommunication,
  pollingSuccessfulCommunication,
  initialCommunication,
  makeWithPollingErrorCommunication,
} from '../redux/communication';
import apolloErrorToAppError from './apolloErrorToAppError';
import noop from '../noop';
import isNotNil from '../isNotNill';

// todo replace all usage (where it is possible) of this function with useMemoizedResultToCommunicationWithData
const resultToCommunicationWithData = <T, S extends OperationVariables, R>(
  convert: (res: T) => R,
  queryResult: QueryResult<T, S> | MutationResult<T>
): {
  communication: ICommunication;
  data: R | undefined;
} => {
  const isPolling =
    (queryResult as QueryResult<T>).networkStatus === NetworkStatus.poll;
  if (queryResult.loading && !isPolling) {
    return {
      communication: requestingCommunication,
      data: isNotNil(queryResult.data) ? convert(queryResult.data) : undefined,
    };
  }
  if (queryResult.error) {
    return {
      communication: makeWithPollingErrorCommunication(
        apolloErrorToAppError(queryResult.error),
        isPolling
      ),
      data: isNotNil(queryResult.data) ? convert(queryResult.data) : undefined,
    };
  }
  if (isNotNil(queryResult.data)) {
    return {
      communication: isPolling
        ? pollingSuccessfulCommunication
        : successfulCommunication,
      data: convert(queryResult.data),
    };
  }
  return { communication: initialCommunication, data: undefined };
};

export const useMemoizedResultToCommunicationWithData = <
  T,
  S extends OperationVariables,
  R,
>({
  memoizedConvert,
  queryResult,
}: {
  memoizedConvert: (res: T) => R;
  queryResult: QueryResult<T, S> | MutationResult<T>;
}): {
  communication: ICommunication;
  data: R | undefined;
} => {
  return useMemo(
    () => resultToCommunicationWithData(memoizedConvert, queryResult),
    [memoizedConvert, queryResult]
  );
};

export const useMemoizedMutationResultToCommunication = (
  result: MutationResult
) =>
  useMemo(
    () => resultToCommunicationWithData(noop, result).communication,
    [result]
  );

// todo replace all usage (where it is possible) of this function with useMemoizedResultToCommunicationWithData
export default resultToCommunicationWithData;
