import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

import { TRACK_FRAGMENT } from './Track';

export const TRACK_EVENT = gql`
  mutation AnalyticsTrackEvent($input: TrackEvent) {
    analytics {
      track(event: $input) {
        ... on Error {
          ...ErrorData
        }
        ... on Track {
          ...TrackData
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${TRACK_FRAGMENT}
`;
