import { groupBy, isNil, toPairs } from 'ramda';

import {
  autoscalingMetricParameterInfo,
  autoscalingQuantity,
} from 'shared/models/Deployment/canary/EndpointMachineConfiguration/Autoscaling/Autoscaling';
import isNotNil from 'shared/utils/isNotNill';
import {
  AutoscalingUpdateConfig,
  AutoscalingMetricsParameterUpdateConfig,
} from 'generated/types';
import { mapObj } from 'shared/utils/collection';

import {
  IAutoscalingSettings,
  IAutoscalingMetricParameterForm,
} from '../../view/settings';

export const convertAutoscalingSettings = (
  settings: IAutoscalingSettings
): AutoscalingUpdateConfig => {
  return {
    metrics: convertMetrics(settings.metrics),
    quantities: convertQuantities(settings.quantities),
  };
};

const convertQuantities = (
  quantities: IAutoscalingSettings['quantities']
): AutoscalingUpdateConfig['quantities'] => {
  return Object.values(quantities).every((x) => isNil(x))
    ? undefined
    : mapObj(
        (x) => (isNil(x) ? undefined : autoscalingQuantity.parser(x)),
        quantities
      );
};

//

const convertMetrics = (
  metrics: IAutoscalingSettings['metrics']
): AutoscalingUpdateConfig['metrics'] => {
  return toPairs(
    groupBy(({ id }) => id, getSendingMetricsWithParameters(metrics))
  ).map(([metricId, metricsWithParamaters]) => ({
    id: metricId,
    parameters: metricsWithParamaters.flatMap(({ parameters }) =>
      parameters.map(convertParameter)
    ),
  }));
};

const getSendingMetricsWithParameters = (
  metrics: IAutoscalingSettings['metrics']
) => {
  const getSendingParams = (params: IAutoscalingMetricParameterForm[]) =>
    params.filter(({ name, value }) => name && value);

  return metrics
    .map(({ id, parameters }) => {
      const sendingParams = getSendingParams(parameters);
      return id && sendingParams.length > 0
        ? ({ id, parameters } as const)
        : undefined;
    })
    .filter(isNotNil);
};

const convertParameter = (
  parameter: IAutoscalingMetricParameterForm
): AutoscalingMetricsParameterUpdateConfig => {
  return {
    name: parameter.name,
    value: autoscalingMetricParameterInfo.parser(parameter.type)(
      parameter.value
    ),
  };
};
