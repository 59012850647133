import { useTheme } from '@mui/material';

// todo after removing Icon components rename this one into IconsColorPalette
type IconsAwesomeColorPalette = {
  info: {
    default: string;
    success: string;
    warning: string;
    error: string;
    nodata: string;
    info: string;
    purple: string;
    disabled: string;
  };
  clickable: {
    active: string;
    disabled: string;
  };
};

export type InfoColorType = keyof IconsAwesomeColorPalette['info'];

// todo implement DarkMode color palette
// todo after removing Icon components rename this one into useIconColorPalette
export function useIconAwesomeColorPalette({
  reversedColors,
}: {
  reversedColors?: boolean;
}): {
  colors: IconsAwesomeColorPalette;
} {
  return {
    colors: useDefaultThemeColorPalette({ reversedColors }),
  };
}

function useDefaultThemeColorPalette({
  reversedColors,
}: {
  reversedColors?: boolean;
}): IconsAwesomeColorPalette {
  const { palette } = useTheme();

  if (reversedColors) {
    return {
      info: {
        default: '#FFFFFF',
        success: palette.success.main,
        warning: palette.warning.main,
        error: palette.error.main,
        nodata: '#404040',
        purple: palette.primary.main,
        info: palette.info.main,
        disabled: palette.action.disabled,
      },
      clickable: {
        active: 'white',
        disabled: palette.text.disabled,
      },
    };
  }

  return {
    info: {
      default: '#56657C',
      success: palette.success.main,
      warning: palette.warning.main,
      error: palette.error.main,
      nodata: '#404040',
      purple: palette.primary.main,
      info: palette.info.main,
      disabled: palette.action.disabled,
    },
    clickable: {
      active: palette.primary.main,
      disabled: palette.text.disabled,
    },
  };
}
