import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ButtonProps as MUIButtonProps } from '@mui/material/Button';
import { useMemo } from 'react';

import { CustomEvent } from 'setup/app/analytics/types';

export type ButtonCommonProps = {
  icon?: IconDefinition;
  iconEnd?: IconDefinition;
  disabled?: boolean;
  dataTest?: string;
  name?: string;
  useDynamicTypography?: boolean;
  trackEvent?: CustomEvent;
} & Pick<
  MUIButtonProps,
  'variant' | 'onClick' | 'children' | 'sx' | 'color' | 'size'
>;

export function useButtonCommonProps(
  props: ButtonCommonProps & { isLoading?: boolean }
) {
  const contentProps = useMemo(
    () => ({
      ...props,
      variant: props.variant ?? 'contained',
      disabled: props.disabled || props.isLoading,
      size: props.size ?? 'medium',
      style: { maxWidth: '100%' },
      useDynamicTypography: props.useDynamicTypography ?? true,
    }),
    [props]
  );

  const buttonProps = useMemo(
    () => ({
      'data-test': props.dataTest,
      variant: contentProps.variant,
      disabled: contentProps.disabled,
      size: contentProps.size,
      style: contentProps.style,
      color: props.color,
      sx: props.sx,
    }),
    [
      contentProps.disabled,
      contentProps.size,
      contentProps.style,
      contentProps.variant,
      props.dataTest,
      props.color,
      props.sx,
    ]
  );

  return {
    contentProps,
    buttonProps,
  };
}
