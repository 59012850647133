import { Box, Divider, Grid, Stack, Typography } from '@mui/material';

import { BulkPredictionPollingState } from 'features/deployment/deployedExperimentRunPlayground/store/bulkPredictionPolling';
import {
  IDeployedExperimentRunSignature,
  IEndpointInfo,
} from 'shared/models/Deployment/DeployedExperimentRun';
import isNotNil from 'shared/utils/isNotNill';
import { isValidStringJson, JsonData, jsonToString } from 'shared/utils/json';
import { ICommunication } from 'shared/utils/redux/communication';
import Button from 'shared/view/elements/Button/Button';
import InlineErrorView from 'shared/view/elements/Errors/InlineErrorView/InlineErrorView';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import Alert from 'shared/view/elements/Alert/Alert';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import CodeBlock from 'shared/view/elements/CodeBlock/CodeBlock';

import ErrorModal from './ErrorModal';

type ExampleRecordType = {
  inputValue: string;
  changeInputValue: (value: string) => void;
  output: JsonData;
  disabled: boolean;
  runningPrediction: ICommunication;
  onRunPrediction: () => void;
  onStartBulkPrediction: () => void;
  onStopBulkPrediction: () => void;
  endpointInfo: IEndpointInfo;
  bulkPrediction: BulkPredictionPollingState;
  signature: IDeployedExperimentRunSignature;
};

const ExampleRecord = ({
  inputValue,
  changeInputValue,
  output,
  disabled,
  runningPrediction,
  onRunPrediction,
  onStartBulkPrediction,
  onStopBulkPrediction,
  endpointInfo,
  bulkPrediction,
  signature,
}: ExampleRecordType) => {
  const { isPopupOpen, closePopup, openPopup } = usePopupManager();

  return (
    <Stack width="100%">
      <Grid
        container
        flexDirection="row"
        display="flex"
        gap={2}
        width="100%"
        wrap="nowrap"
      >
        <Grid item width="100%" xs={6}>
          <Typography variant="subtitle2" mb={2}>
            Input
          </Typography>
          <Typography variant="body2">
            {signature.inputDescription.trim()}
          </Typography>

          <TextInput
            withoutError
            disabled={bulkPrediction.type === 'running' || disabled}
            multiline
            rows={10}
            value={inputValue}
            data-test="input-field"
            dataTest="input-field"
            onChange={(value) => changeInputValue(value)}
          />
          <Stack
            direction="row-reverse"
            justifyContent="space-between"
            width="100%"
            mt={2}
          >
            <Box>
              <Button
                isLoading={runningPrediction.isRequesting}
                variant="outlined"
                sx={{ mr: 1 }}
                disabled={
                  disabled ||
                  !endpointInfo.isActive ||
                  !inputValue ||
                  !isValidStringJson(inputValue) ||
                  bulkPrediction.type === 'running'
                }
                dataTest="run-prediction"
                onClick={onRunPrediction}
              >
                Run
              </Button>
              {bulkPrediction.type !== 'running' ? (
                <Button
                  variant="outlined"
                  disabled={
                    disabled ||
                    !endpointInfo.isActive ||
                    !inputValue ||
                    !isValidStringJson(inputValue)
                  }
                  isLoading={runningPrediction.isRequesting}
                  dataTest="run-bulk-prediction"
                  onClick={onStartBulkPrediction}
                >
                  Run Bulk Prediction
                </Button>
              ) : (
                <Button
                  dataTest="run-bulk-prediction"
                  onClick={onStopBulkPrediction}
                  isLoading={false}
                >
                  Stop Bulk Prediction
                </Button>
              )}
            </Box>
            {inputValue && !isValidStringJson(inputValue) && (
              <InlineErrorView
                error="Invalid JSON"
                dataTest="invalid-json-error"
              />
            )}
          </Stack>
        </Grid>
        <Divider flexItem orientation="vertical" />
        <Grid item width="100%" xs={6}>
          <Typography variant="subtitle2" mb={2}>
            Output
          </Typography>
          <Typography variant="body2">
            {signature.outputDescription.trim()}
          </Typography>
          <CodeBlock withCopy disabledCopy={disabled} pt={1} height="225px">
            {`${isNotNil(output) ? jsonToString(output) : ''}`}
          </CodeBlock>
        </Grid>
      </Grid>
      {runningPrediction.error ? (
        <Alert severity="error">
          There was an error based on your results,{' '}
          <InlineLink to="#" onClick={openPopup} simple variant="caption">
            click here
          </InlineLink>{' '}
          to view detail.
          <ErrorModal
            message={runningPrediction.error.message}
            isOpen={isPopupOpen}
            onClose={closePopup}
          />
        </Alert>
      ) : null}
    </Stack>
  );
};

export default ExampleRecord;
