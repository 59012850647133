import { FieldName } from 'shared/models/FieldName';
import matchBy from 'shared/utils/matchBy';
import { ChangePropertyType } from 'shared/utils/types';

export interface ISorting extends FieldName {
  direction: SortingDirection;
}

export type SortingDirection = 'asc' | 'desc';

type IDateCreatedSorting = ChangePropertyType<
  ISorting,
  'field',
  'date_created'
>;
type IDateUpdatedSorting = ChangePropertyType<
  ISorting,
  'field',
  'date_updated'
>;
export type IDateSorting = IDateCreatedSorting | IDateUpdatedSorting;

export const changeSortingDateFieldName = (
  newNames: Record<IDateSorting['field'], string>,
  sorting: IDateSorting
): ISorting => {
  return matchBy(
    sorting,
    'field'
  )({
    date_created: (dateCreatedSorting) => ({
      ...dateCreatedSorting,
      field: newNames.date_created,
    }),
    date_updated: (dateUpdatedSorting) => ({
      ...dateUpdatedSorting,
      field: newNames.date_updated,
    }),
  });
};
