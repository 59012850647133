import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FC, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import NA from 'shared/view/elements/PageComponents/NA/NA';
import Preloader from 'shared/view/elements/Preloader/Preloader';
import { IApplicationState } from 'setup/store/store';
import RecordsSection, {
  RecordInfo,
} from 'shared/view/elements/RecordsSection/RecordsSection';
import { jsonPrettify, jsonToString } from 'shared/utils/json';
import {
  defaultDeployedExperimentRunInfo,
  IEndpointInfo,
  makeSignatureDisplayValue,
} from 'shared/models/Deployment/DeployedExperimentRun';
import matchType from 'shared/utils/matchType';
import CardLayout from 'shared/view/elements/CardLayout/CardLayout';

import {
  actions as bulkPredictionActions,
  selectors as bulkPredictionSelectors,
} from '../../store/bulkPredictionPolling';
import { actions, selectors } from '../../store';
import CurlExampleRecord from './CurlExampleRecord/CurlExampleRecord';
import ExampleRecord from './ExampleRecord/ExampleRecord';

interface LocalProps {
  endpointInfo: IEndpointInfo;
}

const WrapperContainer = styled(Stack)({
  padding: '32px 32px 24px 32px',
});

const HandleDisableGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isKafkaEnabled',
})<{ isKafkaEnabled: boolean }>(({ isKafkaEnabled }) => ({
  opacity: isKafkaEnabled ? 0.5 : 1,
  cursor: isKafkaEnabled ? 'not-allowed' : '',
}));

const StyledTabs = styled((props: React.ComponentProps<typeof Tabs>) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: 'var(--main-color6)',
  },
});

const mapStateToProps = (state: IApplicationState) => {
  return {
    loadingDeployedExperimentRunPlaygroundInfo:
      selectors.selectCommunications(state).loadingDeployedExperimentRunInfo,
    deployedExperimentRunInfo: selectors.selectDeployedExperimentRunInfo(state),
    runningPrediction: selectors.selectCommunications(state).runningPrediction,
    output: selectors.selectOutput(state),
    bulkPrediction: bulkPredictionSelectors.selectState(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      loadDeployedExperimentRunPlaygroundInfo:
        actions.loadDeployedExperimentRunInfo,
      runPrediction: actions.runPrediction,
      reset: actions.reset,
      startBulkPrediction: bulkPredictionActions.startBulkPrediction,
      stopBulkPrediction: bulkPredictionActions.stopBulkPrediction,
    },
    dispatch
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  LocalProps & {
    isKafkaEnabled: boolean;
  };

type DisplayedModelType = 'pg' | 'info';

const DeployedExperimentRunPlayground: FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const [inputValue, changeInputValue] = useState<string>('');
  const [displayedModelType, changeDisplayedModelType] =
    useState<DisplayedModelType>('pg');

  const deployedExperimentRunInfo =
    props.deployedExperimentRunInfo ?? defaultDeployedExperimentRunInfo;

  const runPrediction = () => {
    if (props.endpointInfo.isActive) {
      props.runPrediction({
        endpointInfo: props.endpointInfo,
        input: jsonPrettify(inputValue),
      });
    }
  };

  const startBulkPrediction = () => {
    if (props.endpointInfo.isActive) {
      props.startBulkPrediction({
        endpointInfo: props.endpointInfo,
        input: jsonPrettify(inputValue),
      });
    }
  };

  const stopBulkPrediction = () => {
    if (props.bulkPrediction.type === 'running') {
      props.stopBulkPrediction();
    }
  };

  const { endpointInfo, loadDeployedExperimentRunPlaygroundInfo, reset } =
    props;

  useEffect(() => {
    if (endpointInfo.isActive) {
      loadDeployedExperimentRunPlaygroundInfo({
        endpointInfo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpointInfo.isActive]);

  useEffect(() => {
    if (!inputValue && props.deployedExperimentRunInfo) {
      changeInputValue(
        jsonPrettify(jsonToString(props.deployedExperimentRunInfo.inputExample))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deployedExperimentRunInfo?.inputExample]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  if (props.loadingDeployedExperimentRunPlaygroundInfo.isRequesting) {
    return (
      <div>
        <Preloader />
      </div>
    );
  }

  return (
    <HandleDisableGrid isKafkaEnabled={props.isKafkaEnabled}>
      <CardLayout data-test="playground-content">
        <StyledTabs
          value={displayedModelType}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label="Model playground"
            disabled={props.isKafkaEnabled}
            id="playground"
            aria-controls="tabpanel-playground"
            value="pg"
            onClick={() => changeDisplayedModelType('pg')}
          />
          <Tab
            label="Model information"
            disabled={props.isKafkaEnabled}
            id="information"
            aria-controls="tabpanel-information"
            value="info"
            onClick={() => changeDisplayedModelType('info')}
          />
        </StyledTabs>
        <WrapperContainer spacing={2} divider={<Divider />}>
          <Box minHeight="320px">
            {matchType(
              {
                pg: () => (
                  <ExampleRecord
                    disabled={props.isKafkaEnabled}
                    inputValue={inputValue}
                    bulkPrediction={props.bulkPrediction}
                    changeInputValue={changeInputValue}
                    endpointInfo={props.endpointInfo}
                    onRunPrediction={runPrediction}
                    onStartBulkPrediction={startBulkPrediction}
                    onStopBulkPrediction={stopBulkPrediction}
                    output={props.output}
                    runningPrediction={props.runningPrediction}
                    signature={deployedExperimentRunInfo.signature}
                  />
                ),
                info: () => (
                  <RecordsSection>
                    <RecordInfo label="Description">
                      {(
                        deployedExperimentRunInfo.signature.description || NA
                      ).trim()}
                    </RecordInfo>
                    <RecordInfo
                      label="Signature"
                      dataTests={{ root: 'signature-record' }}
                    >
                      {makeSignatureDisplayValue(deployedExperimentRunInfo)}
                    </RecordInfo>
                  </RecordsSection>
                ),
              },
              displayedModelType
            )}
          </Box>
          <Stack>
            <Typography variant="subtitle2">curl command</Typography>
            <CurlExampleRecord
              disabled={props.isKafkaEnabled}
              endpointInfo={props.endpointInfo}
              inputValue={inputValue}
            />
          </Stack>
        </WrapperContainer>
      </CardLayout>
    </HandleDisableGrid>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeployedExperimentRunPlayground);
