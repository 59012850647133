import { useMemo } from 'react';

import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import {
  defaultCurveChartWidgetVariant,
  CurveChartWidgetVariant,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/CurveChartWidget';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import handleUnionCases from 'shared/utils/handleUnionCases';
import { validateNotEmpty } from 'shared/utils/validators';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { IOptionType } from 'shared/view/elements/Selects/shared/types';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';

import MonitoringWidgetFormPopupContent from '../../MonitoringWidgetFormPopup/MonitoringWidgetFormPopupContent/MonitoringWidgetFormPopupContent';
import MonitoringOutputDescriptionField from '../shared/MonitoringOutputDescriptionField/MonitoringOutputDescriptionField';
import { BaseMonitoringWidgetFormProps } from '../shared/types/BaseMonitoringWidgetFormProps';

interface CurveChartWidgetFormType {
  title: string;
  type: 'curve';
  variant: CurveChartWidgetVariant;
  output: MonitoringIODescription;
}

const getFieldName = makeGetFieldName<CurveChartWidgetFormType>();

interface Props extends BaseMonitoringWidgetFormProps {
  submitText: string;
  onSubmit: (form: CurveChartWidgetFormType) => void;
  initialValues: CurveChartWidgetFormType | null;
  defaultOutput: MonitoringIODescription;
}

const CurveChartWidgetForm = (props: Props) => {
  const initialValues = useMemo(
    (): CurveChartWidgetFormType => ({
      type: 'curve',
      title: '',
      variant: defaultCurveChartWidgetVariant,
      output: props.defaultOutput,
    }),
    [props.defaultOutput]
  );

  return (
    <PresetFormik
      initialValues={props.initialValues ?? initialValues}
      onSubmit={props.onSubmit}
    >
      {({ isValid, handleReset, values }) => (
        <MonitoringWidgetFormPopupContent
          isValid={isValid}
          onBack={props.onBack}
          onReset={handleReset}
          submitText={props.submitText}
          widgetPreview={values}
          onSubmitClick={() => {
            props.onSubmit(values);
          }}
          widgetExternalDeps={props.widgetExternalDeps}
        >
          <TextInputField
            isRequired={true}
            label="Title"
            name={getFieldName({ title: null })}
            validate={validateNotEmpty('title')}
          />

          <VariantField name={getFieldName({ variant: null })} />

          <MonitoringOutputDescriptionField
            ioDescriptions={props.widgetExternalDeps.ioDescriptions}
            name={getFieldName({ output: null })}
          />
        </MonitoringWidgetFormPopupContent>
      )}
    </PresetFormik>
  );
};

const variantOptions = handleUnionCases<
  CurveChartWidgetVariant,
  IOptionType<CurveChartWidgetVariant>
>()([
  ['roc', (value) => ({ label: 'ROC curve', value })],
  ['precisionRecall', (value) => ({ label: 'Precision-Recall curve', value })],
]);

const VariantField = (props: { name: string }) => {
  return (
    <SelectField name={props.name} options={variantOptions} label={'Variant'} />
  );
};

export default CurveChartWidgetForm;
