/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import {
  isNotNullableRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { ExtractByTypename } from 'shared/utils/types';

import * as Types from './graphql-types/useCreateRegisteredModel.generated';

const CREATE_REGISTERED_MODEL = gql`
  mutation CreateRegisteredModel(
    $name: String!
    $description: String!
    $labels: [String!]
    $readmeText: String
    $workspaceName: String!
    $organizationId: ID
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        createRegisteredModel(
          name: $name
          description: $description
          labels: $labels
          readmeText: $readmeText
        ) {
          id
          name
          labels
          readmeText
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

type ResponseModel = ExtractByTypename<
  Types.CreateRegisteredModel['workspace'],
  'Workspace'
>['createRegisteredModel'];

export const useCreateRegisteredModel = ({
  onSuccess,
}: {
  onSuccess: (registeredModel: ResponseModel) => void;
}) => {
  const [createRegisteredModel, creatingRegisteredModel] = useCustomMutation<
    Types.CreateRegisteredModel,
    Types.CreateRegisteredModelVariables
  >(
    CREATE_REGISTERED_MODEL,
    { context: 'creating registered model' },
    {
      onCompleted: (_res) =>
        isNotNullableRestrictedGraphqlError(_res.workspace) &&
        onSuccess(_res.workspace.createRegisteredModel),
    }
  );

  return {
    createRegisteredModel,
    creatingRegisteredModel,
  };
};
