import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useModelVersionDocumentation } from 'features/catalog/registeredModelVersion/documentation/hooks';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

const ModelVersionDocumentation = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ModelVersionDocumentation' */ 'features/catalog/registeredModelVersion/documentation/view/documentation'
    )
);

const ModelVersionDocumentationPage = () => {
  const { versionId } = useRouteParams(routes.modelVersionDocumentation);

  const { data, communication } = useModelVersionDocumentation({
    id: versionId,
  });

  return (
    <DefaultMatchRemoteDataOrError
      communication={communication}
      data={data}
      context="loading a model version"
    >
      {(modelVersion) => (
        <ModelVersionDocumentation modelVersion={modelVersion} />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default ModelVersionDocumentationPage;
