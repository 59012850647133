import {
  MonitoringDataType,
  MonitoringIOType,
  MonitoringMetricType,
} from 'generated/types';

import { AlertSettingsType } from '../../../MonitoringAlert/AlertSettings';
import { MonitoringIODescription } from '../../../MonitoringIODescription';
import { BaseMonitoringWidget } from './shared/BaseMonitoringWidget';
import { BaseNotInitializedWidget } from './shared/BaseNotInitializedWidget';

interface BaseSingleMetricWidget extends BaseMonitoringWidget {
  type: 'singleMetric';
}

export interface SingleMetricCommonWidget extends BaseSingleMetricWidget {
  variant:
    | {
        type: 'metric';
        metricType: MonitoringMetricType.PREDICTION_COUNT;
        output?: MonitoringIODescription;
      }
    | {
        type: 'metric';
        metricType: Exclude<
          MonitoringMetricType,
          MonitoringMetricType.PREDICTION_COUNT
        >;
        output: MonitoringIODescription;
      };
}

export interface SingleMetricHealthWidget extends BaseSingleMetricWidget {
  variant: {
    type: 'health';
    alertType: AlertSettingsType;
  };
}

export type SingleMetricWidget =
  | SingleMetricCommonWidget
  | SingleMetricHealthWidget;

export const predictionCountOutput: MonitoringIODescription = {
  ioType: MonitoringIOType.OUTPUT,
  name: 'prediction_count',
  dataType: MonitoringDataType.DOUBLE,
};

export type NotInitializedSingleMetricWidget = BaseNotInitializedWidget<{
  type: SingleMetricWidget['type'];
  metricType: SingleMetricCommonWidget['variant']['metricType'];
}>;
