import { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  ConfirmType,
  DefaultConfirmActionProps as Props,
} from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';

const getConfirmationProps = (props: Props) => {
  const { confirmButtonText = 'OK', description, type } = props;

  return {
    title: `${type} ${description}`,
    text: <DefaultConfirmText {...props} />,
    ...(props.type === ConfirmType.delete
      ? {
          confirmButtonText: 'Delete' as const,
          cancelButtonText: 'Cancel' as const,
          maxWidth: 'xs' as const,
          fullWidth: true,
        }
      : { confirmButtonText }),
  };
};

const DefaultConfirmText = ({
  type,
  description,
  additionalText,
  entitiesQuantity,
  entityName,
  customBasicText,
}: Props) => {
  const basicText = useMemo(() => {
    const rightPart =
      entitiesQuantity !== undefined ? (
        `${entitiesQuantity} ${description.toLowerCase()}${
          entitiesQuantity > 1 ? 's' : ''
        }`
      ) : entityName ? (
        <>
          the <span style={{ fontWeight: 500 }}>{entityName}</span>
        </>
      ) : (
        `this ${description}`
      );
    return (
      <>
        Are you sure you want to {type.toLowerCase()} {rightPart}?
      </>
    );
  }, [entityName, entitiesQuantity, description, type]);

  return (
    <Stack direction={'column'} spacing={2}>
      <Typography variant="body2">
        {customBasicText ? customBasicText : basicText}
      </Typography>
      {additionalText ? (
        <Typography variant="body2">{additionalText}</Typography>
      ) : null}
    </Stack>
  );
};

export default getConfirmationProps;
