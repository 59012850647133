import { IAttribute } from 'shared/models/Attribute';
import noop from 'shared/utils/noop';
import { useFirstRenderEffect } from 'shared/view/hooks/useFirstRenderEffect';
import { OmitStrict } from 'shared/utils/types';
import useQueryFromFunction from 'shared/utils/graphql/useQueryFromFunction';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';
import { ExtractByTypename } from 'shared/utils/types';
import {
  Evaluation,
  convertVersionToEvaluation,
} from 'features/evaluations/shared/utils/evaluation';

import {
  EVALUATION_FOR_COMPARING,
  EvaluationForComparing,
  EvaluationForComparingVariables,
} from '../graphql';

type Version = ExtractByTypename<
  EvaluationForComparing['registeredModelVersion'],
  'RegisteredModelVersion'
>;

export type ComparedEvaluation = OmitStrict<
  Evaluation<Version>,
  'attributes'
> & {
  attributes: IAttribute[];
  dateCreated: number;
  dateUpdated: number;
};

export const useEvaluationsForComparing = (ids: string[]) => {
  const [loadEvaluationsForComparing, loadingEvaluations, evaluations] =
    useQueryFromFunction(
      (apolloClient) => async () => {
        const results = await Promise.all(
          ids.map((versionId) =>
            apolloClient.query<
              EvaluationForComparing,
              EvaluationForComparingVariables
            >({
              query: EVALUATION_FOR_COMPARING,
              variables: { versionId },
            })
          )
        );

        return results.map(convert);
      },
      { onCompleted: noop, onError: noop }
    );

  useFirstRenderEffect(() => {
    loadEvaluationsForComparing();
  });

  return {
    loadEvaluationsForComparing,
    loadingEvaluations,
    evaluations,
  };
};

const convert = ({ data }: { data: EvaluationForComparing }) =>
  mapDataOrError(
    data.registeredModelVersion,
    (evaluation): ComparedEvaluation => {
      const _eval = convertVersionToEvaluation(evaluation);
      return {
        ..._eval,
        attributes: convertRowsToCompare(_eval),
      };
    }
  );

const convertRowsToCompare = (version: Evaluation<Version>) => {
  return version.rows.map((row) => ({
    key: row.input,
    value: row.output,
  }));
};
