import { Box, Stack, Typography } from '@mui/material';

import Chip from 'shared/view/elements/Chip/Chip';

import image from '../images/organize-models.png';
import { Circle } from '../Circle';

export const OrganizeModels = () => (
  <Stack direction="row" spacing={0}>
    <Box width="65%" mr={4}>
      <img src={image} width="100%" height="auto" alt="model catalog example" />
    </Box>
    <Box width="35%" display="flex" alignItems="center">
      <Box>
        <Circle mb={5}>1</Circle>
        <Typography mb="12px" variant="subtitle1" color="text.secondary">
          Organize your Models
        </Typography>
        <Box maxWidth="306px">
          <Typography mb={2} variant="body2">
            Effortlessly capture, track, and share models, metadata, and
            statuses in your catalog.
          </Typography>
        </Box>
        <Chip
          variant="outlined"
          label="Unlimited for Free Tier"
          color="primary"
          size="small"
        />
      </Box>
    </Box>
  </Stack>
);
