import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import Button, { ButtonProps } from 'shared/view/elements/Button/Button';
import { useWorkspaceRegisteredModels } from 'features/homepage/hooks/useWorkspaceRegisteredModels';
import { selectCurrentWorkspaceName } from 'features/workspaces';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import routes from 'shared/routes';

export const TryNowButton = (
  props: Partial<ButtonProps> & {
    routePath?: 'modelVersionRelease' | 'modelVersionDocumentation';
  }
) => {
  const navigate = useNavigate();
  const organizationId = useCurrentOrganizationV2();
  const workspaceName = useSelector(selectCurrentWorkspaceName);
  const { routePath = 'modelVersionDocumentation' } = props;

  const { data } = useWorkspaceRegisteredModels(
    {
      organizationId,
      workspaceName,
    },
    { fetchPolicy: 'cache-first' }
  );

  const isEnable = isNotError(data) && !isEmpty(data.models);

  const handleNavigate = () => {
    let url = routes.catalog.getRedirectPath({ workspaceName }); // default navigation to catalog home page
    if (isNotError(data) && !isEmpty(data.models)) {
      // priority to redirect to first  model version doc page   of the first model
      if (!isEmpty(data.models[0].versions.versions)) {
        url = routes[routePath].getRedirectPath({
          versionId: data.models[0].versions.versions[0].id,
          registeredModelId: data.models[0].id,
          workspaceName,
        });
      } else {
        // if model don't have a version, redirect to model doc page
        url = routes.modelDocumentation.getRedirectPath({
          registeredModelId: data.models[0].id,
          workspaceName,
        });
      }
    }
    navigate(url);
  };

  return (
    <Button
      size="small"
      variant="contained"
      {...props}
      isLoading={false}
      onClick={handleNavigate}
      disabled={!isEnable}
    >
      Try it now
    </Button>
  );
};
