import isNotNil from 'shared/utils/isNotNill';

import { MonitoringDashboard } from '../../MonitoringDashboard/MonitoringDashboard';
import { MonitoringPanel } from '../../MonitoringPanel/MonitoringPanel';
import { MakeDefaultModelStateProps } from '../shared/MakeDefaultModelStateProps';
import { makeDecisionsPanel } from './shared/makeDecisionsPanel';
import { makeModelMetricsPanel } from './shared/makeModelMetricsPanel';
import { makePredictionsPanel } from './shared/makePredictionsPanel';
import { makePresetSummaryPanel } from './shared/presetSummaryPanel';

export const makePresetPerformanceDashboard = (
  props: MakeDefaultModelStateProps
): MonitoringDashboard => {
  return {
    createdBy: props.owner,
    dateCreated: new Date().toISOString(),
    id: 'performance',
    name: 'Performance',
    panels: [
      makePresetSummaryPanel(props, 'Summary'),
      makePredictionsPanel(props),
      makeModelMetricsPanel(props),
      makeDecisionsPanel(props),
    ].filter(isNotNil) as MonitoringPanel[],
  };
};
