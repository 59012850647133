import { Stack, styled, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import Card1 from 'features/homepage/images/modelCatalog/card1.svg';
import Card2 from 'features/homepage/images/modelCatalog/card2.svg';
import Card3 from 'features/homepage/images/modelCatalog/card3.svg';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { selectCurrentWorkspaceName } from 'features/workspaces';
import { Button } from 'shared/view/elements/Button';
import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';
import { useWorkspaceRegisteredModels } from 'features/homepage/hooks/useWorkspaceRegisteredModels';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import routes from 'shared/routes';

const StyleCard = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
}));

export const YourTurnStep = (props: WizardStepContentProps) => {
  const organizationId = useCurrentOrganizationV2();
  const navigate = useNavigate();
  const workspaceName = useSelector(selectCurrentWorkspaceName);

  const goToCatalog = () => {
    const url = routes.catalog.getRedirectPath({
      workspaceName,
    });

    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  };

  const { data } = useWorkspaceRegisteredModels(
    {
      organizationId,
      workspaceName,
    },
    { fetchPolicy: 'cache-first' }
  );

  const showSampleModel = isNotError(data) && !isEmpty(data.models);

  const goToSampleModel = (openNewWindow = true) => {
    if (isNotError(data) && !isEmpty(data.models)) {
      const url = routes.modelOverview.getRedirectPath({
        registeredModelId: data.models[data.models.length - 1].id,
        workspaceName,
      });
      if (openNewWindow) {
        const win = window.open(url, '_blank');
        if (win != null) {
          win.focus();
        }
      } else {
        navigate(url);
      }
    }
  };

  const cards = [
    {
      image: Card1,
      title: 'Check out a sample model populated with real data.',
      button: (
        <Button
          variant="outlined"
          onClick={() => goToSampleModel(true)}
          disabled={!showSampleModel}
          isLoading={false}
          size="small"
        >
          Go to sample model
        </Button>
      ),
    },
    {
      image: Card2,
      title: 'Navigate and explore model catalog at your own pace.',
      button: (
        <Button
          variant="outlined"
          size="small"
          onClick={goToCatalog}
          isLoading={false}
        >
          Go to model catalog
        </Button>
      ),
    },
    {
      image: Card3,
      title: 'Register your first model with a quick tutorial.',
      button: (
        <Button
          isLoading={false}
          variant="outlined"
          size="small"
          onClick={props.next}
        >
          Start registering
        </Button>
      ),
    },
  ];

  return (
    <WizardStepContent
      {...props}
      buttons={{
        next: {
          children: 'Go to sample model',
          onClick: () => goToSampleModel(false),
        },
      }}
    >
      <Typography variant="subtitle1" mb={2}>
        Now it's your turn
      </Typography>
      <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
        Ready to see it in action? Give it a try!
      </Typography>
      <Stack spacing={2} direction="row" mt={3}>
        {cards.map((card, index) => (
          <StyleCard spacing={3} padding={3} key={index} alignItems="center">
            <img height="146px" width="auto" src={card.image} alt="" />
            <Stack spacing={2} alignItems="center">
              <Typography variant="body2" textAlign="center">
                {card.title}
              </Typography>
              {card.button}
            </Stack>
          </StyleCard>
        ))}
      </Stack>
    </WizardStepContent>
  );
};
