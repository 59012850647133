import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';

import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { TruncateInMiddleWithFullWidth } from 'shared/view/elements/TruncateInMiddle/TruncateInMiddle';

import styles from './RemovedComparedEntities.module.css';

export function RemovedComparedEntities(props: {
  removedComparedEntities: Array<{
    id: string;
    name: string;
  }>;
  onCancelEntityRemoving(id: string): void;
}) {
  const theme = useTheme();
  return (
    <div className={styles.root}>
      <Grid container={true} spacing={1}>
        {props.removedComparedEntities.map((removedComparedEntity) => (
          <Grid item={true} key={removedComparedEntity.id}>
            <div
              className={styles.item}
              style={{
                borderColor: theme.palette.primary.main,
                color: theme.palette.text.primary,
              }}
            >
              <div className={styles.name}>
                <TruncateInMiddleWithFullWidth
                  text={removedComparedEntity.name}
                />
              </div>
              <IconAwesomeClickable
                icon={ICONS.xMark}
                dataTest="cancel-entity-removing"
                onClick={() =>
                  props.onCancelEntityRemoving(removedComparedEntity.id)
                }
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
