import { useCallback, useEffect, useState } from 'react';

import { toggle } from 'shared/utils/collection';

export const useActiveKeys = (keys: string[]) => {
  const [previousKeys, changePreviousKeys] = useState(keys);
  const [activeKeys, changeActiveKeys] = useState(keys);

  useEffect(() => {
    if (keys !== previousKeys) {
      const newKeys = keys.filter((key) => !previousKeys.includes(key));
      changeActiveKeys([...activeKeys, ...newKeys]);
      changePreviousKeys(keys);
    }
  }, [previousKeys, keys, activeKeys]);

  const toggleKey = useCallback(
    (key: string) => {
      changeActiveKeys(toggle(activeKeys, key));
    },
    [activeKeys]
  );

  return {
    activeKeys,
    toggleKey,
  };
};
