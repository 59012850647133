import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const LoginOptionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

export const ButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 52px;
  height: 42px;
`;

export const OptionLoginButton = styled(Box)`
  border: 1px solid rgba(63, 81, 181, 0.5);
  border-radius: 4px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;
