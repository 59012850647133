import { ObservationChartsSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/ObservationChartsSettings';
import { makeSuccess, Result } from 'shared/utils/result';

export type ObservationChartsSettingsForm = ObservationChartsSettings;

export const getInitialObservationChartsSettingsForm = (
  name: string
): ObservationChartsSettingsForm => {
  return {
    type: 'observationCharts',
    name,
  };
};

export const validateObservationChartsSettingsForm = (
  form: ObservationChartsSettingsForm
): Result<ObservationChartsSettingsForm, string> => {
  return makeSuccess(form);
};
