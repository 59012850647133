import { ReactNode } from 'react';
import Grid from '@mui/material/Grid';

import FormStack from 'shared/view/elements/FormStack/FormStack';
import { componentsSizes } from 'shared/mui/themes/CommonMuiThemeOptions';
interface Props {
  widgetPreview: ReactNode;
  children: ReactNode;
}

const WidgetFormPopupContentContainer = (props: Props) => {
  return (
    <div style={{ minWidth: '80vw' }}>
      <Grid container spacing={4}>
        <Grid item xs={7}>
          <div style={{ height: '50vh' }}>{props.widgetPreview}</div>
        </Grid>
        <Grid item xs={5} minHeight={360}>
          <FormStack maxWidth={componentsSizes.input.maxWidth}>
            {props.children}
          </FormStack>
        </Grid>
      </Grid>
    </div>
  );
};

export default WidgetFormPopupContentContainer;
