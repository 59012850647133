import { combineReducers } from 'redux';

import { makeCommunicationReducerFromResetableAsyncAction } from 'shared/utils/redux/actions';

import { loadFlags } from '../actions';
import { IFlagsState } from '../types';

export default combineReducers<IFlagsState['communications']>({
  loadingFlags: makeCommunicationReducerFromResetableAsyncAction(loadFlags),
});
