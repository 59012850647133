import { Box } from '@mui/material';

const FairnessPage = () => {
  return (
    <Box width="100%">
      <iframe
        title="Fairness"
        src="https://rai-alb-1982709507.us-east-1.elb.amazonaws.com/"
        style={{ width: '100%', height: 2000, border: 0 }}
      />
    </Box>
  );
};

export default FairnessPage;
