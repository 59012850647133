import Stack from '@mui/material/Stack';
import { equals } from 'ramda';

import {
  AlertAggregationWindow,
  AlertEvaluationFrequency,
  AlertStatus,
} from 'generated/types';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import {
  alertAggregationWindowsForSelection,
  getAlertAggregationWindowLabel,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertAggregationWindow';
import {
  alertEvaluationFrequencyForSelection,
  getAlertEvaluationFrequencyLabel,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertEvaluationFrequency';
import {
  alertStatusesForSelection,
  getAlertStatusLabel,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertStatus';
import SubmitButton from 'shared/view/elements/Button/SubmitButton';
import Button from 'shared/view/elements/Button/Button';

import styles from './UpdateBulkAlertsForm.module.css';

export interface UpdateBulkAlertsFormType {
  aggregationWindow: AlertAggregationWindow | undefined;
  evaluationFrequency: AlertEvaluationFrequency | undefined;
  status: AlertStatus | undefined;
}

interface Props {
  onUpdate: (form: UpdateBulkAlertsFormType) => void;
  isLoading: boolean;
  isUpdateButtonDisabled: boolean;
}

const getFieldName = makeGetFieldName<UpdateBulkAlertsFormType>();

const initialValues: UpdateBulkAlertsFormType = {
  aggregationWindow: undefined,
  evaluationFrequency: undefined,
  status: undefined,
};

const aggregationWindowOptions = alertAggregationWindowsForSelection.map(
  (window) => ({
    label: getAlertAggregationWindowLabel(window),
    value: window,
  })
);

const evaluationFrequencyOptions = alertEvaluationFrequencyForSelection.map(
  (frequency) => ({
    label: getAlertEvaluationFrequencyLabel(frequency),
    value: frequency,
  })
);

const alertStatusOptions = alertStatusesForSelection.map((status) => ({
  label: getAlertStatusLabel(status),
  value: status,
}));

const UpdateBulkAlertsForm = (props: Props) => {
  return (
    <PresetFormik initialValues={initialValues} onSubmit={props.onUpdate}>
      {({ values, resetForm }) => (
        <Stack direction="row" alignItems="center">
          <div className={styles.field}>
            <SelectField
              label="Aggregation window"
              options={aggregationWindowOptions}
              name={getFieldName({ aggregationWindow: null })}
            />
          </div>
          <div className={styles.field}>
            <SelectField
              label="Evaluation frequency"
              options={evaluationFrequencyOptions}
              name={getFieldName({ evaluationFrequency: null })}
            />
          </div>
          <div className={styles.field}>
            <SelectField
              label="Alert status"
              options={alertStatusOptions}
              name={getFieldName({ status: null })}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignSelf: 'flex-start',
              paddingTop: '8px',
            }}
          >
            <Stack direction="row">
              <Button
                onClick={() => resetForm()}
                variant="outlined"
                isLoading={false}
              >
                Reset
              </Button>
              <SubmitButton
                disabled={
                  props.isUpdateButtonDisabled || equals(values, initialValues)
                }
                isLoading={props.isLoading}
              >
                Update
              </SubmitButton>
            </Stack>
          </div>
        </Stack>
      )}
    </PresetFormik>
  );
};

export default UpdateBulkAlertsForm;
