import { gql } from '@apollo/client';
import { useCallback } from 'react';

import { AlertStatus } from 'generated/types';
import { ALERT_FRAGMENT } from 'shared/graphql/monitoring/Alert/Alert';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

import {
  CreateAlertMutation,
  CreateAlertMutationVariables,
} from './graphql-types/useCreateAlert.generated';
import {
  convertAlerterTypeToGraphQL,
  convertAlertFormToGraphQL,
} from '../shared/convertAlertFormToGraphQL';
import { MonitoringAlertFormType } from '../../view/shared/MonitoringAlertForm/shared/MonitoringAlertFormType';
import { convertAlertConditionToAlerter } from '../../view/shared/MonitoringAlertForm/shared/AlertCondition';

const CREATE_ALERT = gql`
  mutation CreateAlertMutation($monitoredEntityId: ID!, $alert: CreateAlert!) {
    monitoredEntity(id: $monitoredEntityId) {
      ... on Error {
        ...ErrorData
      }
      ... on MonitoredEntity {
        id
        createAlert(data: $alert) {
          id
          monitoredEntity {
            id
            alerts {
              alerts {
                id
                ...AlertFragment
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ALERT_FRAGMENT}
`;

export const useCreateAlert = ({
  onCompleted,
}: {
  onCompleted: (res: CreateAlertMutation) => void;
}) => {
  const [mutate, creatingAlert] = useCustomMutation<
    CreateAlertMutation,
    CreateAlertMutationVariables
  >(
    CREATE_ALERT,
    { context: 'creating alert' },
    {
      onCompleted,
    }
  );

  const createAlert = useCallback(
    ({
      monitoredEntityId,
      alert,
    }: {
      monitoredEntityId: string;
      alert: MonitoringAlertFormType;
    }) => {
      const alerter = convertAlertConditionToAlerter(alert.condition);
      mutate({
        monitoredEntityId: monitoredEntityId,
        alert: {
          enable: true,
          status: AlertStatus.OK,
          type: convertAlerterTypeToGraphQL(alerter.type),
          ...convertAlertFormToGraphQL(alert),
        },
      });
    },
    [mutate]
  );

  return {
    createAlert,
    creatingAlert,
  };
};
