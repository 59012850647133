import { gql } from '@apollo/client';

export const CUSTOM_ATTRIBUTE_CATEGORY_FRAGMENT = gql`
  fragment CustomAttributeCategoryData on CustomAttributeCategory {
    id
    organizationId
    name
    icon
    description
    sort
    timeCreated
    timeUpdated
  }
`;
