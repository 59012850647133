import { useSelector } from 'react-redux';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { isEmpty } from 'lodash';

import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';
import { selectCurrentUserOrThrowError } from 'features/user';

const WelcomeBanner = () => {
  const user = useSelector(selectCurrentUserOrThrowError);
  const now = new Date();

  return (
    <Grid item textAlign="left" xs={12}>
      <Typography variant="body1" color="text.primary">
        {moment(now).format('dddd, MMMM D')}
      </Typography>
      <Typography variant="h4" color="text.primary">
        Welcome to Verta, {getUserName(user)}
      </Typography>
    </Grid>
  );
};

const getUserName = ({
  fullName,
  firstName,
  lastName,
  username,
}: CurrentUserData) => {
  if (!isEmpty(fullName.trim())) return fullName;
  if (!isEmpty(firstName) || !isEmpty(lastName))
    return `${firstName} ${lastName}`;

  return username;
};

export default WelcomeBanner;
