import { useMemo } from 'react';
import { uniq } from 'ramda';

import { useDriftQuery } from 'features/monitoring/widgets/store/drift/useDrift';
import { DriftTableWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/TableWidget';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import CellRendererNumeric from 'shared/view/elements/DataGrid/columns/CellRendererNumeric';
import { typeSafeConfiguration } from 'shared/view/elements/DataGrid/configuration/helpers/typeSafeConfiguration';
import { DriftQuery } from 'features/monitoring/widgets/store/drift/graphql-types/useDrift.generated';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';
import matchType from 'shared/utils/matchType';
import { MonitoringIOType } from 'generated/types';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import isNotNil from 'shared/utils/isNotNill';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { Scalars } from 'generated/types';
import { ExtractByTypename } from 'shared/utils/types';

import { MonitoringWidgetProps } from '../../shared/types';
import { useIODescriptionDataGridSelection } from '../shared/useIODescriptionDataGridSelection';
import { useFeatureTableSortingProps } from '../shared/useFeatureTableSortingProps';
import { modelVersionColumn } from '../../../shared/modelVersionColumn';

const getDriftMetricType = (metric: DriftTableData) =>
  matchType(
    {
      [MonitoringIOType.INPUT]: () => 'input',
      [MonitoringIOType.OUTPUT]: () => 'output',
    },
    metric.ioType
  );

const FEATURE_FIELD = 'Feature';

const makeColumns = (
  showModelVersionColumn: boolean
): (DataGridColumn<DriftTableData> | null)[] => [
  {
    field: FEATURE_FIELD,
    minWidth: 120,
    flex: 4,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'string',
      (params) => params.row.name
    ),
    renderCell: (params) => <CellRendererString value={params.row.name} />,
  },
  {
    field: 'Type',
    minWidth: 100,
    flex: 1,
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'string',
      (params) => getDriftMetricType(params.row)
    ),
    renderCell: (params) => (
      <CellRendererString value={getDriftMetricType(params.row)} />
    ),
  },
  showModelVersionColumn ? modelVersionColumn : null,
  {
    field: 'Drift value',
    additionalConfiguration: typeSafeConfiguration(
      ['sort', 'filter'],
      'number',
      (params) =>
        isNotNil(params.row.value) ? parseGraphQLNumber(params.row.value) : 0
    ),
    flex: 1,
    align: 'right',
    renderCell: (params) =>
      isNotNil(params.row.value) ? (
        <CellRendererNumeric value={parseGraphQLNumber(params.row.value)} />
      ) : (
        <CellRendererString value="Not available" />
      ),
  },
];

interface DriftTableData {
  id: string;
  name: string;
  ioType: MonitoringIOType;
  modelVersion:
    | ExtractByTypename<
        DriftQuery['monitoredEntity'],
        'MonitoredEntity'
      >['metrics']['drift'][0]['modelVersion']
    | undefined;
  modelVersionId: string | undefined;
  value: Scalars['Number']['output'] | undefined;
}

const makeEmptyRow = (
  ioDescription: MonitoringIODescription
): DriftTableData => {
  return {
    id: ioDescription.name + ioDescription.ioType,
    ioType: ioDescription.ioType,
    name: ioDescription.name,
    modelVersion: undefined,
    modelVersionId: undefined,
    value: undefined,
  };
};

const addEmptyRows = (rows: DriftTableData[], emptyRows: DriftTableData[]) => {
  const missingRows = emptyRows.filter((row) =>
    rows.every((r) => r.ioType !== row.ioType && r.name !== row.name)
  );

  return [...rows, ...missingRows];
};

const DriftTableWidgetView = (
  props: MonitoringWidgetProps<DriftTableWidget>
) => {
  const { communication, data } = useDriftQuery({
    driftMetricType: props.widget.variant.driftMetricType,
    widgetExternalDeps: props.widgetExternalDeps,
  });

  const dataWithIds = useMemo(
    () =>
      (isNotNullableRestrictedGraphqlError(data) ? data : []).map((d, i) => {
        const modelVersionIdOrIdx = isNotNullableRestrictedGraphqlError(
          d.modelVersion
        )
          ? d.modelVersion.id
          : String(i);
        return {
          ...d,
          id: d.__typename + d.name + modelVersionIdOrIdx,
        };
      }),
    [data]
  );

  const emptyRows = useMemo(
    () => props.widgetExternalDeps.ioDescriptions.map(makeEmptyRow),
    [props.widgetExternalDeps.ioDescriptions]
  );

  const rows = useMemo(
    () => addEmptyRows(dataWithIds, emptyRows),
    [dataWithIds, emptyRows]
  );

  const selectionApi = useIODescriptionDataGridSelection({
    changeIODescription: props.widget.changeIODescription,
    ioDescription: props.widget.ioDescription,
    ioDescriptions: props.widgetExternalDeps.ioDescriptions,
    rows,
  });

  const sortingProps = useFeatureTableSortingProps({
    featureField: FEATURE_FIELD,
    changeIODescription: props.widget.changeIODescription,
    ioDescriptions: props.widgetExternalDeps.ioDescriptions,
  });

  const modelVersionIds = useMemo(
    () => uniq(dataWithIds.map((d) => d.modelVersionId)),
    [dataWithIds]
  );

  const columns = useMemo(
    () => makeColumns(modelVersionIds.length > 1).filter(isNotNil),
    [modelVersionIds]
  );

  return (
    <div style={props.size}>
      <DataGridWithTypes
        columns={columns}
        rows={rows}
        communication={communication}
        context="loading drift"
        heightType="parentHeight"
        {...selectionApi}
        {...sortingProps}
      />
    </div>
  );
};

export default DriftTableWidgetView;
