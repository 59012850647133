import { MonitoringDashboard } from 'shared/models/Monitoring/MonitoringModel/MonitoringDashboard/MonitoringDashboard';
import MonitoringPanelView from 'features/monitoring/panel/view/MonitoringPanelView/MonitoringPanelView';
import DnDPanels from 'shared/view/domain/Dashboards/DndPanels/DndPanels';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';

import { useMonitoringDashboard } from '../../store/useMonitoringDashboard';

interface Props {
  dashboard: MonitoringDashboard;
  updateDashboard: (dashboard: MonitoringDashboard) => void;
  widgetExternalDeps: MonitoringWidgetExternalDeps;
}

const MonitoringDashboardView = (props: Props) => {
  const { updatePanels, updatePanel, deletePanel } = useMonitoringDashboard({
    dashboard: props.dashboard,
    updateDashboard: props.updateDashboard,
  });

  return (
    <DnDPanels
      droppableId={props.dashboard.id}
      panels={props.dashboard.panels}
      onReorderedPanels={updatePanels}
    >
      {({ dragHandleProps, panel }) => (
        <MonitoringPanelView
          deletePanel={deletePanel}
          updatePanel={updatePanel}
          panel={panel}
          dragHandleProps={dragHandleProps}
          widgetExternalDeps={props.widgetExternalDeps}
        />
      )}
    </DnDPanels>
  );
};

export default MonitoringDashboardView;
