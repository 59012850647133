import routes from 'shared/routes';
import SystemAdminPagesLayout from 'pages/authorized/SystemAdmin/shared/SystemAdminPagesLayout/SystemAdminPagesLayout';
import PypiConfigurationList from 'features/systemAdmin/integrations/pypi/view/PypiConfigurationList';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import Button from 'shared/view/elements/Button/Button';
import { usePypiConfigurationsList } from 'features/systemAdmin/integrations/pypi/store/usePypiConfigurationsList';
import DefaultMatchRemoteData from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import PypiConfigurationsFormCreate from 'features/systemAdmin/integrations/pypi/view/form/PypiConfigurationsFormCreate';
import { docsLinks } from 'shared/utils/globalConstants/vertaDocLinks';

import useIntegrationPageBreadcrumbs from '../useIntegrationPageBreadcrumbs';
import {
  ConfigurationsWithFormCreate,
  useConfigurationsWithFormCreate,
} from '../ConfigurationsWithFormCreate';

export default function PypiConfigurationsPage() {
  const breadcrumbs = useAppBreadcrumbs(
    useIntegrationPageBreadcrumbs(),
    useBreadcrumbItem({
      name: 'PyPI configurations',
      route: routes.systemAdmin.$.integrations.$.pypi,
    })
  );

  const { data, communication } = usePypiConfigurationsList();

  const useConfigurationsWithFormCreateApi = useConfigurationsWithFormCreate();

  // todo refactoring: make all add buttons in a whole app to looks similar
  return (
    <SystemAdminPagesLayout
      breadcrumbs={breadcrumbs}
      actions={[
        <Button
          {...useConfigurationsWithFormCreateApi.addButtonProps}
          isLoading={false}
          key={'add'}
        >
          Add
        </Button>,
      ]}
    >
      <DefaultMatchRemoteData
        data={data}
        communication={communication}
        context="loading PyPI configurations"
      >
        {(configurations) => (
          <ConfigurationsWithFormCreate
            {...useConfigurationsWithFormCreateApi}
            configurations={configurations}
            FormCreate={PypiConfigurationsFormCreate}
            learnMoreTo={docsLinks.administration.pypi}
          >
            {() => (
              <PypiConfigurationList
                {...useConfigurationsWithFormCreateApi}
                configurations={configurations}
              />
            )}
          </ConfigurationsWithFormCreate>
        )}
      </DefaultMatchRemoteData>
    </SystemAdminPagesLayout>
  );
}
