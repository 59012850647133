import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { CHECKLIST_TEMPLATE_FRAGMENT } from 'shared/graphql/Checklist/ChecklistTemplate';
import { CHECKLIST_TEMPLATE_ITEM_FRAGMENT } from 'shared/graphql/Checklist/ChecklistTemplateItem';
import { CUSTOM_ATTRIBUTE_VALUE_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeValue';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { ExtractByTypename } from 'shared/utils/types';

import * as Types from './graphql-types/useOrganizationGovernance.generated';

export type OrganizationGovernance = ExtractByTypename<
  Types.OrganizationGovernance['organizationV2ByName'],
  'OrganizationV2'
>;

export { Types };

export const QUERY = gql`
  query OrganizationGovernance($organizationName: String!) {
    organizationV2ByName(name: $organizationName) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        name
        checklistTemplates {
          id
          ...ChecklistTemplateData
          checklistTemplateItems {
            id
            ...ChecklistTemplateItemData
          }
          customAttributeValues {
            id
            ...CustomAttributeValueData
          }
        }
        groups {
          id
          name
        }
      }
    }
  }

  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${CHECKLIST_TEMPLATE_FRAGMENT}
  ${CHECKLIST_TEMPLATE_ITEM_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_VALUE_FRAGMENT}
`;

const useOrganizationGovernance = (organizationName: string) => {
  const queryResult = useCustomQuery<Types.OrganizationGovernance>(QUERY, {
    variables: { organizationName },
  });

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert: ({ organizationV2ByName }) => organizationV2ByName,
    queryResult,
  });

  return {
    data,
    communication,
    reload: queryResult.refetch,
  };
};

export default useOrganizationGovernance;
