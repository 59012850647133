import { param, route } from 'shared/routes/shared/routesTreeBuilder';

export const privateRoutes = route('system-admin', {
  tag: 'systemAdmin',
})(
  route('organizations', {
    tag: 'systemAdminOrganizations',
  })(
    param()('organizationName', {
      tag: 'systemAdminOrganization',
    })()
  ),
  route('admins', {
    tag: 'admins',
  })(),
  route('users', {
    tag: 'systemAdminUsers',
  })(),
  route('integrations', {
    tag: 'integrations',
  })(
    route('pypi', {
      tag: 'pypi',
    })(),
    route('kafka', {
      tag: 'kafka',
    })(),
    route('active_directory', {
      tag: 'activeDirectory',
    })()
  )
);
