import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import Button from 'shared/view/elements/Button/Button';
import SubmitButton from 'shared/view/elements/Button/SubmitButton';
import { ProgressButton } from 'shared/view/elements/Button/ProgressButton';
import matchBy from 'shared/utils/matchBy';
import { OmitStrict } from 'shared/utils/types';

import CopyToClipboard from '../CopyToClipboard/CopyToClipboard';

type OmittedButtonProps = OmitStrict<
  React.ComponentProps<typeof Button>,
  'size' | 'variant' | 'iconEnd' | 'children'
> & {
  children: string;
  pressConfirmationInterval?: number;
};

export interface ButtonsProps {
  mainButtonProps?:
    | {
        type: 'copy';
        copyText: string | (() => string);
      }
    | ({
        type: 'button';
      } & OmittedButtonProps)
    | ({
        type: 'submit';
      } & OmittedButtonProps)
    | ({
        type: 'progress-button';
      } & OmittedButtonProps);
  secondaryButtonProps?: OmittedButtonProps & { variant?: 'outlined' | 'text' };
  tertiaryButtonProps?: OmittedButtonProps;
}

// todo: find a better name
export type PopupStateProps = {
  isOpen: boolean;
  onClose(): void;
};

export default function PopupButtons({
  mainButtonProps,
  secondaryButtonProps,
  tertiaryButtonProps,
}: ButtonsProps) {
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      sx={{ width: '100%' }}
    >
      {tertiaryButtonProps ? (
        <Button {...tertiaryButtonProps} variant="text" />
      ) : null}
      <Box sx={{ flexGrow: 1 }} />
      <Stack direction={'row'} justifyContent={'flex-end'}>
        {secondaryButtonProps ? (
          <Button variant="outlined" {...secondaryButtonProps} />
        ) : null}
        {mainButtonProps &&
          matchBy(
            mainButtonProps,
            'type'
          )({
            submit: (p) => (
              <SubmitButton {...p} dataTest={p.dataTest || 'confirm'} />
            ),
            button: (p) => <Button {...p} dataTest={p.dataTest || 'confirm'} />,
            'progress-button': (p) => (
              <ProgressButton
                {...p}
                dataTest={p.dataTest || 'confirm-with-long-press'}
              />
            ),
            copy: (p) => (
              <CopyToClipboard text={p.copyText}>
                {(onCopy) => (
                  <Button
                    onClick={onCopy}
                    isLoading={false}
                    useDynamicTypography={false}
                  >
                    Copy to clipboard
                  </Button>
                )}
              </CopyToClipboard>
            ),
          })}
      </Stack>
    </Stack>
  );
}
