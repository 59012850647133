import {
  IResources,
  cpuInfo,
  gpuInfo,
  gpuQuantityInfo,
  memoryInfo,
} from 'shared/models/Deployment/canary/EndpointMachineConfiguration/Resources';
import { ResourcesUpdateConfig } from 'generated/types';

import { ResultMachineConfigSettings } from '../view/settings';
import { convertAutoscalingSettings } from './convertAutoscalingSettings';

export const convertMachineConfig = (
  machineConfig: ResultMachineConfigSettings
) => {
  return {
    autoscaling: machineConfig.autoscaling
      ? convertAutoscalingSettings(machineConfig.autoscaling)
      : undefined,
    env: machineConfig.env ? convertEnv(machineConfig.env) : undefined,
    resources: machineConfig.resources
      ? convertResourcesToGraphql(machineConfig.resources)
      : undefined,
    isolation: machineConfig.isolation,
  };
};

const convertEnv = (env: NonNullable<ResultMachineConfigSettings['env']>) => {
  const sentEnv = env.filter(({ name, value }) => name && value);
  return sentEnv.length > 0 ? sentEnv : undefined;
};

const convertResourcesToGraphql = (
  resources: IResources
): ResourcesUpdateConfig => {
  return {
    nvidiaGpu: gpuInfo.convert(resources.isGpuEnabled)
      ? { number: gpuQuantityInfo.convert(resources.gpu?.number ?? 1) }
      : null,
    cpuMillis: cpuInfo.convert(resources.cpu),
    memory: memoryInfo.convert(resources.memory),
  };
};
