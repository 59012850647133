import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import Tooltip from 'shared/view/elements/Tooltip/Tooltip';

interface Props {
  onZoomReset: (() => void) | undefined;
}

const ZoomResetAction = (props: Props) => {
  return props.onZoomReset ? (
    <Tooltip type="withSpan" title="Reset zoom">
      <IconAwesomeClickable
        key="zoom"
        onClick={props.onZoomReset}
        icon={ICONS.magnifyingGlassMinus}
      />
    </Tooltip>
  ) : null;
};

export default ZoomResetAction;
