import routes from 'shared/routes';
import Button from 'shared/view/elements/Button/Button';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import SystemAdminsList from 'features/systemAdmin/admins/list/view/SystemAdminsList';
import InviteSystemAdminsPopup from 'features/systemAdmin/admins/add/view/InviteSystemAdminsPopup';
import SystemAdminPagesLayout from 'pages/authorized/SystemAdmin/shared/SystemAdminPagesLayout/SystemAdminPagesLayout';
import DefaultMatchRemoteData from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { useSystemAdminsList } from 'features/systemAdmin/admins/list/store/useSystemAdminsList';

export default function AdminsPage() {
  const {
    isPopupOpen: isOpenAddSystemAdminPopup,
    openPopup: openSystemAdminPopup,
    closePopup: closeSystemAdminPopup,
  } = usePopupManager(false);
  const breadcrumbs = useAppBreadcrumbs(
    useBreadcrumbItem({
      name: 'System admin',
      route: routes.systemAdmin,
    })
  );
  const { data, communication } = useSystemAdminsList();

  return (
    <>
      <SystemAdminPagesLayout
        breadcrumbs={breadcrumbs}
        actions={[
          <Button
            dataTest="add-system-admin"
            key={'add'}
            onClick={openSystemAdminPopup}
            isLoading={false}
          >
            Add
          </Button>,
        ]}
      >
        <DefaultMatchRemoteData
          data={data}
          communication={communication}
          context="loading system admins"
        >
          {(admins) => <SystemAdminsList admins={admins} />}
        </DefaultMatchRemoteData>
      </SystemAdminPagesLayout>
      {isOpenAddSystemAdminPopup ? (
        <InviteSystemAdminsPopup
          isOpen={isOpenAddSystemAdminPopup}
          onClose={closeSystemAdminPopup}
        />
      ) : null}
    </>
  );
}
