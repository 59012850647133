import routes from 'shared/routes';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { TabsLayout } from 'pages/authorized/shared/TabsLayout';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { useWorkspaceEvaluationProjects } from 'features/evaluations/evaluationsList/hooks/useWorkspaceEvaluationProjects';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { Button } from 'shared/view/elements/Button';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import EvaluationProjects from 'features/evaluations/evaluationProjects/view/EvaluationProjects';
import { CreateProjectPopup } from 'features/evaluations/create/view/CreateProject';

import { useEvaluationsPageBreadcrumbs } from './shared/useEvaluationsPageBreadcrumbs';

const EvaluationProjectsPage = () => {
  const workspaceName: string = useWorkspaceNameFromURL();
  const organizationId = useCurrentOrganizationV2();

  const { data, communication } = useWorkspaceEvaluationProjects({
    organizationId,
    workspaceName,
  });

  const breadcrumbs = useAppBreadcrumbs(
    useEvaluationsPageBreadcrumbs(),
    useBreadcrumbItem({
      name: 'Project settings',
      route: routes.evaluationProjects,
    })
  );

  return (
    <TabsLayout
      breadcrumbs={breadcrumbs}
      pageEntityOrError={data}
      actions={[
        <ButtonWithPopup
          key={0}
          button={(buttonProps) => (
            <Button {...buttonProps} isLoading={false}>
              Create project
            </Button>
          )}
          popup={(popupProps) => <CreateProjectPopup {...popupProps} />}
        />,
      ]}
    >
      <DefaultMatchRemoteDataOrError
        communication={communication}
        data={data}
        context="loading evaluations projects"
      >
        {(loaded) => {
          return (
            <EvaluationProjects
              projects={loaded}
              workspaceName={workspaceName}
            />
          );
        }}
      </DefaultMatchRemoteDataOrError>
    </TabsLayout>
  );
};

export default EvaluationProjectsPage;
