import { gql } from '@apollo/client';

import {
  UserDeleteSecondaryDevKeyV2,
  UserDeleteSecondaryDevKeyV2Variables,
} from 'features/developerKeysManager/graphql-types/useUserDeleteSecondaryDevKey.generated';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

const MUTATION = gql`
  mutation UserDeleteSecondaryDevKeyV2($organizationId: ID!, $userId: String!) {
    organizationV2(id: $organizationId) {
      ... on OrganizationV2 {
        id
        deleteSecondaryKey(userId: $userId) {
          ... on ServiceAccountV2 {
            id
            primaryKey
            secondaryKey
            updatedAt
          }
        }
      }
    }
  }
`;

export const useUserDeleteSecondaryDevKey = ({
  organizationId,
  userId,
  onChangeDevKeys,
}: {
  organizationId: string;
  userId: string;
  onChangeDevKeys(keys?: {
    primaryKey: string;
    secondaryKey: string | undefined;
  }): void;
}) => {
  const [mutate, deletingSecondaryDevKey] = useCustomMutation<
    UserDeleteSecondaryDevKeyV2,
    UserDeleteSecondaryDevKeyV2Variables
  >(
    MUTATION,
    { context: 'adding dev key' },
    {
      onCompleted: (res) => {
        if (
          res.organizationV2.__typename === 'OrganizationV2' &&
          res.organizationV2.deleteSecondaryKey.__typename === 'UserV2'
        ) {
          onChangeDevKeys();
          return;
        }
        if (
          res.organizationV2.__typename === 'OrganizationV2' &&
          res.organizationV2.deleteSecondaryKey.__typename ===
            'ServiceAccountV2'
        ) {
          onChangeDevKeys(res.organizationV2.deleteSecondaryKey);
        }
      },
    }
  );

  return {
    deleteSecondaryDevKey: () => mutate({ organizationId, userId }),
    deletingSecondaryDevKey,
  };
};
