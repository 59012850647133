import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';

import * as Types from './graphql-types/useCreateGroup.generated';
import { USER_OR_SERVICE_ACCOUNT_FOR_GROUP } from './useOrganizationGroups';

export { Types };

export const QUERY = gql`
  mutation CreateGroup(
    $organizationId: ID!
    $name: String!
    $description: String!
    $memberIds: [ID!]!
  ) {
    organizationV2(id: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        createGroup(
          input: {
            name: $name
            description: $description
            memberIds: $memberIds
          }
        ) {
          id
          groups {
            id
            name
            description
            builtIn
            members {
              ...UserOrServiceAccountForGroup
            }
            allowedActions {
              ...AllowedActionsData
            }
          }
        }
      }
    }
  }
  ${USER_OR_SERVICE_ACCOUNT_FOR_GROUP}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useCreateGroup = (onCompleted: () => void) => {
  const [run, communication] = useCustomMutation<
    Types.CreateGroup,
    Types.CreateGroupVariables
  >(QUERY, { context: 'creating group' }, { onCompleted });

  return {
    run,
    communication,
  };
};
