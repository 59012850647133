import {
  Grid,
  Stack,
  Typography,
  Paper,
  Box,
  PaperProps,
  styled,
} from '@mui/material';

import AwsLogo from '../images/aws-logo.svg?react';
import AzureLogo from '../images/azure-logo.svg?react';
import GoogleCloudLogo from '../images/google-cloud-logo.svg?react';
import VmwareLogo from '../images/vmware-logo.svg?react';
import TensorFlowLogo from '../images/tensor-flow-logo.svg?react';
import PyTorchLogo from '../images/py-torch-logo.svg?react';
import ScikitLearnLogo from '../images/scikit-learn-logo.svg?react';
import XgBoostLogo from '../images/xg-boost-logo.svg?react';
import RLogo from '../images/r-logo.svg?react';
import OnnxLogo from '../images/onnx-logo.svg?react';
import PmmlLogo from '../images/pmml-logo.svg?react';
import KubeflowLogo from '../images/kubeflow-logo.svg?react';
import AnacondaLogo from '../images/anaconda-logo.svg?react';
import SageMakerLogo from '../images/amazon-sagemaker-logo.svg?react';
import SparkLogo from '../images/spark-logo.svg?react';

const Card = ({ children, title }: PaperProps & { title: string }) => (
  <Paper sx={{ padding: '20px', height: '100%' }}>
    <Stack alignItems="center" spacing={3} display="flex" height="100%">
      <Box flex="0 1 auto" display="flex">
        <Typography variant="subtitle1" color="primary.dark">
          {title}
        </Typography>
      </Box>
      <Stack
        spacing={3}
        flex="1 1 auto"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {children}
      </Stack>
    </Stack>
  </Paper>
);
const Row = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  width: '100%',
}));

export const VertaInfra = () => (
  <Grid container spacing={2} height="100%">
    <Grid item xxs={4}>
      <Card title="Verta runs on">
        <AwsLogo width={37} height={22} />
        <GoogleCloudLogo width={52} height={31} />
        <AzureLogo width={67} height={19} />
        <VmwareLogo width={62} height={10} />
      </Card>
    </Grid>
    <Grid item xxs={4}>
      <Card title="Verta supports">
        <Row>
          <TensorFlowLogo width={49} height={32} />
          <PyTorchLogo width={45} height={34} />
        </Row>
        <Row>
          <SparkLogo width={51} height={26} />
          <RLogo width={28} height={22} />
        </Row>
        <Row>
          <ScikitLearnLogo width={65} height={35} />
          <XgBoostLogo width={49} height={19} />
        </Row>
        <Row>
          <OnnxLogo width={59} height={15} />
          <PmmlLogo width={42} height={13} />
        </Row>
      </Card>
    </Grid>
    <Grid item xxs={4}>
      <Card title="Verta integrates with">
        <KubeflowLogo width={50} height={50} />
        <AnacondaLogo width={58} height={31} />
        <SparkLogo width={43} height={22} />
        <SageMakerLogo width={97} height={41} />
      </Card>
    </Grid>
  </Grid>
);
