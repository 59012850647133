import { useCallback, useMemo } from 'react';

import { DriftMonitoringPanel } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringPanel';
import { MonitoringWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidget';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import {
  STATIC_DISTRIBUTION_OVER_TIME_WIDGET_ID,
  STATIC_DISTRIBUTION_WIDGET_ID,
  STATIC_DRIFT_TABLE_WIDGET_ID,
} from 'shared/models/Monitoring/MonitoringModel/defaultModelState/presetDashboards/presetDriftDashboard';

import { MonitoringPanelProps } from '../shared/MonitoringPanelProps';
import StableMonitoringPanel from '../shared/StableMonitoringPanel/StableMonitoringPanel';
import EditDriftPanelAction from './EditDriftPanelAction/EditDriftPanelAction';
import { useCustomPanelInitialization } from '../shared/useCustomPanelInitialization';

const DriftMonitoringPanelView = (
  props: MonitoringPanelProps<DriftMonitoringPanel>
) => {
  const { panel, updatePanel } = props;
  const onChangeIODescription = useCallback(
    (ioDescription: MonitoringIODescription) =>
      updatePanel({ ...panel, ioDescription }),
    [panel, updatePanel]
  );

  useCustomPanelInitialization({
    panel,
    changeIODescription: onChangeIODescription,
    ioDescriptions: props.widgetExternalDeps.ioDescriptions,
  });

  const widgets = useMemo(
    (): MonitoringWidget[] =>
      props.panel.ioDescription
        ? [
            {
              id: STATIC_DRIFT_TABLE_WIDGET_ID,
              type: 'table',
              title: 'Drift summary',
              variant: {
                type: 'drift',
                driftMetricType: props.panel.driftMetricType,
              },
              changeIODescription: onChangeIODescription,
              ioDescription: props.panel.ioDescription,
            },
            {
              ioDescription: props.panel.ioDescription,
              id: STATIC_DISTRIBUTION_OVER_TIME_WIDGET_ID,
              type: 'histograms',
              title: 'Distribution over time',
              variant: 'distribution',
              driftMetricType: props.panel.driftMetricType,
            },
            {
              ioDescription: props.panel.ioDescription,
              id: STATIC_DISTRIBUTION_WIDGET_ID,
              type: 'barChart',
              title: 'Distribution',
              variant: 'distribution',
            },
          ]
        : [
            {
              id: STATIC_DRIFT_TABLE_WIDGET_ID,
              type: 'table',
              title: 'Drift summary',
              variant: {
                type: 'drift',
                driftMetricType: props.panel.driftMetricType,
              },
            },
            {
              id: STATIC_DISTRIBUTION_OVER_TIME_WIDGET_ID,
              type: 'notInitialized',
              title: 'Distribution over time',
              settings: {
                type: 'histograms',
                variant: 'distribution',
                driftMetricType: props.panel.driftMetricType,
              },
            },
            {
              id: STATIC_DISTRIBUTION_WIDGET_ID,
              type: 'notInitialized',
              title: 'Distribution',
              settings: {
                type: 'barChart',
                variant: 'distribution',
              },
            },
          ],
    [
      onChangeIODescription,
      props.panel.driftMetricType,
      props.panel.ioDescription,
    ]
  );

  return (
    <StableMonitoringPanel
      {...props}
      widgets={widgets}
      actions={[
        <EditDriftPanelAction
          key="edit"
          panel={props.panel}
          updatePanel={props.updatePanel}
        />,
      ]}
    />
  );
};

export default DriftMonitoringPanelView;
