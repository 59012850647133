import { WatchQueryFetchPolicy } from '@apollo/client';
import { gql } from '@apollo/client';
import { equals } from 'ramda';
import { useCallback, useContext, useEffect, useState } from 'react';
import { debounce } from '@mui/material/utils';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import {
  isNotNullableRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import createContext from 'shared/utils/react/createContext';
import { ICommunication } from 'shared/utils/redux/communication';
import { usePagination } from 'shared/view/hooks/usePagination';
import withCancelPreviousQuery from 'shared/utils/graphql/withCancelPreviousQuery';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { ExtractByTypename } from 'shared/utils/types';
import { trackEvent } from 'setup/app/analytics';

import * as Types from './graphql-types/useOrganizationUsers.generated';

export { Types };

export type OrganizationV2Users = ExtractByTypename<
  Types.OrganizationV2Users['organizationV2ByName'],
  'OrganizationV2'
>;

export type Filters = {
  onlyServiceAccounts: boolean;
  groupIds: string[];
  workspaceIds: string[];
  search: string;
};

export const QUERY = gql`
  query OrganizationV2Users($name: String!, $query: UsersQuery!) {
    organizationV2ByName(name: $name) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        name
        groups {
          id
          name
          allowedActions {
            ...AllowedActionsData
          }
        }
        workspaces {
          id
          name
        }
        users(query: $query) {
          pagination {
            page
            limit
            totalRecords
          }
          users {
            ... on UserV2 {
              id
              email
              fullName
              firstName
              lastName
              joinedAt
              updatedAt
              lastSignedIn
              workspaces {
                id
                name
              }
              groups {
                id
                name
              }
            }
            ... on ServiceAccountV2 {
              id
              fullName
              email
              lastSignedIn
              joinedAt
              description
              primaryKey
              secondaryKey
              updatedAt
              workspaces {
                id
                name
              }
              groups {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;

const [organizationUsersContext, OrganizationUsersContextProvider] =
  createContext<{
    usersApi: {
      data: Types.OrganizationV2Users['organizationV2ByName'] | undefined;
      reload: () => void;
      communication: ICommunication;
    };
  }>((props) => props);
export { OrganizationUsersContextProvider };
export const useOrganizationUsersContext = () => {
  return useContext(organizationUsersContext);
};

const useOrganizationUsers = (
  organizationName: string,
  fetchPolicy?: WatchQueryFetchPolicy
) => {
  const [load__, queryResult] = useCustomLazyQuery<
    Types.OrganizationV2Users,
    Types.OrganizationV2UsersVariables
  >(QUERY, {
    fetchPolicy: fetchPolicy ? fetchPolicy : 'cache-and-network',
  });
  const debouncedLoad = useCallback(
    (variables: Types.OrganizationV2UsersVariables) => {
      const res = debounce(
        withCancelPreviousQuery(
          (context) => (vars: Types.OrganizationV2UsersVariables) => {
            load__({
              variables: vars,
              context,
            });
            trackEvent({
              type: 'organization.users.filter_apply',
              data: {
                onlyServiceAccounts:
                  vars.query.isOnlyServiceAccounts || undefined,
                groupIds: vars.query.groupIds || undefined,
                workspaceIds: vars.query.workspaceIds || undefined,
                search: vars.query.search || undefined,
              },
            });
          }
        ),
        300
      );
      return res(variables);
    },
    [load__]
  );

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert: (x) => x.organizationV2ByName,
    queryResult,
  });

  const paginationApi = usePagination({
    totalCount: isNotNullableRestrictedGraphqlError(data)
      ? data.users.pagination.totalRecords
      : 0,
  });

  const [filters, setFilters] = useState<Filters>(initialFilters);
  const setGroupIds = (n: string[]) => setFilters({ ...filters, groupIds: n });
  const setOnlyServiceAccounts = (n: boolean) =>
    setFilters({ ...filters, onlyServiceAccounts: n });
  const setSearch = (n: string) => setFilters({ ...filters, search: n });

  const setWorkspaceIds = (n: string[]) =>
    setFilters({ ...filters, workspaceIds: n });

  const reloadUsers = useCallback(() => {
    debouncedLoad({
      name: organizationName,
      query: {
        pagination: {
          limit: paginationApi.pagination.pageSize,
          page: paginationApi.pagination.currentPage + 1,
        },
        search: filters.search,
        workspaceIds: filters.workspaceIds,
        isOnlyServiceAccounts: filters.onlyServiceAccounts,
        groupIds: filters.groupIds,
      },
    });
  }, [
    organizationName,
    filters.search,
    filters.groupIds,
    filters.onlyServiceAccounts,
    filters.workspaceIds,
    paginationApi.pagination.currentPage,
    paginationApi.pagination.pageSize,
    debouncedLoad,
  ]);

  useEffect(() => {
    reloadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadUsers]);

  return {
    data,
    communication,
    reloadUsers,
    paginationApi,
    filtersApi: {
      clearFilters: () => {
        setFilters(initialFilters);
      },
      isClearFiltersAvailable: !equals(initialFilters, filters),
      availableData: {
        groups: data?.__typename === 'OrganizationV2' ? data.groups : [],
        workspaces:
          data?.__typename === 'OrganizationV2' ? data.workspaces : [],
      },
      filters,
      setGroupIds,
      setOnlyServiceAccounts,
      setSearch,
      setWorkspaceIds,
    },
  };
};

const initialFilters: Filters = {
  groupIds: [],
  onlyServiceAccounts: false,
  search: '',
  workspaceIds: [],
};

export default useOrganizationUsers;
