import { getModelMetricsByType } from 'shared/models/Monitoring/MonitoringMetricType';
import generateId from 'shared/utils/generateId';

import { MonitoringPanel } from '../../../MonitoringPanel/MonitoringPanel';
import { MonitoringWidget } from '../../../MonitoringPanel/MonitoringWidget/MonitoringWidget';
import { MakeDefaultModelStateProps } from '../../shared/MakeDefaultModelStateProps';
import { makePresetMetricRow } from './makePresetMetricRow';

export const makeModelMetricsPanel = (
  props: MakeDefaultModelStateProps
): MonitoringPanel => {
  const modelMetricTypes = getModelMetricsByType(props.monitoredModelType);
  const modelMetricRows = modelMetricTypes.map((metricType, index) =>
    makePresetMetricRow(metricType, props.defaultOutput, index)
  );

  return {
    id: generateId(),
    name: 'Model metrics',
    type: 'custom',
    isOpen: true,
    layout: modelMetricRows.flatMap((row) => row.layout),
    widgets: modelMetricRows.flatMap(
      (row) => row.widgets as MonitoringWidget[]
    ),
  };
};
