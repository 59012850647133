import { ComponentProps, forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { NavLink } from 'react-router-dom';

import { EXTERNAL_LINK_PROPS } from 'shared/utils/globalConstants/externalLinkProps';
import { OmitStrict } from 'shared/utils/types';

type Props = {
  to: string;
  isExternal?: boolean;
} & OmitStrict<ComponentProps<typeof NavLink>, 'to'>;

const LinkWrapper = forwardRef<HTMLAnchorElement, Props>(
  ({ isExternal, ...props }, ref) => {
    if (isExternal) {
      return <NavLink {...props} {...EXTERNAL_LINK_PROPS} ref={ref} />;
    }

    return <NavLink {...props} />;
  }
);

export default LinkWrapper;
