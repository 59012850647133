import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { pick } from 'lodash';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { isNotRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import PagesTabs from 'shared/view/pages/PagesTabs/PagesTabs';
import { TabsLayout } from 'pages/authorized/shared/TabsLayout';
import { UpdatedAtTagGraphql } from 'pages/authorized/CatalogPages/shared/UpdatedAtTag';
import { useLoadCustomAttributeDefinitions } from 'features/catalog/shared/hooks/useLoadCustomAttributeDefinitions';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import AddNewUserButton from 'features/organizations/users/view/AddNewUserPopup/AddNewUserPopup';
import { trackEvent } from 'setup/app/analytics';
import { selectCurrentUserOrThrowError } from 'features/user';
import { selectCurrentWorkspace } from 'features/workspaces';
import { useJobStatusPolling } from 'features/catalog/shared/hooks/useJobStatusPolling';

import ModelPageSettings from '../../../../../../../features/catalog/registeredModel/settings/view/ModelPageSettings/ModelPageSettings';
import { useModelBreadcrumbs } from '../../../../shared/useModelBreadcrumbs';
import useModelTabs from '../../useModelTabs';
import { useModelLayout } from '../store';

export const Layout = () => {
  const currentUser = useSelector(selectCurrentUserOrThrowError);
  const workspace = useSelector(selectCurrentWorkspace);
  const organizationId = useCurrentOrganizationV2() || '';
  const { registeredModelId } = useRouteParams(routes.registeredModelVersion);
  const { data } = useModelLayout({ modelId: registeredModelId });
  // we load the attrs once and use cache for next interactions
  const _customAttrs = useLoadCustomAttributeDefinitions({ organizationId });

  const model = isNotError(data) ? data : undefined;
  const breadcrumbs = useModelBreadcrumbs({ model: data });

  const tabs = useModelTabs({
    registeredModelId,
    activities: model ? model.activities : [],
  });

  const { startPolling } = useJobStatusPolling({
    organizationId: organizationId,
    workspaceId: workspace.id,
    workspaceName: workspace.name,
  });

  useEffect(() => {
    startPolling();
  }, [startPolling]);

  return (
    <TabsLayout
      breadcrumbs={breadcrumbs}
      pageEntityOrError={undefined}
      tabsElement={
        <PagesTabs
          tabs={tabs}
          rightContent={
            <Stack direction="row">
              <AddNewUserButton
                popupProps={{
                  title: 'Share model',
                  linkLabel: 'Model link',
                  linkDescription:
                    'Share this link with team members with existing Verta accounts.',
                  inputDescription:
                    'Invite new people to create an account with Verta and join your team.',
                }}
                iconButtonProps={{
                  onClick: () =>
                    trackEvent({
                      type: 'catalog.registered_model.share_icon_clicked',
                    }),
                }}
                onSuccess={(emails) => {
                  trackEvent({
                    type: 'catalog.registered_model.shared',
                    data: {
                      inviter: pick(currentUser, [
                        'id',
                        'email',
                        'firstName',
                        'lastName',
                        'jobTitle',
                        'company',
                      ]),
                      invitees: { emails },
                    },
                  });
                }}
              />
              <ModelPageSettings model={model} />
            </Stack>
          }
        />
      }
      actions={[
        <UpdatedAtTagGraphql
          key="updated-at-tag"
          dateUpdated={model ? model.dateUpdated : undefined}
        />,
      ]}
    >
      <Outlet />
    </TabsLayout>
  );
};
