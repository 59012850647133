import { gql } from '@apollo/client';

import {
  useToast,
  useToastCommunicationError,
} from 'features/toast/store/hooks';
import { CustomError } from 'shared/models/Error';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import * as GraphqlTypes from './graphql-types/useTestKafkaConfiguration.generated';

const MUTATION = gql`
  mutation TestKafkaConfiguration($config: KafkaConfigurationInput!) {
    kafkaConfigurations {
      id
      test(config: $config)
    }
  }
`;

export const useTestKafkaConfiguration = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const toast = useToast();
  const toastCommunicationError = useToastCommunicationError();

  const [testKafkaConfiguration, testingKafkaConfiguration] = useCustomMutation<
    GraphqlTypes.TestKafkaConfiguration,
    GraphqlTypes.TestKafkaConfigurationVariables
  >(
    MUTATION,
    { context: 'testing kafka configuration' },
    {
      onCompleted: (res) => {
        if (res.kafkaConfigurations.test) {
          toast('Success', 'success');
          onSuccess();
        } else {
          toastCommunicationError(new CustomError('Failed'), {
            context: 'testing configuration',
          });
        }
      },
    }
  );
  return { testKafkaConfiguration, testingKafkaConfiguration };
};
