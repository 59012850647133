import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { Button } from 'shared/view/elements/Button';
import Onboarding1 from 'features/homepage/images/onboarding1.svg';
import Onboarding2 from 'features/homepage/images/onboarding2.svg';
import Onboarding3 from 'features/homepage/images/onboarding3.svg';
import Onboarding4 from 'features/homepage/images/onboarding4.svg';
import {
  OnboardingProps,
  useOnboardingContext,
} from 'features/homepage/hooks/useOnboarding';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { trackEvent } from 'setup/app/analytics';
import { selectCurrentUserOrThrowError } from 'features/user';
import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';

import { RegisterModelPopup } from '../shared/RegisterModelPopup';
import { CollaborateNowPopup } from '../CollaborateNow/CollaborateNowPopup';
import { ModelCatalogPopup } from '../shared/ModelCatalogPopup';
import { AdvancedFeaturesPopup } from '../AdvancedFeatures/AdvancedFeaturesPopup';

interface BackgroundImageType {
  image: string;
}

type TrackedEvents =
  | 'homepage.model_catalog_tutorial.started'
  | 'homepage.registration_tutorial.started'
  | 'homepage.discover_widget.advanced_features_clicked'
  | 'homepage.discover_widget.collaborate_now_clicked';

type StepProps = {
  id: string;
  title: string;
  image: string;
  description: string;
  linkUrl: string;
  buttonLabel: string;
  isExternal: boolean;
  eventType?: TrackedEvents;
  isOpen?: boolean;
  popupCallback?: () => void;
  popupComponent?: ({
    isPopupOpen,
    closePopup,
    onCompleted,
    currentUser,
    isOpen,
    onClose,
  }: {
    isPopupOpen: boolean;
    isOpen: boolean;
    closePopup: () => void;
    onClose: () => void;
    onCompleted: () => void;
    currentUser: CurrentUserData;
  }) => JSX.Element;
};

const BackgroundImageContainer = styled(Box)<BackgroundImageType>((props) => ({
  backgroundImage: `url(${props.image})`,
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
}));

const StyleGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.contrastText,
  height: '424px',
  width: '100%',
  padding: '32px',
}));

const Step = ({ step }: { step: StepProps }) => {
  const currentUser = useSelector(selectCurrentUserOrThrowError);
  const { updateOnboardingSteps, stepsValues } = useOnboardingContext();
  const { isOpen, onClose, onOpen } = usePopupManager();

  const buttonAction = (
    args: Pick<StepProps, 'linkUrl' | 'isExternal' | 'id' | 'eventType'>
  ) => {
    const { linkUrl, isExternal, id, eventType } = args;
    if (eventType) trackEvent({ type: eventType });

    if (isExternal) {
      updateOnboardingSteps({ ...stepsValues, [id]: true });
      window.open(linkUrl, '_blank', 'noopener');
    } else {
      onOpen();
    }
  };

  return (
    <StyleGrid item xs={3} key={step.id} minWidth="280px">
      <Box height="170px" width="100%">
        <BackgroundImageContainer image={step.image} />
      </Box>
      <Box height="170px" mt={1}>
        <Typography
          variant="subtitle1"
          lineHeight="25px"
          color="text.primary"
          textAlign="center"
          mb={1}
        >
          {step.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" textAlign="center">
          {step.description}
        </Typography>
      </Box>
      <Button size="small" isLoading={false} onClick={() => buttonAction(step)}>
        {step.buttonLabel}
      </Button>
      {step.popupComponent
        ? step.popupComponent({
            onClose,
            isOpen: step.isOpen || isOpen,
            isPopupOpen: step.isOpen || isOpen,
            closePopup: () => onClose(),
            onCompleted: () => {
              step.popupCallback?.();
              onClose();
            },
            currentUser,
          })
        : null}
    </StyleGrid>
  );
};

export const GetStartedSteps = () => {
  const [openStep, setOpenStep] = useState<keyof OnboardingProps | undefined>(
    undefined
  );

  const STEPS: StepProps[] = [
    {
      id: 'step1',
      title: '1. Meet Model Catalog',
      description:
        'Discover how catalog unifies model management, discovery, integration, and governance for your entire team.',
      linkUrl: '',
      popupCallback: () => setOpenStep('step2'),
      popupComponent: ModelCatalogPopup,
      isExternal: false,
      buttonLabel: 'Start exploring',
      image: Onboarding1,
      eventType: 'homepage.model_catalog_tutorial.started',
    },
    {
      id: 'step2',
      title: '2. Register a model',
      linkUrl: '',
      description: `Follow our quick tutorial to get hands-ons and register a sample model.`,
      isExternal: false,
      buttonLabel: 'Start registering',
      image: Onboarding2,
      popupComponent: RegisterModelPopup,
      eventType: 'homepage.registration_tutorial.started',
      isOpen: openStep === 'step2',
      popupCallback: () => setOpenStep(undefined),
    },
    {
      id: 'step3',
      title: '3. Explore advanced features',
      description: `Uncover the platform's full potential through core model management and governance tutorials.`,
      linkUrl: '',
      isExternal: false,
      buttonLabel: 'Start exploring',
      image: Onboarding3,
      popupComponent: AdvancedFeaturesPopup,
      eventType: 'homepage.discover_widget.advanced_features_clicked',
    },
    {
      id: 'step4',
      title: '4. Collaborate now',
      description: `Invite your scientists to co-create your catalog and record everything in one place. Share internally as your system of record for models.`,
      linkUrl: '',
      isExternal: false,
      buttonLabel: 'Start inviting',
      image: Onboarding4,
      eventType: 'homepage.discover_widget.collaborate_now_clicked',
      popupCallback: () => setOpenStep(undefined),
      popupComponent: CollaborateNowPopup,
    },
  ];

  return (
    <Stack spacing={2} direction="row">
      {STEPS.map((step) => (
        <Step key={step.id} step={step} />
      ))}
    </Stack>
  );
};
