import { GridColDef } from '@mui/x-data-grid-pro';

import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import isNotNil from 'shared/utils/isNotNill';
import NA from 'shared/view/elements/PageComponents/NA/NA';
import { formatDate } from 'shared/utils/formatters/dateTime';
import { PivotValueGetterParams } from 'shared/view/elements/DataGrid/configuration/pivotValueGetter/PivotValueGetterParams';

export function parseAdditionalConfigurationProps<Data>(
  column: DataGridColumn<Data>
): GridColDef {
  function groupingValueGetter(params: PivotValueGetterParams<Data>) {
    if (column.additionalConfiguration?.valueGetter) {
      const value = column.additionalConfiguration.valueGetter(params);
      if (isNotNil(value)) {
        if (value instanceof Date) {
          return formatDate(value);
        }
        return value;
      }
    }
    return NA;
  }

  return {
    ...column,
    cellClassName: column.cellClassName,
    sortable: Boolean(
      column.additionalConfiguration?.configurationTypes.includes('sort')
    ),
    filterable: Boolean(
      column.additionalConfiguration?.configurationTypes.includes('filter')
    ),
    // todo fix! Find a reason, why it is not exist in library's d.ts, but works
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    groupingValueGetter: groupingValueGetter as any,
    ...column.additionalConfiguration,
  };
}
