import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import {
  DELETE_CUSTOM_ATTRIBUTE_QUERY,
  DeleteCustomAttribute,
  DeleteCustomAttributeVariables,
} from '../graphql';

type Props = {
  onCompleted: () => void;
};

export const useDeleteCustomAttribute = ({ onCompleted }: Props) => {
  const [deleteCustomAttribute, communication] = useCustomMutation<
    DeleteCustomAttribute,
    DeleteCustomAttributeVariables
  >(
    DELETE_CUSTOM_ATTRIBUTE_QUERY,
    {
      context: 'delete custom attribute',
    },
    {
      onCompleted,
    }
  );

  return {
    deleteCustomAttribute,
    communication,
  };
};
