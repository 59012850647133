import { FC } from 'react';
import { useField } from 'formik';

import CheckboxField from 'shared/view/formComponents/formikFields/CheckboxField/CheckboxField';
import { IAutoscalingMetric } from 'features/deployment/canary/endpoints/store/endpointQuery/endpointQuery';
import FormStack from 'shared/view/elements/FormStack/FormStack';

import AutoscalingMetrics from './AutoscalingMetrics/AutoscalingMetrics';
import { getMachineConfigSettingsFieldName } from '../settings/getMachineConfigSettingsFieldName';
import { QuantitiesFields } from './AutoscalingMetrics/QuantitiesFields';

interface Props {
  canonicalAutoscalingMetrics: IAutoscalingMetric[];
}

const AutoscalingSection: FC<React.PropsWithChildren<Props>> = ({
  canonicalAutoscalingMetrics,
}) => {
  const [{ value: autoscalingEnabled }] = useField<boolean>(
    getMachineConfigSettingsFieldName({ autoscalingEnabled: null })
  );
  const disabled = !autoscalingEnabled;

  return (
    <FormStack>
      <CheckboxField
        name={getMachineConfigSettingsFieldName({
          autoscalingEnabled: null,
        })}
        dataTest="autoscaling-enable"
        label="Enable"
      />
      <QuantitiesFields disabled={disabled} />

      <AutoscalingMetrics
        canonicalAutoscalingMetrics={canonicalAutoscalingMetrics}
        disabled={disabled}
      />
    </FormStack>
  );
};

export default AutoscalingSection;
