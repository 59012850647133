import {
  SnackbarKey,
  SnackbarProvider,
  CustomContentProps,
  SnackbarContent,
  useSnackbar,
} from 'notistack';
import { ReactElement, useRef, forwardRef, isValidElement } from 'react';
import { styled, AlertColor, Box } from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useSelector } from 'react-redux';

import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import Alert from 'shared/view/elements/Alert/Alert';
import { selectFlags } from 'features/flags';

import ToastNotifier from '../ToastNotifier/ToastNotifier';

interface Props {
  children: ReactElement;
}

const StyledSnackbarProvider = styled(Box)(({ theme }) => ({
  '&.SnackbarContent-root': {
    flexGrow: 'unset',
    flexWrap: 'unset',
    alignItems: 'flex-start',
  },
  '.SnackbarItem-message': {
    alignItems: 'flex-start',
    gap: 8,
  },
  '.SnackbarItem-action': {
    padding: 8,
  },
  '&.SnackbarItem-variantSuccess': {
    backgroundColor: theme.palette.success.main,
  },
  '&.SnackbarItem-variantError': {
    backgroundColor: theme.palette.error.main,
  },
  '&.SnackbarItem-variantInfo': {
    backgroundColor: theme.palette.info.main,
  },
  '&.SnackbarItem-variantWarning': {
    backgroundColor: theme.palette.warning.main,
  },
}));

const ToastContainer = (props: Props) => {
  const { deployment, isEnableFreeTier } = useSelector(selectFlags);
  const notistackRef = useRef<SnackbarProvider>(null);

  const dismiss = (key: SnackbarKey) => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <StyledSnackbarProvider
      sx={{
        '& .notistack-SnackbarContainer': {
          marginTop:
            deployment.isEnable && isEnableFreeTier ? undefined : '135px',
        },
      }}
    >
      <SnackbarProvider
        maxSnack={6}
        ref={notistackRef}
        Components={{
          error: AlertWrapper,
          success: AlertWrapper,
          warning: AlertWrapper,
          info: AlertWrapper,
        }}
        anchorOrigin={{
          horizontal:
            deployment.isEnable && isEnableFreeTier ? 'center' : 'right',
          vertical: 'top',
        }}
        action={(key) => (
          <IconAwesomeClickable
            icon={ICONS.xMark}
            onClick={() => dismiss(key)}
            reversedColors
            size="lg"
          />
        )}
        iconVariant={{
          default: <Icon icon={null} />,
          info: <Icon icon={null} />,
          success: <Icon icon={ICONS.circleCheck} />,
          warning: <Icon icon={ICONS.triangleExclamation} />,
          error: <Icon icon={ICONS.triangleExclamation} />,
        }}
      >
        <ToastNotifier />
        {props.children}
      </SnackbarProvider>
    </StyledSnackbarProvider>
  );
};

function Icon({ icon }: { icon: IconDefinition | null }) {
  return icon ? (
    <span style={{ paddingTop: 2 }}>
      <IconAwesomeInfo icon={icon} size={'lg'} reversedColors={true} />
    </span>
  ) : (
    <></>
  );
}

interface ReportCompleteProps extends Omit<CustomContentProps, 'message'> {
  message:
    | string
    | { props?: { onClose?: () => undefined; errorStr?: string } };
}

const AlertWrapper = forwardRef<HTMLDivElement, ReportCompleteProps>(
  (props, ref) => {
    const {
      id,
      message,
      variant,
      // notistack props
      action: _action,
      iconVariant: _iconVariant,
      hideIconVariant: _hideIconVariant,
      autoHideDuration: _autoHideDuration,
      anchorOrigin: _anchorOrigin,
      persist: _persist,
      style: _style,
      className: _className,
      ...other
    } = props;
    const isMessageString = typeof message === 'string';

    const { closeSnackbar } = useSnackbar();

    return (
      <SnackbarContent ref={ref}>
        <Alert
          severity={variant as AlertColor}
          onClose={() => {
            if (!isMessageString && message.props?.onClose) {
              message.props.onClose();
            }

            closeSnackbar(id);
          }}
          sx={{ width: '100%' }}
          {...other}
        >
          {isMessageString || isValidElement(message)
            ? message
            : message.props?.errorStr}
        </Alert>
      </SnackbarContent>
    );
  }
);

export default ToastContainer;
