import { useMemo } from 'react';

import { findAlertIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { MonitoringModelAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import { MonitoringWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidget';
import matchBy from 'shared/utils/matchBy';

interface Props {
  widget: MonitoringWidget;
  alerts: MonitoringModelAlert[];
  ioDescriptions: MonitoringIODescription[];
}

export const useAssociatedAlert = (props: Props) => {
  return useMemo(
    () =>
      findAssociatedAlert({
        widget: props.widget,
        alerts: props.alerts,
        ioDescriptions: props.ioDescriptions,
      }),
    [props.widget, props.alerts, props.ioDescriptions]
  );
};

const findAssociatedAlert = (
  props: Props
): MonitoringModelAlert | undefined => {
  return matchBy(
    props.widget,
    'type'
  )({
    barChart: (widget) =>
      findAssociatedDriftAlert({
        widget,
        alerts: props.alerts,
        ioDescriptions: props.ioDescriptions,
      }),
    histograms: (widget) =>
      findAssociatedDriftAlert({
        widget,
        alerts: props.alerts,
        ioDescriptions: props.ioDescriptions,
      }),
    timeSeries: (widget) =>
      matchBy(
        widget.variant,
        'type'
      )({
        drift: (w) =>
          findAssociatedDriftAlert({
            widget: w,
            alerts: props.alerts,
            ioDescriptions: props.ioDescriptions,
          }),
        metric: (w) =>
          props.alerts.find(
            (a) =>
              a.settings.type === 'metric' &&
              a.settings.metricType === w.metricType
          ),
      }),
    confusionMatrix: () => undefined,
    scatterPlot: () => undefined,
    singleMetric: () => undefined,
    table: () => undefined,
    curve: () => undefined,
    notInitialized: () => undefined,
  });
};

const findAssociatedDriftAlert = (props: {
  alerts: MonitoringModelAlert[];
  widget: { ioDescription: MonitoringIODescription };
  ioDescriptions: MonitoringIODescription[];
}): MonitoringModelAlert | undefined =>
  props.alerts.find((alert) => {
    const ioDescription = findAlertIODescription({
      alert,
      ioDescriptions: props.ioDescriptions,
    });
    return (
      ioDescription?.name === props.widget.ioDescription.name &&
      ioDescription.ioType === props.widget.ioDescription.ioType
    );
  });
