import { HttpError } from 'shared/models/Error';
import {
  CustomApiErrorConverter,
  GetErrorMessage,
  IHttpMethodRequestConfig,
} from 'services/HttpActions';

// todo find the better name
export type DeploymentRequestErrorType = 'expectedDeployApiError';
export type DeploymentRequestError = HttpError<DeploymentRequestErrorType>;

export const errorConverter: Record<
  DeploymentRequestErrorType,
  CustomApiErrorConverter
> = {
  expectedDeployApiError: (error) =>
    Boolean(error.error.response?.data && !error.error.code),
};

export const getErrorMessage: GetErrorMessage = (error) => {
  if (error.response?.data) {
    if (typeof error.response.data === 'string') {
      return error.response.data;
    }
    return error.message;
  }
};

export const config = { responseType: 'text' as const };

export const addHandlingDeploymentErrorToRequestConfig = (
  requestConfig: IHttpMethodRequestConfig<string>
): IHttpMethodRequestConfig<DeploymentRequestErrorType> => {
  if (requestConfig.getErrorMessage) {
    console.warn('getErrorMessage is overriden');
  }
  return {
    ...requestConfig,
    config: { ...requestConfig, ...config },
    errorConverters: { ...requestConfig.errorConverters, ...errorConverter },
    getErrorMessage,
  };
};
