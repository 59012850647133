import { SnackbarKey, useSnackbar, VariantType } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppError } from 'shared/models/Error';

import {
  removeToastAction,
  toastCommunicationErrorActionCreator,
} from './actions';
import { selectToasts } from './selectors';

let displayed: SnackbarKey[] = [];

const storeDisplayed = (id: SnackbarKey) => {
  displayed = [...displayed, id];
};

const removeDisplayed = (id: SnackbarKey) => {
  displayed = [...displayed.filter((key) => id !== key)];
};

export const useToastNotifier = () => {
  const toasts = useSelector(selectToasts);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    toasts.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
        dispatch(removeToastAction(key));
        return;
      }

      if (displayed.includes(key)) return;

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (_, myKey) => {
          dispatch(removeToastAction(myKey));
          removeDisplayed(myKey);
        },
      });

      storeDisplayed(key);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeSnackbar, dispatch, toasts]);
};

export const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    (message: string | React.ReactNode, variant: VariantType, opts?: any) => {
      enqueueSnackbar(message, { variant, preventDuplicate: true, ...opts });
    },
    [enqueueSnackbar]
  );
};

export const useToastCommunicationError = () => {
  const dispatch = useDispatch();

  return useCallback(
    <T extends AppError, B extends string>(
      communicationError: T,
      options: {
        toastId?: string;
        context: string;
        customErrorMessageByType?: T extends AppError<infer ErrorType>
          ? ErrorType extends B
            ? Record<B, string>
            : never
          : never;
      }
    ) =>
      dispatch(
        toastCommunicationErrorActionCreator(communicationError, options)
      ),
    [dispatch]
  );
};
