import { Box } from '@mui/material';
import { useState } from 'react';

import { makeEntitiesPrimitivesDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/EntitiesPrimitivesDiffView';
import { makeEntitiesLabelsDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/EntityLabelsDiffView';
import CompareTable from 'shared/view/domain/CompareEntities/New/CompareTable/CompareTable';
import routes from 'shared/routes';
import { formatDateWithTime } from 'shared/utils/formatters/dateTime';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import * as PropertyDiffDescriptions from 'shared/view/domain/CompareEntities/New/CompareTable/PropertyDiffDescriptions';
import { makeAttributesDiff } from 'shared/view/domain/CompareEntities/New/BaseEntityPropertyDiff/AttributesDiff/AttributesDiff';
import ToggleButtons from 'shared/view/domain/CompareEntities/New/CompareTable/ToggleButtons/ToggleButtons';

import { compareEvaluations } from '../model/evaluationsComparing';
import { ComparedEvaluations } from '../model/comparedEvaluations';
import { LabelDistributionChart } from './LabelDistributionChart';
import { ComparedEvaluation } from '../hooks/useEvaluationsForComparing';

interface Props {
  evaluations: ComparedEvaluations;
}
type ViewMode = 'summary' | 'rows';

export const EvaluationsCompareTable = ({ evaluations }: Props) => {
  const [viewMode, setViewMode] = useState<ViewMode>('summary');

  return (
    <CompareTable
      columnEntityName="Evaluation"
      entities={evaluations}
      compareEntities={compareEvaluations}
      getRemovedEntityName={(entity) => entity.version}
      getPropertiesDiffDescriptions={(evaluationsDiff) =>
        getPropertiesDiffDescriptions(
          evaluationsDiff,
          viewMode,
          evaluations.length
        )
      }
      slots={{
        RightMenu: (
          <ToggleButtons
            buttons={[
              {
                text: 'Summary view',
                isSelected: viewMode === 'summary',
                dataTest: 'summary-view',
                onClick: () => setViewMode('summary'),
              },
              {
                text: 'Row level view',
                isSelected: viewMode === 'rows',
                dataTest: 'rows-view',
                onClick: () => setViewMode('rows'),
              },
            ]}
          />
        ),
      }}
    />
  );
};

const getPropertiesDiffDescriptions = (
  evaluationsDiff: ReturnType<typeof compareEvaluations>,
  viewMode: ViewMode,
  compareEntitiesCount: number
) => {
  const isSummaryView = viewMode === 'summary';

  const rows = [
    makeEntitiesPrimitivesDiff({
      name: 'Name',
      diff: evaluationsDiff.version,
      render: (version, { id, registeredModel }) => (
        <InlineLink
          to={routes.evaluationSummary.getRedirectPath({
            evaluationId: id,
            workspaceName: registeredModel.workspace.name,
          })}
        >
          {version}
        </InlineLink>
      ),
    }),
    isSummaryView
      ? makeEntitiesPrimitivesDiff({
          name: 'Description',
          diff: evaluationsDiff.description,
          render: (d) => d,
        })
      : null,

    isSummaryView
      ? makeEntitiesPrimitivesDiff({
          name: 'Project',
          diff: evaluationsDiff.project,
          render: (_, { registeredModel }) => registeredModel.name,
          // <InlineLink
          //   to={routes.projectOverview.getRedirectPath({
          //     projectId: registeredModel.id,
          //     workspaceName: registeredModel.workspace.name,
          //   })}
          // >
          //   {registeredModel.name}
          // </InlineLink>
        })
      : null,

    makeEntitiesPrimitivesDiff({
      name: 'Model/Pipeline identifier',
      diff: evaluationsDiff.modelId,
      render: (d) => d,
    }),

    makeEntitiesPrimitivesDiff({
      name: 'Configuration',
      diff: evaluationsDiff.configuration,
      render: (d) => d,
    }),

    isSummaryView
      ? makeEntitiesPrimitivesDiff({
          name: 'Updated at',
          diff: evaluationsDiff.dateUpdated,
          render: (x) => formatDateWithTime(new Date(x)),
        })
      : null,

    isSummaryView
      ? makeEntitiesLabelsDiff({
          name: 'Labels',
          diff: evaluationsDiff.labels,
        })
      : null,

    isSummaryView
      ? makeEntitiesPrimitivesDiff({
          name: 'Overall comment',
          diff: evaluationsDiff.overallComment,
          render: (x) => x,
        })
      : null,
    isSummaryView
      ? makeEntitiesPrimitivesDiff({
          name: 'Approved',
          diff: evaluationsDiff.approvedRows,
          render: (x) => x,
        })
      : null,
    isSummaryView
      ? makeEntitiesPrimitivesDiff({
          name: 'Rejected',
          diff: evaluationsDiff.rejectedRows,
          render: (x) => x,
        })
      : null,
    isSummaryView
      ? makeEntitiesPrimitivesDiff({
          name: 'Total',
          diff: evaluationsDiff.rows,
          render: (x) => x,
        })
      : null,
    isSummaryView
      ? makeEntitiesPrimitivesDiff({
          name: 'Labels distribution',
          diff: evaluationsDiff.labelsDistribution,
          render: (x) => (
            <Box>
              <LabelDistributionChart
                labelsDistribution={
                  JSON.parse(x) as ComparedEvaluation['labelsDistribution']
                }
              />
            </Box>
          ),
        })
      : null,

    isSummaryView
      ? null
      : {
          ...makeAttributesDiff(evaluationsDiff.attributes),
          name: 'Inputs',
          highlight: true,
          colName: 'Output',
          compareEntitiesCount,
        },
  ];
  return PropertyDiffDescriptions.makePropertyDiffDescriptions(rows);
};
