import { Box, Divider, Grid, Typography, Stack } from '@mui/material';
import { isEmpty, isNil } from 'ramda';

import Accordion from 'shared/view/elements/Accordion/Accordion';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import DeleteIconButton from 'shared/view/elements/DeleteIconButton/DeleteIconButton';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import matchType from 'shared/utils/matchType';
import pluralize from 'shared/utils/pluralize';
import { useDeleteChecklistTemplate } from 'features/organizations/governance/store/useDeleteChecklistTemplate';
import { OrganizationGovernance } from 'features/organizations/governance/store/useOrganizationGovernance';
import { useLoadCustomAttributeDefinitions } from 'features/catalog/shared/hooks/useLoadCustomAttributeDefinitions';
import { trackEvent } from 'setup/app/analytics';
import { handleChecklistConstrain } from 'features/shared/handleChecklistConstrain';

import { ChecklistTemplatePopup } from './ChecklistTemplatePopup/ChecklistTemplatePopup';

type Props = {
  reload: () => void;
  checklistTemplate: NonNullable<
    OrganizationGovernance['checklistTemplates']
  >[0];
  groups: OrganizationGovernance['groups'];
};

type ChecklistTemplateItemProps = {
  checklistTemplateItem: NonNullable<
    NonNullable<
      OrganizationGovernance['checklistTemplates']
    >[0]['checklistTemplateItems']
  >[0];
  groups: OrganizationGovernance['groups'];
};

const ChecklistTemplateItem = ({
  checklistTemplateItem,
  groups,
}: ChecklistTemplateItemProps) => {
  const { name, itemType, description, userGroupId, required } =
    checklistTemplateItem;

  const parsedItemType = matchType(
    {
      CHECKBOX: () => 'Checkbox',
      SYSTEM: () => 'System check',
      WORKFLOW: () => 'Workflow',
      UNKNOWN: () => 'Unknown',
    },
    itemType
  );

  return (
    <Box pb={2}>
      <Stack spacing={2}>
        <Stack direction="row" spacing={1}>
          <IconAwesomeInfo icon={ICONS.circleCheck} size="xl" />
          <Box display="flex" justifyContent="space-between" width="100%">
            <Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography variant="body2" color="text.primary">
                  {name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  | {parsedItemType}
                </Typography>
              </Stack>
              {description ? (
                <Typography variant="body2" color="text.secondary">
                  {description}
                </Typography>
              ) : null}

              {groups.map((group) =>
                group.id === userGroupId ? (
                  <Typography
                    key={group.id}
                    variant="caption"
                    color="text.secondary"
                  >
                    Restricted to: {group.name}
                  </Typography>
                ) : null
              )}
            </Stack>

            {required ? (
              <Typography mt="8px" variant="caption" color="text.secondary">
                Required
              </Typography>
            ) : null}
          </Box>
        </Stack>

        <Divider />
      </Stack>
    </Box>
  );
};

const Actions = ({
  item,
  groups,
  reload,
}: {
  item: Props['checklistTemplate'];
  groups: Props['groups'];
  reload: () => void;
}) => {
  const numberItems =
    isEmpty(item.checklistTemplateItems) || isNil(item.checklistTemplateItems)
      ? 0
      : item.checklistTemplateItems.length;

  const { deleteChecklistTemplate, communication } = useDeleteChecklistTemplate(
    {
      onCompleted: reload,
    }
  );

  return (
    <Stack direction="row" alignItems="center" pr={2} spacing={2}>
      {numberItems > 0 ? (
        <Typography variant="body2" color="text.primary" pr={4}>
          {pluralize('item', numberItems, true)}
        </Typography>
      ) : null}
      <ButtonWithPopup
        button={(props) => (
          <IconAwesomeClickable
            {...props}
            onClick={() => {
              trackEvent({ type: 'organization.checklists.edit_clicked' });
              props.onClick();
            }}
            size="lg"
            dataTest="edit-checklist-template"
            icon={ICONS.pencil}
          />
        )}
        popup={(props) => (
          <ChecklistTemplatePopup
            {...props}
            checklistTemplate={item}
            submitButton={{
              children: 'Save changes',
            }}
            onCompleted={reload}
            title="Edit checklist"
            organizationId={item.organizationId}
            groups={groups}
          />
        )}
      />

      <DeleteIconButton
        description={`checklist cemplate "${item.name}"`}
        entityName="checklist template"
        disabled={communication.isRequesting}
        onDelete={() => {
          trackEvent({ type: 'organization.checklists.delete_clicked' });
          deleteChecklistTemplate({
            organizationId: item.organizationId,
            checklistTemplateId: item.id,
          });
        }}
      />
    </Stack>
  );
};

export const ChecklistTemplate = ({
  checklistTemplate,
  reload,
  groups,
}: Props) => {
  const { id, name, checklistTemplateItems, organizationId } =
    checklistTemplate;
  const { data: definitions } = useLoadCustomAttributeDefinitions(
    { organizationId },
    { fetchPolicy: 'cache-first' }
  );

  const subTitle = handleChecklistConstrain(checklistTemplate, definitions);

  return (
    <Accordion
      dataTest="checklist-template"
      key={`checklist-template-header-${id}`}
      title={name}
      subtitle={subTitle}
      summaryRightContent={
        <Actions groups={groups} item={checklistTemplate} reload={reload} />
      }
    >
      <Grid container>
        <Grid item xs={7} pt={2}>
          {checklistTemplateItems?.map((checklistTemplateItem) => (
            <ChecklistTemplateItem
              key={`checklist-template-items-${checklistTemplateItem.id}`}
              checklistTemplateItem={checklistTemplateItem}
              groups={groups}
            />
          ))}
        </Grid>
      </Grid>
    </Accordion>
  );
};
