import { CSSProperties } from 'react';

import { PrismComponent } from './Prims';

type Language =
  | 'bash'
  | 'C'
  | 'clike'
  | 'cpp'
  | 'css'
  | 'java'
  | 'javascript'
  | 'markdown'
  | 'objc'
  | 'python'
  | 'rust'
  | 'sql'
  | 'swift'
  | 'typescript'
  | 'xml'
  | 'plaintext';

export type SyntaxHighlighterProps = {
  language: Language;
  children: string;
  style?: CSSProperties;
  additionalClassnames?: string;
};

export const SyntaxHighlighter = (props: SyntaxHighlighterProps) => {
  return <PrismComponent {...props} />;
};
