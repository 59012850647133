import { FC } from 'react';

import InlineCommunicationError from 'shared/view/elements/Errors/InlineCommunicationError/InlineCommunicationError';
import BaseErrorBoundary from 'shared/utils/react/BaseErrorBoundary';

const renderError = (error: Error) => (
  <InlineCommunicationError error={error} context="running application" />
);

const ErrorBoundary: FC<React.PropsWithChildren<unknown>> = (props) => {
  return (
    <BaseErrorBoundary renderError={renderError}>
      {props.children}
    </BaseErrorBoundary>
  );
};

export default ErrorBoundary;
