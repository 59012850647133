import { ReactNode } from 'react';
import { BoxProps } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MuiDialog, { DialogProps } from '@mui/material/Dialog';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import DialogActions from '@mui/material/DialogActions';

import PopupTitle, {
  PopupTitleProps,
} from 'shared/view/elements/Popup/PopupTitle';
import PopupButtons, {
  ButtonsProps,
} from 'shared/view/elements/Popup/PopupButtons';

export interface PopupProps
  extends PopupTitleProps,
    Pick<DialogProps, 'maxWidth' | 'fullWidth' | 'scroll'> {
  isOpen: boolean;
  children: ReactNode;
  fullScreen?: boolean;
  buttons?: ButtonsProps;
  headerAdornment?: ReactNode;
  dataTest?: string;
  dialogContentProps?: DialogContentProps & Pick<BoxProps, 'height'>;
}

export default function Popup(props: PopupProps) {
  const {
    title,
    isOpen,
    children,
    onClose,
    fullScreen,
    buttons,
    maxWidth,
    fullWidth,
    dataTest,
    headerAdornment,
    dialogContentProps,
    scroll = 'paper',
  } = props;

  return (
    <MuiDialog
      open={isOpen}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      data-test={dataTest}
      scroll={scroll}
    >
      <PopupTitle
        onClose={onClose}
        title={title}
        headerAdornment={headerAdornment}
      />
      <DialogContent
        {...dialogContentProps}
        sx={{
          maxHeight: 'calc(100vh - 144px)',
          height: dialogContentProps?.height,
          ...dialogContentProps?.sx,
        }}
      >
        {children}
      </DialogContent>
      {buttons ? (
        <DialogActions>
          <PopupButtons {...buttons} />
        </DialogActions>
      ) : null}
    </MuiDialog>
  );
}
