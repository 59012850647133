import { FieldConfig, Field, FieldProps } from 'formik';

import { OmitStrict } from 'shared/utils/types';
import Checkbox, {
  ICheckboxProps,
} from 'shared/view/elements/Checkbox/Checkbox';
import FormHelperText from 'shared/view/elements/FormHelperText/FormHelperText';

export default function CheckboxField(
  props: { withoutError?: boolean } & FieldConfig &
    OmitStrict<ICheckboxProps, 'value' | 'name' | 'onChange'>
) {
  return (
    <Field {...props}>
      {({ field, form }: FieldProps<boolean>) => (
        <div>
          <Checkbox
            {...props}
            value={field.value}
            onChange={(value) => {
              form.setFieldValue(props.name, value);
            }}
          />
          {props.withoutError ? null : <FormHelperText errorMessage="" />}
        </div>
      )}
    </Field>
  );
}
