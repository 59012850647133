import { useMemo } from 'react';

import { getPaginationProps } from 'shared/view/elements/Pagination/Pagination';
import { usePagination } from 'shared/view/hooks/usePagination';

type Props<T> = {
  values: T[] | undefined | null;
  totalCount: number;
  pageSize?: number;
  additionalActionOnPageChange?: (currentPage: number) => void;
};
export const useVirtualPagination = <T>(props: Props<T>) => {
  const { values, ...other } = props;

  const pagination = usePagination(other);
  const paginationProps = getPaginationProps(pagination);

  const paginatedValues = useMemo(() => {
    const { page, pageSize } = paginationProps;
    const start = page * pageSize;
    const end = (page + 1) * pageSize;

    return values ? values.slice(start, end) : ([] as T[]);
  }, [values, paginationProps]);

  return { paginatedValues, paginationProps, pagination };
};
