import React, { useMemo } from 'react';

import NoDataSVG from './img/no-data.svg?react';
import styles from './WhenNotNil.module.css';

interface Props {
  children: React.ReactNode;
  isNil: boolean;
  width: number;
  height: number;
  nilText?: string;
}

const calcSvgSize = ({ width, height }: { width: number; height: number }) => {
  const min = Math.min(width, height);
  const minWidth = min > 0 ? min : 0;
  const ratio = 3 / 4;
  return minWidth * ratio;
};

const WhenNotNil: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const svgSize = useMemo(
    () => calcSvgSize({ width: props.width, height: props.height }),
    [props.width, props.height]
  );

  return props.isNil ? (
    <div className={styles.root}>
      <span className={styles.nilText}>{props.nilText ?? 'No data'}</span>
      <NoDataSVG width={svgSize} height={svgSize} />
    </div>
  ) : (
    <>{props.children}</>
  );
};

export default WhenNotNil;
