import axios, { AxiosError, AxiosPromise, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { head, toPairs } from 'ramda';

import { createCodeErrorFromError, HttpError } from 'shared/models/Error';
import { commonAPIErrorMessages } from 'shared/utils/customErrorMessages';
import {
  retryCondition,
  maxRetries,
  getRetryDelay,
} from 'shared/utils/api/retrying';

import { currentOrganizationStorage } from './utils/currentOrganizationStorage';

export const baseUrl = `${window.location.origin}/api`;

axiosRetry(axios, {
  retryCondition: (error) =>
    retryCondition({
      errorStatus: error.response?.status,
      errorCode: error.code,
    }),
  shouldResetTimeout: true,
  retries: maxRetries,
  retryDelay: (retryNumber) => getRetryDelay({ retryNumber }),
});

// we have to use absolute baseURL when we use axios-retry. https://github.com/JustinBeckwith/retry-axios/issues/4
axios.defaults.baseURL = baseUrl;
axios.defaults.responseType = 'json';
axios.defaults.validateStatus = (status) =>
  (status >= 200 && status < 300) || status === 302;
axios.defaults.timeout = 30000;

export class HttpActions {
  static get<T = any, ErrorType extends string = string>(
    opt: IHttpMethodRequestConfig<ErrorType>
  ): AxiosPromise<T> {
    const organizationId = currentOrganizationStorage.read();
    if (organizationId)
      axios.defaults.headers.common['Grpc-Metadata-organization-id'] =
        organizationId;

    return axios.get(opt.url, opt.config).catch((error) => {
      if (isAxiosError(error) && opt.errorConverters) {
        throw handleCustomApiErrorWithFallback(
          error,
          opt.errorConverters,
          opt.getErrorMessage
        );
      }
      throw handleApiError(error, opt.getErrorMessage);
    }) as AxiosPromise<T>;
  }

  static post<T = any, ErrorType extends string = string>(
    opt: IHttpMethodRequestConfig<ErrorType>
  ): AxiosPromise<T> {
    const organizationId = currentOrganizationStorage.read();
    if (organizationId)
      axios.defaults.headers.common['Grpc-Metadata-organization-id'] =
        organizationId;

    return axios.post(opt.url, opt.data, opt.config).catch((error) => {
      if (isAxiosError(error) && opt.errorConverters) {
        throw handleCustomApiErrorWithFallback(
          error,
          opt.errorConverters,
          opt.getErrorMessage
        );
      }
      throw handleApiError(error, opt.getErrorMessage);
    }) as AxiosPromise<T>;
  }

  static put<T = any, ErrorType extends string = string>(
    opt: IHttpMethodRequestConfig<ErrorType>
  ): AxiosPromise<T> {
    const organizationId = currentOrganizationStorage.read();
    if (organizationId)
      axios.defaults.headers.common['Grpc-Metadata-organization-id'] =
        organizationId;

    return axios.put(opt.url, opt.data, opt.config).catch((error) => {
      if (isAxiosError(error) && opt.errorConverters) {
        throw handleCustomApiErrorWithFallback(
          error,
          opt.errorConverters,
          opt.getErrorMessage
        );
      }
      throw handleApiError(error, opt.getErrorMessage);
    }) as AxiosPromise<T>;
  }

  static patch<T = any, ErrorType extends string = string>(
    opt: IHttpMethodRequestConfig<ErrorType>
  ): AxiosPromise<T> {
    const organizationId = currentOrganizationStorage.read();
    if (organizationId)
      axios.defaults.headers.common['Grpc-Metadata-organization-id'] =
        organizationId;
    return axios.patch(opt.url, opt.data, opt.config).catch((error) => {
      if (isAxiosError(error) && opt.errorConverters) {
        throw handleCustomApiErrorWithFallback(
          error,
          opt.errorConverters,
          opt.getErrorMessage
        );
      }
      throw handleApiError(error, opt.getErrorMessage);
    }) as AxiosPromise<T>;
  }

  static delete<T = any, ErrorType extends string = string>(
    opt: IHttpMethodRequestConfig<ErrorType>
  ): AxiosPromise<T> {
    const organizationId = currentOrganizationStorage.read();
    if (organizationId)
      axios.defaults.headers.common['Grpc-Metadata-organization-id'] =
        organizationId;

    return axios.delete(opt.url, opt.config).catch((error) => {
      if (isAxiosError(error) && opt.errorConverters) {
        throw handleCustomApiErrorWithFallback(
          error,
          opt.errorConverters,
          opt.getErrorMessage
        );
      }
      throw handleApiError(error, opt.getErrorMessage);
    }) as AxiosPromise<T>;
  }
}

function handleCustomApiErrorWithFallback<T extends string>(
  error: Error | AxiosError,
  errorConverters: CustomApiErrorConverters<T>,
  getErrorMessage?: GetErrorMessage
) {
  if (isAxiosError(error)) {
    const appropriateCustomErrorPair = head(
      toPairs<CustomApiErrorConverter>(errorConverters).filter(([_, pred]) =>
        pred({
          error,
          status: error.response.status,
          serverErrorResponse: error.response.data as IApiErrorData | undefined,
        })
      )
    );

    if (appropriateCustomErrorPair) {
      const errorType = appropriateCustomErrorPair[0];
      throw new HttpError({
        message: getErrorMessage
          ? getErrorMessage(error)
          : (commonAPIErrorMessages as Record<string, string>)[errorType] ||
            errorType,
        status: error.response.status,
        type: errorType,
      });
    }
  }
  handleApiError(error, getErrorMessage);
}

function handleApiError(
  error: Error | AxiosError,
  getErrorMessage?: GetErrorMessage
) {
  if (isAxiosError(error)) {
    const backendMessage = getServerErrorMessage(error);
    throw new HttpError({
      message: getErrorMessage ? getErrorMessage(error) : backendMessage,
      status: error.response.status,
      type: undefined,
      isMessageUserFriendly: false,
    });
  } else {
    throw createCodeErrorFromError(error);
  }
}

export interface IHttpMethodRequestConfig<ErrorType extends string> {
  url: string;
  data?: unknown;
  config?: AxiosRequestConfig;
  errorConverters?: CustomApiErrorConverters<ErrorType>;
  getErrorMessage?: GetErrorMessage;
}

type CustomApiErrorConverters<T extends string> = Record<
  T,
  CustomApiErrorConverter
>;
export type CustomApiErrorConverter = (props: {
  error: AxiosError;
  status: number;
  serverErrorResponse?: IApiErrorData;
}) => boolean;
export type GetErrorMessage = (error: AxiosError) => string | undefined;

interface IApiErrorData {
  code: number;
  error: string;
  message: string;
}

function isAxiosError(
  arg: Error
): arg is AxiosError & { response: { status: number; data: unknown } } {
  return 'response' in arg;
}

function getServerErrorMessage(error: Error | AxiosError): string | undefined {
  if (isAxiosError(error)) {
    return (
      // todo probably legacy code and can produce malfunctions. Need to be checked and fixed;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      error.response?.data
        ? (error.response.data as IApiErrorData).message
        : undefined
    );
  }
}
