import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material';

import { AlertStatus } from 'generated/types';
import { AlertSettingsType } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertSettings';
import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import routes from 'shared/routes';
import capitalize from 'shared/utils/capitalize';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { useHover } from 'shared/utils/react/useHover';

import { OverviewWidgetWrapperHoverable } from '../shared/OverviewWidget/OverviewWidget';

interface Props {
  model: MonitoringModel;
}

const MonitoringOverviewAlertsWidget = (props: Props) => {
  const { handlers, isHovered } = useHover();

  return (
    <OverviewWidgetWrapperHoverable minHeight="450px" {...handlers}>
      <Box
        style={{
          width: '100%',
          flexGrow: '2',
          padding: '24px 24px 16px 24px',
        }}
      >
        <Stack spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center">
              <div>
                <IconAwesomeInfo
                  icon={ICONS.sensorTriangleExclamation}
                  size="lg"
                />
              </div>
              <Typography variant="subtitle1">Alerts</Typography>
            </Stack>

            <div style={{ opacity: isHovered ? 1 : 0 }}>
              <InlineLink
                to={routes.monitoringAlerts.getRedirectPath({
                  monitoringModelId: props.model.id,
                  workspaceName: props.model.workspaceName,
                })}
              >
                Go to alerts
              </InlineLink>
            </div>
          </Stack>

          {['drift' as const, 'metric' as const].map((type) => (
            <AlertsCard key={type} type={type} model={props.model} />
          ))}
        </Stack>
      </Box>
    </OverviewWidgetWrapperHoverable>
  );
};

const AlertsCardWrapper = styled(Box)(
  ({ isAlerting }: { isAlerting: boolean }) => ({
    backgroundColor: '#fff',
    border: isAlerting ? '1px solid #F44336' : '1px solid transparent',
    borderRadius: '4px',
    padding: '44px 16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  })
);

const AlertsCard = (props: {
  type: AlertSettingsType;
  model: MonitoringModel;
}) => {
  const alerts = props.model.alerts
    .filter((a) => a.status === AlertStatus.ALERTING)
    .filter((a) => a.settings.type === props.type);
  return (
    <AlertsCardWrapper isAlerting={alerts.length > 0}>
      <Typography variant="h3">{alerts.length}</Typography>
      <Typography variant="subtitle2">{`${capitalize(
        props.type
      )} alerts`}</Typography>
    </AlertsCardWrapper>
  );
};

export default MonitoringOverviewAlertsWidget;
