import React from 'react';

import Select from 'shared/view/elements/Selects/Select/Select';
import { IOptionType } from 'shared/view/elements/Selects/shared/types';

import { ObservationChartXAxisType } from '../utils/settings';

const SelectObservationAxisType: React.FC<
  React.PropsWithChildren<{
    xAxisType: ObservationChartXAxisType;
    setXAxisType: (type: ObservationChartXAxisType) => void;
  }>
> = ({ xAxisType, setXAxisType }) => {
  const options: Array<IOptionType<ObservationChartXAxisType>> = [
    {
      label: 'Timestamp',
      value: 'timestamp',
    },
    {
      label: 'Epoch number',
      value: 'epochNumber',
    },
  ];

  return (
    <div style={{ minWidth: 200 }}>
      <Select
        options={options}
        label={'Axis type'}
        value={options.find((opt) => opt.value === xAxisType)}
        onChange={(opt) => setXAxisType(opt.value)}
      />
    </div>
  );
};

export default SelectObservationAxisType;
