import { gql } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { PYPI_CONFIGURATION_FRAGMENT } from 'shared/graphql/SystemAdmin/PypiIntegration';
import {
  UpdatePypiConfiguration,
  UpdatePypiConfigurationVariables,
} from 'features/systemAdmin/integrations/pypi/store/graphql-types/useUpdatePypiConfiguration.generated';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

const MUTATION = gql`
  mutation UpdatePypiConfiguration(
    $id: ID!
    $name: String!
    $url: String!
    $username: String!
    $password: String!
    $enabled: Boolean!
  ) {
    pypiConfiguration(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on PypiConfiguration {
        id
        update(
          name: $name
          url: $url
          username: $username
          password: $password
          enabled: $enabled
        ) {
          id
          ...PypiConfigurationData
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${PYPI_CONFIGURATION_FRAGMENT}
`;

export const useUpdatePypiConfiguration = () => {
  const [updatePypiConfiguration, updatingPypiConfiguration] =
    useCustomMutation<
      UpdatePypiConfiguration,
      UpdatePypiConfigurationVariables
    >(MUTATION, { context: 'updating PyPI configuration' });

  return {
    updatePypiConfiguration,
    updatingPypiConfiguration,
  };
};
