import { useCallback } from 'react';

import { useCustomMonitoringPanel } from 'features/monitoring/panel/store/useCustomMonitoringPanel';
import useCreateWidgetAction from 'features/monitoring/shared/CreateWidgetAction/CreateWidgetAction';
import CustomMonitoringWidget from 'features/monitoring/widgets/view/CustomMonitoringWidget/CustomMonitoringWidget';
import { CustomMonitoringPanel } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringPanel';
import Panel from 'shared/view/domain/Dashboards/Panel/Panel';
import MonitoringWidgetsGridLayout from 'shared/view/domain/Monitoring/MonitoringWidget/MonitoringWidgetsGridLayout/MonitoringWidgetsGridLayout';
import { draggableHandleClassName } from 'shared/view/elements/IconAwesome/IconAwesomeDraggable';

import { MonitoringPanelProps } from '../shared/MonitoringPanelProps';

const CustomMonitoringPanelView = (
  props: MonitoringPanelProps<CustomMonitoringPanel>
) => {
  const {
    updateLayout,
    deleteWidget,
    updateWidget,
    createWidget,
    changePanelOpen,
    deleteCurrentPanel,
  } = useCustomMonitoringPanel({
    panel: props.panel,
    updatePanel: props.updatePanel,
    deletePanel: props.deletePanel,
  });

  const createWidgetAction = useCreateWidgetAction({
    widgetExternalDeps: props.widgetExternalDeps,
    createWidget,
  });

  const { panel, updatePanel } = props;
  const onRename = useCallback(
    (name: string) => updatePanel({ ...panel, name }),
    [panel, updatePanel]
  );

  return (
    <>
      {createWidgetAction.popup}
      <Panel
        isOpen={props.panel.isOpen}
        onChangeOpen={changePanelOpen}
        dragHandleProps={props.dragHandleProps}
        name={props.panel.name}
        onDelete={deleteCurrentPanel}
        onRename={onRename}
        onCreate={createWidgetAction.onClick}
        widgetsCount={props.panel.widgets.length}
      >
        <MonitoringWidgetsGridLayout
          layout={props.panel.layout}
          onLayoutChange={updateLayout}
          draggableHandleClassName={draggableHandleClassName}
        >
          {props.panel.widgets.map((widget) => (
            <div key={widget.id}>
              <CustomMonitoringWidget
                widget={widget}
                widgetExternalDeps={props.widgetExternalDeps}
                updateWidget={updateWidget}
                deleteWidget={deleteWidget}
              />
            </div>
          ))}
        </MonitoringWidgetsGridLayout>
      </Panel>
    </>
  );
};

export default CustomMonitoringPanelView;
