import { AggregationType } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/shared/Aggregation';
import { getSelectedOption } from 'shared/view/elements/Selects/Select/helpers';
import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';
import Select from 'shared/view/elements/Selects/Select/Select';
import useVertaSelectOptionsWithClear from 'shared/view/elements/Selects/shared/hooks/useVertaSelectOptionsWithClear';

interface ILocalProps {
  value: AggregationType | undefined;
  onChange(type: AggregationType | undefined): void;
}

const AggregationTypeSelect = (props: ILocalProps) => {
  const aggregationTypeOptions = exhaustiveStringTuple<AggregationType>()(
    'average',
    'count',
    'median',
    'stdev',
    'sum',
    'variance'
  ).map((type) => ({ label: type, value: type }));

  const selectedOption = props.value
    ? getSelectedOption(props.value, aggregationTypeOptions)
    : undefined;

  const options = useVertaSelectOptionsWithClear(aggregationTypeOptions);

  return (
    <div style={{ minWidth: 150 }}>
      <Select
        value={selectedOption}
        options={options}
        onChange={(option) => {
          props.onChange(option.value);
        }}
        label={'Aggregation type'}
        withoutError={true}
      />
    </div>
  );
};

export default AggregationTypeSelect;
