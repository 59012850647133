import { gql } from '@apollo/client';

export const MONITORING_IO_DESCRIPTION_FRAGMENT = gql`
  fragment MonitoringIODescriptionFragment on MonitoringIODescription {
    ... on MonitoringInputDescription {
      ioType
      name
      dataType
    }
    ... on MonitoringOutputDescription {
      ioType
      name
      dataType
    }
  }
`;
