import { groupBy, isEmpty, map, pipe, toPairs, unnest } from 'ramda';

import { CanaryStrategy, CanaryStrategyRule } from 'generated/types';
import { parsePercentage } from 'shared/utils/percentage/percentage';
import isNotNil from 'shared/utils/isNotNill';
import { getRuleParameterParser } from 'shared/models/Deployment/canary/CanaryRules/CanaryRuleParameter';
import { parseCanaryRuleInterval } from 'shared/models/Deployment/canary/CanaryRules/CanaryRuleInterval';
import { OmitStrict } from 'shared/utils/types';

import {
  CanaryStrategyForm,
  SelectedCanaryRule,
} from '../../view/UpdateEndpointSection/settings/CanaryStrategyForm';

export const convertCanarySettingsToGraphql = (
  state: CanaryStrategyForm
): CanaryStrategy => {
  const convertedStep = parsePercentage(state.step);
  const convertedInterval = parseCanaryRuleInterval(state.interval);

  return {
    progressStep: convertedStep,
    progressInterval: convertedInterval,
    rules: parseSelectedRules(state.selectedRules),
  };
};

type SendingRule = OmitStrict<SelectedCanaryRule, 'canonicalRule'> & {
  canonicalRule: Required<SelectedCanaryRule>['canonicalRule'];
};
const getSendingRules = (
  selectedRules: SelectedCanaryRule[]
): SendingRule[] => {
  return selectedRules
    .map(({ id, canonicalRule, parameters }) =>
      canonicalRule
        ? {
            id,
            canonicalRule,
            parameters,
          }
        : undefined
    )
    .filter(isNotNil);
};

const parseSelectedRules = (
  selectedRules: SelectedCanaryRule[]
): CanaryStrategyRule[] => {
  return pipe(
    getSendingRules,
    groupBy(({ canonicalRule }: SendingRule) => canonicalRule.id),
    toPairs,
    map(([id, rules]: [string, SendingRule[]]) => {
      return {
        id,
        parameters: rules
          .flatMap(({ parameters }) => parameters)
          .filter(isNotNil)
          .filter(({ value }) => !isEmpty(value))
          .map(({ name, value, type }) => ({
            name,
            value: String(getRuleParameterParser(type)(value)),
          })),
      };
    }),
    unnest
  )(selectedRules);
};
