import usePromptBeforeLeavingSite from 'shared/view/hooks/usePromptBeforeLeavingSite';

import { useFirstRenderEffect } from './useFirstRenderEffect';
import ReactRouterPrompt from '../elements/ReactRouterPrompt';

export default function usePromptUserAboutUnsavedChangesOnLivingRoute(
  thereIsUnsavedChanges: boolean,
  promptMessage?: string
) {
  const message = promptMessage || 'Changes you made may not be saved.';

  usePromptBeforeLeavingSite(thereIsUnsavedChanges, message);

  return (
    <ReactRouterPrompt when={thereIsUnsavedChanges}>
      {({ isActive, onCancel, onConfirm }) =>
        isActive ? (
          <RenderPrompt
            message={message}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        ) : null
      }
    </ReactRouterPrompt>
  );
}

const RenderPrompt = (props: {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  useFirstRenderEffect(() => {
    const p = confirm(props.message);

    if (p) props.onConfirm();
    else props.onCancel();
  });

  return null;
};
