import { useOutlierDetectionOverTime } from 'features/monitoring/widgets/store/outlierDetection/useOutlierDetectionOverTime';
import { OutlierDetectionChartWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/ScatterPlotWidget';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

import { MonitoringWidgetProps } from '../../shared/types';
import OutlierDetectionChart from './OutlierDetectionChart/OutlierDetectionChart';

const OutlierDetectionChartWidgetView = (
  props: MonitoringWidgetProps<OutlierDetectionChartWidget>
) => {
  const { data, communication } = useOutlierDetectionOverTime({
    widgetExternalDeps: props.widgetExternalDeps,
    description: props.widget.ioDescription,
  });

  return (
    <DefaultMatchRemoteDataOrError
      data={data}
      communication={communication}
      context="loading outlier detection"
    >
      {(loadedData) => (
        <OutlierDetectionChart
          id={props.id}
          outlierDetection={loadedData}
          onSetReset={props.onSetReset}
          size={props.size}
        />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default OutlierDetectionChartWidgetView;
