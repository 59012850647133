import { useField } from 'formik';
import { createRef } from 'react';
import Stack from '@mui/material/Stack';

import { validateNotEmpty } from 'shared/utils/validators';
import Button from 'shared/view/elements/Button/Button';
import matchType from 'shared/utils/matchType';
import InfoIcon from 'shared/view/elements/InfoIcon/InfoIcon';
import { CustomError } from 'shared/models/Error';
import { useToastCommunicationError } from 'features/toast/store/hooks';
import TextInput from 'shared/view/elements/TextInput/TextInput';

export function FileWithTextAreaField({
  name,
  label,
  fileFormat,
  info,
  accept,
}: {
  name: string;
  label: string;
  fileFormat: 'base64' | 'text';
  info?: string;
  accept?: string;
}) {
  const [inputProps, metaProps, { setValue }] = useField<string | undefined>({
    name,
    validate: validateNotEmpty(label),
  });

  const toastCommunicationError = useToastCommunicationError();

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const _100kbInBytes = 102400;
      if (file.size > _100kbInBytes) {
        toastCommunicationError(
          new CustomError(
            'The file size is too large. Its size should be less than 100kb.'
          ),
          { context: 'uploading a file' }
        );
        return;
      }
      const res = await matchType(
        {
          base64: () => fileToBase64(file),
          text: () => fileToText(file),
        },
        fileFormat
      );
      setValue(res);
    }
  };

  return (
    <Stack direction="row" width="100%">
      <TextInput
        withoutError
        minWidth="90%"
        multiline={true}
        withoutLabelPadding
        name={name}
        value={inputProps.value}
        label={label}
        onBlur={inputProps.onBlur}
        onChange={setValue}
        meta={metaProps}
        endAdornmentIcons={[
          <InfoIcon key={'info'} tooltip={info} tooltipPlacement="left" />,
        ]}
      />
      <UploadControl accept={accept} onChange={uploadFile}>
        {(onClick) => (
          <Button onClick={onClick} isLoading={false} variant="outlined">
            Upload
          </Button>
        )}
      </UploadControl>
    </Stack>
  );
}

const UploadControl = ({
  children,
  accept,
  onChange,
}: {
  children: (onClick: () => void) => React.ReactNode;
  accept?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const ref = createRef<HTMLInputElement>();
  return (
    <div>
      <input hidden type="file" accept={accept} onChange={onChange} ref={ref} />
      {children(() => {
        ref.current?.click();
      })}
    </div>
  );
};

const fileToBase64 = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const fileToText = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
