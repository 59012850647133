import { param, route } from 'shared/routes/shared/routesTreeBuilder';

const serviceAccounts = route('serviceAccounts', {
  tag: 'organizationServiceAccounts',
})(
  route('create', {
    tag: 'organizationServiceAccountsCreate',
  })(),
  route(
    'username',
    undefined
  )(
    param()('username', {
      tag: 'organizationServiceAccount',
    })()
  )
);

export const privateRoutes = route('organizations', {
  tag: 'organizations',
})(
  route('new', {
    tag: 'createOrganization',
  })(),
  param()('organizationName', {
    tag: 'organizationName',
  })(
    route('members', {
      tag: 'organizationMembers',
    })(
      param()('memberUsername', {
        tag: 'organizationMember',
      })()
    ),
    route('overview', {
      tag: 'organizationOverview',
    })(),
    route('permissions', {
      tag: 'organizationPermissions',
    })(),
    serviceAccounts,
    route('registries', {
      tag: 'organizationContainerRegistries',
    })()
  )
);
