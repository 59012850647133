import { useDeleteEntityAction } from 'features/shared/DeleteEntityIcon/useDeleteEntityAction';
import routes from 'shared/routes';
import isNotNil from 'shared/utils/isNotNill';
import { MenuAction } from 'shared/view/elements/ActionsMenu/ActionsMenu';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';

import DatasetVersionEditor from './DatasetVersionEditor';

interface Props {
  datasetVersion: { id: string; description: string } | null;
  dataset: {
    id: string;
    workspace: { name: string };
    allowedActions: { delete: boolean; update: boolean };
  } | null;
}

export const useDatasetVersionSettingsActions = (props: Props) => {
  const popupManagerApi = usePopupManager();

  const actions: MenuAction[] = [
    {
      type: 'button' as const,
      label: 'Edit dataset version information',
      dataTest: 'edit-info-menu-item',
      disabled: !props.dataset?.allowedActions.update,
      onClick: popupManagerApi.openPopup,
      additionalContent:
        popupManagerApi.isPopupOpen && props.datasetVersion ? (
          <DatasetVersionEditor
            isOpen={popupManagerApi.isPopupOpen}
            datasetVersion={props.datasetVersion}
            onClose={popupManagerApi.closePopup}
          />
        ) : null,
    },
    useDeleteEntityAction({
      id: props.datasetVersion?.id ?? null,
      entityType: 'datasetVersion',
      redirectUrl: props.dataset
        ? routes.datasetVersions.getRedirectPath({
            workspaceName: props.dataset.workspace.name,
            datasetId: props.dataset.id,
          })
        : null,
      isNotAllowedToDelete: !props.dataset?.allowedActions.delete,
    }),
  ].filter(isNotNil);

  return actions;
};
