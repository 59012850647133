/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { IEnvironmentBlob } from 'shared/models/Versioning/Blob/EnvironmentBlob';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import { convertGraphqlAttributeWithWidgetBlob } from 'shared/graphql/Attribute/converters';
import { IAttributeWithWidgetBlob } from 'shared/models/AttributeWithWidgetBlob';
import { convertEnvironmentBlob } from 'shared/models/Versioning/Blob/EnvironmentBlob/converters';
import { CODE_VERSION_BLOB_FRAGMENT } from 'shared/graphql/CodeVersion/CodeVersionFromBlob';
import { OmitStrict } from 'shared/utils/types';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { selectFlags } from 'features/flags';
import { ExtractByTypename } from 'shared/utils/types';

import * as Types from './graphql-types/useModelVersionReproduce.generated';

export type RegisteredModelVersion = ExtractByTypename<
  Types.ModelVersionReproduce['registeredModelVersion'],
  'RegisteredModelVersion'
>;

export type IRegisteredModelVersion = OmitStrict<
  RegisteredModelVersion,
  'environmentBlob' | 'attributes'
> & {
  environmentBlob: IEnvironmentBlob | null;
  attributes: IAttributeWithWidgetBlob[];
  dockerMetadata: RegisteredModelVersion['dockerMetadata'] | null;
};

const MODEL_VERSION_REPRODUCE = gql`
  query ModelVersionReproduce($versionId: ID!, $isEnableDatasets: Boolean!) {
    registeredModelVersion(id: $versionId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        version
        environmentBlob
        attributes {
          ...AttributeData
        }
        run {
          ... on Error {
            ...ErrorData
          }
          ... on ExperimentRun {
            id
            name
            experiment {
              id
              name
            }
            project {
              name
              id
              workspace {
                id
                ...WorkspaceData
              }
            }
          }
        }
        codeVersions {
          ...CodeVersionBlob
        }
        datasets @include(if: $isEnableDatasets) {
          key
          type
          linkedArtifactId
          linkedDatasetVersion {
            ... on Error {
              ...ErrorData
            }
            ... on DatasetVersion {
              id
              version
              dateUpdated
              tags
              description
              owner {
                id
                fullName
                username
              }
              dataset {
                id
                name
                workspace {
                  id
                  ...WorkspaceData
                }
              }
            }
          }
        }
        dockerMetadata {
          requestPort
          requestPath
          healthPath
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ATTRIBUTE_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${CODE_VERSION_BLOB_FRAGMENT}
`;

export const useModelVersionReproduce = ({
  versionId,
}: {
  versionId: string;
}) => {
  const { isEnableDatasets } = useSelector(selectFlags);

  const query = useCustomQuery<
    Types.ModelVersionReproduce,
    Types.ModelVersionReproduceVariables
  >(MODEL_VERSION_REPRODUCE, {
    variables: {
      versionId,
      isEnableDatasets: Boolean(isEnableDatasets),
    },
  });

  const { data: version, communication: loadingVersion } =
    resultToCommunicationWithData(convertResponse, query);

  return {
    version,
    loadingVersion,
  };
};

const hiddenAttributesKeys = ['workflow_status', 'workflow_templates'];

const convertResponse = ({
  registeredModelVersion,
}: Types.ModelVersionReproduce) =>
  mapDataOrError(registeredModelVersion, (version) => {
    const attributes = version.attributes
      .map(convertGraphqlAttributeWithWidgetBlob)
      .filter((attr) => !hiddenAttributesKeys.includes(attr.key));
    const modelVersion: IRegisteredModelVersion = {
      ...version,
      attributes,
      environmentBlob: convertEnvironmentBlob(version.environmentBlob),
      codeVersions: version.codeVersions,
      dockerMetadata: version.dockerMetadata,
    };
    return modelVersion;
  });
