import Stack from '@mui/material/Stack';

import { ActionTypeV2, ResourceTypeV2 } from 'generated/types';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';
import InfoIcon from 'shared/view/elements/InfoIcon/InfoIcon';
import matchType from 'shared/utils/matchType';
import handleUnionCases from 'shared/utils/handleUnionCases';

const BaseOrganizationRolesTable = (props: {
  renderCell: (row: {
    resourceType: ResourceTypeV2;
    actionType: ActionTypeV2;
  }) => React.ReactNode;
}) => {
  const columns: Array<DataGridColumn<{ resourceType: ResourceTypeV2 }>> = [
    {
      field: ' ',
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ textTransform: 'capitalize' }}>
          <CellRendererString
            value={matchType(
              {
                DATA: () => 'Data',
                ENDPOINTS: () => 'Endpoints',
                PROJECT: () => 'Project',
                MODELS_AND_VERSIONS: () => 'Models and Versions',
                MONITOR: () => 'Monitor',
                RESOURCE_TYPE_UNKNOWN: () => 'Unknown',
              },
              params.row.resourceType
            )}
          />
        </div>
      ),
    },
    ...handleUnionCases<
      Exclude<ActionTypeV2, ActionTypeV2.ACTION_TYPE_UNKNOWN>,
      { actionType: ActionTypeV2; label: string; tooltip: string | undefined }
    >()([
      [
        ActionTypeV2.READ,
        (actionType) => ({ actionType, label: 'Read', tooltip: undefined }),
      ],
      [
        ActionTypeV2.UPDATE,
        (actionType) => ({ actionType, label: 'Update', tooltip: undefined }),
      ],
      [
        ActionTypeV2.CREATE,
        (actionType) => ({ actionType, label: 'Create', tooltip: undefined }),
      ],
      [
        ActionTypeV2.DELETE,
        (actionType) => ({ actionType, label: 'Delete', tooltip: undefined }),
      ],
      [
        ActionTypeV2.APPROVE_REJECT,
        (actionType) => ({
          actionType,
          label: 'Approve/Reject',
          tooltip:
            'Anyone with approval rights can approve or reject a model version stage change request.',
        }),
      ],
      [
        ActionTypeV2.REGISTER,
        (actionType) => ({
          actionType,
          tooltip:
            'Register permission grants access to register a new model version.',
          label: 'Register',
        }),
      ],
      [
        ActionTypeV2.DEPLOY,
        (actionType) => ({
          actionType,
          label: 'Deploy',
          tooltip:
            'Deploy permission grants access to deploy an endpoint. Endpoints can be deployed from a Registered Model Version or an Experiment Run (typically for testing).',
        }),
      ],
      [
        ActionTypeV2.PREDICT,
        (actionType) => ({
          actionType,
          label: 'Predict',
          tooltip:
            'Predict endpoint permission grants access to run predictions on an endpoint.',
        }),
      ],
    ]).map(
      ({
        actionType,
        label,
        tooltip,
      }): DataGridColumn<{ resourceType: ResourceTypeV2 }> => ({
        flex: 1,
        field: label,
        renderHeader: (params) => {
          return (
            <Stack direction="row" alignItems="center">
              <span>{params.field}</span>{' '}
              {tooltip ? <InfoIcon tooltip={tooltip} /> : null}
            </Stack>
          );
        },
        disableColumnMenu: true,
        renderCell: (params) =>
          props.renderCell({
            ...params.row,
            actionType,
          }),
      })
    ),
  ];

  return (
    <DataGridWithTypes
      heightType="autoHeightButMax5Rows"
      rows={exhaustiveStringTuple<
        Exclude<ResourceTypeV2, ResourceTypeV2.RESOURCE_TYPE_UNKNOWN>
      >()(
        ResourceTypeV2.DATA,
        ResourceTypeV2.PROJECT,
        ResourceTypeV2.MODELS_AND_VERSIONS,
        ResourceTypeV2.ENDPOINTS,
        ResourceTypeV2.MONITOR
      ).map((t) => ({
        id: t,
        resourceType: t,
      }))}
      columns={columns}
    />
  );
};

export default BaseOrganizationRolesTable;
