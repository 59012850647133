import { Box, Divider, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import Button from 'shared/view/elements/Button/Button';
import {
  EmptyFilteredList,
  EmptyGridContainer,
} from 'shared/view/elements/EmptyGrid/EmptyGrid';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import { CustomAttributeTargetType } from 'generated/types';
import { trackEvent } from 'setup/app/analytics';

import { CustomAttributeCategoryPopup } from './CustomAttributeCategoryPopup/CustomAttributeCategoryPopup';
import { CustomModelAttributePopup } from './CustomModelAttributePopup/CustomModelAttributePopup';
import { useOrganizationCustomAttributes } from '../hooks/useOrganizationCustomAttributes';
import {
  OrganizationV2,
  CustomAttributeDefinitions,
  CustomAttributeCategories,
} from '../graphql';
import CustomAttributeListItem from './CustomAttributeListItem';
import { CategoriesGrid } from './CategoriesGrid/CategoriesGrid';

interface Workspace {
  registeredModels: {
    models: {
      id: string;
      customAttributeValues: {
        customAttributeDefinitionId: string;
        selectedValue: string;
      }[];
    }[];
  };
  registeredModelVersions: {
    versions: {
      id: string;
      customAttributeValues: {
        customAttributeDefinitionId: string;
        selectedValue: string;
      }[];
    }[];
  };
}

type AttrsWithValues = Record<string, boolean>;

type Props = ReturnType<typeof useOrganizationCustomAttributes> & {
  organization: OrganizationV2;
  workspaces: Workspace[];
  reload: () => void;
};

type CustomAttributeListProps = {
  attributes: CustomAttributeDefinitions;
  reload: () => void;
  attrsWithValues: AttrsWithValues;
  categories: CustomAttributeCategories | null;
};

function processCustomAttributeValues(
  workspaces: Workspace[]
): Record<string, boolean> {
  const result: Record<string, boolean> = {};

  workspaces.forEach((workspace) => {
    workspace.registeredModels.models.forEach((model) => {
      if (model.customAttributeValues.length > 0) {
        model.customAttributeValues.forEach((attribute) => {
          result[attribute.customAttributeDefinitionId] = true;
        });
      }
    });

    workspace.registeredModelVersions.versions.forEach((version) => {
      if (version.customAttributeValues.length > 0) {
        version.customAttributeValues.forEach((attribute) => {
          result[attribute.customAttributeDefinitionId] = true;
        });
      }
    });
  });

  return result;
}

const CustomAttributeList = ({
  attributes,
  reload,
  attrsWithValues,
  categories,
}: CustomAttributeListProps) =>
  isEmpty(attributes) ? (
    <EmptyGridContainer maxHeight="120px" sx={{ backgroundColor: 'white' }}>
      <EmptyFilteredList icon={false} message="No custom attributes." />
    </EmptyGridContainer>
  ) : (
    <Stack>
      {attributes.map((attr) => (
        <CustomAttributeListItem
          dataTest="custom-attribute"
          key={`custom-attribute-header-${attr.id}`}
          attribute={attr}
          reload={reload}
          attrsWithValues={attrsWithValues}
          categories={categories}
        />
      ))}
    </Stack>
  );

export const OrganizationCustomAttributesScreen = ({
  organization,
  workspaces,
  reload,
}: Props) => {
  const categories = isNotError(organization.customAttributeCategories)
    ? organization.customAttributeCategories.customAttributeCategories
    : null;

  const categoriesInUse: Record<string, boolean> = {};

  const attrsWithValues = processCustomAttributeValues(workspaces);

  const customAttributes = isNotError(organization.customAttributeDefinitions)
    ? organization.customAttributeDefinitions.customAttributeDefinitions
    : null;

  const modelVersionAttrs: CustomAttributeDefinitions = [];
  const modelAttrs: CustomAttributeDefinitions = [];

  customAttributes?.forEach((attr) => {
    categoriesInUse[attr.customAttributeCategoryId] = true;

    if (attr.targetType === CustomAttributeTargetType.REGISTERED_MODEL) {
      modelAttrs.push(attr);
    }
    if (
      attr.targetType === CustomAttributeTargetType.REGISTERED_MODEL_VERSION
    ) {
      modelVersionAttrs.push(attr);
    }
  });

  return (
    <Stack spacing={5} mt={5}>
      <Stack spacing={3}>
        <Box display="flex" justifyContent="space-between" alignItems="start">
          <Stack spacing={1}>
            <Typography variant="h6" color="text.primary">
              Attributes categories
            </Typography>
            <Typography variant="body2" color="text.secondary" maxWidth="600px">
              Categories will be applied to both model and version attributes.
            </Typography>
          </Stack>
          <ButtonWithPopup
            key="create-new-custom-attribute-category"
            button={(props) => (
              <Button
                {...props}
                onClick={() => {
                  trackEvent({
                    type: 'organization.custom_attributes.category_create_clicked',
                  });
                  props.onClick();
                }}
              >
                Add attribute category
              </Button>
            )}
            popup={(props) => (
              <CustomAttributeCategoryPopup
                {...props}
                category={undefined}
                submitButton={{ children: 'Create category' }}
                title="Add attribute category"
                organizationId={organization.id}
                isCategoryInUse={false}
              />
            )}
          />
        </Box>
        <CategoriesGrid
          categories={categories}
          categoriesInUse={categoriesInUse}
        />
      </Stack>
      <Divider />
      <Stack spacing={3}>
        <Box display="flex" justifyContent="space-between" alignItems="start">
          <Stack>
            <Typography variant="h6" color="text.primary">
              Model Attributes
            </Typography>
            <Typography variant="body2" color="text.secondary" maxWidth="600px">
              By using custom attributes in your models, you can adjust them to
              more accurately address the unique requirements and constraints of
              your business process or use case
            </Typography>
          </Stack>
          <ButtonWithPopup
            key="create-new-custom-attribute"
            button={(props) => (
              <Button
                {...props}
                onClick={() => {
                  trackEvent({
                    type: 'organization.custom_attributes.registered_model_create_clicked',
                  });
                  props.onClick();
                }}
              >
                Add custom attribute
              </Button>
            )}
            popup={(props) => (
              <CustomModelAttributePopup
                {...props}
                type={CustomAttributeTargetType.REGISTERED_MODEL}
                customAttribute={undefined}
                submitButton={{ children: 'Create attribute' }}
                title="Add custom model attribute"
                organizationId={organization.id}
                attrsWithValues={attrsWithValues}
                categories={categories}
              />
            )}
          />
        </Box>

        <CustomAttributeList
          attributes={modelAttrs}
          reload={reload}
          attrsWithValues={attrsWithValues}
          categories={categories}
        />
      </Stack>
      <Divider />
      <Stack spacing={3}>
        <Box display="flex" justifyContent="space-between" alignItems="start">
          <Stack>
            <Typography variant="h6" color="text.primary">
              Version attributes
            </Typography>
            <Typography variant="body2" color="text.secondary" maxWidth="600px">
              Similarly to model custom attributes, version attributes can be
              used to register parameters that are unique to your business
              process at a version level.
            </Typography>
          </Stack>
          <ButtonWithPopup
            key="create-new-custom-attribute"
            button={(props) => (
              <Button
                {...props}
                onClick={() => {
                  trackEvent({
                    type: 'organization.custom_attributes.registered_model_version_create_clicked',
                  });
                  props.onClick();
                }}
              >
                Add custom attribute
              </Button>
            )}
            popup={(props) => (
              <CustomModelAttributePopup
                {...props}
                type={CustomAttributeTargetType.REGISTERED_MODEL_VERSION}
                customAttribute={undefined}
                submitButton={{ children: 'Create attribute' }}
                title="Add custom model version attribute"
                organizationId={organization.id}
                attrsWithValues={attrsWithValues}
                categories={categories}
              />
            )}
          />
        </Box>

        <CustomAttributeList
          attributes={modelVersionAttrs}
          reload={reload}
          attrsWithValues={attrsWithValues}
          categories={categories}
        />
      </Stack>
    </Stack>
  );
};
