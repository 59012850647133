import { useMemo } from 'react';

import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';
import isNotNil from 'shared/utils/isNotNill';

import { DimensionScale, getDimensionScale } from './dimensionScale';
import {
  ParallelCoordinateChartData,
  ParallelCoordinateChartDataValue,
} from './types';

export interface Dimension {
  key: string;
  x: number;
  getY: (value: ParallelCoordinateChartDataValue) => number;
  scale: DimensionScale['scale'];
}

export const useDimensions = ({
  data,
  innerHeight,
  innerWidth,
  keys,
}: {
  keys: string[];
  data: ParallelCoordinateChartData[];
  innerWidth: number;
  innerHeight: number;
}) => {
  const levelWidth = innerWidth / (keys.length - 1);

  const dimensions: Dimension[] = useMemo(
    () =>
      keys.map((key, index): Dimension => {
        const dimensionScale = getDimensionScale(
          data.map((d) => d[key]).filter(isNotNil),
          { innerHeight }
        );

        const getY = (value: ParallelCoordinateChartDataValue) => {
          switch (dimensionScale.type) {
            case 'point':
              return dimensionScale.scale(String(value)) ?? 0;

            case 'linear':
              return dimensionScale.scale(Number(value));

            default: {
              exhaustiveCheck(dimensionScale);
            }
          }
        };

        return {
          key,
          getY,
          scale: dimensionScale.scale,
          x: index * levelWidth,
        };
      }),
    [data, innerHeight, keys, levelWidth]
  );

  return dimensions;
};
