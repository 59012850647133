import { RegisteredModelVersionLockLevel } from 'generated/types';
import Select from 'shared/view/elements/Selects/Select/Select';
import handleUnionCases from 'shared/utils/handleUnionCases';
import {
  IChangingLockLevelsAccess,
  RegisteredModelVersionLockLevelWithoutUnknown,
} from 'shared/models/Registry/RegisteredModelVersion/LockLevel';
import { IOptionType } from 'shared/view/elements/Selects/shared/types';

interface ILocalProps {
  lockLevel: RegisteredModelVersionLockLevel;
  changingLockLevelsAccess: IChangingLockLevelsAccess;
  isChanging: boolean;
  disabled?: boolean;
  onChange(lockLevel: RegisteredModelVersionLockLevelWithoutUnknown): void;
}

const LockLevelSelector = (props: ILocalProps) => {
  const options = getOptions(props.changingLockLevelsAccess);

  return (
    <Select
      value={options.find((opt) => opt.value === props.lockLevel)}
      options={options}
      label={'Lock level'}
      dataTest="lock-level-selector"
      disabled={props.isChanging || props.disabled}
      withoutError={true}
      onChange={({ value }) => props.onChange(value)}
    />
  );
};

const getOptions = (changingLockLevelsAccess: IChangingLockLevelsAccess) => {
  return handleUnionCases<
    RegisteredModelVersionLockLevelWithoutUnknown,
    IOptionType<RegisteredModelVersionLockLevelWithoutUnknown>
  >()([
    [
      RegisteredModelVersionLockLevel.OPEN,
      (value) => ({
        label: 'Open',
        value,
        disabled: !changingLockLevelsAccess[value],
      }),
    ],
    [
      RegisteredModelVersionLockLevel.REDACT,
      (value) => ({
        label: 'Redact',
        value,
        disabled: !changingLockLevelsAccess[value],
      }),
    ],
    [
      RegisteredModelVersionLockLevel.CLOSED,
      (value) => ({
        label: 'Closed',
        value,
        disabled: !changingLockLevelsAccess[value],
      }),
    ],
  ]);
};

export default LockLevelSelector;
