import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { Stack } from '@mui/material';

import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import InfoIcon from 'shared/view/elements/InfoIcon/InfoIcon';

type Props = {
  isLoading: boolean;
  optionsEmpty: boolean;
};

export default function VertaSelectBaseAdornment({
  isLoading,
  optionsEmpty,
}: Props) {
  const adornmentSize: SizeProp = 'lg';

  const icons: JSX.Element[] = [];

  if (isLoading) {
    icons.push(
      <IconAwesomeInfo
        icon={ICONS.circleNotch}
        spin={true}
        size={adornmentSize}
      />
    );
  }

  if (optionsEmpty) {
    icons.push(
      <InfoIcon
        tooltip="No options are available for this selection"
        iconSize={adornmentSize}
      />
    );
  }

  return icons.length > 0 ? (
    <Stack direction="row" alignItems="center" mr={3}>
      {icons}
    </Stack>
  ) : null;
}
