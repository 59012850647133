import { PredicateOperator } from 'generated/types';
import { getLabelByPredicateOperator } from 'shared/models/PredicateOperator';
import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';
import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';
import matchBy from 'shared/utils/matchBy';

export type Alerter = AlerterFixed | AlerterRange;

export type AlerterType = Alerter['type'];

export interface AlerterFixed {
  type: 'fixed';
  threshold: number;
  operator: PredicateOperator;
}

export interface AlerterRange {
  type: 'range';
  upperBound: number;
  lowerBound: number;
  alertIfOutsideRange: boolean;
}

export const defaultAlertThreshold = 0.7;
export const defaultAlertOperator = PredicateOperator.GT;

export const defaultAlertUpperBound = 1;
export const defaultAlertLowerBound = 0;

const isSelectableOperatorMap: Record<PredicateOperator, boolean> = {
  [PredicateOperator.CONTAIN]: false,
  [PredicateOperator.NOT_CONTAIN]: false,
  [PredicateOperator.IN]: false,
  [PredicateOperator.NOT_IN]: false,

  [PredicateOperator.NE]: true,
  [PredicateOperator.EQ]: true,
  [PredicateOperator.GT]: true,
  [PredicateOperator.GTE]: true,
  [PredicateOperator.LT]: true,
  [PredicateOperator.LTE]: true,
};

export const alerterFixedOperatorsForSelection =
  exhaustiveStringTuple<PredicateOperator>()(
    PredicateOperator.CONTAIN,
    PredicateOperator.NOT_CONTAIN,
    PredicateOperator.NOT_IN,
    PredicateOperator.IN,
    PredicateOperator.NE,
    PredicateOperator.EQ,
    PredicateOperator.GT,
    PredicateOperator.GTE,
    PredicateOperator.LT,
    PredicateOperator.LTE,
    PredicateOperator.NOT_IN
  ).filter((operator) => isSelectableOperatorMap[operator]);

export const toAlerterRuleString = (alerter: Alerter): string => {
  return matchBy(
    alerter,
    'type'
  )({
    fixed: ({ operator, threshold }) =>
      `${getLabelByPredicateOperator(operator)} ${threshold}`,
    range: ({ upperBound, lowerBound, alertIfOutsideRange }) =>
      alertIfOutsideRange
        ? `not between ${lowerBound} and ${upperBound}`
        : `${lowerBound} - ${upperBound}`,
  });
};

export const checkIsAlertingByAlerter = (value: number, alerter: Alerter) => {
  return matchBy(
    alerter,
    'type'
  )({
    fixed: ({ operator, threshold }) => {
      switch (operator) {
        case PredicateOperator.GT:
          return value > threshold;
        case PredicateOperator.GTE:
          return value >= threshold;
        case PredicateOperator.LT:
          return value < threshold;
        case PredicateOperator.LTE:
          return value <= threshold;
        case PredicateOperator.EQ:
          return value === threshold;
        case PredicateOperator.NE:
          return value !== threshold;
        case PredicateOperator.NOT_CONTAIN:
        case PredicateOperator.CONTAIN:
        case PredicateOperator.IN:
        case PredicateOperator.NOT_IN:
          return false;
        default:
          return exhaustiveCheck(operator);
      }
    },
    range: (rangeAlerter) => {
      if (rangeAlerter.alertIfOutsideRange) {
        return (
          value < rangeAlerter.lowerBound || value > rangeAlerter.upperBound
        );
      }

      return (
        rangeAlerter.upperBound >= value && rangeAlerter.lowerBound <= value
      );
    },
  });
};
