// eslint-disable-next-line no-restricted-imports
import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    inputText: React.CSSProperties;
    avatarInitials: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    buttonMedium: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    inputLabel: React.CSSProperties;
    helperText: React.CSSProperties;
    tooltip: React.CSSProperties;
    formLabel?: React.CSSProperties;
    formValue?: React.CSSProperties;
    date?: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    inputText?: React.CSSProperties;
    avatarInitials?: React.CSSProperties;
    buttonLarge?: React.CSSProperties;
    buttonMedium?: React.CSSProperties;
    buttonSmall?: React.CSSProperties;
    inputLabel?: React.CSSProperties;
    helperText?: React.CSSProperties;
    tooltip?: React.CSSProperties;
    formLabel?: React.CSSProperties;
    formValue?: React.CSSProperties;
    date?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    inputText: true;
    avatarInitials: true;
    buttonLarge: true;
    buttonMedium: true;
    buttonSmall: true;
    inputLabel: true;
    helperText: true;
    tooltip: true;
    formLabel: true;
    formValue: true;
    date: true;
  }
}

const commonProps: React.CSSProperties = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
};

export const muiThemeTypography: TypographyOptions = {
  fontFamily: 'Roboto',
  h1: {
    fontSize: '60px',
    lineHeight: '72px',
    letterSpacing: '-0.5px',
  },
  h3: {
    fontWeight: 300,
    fontSize: '48px',
    lineHeight: '56px',
  },
  h4: {
    fontFamily: 'DM Sans',
    fontSize: '34px',
    lineHeight: '42px',
    letterSpacing: '0px',
  },
  h5: {
    fontFamily: 'DM Sans',
    fontSize: '28px',
    lineHeight: '37px',
  },
  h6: {
    fontFamily: 'DM Sans',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '38px',
    letterSpacing: '0px',
  },
  body2: {
    ...commonProps,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
  },
  subtitle1: {
    fontFamily: 'DM Sans',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '32px',
    letterSpacing: '0.15px',
  },
  subtitle2: {
    fontFamily: 'DM Sans',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.1px',
  },
  buttonLarge: {
    ...commonProps,
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '26px',
    letterSpacing: 0.46,
  },
  buttonMedium: {
    ...commonProps,
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '26px',
    letterSpacing: 0.46,
  },
  buttonSmall: {
    ...commonProps,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '22px',
    letterSpacing: 0.46,
  },
  caption: {
    ...commonProps,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '166%',
    letterSpacing: 0.4,
  },
  overline: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '32px',
    letterSpacing: '1px',
  },
  avatarInitials: {
    ...commonProps,
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '24px',
    letterSpacing: 0.14,
    display: 'block',
  },
  inputLabel: {
    ...commonProps,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: 0.15,
  },
  helperText: {
    ...commonProps,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
    letterSpacing: 0.4,
  },
  inputText: {
    ...commonProps,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0.15,
  },
  tooltip: {
    ...commonProps,
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '14px',
  },
  formLabel: {
    ...commonProps,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '0.1px',
  },
  formValue: {
    ...commonProps,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0.1px',
  },
};
