import { Distribution } from 'shared/models/Monitoring/Distribution/Distribution';
import { MonitoringAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import { Size } from 'shared/utils/charts/chartSizes';
import isNotNil from 'shared/utils/isNotNill';
import TooltipWithAlertDecorations from 'shared/view/domain/Monitoring/MonitoringAlert/AlertDecorations/TooltipWithAlertDecorations/TooltipWithAlertDecorations';

import LiveReferenceCompareChart from '../LiveReferenceCompareChart/LiveReferenceCompareChart';

interface Props {
  id: string;
  time: Date | undefined;
  liveDistributions: Distribution[];
  referenceDistributions: Distribution[];
  size: Size;
  alert: MonitoringAlert | undefined;
  dataColor: string;
  values: Array<{ modelVersion: string; drift: number | undefined }>;
}

const HistogramTooltipChart = (props: Props) => {
  return (
    <TooltipWithAlertDecorations
      alert={props.alert}
      date={props.time}
      label="Drift"
      values={props.values
        .map((v) =>
          isNotNil(v.drift)
            ? { modelVersion: v.modelVersion, value: v.drift }
            : null
        )
        .filter(isNotNil)}
    >
      <LiveReferenceCompareChart
        id={`tooltip-distribution-chart-${props.id}`}
        liveDistributions={props.liveDistributions}
        referenceDistributions={props.referenceDistributions}
        size={props.size}
      />
    </TooltipWithAlertDecorations>
  );
};

export default HistogramTooltipChart;
