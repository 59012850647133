import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { toGraphQLDate } from 'shared/utils/graphql/toGraphQLDate';
import { convertTimeRangeToDateRange } from 'shared/utils/TimeRange';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';
import { convertMonitoringFilterToGraphQL } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';

import { convertIODescriptionToQuery } from '../shared/convertIODescriptionToQuery';
import {
  DistributionQuery,
  DistributionQueryVariables,
} from './graphql-types/useDistribution.generated';

const DISTRIBUTION_QUERY = gql`
  query DistributionQuery(
    $monitoredEntityId: ID!
    $distributionQuery: MonitoringDistributionQuery!
  ) {
    monitoredEntity(id: $monitoredEntityId) {
      ... on Error {
        ...ErrorData
      }
      ... on MonitoredEntity {
        id
        metrics {
          referenceDistribution(query: $distributionQuery) {
            name
            modelVersionId
            bucketLimits
            bucketCounts
            modelVersion {
              ... on Error {
                ...ErrorData
              }
              ... on RegisteredModelVersion {
                id
                version
              }
            }
          }
          liveDistribution(query: $distributionQuery) {
            name
            modelVersionId
            bucketLimits
            bucketCounts
            modelVersion {
              ... on Error {
                ...ErrorData
              }
              ... on RegisteredModelVersion {
                id
                version
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

interface Props {
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  description: MonitoringIODescription;
}

export const useDistributionQuery = (props: Props) => {
  const variables = useMemo((): DistributionQueryVariables => {
    const dateRange = convertTimeRangeToDateRange(
      props.widgetExternalDeps.timeRange
    );
    return {
      monitoredEntityId: props.widgetExternalDeps.monitoredEntityId,
      distributionQuery: {
        startDate: toGraphQLDate(dateRange.from),
        endDate: toGraphQLDate(dateRange.to),
        ioDescriptions: [convertIODescriptionToQuery(props.description)],
        filters: props.widgetExternalDeps.filters.map(
          convertMonitoringFilterToGraphQL
        ),
      },
    };
  }, [
    props.widgetExternalDeps.timeRange,
    props.widgetExternalDeps.monitoredEntityId,
    props.widgetExternalDeps.filters,
    props.description,
  ]);

  const query = useCustomQuery<DistributionQuery, DistributionQueryVariables>(
    DISTRIBUTION_QUERY,
    {
      variables,
    }
  );

  const convert = useCallback(
    (res: DistributionQuery) => {
      return mapDataOrError(res.monitoredEntity, (monitoredEntity) => {
        const referenceDistributions =
          monitoredEntity.metrics.referenceDistribution.filter((r) =>
            props.widgetExternalDeps.registeredModelVersionIds.includes(
              r.modelVersionId
            )
          );
        const liveDistributions =
          monitoredEntity.metrics.liveDistribution.filter((l) =>
            props.widgetExternalDeps.registeredModelVersionIds.includes(
              l.modelVersionId
            )
          );

        return {
          referenceDistributions,
          liveDistributions,
        };
      });
    },
    [props.widgetExternalDeps.registeredModelVersionIds]
  );

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult: query,
  });
};
