import { Stack } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import DialogTitle from '@mui/material/DialogTitle';
import { ReactNode } from 'react';

import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';

export interface PopupTitleProps {
  title: string;
  onClose?: () => void;
  headerAdornment?: ReactNode;
}

const ICONS_SIZE = 'lg';

export default function PopupTitle(props: PopupTitleProps) {
  const { title, onClose, headerAdornment } = props;

  return (
    <DialogTitle>
      <Stack direction="row" alignItems="center">
        <DynamicTypography value={title} variant="h6" />
        <Stack direction="row">
          {headerAdornment ?? headerAdornment}
          {onClose ? (
            <IconAwesomeClickable
              onClick={onClose}
              icon={ICONS.xMark}
              dataTest={'dialog-close'}
              size={ICONS_SIZE}
            />
          ) : null}
        </Stack>
      </Stack>
    </DialogTitle>
  );
}
