/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { selectFlags } from 'features/flags';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { ACTIVITY_FRAGMENT } from 'shared/graphql/registry/stage/Activity';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { CUSTOM_ATTRIBUTE_VALUE_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeValue';
import { ExtractByTypename } from 'shared/utils/types';

import {
  ModelVersionSummary,
  ModelVersionSummaryVariables,
} from './graphql-types/useModelVersionSummary.generated';

export type RegisteredModelVersion = ExtractByTypename<
  ModelVersionSummary['registeredModelVersion'],
  'RegisteredModelVersion'
>;

const MODEL_VERSION_SUMMARY = gql`
  query ModelVersionSummary(
    $versionId: ID!
    $isEnableMonitoring: Boolean!
    $isEnableDeployment: Boolean!
  ) {
    registeredModelVersion(id: $versionId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        dateCreated
        dateUpdated
        labels
        readmeText
        version
        description
        stage
        redirectMetadata
        lockLevel
        attributes {
          ... on KeyValue {
            ... on FloatKeyValue {
              key
              floatValue: value
            }
            ... on StringKeyValue {
              key
              stringValue: value
            }
            ... on BoolKeyValue {
              key
              boolValue: value
            }
            ... on JsonKeyValue {
              key
              content
            }
          }
        }
        owner {
          ...OwnerData
        }
        registeredModel {
          id
          name
          allowedActions {
            ...AllowedActionsData
            lock
            unlock
            updateRedact
          }
          workspace {
            id
            ...WorkspaceData
          }
        }
        deployments @include(if: $isEnableDeployment) {
          endpoint {
            path
            id
            workspace {
              id
              name
            }
            monitoredEntities @include(if: $isEnableMonitoring) {
              entities {
                id
                monitoredModels {
                  models {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        activities {
          id
          ...ActivityFragment
        }
        monitoredModels @include(if: $isEnableMonitoring) {
          models {
            id
            name
          }
        }
        customAttributeValues {
          id
          ...CustomAttributeValueData
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_VALUE_FRAGMENT}
`;

export const useModelVersionSummary = ({
  versionId,
}: {
  versionId: string;
}) => {
  const { monitoring, deployment } = useSelector(selectFlags);

  const query = useCustomQuery<
    ModelVersionSummary,
    ModelVersionSummaryVariables
  >(MODEL_VERSION_SUMMARY, {
    variables: {
      versionId,
      isEnableMonitoring: monitoring.isEnable,
      isEnableDeployment: deployment.isEnable,
    },
  });

  const { data: version, communication: loadingVersion } =
    resultToCommunicationWithData(convertResponse, query);

  return {
    version,
    loadingVersion,
    reload: query.refetch,
  };
};

const convertResponse = ({ registeredModelVersion }: ModelVersionSummary) =>
  mapDataOrError(registeredModelVersion, (version) => version);
