/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { KAFKA_CONFIG_FRAGMENT } from 'shared/graphql/Deployment/Endpoint/KafkaConfig/KafkaConfig';
import {
  UpdateEndpointEnvironmentKafka,
  UpdateEndpointEnvironmentKafkaVariables,
} from 'features/deployment/canary/endpoints/store/endpointEnvironmentKafka/graphql-types/useUpdateEndpointEnvironmentKafka.generated';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

const MUTATION = gql`
  mutation UpdateEndpointEnvironmentKafka(
    $workspaceName: String!
    $organizationId: ID
    $endpointId: ID!
    $environmentId: ID!
    $kafkaReq: KafkaConfigUpdateRequestInput!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoint(id: $endpointId) {
          ... on Error {
            ...ErrorData
          }
          ... on Endpoint {
            id
            environment(id: $environmentId) {
              id
              updateKafka(req: $kafkaReq) {
                id
                status
                kafka {
                  ...KafkaConfigData
                }
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${KAFKA_CONFIG_FRAGMENT}
`;

export function useUpdateEndpointEnvironmentKafka() {
  const [updateKafka, updatingKafka] = useCustomMutation<
    UpdateEndpointEnvironmentKafka,
    UpdateEndpointEnvironmentKafkaVariables
  >(MUTATION, { context: 'updating kafka for environment' });

  return {
    updateKafka,
    updatingKafka,
  };
}
