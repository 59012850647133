import { createAction } from 'typesafe-actions';
import * as Sentry from '@sentry/react';

import {
  IRegistrationByEmail,
  ILoginByEmail,
  AuthExternalServiceProvider,
  ILoginByLdap,
  RegistrationByEmail,
} from 'shared/models/Authorization';
import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';
import { makeThunkApiRequest } from 'shared/utils/redux/actions';
import { AppError } from 'shared/models/Error';
import { profileErrorMessages } from 'shared/utils/customErrorMessages';
import AuthenticationService from 'services/auth/AuthenticationService';
import { UpdateUserInfo } from 'features/workspaces/store/types';
import * as CurrentUserQuery from 'services/auth/graphql-types/AuthenticationService.generated';
import unwrapAsyncActionResult from 'shared/utils/redux/actions/unwrapAsyncActionResult';

import { IUserState } from './types';

const namespace = 'user';

export const authenticateUserByExternalService = makeThunkApiRequest(
  namespace,
  'AUTHENTICATE',
  undefined
)<
  {
    authExternalServiceProvider: AuthExternalServiceProvider;
    redirectBack: string | undefined;
    signUp?: boolean;
  },
  void,
  AppError
>(async ({
  payload: { redirectBack, authExternalServiceProvider, signUp },
}) => {
  AuthenticationService.loginByExternalService(
    authExternalServiceProvider,
    redirectBack,
    signUp
  );
});

export const registerUser = makeThunkApiRequest(
  namespace,
  'REGISTER',
  undefined
)<IRegistrationByEmail, void, IUserState['communications']['registrationUser']>(
  async ({ payload, dispatch, getState, dependencies }) => {
    const createUserToken = getState().flags.data.flags.createUserToken;
    await AuthenticationService.registerUser(payload, createUserToken);
    await loginByEmail({ data: payload, redirectBack: undefined })(
      dispatch,
      getState,
      dependencies
    );
  }
);

export const registerUserByEmail = makeThunkApiRequest(
  namespace,
  'REGISTER_BY_EMAIL',
  undefined
)<
  { values: RegistrationByEmail; onSuccess: () => void },
  CurrentUserData,
  IUserState['communications']['registrationUserByEmail']
>(async ({ payload, dispatch, getState, dependencies }) => {
  const createUserToken = getState().flags.data.flags.createUserToken;
  const { values, onSuccess } = payload;

  const { currentUser } = await AuthenticationService.registerUserByEmail(
    values,
    createUserToken
  );

  await loginByEmail({
    data: values,
    redirectBack: undefined,
    skipRedirect: true,
  })(dispatch, getState, dependencies);

  onSuccess();

  return currentUser;
});

export const loginByEmail = makeThunkApiRequest(
  namespace,
  'LOGIN_BY_EMAIL',
  undefined
)<
  {
    data: ILoginByEmail;
    redirectBack: string | undefined;
    skipRedirect?: boolean;
  },
  { currentUser: CurrentUserData },
  IUserState['communications']['loginingByEmail']
>(async ({
  payload: { data, skipRedirect },
  dependencies: { apolloClient },
}) => {
  const currentUser = await AuthenticationService.loginByEmail(
    apolloClient,
    data
  );
  if (!skipRedirect) {
    window.location.replace('/');
  }
  return { currentUser };
});

export const loginByLdap = makeThunkApiRequest(
  namespace,
  'LOGIN_BY_LDAP',
  undefined
)<
  {
    data: ILoginByLdap;
    redirectBack: string | undefined;
  },
  { currentUser: CurrentUserData },
  IUserState['communications']['loginByLdap']
>(async ({ payload: { data }, dependencies: { apolloClient } }) => {
  const currentUser = await AuthenticationService.loginByLdap(
    apolloClient,
    data
  );
  window.location.replace('/');
  return { currentUser };
});

export const logoutUser = makeThunkApiRequest(namespace, 'LOGOUT', {
  context: 'logouting',
})<Record<string, never>, void, AppError>(async () => {
  await AuthenticationService.logout();
  Sentry.setUser(null);
});

export const checkUserAuthentication = makeThunkApiRequest(
  namespace,
  'CHECK_USER_AUTHENTICATION',
  undefined
)<void, CurrentUserQuery.CurrentUser, AppError>(async ({
  dependencies: { apolloClient },
}) => {
  return await AuthenticationService.loadCurrentUser(apolloClient);
});

export const updateUsername = makeThunkApiRequest(
  namespace,
  'UPDATE_USERNAME',
  {
    context: 'updating username',
    customErrorMessageByType: {
      usernameAlreadyExists: profileErrorMessages.usernameAlreadyExists,
    },
  }
)<
  { id: string; username: string },
  string,
  IUserState['communications']['updatingUsername']
>(
  async ({ payload: { id, username } }) =>
    await AuthenticationService.updateUsername(id, username)
);

export const updateUser = createAction(
  `${namespace}/UPDATE_USER`
)<CurrentUserData>();

export const updateUserInfo = makeThunkApiRequest(
  namespace,
  'UPDATE_USER_INFO',
  undefined
)<
  {
    values: UpdateUserInfo;
    onSuccess: (u?: CurrentUserQuery.CurrentUser['self']) => void;
  },
  void,
  IUserState['communications']['updatingUserInfo']
>(async ({
  payload: { values, onSuccess },
  dispatch,
  getState,
  dependencies,
}) => {
  await AuthenticationService.updateUserInfo(values);

  const data = await unwrapAsyncActionResult(
    checkUserAuthentication(undefined)(dispatch, getState, dependencies)
  ).catch(() => undefined);

  await onSuccess(data?.self);
});
