import { head, last } from 'ramda';

import { isNotRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import isNotNil from 'shared/utils/isNotNill';

import { RestrictedErrorOrBuild } from './Build';

export const getEndpointPollingInterval = () => {
  return process.env.NODE_ENV === 'production' ? 10000 : 5000;
};

export const defaultEndpointEnvironmentName = 'production';

type EndpointWithComponents<T> = {
  environments: Array<{ components: T[] }>;
};
export const getEndpointEnvironmentComponentsWithBuilds = <
  B extends RestrictedErrorOrBuild | null,
  T extends { build: B },
>(
  endpoint: EndpointWithComponents<T>
) => {
  return getComponentsWithBuilds<B, T>(
    getEnvironment(endpoint)?.components || []
  );
};

export const getComponentsWithBuilds = <
  B extends RestrictedErrorOrBuild | null,
  T extends { build: B },
>(
  components: T[]
) => {
  return components
    .map((component) =>
      isNotNil(component.build) && isNotRestrictedGraphqlError(component.build)
        ? { ...component, build: component.build }
        : undefined
    )
    .filter(isNotNil);
};

export const makeEndpointPath = (string: string) =>
  `/${string}`.replace(/\/+/g, '/');

export const getEnvironment = <T>(endpoint: {
  environments: T[];
}): T | undefined => head(endpoint.environments);

export function getBuildWithCanaryRolloutPercentage<
  Build extends { id: string },
  RolloutStatus extends {
    components: Array<{ buildID: string; ratio: number[] } | null>;
  },
>(
  build: Build,
  rolloutStatus: RolloutStatus | null = null
): Build & { canaryRolloutPercentage: number | null } {
  const rolloutStatusComponents = rolloutStatus?.components || [];

  return {
    ...build,
    canaryRolloutPercentage:
      last(
        rolloutStatusComponents
          .filter(isNotNil)
          .find(({ buildID }) => build.id === buildID)?.ratio ?? []
      ) ?? null,
  };
}
