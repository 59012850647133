import { useMemo, useState } from 'react';

import { IAttribute } from 'shared/models/Attribute';
import * as AttributeWithCrossRunDashboardForComparing from 'features/experimentRuns/compare/model/AttributeWithCrossRunDashboardForComparing';
import * as CrossRunDashboardForComparing from 'features/experimentRuns/compare/model/CrossRunDashboardForComparing';
import { WidgetSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/WidgetSettings';
import generateId from 'shared/utils/generateId';
import usePromptUserAboutUnsavedChangesOnLivingRoute from 'shared/view/hooks/usePromptUserAboutUnsavedChangesOnLivingRoute';

import { ComparedExperimentRun } from '../../store/utils/comparedExperimentRuns';
import { useSaveDashboardChanges } from './saveDashboardChanges/useSaveDashboardChanges';

export type CrossRunDashboardForComparingProject = {
  id: string;
  attributes: IAttribute[];
  workspace: { name: string };
};

export type UseCrossRunDashboardForComparingResult = ReturnType<
  typeof useCrossRunDashboardForComparing
>;
export const useCrossRunDashboardForComparing = (props: {
  project: CrossRunDashboardForComparingProject;
  experimentRuns: Array<ComparedExperimentRun>;
}) => {
  const initialDashboard = useMemo(
    () =>
      AttributeWithCrossRunDashboardForComparing.getCrossRunDashboardForComparingOrDefault(
        props.project,
        props.experimentRuns
      ),
    [props.experimentRuns, props.project]
  );
  const [currentDashboard, setCurrentDashboard] = useState(initialDashboard);

  const isCurrentDashboardChanged = !CrossRunDashboardForComparing.equals(
    currentDashboard,
    initialDashboard
  );

  const dashboardChangesActions = useDashboardChangesActions({
    currentDashboard,
    initialDashboard,
    project: props.project,
    setCurrentDashboard,
  });

  const promptUserAboutUnsavedChangesOnLivingRoute =
    usePromptUserAboutUnsavedChangesOnLivingRoute(isCurrentDashboardChanged);

  const updateLayout = (
    layout: CrossRunDashboardForComparing.CrossRunDashboardForComparing['layout']
  ) =>
    setCurrentDashboard(
      CrossRunDashboardForComparing.updateLayout(currentDashboard, layout)
    );

  const widgetActions = useWidgetActions({
    currentDashboard,
    initialDashboard,
    project: props.project,
    setCurrentDashboard,
  });

  return {
    ...widgetActions,
    promptUserAboutUnsavedChangesOnLivingRoute,
    dashboardChangesActions,
    currentDashboard,
    updateLayout,
    experimentRuns: props.experimentRuns,
  };
};

function useWidgetActions(props: {
  currentDashboard: CrossRunDashboardForComparing.CrossRunDashboardForComparing;
  initialDashboard: CrossRunDashboardForComparing.CrossRunDashboardForComparing;
  project: { id: string };
  setCurrentDashboard(
    x: CrossRunDashboardForComparing.CrossRunDashboardForComparing
  ): void;
}) {
  const createWidget = (widgetSettings: WidgetSettings) =>
    props.setCurrentDashboard(
      CrossRunDashboardForComparing.addCustomWidget(
        props.currentDashboard,
        widgetSettings,
        generateId()
      )
    );

  const deleteWidget = (id: string) =>
    props.setCurrentDashboard(
      CrossRunDashboardForComparing.deleteWidget(props.currentDashboard, id)
    );

  const editWidget = (id: string, widgetSettings: WidgetSettings) =>
    props.setCurrentDashboard(
      CrossRunDashboardForComparing.updateCustomWidget(
        props.currentDashboard,
        id,
        widgetSettings
      )
    );

  return { createWidget, deleteWidget, editWidget };
}

function useDashboardChangesActions(props: {
  currentDashboard: CrossRunDashboardForComparing.CrossRunDashboardForComparing;
  initialDashboard: CrossRunDashboardForComparing.CrossRunDashboardForComparing;
  project: { id: string };
  setCurrentDashboard(
    x: CrossRunDashboardForComparing.CrossRunDashboardForComparing
  ): void;
}) {
  const {
    saveDashboardChanges: __saveDashboardChanges,
    savingDashboardChanges,
  } = useSaveDashboardChanges();

  return !CrossRunDashboardForComparing.equals(
    props.currentDashboard,
    props.initialDashboard
  )
    ? ({
        save: {
          savingDashboardChanges,
          saveDashboardChanges: () =>
            __saveDashboardChanges(props.currentDashboard, props.project.id),
        },
        reset: {
          resetDashboardChanges: () =>
            props.setCurrentDashboard(props.initialDashboard),
        },
      } as const)
    : undefined;
}
