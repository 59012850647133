import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import { InformationalTile } from '../../../shared/InformationalTile';
import { TryNowButton } from '../../../shared/TryNowButton';
import VulnerabilityScan from '../../../../images/vulnerability-scanning.svg';

export const ScanSampleModel = (props: WizardStepContentProps) => (
  <WizardStepContent
    {...props}
    buttons={{ next: { children: 'Complete tutorial' } }}
  >
    <Typography variant="subtitle1" mb={2}>
      Now it's your turn
    </Typography>
    <Typography variant="body2" mb={3}>
      Ready to see it in action? Give it a try!
    </Typography>
    <InformationalTile
      title="Scan a sample model to view vulnerability report"
      description={
        'Initiate a vulnerability scan on one of the sample models in your organization or visit the release page of any registered model version to initiate and view the scanning results.\n\nHeads up: The container scanning service may take up to 12 hours to return a result in the case of large models.'
      }
      image={VulnerabilityScan}
    >
      <TryNowButton routePath="modelVersionRelease" />
    </InformationalTile>
  </WizardStepContent>
);
