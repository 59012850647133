import isNotNil from './isNotNill';
import { safeParseJson } from './json';

export class StorageManager<T> {
  constructor(
    private key: string,
    private storage: Storage
  ) {
    this.key = key;
    this.storage = storage;
  }

  public read = (): T | null => {
    const json = this.storage.getItem(this.key);
    if (json) {
      const state = safeParseJson<T>(json);
      if (isNotNil(state)) return state;
    }

    return null;
  };

  public write = (state: T) => {
    const json = JSON.stringify(state);
    this.storage.setItem(this.key, json);
  };
}
