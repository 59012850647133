import { RegisteredModelVersionLockLevel } from 'generated/types';
import {
  IAllowedActionsForCheckingLockLevelsAccess,
  RegisteredModelVersionLockLevelWithoutUnknown,
} from 'shared/models/Registry/RegisteredModelVersion/LockLevel';

export interface ILockLevelForm {
  lockLevel: RegisteredModelVersionLockLevelWithoutUnknown;
}

export const getDefaultLockLevelForm = (): ILockLevelForm => ({
  lockLevel: RegisteredModelVersionLockLevel.OPEN,
});

export interface ILockLevelFormDeps {
  lockLevel: RegisteredModelVersionLockLevelWithoutUnknown;
  allowedActions: Pick<IAllowedActionsForCheckingLockLevelsAccess, 'lock'>;
}
