import { useAppBreadcrumbs } from 'features/layout/view/Layout/AppBreadcrumbs';
import { useIntegrationsPageBreadcrumb } from 'pages/authorized/SystemAdmin/IntegrationsPage/useIntegrationsPageBreadcrumb';

import { useSystemAdminBreadcrumb } from '../shared/useSystemAdminBreadcrumb';

const useIntegrationPageBreadcrumbs = () => {
  return useAppBreadcrumbs(
    useSystemAdminBreadcrumb(),
    useIntegrationsPageBreadcrumb()
  );
};

export default useIntegrationPageBreadcrumbs;
