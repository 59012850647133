import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { QueryHookOptions } from '@apollo/client';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import {
  isNotNullableRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { selectCurrentUserOrThrowError } from 'features/user';
import { UserData } from 'shared/graphql/User/graphql-types/User.generated';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { USER_OR_SERVICE_ACCOUNT_FOR_GROUP } from 'features/organizations/groups/store/useOrganizationGroups';
import { OmitStrict } from 'shared/utils/types';

import {
  OrganizationUsersQuery,
  OrganizationUsersQueryVariables,
} from './graphql-types/useCurrentOrganizationUsers.generated';

type Props = {
  onCompleted?: () => void;
  options?: OmitStrict<
    QueryHookOptions<
      Partial<OrganizationUsersQuery>,
      OrganizationUsersQueryVariables
    >,
    'variables'
  >;
};

const ORGANIZATION_USERS_QUERY = gql`
  query OrganizationUsersQuery($organizationId: ID!) {
    organizationV2(id: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        users {
          users {
            ...UserOrServiceAccountForGroup
          }
        }
      }
    }
  }
  ${USER_OR_SERVICE_ACCOUNT_FOR_GROUP}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useCurrentOrganizationUsers = (props: Props) => {
  const currentUser = useSelector(selectCurrentUserOrThrowError);
  const organizationId = useCurrentOrganizationV2();

  const [load, queryResult] = useCustomLazyQuery<
    Partial<OrganizationUsersQuery>,
    OrganizationUsersQueryVariables
  >(ORGANIZATION_USERS_QUERY, {
    ...props.options,
    onCompleted: props.onCompleted,
    variables: {
      organizationId: organizationId ?? '',
    },
  });

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert: memoizedConvert({ currentUser }),
    queryResult,
  });

  return {
    data: isNotNullableRestrictedGraphqlError(data) ? data : undefined,
    communication,
    load,
  };
};

const memoizedConvert =
  (props: { currentUser: UserData }) =>
  (res: Partial<OrganizationUsersQuery>) => {
    if (isNotNullableRestrictedGraphqlError(res.organizationV2)) {
      return res.organizationV2.users.users;
    }

    return [props.currentUser];
  };
