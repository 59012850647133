import { useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import cn from 'classnames';
import { FormControlLabel, Stack } from '@mui/material';
import pluralize from 'pluralize';

import { ComparedEntities } from 'shared/models/CompareEntities/new/comparedEntities';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import Switch from 'shared/view/elements/Switch/Switch';

// eslint-disable-next-line postcss-modules/no-unused-class
import styles from './CompareTable.module.css';
import { usePropertiesExpansions } from './usePropertiesExpansions';
import { RemovedComparedEntities } from './RemovedComparedEntities/RemovedComparedEntities';
import { useComparedEntites } from './useComparedEntities';
import Wrapper from './Wrapper';
import { PropertyDiffDescriptions } from './PropertyDiffDescriptions';
import RowDiff from './BaseComponents/RowDiff';
import ExpansionProperty from './BaseComponents/ExpansionProperty';
import { RowWithSingleColumn } from './BaseComponents/RowWithSingleColumn';

interface Props<T extends { id: string }, EntitiesDiff> {
  entities: ComparedEntities<T>;
  getRemovedEntityName(entity: T): string;
  columnEntityName: string;
  compareEntities: (entities: ComparedEntities<T>) => EntitiesDiff;
  getPropertiesDiffDescriptions: (
    entitiesDiff: EntitiesDiff
  ) => PropertyDiffDescriptions;
  children?: (comparedEntities: ComparedEntities<T>) => React.ReactNode;
  slots?: {
    RightMenu?: React.ReactNode;
  };
}

function CompareTable<T extends { id: string }, EntitiesDiff>(
  props: Props<T, EntitiesDiff>
) {
  const comparedEntitiesApi = useComparedEntites(props.entities);

  const compareEntities = props.compareEntities;
  const entitiesDiff = useMemo(
    () => compareEntities(comparedEntitiesApi.comparedEntities),
    [comparedEntitiesApi.comparedEntities, compareEntities]
  );
  const propertiesDiffDescriptions =
    props.getPropertiesDiffDescriptions(entitiesDiff);

  const propertiesExpansionsApi = usePropertiesExpansions(
    comparedEntitiesApi.comparedEntities.length,
    propertiesDiffDescriptions
  );
  const [isShowOnlyDifferences, setIsShowOnlyDifferences] = useState(true);

  const theme = useTheme();

  return (
    <>
      <Stack direction={'column'}>
        <Stack
          direction={'row'}
          alignItems="start"
          justifyContent={'space-between'}
        >
          <Stack>
            {props.slots?.RightMenu !== undefined
              ? props.slots.RightMenu
              : null}
            {comparedEntitiesApi.removedComparedEntities.length > 0 ? (
              <RemovedComparedEntities
                removedComparedEntities={comparedEntitiesApi.removedComparedEntities.map(
                  (e) => ({ id: e.id, name: props.getRemovedEntityName(e) })
                )}
                onCancelEntityRemoving={
                  comparedEntitiesApi.cancelComparedEntityRemoving
                }
              />
            ) : null}
          </Stack>
          <div style={{ width: 'max-content' }}>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <FormControlLabel
                label="Hide identical values"
                labelPlacement="start"
                control={
                  <Switch
                    name="showOnlyDifferences"
                    checked={isShowOnlyDifferences}
                    onChange={(_, v) => setIsShowOnlyDifferences(v)}
                  />
                }
              />
              <FormControlLabel
                label="Expand all"
                labelPlacement="start"
                control={
                  <Switch
                    name="showOnlyDifferences"
                    checked={
                      propertiesExpansionsApi.expandAllPropertiesButton
                        .isAllPropertiesExpanded
                    }
                    onChange={(_, v) => {
                      if (v) {
                        propertiesExpansionsApi.expandAllPropertiesButton.expandAll();
                      } else {
                        propertiesExpansionsApi.collapseAllPropertiesButton.collapseAll();
                      }
                    }}
                  />
                }
              />
            </Stack>
          </div>
        </Stack>
        <Wrapper
          comparedEntitiesCount={comparedEntitiesApi.comparedEntities.length}
        >
          {(columnWidths) => (
            <table
              className={cn(styles.table, {
                [styles.showOnlyDifference]: isShowOnlyDifferences,
              })}
              style={{ color: theme.palette.text.primary }}
            >
              <thead>
                <tr className={styles.headersRow}>
                  <th
                    style={{
                      width: columnWidths.firstColumn,
                      minWidth: columnWidths.firstColumn,
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <span>
                        {pluralize(
                          props.columnEntityName,
                          comparedEntitiesApi.comparedEntities.length
                        )}
                      </span>
                    </Stack>
                  </th>
                  {comparedEntitiesApi.comparedEntities.map(
                    (comparedEntity) => (
                      <th
                        key={comparedEntity.id}
                        style={{
                          width: columnWidths.entityColumns,
                          minWidth: columnWidths.entityColumns,
                          verticalAlign: 'middle',
                          height: 59,
                        }}
                        data-test="entity-column"
                      >
                        <div className={styles.headerContent}>
                          <span>
                            {props.columnEntityName}{' '}
                            {comparedEntity.entityIndex + 1}
                          </span>
                          {comparedEntitiesApi.removeComparedEntity && (
                            <IconAwesomeClickable
                              dataTest="remove-entity"
                              icon={ICONS.xMark}
                              onClick={comparedEntitiesApi.removeComparedEntity.bind(
                                null,
                                comparedEntity.id
                              )}
                              reversedColors={true}
                              size={'1x'}
                            />
                          )}
                        </div>
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <PropertiesDiff
                propertiesDiffDescriptions={propertiesDiffDescriptions}
                propertiesExpansionsApi={propertiesExpansionsApi}
              />
            </table>
          )}
        </Wrapper>
      </Stack>
      {props.children?.(comparedEntitiesApi.comparedEntities)}
    </>
  );
}

const PropertiesDiff = ({
  propertiesExpansionsApi,
  propertiesDiffDescriptions,
}: {
  propertiesDiffDescriptions: PropertyDiffDescriptions;
  propertiesExpansionsApi: ReturnType<typeof usePropertiesExpansions>;
}) => {
  return (
    <>
      {propertiesDiffDescriptions.map((highLevelProp, i) =>
        highLevelProp.type === 'rowDiff' ? (
          <RowDiff {...highLevelProp} key={i} />
        ) : (
          <ExpansionProperty
            {...highLevelProp}
            key={i}
            isExpanded={propertiesExpansionsApi.propertyExpansionApi.checkIsExpanded(
              highLevelProp.name
            )}
            toggleExpansion={
              propertiesExpansionsApi.propertyExpansionApi.toggle
            }
          >
            {highLevelProp.children.map((nestedProp, nestedChildIdx) =>
              nestedProp.type === 'rowDiff' ? (
                <RowDiff {...nestedProp} key={nestedChildIdx} />
              ) : (
                <ExpansionProperty
                  {...nestedProp}
                  key={nestedChildIdx}
                  isExpanded={propertiesExpansionsApi.propertyExpansionApi
                    .getNestedExpansions(highLevelProp.name)
                    .includes(nestedProp.name)}
                  toggleExpansion={() =>
                    propertiesExpansionsApi.propertyExpansionApi.toggleNestedExpansion(
                      highLevelProp.name,
                      nestedProp.name
                    )
                  }
                >
                  {nestedProp.children.map((prop) =>
                    prop.type === 'rowDiff' ? (
                      <RowDiff {...prop} key={prop.name} />
                    ) : (
                      <RowWithSingleColumn {...prop} key={prop.name} />
                    )
                  )}
                </ExpansionProperty>
              )
            )}
          </ExpansionProperty>
        )
      )}
    </>
  );
};

export default CompareTable;
