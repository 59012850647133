import { VegaLite, VisualizationSpec } from 'react-vega';
import 'github-markdown-css/github-markdown-light.css';

import { generateSpecWithNamedDataSource } from 'shared/view/domain/CrossRunDashboard/CustomWidgetView/VegaVisualization/VegaSpec';
import { ExperimentRunWithDisplayedFields } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/ExperimentRunWithDisplayedFields';
import { WidgetSettings } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/WidgetSettings';
import ParallelCoordinateChartWidget from 'shared/view/domain/CrossRunDashboard/CustomWidgetView/ParallelCoordinateChartWidget';
import TableWidget from 'shared/view/domain/CrossRunDashboard/CustomWidgetView/TableWidget';
import ObservationChartsWidget from 'shared/view/domain/CrossRunDashboard/CustomWidgetView/ObservationChartsWidget';
import MarkdownView from 'shared/view/elements/MarkdownManager/shared/Markdown/Markdown';

interface ILocalProps {
  id: string;
  widgetSettings: WidgetSettings;
  width: number;
  height: number;
  experimentRuns: ExperimentRunWithDisplayedFields[];
}

const WidgetPreview = (props: ILocalProps) => {
  if (props.widgetSettings.type === 'parallelCoordinateChart') {
    return (
      <ParallelCoordinateChartWidget
        {...props}
        widgetSettings={props.widgetSettings}
      />
    );
  }
  if (props.widgetSettings.type === 'table') {
    return <TableWidget {...props} widgetSettings={props.widgetSettings} />;
  }
  if (props.widgetSettings.type === 'markdown') {
    return (
      <div
        style={{
          width: `${props.width}px`,
          height: `${props.height}px`,
          overflow: 'auto',
        }}
      >
        <MarkdownView>{props.widgetSettings.markdown}</MarkdownView>
      </div>
    );
  }
  if (props.widgetSettings.type === 'observationCharts') {
    return <ObservationChartsWidget {...props} />;
  }

  const specWithNamedDataSource = generateSpecWithNamedDataSource(
    props.widgetSettings,
    props.experimentRuns
  );
  return (
    <VegaLite
      spec={specWithNamedDataSource.spec as VisualizationSpec}
      data={specWithNamedDataSource.data}
      width={props.width - 2} // 2 need to fit the chart in the widget wrapper. todo: fix it
      height={props.height - 2}
      actions={false}
    />
  );
};

export default WidgetPreview;
