import { ScatterPlotWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/ScatterPlotWidget';

import { MonitoringWidgetProps } from '../shared/types';
import OutlierDetectionChartWidget from './OutlierDetectionChartWidget/OutlierDetectionChartWidget';

const ScatterPlotWidgetView = (
  props: MonitoringWidgetProps<ScatterPlotWidget>
) => {
  return <OutlierDetectionChartWidget {...props} />;
};

export default ScatterPlotWidgetView;
