import { useCallback, useState } from 'react';

export type OnSetReset = (f: (() => void) | null) => void;

export const useZoomReset = () => {
  const [onReset, setOnReset] = useState<(() => void) | undefined>(undefined);

  const onSetReset: OnSetReset = useCallback(
    (f: (() => void) | null) => setOnReset(() => f),
    [setOnReset]
  );

  return { onReset, onSetReset };
};
