import { param, route } from 'shared/routes/shared/routesTreeBuilder';

const dashboards = route('dashboards', {
  tag: 'monitoringDashboards',
})(
  param()('dashboardId', {
    tag: 'monitoringDashboard',
  })()
);

export const privateRoutes = route('monitoring', {
  tag: 'monitoring',
})(
  route('create', {
    tag: 'monitoringModelCreation',
  })(),
  param()('monitoringModelId', {
    tag: 'monitoringModel',
  })(
    route('overview', {
      tag: 'monitoringOverview',
    })(),
    route('performance', {
      tag: 'monitoringPerformance',
    })(),
    dashboards,
    route('compare', {
      tag: 'monitoringCompare',
    })(),
    route('alerts', {
      tag: 'monitoringAlerts',
    })(
      route('create', {
        tag: 'monitoringAlertCreation',
      })(),
      param()('alertId', {
        tag: 'monitoringAlert',
      })(
        route('history', {
          tag: 'monitoringAlertHistory',
        })()
      )
    )
  )
);
