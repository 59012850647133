import { useState } from 'react';
import { Box, Typography } from '@mui/material';

import CardLayout from 'shared/view/elements/CardLayout/CardLayout';
import {
  EvaluationData,
  EvaluationHeaderKey,
} from 'features/evaluations/shared/utils/evaluation';

import { Evaluation } from '../../hooks/useEvaluationSummary';
import { EvaluateRow } from '../EvaluateRow/EvaluateRow';

type Props = {
  evaluation: Evaluation;
};

export const EvaluationsSlider = (props: Props) => {
  const { evaluation } = props;

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedEvaluation, setSelectedEvaluation] = useState<
    EvaluationData | undefined
  >(evaluation.rows[selectedIndex]);

  return selectedEvaluation ? (
    <CardLayout
      isEmbeded={false}
      header={{
        leftContent: (
          <Typography variant="subtitle2" mt={1.25} mb={1} ml={1}>
            {`ID ${selectedEvaluation[EvaluationHeaderKey.ID]}`}
          </Typography>
        ),
        rightContent: (
          <Typography variant="subtitle2" mr={1}>
            {`${selectedEvaluation[EvaluationHeaderKey.ID]}/${
              evaluation.rows.length
            }`}
          </Typography>
        ),
      }}
    >
      <Box px={4} pb={2} height="700px">
        <EvaluateRow
          {...evaluation}
          row={selectedEvaluation}
          currentRowIdx={selectedIndex}
          totalRows={evaluation.rows.length}
          isReadOnly
          handleMoveToRowClick={(idx) => {
            setSelectedIndex(idx);
            setSelectedEvaluation(evaluation.rows[idx]);
          }}
        />
      </Box>
    </CardLayout>
  ) : null;
};
