import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import {
  AlertAggregationWindow,
  AlertStatus,
  MonitoringMetricType,
  AlertEvaluationFrequency,
  MonitoringDriftMetricType,
} from 'generated/types';
import {
  AlertSettings,
  AlertSettingsType,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertSettings';
import { MonitoringAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import matchType from 'shared/utils/matchType';

import {
  AlertCondition,
  convertAlertConditionToAlerter,
} from './AlertCondition';

export interface MonitoringAlertFormType {
  name: string;
  status: AlertStatus;
  ioDescription: MonitoringIODescription;
  output: MonitoringIODescription;
  evaluationFrequency: AlertEvaluationFrequency;
  aggregationWindow: AlertAggregationWindow;
  type: AlertSettingsType;
  driftMetricType: MonitoringDriftMetricType;
  metricType: MonitoringMetricType;
  condition: AlertCondition;
}

export const convertAlertFormToPreviewAlert = (
  form: MonitoringAlertFormType
): MonitoringAlert => {
  const ioDescription = matchType(
    {
      drift: () => form.ioDescription,
      metric: () => form.output,
    },
    form.type
  );

  return {
    ...form,
    id: 'alert-from-form',
    alerter: convertAlertConditionToAlerter(form.condition),
    settings: matchType<AlertSettingsType, AlertSettings>(
      {
        drift: () => ({
          type: 'drift',
          driftMetricType: form.driftMetricType,
          ioDescription: form.ioDescription,
        }),
        metric: () => ({
          type: 'metric',
          metricType: form.metricType,
          output: form.output,
        }),
      },
      form.type
    ),
    status: AlertStatus.OK,
    dateCreated: new Date(),
    dateUpdated: new Date(),
    featureName: `${ioDescription.ioType.toLowerCase()}.${ioDescription.name}`,
  };
};
