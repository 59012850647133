import { Typography } from '@mui/material';

import PaperLink from 'features/catalog/registeredModelVersion/shared/view/PaperLink';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { validateUrl } from 'shared/view/elements/TextEditorManager/Editor/utils/url';

const PipelineLink = ({ modelId }: { modelId?: string }) => {
  if (!modelId) {
    return (
      <PaperLink
        icon={ICONS.rectangleVerticalHistory}
        sx={{ width: 416, p: 2, height: 74.91 }}
      >
        <Typography variant="caption" color="text.secondary">
          No model or pipeline
        </Typography>
      </PaperLink>
    );
  }

  return (
    <PaperLink icon={ICONS.rectangleVerticalHistory} sx={{ width: 416, p: 2 }}>
      {validateUrl(modelId) ? (
        <InlineLink
          onClick={(e) => e.stopPropagation()}
          isExternal
          to={modelId}
        >
          {modelId}
        </InlineLink>
      ) : (
        <Typography variant="body2">{modelId}</Typography>
      )}
      <Typography variant="caption" color="text.secondary">
        Model/Pipeline identifier
      </Typography>
    </PaperLink>
  );
};

export default PipelineLink;
