import { useState } from 'react';

import * as UsernameOrEmail from 'shared/models/UsernameOrEmail';

export interface InviteFormField {
  value: string;
  error: string | undefined;
  touched: boolean;
}

const initialUsernameOrEmailField: InviteFormField = {
  value: '',
  error: UsernameOrEmail.validate(''),
  touched: false,
};

export function useInvitePopup() {
  const [usernameOrEmailField, setUsernameOrEmailField] = useState(
    initialUsernameOrEmailField
  );

  const resetToInitial = () => {
    setUsernameOrEmailField(initialUsernameOrEmailField);
  };

  return {
    resetToInitial,
    usernameOrEmailField,
    setUsernameOrEmailField,
  };
}
