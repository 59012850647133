/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';

import { KafkaConfigurationFragment } from '../store/kafkaConfigurationFragment';
import * as GraphqlTypes from './graphql-types/useCreateKafkaConfiguration.generated';

const MUTATION = gql`
  mutation CreateKafkaConfiguration($config: KafkaConfigurationInput!) {
    kafkaConfigurations {
      id
      createKafkaConfiguration(config: $config) {
        id
        configurations {
          id
          ...KafkaConfigurationFragment
          deployments {
            endpoint {
              id
              workspace {
                id
                ...WorkspaceData
              }
            }
          }
        }
      }
    }
  }
  ${KafkaConfigurationFragment}
  ${WORKSPACE_FRAGMENT}
`;

export const useCreateKafkaConfiguration = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [createKafkaConfiguration, creatingKafkaConfiguration] =
    useCustomMutation<
      GraphqlTypes.CreateKafkaConfiguration,
      GraphqlTypes.CreateKafkaConfigurationVariables
    >(MUTATION, { context: 'creating kafka configuration' }, { onCompleted });
  return { createKafkaConfiguration, creatingKafkaConfiguration };
};
