import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useModelDocumentation } from 'features/catalog/registeredModel/documentation/hooks';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

const ModelDocumentation = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ModelDocumentation' */ 'features/catalog/registeredModel/documentation/view/documentation'
    )
);

const ModelDocumentationPage = () => {
  const { registeredModelId } = useRouteParams(routes.modelDocumentation);

  const { data, communication } = useModelDocumentation({
    id: registeredModelId,
  });

  return (
    <DefaultMatchRemoteDataOrError
      communication={communication}
      data={data}
      context="loading model"
    >
      {(model) => <ModelDocumentation model={model} />}
    </DefaultMatchRemoteDataOrError>
  );
};

export default ModelDocumentationPage;
