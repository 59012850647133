/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import * as actions from '../actions';
import { IUserState } from '../types';

const initial: IUserState['data'] = {
  user: null,
  registrationUser: null,
  organizations: null,
  organizationsV2: null,
};

const dataReducer: Reducer<IUserState['data'], ActionType<typeof actions>> = (
  state = initial,
  action
) => {
  switch (action.type) {
    case getType(actions.registerUserByEmail.success): {
      return {
        ...state,
        registrationUser: action.payload,
      };
    }

    case getType(actions.updateUser): {
      return {
        ...state,
        user: action.payload,
      };
    }
    case getType(actions.checkUserAuthentication.success): {
      const payload = action.payload;

      return {
        ...state,
        user: payload.self,
        registrationUser: payload.self,
        organizations: payload.organizations,
        organizationsV2: payload.organizationsV2.organizations,
      };
    }

    case getType(actions.updateUsername.success): {
      if (!state.user) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          username: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default dataReducer;
