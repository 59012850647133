import { Typography, Stack } from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import DeleteIconButton from 'shared/view/elements/DeleteIconButton/DeleteIconButton';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS, IconsProps } from 'shared/view/elements/IconAwesome/ICONS';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import { trackEvent } from 'setup/app/analytics';

import { StyledCategoryItem, IconWrapper } from '../styles';
import { CustomAttributeCategories } from '../../graphql';
import { CustomAttributeCategoryPopup } from '../CustomAttributeCategoryPopup/CustomAttributeCategoryPopup';
import { useDeleteCustomAttributeCategory } from '../../hooks/useDeleteCustomAttrCategory';

interface AttributeCategoryItemProps {
  category: CustomAttributeCategories[0];
  isCategoryInUse: boolean;
}

export const AttributeCategoryItem = ({
  category,
  isCategoryInUse,
}: AttributeCategoryItemProps) => {
  const icon = ICONS[category.icon as keyof IconsProps] as IconDefinition;
  const { deleteCustomAttributeCategory, communication } =
    useDeleteCustomAttributeCategory({ onCompleted: () => null });

  return (
    <StyledCategoryItem>
      <Stack direction="row" spacing={1.5} alignItems="center">
        <IconWrapper icon={icon} />
        <Typography variant="body1" color="text.primary" lineHeight="unset">
          {category.name}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={2}>
        <ButtonWithPopup
          button={(props) => (
            <IconAwesomeClickable
              {...props}
              onClick={() => {
                trackEvent({
                  type: 'organization.custom_attributes.category_edit_clicked',
                });
                props.onClick();
              }}
              size="lg"
              dataTest="edit-checklist-template"
              icon={ICONS.pencil}
            />
          )}
          popup={(props) => (
            <CustomAttributeCategoryPopup
              {...props}
              category={category}
              submitButton={{ children: 'Save changes' }}
              title="Edit attribute category"
              organizationId={category.organizationId}
              isCategoryInUse={isCategoryInUse}
            />
          )}
        />

        <DeleteIconButton
          description="attribute category"
          entityName="attribute category"
          disabled={communication.isRequesting}
          customBasicText="Please be aware that by deleting an attribute category, any model and version attributes associated with it will be listed as ”Attributes without category”."
          additionalConfirmText="Make sure that you have carefully considered the implications before deciding to proceed with the deletion."
          longPressConfirmation
          onDelete={() => {
            trackEvent({
              type: 'organization.custom_attributes.category_delete_clicked',
            });
            deleteCustomAttributeCategory({
              organizationId: category.organizationId,
              categoryId: category.id,
            });
          }}
        />
      </Stack>
    </StyledCategoryItem>
  );
};
