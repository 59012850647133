import { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import Popup from 'shared/view/elements/Popup/Popup';
import TextInput from 'shared/view/elements/TextInput/TextInput';
import { docsLinks } from 'shared/utils/globalConstants/vertaDocLinks';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import { Stage } from 'generated/types';
import { selectFlags } from 'features/flags';

import { useCreateTransition } from '../../hooks/useCreateTransition';
import CustomDesiredStageMessage from './CustomDesiredStageMessage';
import { useUpdateStage } from '../../hooks/useUpdateStage';

interface Props {
  isOpen: boolean;
  versionId: string;
  isEditable: boolean;
  onClose(): void;
  stage: Stage;
  desiredStage: Stage;
}

const TransitionRequestPopup = (props: Props) => {
  const { isOpen, onClose, isEditable, versionId, stage, desiredStage } = props;
  const [comment, setComment] = useState('');
  const {
    isEnableFreeTier,
    catalog: { isEnablePromotionApprovalRequired },
  } = useSelector(selectFlags);

  const onCancel = () => {
    setComment('');
    onClose();
  };

  const { createTransition, creatingTransition } = useCreateTransition({
    onCompleted: onCancel,
  });
  const { updateStage, updatingStage } = useUpdateStage({
    onCompleted: onCancel,
  });

  const onConfirm = () => {
    const payload = {
      desiredStage,
      comment,
      registeredModelVersionId: versionId,
    };
    if (isEnableFreeTier && !isEnablePromotionApprovalRequired) {
      updateStage(payload);
    } else {
      createTransition(payload);
    }
    setComment('');
    onClose();
  };

  const isLoading =
    creatingTransition.isRequesting || updatingStage.isRequesting;

  return (
    <Popup
      title={
        desiredStage === Stage.ARCHIVED
          ? 'Archive version'
          : `Move version to ${desiredStage.toLowerCase()}`
      }
      isOpen={isOpen}
      onClose={onCancel}
      maxWidth="xs"
      fullWidth
      dataTest="transition-request-popup"
      buttons={{
        mainButtonProps: {
          disabled: !isEditable,
          type: 'button',
          children: 'Request stage change',
          onClick: onConfirm,
          isLoading,
        },
        secondaryButtonProps: {
          children: 'Cancel',
          onClick: onCancel,
          isLoading: false,
        },
      }}
    >
      <Stack spacing={3} pt={1}>
        <CustomDesiredStageMessage stage={stage} desiredStage={desiredStage} />
        <Typography variant="body2">
          <strong>Note:</strong> After requesting a stage change, another team
          member will need to approve the request to complete the change.
          <span style={{ display: 'inline-flex', marginLeft: '4px' }}>
            <InlineLink
              simple
              variant="body2"
              to={docsLinks.registry.guides.transitionModelStages}
              isExternal
            >
              Learn more
            </InlineLink>
          </span>
        </Typography>
        <Box width="100%">
          <TextInput
            disabled={!isEditable}
            maxWidth="100%"
            hint="Max. 250 characters"
            value={comment}
            meta={{
              touched: true,
              error:
                comment.length >= 250
                  ? 'maximum character limit reached'
                  : undefined,
            }}
            label="Add comment (optional)"
            onChange={(value) => setComment(value)}
            name="comment"
          />
        </Box>
      </Stack>
    </Popup>
  );
};

export default TransitionRequestPopup;
