import { Button } from 'shared/view/elements/Button';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';

import { FineTuningModal } from './FineTuningModal/FineTuningModal';

export const FineTuningButton = () => (
  <ButtonWithPopup
    key={0}
    button={(buttonProps) => (
      <Button {...buttonProps} isLoading={false} variant="outlined">
        Fine-tune
      </Button>
    )}
    popup={(popupProps) => <FineTuningModal {...popupProps} />}
  />
);
