import { DefaultOptions } from '@apollo/client';
import { InMemoryCache, TypePolicies } from '@apollo/client/cache';

import { StrictTypedTypePolicies } from 'generated/apollo-helpers';
import generatedIntrospection from 'generated/fragmentTypes.json';

export const makeCache = () => {
  const possibleTypes = generatedIntrospection.possibleTypes as Record<
    string,
    string[]
  >;

  const typePolicies: StrictTypedTypePolicies = {};

  return new InMemoryCache({
    possibleTypes,
    typePolicies: typePolicies as TypePolicies,
  });
};

export const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
};
