import { FieldArray } from 'formik';

import TagsManager from 'shared/view/domain/Labels/TagsManager/TagsManager';
import FieldWithTopLabel from 'shared/view/elements/FieldWithTopLabel/FieldWithTopLabel';

interface Props {
  name: string;
}

const TagsField = (props: Props) => {
  return (
    <FieldArray name={props.name}>
      {(arrayHelpers) => (
        <FieldWithTopLabel label="Labels">
          <TagsManager
            tags={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              arrayHelpers.form.values[arrayHelpers.name] || []
            }
            isEditable={true}
            renderPlaceholder={false}
            isUpdating={false}
            onAddTag={(tag) => arrayHelpers.push(tag)}
            onRemoveTag={(deletedTag) => {
              arrayHelpers.remove(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                arrayHelpers.form.values[arrayHelpers.name].findIndex(
                  (tag: string) => tag === deletedTag
                )
              );
            }}
          />
        </FieldWithTopLabel>
      )}
    </FieldArray>
  );
};

export default TagsField;
