import { MonitoringPanel } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringPanel';
import matchBy from 'shared/utils/matchBy';

import CustomMonitoringPanelView from './CustomMonitoringPanelView/CustomMonitoringPanelView';
import DriftMonitoringPanelView from './DriftMonitoringPanelView/DriftMonitoringPanelView';
import OutliersMonitoringPanelView from './OutliersMonitoringPanelView/OutliersMonitoringPanelView';
import { MonitoringPanelProps } from './shared/MonitoringPanelProps';

const MonitoringPanelView = (props: MonitoringPanelProps<MonitoringPanel>) => {
  return matchBy(
    props.panel,
    'type'
  )({
    custom: (panel) => <CustomMonitoringPanelView {...props} panel={panel} />,
    drift: (panel) => <DriftMonitoringPanelView {...props} panel={panel} />,
    outliers: (panel) => (
      <OutliersMonitoringPanelView {...props} panel={panel} />
    ),
  });
};

export default MonitoringPanelView;
