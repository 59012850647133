import { getArrayItem } from 'shared/utils/collection';
import { Result } from 'shared/utils/result';
import {
  BoxPlotXAxisDataSpec,
  BoxPlotYAxisDataSpec,
  BoxPlotAxisesDataSpec,
  BoxPlotSettings,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/BoxPlotSettings';
import { emptyCustomDomain } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/shared/CustomDomain';
import { OmitStrict } from 'shared/utils/types';

export type BoxPlotSettingsForm = {
  name: string;
  type: 'boxPlot';
  xAxis: OmitStrict<BoxPlotSettings['xAxis'], 'spec'> & {
    spec?: BoxPlotXAxisDataSpec;
  };
  yAxis: OmitStrict<BoxPlotSettings['yAxis'], 'spec'> & {
    spec?: BoxPlotYAxisDataSpec;
  };
};

export const getInitialBoxPlotSettingsForm = (
  name: string,
  axisesSpec: BoxPlotAxisesDataSpec
): BoxPlotSettingsForm => {
  const xAxisSpec = axisesSpec.xAxis.dateUpdated;
  const yAxisSpec = getArrayItem(0, axisesSpec.yAxis);

  return {
    type: 'boxPlot',
    name,
    xAxis: {
      spec: xAxisSpec,
      isLogScale: false,
      domain: emptyCustomDomain,
    },
    yAxis: {
      spec: yAxisSpec,
      isLogScale: false,
      domain: emptyCustomDomain,
    },
  };
};

export const validateBoxPlotSettingsForm = (
  form: BoxPlotSettingsForm
): Result<BoxPlotSettings, string> => {
  if (form.xAxis.spec && form.yAxis.spec) {
    return {
      type: 'success',
      data: {
        name: form.name,
        type: 'boxPlot',
        xAxis: {
          spec: form.xAxis.spec,
          isLogScale: form.xAxis.isLogScale,
          domain: form.xAxis.domain,
        },
        yAxis: {
          spec: form.yAxis.spec,
          isLogScale: form.yAxis.isLogScale,
          domain: form.yAxis.domain,
        },
      },
    };
  }
  return {
    type: 'error',
    error: 'Select a metric to visualize in this box plot.',
  };
};
