import { Grid, Stack, Typography, Box } from '@mui/material';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { flatten } from 'ramda';

import { selectFlags } from 'features/flags';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';
import {
  selectErrorReporterModalData,
  selectRequestResponses,
} from 'features/errorReporter/store/selectors';
import { closeErrorReporterModal } from 'features/errorReporter/store/actions';
import { selectCurrentUser } from 'features/user';
import { obfuscateRequestResponses } from 'features/errorReporter/requestResponse/RequestResponse';
import { ErrorReport } from 'features/errorReporter/ErrorReport';
import { ErrorWithInfo } from 'features/errorReporter';
import isNotNil from 'shared/utils/isNotNill';
import { formatDateWithTime } from 'shared/utils/formatters/dateTime';
import Popup from 'shared/view/elements/Popup/Popup';
import TextInput from 'shared/view/elements/TextInput/TextInput';

const ErrorReporterModal: FC<React.PropsWithChildren<unknown>> = () => {
  const { isOpened, errorWithInfo } = useSelector(selectErrorReporterModalData);
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeErrorReporterModal());

  const [description, setDescription] = useState('');
  const errorReportingFlags = useSelector(selectFlags).errorReporting;

  const createErrorReport = useCreateErrorReport({
    description,
    errorWithInfo,
    ...errorReportingFlags,
  });

  return (
    <Popup
      title="Report an error"
      isOpen={isOpened}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      buttons={{
        mainButtonProps: {
          type: 'copy',
          copyText: createErrorReport,
        },
        secondaryButtonProps: {
          dataTest: 'close-popup-button',
          children: 'Cancel',
          onClick: onClose,
          isLoading: false,
        },
      }}
    >
      <Stack spacing={3}>
        <Box>
          <Typography variant="body2">
            1. Describe actions that caused the error
          </Typography>
          <Typography variant="body2">
            2. Click button to copy error log and your description
          </Typography>
          <Typography variant="body2">
            3. Paste your report in the Verta support channel
          </Typography>
        </Box>
        <TextInput
          maxWidth="100%"
          rows={5}
          hint="Max. 250 characters"
          value={description}
          multiline
          meta={{
            touched: true,
            error:
              description.length >= 250
                ? 'maximum character limit reached'
                : undefined,
          }}
          label="Description"
          onChange={(value) => setDescription(value)}
          name="description-input"
          data-test="description-input"
        />

        <Grid container alignItems="center">
          {errorReportingFlags.supportChannelLink ? (
            <Grid item>
              <InlineLink
                isExternal={true}
                to={errorReportingFlags.supportChannelLink}
              >
                Support channel
              </InlineLink>
            </Grid>
          ) : null}
        </Grid>
      </Stack>
    </Popup>
  );
};

const useCreateErrorReport = ({
  description,
  errorWithInfo,
  isEnableAttachingRequestResponses,
  isEnableObfuscation,
}: {
  description: string;
  errorWithInfo: ErrorWithInfo | undefined;
  isEnableAttachingRequestResponses: boolean;
  isEnableObfuscation: boolean;
}) => {
  const requestResponses = useSelector(selectRequestResponses);
  const currentUser = useSelector(selectCurrentUser);
  const { pathname } = useLocation();

  return () => {
    const reportedRequestResponses = (() => {
      if (isEnableAttachingRequestResponses) {
        const reqRes = errorWithInfo?.requestResponses || requestResponses;
        return isEnableObfuscation ? obfuscateRequestResponses(reqRes) : reqRes;
      }
      return undefined;
    })();

    return generateStringErrorReport({
      title: 'Verta webapp error report',
      fields: [
        description ? { name: 'Description', value: description } : null,
        {
          name: 'Date',
          value: formatDateWithTime(errorWithInfo?.createdAt || new Date()),
        },
      ].filter(isNotNil),
      errorReport: {
        error: errorWithInfo
          ? {
              name: errorWithInfo.error.name,
              message: errorWithInfo.error.message,
              stack: errorWithInfo.error.stack,
            }
          : undefined,
        pathname: errorWithInfo?.pathname || pathname,
        username: currentUser?.username,
        description,
        requestResponses: reportedRequestResponses,
        commitSha: String(process.env.COMMIT_SHA),
      },
    });
  };
};

const generateStringErrorReport = ({
  title,
  fields,
  errorReport,
}: {
  title: string;
  fields: Array<{ name: string; value: string }>;
  errorReport: ErrorReport;
}) => {
  return flatten([
    title,
    Array.from({ length: title.length })
      .map(() => '-')
      .join(''),
    fields.map(({ name, value }) => `${name}: ${value}`),
    '---BEGIN ERROR REPORT---',
    btoa(JSON.stringify(errorReport)),
    '---END ERROR REPORT---',
  ]).join('\n');
};

export default ErrorReporterModal;
