import { FC, useMemo } from 'react';
import { TypographyProps, useTheme } from '@mui/material';
import { path, isEmpty, isNil } from 'ramda';

import { IconAwesomeProps } from 'shared/view/elements/IconAwesome/IconAwesomeProps';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import {
  InfoColorType,
  useIconAwesomeColorPalette,
} from 'shared/view/elements/IconAwesome/hooks/useIconAwesomeColorPalette';

type InfoProps = {
  infoType?: InfoColorType;
  spin?: boolean;
  beatFade?: boolean;
  reversedColors?: boolean;
  color?: TypographyProps['color'];
};

type IconAwesomeInfoProps = IconAwesomeProps & InfoProps;

// todo create folder 'info' and move this file there
// todo after removing Icon components rename this one into IconInfo
export const IconAwesomeInfo: FC<
  React.PropsWithChildren<IconAwesomeInfoProps>
> = (props) => {
  return (
    <IconAwesomeCustomizable
      {...props}
      nativeProps={{
        color: useIconInfoColor(props),
        spin: props.spin,
        beatFade: props.beatFade,
      }}
    />
  );
};

function useIconInfoColor({ reversedColors, infoType, color }: InfoProps) {
  const theme = useTheme();

  const { colors } = useIconAwesomeColorPalette({
    reversedColors,
  });

  return useMemo(() => {
    let finalColor = infoType ? colors.info[infoType] : colors.info.default;
    if (typeof color === 'string') {
      if (color === 'inherit') return color;
      const themeColor = path(color.split('.'))(theme.palette);
      if (!isEmpty(themeColor) && !isNil(themeColor))
        finalColor = themeColor as string;
    }
    return finalColor;
  }, [color, colors.info, infoType, theme.palette]);
}
