import { equals } from 'ramda';

import isNotNil from 'shared/utils/isNotNill';
import {
  IGroupedOptions,
  IOptionType,
} from 'shared/view/elements/Selects/shared/types';

export const getSelectedOptionsFromGroupedOptions = <T>(
  values: T[],
  groupedOptions: IGroupedOptions<T>
): Array<IOptionType<T>> => {
  const options = groupedOptions.groups.flatMap((x) => x.options);
  return values
    .map((value) => options.find((opt) => equals(opt.value, value)))
    .filter(isNotNil);
};
