import { useMemo } from 'react';
import moment from 'moment';
import { Grid, Typography, Stack, StackProps } from '@mui/material';
import { isEmpty } from 'ramda';

import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { formatDateWithTime } from 'shared/utils/formatters/dateTime';
import { getNewOwnerDisplayedName } from 'shared/models/Owner';
import { RightPartFields } from 'shared/view/elements/SummaryViewComponents/SummaryGrid/RightPartFields';
import { SummaryTextRecordInfo } from 'shared/view/elements/SummaryViewComponents/SummaryGrid/SummaryGrid';

import LabelsManager from './LabelsManager';
import { Evaluation } from '../../hooks/useEvaluationSummary';

type Props = StackProps &
  Pick<
    Evaluation,
    | 'id'
    | 'description'
    | 'labels'
    | 'owner'
    | 'dateCreated'
    | 'dateUpdated'
    | 'registeredModel'
    | 'rows'
  > & {
    additionalFields?: JSX.Element[];
  };

const InfoStack: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    id,
    description,
    labels,
    owner,
    dateCreated,
    dateUpdated,
    registeredModel: { allowedActions },
    additionalFields = [],
    rows,
    ...other
  } = props;

  const createdAt = useMemo(
    () => formatDateWithTime(dateCreated),
    [dateCreated]
  );

  const updatedAt = useMemo(() => moment(dateUpdated).fromNow(), [dateUpdated]);

  return (
    <Stack spacing={2} {...other}>
      <Stack spacing={1}>
        <Typography variant="subtitle2" color="text.secondary">
          Evaluation description
        </Typography>
        <Typography
          variant="body2"
          color={isEmpty(description) ? 'text.disabled' : 'text.primary'}
        >
          {isEmpty(description) ? 'No description.' : description}
        </Typography>
      </Stack>
      <Grid container>
        <RightPartFields
          withMargin={false}
          spacing={1}
          fields={[
            <SummaryTextRecordInfo
              key="owner-0"
              icon={ICONS.user}
              label="Owner"
              textVariant="caption"
            >
              {getNewOwnerDisplayedName(owner)}
            </SummaryTextRecordInfo>,
            <SummaryTextRecordInfo
              key="created-1"
              icon={ICONS.clock}
              label="Created at"
              textVariant="caption"
            >
              {createdAt}
            </SummaryTextRecordInfo>,
            <SummaryTextRecordInfo
              key="updated-2"
              icon={ICONS.arrowsRotate}
              label="Updated at"
              textVariant="caption"
            >
              {updatedAt}
            </SummaryTextRecordInfo>,

            ...additionalFields,
          ]}
        />
      </Grid>
      <Stack spacing={2} pt={1}>
        <Typography variant="subtitle2" color="text.secondary">
          Evaluation labels
        </Typography>
        <LabelsManager
          id={id}
          labels={labels}
          allowedActions={allowedActions}
          rows={rows}
        />
      </Stack>
    </Stack>
  );
};

export default InfoStack;
