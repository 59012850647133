/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

export const CUSTOM_DASHBOARD_SUMMARIES_CHARTS = gql`
  query CustomDashboardSummariesCharts($orgId: ID!) {
    organizationV2(id: $orgId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        customDashboard {
          ... on CustomDashboard {
            name
            summaries {
              name
              value
            }
            charts {
              name
              chartValues {
                name
                value
              }
            }
            __typename
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const CUSTOM_DASHBOARD_TOP_MODELS = gql`
  query CustomDashboardTopModels($orgId: ID!, $sortBy: String!) {
    organizationV2(id: $orgId) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        customDashboardTopModels(sortBy: $sortBy) {
          ... on CustomDashboardTopModels {
            models {
              ... on RegisteredModel {
                id
                name
                workspace {
                  id
                  name
                }
                customAttributeValues {
                  id
                  customAttributeDefinitionId
                  targetId
                  selectedValue
                }
              }
              ... on Error {
                ...ErrorData
              }
            }
          }
          ... on Error {
            ...ErrorData
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;
