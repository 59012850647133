import { useTheme, Typography, CardActionArea } from '@mui/material';

import { Button } from 'shared/view/elements/Button';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import {
  docsLinks,
  pythonDocsLinks,
} from 'shared/utils/globalConstants/vertaDocLinks';
import { trackEvent } from 'setup/app/analytics';

import {
  CARD_DOCUMENTATION_TYPE,
  documentationType,
  enumCardsDocumentationTypes,
} from './values/DocumentationCardsType';
import {
  CardContent,
  CardsList,
  CardType,
  SeeMoreCard,
  WrapperCard,
} from './styled-components';

type CardProps = {
  type: enumCardsDocumentationTypes;
  title: string;
  body?: string;
  link: string;
  key: string;
};

const docs = {
  [documentationType.CATALOG]: {
    link: docsLinks.registry.guides.home,
    title: 'Learn more about catalog',
    icon: ICONS.rectangleVerticalHistory,
  },
  [documentationType.VERTA]: {
    link: pythonDocsLinks,
    title: 'Python client documentation',
    icon: ICONS.endpoints,
  },
};

const goToDocs = (link: string) => {
  return window.open(link, '_blank', 'noopener');
};

const CardDocumentation = (props: { card: CardProps }) => {
  const { card } = props;
  return (
    <WrapperCard>
      <CardActionArea
        onClick={() => {
          trackEvent({
            type: 'homepage.documentation_widget.link_clicked',
            data: { link: card.link },
          });
          goToDocs(card.link);
        }}
      >
        <CardContent>
          <CardType
            spacing={1}
            direction="row"
            sx={{ color: 'text.secondary' }}
          >
            <IconAwesomeCustomizable
              icon={CARD_DOCUMENTATION_TYPE[card.type].icon}
              size="lg"
            />
            <Typography variant="overline">
              {CARD_DOCUMENTATION_TYPE[card.type].label}
            </Typography>
          </CardType>
          <Typography
            variant="subtitle1"
            mt={0}
            lineHeight="27px"
            color="CaptionText"
          >
            {card.title}
          </Typography>
          <Typography mt={1} variant="body2">
            {card.body}
          </Typography>
        </CardContent>
      </CardActionArea>
    </WrapperCard>
  );
};

export const CardsDocumentation = (props: {
  type: documentationType;
  cards: Array<CardProps>;
}) => {
  const theme = useTheme();
  const { type, cards } = props;
  return (
    <>
      <Typography variant="subtitle1" color="text.secondary">
        More about {type}
      </Typography>
      <CardsList spacing={2} direction="row">
        {cards.map((card) => (
          <CardDocumentation card={card} key={card.key} />
        ))}
        <SeeMoreCard spacing={2}>
          <IconAwesomeCustomizable
            icon={docs[type].icon}
            size="2x"
            nativeProps={{ color: theme.palette.additionalBackground.inactive }}
          />
          <Button
            size="small"
            isLoading={false}
            variant="outlined"
            onClick={() => window.open(docs[type].link, '_blank', 'noopener')}
          >
            {docs[type].title}
          </Button>
          <Typography variant="subtitle2"></Typography>
        </SeeMoreCard>
      </CardsList>
    </>
  );
};
