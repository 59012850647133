import { Box, BoxProps, alpha, styled } from '@mui/material';
import { path } from 'ramda';

interface DotProps extends BoxProps {
  outlined?: boolean;
  color?: string;
  size?: 'medium' | 'default' | 'small';
  disabled?: boolean;
}

const SIZES = {
  medium: '14px',
  default: '12px',
  small: '10px',
};

const Dot = styled((props: DotProps) => <Box {...props} />)((props) => {
  const { size, outlined, theme, color, disabled } = props;

  let dotColor = 'currentColor';
  let diameter = '12px';

  if (color) {
    dotColor = path(color.split('.'), theme.palette) ?? color;
  }

  if (disabled) {
    dotColor = alpha(dotColor, 0.5);
  }

  if (size) {
    diameter = SIZES[size];
  }

  return {
    height: diameter,
    width: diameter,
    borderRadius: '50%',
    borderColor: dotColor,
    backgroundColor: outlined ? 'transparent' : dotColor,
    borderWidth: outlined ? '2px' : 'none',
    borderStyle: outlined ? 'solid' : 'none',
    boxSizing: 'border-box',
  };
});

export default Dot;
