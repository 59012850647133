import Stack from '@mui/material/Stack';
import { sortBy } from 'ramda';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import Accordion from 'shared/view/elements/Accordion/Accordion';
import Button from 'shared/view/elements/Button/Button';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import DefaultConfirmAction, {
  ConfirmType,
} from 'shared/view/elements/ConfirmAction/DefaultConfirmAction';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import useOrganizationRoles, {
  Role,
  EnabledResourceActions,
} from 'features/organizations/roles/store/useOrganizationRoles';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { useDeleteRole } from 'features/organizations/roles/store/useDeleteRole';
import CreateRolePopup from 'features/organizations/roles/view/CreateRolePopup';
import EditRolePopup from 'features/organizations/roles/view/EditRolePopup';
import { trackEvent } from 'setup/app/analytics';

import OrganizationLayout from '../shared/OrganizationLayout';
import OrganizationRolesTable from '../../../../features/organizations/roles/view/OrganizationRolesTable';

const OrganizationRolesPage = () => {
  const params = useRouteParams(routes.organizationRolesAndPermissions);
  const rolesApi = useOrganizationRoles(params.name);
  const deleteRoleApi = useDeleteRole();

  return (
    <OrganizationLayout
      pageEntityOrError={undefined}
      actions={(() => {
        const orgInfo =
          rolesApi.communicationWithData.data?.__typename === 'OrganizationV2'
            ? {
                organizationId: rolesApi.communicationWithData.data.id,
                enabledResourceActions:
                  rolesApi.communicationWithData.data.enabledResourceActions,
              }
            : undefined;
        return orgInfo
          ? [
              <ButtonWithPopup
                key={0}
                button={(props) => (
                  <Button
                    {...props}
                    onClick={() => {
                      trackEvent({ type: 'organization.roles.create_clicked' });
                      props.onClick();
                    }}
                    dataTest="open-new-role-popup"
                  >
                    Create new role
                  </Button>
                )}
                popup={(props) => <CreateRolePopup {...props} {...orgInfo} />}
              />,
            ]
          : [];
      })()}
    >
      <DefaultMatchRemoteDataOrError
        {...rolesApi.communicationWithData}
        context="loading roles and permissions"
      >
        {(loadedData) => (
          <Stack direction="column">
            {sortBy((r) => !r.builtIn, loadedData.roles).map((role) => (
              <Accordion
                key={role.id}
                title={`${role.name} ${role.builtIn ? '(Locked)' : ''}`}
                subtitle={role.description}
                summaryRightContent={
                  <Stack direction="row">
                    <EditRolePopupButton
                      role={role}
                      enabledResourceActions={loadedData.enabledResourceActions}
                    />
                    <DefaultConfirmAction
                      description="role"
                      type={ConfirmType.delete}
                      entityName={role.name}
                    >
                      {(withConfirm) => (
                        <IconAwesomeClickable
                          icon={ICONS.trashCan}
                          dataTest="delete-button"
                          disabled={
                            !role.allowedActions.delete ||
                            deleteRoleApi.communication.isRequesting
                          }
                          onClick={withConfirm(() => {
                            trackEvent({
                              type: 'organization.roles.delete_clicked',
                            });
                            deleteRoleApi.run({
                              organizationId: role.orgId,
                              roleId: role.id,
                            });
                          })}
                        />
                      )}
                    </DefaultConfirmAction>
                  </Stack>
                }
              >
                <OrganizationRolesTable
                  data={role}
                  enabledResourceActions={loadedData.enabledResourceActions}
                />
              </Accordion>
            ))}
          </Stack>
        )}
      </DefaultMatchRemoteDataOrError>
    </OrganizationLayout>
  );
};

const EditRolePopupButton = ({
  role,
  enabledResourceActions,
}: {
  role: Role;
  enabledResourceActions: EnabledResourceActions;
}) => {
  const api = usePopupManager();
  return (
    <>
      {api.isPopupOpen ? (
        <EditRolePopup
          enabledResourceActions={enabledResourceActions}
          role={role}
          onClose={api.closePopup}
        />
      ) : null}
      <IconAwesomeClickable
        disabled={!role.allowedActions.update}
        dataTest="edit-button"
        icon={ICONS.pencil}
        onClick={() => {
          trackEvent({ type: 'organization.roles.edit_clicked' });
          api.openPopup();
        }}
      />
    </>
  );
};

export default OrganizationRolesPage;
