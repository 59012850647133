import { Box, Stack, Typography } from '@mui/material';

import {
  useBreadcrumbItem,
  useAppBreadcrumbs,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import routes from 'shared/routes';
import { ICommunication } from 'shared/utils/redux/communication';
import { useToastCommunicationErrorIgnoringNotFound } from 'shared/view/elements/Notification/Notification';
import { useEndpointsPageBreadcrumb } from 'pages/authorized/EndpointsPages/useEndpointsPageBreadcrumbs';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
import { EndpointWithKafkaConfigurations } from 'features/deployment/canary/shared/EndpointWithKafkaConfigurations';
import isNotNil from 'shared/utils/isNotNill';
import {
  isNotNullableRestrictedGraphqlError,
  RestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { RequiredOwner } from 'features/editResourceInformation/store/useEditResourceInformation';
import CopyButton from 'shared/view/elements/CopyButton/CopyButton';
import { getEnvironment } from 'shared/models/Deployment/canary/Endpoint';
import { EndpointEnvironmentStatus, KafkaConfigStatus } from 'generated/types';
import Chip from 'shared/view/elements/Chip/Chip';
import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

import EndpointPageTabs from '../EndpointPageTabs/EndpointPageTabs';

interface Props {
  endpointId: string;
  status: EndpointEnvironmentStatus | undefined;
  kafkaStatus?: KafkaConfigStatus | null;
}

const chipColor = (status: EndpointEnvironmentStatus) => {
  switch (status) {
    case EndpointEnvironmentStatus.ERROR:
      return 'error';
    case EndpointEnvironmentStatus.ACTIVE:
      return 'success';
    case EndpointEnvironmentStatus.UNKNOWN:
    case EndpointEnvironmentStatus.INACTIVE:
      return 'warning';
    case EndpointEnvironmentStatus.CREATING:
    case EndpointEnvironmentStatus.UPDATING:
    default:
      return 'info';
  }
};

const EndpointTopEnhancer = (props: Props) => {
  const { status, endpointId, kafkaStatus } = props;
  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="overline" color="text.secondary">
        {endpointId}
      </Typography>
      {status ? (
        <Chip label={status} color={chipColor(status)} size="small" />
      ) : null}
      {isNotNil(kafkaStatus) && kafkaStatus !== KafkaConfigStatus.INACTIVE ? (
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconAwesomeCustomizable icon={ICONS.kafka} size="lg" />
          <Typography variant="overline" color="text.secondary">
            KAFKA ACTIVE
          </Typography>
        </Stack>
      ) : null}
    </Stack>
  );
};

function EndpointDetailsLayout<
  E extends EndpointWithKafkaConfigurations<{
    __typename: 'Endpoint';
    id: string;
    path: string;
    allowedActions: { delete: boolean; update: boolean };
    description: string;
    owner: RequiredOwner;
    environments: Array<{
      id: string;
      status?: EndpointEnvironmentStatus;
      kafka?: {
        configId?: string | null;
        status?: KafkaConfigStatus | null;
      } | null;
    }>;
  }>,
>({
  data,
  endpointId,
  communication,
  children,
  actions,
}: {
  data: E | RestrictedGraphqlError | undefined | null;
  endpointId: string;
  communication: ICommunication;
  children: (endpoint: E) => JSX.Element;
  actions?: JSX.Element[] | null;
}) {
  const toastCommunicationErrorIgnoringNotFound =
    useToastCommunicationErrorIgnoringNotFound();
  // todo probably workaround, need to remove?
  if (communication.error) {
    toastCommunicationErrorIgnoringNotFound(communication.error, {
      toastId: 'endpointError',
      context: 'loading endpoint',
    });
  }

  const topEnhancer =
    isNotNullableRestrictedGraphqlError(data) && isNotNil(data.endpoint) ? (
      <EndpointTopEnhancer
        endpointId={endpointId}
        status={getEnvironment(data.endpoint)?.status}
        kafkaStatus={getEnvironment(data.endpoint)?.kafka?.status}
      />
    ) : undefined;

  const breadcrumbs = useAppBreadcrumbs(
    useEndpointsPageBreadcrumb(),
    useBreadcrumbItem({
      route: routes.endpointOverview,
      hasTopEnhancer: true,
      ...(isNotNullableRestrictedGraphqlError(data) && isNotNil(data.endpoint)
        ? { name: data.endpoint.path, topEnhancer }
        : { isLoading: true }),
    })
  );

  return (
    <AuthorizedLayout
      actions={actions}
      pageEntityOrError={data}
      breadcrumbs={breadcrumbs}
      tabsElement={
        <EndpointPageTabs
          endpointId={endpointId}
          endpoint={
            isNotNullableRestrictedGraphqlError(data) ? data.endpoint : null
          }
        />
      }
      titleAdditionalContent={
        isNotNullableRestrictedGraphqlError(data) ? (
          <Box ml={1}>
            <CopyButton copyText={data.endpoint.path} size="lg" />
          </Box>
        ) : null
      }
    >
      <DefaultMatchRemoteDataOrError
        communication={communication}
        data={data}
        context={'loading endpoint'}
      >
        {(loadedData) => <>{children(loadedData)}</>}
      </DefaultMatchRemoteDataOrError>
    </AuthorizedLayout>
  );
}

export default EndpointDetailsLayout;
