import Stack from '@mui/material/Stack';
import { FC } from 'react';

import Button from 'shared/view/elements/Button/Button';
import { Token } from 'shared/models/Deployment/canary/Token';

import { useEnableTokens } from '../../../store/enableTokens/enableTokens';

interface Props {
  tokensEnabled: boolean;
  environmentId: string;
  endpointId: string;
  workspaceName: string;
  organizationId: string | undefined;
  hasAccess: boolean;
  tokens: Token[];
}

const EnableTokensControl: FC<React.PropsWithChildren<Props>> = (props) => {
  const { enableTokens, enablingTokens } = useEnableTokens();

  const onClick = () => {
    enableTokens(!props.tokensEnabled, {
      ...props,
    });
  };

  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Button
        isLoading={enablingTokens.isRequesting}
        onClick={onClick}
        disabled={!props.hasAccess}
      >
        {props.tokensEnabled ? 'Disable' : 'Enable'}
      </Button>

      <span>
        Access tokens are {props.tokensEnabled ? 'enabled' : 'disabled'}
      </span>
    </Stack>
  );
};

export default EnableTokensControl;
