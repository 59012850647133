import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { ACTIVITY_FRAGMENT } from 'shared/graphql/registry/stage/Activity';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { CUSTOM_ATTRIBUTE_VALUE_FRAGMENT } from 'shared/graphql/CustomAttribute/CustomAttributeValue';

const MODEL_BASIC_FRAGMENT = gql`
  fragment ModelBasicData on RegisteredModel {
    id
    name
    description
    taskType
    dataType
    dateUpdated
    pii
    versions {
      versions {
        id
        version
        stage
      }
    }
  }
`;

export const MODEL_LAYOUT = gql`
  query ModelLayoutCases($modelId: ID!) {
    registeredModel(id: $modelId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        ...ModelBasicData
        owner {
          ...OwnerData
        }
        allowedActions {
          ...AllowedActionsData
        }
        activities {
          id
          ...ActivityFragment
        }
        customAttributeValues {
          id
          ...CustomAttributeValueData
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${MODEL_BASIC_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
  ${OWNER_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${CUSTOM_ATTRIBUTE_VALUE_FRAGMENT}
`;
