/* eslint-disable @typescript-eslint/ban-types */
import { mergeAll } from 'ramda';

import { RecordValues } from 'shared/utils/types';

import { Route } from './routesTreeBuilder';
import { routesTree } from '../routes';

type RoutesByTagsUnion = GetRoutesByTagsHelper<typeof routesTree, never>;
type RoutesByTags = {
  [T in RoutesByTagsUnion as NonNullable<T['tag']>]: T['route'];
};

type GetRoutesByTagsHelper<
  R extends Route<any, string, any, any, any>,
  RoutesAccum,
> = {} extends R['$']
  ? RoutesAccum | { tag: R['tag']; route: R }
  : RecordValues<{
      [K in keyof R['$']]: GetRoutesByTagsHelper<
        R['$'][K],
        RoutesAccum | { tag: R['tag']; route: R }
      >;
    }>;

export const getRoutesByTags = (
  root: Route<any, any, any, any, Record<string, any>>
): RoutesByTags => {
  return {
    [root.tag]: root,
    ...mergeAll(Object.values(root.$).map((r) => getRoutesByTags(r))),
  } as RoutesByTags;
};
