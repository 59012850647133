import React, { useCallback } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';

import { ChartMargin } from 'shared/utils/charts/chartSizes';
import isNotNil from 'shared/utils/isNotNill';

import { BarSettings } from './getBarSettings';
import CustomTooltipWithBounds from './CustomTooltipWithBound/CustomTooltipWithBound';

export function useBarTooltip<TooltipData>({
  renderTooltipContent,
  margin,
}: {
  renderTooltipContent: (data: TooltipData) => React.ReactElement;
  margin: ChartMargin;
}) {
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  const makeHandlers = useCallback(
    (d: TooltipData) => {
      const onMouseLeave = () => hideTooltip();

      const onMouseMove = (e: React.MouseEvent, bar: BarSettings) => {
        hideTooltip();
        const top = (localPoint(e)?.y ?? 0) + margin.top;
        const left = bar.x + bar.width / 2;
        showTooltip({
          tooltipData: d,
          tooltipTop: top,
          tooltipLeft: left,
        });
      };

      return {
        onMouseLeave,
        onMouseMove,
      };
    },
    [margin.top, hideTooltip, showTooltip]
  );

  const tooltip = (
    <>
      {tooltipOpen && isNotNil(tooltipData) && (
        <CustomTooltipWithBounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          {renderTooltipContent(tooltipData)}
        </CustomTooltipWithBounds>
      )}
    </>
  );

  return {
    makeHandlers,
    tooltip,
  };
}

export function useHorizontalBarTooltip<TooltipData>({
  renderTooltipContent,
}: {
  renderTooltipContent: (data: TooltipData) => React.ReactElement;
}) {
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  const makeHandlers = useCallback(
    (d: TooltipData) => {
      const onMouseLeave = () => hideTooltip();

      const onMouseMove = (e: React.MouseEvent) => {
        hideTooltip();
        const top = (localPoint(e)?.y ?? 0) + 10;
        const left = localPoint(e)?.x ?? 0;
        showTooltip({
          tooltipData: d,
          tooltipTop: top,
          tooltipLeft: left,
        });
      };

      return {
        onMouseLeave,
        onMouseMove,
      };
    },
    [hideTooltip, showTooltip]
  );

  const tooltip = (
    <>
      {tooltipOpen && isNotNil(tooltipData) && (
        <CustomTooltipWithBounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          {renderTooltipContent(tooltipData)}
        </CustomTooltipWithBounds>
      )}
    </>
  );

  return {
    makeHandlers,
    tooltip,
  };
}
