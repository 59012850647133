import { useSelector } from 'react-redux';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { isNotNullableRestrictedGraphqlError as isNotError } from 'shared/graphql/ErrorFragment';
import { selectCurrentWorkspace } from 'features/workspaces';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { useModelVersionRelease } from 'features/catalog/registeredModelVersion/govern/hooks/useModelVersionRelease';

const ModelVersionRelease = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ModelVersionRelease' */ 'features/catalog/registeredModelVersion/release/view/release'
    )
);

const ReleasePage = () => {
  const { versionId } = useRouteParams(routes.modelVersionRelease);
  const workspace = useSelector(selectCurrentWorkspace);

  const { version, loadingVersion, reloadVersion } = useModelVersionRelease({
    versionId,
    workspaceId: workspace.id,
    organizationId:
      workspace.type === 'organization' ? workspace.organizationId : '',
  });

  return (
    <DefaultMatchRemoteDataOrError
      communication={loadingVersion}
      data={version}
      context="loading model version"
    >
      {(loadedModelVersion) => (
        <ModelVersionRelease
          reloadVersion={reloadVersion}
          version={loadedModelVersion}
          checklistTemplates={
            isNotError(loadedModelVersion.checklistTemplates)
              ? loadedModelVersion.checklistTemplates
              : []
          }
          groups={
            isNotError(loadedModelVersion.groups)
              ? loadedModelVersion.groups
              : []
          }
        />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default ReleasePage;
