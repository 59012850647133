import { RowDiffRenderResult } from 'shared/view/domain/CompareEntities/New/CompareTable/BaseComponents/RowDiff';
import { ItemPropertyDiff } from 'shared/models/CompareEntities/new/itemsPropertiesComparing';
import isNotNil from 'shared/utils/isNotNill';
import { Primitive } from 'shared/utils/types';

import { makeRowDiffDescription } from '../CompareTable/PropertyDiffDescriptions/PropertyDiffDescription';

export default function makeItemPropertyDiffView<
  Item,
  Value extends Primitive | Record<string, unknown>,
>(props: {
  diff: ItemPropertyDiff<Item, Value>;
  name: string;
  render: (
    value: NonNullable<Value>,
    item: NonNullable<Item>
  ) => RowDiffRenderResult;
}) {
  return makeRowDiffDescription({
    name: props.name,
    data: props.diff.valuesWithItems,
    isDifferent: props.diff.isDifferent,
    getValue: ({ value }) => value,
    render: ({ value, item }) => {
      const isValueNotNilAndEmptyString =
        isNotNil(value) && (typeof value === 'string' ? value !== '' : true);

      return isValueNotNilAndEmptyString && isNotNil(item)
        ? props.render(value, item)
        : undefined;
    },
  });
}
