/* eslint-disable rulesdir/no-deprecated-fields */
import { useEffect } from 'react';
import { gql } from '@apollo/client';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { usePagination } from 'shared/view/hooks/usePagination';
import { IWorkspace } from 'shared/models/Workspace';
import isNotNil from 'shared/utils/isNotNill';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import {
  mapDataOrError,
  isNotNullableRestrictedGraphqlError,
} from 'shared/graphql/ErrorFragment';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { ExtractByTypename } from 'shared/utils/types';

import * as GraphqlTypes from './graphql-types/datasetsQuery.generated';

type Workspace = ExtractByTypename<
  GraphqlTypes.Datasets['workspace'],
  'Workspace'
>;
export type Datasets = Workspace['datasets']['datasets'];

const DATASETS = gql`
  query Datasets(
    $workspaceName: String!
    $organizationId: ID
    $query: DatasetsQuery!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Workspace {
        id
        datasets(query: $query) {
          datasets {
            id
            name
            tags
            description
            visibility
            dateCreated
            dateUpdated
            owner {
              ...OwnerData
            }
            workspace {
              id
              ...WorkspaceData
            }
          }
          total
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
`;

export const useDatasetsQuery = ({
  workspaceName,
  organizationId,
}: {
  workspaceName: IWorkspace['name'];
  organizationId: string | undefined;
}) => {
  const [loadQuery, query] = useCustomLazyQuery<
    GraphqlTypes.Datasets,
    GraphqlTypes.DatasetsVariables
  >(DATASETS, {
    notifyOnNetworkStatusChange: true,
  });

  const { communication: loadingDatasets, data } =
    resultToCommunicationWithData(
      (res) =>
        mapDataOrError(res.workspace, (x) => {
          const datasets = x.datasets.datasets;
          return {
            datasets: datasets.filter(isNotNil),
            totalCount: x.datasets.total,
          };
        }),
      query
    );

  const { pagination, onCurrentPageChange } = usePagination({
    totalCount: isNotNullableRestrictedGraphqlError(data) ? data.totalCount : 0,
  });

  const loadDatasets = () => {
    loadQuery({
      variables: {
        workspaceName,
        organizationId,
        query: {
          pagination: {
            limit: pagination.pageSize,
            page: pagination.currentPage + 1,
          },
        },
      },
    });
  };

  useEffect(() => {
    loadDatasets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.pageSize,
    pagination.currentPage,
    workspaceName,
    organizationId,
  ]);

  return {
    data,
    loadingDatasets,
    pagination,
    onCurrentPageChange,
    reload: loadDatasets,
  };
};
