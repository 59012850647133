export enum TaskType {
  COMPUTER_VISION = 'Computer Vision',
  NLP = 'NLP',
  TABULAR_CLASSIFICATION = 'Tabular Classification',
  REGRESSION = 'Regression',
  MISCELLANEOUS = 'Miscellaneous',
}

export enum SourceType {
  HUGGING_FACE = 'Hugging Face',
  VERTA_COLLAB = 'Colab',
  VERTA_NOTEBOOK = 'Verta',
}

export enum LocationType {
  LOCALLY = 'Run locally',
  CLOUD = 'Run in cloud',
}

export enum LibraryType {
  PYTORCH = 'PyTorch',
  PYTORCH_TS = 'PyTorch/Tensorflow',
  KERAS = 'Keras/Tensorflow',
  SCIKIT = 'Scikit-learn',
  XGBOOST = 'XGBoost',
  ANY = 'Any library',
}
