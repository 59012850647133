import { ReactElement, useCallback, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { uniq } from 'ramda';
import { scaleOrdinal } from '@visx/scale';

import ChartWithLegendContainer from 'shared/view/charts/ChartWithLegendContainer/ChartWithLegendContainer';
import { Size } from 'shared/utils/charts/chartSizes';
import { useActiveKeys } from 'shared/utils/activeKeys';
import { useChartLegendProps } from 'shared/utils/charts/chartLegendProps/useChartLegendProps';
import { GroupedBarData } from 'shared/view/charts/GroupedBarChart/BaseGroupedBarChart';
import DefaultTooltipContent from 'shared/view/charts/shared/DefaultTooltipContent/DefaultTooltipContent';
import { chartsDataColors } from 'shared/view/charts/shared/colors';

import { liveReferenceLegendWidth } from '../../../shared/liveReferenceLegendWidth';

export interface DistributionBarChartMeta {
  normalized: number;
  count: number;
  totalCount: number;
  modelVersion: string;
  colorKey: string;
}

type DistributionBarData = GroupedBarData<DistributionBarChartMeta>;

interface Props<D> {
  chartData: D[];
  size: Size;
  children: (props: {
    size: Size;
    data: D[];
    renderTooltipContent: (d: DistributionBarData) => ReactElement;
    getColorByData: (d: DistributionBarData) => string;
  }) => ReactElement;
  withoutTooltipAndLegend: boolean | undefined;
}

const renderTooltipContent = (d: DistributionBarData) => (
  <Stack>
    <DefaultTooltipContent label="Model version">
      {d.meta.modelVersion}
    </DefaultTooltipContent>
    <DefaultTooltipContent label="Normalized">
      {d.meta.normalized}
    </DefaultTooltipContent>
    <DefaultTooltipContent label="Bin count">
      {d.meta.count}
    </DefaultTooltipContent>
    <DefaultTooltipContent label="Total count">
      {d.meta.totalCount}
    </DefaultTooltipContent>
  </Stack>
);

const emptyRenderTooltipContent = () => <span>{null}</span>;

const BaseDistributionBarChart = <
  D extends { barsData: Array<DistributionBarData> },
>(
  props: Props<D>
) => {
  const keys = useMemo(
    () =>
      uniq(
        props.chartData.flatMap((d) => d.barsData.map((b) => b.meta.colorKey))
      ),
    [props.chartData]
  );
  const { activeKeys, toggleKey } = useActiveKeys(keys);

  const data = useMemo(
    () =>
      props.chartData
        .map((d) => ({
          ...d,
          barsData: d.barsData.filter((b) =>
            activeKeys.includes(b.meta.colorKey)
          ),
        }))
        .filter((d) => d.barsData.length > 0),
    [activeKeys, props.chartData]
  );

  const colorScale = useMemo(
    () => scaleOrdinal({ domain: keys, range: chartsDataColors }),
    [keys]
  );

  const legendProps = useChartLegendProps({
    activeKeys,
    toggleKey,
    colorScale,
    legendPosition: 'right',
  });

  const getColorByData = useCallback(
    (d: DistributionBarData) => colorScale(d.meta.colorKey),
    [colorScale]
  );

  if (props.withoutTooltipAndLegend) {
    return props.children({
      data,
      size: props.size,
      renderTooltipContent: emptyRenderTooltipContent,
      getColorByData,
    });
  }

  return (
    <ChartWithLegendContainer
      widgetSize={props.size}
      legendProps={legendProps}
      legendWidth={liveReferenceLegendWidth}
    >
      {(size) =>
        props.children({ data, size, renderTooltipContent, getColorByData })
      }
    </ChartWithLegendContainer>
  );
};

export default BaseDistributionBarChart;
