import { type } from 'ramda';

import { Primitive } from 'shared/utils/types';

import { IKeyValuePair } from './KeyValuePair';

export type IAttribute<T = IAttributeValue> = IKeyValuePair<T>;

export type IAttributeValue =
  | IPrimitiveAttribute['value']
  | IListAttribute['value']
  | IObjectAttribute['value'];

export const matchAttribute = <R>(
  matchers: {
    primitive: (x: IPrimitiveAttribute) => R;
    list: (x: IListAttribute) => R;
    object: (x: IObjectAttribute) => R;
  },
  attribute: IAttribute
): R => {
  if (isListAttribute(attribute)) {
    return matchers.list(attribute);
  }
  if (isObjectAttribute(attribute)) {
    return matchers.object(attribute);
  }
  return matchers.primitive(attribute as IPrimitiveAttribute);
};

export type IListAttribute = IAttribute<Array<unknown>>;
export const isListAttribute = (
  attribute: IAttribute
): attribute is IListAttribute => Array.isArray(attribute.value);

export type IObjectAttribute = IAttribute<Record<string, any>>;
export const isObjectAttribute = (
  attribute: IAttribute
): attribute is IObjectAttribute => type(attribute.value) === 'Object';

export type IPrimitiveAttribute = IAttribute<Primitive>;
export const isPrimitiveAttribute = (
  attribute: IAttribute
): attribute is IPrimitiveAttribute =>
  !isListAttribute(attribute) && !isObjectAttribute(attribute);
