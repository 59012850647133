import routes from 'shared/routes';
import SystemAdminPagesLayout from 'pages/authorized/SystemAdmin/shared/SystemAdminPagesLayout/SystemAdminPagesLayout';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import DefaultMatchRemoteData from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import useActiveDirectoryConfiguration from 'features/systemAdmin/integrations/active-directory/store/activeDirectoryConfiguration/useActiveDirectoryConfiguration';
import ActiveDirectoryConfiguration from 'features/systemAdmin/integrations/active-directory/view/ActiveDirectoryConfiguration';

import useIntegrationPageBreadcrumbs from '../useIntegrationPageBreadcrumbs';

export default function ActiveDirectoryConfigurationPage() {
  const breadcrumbs = useAppBreadcrumbs(
    useIntegrationPageBreadcrumbs(),
    useBreadcrumbItem({
      name: 'Active Directory configuration',
      route: routes.systemAdmin.$.integrations.$.active_directory,
    })
  );

  const { data, communication } = useActiveDirectoryConfiguration();

  return (
    <SystemAdminPagesLayout breadcrumbs={breadcrumbs}>
      <DefaultMatchRemoteData
        data={data}
        communication={communication}
        context="loading active directory configuration"
      >
        {({ configuration, organizations }) => (
          <ActiveDirectoryConfiguration
            configuration={configuration}
            organizations={organizations}
          />
        )}
      </DefaultMatchRemoteData>
    </SystemAdminPagesLayout>
  );
}
