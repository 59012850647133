import { useMemo } from 'react';

import { useZoom } from 'shared/view/charts/shared/zoom/useZoom';

import { scaleLinearZoomed } from './scaleLinearZoomed';
import { OnSetReset } from './useZoomReset';

interface SelectedDomain {
  x: [number, number];
  y: [number, number];
}

export const useZoomedXYChartScales = (props: {
  onSetReset: OnSetReset;
  yDomain: [number, number];
  xDomain: [number, number];
  innerHeight: number;
  innerWidth: number;
}) => {
  const { selectedDomain, ...zoomApi } = useZoom<SelectedDomain>({
    getDomain: ({ x0, x1, y0, y1 }) => ({
      x: [x0, x1],
      y: [y0, y1],
    }),
    onSetReset: props.onSetReset,
  });

  const yScale = useMemo(
    () =>
      scaleLinearZoomed({
        selectedDomain: selectedDomain?.y,
        domain: props.yDomain,
        range: [props.innerHeight, 0],
      }),
    [selectedDomain?.y, props.yDomain, props.innerHeight]
  );

  const xScale = useMemo(
    () =>
      scaleLinearZoomed({
        selectedDomain: selectedDomain?.x,
        domain: props.xDomain,
        range: [0, props.innerWidth],
      }),
    [selectedDomain?.x, props.xDomain, props.innerWidth]
  );

  return { yScale, xScale, zoomApi, selectedDomain };
};
