import { useTheme } from '@mui/material';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useMemo } from 'react';
import { GridColumns, GridRows } from '@visx/grid';
import { AxisLeft } from '@visx/axis';
import { ScalePoint } from 'd3-scale';

import { getChartSizes } from 'shared/utils/charts/chartSizes';
import { DefaultTruncatedTickComponent } from 'shared/view/charts/shared/TickComponentWithTruncation/TickComponentWithTruncation';
import { tickLabelFontSize } from 'shared/view/charts/Axis/defaultStyles';
import { useZoom } from 'shared/view/charts/shared/zoom/useZoom';
import PlotWithBrush from 'shared/view/charts/MultiLineChart/PlotWithBrush';
import Borders from 'shared/view/charts/shared/zoom/Borders';

import CustomViolinPlot from './CustomViolinPlot';
import AxisBottomWithRotatedTicks from '../../Axis/AxisBottomWithRotatedTicks/AxisBottomWithRotatedTicks';
import ChartSVG from '../../shared/ChartSVG/ChartSVG';
import { defaultChartMargin } from '../../shared/margin';
import { ViolinChartProps } from '../shared/types';
import {
  useViolinChartTooltipWithView,
  ViolinChartTooltipData,
} from '../shared/tooltip';
import { getZoomedScale } from './getZoomedScale';

interface ViolinAreaChartProps<Meta>
  extends ViolinChartProps<Meta, ScalePoint<string>> {
  yScaleDomain: string[];
  renderTooltipContent: (
    tooltipData: ViolinChartTooltipData<Meta>
  ) => React.ReactNode;
}

type SelectedDomain = {
  x: string[];
  y: string[];
};

function ViolinAreaChart<Meta>(props: ViolinAreaChartProps<Meta>) {
  const { selectedDomain, ...zoomApi } = useZoom<SelectedDomain>({
    getDomain: (bounds) => ({
      x: bounds.xValues || [],
      y: bounds.yValues || [],
    }),
    onSetReset: props.onSetReset,
  });

  const chartSizes = getChartSizes({
    margin: props.margin ?? defaultChartMargin,
    width: props.width,
    height: props.height,
  });

  const xScale = useMemo(
    () =>
      scaleBand({
        domain: selectedDomain?.x ?? props.data.map((d) => d.key),
        range: [0, chartSizes.innerWidth],
        padding: 0.2,
      }),
    [selectedDomain, chartSizes.innerWidth, props.data]
  );
  const xScaleBandwidth = xScale.bandwidth();

  const { zoomedScale: yScale, selectedDomainScale: selectedDomainYScale } =
    useMemo(
      () =>
        getZoomedScale({
          domain: props.yScaleDomain,
          selectedDomain: selectedDomain?.y,
          range: [chartSizes.innerHeight, 0],
          innerHeight: chartSizes.innerHeight,
        }),
      [chartSizes.innerHeight, selectedDomain?.y, props.yScaleDomain]
    );
  const yScaleBandwidth = 0;

  const filteredDataByX = selectedDomain
    ? props.data.filter((d) => selectedDomain.x.some((x) => d.key === x))
    : props.data;
  const valueScales = useMemo(
    () =>
      filteredDataByX
        .map(({ items }) => Math.max(...items.map((d) => d.value)))
        .map((max) => {
          return scaleLinear({
            // if the domain is [0, 0] and value is 0, instead of line we have a bar because scale returns the last number from range (xScaleBandwidth / 2)
            domain: [0, max === 0 ? 1 : max],
            range: [0, xScaleBandwidth / 2],
          });
        }),
    [filteredDataByX, xScaleBandwidth]
  );

  const tooltipApi = useViolinChartTooltipWithView({
    chartSizes,
    data: props.data,
    renderTooltipContent: props.renderTooltipContent,
    onTooltipAreaClick: props.onTooltipAreaClick,
    xScale,
  });

  const theme = useTheme();

  return (
    <ChartSVG
      width={chartSizes.width}
      height={chartSizes.height}
      margin={chartSizes.margin}
      isNilData={props.data.every((d) => d.items.length === 0)}
      outSvgContent={tooltipApi.tooltipView}
    >
      <AxisLeft
        scale={selectedDomainYScale}
        stroke={theme.palette.charts.accentColor}
        tickStroke={theme.palette.charts.accentColor}
        hideTicks={true}
        tickComponent={(tickProps) => (
          <DefaultTruncatedTickComponent
            {...tickProps}
            fill={theme.palette.text.primary}
            fontSize={tickLabelFontSize}
          />
        )}
      />
      <GridRows
        scale={selectedDomainYScale}
        width={chartSizes.innerWidth}
        stroke={theme.palette.charts.accentColor}
        strokeWidth={0.5}
        offset={yScaleBandwidth}
        pointerEvents="none"
      />

      <AxisBottomWithRotatedTicks
        scale={xScale}
        top={chartSizes.innerHeight}
        tickStroke={theme.palette.charts.accentColor}
      />
      <GridColumns
        scale={xScale}
        height={chartSizes.innerHeight}
        stroke={theme.palette.charts.accentColor}
        strokeWidth={1}
        offset={xScaleBandwidth / 2}
        pointerEvents="none"
      />

      {props.renderAdditionalContent?.({
        chartSizes,
        xScale,
        yScale,
        displayedData: filteredDataByX,
      })}

      <PlotWithBrush
        {...zoomApi}
        tooltipApi={tooltipApi}
        chartSizes={chartSizes}
        xScale={xScale}
        yScale={yScale}
      >
        <Borders
          chartId={props.id}
          width={chartSizes.innerWidth}
          height={chartSizes.innerHeight}
        >
          {filteredDataByX.map((d, i) => {
            const color = props.getColorByData(d);

            return (
              <Group
                data-test="violin-area-chart-group"
                key={`${d.key}${i}`}
                left={(xScale(d.key) ?? 0) + xScaleBandwidth / 2}
              >
                <CustomViolinPlot
                  data={d.items}
                  y={(item) => item.key}
                  yScale={yScale}
                  valueScale={valueScales[i]}
                  value={(item) => item.value}
                  strokeWidth={1}
                  stroke={color}
                  fill={color}
                  fillOpacity={0.5}
                />
              </Group>
            );
          })}
        </Borders>
      </PlotWithBrush>
    </ChartSVG>
  );
}

export default ViolinAreaChart;
