import { useMemo, useEffect } from 'react';

import { useOrganizationGroups } from 'features/catalog/shared/hooks/useOrganizationGroups';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

type Props = {
  id: any | undefined | null;
  fallbackComponent?: JSX.Element;
};

export const GroupRecordInfo = ({ id, fallbackComponent }: Props) => {
  const organizationId = useCurrentOrganizationV2();

  const { data = [], load } = useOrganizationGroups(
    { organizationId: organizationId || '' },
    { fetchPolicy: 'cache-first' }
  );

  const value = useMemo(() => {
    if (id) {
      const group = [...data].find((u) => u.id === id);
      if (group) {
        return group.name || group.id;
      }
    }

    return undefined;
  }, [data, id]);

  useEffect(() => {
    if (id) {
      load();
    }
  }, [id, load]);

  return value ? <span>{String(value)}</span> : fallbackComponent ?? <></>;
};
