import { FC, forwardRef } from 'react';
import { styled } from '@mui/material';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

import { OmitStrict } from 'shared/utils/types';
import { IconAwesomeProps } from 'shared/view/elements/IconAwesome/IconAwesomeProps';
import { transientOptions } from 'shared/utils/transientOptions';

// if you want to use className here, ask yourself first - why? Why do you need to have some really unique
// style, which can't be covered with other nativeProps? Discuss it with designer first.
interface Props extends IconAwesomeProps {
  animationDuration?: number;
  nativeProps?: OmitStrict<
    FontAwesomeIconProps,
    'icon' | 'onClick' | 'size' | 'className'
  >;
}

export const ICON_AWESOME_DEFAULT_SIZE = '1x';

// todo after removing Icon components rename this one into IconCustomizable
export const IconAwesomeCustomizable: FC<React.PropsWithChildren<Props>> =
  forwardRef<HTMLElement, Props>(
    ({ icon, size, nativeProps, dataId, animationDuration }, ref) => {
      return (
        <IconWrapper
          ref={ref}
          data-id={dataId}
          $animationDuration={animationDuration}
        >
          <FontAwesomeIcon
            {...nativeProps}
            size={size ? size : ICON_AWESOME_DEFAULT_SIZE}
            icon={icon}
          />
        </IconWrapper>
      );
    }
  );

// fontawesome animation for spin has a default 2s. animationDuration can adjust the duration in any icon.
const IconWrapper = styled(
  'span',
  transientOptions
)<{ $animationDuration?: number }>(({ $animationDuration = 2 }) => ({
  display: 'inline-flex',
  '& .fa-spin': {
    WebkitAnimationName: 'fa-spin',
    animationName: 'fa-spin',
    WebkitAnimationDelay: 'var(--fa-animation-delay, 0s)',
    animationDelay: 'var(--fa-animation-delay, 0s)',
    WebkitAnimationDirection: 'var(--fa-animation-direction, normal)',
    animationDirection: 'var(--fa-animation-direction, normal)',
    WebkitAnimationDuration: `var(--fa-animation-duration, ${$animationDuration}s)`,
    animationDuration: `var(--fa-animation-duration, ${$animationDuration}s)`,
    WebkitAnimationIterationCount:
      'var(--fa-animation-iteration-count, infinite)',
    animationIterationCount: 'var(--fa-animation-iteration-count, infinite)',
    WebkitAnimationTimingFunction: 'var(--fa-animation-timing, linear)',
    animationTimingFunction: 'var(--fa-animation-timing, linear)',
  },
}));
