import { useCallback, useMemo } from 'react';

import PanelActionIcon from 'shared/view/domain/Dashboards/Panel/PanelActionIcon/PanelActionIcon';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import NameFormPopup, {
  NameFormPopupType,
  NameFormType,
} from 'shared/view/domain/Dashboards/NameForm/NameFormPopup';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

interface Props {
  name: string;
  onRename: (name: string) => void;
}

const EditPanelAction = (props: Props) => {
  const { closePopup, isPopupOpen, openPopup } = usePopupManager();

  const onSubmit = useCallback(
    (form: NameFormType) => {
      props.onRename(form.name);
      closePopup();
    },
    [props, closePopup]
  );

  const initialValues = useMemo(
    () => ({
      name: props.name,
    }),
    [props.name]
  );

  return (
    <div>
      <PanelActionIcon
        title="Edit panel"
        icon={ICONS.pencil}
        onClick={openPopup}
        dataTest="update-panel"
      />
      <NameFormPopup
        entityName="panel"
        type={NameFormPopupType.Edit}
        isOpen={isPopupOpen}
        initialValues={initialValues}
        onSubmit={onSubmit}
        closePopup={closePopup}
      />
    </div>
  );
};

export default EditPanelAction;
