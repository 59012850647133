import { useMemo } from 'react';

import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { defaultMonitoringMetricType } from 'shared/models/Monitoring/MonitoringMetricType';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import { validateNotEmpty } from 'shared/utils/validators';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { TimeSeriesWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/TimeSeriesWidget';
import { defaultMonitoringDriftMetricType } from 'shared/models/Monitoring/MonitoringDriftMetricType';
import { MonitoringMetricType } from 'generated/types';
import { OmitStrict } from 'shared/utils/types';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import { getAvailableIODescriptionsForDrift } from 'shared/models/Monitoring/Distribution/Drift';
import { MonitoredModelType } from 'shared/models/Monitoring/MonitoringModel/MonitoredModelType';

import MonitoringWidgetFormPopupContent from '../../MonitoringWidgetFormPopup/MonitoringWidgetFormPopupContent/MonitoringWidgetFormPopupContent';
import MonitoringMetricTypeField from '../shared/MonitoringMetricTypeField/MonitoringMetricTypeField';
import { BaseMonitoringWidgetFormProps } from '../shared/types/BaseMonitoringWidgetFormProps';
import MonitoringIODescriptionField from '../shared/MonitoringIODescriptionField/MonitoringIODescriptionField';
import MonitoringDriftMetricTypeField from '../shared/MonitoringDriftMetricTypeField/MonitoringDriftMetricTypeField';
import MonitoringOutputDescriptionField from '../shared/MonitoringOutputDescriptionField/MonitoringOutputDescriptionField';
import VariantFields, {
  VariantMatchers,
} from '../shared/VariantFields/VariantFields';

type Form = OmitStrict<TimeSeriesWidget, 'id'>;

const getFieldName = makeGetFieldName<Form>();

interface Props extends BaseMonitoringWidgetFormProps {
  onSubmit: (form: Form) => void;
  initialValues: Form | null;
  defaultInput: MonitoringIODescription;
  defaultOutput: MonitoringIODescription;
}

const makeInitialValues = (props: {
  defaultInput: MonitoringIODescription;
  defaultOutput: MonitoringIODescription;
}): Form => ({
  type: 'timeSeries',
  title: '',
  variant: {
    type: 'drift',
    driftMetricType: defaultMonitoringDriftMetricType,
    ioDescription: props.defaultInput,
  },
  alert: undefined,
});

const makeVariants = (props: {
  defaultInput: MonitoringIODescription;
  defaultOutput: MonitoringIODescription;
  ioDescriptions: MonitoringIODescription[];
  monitoredModelType: MonitoredModelType;
}): VariantMatchers<Form['variant']> => ({
  drift: {
    initialValue: {
      ioDescription: props.defaultInput,
      driftMetricType: defaultMonitoringDriftMetricType,
      type: 'drift',
    },
    label: 'Drift',
    render: () => (
      <>
        <MonitoringIODescriptionField
          label="Feature"
          name={getFieldName({ variant: { ioDescription: null } })}
          ioDescriptions={getAvailableIODescriptionsForDrift(
            props.ioDescriptions
          )}
        />

        <MonitoringDriftMetricTypeField
          name={getFieldName({ variant: { driftMetricType: null } })}
        />
      </>
    ),
  },
  metric: {
    initialValue: {
      type: 'metric',
      metricType: defaultMonitoringMetricType,
      output: props.defaultOutput,
    },
    label: 'Metric',
    render: ({ metricType }) => (
      <>
        {metricType !== MonitoringMetricType.PREDICTION_COUNT ? (
          <MonitoringOutputDescriptionField
            name={getFieldName({ variant: { output: null } })}
            ioDescriptions={props.ioDescriptions}
          />
        ) : null}
        <MonitoringMetricTypeField
          name={getFieldName({ variant: { metricType: null } })}
          monitoredModelType={props.monitoredModelType}
        />
      </>
    ),
  },
});

const TimeSeriesWidgetForm = (props: Props) => {
  const variants = useMemo(
    (): VariantMatchers<Form['variant']> =>
      makeVariants({
        defaultInput: props.defaultInput,
        defaultOutput: props.defaultOutput,
        ioDescriptions: props.widgetExternalDeps.ioDescriptions,
        monitoredModelType: props.widgetExternalDeps.monitoredModelType,
      }),
    [
      props.defaultInput,
      props.defaultOutput,
      props.widgetExternalDeps.ioDescriptions,
      props.widgetExternalDeps.monitoredModelType,
    ]
  );

  const initialValues = useMemo(
    () =>
      makeInitialValues({
        defaultInput: props.defaultInput,
        defaultOutput: props.defaultOutput,
      }),
    [props.defaultInput, props.defaultOutput]
  );

  return (
    <PresetFormik<Form>
      initialValues={props.initialValues ?? initialValues}
      onSubmit={props.onSubmit}
    >
      {({ isValid, handleReset, values, setFieldValue }) => (
        <MonitoringWidgetFormPopupContent
          isValid={isValid}
          onBack={props.onBack}
          onReset={handleReset}
          submitText={props.submitText}
          widgetPreview={values}
          onSubmitClick={() => {
            props.onSubmit(values);
          }}
          widgetExternalDeps={props.widgetExternalDeps}
        >
          <TextInputField
            isRequired={true}
            label="Title"
            name={getFieldName({ title: null })}
            validate={validateNotEmpty('title')}
          />

          <VariantFields<Form['variant']>
            variants={variants}
            variantTypeFieldName={getFieldName({ variant: { type: null } })}
            value={values.variant}
            onChange={(variant) =>
              setFieldValue(getFieldName({ variant: null }), variant)
            }
          />
        </MonitoringWidgetFormPopupContent>
      )}
    </PresetFormik>
  );
};

export default TimeSeriesWidgetForm;
