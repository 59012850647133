// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MuiMenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import ListItemIcon from '@mui/material/ListItemIcon';
import { TypographyProps } from '@mui/material';

import LinkWrapper from 'shared/view/elements/LinkWrapper/LinkWrapper';
import { OmitStrict } from 'shared/utils/types';
import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';

const BaseMenuItem = (
  props: {
    icon?: IconDefinition;
    rightContent?: React.ReactNode;
    children: string;
    dataTest?: string;
    variant?: TypographyProps['variant'];
    textColorizationStyle?: React.CSSProperties;
  } & MenuItemProps
) => {
  const {
    dataTest,
    textColorizationStyle,
    icon,
    rightContent,
    children,
    sx,
    variant,
    ...rest
  } = props;

  return (
    <MuiMenuItem
      {...rest}
      data-test={dataTest}
      sx={{ ...sx, minWidth: 100, maxWidth: 400 }}
    >
      {icon ? (
        <ListItemIcon style={{ minWidth: '24px' }}>
          <IconAwesomeInfo icon={icon} color={props.color} />
        </ListItemIcon>
      ) : null}
      <DynamicTypography
        variant={variant}
        value={children}
        sx={textColorizationStyle}
        dataTest="menu-item-text"
      />
      {rightContent}
    </MuiMenuItem>
  );
};

export function LinkMenuItem(
  props: React.ComponentProps<typeof BaseMenuItem> &
    OmitStrict<React.ComponentProps<typeof LinkWrapper>, 'children'>
) {
  return <BaseMenuItem {...props} {...{ component: LinkWrapper }} />;
}

export function ButtonMenuItem(
  props: React.ComponentProps<typeof BaseMenuItem> & {
    onClick(e: React.MouseEvent<HTMLElement, MouseEvent>): void;
  }
) {
  return <BaseMenuItem {...props} />;
}
