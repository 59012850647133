import { useMemo } from 'react';

import {
  defaultScatterPlotWidgetVariant,
  ScatterPlotWidgetVariant,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/ScatterPlotWidget';
import { MonitoringIODescription } from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import handleUnionCases from 'shared/utils/handleUnionCases';
import { ReplaceProps } from 'shared/utils/types';
import { validateNotEmpty } from 'shared/utils/validators';
import PresetFormik from 'shared/view/formComponents/presetComponents/PresetFormik/PresetFormik';
import { IOptionType } from 'shared/view/elements/Selects/shared/types';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';

import MonitoringWidgetFormPopupContent from '../../MonitoringWidgetFormPopup/MonitoringWidgetFormPopupContent/MonitoringWidgetFormPopupContent';
import MonitoringIODescriptionField from '../shared/MonitoringIODescriptionField/MonitoringIODescriptionField';
import { BaseMonitoringWidgetFormProps } from '../shared/types/BaseMonitoringWidgetFormProps';
import VariantTypeField from '../shared/VariantFields/VariantTypeField/VariantTypeField';

interface ScatterPlotWidgetFormType {
  type: 'scatterPlot';
  title: string;
  variant: ScatterPlotWidgetVariant;
  ioDescription: MonitoringIODescription;
}

const getFieldName = makeGetFieldName<ScatterPlotWidgetFormType>();

interface Props extends BaseMonitoringWidgetFormProps {
  submitText: string;
  onSubmit: (
    form: ReplaceProps<
      ScatterPlotWidgetFormType,
      {
        ioDescription: MonitoringIODescription;
      }
    >
  ) => void;
  initialValues: ScatterPlotWidgetFormType | null;
  defaultInput: MonitoringIODescription;
}

const variantOptions = handleUnionCases<
  ScatterPlotWidgetVariant,
  IOptionType<ScatterPlotWidgetVariant>
>()([['outlierDetection', (value) => ({ label: 'Outlier detection', value })]]);

const ScatterPlotWidgetForm = (props: Props) => {
  const initialValues = useMemo(
    (): ScatterPlotWidgetFormType => ({
      type: 'scatterPlot',
      title: '',
      variant: defaultScatterPlotWidgetVariant,
      ioDescription: props.defaultInput,
    }),
    [props.defaultInput]
  );
  return (
    <PresetFormik
      initialValues={props.initialValues ?? initialValues}
      onSubmit={props.onSubmit}
    >
      {({ isValid, values, handleReset }) => (
        <MonitoringWidgetFormPopupContent
          isValid={isValid}
          onBack={props.onBack}
          onReset={handleReset}
          submitText={props.submitText}
          widgetExternalDeps={props.widgetExternalDeps}
          widgetPreview={values}
          onSubmitClick={() => {
            props.onSubmit(values);
          }}
        >
          <TextInputField
            isRequired={true}
            label="Title"
            name={getFieldName({ title: null })}
            validate={validateNotEmpty('title')}
          />

          <VariantTypeField
            name={getFieldName({ variant: null })}
            options={variantOptions}
          />

          <MonitoringIODescriptionField
            label="Feature"
            name={getFieldName({ ioDescription: null })}
            ioDescriptions={props.widgetExternalDeps.ioDescriptions}
          />
        </MonitoringWidgetFormPopupContent>
      )}
    </PresetFormik>
  );
};

export default ScatterPlotWidgetForm;
