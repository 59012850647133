import { ReactNode, useCallback, useMemo } from 'react';

import {
  getDefaultIODescriptionsOrThrowError,
  MonitoringIODescription,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringIODescription';
import { useUpdateAlert } from 'features/monitoring/alerts/store/updateAlert/useUpdateAlert';
import {
  findAlertIODescription,
  MonitoringAlert,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import Popup from 'shared/view/elements/Popup/Popup';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { defaultMonitoringDriftMetricType } from 'shared/models/Monitoring/MonitoringDriftMetricType';
import { defaultMonitoringMetricType } from 'shared/models/Monitoring/MonitoringMetricType';
import {
  defaultAlertThreshold,
  defaultAlertLowerBound,
  defaultAlertUpperBound,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/Alerter';
import { useToast } from 'features/toast/store/hooks';
import { MonitoredModelType } from 'shared/models/Monitoring/MonitoringModel/MonitoredModelType';

import MonitoringAlertForm from '../MonitoringAlertForm/MonitoringAlertForm';
import { MonitoringAlertFormType } from '../MonitoringAlertForm/shared/MonitoringAlertFormType';

interface Props {
  alert: MonitoringAlert;
  monitoredModelType: MonitoredModelType;
  ioDescriptions: MonitoringIODescription[];
  renderButton: (openPopup: () => void) => ReactNode;
}

const makeInitialValues = (props: {
  ioDescriptions: MonitoringIODescription[];
  alert: MonitoringAlert;
}): MonitoringAlertFormType => {
  const { defaultInput, defaultOutput } = getDefaultIODescriptionsOrThrowError(
    props.ioDescriptions
  );
  const ioDescription = findAlertIODescription(props);
  return {
    ...props.alert,
    ioDescription:
      props.alert.settings.type === 'drift'
        ? ioDescription ?? defaultInput
        : defaultInput,
    output:
      props.alert.settings.type === 'metric'
        ? ioDescription ?? defaultOutput
        : defaultOutput,
    condition: {
      threshold:
        props.alert.alerter.type === 'fixed'
          ? props.alert.alerter.threshold
          : defaultAlertThreshold,
      operator:
        props.alert.alerter.type === 'fixed'
          ? props.alert.alerter.operator
          : props.alert.alerter.alertIfOutsideRange
            ? 'notBetween'
            : 'between',
      lowerBound:
        props.alert.alerter.type === 'range'
          ? props.alert.alerter.lowerBound
          : defaultAlertLowerBound,
      upperBound:
        props.alert.alerter.type === 'range'
          ? props.alert.alerter.upperBound
          : defaultAlertUpperBound,
    },
    driftMetricType:
      props.alert.settings.type === 'drift'
        ? props.alert.settings.driftMetricType
        : defaultMonitoringDriftMetricType,
    metricType:
      props.alert.settings.type === 'metric'
        ? props.alert.settings.metricType
        : defaultMonitoringMetricType,
    type: props.alert.settings.type,
  };
};

export const UpdateAlertForm = (props: {
  alert: MonitoringAlert;
  onSubmit: (form: MonitoringAlertFormType) => void;
  monitoredModelType: MonitoredModelType;
  ioDescriptions: MonitoringIODescription[];
  isLoading: boolean;
}) => {
  const initialValues = useMemo(
    (): MonitoringAlertFormType =>
      makeInitialValues({
        alert: props.alert,
        ioDescriptions: props.ioDescriptions,
      }),
    [props.alert, props.ioDescriptions]
  );

  return (
    <MonitoringAlertForm
      monitoredModelType={props.monitoredModelType}
      initialValues={initialValues}
      isLoading={props.isLoading}
      onSubmit={props.onSubmit}
      ioDescriptions={props.ioDescriptions}
      submitText="OK"
    />
  );
};

const UpdateAlertAction = (props: Props) => {
  const { closePopup, isPopupOpen, openPopup } = usePopupManager();

  const toast = useToast();

  const onCompleted = useCallback(() => {
    closePopup();
    toast('Alert updated', 'success');
  }, [closePopup, toast]);

  const { updateAlert, updatingAlert } = useUpdateAlert({
    onCompleted,
  });

  const onSubmit = useCallback(
    (form: MonitoringAlertFormType) => {
      updateAlert({
        alert: form,
        alertId: props.alert.id,
      });
    },
    [props.alert.id, updateAlert]
  );

  return (
    <div>
      {props.renderButton(openPopup)}

      <Popup
        title="Edit alert rule"
        isOpen={isPopupOpen}
        onClose={closePopup}
        maxWidth={false}
      >
        <UpdateAlertForm
          alert={props.alert}
          monitoredModelType={props.monitoredModelType}
          isLoading={updatingAlert.isRequesting}
          onSubmit={onSubmit}
          ioDescriptions={props.ioDescriptions}
        />
      </Popup>
    </div>
  );
};

export default UpdateAlertAction;
