import moment from 'moment';

import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import routes from 'shared/routes';
import makeLinkColumn from 'shared/view/elements/DataGrid/columns/link/linkColumn';
import { trackEvent } from 'setup/app/analytics';
import { ExtractByTypename } from 'shared/utils/types';

import { CatalogWidget } from '../../hooks/graphql-types/useHomepageCatalogWidget.generated';

type Workspace = ExtractByTypename<CatalogWidget['workspace'], 'Workspace'>;

const columns: Array<
  DataGridColumn<Workspace['registeredModels']['models'][0]>
> = [
  {
    field: 'Model',
    flex: 1,
    renderCell: (params) => <CellRendererString value={params.row.name} />,
  },
  {
    field: 'Last update',
    flex: 1,
    renderCell: (params) => (
      <CellRendererString value={moment(params.row.dateUpdated).fromNow()} />
    ),
  },
  makeLinkColumn(
    (row) =>
      routes.modelOverview.getRedirectPath({
        registeredModelId: row.id,
        workspaceName: row.workspace.name,
      }),
    () => trackEvent({ type: 'homepage.catalog_widget.monitor_link_clicked' })
  ),
];

const CatalogWidgetList = (props: {
  models: Workspace['registeredModels']['models'];
}) => {
  return (
    <DataGridWithTypes
      rows={props.models.slice(0, 3)}
      heightType="autoHeightButMax5Rows"
      density="compact"
      columns={columns}
    />
  );
};

export default CatalogWidgetList;
