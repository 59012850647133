import React from 'react';

import {
  ICanaryStrategy,
  ICanaryRule,
} from 'features/deployment/canary/endpoints/store/endpointMonitoring/endpointMonitoring';
import { CanaryRuleParametersType } from 'generated/types';
import { getRuleParameterValueFormater } from 'shared/models/Deployment/canary/CanaryRules/CanaryRuleParameter';
import { formatCanaryRuleInterval } from 'shared/models/Deployment/canary/CanaryRules/CanaryRuleInterval';
import { formatCanaryRuleStep } from 'shared/models/Deployment/canary/CanaryRules/CanaryRuleStep';
import RecordsSection, {
  RecordInfo,
  NestedRecordInfo,
} from 'shared/view/elements/RecordsSection/RecordsSection';

interface ILocalProps {
  canaryStrategy: ICanaryStrategy;
  canaryRules: ICanaryRule[];
}

interface IFullRule {
  id: string;
  name: string;
  description: string;
  parameters: IFullParameter[];
}

interface IFullParameter {
  name: string;
  value: string;
  description: string;
  type: CanaryRuleParametersType;
}

const CanaryStrategy: React.FC<React.PropsWithChildren<ILocalProps>> = ({
  canaryStrategy,
  canaryRules,
}) => {
  const rules = getFullRules({ canaryRules, canaryStrategy });

  return (
    <RecordsSection>
      {canaryStrategy ? (
        <RecordInfo
          label="Progress interval"
          dataTests={{ value: 'canary-rules-data-interval' }}
        >
          {formatCanaryRuleInterval(canaryStrategy.progressInterval)}
        </RecordInfo>
      ) : null}
      {canaryStrategy ? (
        <RecordInfo
          label="Progress step"
          dataTests={{ value: 'canary-rules-data-step' }}
        >
          {formatCanaryRuleStep(canaryStrategy.progressStep)}
        </RecordInfo>
      ) : null}
      {rules.map((rule, index) => (
        <NestedRecordInfo
          key={index}
          label={rule.description}
          dataTests={{
            root: `canary-rules-data-rule-${index}`,
            label: 'canary-rules-data-rule-description',
          }}
        >
          {rule.parameters.map((parameter, parameterIndex) => {
            const format = getRuleParameterValueFormater(parameter);

            return (
              <RecordInfo
                key={parameterIndex}
                label={parameter.description}
                dataTests={{
                  value: 'canary-rules-data-rule-parameter-value',
                }}
              >
                {format(parameter.value)}
              </RecordInfo>
            );
          })}
        </NestedRecordInfo>
      ))}
    </RecordsSection>
  );
};

const getFullRules = ({
  canaryRules,
  canaryStrategy,
}: {
  canaryStrategy: ICanaryStrategy;
  canaryRules: ICanaryRule[];
}): IFullRule[] => {
  if (canaryStrategy === null) {
    return [];
  }

  return canaryStrategy.rules.map((rule) => {
    const findedRule = canaryRules.find((r) => r.id === rule.id);

    return {
      id: rule.id,
      name: findedRule ? findedRule.name : '',
      description: findedRule ? findedRule.description : '',
      parameters: rule.parameters.map((parameter) => {
        const findedParameter = findedRule
          ? findedRule.parameters.find((p) => p.name === parameter.name)
          : null;

        const res: IFullParameter = {
          name: parameter.name,
          value: parameter.value,
          description: findedParameter ? findedParameter.description : '',
          type: findedParameter
            ? findedParameter.type
            : CanaryRuleParametersType.UNKNOWN,
        };
        return res;
      }),
    };
  });
};

export default CanaryStrategy;
