import { isEqual, isNil, orderBy } from 'lodash';

import {
  AlertAggregationWindow,
  AlertEvaluationFrequency,
  AlertStatus,
} from 'generated/types';
import { getAlertAggregationWindowLabel } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertAggregationWindow';
import {
  Alerter,
  toAlerterRuleString,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/Alerter';
import { getAlertEvaluationFrequencyLabel } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertEvaluationFrequency';
import { AlertHistoryItem } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertHistoryItem';
import MonitoringAlertStatus from 'shared/view/domain/Monitoring/MonitoringAlert/MonitoringAlertStatus/MonitoringAlertStatus';
import CellRendererDate from 'shared/view/elements/DataGrid/columns/CellRendererDate';
import CellRendererString from 'shared/view/elements/DataGrid/columns/CellRendererString';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import Section from 'shared/view/elements/SectionComponents/Section/Section';
import { ICommunication } from 'shared/utils/redux/communication';
import isNotNil from 'shared/utils/isNotNill';

interface AlertHistoryTableRow {
  id: string;
  time: Date;
  aggregationWindow: AlertAggregationWindow | undefined;
  evaluationFrequency: AlertEvaluationFrequency | undefined;
  status: AlertStatus | undefined;
  alerter: Alerter | undefined;
}

const returnDataIfChanged = <T extends Record<K, unknown>, K extends keyof T>(
  array: T[],
  index: number,
  key: K
): T[K] | undefined => {
  const current = array[index][key];
  const next = array[index + 1]?.[key];

  return isEqual(current, next) ? undefined : current;
};

const makeTableRows = (history: AlertHistoryItem[]): AlertHistoryTableRow[] => {
  const alertsFromHistory = history.map((d) => ({
    ...d.alert,
    status: d.status,
    time: d.time,
  }));
  return orderBy(
    alertsFromHistory
      .map((d, index) => ({
        id: String(+d.time),
        time: d.time,
        aggregationWindow: returnDataIfChanged(
          alertsFromHistory,
          index,
          'aggregationWindow'
        ),
        evaluationFrequency: returnDataIfChanged(
          alertsFromHistory,
          index,
          'evaluationFrequency'
        ),
        status: returnDataIfChanged(alertsFromHistory, index, 'status'),
        alerter: returnDataIfChanged(alertsFromHistory, index, 'alerter'),
      }))
      .filter((_, index) => {
        const keys = [
          'aggregationWindow' as const,
          'evaluationFrequency' as const,
          'status' as const,
          'alerter' as const,
        ];

        return keys.some((key) =>
          isNotNil(returnDataIfChanged(alertsFromHistory, index, key))
        );
      }),
    (d: AlertHistoryTableRow) => d.time,
    'desc'
  );
};

const columns: DataGridColumn<AlertHistoryTableRow>[] = [
  {
    field: 'Timestamp',
    flex: 2,
    renderCell: (params) => (
      <CellRendererDate value={params.row.time} showTime={true} />
    ),
  },
  {
    field: 'Condition',
    flex: 2,
    renderCell: (params) => (
      <CellRendererString
        value={
          params.row.alerter ? toAlerterRuleString(params.row.alerter) : null
        }
      />
    ),
  },
  {
    field: 'Aggregation',
    flex: 1,
    renderCell: (params) => (
      <CellRendererString
        value={
          !isNil(params.row.aggregationWindow)
            ? getAlertAggregationWindowLabel(params.row.aggregationWindow)
            : null
        }
      />
    ),
  },
  {
    field: 'Frequency',
    flex: 1,
    renderCell: (params) => (
      <CellRendererString
        value={
          !isNil(params.row.evaluationFrequency)
            ? getAlertEvaluationFrequencyLabel(params.row.evaluationFrequency)
            : null
        }
      />
    ),
  },
  {
    field: 'Status',
    width: 60,
    align: 'center',
    renderCell: (params) =>
      !isNil(params.row.status) ? (
        <MonitoringAlertStatus status={params.row.status} />
      ) : (
        '-'
      ),
  },
];

interface Props {
  alert:
    | {
        name: string;
        history: AlertHistoryItem[];
      }
    | undefined;
  loadingAlert: ICommunication;
}

const MonitoringAlertHistory = (props: Props) => {
  return (
    <Section
      label={
        props.alert ? `Alert history: ${props.alert.name}` : 'Alert history'
      }
    >
      <DataGridWithTypes
        columns={columns}
        rows={props.alert ? makeTableRows(props.alert.history) : undefined}
        heightType="pageHeight"
        communication={props.loadingAlert}
        context="loading alert history"
      />
    </Section>
  );
};

export default MonitoringAlertHistory;
