import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

import { RegisteredModelVersionLockLevel, Stage } from 'generated/types';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import capitalize from 'shared/utils/capitalize';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { RegisteredModelVersion } from 'pages/authorized/CatalogPages/ModelVersionPages/shared/Layout/graphql';
import { convertGraphqlAttributeWithWidgetBlob } from 'shared/graphql/Attribute/converters';
import { getModelVersionType } from 'shared/models/Registry/RegisteredModelVersion/Type';
import SharedChip from 'shared/view/elements/Chip/Chip';

const Chip = styled(SharedChip)({
  paddingLeft: '2px',
  paddingRight: '2px',
});

interface Props {
  kind: 'tile' | 'title';
  versionId: string;
  lockLevel: RegisteredModelVersionLockLevel;
  stage: Stage;
  attributes: RegisteredModelVersion['attributes'];
}

const ModelVersionTypeInfo = (props: Props) => {
  const { kind, versionId, lockLevel, stage, attributes } = props;
  const versionType = getModelVersionType(filterAttrs(attributes));
  const icon = getLockLevelIcon(lockLevel);

  return (
    <Stack
      direction="row"
      justifyContent={kind === 'tile' ? 'space-between' : ''}
      alignItems="center"
      spacing={2}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        {icon ? <IconAwesomeInfo icon={icon} /> : null}
        <Typography variant="overline" color="text.secondary">
          {versionType} |
        </Typography>
        <Typography variant="overline" color="text.secondary">
          {versionId}
        </Typography>
      </Stack>
      <Chip
        label={capitalize(stage)}
        size="small"
        color={stage === Stage.PRODUCTION ? 'success' : 'default'}
      />
    </Stack>
  );
};

const getLockLevelIcon = (lockLevel: RegisteredModelVersionLockLevel) => {
  switch (lockLevel) {
    case RegisteredModelVersionLockLevel.OPEN:
      return undefined;
    case RegisteredModelVersionLockLevel.CLOSED:
      return ICONS.lock;
    case RegisteredModelVersionLockLevel.REDACT:
      return ICONS.userLock;
    default:
      return undefined;
  }
};

const hiddenAttributesKeys = ['workflow_status', 'workflow_templates'];

const filterAttrs = (attributes: RegisteredModelVersion['attributes']) => {
  return attributes
    .map(convertGraphqlAttributeWithWidgetBlob)
    .filter((attr) => !hiddenAttributesKeys.includes(attr.key));
};

export default ModelVersionTypeInfo;
