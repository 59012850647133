import { OmitStrict } from 'shared/utils/types';

import { DataGridColumn } from '../DataGridColumn';
import { RenderCellParams } from '../renderCell/RenderCellParams';

export const actionsColumnSettings = {
  width: 120,
  field: 'Actions',
};

type Props<T> = OmitStrict<DataGridColumn<T>, 'field'> & {
  renderCell: (params: RenderCellParams<T>) => JSX.Element;
  dataTest?: string;
};

const makeActionsColumn = <T extends any>(
  props: Props<T>
): DataGridColumn<T> => ({
  ...actionsColumnSettings,
  ...props,
});

export default makeActionsColumn;
