import { useMemo } from 'react';

import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { DataGridColumn } from 'shared/view/elements/DataGrid/DataGridColumn';
import { DataGridWithTypes } from 'shared/view/elements/DataGrid/DataGridWithTypes';
import CellRendererLink from 'shared/view/elements/DataGrid/columns/CellRendererLink';
import CellRendererDate from 'shared/view/elements/DataGrid/columns/CellRendererDate';
import { typeSafeConfiguration } from 'shared/view/elements/DataGrid/configuration/helpers/typeSafeConfiguration';
import parseGraphqlDate from 'shared/utils/graphql/parseGraphqlDate';
import isNotNil from 'shared/utils/isNotNill';
import routes from 'shared/routes';
import { isNotNullableRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { ExtractByTypename } from 'shared/utils/types';

import { useMonitoringModels } from '../../store/models/useMonitoringModels';
import { MonitoringModels } from '../../store/models/graphql-types/useMonitoringModels.generated';

type Workspace = ExtractByTypename<MonitoringModels['workspace'], 'Workspace'>;

const makeColumns = ({
  workspaceName,
}: {
  workspaceName: string;
}): DataGridColumn<Workspace['monitoredModels']['models'][0]>[] => {
  const columns: (DataGridColumn<
    Workspace['monitoredModels']['models'][0]
  > | null)[] = [
    {
      field: 'Model name',
      flex: 1,
      additionalConfiguration: typeSafeConfiguration(
        ['sort', 'filter'],
        'string',
        (params) => params.row.name
      ),
      renderCell: (params) => (
        <CellRendererLink
          label={params.row.name}
          to={routes.monitoringModel.getRedirectPath({
            monitoringModelId: params.row.id,
            workspaceName,
          })}
          dataTest="monitored-model-link"
        />
      ),
    },
    {
      field: 'Released',
      additionalConfiguration: typeSafeConfiguration(
        ['sort', 'filter'],
        'date',
        (params) => parseGraphqlDate(params.row.createdAt)
      ),
      renderCell: (params) => (
        <CellRendererDate
          showTime={false}
          value={parseGraphqlDate(params.row.createdAt)}
        />
      ),
    },
  ];

  return columns.filter(isNotNil);
};

const MonitoringModelsTable = () => {
  const workspaceName = useWorkspaceNameFromURL();
  const organizationId = useCurrentOrganizationV2();
  const { data, communication } = useMonitoringModels({
    workspaceName,
    organizationId,
  });

  const columns = useMemo(
    () =>
      makeColumns({
        workspaceName,
      }),
    [workspaceName]
  );

  return (
    <DataGridWithTypes
      rows={isNotNullableRestrictedGraphqlError(data) ? data.models : undefined}
      columns={columns}
      communication={communication}
      context={'loading models'}
      heightType="pageHeight"
    />
  );
};

export default MonitoringModelsTable;
