import * as Workspace from 'shared/models/Workspace';
import * as CurrentWorkspace from 'shared/models/Workspace/CurrentWorkspace';

const workspaceLocalStorageKey = 'workspace';

const getWorkspaceFromLocalStorage = (
  localStorage: Record<string, string | undefined>
) => {
  const workspaceFromLocalStorageJSON = localStorage[workspaceLocalStorageKey];
  return workspaceFromLocalStorageJSON
    ? (JSON.parse(
        workspaceFromLocalStorageJSON
      ) as CurrentWorkspace.ICurrentWorkspace)
    : null;
};

export const saveWorkspaceInLocalStorage = (
  shortWorkspace: CurrentWorkspace.ICurrentWorkspace
) => {
  localStorage[workspaceLocalStorageKey] = JSON.stringify(shortWorkspace);
};

export const getInitialCurrentWorkspace = ({
  userDefaultWorkspaceId,
  userWorkspaces,
  localStorage,
}: {
  userDefaultWorkspaceId: string | null;
  userWorkspaces: Workspace.IUserWorkspaces;
  localStorage: Record<string, string | undefined>;
}): CurrentWorkspace.ICurrentWorkspace => {
  const workspaceFromLocalStorage = getWorkspaceFromLocalStorage(localStorage);

  return CurrentWorkspace.getCurrentWorkspace(
    userDefaultWorkspaceId,
    userWorkspaces,
    workspaceFromLocalStorage
  );
};

export const getInitialCurrentWorkspaceV2 = ({
  localStorage,
  workspaces,
  userDefaultWorkspaceId,
}: {
  userDefaultWorkspaceId: string | null;
  workspaces: Array<{ id: string; organizationId: string }>;
  localStorage: Record<string, string | undefined>;
}): CurrentWorkspace.ICurrentWorkspace | undefined => {
  const workspaceFromLocalStorage = getWorkspaceFromLocalStorage(localStorage);
  const res =
    workspaces.find(
      (w) =>
        w.id ===
        (workspaceFromLocalStorage?.type === 'organization' &&
          workspaceFromLocalStorage.id)
    ) || workspaces.find((w) => w.id === userDefaultWorkspaceId);
  return res
    ? {
        id: res.id,
        organizationId: res.organizationId,
        namespace: null,
        organizationName: '',
        type: 'organization',
      }
    : undefined;
};
