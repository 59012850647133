import Borders from '../../zoom/Borders';

interface Props {
  chartId: string;
  innerWidth: number;
  innerHeight: number;
  children: JSX.Element[] | JSX.Element;
}

const XYChartBorders = (props: Props) => {
  return (
    <Borders
      chartId={props.chartId}
      width={props.innerWidth + 8} // additional values is needed to show points of lines
      height={props.innerHeight + 10}
      transform="translate(-5, -5)"
    >
      {props.children}
    </Borders>
  );
};

export default XYChartBorders;
