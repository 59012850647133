import { Stack } from '@mui/material';

import { makeGetFieldName } from 'shared/utils/getFieldName';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { useDatasetsQuery } from 'features/datasets/datasets/store/datasetsQuery/datasetsQuery';
import DefaultMatchRemoteData from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import { isNotRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { Datasets } from 'features/datasets/datasets/store/datasetsQuery/datasetsQuery';
import { validateNotEmpty } from 'shared/utils/validators';

const getField = makeGetFieldName<any>();

const DatasetConfigurationFields = ({ datasets }: { datasets: Datasets }) => {
  const datasetOptions = datasets.map((dataset) => ({
    label: dataset.name,
    value: dataset.id,
  }));
  return (
    <Stack my={1} spacing={1}>
      <SelectField
        label="Select train dataset"
        name={getField({ trainDataset: null })}
        options={datasetOptions}
        validate={validateNotEmpty('trainDataset')}
        required
        withoutError
      />
      <SelectField
        label="Select evaluation dataset (optional)"
        name={getField({ evaluationDataset: null })}
        options={datasetOptions}
        withoutError
      />
      <SelectField
        label="Select test dataset (optional)"
        name={getField({ testDataset: null })}
        options={datasetOptions}
        withoutError
      />
    </Stack>
  );
};

export const ExistingDatasetConfiguration = () => {
  const workspaceName = useWorkspaceNameFromURL();
  const organizationId = useCurrentOrganizationV2();
  const { data, loadingDatasets } = useDatasetsQuery({
    workspaceName,
    organizationId,
  });
  return (
    <DefaultMatchRemoteData
      communication={loadingDatasets}
      data={data}
      context={'preparing for create endpoint'}
    >
      {(datasets) =>
        isNotRestrictedGraphqlError(datasets) ? (
          <DatasetConfigurationFields datasets={datasets.datasets} />
        ) : (
          <></>
        )
      }
    </DefaultMatchRemoteData>
  );
};
