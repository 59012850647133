import { Stack } from '@mui/material';

import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeLink } from 'shared/view/elements/IconAwesome/IconAwesomeLink';

export const SocialButtons = () => (
  <Stack
    direction="row"
    spacing={2}
    sx={{ '& a': { width: 'fit-content', padding: 0 } }}
  >
    <IconAwesomeLink
      icon={ICONS.youtube}
      to="https://www.youtube.com/channel/UClJKA9nhsFNpxK5ACRKjzbA"
      isExternal
      size="lg"
      disabled
    />
    <IconAwesomeLink
      icon={ICONS.twitter}
      to="https://twitter.com/VertaAI"
      isExternal
      size="lg"
      disabled
    />
    <IconAwesomeLink
      icon={ICONS.slack}
      to="https://verta-community.slack.com/ssb/redirect"
      isExternal
      size="lg"
      disabled
    />
    <IconAwesomeLink
      icon={ICONS.linkedin}
      to="https://www.linkedin.com/company/vertaai/"
      isExternal
      size="lg"
      disabled
    />
    <IconAwesomeLink
      icon={ICONS.github}
      to="https://github.com/VertaAI"
      isExternal
      size="lg"
      disabled
    />
    <IconAwesomeLink
      icon={ICONS.medium}
      to="https://medium.com/vertaai"
      isExternal
      size="lg"
      disabled
    />
  </Stack>
);
