import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import ExperimentCreationPopup from 'features/experiments/creation/view/ExperimentCreationPopup/ExperimentCreationPopup';
import Button from 'shared/view/elements/Button/Button';

import { ProjectDetailsLayoutWithDefaultProjectLoading } from '../shared/layouts/ProjectDetailsLayout';

const ExperimentsTable = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ExperimentsList' */ 'features/experiments/experiments/view/ExperimentsTable/ExperimentsTable'
    )
);

const ExperimentsPage = () => {
  const { projectId } = useRouteParams(routes.experiments);
  const workspaceName = useWorkspaceNameFromURL();
  return (
    <ProjectDetailsLayoutWithDefaultProjectLoading
      actions={[
        <ButtonWithPopup
          key={0}
          button={(props) => <Button {...props}>Create experiment</Button>}
          popup={(props) => (
            <ExperimentCreationPopup
              {...props}
              projectId={projectId}
              workspaceName={workspaceName}
            />
          )}
        />,
      ]}
    >
      {({ project }) => <ExperimentsTable project={project} />}
    </ProjectDetailsLayoutWithDefaultProjectLoading>
  );
};

export default ExperimentsPage;
