import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';

import { MonitoringModel } from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import { MonitoringAggregation } from 'shared/models/Monitoring/MonitoringModel/MonitoringAggregation';
import { TimeRange } from 'shared/utils/TimeRange';
import {
  MonitoringModelRegisteredModelVersion,
  sortModelVersionsDescend,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringModelRegisteredModelVersion';
import MonitoringFilters from 'features/monitoring/filters/view/MonitoringFilters/MonitoringFilters';
import MonitoringAggregationControl from 'shared/view/domain/Monitoring/MonitoringAggregation/MonitoringAggregationControl/MonitoringAggregationControl';
import MonitoringTimeRangeControl from 'shared/view/domain/Monitoring/MonitoringModel/MonitoringTimeRangeControl/MonitoringTimeRangeControl';
import MonitoringTimeRangeQuickSelect from 'shared/view/domain/Monitoring/MonitoringModel/MonitoringTimeRangeQuickSelect/MonitoringTimeRangeQuickSelect';
import MonitoringModelVersionSelect from 'shared/view/domain/Monitoring/MonitoringModel/MonitoringModelVersionSelect/MonitoringModelVersionSelect';
import MonitoringModelStatusLine from 'shared/view/domain/Monitoring/MonitoringModel/MonitoringModelStatusLine/MonitoringModelStatusLine';
import MonitoringModelFilters from 'shared/view/domain/Monitoring/MonitoringModel/MonitoringModelFilters/MonitoringModelFilters';
import { MonitoringFilter } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';
import StickyHeader from 'features/layout/view/Layout/StickyHeader';

interface Props {
  model: MonitoringModel;
  updateTimeRange: (timeRange: TimeRange) => void;
  updateFilters: (filters: MonitoringFilter[]) => void;
  selectRegisteredModelVersion: (
    x: MonitoringModelRegisteredModelVersion
  ) => void;
  updateAggregation?: (aggregation: MonitoringAggregation) => void;
}

const MonitoringModelBar = (props: Props) => {
  const removeFilter = (id: number) =>
    props.updateFilters(
      props.model.state.filters.filter((filter) => filter.id !== id)
    );

  return (
    <StickyHeader>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Stack direction="row" alignItems={'flex-start'}>
                <MonitoringFilters
                  applyFilters={props.updateFilters}
                  filters={props.model.state.filters}
                  ioDescriptions={props.model.ioDescriptions}
                />

                {/* Added negative margin to compensate space reserved for select label */}
                <div style={{ width: 150, marginTop: '-8px' }}>
                  <MonitoringModelVersionSelect
                    registeredModelVersions={sortModelVersionsDescend(
                      props.model.registeredModelVersions
                    )}
                    selectedRegisteredModelVersion={
                      props.model.state.registeredModelVersion
                    }
                    onChange={props.selectRegisteredModelVersion}
                  />
                </div>
                {/* Added negative margin to compensate space reserved for select label */}
                <div style={{ width: 150, marginTop: '-8px' }}>
                  <MonitoringTimeRangeQuickSelect
                    timeRange={props.model.state.timeRange}
                    updateTimeRange={props.updateTimeRange}
                  />
                </div>
                <MonitoringTimeRangeControl
                  timeRange={props.model.state.timeRange}
                  updateTimeRange={props.updateTimeRange}
                />
                {props.updateAggregation ? (
                  <MonitoringAggregationControl
                    aggregation={props.model.state.aggregation}
                    onAggregationChange={props.updateAggregation}
                    timeRange={props.model.state.timeRange}
                  />
                ) : null}
              </Stack>
            </Grid>

            <Grid item md={6}>
              <MonitoringModelStatusLine model={props.model} />
            </Grid>
          </Grid>
        </Stack>
        {props.model.state.filters.length > 0 ? (
          <MonitoringModelFilters
            filters={props.model.state.filters}
            removeFilter={removeFilter}
          />
        ) : null}
      </Stack>
    </StickyHeader>
  );
};

export default MonitoringModelBar;
