import { MonitoringDataType } from 'generated/types';

import { MonitoringIODescription } from '../MonitoringModel/MonitoringIODescription';

export const getAvailableIODescriptionsForDrift = (
  ioDescriptions: MonitoringIODescription[]
) => {
  return ioDescriptions.filter(
    (io) => io.dataType !== MonitoringDataType.STRING
  );
};
