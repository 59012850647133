import Stack, { StackProps } from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface ItemProps {
  value: number;
  color?: string;
  label?: string;
}

export interface StatsLegendItemProps extends StackProps {
  item: ItemProps;
  height?: number | string;
  valueVariant?: TypographyProps['variant'];
  labelVariant?: TypographyProps['variant'];
}

export const StatsLegendItem = (props: StatsLegendItemProps) => {
  const {
    item,
    height = '12px',
    valueVariant = 'h6',
    labelVariant = 'inputLabel',
    ...other
  } = props;

  const numberHeight = Number(String(height).replace(/[^0-9]/g, ''));

  return (
    <Stack spacing={1} {...other}>
      <Stack spacing={1} direction="row" alignItems="center">
        <Box
          sx={{
            backgroundColor: item.color,
            borderRadius: '4px',
            width: `${numberHeight}px`,
            height: `${numberHeight}px`,
          }}
        />
        <Typography variant={valueVariant}>{item.value}</Typography>
      </Stack>
      <Typography variant={labelVariant}>{item.label}</Typography>
    </Stack>
  );
};
