import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import viewScan from '../images/view-scan-results-1.png';

export const ViewScanResults = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      View scan results
    </Typography>
    <Typography variant="body2" mb={3}>
      Once the scan is finished (duration depends on your model's size), we
      offer a quick view of the results and highlight vulnerabilities based on
      their severity.
    </Typography>
    <img src={viewScan} alt="view scan results" width="90%" height="auto" />
  </WizardStepContent>
);
