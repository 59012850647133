import { useMemo } from 'react';

import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { Size } from 'shared/utils/charts/chartSizes';
import { OnSetReset } from 'shared/utils/charts/zoom/useZoomReset';
import { TimeSeriesDriftWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/TimeSeriesWidget';
import { useDriftOverTimeQuery } from 'features/monitoring/widgets/store/drift/useDriftOverTime';
import { DriftOverTimeQuery } from 'features/monitoring/widgets/store/drift/graphql-types/useDriftOverTime.generated';
import parseGraphqlDate from 'shared/utils/graphql/parseGraphqlDate';
import { parseGraphQLNumber } from 'shared/utils/graphql/parseGraphQLNumber';
import { MonitoringAlert } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/MonitoringAlert';
import { isNotRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { ExtractByTypename } from 'shared/utils/types';

import { MonitoringWidgetProps } from '../shared/types';
import TimeSeriesChart, {
  TimeSeriesChartData,
} from './shared/TimeSeriesChart/TimeSeriesChart';

const TimeSeriesDriftWidgetView = (
  props: MonitoringWidgetProps<TimeSeriesDriftWidget>
) => {
  const { communication, data } = useDriftOverTimeQuery({
    widgetExternalDeps: props.widgetExternalDeps,
    driftMetricType: props.widget.variant.driftMetricType,
    ioDescription: props.widget.variant.ioDescription,
  });

  return (
    <DefaultMatchRemoteDataOrError
      data={data}
      communication={communication}
      context="loading distribution"
    >
      {(loadedData) => (
        <TimeSeriesDriftChart
          id={props.id}
          size={props.size}
          onSetReset={props.onSetReset}
          driftOverTime={loadedData}
          alert={props.widget.alert}
        />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

const TimeSeriesDriftChart = (props: {
  id: string;
  size: Size;
  onSetReset: OnSetReset;
  driftOverTime: ExtractByTypename<
    DriftOverTimeQuery['monitoredEntity'],
    'MonitoredEntity'
  >['metrics']['driftOverTime'];
  alert: MonitoringAlert | undefined;
}) => {
  const data = useMemo(
    () => props.driftOverTime.flatMap(convertData),
    [props.driftOverTime]
  );

  return (
    <TimeSeriesChart
      id={props.id}
      data={data}
      size={props.size}
      yLabel="Drift"
      onSetReset={props.onSetReset}
      alert={props.alert}
    />
  );
};

const convertData = (
  data: ExtractByTypename<
    DriftOverTimeQuery['monitoredEntity'],
    'MonitoredEntity'
  >['metrics']['driftOverTime'][0]
): TimeSeriesChartData[] => {
  return data.time.map((time, index) => ({
    key: isNotRestrictedGraphqlError(data.modelVersion)
      ? data.modelVersion.version
      : data.modelVersionId,
    time: parseGraphqlDate(time),
    value: parseGraphQLNumber(data.values[index]),
    modelVersion: isNotRestrictedGraphqlError(data.modelVersion)
      ? data.modelVersion.version
      : data.modelVersionId,
  }));
};

export default TimeSeriesDriftWidgetView;
