import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import { IWorkspaces } from '../types';
import * as actions from '../actions/actions';

const initial: IWorkspaces['data'] = {
  organizations: [],
  currentWorkspace: { type: 'user' },
};

const dataReducer: Reducer<IWorkspaces['data'], ActionType<typeof actions>> = (
  state = initial,
  action
) => {
  switch (action.type) {
    case getType(actions.setCurrentWorkspace): {
      return { ...state, currentWorkspace: action.payload };
    }
    case getType(actions.setOrganizationWorkspaces): {
      return { ...state, organizations: action.payload };
    }
    case getType(actions.loadOrganizationWorkspaces.success): {
      return {
        ...state,
        organizations: action.payload.organizationWorkspaces,
      };
    }
    default:
      return state;
  }
};

export default dataReducer;
