import * as CrossRunDashboard from 'shared/models/CrossRunDashboard/CrossRunDashboard';
import usePromptUserAboutUnsavedChangesOnLivingRoute from 'shared/view/hooks/usePromptUserAboutUnsavedChangesOnLivingRoute';

import { useDashboardActions } from './useDashboardActions';
import { usePanelActions } from './usePanelActions';
import { useWidgetActions } from './useWidgetActions';
import { IUseCrossRunDashboardProps } from './IUseCrossRunDashboardProps';

export const useCrossRunDashboard = (props: IUseCrossRunDashboardProps) => {
  const isDashboardChanged = !CrossRunDashboard.equals(
    props.selectedCrossRunDashboard,
    props.initialSelectedCrossRunDashboard
  );

  const promptUserAboutUnsavedChangesOnLivingRoute =
    usePromptUserAboutUnsavedChangesOnLivingRoute(isDashboardChanged);

  const dashboardActions = useDashboardActions(props);

  const widgetActions = useWidgetActions(props);

  const panelActions = usePanelActions(props);

  return {
    ...widgetActions,
    ...panelActions,
    ...dashboardActions,
    promptUserAboutUnsavedChangesOnLivingRoute,
    selectedCrossRunDashboard: props.selectedCrossRunDashboard,
    crossRunDashboards: props.crossRunDashboards,
    experimentRuns: props.experimentRuns,
  };
};
