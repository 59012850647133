import {
  alertEvaluationFrequencyForSelection,
  getAlertEvaluationFrequencyLabel,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/AlertEvaluationFrequency';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';

interface Props {
  name: string;
}

const options = alertEvaluationFrequencyForSelection.map((frequency) => ({
  label: getAlertEvaluationFrequencyLabel(frequency),
  value: frequency,
}));

const EvaluationFrequencySelect = (props: Props) => {
  return (
    <SelectField
      name={props.name}
      options={options}
      label={'Evaluation frequency'}
    />
  );
};

export default EvaluationFrequencySelect;
