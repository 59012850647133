import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import Tooltip from 'shared/view/elements/Tooltip/Tooltip';

interface Props {
  onClick: () => void;
  icon: IconDefinition;
  title: string;
  dataTest?: string;
}

const PanelActionIcon = (props: Props) => {
  return (
    <Tooltip type="withSpan" title={props.title}>
      <IconAwesomeClickable
        size="1x"
        icon={props.icon}
        onClick={props.onClick}
        dataTest={props.dataTest}
      />
    </Tooltip>
  );
};

export default PanelActionIcon;
