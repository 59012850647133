import { ComponentProps, FC } from 'react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { TypographyProps } from '@mui/material';

import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import Tooltip from 'shared/view/elements/Tooltip/Tooltip';

import { ICONS } from '../IconAwesome/ICONS';

export interface InfoIconProps {
  tooltip: ComponentProps<typeof Tooltip>['title'];
  iconSize?: SizeProp;
  tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
  color?: TypographyProps['color'];
}

const InfoIcon: FC<React.PropsWithChildren<InfoIconProps>> = (props) => {
  return (
    <Tooltip
      title={props.tooltip}
      type="default"
      placement={props.tooltipPlacement}
    >
      <span style={{ display: 'inline-flex' }}>
        <IconAwesomeInfo
          icon={ICONS.circleInfo}
          size={props.iconSize || 'lg'}
          color={props.color}
        />
      </span>
    </Tooltip>
  );
};

export default InfoIcon;
