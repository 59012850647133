/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { USER_FRAGMENT } from 'shared/graphql/User/User';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';

import * as Types from './graphql-types/DatasetVersion.generated';

export type GraphqlDatasetVersion = Types.DatasetVersionData;

export const DATASET_VERSION_FRAGMENT = gql`
  fragment DatasetVersionData on DatasetVersion {
    id
    datasetID
    dateCreated
    dateUpdated
    description
    dataset {
      id
      name
      visibility
      allowedActions {
        ...AllowedActionsData
      }
      workspace {
        id
        ...WorkspaceData
      }
    }
    tags
    blobInfo
    version
    owner {
      id
      ...UserData
    }
    attributes {
      ...AttributeData
    }
  }
  ${ATTRIBUTE_FRAGMENT}
  ${USER_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;
