import Popup, { PopupProps } from 'shared/view/elements/Popup/Popup';
import { trackEvent } from 'setup/app/analytics';
import { useOnboardingContext } from 'features/homepage/hooks/useOnboarding';
import { Wizard, WizardStepProps } from 'shared/view/elements/Wizard';

import * as slides from './slides';

const steps: WizardStepProps[] = [
  {
    stepLabel: { icon: '1', text: 'Invite Users' },
    slides: [slides.InviteUser],
  },
  {
    stepLabel: { icon: '2', text: 'Manage Users' },
    slides: [slides.ManageUserList, slides.ManageUserAdmins],
  },
  {
    stepLabel: false,
    slides: [slides.InviteYourTeam],
  },
];

export const CollaborateNowPopup = (props: Partial<PopupProps>) => {
  const { updateOnboardingSteps, stepsValues } = useOnboardingContext();

  const onCompleted = async () => {
    await updateOnboardingSteps({ ...stepsValues, step4: true });
    trackEvent({ type: 'homepage.discover_widget.collaborate_now_finished' });
    props.onClose?.();
  };

  const onClose = () => {
    trackEvent({ type: 'homepage.discover_widget.collaborate_now_abandoned' });
    props.onClose?.();
  };

  return (
    <Popup
      title="Collaborate now"
      maxWidth="lg"
      fullWidth
      dialogContentProps={{ height: '1065px' }}
      {...props}
      isOpen={Boolean(props.isOpen)}
      onClose={onClose}
    >
      <Wizard steps={steps} onCompleted={onCompleted} />
    </Popup>
  );
};
