import Stack from '@mui/material/Stack';

import CodeBlock from 'shared/view/elements/CodeBlock/CodeBlock';
import Popup from 'shared/view/elements/Popup/Popup';

export const ConfigPopup = ({
  isPopupOpen,
  closePopup,
  configuration,
}: {
  isPopupOpen: boolean;
  closePopup: () => void;
  configuration: string;
}) => {
  return (
    <Popup
      title="Configuration"
      isOpen={isPopupOpen}
      onClose={closePopup}
      maxWidth="xs"
      fullWidth
      scroll="body"
      dataTest="evaluation-commment-edit-popup"
      buttons={{
        mainButtonProps: {
          type: 'button',
          dataTest: 'close-popup-button',
          children: 'Close',
          onClick: closePopup,
          isLoading: false,
          disabled: false,
        },
      }}
    >
      <Stack spacing={3}>
        <CodeBlock>{configuration}</CodeBlock>
      </Stack>
    </Popup>
  );
};
