import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import { max } from 'lodash';

import { selectFlags } from 'features/flags';
import routes from 'shared/routes';
import { VertaLogo } from 'shared/view/elements/VertaLogo';

import LoginByEmailForm from './shared/LoginByEmailForm';
import LoginByActiveDirectory from './shared/LoginByActiveDirectory';
import SignupByEmailForm from './shared/SignupByEmailForm/SignupByEmailForm';
import { SocialButtons } from '../shared/SocialButtons';
import Auth0 from './shared/Auth0';
import { Layout, LeftSlot, RightSlot } from '../shared/Layout';
import { VertaOverall } from '../shared/VertaOverall';
import { CopyRightFooter } from '../shared/CopyRightFooter';
import { VertaOverallFreeTier } from '../shared/VertaOverallFreeTier';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const LoginPage = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const [isActiveDirectory, setIsActiveDirectory] = useState<boolean>(false);
  const { isEnableFreeTier, isEnableAuth0Login } = useSelector(selectFlags);
  const { height = 450, ref } = useResizeDetector();
  const { height: windowHeight } = useWindowDimensions();

  const goToSignUp = () => {
    if (isEnableFreeTier) {
      navigate(routes.signUp.getPath(), {
        state: { showBackButton: false },
      });
    }

    setIsLogin(false);
  };

  const goToActiveDirectory = () => {
    if (isEnableFreeTier) {
      return;
    }

    setIsActiveDirectory(true);
  };

  const maxHeight = useMemo(() => {
    return `${max([height + 64, windowHeight]) ?? height}px`;
  }, [height, windowHeight]);

  return (
    <Layout sx={{ backgroundColor: isEnableFreeTier ? '#FFF' : undefined }}>
      <LeftSlot>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          padding={{ xxs: 5, sm: 4 }}
          ref={ref}
          minHeight="100vh"
        >
          <Box mt={4} mb={6}>
            <VertaLogo
              width="76"
              height="24"
              color="primary.main"
              onClickRedirect={false}
              onClick={() => {
                window.location.replace('https://www.verta.ai/');
              }}
            />
          </Box>
          <Box
            flex="1 1 auto"
            mb={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {isEnableFreeTier && isEnableAuth0Login && <Auth0 />}

            {!isEnableFreeTier && isLogin && !isActiveDirectory && (
              <LoginByEmailForm
                goToSignUp={goToSignUp}
                goToActiveDirectory={goToActiveDirectory}
              />
            )}

            {!isEnableFreeTier && isActiveDirectory && (
              <LoginByActiveDirectory
                goBack={() => {
                  setIsLogin(true);
                  setIsActiveDirectory(false);
                }}
              />
            )}

            {!isLogin && !isEnableFreeTier && !isActiveDirectory && (
              <SignupByEmailForm
                goBack={() => {
                  setIsLogin(true);
                  setIsActiveDirectory(false);
                }}
              />
            )}
          </Box>
          <Box flex="0 1 40px" display="flex">
            <SocialButtons />
          </Box>
        </Box>
      </LeftSlot>

      <RightSlot
        sx={{ backgroundColor: isEnableFreeTier ? '#F7FAFC' : undefined }}
      >
        <Box
          overflow="auto"
          height="100%"
          px={7}
          pt={20}
          pb={4}
          maxHeight={maxHeight}
          justifyContent="center"
          display="flex"
        >
          <Box maxWidth="md">
            {isEnableFreeTier ? <VertaOverallFreeTier /> : <VertaOverall />}
            <CopyRightFooter />
          </Box>
        </Box>
      </RightSlot>
    </Layout>
  );
};

export default LoginPage;
