import { AutoscalingMetricParameterType } from 'generated/types';
import { skipWhenEmpty, validatePositiveNumber } from 'shared/utils/validators';
import {
  validateLatency,
  parseLatency,
  formatLatency,
} from 'shared/utils/latency/latency';
import {
  validatePercentage,
  parsePercentage,
  formatPercentage,
} from 'shared/utils/percentage/percentage';
import matchType from 'shared/utils/matchType';

export const autoscalingQuantity = {
  validate: skipWhenEmpty(validatePositiveNumber),
  parser: Number,
  format: (x: string) => x,
};

export const autoscalingMetricParameterInfo = {
  validate: (type: AutoscalingMetricParameterType) =>
    skipWhenEmpty(
      matchType(
        {
          COUNT: () => validatePositiveNumber,
          LATENCY_MS: () => validateLatency('Parameter'),
          PERCENTAGE: () => validatePercentage('Parameter'),
          UNKNOWN: () => () => undefined,
        },
        type
      )
    ),

  parser: (type: AutoscalingMetricParameterType) => {
    return matchType(
      {
        COUNT: () => (value: string) => value,
        LATENCY_MS: () => (value: string) => String(parseLatency(value)),
        PERCENTAGE: () => (value: string) => String(parsePercentage(value)),
        UNKNOWN: () => (value: string) => value,
      },
      type
    );
  },

  format: (type: AutoscalingMetricParameterType, value: string) => {
    return matchType(
      {
        COUNT: () => value,
        LATENCY_MS: () => formatLatency(value),
        PERCENTAGE: () => formatPercentage(value),
        UNKNOWN: () => value,
      },
      type
    );
  },
};
