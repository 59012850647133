import { useState } from 'react';
import Typography from '@mui/material/Typography';

import { ChipProps } from 'shared/view/elements/Chip/Chip';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import OptionalTooltip from 'shared/view/elements/Tooltip/OptionalTooltip';

import AddTagModal from './AddTagModal/AddTagModal';
import Tag from './Tag/Tag';
import { ContentWrapper, PlaceholderWrapper } from './styled-components';

type Props = {
  tags: string[];
  tagProps?: Pick<ChipProps, 'color' | 'variant' | 'size' | 'sx'>;
  isUpdating: boolean;
  isEditable: boolean;
  renderPlaceholder?: boolean;
  onAddTag(tag: string): void;
  onRemoveTag(tag: string): void;
  onTagClicked?(e: React.MouseEvent<Element, MouseEvent>, tag: string): void;
  getTagProps?(tag: string): ChipProps;
};

const TagsManager = (props: Props) => {
  const [isShowAddTagActionModal, setIsShowAddTagActionModal] = useState(false);
  const {
    tags,
    tagProps,
    isUpdating,
    isEditable,
    onAddTag,
    onRemoveTag,
    onTagClicked,
    renderPlaceholder = true,
    getTagProps,
  } = props;

  const showAddTagModal = () => {
    setIsShowAddTagActionModal(true);
  };

  const closeAddTagModal = () => {
    setIsShowAddTagActionModal(false);
  };

  const handleAddTag = (tag: string) => {
    onAddTag(tag);
  };

  return (
    <ContentWrapper data-test="tags-manager">
      {tags.length === 0 && renderPlaceholder ? (
        <PlaceholderWrapper>
          <OptionalTooltip
            showTooltip={!isEditable}
            type="withSpan"
            title="Read only"
          >
            <Typography variant="body2" color="text.disabled">
              {isEditable ? 'Add' : 'No'} Labels
            </Typography>
          </OptionalTooltip>
        </PlaceholderWrapper>
      ) : null}
      {tags.map((tag: string, i: number) => (
        <Tag
          key={i}
          value={tag}
          isEditable={isEditable}
          isUpdating={isUpdating}
          onDelete={onRemoveTag}
          onClick={onTagClicked}
          getTagProps={getTagProps}
          {...tagProps}
        />
      ))}
      <IconAwesomeClickable
        icon={ICONS.circlePlus}
        onClick={showAddTagModal}
        size="lg"
        dataTest="tags-manager-open-tag-creator-button"
        disabled={!isEditable}
      />
      {isShowAddTagActionModal && (
        <AddTagModal
          isOpen={isShowAddTagActionModal}
          tags={tags}
          tagProps={tagProps}
          isUpdating={isUpdating}
          isEditable={isEditable}
          onRemove={onRemoveTag}
          onAdd={handleAddTag}
          onClose={closeAddTagModal}
        />
      )}
    </ContentWrapper>
  );
};

export default TagsManager;
