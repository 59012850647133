import React from 'react';
import cn from 'classnames';

import styles from './DefaultTooltipContent.module.css';

interface ILocalProps {
  label: string;
  children: React.ReactNode;
  labelColor?: string;
  isActive?: boolean;
}

const DefaultTooltipContent: React.FC<React.PropsWithChildren<ILocalProps>> = ({
  label,
  labelColor,
  children,
  isActive,
}) => {
  return (
    <div className={cn(styles.root, { [styles.active]: isActive })}>
      <span
        className={styles.label}
        style={{ color: labelColor, fontWeight: 'bold' }}
      >{`${label}: `}</span>
      <span className={styles.content}>{children}</span>
    </div>
  );
};

export default DefaultTooltipContent;
