import { gql } from '@apollo/client';

import { ErrorCode } from 'generated/types';
import { AppError, CustomError } from 'shared/models/Error';
import isNotNil from 'shared/utils/isNotNill';
import matchType from 'shared/utils/matchType';

export const RESTRICTED_GRAPHQL_ERROR_FRAGMENT = gql`
  fragment ErrorData on Error {
    code
    message
  }
`;

export type RestrictedGraphqlError = {
  __typename: 'Error';
  message: string;
  code: ErrorCode;
};

export type RestrictedGraphqlErrorOrData<T> = RestrictedGraphqlError | T;

export const isRestrictedGraphqlError = (
  data: unknown
): data is RestrictedGraphqlError => {
  return Boolean(
    typeof data === 'object' &&
      isNotNil(data) &&
      '__typename' in data &&
      (data as any).__typename === 'Error'
  );
};

export const isNotRestrictedGraphqlError = <
  T extends RestrictedGraphqlErrorOrData<unknown>,
>(
  errorOrData: T
): errorOrData is Exclude<T, { __typename: 'Error' }> => {
  return !isRestrictedGraphqlError(errorOrData);
};

export type NotNullableRestrictedGraphqlError<
  T extends RestrictedGraphqlErrorOrData<unknown>,
> = Exclude<T, { __typename: 'Error' } | null | undefined>;

export const isNotNullableRestrictedGraphqlError = <
  T extends RestrictedGraphqlErrorOrData<unknown>,
>(
  errorOrData: T | null | undefined
): errorOrData is NotNullableRestrictedGraphqlError<T> => {
  return isNotNil(errorOrData) && !isRestrictedGraphqlError(errorOrData);
};

export function mapDataOrError<
  F1 extends RestrictedGraphqlErrorOrData<unknown>,
  F2 extends RestrictedGraphqlErrorOrData<unknown>,
  R,
>(
  f1: F1,
  f2: (f1: Exclude<F1, { __typename: 'Error' }>) => F2,
  f3: (res: Exclude<F2, { __typename: 'Error' }>) => R
): R | RestrictedGraphqlError;
export function mapDataOrError<
  F1 extends RestrictedGraphqlErrorOrData<unknown>,
  R,
>(
  f1: F1,
  f2: (res: Exclude<F1, { __typename: 'Error' }>) => R
): R | RestrictedGraphqlError;
export function mapDataOrError<
  F1 extends RestrictedGraphqlErrorOrData<unknown>,
  R,
>(data: F1, ...fs: Array<(x: unknown) => unknown>): R | RestrictedGraphqlError {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return fs.reduce((res, f) => {
    if (isNotNullableRestrictedGraphqlError(res)) {
      return f(res);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return res as any;
  }, data) as any;
}

export const restrictedGraphqlErrorToAppError = (
  r: RestrictedGraphqlError
): AppError => {
  return matchType(
    {
      Forbidden: () => new CustomError('Permission denied'),
      NotFound: () => new CustomError('Not found'),
    },
    r.code
  );
};
