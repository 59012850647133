import { Context, createContext, FC, FunctionComponent } from 'react';

export default function factory<P, C = P>(
  apply?: (
    ...args: Parameters<FunctionComponent<React.PropsWithChildren<P>>>
  ) => C
): [Context<C>, FC<React.PropsWithChildren<P>>] {
  const context = createContext<C>({} as C);
  const Provider: FC<React.PropsWithChildren<P>> = (...reactRenderContext) => {
    const [{ children }] = reactRenderContext;
    const { Provider: HocProvider } = context;
    return (
      <HocProvider value={apply?.(...reactRenderContext) ?? ({} as C)}>
        {children}
      </HocProvider>
    );
  };
  return [context, Provider];
}
