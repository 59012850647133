import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { ATTRIBUTE_FRAGMENT } from 'shared/graphql/Attribute/Attribute';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import {
  Workflow,
  WorkflowStatus,
  WorkflowTemplate,
} from 'shared/models/Registry/RegisteredModelVersion/Workflows/Workflow';
import capitalize from 'shared/utils/capitalize';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { safeParseJson } from 'shared/utils/json';
import { ExtractByTypename } from 'shared/utils/types';

import {
  ModelVersionWorkflows,
  ModelVersionWorkflowsVariables,
} from './graphql-types/useModelVersionWorkflows.generated';

type RegisteredModelVersion = ExtractByTypename<
  ModelVersionWorkflows['registeredModelVersion'],
  'RegisteredModelVersion'
>;

const WORKFLOWS_QUERY = gql`
  query ModelVersionWorkflows($versionId: ID!) {
    registeredModelVersion(id: $versionId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        version
        attributes {
          ...AttributeData
        }
      }
    }
  }
  ${ATTRIBUTE_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export type ParsedWorkflows = {
  active: Workflow[];
  complete: Workflow[];
  templates: WorkflowTemplate[];
};

export const useModelVersionWorkflows = ({
  versionId,
}: {
  versionId: string;
}) => {
  const query = useCustomQuery<
    ModelVersionWorkflows,
    ModelVersionWorkflowsVariables
  >(WORKFLOWS_QUERY, {
    variables: { versionId },
    fetchPolicy: 'network-only',
  });

  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert: convert,
    queryResult: query,
  });

  return {
    data,
    communication,
    reload: query.refetch,
  };
};

const convert = (res: ModelVersionWorkflows) => {
  return mapDataOrError(res.registeredModelVersion, (modelVersion) => {
    const workflowStatus = parseAttributeValue<{
      workflow_status: { active: Workflow[]; complete: Workflow[] };
    }>('workflow_status', modelVersion.attributes);

    const workflowTemplates = parseAttributeValue<{
      workflow_templates: WorkflowTemplate[];
    }>('workflow_templates', modelVersion.attributes);

    return {
      ...modelVersion,
      active: workflowStatus?.workflow_status.active.map(convertWorkflow) ?? [],
      complete:
        workflowStatus?.workflow_status.complete.map(convertWorkflow) ?? [],
      templates: workflowTemplates?.workflow_templates ?? [],
    };
  });
};

const convertWorkflow = <
  T extends { status: WorkflowStatus; steps: Array<S> },
  S extends { status: WorkflowStatus },
>(
  workflow: T
): T => {
  return {
    ...workflow,
    status: formatStatus(workflow.status),
    steps: workflow.steps.map((d) => ({
      ...d,
      status: formatStatus(d.status),
    })),
  };
};

const formatStatus = (status: WorkflowStatus): WorkflowStatus =>
  status
    .split(' ')
    .map((s) => capitalize(s))
    .join(' ') as WorkflowStatus;

const parseAttributeValue = <Data,>(
  key: string,
  attributes: RegisteredModelVersion['attributes']
): Data | undefined => {
  const attr = attributes.find((a) => a.key === key);

  if (attr && attr.__typename === 'JsonKeyValue') {
    return safeParseJson<Data>(attr.content);
  }

  return undefined;
};
