import React from 'react';
import { Helmet } from 'react-helmet';
import { createContext, useState, useContext } from 'react';

import { IBreadcrumbItem } from 'shared/view/elements/Breadcrumbs/Breadcrumbs';

type Props = {
  children: Exclude<React.ReactNode, null | undefined>;
};

type State = {
  title: string;
  setTitle: (title: string) => void;
  setTitleByBreadcrumbs: (breadcrumbs: IBreadcrumbItem[]) => void;
};

const DEFAULT_SEO_TITLE = 'Verta AI';

const initialState: State = {
  title: DEFAULT_SEO_TITLE,
  setTitle: () => undefined,
  setTitleByBreadcrumbs: () => undefined,
};

const SeoContext = createContext(initialState);

export const SEOProvider = ({ children }: Props) => {
  const [title, setTitle] = useState<string>(DEFAULT_SEO_TITLE);

  const setTitleByBreadcrumbs = (breadcrumbs?: IBreadcrumbItem[]) => {
    const breadcrumbTitle: string = breadcrumbs
      ? getTitleByBreadcrumbs(breadcrumbs)
      : DEFAULT_SEO_TITLE;

    setTitle(breadcrumbTitle);
  };

  const value = {
    title,
    setTitle,
    setTitleByBreadcrumbs,
  };

  return (
    <SeoContext.Provider value={value}>
      <Helmet>
        <title>{value.title}</title>
      </Helmet>
      {children}
    </SeoContext.Provider>
  );
};

const getTitleByBreadcrumbs = (breadcrumbs: IBreadcrumbItem[]) => {
  const isLoading = breadcrumbs.some((breadcrumb) => !breadcrumb.isLoaded);

  if (isLoading) {
    return '...Loading';
  }

  return breadcrumbs.map((breadcrumb) => breadcrumb.name).join(' > ');
};

export const useSeoContext = () => useContext(SeoContext);
