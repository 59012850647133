import { MutationHookOptions } from '@apollo/client';

import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import {
  ADD_ATTRIBUTES,
  EvaluationAddAttributes,
  EvaluationAddAttributesVariables,
} from '../graphql';

export const useAddAttributes = (
  options?: MutationHookOptions<
    EvaluationAddAttributes,
    EvaluationAddAttributesVariables
  >
) => {
  const [addAttributes, addingAttributes] = useCustomMutation<
    EvaluationAddAttributes,
    EvaluationAddAttributesVariables
  >(ADD_ATTRIBUTES, { context: 'creating attributes' }, { ...options });

  return { addAttributes, addingAttributes };
};
