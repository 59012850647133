import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';

import ImportPage from './ImportPage';
import ImportedModelsPage from './ImportedModelsPage';

export const modelImportPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.importedModels.getPath(),
      element: <ImportedModelsPage />,
    },
    {
      path: routes.import.getPath(),
      element: <ImportPage />,
    },
  ],
};
