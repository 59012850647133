import { init, last } from 'ramda';

interface AlertInterval {
  isAlerting: boolean;
  key: string;
}

interface ViolatingInterval {
  start: string;
  end: string | null;
}

export const makeViolatingIntervals = (
  history: AlertInterval[]
): ViolatingInterval[] =>
  history.reduce<ViolatingInterval[]>((intervals, historyItem) => {
    const lastInterval = last(intervals);

    if (historyItem.isAlerting && (!lastInterval || lastInterval.end)) {
      return intervals.concat({
        start: historyItem.key,
        end: null,
      });
    }

    const isOk = !historyItem.isAlerting;
    if (isOk && lastInterval && !lastInterval.end) {
      return init(intervals).concat({
        start: lastInterval.start,
        end: historyItem.key,
      });
    }

    return intervals;
  }, []);
