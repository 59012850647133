import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

import { SelectIconComponent } from 'shared/view/elements/Selects/shared/view/SelectIconComponent';

interface Props {
  value: Array<{ label: string; value: string }>;
  label: string;
  options: Array<{ label: string; value: string }>;
  name?: string;
  onChange(v: Array<{ label: string; value: string }>): void;
}

const MultiAutocomplete = (props: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <Autocomplete
      sx={{
        '#multiple-limit-tags':
          // todo: add comment to explain why we need this logic
          props.value.length > 0 && !isOpened
            ? {
                height: 0,
                padding: '1px',
              }
            : {},
      }}
      forcePopupIcon
      multiple
      limitTags={2}
      id="multiple-limit-tags"
      value={props.value}
      options={props.options}
      open={isOpened}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(o, v) => o.value === v.value}
      ChipProps={{ size: 'small' }}
      onFocus={() => setIsOpened(true)}
      onOpen={() => setIsOpened(true)}
      onBlur={() => setIsOpened(false)}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            name={props.name}
            size="small"
            label={props.label}
          />
        );
      }}
      onChange={(_, v) =>
        props.onChange(v as { label: string; value: string }[])
      }
      popupIcon={<SelectIconComponent />}
    />
  );
};

export default MultiAutocomplete;
