import { QueryHookOptions } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { mapDataOrError } from 'shared/graphql/ErrorFragment';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';

import {
  CustomDashboardSummariesCharts,
  CustomDashboardSummariesChartsVariables,
} from '../graphql/graphql-types/queries.generated';
import { CUSTOM_DASHBOARD_SUMMARIES_CHARTS } from '../graphql/queries';

export const useCustomDashboard = (
  props: {
    orgId: string;
  },
  options?: QueryHookOptions<
    CustomDashboardSummariesCharts,
    CustomDashboardSummariesChartsVariables
  >
) => {
  const { orgId } = props;

  const query = useCustomQuery<
    CustomDashboardSummariesCharts,
    CustomDashboardSummariesChartsVariables
  >(CUSTOM_DASHBOARD_SUMMARIES_CHARTS, {
    ...options,
    variables: {
      orgId,
    },
  });

  const { data, communication } = resultToCommunicationWithData(
    convertResponse,
    query
  );

  return {
    data,
    communication,
    reload: query.refetch,
  };
};

const convertResponse = ({ organizationV2 }: CustomDashboardSummariesCharts) =>
  mapDataOrError(
    organizationV2,
    (_organizationV2) => _organizationV2.customDashboard
  );
