import { ActionType, createReducer } from 'typesafe-actions';

import { ToastState } from './types';
import * as actions from './actions';

const initialState: ToastState = {
  toasts: [],
};

export const reducer = createReducer<ToastState, ActionType<typeof actions>>(
  initialState
)
  .handleAction(actions.toastAction, (state, action) => ({
    toasts: state.toasts.concat({
      key: new Date().toISOString(),
      dismissed: false,
      message: action.payload.message,
      options: { variant: action.payload.variant },
    }),
  }))
  .handleAction(actions.createToastAction, (state, action) => ({
    toasts: state.toasts.concat(action.payload),
  }))
  .handleAction(actions.closeToastAction, (state, action) => ({
    toasts: state.toasts.map((toast) =>
      toast.key === action.payload ? { ...toast, dismissed: true } : toast
    ),
  }))
  .handleAction(actions.removeToastAction, (state, action) => ({
    toasts: state.toasts.filter((toast) => toast.key !== action.payload),
  }));
