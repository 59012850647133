export const getContextualHelper = (context: string) => {
  switch (context) {
    case 'autoscaling':
      return `Autoscaling capabilities allow the system to adapt to changing query loads. Verta models are always warm, i.e there is always at least one copy of a deployed model running and can instantly serve requests. Verta manages replicas of models to ensure models scale based on traffic patterns. You can define your scale-up and scale-down replica configuration and choose to trigger auto scaling based on memory utilization, CPU utilization, or the number of requests per worker.`;
    case 'model':
      return `For updating an endpoint during a rollout, deploy a model either from Model Registry (with a Registered Model Version) or Projects (via an Experiment Run).

      Deploy from Catalog:
      Recommended for production and staged releases, choose a Registered Model and specific version.
      
      Deploy from Projects:
      For quick ad hoc deployment and testing, use an Experiment Run. It corresponds to one execution of a modeling script in Verta’s experiment management module, representing a particular Experiment configuration. Provide the Experiment Run ID for deployment.
      
      Build:
      A new build triggers upon endpoint update, with an option to use an existing build.`;
    case 'environment':
      return 'Environment variables let you control specific logic in your application dynamically during runtime. For example, if you want to add different behavior for production vs development endpoint, you can use VAR_DEV or VAR_PROD variables for the system to behave differently based on the value. Another example can be PREPROCESSING_METHOD=none and PREPROCESSING_METHOD=tokenizer.';
    case 'rollout':
      return `The rollout strategy lets you define how you want to deploy or update your service. You can choose direct or canary rollouts for endpoints.

      With the direct rollout, all your workloads are quickly switched to the new model version.
      
      Canary deploys update versions by progressively switching traffic from one version of the instances to another. It helps you deploy the change impacting a smaller number of requests and users to analyze the impact. In addition, you can also control what percentage of traffic is routed to new deployment for a controlled traffic cutover.
      
      This is a safe deployment choice with the option to hold the updates if the release does not meet performance expectations.
      
      You can configure the following canary parameters:
      
      Interval: The time duration for each phase of the rollout (30s, 1m, 5m. 15m, 30m, 1hr, etc.)
      Steps: The percentage of production workload getting the new build with each phase of the canary roll-out
      Rules & Thresholds: Define one or more rules and thresholds that determine when to auto roll back the release. For example; if the maximum error percentage is above a defined threshold or maximum average latency is more than the acceptable limit etc`;
    default:
      return '';
  }
};
