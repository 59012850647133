import { ConfigureStore } from '../store';
import configureStoreProduction from './configureStore.production';
import configureStoreDevelopment from './configureStore.development';

const configureStore: ConfigureStore =
  process.env.NODE_ENV === 'production'
    ? configureStoreProduction
    : configureStoreDevelopment;

export default configureStore;
