/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { entityTypeWithCollaboratorsToString } from 'features/collaborators/view/Collaboration/shared/entityTypeWithCollaboratorsToString';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import {
  isNotNullableRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';

import * as GraphqlTypes from './graphql-types/useDeleteEndpoint.generated';

const DELETE_ENDPOINT = gql`
  mutation DeleteEndpoint(
    $workspaceName: String!
    $organizationId: ID
    $id: ID!
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoint(id: $id) {
          ... on Error {
            ...ErrorData
          }
          ... on Endpoint {
            id
            delete
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useDeleteEndpoint = (onCompleted?: () => void) => {
  const [deleteEntity, deletingEntity] = useCustomMutation<
    GraphqlTypes.DeleteEndpoint,
    GraphqlTypes.DeleteEndpointVariables
  >(
    DELETE_ENDPOINT,
    { context: `deleting ${entityTypeWithCollaboratorsToString('endpoint')}` },
    {
      onCompleted: ({ workspace }) => {
        if (
          onCompleted &&
          isNotNullableRestrictedGraphqlError(workspace) &&
          isNotNullableRestrictedGraphqlError(workspace.endpoint)
        ) {
          onCompleted();
        }
      },
    }
  );

  return { deleteEntity, deletingEntity };
};
