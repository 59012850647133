import { useState, useCallback, useMemo } from 'react';

import {
  getAvailableWidgetTypesByMonitoredModelType,
  getDescriptionByMonitoringWidgetType,
  getImageByMonitoringWidgetType,
  getLabelByMonitoringWidgetType,
  InitializedMonitoringWidgetType,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetType';
import WidgetBuilderTabs from 'shared/view/domain/Dashboards/WidgetBuilderTabs/WidgetBuilderTabs';
import WidgetFormPopup from 'shared/view/domain/Dashboards/WidgetFormPopup';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';

import MonitoringWidgetForm, {
  MonitoringWidgetFormType,
} from '../../MonitoringWidgetForm/MonitoringWidgetForm';

interface Props {
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  onSubmit: (form: MonitoringWidgetFormType) => void;
  closePopup: () => void;
  openPopup: () => void;
}

const MonitoringWidgetBuilder = (props: Props) => {
  const [type, changeType] = useState<InitializedMonitoringWidgetType>();

  const onBack = useCallback(() => {
    changeType(undefined);
  }, []);

  const widgetInfos = useMemo(
    () =>
      getAvailableWidgetTypesByMonitoredModelType(
        props.widgetExternalDeps.monitoredModelType
      ).map(makeWidgetInfo),
    [props.widgetExternalDeps.monitoredModelType]
  );

  if (type) {
    return (
      <WidgetFormPopup isOpen closePopup={props.closePopup}>
        <MonitoringWidgetForm
          submitText="Create"
          initialValues={null}
          widgetType={type}
          onSubmit={props.onSubmit}
          onBack={onBack}
          widgetExternalDeps={props.widgetExternalDeps}
        />
      </WidgetFormPopup>
    );
  }

  return <WidgetBuilderTabs infos={widgetInfos} changeType={changeType} />;
};

const makeWidgetInfo = (type: InitializedMonitoringWidgetType) => ({
  description: getDescriptionByMonitoringWidgetType(type),
  image: getImageByMonitoringWidgetType(type),
  label: getLabelByMonitoringWidgetType(type),
  type,
});

export default MonitoringWidgetBuilder;
