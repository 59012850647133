import { gql } from '@apollo/client';

import { ARTIFACT_FRAGMENT } from 'shared/graphql/Artifact/Artifact';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

export const MODEL_VERSION_DOCUMENTATION_DATA_QUERY = gql`
  query ModelVersionDocumentationData($id: ID!) {
    registeredModelVersion(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModelVersion {
        id
        readmeText
        inputDescription
        outputDescription
        artifacts {
          ...ArtifactData
        }
        registeredModel {
          id
          name
          dataType
          taskType
          allowedActions {
            ...AllowedActionsData
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${ARTIFACT_FRAGMENT}
`;
