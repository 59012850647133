import NotFound from 'shared/view/elements/NotFoundComponents/NotFound/NotFound';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import routes from 'shared/routes';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';

const NotFoundPage = () => {
  const breadcrumbs = useAppBreadcrumbs(
    useBreadcrumbItem({
      route: routes.root,
      name: 'Not found',
    })
  );

  return (
    <AuthorizedLayout pageEntityOrError={undefined} breadcrumbs={breadcrumbs}>
      <NotFound errorMessage="Page not found" />
    </AuthorizedLayout>
  );
};

export default NotFoundPage;
