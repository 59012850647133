import { ApolloError } from '@apollo/client';

import { AppError, AppApolloError } from 'shared/models/Error';

import {
  isServerError,
  isServerParseError,
  getHttpErrorFromServerError,
} from './apolloError';
import normalizeError from '../normalizeError';
import { isNotEmptyArray } from '../collection';

const showTrace = (error: ApolloError) => {
  if (
    window.location.host.includes('dev.verta.ai') ||
    window.location.host.includes('localhost:3001')
  ) {
    /* eslint-disable no-console */
    console.trace(error);
  }
};

const apolloErrorToAppError = (apolloError: ApolloError): AppError => {
  showTrace(apolloError);
  if (
    apolloError.networkError &&
    (isServerError(apolloError.networkError) ||
      isServerParseError(apolloError.networkError))
  ) {
    return getHttpErrorFromServerError(apolloError.networkError);
  }

  // todo probably legacy code and can produce malfunctions. Need to be checked and fixed;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const graphqlErrors = apolloError.graphQLErrors ?? [];
  if (isNotEmptyArray(graphqlErrors)) {
    return new AppApolloError({
      errors: graphqlErrors,
    });
  }

  return normalizeError(new Error('Unknown error'));
};

// implement correct check
export const apolloErrorFromUnknownToAppError = (
  apolloError: unknown
): AppError => apolloErrorToAppError(apolloError as ApolloError);

export default apolloErrorToAppError;
