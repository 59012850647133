import FormControl from '@mui/material/FormControl';
import { KeyboardEvent, FocusEvent, MouseEvent, useCallback } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import { BaseTextFieldProps } from '@mui/material/TextField';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import { TextFieldProps } from '@mui/material/TextField';
import { BoxProps } from '@mui/material/Box';
import { Theme, SxProps } from '@mui/material';

import FormHelperText from 'shared/view/elements/FormHelperText/FormHelperText';

import FieldBox from '../../FieldBox/FieldBox';

type Props = BoxProps & {
  children: JSX.Element;
  errorMessage?: string;
  hint?: string | JSX.Element;
  withoutError?: boolean;
  withoutLabelPadding?: boolean;
  dataTestError?: string;
};

export function InputBase(props: Props) {
  const {
    errorMessage,
    children,
    dataTestError,
    hint,
    withoutError,
    withoutLabelPadding,
    ...other
  } = props;

  return (
    <FieldBox pt={Boolean(withoutLabelPadding) ? 0 : 1} {...other}>
      <FormControl fullWidth error={Boolean(errorMessage)}>
        {children}
        {!withoutError ? (
          <FormHelperText
            hint={hint}
            error={Boolean(errorMessage)}
            errorMessage={errorMessage}
            dataTestError={dataTestError}
          />
        ) : null}
      </FormControl>
    </FieldBox>
  );
}

export type InputCommonProps = Pick<BoxProps, 'maxWidth' | 'minWidth'> &
  Pick<BaseTextFieldProps, 'size' | 'onKeyDown' | 'className'> & {
    hint?: string | JSX.Element;
    label?: string;
    startAdornmentIcons?: JSX.Element[];
    endAdornmentIcons?: JSX.Element[];
    name?: string;
    disabled?: boolean;
    readOnly?: boolean;
    isRequired?: boolean;
    dataTest?: string;
    dataTestError?: string;
    withoutError?: boolean;
    withoutLabelPadding?: boolean;
    errorMessage?: string;
    onKeyUp?(e: KeyboardEvent<HTMLDivElement>): void;
    onBlur?(e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>): void;
    onClick?(e: MouseEvent<HTMLDivElement>): void;
    onEnter?(): void;
    meta?: { touched: boolean; error?: string | null };
    rows?: number;
    placeholder?: string;
    sx?: SxProps<Theme>;
  };

export function useInputCommonProps(
  props: InputCommonProps,
  inputProps?: InputBaseComponentProps
) {
  let errorMessage =
    props.meta?.touched && props.meta.error ? props.meta.error : undefined;
  if (errorMessage === undefined) {
    errorMessage = props.errorMessage;
  }

  const hint = props.hint;

  const labelAppend = props.isRequired ? ' *' : '';
  const label = `${props.label || ''}${labelAppend}`;

  const { onEnter, onKeyUp: _onKeyUp } = props;

  const onKeyUp = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === 13) {
        onEnter?.();
      }
      _onKeyUp?.(e);
    },
    [onEnter, _onKeyUp]
  );

  const fieldProps: TextFieldProps & {
    'data-test'?: string;
  } = {
    label,
    onKeyUp,
    InputProps: {
      placeholder: props.placeholder,
      inputProps,
      readOnly: props.readOnly,
      startAdornment: props.startAdornmentIcons ? (
        <InputAdornment position="start">
          <Stack direction={'row'} spacing={0.5}>
            {props.startAdornmentIcons.map((icon) => icon)}
          </Stack>
        </InputAdornment>
      ) : null,
      endAdornment: props.endAdornmentIcons ? (
        <InputAdornment position="end">
          <Stack direction={'row'} spacing={0.5}>
            {props.endAdornmentIcons.map((icon) => icon)}
          </Stack>
        </InputAdornment>
      ) : null,
      maxRows: props.rows !== undefined ? undefined : 5,
    },
    rows: props.rows,
    name: props.name,
    'data-test': props.dataTest,
    disabled: props.disabled,
    onBlur: props.onBlur,
    onClick: props.onClick,
    size: props.size || 'small',
    variant: 'outlined' as const,
    error: Boolean(errorMessage),
    onKeyDown: props.onKeyDown,
    className: props.className,
    sx: props.sx,
  };

  return {
    fieldProps,
    baseProps: {
      withoutError: props.withoutError,
      withoutLabelPadding: props.withoutLabelPadding,
      errorMessage,
      dataTestError: props.dataTestError,
      minWidth: props.minWidth,
      maxWidth: props.maxWidth,
      hint,
    },
  };
}
