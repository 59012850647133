import { TableWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/TableWidget';
import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';

import OutlierDetectionTableWidget from './OutlierDetectionTableWidget/OutlierDetectionTableWidget';
import { MonitoringWidgetProps } from '../shared/types';
import DriftTableWidgetView from './DriftTableWidget/DriftTableWidget';

const TableWidgetView = (props: MonitoringWidgetProps<TableWidget>) => {
  switch (props.widget.variant.type) {
    case 'outlierDetection':
      return (
        <OutlierDetectionTableWidget
          {...props}
          widget={{ ...props.widget, variant: props.widget.variant }}
        />
      );
    case 'drift':
      return (
        <DriftTableWidgetView
          {...props}
          widget={{ ...props.widget, variant: props.widget.variant }}
        />
      );
    default:
      exhaustiveCheck(props.widget.variant);
  }
};

export default TableWidgetView;
