import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';

import { WidgetSettings } from './CrossRunCustomWidget/WidgetSettings/WidgetSettings';

export type CrossRunWidgetType = CrossRunWidget['settings']['type'];
export const crossRunWidgetTypes = exhaustiveStringTuple<CrossRunWidgetType>()(
  'scatterChart',
  'boxPlot',
  'barChart',
  'lineChart',
  'parallelCoordinateChart',
  'table',
  'observationCharts',
  'markdown'
);
export type CrossRunWidget = {
  id: string;
  type: 'custom';
  settings: WidgetSettings;
};
