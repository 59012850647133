import { Box, BoxProps, Stack, styled } from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { transientOptions } from 'shared/utils/transientOptions';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';

export const Container = styled(Stack)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.action.hover,
  paddingTop: theme.spacing(4),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
}));

export const IconWrapper = styled(
  ({
    icon,
    isDisabled,
    ...props
  }: BoxProps & { icon: IconDefinition; isDisabled?: boolean }) => (
    <Box {...props}>
      <IconAwesomeInfo
        icon={icon}
        size="xl"
        color={isDisabled ? 'text.disabled' : 'primary.main'}
      />
    </Box>
  ),
  transientOptions
)(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: theme.palette.background.paper,
  padding: '12px',
  width: 'fit-content',
}));
