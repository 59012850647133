import { Box, BoxProps, styled } from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';

export const StyledCategoryItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '4px',
  border: theme.palette.border.default,
  width: '100%',
  padding: '12px',
}));

export const IconWrapper = styled(
  (props: BoxProps & { icon: IconDefinition }) => (
    <Box {...props}>
      <IconAwesomeInfo icon={props.icon} size="xl" color="primary.main" />
    </Box>
  )
)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  backgroundColor: theme.palette.additionalBackground.stepBackground,
  height: '32px',
  width: '32px',
  svg: {
    height: '20px',
  },
}));
