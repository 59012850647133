import { IGitCodeBlob } from 'shared/models/Versioning/Blob/CodeBlob';
import * as GitUrl from 'shared/utils/gitUrl/gitUrl';
import matchBy from 'shared/utils/matchBy';
import InlineLink from 'shared/view/elements/InlineLink/InlineLink';

interface ILocalProps {
  commitHash: string;
  remoteRepoUrl: IGitCodeBlob['data']['remoteRepoUrl'];
}

const HashProp = ({ remoteRepoUrl, commitHash }: ILocalProps) => {
  return (
    <span data-test="git-hash">
      {matchBy(
        remoteRepoUrl,
        'type'
      )({
        git: ({ value }) => (
          <InlineLink to={GitUrl.makeCommitUrl(value, commitHash)} isExternal>
            {commitHash}
          </InlineLink>
        ),
        unknown: () => <span>{commitHash}</span>,
      })}
    </span>
  );
};

export default HashProp;
