import { gql } from '@apollo/client';

import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';

import {
  DeleteAlertMutation,
  DeleteAlertMutationVariables,
} from './graphql-types/useDeleteAlert.generated';

const DELETE_ALERT_MUTATION = gql`
  mutation DeleteAlertMutation($alertId: ID!) {
    alert(id: $alertId) {
      ... on Error {
        ...ErrorData
      }
      ... on Alert {
        id
        delete
        monitoredEntity {
          id
          alerts {
            alerts {
              id
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useDeleteAlert = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [mutate, communication] = useCustomMutation<
    DeleteAlertMutation,
    DeleteAlertMutationVariables
  >(DELETE_ALERT_MUTATION, { context: `deleting alert` }, { onCompleted });

  return { mutate, communication };
};
