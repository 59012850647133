import { identity } from 'ramda';

import { RequestVariableMatcher } from './graphqlRequestResponseObfuscator';

export const whenVariableIsEqualTo = (
  expected: string
): RequestVariableMatcher =>
  identity(({ key, value }, obf) => (key === expected ? obf(value) : value));

export const whenVariableIsEqualToField = identity<RequestVariableMatcher>(
  ({ key, value }, obf, { field }) => (key === field ? obf(value) : value)
);
