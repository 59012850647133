import {
  AvailableTableWidgetColumns,
  TableWidgetSettings,
  TableWidgetColumn,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/WidgetSettings/TableWidgetSettings';
import { isNotEmptyArray } from 'shared/utils/collection';
import { Result, makeSuccess, makeError } from 'shared/utils/result';

export type TableWidgetSettingsForm = {
  type: 'table';
  name: string;
  columns: TableWidgetColumn[];
};

export const getInitialTableWidgetSettingsForm = (
  name: string,
  columns: AvailableTableWidgetColumns
): TableWidgetSettingsForm => {
  return {
    type: 'table',
    name,
    columns: [columns.single[0]],
  };
};

export const validateTableWidgetSettingsForm = (
  form: TableWidgetSettingsForm
): Result<TableWidgetSettings, string> => {
  if (isNotEmptyArray(form.columns)) {
    return makeSuccess({
      type: 'table',
      name: form.name,
      columns: form.columns,
    });
  }
  return makeError('Select a column to visualize in this table.');
};
