/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { EndpointEnvironmentUpdateStrategy } from 'generated/types';
import useMutationFromFunction from 'shared/utils/graphql/useMutationFromFunction';
import { BuildSource } from 'shared/models/Deployment/canary/Build';
import matchType from 'shared/utils/matchType';
import { ResultMachineConfigSettings } from 'features/deployment/canary/machineConfig/view/settings';
import { convertMachineConfig } from 'features/deployment/canary/machineConfig/convertMachineConfig';
import { AppError } from 'shared/models/Error';
import {
  isNotNullableRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';

import * as UpdateEnvironmentGraphqlTypes from './graphql-types/UpdateEnvironmentQuery.generated';
import * as CreateNewBuildGraphqlTypes from './graphql-types/useUpdateWithCreatingNewBuild.generated';
import { CanaryStrategyForm } from '../../view/UpdateEndpointSection/settings/CanaryStrategyForm';
import { convertCanarySettingsToGraphql } from './convertCanarySettingsToGraphql';
import { UPDATE_ENVIRONMENT } from './UpdateEnvironmentQuery';

export const CREATE_NEW_BUILD = gql`
  mutation CreateNewBuild(
    $workspaceName: String!
    $organizationId: ID
    $endpointId: ID!
    $environmentId: ID!
    $modelId: ID
    $modelVersionId: ID
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoint(id: $endpointId) {
          ... on Error {
            ...ErrorData
          }
          ... on Endpoint {
            id
            environment(id: $environmentId) {
              id
              createBuild(runId: $modelId, modelVersion: $modelVersionId) {
                id
              }
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export type UpdateWithCreatingNewBuildSettings = {
  sourceType: BuildSource;
  sourceId: string;
  endpointId: string;
  environmentId: string;
  workspaceName: string;
  organizationId: string | undefined;
} & {
  buildType: 'newBuild';
  strategy: EndpointEnvironmentUpdateStrategy;
  canary: CanaryStrategyForm | undefined;

  machineConfig: ResultMachineConfigSettings;
};

export const useUpdateWithCreatingNewBuild = ({
  onCompleted,
  onError,
}: {
  onCompleted: () => void;
  onError: (error: AppError) => void;
}) => {
  const [update, updating] = useMutationFromFunction(
    (apolloClient) =>
      async ({
        strategy,
        endpointId,
        environmentId,
        workspaceName,
        canary,
        sourceType: source,
        organizationId,
        sourceId,
        machineConfig,
      }: UpdateWithCreatingNewBuildSettings) => {
        const res = await apolloClient.mutate<
          CreateNewBuildGraphqlTypes.CreateNewBuild,
          CreateNewBuildGraphqlTypes.CreateNewBuildVariables
        >({
          mutation: CREATE_NEW_BUILD,
          variables: {
            endpointId,
            environmentId,
            workspaceName,
            ...matchType<
              BuildSource,
              { modelId?: string; modelVersionId?: string }
            >(
              {
                experimentRun: () => ({ modelId: sourceId }),
                modelVersion: () => ({ modelVersionId: sourceId }),
                noModel: () => ({}),
              },
              source
            ),
          },
        });
        if (
          res.data &&
          isNotNullableRestrictedGraphqlError(res.data.workspace) &&
          isNotNullableRestrictedGraphqlError(res.data.workspace.endpoint) &&
          res.data.workspace.endpoint.environment
        ) {
          await apolloClient.mutate<
            UpdateEnvironmentGraphqlTypes.UpdateEnvironment,
            UpdateEnvironmentGraphqlTypes.UpdateEnvironmentVariables
          >({
            mutation: UPDATE_ENVIRONMENT,
            variables: {
              ...convertMachineConfig(machineConfig),
              buildId: res.data.workspace.endpoint.environment.createBuild.id,
              strategy,
              endpointId,
              environmentId,
              organizationId,
              workspaceName,
              canary:
                strategy === EndpointEnvironmentUpdateStrategy.CANARY && canary
                  ? convertCanarySettingsToGraphql(canary)
                  : undefined,
            },
          });
        }
      },
    { context: 'updating endpoint' },
    {
      onCompleted,
      onError,
    }
  );

  return {
    updatingWithCreatingNewBuild: { communication: updating },
    updateWithCreatingNewBuild: update,
  };
};
