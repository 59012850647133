import { ILoadArtifactUrlService } from 'features/artifactManager/store/types';
import { HttpActions } from 'services/HttpActions';

const RegisteredModelVersionService: ILoadArtifactUrlService = {
  async loadArtifactUrl(versionId, artifact): Promise<string> {
    const response = await HttpActions.post<{ url: string }>({
      url: `/v1/registry/model_versions/${versionId}/getUrlForArtifact`,
      data: {
        id: versionId,
        key: artifact.key,
        artifact_type: artifact.type,
        method: 'GET',
      },
    });
    return response.data.url;
  },
};

export default RegisteredModelVersionService;
