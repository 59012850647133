import { SingleMetricWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/SingleMetricWidget';
import matchBy from 'shared/utils/matchBy';

import { MonitoringWidgetProps } from '../shared/types';
import SingleMetricCommonWidgetView from './SingleMetricCommonWidget/SingleMetricCommonWidget';
import SingleMetricHealthWidgetView from './SingleMetricHealthWidget/SingleMetricHealthWidget';

const SingleMetricWidgetView = (
  props: MonitoringWidgetProps<SingleMetricWidget>
) => {
  return matchBy(
    props.widget.variant,
    'type'
  )({
    metric: (variant) => (
      <SingleMetricCommonWidgetView
        {...props}
        widget={{ ...props.widget, variant }}
      />
    ),
    health: (variant) => (
      <SingleMetricHealthWidgetView
        {...props}
        widget={{ ...props.widget, variant }}
      />
    ),
  });
};

export default SingleMetricWidgetView;
