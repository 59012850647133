import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import capitalize from 'shared/utils/capitalize';
import { isNotRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';
import { Stage } from 'generated/types';
import { getAvailableStages } from 'shared/models/Registry/RegisteredModelVersion/Stage/AvailableStages';
import { selectFlags } from 'features/flags';
import { stageToView } from 'shared/view/domain/Registry/ModelVersion/RegisteredModelVersionStage/stageToView';
import {
  getActivitiesWithTransitionsPendingApproval,
  getPerviousStageWhenArchived,
} from 'shared/models/Registry/RegisteredModelVersion/Stage/ActivityWithTransitionPendingApproval';
import { LoadChecklistTemplates } from 'features/catalog/shared/store/useLoadChecklistTemplates/graphql-types/index.generated';

import { RegisteredModelVersion } from '../graphql';

export const useCrossClusterVersion = (
  version: RegisteredModelVersion | null,
  checklistTemplates:
    | LoadChecklistTemplates['checklistTemplates']['checklistTemplates']
    | null
) => {
  const { isEnablePromotion } = useSelector(selectFlags);

  const crossClusterInProd = useMemo(
    () => isEnablePromotion && version?.stage === Stage.PRODUCTION,
    [isEnablePromotion, version]
  );

  const hasCrossClusterLink = useMemo(
    () => version && Boolean(version.redirectMetadata),
    [version]
  );

  const pendingRequests = useMemo(() => {
    if (version?.activities) {
      return getActivitiesWithTransitionsPendingApproval(version.activities);
    }
    return [];
  }, [version]);

  const stageBlockedObj = buildStageBlockedObjectByChecklist(
    checklistTemplates || [],
    version?.checklistItemValues?.checklistItemValues || []
  );

  const items = useMemo(() => {
    if (version) {
      const disabled =
        !isNotRestrictedGraphqlError(version) ||
        !version.registeredModel.allowedActions.update;

      const previousStage = getPerviousStageWhenArchived({
        currentStage: version.stage,
        activities: version.activities,
      });

      return getAvailableStages(version.stage).map((availableStage) => {
        const isDisabled =
          disabled ||
          !isEmpty(
            pendingRequests.filter(
              (request) =>
                request.transition.desiredStage === availableStage &&
                request.transition.sourceStage === version.stage
            )
          ) ||
          stageBlockedObj[availableStage];

        let label = `Move to ${stageToView(availableStage, isEnablePromotion)}`;

        if (availableStage === Stage.ARCHIVED) {
          label = 'Archive version';
        } else if (
          version.stage === Stage.ARCHIVED &&
          availableStage === previousStage
        ) {
          label = label + ' (previous)';
        }

        return {
          disabled: isDisabled,
          value: availableStage,
          label,
        };
      });
    }

    return [];
  }, [isEnablePromotion, pendingRequests, stageBlockedObj, version]);

  const stageLabel = useMemo(() => {
    if (version) {
      return capitalize(stageToView(version.stage, isEnablePromotion));
    }
    return '';
  }, [version, isEnablePromotion]);

  return { stageLabel, items, crossClusterInProd, hasCrossClusterLink };
};

const buildStageBlockedObjectByChecklist = (
  templates: NonNullable<
    LoadChecklistTemplates['checklistTemplates']['checklistTemplates']
  >,
  values: NonNullable<
    NonNullable<
      RegisteredModelVersion['checklistItemValues']
    >['checklistItemValues']
  >
) => {
  const stageTemplate: Record<Stage, boolean> = {
    [Stage.UNASSIGNED]: false,
    [Stage.DEVELOPMENT]: false,
    [Stage.STAGING]: false,
    [Stage.PRODUCTION]: false,
    [Stage.ARCHIVED]: false,
    [Stage.UNKNOWN]: false,
  };

  templates.forEach(({ desiredStage, checklistTemplateItems }) => {
    if (desiredStage !== null && desiredStage !== Stage.UNKNOWN) {
      checklistTemplateItems
        ?.filter((item) => item.required)
        .forEach((item) => {
          const value = values.find(
            (val) => val.checklistTemplateItemId === item.id
          );
          if (value === undefined || !value.completed) {
            stageTemplate[desiredStage] = true;
          }
        });
    }
  });

  return stageTemplate;
};
