import * as React from 'react';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
import PagesTabs from 'shared/view/pages/PagesTabs/PagesTabs';
import getTabs from 'shared/view/pages/PagesTabs/getTabs';
import routes from 'shared/routes';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';

type Props = Pick<
  React.ComponentProps<typeof AuthorizedLayout>,
  'children' | 'pageEntityOrError' | 'actions'
>;

const OrganizationLayout = (props: Props) => {
  const { name } = useRouteParams(routes.organization);
  const breadcrumbs = useAppBreadcrumbs(
    useBreadcrumbItem({
      route: routes.organization,
      name,
    })
  );

  return (
    <AuthorizedLayout
      breadcrumbs={breadcrumbs}
      tabsElement={<OrganizationPagesTabs />}
      {...props}
    ></AuthorizedLayout>
  );
};

const OrganizationPagesTabs = () => {
  const { name } = useRouteParams(routes.organization);

  return (
    <PagesTabs
      tabs={getTabs(routes.organization.$)(
        [
          ['users', () => 'Users'],
          ['groups', () => 'Groups'],
          ['roles-and-permissions', () => 'Roles & Permissions'],
          ['workspaces', () => 'Workspaces'],
          ['governance', () => 'Governance'],
          ['custom-attributes', () => 'Custom Attributes'],
        ],
        { name }
      )}
    />
  );
};

export default OrganizationLayout;
