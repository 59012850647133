import { Box, Stack, Typography } from '@mui/material';

import Chip from 'shared/view/elements/Chip/Chip';

import image from '../images/custom-attributes.png';
import { Circle } from '../Circle';

export const CustomAttributes = () => (
  <Stack direction="row" spacing={0}>
    <Box width="35%" display="flex" alignItems="center" mr={4}>
      <Box>
        <Circle mb={5}>4</Circle>
        <Typography mb="12px" variant="subtitle1" color="text.secondary">
          Custom Model Attributes
        </Typography>
        <Box maxWidth="220px">
          <Typography mb={2} variant="body2">
            All model metadata in one place for everyone's convenience.
          </Typography>
        </Box>
        <Chip
          variant="outlined"
          label="Unlimited for Free Tier"
          color="primary"
          size="small"
        />
      </Box>
    </Box>
    <Box width="65%">
      <img
        src={image}
        width="100%"
        height="auto"
        alt="custom attributes example"
      />
    </Box>
  </Stack>
);
