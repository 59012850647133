import { Box, Typography } from '@mui/material';

import Popup from 'shared/view/elements/Popup/Popup';
import InlineErrorView from 'shared/view/elements/Errors/InlineErrorView/InlineErrorView';

interface Props {
  isOpen: boolean;
  onClose(): void;
  message: string;
}

const ErrorModal = (props: Props) => {
  const { isOpen, onClose, message } = props;

  return (
    <Popup
      title="Error details"
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      dataTest="tags-manager-add-tag-popup"
      buttons={{
        mainButtonProps: {
          dataTest: 'confirm-cancel-button',
          children: 'Close',
          onClick: onClose,
          isLoading: false,
          type: 'button',
        },
      }}
    >
      <Box>
        <Typography variant="h6">
          Below are the details of the error.
        </Typography>
        <InlineErrorView style={{ whiteSpace: 'pre-wrap' }} error={message} />
      </Box>
    </Popup>
  );
};

export default ErrorModal;
