import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';
import { NonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';

import {
  filterKeyValueSpecsByType,
  KeyValueDataType,
  KeyValueSpec,
} from '../KeyValueSpec';
import { CommonWidgetSettings } from './shared/CommonWidgetSettings';

export type TableWidgetColumn = TableWidgetSingleColumn | KeyValueColumn;
type TableWidgetSingleColumn = {
  type: 'id' | 'experiment' | 'timestamp' | 'tags';
};
type KeyValueColumn = KeyValueSpec<
  KeyValueDataType,
  'hyperparameter' | 'metric'
>;

export interface TableWidgetSettings extends CommonWidgetSettings<'table'> {
  columns: NonEmptyArray<TableWidgetColumn>;
}

export type AvailableTableWidgetColumns = {
  single: TableWidgetSingleColumn[];
  keyValue: KeyValueColumn[];
};
export const getAvailableTableWidgetColumns = (
  keyValueSpecs: Array<KeyValueSpec>
): AvailableTableWidgetColumns => {
  const singleColumns: TableWidgetSingleColumn[] = exhaustiveStringTuple<
    TableWidgetSingleColumn['type']
  >()('id', 'experiment', 'timestamp', 'tags').map((type) => ({ type }));
  const keyValueColumns = exhaustiveStringTuple<KeyValueColumn['subtype']>()(
    'metric',
    'hyperparameter'
  ).flatMap((type) => filterKeyValueSpecsByType(type, keyValueSpecs));
  return {
    single: singleColumns,
    keyValue: keyValueColumns,
  };
};
