import { useDeleteDataset } from 'features/datasets/datasetSettings/store/useDeleteDataset';
import { useDeleteDatasetVersion } from 'features/datasetVersions/store/deleteDatasetVersion/deleteDatasetVersion';
import { useDeleteEndpoint } from 'features/deployment/canary/endpointSettings/store/useDeleteEndpoint';
import { useDeleteMonitoringModel } from 'features/monitoring/models/modelSettings/store/useDeleteMonitoringModel';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { useDeleteProject } from 'features/projects/projectSettings/store/useDeleteProject';
import { useDeleteRegisteredModel } from 'features/registry/registeredModel/registeredModelDeletion/deleteRegisteredModel/useDeleteRegisteredModel';
import { useDeleteRegisteredModelVersion } from 'features/registry/registeredModelVersion/registeredModelVersionDeletion/deleteRegisteredModelVersion/useDeleteRegisteredModelVersion';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { DeleteEntityType } from 'shared/models/DeleteEntity';
import matchType from 'shared/utils/matchType';

interface Props {
  type: DeleteEntityType;
  onCompleted: () => void;
}

export const useDeleteEntity = (props: Props) => {
  const project = useDeleteProject({
    onCompleted: props.onCompleted,
  });

  const dataset = useDeleteDataset({
    onCompleted: props.onCompleted,
  });

  const endpoint = useDeleteEndpoint(props.onCompleted);

  const registeredModel = useDeleteRegisteredModel({
    onCompleted: props.onCompleted,
  });

  const modelVersion = useDeleteRegisteredModelVersion({
    onCompleted: props.onCompleted,
  });

  const monitoringModel = useDeleteMonitoringModel({
    onCompleted: props.onCompleted,
  });

  const datasetVersion = useDeleteDatasetVersion({
    onCompleted: props.onCompleted,
  });

  const mutationByType = {
    project,
    dataset,
    endpoint,
    registeredModel,
    modelVersion,
    monitoringModel,
    datasetVersion,
  }[props.type];

  const deletingEntity = mutationByType.deletingEntity;

  const workspaceName = useWorkspaceNameFromURL();
  const organizationId = useCurrentOrganizationV2();

  const deleteEntity = (id: string) => {
    matchType(
      {
        project: () => {
          project.deleteEntity({ id });
        },
        dataset: () => {
          dataset.deleteEntity({ id });
        },
        datasetVersion: () => {
          datasetVersion.deleteEntity({
            id,
          });
        },
        endpoint: () => {
          endpoint.deleteEntity({ id, workspaceName, organizationId });
        },
        modelVersion: () => {
          modelVersion.deleteEntity({ id });
        },
        registeredModel: () => {
          registeredModel.deleteEntity({ id });
        },
        monitoringModel: () => {
          monitoringModel.deleteEntity({ monitoringModelId: id });
        },
      },
      props.type
    );
  };

  return {
    deleteEntity,
    deletingEntity,
  };
};
