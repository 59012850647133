import routes from 'shared/routes';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { TabsLayout } from 'pages/authorized/shared/TabsLayout';
import { CreateEvaluation } from 'features/evaluations/create/view/CreateEvaluation';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';
import { useWorkspaceNameFromURL } from 'features/workspaces/store/hooks/useWorkspaceNameFromURL';
import { useWorkspaceEvaluationProjects } from 'features/evaluations/evaluationsList/hooks/useWorkspaceEvaluationProjects';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

import { useEvaluationsPageBreadcrumbs } from './shared/useEvaluationsPageBreadcrumbs';

const EvaluationCreatePage = () => {
  const breadcrumbs = useAppBreadcrumbs(
    useEvaluationsPageBreadcrumbs(),
    useBreadcrumbItem({
      name: 'Creating evaluation',
      route: routes.createEvaluation,
    })
  );

  const organizationId = useCurrentOrganizationV2();
  const workspaceName: string = useWorkspaceNameFromURL();
  const { data, communication } = useWorkspaceEvaluationProjects({
    organizationId,
    workspaceName,
  });

  return (
    <TabsLayout breadcrumbs={breadcrumbs} pageEntityOrError={data}>
      <DefaultMatchRemoteDataOrError
        communication={communication}
        data={data}
        context="loading model"
      >
        {(loaded) => <CreateEvaluation evaluationProjects={loaded} />}
      </DefaultMatchRemoteDataOrError>
    </TabsLayout>
  );
};

export default EvaluationCreatePage;
