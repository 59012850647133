import { pipe, values } from 'ramda';

import { makeObjectFromArray } from 'shared/utils/collection';

import { isDifferentIfAtleastOneItemIsDifferentOrMissed } from '../helpers';

export type ItemsPropertiesDiff<Item, K extends keyof Item> = {
  isDifferent: boolean;
  properties: {
    [Z in NonNullable<K>]: ItemPropertyDiff<Item, Item[Z]>;
  };
};

export type ItemPropertyDiff<Item, Value> = {
  isDifferent: boolean;
  valuesWithItems: Array<{
    value: Value | undefined | null;
    item: Item | undefined | null;
  }>;
};

export const compareItemsProperties = <Item, K extends keyof Item>(
  items: (Item | undefined)[],
  comparedProperties: K[]
): ItemsPropertiesDiff<Item, K> => {
  const properties = pipe(() =>
    makeObjectFromArray(
      (comparedKey) => ({
        isDifferent: isDifferentIfAtleastOneItemIsDifferentOrMissed(
          (item) => item[comparedKey],
          items
        ),
        valuesWithItems: items.map((item) => ({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          value: item?.[comparedKey] as any,
          item: item,
        })),
      }),
      comparedProperties
    )
  )();
  return {
    properties,
    isDifferent: values(properties).some(({ isDifferent }) => isDifferent),
  };
};
