import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

const SizesMap: Record<SizeProp, number> = {
  '2xs': 18,
  xs: 20,
  sm: 22,
  lg: 28,
  xl: 32,
  '2xl': 40,
  '1x': 24,
  '2x': 44,
  '3x': 64,
  '4x': 84,
  '5x': 104,
  '6x': 124,
  '7x': 144,
  '8x': 164,
  '9x': 184,
  '10x': 204,
};

/**
 * Mapping hook to map FontAwesome Font size into real visual sizes (width/height) for MUI IconButton.
 * It is required because FontAwesome icons real sizes (width/height) are different depends on icon,
 * but for correct alignment of FormControlLabel we need to have equal sizes (for one Font size) of icons,
 * like it is implemented in native MUI Icons.
 * @param size - IconAwesome Font size
 */
export function useIconAwesomeMUIStylesConverter({
  size,
}: {
  size: SizeProp;
}): SxProps<Theme> {
  return {
    width: SizesMap[size],
    height: SizesMap[size],
    fontSize: '1rem',
  };
}
