import { useEffect, useState, createRef } from 'react';
import { Stack, styled } from '@mui/material';

const StyledStack = styled(Stack)(({ className, theme }) => {
  if (className === 'stuck') {
    return {
      zIndex: 10,
      backgroundColor: theme.palette.background.level2,
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: '0px 3px 2px 0px rgb(0 0 0 / 15%)',
      paddingLeft: 32,
      paddingRight: 32,
      marginLeft: -32,
      marginRight: -32,
      paddingTop: 16,
      paddingBottom: 16,
    };
  }
});

const StickyHeader = ({
  children,
  ...rest
}: {
  children: JSX.Element;
  [x: string]: any;
}) => {
  const [stuck, setStuck] = useState(false);
  const ref = createRef<HTMLInputElement>();

  useEffect(() => {
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        setStuck(e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );
    if (cachedRef) {
      observer.observe(cachedRef);
    }
    if (cachedRef) {
      return () => observer.unobserve(cachedRef);
    }
  }, [ref]);

  return (
    <StyledStack
      position="sticky"
      top="-20px"
      className={stuck ? 'stuck' : ''}
      ref={ref}
      {...rest}
    >
      {children}
    </StyledStack>
  );
};

export default StickyHeader;
