import { useCallback } from 'react';

import InvitePopup from 'shared/view/elements/InvitePopup/InvitePopup';
import { useAddSystemAdmin } from 'features/systemAdmin/admins/add/store/useAddSystemAdmin';
import { useInvitePopup } from 'shared/view/elements/InvitePopup/useInvitePopup';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

export default function InviteSystemAdminsPopup(props: Props) {
  const { isOpen, onClose } = props;
  const { usernameOrEmailField, setUsernameOrEmailField } = useInvitePopup();

  const { addSystemAdmin, addingSystemAdmin } = useAddSystemAdmin({
    onCompleted: onClose,
  });

  const addUser = useCallback(
    (usernameOrEmail: string) => {
      addSystemAdmin({ usernameOrEmail });
    },
    [addSystemAdmin]
  );

  return (
    <InvitePopup
      popupKey={'SystemAdmins'}
      isOpen={isOpen}
      onClose={onClose}
      addUser={addUser}
      addingUser={addingSystemAdmin}
      userEntityName={'system admin'}
      usernameOrEmailField={usernameOrEmailField}
      setUsernameOrEmailField={setUsernameOrEmailField}
    />
  );
}
