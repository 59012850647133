import { Grid, Stack } from '@mui/material';
import { useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

interface Props {
  id: number;
  plusIcon: ReactNode;
  columnField: ReactNode;
  operatorField: ReactNode;
  valueField: ReactNode;
  onRemove: () => void;
}

const BaseFilterItem = (props: Props) => {
  const spacing = useTheme().spacing(1.5);

  return (
    <div data-test-index={props.id}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {props.columnField}
        </Grid>
        <Grid item xs={3.2}>
          {props.operatorField}
        </Grid>
        <Grid item xs={3.2}>
          {props.valueField}
        </Grid>

        <Grid item xs={1}>
          <div>
            <Stack direction="row">
              <div style={{ marginTop: spacing }}>
                <IconAwesomeClickable
                  size="lg"
                  icon={ICONS.trashCan}
                  onClick={props.onRemove}
                  dataTest="remove-env-variable"
                />
              </div>

              <div style={{ marginTop: spacing }}>{props.plusIcon}</div>
            </Stack>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default BaseFilterItem;
