import { FC } from 'react';

import ToggleButtonGroup from 'shared/view/elements/ToggleButtonGroup/ToggleButtonGroup';
import ToggleButton from 'shared/view/elements/ToggleButton/ToggleButton';

interface Props {
  buttons: [
    { text: string; isSelected: boolean; dataTest: string; onClick(): void },
    { text: string; isSelected: boolean; dataTest: string; onClick(): void },
  ];
}

const ToggleButtons: FC<React.PropsWithChildren<Props>> = ({ buttons }) => {
  const value = buttons.find((button) => button.isSelected)?.text;

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      value={value}
      style={{ width: 'max-content' }}
    >
      {buttons.map(({ text, dataTest, isSelected, onClick }, i) => (
        <ToggleButton
          key={i}
          value={text}
          data-test={dataTest}
          onClick={onClick}
          data-test-selected={isSelected}
        >
          {text}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleButtons;
