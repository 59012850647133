import { NonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';
import { RecordFromUnion } from 'shared/utils/types';

import { dateUpdatedSpec, DateUpdatedSpec } from '../DataSpec';
import {
  filterKeyValueSpecsByType,
  filterKeyValueSpecsByValueType,
  KeyValueSpec,
} from '../KeyValueSpec';
import { CommonWidgetSettings } from './shared/CommonWidgetSettings';
import { CustomDomain } from './shared/CustomDomain';

export type ScatterChartXAxisDataSpec = KeyValueSpec | DateUpdatedSpec;
export type ScatterChartYAxisDataSpec = KeyValueSpec;
type ExperimentRunStringField = {
  type: 'experimentRunStringField';
  name: 'owner' | 'experiment';
};
export type ScatterChartZAxisDataSpec =
  | ExperimentRunStringField
  | KeyValueSpec<'string', 'attribute'>;

export interface ScatterChartSettings
  extends CommonWidgetSettings<'scatterChart'> {
  xAxis: {
    spec: ScatterChartXAxisDataSpec;
    isLogScale: boolean;
    domain: CustomDomain;
  };
  yAxis: {
    spec: ScatterChartYAxisDataSpec;
    isLogScale: boolean;
    domain: CustomDomain;
  };
  zAxis: {
    spec: ScatterChartZAxisDataSpec;
  };
}

export interface ScatterChartAxisesDataSpec {
  xAxis: RecordFromUnion<
    ScatterChartXAxisDataSpec['type'],
    {
      dateUpdated: DateUpdatedSpec;
      keyValue: Array<KeyValueSpec>;
    }
  >;
  yAxis: ScatterChartYAxisDataSpec[];
  zAxis: RecordFromUnion<
    ScatterChartZAxisDataSpec['type'],
    {
      keyValue: Array<KeyValueSpec<'string', 'attribute'>>;
      experimentRunStringField: NonEmptyArray<ExperimentRunStringField>;
    }
  >;
}

export const getScatterChartAxisesDataSpecs = (
  keyValueSpecs: KeyValueSpec[]
): ScatterChartAxisesDataSpec => {
  const stringKeyValueAttributeSpecs = filterKeyValueSpecsByType(
    'attribute',
    filterKeyValueSpecsByValueType('string', keyValueSpecs)
  );
  return {
    xAxis: {
      dateUpdated: dateUpdatedSpec,
      keyValue: keyValueSpecs,
    },
    yAxis: keyValueSpecs,
    zAxis: {
      keyValue: stringKeyValueAttributeSpecs,
      experimentRunStringField: [
        {
          name: 'experiment',
          type: 'experimentRunStringField',
        },
        {
          name: 'owner',
          type: 'experimentRunStringField',
        },
      ],
    },
  };
};
