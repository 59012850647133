import { Stack } from '@mui/material';

import MonitoringModelDetailsLayout from 'pages/authorized/Monitoring/MonitoringModelPages/shared/MonitoringModelDetailsLayout/MonitoringModelDetailsLayout';
import { MonitoringDashboard } from 'shared/models/Monitoring/MonitoringModel/MonitoringDashboard/MonitoringDashboard';
import { useDebounced } from 'shared/utils/debounce';
import {
  MonitoringModel,
  MonitoringModelState,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringModel';
import { AppBreadcrumbs } from 'features/layout/view/Layout/AppBreadcrumbs';
import { useMonitoringModelStateManager } from 'features/monitoring/models/model/store/state/useMonitoringModelStateManager';
import { useDashboardSettingsActions } from 'features/monitoring/dashboards/dashboardSettings/store/useDashboardSettingsActions';

import MonitoringModelBar from './MonitoringModelBar/MonitoringModelBar';

interface Props {
  model: MonitoringModel;
  updateModelState: (state: MonitoringModelState) => void;
  dashboard: MonitoringDashboard;
  updateDashboard: (dashboard: MonitoringDashboard) => void;
  createDashboard: (dashboard: MonitoringDashboard) => void;
  children: JSX.Element;
  breadcrumbs: AppBreadcrumbs;
}

const MonitoringDashboardLayout = (props: Props) => {
  const {
    updateFilters,
    updateTimeRange,
    updateAggregation,
    selectRegisteredModelVersion,
    deleteDashboard,
    updateDashboards,
  } = useMonitoringModelStateManager({
    modelState: props.model.state,
    updateModelState: props.updateModelState,
    registeredModelVersions: props.model.registeredModelVersions,
  });

  const debouncedChangeTimeRange = useDebounced(updateTimeRange, 500);

  const actions = useDashboardSettingsActions({
    model: props.model,
    dashboard: props.dashboard,
    deleteDashboard: deleteDashboard,
    updateDashboard: props.updateDashboard,
    allowedActions: props.model.monitoredEntity.allowedActions,
    updateDashboards,
    dashboards: props.model.state.dashboards,
  });

  return (
    <MonitoringModelDetailsLayout
      breadcrumbs={props.breadcrumbs}
      model={props.model}
      createDashboard={props.createDashboard}
      pageSettingsActions={actions}
    >
      <Stack spacing={3}>
        <MonitoringModelBar
          model={props.model}
          updateAggregation={updateAggregation}
          updateTimeRange={debouncedChangeTimeRange}
          updateFilters={updateFilters}
          selectRegisteredModelVersion={selectRegisteredModelVersion}
        />
        <div>{props.children}</div>
      </Stack>
    </MonitoringModelDetailsLayout>
  );
};

export default MonitoringDashboardLayout;
