import routes from 'shared/routes';
import { RoutesGenerator } from 'pages/authorized/types';
import { CustomRedirect } from 'shared/routes/shared/CustomRedirect';

import DashboardPage from './DashboardPages';
import GeneralDashboard from './GeneralDashboard';
import PortfolioDashboard from './PortfolioDashboard';

export const DashboardPages: RoutesGenerator = {
  getRoutes: () => [
    {
      path: routes.dashboard.getPath(),
      element: <DashboardPage />,
      children: [
        {
          path: '',
          index: true,
          element: <CustomRedirect route={routes.dashboardGeneral} replace />,
        },
        {
          path: routes.dashboardGeneral.getPath(),
          element: <GeneralDashboard />,
        },
        {
          path: routes.dashboardPortfolio.getPath(),
          element: <PortfolioDashboard />,
        },
      ],
    },
  ],
};
