import {
  formatPercentage,
  validatePercentage,
} from 'shared/utils/percentage/percentage';
import { combineValidators, validateNotEmpty } from 'shared/utils/validators';

export const validateCanaryRuleStep = combineValidators([
  validateNotEmpty('step'),
  validatePercentage('step'),
]);

export const formatCanaryRuleStep = (value: number) =>
  formatPercentage(String(value));
