import { Box, alpha, styled } from '@mui/material';

export const Circle = styled(Box)(({ theme }) => ({
  ...theme.typography.h5,
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.dark,
  borderRadius: '52px',
  width: '52px',
  height: '52px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
}));
