import { IDatasetVersion } from 'shared/models/DatasetVersion';
import { convertGraphqlAttribute } from 'shared/graphql/Attribute/converters';

import { GraphqlDatasetVersion } from './DatasetVersion';
import { convertDatasetVersionBlob } from './Blob';

export const convertGraphqlDatasetVersion = (
  data: GraphqlDatasetVersion
): IDatasetVersion => {
  return {
    id: data.id,
    allowedActions: data.dataset.allowedActions,
    attributes: data.attributes.map(convertGraphqlAttribute),
    datasetId: data.datasetID,
    dateLogged: new Date(Number(data.dateCreated)),
    dateUpdated: new Date(Number(data.dateUpdated)),
    description: data.description,
    version: data.version,
    tags: data.tags,
    ownerId: data.owner.id,
    owner: data.owner,
    dataset: data.dataset,
    ...convertDatasetVersionInfo(data),
  };
};

const convertDatasetVersionInfo = (data: GraphqlDatasetVersion) => {
  if (data.blobInfo) {
    return convertDatasetVersionBlob(data.blobInfo);
  }

  throw new Error('Did not find correct dataset version info');
};
