import { gql } from '@apollo/client';

import { entityTypeWithCollaboratorsToString } from 'features/collaborators/view/Collaboration/shared/entityTypeWithCollaboratorsToString';
import useCustomMutation from 'shared/view/hooks/apollo/useCustomMutation';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';

import * as GraphqlTypes from './graphql-types/useDeleteProject.generated';

const MUTATION = gql`
  mutation DeleteProject($id: ID!) {
    project(id: $id) {
      ... on Error {
        ...ErrorData
      }
      ... on Project {
        id
        delete
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

export const useDeleteProject = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [deleteEntity, deletingEntity] = useCustomMutation<
    GraphqlTypes.DeleteProject,
    GraphqlTypes.DeleteProjectVariables
  >(
    MUTATION,
    { context: `deleting ${entityTypeWithCollaboratorsToString('project')}` },
    { onCompleted }
  );

  return { deleteEntity, deletingEntity };
};
