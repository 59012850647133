import { Box } from '@mui/material';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

import { IconAwesomeCustomizable } from 'shared/view/elements/IconAwesome/IconAwesomeCustomizable';
import matchType from 'shared/utils/matchType';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

export type PreloaderSize = 'normal' | 'small' | 'big' | 'medium';

interface Props {
  size?: PreloaderSize;
}

const getPreloaderSize = (
  preloaderSize: PreloaderSize
): FontAwesomeIconProps['size'] =>
  matchType(
    {
      big: () => '7x',
      medium: () => '5x',
      normal: () => '3x',
      small: () => '1x',
    },
    preloaderSize
  );

const Preloader = (props: Props) => {
  return (
    <Box color="primary.main">
      <IconAwesomeCustomizable
        animationDuration={1}
        icon={ICONS.spinnerThird}
        size={getPreloaderSize(props.size || 'normal')}
        nativeProps={{ color: 'inherit', spin: true }}
      />
    </Box>
  );
};

export default Preloader;
