import { Stack, Grid, Typography } from '@mui/material';

import SummaryCard from 'features/dashboard/dashboard/shared/SummaryCard';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import { CustomDashboardSummary } from 'generated/types';

interface SummaryProps {
  isLoading: boolean;
  summaries: CustomDashboardSummary[];
}

export const Summary = (props: SummaryProps) => {
  const { summaries, isLoading } = props;
  const primaryColor = 'primary.main';

  const getValueByName = (name: string) => {
    return String(summaries.find((summary) => summary.name === name)?.value);
  };

  const BUSINESS_VALUE = 'Business Value Delivered';
  const REVENUE_INCREASE = 'Revenue Increase';
  const COST_SAVING = 'Cost Saving';
  const MODEL_PREDICTIONS = 'Model Predictions';
  const MODELS_IN_PRODUCTION = 'Models in Production';
  const PRODUCTION_INCIDENTS = 'Production Incidents';
  const COMPLIANCE_INCIDENTS = 'Compliance Incidents';

  return (
    <Stack spacing={3}>
      <Typography variant="h6">Summary</Typography>
      <Grid container gap={3} wrap="nowrap">
        <Grid item md={3}>
          <SummaryCard
            variant="square"
            title={BUSINESS_VALUE}
            value={getValueByName(BUSINESS_VALUE)}
            valueSymbol="$"
            icon={
              <IconAwesomeInfo
                icon={ICONS.briefcase}
                color={primaryColor}
                size={'xl'}
              />
            }
            isLoading={isLoading}
          />
        </Grid>
        <Grid item md={3}>
          <SummaryCard
            variant="square"
            title={REVENUE_INCREASE}
            value={getValueByName(REVENUE_INCREASE)}
            valueSymbol="$"
            icon={
              <IconAwesomeInfo
                icon={ICONS.arrowUpRightDots}
                color={primaryColor}
                size={'xl'}
              />
            }
            isLoading={isLoading}
          />
        </Grid>
        <Grid item md={3}>
          <SummaryCard
            variant="square"
            title={COST_SAVING}
            value={getValueByName(COST_SAVING)}
            valueSymbol="$"
            iconBackground=""
            icon={
              <IconAwesomeInfo
                icon={ICONS.piggyBank}
                color={primaryColor}
                size={'xl'}
              />
            }
            isLoading={isLoading}
          />
        </Grid>
        <Grid item md={3}>
          <SummaryCard
            variant="square"
            title={MODEL_PREDICTIONS}
            value={getValueByName(MODEL_PREDICTIONS)}
            icon={
              <IconAwesomeInfo
                icon={ICONS.diagramProject}
                color={primaryColor}
                size={'xl'}
              />
            }
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      <Grid container gap={3} wrap="nowrap">
        <Grid item md={4}>
          <SummaryCard
            variant="slim"
            title={MODELS_IN_PRODUCTION}
            value={getValueByName(MODELS_IN_PRODUCTION)}
            icon={
              <IconAwesomeInfo
                icon={ICONS.regularRectangleVertical}
                color={'text.secondary'}
                size={'1x'}
              />
            }
            isLoading={isLoading}
          />
        </Grid>
        <Grid item md={4}>
          <SummaryCard
            variant="slim"
            title={PRODUCTION_INCIDENTS}
            value={getValueByName(PRODUCTION_INCIDENTS)}
            iconBackground="#FEEBEF"
            icon={
              <IconAwesomeInfo
                icon={ICONS.hexagonExclamation}
                color={'error.light'}
                size={'1x'}
              />
            }
            isLoading={isLoading}
          />
        </Grid>
        <Grid item md={4}>
          <SummaryCard
            variant="slim"
            title={COMPLIANCE_INCIDENTS}
            value={getValueByName(COMPLIANCE_INCIDENTS)}
            iconBackground="#FEEBEF"
            icon={
              <IconAwesomeInfo
                icon={ICONS.hexagonExclamation}
                color={'error.light'}
                size={'1x'}
              />
            }
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
