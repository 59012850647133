import { keys } from 'ramda';

import { Distribution } from 'shared/models/Monitoring/Distribution/Distribution';
import {
  NormalizedDistribution,
  normalizeDistribution,
} from 'shared/models/Monitoring/Distribution/NormalizedDistribution';
import { head, isNonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';
import { GroupedBarData } from 'shared/view/charts/GroupedBarChart/BaseGroupedBarChart';

import { DistributionBarChartMeta } from './BaseDistributionBarChart';

const makeDistributionBarData = (props: {
  index: number;
  normalizedDistribution: NormalizedDistribution;
  normalizedReferenceDistributions: NormalizedDistribution[];
}): GroupedBarData<DistributionBarChartMeta> => {
  return {
    key: props.normalizedDistribution.modelVersion,
    meta: {
      count: props.normalizedDistribution.counts[props.index],
      totalCount: props.normalizedDistribution.totalCount,
      normalized: props.normalizedDistribution.normalizedValues[props.index],
      modelVersion: props.normalizedDistribution.modelVersion,
      colorKey: props.normalizedDistribution.modelVersion,
    },
    value: props.normalizedDistribution.normalizedValues[props.index],
  };
};

const makeSingleDistributionBarChartData = (props: {
  index: number;
  type: 'reference' | 'live';
  normalizedDistribution: NormalizedDistribution;
}): GroupedBarData<DistributionBarChartMeta> => {
  return {
    key: props.type,
    meta: {
      count: props.normalizedDistribution.counts[props.index],
      totalCount: props.normalizedDistribution.totalCount,
      normalized: props.normalizedDistribution.normalizedValues[props.index],
      modelVersion: props.normalizedDistribution.modelVersion,
      colorKey: props.type,
    },
    value: props.normalizedDistribution.normalizedValues[props.index],
  };
};

export const makeDistributionBarsDataBuilder = (props: {
  liveDistributions: Distribution[];
  referenceDistributions: Distribution[];
}) => {
  const normalizedLiveDistributions = props.liveDistributions.map(
    normalizeDistribution
  );
  const normalizedReferenceDistributions = props.referenceDistributions.map(
    normalizeDistribution
  );

  const isOnlySingleLiveReferenceDistribution =
    normalizedLiveDistributions.length === 1 &&
    normalizedReferenceDistributions.length === 1 &&
    isNonEmptyArray(normalizedLiveDistributions) &&
    isNonEmptyArray(normalizedReferenceDistributions);

  if (isOnlySingleLiveReferenceDistribution) {
    const liveReference = {
      live: head(normalizedLiveDistributions),
      reference: head(normalizedReferenceDistributions),
    };

    return (index: number) =>
      keys(liveReference).map((type) =>
        makeSingleDistributionBarChartData({
          index,
          normalizedDistribution: liveReference[type],
          type,
        })
      );
  }

  return (index: number) =>
    normalizedLiveDistributions.map((normalizedDistribution) =>
      makeDistributionBarData({
        index,
        normalizedDistribution,
        normalizedReferenceDistributions,
      })
    );
};
