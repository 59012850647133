import { useSelector } from 'react-redux';

import { selectFlags } from 'features/flags';
import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { useModelOverview } from 'features/catalog/registeredModel/overview/hooks';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';

const ModelOverview = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ModelOverview' */ 'features/catalog/registeredModel/overview/view/overview'
    )
);

const ModelOverviewPage = () => {
  const { registeredModelId, workspaceName } = useRouteParams(
    routes.modelOverview
  );
  const { monitoring, deployment } = useSelector(selectFlags);

  const { data, communication, reload } = useModelOverview({
    id: registeredModelId,
    isEnableDeployment: deployment.isEnable,
    isEnableMonitoring: monitoring.isEnable,
  });

  return (
    <DefaultMatchRemoteDataOrError
      communication={communication}
      data={data}
      context="loading model"
    >
      {(model) => (
        <ModelOverview
          model={model}
          reloadModel={reload}
          isLoadingModel={communication.isRequesting}
          workspaceName={workspaceName}
        />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default ModelOverviewPage;
