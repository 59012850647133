import { Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import manage1 from '../../../images/manage-users-1.png';

export const ManageUserList = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Manage Users
    </Typography>
    <Typography variant="body2" mb={3}>
      If you are an organization admin, you can track sign-ins on the
      organization admin panel.
    </Typography>
    <img
      src={manage1}
      width="90%"
      height="auto"
      alt="list of users on organization page"
    />
  </WizardStepContent>
);
