/// <reference types="@types/segment-analytics" />

import { ReactNode, useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Location, Params, useLocation, useParams } from 'react-router-dom';
import { values, keys } from 'lodash';

import { CustomEvent } from './types';

export type {
  RegisteredModel_Edited,
  RegisteredModel_Documentation_Edited,
  RegisteredModel_Documentation_Selected,
} from './types/registeredModel';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

export const load = (segmentWriteKey: string | null) => {
  try {
    if (segmentWriteKey) {
      window.analytics.load(segmentWriteKey);
    }
  } catch (e: unknown) {
    // if analytics call fails, we want to swallow the error
  }
};

export const page = (
  category: string,
  name: string,
  properties: Record<string, string | undefined>
) => {
  try {
    window.analytics.page(category, name, properties);
  } catch (e: unknown) {
    // if analytics call fails, we want to swallow the error
  }
};

export const identify = (userId: string, email: string) => {
  try {
    window.analytics.identify(userId, { email });
  } catch (e: unknown) {
    // if analytics call fails, we want to swallow the error
  }
};

export const trackEvent = (event: CustomEvent) => {
  try {
    const { type, data } = event;
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(`event tracked ${type}`, event);
    }
    window.analytics.track(type, data);
  } catch (e: unknown) {
    // if analytics call fails, we want to swallow the error
  }
};

const getPagePropsForSegment = ({
  location,
  params,
}: {
  location: Location;
  params: Params;
}) => {
  let category = '';
  let name = location.pathname;

  const path = location.pathname.split('/');
  const paramsKeys = keys(params);
  const paramsValues = values(params);

  for (let i = 0; i < paramsValues.length; i++) {
    for (let j = 0; j < path.length; j++) {
      if (path[j] === paramsValues[i]) {
        path[j] = `:${paramsKeys[i]}`;
      }
    }
  }

  for (let i = 0; i < path.length; i++) {
    if (category === '' || category === ':workspaceName') {
      category = path[i];
    } else {
      i = path.length;
    }
  }

  name = path.join('/');

  return { category, name, properties: params };
};

export const AnalyticsBoundary = (props: {
  children: JSX.Element | ReactNode;
}) => {
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    const { category, name, properties } = getPagePropsForSegment({
      location,
      params,
    });

    page(category, name, properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <>{props.children}</>;
};
