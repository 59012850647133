import { HttpActions } from 'services/HttpActions';
import { ILoadArtifactUrlService } from 'features/artifactManager/store/types';

const ExperimentsDataService: ILoadArtifactUrlService = {
  async loadArtifactUrl(experimentId, artifact): Promise<string> {
    const response = await HttpActions.post<{ url: string }>({
      url: '/v1/modeldb/experiment/getUrlForArtifact',
      data: {
        id: experimentId,
        key: artifact.key,
        artifact_type: artifact.type,
        method: 'GET',
      },
    });
    return response.data.url;
  },
};

export default ExperimentsDataService;
