import { ActionType, createReducer } from 'typesafe-actions';

import { darkModeStateStorageManager } from './storage';
import { DarkModeState } from './types';
import * as actions from './actions';

const initialState = {
  isEnableDarkMode: darkModeStateStorageManager.read() ?? false,
};

export const reducer = createReducer<DarkModeState, ActionType<typeof actions>>(
  initialState
).handleAction(actions.changeIsEnableDarkMode, (state, action) => ({
  ...state,
  isEnableDarkMode: action.payload,
}));
