import { ValueGetterParams } from 'shared/view/elements/DataGrid/configuration/valueGetter/ValueGetterParams';
import {
  DataGridColumnConfiguration,
  DataGridColumnConfigurationType,
  DataGridColumnConfigurationValueGetter,
  DataGridColumnSupportedPrimitives,
} from 'shared/view/elements/DataGrid/configuration/DataGridColumnConfiguration';

export function typeSafeConfiguration<
  Data,
  Type extends keyof DataGridColumnSupportedPrimitives,
>(
  configurationTypes: DataGridColumnConfigurationType[],
  supportedPrimitiveType: Type,
  valueGetter: (
    params: ValueGetterParams<Data>
  ) => DataGridColumnSupportedPrimitives[Type] | null | undefined
): DataGridColumnConfiguration<Data> {
  return {
    configurationTypes,
    valueGetter: valueGetter as DataGridColumnConfigurationValueGetter<Data>,
    type: supportedPrimitiveType,
  };
}
