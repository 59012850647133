import { gql } from '@apollo/client';

export const CHECKLIST_TEMPLATE_FRAGMENT = gql`
  fragment ChecklistTemplateData on ChecklistTemplate {
    id
    name
    blocksDeployment
    desiredStage
    applyToRiskLevel
    organizationId
    timeCreated
    timeUpdated
    sort
  }
`;
