import { ApolloClient } from '@apollo/client';

import { SearchEntities } from 'shared/models/HighLevelSearch/SearchEntities';
import { ISearchSettings } from 'shared/models/HighLevelSearch/SearchSettings';
import { SearchResult } from 'shared/models/HighLevelSearch/SearchResult';
import { EntitiesBySearchFields } from 'shared/models/HighLevelSearch/EntitiesBySearchFields';
import { SearchEntitiesResults } from 'shared/models/HighLevelSearch/SearchEntitiesResults';
import {
  DataWithPagination,
  IPaginationSettings,
} from 'shared/models/Pagination';
import { IWorkspace } from 'shared/models/Workspace';
import { exhaustiveCheck } from 'shared/utils/exhaustiveCheck';
import { RecordValues } from 'shared/utils/types';
import matchType from 'shared/utils/matchType';
import { paginationSettings } from 'features/highLevelSearch/constants';
import { moveFirstItem } from 'shared/utils/collection';
import { loadExperimentRunsListByWorkspace } from 'services/highLevelSearch/loadExperimentRunsListByWorkspace/loadExperimentRunsListByWorkspace';
import { loadEndpointResults } from 'services/highLevelSearch/endpoints/loadEndpointResults/loadEndpointResults';

import { loadDatasetResults } from './loadDatasetResults/loadDatasetResults';
import { loadRegisteredModelResults } from './loadRegisteredModelResults/loadRegisteredModelResults';
import { loadProjectResults } from './projects/loadProjectResults/loadProjectResults';
import loadDatasetVersionResults from './loadDatasetVersionResults/loadDatasetVersionResults';
import {
  IHighLevelDateSorting,
  IHighLevelSearchSettings,
} from './shared/types';
import { loadRegisteredModelVersionResults } from './loadRegisteredModelVersions/loadRegisteredModelVersions';
import { loadExperimentResults } from './loadExperiments/loadExperiments';

export type ILoadEntitiesByTypeResult = RecordValues<{
  [K in keyof SearchEntitiesResults]: {
    type: K;
    data: SearchEntitiesResults[K]['data'];
  };
}>;

interface ILoadEntitiesSettings {
  workspaceName: IWorkspace['name'];
  organizationId: string | undefined;
  pagination: IPaginationSettings;
  searchSettings: ISearchSettings;
  availableSearchEntities: SearchEntities[];
}

const makeHighLevelSearchService = (apolloClient: ApolloClient<unknown>) =>
  class HighLevelSearchService {
    static async loadFullEntitiesByType(
      settings: ILoadEntitiesSettings
    ): Promise<ILoadEntitiesByTypeResult> {
      const type = settings.searchSettings.type;
      switch (type) {
        case 'projects': {
          return {
            type,
            data: await this.loadProjectsBySearchFields(settings),
          };
        }
        case 'endpoints': {
          return {
            type,
            data: await this.loadEndpointsBySearchFields(settings),
          };
        }
        case 'experimentRuns': {
          return {
            type,
            data: await this.loadExperimentRunsBySearchFields(settings),
          };
        }
        case 'experiments': {
          return {
            type,
            data: await this.loadExperimentsBySearchFields(settings),
          };
        }
        case 'datasets': {
          return {
            type,
            data: await this.loadDatasetsBySearchFields(settings),
          };
        }
        case 'datasetVersions': {
          return {
            type,
            data: await this.loadDatasetVersionsBySearchFields(settings),
          };
        }
        case 'registeredModels': {
          return {
            type,
            data: await this.loadRegisteredModelsBySearchFields(settings),
          };
        }
        case 'registeredModelVersions': {
          return {
            type,
            data: await this.loadRegisteredModelVersionsBySearchFields(
              settings
            ),
          };
        }
        default:
          return exhaustiveCheck(type);
      }
    }

    static async loadFullEntitiesByTypeAndUpdateOthersCounts(
      handlers: {
        onSuccess: (res: ILoadEntitiesByTypeResult) => void;
        onError: (error: Error) => void;
      },
      settings: ILoadEntitiesSettings
    ) {
      const entitiesCountLoaders: {
        [K in SearchEntities]: (
          settings: ILoadEntitiesSettings
        ) => Promise<{ totalCount: number }>;
      } = {
        projects: this.loadProjectsBySearchFields,
        endpoints: this.loadEndpointsBySearchFields,
        experiments: this.loadExperimentsBySearchFields,
        datasets: this.loadDatasetsBySearchFields,
        datasetVersions: this.loadDatasetVersionsBySearchFields,
        experimentRuns: this.loadExperimentRunsBySearchFields,
        registeredModels: this.loadRegisteredModelsBySearchFields,
        registeredModelVersions: this.loadRegisteredModelVersionsBySearchFields,
      };

      const promises = moveFirstItem(
        (entityType) => settings.searchSettings.type === entityType,
        0,
        settings.availableSearchEntities
      ).map((entityType) => {
        const loadEntitiesCount = entitiesCountLoaders[entityType];
        return entityType === settings.searchSettings.type
          ? this.loadFullEntitiesByType(settings)
              .then(({ type, data }) =>
                handlers.onSuccess({ type, data } as ILoadEntitiesByTypeResult)
              )
              .catch(handlers.onError)
          : loadEntitiesCount(settings)
              .then((data) =>
                handlers.onSuccess({
                  type: entityType,
                  data,
                } as ILoadEntitiesByTypeResult)
              )
              .catch(handlers.onError);
      });

      await Promise.all(promises);
    }

    private static loadProjectsBySearchFields =
      this.makeLoadEntitiesBySearchFields('projects', (settings) =>
        loadProjectResults({ ...settings, apolloClient })
      );

    private static loadEndpointsBySearchFields =
      this.makeLoadEntitiesBySearchFields('endpoints', (settings) =>
        loadEndpointResults({ ...settings, apolloClient })
      );

    private static loadExperimentsBySearchFields =
      this.makeLoadEntitiesBySearchFields('experiments', (settings) =>
        loadExperimentResults({
          ...settings,
          apolloClient,
        })
      );

    private static loadExperimentRunsBySearchFields =
      this.makeLoadEntitiesBySearchFields('experimentRuns', (settings) =>
        loadExperimentRunsListByWorkspace({
          ...settings,
          apolloClient,
        })
      );

    private static loadDatasetsBySearchFields =
      this.makeLoadEntitiesBySearchFields('datasets', (settings) =>
        loadDatasetResults({ ...settings, apolloClient })
      );
    private static loadDatasetVersionsBySearchFields =
      this.makeLoadEntitiesBySearchFields(
        'datasetVersions',
        async (settings) => {
          const filter = settings.filter;
          return filter.type === 'tag'
            ? loadDatasetVersionResults({
                ...settings,
                filter,
                apolloClient,
              })
            : { data: [], totalCount: 0 };
        }
      );

    private static loadRegisteredModelsBySearchFields =
      this.makeLoadEntitiesBySearchFields('registeredModels', (settings) =>
        loadRegisteredModelResults({
          ...settings,
          apolloClient,
        })
      );

    private static loadRegisteredModelVersionsBySearchFields =
      this.makeLoadEntitiesBySearchFields(
        'registeredModelVersions',
        (settings) =>
          loadRegisteredModelVersionResults({
            ...settings,
            apolloClient,
          })
      );

    private static makeLoadEntitiesBySearchFields<
      E extends SearchResult['entityType'],
      T extends { id: string },
    >(
      entityType: E,
      f: (settings: IHighLevelSearchSettings) => Promise<DataWithPagination<T>>
    ) {
      return async (
        settings: ILoadEntitiesSettings
      ): Promise<Required<EntitiesBySearchFields<T & { entityType: E }>>> => {
        const entitiesByTypes = await this.loadEntitiesBySearchFields(
          f,
          settings
        );
        return this.getDisplayedEntitiesBySearchFields({
          entityType,
          ...entitiesByTypes,
        });
      };
    }

    private static async loadEntitiesBySearchFields<T extends { id: string }>(
      f: (settings: IHighLevelSearchSettings) => Promise<DataWithPagination<T>>,
      settings: ILoadEntitiesSettings
    ) {
      const pagination = { ...settings.pagination, totalCount: 0 };
      const sorting: IHighLevelDateSorting | undefined = settings.searchSettings
        .sorting
        ? {
            direction: settings.searchSettings.sorting.direction,
            key: undefined,
            field: matchType(
              {
                dateCreated: () => 'date_created',
                dateUpdated: () => 'date_updated',
              },
              settings.searchSettings.sorting.field
            ),
          }
        : undefined;

      const emptyResult = { totalCount: 0, data: [] as T[] };

      const entitiesByNamesPromise = settings.searchSettings.nameOrTag
        ? f({
            filter: {
              type: 'name',
              value: settings.searchSettings.nameOrTag,
            },
            pagination,
            sorting,
            workspaceName: settings.workspaceName,
            organizationId: settings.organizationId,
          }).catch(() => emptyResult)
        : emptyResult;
      const entitiesByTagsPromise = settings.searchSettings.nameOrTag
        ? f({
            filter: {
              type: 'tag',
              value: settings.searchSettings.nameOrTag,
            },
            pagination,
            sorting,
            workspaceName: settings.workspaceName,
            organizationId: settings.organizationId,
          }).catch(() => emptyResult)
        : Promise.resolve(emptyResult);

      const [entitiesByNames, entitiesByTags] = await Promise.all([
        entitiesByNamesPromise,
        entitiesByTagsPromise,
      ]);
      return {
        entitiesByNames,
        entitiesByTags,
      };
    }

    private static getDisplayedEntitiesBySearchFields<
      T extends { id: string },
      E extends SearchResult['entityType'],
    >({
      entityType,
      entitiesByNames,
      entitiesByTags,
    }: {
      entityType: E;
      entitiesByNames: DataWithPagination<T>;
      entitiesByTags: DataWithPagination<T>;
    }) {
      if (
        Math.max(entitiesByNames.totalCount, entitiesByTags.totalCount) <=
        paginationSettings.pageSize
      ) {
        const entitiesByNameWithoutEntityByTag = entitiesByNames.data
          .filter((x) =>
            entitiesByTags.data.every((entityByTag) => entityByTag.id !== x.id)
          )
          .map((x) => ({ ...x, entityType }));
        return {
          totalCount:
            entitiesByNameWithoutEntityByTag.length + entitiesByTags.totalCount,
          data: {
            tag: entitiesByTags.data.map((x) => ({ ...x, entityType })),
            name: entitiesByNameWithoutEntityByTag,
          },
        };
      }
      return {
        totalCount: entitiesByNames.totalCount + entitiesByTags.totalCount,
        data: {
          name: entitiesByNames.data.map((x) => ({ ...x, entityType })),
          tag: entitiesByTags.data.map((x) => ({ ...x, entityType })),
        },
      };
    }
  };

export default makeHighLevelSearchService;
