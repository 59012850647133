import { useAppBreadcrumbs } from 'features/layout/view/Layout/AppBreadcrumbs';
import MonitoringModelsTable from 'features/monitoring/models/modelsTable/view/MonitoringModelsTable/MonitoringModelsTable';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';

import { useMonitoringPageBreadcrumb } from '../shared/useMonitoringPageBreadcrumb';

const MonitoringPage = () => {
  return (
    <AuthorizedLayout
      breadcrumbs={useAppBreadcrumbs(useMonitoringPageBreadcrumb())}
      pageEntityOrError={undefined}
    >
      <MonitoringModelsTable />
    </AuthorizedLayout>
  );
};

export default MonitoringPage;
