import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { addError } from '../store/actions';
import { ErrorWithInfo } from '../store/types';

const useAddError = ({
  error,
  context,
}: {
  error: ErrorWithInfo['error'];
  context: string;
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(addError({ error, context, pathname }));
  }, [dispatch, error, context, pathname]);
};

export default useAddError;
