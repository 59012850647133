import { Box, Typography } from '@mui/material';

import {
  WizardStepContent,
  WizardStepContentProps,
} from 'shared/view/elements/Wizard';

import docsTabHighlight from '../images/docs-tab-highlight.png';

export const DocsTabHighlight = (props: WizardStepContentProps) => (
  <WizardStepContent {...props}>
    <Typography variant="subtitle1" mb={2}>
      Write model documentation
    </Typography>
    <Typography variant="body2" mb={2}>
      Our model documentation tool empowers everyone to contribute to model
      documentation with ease, utilizing model assistance for enhanced speed and
      accuracy.
    </Typography>
    <Typography variant="body2" mb={3}>
      Documentation is available for every version and each model card, allowing
      you to browse and edit them all conveniently on the model card.
    </Typography>
    <Box>
      <img
        src={docsTabHighlight}
        width="100%"
        height="auto"
        alt="homepage invite users button modal"
      />
    </Box>
  </WizardStepContent>
);
