import { useMemo } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MUIFormHelperText from '@mui/material/FormHelperText';

import DynamicTypography from 'shared/view/elements/DynamicTypography/DynamicTypography';

type Props = {
  errorMessage: string | undefined;
  hint?: string | JSX.Element;
  dataTestError?: string | undefined;
  error?: boolean;
};

export default function FormHelperText({
  errorMessage,
  hint,
  dataTestError,
  error,
}: Props) {
  const message = useMemo(() => {
    if (error && errorMessage !== undefined) return errorMessage;
    if (hint !== undefined) return hint;

    return '';
  }, [error, errorMessage, hint]);
  // hack to support https://vertaai.atlassian.net/browse/FE-355 - prevent jumping when error appears
  if (!errorMessage && !Boolean(hint)) {
    return (
      <MUIFormHelperText error={error}>
        <span>&nbsp;</span>
      </MUIFormHelperText>
    );
  }

  return (
    // component should be div otherwise we get the error about nested <p> tags
    <MUIFormHelperText component="div" error={error}>
      {typeof message === 'string' ? (
        <DynamicTypography
          value={message}
          dataTest={dataTestError}
          variant="helperText"
        />
      ) : (
        message
      )}
    </MUIFormHelperText>
  );
}
