import { scaleOrdinal } from '@visx/scale';
import { useMemo } from 'react';

import { OutlierDetectionOverTime } from 'shared/models/Monitoring/OutlierDetection';
import { useActiveKeys } from 'shared/utils/activeKeys';
import { Size } from 'shared/utils/charts/chartSizes';
import { OnSetReset } from 'shared/utils/charts/zoom/useZoomReset';
import ChartWithLegendContainer from 'shared/view/charts/ChartWithLegendContainer/ChartWithLegendContainer';
import { LegendItem } from 'shared/view/charts/CustomLegend/CustomLegend';

import OutlierDetectionChartCore from './OutlierDetectionChartCore/OutlierDetectionChartCore';
import {
  OutlierDetectionChartData,
  outlierDetectionChartDataTypes,
  getColorByOutlierDetectionChartDataType,
  getDataLabelByOutlierDetectionChartDataType,
  OutlierData,
  InlierData,
  MeanData,
  MedianData,
  NormalAreaData,
} from './OutlierDetectionChartCore/utils/types';

interface Props {
  outlierDetection: OutlierDetectionOverTime[];
  size: Size;
  id: string;
  onSetReset: OnSetReset;
}

const colorScale = scaleOrdinal({
  domain: outlierDetectionChartDataTypes,
  range: outlierDetectionChartDataTypes.map(
    getColorByOutlierDetectionChartDataType
  ),
});

const OutlierDetectionChart = (props: Props) => {
  const chartData = useMemo(
    () => props.outlierDetection.flatMap(convertData),
    [props.outlierDetection]
  );

  const { activeKeys: activeTypes, toggleKey: toggleType } = useActiveKeys(
    outlierDetectionChartDataTypes
  );

  const data = useMemo(
    () => chartData.filter((d) => activeTypes.includes(d.type)),
    [activeTypes, chartData]
  );

  return (
    <ChartWithLegendContainer
      widgetSize={props.size}
      legendProps={{
        position: 'right',
        scale: colorScale,
        renderItem: (type) => (
          <LegendItem
            color={colorScale(type)}
            label={getDataLabelByOutlierDetectionChartDataType(type)}
            onClick={() => toggleType(type)}
            isUnActive={!activeTypes.includes(type)}
          />
        ),
      }}
      legendWidth={150}
    >
      {(size) => (
        <OutlierDetectionChartCore
          id={props.id}
          data={data}
          onSetReset={props.onSetReset}
          width={size.width}
          height={size.height}
        />
      )}
    </ChartWithLegendContainer>
  );
};

const convertData = (
  data: OutlierDetectionOverTime
): Array<OutlierDetectionChartData> => {
  const x = data.time.valueOf();
  const outliers: OutlierData[] = data.outliers.map((y) => ({
    type: 'outlier',
    x,
    y,
    sigma: data.sigma,
    modelVersion: data.modelVersion,
  }));

  const inliers: InlierData[] = data.inliers.map((y) => ({
    type: 'inlier',
    x,
    y,
    sigma: data.sigma,
    modelVersion: data.modelVersion,
  }));

  const mean: MeanData = {
    x,
    y: data.mean,
    type: 'mean',
  };

  const median: MedianData = {
    x,
    y: data.median,
    type: 'median' as const,
  };

  const normalArea: NormalAreaData = {
    x,
    y1: data.mean + data.sigma * data.outlierSigmaThreshold,
    y0: data.mean - data.sigma * data.outlierSigmaThreshold,
    type: 'normalArea' as const,
  };

  return [mean, normalArea, inliers, outliers, median].flat();
};

export default OutlierDetectionChart;
