/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { useCustomLazyQuery } from 'shared/view/hooks/apollo/useCustomLazyQuery';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import {
  mapDataOrError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import isNotNil from 'shared/utils/isNotNill';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { ExtractByTypename } from 'shared/utils/types';

import * as Types from './graphql-types/useWorkspaceModels.generated';

export type RegisteredModels = ExtractByTypename<
  Types.UseCaseModels['workspace'],
  'Workspace'
>['registeredModels']['models'];

export const useWorkspaceModels = () => {
  const [load, queryResult] = useCustomLazyQuery<
    Types.UseCaseModels,
    Types.UseCaseModelsVariables
  >(QUERY);
  const { data, communication } = useMemoizedResultToCommunicationWithData({
    memoizedConvert,
    queryResult,
  });

  return {
    data,
    communication,
    load,
  };
};

const memoizedConvert = (res: Types.UseCaseModels) =>
  mapDataOrError(res.workspace, (workspaceData) => ({
    models: workspaceData.registeredModels.models.filter(isNotNil),
    totalCount: workspaceData.registeredModels.pagination.totalRecords,
  }));

const QUERY = gql`
  query UseCaseModels(
    $workspaceName: String!
    $pagination: PaginationQuery!
    $stringPredicates: [StringPredicate!]
    $sorting: SortingQuery!
    $organizationId: ID
  ) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        registeredModels(
          query: {
            pagination: $pagination
            stringPredicates: $stringPredicates
            sorting: $sorting
          }
        ) {
          models {
            id
            name
            description
            labels
            dateCreated
            dateUpdated
            taskType
            dataType
            customAttributeValues {
              id
              customAttributeDefinitionId
              selectedValue
              targetType
            }
            owner {
              ...OwnerData
            }
            workspace {
              id
              ...WorkspaceData
            }
          }
          pagination {
            totalRecords
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;
