import { SupportedIntegrations } from 'shared/models/SystemAdmin/SupportedIntegrations';
import matchType from 'shared/utils/matchType';
import PypiLogoSVG from 'features/systemAdmin/integrations/list/view/item/img/pypiLogo.svg?react';
import KafkaLogoSvg from 'features/systemAdmin/integrations/list/view/item/img/kafkaLogo.svg?react';
import ActiveDirectoryLogoSvg from 'features/systemAdmin/integrations/list/view/item/img/activeDirectoryLogo.svg?react';

export default function IntegrationItemLogo({
  type,
}: {
  type: SupportedIntegrations;
}) {
  return matchType(
    {
      Pypi: () => <PypiLogoSVG />,
      Kafka: () => <KafkaLogoSvg />,
      ActiveDirectory: () => <ActiveDirectoryLogoSvg />,
    },
    type
  );
}
