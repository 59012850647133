import { useCallback } from 'react';
import { Layout } from 'react-grid-layout';

import { MonitoringPanel } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringPanel';

export const useBaseMonitoringPanel = <Panel extends MonitoringPanel>({
  panel,
  updatePanel,
  deletePanel,
}: {
  panel: Panel;
  updatePanel: (panel: Panel) => void;
  deletePanel: (panelId: string) => void;
}) => {
  const updateLayout = useCallback(
    (layout: Layout[]) => {
      updatePanel({
        ...panel,
        layout,
      });
    },
    [panel, updatePanel]
  );

  const deleteCurrentPanel = useCallback(() => {
    deletePanel(panel.id);
  }, [panel, deletePanel]);

  const changePanelOpen = useCallback(
    (isOpen: boolean) => {
      updatePanel({ ...panel, isOpen });
    },
    [panel, updatePanel]
  );

  return {
    updateLayout,
    changePanelOpen,
    deleteCurrentPanel,
  };
};
