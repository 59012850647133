import { Box, Divider, Stack, Typography, Collapse, Grid } from '@mui/material';
import { FormikErrors, useFormikContext } from 'formik';
import { useState } from 'react';

import {
  validateNotEmpty,
  combineValidators,
  validateMaxLength,
} from 'shared/utils/validators';
import SelectField from 'shared/view/formComponents/formikFields/SelectField/SelectField';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import SwitchField from 'shared/view/formComponents/formikFields/SwitchField/SwitchField';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { IconAwesomeInfo } from 'shared/view/elements/IconAwesome/IconAwesomeInfo';
import matchType from 'shared/utils/matchType';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import Tooltip from 'shared/view/elements/Tooltip/Tooltip';
import {
  ChecklistTemplateItemType,
  ChecklistTemplateItemInput,
} from 'generated/types';
import Checkbox from 'shared/view/elements/Checkbox/Checkbox';

import { getField } from './ChecklistTemplatePopup';
import { OrganizationGovernance } from '../../store/useOrganizationGovernance';

interface ChecklistTemplateItemTypeOpt {
  label: string;
  value: ChecklistTemplateItemType;
}

const ITEM_TYPE_OPTS: ChecklistTemplateItemTypeOpt[] = [
  {
    label: 'Checkbox',
    value: ChecklistTemplateItemType.CHECKBOX,
  },
  // {
  //   label: 'System check',
  //   value: ChecklistTemplateItemType.SYSTEM,
  // },
];

// const SYSTEM_TYPE_OPTS = [
//   {
//     label: 'Dependencies',
//     value: 'Dependencies',
//   },
//   {
//     label: 'Model API',
//     value: 'Model API',
//   },
//   {
//     label: 'Security scan passed',
//     value: 'Security scan passed',
//   },
// ];

type Props = {
  expanded: boolean;
  onOpen: (idx: number) => void;
  onRemove: (idx: number) => void;
  value: ChecklistTemplateItemInput;
  index: number;
  deletable: boolean;
  error?: FormikErrors<ChecklistTemplateItemInput>;
  replace: (idx: number, value: ChecklistTemplateItemInput) => void;
  groups: OrganizationGovernance['groups'];
};

export const ChecklistTemplateItem = (props: Props) => {
  const {
    expanded,
    index,
    onOpen,
    onRemove,
    value,
    deletable,
    error,
    replace,
    groups,
  } = props;

  const [isRestricted, setIsRestricted] = useState(
    value.userGroupId && value.userGroupId !== '0' ? true : false
  );

  const groupOptions = groups.map((role) => ({
    label: role.name,
    value: role.id,
  }));

  const { setFieldValue } = useFormikContext();

  const handleGroup = () => {
    if (isRestricted) {
      setFieldValue(
        getField({
          checklistTemplateItems: {
            [index]: {
              userGroupId: null,
            },
          },
        }),
        undefined
      );
    }
    setIsRestricted(!isRestricted);
  };

  return (
    <Stack spacing={2}>
      <Divider />
      <Collapse in={expanded} sx={{ width: '100%' }} collapsedSize="28px">
        {expanded ? (
          <Grid container spacing={1} rowSpacing={2}>
            <Grid item xxs={4}>
              <SelectField
                label="Item type"
                onChange={(val) => {
                  replace(index, { ...value, itemType: val.value, name: '' });
                }}
                name={getField({
                  checklistTemplateItems: {
                    [index]: {
                      itemType: null,
                    },
                  },
                })}
                options={ITEM_TYPE_OPTS}
                withoutError
              />
            </Grid>
            <Grid item xxs={7}>
              {matchType(
                {
                  SYSTEM: () => (
                    <TextInputField
                      label="Item name"
                      name={getField({
                        checklistTemplateItems: {
                          [index]: {
                            name: null,
                          },
                        },
                      })}
                      isRequired
                      validate={combineValidators([
                        validateNotEmpty('Item name'),
                        validateMaxLength(250),
                      ])}
                      withoutError={!error?.name}
                      errorMessage={error?.name}
                    />
                    // <SelectField
                    //   label="Select item"
                    //   name={getField({
                    //     checklistTemplateItems: {
                    //       [index]: {
                    //         name: null,
                    //       },
                    //     },
                    //   })}
                    //   options={SYSTEM_TYPE_OPTS}
                    //   required
                    //   validate={validateNotEmpty('Item')}
                    //   withoutError={!error?.name}
                    //   errorMessage={error?.name}
                    // />
                  ),
                  CHECKBOX: () => (
                    <TextInputField
                      label="Item name"
                      name={getField({
                        checklistTemplateItems: {
                          [index]: {
                            name: null,
                          },
                        },
                      })}
                      isRequired
                      validate={combineValidators([
                        validateNotEmpty('Item name'),
                        validateMaxLength(250),
                      ])}
                      withoutError={!error?.name}
                      errorMessage={error?.name}
                    />
                  ),
                  WORKFLOW: () => <div>WORKFLOW</div>,
                  UNKNOWN: () => <div>UNKNOWN</div>,
                },
                value.itemType
              )}
            </Grid>
            <Grid item xxs={1}>
              <Box
                display="flex"
                justifyContent="end"
                alignItems="start"
                pt="12px"
              >
                <IconAwesomeClickable
                  onClick={() => onRemove(index)}
                  dataTest="delete-checklist-template-item"
                  size="lg"
                  icon={ICONS.trashCan}
                  disabled={!deletable}
                />
              </Box>
            </Grid>
            <Grid item xxs={11}>
              <TextInputField
                label="Item description (optional)"
                name={getField({
                  checklistTemplateItems: {
                    [index]: {
                      description: null,
                    },
                  },
                })}
                maxLength={250}
                hint="Max. 250 characters"
              />
            </Grid>
            <Grid item xxs={11} my="10px">
              <Checkbox
                onChange={handleGroup}
                dataTest="restricted"
                label="Restrict who can mark this checklist item complete to users within a group"
                value={isRestricted}
                maxWidth="100%"
                color="text.primary"
              />
              {isRestricted && (
                <Grid item xxs={12} ml="30px" mt="5px">
                  <SelectField
                    label="Select group"
                    name={getField({
                      checklistTemplateItems: {
                        [index]: {
                          userGroupId: null,
                        },
                      },
                    })}
                    options={groupOptions}
                    required
                    withoutError={!error?.userGroupId}
                    validate={validateNotEmpty('Group')}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xxs={11}>
              <SwitchField
                label="Mark item as required"
                name={getField({
                  checklistTemplateItems: {
                    [index]: {
                      required: null,
                    },
                  },
                })}
              />
            </Grid>
            {value.itemType === ChecklistTemplateItemType.CHECKBOX ? (
              <Grid item xxs={11}>
                <SwitchField
                  label="Ask for evidence"
                  info="Users will be prompted for a mandatory input field to provide evidence."
                  name={getField({
                    checklistTemplateItems: {
                      [index]: {
                        requireEvidence: null,
                      },
                    },
                  })}
                />
              </Grid>
            ) : null}
          </Grid>
        ) : (
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2">{value.name}</Typography>
            <Stack direction="row" alignItems="center">
              {error ? (
                <IconAwesomeInfo
                  icon={ICONS.triangleExclamation}
                  size="lg"
                  infoType="error"
                />
              ) : null}
              <Tooltip type="withSpan" title="Edit item">
                <IconAwesomeClickable
                  onClick={() => onOpen(index)}
                  dataTest="open-checklist-template-item"
                  size="lg"
                  icon={ICONS.pencil}
                />
              </Tooltip>
            </Stack>
          </Box>
        )}
      </Collapse>
    </Stack>
  );
};
