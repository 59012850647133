import { useRouteParams } from 'shared/utils/router/useRouteParams';
import routes from 'shared/routes';
import { makeLazyLoadComponentWithPreloader } from 'shared/view/elements/LazyLoadComponent/LazyLoadComponent';
import {
  useAppBreadcrumbs,
  useBreadcrumbItem,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { TabsLayout } from 'pages/authorized/shared/TabsLayout';
import { LinkButton } from 'shared/view/elements/Button';

const ImportedModels = makeLazyLoadComponentWithPreloader(
  () =>
    import(
      /* webpackChunkName: 'ImportedModels' */ 'features/catalog/importModels/importedModels/view/importedModels'
    )
);

const ImportedModelsPage = () => {
  const { workspaceName } = useRouteParams(routes.import);

  const breadcrumbs = useAppBreadcrumbs(
    useBreadcrumbItem({ name: 'Model catalog', route: routes.catalog }),
    useBreadcrumbItem({
      name: 'Imported models page',
      route: routes.importedModels,
    })
  );

  return (
    <TabsLayout
      breadcrumbs={breadcrumbs}
      pageEntityOrError={undefined}
      actions={[
        <LinkButton
          key="import-models"
          to={routes.import.getRedirectPath({
            workspaceName: workspaceName,
          })}
          variant="contained"
        >
          Import Models
        </LinkButton>,
      ]}
    >
      <ImportedModels workspaceName={workspaceName} />
    </TabsLayout>
  );
};

export default ImportedModelsPage;
