/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { useRouteParams } from 'shared/utils/router/useRouteParams';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import {
  useBreadcrumbItem,
  useAppBreadcrumbs,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import routes from 'shared/routes';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { TabsLayout } from 'pages/authorized/shared/TabsLayout';

import {
  ModelVersionCreation,
  ModelVersionCreationVariables,
} from './graphql-types/ModelVersionCreationPage.generated';
import { useModelPageBreadcrumbs } from '../../shared/useModelPageBreadcrumbs';
import CreateRegisteredModelVersion from './CreateRegisteredModelVersion';

const REGISTERED_MODEL = gql`
  query ModelVersionCreation($registeredModelId: ID!) {
    registeredModel(id: $registeredModelId) {
      ... on Error {
        ...ErrorData
      }
      ... on RegisteredModel {
        id
        name
        taskType
        dataType
        versions {
          versions {
            id
            stage
          }
        }
        allowedActions {
          ...AllowedActionsData
          lock
          unlock
        }
        workspace {
          id
          ...WorkspaceData
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;

const useRegisteredModel = ({
  registeredModelId,
}: {
  registeredModelId: string;
}) => {
  const query = useCustomQuery<
    ModelVersionCreation,
    ModelVersionCreationVariables
  >(REGISTERED_MODEL, {
    variables: {
      registeredModelId,
    },
  });

  const { data: registeredModel, communication: loadingRegisteredModel } =
    resultToCommunicationWithData((res) => res.registeredModel, query);

  return {
    registeredModel,
    loadingRegisteredModel,
  };
};

const RegisteredModelVersionCreationPage = () => {
  const { registeredModelId } = useRouteParams(
    routes.createRegisteredModelVersion
  );

  const { loadingRegisteredModel, registeredModel } = useRegisteredModel({
    registeredModelId,
  });

  const breadcrumbs = usePageBreadcrumbs();

  return (
    <TabsLayout breadcrumbs={breadcrumbs} pageEntityOrError={registeredModel}>
      <DefaultMatchRemoteDataOrError
        data={registeredModel}
        communication={loadingRegisteredModel}
        context="loading registered model"
      >
        {(loadedRegisteredModel) => (
          <CreateRegisteredModelVersion
            registeredModel={loadedRegisteredModel}
          />
        )}
      </DefaultMatchRemoteDataOrError>
    </TabsLayout>
  );
};

const usePageBreadcrumbs = () => {
  const params = useRouteParams(routes.registeredModelVersion);
  return useAppBreadcrumbs(
    useModelPageBreadcrumbs(params),
    useBreadcrumbItem({
      route: routes.createRegisteredModelVersion,
      name: 'Create',
    })
  );
};

export default RegisteredModelVersionCreationPage;
