import { CurrentUserData } from 'shared/graphql/User/graphql-types/User.generated';
import { OmitStrict, RecordFromUnion } from 'shared/utils/types';

export type WorkspaceType = 'user' | 'organization';

// todo remove this redundant type
export type WorkspaceName = string;

export type IWorkspace = RecordFromUnion<
  WorkspaceType,
  {
    user: IUserWorkspace;
    organization: IOrganizationWorkspace;
  }
>[WorkspaceType];

export type IUserWorkspace = {
  type: 'user';
  id: string;
  name: WorkspaceName;
  namespace: string | null;
  organizationName?: string;
};
export type IOrganizationWorkspace = {
  type: 'organization';
  id: string;
  name: WorkspaceName;
  organizationId: string;
  organizationName: string;
  namespace: string | null;
};

export type IWorkspaceV2 = {
  type: 'organization';
  id: string;
  name: WorkspaceName;
  organizationId: string;
  namespace: null;
};

export const convertOrganizationWorkspace = ({
  id: organizationId,
  workspace: { id, name, namespace, organizationV2 },
}: {
  id: string;
  workspace: {
    id: string;
    name: string;
    namespace: string | null;
    organizationV2?: { name: string } | undefined;
  };
}): IOrganizationWorkspace => {
  return {
    type: 'organization',
    id,
    name,
    organizationId,
    organizationName: organizationV2?.name || name,
    namespace,
  };
};

export interface IUserWorkspaces {
  user: IUserWorkspace;
  organizations: IOrganizationWorkspace[];
}

export type IOrganizationShortWorkspace = OmitStrict<
  IOrganizationWorkspace,
  'name'
>;
export type IUserShortWorkspace = OmitStrict<IUserWorkspace, 'name'>;
export type IShortWorkspace = IOrganizationShortWorkspace | IUserShortWorkspace;
export interface IEntityWithWorkspace {
  workspace: IWorkspace;
}

export type IEntityWithOrganizationWorkspace<T> = T & {
  workspace: IOrganizationWorkspace;
};
export const isEntityWithOrganizationWorkspace = <
  T extends IEntityWithWorkspace,
>(
  entity: T
): entity is IEntityWithOrganizationWorkspace<T> => {
  return entity.workspace.type === 'organization';
};

export type IOrganizationToWorkspaceData = {
  id: string;
  workspaceId: string;
  name: string;
  namespace: string | null;
};
export const organizationToWorkspace = (
  organization: IOrganizationToWorkspaceData
): IOrganizationWorkspace => ({
  type: 'organization',
  id: organization.workspaceId,
  name: organization.name,
  organizationId: organization.id,
  namespace: organization.namespace,
  organizationName: organization.name,
});

export const currentUserToWorkspace = (
  currentUser: CurrentUserData
): IUserWorkspace => ({
  type: 'user',
  id: currentUser.workspaceId,
  name: currentUser.username,
  namespace: null,
});

export const getDefaultWorkspace = (
  defaultWorkspaceId: string | null,
  userWorkspaces: IUserWorkspaces
): IWorkspace => {
  if (defaultWorkspaceId) {
    return (
      [userWorkspaces.user, ...userWorkspaces.organizations].find(
        (workspace) => workspace.id === defaultWorkspaceId
      ) || userWorkspaces.user
    );
  }
  return userWorkspaces.user;
};
