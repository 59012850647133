import { ComponentProps, Fragment, ReactNode, useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { OmitStrict } from 'shared/utils/types';
import matchBy from 'shared/utils/matchBy';

import { ConfirmActionBasicProps } from '../ConfirmAction/ConfirmAction';
import DefaultConfirmAction, {
  DefaultConfirmActionProps,
} from '../ConfirmAction/DefaultConfirmAction';
import Menu from '../Menu/Menu';
import { ButtonMenuItem, LinkMenuItem } from '../Menu/MenuItem';
import OptionalTooltip from '../Tooltip/OptionalTooltip';

interface Props {
  actions: MenuAction[];
  renderAnchor: ComponentProps<typeof Menu>['renderAnchor'];
}

const ActionsMenu = (props: Props) => {
  const [isConfirmOpen, changeIsConfirmOpen] = useState(false);

  return (
    <div>
      {props.actions.map((action) => (
        <Fragment key={`additional-content-${action.label}`}>
          {action.additionalContent}
        </Fragment>
      ))}

      <Menu isForceOpen={isConfirmOpen} renderAnchor={props.renderAnchor}>
        {props.actions.map((action) => (
          <MenuActionItem
            key={action.label}
            action={action}
            onConfirmOpenChange={changeIsConfirmOpen}
          />
        ))}
      </Menu>
    </div>
  );
};

interface BaseMenuAction {
  label: string;
  dataTest?: string;
  disabled?: boolean;
  isLoading?: boolean;
  tooltipTitle?: string;
  additionalContent?: ReactNode;
  labelColor?: string;
}

interface LinkMenuAction extends BaseMenuAction {
  type: 'link';
  to: string;
}

interface ButtonMenuAction extends BaseMenuAction {
  type: 'button';
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  confirmAction?: DefaultConfirmActionProps &
    OmitStrict<ConfirmActionBasicProps, 'children'>;
}

interface IconMenuAction extends BaseMenuAction {
  type: 'icon';
  icon: IconDefinition;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  confirmAction?: DefaultConfirmActionProps &
    OmitStrict<ConfirmActionBasicProps, 'children'>;
}

export type MenuAction = ButtonMenuAction | LinkMenuAction | IconMenuAction;

const MenuActionItem = (props: {
  action: MenuAction;
  onConfirmOpenChange: (isOpen: boolean) => void;
}) => {
  if (
    (props.action.type === 'button' || props.action.type === 'icon') &&
    props.action.confirmAction
  ) {
    const action = props.action;
    return (
      <DefaultConfirmAction
        {...props.action.confirmAction}
        onConfirmOpenChange={props.onConfirmOpenChange}
      >
        {(withConfirmAction) => (
          <CommonMenuActionItem
            {...action}
            onClick={(e) => {
              withConfirmAction(action.onClick)(e);
              e.stopPropagation();
            }}
          />
        )}
      </DefaultConfirmAction>
    );
  }

  return <CommonMenuActionItem {...props.action} />;
};

const CommonMenuActionItem = (action: MenuAction) => {
  let tooltipText = action.tooltipTitle ?? '';

  if (action.type === 'icon') {
    tooltipText = action.label;
  }

  return (
    <OptionalTooltip
      showTooltip={Boolean(tooltipText)}
      title={tooltipText}
      type="withSpan"
    >
      {matchBy(
        action,
        'type'
      )({
        button: (params) => (
          <ButtonMenuItem
            disabled={params.disabled}
            dataTest={params.dataTest}
            onClick={params.onClick}
            textColorizationStyle={{
              color: params.labelColor,
            }}
          >
            {params.label}
          </ButtonMenuItem>
        ),
        link: (params) => (
          <LinkMenuItem
            to={params.to}
            dataTest={params.dataTest}
            disabled={params.disabled}
            textColorizationStyle={{
              color: params.labelColor,
            }}
          >
            {action.label}
          </LinkMenuItem>
        ),
        icon: (params) => (
          <ButtonMenuItem
            disabled={params.disabled}
            dataTest={params.dataTest}
            onClick={params.onClick}
            textColorizationStyle={{
              color: params.labelColor,
            }}
          >
            {params.label}
          </ButtonMenuItem>
        ),
      })}
    </OptionalTooltip>
  );
};

export default ActionsMenu;
