import { Typography, Stack, Box, styled } from '@mui/material';

import { CustomAttributeTargetType } from 'generated/types';
import DeleteIconButton from 'shared/view/elements/DeleteIconButton/DeleteIconButton';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import ButtonWithPopup from 'shared/view/elements/ButtonWithPopup/ButtonWithPopup';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';
import { useDeleteCustomAttribute } from 'features/organizations/custom-attributes/hooks';
import Chip from 'shared/view/elements/Chip/Chip';
import matchType from 'shared/utils/matchType';
import { trackEvent } from 'setup/app/analytics';

import { CustomModelAttributePopup } from './CustomModelAttributePopup/CustomModelAttributePopup';
import {
  CustomAttributeDefinitions,
  CustomAttributeCategories,
} from '../graphql';

type AttrsWithValues = Record<string, boolean>;

type Props = {
  dataTest?: string;
  attribute: CustomAttributeDefinitions[0];
  reload: () => void;
  attrsWithValues: AttrsWithValues;
  categories: CustomAttributeCategories | null;
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '4px',
  border: theme.palette.border.default,
  width: '100%',
  padding: '18px 16px',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  marginRight: theme.spacing(4),
  span: {
    lineHeight: '18px',
  },
}));

const Actions = ({
  item,
  reload,
  attrsWithValues,
  categories,
}: {
  item: CustomAttributeDefinitions[0];
  reload: () => void;
  attrsWithValues: AttrsWithValues;
  categories: CustomAttributeCategories | null;
}) => {
  const { deleteCustomAttribute, communication } = useDeleteCustomAttribute({
    onCompleted: reload,
  });

  const isTargetTypeModel =
    item.targetType === CustomAttributeTargetType.REGISTERED_MODEL;

  return (
    <>
      <Box>
        <ButtonWithPopup
          button={(props) => (
            <IconAwesomeClickable
              {...props}
              onClick={() => {
                trackEvent({
                  type: isTargetTypeModel
                    ? 'organization.custom_attributes.registered_model_edit_clicked'
                    : 'organization.custom_attributes.registered_model_version_edit_clicked',
                });
                props.onClick();
              }}
              size="lg"
              dataTest="edit-checklist-template"
              icon={ICONS.pencil}
            />
          )}
          popup={(props) => (
            <CustomModelAttributePopup
              {...props}
              customAttribute={item}
              submitButton={{ children: 'Save changes' }}
              type={item.targetType}
              title={`Edit custom ${
                isTargetTypeModel ? 'model' : 'model version'
              } attribute`}
              organizationId={item.organizationId}
              attrsWithValues={attrsWithValues}
              categories={categories}
            />
          )}
        />
      </Box>

      <DeleteIconButton
        description={'custom attribute'}
        entityName="custom attribute"
        disabled={communication.isRequesting}
        customBasicText="Please be aware that the information associated with this custom attribute will be erased from all other models in your organization as well."
        additionalConfirmText="Make sure that you have carefully considered the implications before deciding to proceed with the deletion."
        longPressConfirmation
        onDelete={() => {
          trackEvent({
            type: isTargetTypeModel
              ? 'organization.custom_attributes.registered_model_delete_clicked'
              : 'organization.custom_attributes.registered_model_version_delete_clicked',
          });
          deleteCustomAttribute({
            organizationId: item.organizationId,
            customAttributeId: item.id,
          });
        }}
      />
    </>
  );
};

const CustomAttributeListItem = (props: Props) => {
  const categoryName =
    props.attribute.customAttributeCategoryId &&
    props.categories?.find(
      (category) => category.id === props.attribute.customAttributeCategoryId
    )?.name;

  return (
    <StyledBox>
      <Stack direction="row" spacing={0.5}>
        <Typography variant="body1" color="text.primary">
          {props.attribute.name}
        </Typography>
        <Typography variant="body1" color="text.secondary" ml="8px !important">
          |{' '}
          {matchType(
            {
              USER: () => 'List of users',
              CUSTOM_LIST: () => 'Custom List',
              GROUP: () => 'List of groups',
              TEXT: () => 'Short text input',
              LONG_TEXT: () => 'Long text input',
              NUMERIC: () => 'Numeric input',
              UNKNOWN: () => undefined,
            },
            props.attribute.attributeType
          )}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" pr={2} spacing={2}>
        {categoryName ? <StyledChip label={categoryName} size="small" /> : null}
        <Actions
          item={props.attribute}
          reload={props.reload}
          attrsWithValues={props.attrsWithValues}
          categories={props.categories}
        />
      </Stack>
    </StyledBox>
  );
};

export default CustomAttributeListItem;
