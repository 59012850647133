import { MonitoringMetricType } from 'generated/types';
import matchType from 'shared/utils/matchType';

import { MonitoredModelType } from './MonitoringModel/MonitoredModelType';

export const defaultMonitoringMetricType =
  MonitoringMetricType.PREDICTION_COUNT;

export const getLabelByMonitoringMetricType = (
  metric: MonitoringMetricType
) => {
  return matchType(
    {
      [MonitoringMetricType.ACCURACY]: () => 'Accuracy',
      [MonitoringMetricType.PRECISION]: () => 'Precision',
      [MonitoringMetricType.RECALL]: () => 'Recall',
      [MonitoringMetricType.F1]: () => 'F1',
      [MonitoringMetricType.PREDICTION_COUNT]: () => 'Prediction Count',
      [MonitoringMetricType.TPR]: () => 'True positives rate',
      [MonitoringMetricType.FPR]: () => 'False positives rate',
      [MonitoringMetricType.TP]: () => 'True positives',
      [MonitoringMetricType.FP]: () => 'False positives',
      [MonitoringMetricType.TN]: () => 'True negatives',
      [MonitoringMetricType.FN]: () => 'False negatives',
      [MonitoringMetricType.R2]: () => 'R-squared',
      [MonitoringMetricType.RMSE]: () => 'Root Mean Squared Error',
      [MonitoringMetricType.MSE]: () => 'Mean Squared Error',
      [MonitoringMetricType.MAE]: () => 'Mean absolute error',
    },
    metric
  );
};

export const getAvailableMetricTypesForSelection = (
  monitoredModelType: MonitoredModelType
) => {
  return matchType(
    {
      CLASSIFICATION: () => [
        MonitoringMetricType.PREDICTION_COUNT,
        MonitoringMetricType.ACCURACY,
        MonitoringMetricType.F1,
        MonitoringMetricType.PRECISION,
        MonitoringMetricType.RECALL,
        MonitoringMetricType.TPR,
        MonitoringMetricType.FPR,
        MonitoringMetricType.TN,
        MonitoringMetricType.TP,
        MonitoringMetricType.FP,
        MonitoringMetricType.FN,
      ],
      REGRESSION: () => [
        MonitoringMetricType.PREDICTION_COUNT,
        MonitoringMetricType.R2,
        MonitoringMetricType.RMSE,
        MonitoringMetricType.MSE,
        MonitoringMetricType.MAE,
      ],
    },
    monitoredModelType
  );
};

const classificationModelMetrics = [
  MonitoringMetricType.ACCURACY,
  MonitoringMetricType.PRECISION,
  MonitoringMetricType.RECALL,
  MonitoringMetricType.F1,
];
const regressionModelMetrics = [
  MonitoringMetricType.R2,
  MonitoringMetricType.RMSE,
  MonitoringMetricType.MSE,
  MonitoringMetricType.MAE,
];

export const getModelMetricsByType = (
  monitoredModelType: MonitoredModelType
) => {
  return matchType(
    {
      CLASSIFICATION: () => classificationModelMetrics,
      REGRESSION: () => regressionModelMetrics,
    },
    monitoredModelType
  );
};
