import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

import { ActiveDirectoryConfiguration as ActiveDirectoryConfigurationProps } from 'features/systemAdmin/integrations/active-directory/store/activeDirectoryConfiguration/graphql-types/useActiveDirectoryConfiguration.generated';
import RecordsSection, {
  RecordInfo,
} from 'shared/view/elements/RecordsSection/RecordsSection';
import ActiveDirectoryConfigurationToken from 'features/systemAdmin/integrations/active-directory/view/ActiveDirectoryConfigurationToken';
import Section from 'shared/view/elements/SectionComponents/Section/Section';
import CopyToClipboard from 'shared/view/elements/CopyToClipboard/CopyToClipboard';
import { IconAwesomeClickable } from 'shared/view/elements/IconAwesome/IconAwesomeClickable';
import { ICONS } from 'shared/view/elements/IconAwesome/ICONS';

type Props = {
  configuration: NonNullable<
    ActiveDirectoryConfigurationProps['activeDirectory']['configuration']
  >;
  organizations: NonNullable<
    ActiveDirectoryConfigurationProps['allOrganizations']
  >;
};

const SYNCHRONIZE_ACTIVE_DIRECTORY_TOOLTIP = `Your active directory server administrator, can synchronize all active directory Organizations, Teams and Roles with Verta Platform on a regular schedule or on-demand.`;
const SYNCHRONIZE_ACTIVE_DIRECTORY_PATTERNS = `verta:system-admins\nverta:org:[ORG]:admins\nverta:org:[ORG]:members\nverta:org:[ORG]:team:[TEAM]:members`;

export default function ActiveDirectoryConfiguration(props: Props) {
  const urlToCopy = `${window.location.origin}${props.configuration.url}`;

  return (
    <Stack direction="column" divider={<Divider />} spacing={3}>
      <Section
        label={'Active Directory provisioning'}
        info={
          'Share Verta address and token details with your Active Directory admin to provision users and groups.'
        }
      >
        <RecordsSection>
          <RecordInfo label={'Verta address'}>
            <FormControlLabel
              control={
                <CopyToClipboard text={urlToCopy}>
                  {(onCopy) => (
                    <IconAwesomeClickable
                      dataTest="copy-button"
                      icon={ICONS.copy}
                      onClick={onCopy}
                    />
                  )}
                </CopyToClipboard>
              }
              label={urlToCopy}
              labelPlacement={'start'}
            />
          </RecordInfo>
          <RecordInfo label={'Token'}>
            <ActiveDirectoryConfigurationToken
              token={props.configuration.token}
            />
          </RecordInfo>
        </RecordsSection>
      </Section>
      <Section
        label={'Synchronize Active Directory groups'}
        info={SYNCHRONIZE_ACTIVE_DIRECTORY_TOOLTIP}
      >
        <RecordsSection>
          <RecordInfo label={'Recommended metadata pattern'}>
            <Typography display={'inline'} style={{ whiteSpace: 'pre-line' }}>
              {SYNCHRONIZE_ACTIVE_DIRECTORY_PATTERNS}
            </Typography>
          </RecordInfo>
        </RecordsSection>
      </Section>
    </Stack>
  );
}
