import CustomTooltipWithBounds from 'shared/view/charts/shared/CustomTooltipWithBound/CustomTooltipWithBound';

import { FormatNumber, ParallelCoordinateChartData } from '../types';
import styles from './ParallelCoordinateChartTooltip.module.css';

interface Props {
  tooltipTop: number | undefined;
  tooltipLeft: number | undefined;
  tooltipData: ParallelCoordinateChartData;
  formatNumber: FormatNumber;
}

const ParallelCoordinateChartTooltip: React.FC<
  React.PropsWithChildren<Props>
> = ({ tooltipData, tooltipTop, tooltipLeft, formatNumber }) => {
  return (
    <CustomTooltipWithBounds
      key={Math.random()}
      top={tooltipTop}
      left={tooltipLeft}
    >
      {Object.entries(tooltipData).map(([key, value]) => (
        <div className={styles.tooltipRow} key={key}>
          <span className={styles.tooltipKey}>{key}:</span>
          {typeof value === 'number' ? formatNumber(value) : value}
        </div>
      ))}
    </CustomTooltipWithBounds>
  );
};

export default ParallelCoordinateChartTooltip;
