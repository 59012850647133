/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client';

import { WORKSPACE_FRAGMENT } from 'shared/graphql/Workspace';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { BUILD_OR_ERROR_FRAGMENT } from 'shared/graphql/Deployment/Endpoint/Endpoint';
import { PERMISSION_FRAGMENT } from 'shared/graphql/Permission/Permission';
import { OmitStrict } from 'shared/utils/types';
import { KAFKA_CONFIGURATIONS_SIMPLIFIED_LIST_FRAGMENT } from 'shared/graphql/Deployment/Endpoint/KafkaConfig/KafkaConfiguration';
import { EndpointWithKafkaConfigurations } from 'features/deployment/canary/shared/EndpointWithKafkaConfigurations';
import {
  mapDataOrError,
  RestrictedGraphqlErrorOrData,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';
import { OWNER_FRAGMENT } from 'shared/graphql/OwnerFragment';
import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { ExtractByTypename } from 'shared/utils/types';
import { MACHINE_CONFIG_INFO_QUERY } from 'shared/graphql/Deployment/Endpoint/MachineConfig/MachineConfigInfo';

import * as GraphqlTypes from './graphql-types/endpointOverview.generated';

type Workspace = ExtractByTypename<
  GraphqlTypes.EndpointOverview['workspace'],
  'Workspace'
>;
type Environments = Endpoint['environments'];
export type ICanaryStrategy = NonNullable<
  Environments[0]['updateRequest']
>['canaryStrategy'];
export type Endpoint = ExtractByTypename<Workspace['endpoint'], 'Endpoint'>;

const ENDPOINT_OVERVIEW = gql`
  query EndpointOverview(
    $workspaceName: String!
    $organizationId: ID
    $id: ID!
  ) {
    kafkaConfigurations {
      id
      ...KafkaConfigurationsSimplifiedListData
    }
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        endpoint(id: $id) {
          ... on Error {
            ...ErrorData
          }
          ... on Endpoint {
            id
            description
            labels
            dateCreated
            dateUpdated
            path
            fullUrl
            owner {
              ...OwnerData
            }
            allowedActions {
              ...AllowedActionsData
            }
            environments {
              id
              status
              tokens {
                id
                value
              }
              updateRequest {
                canaryStrategy {
                  progressInterval
                  progressStep
                  rules {
                    id
                    parameters {
                      name
                      value
                    }
                  }
                }
                ...MachineConfigInfo
              }
              components {
                build {
                  ...BuildOrErrorData
                }
                status
              }
              rolloutStatus {
                time
                components {
                  buildID
                  ratio
                }
              }
              kafka {
                status
                message
              }
              tokensEnabled
            }
            workspace {
              id
              ...WorkspaceData
            }
            visibility
            customPermission {
              ...PermissionData
            }
          }
        }
      }
    }
  }
  ${OWNER_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${BUILD_OR_ERROR_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${PERMISSION_FRAGMENT}
  ${MACHINE_CONFIG_INFO_QUERY}
  ${KAFKA_CONFIGURATIONS_SIMPLIFIED_LIST_FRAGMENT}
`;

export const useEndpointOverviewQuery = (
  { workspaceName, id, organizationId }: GraphqlTypes.EndpointOverviewVariables,
  options?: OmitStrict<
    QueryHookOptions<any, GraphqlTypes.EndpointOverviewVariables>,
    'variables'
  >
) => {
  const queryResult = useCustomQuery<
    GraphqlTypes.EndpointOverview,
    GraphqlTypes.EndpointOverviewVariables
  >(ENDPOINT_OVERVIEW, {
    variables: {
      workspaceName,
      id,
      organizationId,
    },
    ...options,
  });

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert,
    queryResult,
  });
};

const memoizedConvert = (
  data: GraphqlTypes.EndpointOverview
): RestrictedGraphqlErrorOrData<EndpointWithKafkaConfigurations<Endpoint>> => {
  return mapDataOrError(
    data.workspace,
    (x) => x.endpoint,
    (endpoint) => ({
      endpoint: endpoint,
      kafkaConfigurations: data.kafkaConfigurations.configurations,
    })
  );
};
