import {
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Chip as MUIChip,
  ChipProps as MUIChipProps,
  styled,
  Theme,
  SxProps,
} from '@mui/material';
import { alpha } from '@mui/material';

import capitalize from 'shared/utils/capitalize';

// ts-unused-exports:disable-next-line
export interface ChipProps extends MUIChipProps {}
//@ts-ignore
// ts-unused-exports:disable-next-line
export const Chip = styled(MUIChip)((props) => {
  const { theme, variant, color } = props;

  const defaultColors: SxProps<Theme> = {
    color: theme.palette.text.primary,
    borderColor: theme.palette.default.main,
    '& .MuiChip-deleteIconFilledColorDefault': {
      color: alpha(theme.palette.default.dark || '#000', 0.7),
      ':hover': { color: theme.palette.default.dark || '#000' },
    },
  };

  if (variant === 'outlined' && (color === 'default' || color === undefined)) {
    return defaultColors;
  }

  if (variant === 'filled' && (color === 'default' || color === undefined)) {
    defaultColors.backgroundColor = theme.palette.default.main || '#000';
    return defaultColors;
  }

  if (variant === 'outlined' && color !== undefined) {
    const outlinedColors: SxProps<Theme> = {
      color: theme.palette[color].main,
      borderColor: theme.palette[color].main,
    };
    //@ts-ignore
    outlinedColors[`& .MuiChip-deleteIconOutlinedColor${capitalize(color)}`] = {
      color: theme.palette[color].main,
      ':hover': { color: theme.palette[color].dark },
    };

    return outlinedColors;
  }
});

// ts-unused-exports:disable-next-line
export default Chip;
