import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';

import { NonEmptyArray } from 'shared/utils/opaqueTypes/NonEmptyArray';
import { IBreadcrumbItem } from 'shared/view/elements/Breadcrumbs/Breadcrumbs';

export type AppBreadcrumbs = [IBreadcrumbItem, ...IBreadcrumbItem[]] & {
  __type: 'appBreadcrumbs';
};

export const useBreadcrumbItem = (
  props: {
    route: {
      getRedirectPath(params: unknown): string;
      getPath(): string;
    };
    topEnhancer?: React.ReactNode | undefined;
    hasTopEnhancer?: boolean;
  } & (
    | { isLoading: true; name?: undefined }
    | { isLoading?: false | undefined; name: string }
  )
): IBreadcrumbItem => {
  const params = useParams();

  return useMemo(
    () => ({
      isLoaded: !Boolean(props.isLoading),
      name: props.isLoading ? '' : props.name,
      redirectPath: props.route.getRedirectPath(params),
      topEnhancer: props.topEnhancer,
      hasTopEnhancer: props.hasTopEnhancer,
    }),
    [
      params,
      props.hasTopEnhancer,
      props.isLoading,
      props.name,
      props.route,
      props.topEnhancer,
    ]
  );
};

export const useAppBreadcrumbs = (
  ...breadcrumbsArray: NonEmptyArray<AppBreadcrumbs | IBreadcrumbItem>
): AppBreadcrumbs => {
  const breadcrumbs: IBreadcrumbItem[] = useMemo(() => {
    return breadcrumbsArray.flat();
    // we should track changes elements of breadcrumbs, so this `[firstBreadcrumb, restBreadcrumbs]` is wrong
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, breadcrumbsArray);
  return breadcrumbs as AppBreadcrumbs;
};
