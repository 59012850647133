import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import { RESTRICTED_GRAPHQL_ERROR_FRAGMENT } from 'shared/graphql/ErrorFragment';
import { useMemoizedResultToCommunicationWithData } from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { ExtractByTypename } from 'shared/utils/types';

import * as Types from './graphql-types/useOrganizationWorkspaces.generated';

export { Types };

export type OrganizationWorkspacesV2 = ExtractByTypename<
  Types.OrganizationWorkspacesV2['organizationV2ByName'],
  'OrganizationV2'
>;

export const QUERY = gql`
  query OrganizationWorkspacesV2($organizationName: String!) {
    organizationV2ByName(name: $organizationName) {
      ... on Error {
        ...ErrorData
      }
      ... on OrganizationV2 {
        id
        name
        roles {
          id
          name
          description
          builtIn
        }
        groups {
          id
          name
          description
          builtIn
        }
        workspaces {
          orgId
          id
          name
          description
          namespace
          createdTimestamp
          updatedTimestamp
          builtIn
          allowedActions {
            ...AllowedActionsData
          }
          permissions {
            role {
              id
              name
              description
              builtIn
            }
            group {
              id
              name
              description
              builtIn
            }
          }
        }
      }
    }
  }
  ${ALLOWED_ACTIONS_FRAGMENT}
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
`;

const useOrganizationWorkspaces = (organizationName: string) => {
  const queryResult = useCustomQuery<
    Types.OrganizationWorkspacesV2,
    Types.OrganizationWorkspacesV2Variables
  >(QUERY, { variables: { organizationName } });

  return useMemoizedResultToCommunicationWithData({
    memoizedConvert: (x) => x.organizationV2ByName,
    queryResult,
  });
};

export default useOrganizationWorkspaces;
