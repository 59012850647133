import { pipe } from 'ramda';

import { weakGroupBy } from 'shared/utils/collection';
import exhaustiveStringTuple from 'shared/utils/exhaustiveStringTuple';
import handleUnionCases from 'shared/utils/handleUnionCases';
import isNotNil from 'shared/utils/isNotNill';
import { DataSpec } from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/DataSpec';
import {
  KeyValueDataType,
  KeyValueSpec,
} from 'shared/models/CrossRunDashboard/CrossRunWidget/CrossRunCustomWidget/KeyValueSpec';
import {
  IGroupedOptions,
  IOptionsGroup,
  IOptionType,
} from 'shared/view/elements/Selects/shared/types';

export const dataSpecsToGroupedOptions = <
  T extends KeyValueDataType = KeyValueDataType,
>(
  dataSpecs: DataSpec<T>[]
): IGroupedOptions<DataSpec<T>> => {
  const dateSpec = dataSpecs.find(({ type }) => type === 'dateUpdated');
  const keyValueSpecs = dataSpecs
    .map((spec) => (spec.type === 'keyValue' ? spec : undefined))
    .filter(isNotNil);

  return {
    type: 'groupedOptions',
    groups: handleUnionCases<
      DataSpec['type'],
      Array<IOptionsGroup<DataSpec<T>>> | undefined
    >()([
      [
        'dateUpdated',
        () =>
          dateSpec
            ? [
                {
                  label: 'Date',
                  options: [{ label: 'Date', value: dateSpec }],
                },
              ]
            : undefined,
      ],
      ['keyValue', () => keyValueSpecsToOptionsGroups(keyValueSpecs)],
    ])
      .flatMap((x) => x)
      .filter(isNotNil),
  };
};

export const keyValueSpecsToGroupedOptions = <T extends KeyValueSpec>(
  specs: T[]
): IGroupedOptions<T> => ({
  type: 'groupedOptions',
  groups: keyValueSpecsToOptionsGroups(specs),
});

export const keyValueSpecsToOptionsGroups = <T extends KeyValueSpec>(
  specs: T[]
) => {
  return pipe(
    () => dataSpecsToOptions(specs),
    (x) => weakGroupBy(({ value }) => value.subtype, x),
    (x) => {
      return exhaustiveStringTuple<KeyValueSpec['subtype']>()(
        'metric',
        'hyperparameter',
        'attribute'
      )
        .map((subtype): IOptionsGroup<T> | undefined => {
          const options = x[subtype];
          return options ? { label: subtype, options } : undefined;
        })
        .filter(isNotNil);
    }
  )();
};

const dataSpecsToOptions = <T extends DataSpec>(
  specs: T[]
): Array<IOptionType<T>> =>
  specs.map((spec) => ({ label: spec.key, value: spec }));
