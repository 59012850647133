import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import routes from 'shared/routes';

import { selectCurrentWorkspaceName } from '../selectors';

export const useWorkspaceNameFromURL = () => {
  const location = useLocation();
  const currentWorkspaceName = useSelector(selectCurrentWorkspaceName);
  const match = routes.workspace.getMatch(location.pathname);

  return match?.workspaceName || currentWorkspaceName;
};
