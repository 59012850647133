import { createReducer, ActionType, getType } from 'typesafe-actions';
import { combineReducers } from 'redux';

import * as ActionHelpers from 'shared/utils/redux/actions';
import makeResetReducer from 'shared/utils/redux/makeResetReducer';

import * as actions from '../actions';
import * as Types from '../types';
import {
  bulkPredictionReducer,
  actions as bulkPredictionActions,
} from '../bulkPredictionPolling';

export default makeResetReducer(
  getType(actions.reset),
  combineReducers<Types.IDeployedExperimentRunPlaygroundState>({
    data: combineReducers<Types.IDeployedExperimentRunPlaygroundState['data']>({
      deployedExperimentRunInfo: createReducer<
        Types.IDeployedExperimentRunPlaygroundState['data']['deployedExperimentRunInfo'],
        ActionType<typeof actions.loadDeployedExperimentRunInfo>
      >(null).handleAction(
        actions.loadDeployedExperimentRunInfo.success,
        // todo probably legacy code and can produce malfunctions. Need to be checked and fixed;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (_, action) => action.payload.deployedExperimentRunInfo ?? null
      ),

      output: createReducer<
        Types.IDeployedExperimentRunPlaygroundState['data']['output'],
        | ActionType<typeof actions.runPrediction.success>
        | ActionType<typeof bulkPredictionActions>
      >(null)
        .handleAction(
          actions.runPrediction.success,
          (_, action) => action.payload.output ?? null
        )
        .handleAction(
          bulkPredictionActions.loadedOutput,
          (_, action) => action.payload ?? null
        ),
    }),
    communications: combineReducers<
      Types.IDeployedExperimentRunPlaygroundState['communications']
    >({
      loadingDeployedExperimentRunInfo:
        ActionHelpers.makeCommunicationReducerFromResetableAsyncAction(
          actions.loadDeployedExperimentRunInfo
        ),
      runningPrediction:
        ActionHelpers.makeCommunicationReducerFromResetableAsyncAction(
          actions.runPrediction
        ),
      bulkPrediction: bulkPredictionReducer,
    }),
  })
);
