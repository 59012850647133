import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { MonitoringWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidget';
import Popup from 'shared/view/elements/Popup/Popup';
import { usePopupManager } from 'shared/view/hooks/usePopupManager';
import { MonitoringWidgetExternalDeps } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/MonitoringWidgetExternalDeps';

import { MonitoringWidgetFormType } from '../MonitoringWidgetForm/MonitoringWidgetForm';
import MonitoringWidgetBuilder from './MonitoringWidgetBuilder/MonitoringWidgetBuilder';

interface Props {
  widgetExternalDeps: MonitoringWidgetExternalDeps;
  createWidget: (MonitoringWidget: MonitoringWidget) => void;
}

const useCreateWidgetAction = (props: Props) => {
  const { openPopup, isPopupOpen, closePopup } = usePopupManager();

  const { createWidget } = props;

  const onSubmit = useCallback(
    (form: MonitoringWidgetFormType) => {
      createWidget({
        id: uuidv4(),
        ...form,
      });
      closePopup();
    },
    [createWidget, closePopup]
  );

  return {
    onClick: openPopup,
    popup: (
      <Popup title="Create widget" isOpen={isPopupOpen} onClose={closePopup}>
        <MonitoringWidgetBuilder
          onSubmit={onSubmit}
          closePopup={closePopup}
          openPopup={openPopup}
          widgetExternalDeps={props.widgetExternalDeps}
        />
      </Popup>
    ),
  };
};

export default useCreateWidgetAction;
