import { Grid, GridProps, styled } from '@mui/material';

export const Layout = (props: GridProps) => (
  <Grid container flex={1} height="100%" {...props} />
);

export const LeftSlot = (props: GridProps) => (
  <Grid item xxs={12} sm={5} md={4} height="100%" {...props} />
);

export const RightSlot = styled((props: GridProps) => (
  <Grid item xxs={0} sm={7} md={8} {...props} />
))(({ theme }) => ({
  height: '100%',
  borderTopLeftRadius: '40px',
  borderBottomLeftRadius: '40px',
  background: theme.palette.additionalBackground.loginBackground,
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
