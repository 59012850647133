import { Stack, Typography } from '@mui/material';
import * as React from 'react';

import InlineLink from '../../InlineLink/InlineLink';
import NotFoundInfoGraphic from '../NotFoundInfoGraphic/NotFoundInfoGraphic';

interface ILocalProps {
  errorMessage: string;
}

class NotFound extends React.PureComponent<ILocalProps> {
  public render() {
    const { errorMessage } = this.props;
    return (
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        margin="4em 0 0.5em"
      >
        <Typography variant="h6" align="center">
          {errorMessage}
        </Typography>
        <Typography color="default.dark" align="center" fontSize="14px">
          Visit the <InlineLink to={'/'}>Homepage</InlineLink> or contact us
          about the problem.
        </Typography>
        <div
          style={{
            height: '300px',
            width: '357px',
          }}
        >
          <NotFoundInfoGraphic />
        </div>
      </Stack>
    );
  }
}

export default NotFound;
