/* eslint-disable rulesdir/no-deprecated-fields */
import { gql } from '@apollo/client';

import { useCustomQuery } from 'shared/view/hooks/apollo/useCustomQuery';
import { IWorkspace } from 'shared/models/Workspace';
import resultToCommunicationWithData from 'shared/utils/graphql/queryResultToCommunicationWithData';
import { ALLOWED_ACTIONS_FRAGMENT } from 'shared/graphql/collaborators/fragments';
import {
  isNotNullableRestrictedGraphqlError,
  RESTRICTED_GRAPHQL_ERROR_FRAGMENT,
} from 'shared/graphql/ErrorFragment';

import * as Types from './graphql-types/registeredModels.generated';

const REGISTERED_MODELS = gql`
  query RegisteredModels($workspaceName: String!, $organizationId: ID) {
    workspace(name: $workspaceName, organizationId: $organizationId) {
      ... on Error {
        ...ErrorData
      }
      ... on Workspace {
        id
        registeredModels {
          models {
            id
            name
            allowedActions {
              ...AllowedActionsData
              lock
            }
          }
        }
      }
    }
  }
  ${RESTRICTED_GRAPHQL_ERROR_FRAGMENT}
  ${ALLOWED_ACTIONS_FRAGMENT}
`;
export const useRegisteredModels = ({
  workspaceName,
  organizationId,
}: {
  workspaceName: IWorkspace['name'];
  organizationId: string | undefined;
}) => {
  const query = useCustomQuery<
    Types.RegisteredModels,
    Types.RegisteredModelsVariables
  >(REGISTERED_MODELS, {
    variables: {
      workspaceName,
      organizationId,
    },
  });

  const { data: registeredModels, communication: loadingRegisteredModels } =
    resultToCommunicationWithData(
      (res) =>
        isNotNullableRestrictedGraphqlError(res.workspace)
          ? res.workspace.registeredModels.models
          : [],
      query
    );

  return {
    registeredModels: registeredModels || [],
    loadingRegisteredModels,
  };
};
