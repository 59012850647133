import { EffectCallback, useEffect, useState } from 'react';

export const useFirstRenderEffect = (callback: EffectCallback) => {
  const [isFirstRender, changeIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      callback();
      changeIsFirstRender(false);
    }
  }, [callback, changeIsFirstRender, isFirstRender]);
};
