import React from 'react';

import TagsManager from 'shared/view/domain/Labels/TagsManager/TagsManager';
import { canUserUpdateRegisteredModelVersion } from 'shared/models/Registry/RegisteredModelVersion/Access';
import { RegisteredModel } from 'features/catalog/registeredModel/overview/graphql';

import {
  useAddLabelMutation,
  useDeleteLabelMutation,
} from '../../store/endpointLabelsManager/endpointLabelsManager';

interface ILocalProps
  extends Pick<RegisteredModel, 'id' | 'labels' | 'allowedActions'> {}

const EndpointLabelsManager: React.FC<ILocalProps> = ({
  id,
  labels,
  allowedActions,
}) => {
  const [addLabel, addingLabel] = useAddLabelMutation();
  const [deleteLabel, deletingLabel] = useDeleteLabelMutation();

  return (
    <TagsManager
      tags={labels}
      isEditable={canUserUpdateRegisteredModelVersion(allowedActions)}
      isUpdating={addingLabel.isRequesting || deletingLabel.isRequesting}
      onAddTag={(label) => {
        return addLabel({ id, labels: [label] });
      }}
      onRemoveTag={(label) => {
        return deleteLabel({ id, label });
      }}
      tagProps={{ color: 'primary' }}
    />
  );
};

export default EndpointLabelsManager;
