import { FC } from 'react';

import PagesTabs from 'shared/view/pages/PagesTabs/PagesTabs';
import routes from 'shared/routes';
import AuthorizedLayout from 'pages/authorized/shared/AuthorizedLayout/AuthorizedLayout';
import getTabs from 'shared/view/pages/PagesTabs/getTabs';

type Props = Pick<
  React.ComponentProps<typeof AuthorizedLayout>,
  'actions' | 'children' | 'breadcrumbs'
>;

const SystemAdminPagesLayout: FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, breadcrumbs, actions } = props;

  return (
    <AuthorizedLayout
      pageEntityOrError={undefined}
      breadcrumbs={breadcrumbs}
      actions={actions}
      tabsElement={
        <PagesTabs
          tabs={getTabs(routes.systemAdmin.$)(
            [
              ['admins', () => 'Admins'],
              ['users', () => 'Users'],
              ['organizations', () => 'Organizations'],
              ['integrations', () => 'Integrations'],
            ],
            {}
          )}
        />
      }
    >
      {children}
    </AuthorizedLayout>
  );
};

export default SystemAdminPagesLayout;
