import { MonitoringBooleanFilter } from 'shared/models/Monitoring/MonitoringFilters/MonitoringFilter';
import Select from 'shared/view/elements/Selects/Select/Select';

interface Props {
  filter: MonitoringBooleanFilter;
  updateFilter: (filter: MonitoringBooleanFilter) => void;
}

const options = [true, false].map((value) => ({
  label: value.toString(),
  value,
}));

const MonitoringBooleanFilterValueSelect = (props: Props) => {
  return (
    <Select
      options={options}
      value={options.find((opt) => opt.value === props.filter.value)}
      onChange={(opt) =>
        props.updateFilter({
          ...props.filter,
          value: opt.value,
        })
      }
    />
  );
};

export default MonitoringBooleanFilterValueSelect;
