import { useRouteParams } from 'shared/utils/router/useRouteParams';
import {
  useBreadcrumbItem,
  useAppBreadcrumbs,
} from 'features/layout/view/Layout/AppBreadcrumbs';
import { useMonitoringModelContext } from 'features/monitoring/models/model/store/state/context';
import routes from 'shared/routes';
import MonitoringAlertHistory from 'features/monitoring/alerts/view/MonitoringAlertHistory/MonitoringAlertHistory';
import { useAlertHistory } from 'features/monitoring/alerts/store/alertHistory/useAlertHistory';
import { isNotRestrictedGraphqlError } from 'shared/graphql/ErrorFragment';

import MonitoringAlertsLayout from '../shared/MonitoringAlertsLayout/MonitoringAlertsLayout';
import { useMonitoringModelPageBreadcrumbs } from '../shared/useMonitoringModelPageBreadcrumbs';

const MonitoringAlertHistoryPage = () => {
  const { alertId } = useRouteParams(routes.monitoringAlert);

  const { model, updateModelState } = useMonitoringModelContext();

  const { data, communication } = useAlertHistory({ alertId });

  const alert = data && isNotRestrictedGraphqlError(data) ? data : undefined;

  const breadcrumbs = useAppBreadcrumbs(
    useMonitoringModelPageBreadcrumbs({
      modelName: model.name,
    }),
    useBreadcrumbItem({
      name: 'Alerts' + (alert?.name ? `: ${alert.name}` : ''),
      route: routes.monitoringAlertCreation,
    })
  );

  return (
    <MonitoringAlertsLayout
      model={model}
      updateModelState={updateModelState}
      breadcrumbs={breadcrumbs}
    >
      <MonitoringAlertHistory alert={alert} loadingAlert={communication} />
    </MonitoringAlertsLayout>
  );
};

export default MonitoringAlertHistoryPage;
