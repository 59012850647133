import { pipe, prepend, take, uniq } from 'ramda';

const size = 10;

export const addRecentSearch = (
  value: string,
  recentSearches: string[]
): string[] => {
  return pipe(prepend(value), uniq, (x) => take(size, x))(recentSearches);
};
