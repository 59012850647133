import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { validateDescription } from 'shared/models/Description';
import { validateNotEmpty } from 'shared/utils/validators';
import TagsField from 'shared/view/formComponents/formikFields/TagsFieldWithTopLabel/TagsFieldWithTopLabel';
import { selectCurrentWorkspace } from 'features/workspaces/store';
import {
  useCreateProject,
  IProjectCreationSettings,
} from 'features/projects/projectCreation/store/projectCreation/projectCreation';
import routes from 'shared/routes';
import { makeGetFieldName } from 'shared/utils/getFieldName';
import TextInputField from 'shared/view/formComponents/formikFields/TextInputField/TextInputField';
import FormStack from 'shared/view/elements/FormStack/FormStack';
import { PopupStateProps } from 'shared/view/elements/Popup/PopupButtons';
import PopupForm from 'shared/view/formComponents/PopupForm';
import { useCurrentOrganizationV2 } from 'features/organizations/hooks/useCurrentOrganizationV2';

const getFieldName = makeGetFieldName<IProjectCreationSettings>();

const ProjectCreationPopup = (props: PopupStateProps) => {
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const organizationId = useCurrentOrganizationV2();
  const initialSettings: IProjectCreationSettings = React.useMemo(() => {
    return {
      name: '',
      description: '',
      tags: [],
      workspaceName: currentWorkspace.name,
      organizationId,
    };
  }, [currentWorkspace.name, organizationId]);

  const { createProject, creatingProject } = useCreateProject();
  const navigate = useNavigate();
  const onCreateProject = (form: IProjectCreationSettings) => {
    createProject(
      {
        description: form.description || '',
        name: form.name || '',
        // todo probably legacy code and can produce malfunctions. Need to be checked and fixed;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        tags: form.tags ?? [],
        workspaceName: currentWorkspace.name,
        organizationId,
      },
      (projectId) =>
        navigate(
          routes.projectOverview.getRedirectPath({
            projectId,
            workspaceName: currentWorkspace.name,
          })
        )
    );
  };

  return (
    <PopupForm<IProjectCreationSettings>
      {...props}
      initialValues={initialSettings}
      title="Create project"
      communication={creatingProject}
      onSubmit={onCreateProject}
    >
      {() => (
        <FormStack>
          <TextInputField
            name={getFieldName({ name: null })}
            validate={validateNotEmpty('project name')}
            label="Project name"
            dataTest="name"
            isRequired={true}
          />
          <TextInputField
            name={getFieldName({ workspaceName: null })}
            label="Workspace"
            dataTest="workspace"
            disabled={true}
          />
          <TextInputField
            name={getFieldName({ description: null })}
            validate={validateDescription}
            label="Description"
            dataTest="description"
          />
          <TagsField name={getFieldName({ tags: null })} />
        </FormStack>
      )}
    </PopupForm>
  );
};

export default ProjectCreationPopup;
