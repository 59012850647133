import { uniq } from 'ramda';
import { useMemo } from 'react';

import { OmitStrict } from 'shared/utils/types';

import BaseMultiLevelBarChart, {
  BaseMultiLevelBarChartProps,
} from './BaseMultiLevelBarChart';

interface Props
  extends OmitStrict<
    BaseMultiLevelBarChartProps,
    'groupKeys' | 'getGroupLeftOffset'
  > {}

const MultiLevelBarChart = (props: Props) => {
  const groupKeys = useMemo(
    () => uniq(props.data.flatMap((d) => d.groups.map((g) => g.groupKey))),
    [props.data]
  );

  return (
    <BaseMultiLevelBarChart
      {...props}
      groupKeys={groupKeys}
      getGroupLeftOffset={() => 0}
    />
  );
};

export default MultiLevelBarChart;
