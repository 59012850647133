import { RocWidget } from 'shared/models/Monitoring/MonitoringModel/MonitoringPanel/MonitoringWidget/Widgets/CurveChartWidget';
import { DefaultMatchRemoteDataOrError } from 'shared/view/elements/MatchRemoteDataComponents/DefaultMatchRemoteData';
import { useRoc } from 'features/monitoring/widgets/store/roc/useRoc';

import RocChart from './RocChart/RocChart';
import { MonitoringWidgetProps } from '../../../shared/types';

const RocWidgetView = (props: MonitoringWidgetProps<RocWidget>) => {
  const { data, communication } = useRoc({
    widgetExternalDeps: props.widgetExternalDeps,
    output: props.widget.output,
  });

  return (
    <DefaultMatchRemoteDataOrError
      data={data}
      communication={communication}
      context="loading RoC"
    >
      {(loadedData) => (
        <RocChart
          id={props.id}
          roc={loadedData}
          onSetReset={props.onSetReset}
          widgetSize={props.size}
        />
      )}
    </DefaultMatchRemoteDataOrError>
  );
};

export default RocWidgetView;
