import { ScaleBand, ScalePoint } from 'd3-scale';

import { makeViolatingIntervals } from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/ViolatingIntervals/ViolatingIntervals';
import {
  Alerter,
  checkIsAlertingByAlerter,
} from 'shared/models/Monitoring/MonitoringModel/MonitoringAlert/Alerter';
import isNotNil from 'shared/utils/isNotNill';

import { HistogramsChartData } from '../HistogramsChart';

export const makeViolatingHistogramAreas = ({
  alerter,
  xScale,
  data,
  innerWidth,
}: {
  xScale: ScaleBand<string> | ScalePoint<string>;
  data: HistogramsChartData[];
  innerWidth: number;
  alerter: Alerter;
}) => {
  const isAlertingKey = (key: string) =>
    data
      .filter((d) => d.key === key)
      .some(
        (d) =>
          isNotNil(d.meta.drift) &&
          checkIsAlertingByAlerter(d.meta.drift, alerter)
      );

  const items = xScale.domain().map((key) => ({
    isAlerting: isAlertingKey(key),
    key,
  }));

  const intervals = makeViolatingIntervals(items);

  const getPosition = (key: string) => xScale(key) ?? 0;

  return intervals.map((interval) => ({
    x1: getPosition(interval.start),
    x2: interval.end ? getPosition(interval.end) : innerWidth,
  }));
};
